import React, { useState } from 'react';
import {
  DocumentNode,
  PureQueryOptions,
  useApolloClient,
} from '@apollo/client';
import { Container } from 'components/@codelitt/ay-design-library';
import ConfirmationModal from 'components/ConfirmationModal';
import styles from './EditCompForm.module.scss';
import DeleteIcon from 'assets/images/icons/delete.svg';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import NotificationMessage from 'components/NotificationMessage';
import { ISaleInput } from 'interfaces/inputs/ISaleInput';
import { IProperty } from 'interfaces/IProperty';
import { ErrorLogger } from 'services/ErrorLogger';
import { useTranslation } from 'react-i18next';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';

export interface IQueryParams {
  query: DocumentNode;
  paramName: string;
  resultName: string;
}

interface Props {
  comp: ILeaseInput | ISaleInput | IProperty;
  compForm: React.ReactNode;
  deleteButtonText?: string;
  deleteErrorMessage: string;
  deleteQueryParams: IQueryParams;
  headerModal?: string;
  onDeleted?: () => void;
  paragraphModal?: string[];
  refetchQueries?: PureQueryOptions[];
  showDeleteButton?: boolean;
  title?: string;
  titleWrapperClass?: string;
}

const EditCompForm: React.FC<Props> = ({
  comp,
  compForm,
  deleteButtonText,
  deleteErrorMessage,
  deleteQueryParams,
  headerModal,
  onDeleted,
  paragraphModal,
  refetchQueries,
  showDeleteButton,
  title,
  titleWrapperClass,
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState<string>('');

  const showDeleteCompError = (apiMessage?: string) => {
    const message = apiMessage || deleteErrorMessage;

    setMessageError(message);
    setShowError(true);
  };

  const confirmDeleteComp = async () => {
    if (!comp.id) {
      return;
    }

    try {
      setIsDeleting(true);

      const { data } = await client.mutate<any>({
        mutation: deleteQueryParams.query,
        variables: { [deleteQueryParams.paramName]: +comp.id },
        refetchQueries,
      });

      const deleteResult = data?.[deleteQueryParams.resultName];
      if (deleteResult?.message) {
        showDeleteCompError(deleteResult?.message);
      } else if (deleteResult) {
        onDeleted?.();
      } else {
        showDeleteCompError();
      }
    } catch (e) {
      ErrorLogger.log(e as any, deleteErrorMessage);
      showDeleteCompError();
    } finally {
      setShowConfirmDelete(false);
      setIsDeleting(false);
    }
  };

  const renderHeader = () => {
    if (!title && !showDeleteButton) {
      return null;
    }

    return (
      <div className={styles['action-container']}>
        {!!title && (
          <div className={titleWrapperClass || styles['title']}>{title}</div>
        )}

        {showDeleteButton && (
          <button
            className={styles['delete-button']}
            onClick={() => setShowConfirmDelete(true)}
          >
            <>
              {deleteButtonText ||
                t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.deleteComp`)}
              <img
                src={DeleteIcon}
                className={styles['delete-icon']}
                alt="Delete"
              />
            </>
          </button>
        )}
      </div>
    );
  };

  return (
    <Container>
      {renderHeader()}

      {compForm}

      {showConfirmDelete && (
        <ConfirmationModal
          onCloseModal={() => setShowConfirmDelete(false)}
          negativeButton={{
            label: isDeleting
              ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`)
              : t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`),
            isDisabled: isDeleting,
            onclick: () => confirmDeleteComp(),
          }}
          positiveButton={{
            label: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`),
            onclick: () => setShowConfirmDelete(false),
          }}
          header={headerModal}
          paragraph={paragraphModal}
        />
      )}

      <NotificationMessage
        show={showError}
        text={messageError}
        onClose={() => {
          setShowError(false);
        }}
      />
    </Container>
  );
};

export default EditCompForm;
