import {
  COMPANY_SECTIONS,
  COMPANY_SECTIONS_TITLES,
} from 'constants/companySections';
import {
  PROPERTY_SECTIONS,
  PROPERTY_SECTIONS_TITLES,
} from 'constants/propertySections';

export enum PROFILE_TYPES {
  PROPERTY = 'property',
  COMPANY = 'company',
  SUBMARKET = 'submarket',
  MARKET = 'market',
  PROPERTY_SET = 'property_set',
  COMP_SET = 'comp_set',
}

export type FooterSectionType = PROPERTY_SECTIONS | COMPANY_SECTIONS | string;

export type FooterSectionsTitles =
  | PROPERTY_SECTIONS_TITLES
  | COMPANY_SECTIONS_TITLES;

export type FooterButtonType = {
  label: FooterSectionType | FooterSectionsTitles | string;
  type: FooterSectionType;
  iconName?: string;
  iconUrl?: string;
  isVisible?: boolean;
};
