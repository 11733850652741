import { ISubmarketProfileConcessionSlices } from 'interfaces/ISubmarketLeasingConcession';
import { max } from 'd3';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { formatQuarterForTooltip } from 'utils/graphs';
import { colors } from 'constants/colors';
import {
  getCurrencyText,
  getMonthsText,
} from 'components/MarketProfile/Sections/Performance/HistoricalLeasing/Concessions/utils';
import { roundNumberToNearestInt } from 'utils/formatters/number';

const generateBarValues = (
  data: ISubmarketProfileConcessionSlices,
  maxYValue: number,
  isQuarterly?: boolean,
) => {
  const partialPercentage = (1 * (data.avgFreeRentMonth || 0)) / maxYValue;
  return {
    id: `leases-concessions-bar-${data.year}${
      isQuarterly ? `-${data.quarter}` : ''
    }`,
    x: handleYearQuarterData(data, !isQuarterly),
    quarter: data.quarter,
    y: partialPercentage || 0,
    label: `${
      data.avgFreeRentMonth ? getMonthsText(data.avgFreeRentMonth) : ''
    } ${formatQuarterForTooltip(data.quarter, isQuarterly)}`,
    topItems: data.topItems ? data.topItems : [],
    year: data.year,
    isBar: true,
  };
};

const generateLineValues = (
  d: ISubmarketProfileConcessionSlices,
  maxYValue: number,
  isQuarterly?: boolean,
  unitOfMeasurement?: string,
  currencySymbol?: string,
) => {
  const partialPercentage = (1 * (d.avgTIAllowance || 0)) / maxYValue;
  return {
    id: `leases-concessions-line-${d.year}${
      isQuarterly ? `-${d.quarter}` : ''
    }`,
    label: `${getCurrencyText(
      d.avgTIAllowance || 0,
      unitOfMeasurement,
      currencySymbol,
    )} ${formatQuarterForTooltip(d.quarter, isQuarterly)}`,
    x: handleYearQuarterData(d, !isQuarterly),
    y: partialPercentage || 0,
    color: colors.supportive500,
    quarter: d.quarter,
    year: d.year,
    topItems: d.topItems,
  };
};

export const getYAxisValuesFromTotal = (
  highestValue: number,
  numberOfAxis = 4,
) => {
  const firstLevel = 1 / numberOfAxis;
  const xAxisValues = [0];
  for (let i = 1; i <= numberOfAxis; i++) {
    const partialValue = firstLevel * i;
    xAxisValues.push(highestValue * partialValue);
  }
  return xAxisValues;
};

const handleYearQuarterData = (data: any, isAnnual: boolean) => {
  return `${data.year}/${isAnnual ? 1 : data.quarter}`;
};

export const parseGraphData = (
  data: ISubmarketProfileConcessionSlices[],
  currencySymbol: string,
  unitOfMeasurement: UnitOfMeasurement,
  isQuarterly?: boolean,
) => {
  const today = new Date();
  const currentYear = new Date().getFullYear();
  const currentQuarter = Math.floor((today.getMonth() + 3) / 3);

  if (!isQuarterly) {
    data.forEach(item => (item.quarter = 1));
  }
  const items = data.filter(
    item => !(item.year >= currentYear && item.quarter > currentQuarter),
  );

  const totalBarYValue =
    max(items, (d: ISubmarketProfileConcessionSlices) => d.avgFreeRentMonth) ||
    0;

  const yBarAxis = getYAxisValuesFromTotal(
    roundNumberToNearestInt(totalBarYValue),
  );

  const totalLineYValue =
    max(items, (d: ISubmarketProfileConcessionSlices) => d.avgTIAllowance) || 0;

  const yLineAxis = getYAxisValuesFromTotal(
    roundNumberToNearestInt(totalLineYValue),
  );
  const xAxis = items.map(d => handleYearQuarterData(d, !isQuarterly));

  const lineData = items
    .filter(d => d.avgTIAllowance !== null)
    .map((d: ISubmarketProfileConcessionSlices) =>
      generateLineValues(
        d,
        yLineAxis[yLineAxis.length - 1],
        isQuarterly,
        unitOfMeasurement,
        currencySymbol,
      ),
    );

  const barData = items.map((d: ISubmarketProfileConcessionSlices) =>
    generateBarValues(d, yBarAxis[yBarAxis.length - 1], isQuarterly),
  );

  return {
    xAxis,
    yAxis: [yLineAxis, yBarAxis],
    data: barData,
    lineData: lineData,
  };
};
