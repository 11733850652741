import ISale from 'interfaces/ISale';
import { getSaleFormattedFields, SaleField } from './fields';
import { IdName } from 'interfaces/IdName';

export const buildTagsListItems = (
  sale: ISale,
  displayFields: SaleField[],
): IdName[] =>
  displayFields
    .map(field => getSaleFormattedFields(sale, field))
    ?.map((field, i) => ({ id: displayFields[i], name: field }));
