import { startCase, isNaN } from 'lodash';

import locations from 'routes';
import { SideNavigationItemType } from 'components/SideNavigationBar/types';
import {
  AgencyLeasingSection,
  AgencyLeasingOverviewSection,
  AgencyLeasingPropertySection,
  AgencyLeasingTenantSection,
} from 'components/AgencyAssignments/types';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

export const isOverviewSection = (section: string) =>
  section === AgencyLeasingSection.Overview ||
  Object.values(AgencyLeasingOverviewSection).indexOf(
    section as AgencyLeasingOverviewSection,
  ) !== -1;

export const isPropertySection = (section: string) =>
  section === AgencyLeasingSection.Properties ||
  Object.values(AgencyLeasingPropertySection).indexOf(
    section as AgencyLeasingPropertySection,
  ) !== -1;

export const isAvailabilitySection = (section: string) =>
  section === AgencyLeasingSection.Availabilities;

export const isTenantSection = (section: string) =>
  section === AgencyLeasingSection.Tenants ||
  Object.values(AgencyLeasingTenantSection).indexOf(
    section as AgencyLeasingTenantSection,
  ) !== -1;

export const isRecentActivitySection = (section: string) =>
  section === AgencyLeasingSection.RecentActivity;

export const buildNavigationItems = (
  agencyLeasingId?: number,
  disabledItems: string[] = [],
): SideNavigationItemType[] => {
  if (!agencyLeasingId || isNaN(agencyLeasingId)) {
    return [];
  }

  const agencyLeasingPath = locations.showAgencyAssignments(agencyLeasingId);

  return [
    {
      name: AgencyLeasingSection.Overview,
      isDisabled: disabledItems.indexOf(AgencyLeasingSection.Overview) > -1,
      to: `${agencyLeasingPath}/${AgencyLeasingOverviewSection.Summary}`,
      icon: 'tourOverview',
      label: translateText(`${I18N_AVANT_PROPERTY_HEADINGS_PATH}.overview`),
      subitems: [
        {
          name: AgencyLeasingOverviewSection.Summary,
          isDisabled:
            disabledItems.indexOf(AgencyLeasingOverviewSection.Summary) > -1,
          to: `${agencyLeasingPath}/${AgencyLeasingOverviewSection.Summary}`,
          icon: 'nearbyAmenities',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.summary`),
        },
        {
          name: AgencyLeasingOverviewSection.Grid,
          isDisabled:
            disabledItems.indexOf(AgencyLeasingOverviewSection.Grid) > -1,
          to: `${agencyLeasingPath}/${AgencyLeasingOverviewSection.Grid}`,
          icon: 'grid',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.grid`),
        },
      ],
    },
    {
      name: AgencyLeasingSection.Properties,
      isDisabled: disabledItems.indexOf(AgencyLeasingSection.Properties) > -1,
      to: `${agencyLeasingPath}/${AgencyLeasingPropertySection.Summary}`,
      icon: 'performance',
      label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`),
      subitems: [
        {
          name: AgencyLeasingPropertySection.Summary,
          isDisabled:
            disabledItems.indexOf(AgencyLeasingPropertySection.Summary) > -1,
          to: `${agencyLeasingPath}/${AgencyLeasingPropertySection.Summary}`,
          icon: 'board',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.summary`),
        },
        {
          name: AgencyLeasingPropertySection.List,
          isDisabled:
            disabledItems.indexOf(AgencyLeasingPropertySection.List) > -1,
          to: `${agencyLeasingPath}/${AgencyLeasingPropertySection.List}`,
          icon: 'list',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.list`),
        },
      ],
    },
    {
      name: AgencyLeasingSection.Availabilities,
      isDisabled:
        disabledItems.indexOf(AgencyLeasingSection.Availabilities) > -1,
      to: `${agencyLeasingPath}/${AgencyLeasingSection.Availabilities}`,
      icon: 'building',
      label: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.availability_plural`,
      ),
    },
    {
      name: AgencyLeasingSection.Tenants,
      isDisabled: disabledItems.indexOf(AgencyLeasingSection.Tenants) > -1,
      to: `${agencyLeasingPath}/${AgencyLeasingTenantSection.Summary}`,
      icon: 'tenantProspect',
      label: startCase(
        translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenant_plural`),
      ),
      subitems: [
        {
          name: AgencyLeasingTenantSection.Summary,
          isDisabled:
            disabledItems.indexOf(AgencyLeasingTenantSection.Summary) > -1,
          to: `${agencyLeasingPath}/${AgencyLeasingTenantSection.Summary}`,
          icon: 'board',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.summary`),
        },
        {
          name: AgencyLeasingTenantSection.List,
          isDisabled:
            disabledItems.indexOf(AgencyLeasingTenantSection.List) > -1,
          to: `${agencyLeasingPath}/${AgencyLeasingTenantSection.List}`,
          icon: 'list',
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.list`),
        },
      ],
    },
    {
      name: AgencyLeasingSection.RecentActivity,
      isDisabled:
        disabledItems.indexOf(AgencyLeasingSection.RecentActivity) > -1,
      to: `${agencyLeasingPath}/${AgencyLeasingSection.RecentActivity}`,
      icon: 'activity',
      label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.recentActivity`),
    },
  ];
};

export const getAgencyLeasingSectionFromURL = (URL: string) => {
  const AGENCY_LEASING_URL_REG_EXP = /\/agency-assignments\/\d+\/(?=([A-Za-z\-](#)?[A-Za-z\-]))/;

  if (!AGENCY_LEASING_URL_REG_EXP.test(URL)) {
    return '';
  }

  const section = URL.replace(AGENCY_LEASING_URL_REG_EXP, '');

  if (
    isOverviewSection(section) ||
    isPropertySection(section) ||
    isAvailabilitySection(section) ||
    isTenantSection(section) ||
    isRecentActivitySection(section)
  ) {
    return section;
  }

  return '';
};
