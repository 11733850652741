import { useQuery } from '@apollo/client';
import { IProperty } from 'interfaces/IProperty';
import { PROPERTIES_ANALYTICS_SUBMARKETS_SIZE } from 'graphql/propertiesAnalytics/queries';
import { IPropertiesAnalyticsSubmarketsSizeResponse } from 'interfaces/IPropertiesAnalyticsSubmarketsSize';
import { DONUT_GRAPH_DATA_LIMIT } from '../utils';

export const useSubmarketsData = (properties: IProperty[]) => {
  const ids = properties.map(property => property.id);

  const variables = {
    search: {
      filter: {
        ids,
      },
      limit: DONUT_GRAPH_DATA_LIMIT,
    },
  };

  const { data: submarketsData, loading: isLoadingSubmarkets } = useQuery<{
    propertiesAnalyticsSubmarketsSize: IPropertiesAnalyticsSubmarketsSizeResponse;
  }>(PROPERTIES_ANALYTICS_SUBMARKETS_SIZE, {
    variables: variables,
  });

  return {
    submarketsData,
    isLoadingSubmarkets,
  };
};
