import { EscalationType, LeaseTypeEnum } from 'constants/leases';
import { PropertySources } from 'constants/propertySources';
import { getTodayDateISOString } from 'utils/date';
import { fieldHasValue } from 'utils/objects';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyExpense } from 'interfaces/inputs/IPropertyExpense';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';

export const getPricingQuoteState = (
  pricingQuote: IPricingQuoteInput,
): IPricingQuoteInput => {
  return {
    leaseType: LeaseTypeEnum.DIRECT,
    source: PropertySources.QUOTE,
    date: getTodayDateISOString(),
    escalationType: EscalationType.Percentage,
    ...pricingQuote,
  };
};

export const isValidForm = (priceQuote: IPricingQuoteInput) => {
  const { property, date, taxAndOpex } = priceQuote;
  const quoteExpense = getExpenseFromQuoteDate(date, property);
  if (quoteExpense && !taxAndOpex) {
    return false;
  }
  if (priceQuote.escalation && !priceQuote.escalationType) return false;

  return (
    fieldHasValue(property) &&
    fieldHasValue(priceQuote.askingRent) &&
    fieldHasValue(priceQuote.leaseType) &&
    fieldHasValue(priceQuote.rentType) &&
    fieldHasValue(priceQuote.source) &&
    fieldHasValue(priceQuote.pricing) &&
    fieldHasValue(date)
  );
};

export const getExpenseFromQuoteDate = (
  date?: string,
  leaseProperty?: IProperty,
): IPropertyExpense | undefined => {
  let quoteExpense: IPropertyExpense | undefined;
  if (leaseProperty?.expenses) {
    quoteExpense = leaseProperty.expenses.find(
      x => x.year === Number(getYearFromDate(date)),
    );
  }

  return quoteExpense;
};

export const getYearFromDate = (date?: string | null) => {
  return date?.split('-')[0];
};
