import React, { useEffect, useState } from 'react';

import FormControl from 'components/FormControl';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import TextInput from 'components/Inputs/TextInput';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { REGEX_INTEGERS } from 'constants/regex';
import { TimeMeasurement } from 'constants/timeMeasurement';
import { IPropertyExpense } from 'interfaces/inputs/IPropertyExpense';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import { translateText } from 'utils/i18n';
import { currencyInputFormatter } from 'utils/formatters/currency';

import { calculateTaxAndOpexTotal } from '../../calculateFields';
import { CURRENT_YEAR } from '..';
import {
  getAnnualMonthlyOptions,
  getAnnualMonthlySelectedOptions,
} from '../../utils';
import styles from './Expense.module.scss';

interface Props {
  expense: IPropertyExpense;
  isDisabled?: boolean;
  updateExpenseField: (fieldName: string, fieldValue: any) => void;
  propertyCurrencySymbol: string;
  isSampleExpense?: boolean;
  isAnnualMonthlyVisible?: boolean;
}

const Expense: React.FC<Props> = ({
  expense,
  isDisabled,
  updateExpenseField,
  propertyCurrencySymbol,
  isSampleExpense,
  isAnnualMonthlyVisible,
}) => {
  const [taxOpexFieldsDisabled, setTaxOpexFieldsDisabled] = useState(false);
  const ANNUAL_MONTHLY_OPTIONS = getAnnualMonthlyOptions();

  useEffect(() => {
    if (expense) {
      const { tax, taxAndOpex, operatingExpenses } = expense;
      if (taxAndOpex) {
        setTaxOpexFieldsDisabled(true);
      }

      if (tax && operatingExpenses && !taxAndOpex) {
        calculateTaxAndOpex(tax, operatingExpenses);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTaxAndOpex = (tax?: number | null, opex?: number | null) => {
    // calculate total tax&opex on tax or opex value changes
    const taxOpex = calculateTaxAndOpexTotal(tax, opex) || null;
    updateExpenseField('taxAndOpex', taxOpex);
  };

  const updateOperatingExpenses = (value: string) => {
    const tax = convertToNumber(expense.tax);
    const opex = convertToNumber(value);
    updateExpenseField('operatingExpenses', value);
    calculateTaxAndOpex(tax, opex);
  };

  const updateTax = (value: string) => {
    const tax = convertToNumber(value);
    const opex = convertToNumber(expense.operatingExpenses);
    updateExpenseField('tax', value);
    calculateTaxAndOpex(tax, opex);
  };

  const onTaxAndOpexInput = (e: React.FormEvent<HTMLInputElement>) => {
    // when tax&opex total is manually updated by the user
    // disable and clear tax & opex single fields

    const hasValue = !!(e.target as HTMLInputElement).value.trim();

    if (hasValue) {
      updateExpenseField('tax', null);
      updateExpenseField('operatingExpenses', null);
      setTaxOpexFieldsDisabled(true);
    } else {
      setTaxOpexFieldsDisabled(false);
    }
    updateExpenseField('taxAndOpexChangedManually', true);
  };

  const label = isDisabled
    ? `${translateText(
        'avantPlatform.attributes.property.label.taxAndOpex',
      )} ${translateText('avantPlatform.attributes.property.label.currentEst')}`
    : translateText('avantPlatform.attributes.property.label.taxAndOpex');

  return (
    <FormControl
      isMultiline
      label={label}
      wrapperClassName={styles['row']}
      disabled={isDisabled}
    >
      <div className={styles['row-container']}>
        <TextInput
          pattern={REGEX_INTEGERS}
          value={expense?.year}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterYear',
          )}
          onChange={value => updateExpenseField('year', value)}
          label={translateText(
            'avantPlatform.attributes.property.label.expensesYear',
          )}
          isDisabled={isDisabled}
          disabledPlaceholder={isSampleExpense ? `${CURRENT_YEAR}` : ''}
          labelClassName={styles['expense-label']}
          inputClassName={styles['input-year']}
        />
        <CurrencyInput
          currencySymbol={propertyCurrencySymbol}
          iconPosition="left"
          id={isSampleExpense ? 'property-tax-opex-ex' : 'property-tax-opex'}
          onChange={value => updateExpenseField('taxAndOpex', value)}
          onInput={onTaxAndOpexInput}
          formatter={currencyInputFormatter}
          placeholder={translateText('avantProperties.pursuit.label.enterQty')}
          value={expense?.taxAndOpex}
          useDefaultCurrencyFormatter
          label={translateText(
            'avantPlatform.attributes.property.label.taxAndOpexInputLabel',
          )}
          isDisabled={isDisabled}
          disabledPlaceholder={isSampleExpense ? 'NA' : ''}
          labelClassName={styles['expense-label']}
          inputClassName={styles['input-currency']}
        />
        <CurrencyInput
          currencySymbol={propertyCurrencySymbol}
          iconPosition="left"
          id={isSampleExpense ? 'property-opex-ex' : 'property-opex'}
          isDisabled={isDisabled || taxOpexFieldsDisabled}
          onChange={updateOperatingExpenses}
          formatter={currencyInputFormatter}
          placeholder={translateText('avantProperties.pursuit.label.enterQty')}
          value={expense?.operatingExpenses}
          useDefaultCurrencyFormatter
          label={translateText('avantPlatform.attributes.property.label.opex')}
          labelClassName={styles['expense-label']}
          inputClassName={styles['input-currency']}
        />
        <CurrencyInput
          id={isSampleExpense ? 'property-tax-ex' : 'property-tax'}
          formatter={currencyInputFormatter}
          isDisabled={isDisabled || taxOpexFieldsDisabled}
          value={expense?.tax}
          iconPosition="left"
          currencySymbol={propertyCurrencySymbol}
          placeholder={translateText('avantProperties.pursuit.label.enterQty')}
          onChange={updateTax}
          useDefaultCurrencyFormatter
          label={translateText('avantPlatform.attributes.property.label.taxes')}
          labelClassName={styles['expense-label']}
          inputClassName={styles['input-currency']}
        />
      </div>

      {isAnnualMonthlyVisible && (
        <ChoiceInput
          containerWrapperClassName={styles['row-options']}
          data={ANNUAL_MONTHLY_OPTIONS}
          selectedItem={getAnnualMonthlySelectedOptions(
            expense?.timeMeasurement!,
          )}
          onChange={selectedOption => {
            updateExpenseField(
              'timeMeasurement',
              selectedOption
                ? (selectedOption?.id as TimeMeasurement)
                : TimeMeasurement.ANNUAL,
            );
          }}
        />
      )}
    </FormControl>
  );
};

export default Expense;
