import { AxisData } from 'components/Graphs/VerticalGroupBarChart/types';
import React from 'react';
import {
  Flyout,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import {
  DEFAULT_LINE_STYLE,
  handleQuarterAxis,
  NONE_STYLE_VALUE,
} from './utils';
import { LineChartProps, LineData } from './types';
import { formatNumberPercentage } from 'utils/formatters/number';

const VictoryLineChart: React.FC<LineChartProps> = ({
  chartStyle,
  data,
  getLabel,
  isQuarterly,
  legend,
  title,
  hideOddXTickValues,
  tickFormat = d => formatNumberPercentage(parseInt(d)),
}) => {
  return (
    <div>
      <VictoryChart
        style={chartStyle?.chart?.style || DEFAULT_LINE_STYLE.chart?.style}
        height={chartStyle?.chart?.height || DEFAULT_LINE_STYLE.chart?.height}
        width={chartStyle?.chart?.width || DEFAULT_LINE_STYLE.chart?.width}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiPadding={
              chartStyle?.chart?.voronoiPadding ||
              DEFAULT_LINE_STYLE.chart?.voronoiPadding
            }
          />
        }
      >
        <VictoryLabel
          x={chartStyle?.label?.x || DEFAULT_LINE_STYLE.label?.x}
          y={chartStyle?.label?.y || DEFAULT_LINE_STYLE.label?.y}
          text={title}
          backgroundPadding={
            chartStyle?.label?.backgroundPadding ||
            DEFAULT_LINE_STYLE.label?.backgroundPadding
          }
          style={chartStyle?.label?.style || DEFAULT_LINE_STYLE.label?.style}
        />
        {legend ? (
          <VictoryLegend
            height={
              chartStyle?.legend?.height || DEFAULT_LINE_STYLE.legend?.height
            }
            width={
              chartStyle?.legend?.width || DEFAULT_LINE_STYLE.legend?.width
            }
            x={chartStyle?.legend?.x || DEFAULT_LINE_STYLE.legend?.x}
            y={chartStyle?.legend?.y || DEFAULT_LINE_STYLE.legend?.y}
            orientation="horizontal"
            style={
              chartStyle?.legend?.style || DEFAULT_LINE_STYLE.legend?.style
            }
            data={legend}
          />
        ) : null}
        <VictoryAxis
          dependentAxis
          tickValues={data.yAxis}
          style={
            (chartStyle?.yAxis as AxisData)?.style ||
            (DEFAULT_LINE_STYLE.yAxis as AxisData)?.style
          }
          tickFormat={tickFormat}
        />
        <VictoryAxis
          tickValues={data.xAxis}
          tickFormat={d =>
            handleQuarterAxis(d, { isQuarterly, hideOddXTickValues })
          }
          style={chartStyle?.xAxis?.style || DEFAULT_LINE_STYLE.xAxis?.style}
          axisValue={chartStyle?.xAxis?.axisValue}
        />
        {data?.historicalLineData?.map((x: LineData, index: number) => (
          <VictoryGroup
            key={index}
            labelComponent={
              <VictoryTooltip
                style={x.tooltipStyle || DEFAULT_LINE_STYLE.tooltip?.style}
                cornerRadius={DEFAULT_LINE_STYLE.tooltip?.cornerRadius}
                pointerLength={DEFAULT_LINE_STYLE.tooltip?.pointerLength}
                flyoutHeight={DEFAULT_LINE_STYLE.tooltip?.flyoutHeight}
                flyoutPadding={DEFAULT_LINE_STYLE.tooltip?.flyoutPadding}
                dy={-7}
                flyoutStyle={{ ...x.tooltipFlyoutStyle }}
                flyoutComponent={
                  <Flyout
                    pointerWidth={DEFAULT_LINE_STYLE.tooltip?.pointerWidth}
                  />
                }
              />
            }
            labels={({ datum }) => getLabel?.(datum) || ''}
            data={x.values}
          >
            <VictoryLine name={`historical-line-${index}`} style={x.style} />
            <VictoryScatter
              name={`historical-scatter-${index}`}
              style={{
                data: {
                  fill: ({ active }) =>
                    active
                      ? x.styleOnHover?.style.fill || NONE_STYLE_VALUE
                      : x.style?.data.fill!,
                  stroke: ({ active }) =>
                    active
                      ? x.styleOnHover?.style.stroke || NONE_STYLE_VALUE
                      : NONE_STYLE_VALUE,
                  strokeWidth: ({ active }) =>
                    active
                      ? x.styleOnHover?.style.strokeWidth || 0
                      : x.style?.data.strokeWidth!,
                  cursor: ({ active }) =>
                    active
                      ? x.styleOnHover?.style?.cursor || x.style?.data.cursor!
                      : x.style?.data.cursor!,
                },
              }}
              size={3}
            />
          </VictoryGroup>
        ))}
      </VictoryChart>
    </div>
  );
};

export default VictoryLineChart;
