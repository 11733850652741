import styles from './AvailabilityCard.module.scss';
import React from 'react';
import TagsList from 'components/TagsList';
import { AvailabilityFields, IAvailability } from 'interfaces/IAvailability';
import { DOT_LG } from 'constants/placeholders';
import { buildTagsListItems, getAvailabilityFormattedField } from './util';
import Button from 'components/Button/new';

type Props = {
  availability: IAvailability;
  onDelete?: (availability: IAvailability) => void;
  onEdit?: (availability: IAvailability) => void;
};

const AvailabilityCard: React.FC<Props> = ({
  availability,
  onEdit,
  onDelete,
}) => {
  const tags = buildTagsListItems(
    [
      AvailabilityFields.SpaceUse,
      AvailabilityFields.LeaseType,
      AvailabilityFields.FullFloor,
      AvailabilityFields.AvailableSpace,
      AvailabilityFields.Term,
      AvailabilityFields.FloorContiguousSpace,
      AvailabilityFields.BuildingContiguousSpace,
      AvailabilityFields.LandlordBrokerCompany,
      AvailabilityFields.LandlordBrokerContact,
    ],
    availability,
  );

  const getHeaderButtons = () => (
    <div className={styles['action-container']}>
      <Button
        onClick={() => onEdit?.(availability)}
        icon="edit"
        size="xs"
        iconSize={0.75}
        type={'contextual-positive'}
        wrapperClassName={styles['button']}
      />
      <Button
        icon="delete"
        size="xs"
        iconSize={0.75}
        onClick={() => onDelete?.(availability)}
        wrapperClassName={styles['button']}
        type="delete"
      />
    </div>
  );

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>
        <div className={styles['action-container']}>
          <span className={styles.title}>
            {[
              getAvailabilityFormattedField(
                AvailabilityFields.AskingRent,
                availability,
              ),
              getAvailabilityFormattedField(
                AvailabilityFields.TimeMeasurement,
                availability,
              ),
              availability.askingRentType,
              getAvailabilityFormattedField(
                AvailabilityFields.AvailableSpace,
                availability,
              ),
              getAvailabilityFormattedField(
                AvailabilityFields.Floor,
                availability,
              ),
            ]
              .filter(Boolean)
              .join(` ${DOT_LG} `)}
          </span>
          {getHeaderButtons()}
        </div>

        <span className={styles.title}>
          {[
            getAvailabilityFormattedField(
              AvailabilityFields.Suite,
              availability,
            ),
            availability.occupancy,
          ]
            .filter(Boolean)
            .join(` ${DOT_LG} `)}
        </span>
      </h2>
      <div className={styles.content}>
        {!!tags && <TagsList tags={tags} tagBackgroundClass={styles.tag} />}
        {availability?.comments && (
          <p className={styles.notes}>
            <b>Notes: </b>
            {availability?.comments}
          </p>
        )}
      </div>
    </div>
  );
};

export default AvailabilityCard;
