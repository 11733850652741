import {
  AddressAndNameColumn,
  AmenitiesColumn,
  AvailabilityColumn,
  BasicColumn,
  PrimaryColumn,
  RecentLeaseColumn,
  RecentSaleColumn,
  TenantIndustriesColumn,
} from 'components/PropertyProfile/Sections/Comparative/ComparativeColumns';
import { translateText } from 'utils/i18n';
import { I18N_PROPERTY_LABEL_PATH } from 'components/PropertyProfile/constants';
import { capitalize } from 'lodash';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export const getComparativeRows = () => ({
  primary: {
    component: PrimaryColumn,
    isVisible: true,
  },
  addressAndName: {
    component: AddressAndNameColumn,
    headerText: translateText(`${I18N_PROPERTY_LABEL_PATH}.basic`),
    isVisible: true,
  },
  basic: {
    component: BasicColumn,
    isVisible: true,
  },
  availability: {
    component: AvailabilityColumn,
    headerText: capitalize(
      translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.availability`),
    ),
    isVisible: true,
  },
  amenities: {
    component: AmenitiesColumn,
    headerText: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.amenities`),
    isVisible: true,
  },
  tenantIndustries: {
    component: TenantIndustriesColumn,
    headerText: translateText(`${I18N_PROPERTY_LABEL_PATH}.largestIndustries`),
    isVisible: true,
  },
  recentLease: {
    component: RecentLeaseColumn,
    headerText: translateText(`${I18N_PROPERTY_LABEL_PATH}.mostRecentLease`),
    isVisible: true,
  },
  recentSale: {
    component: RecentSaleColumn,
    headerText: translateText(`${I18N_PROPERTY_LABEL_PATH}.mostRecentSale`),
    isVisible: true,
  },
});
