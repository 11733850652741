import React, { useContext } from 'react';
import Button from 'components/Button/new';
import classnames from 'classnames';
import styles from './ActivityCardActions.module.scss';
import { IScoop } from 'interfaces/IScoop';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

interface Props {
  onCommentsClick?: () => void;
  onEditClick?: () => void;
  onShareClick?: () => void;
  scoop?: IScoop;
}

const ActivityCardActions: React.FC<Props> = ({
  onCommentsClick,
  onEditClick,
  onShareClick,
  scoop,
}) => {
  const { hasEditorAccess } = useContext(subscriptionsContext);
  return hasEditorAccess ? (
    <div
      className={classnames(styles['activity-actions'], {
        [styles['scoop-actions']]: !!scoop,
      })}
    >
      {!!scoop && (
        <Button
          icon="comment"
          iconPosition="left"
          label={`${translateText(
            I18N_AVANT_PROPERTY_COMMON_LABEL_PATH + '.comment_plural',
          )} (${scoop.totalScoopComments || 0})`}
          onClick={() => onCommentsClick?.()}
          size="l"
          type="contextual"
        />
      )}
      <Button
        icon="share"
        iconPosition="left"
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.share`)}
        onClick={() => onShareClick?.()}
        size="l"
        type="contextual"
      />
      <Button
        icon="edit"
        iconPosition="left"
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.edit`)}
        onClick={() => onEditClick?.()}
        size="l"
        type="contextual"
      />
    </div>
  ) : null;
};

export default ActivityCardActions;
