import React from 'react';
import classnames from 'classnames';
import style from './AlternateAddressIconSmall.module.scss';
import ReactTooltip from 'react-tooltip';
import AlternateAddressIcon from 'assets/images/icons/alternative-address.svg';
import { IProperty } from 'interfaces/IProperty';
import { translateText } from 'utils/i18n';
import { I18N_PROPERTY_LABEL_PATH } from 'components/PropertyProfile/constants';

type PropTypes = {
  property: IProperty | undefined;
};

const AlternateAddressIconSmall: React.FC<PropTypes> = (props: PropTypes) => {
  const { property } = props;
  const dataTipId = Math.random()
    .toString(36)
    .substring(2, 7);

  if (
    !!property?.numberOfAlternativeAddresses &&
    property?.numberOfAlternativeAddresses > 0
  ) {
    const dataTipText =
      `<b>${translateText(
        `${I18N_PROPERTY_LABEL_PATH}.alternateAddresses`,
      )}:</b>` +
      [
        property?.secondaryAddress,
        property?.tertiaryAddress,
        property?.quaternaryAddress,
      ]
        .filter(address => address !== undefined)
        .map(address => `<br>${address}`)
        .join('');

    return (
      <div data-tip={dataTipText} data-html={true} data-for={dataTipId}>
        <img
          src={AlternateAddressIcon}
          className={classnames(style['major'])}
        />
        <ReactTooltip id={dataTipId} effect="solid" arrowColor="transparent" />
      </div>
    );
  } else {
    return null;
  }
};

export default AlternateAddressIconSmall;
