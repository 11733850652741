import React from 'react';
import styles from './ComparativeColumns.module.scss';
import ComparativeCard from 'components/PropertyProfile/Sections/Comparative/ComparativeCard';
import { Column } from '../constants';
import InformationRow from './ColumnInformation/InformationRow';
import RowSeparator from './ColumnInformation/RowSeparator';
import { formatArea } from 'utils/formatters/area';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { maxBy } from 'lodash';
import {
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
  UNITS_MEASUREMENT_ABBREVIATION,
} from 'constants/unitOfMeasurement';
import {
  isOfficeBuilding,
  isMultifamilyBuilding,
  isDataCenterBuilding,
  isIndustrialBuilding,
} from 'utils/properties';
import { CountryCodes } from 'constants/countryCodes';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_CHART_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_AVANT_PROPERTY_COMP_SET_PATH,
} from 'constants/i18n';
import { formatNumberWithDecimals } from 'utils/formatters/number';

const BasicColumn: React.FC<Column> = ({ property, columnTheme }) => {
  if (!property) return null;
  const { dataCenter, multifamily, industrial, office } = property;

  const getCommonRows = () => {
    const unitOfMeasurement = getUnitOfMeasurementForProperty(
      'buildingSize',
      ModelsWithUnitsOfMeasurement.Property,
      property?.propertyCountry?.code || property?.measurementSystem,
    );

    const renovatedYear = maxBy(property.renovatedYears, 'year')?.year;
    const builtAndRenovatedYear = `${translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.built`,
    )} ${property.builtYear || DASH_PLACEHOLDER} / ${translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.renovated`,
    )} ${renovatedYear || DASH_PLACEHOLDER}`;

    const buildingSize =
      formatArea(
        getFieldValueForProperty(
          'buildingSize',
          property,
          ModelsWithUnitsOfMeasurement.Property,
        ),
        unitOfMeasurement,
      ) || DASH_PLACEHOLDER;

    const floorPlateSize =
      formatArea(
        getFieldValueForProperty(
          'floorPlateSize',
          property,
          ModelsWithUnitsOfMeasurement.Property,
        ),
        `${unitOfMeasurement} ${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.floorplate`,
        )}`,
      ) || DASH_PLACEHOLDER;

    let parkingRatio = DASH_PLACEHOLDER;

    if (property?.parkingRatio) {
      const fixedParkingRatio = parseFloat(property?.parkingRatio.toFixed(2));
      if (
        property.propertyCountry?.code === CountryCodes.CA &&
        property?.buildingSize
      ) {
        let parkingRatioPerSf = Math.floor(
          property?.buildingSize * (fixedParkingRatio / 1000),
        );
        parkingRatioPerSf = property?.buildingSize / parkingRatioPerSf;
        parkingRatio = `1 ${translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.per`,
        )} ${formatNumberWithDecimals(parkingRatioPerSf, 2)} sf ${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.parkingRatio`,
        )}`;
      } else {
        parkingRatio = `${formatNumberWithDecimals(
          fixedParkingRatio,
          2,
        )} ${translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.per`,
        )} 1000 sf ${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.parkingRatio`,
        )}`;
      }
    }

    return (
      <>
        <InformationRow>
          <span className={styles['text-info']}>{buildingSize}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{floorPlateSize}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{builtAndRenovatedYear}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{parkingRatio}</span>
        </InformationRow>
      </>
    );
  };

  const getOfficeRows = () => {
    if (!isOfficeBuilding(property)) return null;

    const unitOfMeasurement = getUnitOfMeasurementForProperty(
      'typicalCeilingHeightFeet',
      ModelsWithUnitsOfMeasurement.Office,
      property?.propertyCountry?.code || property?.measurementSystem,
    );

    let propertyCeiling = DASH_PLACEHOLDER;
    if (unitOfMeasurement === UnitOfMeasurement.feet) {
      const ceiling = [
        office!.typicalCeilingHeightFeet &&
          `${office!.typicalCeilingHeightFeet}'`,
        office!.typicalCeilingHeightInches &&
          `${office!.typicalCeilingHeightInches}''`,
      ]
        .filter(Boolean)
        .join(' ');

      propertyCeiling = ceiling
        ? `${ceiling} ${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.ceiling`,
          )}`
        : DASH_PLACEHOLDER;
    } else {
      const typicalCeilingHeightMt = office!.typicalCeilingHeightMt;
      propertyCeiling = typicalCeilingHeightMt
        ? `${typicalCeilingHeightMt}${UnitOfMeasurement.meter} ${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.ceiling`,
          )}`
        : DASH_PLACEHOLDER;
    }

    const columnSpacingMin = getFieldValueForProperty(
      'columnSpacingMin',
      property,
      ModelsWithUnitsOfMeasurement.Office,
    );
    const columnSpacingMax = getFieldValueForProperty(
      'columnSpacingMax',
      property,
      ModelsWithUnitsOfMeasurement.Office,
    );
    const columnSpacingUnitOfMeasurement = getUnitOfMeasurementForProperty(
      'columnSpacingMax',
      ModelsWithUnitsOfMeasurement.Office,
      property?.propertyCountry?.code || property?.measurementSystem,
    );
    const unitOfMeasurementAbbreviation =
      UNITS_MEASUREMENT_ABBREVIATION[columnSpacingUnitOfMeasurement];

    const propertyColumnSpacing =
      (columnSpacingMin &&
        columnSpacingMax &&
        `${columnSpacingMin}${unitOfMeasurementAbbreviation} x ${columnSpacingMax}${unitOfMeasurementAbbreviation} ${translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.columns`,
        )}`) ||
      DASH_PLACEHOLDER;

    return (
      <>
        <RowSeparator columnTheme={columnTheme} fullWidth />
        <InformationRow>
          <span className={styles['text-info']}>{propertyCeiling}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{propertyColumnSpacing}</span>
        </InformationRow>
      </>
    );
  };

  const getMultifamilyRows = () => {
    if (!isMultifamilyBuilding(property)) return null;

    const unitOfMeasurement = getUnitOfMeasurementForProperty(
      'avgUnitSizeSf',
      ModelsWithUnitsOfMeasurement.Multifamily,
      property?.propertyCountry?.code || property?.measurementSystem,
    );
    const avgUnitSize = getFieldValueForProperty(
      'avgUnitSizeSf',
      property,
      ModelsWithUnitsOfMeasurement.Multifamily,
    );

    return (
      <>
        <RowSeparator columnTheme={columnTheme} fullWidth />
        <InformationRow>
          <span className={styles['text-info']}>
            {formatArea(
              multifamily?.totalUnits,
              `${translateText(
                `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.totalUnits`,
              )}`,
            ) || DASH_PLACEHOLDER}
          </span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>
            {formatArea(
              avgUnitSize,
              `${unitOfMeasurement} ${translateText(
                `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.avgUnitSize`,
              )}`,
            ) || DASH_PLACEHOLDER}
          </span>
        </InformationRow>
      </>
    );
  };

  const getDataCenterRows = () => {
    if (!isDataCenterBuilding(property)) return null;

    const comissionedPowerText =
      dataCenter?.commissionedPower &&
      `${dataCenter?.commissionedPower} ${UnitOfMeasurement.kw} ${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.commissioned`,
      )}`;

    const underConstPower = dataCenter?.underConstPower
      ? `${dataCenter?.underConstPower} ${UnitOfMeasurement.kw} ${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.underConstruction`,
        )}`
      : DASH_PLACEHOLDER;

    const plannedPower = dataCenter?.plannedPower
      ? `${dataCenter?.plannedPower} ${UnitOfMeasurement.kw} ${translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.planned`,
        )}`
      : DASH_PLACEHOLDER;

    return (
      <>
        <RowSeparator columnTheme={columnTheme} fullWidth />
        <InformationRow>
          <span className={styles['text-info']}>{comissionedPowerText}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{underConstPower}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{plannedPower}</span>
        </InformationRow>
      </>
    );
  };

  const getIndustrialRows = () => {
    if (!isIndustrialBuilding(property)) return null;

    const siteCoverageRatio = property.siteCoverageRatio
      ? `${(property.siteCoverageRatio * 100).toFixed(0)}% ${translateText(
          `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.topTitle`,
        )} `
      : DASH_PLACEHOLDER;

    let loadingDocksAndDriveIns = DASH_PLACEHOLDER;
    if (industrial?.driveInDoors || industrial?.loadingDocks) {
      const driveIns = industrial?.driveInDoors
        ? `${industrial?.driveInDoors} ${translateText(
            `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.driveIns`,
          )}`
        : undefined;
      const loadingDocks = industrial?.loadingDocks
        ? `${industrial?.loadingDocks} ${translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.loadingDocks`,
          )}`
        : undefined;

      loadingDocksAndDriveIns = [loadingDocks, driveIns].join(' / ');
    }

    let clearMinAndMaxHeight = DASH_PLACEHOLDER;
    if (
      industrial?.typicalClearHeightMin ||
      industrial?.typicalClearHeightMax
    ) {
      clearMinAndMaxHeight = `${
        industrial?.typicalClearHeightMin
      }' ${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.min`)} ${
        industrial?.typicalClearHeightMax
      }' ${translateText(
        `${I18N_AVANT_PROPERTY_COMP_SET_PATH}.maxClearHeight`,
      )}`;
    }

    const sprinklers = industrial?.sprinklers
      ? `${industrial?.sprinklers} ${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.sprinklers`,
        )}`
      : DASH_PLACEHOLDER;

    const trailerParkingSpaces = industrial?.trailerParkingSpaces
      ? `${industrial?.trailerParkingSpaces} ${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.trailerParkingSpaces`,
        )}`
      : DASH_PLACEHOLDER;

    return (
      <>
        <RowSeparator columnTheme={columnTheme} fullWidth />
        <InformationRow>
          <span className={styles['text-info']}>{siteCoverageRatio}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{loadingDocksAndDriveIns}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{clearMinAndMaxHeight}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{sprinklers}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={styles['text-info']}>{trailerParkingSpaces}</span>
        </InformationRow>
      </>
    );
  };

  return (
    <ComparativeCard
      theme={columnTheme}
      wrapperClassName={styles['primary-information']}
    >
      <div className={styles.body}>
        {getCommonRows()}
        {getMultifamilyRows()}
        {getOfficeRows()}
        {getDataCenterRows()}
        {getIndustrialRows()}
      </div>
    </ComparativeCard>
  );
};

export default BasicColumn;
