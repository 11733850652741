import { isNil } from 'lodash';
import { colors } from 'constants/colors';
import { IAbsorptionChartData } from 'interfaces/IAbsorptionChartData';
import { extent } from 'd3';
import { getFullYear } from 'components/Graphs/utils/helpers';
import { DEFAULT_ABSORPTION_YEARS } from './constants';
import { formatArea } from 'utils/formatters/area';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { IPropertySearchFilters } from 'interfaces/IPropertySearch';
import { getStartYearAndEndYearFromFilters } from '../FindComps/utils';
import { getYearFromDate } from 'utils/date';
import dayjs from 'dayjs';
import { validateAndBuildQuarterlyData } from 'utils/graphs';
import { DOT_LG } from 'constants/placeholders';
import {
  VictoryBarStyle,
  AxisData,
} from 'components/Graphs/VerticalGroupBarChart/types';
import {
  CHART_PADDING,
  DEFAULT_BAR_STYLE,
} from 'components/VictoryBarChart/utils';
import { abbreviateNumber } from 'utils/formatters/number';

type GetYearsToQueryParams = {
  propertyFilters?: IPropertySearchFilters;
  isQuarterly?: boolean;
  numberOfYears?: number;
};

type GetAbsortionChartStyleParams = {
  width?: number;
  unitOfMeasurement?: string;
  isQuarterly?: boolean;
  hideOddXTickValues?: boolean;
};

const getBarColor = (value: number) =>
  value > 0 ? colors.supportive500 : colors.secondaryColor500;

export const ABSORPTION_BAR_WIDTH = 13;
export const ABSORPTION_CUSTOM_YEARS = 20;

export const getYearsToQuery = (params: GetYearsToQueryParams = {}) => {
  const { propertyFilters, isQuarterly, numberOfYears } = params;
  const numberOfYearsBack =
    numberOfYears || isQuarterly
      ? DEFAULT_ABSORPTION_YEARS
      : ABSORPTION_CUSTOM_YEARS;
  const currentYear = getFullYear();
  const { startYear, endYear } = getStartYearAndEndYearFromFilters(
    currentYear - numberOfYearsBack,
    currentYear,
    propertyFilters,
  );
  return {
    endYear,
    startYear,
  };
};

export const getAbsortionChartStyle = (
  params: GetAbsortionChartStyleParams = {},
): VictoryBarStyle => {
  const {
    width = ABSORPTION_BAR_WIDTH,
    unitOfMeasurement = UnitOfMeasurement.sf,
    isQuarterly,
    hideOddXTickValues,
  } = params;

  return {
    chart: {
      height: 150,
      padding: {
        ...CHART_PADDING,
        left: 70,
        top: 40,
        bottom: 25,
      },
      style: {
        parent: {
          ...DEFAULT_BAR_STYLE.chart?.style.parent,
          background: colors.primaryColor900,
        },
      },
    },
    legend: {
      x: 325,
      style: {
        ...DEFAULT_BAR_STYLE.legend?.style,
        labels: {
          ...DEFAULT_BAR_STYLE.legend?.style.labels,
          fontSize: 5,
        },
      },
    },
    label: {
      style: [
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 7,
        },
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 5,
        },
      ],
    },
    bar: {
      ...DEFAULT_BAR_STYLE.bar,
      width,
      cornerRadius: { top: 2 },
      tooltip: {
        cornerRadius: 2,
        pointerLength: 0,
        flyoutHeight: 12,
        flyoutStyle: {
          fill: (data: any) => getBarColor(data.datum.y),
          border: 'none',
        },
        y: 38,
        offset: {
          x: 0,
          y: (data: any) => (data.datum.y > 0 ? 0 : -12),
        },
      },
      style: {
        ...DEFAULT_BAR_STYLE.bar?.style,
        data: {
          fill: (data: any) => getBarColor(data.datum.y),
        },
      },
    },
    xAxis: {
      offsetY: 25,
      style: {
        ...DEFAULT_BAR_STYLE.xAxis?.style,
        tickLabels: {
          fontSize: 5,
          fill: colors.ayWhiteColor,
          paddingLeft: 20,
        },
      },
      tickFormat: (tick: any) => {
        if (isQuarterly) {
          const [year, quarter] = tick.split('/');

          return +quarter === 1 ? year : '';
        }

        return hideOddXTickValues
          ? tick % 2 === 0
            ? `${tick}`
            : ''
          : `${tick}`;
      },
    },
    yAxis: {
      style: {
        ...(DEFAULT_BAR_STYLE.yAxis as AxisData)?.style,
        tickLabels: {
          fontSize: 5,
          fill: colors.ayWhiteColor,
        },
        grid: {
          stroke: colors.uiColor800,
        },
        ticks: {
          stroke: colors.uiColor800,
          size: 15,
        },
      },
      tickFormat: (d: any) => {
        if (isNil(d)) {
          return '';
        }

        return `${abbreviateNumber(d)} ${unitOfMeasurement}`;
      },
      offsetX: 70,
    },
  };
};

export const parseGraphData = (
  paramData: IAbsorptionChartData[],
  unitOfMeasurement?: string,
  isQuarterly?: boolean,
) => {
  const currentYear = Number(getYearFromDate());
  const currentQuarter = dayjs(new Date()).quarter();
  const data = paramData || [];
  let validData = hasData(data)
    ? data.reduce<IAbsorptionChartData[]>(
        (acc, { absorptionSf, absorptionSm, ...rest }) => [
          ...acc,
          {
            ...rest,
            absorptionSf: !absorptionSf ? 0 : absorptionSf,
            absorptionSm: !absorptionSm ? 0 : absorptionSm,
          },
        ],
        [],
      )
    : data.filter(
        d =>
          d.absorptionSf !== undefined &&
          d.absorptionSf !== 0 &&
          d.absorptionSf !== null &&
          d.absorptionSf !== 0,
      );

  if (isQuarterly) {
    validData = validateAndBuildQuarterlyData(
      validData,
      currentYear,
      currentQuarter,
      (item, quarter) => `${item.year}/${quarter}`,
    ) as IAbsorptionChartData[];
  }

  const fieldName =
    unitOfMeasurement === UnitOfMeasurement.sm
      ? 'absorptionSm'
      : 'absorptionSf';
  const extentValues = extent(
    validData,
    (d: IAbsorptionChartData) => d[fieldName],
  ) || [0, 0];

  const minValue = extentValues[0] || 0;
  const maxValue = extentValues[1] || 0;

  const max = Math.max(Math.abs(minValue), Math.abs(maxValue));
  const halfMax = max / 2;

  return {
    xAxis: isQuarterly
      ? validData.map(d => d.date)
      : validData.map((d: IAbsorptionChartData) => d.year),
    yAxis: [max, halfMax, 0, -halfMax, -max],
    data: validData.map((d: IAbsorptionChartData) => {
      const fieldValue = d[fieldName];
      const formattedArea = formatArea(fieldValue, unitOfMeasurement);

      return {
        id: `absorption-chart-${d.year}${isQuarterly ? `-q${d.quarter}` : ''}`,
        x: isQuarterly ? d.date : d.year,
        y: fieldValue,
        color: getBarColor(fieldValue),
        label: isQuarterly
          ? `${formattedArea} ${DOT_LG} Q${d.quarter}`
          : formattedArea,
      };
    }),
  };
};

export const hasData = (data: IAbsorptionChartData[]) =>
  !data.every(
    d =>
      d.absorptionSf === null ||
      d.absorptionSf === 0 ||
      d.absorptionSm === null ||
      d.absorptionSm === 0,
  );
