import React, { useMemo } from 'react';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_PAGES,
} from 'constants/i18n';
import { ILeasesAnalyticsExpirationsResponse } from 'interfaces/ILeasesAnalyticsExpiration';
import { isMissingData } from './utils';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { LeasePeriodType } from 'constants/leases';
import VictoryLeaseExpiration from 'components/Graphs/VictoryLeaseExpiration';
import { getExpirationStyle } from 'components/MarketProfile/Sections/Performance/HistoricalLeasing/LeaseExpiration/utils';

type Props = {
  data: ILeasesAnalyticsExpirationsResponse;
  setPropertiesPopup?: (popup: ILargestPropertiesPopup) => void;
  unitOfMeasurement: UnitOfMeasurement;
  periodType?: string;
};

const I18N_PATH = `${I18N_AVANT_PROPERTY_PAGES}.findComps.subtitles`;

const LeaseExpiration = ({
  data,
  setPropertiesPopup,
  unitOfMeasurement,
  periodType,
}: Props) => {
  const _isMissingData = useMemo(() => {
    return isMissingData(data);
  }, [data]);
  const leaseExpirationsTitle = translateText(
    `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.leaseExpirations`,
  );
  const isQuarterly = periodType === LeasePeriodType.QUARTER;

  const handleClick = (expiringLeasePeriod: string) => {
    const date = expiringLeasePeriod.split('/');
    const currentYear = date[0];
    const currentQuarter = date[1];
    if (expiringLeasePeriod) {
      let leasesIds;
      if (!isQuarterly) {
        leasesIds = data?.items.find(
          (x: any) => x.year.toString() === currentYear,
        )?.topItems;
      } else {
        leasesIds = data?.items.find(
          (x: any) =>
            x.year.toString() === currentYear &&
            x.quarter.toString() === currentQuarter,
        )?.topItems;
      }
      setPropertiesPopup?.({
        isVisible: true,
        popupFilter: { ids: leasesIds },
        popupTitle: leaseExpirationsTitle,
        popupSizeLimit: 50,
        popupSubtitle: translateText(`${I18N_PATH}.largestLeasesExpiring`, {
          info: `${currentYear}${isQuarterly ? ` Q${currentQuarter}` : ''}`,
        }),
        isLeases: true,
      });
    }
  };

  if (!data?.items) {
    return null;
  }

  return (
    <VictoryLeaseExpiration
      unitOfMeasurement={unitOfMeasurement}
      handleClick={handleClick}
      data={data}
      isQuarterly={isQuarterly}
      style={getExpirationStyle(isQuarterly, unitOfMeasurement)}
      isMissingData={_isMissingData}
    />
  );
};

export default LeaseExpiration;
