import React from 'react';
import { tooltipId } from '../nodes';
import { select } from 'd3-selection';
import { HoverData, ITooltipBar } from '../interfaces';
import styles from '../RecentSales.module.scss';
import { formatArea } from 'utils/formatters/area';
import { abbreviateNumber } from 'utils/formatters/number';

interface Props {
  currencySymbol?: string;
  unitOfMeasurement?: string;
  tooltipData?: ITooltipBar;
}

export const updateTooltipPosition = (barNode: any, dataPoint: HoverData) => {
  const tooltip = select(`#${tooltipId}`);

  if (barNode) {
    const { isMostRecent } = dataPoint;
    const tooltipNode: any = tooltip.node();
    const tooltipNodeBounds = tooltipNode.getBoundingClientRect();
    const currentBarBounds = barNode.getBoundingClientRect();

    const topDistance = isMostRecent ? 135 : 130;

    tooltip
      .style('display', 'flex')
      .style(
        'top',
        `${currentBarBounds.y -
          (tooltipNodeBounds.height + topDistance) +
          document.documentElement.scrollTop}px`,
      )
      .style(
        'left',
        `${currentBarBounds.x +
          currentBarBounds.width / 2 -
          tooltipNode.getBoundingClientRect().width / 2}px`,
      );
  } else {
    tooltip.style('display', 'none');
  }
};

const Tooltip: React.FC<Props> = ({
  tooltipData,
  currencySymbol,
  unitOfMeasurement,
}) => {
  const price =
    !!tooltipData?.price &&
    `${currencySymbol}${abbreviateNumber(tooltipData?.price)}`;

  return (
    <dl className={styles['tooltip']} id={tooltipId}>
      {!!price && <dd className={styles['tooltip-main-text']}>{price}</dd>}
      {!!tooltipData?.size && (
        <dd className={styles['tooltip-secondary-text']}>
          {formatArea(tooltipData?.size, unitOfMeasurement)}
        </dd>
      )}
      {!!tooltipData?.count && (
        <dd className={styles['tooltip-tertiary-text']}>{`${
          tooltipData?.count
        }  ${tooltipData?.count === 1 ? 'Sale' : 'Sales'}`}</dd>
      )}
    </dl>
  );
};

export default Tooltip;
