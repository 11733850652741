import dayjs from 'dayjs';
import dateFormats from 'constants/dateFormats';

export const isValidDateFormat = (
  date: string | undefined | null,
  format: string,
): boolean => {
  if (!date) return false;
  return dayjs(date, format).format(format) === date;
};

export const isValidISOFormat = (date: string | undefined | null): boolean => {
  return (
    isValidDateFormat(date, dateFormats.ISO_DATE) ||
    isValidDateFormat(date, dateFormats.ISO_DATE_DAY_NO_LEADING_ZEROS) ||
    isValidDateFormat(date, dateFormats.ISO_DATE_MONTH_NO_LEADING_ZEROS) ||
    isValidDateFormat(date, dateFormats.ISO_DATE_NO_LEADING_ZEROS)
  );
};
