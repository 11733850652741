import { BarStyle } from 'components/Graphs/VerticalBarChart/interfaces';
import {
  AxisData,
  VictoryBarStyle,
} from 'components/Graphs/VerticalGroupBarChart/types';
import {
  DEFAULT_BAR_STYLE,
  getYAxisValuesFromTotal,
} from 'components/VictoryBarChart/utils';
import { colors } from 'constants/colors';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_NAVIGATION,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { StatusTypes, StatusTypesIDs } from 'constants/statusTypes';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { ScaleLinear, scaleLinear } from 'd3';
import {
  abbreviateNumber,
  formatNumberWithDecimals,
} from 'utils/formatters/number';
import { translateText } from 'utils/i18n';

export interface ITimeLineData {
  year: number;
  size: number | null;
  sizeMt: number | null;
  topItems?: number[];
}

export interface PropertiesAnalyticsSquareFootageResponse {
  text: string;
  timelineData: ITimeLineData[];
}

const MIN_BAR_HEIGHT = 10;
const I18N_PATH = `${I18N_AVANT_PROPERTY_NAVIGATION}.properties.label`;

export const BAR_HEIGHT = 180;
export const BAR_WIDTH = 15;

export const PROPERTY_COUNT_LIMIT = 50;
export const DELIVERY_TIMELINE_STACK_COLORS = [
  colors.ayGrassGreenColor,
  colors.ayPureWhiteColor,
  colors.primaryColor500,
];

const getBarStyle = (
  color: string,
  secondaryColor: string,
  isBottomRounded: boolean,
  isTopRounded: boolean,
  textColor: string,
): BarStyle => ({
  color,
  secondaryColor,
  textColor,
  isTopRounded,
  isBottomRounded,
  width: BAR_WIDTH,
  tooltipWidth: 180,
});

const getYearSize = (
  year: number,
  timelineData?: ITimeLineData[],
  measurementKPI?: string,
) => {
  return measurementKPI === UnitOfMeasurement.sf
    ? timelineData?.find(x => x.year === year)?.size || 0
    : timelineData?.find(x => x.year === year)?.sizeMt || 0;
};

const getTopItems = (year: number, timelineData?: ITimeLineData[]) => {
  return timelineData?.find(x => x.year === year)?.topItems || [];
};

const generateBarValues = (
  data: PropertiesAnalyticsSquareFootageResponse[],
  dataType: string,
  year: number,
  measurementKPI: string,
): any => {
  const underConstructionData = data.find(
    (x: any) => x.text === StatusTypes.UnderConstruction,
  );

  const underRenovationData = data.find(
    (x: any) => x.text === StatusTypes.UnderRenovation,
  );

  const proposedShortData = data.find(
    (x: any) => x.text === 'Proposed short-term',
  );

  const proposedLongData = data.find(
    (x: any) => x.text === 'Proposed long-term',
  );
  const maxSize =
    measurementKPI === UnitOfMeasurement.sf
      ? getMaxSizeSF(data)
      : getMaxSizeMt(data);

  const yScale = getYScale(maxSize ? maxSize : 0, BAR_HEIGHT);

  const underConstructionValue = getYearSize(
    year,
    underConstructionData?.timelineData,
    measurementKPI,
  );
  const proposedValue =
    getYearSize(year, proposedShortData?.timelineData, measurementKPI) +
    getYearSize(year, proposedLongData?.timelineData, measurementKPI);

  const underRenovationValue = getYearSize(
    year,
    underRenovationData?.timelineData,
    measurementKPI,
  );

  const proprosedTopItems = getTopItems(
    year,
    proposedShortData?.timelineData,
  ).concat(getTopItems(year, proposedLongData?.timelineData));

  switch (dataType) {
    case StatusTypes.UnderConstruction: {
      return getUnderConstructionValue(
        year,
        yScale,
        underConstructionValue,
        underRenovationValue,
        proposedValue,
        getTopItems(year, underConstructionData?.timelineData),
        measurementKPI,
      );
    }
    case StatusTypes.UnderRenovation: {
      return getUnderRenovationValue(
        year,
        yScale,
        underConstructionValue,
        underRenovationValue,
        proposedValue,
        getTopItems(year, underRenovationData?.timelineData),
        measurementKPI,
      );
    }
    case 'Proposed': {
      return getProposedValue(
        year,
        yScale,
        underConstructionValue,
        underRenovationValue,
        proposedValue,
        proprosedTopItems,
        measurementKPI,
      );
    }
  }
};

const getUnderConstructionValue = (
  year: number,
  yScale: ScaleLinear<number, number>,
  underConstructionValue?: number,
  underRenovationValue?: number,
  proposedValue?: number,
  topItems?: number[],
  measurementKPI?: string,
) => {
  const isTopRounded = underRenovationValue
    ? false
    : proposedValue
    ? false
    : true;
  return {
    data: {
      x: year,
      y: getMinHeightBarValue(yScale, underConstructionValue || 0),
      label: `${translateText(
        `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.underConstruction`,
      )} ${abbreviateNumber(underConstructionValue)} ${
        measurementKPI === UnitOfMeasurement.sf
          ? UnitOfMeasurement.sf
          : UnitOfMeasurement.sm
      }`,
      topItems: topItems,
      status: `${translateText(
        `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.underConstruction`,
      )}`,
      color: colors.ayGrassGreenColor,
    },
    style: getBarStyle(
      colors.ayGrassGreenColor,
      colors.primaryColor700,
      true,
      isTopRounded,
      colors.ayPureWhiteColor,
    ),
  };
};

const getUnderRenovationValue = (
  year: number,
  yScale: ScaleLinear<number, number>,
  underConstructionValue?: number,
  underRenovationValue?: number,
  proposedValue?: number,
  topItems?: number[],
  measurementKPI?: string,
) => {
  return {
    data: {
      x: year,
      y: getMinHeightBarValue(yScale, underRenovationValue || 0),
      label: `${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.underRenovation`,
      )} ${abbreviateNumber(underRenovationValue)} ${
        measurementKPI === UnitOfMeasurement.sf
          ? UnitOfMeasurement.sf
          : UnitOfMeasurement.sm
      }`,
      topItems: topItems,
      status: `${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.underRenovation`,
      )}`,
      color: colors.ayPureWhiteColor,
    },

    style: getBarStyle(
      colors.ayPureWhiteColor,
      colors.uiColor400,
      !underConstructionValue,
      !proposedValue,
      colors.uiColor900,
    ),
  };
};

const getProposedValue = (
  year: number,
  yScale: ScaleLinear<number, number>,
  underConstructionValue?: number,
  underRenovationValue?: number,
  proposedValue?: number,
  topItems?: number[],
  measurementKPI?: string,
) => {
  const isBottomRounded = underRenovationValue
    ? false
    : underConstructionValue
    ? false
    : true;

  return {
    data: {
      x: year,
      y: getMinHeightBarValue(yScale, proposedValue || 0),
      label: `${translateText(`${I18N_PATH}.proposed`)} ${abbreviateNumber(
        proposedValue,
      )} ${
        measurementKPI === UnitOfMeasurement.sf
          ? UnitOfMeasurement.sf
          : UnitOfMeasurement.sm
      }`,
      topItems: topItems,
      status: `${translateText(`${I18N_PATH}.proposed`)}`,
      color: colors.primaryColor500,
    },
    style: getBarStyle(
      colors.primaryColor500,
      colors.primaryColor700,
      isBottomRounded,
      true,
      colors.ayPureWhiteColor,
    ),
  };
};

export const parseGraphData = (
  graphData: PropertiesAnalyticsSquareFootageResponse[],
  measurementKPI: string,
): { barData: any } | null => {
  if (!graphData) return null;

  const typesArray = [
    StatusTypes.UnderConstruction,
    StatusTypes.UnderRenovation,
    'Proposed',
  ];
  const yAxis = getYAxisValuesFromTotal(
    measurementKPI === UnitOfMeasurement.sf
      ? getMaxSizeSF(graphData)
      : getMaxSizeMt(graphData),
  );
  const xAxis = graphData?.[0]?.timelineData?.map((d: any) => d.year);
  const params = typesArray.map(status => {
    return graphData?.[0]?.timelineData?.map(item =>
      generateBarValues(graphData, status, item.year, measurementKPI),
    );
  });

  return {
    barData: {
      yAxis,
      xAxis,
      params,
    },
  };
};

export const getMaxSizeMt = (
  graphData: PropertiesAnalyticsSquareFootageResponse[],
) => {
  const yearSizeArray: number[] = [];
  const filteredData = graphData
    .filter(
      x =>
        x.text === StatusTypes.UnderConstruction ||
        x.text === StatusTypes.UnderRenovation ||
        x.text === 'Proposed short-term' ||
        x.text === 'Proposed long-term',
    )
    .map(x => x.timelineData);
  filteredData[0].forEach(item => {
    let totalSize = 0;
    const yearData = filteredData.map(x => x.filter(y => y.year === item.year));
    yearData.map(x => (totalSize += x[0].sizeMt || 0));
    yearSizeArray.push(totalSize);
  });

  return Number(formatNumberWithDecimals(Math.max(...yearSizeArray)));
};

export const getMaxSizeSF = (
  graphData: PropertiesAnalyticsSquareFootageResponse[],
) => {
  const yearSizeArray: number[] = [];
  const filteredData = graphData
    .filter(
      x =>
        x.text === StatusTypes.UnderConstruction ||
        x.text === StatusTypes.UnderRenovation ||
        x.text === 'Proposed short-term' ||
        x.text === 'Proposed long-term',
    )
    .map(x => x.timelineData);
  filteredData[0].forEach(item => {
    let totalSize = 0;
    const yearData = filteredData.map(x => x.filter(y => y.year === item.year));
    yearData.map(x => (totalSize += x[0].size || 0));
    yearSizeArray.push(totalSize);
  });

  return Number(formatNumberWithDecimals(Math.max(...yearSizeArray)));
};

const getYScale = (maxSize: number, height: number) => {
  const yScale: ScaleLinear<number, number> = scaleLinear()
    .domain([0, maxSize])
    .range([0, height]);

  return yScale;
};

const getMinHeightBarValue = (
  yScale: ScaleLinear<number, number>,
  value: number,
) => {
  if (!value) return 0;
  const barHeight = yScale(value);
  const minBarValue = yScale.invert(MIN_BAR_HEIGHT);
  const yValue = barHeight! < MIN_BAR_HEIGHT ? minBarValue : value;

  return yValue;
};

export const getBorderRadiusBar = (
  datum: any,
  item: any,
  isTop?: boolean,
): number => {
  const borderBar = item.find((bar: any) => bar.data.x === datum.x);
  if (isTop) {
    return borderBar?.style?.isTopRounded ? 2 : 0;
  }

  return borderBar?.style?.isBottomRounded ? 2 : 0;
};

export const getLegends = (statuses?: number[]) => {
  const legends = [];
  const hasUnderConstruction = statuses?.find(
    x => x === Number(StatusTypesIDs.UnderConstruction),
  );

  const hasUnderRenovation = statuses?.find(
    x => x === Number(StatusTypesIDs.UnderRenovation),
  );

  const hasProposed = statuses?.find(
    x =>
      x === Number(StatusTypesIDs.ProposedLongTerm) ||
      x === Number(StatusTypesIDs.ProposedShortTerm),
  );

  if (hasUnderConstruction) {
    legends.push({
      name: translateText(
        `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.underConstruction`,
      ),
      symbol: { fill: colors.ayGrassGreenColor },
    });
  }

  if (hasUnderRenovation) {
    legends.push({
      name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.underRenovation`),
      symbol: { fill: colors.ayWhiteColor },
    });
  }

  if (hasProposed) {
    legends.push({
      name: translateText(`${I18N_PATH}.proposed`),
      symbol: { fill: colors.primaryColor500 },
    });
  }
  return legends;
};

export const getTooltipFlyoutStyle = (datum: any, item: any) => {
  const borderBar = item.find((bar: any) => bar.data.x === datum.x);
  return borderBar?.style.color;
};

export const getTooltipStyle = (datum: any, item: any) => {
  const borderBar = item.find((bar: any) => bar.data.x === datum.x);
  return borderBar?.style.textColor;
};

export const getStatusTranslation = (status: string) => {
  if (status === StatusTypes.UnderConstruction) {
    return translateText(
      `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.underConstruction`,
    );
  }

  if (status === StatusTypes.UnderRenovation) {
    return translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.underRenovation`);
  }

  if (status === 'Proposed') {
    return translateText(`${I18N_PATH}.proposed`);
  }
};

export const getStrokeWidth = (datum: any) => (datum?.tick >= 0 ? 1 : 0);

export const getStrokeColor = (tick: any, tickHovered: any) => {
  if (tick === tickHovered?.x) {
    if (tickHovered?.status === 'Under Renovation') {
      return tickHovered?.color || colors.ayPureWhiteColor;
    }
    if (tickHovered?.status === 'Under Construction') {
      return tickHovered?.color || colors.ayGrassGreenColor;
    }
    if (tickHovered?.status === 'Proposed') {
      return tickHovered?.color || colors.primaryColor500;
    }
  }

  return colors.uiColor700;
};

export const getDeliveryTimelineStyle = (
  measurementKPI: string,
  item?: any,
) => {
  const timelineStyle: VictoryBarStyle = {
    ...DEFAULT_BAR_STYLE,
    chart: {
      domainPadding: { x: [10, 0] },
      style: {
        parent: {
          background: colors.ayBlackColor,
          borderRadius: 5,
        },
      },
      height: BAR_HEIGHT,
    },
    yAxis: {
      ...DEFAULT_BAR_STYLE.yAxis,
      style: {
        ...(DEFAULT_BAR_STYLE.yAxis as AxisData).style,
        tickLabels: {
          ...(DEFAULT_BAR_STYLE.yAxis as AxisData).style.tickLabels,
          fontSize: 5,
          paddingRight: 5,
          paddingBottom: 10,
          fontFamily: 'Open Sans',
        },
        grid: {
          stroke: colors.uiColor800,
          strokeWidth: getStrokeWidth,
        },
        ticks: {
          stroke: colors.uiColor800,
          strokeWidth: getStrokeWidth,
          size: 15,
        },
      },
      tickFormat: (d = 0) =>
        `${`${abbreviateNumber(d)} ${
          measurementKPI === UnitOfMeasurement.sf
            ? UnitOfMeasurement.sf
            : UnitOfMeasurement.sm
        }` ||
          `0 ${
            measurementKPI === UnitOfMeasurement.sf
              ? UnitOfMeasurement.sf
              : UnitOfMeasurement.sm
          }`}`,
    },
    xAxis: {
      style: {
        tickLabels: {
          ...(DEFAULT_BAR_STYLE.xAxis as AxisData).style.tickLabels,
          fontSize: 5,
          paddingTop: 10,
        },
      },
    },
    legend: {
      ...DEFAULT_BAR_STYLE.legend,
      style: {
        ...DEFAULT_BAR_STYLE.legend?.style,
        labels: { fill: colors.ayWhiteColor, fontSize: 5 },
      },
    },
    label: {
      ...DEFAULT_BAR_STYLE.label,
      backgroundPadding: [{ bottom: 2 }],
      style: [
        { fill: colors.ayWhiteColor, fontSize: 7 },
        { fill: colors.ayWhiteColor, fontSize: 5 },
      ],
    },
    bar: {
      ...DEFAULT_BAR_STYLE.bar,
      cornerRadius: {
        top: ({ datum }) => getBorderRadiusBar(datum, item, true),
        bottom: ({ datum }) => getBorderRadiusBar(datum, item),
      },
      animate: {
        duration: 2000,
        onLoad: { duration: 1000 },
      },
      width: BAR_WIDTH,
      tooltip: {
        cornerRadius: 2,
        pointerLength: 0,
        flyoutHeight: 15,
        flyoutStyle: {
          fill: ({ datum }) => getTooltipFlyoutStyle(datum, item),
          border: 'none',
        },
        y: 48,
        style: {
          fill: (props: any) => getTooltipStyle(props.datum, item),
          fontSize: 5,
          fontFamily: 'Open Sans',
        },
      },
    },
  };
  return timelineStyle;
};
