import _ from 'lodash';
import { truncateFloat } from 'utils/formatters/number';

export const handleFormStatusUpdate = (
  data: object | undefined,
  inputData: object,
  callback: (isDirty: boolean) => void,
) => {
  if (data || inputData) {
    // This is to avoid inconsistency between the falsy values from the API and the ones generated in the UI.
    // Please note that ZERO is a valid value, so that's why it compares null/undefined/'' values.
    // For instance, in the API { comments: null } but in the UI { comments: "" } and, of course, they don't match when compared.
    // FYI, if you try to set the value to null in the UI either React or TpeScript will complain.
    const getTruthyValues = (item: any) =>
      _.omitBy(
        item,
        (value: any) => value === null || value === undefined || value === '',
      );

    callback(!_.isEqual(getTruthyValues(data), getTruthyValues(inputData)));
  }
};

export const calculateInterestPercent = (
  partialInvestmentVolume: number,
  price: number,
) => {
  const result = partialInvestmentVolume / (price || 0);

  if (Number.isNaN(result) || !Number.isFinite(result)) return 0;

  return truncateFloat(result * 100, 2);
};

export const calculatePartialInvestmentVolume = (
  interestPercent: number,
  price: number,
) => {
  const result = truncateFloat((interestPercent / 100) * price, 2);
  const isResultValid = !Number.isNaN(result) && Number.isFinite(result);
  return isResultValid ? result : 0;
};
