import React from 'react';
import { params } from './constants';
import { getRentTypesOptions } from './utils';
import { RentTypes } from 'constants/rentTypes';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import styles from '../../FormSection.module.scss';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { translateText } from 'utils/i18n';
import { isUkProperty } from 'utils/properties';
import Checkbox from 'components/Checkbox';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  required?: boolean;
  property?: IProperty;
  rentType: RentTypes;
  selectedItem: string | null | undefined;
  onChange: (field: string, value: any) => void;
  showCleaningIncluded?: boolean;
  cleaningIncludedSelected?: boolean;
}

const RentType: React.FC<Props> = ({
  onChange,
  property,
  rentType,
  selectedItem,
  required = false,
  showCleaningIncluded = false,
  cleaningIncludedSelected = false,
}) => {
  const isPropertyUk = isUkProperty(property);

  const rentTypeOptions = isPropertyUk
    ? getRentTypesOptions().slice(0, 2)
    : getRentTypesOptions();

  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText(params[rentType].label)}
    >
      <div className={styles['wrapper-radio-container']}>
        <ChoiceInput
          selectedItem={{
            id: selectedItem,
            name: selectedItem,
          }}
          data={rentTypeOptions}
          onChange={item => onChange(params[rentType].field, item?.id)}
          containerWrapperClassName={styles['radio-input-container']}
        />
        {showCleaningIncluded && (
          <Checkbox
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.cleaningIncluded`,
            )}
            onChange={checked => onChange('cleaningIncluded', checked)}
            selected={cleaningIncludedSelected}
            labelClassName={styles['checkbox-label']}
          />
        )}
      </div>
    </FormControl>
  );
};

export default RentType;
