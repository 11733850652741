import React, { useContext, useState } from 'react';
import { Col, Input } from 'components/@codelitt/ay-design-library';
import Button from 'components/Button';
import { IRadioItem } from 'components/Inputs/RadioInput';
import {
  FileToPreview,
  IMediaUploadQueryConfig,
} from 'components/ModalMedia/types';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH } from 'constants/i18n';
import { MediaNotesContext } from 'contexts/MediaNoteContext';
import { IdName } from 'interfaces/IdName';
import { translateText } from 'utils/i18n';

import ImageCategoryPicker from '../ImageCategoryPicker';
import styles from './UploadItem.module.scss';

enum OPTIONS_TYPE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

type Props = {
  media: FileToPreview;
  onDelete: (imgKey: string) => void;
  onCategoryChange: (
    imgKey: string,
    category: IdName,
    isSecondaryOption: boolean,
  ) => void;
  queryConfig: IMediaUploadQueryConfig;
  hasCategories?: boolean;
  profileType?: PROFILE_TYPES;
};

const UploadItem: React.FC<Props> = ({
  hasCategories,
  media,
  onDelete,
  onCategoryChange,
  queryConfig,
  profileType,
}) => {
  const { PRIMARY, SECONDARY } = OPTIONS_TYPE;

  const [category, setCategory] = useState<IdName | null>(null);
  const [subCategory, setSubCategory] = useState<IdName | null>(null);
  const isVideo = !!media.isVideo;

  const { addOrUpdateMediaNote } = useContext(MediaNotesContext);
  const [note, setNote] = useState('');

  const onChangeNote = (newValue: string) => {
    setNote(newValue);
    addOrUpdateMediaNote(media.key, newValue);
  };

  const buildSelectedValue = (item: IdName | null) => {
    if (!item) return null;
    return {
      value: item.id,
      label: item.name,
    };
  };

  const onSelectionChange = (
    type: OPTIONS_TYPE,
    selectedItem: IRadioItem | null,
  ) => {
    const newCategory = {
      id: selectedItem?.value!,
      name: selectedItem?.label,
    };

    if (type === PRIMARY) {
      setCategory(newCategory);
      setSubCategory(null);
    } else {
      setSubCategory(newCategory);
      setCategory(null);
    }
    onCategoryChange(media.key, newCategory, type === OPTIONS_TYPE.SECONDARY);
  };

  return (
    <>
      <Col lg={3} md={3} sm={4} xs={4} wrapperClassName={styles.container}>
        <div className={styles['picture-container']}>
          <div className={styles['container-img']}>
            {isVideo ? (
              <video className={styles['item-img']} controls>
                <source src={URL.createObjectURL(media.file)} />
              </video>
            ) : (
              <img
                className={styles['item-img']}
                src={URL.createObjectURL(media.file)}
                alt={category?.name}
              />
            )}
            <div className={styles['progress-bar']}>
              <div
                className={styles['progress-bar-fill']}
                style={{ width: `${media.uploadProgress}%` }}
              />
            </div>
            <div className={styles['trash']}>
              <Button
                onClick={() => {
                  onDelete(media.key);
                }}
                wrapperClassName={styles['delete-btn']}
                type={'ghost'}
              />
            </div>
          </div>
          {hasCategories ? (
            <ImageCategoryPicker
              profileType={profileType}
              propertyTypeId={queryConfig.propertyTypeId}
              isVideo={isVideo}
              selectedCategory={buildSelectedValue(category)}
              selectedSubCategory={buildSelectedValue(subCategory)}
              onCategoryChange={(selectedItem: IRadioItem) =>
                onSelectionChange(PRIMARY, selectedItem)
              }
              onSubCategoryChange={(selectedItem: IRadioItem | null) =>
                onSelectionChange(SECONDARY, selectedItem)
              }
            />
          ) : (
            <section className={styles['file-name']}>{media.name}</section>
          )}
        </div>
        <div className={styles['note-container']}>
          <Input
            placeholder={translateText(
              `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterDescriptionNote`,
            )}
            value={note}
            onChange={onChangeNote}
            type="textarea"
          />
        </div>
      </Col>
    </>
  );
};

export default UploadItem;
