import React from 'react';
import classnames from 'classnames';
import styles from '../DonutGraph.module.scss';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import classNames from 'classnames';
import { DONUT_SIZE } from '../graphConstants';

export enum DONUT_COLOR_TOOLTIP {
  white = 'white',
  goldColor = 'gold-color',
}
interface Props {
  customSize?: number;
  donutGraphData?: IDonutGraphData;
  primaryColor?: DONUT_COLOR_TOOLTIP;
  showSubtitle: boolean;
  size?: DONUT_SIZE;
  tooltipTitle?: string;
  wrapperClassName?: string;
}

const Tooltip: React.FC<Props> = (props: Props) => {
  const {
    customSize,
    donutGraphData,
    primaryColor,
    showSubtitle,
    size,
    tooltipTitle,
  } = props;

  if (!donutGraphData && !tooltipTitle) return null;

  const renderTag = (text: string, index?: number) => {
    const tagColor = primaryColor ? styles[`${primaryColor}`] : '';

    if (size === DONUT_SIZE.SMALL || !!customSize) {
      return (
        <div key={index}>
          <div
            className={classnames(
              styles.tag,
              styles.active,
              styles['big-font'],
              styles.small,
              tagColor,
            )}
          >
            {text}
          </div>
        </div>
      );
    }

    return (
      <div
        key={index}
        className={classnames(
          styles.tag,
          styles.active,
          styles['big-font'],
          DONUT_SIZE.LARGE ? styles.large : null,
          tagColor,
        )}
      >
        {text}
      </div>
    );
  };

  const renderTextWithClass = (title?: string) => {
    if (donutGraphData?.titleClassName) {
      return <span className={donutGraphData.titleClassName}>{title}</span>;
    }
    return title;
  };

  const renderSubtitle = (text: string, index?: number) => {
    if (!text?.trim()?.length)
      return <span key={index} className={styles['empty-tag']} />;

    return (
      <div
        key={index}
        className={classnames(
          styles.tag,
          styles.subtitle,
          DONUT_SIZE.LARGE ? styles.large : null,
        )}
      >
        {renderTextWithClass(text)}
      </div>
    );
  };

  const renderTitleString = () => {
    return (
      <div
        className={classnames({
          [styles['gold-title']]:
            primaryColor === DONUT_COLOR_TOOLTIP.goldColor,
        })}
      >
        {tooltipTitle}
      </div>
    );
  };

  const hasTitle = !!donutGraphData?.title || !!tooltipTitle;
  return (
    <div
      className={classNames(styles['tooltip'], props.wrapperClassName, {
        [styles['small']]: size === DONUT_SIZE.SMALL,
      })}
    >
      {hasTitle && (
        <div
          className={classNames(styles['tooltip-title'], {
            [styles['white']]: primaryColor === DONUT_COLOR_TOOLTIP.white,
            [styles['middle']]: showSubtitle,
            [styles['small']]: size === DONUT_SIZE.SMALL,
            [styles['gold-color']]:
              primaryColor === DONUT_COLOR_TOOLTIP.goldColor,
          })}
        >
          {donutGraphData
            ? renderTextWithClass(donutGraphData.title)
            : renderTitleString()}
        </div>
      )}

      {donutGraphData &&
        donutGraphData?.tags?.map((tag, index) =>
          showSubtitle ? renderSubtitle(tag, index) : renderTag(tag, index),
        )}
    </div>
  );
};

export default Tooltip;
