import React from 'react';
import Icon from 'components/Icon';
import { colors } from 'constants/colors';
import { IdName } from 'interfaces/IdName';
import { includesAmenity } from 'utils/amenities';
import styles from '../ComparativeColumns.module.scss';
import { ComparativeCardTheme } from 'components/PropertyProfile/Sections/Comparative/constants';
import RowSeparator from 'components/PropertyProfile/Sections/Comparative/ComparativeColumns/ColumnInformation/RowSeparator';
import InformationRow from 'components/PropertyProfile/Sections/Comparative/ComparativeColumns/ColumnInformation/InformationRow';

interface Props {
  isLastItem: boolean;
  approvedAmenity: IdName;
  columnTheme: ComparativeCardTheme;
  propertyAmenities: IdName[] | undefined;
}

const AmenityRow: React.FC<Props> = ({
  isLastItem,
  columnTheme,
  approvedAmenity,
  propertyAmenities,
}) => {
  const { aySuccessColor, ayGrey64Color } = colors;
  const hasAmenity = includesAmenity(approvedAmenity.name, propertyAmenities);

  return (
    <div>
      <InformationRow>
        <div className={styles.amenity}>
          <span className={styles['text-info']}>{approvedAmenity.name}</span>
          {
            <Icon
              name={hasAmenity ? 'check' : 'times'}
              color={hasAmenity ? aySuccessColor : ayGrey64Color}
              size={0.75}
            />
          }
        </div>
      </InformationRow>
      {!isLastItem && <RowSeparator columnTheme={columnTheme} />}
    </div>
  );
};

export default AmenityRow;
