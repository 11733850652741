import React from 'react';
import { getLeaseTypeOptions } from './utils';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import styles from '../../FormSection.module.scss';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { translateText } from 'utils/i18n';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string;
  required?: boolean;
  property?: IProperty;
  isLeaseType?: boolean;
  exceptions?: ILeaseInput;
  onChange: (field: string, value: any) => void;
}

const LeaseType: React.FC<Props> = ({
  value,
  property,
  onChange,
  exceptions,
  required = false,
  isLeaseType = false,
}) => {
  const { t } = useTranslation();
  const field = isLeaseType ? 'leaseType' : 'type';
  let options = getLeaseTypeOptions(property, t);
  if (exceptions) {
    options = options.filter(option => !exceptions[option.id]);
  }

  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText('avantPlatform.attributes.lease.label.leaseType')}
    >
      <ChoiceInput
        data={options}
        onChange={item => onChange(field, item?.id)}
        selectedItem={{ id: value, name: value }}
        containerWrapperClassName={styles['radio-input-container']}
      />
    </FormControl>
  );
};

export default LeaseType;
