import { gql } from '@apollo/client';

export const GET_DOCUMENT_CATEGORIES_QUERY = gql`
  query getDocumentCategories {
    documentCategories {
      id
      name
    }
  }
`;

export const GET_DOCUMENTS_BY_PURSUIT_QUERY = gql`
  query documents($pursuitId: Int!) {
    documents(pursuitId: $pursuitId) {
      id
      url
      name
      createdAt
      pursuitId
      createdUser {
        id
        firstName
        lastName
      }
      updatedUser {
        id
        firstName
        lastName
      }
      category {
        id
        name
      }
    }
  }
`;

export const GET_DOCUMENTS_BY_SCOOP_QUERY = gql`
  query documents($scoopId: Int) {
    documents(scoopId: $scoopId) {
      id
      url
      createdAt
      name
      createdUser {
        id
        firstName
        lastName
      }
      updatedUser {
        id
        firstName
        lastName
      }
      category {
        id
        name
      }
    }
  }
`;
