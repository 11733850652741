import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import propertyIcon from 'assets/images/icons/property-red.svg';
import companyIcon from 'assets/images/icons/company.svg';
import leaseIcon from 'assets/images/icons/lease-search-result.svg';
import marketAndSubmarketIcon from 'assets/images/icons/map-marker-red.svg';
import { SuggestionIcon } from '../enums';

export const getSuggestionIcon = (
  propertyCompany: IPropertyCompany,
  suggestionIconType?: SuggestionIcon,
) => {
  switch (suggestionIconType) {
    case SuggestionIcon.companyIcon:
      return companyIcon;

    case SuggestionIcon.propertyIcon:
      return propertyIcon;

    case SuggestionIcon.leaseIcon:
      return leaseIcon;

    case SuggestionIcon.dynamicIcon: {
      const type = propertyCompany.type;
      const isCompany = !!propertyCompany.companyId;
      const isMarketOrSubmarket =
        !!propertyCompany.submarketId || !!propertyCompany.marketId;

      if (isCompany) return companyIcon;

      if (isMarketOrSubmarket) return marketAndSubmarketIcon;

      if (type === SuggestionIcon.mapBoxAddress) return null;

      return propertyIcon;
    }

    default:
      return null;
  }
};
