import { IActivity } from 'interfaces/IActivity';
import { TAG_LIMIT, TypeOfCard } from '../utils';
import React from 'react';
import { PricingQuoteCardField } from 'components/PricingQuotes/PropertyPricingQuoteCard/fields';
import CardContainer from '../CardContainer';
import classNames from 'classnames';
import styles from './PricingQuoteCard.module.scss';
import CardHeader from '../CardHeader';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import TagsList from 'components/TagsList';
import { ColorNames } from 'constants/colorNames';
import { buildTagsListItems } from 'components/PricingQuotes/PropertyPricingQuoteCard/buildTagsListItems';
import Owner from '../Owner';
import CompsComments from 'components/CompsComments';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { IPricingQuote } from 'interfaces/IPricingQuote';
import LinksList from 'components/LinksList';
import { IdName } from 'interfaces/IdName';
import { getTranslatedRentType } from 'utils/leases';

export interface Props {
  activity?: IActivity;
  propertyPriceQuote?: IPricingQuote;
  propertySetId?: number;
  refetch?: () => void;
  type?: TypeOfCard;
  displayFields: PricingQuoteCardField[];
}

const PricingQuoteCard: React.FC<Props> = props => {
  const { type, activity, displayFields, refetch } = props;
  const renderSeparator = () => <div className={styles['card-separator']} />;
  const propertyPriceQuote = activity?.propertyPriceQuote
    ? activity?.propertyPriceQuote
    : props.propertyPriceQuote;
  const property = propertyPriceQuote?.property;
  const isCarousel =
    type === TypeOfCard.CAROUSEL_PROPERTY || type === TypeOfCard.CAROUSEL;

  const tags = buildTagsListItems(propertyPriceQuote!, displayFields);

  const renderTags = () => {
    if (!tags.length) return null;
    return (
      <Row>
        <Col xs={4} lg={12}>
          <TagsList
            tags={tags}
            tagBackgroundClass={styles[`background-${ColorNames.uiColor100}`]}
            tagLimit={isCarousel ? TAG_LIMIT : 0}
          />
        </Col>
      </Row>
    );
  };

  const renderCreatedByUser = () => {
    if (!propertyPriceQuote?.createdUser) return null;
    return (
      <Row>
        <Col>
          <Owner
            createdUser={propertyPriceQuote?.createdUser}
            createdAt={propertyPriceQuote?.createdAt!}
            updatedUser={propertyPriceQuote?.updatedUser!}
            updatedAt={propertyPriceQuote?.updatedAt}
            colored
            lineBreak={type === TypeOfCard.HOME || isCarousel}
            wrapperClassName={styles['owner-container']}
            smallText={isCarousel}
            ignoreSameUserEdit
          />
        </Col>
      </Row>
    );
  };

  const renderNotes = () => {
    if (!propertyPriceQuote?.comment) return null;
    return (
      <Row>
        <Col>
          <CompsComments
            comments={propertyPriceQuote.comment}
            hideNote={isCarousel}
            className={styles.notes}
          />
        </Col>
      </Row>
    );
  };

  const renderText = () => {
    const rentType = propertyPriceQuote?.rentType
      ? getTranslatedRentType(propertyPriceQuote?.rentType)
      : '';
    const text = `${getCurrencySymbol(
      !!activity?.property?.propertyCountry?.currencyCode
        ? activity?.property?.propertyCountry?.currencyCode
        : propertyPriceQuote?.property?.propertyCountry?.currencyCode,
    )}${propertyPriceQuote?.askingRentPsf!.toFixed(2)} ${rentType} ${
      propertyPriceQuote?.pricing
    }`;
    return (
      <>
        <p className={styles.text}>{text}</p>
      </>
    );
  };
  const renderTenantCompany = () =>
    propertyPriceQuote?.tenantCompany ? (
      <LinksList list={[propertyPriceQuote?.tenantCompany] as IdName[]} bold />
    ) : null;

  return (
    <>
      <CardContainer
        wrapperClassName={classNames({
          [styles.share]: type === TypeOfCard.SHARE,
        })}
      >
        <CardHeader
          property={property}
          propertySetId={props.propertySetId}
          activity={
            activity ? activity : { propertyPriceQuote: propertyPriceQuote }
          }
          tagLabel={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.pricingQuote`,
          )}
          refetchActivities={refetch!}
          type={type}
        />
        <div className={styles.content}>
          {type !== TypeOfCard.CAROUSEL_PROPERTY && renderSeparator()}
          {renderTenantCompany()}
          {renderText()}
          {renderTags()}
          {renderNotes()}
          {!isCarousel && renderSeparator()}
          {renderCreatedByUser()}
        </div>
      </CardContainer>
    </>
  );
};

export default PricingQuoteCard;
