const ONE_MEGA_BYTE = 1000000;
const MAX_SIZE = ONE_MEGA_BYTE * 10;

export const isValidImageFormat = (image: File) => {
  return ['image/jpeg', 'image/jpg', 'image/png'].includes(image.type);
};

export const isValidImageSize = (image: File) => {
  return image.size <= MAX_SIZE;
};

export const isValidImage = (image: File | null) => {
  return !!image && isValidImageFormat(image) && isValidImageSize(image);
};
