import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import FormControl from 'components/FormControl';
import DateInput from 'components/Inputs/DateInput';
import Checkbox from 'components/Checkbox';
import TextInput from 'components/Inputs/TextInput';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { REGEX_INTEGERS } from 'constants/regex';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ILeaseOption } from 'interfaces/ILeaseOption';
import { translateText } from 'utils/i18n';
import { currencyInputFormatter } from 'utils/formatters/currency';

import styles from '../../../FormSection.module.scss';
import localStyles from '../LeaseOptions.module.scss';
interface Props {
  currentOption: ILeaseOption;
  setCurrentOption: Dispatch<SetStateAction<ILeaseOption>>;
  commonFields: React.ReactNode;
  currencySymbol?: string | null;
}
export const TerminationFields = ({
  commonFields,
  setCurrentOption,
  currentOption,
  currencySymbol,
}: Props) => {
  return (
    <>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.terminationOptionDate`,
        )}
        wrapperClassName={styles['form-row']}
      >
        <div
          className={classNames(
            styles['large-input-container'],
            styles['large-input-container-gap'],
          )}
        >
          <DateInput
            id="termination-option-date"
            value={currentOption.optionDate}
            onChange={value => {
              setCurrentOption({
                ...currentOption,
                optionDate: value ?? null,
              });
            }}
          />
          <Checkbox
            label={translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.rollingTermination`,
            )}
            onChange={checked =>
              setCurrentOption({
                ...currentOption,
                rollingBreak: checked,
              })
            }
            selected={!!currentOption.rollingBreak}
            labelClassName={styles['checkbox-label']}
          />
        </div>
      </FormControl>
      {commonFields}
      <div className={localStyles['termination-row']}>
        <FormControl
          wrapperClassName={styles['form-row']}
          label={translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.terminationPenalty`,
          )}
        >
          <div className={localStyles['termination-penalty']}>
            <CurrencyInput
              id="termination-penalty"
              value={currentOption?.breakOptionPenalty}
              onChange={value => {
                setCurrentOption({
                  ...currentOption,
                  breakOptionPenalty: value,
                });
              }}
              iconPosition="left"
              formatter={currencyInputFormatter}
              placeholder={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
              )}
              currencySymbol={currencySymbol}
            />
            <UnitOfMeasurementLegend
              unitOfMeasurement={UnitOfMeasurement.psf}
              shouldConvert={false}
            />
          </div>
        </FormControl>
        <FormControl
          wrapperClassName={styles['form-row']}
          label={translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.freeRentIncentive`,
          )}
        >
          <TextInput
            id="free-rent-incentive"
            value={currentOption?.freeRent ?? ''}
            pattern={REGEX_INTEGERS}
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enterMonths`,
            )}
            onChange={value => {
              setCurrentOption({
                ...currentOption,
                freeRent: value ? Number(value) : null,
              });
            }}
          />
        </FormControl>
      </div>
    </>
  );
};
