import { useState } from 'react';
import { HeatMap } from './constants';

export interface IHeatMapControlsState {
  heatmapSelectedOption: HeatMap;
  isHeatmapControlsOpen: boolean;
}

const defaultState: IHeatMapControlsState = {
  heatmapSelectedOption: HeatMap.NONE,
  isHeatmapControlsOpen: false,
};

const useHeatmapControls = (initialState?: IHeatMapControlsState) => {
  const [heatmapState, setHeatmapState] = useState<IHeatMapControlsState>(
    initialState || defaultState,
  );

  return {
    heatmapState,
    setHeatmapState,
  };
};

export default useHeatmapControls;
