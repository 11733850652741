import React from 'react';
import classnames from 'classnames';
import styles from './SwitchOptionButton.module.scss';

export interface SwitchOption {
  text: string;
  value: any;
}

interface Props {
  label?: string;
  labelClassName?: string;
  buttonClass?: string;
  options: SwitchOption[];
  wrapperClassName?: string;
  activeOption: SwitchOption | null;
  onChange: (option: SwitchOption) => void;
}

const SwitchOptionButton: React.FC<Props> = ({
  label,
  labelClassName,
  options,
  onChange,
  buttonClass,
  activeOption,
  wrapperClassName,
}) => (
  <>
    {label && (
      <p className={!!labelClassName ? labelClassName : styles.label}>
        {label}
      </p>
    )}
    <div className={classnames(styles.container, wrapperClassName)}>
      {options.map(option => (
        <button
          key={option.value}
          onClick={() => onChange(option)}
          className={classnames(styles.button, buttonClass, {
            [styles['is-active']]: option.value === activeOption?.value,
          })}
        >
          {option.text}
        </button>
      ))}
    </div>
  </>
);

export default SwitchOptionButton;
