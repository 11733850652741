export enum FieldType {
  common = 'common',
  dataCenter = 'dataCenter',
  healthcare = 'healthcare',
  industrial = 'industrial',
  flexIndustrial = 'flexIndustrial',
  multifamily = 'multifamily',
  office = 'office',
  lifeScience = 'lifeScience',
}

export type RegionAvailability = {
  isEurope: boolean;
  isUK: boolean;
};
