import React from 'react';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { ISearchInputCustomOption } from 'interfaces/ISearchInputCustomOption';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import SearchInput from 'components/SearchInput';
import { SearchResultDataType } from 'components/SearchInput/interfaces';
import locations from 'routes';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_LABEL_PATH } from 'constants/i18n';
import { PropertyCompanyType } from 'constants/propertyCompanyType';
import { SearchParamsService } from 'services/SearchParamsService';
import { SearchInputSize, SuggestionIcon } from '../SearchInput/enums';

const I18N_COMPANY_PROPERTY_SEARCH_INPUT_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.companyPropertySearchInput`;

export type Props = {
  aliasPlaceholder?: (suggestionName: string) => string;
  autofocus?: boolean;
  clearAfterSuggestionClick?: boolean;
  customOption?: ISearchInputCustomOption;
  editValue?: string;
  filterOnlyCompanies?: boolean;
  filterOnlyCities?: boolean;
  hasBottomMargin?: boolean;
  hideEmptySuggestionsPressEnter?: boolean;
  ignoreCompanyId?: number;
  includeSubmarkets?: boolean;
  includeMapBoxResults?: boolean;
  includeMarkets?: boolean;
  initialValue?: string;
  inputId?: string;
  isDisabled?: boolean;
  isEditable?: boolean;
  isSearchIconLeftVisible?: boolean;
  navbar?: boolean;
  onChangeText?: (value?: string) => void;
  onClearSelectedSuggestion?: () => void;
  onClickSuggestionCustom?: (propertyCompany: IPropertyCompany) => void;
  placeholder?: string;
  removeMergedCompanies?: boolean;
  selectable?: boolean;
  size?: SearchInputSize;
  withMarketSwitch?: boolean;
  wrapperClassName?: string;
  inputContainerClassName?: string;
  suggestionClassName?: string;
  inputBottomChildren?: React.ReactNode;
};

const CompaniesPropertiesSearchInput: React.FC<Props> = props => {
  const {
    aliasPlaceholder,
    autofocus,
    clearAfterSuggestionClick,
    customOption,
    editValue,
    filterOnlyCompanies,
    filterOnlyCities,
    hasBottomMargin,
    hideEmptySuggestionsPressEnter,
    ignoreCompanyId,
    includeSubmarkets = false,
    includeMapBoxResults = false,
    includeMarkets = false,
    initialValue,
    inputId,
    isDisabled,
    isEditable,
    isSearchIconLeftVisible,
    navbar,
    onChangeText,
    onClearSelectedSuggestion,
    onClickSuggestionCustom,
    placeholder = translateText(
      `${I18N_COMPANY_PROPERTY_SEARCH_INPUT_LABEL_PATH}.inputPlaceholder`,
    ),
    removeMergedCompanies,
    selectable,
    size,
    withMarketSwitch,
    wrapperClassName,
    inputContainerClassName,
    suggestionClassName,
    inputBottomChildren,
  } = props;

  const parseMergedCompanyToPropertyCompany = (
    mergeCompanyId: number,
    mergeCompanyName: string,
  ): IPropertyCompany => {
    return {
      id: mergeCompanyId,
      name: mergeCompanyName,
      type: 'company',
      industryName: '',
      headquarters: '',
      companyId: mergeCompanyId,
    };
  };

  const filterSuggestions = (suggestions: SearchResultDataType[]) => {
    if (!ignoreCompanyId && !removeMergedCompanies) return suggestions;

    return (suggestions as IPropertyCompany[]).filter(
      (item: IPropertyCompany) => {
        const validId = ignoreCompanyId
          ? item.companyId !== ignoreCompanyId
          : true;
        const validCompany = removeMergedCompanies
          ? !item.mergedCompanyId
          : true;
        return validId && validCompany;
      },
    );
  };

  const onClickSuggestion = (propertyCompany: IPropertyCompany) => {
    const locationOptions = {
      [PropertyCompanyType.COMPANY]: locations.showCompany(
        propertyCompany?.companyId!,
      ),
      [PropertyCompanyType.PROPERTY]: locations.showProperty(
        propertyCompany?.propertyId!,
      ),
      [PropertyCompanyType.MARKET]: locations.showMarket(
        propertyCompany.marketId!,
      ),
      [PropertyCompanyType.SUBMARKET]: locations.showSubmarket(
        propertyCompany?.submarketId!,
        propertyCompany?.propertyTypeId!,
      ),
      [PropertyCompanyType.MAPBOX_ADDRESS]: locations.showAddressProfileAmenities(
        SearchParamsService.parseMapBoxDetailsToUrlParams(
          propertyCompany?.mapBoxDetail,
          propertyCompany?.displayAddress,
        ),
      ),
    };

    window.location.href =
      locationOptions[propertyCompany.type! as PropertyCompanyType];
  };

  const onClickSearchSuggestion = (value: IPropertyCompany) => {
    const { mergedCompanyId, mergedCompanyName } = value;

    // Check if the value is a merged company, and use it for the click action
    const selectedValue =
      mergedCompanyId && mergedCompanyName
        ? parseMergedCompanyToPropertyCompany(
            mergedCompanyId,
            mergedCompanyName,
          )
        : value;

    !!onClickSuggestionCustom
      ? onClickSuggestionCustom?.(selectedValue)
      : onClickSuggestion(selectedValue);
  };

  return (
    <SearchInput
      aliasPlaceholder={aliasPlaceholder}
      clearAfterSuggestionClick={clearAfterSuggestionClick}
      hasBottomMargin={hasBottomMargin}
      inputId={inputId || 'companies-properties-search-input'}
      isEditable={isEditable}
      navbar={navbar}
      autofocus={autofocus}
      suggestionIconType={SuggestionIcon.dynamicIcon}
      onClickSuggestion={onClickSearchSuggestion}
      onChangeText={onChangeText}
      onClearSelectedSuggestion={onClearSelectedSuggestion}
      resultSelectedComponent={editValue}
      filterSuggestions={filterSuggestions}
      inputBottomChildren={inputBottomChildren}
      placeholder={placeholder}
      selectable={selectable}
      initialValue={initialValue}
      hideEmptySuggestionsPressEnter={hideEmptySuggestionsPressEnter}
      size={size}
      isSearchIconLeftVisible={isSearchIconLeftVisible}
      graphqlParams={{
        query: SEARCH_COMPANIES_PROPERTIES_QUERY,
        field: 'name',
        resultDataName: 'propertiesCompanies',
        onlyCompanies: filterOnlyCompanies,
        onlyCities: filterOnlyCities,
        withSubmarkets: includeSubmarkets,
        withMarkets: includeMarkets,
      }}
      withMarketSwitch={withMarketSwitch}
      parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
        return data.map(propertyCompany => ({
          ...propertyCompany,
          ...(filterOnlyCities
            ? { name: `${propertyCompany.name}, ${propertyCompany.state}` }
            : { name: propertyCompany.displayAddress || propertyCompany.name }),
        }));
      }}
      customOption={
        customOption && {
          btnLabel: customOption.btnLabel,
          onSelection: customOption.onSelection,
        }
      }
      wrapperClassName={wrapperClassName}
      suggestionClassName={suggestionClassName}
      inputContainerClassName={inputContainerClassName}
      isDisabled={isDisabled}
      includeMapBoxResults={includeMapBoxResults}
    />
  );
};

export default CompaniesPropertiesSearchInput;
