import { IdName } from 'interfaces/IdName';
import { IUser } from 'interfaces/IUser';
import { LeaseCardField, getFormattedField } from 'components/Leases/fields';
import { ILease } from 'interfaces/ILease';

export const buildScoopCategories = (scoopCategories: IdName[]): string => {
  return scoopCategories.map(category => `#${category.name}`).join(' ');
};

export const getAuthorName = (user?: IUser): string | null => {
  return [user?.firstName, user?.lastName].filter(Boolean).join(' ') || null;
};

export const buildTenantLeaseDetails = (lease: ILease): (string | null)[] => {
  return [
    LeaseCardField.Size,
    LeaseCardField.Floor,
    LeaseCardField.RentPrice,
    LeaseCardField.Escalation,
    LeaseCardField.FreeMonths,
    LeaseCardField.TI,
    LeaseCardField.AdditionalConcessions,
  ]
    .map(field => getFormattedField(lease, field))
    .filter(Boolean);
};
