import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useQuery, DocumentNode } from '@apollo/client';
import { isAtBottomOfPage } from 'utils/position/scroll';
import { ErrorLogger } from 'services/ErrorLogger';
import { ITIM } from 'interfaces/ITIM';
import { ILease } from 'interfaces/ILease';
import ISale from 'interfaces/ISale';
import { IProperty } from 'interfaces/IProperty';

import { BOTTOM_PAGINATION_OFFSET } from '../FindComps/findCompsConstants';
import { GetQueryVariables, ResponseDataType } from './types';

type ItemType = ITIM[] | ILease[] | ISale[] | IProperty[];

type UsePaginationProps = {
  query: DocumentNode;
  getQueryVariables: GetQueryVariables;
  skip: boolean;
  typeOfData: 'properties' | 'leases' | 'sales' | 'TIMs';
  isActive?: boolean;
  onCompleted: (data: ResponseDataType) => void;
  onError: () => void;
};
const PAGE_SIZE = 50;

export const usePagination = ({
  query,
  getQueryVariables,
  skip,
  isActive,
  onCompleted,
  typeOfData,
  onError,
}: UsePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [items, setItems] = useState<ItemType[]>([]);
  const [preventFetching, setPrefentFetching] = useState(false);

  const { data, loading, fetchMore, error, refetch } = useQuery<any>(query, {
    variables: getQueryVariables(1),
    skip: skip,
    onError: () => {
      onError?.();
      setItems([]);
    },
    onCompleted: data => {
      setPrefentFetching(false);
      const newItems = data?.[typeOfData].results ?? [];
      setItems(newItems);
      if (newItems.length < PAGE_SIZE) {
        setPrefentFetching(true);
      }
      if (onCompleted) {
        onCompleted(data);
      }
    },
  });

  const loadNextPage = debounce(async () => {
    if (
      !data ||
      loading ||
      isLoadingNextPage ||
      preventFetching ||
      data.totalPages <= currentPage
    ) {
      return;
    }

    try {
      setIsLoadingNextPage(true);
      const nextPage = currentPage + 1;
      const positionScroll = window.scrollY;

      const { data: nextData } = await fetchMore({
        variables: getQueryVariables(nextPage),
      });

      if (nextData?.[typeOfData]?.results) {
        setItems(prevItems => [...prevItems, ...nextData[typeOfData].results]);

        if (nextData?.[typeOfData]?.results.length < PAGE_SIZE) {
          setPrefentFetching(true);
        }
      }
      setCurrentPage(nextPage);
      if (isAtBottomOfPage()) {
        window.scrollTo(0, positionScroll);
      }
    } catch (e) {
      ErrorLogger.log(e as any, 'Unexpected error loading more data');
    } finally {
      setIsLoadingNextPage(false);
    }
  }, 300);

  useEffect(() => {
    const triggerLoadNextPage = async () => {
      if (!isActive) return;
      if (
        window.scrollY + window.innerHeight >=
        document.body.offsetHeight - BOTTOM_PAGINATION_OFFSET
      ) {
        await loadNextPage();
      }
    };

    window.addEventListener('scroll', triggerLoadNextPage);

    return () => {
      window.removeEventListener('scroll', triggerLoadNextPage);
    };
  }, [isActive, data, currentPage, isLoadingNextPage, loadNextPage]);

  return {
    data,
    items,
    error,
    isLoadingNextPage,
    loading,
    refetch,
    setCurrentPage,
  };
};
