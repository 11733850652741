import React from 'react';
import classnames from 'classnames';
import Button from 'components/Button/new';
import { IdName } from 'interfaces/IdName';
import styles from './SelectAndDeselect.module.scss';
import { Row, Container, Col } from 'components/@codelitt/ay-design-library';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  onDeselectAll: () => void;
  onSelectAll: (selectedOptions?: IdName[]) => void;
  options?: IdName[];
  selectedItems?: IdName[];
  wrapperClassName?: string;
}

const SelectAndDeselect: React.FC<Props> = ({
  onSelectAll,
  onDeselectAll,
  options,
  selectedItems,
  wrapperClassName,
}) => {
  const areAllSelected = selectedItems?.length === options?.length;

  return (
    <Container wrapperClassName={classnames(styles.actions, wrapperClassName)}>
      <Row>
        <Col lg={12} wrapperClassName={styles['actions-row']}>
          <Button
            type="link-strong"
            label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectAll`)}
            wrapperClassName={styles['actions-button']}
            onClick={() =>
              onSelectAll(
                options?.map(item => ({ id: item.id, name: item.name })),
              )
            }
            disabled={areAllSelected}
          />
          <p className={styles.separator}>-</p>
          <Button
            disabled={!selectedItems?.length}
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.deselectAll`,
            )}
            onClick={onDeselectAll}
            type="link-strong"
            wrapperClassName={styles['actions-button']}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SelectAndDeselect;
