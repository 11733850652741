import React, { useContext } from 'react';

import Button from 'components/Button/new';
import DownloadActivitiesButton from 'components/DownloadActivitiesButton';
import ShareActivitiesButton from 'components/ShareActivitiesButton';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

import styles from './RecentActivityControl.module.scss';
import { RecentActivityControlProps } from './types';

const RecentActivityControl = ({
  disableDownloadButton,
  queryVariablesToDownload,
  onAddScoopButtonClick,
}: RecentActivityControlProps) => {
  const { hasEditorAccess } = useContext(subscriptionsContext);

  return (
    <div className={styles['recent-activity-control']}>
      {hasEditorAccess && (
        <Button
          icon="add"
          type="contextual-positive"
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.addScoop`)}
          onClick={() => onAddScoopButtonClick?.()}
          wrapperClassName={styles.control}
        />
      )}
      {queryVariablesToDownload && hasEditorAccess && (
        <>
          <ShareActivitiesButton
            wrapperClassName={styles.control}
            queryVariables={queryVariablesToDownload}
            disabled={disableDownloadButton}
          />
          <DownloadActivitiesButton
            isWhite
            disabled={disableDownloadButton}
            queryVariables={queryVariablesToDownload}
            notificationClassName={styles.notification}
            wrapperClassName={styles.control}
          />
        </>
      )}
    </div>
  );
};

export default RecentActivityControl;
