import { SortDirections } from 'constants/sortDirections';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { PROPERTIES_SIZE_LIMIT } from './constants';

export const getCompsPopupSearchFilter = (
  propertiesPopup: ILargestPropertiesPopup,
) => {
  let filter = {};
  let page = {};

  if (propertiesPopup.popupFilter) {
    filter = { ...propertiesPopup.popupFilter };
    page = {
      page: 1,
      size: propertiesPopup.popupSizeLimit || PROPERTIES_SIZE_LIMIT,
    };
  }

  let sortFieldName = 'buildingSize';
  sortFieldName = propertiesPopup.isLeases ? 'size' : sortFieldName;
  sortFieldName = propertiesPopup.isSales ? 'partialInvVol' : sortFieldName;
  return {
    search: {
      filter,
      page,
      order: {
        direction: SortDirections.desc,
        field: sortFieldName,
      },
    },
  };
};
