import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { SwitchOption } from 'components/SwitchOptionButton';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { translateText } from 'utils/i18n';
import { abbreviateNumber } from 'utils/formatters/number';
import { LeaseBaseRentTime, LeaseRentType } from 'constants/leases';
import { orderBy, isNil, capitalize } from 'lodash';
import { PropertyBlockAvailabilities } from 'interfaces/IAvailability';
import { PropertyBlockAvailabilityFields } from 'components/AskingRentMap/types';
import { formatCurrency, getCurrencySymbol } from 'utils/formatters/currency';

export const ASKING_RENT_TOGGLE_VALUE = 'asking-rent';
export const BLOCK_SIZE_TOGGLE_VALUE = 'block-size';
const PROPERTIES_PIN_LIMIT = 25;

export const ASKING_RENT_MAP_TOGGLE_OPTIONS = [
  {
    value: ASKING_RENT_TOGGLE_VALUE,
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.askingRent`),
  },
  {
    value: BLOCK_SIZE_TOGGLE_VALUE,
    text: capitalize(
      translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.blockSize`),
    ),
  },
];

export const getPinLabel = (
  data: PropertyBlockAvailabilities[],
  filters: LeaseToggleOptions,
  propertyId?: number,
  mapToggleOption?: SwitchOption,
) => {
  const pAvailabilityBlock = (data || []).find(
    item => item.propertyId === propertyId,
  );

  if (!pAvailabilityBlock) {
    return DASH_PLACEHOLDER;
  }

  if (mapToggleOption?.value === ASKING_RENT_TOGGLE_VALUE) {
    const askingRentValue = getValueByFilters(filters, pAvailabilityBlock);
    if (askingRentValue) {
      return `${formatCurrency(askingRentValue, {
        currencySymbol: getCurrencySymbol(pAvailabilityBlock.currency),
        decimals: 0,
      })}`;
    }
    return DASH_PLACEHOLDER;
  } else {
    return (
      `${abbreviateNumber(pAvailabilityBlock.totalAvailability)}` ||
      DASH_PLACEHOLDER
    );
  }
};

export const getValueByFilters = (
  filters: LeaseToggleOptions,
  availability: PropertyBlockAvailabilities,
) => {
  const { rentType, timeMeasurement } = filters;
  let rent;

  if (timeMeasurement === LeaseBaseRentTime.Monthly) {
    rent =
      rentType === LeaseRentType.FS
        ? availability.askingRentFSMonthly
        : availability.askingRentNNNMonthly;
  } else {
    rent =
      rentType === LeaseRentType.FS
        ? availability.askingRentFS
        : availability.askingRentNNN;
  }

  return rent;
};

export const getFieldNameByFilters = (filters: LeaseToggleOptions) => {
  const { rentType, timeMeasurement } = filters;

  if (timeMeasurement === LeaseBaseRentTime.Monthly) {
    return rentType === LeaseRentType.FS
      ? PropertyBlockAvailabilityFields.askingRentFSMonthly
      : PropertyBlockAvailabilityFields.askingRentNNNMonthly;
  } else {
    return rentType === LeaseRentType.FS
      ? PropertyBlockAvailabilityFields.askingRentFS
      : PropertyBlockAvailabilityFields.askingRentNNN;
  }
};

export const getPropertiesForAskingRentMap = (
  filters: LeaseToggleOptions,
  data?: PropertyBlockAvailabilities[],
  mapToggleOption?: SwitchOption,
) => {
  const fieldName =
    mapToggleOption?.value === ASKING_RENT_TOGGLE_VALUE
      ? getFieldNameByFilters(filters)
      : PropertyBlockAvailabilityFields.totalAvailability;
  const mountedData = orderBy(data || [], fieldName, 'desc').filter(x => {
    const value = x[fieldName];
    return !isNil(value);
  });

  return mountedData.slice(0, PROPERTIES_PIN_LIMIT - 1);
};
