import { DropdownOption } from 'components/Dropdown';
import { I18N_PLATFORM_COMMON_LABELS_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';

export const shareActivitiesOptions: DropdownOption[] = [
  {
    id: 1,
    label: translateText(`${I18N_PLATFORM_COMMON_LABELS_PATH}.lastWeeks`, {
      number: '2',
    }),
    value: '14',
  },
  {
    id: 2,
    label: translateText(`${I18N_PLATFORM_COMMON_LABELS_PATH}.lastDays`, {
      number: '30',
    }),
    value: '30',
  },
  {
    id: 2,
    label: translateText(`${I18N_PLATFORM_COMMON_LABELS_PATH}.lastDays`, {
      number: '60',
    }),
    value: '60',
  },
  {
    id: 2,
    label: translateText(`${I18N_PLATFORM_COMMON_LABELS_PATH}.lastDays`, {
      number: '90',
    }),
    value: '90',
  },
];
