import dayjs from 'dayjs';
import { IHistoricalPerformanceTable } from 'interfaces/IHistoricalPerformance';
import { last } from 'lodash';
import { removeTypenameKey } from 'utils/graphql/typename';
import { convertObjectNumericFields } from 'utils/parsers/convertObjectNumericFields';
import { ICellFooter } from '../EditTable/interfaces';
import { formatArea } from 'utils/formatters/area';
import { sumSFValues } from '../../utils';
import { getYearFromDate } from 'utils/date';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { PropertyTypes } from 'constants/propertyTypes';
import { translateText } from 'utils/i18n';
import { I18N_HISTORICAL_PERFORMANCE_TEXT_PATH } from 'components/HistoricalPerformance/constants';
import { convertToNumber } from 'utils/parsers/convertToNumber';

const AVAILABILITY_NUMERIC_FIELDS = [
  'availableSfDirect',
  'availableSfSublet',
  'availableSfShell',
  'availableKwTurnkey',
  'vacantSfDirect',
  'vacantSfSublet',
  'vacantSfShell',
  'vacantKwTurnkey',
  'baseRent',
];

export const getPlaceHolderDate = (date?: string) => {
  const year = getYearFromDate(date);

  return `12 / 31 / ${year}`;
};

const convertAvailabilityVacancyFields = (dataItem: any, fields: string[]) => {
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const value = dataItem[field];
    dataItem[field] = value === undefined ? null : value;
  }
  return dataItem;
};

const isInvalidAvailabilityVacancyData = (
  data: IHistoricalPerformanceTable,
  totalSize: number,
  unitOfMeasurement: string,
): string => {
  if (unitOfMeasurement === UnitOfMeasurement.sf) {
    const directSumInvalid =
      sumSFValues(data.availableSfDirect, data.availableSfSublet) >
      2 * totalSize;
    const subletSumInvalid =
      sumSFValues(data.vacantSfDirect, data.vacantSfSublet) > totalSize;
    const shellSumInvalid =
      (convertToNumber(data.vacantSfShell) || 0) > totalSize ||
      (convertToNumber(data.availableSfShell) || 0) > totalSize;

    if (directSumInvalid || subletSumInvalid || shellSumInvalid) {
      return translateText(
        `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.buildingSizeError`,
        { buildingSize: formatArea(totalSize, unitOfMeasurement) },
      );
    }
  }

  if (unitOfMeasurement === UnitOfMeasurement.kw) {
    const hasSfAndKwFields =
      (!!data.vacantSfShell || !!data.availableSfShell) &&
      (!!data.vacantKwTurnkey || !!data.availableKwTurnkey);

    if (hasSfAndKwFields) {
      return translateText(
        `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.invalidShellTurnkeyError`,
      );
    }
    const kwSizeInvalid =
      (convertToNumber(data.vacantKwTurnkey) || 0) > totalSize ||
      (convertToNumber(data.availableKwTurnkey) || 0) > totalSize;

    if (kwSizeInvalid) {
      return translateText(
        `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.availableVacantSizeError`,
        { buildingSize: formatArea(totalSize, unitOfMeasurement) },
      );
    }
  }

  return '';
};

export const hasInvalidAvailabilityVacancyData = (
  totalSize: number | undefined,
  unitOfMeasurement: string,
  data?: IHistoricalPerformanceTable[],
): string[] => {
  if (data && totalSize) {
    return data
      ?.map(d =>
        isInvalidAvailabilityVacancyData(d, totalSize, unitOfMeasurement),
      )
      .filter(error => error?.length > 0);
  }
  return [];
};

export const prepareAvailabilityVacancyToSubmit = (
  initialData: IHistoricalPerformanceTable[],
  propertyId: number,
  data?: IHistoricalPerformanceTable[],
) => {
  const convertedData = data?.map(d => {
    const dataItem = {
      ...removeTypenameKey(
        convertObjectNumericFields(d, AVAILABILITY_NUMERIC_FIELDS),
      ),
      propertyId,
    };

    delete dataItem.isPlaceholder;

    return convertAvailabilityVacancyFields(
      dataItem,
      AVAILABILITY_NUMERIC_FIELDS,
    );
  });
  const toBeSubmittedData: IHistoricalPerformanceTable[] = [];
  convertedData?.forEach(data => {
    const foundItem = initialData.find(item => item.id === data.id);
    if (!!foundItem) {
      let isChanged = false;
      if (foundItem.availableKwTurnkey !== data.availableKwTurnkey)
        isChanged = true;
      if (foundItem.availableSfDirect !== data.availableSfDirect)
        isChanged = true;
      if (foundItem.availableSfShell !== data.availableSfShell)
        isChanged = true;
      if (foundItem.availableSfSublet !== data.availableSfSublet)
        isChanged = true;
      if (foundItem.date !== data.date) isChanged = true;
      if (foundItem.notes !== data.notes) isChanged = true;
      if (foundItem.rentType !== data.rentType) isChanged = true;
      if (foundItem.timeMeasurement !== data.timeMeasurement) isChanged = true;
      if (foundItem.vacantKwTurnkey !== data.vacantKwTurnkey) isChanged = true;
      if (foundItem.vacantSfDirect !== data.vacantSfDirect) isChanged = true;
      if (foundItem.vacantSfShell !== data.vacantSfShell) isChanged = true;
      if (foundItem.vacantSfSublet !== data.vacantSfSublet) isChanged = true;
      if (foundItem.baseRent !== data.baseRent) isChanged = true;
      if (isChanged) {
        toBeSubmittedData.push(data);
      }
    } else {
      toBeSubmittedData.push(data);
    }
  });
  return toBeSubmittedData;
};

export const generateAvailabilityPlaceholder = (
  currentData: IHistoricalPerformanceTable[],
) => {
  const lastRow = last(currentData);

  const currentYear = dayjs()
    .utc()
    .year();

  const year = lastRow?.date
    ? dayjs(lastRow?.date)
        .subtract(1, 'year')
        .year()
    : currentYear;

  return {
    ...lastRow,
    id: null,
    date: `${year}-12-31`,
    notes: '',
    isPlaceholder: true,
  };
};

export const generateAvailabilityFooterCells = (
  data: IHistoricalPerformanceTable,
  unitOfMeasurement: string,
  propertyType: PropertyTypes | undefined,
): ICellFooter[] => {
  let footerCells: ICellFooter[] = [{ value: getPlaceHolderDate(data.date) }];

  if (propertyType === PropertyTypes.dataCenter) {
    footerCells = [
      ...footerCells,
      {
        value: formatArea(data.availableSfShell, unitOfMeasurement),
        isAlignedOnRight: true,
      },
      {
        value: formatArea(data.vacantSfShell, unitOfMeasurement),
        isAlignedOnRight: true,
      },
      {
        value: formatArea(data.availableKwTurnkey, UnitOfMeasurement.kw),
        isAlignedOnRight: true,
      },
      {
        value: formatArea(data.vacantKwTurnkey, UnitOfMeasurement.kw),
        isAlignedOnRight: true,
      },
    ];
  } else {
    footerCells = [
      ...footerCells,
      {
        value: formatArea(data.availableSfDirect, unitOfMeasurement),
        isAlignedOnRight: true,
      },
      {
        value: formatArea(data.vacantSfDirect, unitOfMeasurement),
        isAlignedOnRight: true,
      },
      {
        value: formatArea(data.availableSfSublet, unitOfMeasurement),
        isAlignedOnRight: true,
      },
      {
        value: formatArea(data.vacantSfSublet, unitOfMeasurement),
        isAlignedOnRight: true,
      },
    ];
  }

  footerCells.push({ value: data.notes });
  return footerCells;
};
