import { useContext } from 'react';
import { DocumentNode, useApolloClient } from '@apollo/client';
import { authContext } from 'contexts/AuthContext';
import { GET_ACTIVITIES_QUERY } from 'graphql/activities';
import { buildQueryFilter } from 'pages/ExploreActivity/buildQueryFilter';

const useMutationRefetchActivities = () => {
  const client = useApolloClient();
  const { user } = useContext(authContext);

  return async (mutation: DocumentNode, variables: any) =>
    await client.mutate({
      mutation,
      variables,
      refetchQueries: [
        {
          query: GET_ACTIVITIES_QUERY,
          variables: {
            search: {
              filter: buildQueryFilter(user),
              shouldReturnPricingQuotes: true,
              page: {
                page: 1,
                size: 5,
              },
              order: {
                direction: 'desc',
                field: 'activityDate',
              },
              includeScoopsWithNoProperties: true,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });
};

export default useMutationRefetchActivities;
