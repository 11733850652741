import React, { useEffect, useRef, useState } from 'react';
import { Column, Row } from 'react-table';
import styles from './EditTable.module.scss';
import { EditableTable } from './interfaces';
import classnames from 'classnames';
import { fieldHasValue } from 'utils/objects';
import { Keyboard } from 'constants/keyboard';
import { last } from 'lodash';

type Props = EditableTable<Object> & {
  column: Column;
  row: Row;
  value?: string | number | null;
  readOnly?: boolean;
};

const EditableCell: React.FC<Props> = ({
  addPlaceholderToDataTable,
  cellsFormatters,
  cellsFocusOrder,
  column,
  columns,
  data,
  focusedCell,
  row,
  setFocusedCell,
  updateCellValue,
  value: initialValue,
  readOnly = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const cellFormatter = cellsFormatters?.find(f => f.columnId === column.id);
  const isAlignedOnRight = cellFormatter?.isAlignedOnRight;
  const getFormattedValue = (value?: string | number | null) =>
    fieldHasValue(value) ? cellFormatter?.formatter?.(value!) || value! : '';

  const [value, setValue] = useState(getFormattedValue(initialValue));
  const [isValid, setIsValid] = useState(true);

  const onBlur = () => {
    if (value?.toString() !== initialValue?.toString()) {
      updateCellValue({
        rowIndex: row.index,
        columnId: column.id,
        value,
      });
    }
  };

  const onFocus = (e: any) => validateCell(e.target.value);

  const onClick = () =>
    setFocusedCell({
      ...focusedCell,
      columnId: column.id,
      rowIndex: row.index,
    });

  const onKeyDown = (e: any) => {
    if (cellsFocusOrder) {
      if ([Keyboard.Enter, Keyboard.Tab].includes(e.keyCode)) {
        e.preventDefault();
        const isLastRow = data.length - 1 === row.index;
        const nextColumnId = cellsFocusOrder[column.id || 0];
        const lastCellId = last(columns)?.id;
        const isLastCellFocused = column.id === lastCellId;

        setFocusedCell({
          ...focusedCell,
          columnId: nextColumnId,
          rowIndex: isLastCellFocused ? row.index + 1 : row.index,
        });

        if (isLastRow && isLastCellFocused) addPlaceholderToDataTable?.();
      } else if ([Keyboard.DownArrow, Keyboard.UpArrow].includes(e.keyCode)) {
        e.preventDefault();
        setFocusedCell({
          ...focusedCell,
          columnId: column.id,
          rowIndex: row.index + (e.keyCode === Keyboard.DownArrow ? 1 : -1),
        });
      }
    }
  };

  const validateCell = (value?: string | number | null) => {
    if (cellFormatter?.validator) setIsValid(cellFormatter?.validator(value));
  };

  const onChange = (e: any) => {
    const val = e.target.value;
    const hasPatternAndMatchesIt =
      cellFormatter?.pattern && !!val.match(cellFormatter?.pattern);

    if (!val || !cellFormatter?.pattern || hasPatternAndMatchesIt) {
      setValue(val);
    }

    validateCell(val);
  };

  useEffect(() => {
    if (
      focusedCell &&
      row.index === focusedCell.rowIndex &&
      column.id === focusedCell.columnId
    ) {
      inputRef.current?.focus();
    }
  }, [focusedCell, row, column]);

  return (
    <input
      ref={inputRef}
      value={value}
      onClick={onClick}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      disabled={(value as any).readOnly || readOnly}
      className={classnames(styles['editable-cell'], {
        [styles['cell-align-right']]: isAlignedOnRight,
        [styles['cell-invalid']]: !isValid,
      })}
    />
  );
};

export default EditableCell;
