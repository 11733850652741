import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { customFetch } from './customFetch';
import { propertiesAuthContext } from './authHeaders';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { userLocationLink } from './userLocationLink';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import AuthService from 'services/Auth';

const OKTA_TOKENS = '@ay-pia-web-OKTA-Token';

const createClient = () => {
  const uploadLink = createUploadLink({
    uri: window._env_.REACT_APP_GRAPHQL_API_URL,
    credentials: 'same-origin',
    fetch: customFetch,
  });

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (window._env_.REACT_APP_ENV === 'LOCAL') {
      console.log('[GraphQL error operation]', operation);
    }
    const appServiceStatus = localStorage.getItem(
      LocalStorageKeys.appServicesStatus,
    );
    if (!!appServiceStatus && appServiceStatus === 'true') {
      if (!!graphQLErrors && graphQLErrors[0].message === 'Unauthorized!') {
        Sentry.captureMessage(
          JSON.stringify({
            tokenExists: !!localStorage.getItem(OKTA_TOKENS),
            isTokenReady: AuthService.isOktaTokensReady(),
            wsStatus: AuthService.fetchWSConnectionsStatus(),
          }),
        );
      } else {
        try {
          Sentry.withScope(scope => {
            scope.setExtra('query', operation.operationName);
            scope.setExtra('variables', JSON.stringify(operation.variables));
            scope.setExtra(
              'graphQLErrors',
              JSON.stringify(graphQLErrors || {}),
            );
            scope.setExtra('networkError', JSON.stringify(networkError || {}));
            Sentry.captureException(new Error('GraphQL error'));
          });
        } catch (_ignored) {}
      }
    }
  });

  const link = ApolloLink.from([
    errorLink,
    propertiesAuthContext,
    userLocationLink,
    uploadLink,
  ]);

  return new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject: object => {
        switch (object.__typename) {
          case 'IdName': {
            const obj: any = object;
            return obj?.id + DASH_PLACEHOLDER + obj?.name;
          }
          case 'ThirdPartyInfo': {
            const obj: any = object;
            return obj.id != null ? obj.id : obj.thirdPartyTypeName;
          }
          default:
            return defaultDataIdFromObject(object);
        }
      },
    }),
    link,
    connectToDevTools:
      !window._env_.REACT_APP_ENV || window._env_.REACT_APP_ENV !== 'PROD',
  });
};

export default createClient;
