import { isNil, isNumber } from 'lodash';

import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { truncateFloat } from 'utils/formatters/number';
import { fieldHasValue } from 'utils/objects';
import { translateText } from 'utils/i18n';

const checkValueIsValid = (value?: number | string | null): boolean =>
  value !== '' && !isNil(value);

const checkValueIsValidNumber = (value: number | undefined | null): boolean =>
  checkValueIsValid(value) && isNumber(value);

export const getCustomOrCalculatedNetEffectiveRent = (
  netEffectiveRentCustom?: number,
  netEffectiveRent?: number,
): number | null => {
  const netEffectiveRentValue = netEffectiveRentCustom || netEffectiveRent;
  return fieldHasValue(netEffectiveRentValue)
    ? truncateFloat(netEffectiveRentValue!, 2)
    : null;
};

export const getInputsData = (
  updateLeaseProperty: (field: string, value: string) => void,
  netEffectiveRentCustom: number | null,
  netEffectiveRent: number | null,
  isCustom: boolean,
) => {
  if (!isCustom) {
    return {
      id: 'ner-calculated-input',
      label: translateText('avantProperties.pursuit.label.calculatedNer'),
      value: netEffectiveRent
        ? truncateFloat(netEffectiveRent, 2)
        : netEffectiveRentCustom,
      isDisabled: true,
      isLabelDisabled: !checkValueIsValidNumber(netEffectiveRent),
      onChange: undefined,
      placeholder: DASH_PLACEHOLDER,
    };
  } else {
    return {
      id: 'ner-custom-input',
      label: translateText('avantProperties.pursuit.label.customNer'),
      value: netEffectiveRentCustom,
      isDisabled: false,
      isLabelDisabled: false,
      placeholder: translateText('avantProperties.pursuit.label.enterQty'),
      onChange: (value: string) =>
        updateLeaseProperty('netEffectiveRentCustom', value),
    };
  }
};
