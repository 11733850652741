import React, { useEffect, useRef, useState } from 'react';
import styles from './AddressSearchInput.module.scss';
import ClearIcon from 'assets/images/icons/clear-icon-grey-64.svg';
import classnames from 'classnames';
import { useMapsAutocomplete } from 'hooks/useMapsAutocomplete';
import { hasStreetAddress, isCity } from './getAddressParts';
import { useTranslation } from 'react-i18next';
import {
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
} from 'constants/i18n';
import NotificationMessage from 'components/NotificationMessage';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onClickSuggestion?: (
    place: google.maps.places.PlaceResult | null,
    suggestionDescription?: string,
  ) => void;
  onClear?: () => void;
  clearable?: boolean;
}

const ON_BLUR_DELAY = 500;

const AddressSearchInput: React.FC<Props> = props => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSuggestionListOpen, setIsSuggestionListOpen] = useState(false);
  const [placesSuggestions, setPlacesSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [autocompleteService, sessionToken] = useMapsAutocomplete();
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    google.maps.places.AutocompletePrediction
  >();
  const [isInputHovered, setIsInputHovered] = useState(false);
  const [isInvalidSuggestion, setIsInvalidSuggestion] = useState(false);

  const clearBtnVisible = props.clearable && props.value.length > 0;
  const hasSuggestions = placesSuggestions.length > 0;

  useEffect(() => {
    if (!props.value || !autocompleteService || selectedSuggestion) return;

    autocompleteService.getPlacePredictions(
      { input: props.value, sessionToken: sessionToken },
      (
        predictions: google.maps.places.AutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) return;

        setPlacesSuggestions(predictions || []);
        setSelectedSuggestion(undefined);
        setIsSuggestionListOpen(true);
      },
    );
    // eslint-disable-next-line
  }, [props.value]);

  const cleanSuggestions = () => {
    setPlacesSuggestions([]);
    setIsSuggestionListOpen(false);
  };

  const onClickSuggestion = (
    suggestion: google.maps.places.AutocompletePrediction,
  ) => {
    const suggestionDescription = hasStreetAddress(suggestion)
      ? ''
      : suggestion.structured_formatting.main_text;

    if (containerRef.current) {
      const placeService = new google.maps.places.PlacesService(
        containerRef.current,
      );
      placeService.getDetails(
        {
          placeId: suggestion.place_id,
          sessionToken: sessionToken,
        },
        (
          place: google.maps.places.PlaceResult | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) return;
          const shortCountryName = place?.address_components?.find(comp =>
            comp.types.includes('country'),
          )?.short_name;
          props.onClickSuggestion?.(place, suggestionDescription);

          if (
            !place?.address_components?.find(addressComp =>
              isCity(addressComp, shortCountryName || 'default'),
            )
          ) {
            setIsInvalidSuggestion(true);
          }
        },
      );
    }
    setSelectedSuggestion(suggestion);
    cleanSuggestions();
  };

  const renderSuggestions = () => (
    <div
      ref={containerRef}
      className={classnames(styles['suggestion-container'], {
        [styles['hover']]: isInputHovered,
      })}
    >
      {placesSuggestions?.map(suggestion => (
        <div
          key={suggestion.place_id}
          className={styles['suggestion-item']}
          onClick={() => onClickSuggestion(suggestion)}
        >
          <div>{suggestion.structured_formatting.main_text}</div>
          <div className={styles['secondary-text']}>
            {suggestion.structured_formatting.secondary_text}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.container}>
      {clearBtnVisible && (
        <img
          src={ClearIcon}
          alt="Clear"
          className={styles['btn-clear']}
          onClick={() => {
            props.onClear?.();
            props.onChange('');
            cleanSuggestions();
          }}
        />
      )}
      <input
        placeholder={t(
          `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterAddress`,
        )}
        ref={inputRef}
        className={classnames(styles.input, {
          [styles['suggestion-list-open']]: isSuggestionListOpen,
        })}
        value={props.value}
        onChange={e => {
          props.onChange(e.target.value);
          setSelectedSuggestion(undefined);
        }}
        onFocus={() => setIsSuggestionListOpen(hasSuggestions)}
        onMouseEnter={() => setIsInputHovered(true)}
        onMouseLeave={() => setIsInputHovered(false)}
        onBlur={() => {
          setTimeout(() => {
            setIsSuggestionListOpen(false);
          }, ON_BLUR_DELAY);
        }}
      />
      {isSuggestionListOpen && (
        <div className={styles['separator-container']}>
          <div className={styles['separator']} />
        </div>
      )}
      {isSuggestionListOpen && renderSuggestions()}
      <NotificationMessage
        show={isInvalidSuggestion}
        text={t(
          `${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound.propertyCityNotFound`,
        )}
        onClose={() => setIsInvalidSuggestion(false)}
      />
    </div>
  );
};

export default AddressSearchInput;
