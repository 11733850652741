import React from 'react';
import {
  getTitle,
  getSwitchOptions,
  HistoricalAvailabilityTypes,
} from './utils';
import SwitchOptionButton from 'components/SwitchOptionButton';
import styles from './HistoricalAvailability.module.scss';
import VictoryLineChart from '../VictoryLineChart';
import { LineChart, VictoryLineStyle } from '../VictoryLineChart/types';
import { Col, Row } from '../../@codelitt/ay-design-library';

type Props = {
  graphData: LineChart;
  activeType: HistoricalAvailabilityTypes;
  setActiveType: (type: HistoricalAvailabilityTypes) => void;
  isQuarterly?: boolean;
  getLabel?: (datum?: any) => string;
  hideOddXTickValues?: boolean;
  legends?: any;
  subTitle?: string;
  chartStyle?: VictoryLineStyle;
};

const HistoricalAvailabilityVictoryChart: React.FC<Props> = ({
  graphData,
  activeType,
  setActiveType,
  isQuarterly,
  getLabel,
  hideOddXTickValues,
  legends,
  subTitle,
  chartStyle,
}) => {
  const { OCCUPIED, AVAILABLE, VACANT } = HistoricalAvailabilityTypes;

  const headerContent = () => (
    <div className={styles['content-switch']}>
      <SwitchOptionButton
        buttonClass={styles['switch-button']}
        wrapperClassName={styles['types-switch']}
        activeOption={getSwitchOptions()[activeType]}
        onChange={({ value }) => setActiveType?.(value)}
        options={[
          getSwitchOptions()[VACANT],
          getSwitchOptions()[AVAILABLE],
          getSwitchOptions()[OCCUPIED],
        ]}
      />
    </div>
  );

  return (
    <>
      <Row>
        <Col wrapperClassName={styles['content-chart']}>
          {headerContent()}
          <VictoryLineChart
            data={graphData}
            title={[getTitle(activeType), subTitle || '']}
            legend={legends}
            getLabel={getLabel}
            isQuarterly={isQuarterly}
            hideOddXTickValues={hideOddXTickValues}
            chartStyle={chartStyle}
          />
        </Col>
      </Row>
    </>
  );
};

export default HistoricalAvailabilityVictoryChart;
