import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';

import { UNITS_MEASUREMENT_AREA } from 'constants/unitOfMeasurement';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import dateFormats from 'constants/dateFormats';
import { ILease } from 'interfaces/ILease';
import { IdName } from 'interfaces/IdName';
import { formatArea } from 'utils/formatters/area';
import { formatDate } from 'utils/formatters/date';
import { getBrokerName } from 'utils/formatters/broker';
import { upperFirstLetter } from 'utils/formatters/string';
import {
  formatNumberWithCommas,
  formatNumberWithDecimals,
  truncateFloat,
} from 'utils/formatters/number';
import { getCurrencySymbol } from 'utils/formatters/currency';
import {
  getFieldValueForLease,
  getUnitOfMeasurementForLease,
} from 'utils/unitsOfMeasurement';
import { translateText } from 'utils/i18n';
import {
  getTranslatedLeaseType,
  getTranslatedRentType,
  getTranslatedTransactionType,
  hasSingleFloor,
} from 'utils/leases';

export enum LeaseCardField {
  TenantCompanyName,
  Size,
  RentPrice,
  Escalation,
  TI,
  FreeMonths,
  Floor,
  SpaceUse,
  ExpirationDate,
  TenantBroker,
  LandlordBroker,
  AdditionalConcessions,
  SignedDate,
  TaxAndOpex,
  CommencementDate,
  TenantIndustry,
  Term,
  Type,
  ExpandingContracting,
  LeaseOptions,
}

const MANY_FLOORS = 2;
const SINGLE_FLOOR = 1;

export const getFormattedArrayField = (
  lease: ILease,
  field: LeaseCardField,
  leaseOptionTypes: IdName[] | undefined,
) => {
  switch (field) {
    case LeaseCardField.LeaseOptions: {
      if (!lease.leaseOptions?.length || !leaseOptionTypes?.length) return;
      return lease?.leaseOptions?.map(lo => {
        const optionName = leaseOptionTypes?.find(o => o.id === lo.optionTypeId)
          ?.name;
        const date = lo.optionDate
          ? formatDate(lo.optionDate, dateFormats.MONTH_YEAR)
          : '';
        return `${optionName} Option ${date}`;
      });
    }
    default:
      return null;
  }
};

export const getFormattedField = (
  lease: ILease,
  field: LeaseCardField,
): string | null => {
  const measurementSystem = lease.measurementSystem;

  const currencySymbol = getCurrencySymbol(
    lease?.country?.currencyCode || lease?.currencyCode,
  );

  switch (field) {
    default:
      return null;

    case LeaseCardField.TenantCompanyName:
      return lease.tenantCompany?.name || null;

    case LeaseCardField.Size:
      const unitOfMeasurement = getUnitOfMeasurementForLease(
        'size',
        measurementSystem,
      );
      const leaseSize = getFieldValueForLease('size', lease);
      return leaseSize ? formatArea(leaseSize, unitOfMeasurement) : null;

    case LeaseCardField.TaxAndOpex:
      return Number(getFieldValueForLease('taxAndOpex', lease)) > 0
        ? `${currencySymbol}${getFieldValueForLease(
            'taxAndOpex',
            lease,
          ).toFixed(2)} ${translateText(
            `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.taxAndOpex`,
          )}`
        : '';

    case LeaseCardField.CommencementDate:
      return !!lease.commencementDate
        ? `${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.commencing`,
          )} ${dayjs(lease.commencementDate)
            .utc()
            .format('MMMM D, YYYY')}`
        : null;

    case LeaseCardField.RentPrice:
      return lease.baseRent && lease.rentType
        ? `${currencySymbol}${formatNumberWithDecimals(
            lease.baseRent,
          )} ${getTranslatedRentType(lease.rentType)}`
        : null;

    case LeaseCardField.AdditionalConcessions:
      const additionalConcessionsPsf = getFieldValueForLease(
        'additionalConcessionsPSF',
        lease,
      );
      const areaUnit = getUnitOfMeasurementForLease(
        'additionalConcessionsPSF',
        measurementSystem,
      );
      return additionalConcessionsPsf && +additionalConcessionsPsf > 0
        ? `${currencySymbol}${truncateFloat(additionalConcessionsPsf, 2)} ${
            UNITS_MEASUREMENT_AREA[areaUnit]
          } ${translateText(
            'avantPlatform.attributes.lease.label.additionalConcessions',
          )}`
        : null;

    case LeaseCardField.Escalation:
      return lease.escalationFormatted || null;

    case LeaseCardField.TI: {
      if (isNil(lease.tenantImprovementAllowance)) {
        return null;
      }

      const ti = formatNumberWithCommas(lease.tenantImprovementAllowance, 2);

      const tiAllowance = translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.tis`,
      );
      return `${currencySymbol}${ti} ${tiAllowance}`;
    }

    case LeaseCardField.FreeMonths:
      return !isNil(lease.freeRentMonths)
        ? `${lease.freeRentMonths} ` +
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.month`, {
              count: lease.freeRentMonths,
            }) +
            ' ' +
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.free`) +
            ' ' +
            getTranslatedRentType(lease?.freeRentType?.name ?? '')
        : null;

    case LeaseCardField.Floor:
      return isEmpty(lease.floor)
        ? null
        : `${upperFirstLetter(
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.floor`, {
              count: hasSingleFloor(lease) ? SINGLE_FLOOR : MANY_FLOORS,
            }),
          )} ${lease.floor}`;
    case LeaseCardField.SpaceUse:
      return lease.spaceUse?.name;
    case LeaseCardField.ExpirationDate:
      return !!lease.expirationDate
        ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.expiring`) +
            ` ${formatDate(lease.expirationDate, 'MMM YYYY')}`
        : null;
    case LeaseCardField.TenantBroker: {
      const tenantBroker = lease?.tenantBrokers?.[0] || lease?.tenantBroker;
      const brokerName = getBrokerName(tenantBroker);
      return brokerName
        ? translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.trBrokerName`,
            {
              name: brokerName,
            },
          )
        : null;
    }
    case LeaseCardField.LandlordBroker: {
      const landlordBroker =
        lease?.landlordBrokers?.[0] || lease?.landlordBroker;
      const brokerName = getBrokerName(landlordBroker);
      return brokerName
        ? translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.alBrokerName`,
            {
              name: brokerName,
            },
          )
        : null;
    }
    case LeaseCardField.SignedDate:
      return !!lease.signDate
        ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.signed`) +
            ` ${formatDate(lease.signDate, 'MMM YYYY')}`
        : null;
    case LeaseCardField.Type:
      if (!lease.type && !lease.transactionType) return null;
      return `${!!lease.type ? getTranslatedLeaseType(lease.type) : ''} ${
        lease.type && lease.transactionType ? '/' : ''
      } ${
        !!lease.transactionType
          ? getTranslatedTransactionType(lease.transactionType)
          : ''
      }`;
    case LeaseCardField.TenantIndustry:
      return lease.tenantCompany?.industry?.name || null;
    case LeaseCardField.Term:
      return lease.termInMonths
        ? `${+(lease.termInMonths / 12).toFixed(1)} ${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`,
          )}`
        : null;
    case LeaseCardField.ExpandingContracting:
      return lease?.expandingContracting?.name ?? '';
  }
};
