import React from 'react';
import { IUser } from 'interfaces/IUser';
import styles from './UserMailToLink.module.scss';

interface Props {
  user: IUser;
}

const UserMailToLink: React.FC<Props> = props => {
  const fullName = [props.user?.firstName, props.user?.lastName]
    .filter(Boolean)
    .join(' ')
    .trim();

  return (
    <a className={styles.link} href={`mailto:${props.user?.email}`}>
      {fullName}
    </a>
  );
};

export default UserMailToLink;
