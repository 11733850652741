import { IInfoBox } from './constants';
import React, { Fragment, useContext } from 'react';
import styles from './InfoBoxes.module.scss';
import { modifyTitle } from './utils';
import { detectNonEnglishUserLanguage } from 'pages/ProjectsPage/utils';
import { authContext } from 'contexts/AuthContext';

interface Props {
  data: IInfoBox[];
  textAlign?: 'left' | 'center' | 'right';
}

// TODO: We might want to add support to render just one box with the correct markup in the future
const InfoBoxes: React.FC<Props> = ({ data, textAlign = 'left' }) => {
  const { user } = useContext(authContext);
  const isLanguageNonEnglish = detectNonEnglishUserLanguage(user.language);
  return (
    <ul className={styles['boxes-list']}>
      {data.map(({ title, details }, index) => (
        <li key={index} className={styles.box} style={{ textAlign }}>
          <h5 className={styles['box-title']}>{title}</h5>
          <dl className={styles['box-details']}>
            {details.map(row => (
              <Fragment key={row.title}>
                <dd className={styles['kpi-value']}>{row.value}</dd>
                <dt>{modifyTitle(isLanguageNonEnglish, row.title)}</dt>
                {row.hint && <dd className={styles.hint}>{row.hint}</dd>}
              </Fragment>
            ))}
          </dl>
        </li>
      ))}
    </ul>
  );
};

export type InfoBoxType = IInfoBox;
export default InfoBoxes;
