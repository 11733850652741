import React from 'react';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

import styles from '../../FormSection.module.scss';

interface Props {
  leaseInput: ILeaseInput;
  updateLeaseProperty: (property: string, propertyValue: any) => void;
}
const Sublessor: React.FC<Props> = ({ leaseInput, updateLeaseProperty }) => {
  return (
    <CompanySearchCombo
      label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.sublessor`)}
      btnLabel={translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.createSublessor`,
      )}
      placeholder={translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.enterSublessorName`,
      )}
      wrapperClassName={styles['form-row']}
      inputId={'lease-sublessor-input-id'}
      companies={leaseInput.sublessor ? [leaseInput.sublessor] : []}
      onCompanySelection={(value: any) => {
        const newValue = value?.custom || value;
        updateLeaseProperty(
          'sublessor',
          newValue
            ? {
                id: newValue.id,
                name: newValue.name,
              }
            : null,
        );
      }}
    />
  );
};

export default Sublessor;
