import React, { useEffect, useState, useMemo } from 'react';
import {
  getRefetchQueries,
  getTabsData,
  HistoricalPerformanceTabs,
  ModalContentType,
} from './utils';
import Tabs, { TabType } from 'components/Tabs';
import AvailabilityVacancy from './AvailabilityVacancy';
import BuildingPricing from './BuildingPricing';
import SegmentPricing from './SegmentPricing';
import { useProperty } from 'hooks/useProperty';
import Loader from '../Loader';
import ConfirmationModal from 'components/ConfirmationModal';
import HistoricalPerformanceModal from '.';
import { ConfirmUnsavedChanges, SegmentPricingParams } from './interfaces';
import {
  MarketPricingRentType,
  MarketPricingTimeMeasurement,
} from 'interfaces/inputs/IMarketPricingInput';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
} from 'constants/unitOfMeasurement';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { IdName } from 'interfaces/IdName';
import ErrorMessage from './ErrorMessage';
import SegmentControl from './SegmentPricing/SegmentControl';
import { getMissingFields } from 'utils/propertySections';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';
import {
  I18N_HISTORICAL_PERFORMANCE_LABEL_PATH,
  I18N_HISTORICAL_PERFORMANCE_TEXT_PATH,
} from '../constants';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

type Props = {
  onClose: () => void;
  propertyId?: number;
  marketsFilter?: IdName[];
  propertySetId?: number;
  companyId?: number;
};

const EditHistoricalPerformance: React.FC<Props> = ({
  onClose,
  propertyId,
  companyId,
  marketsFilter,
  propertySetId,
}) => {
  const { property, isLoading: isLoadingProperty } = useProperty(propertyId);

  const [segmentPricingParams, setSegmentPricingParams] = useState<
    SegmentPricingParams
  >({
    timeMeasurement: MarketPricingTimeMeasurement.Annual,
    rentType: MarketPricingRentType.FS,
  });

  useEffect(() => {
    if (property) {
      const unitOfMeasurement = getUnitOfMeasurementForProperty(
        'buildingSize',
        ModelsWithUnitsOfMeasurement.Property,
        property?.propertyCountry?.code || property?.measurementSystem,
      );

      setSegmentPricingParams({
        ...segmentPricingParams,
        market: property.market,
        propertyClass: property.propertyClass,
        propertyId: property.id,
        propertySubtype: property.propertySubtype,
        propertyType: property.propertyType,
        submarket: property.submarket,
        currencyCode:
          property.propertyCountry?.currencyCode || property.currencyCode,
        unitOfMeasurement,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  const [unsavedChanges, setUnsavedChanges] = useState<ConfirmUnsavedChanges>({
    onCofirm: undefined,
    hasChanges: false,
    isConfirmModalVisible: false,
    shouldResetAvailability: false,
    shouldResetSegment: false,
  });

  const setHasUnsavedChanges = (hasChanges: boolean, shouldRefetch: boolean) =>
    setUnsavedChanges({
      ...unsavedChanges,
      hasChanges,
      shouldResetAvailability: shouldRefetch,
      shouldResetSegment: shouldRefetch,
    });

  const onCloseConfirmModal = () =>
    setUnsavedChanges({
      ...unsavedChanges,
      isConfirmModalVisible: false,
    });

  const confirmChangeTab = (tab: TabType) => {
    if (unsavedChanges.hasChanges) {
      setUnsavedChanges({
        ...unsavedChanges,
        isConfirmModalVisible: true,
        onCofirm: () => {
          if (tab) setActiveTabName(tab?.name);
          onCloseConfirmModal();
        },
      });
    } else {
      setActiveTabName(tab.name);
    }
  };

  const confirmOnClose = () => {
    if (unsavedChanges.hasChanges) {
      setUnsavedChanges({
        ...unsavedChanges,
        isConfirmModalVisible: true,
        onCofirm: () => {
          onClose();
          onCloseConfirmModal();
        },
      });
    } else {
      onClose();
    }
  };

  const undoUnsavedChanges = () => {
    unsavedChanges.onCofirm?.();
    setUnsavedChanges({
      ...unsavedChanges,
      hasChanges: false,
      isConfirmModalVisible: false,
      shouldResetAvailability:
        activeTabName === HistoricalPerformanceTabs.AVAILABILITY_VACANCY,
      shouldResetSegment:
        activeTabName === HistoricalPerformanceTabs.SEGMENT_PRICING,
    });
  };

  const [activeTabName, setActiveTabName] = useState<string>(
    HistoricalPerformanceTabs.AVAILABILITY_VACANCY,
  );

  const canModifyData = useMemo(
    () =>
      !!segmentPricingParams?.market?.id &&
      !!segmentPricingParams?.submarket?.id &&
      !!segmentPricingParams?.propertyClass?.id &&
      !!segmentPricingParams?.propertySubtype?.id &&
      !!segmentPricingParams?.propertyType?.id,
    [segmentPricingParams],
  );

  const renderSegmentPricingContent = () => {
    if (isLoadingProperty) return <Loader />;
    if (canModifyData) {
      return (
        <>
          <SegmentControl segmentPricingParams={segmentPricingParams} />

          <SegmentPricing
            onClose={confirmOnClose}
            segmentPricingParams={segmentPricingParams}
            setSegmentPricingParams={setSegmentPricingParams}
            shouldResetData={unsavedChanges.shouldResetSegment}
            setHasUnsavedChanges={setHasUnsavedChanges}
            refetchQueries={
              companyId || propertyId
                ? getRefetchQueries(
                    companyId,
                    propertyId,
                    marketsFilter,
                    propertySetId,
                  )
                : []
            }
          />
        </>
      );
    }

    const missingFields = getMissingFields(segmentPricingParams, [
      {
        id: 'market',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`),
      },
      {
        id: 'submarket',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.submarket`),
      },
      {
        id: 'propertyClass',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.class`),
      },
      {
        id: 'propertySubtype',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.subtype`),
      },
      {
        id: 'propertyType',
        name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.type`),
      },
    ]);

    return (
      <ErrorMessage
        message={translateText(
          `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.editDisabledError`,
          {
            count: missingFields.length,
            missingFields: missingFields.join(', '),
          },
        )}
      />
    );
  };

  const tabsContent: ModalContentType = {
    availability: isLoadingProperty ? (
      <Loader />
    ) : (
      <AvailabilityVacancy
        unitOfMeasurement={
          segmentPricingParams.unitOfMeasurement || UnitOfMeasurement.sf
        }
        onClose={confirmOnClose}
        propertyId={propertyId}
        propertyType={property?.propertyType?.name}
        companyId={companyId}
        marketsFilter={marketsFilter}
        propertySetId={propertySetId}
        shouldResetData={unsavedChanges.shouldResetAvailability}
        setHasUnsavedChanges={setHasUnsavedChanges}
        buildingSize={property?.buildingSize}
        buildingSizeInKw={property?.dataCenter?.totalPower}
        currencySymbol={getCurrencySymbol(segmentPricingParams.currencyCode)}
      />
    ),
    building: <BuildingPricing />,
    segment: renderSegmentPricingContent(),
  };

  return (
    <HistoricalPerformanceModal
      onClose={confirmOnClose}
      title={translateText(
        `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.editPerformanceLabel`,
      )}
    >
      <Tabs
        activeTabName={activeTabName}
        tabs={getTabsData(tabsContent)}
        onActiveTabChange={confirmChangeTab}
      />

      {unsavedChanges.isConfirmModalVisible && (
        <ConfirmationModal
          onCloseModal={onCloseConfirmModal}
          negativeButton={{
            label: translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesUndoChanges`,
            ),
            onclick: undoUnsavedChanges,
          }}
          positiveButton={{
            label: translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`,
            ),
            onclick: onCloseConfirmModal,
          }}
          header={translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.unsavedChanges`,
          )}
          paragraph={[
            translateText(
              `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.unsavedChangeMessage`,
            ),
          ]}
        />
      )}
    </HistoricalPerformanceModal>
  );
};

export default EditHistoricalPerformance;
