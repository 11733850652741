import { ITableCell } from 'interfaces/ITableCell';
import { IdName } from 'interfaces/IdName';
import { formatArea } from 'utils/formatters/area';
import { upperFirstLetter } from 'utils/formatters/string';
import { Link } from 'react-router-dom';
import locations from 'routes';
import styles from './PropertiesTableContainer.module.scss';
import React from 'react';
import { IProperty } from 'interfaces/IProperty';
import { getPropertyAddress } from 'utils/formatters/property';
import { getFieldValueForProperty } from 'utils/unitsOfMeasurement';
import { TranslateFunction, translateText } from 'utils/i18n';
import tableViewStyles from 'components/TableView/TableView.module.scss';
import LinksList from 'components/LinksList';
import { isEmpty } from 'lodash';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

export const getPropertyTableHeader = (t: TranslateFunction) => [
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.address`),
    id: 'primaryAddress',
    accessor: (property: IProperty) => {
      return getPropertyAddress(property) || '';
    },
    sticky: 'left',
    width: 241,
    Cell: ({ cell }: ITableCell) => (
      <div className={styles['cell-address']}>
        <Link
          to={locations.showProperty(cell.row.original.id)}
          className={styles['link']}
        >
          {cell.value}
        </Link>
      </div>
    ),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.name`),
    accessor: 'name',
    sticky: 'left',
    width: 241,
    Cell: ({ cell }: ITableCell) => (
      <div className={styles['cell-name']}>{cell.value}</div>
    ),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`),
    id: 'market',
    accessor: 'market.name',
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.submarket`),
    id: 'submarket',
    accessor: 'submarket.name',
    Cell: ({ cell }: ITableCell) => {
      const { original } = cell?.row;
      return (
        <div className={styles['cell-address']}>
          <Link
            to={locations.showSubmarket(
              original?.submarket?.id,
              original?.propertyType?.id,
            )}
            className={styles['link']}
          >
            {cell.value}
          </Link>
        </div>
      );
    },
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.type`),
    accessor: 'type',
    Cell: ({ cell: { value } }: ITableCell) => upperFirstLetter(value),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.class`),
    id: 'propertyClass',
    accessor: 'propertyClass.name',
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.subtype`),
    id: 'propertySubtype',
    accessor: 'propertySubtype.name',
  },
  {
    header: t('avantProperties.pursuit.label.builtYear'),
    id: 'builtYear',
    accessor: (property: IProperty) =>
      getFieldValueForProperty('builtYear', property),
    Cell: ({ cell: { value } }: ITableCell) => (
      <div className={tableViewStyles['number-field']}>{value}</div>
    ),
  },
  {
    header: t(`${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.buildingSize`),
    id: 'buildingSize',
    accessor: (property: IProperty) =>
      getFieldValueForProperty('buildingSize', property),
    Cell: ({ cell: { value } }: ITableCell) => (
      <div className={tableViewStyles['number-field']}>
        {formatArea(+value, '')}
      </div>
    ),
  },
  {
    header:
      upperFirstLetter(
        translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.floor_plural`),
      ) || '',
    accessor: 'stories',
    Cell: ({ cell: { value } }: ITableCell) => (
      <div className={tableViewStyles['number-field']}>{value}</div>
    ),
  },
  {
    header:
      upperFirstLetter(
        translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.owner`),
      ) || '',
    accessor: 'owners',
    Cell: ({ cell }: ITableCell) => {
      const owners: IdName[] = cell.value;
      return !isEmpty(owners) ? (
        <LinksList list={owners} bold separator=", " />
      ) : null;
    },
  },
];
