import React, { useContext, useEffect, useRef, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import Carousel from 'nuka-carousel';
import { IProperty } from 'interfaces/IProperty';
import ModalWrapper from 'components/ModalWrapper';
import { Col, Container, Row } from 'components/@codelitt/ay-design-library';
import arrowRightIcon from 'assets/images/icons/arrow-right.svg';
import arrowLeftIcon from 'assets/images/icons/arrow-left.svg';
import {
  PropertyMap,
  PropertyMapSize,
  ZoomLevel,
} from 'components/PropertyMap';
import Button from 'components/Button';
import { I18N_MODAL_MEDIA_PICTURE_TEXT_PATH } from 'components/ModalMedia/constants';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { GET_PROPERTY_QUERY_WITHOUT_COMMENTS } from 'graphql/property';
import {
  GET_PROPERTY_MEDIA_QUERY,
  SET_COVER_MEDIA_MUTATION,
} from 'graphql/images';
import { ErrorLogger } from 'services/ErrorLogger';
import { IMedia } from 'interfaces/IMedia';
import { isVideo } from 'utils/media';
import { translateText } from 'utils/i18n';
import { transformImageUrl } from 'utils/cloudinary/urls';
import {
  forceFileDownload,
  getPropertyImagesOrdered,
} from '../../ModalMedia/utils';
import styles from './ModalGallery.module.scss';

interface Props {
  onCloseModal: () => void;
  property: IProperty;
  initialImageUrl?: string | null;
  keepScrollLocked?: boolean;
}

const MAP_ITEM_ID = -9999;
const CELL_SPACE = 32;
const GALLERY_IMAGE_WIDTH = 1120;
const GALLERY_IMAGE_HEIGHT = 560;

const ModalGallery: React.FC<Props> = props => {
  const client = useApolloClient();
  const { hasEditorAccess } = useContext(subscriptionsContext);

  const carouselRef = useRef<any>(null);
  const { initialImageUrl, keepScrollLocked, property } = props;
  const { id } = property;

  const { data, loading, refetch: refetchImages } = useQuery<{
    media: IMedia[];
  }>(GET_PROPERTY_MEDIA_QUERY, {
    variables: { propertyId: id },
  });

  const mediaItems = data?.media || [];

  const [slideIndex, setSlideIndex] = useState(0);
  const [carouselItems, setCarouselItems] = useState<IMedia[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (loading) return;

    if (carouselItems.length) {
      setCarouselItems(previousItems => {
        return previousItems.map(media => {
          return mediaItems.find(newMedia => newMedia.id === media.id) || media;
        });
      });
    } else {
      const sortedMediaItems = getPropertyImagesOrdered(mediaItems);
      const initialIndex = sortedMediaItems.findIndex(
        i => i.url === initialImageUrl,
      );
      setSlideIndex(initialIndex >= 0 ? initialIndex : 0);

      // Add Mapbox as a mediaItem to display in the carousel, as the last item
      sortedMediaItems.push({
        id: MAP_ITEM_ID,
      });
      setCarouselItems(sortedMediaItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaItems, initialImageUrl, loading]);

  const { onCloseModal } = props;
  const isSingleImage = carouselItems.length === 1;

  const nextSlide = (_: number, endSlide: number) => {
    setSlideIndex(endSlide);
  };

  const setAsCover = async (media: IMedia) => {
    try {
      setIsSaving(true);
      await client.mutate({
        mutation: SET_COVER_MEDIA_MUTATION,
        variables: {
          mediaId: media.id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_PROPERTY_QUERY_WITHOUT_COMMENTS,
            variables: { id: property.id },
          },
        ],
      });
      await refetchImages();
    } catch (e) {
      ErrorLogger.log(e as any, 'Unable to set an image as cover');
    }

    setIsSaving(false);
  };

  const renderActions = (media: IMedia) => {
    if (carouselItems[slideIndex].id !== media.id || media.id === MAP_ITEM_ID)
      return null;

    const isCover = carouselItems[slideIndex].isCover;

    const itemType = isVideo(media) ? 'video' : 'photo';

    const showSetCoverButton = hasEditorAccess ? (
      <Button
        wrapperClassName={styles['set-primary-btn']}
        onClick={() => {
          setAsCover(media);
        }}
        type={'ghost'}
        isDisabled={isSaving}
        label={
          isSaving
            ? `${translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`,
              )}`
            : translateText(
                `${I18N_MODAL_MEDIA_PICTURE_TEXT_PATH}.setItemAsCoverMessage`,
                { itemType },
              )
        }
      />
    ) : null;

    return (
      <Row>
        <Col wrapperClassName={styles['download-container']}>
          <div className={styles['set-primary-btn-container']}>
            {isCover ? (
              <p className={styles['cover-label']}>
                {translateText(
                  `${I18N_MODAL_MEDIA_PICTURE_TEXT_PATH}.itemIsCoverMessage`,
                  { itemType },
                )}
              </p>
            ) : (
              showSetCoverButton
            )}
          </div>
          {hasEditorAccess && (
            <div
              className={styles['download-icon']}
              onClick={() => {
                forceFileDownload(carouselItems[slideIndex].url!);
              }}
            />
          )}
          {media.notes && (
            <div className={styles['gallery-note']}>{media.notes}</div>
          )}
        </Col>
      </Row>
    );
  };

  const getMediaItem = (media: IMedia) => {
    if (media?.id === MAP_ITEM_ID) {
      return (
        <PropertyMap
          property={property}
          size={PropertyMapSize.fullWidth}
          zoomLevel={ZoomLevel.extraBig}
          showControls
        />
      );
    }

    if (isVideo(media)) {
      return (
        <video controls className={styles['media-item']}>
          <source src={media.url} />
        </video>
      );
    }

    return (
      <img
        className={styles['media-item']}
        src={transformImageUrl(
          media.url!,
          GALLERY_IMAGE_WIDTH,
          GALLERY_IMAGE_HEIGHT,
        )}
        alt={media.category?.name}
      />
    );
  };

  const renderImages = () => {
    if (loading || !carouselItems.length) return null;

    return (
      <Carousel
        ref={carouselRef}
        slideIndex={slideIndex}
        defaultControlsConfig={{
          containerClassName: styles['slide-control-container'],
        }}
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
        renderBottomCenterControls={() => null}
        renderTopCenterControls={({ previousSlide, nextSlide }) => (
          <>
            {!isSingleImage && (
              <img
                src={arrowLeftIcon}
                className={styles['arrow-left']}
                onClick={previousSlide}
                alt={translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.arrowLeft`,
                )}
              />
            )}
            {getSlideControllerLabel(carouselItems[slideIndex])}
            {!isSingleImage && (
              <img
                src={arrowRightIcon}
                className={styles['arrow-right']}
                onClick={nextSlide}
                alt={translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.arrowRight`,
                )}
              />
            )}
          </>
        )}
        beforeSlide={nextSlide}
        cellSpacing={CELL_SPACE}
        wrapAround
        dragging={slideIndex !== carouselItems.length - 1}
        speed={1000}
      >
        {carouselItems.map(
          media =>
            (media.url || media.id === MAP_ITEM_ID) && (
              <Container key={media.id}>
                <div className={styles['media-carousel-wrapper']}>
                  {getMediaItem(media)}
                </div>
                {renderActions(media)}
              </Container>
            ),
        )}
      </Carousel>
    );
  };

  const getSlideControllerLabel = (media: IMedia): string => {
    if (media?.id === MAP_ITEM_ID) {
      return translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.map`);
    }

    return media?.subAmenity
      ? `${media?.category?.name} - ${media?.subAmenity?.name}`
      : media?.category?.name;
  };

  return (
    <ModalWrapper
      fullScreen
      darkTheme
      withCloseButton
      withCloseLabel
      withOpacity
      onCancel={onCloseModal}
      keepScrollLocked={keepScrollLocked}
      wrapperClassName={styles['modal-centered']}
    >
      <Row>
        <Col wrapperClassName={styles['title-container']}>
          <h2 className={styles['title']}>{property.primaryAddress}</h2>
          <h3 className={styles['subtitle']}>{property.name}</h3>
        </Col>
        <Col wrapperClassName={styles['slide-images-container']}>
          {renderImages()}
        </Col>
      </Row>
    </ModalWrapper>
  );
};

export default ModalGallery;
