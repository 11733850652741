import AuthService from 'services/Auth';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Operation,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { getLanguageId } from './authHeaders';

const getUserId = () => AuthService.getLocalUser()?.id || '';

const isFileUpload = (operation: Operation) => {
  return (
    operation.variables &&
    Object.keys(operation.variables).some(key => {
      return operation.variables[key] instanceof File;
    })
  );
};

const importerAuthContext = new ApolloLink((operation, forward) => {
  const isAuthReinforced = process.env['IS_OKTA_AUTH_REINFORCED'] === 'true';
  const apiKey = window._env_.IMPORTER_API_KEY;
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(isAuthReinforced && {
        'x-api-token': AuthService.getOktaToken('importer-api'),
      }),
      ...(isAuthReinforced && { 'ay-api-auth': 'OKTA' }),
      ...(!isAuthReinforced && {
        authorization: apiKey ? `Bearer ${apiKey}` : '',
      }),
      'user-id': getUserId(),
      'language-id': getLanguageId(),
    },
  }));

  return forward(operation);
});

const importUrl = `${window._env_.IMPORTER_API_URL}/graphql`;

const uploadLinkWithAuth = ApolloLink.from([
  importerAuthContext,
  createUploadLink({
    uri: importUrl,
  }),
]);

const httpLink = ApolloLink.from([
  importerAuthContext,
  new HttpLink({
    uri: importUrl,
  }),
]);

const importerLink = new RetryLink({ attempts: { max: 1 } }).split(
  isFileUpload,
  uploadLinkWithAuth,
  httpLink,
);

const importerClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: importerLink,
  name: 'importer-api-client',
});

export default importerClient;
