import i18next from 'i18next';
import {
  CountryNames,
  CountryNamesToCountryCodes,
  CountryCodes,
} from 'constants/countryCodes';
import { IPropertyCommon } from 'interfaces/IPropertyCommon';
import { I18nLanguage } from 'constants/i18n';
import { UnitOfMeasurementCode } from 'constants/unitOfMeasurement';
import AuthService from 'services/Auth';
import { LanguageEnum } from 'constants/languages';

const US_POSTAL_CODE_LENGTH = 5;

export type TranslateFunction = (value: string, options?: any) => string;

export const formatPostalCode = (country: string, postalCode?: string) => {
  if (!postalCode) return null;
  if (country === CountryNames.US) {
    return postalCode.padStart(US_POSTAL_CODE_LENGTH, '0');
  }

  return postalCode;
};

export const translateText = (
  textKey: string | string[],
  params?: Record<string, unknown>,
): string => {
  return i18next.t(textKey, params);
};

export const parseLocaleKeyString = (localeKey: string) => {
  if (!localeKey) return undefined;
  return localeKey.replace('_', '-').toLowerCase();
};

export const getI18nLanguageForMeasurementSystem = (
  countryCode?: string,
): I18nLanguage =>
  countryCode === UnitOfMeasurementCode.UK
    ? I18nLanguage.en_GB
    : I18nLanguage.en;

export const getI18nLanguageForProperty = (
  property?: IPropertyCommon,
): I18nLanguage => {
  const countryCode =
    CountryNamesToCountryCodes[
      property?.country as keyof typeof CountryNamesToCountryCodes
    ];
  switch (countryCode as string) {
    case CountryCodes.UK:
      return I18nLanguage.en_GB;
    case CountryCodes.CA:
      return I18nLanguage.en_CA;
    default:
      return I18nLanguage.en;
  }
};

export const getUserLocaleKey = () =>
  parseLocaleKeyString(
    AuthService.getLocalUser()?.language || LanguageEnum.EN,
  )?.toLocaleLowerCase();
