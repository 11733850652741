export enum FindCompTabs {
  leases = 'leases',
  sales = 'sales',
  properties = 'properties',
  pricingQuote = 'pricingQuote',
  tims = 'tims',
}

export enum FindCompCriteriaTabs {
  lease = 'lease',
  sale = 'sale',
  tims = 'tims',
}

export enum FindCompTabsViews {
  list = 'list',
  summary = 'summary',
}
