export {
  GET_IMAGE_CATEGORIES_QUERY,
  GET_PROPERTY_MEDIA_QUERY,
  GET_SUBMARKET_MEDIA_QUERY,
  GET_NEIGHBORING_OWNERS_LOGOS_QUERY,
  GET_NEIGHBORING_TENANTS_LOGOS_QUERY,
  GET_MARKET_ANALYICS_MEDIA,
  GET_MARKET_MEDIA_QUERY,
} from './queries';

export {
  SET_COVER_MEDIA_MUTATION,
  DELETE_MEDIA_MUTATION,
  CREATE_MEDIA_MUTATION,
  SET_MEDIA_AMENITY_MUTATION,
  SET_MEDIA_CATEGORY_MUTATION,
} from './mutations';

export { MEDIA_FRAGMENT } from './fragments';
