import { isString } from 'lodash';

export const isTruePositiveError = (
  errorMessage: string,
  displayedMessage?: string,
) => {
  let isErrorMessageTruePositive = true;
  let isDisplayedMessageTruePositive = true;
  const errorMessagesToBeMuted: any[string] = [
    'There is an existing property',
    'Failed to update company',
    'To ensure quality of data processing',
    'Property already exists',
    'Possible duplicate',
    'failed to create company',
  ];
  errorMessagesToBeMuted.forEach((errorMessageToBeMuted: string) => {
    if (
      isString(errorMessage) &&
      errorMessage.includes(errorMessageToBeMuted)
    ) {
      isErrorMessageTruePositive = false;
    }
    if (
      isString(displayedMessage) &&
      displayedMessage.includes(errorMessageToBeMuted)
    ) {
      isDisplayedMessageTruePositive = false;
    }
  });
  return isErrorMessageTruePositive && isDisplayedMessageTruePositive;
};
