import classnames from 'classnames';
import Button from 'components/Button/new';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import React, { useContext, useState } from 'react';
import styles from './LeaseActions.module.scss';
import NotificationMessage from 'components/NotificationMessage';
import { translateText as t } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import OptionsContainer from 'components/OptionsContainer';
import ModalEditComp from 'components/ModalEditComp';
import { CompType } from 'constants/compType';
import { ILease } from 'interfaces/ILease';
import { useMutation } from '@apollo/client';
import { SHARE_ACTIVITIES_MESSAGE_MUTATION } from 'graphql/share';
import { IActivity } from 'interfaces/IActivity';
import { ShareScoopsModalMessage } from 'components/ShareScoopsModalMessage';

interface Props {
  activity?: IActivity;
  refetchActivities: () => void;
  lease: ILease;
  hideShare?: boolean;
}

const I18N_PATH = 'avantProperties.texts.exploreActivity.scoop';

const LeaseActions: React.FC<Props> = ({
  refetchActivities,
  lease,
  hideShare,
  activity,
}) => {
  const { hasEditorAccess } = useContext(subscriptionsContext);

  const [isShareActivityOpened, setIsShareActivityOpened] = useState<boolean>(
    false,
  );
  const [showShareNotification, setShowShareNotification] = useState(false);
  const [showEditLeaseModal, setShowEditLeaseModal] = useState(false);

  const [shareMessage] = useMutation(SHARE_ACTIVITIES_MESSAGE_MUTATION);

  const onShareButtonClick = () => {
    shareMessage({
      variables: {
        search: {
          filter: {
            ids: [activity?.id],
          },
          includeScoopsWithNoProperties: true,
          shouldReturnPricingQuotes: true,
        },
      },
    });
    setIsShareActivityOpened(true);
  };
  const closeShareActivity = () => setIsShareActivityOpened(false);

  const renderActivityShare = () => {
    if (!isShareActivityOpened) return null;

    return <ShareScoopsModalMessage onClose={closeShareActivity} />;
  };

  const renderSuccessShareNotification = () => {
    return (
      <NotificationMessage
        show={showShareNotification}
        isSuccess
        text={t(`${I18N_PATH}.scoopNotification`)}
        onClose={() => setShowShareNotification(false)}
      />
    );
  };

  const onEdit = () => {
    setShowEditLeaseModal(true);
  };

  const renderModalLease = () => {
    return showEditLeaseModal ? (
      <ModalEditComp
        type={CompType.lease}
        compId={lease.id!}
        onUpdate={refetchActivities}
        onModalToggle={setShowEditLeaseModal}
      />
    ) : null;
  };

  const mobileActions: JSX.Element[] = [
    <Button
      key="edit"
      icon="edit"
      iconPosition="left"
      onClick={onEdit}
      type="contextual-positive"
      label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.edit`)}
      wrapperClassName={styles['mobile-action-button']}
    />,
    <Button
      key="share"
      icon="share"
      iconPosition="left"
      onClick={onShareButtonClick}
      type="contextual-positive"
      label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.share`)}
      wrapperClassName={styles['mobile-action-button']}
    />,
  ];

  return hasEditorAccess ? (
    <>
      {renderActivityShare()}
      {renderSuccessShareNotification()}
      {renderModalLease()}
      <div className={classnames(styles['actions'])}>
        {!hideShare && (
          <Button
            icon="share"
            iconPosition="left"
            onClick={onShareButtonClick}
            size="s"
            type="contextual"
          />
        )}
        <Button
          icon="edit"
          iconPosition="left"
          onClick={onEdit}
          size="s"
          type="contextual"
        />
      </div>
      <div className={classnames(styles['mobile-actions'])}>
        <OptionsContainer
          isMedium
          customOptions={mobileActions}
          optionButtonIcon={'more'}
          optionContainerStyle={'contextual'}
          optionsIconClassName={styles['options-icon-container']}
        />
      </div>
    </>
  ) : null;
};

export default LeaseActions;
