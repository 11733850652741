import React, { useEffect, useRef, useState } from 'react';
import { ICompany } from 'interfaces/ICompany';
import styles from './TransactionVolume.module.scss';
import { select } from 'd3-selection';
import Rhombus, { TransactionType } from './Elements/Rhombus';
import { graphDimensions } from './graphConstants';
import { ITransactionVolumeDataPoint } from './interfaces';
import GraphContainer from '../GraphContainer';
import BackgroundLines from './Elements/BackgroundLines';
import Tooltip, { joinTexts, TooltipType } from './Elements/Tooltip';
import {
  createEmptyYearsDataPoints,
  getAreaSoldAndPurchased,
  getMinMaxValues,
  getUnitOfMeasurement,
} from './utils';
import {
  abbreviateCurrency,
  getCurrencySymbol,
} from 'utils/formatters/currency';
import { useQuery } from '@apollo/client';
import { COMPANY_TRANSACTION_VOLUME_QUERY } from 'graphql/company';
import { IdName } from 'interfaces/IdName';
import CompsPopup from 'components/CompsPopup';
import { formatArea } from 'utils/formatters/area';
import { ColorNames } from 'constants/colorNames';
import { SEARCH_SALES_QUERY } from 'graphql/sales';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_CHART_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { deepCopy } from 'utils/objects';

export type GraphDimension = {
  width: number;
  height: number;
};

interface Props {
  company: ICompany;
  marketsFilter?: IdName[];
  showComponent?: (value: boolean) => void;
}

export const MAX_YEARS_OF_DATA = 5;

const TransactionVolumeGraph: React.FC<Props> = props => {
  const { company, marketsFilter, showComponent } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const [graphContainerDimensions, setGraphContainerDimensions] = useState<
    GraphDimension
  >({
    width: 0,
    height: 0,
  });

  const [selectedDataPoint, setSelectedDataPoint] = useState<
    ITransactionVolumeDataPoint
  >();

  const [totalDataPoints, setTotalDataPoints] = useState<
    ITransactionVolumeDataPoint
  >();

  const [compsPopupQueryParams, setCompsPopupQueryParams] = useState<
    any | null
  >(null);

  const [compsPopupTitle, setCompsPopupTitle] = useState<string | null>(null);

  const [showComsPopup, setShowComsPopup] = useState(false);

  const { loading, data } = useQuery<{
    companyTransactionVolume: ITransactionVolumeDataPoint[];
  }>(COMPANY_TRANSACTION_VOLUME_QUERY, {
    variables: {
      search: {
        id: company?.id,
        numberOfPastYears: MAX_YEARS_OF_DATA,
        marketIds: marketsFilter?.map(market => market.id) || null,
      },
    },
  });

  const transactions = createEmptyYearsDataPoints(
    deepCopy(data?.companyTransactionVolume || []),
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const { width, height } = containerRef.current!.getBoundingClientRect();

    const graphWidth =
      width - graphDimensions.MARGINS.left - graphDimensions.MARGINS.right;

    if (!graphContainerDimensions.width) {
      // Set the dimensions of the graph to use the container size, so even if it is in a smaller screen, the graph will
      // fit in it.
      setGraphContainerDimensions({ width: graphWidth, height });
    }

    select(svgRef.current)
      .attr('width', graphWidth)
      .attr('height', graphDimensions.HEIGHT);

    // eslint-disable-next-line
  }, [containerRef.current, loading]);

  useEffect(() => {
    let totalPurchased = 0;
    let totalSold = 0;
    let countPropertiesPurchased = 0;
    let countPropertiesSold = 0;
    let sumSFPurchased = 0;
    let sumSFSold = 0;
    let sumSMPurchased = 0;
    let sumSMSold = 0;

    const firsDataPoint = data?.companyTransactionVolume?.[0];

    const currencyCode = firsDataPoint?.currencyCode;
    const measurementSystem = firsDataPoint?.measurementSystem;

    // Calculate the totals to show in the default tooltip
    data?.companyTransactionVolume?.forEach(d => {
      totalPurchased += d.valuePurchased;
      totalSold += d.valueSold;
      countPropertiesPurchased += d.countPropertiesPurchased;
      countPropertiesSold += d.countPropertiesSold;
      sumSFPurchased += d.totalSFPurchased;
      sumSFSold += d.totalSFSold;
      sumSMPurchased += d.totalSquareMetersPurchased;
      sumSMSold += d.totalSquareMetersSold;
    });

    setTotalDataPoints({
      year: 0,
      valuePurchased: totalPurchased,
      countPropertiesPurchased: countPropertiesPurchased,
      totalSFPurchased: sumSFPurchased,
      totalSquareMetersPurchased: sumSMPurchased,
      valueSold: totalSold,
      countPropertiesSold: countPropertiesSold,
      totalSFSold: sumSFSold,
      totalSquareMetersSold: sumSMSold,
      currencyCode,
      measurementSystem,
    });
  }, [data]);

  useEffect(() => {
    if (!loading) {
      showComponent?.(transactions.length > 0);
    }

    // eslint-disable-next-line
  }, [loading, transactions]);

  const getCompsPopupTitle = (
    transactionType: TransactionType,
    transaction: ITransactionVolumeDataPoint,
  ) => {
    const {
      valuePurchased,
      valueSold,
      countPropertiesPurchased,
      countPropertiesSold,
      currencyCode,
      measurementSystem,
    } = transaction;
    const isPurchase = transactionType === TransactionType.purchase;
    const currencySymbol = getCurrencySymbol(currencyCode);

    const { totalAreaPurchased, totalAreaSold } = getAreaSoldAndPurchased(
      transaction,
    );

    return joinTexts([
      String(transaction.year),
      isPurchase
        ? `${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.purchased`,
          )}: ${currencySymbol}${abbreviateCurrency(valuePurchased)}`
        : `${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.sold`,
          )}: ${currencySymbol}${abbreviateCurrency(valueSold)}`,
      formatArea(
        isPurchase ? totalAreaPurchased : totalAreaSold,
        getUnitOfMeasurement(measurementSystem),
      ),
      `${
        isPurchase ? countPropertiesPurchased : countPropertiesSold
      } ${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.property`, {
        count: isPurchase ? countPropertiesPurchased! : countPropertiesSold,
      })}`,
    ]);
  };

  const onTransactionClick = (
    transactionType: TransactionType,
    dataPoint: ITransactionVolumeDataPoint,
  ) => {
    let buyers: IdName[] | undefined;
    let sellers: IdName[] | undefined;

    if (transactionType === TransactionType.purchase) {
      buyers = [{ id: company.id }];
    }

    if (transactionType === TransactionType.sell) {
      sellers = [{ id: company.id }];
    }

    setCompsPopupQueryParams({
      search: {
        filter: {
          markets: marketsFilter ? marketsFilter : undefined,
          buyers,
          sellers,
          fields: {
            and: [
              {
                key: 'date',
                op: 'gte',
                value: `${dataPoint.year}-01-01`,
              },
              {
                key: 'date',
                op: 'lte',
                value: `${dataPoint.year}-12-31`,
              },
            ],
          },
        },
      },
    });
    setCompsPopupTitle(getCompsPopupTitle(transactionType, dataPoint));
  };

  useEffect(() => {
    if (compsPopupQueryParams) {
      setShowComsPopup(true);
    }
  }, [compsPopupQueryParams]);

  if (transactions.length === 0) {
    return null;
  }

  const isReady = svgRef.current && graphContainerDimensions.width > 0;

  const { maxValue } = getMinMaxValues(transactions);
  const maxValueFormatted =
    getCurrencySymbol(transactions?.[0]?.currencyCode) +
    abbreviateCurrency(maxValue);

  return (
    <>
      <GraphContainer wrapperClassName={styles['transaction-volume-container']}>
        <div ref={containerRef} className={styles['svg-container']}>
          <h3 className={styles.title}>
            {translateText(
              `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.transactionVolume.title`,
            )}
          </h3>

          <Tooltip
            dataPoint={selectedDataPoint}
            totalDataPoint={totalDataPoints}
            type={TooltipType.purchase}
          />

          <p className={styles['legend-top']}>{maxValueFormatted}</p>
          <svg className={styles['svg-graph']} ref={svgRef}>
            {isReady && (
              <>
                <BackgroundLines graphDimension={graphContainerDimensions} />
                <Rhombus
                  onHoverDataPoint={setSelectedDataPoint}
                  data={transactions}
                  graphDimension={graphContainerDimensions}
                  onClick={onTransactionClick}
                />
              </>
            )}
          </svg>
          <p className={styles['legend-bottom']}>{maxValueFormatted}</p>

          <Tooltip
            dataPoint={selectedDataPoint}
            totalDataPoint={totalDataPoints}
            type={TooltipType.sell}
          />
        </div>
      </GraphContainer>
      {showComsPopup && (
        <CompsPopup
          hideAction
          graphqlVariables={compsPopupQueryParams}
          onClose={() => {
            setShowComsPopup(false);
            setCompsPopupTitle(null);
            setCompsPopupQueryParams(null);
          }}
          showPropertyData
          tagBackgroundColor={ColorNames.ayGrey8Color}
          title={compsPopupTitle || ''}
          graphqlQuery={SEARCH_SALES_QUERY}
        />
      )}
    </>
  );
};

export default TransactionVolumeGraph;
