import React from 'react';
import { map, groupBy } from 'lodash';
import { DocumentNode, OperationVariables, useQuery } from '@apollo/client';
import { IdName } from 'interfaces/IdName';
import OptionsFilter from '../OptionsFilter';
import { Size } from 'components/Inputs/ChoiceInput';
import FormCardGroup from 'components/FormCardGroup';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';

type Props = {
  query: DocumentNode;
  isDisabled?: boolean;
  choiceInputSize?: Size;
  hasHeaderLabel?: boolean;
  isMultipleSelect?: boolean;
  itemWrapperClassName?: string;
  haveItemsBackground?: boolean;
  variables?: OperationVariables;
  selectedItem?: SearchOptionsType;
  selectedItems?: SearchOptionsType[];
  onChangeSingle?: (newValue?: IdName) => void;
  queryParser: (data: any) => SearchOptionsType[];
  groupOptionsParams: {
    groupBy: string;
    groupClassName?: string;
  };
  onChangeMultiple?: (
    newValue: IdName[],
    allOptions: SearchOptionsType[],
  ) => void;
};

const RemoteDataFilterGroups: React.FC<Props> = props => {
  const { loading, data } = useQuery(props.query, {
    variables: props.variables,
  });

  if (loading) return null;

  const groupedOptions = map(
    groupBy(props.queryParser(data), props.groupOptionsParams.groupBy),
    (group: any) => group,
  );

  return (
    <FormCardGroup wrapperClassName={props.groupOptionsParams.groupClassName}>
      {groupedOptions.map((group: any[], index: number) => (
        <OptionsFilter
          key={index}
          options={group}
          allOptions={group}
          label={group[0][props.groupOptionsParams.groupBy]}
          {...props}
        />
      ))}
    </FormCardGroup>
  );
};

export default RemoteDataFilterGroups;
