import { gql } from '@apollo/client';
import { AMENITY_DATA } from 'graphql/amenities/fragments';

export const GET_APPROVED_AMENITIES_QUERY = gql`
  query getAmenities {
    amenities(isApproved: true) {
      ...amenityData
    }
  }
  ${AMENITY_DATA}
`;

export const GET_APPROVED_PROPERTY_TYPE_AMENITIES_QUERY = gql`
  query getAmenities($propertyTypeIds: [Int]!) {
    amenities(
      isApprovedForPropertyType: true
      propertyTypeIds: $propertyTypeIds
    ) {
      ...amenityData
    }
  }
  ${AMENITY_DATA}
`;

export const GET_AMENITIES_POIS_QUERY = gql`
  query poisWithCount($search: PoiInput) {
    poisWithCount(search: $search) {
      results {
        source
        sectorIdentity
        sectorDetail
        industryIdentity
        locationName
        latitude
        longitude
        avantPropertyId
        categoryTags
        ayMarketId
        parentCompany
        areaNet
        areaNetSource
        areaGross
        areaGrossSource
        rankSectorIdentity
        rankSectorDetail
        rankIndustryIdentity
        distance
      }
      amenitiesCount {
        name
        total
      }
    }
  }
`;
