import React from 'react';
import { kebabCase } from 'lodash';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import useTruncate from 'hooks/useTruncate';
import styles from './Truncate.module.scss';

interface Props {
  children: string;
  length?: number;
  hasEllipsis?: boolean;
  wrapperClassName?: string;
}

const Truncate: React.FC<Props> = ({
  children = '',
  length = 15,
  hasEllipsis = false,
  wrapperClassName = '',
}) => {
  const { str, isTruncated } = useTruncate(children, length, hasEllipsis);
  const id = kebabCase(children);
  return (
    <>
      {isTruncated && (
        <ReactTooltip
          arrowColor="transparent"
          className={styles['truncate-tooltip']}
          id={id}
        />
      )}
      <span
        className={classnames(
          isTruncated && styles.truncated,
          isTruncated && wrapperClassName,
        )}
        data-for={id}
        data-tip={children}
      >
        {str}
      </span>
    </>
  );
};

export default Truncate;
