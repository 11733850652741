import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import styles from './Navbar.module.scss';
import LogoImg from '../../assets/images/avant-small.svg';
import locations from '../../routes';
import UserAvatar from '../UserAvatar';
import { IUser } from 'interfaces/IUser';
import CompaniesPropertiesSearchInput from '../../components/CompaniesPropertiesSearchInput';
import { SearchInputSize } from '../SearchInput/enums';
import { getAddButtonOptions } from './utils';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { NavBarGridControl } from 'components/NavBarGridControl';
import { useTranslation } from 'react-i18next';
import ManageScoopModal from 'components/ManageScoopModal';
import CreateHistoricalPerformance from 'components/HistoricalPerformance/HistoricalPerformanceModal/CreateHistoricalPerformance';
import OptionsButton from '../OptionsButton';
import { AgencyLeasingModalForm } from 'components/AgencyLeasingModalForm';
import { PropertyCompSetModalForm } from 'pages/PropertyPage/PropertyCompSetModalForm';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import AYLink from 'components/AYLink';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { detectNonEnglishUserLanguage } from 'pages/ProjectsPage/utils';
import { authContext } from 'contexts/AuthContext';
import classNames from 'classnames';

type PropTypes = {
  wrapperClassName?: string;
  isSearchVisible?: boolean;
  isLogoVisible?: boolean;
  user: IUser;
};

export const NAVBAR_HEIGHT = 56;

const SCOOP_MODAL_KEY = 'scoop_modal';
const HISTORICAL_PERFORMANCE_MODAL_KEY = 'historical_performance_modal';
const COMP_SET_MODAL_KEY = 'comp_set_modal';
const LEASING_ASSIGNMENT_MODAL_KEY = 'leasign_assignment';

const Navbar: React.FC<PropTypes> = ({
  wrapperClassName = '',
  isSearchVisible = false,
  isLogoVisible = false,
  ...props
}: PropTypes) => {
  const history = useHistory();
  const { hasEditorAccess } = useContext(subscriptionsContext);
  const { t } = useTranslation();
  const { user } = useContext(authContext);
  const isLanguageNonEnglish = detectNonEnglishUserLanguage(user.language);

  const [modalsVisibility, setModalsVisibility] = useState({
    [SCOOP_MODAL_KEY]: false,
    [HISTORICAL_PERFORMANCE_MODAL_KEY]: false,
    [COMP_SET_MODAL_KEY]: false,
    [LEASING_ASSIGNMENT_MODAL_KEY]: false,
  });

  const toggleModal = (modal: any, isVisible: boolean) =>
    setModalsVisibility({
      ...modalsVisibility,
      [modal]: isVisible,
    });

  const handlePropertyCompSetSubmit = (propertySet: IPropertySet) => {
    if (propertySet.targetProperty?.id) {
      history.push(locations.showProperty(propertySet.targetProperty.id));
    }
  };

  const handleAgencyLeasingSubmit = (propertySet: IPropertySet) => {
    if (propertySet.id) {
      history.push(locations.showAgencyAssignments(propertySet.id));
    }
  };

  return (
    <div
      className={classnames(styles.navbar, wrapperClassName, {
        [styles['navbar-border']]: isSearchVisible,
      })}
    >
      {isLogoVisible && (
        <div className={styles.icon} key="img">
          <Link to={locations.root()}>
            <img src={LogoImg} className={styles.logo} alt="Avison Young" />
          </Link>
          <NavBarGridControl />
        </div>
      )}

      {isSearchVisible && (
        <div className={styles.search} key="search">
          <CompaniesPropertiesSearchInput
            autofocus={false}
            selectable={true}
            navbar={true}
            size={SearchInputSize.Small}
            placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.search`)}
            withMarketSwitch
            includeSubmarkets
            includeMarkets
            includeMapBoxResults
          />
        </div>
      )}
      <div className={styles.settings}>
        <AYLink to="/projects" wrapperClassName={styles['project-link']}>
          {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.projects`)}
        </AYLink>
        {!!props.user.id && (
          <>
            {hasEditorAccess ? (
              <div>
                <OptionsButton
                  label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.add`)}
                  dropdownOptions={getAddButtonOptions(
                    history,
                    () => toggleModal(SCOOP_MODAL_KEY, true),
                    () => toggleModal(HISTORICAL_PERFORMANCE_MODAL_KEY, true),
                    () => toggleModal(COMP_SET_MODAL_KEY, true),
                    () => toggleModal(LEASING_ASSIGNMENT_MODAL_KEY, true),
                  )}
                  optionsContainerStyle="contextual-positive"
                  optionsButtonClassName={classNames(
                    styles['add-button'],
                    isLanguageNonEnglish && styles['add-button-non-english'],
                  )}
                  wrapperClassName={styles['container-add-button']}
                  optionClassName={styles['add-comps-button-option']}
                />
              </div>
            ) : null}
            <div>
              <Link
                to={locations.userSettings()}
                className={styles['link-profile']}
              >
                <UserAvatar user={props.user} />
              </Link>
            </div>
          </>
        )}

        <ManageScoopModal
          onCloseModal={() => toggleModal(SCOOP_MODAL_KEY, false)}
          isVisible={modalsVisibility[SCOOP_MODAL_KEY]}
        />

        {modalsVisibility[HISTORICAL_PERFORMANCE_MODAL_KEY] && (
          <CreateHistoricalPerformance
            onClose={() => toggleModal(HISTORICAL_PERFORMANCE_MODAL_KEY, false)}
          />
        )}

        {modalsVisibility[COMP_SET_MODAL_KEY] && (
          <PropertyCompSetModalForm
            onCancel={() => toggleModal(COMP_SET_MODAL_KEY, false)}
            onSubmit={handlePropertyCompSetSubmit}
          />
        )}

        {modalsVisibility[LEASING_ASSIGNMENT_MODAL_KEY] && (
          <AgencyLeasingModalForm
            onCancel={() => toggleModal(LEASING_ASSIGNMENT_MODAL_KEY, false)}
            onSubmit={handleAgencyLeasingSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
