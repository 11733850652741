import { IUser } from 'interfaces/IUser';
import {
  SIGN_IN_MUTATION,
  SIGN_IN_WITH_MS_MUTATION,
  SIGN_UP_MS_MUTATION,
  SIGN_IN_WITH_JWT_TOKEN_MUTATION,
  SIGN_UP_WITH_JWT_TOKEN_MUTATION,
  USER_QUERY,
  OAUTH_EMAIL_QUERY,
} from 'graphql/user';
import { GET_THOUGHTSPOT_TOKEN } from 'graphql/thoughtspot';
import { UserNotFoundError } from 'services/Auth/UserNotFoundError';
import { AuthProviders } from 'constants/authProviders';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import { usersClient } from 'graphql/usersClient';
import oktaAuth from 'utils/oktaAuth';
import createClient from 'graphql/propertiesClient';

const TOKEN_KEY = '@ay-pia-web-Token';
const USER_KEY = '@ay-pia-web-User';
const OKTA_TOKENS = '@ay-pia-web-OKTA-Token';
const WS_CONNECTIONS_STATUS = '@ay-pia-web-WS-Status';
const THOUGHTSPOT_TOKEN = '@ay-pia-web-TS-Token';
export const NO_USER = { id: 0, email: '' };
const TOKEN = { token: '', expiry: 0 };
const NO_TOKEN = {
  'users-api': TOKEN,
  'properties-api': TOKEN,
  'importer-api': TOKEN,
  'geospatial-api': TOKEN,
  'data-science': TOKEN,
  'avant-functions': TOKEN,
  timestamp: 1000000000,
};
const NO_STATUS = {
  tokensWS: { status: 'idle', lastActivity: 0 },
  propertiesWS: { status: 'idle', lastActivity: 0 },
};

class AuthService {
  public static isAuthenticated() {
    return localStorage.getItem(TOKEN_KEY) !== null;
  }

  public static getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  public static getThoughtspotToken() {
    return localStorage.getItem(THOUGHTSPOT_TOKEN);
  }

  public static async retrieveOktaTokens() {
    const platformTokens = await this.fetchOktaTokens();
    const applicationTokens = platformTokens['properties-web-app'];
    const expiryTime = this.fetchMinExpiryTime(applicationTokens);
    const timeInSeconds = Date.now() / 1000;
    const expiryTimestamp = timeInSeconds + expiryTime;
    localStorage.setItem(
      OKTA_TOKENS,
      JSON.stringify({
        ...applicationTokens,
        timestamp: expiryTimestamp,
      }),
    );
  }
  public static async retrieveTSTokens(userName: string | undefined) {
    const token = await this.fetchThoughtspotToken(userName);
    const timeInSeconds = Date.now() / 1000;
    const expiryTimestamp = timeInSeconds + 86300;
    localStorage.setItem(
      THOUGHTSPOT_TOKEN,
      JSON.stringify({
        token,
        timestamp: expiryTimestamp,
      }),
    );
  }

  public static async setThoughtspotToken(userName: string | undefined) {
    const token = localStorage.getItem(THOUGHTSPOT_TOKEN);
    if (!!token) {
      const parsedToken = JSON.parse(token);
      const expiryTime = parsedToken['timestamp'];
      const timeInSecondsNow = Date.now() / 1000;
      if (
        expiryTime - timeInSecondsNow >
        parseInt(window._env_.OKTA_TOKEN_EXPIRY_BUFFER)
      ) {
        return token;
      } else {
        await this.retrieveTSTokens(userName);
      }
    } else {
      await this.retrieveTSTokens(userName);
    }
  }

  public static async setOktaToken() {
    const tokens = localStorage.getItem(OKTA_TOKENS);
    if (!!tokens) {
      const parsedTokens = JSON.parse(tokens);
      const expiryTime = parsedTokens['timestamp'];
      const timeInSecondsNow = Date.now() / 1000;
      if (
        expiryTime - timeInSecondsNow >
        parseInt(window._env_.OKTA_TOKEN_EXPIRY_BUFFER)
      ) {
        return tokens;
      } else {
        await this.retrieveOktaTokens();
      }
    } else {
      await this.retrieveOktaTokens();
    }
  }

  public static getOktaToken(projectName: string) {
    const tokens: any =
      JSON.parse(localStorage.getItem(OKTA_TOKENS)!) || NO_TOKEN;
    return tokens[projectName]['token'];
  }

  public static isOktaTokensReady() {
    const tokens = JSON.parse(localStorage.getItem(OKTA_TOKENS)!) || NO_TOKEN;
    const expiryTime = tokens['timestamp'];
    const timeInSecondsNow = Date.now() / 1000;
    if (
      expiryTime - timeInSecondsNow >
      parseInt(window._env_.OKTA_TOKEN_EXPIRY_BUFFER)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public static fetchWSConnectionsStatus() {
    //{tokensWS: {status:'', lastActivity:'' }, propertiesWS: {status: '', lastActivity: ''}}
    const wsConnectionsStatus =
      JSON.parse(localStorage.getItem(WS_CONNECTIONS_STATUS)!) || NO_STATUS;
    const tokensLastActivity = wsConnectionsStatus['tokensWS']['lastActivity'];
    const tokensStatus = wsConnectionsStatus['tokensWS']['status'];
    const propertiesLastActivity =
      wsConnectionsStatus['propertiesWS']['lastActivity'];
    const propertiesStatus = wsConnectionsStatus['propertiesWS']['status'];
    const timeInSecondsNow = Date.now() / 1000;
    return {
      tokensWS: `status is ${tokensStatus}, last activity was ${timeInSecondsNow -
        tokensLastActivity}s ago`,
      propertiesWS: `status is ${propertiesStatus}, last activity was ${timeInSecondsNow -
        propertiesLastActivity}s ago`,
    };
  }

  public static setWSConnectionsStatus(socketName: string, status: Object) {
    const wsConnectionsStatus = JSON.parse(
      localStorage.getItem(WS_CONNECTIONS_STATUS)!,
    );
    if (socketName === 'tokens') {
      const tokensWS = status;
      localStorage.setItem(
        WS_CONNECTIONS_STATUS,
        JSON.stringify({ ...wsConnectionsStatus, tokensWS }),
      );
    } else {
      const propertiesWS = status;
      localStorage.setItem(
        WS_CONNECTIONS_STATUS,
        JSON.stringify({ ...wsConnectionsStatus, propertiesWS }),
      );
    }
  }

  public static fetchMinExpiryTime(accessTokens: any) {
    return Math.min(
      accessTokens['avant-functions'].expiry,
      accessTokens['geospatial-api'].expiry,
      accessTokens['data-science'].expiry,
      accessTokens['importer-api'].expiry,
      accessTokens['properties-api'].expiry,
      accessTokens['users-api'].expiry,
    );
  }

  public static async fetchOktaTokens() {
    const access: any = await oktaAuth.tokenManager.get('accessToken');
    const body = JSON.stringify({
      projectName: 'properties',
      userToken: this.getToken(),
      accessToken: access.accessToken,
    });
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await fetch(
        `${window._env_.OKTA_APP_SERVICE_TOKENS_URL}`,
        {
          method: 'POST',
          body,
          headers,
        },
      );
      return response.json();
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public static async fetchThoughtspotToken(userName: string | undefined) {
    if (!userName) return null;

    try {
      const { data } = await createClient().query<{
        thoughtspotToken: string | null;
      }>({
        variables: { userName },
        query: GET_THOUGHTSPOT_TOKEN,
        // Every token can be used only one time, so we can't cache this query
        fetchPolicy: 'network-only',
      });

      return data.thoughtspotToken;
    } catch (e) {
      console.error('Error fetching Thoughtspot token', e);
      return null;
    }
  }

  public static getLocalUser() {
    try {
      const userJson = localStorage.getItem(USER_KEY);
      if (userJson) {
        return JSON.parse(userJson);
      }
    } catch (__) {}

    return NO_USER;
  }

  public static updateLocalUser(user: IUser) {
    if (user.token) {
      localStorage.setItem(TOKEN_KEY, user.token);
    }
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public static logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
  }

  public static async login(
    email: string,
    password: string,
  ): Promise<IUser | null> {
    let user = null;
    try {
      // wipe any token stored locally before a new login
      this.logout();

      const { data } = await usersClient.mutate({
        mutation: SIGN_IN_MUTATION,
        variables: {
          email: email.toLowerCase(),
          password,
        },
        update(cache, { data: mutationData }) {
          if (mutationData?.signIn?.token) {
            cache.writeQuery({
              query: USER_QUERY,
              data: { me: mutationData.signIn },
            });
          }
        },
      });

      if (data && data.signIn && data.signIn.token) {
        user = data.signIn;
        this.saveLocalUser(user.token, user);
        await this.setOktaToken();
        await this.setThoughtspotToken(user.email);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    return Promise.resolve(user);
  }

  public static async findAuthProvider(email: string): Promise<AuthProviders> {
    const { data } = await usersClient.query({
      query: OAUTH_EMAIL_QUERY,
      variables: {
        email: email.toLowerCase(),
      },
      fetchPolicy: 'network-only',
    });

    return data?.oAuthEmail?.provider || AuthProviders.OKTA;
  }

  public static async loginWithMS(
    email: string,
    msToken: string,
  ): Promise<IUser | null> {
    let user = null;
    try {
      const { data } = await usersClient.mutate({
        mutation: SIGN_IN_WITH_MS_MUTATION,
        variables: {
          email: email.toLowerCase(),
          msToken,
        },
        update(cache, { data: mutationData }) {
          if (mutationData?.signInWithMS?.token) {
            cache.writeQuery({
              query: USER_QUERY,
              data: { me: mutationData.signInWithMS },
            });
          }
        },
      });

      if (data && data.signInWithMS && data.signInWithMS.token) {
        user = data.signInWithMS;
        this.saveLocalUser(user.token, user);
        await this.setOktaToken();
        await this.setThoughtspotToken(user.email);
      }
    } catch (err) {
      if ((err as any).message.indexOf('User not found') !== -1) {
        throw new UserNotFoundError();
      }
      console.error(err);
    }

    return Promise.resolve(user);
  }

  public static async loginWithJwtToken(
    email: string,
    jwtToken: string,
    oAuthProvider: AuthProviders,
  ): Promise<IUser | null> {
    let user = null;
    try {
      const { data } = await usersClient.mutate({
        mutation: SIGN_IN_WITH_JWT_TOKEN_MUTATION,
        variables: {
          email: email.toLowerCase(),
          jwtToken,
          oAuthProvider,
        },
        update(cache, { data: mutationData }) {
          if (mutationData?.signInWithJWTToken?.token) {
            cache.writeQuery({
              query: USER_QUERY,
              data: { me: mutationData.signInWithJWTToken },
            });
          }
        },
      });

      if (data && data.signInWithJWTToken && data.signInWithJWTToken.token) {
        user = data.signInWithJWTToken;
        this.saveLocalUser(user.token, user);
        await this.setOktaToken();
        await this.setThoughtspotToken(user.email);
      }
    } catch (err) {
      if ((err as any).message.indexOf('User not found') !== -1) {
        throw new UserNotFoundError();
      }
      console.error(err);
    }

    return Promise.resolve(user);
  }

  public static async createAccountWithMS(
    user: IUser,
    msToken: string,
  ): Promise<IUser | null> {
    let createdUser = null;
    try {
      const { data } = await usersClient.mutate({
        mutation: SIGN_UP_MS_MUTATION,
        variables: {
          user: {
            firstName: user.firstName,
            lastName: '',
            email: user.email,
          },
          msToken,
        },
        update(cache, { data: mutationData }) {
          if (mutationData.signUpWithMS?.token) {
            cache.writeQuery({
              query: USER_QUERY,
              data: { me: mutationData.signUpWithMS },
            });
          }
        },
      });

      if (data && data.signUpWithMS?.token) {
        createdUser = data.signUpWithMS;
        this.saveLocalUser(createdUser.token, createdUser);
        await this.setOktaToken();
        await this.setThoughtspotToken(user.email);
      }
    } catch (err) {
      console.error(err);
    }

    return Promise.resolve(createdUser);
  }

  public static async createAccountWithJWTToken(
    user: IUser,
    jwtToken: string,
    oAuthProvider: AuthProviders,
  ): Promise<IUser | null> {
    let createdUser = null;
    try {
      const { data } = await usersClient.mutate({
        mutation: SIGN_UP_WITH_JWT_TOKEN_MUTATION,
        variables: {
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          },
          jwtToken,
          oAuthProvider,
        },
        update(cache, { data: mutationData }) {
          if (mutationData.signUpWithJWTToken?.token) {
            cache.writeQuery({
              query: USER_QUERY,
              data: { me: mutationData.signUpWithJWTToken },
            });
          }
        },
      });

      if (data && data.signUpWithJWTToken?.token) {
        createdUser = data.signUpWithJWTToken;
        this.saveLocalUser(createdUser.token, createdUser);
        await this.setOktaToken();
        await this.setThoughtspotToken(user.email);
      }
    } catch (err) {
      console.error(err);
    }

    return Promise.resolve(createdUser);
  }

  public static async refreshUser() {
    let user = null;
    try {
      const { data } = await usersClient.query({
        query: USER_QUERY,
        fetchPolicy: 'network-only',
      });

      if (data.me) {
        user = data.me;
        this.updateLocalUser(user);
        await this.setOktaToken();
        await this.setThoughtspotToken(user.email);
      } else {
        this.logout();
        localStorage.removeItem(LocalStorageKeys.oAuthProvider);
      }
    } catch (__) {}

    return user;
  }

  private static saveLocalUser(token: string, user: IUser) {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
}

export default AuthService;
