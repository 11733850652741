import { PureQueryOptions } from '@apollo/client';
import { MediaTypes } from 'constants/media';
import { PropertyImageSizes } from 'constants/propertyImageSizes';
import {
  GET_PROPERTY_MEDIA_QUERY,
  GET_SUBMARKET_MEDIA_QUERY,
} from 'graphql/images';
import {
  GET_PROPERTY_DOCUMENTS_QUERY,
  GET_PROPERTY_QUERY,
} from 'graphql/property';

import { IdName } from 'interfaces/IdName';
import { ISubmarket } from 'interfaces/IMarket';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyImage } from 'interfaces/IPropertyImage';
import { getVideoThumbnailUrl, transformImageUrl } from 'utils/cloudinary/urls';
import { GET_SCOOP_MEDIA } from 'graphql/scoops/queries';

// Used to order the images, following the AC
export enum ImageCategory {
  EXTERIOR = 'Exterior',
  LOBBY = 'Lobby',
  SPACE = 'Space',
  FLOOR_PLAN = 'Floor Plan',
  SITE_PLAN = 'Site Plan',
  AMENITY = 'Amenity',
}

export const getFileNameFromURL = (url: string, defaultName = '') => {
  return url.split('/').pop() || defaultName;
};

/**
 * Downloads an image from a given URL address.
 * It will force the browser to download it, instead of opening in a new tab or window.
 *
 * @param url
 */
export const forceFileDownload = (url?: string) => {
  if (!url) return;

  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function() {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = getFileNameFromURL(url, 'img');
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
};

/**
 * Since files don't have a unique ID, we create a near-to-unique identifier
 * by concatenating a few of the file's attributes we know
 * @param file
 */
export const buildFileKey = (file: File): string => {
  return [file.name, file.lastModified, file.size].join('');
};

export const sortImageCategories = (categories: IdName[]): IdName[] => {
  const orderedCategories = Object.values(ImageCategory);
  return categories.sort((a, b) => {
    const i = a.name;
    const j = b.name;
    return orderedCategories.indexOf(i) > orderedCategories.indexOf(j) ? 1 : -1;
  });
};

export const getPropertyImagesOrdered = (images?: IPropertyImage[] | null) => {
  if (!images) return [];
  const orderedCategories = Object.values(ImageCategory);

  // Order the images based in the Enum and the AC
  return [...images!].sort((a, b) => {
    const i = a.category?.name;
    const j = b.category?.name;
    if (a.isCover) {
      return -1;
    }

    if (b.isCover) {
      return 1;
    }

    return orderedCategories.indexOf(i) > orderedCategories.indexOf(j) ? 1 : -1;
  });
};

export const getCoverImageUrl = (
  property?: IProperty | null,
  imageWidth?: PropertyImageSizes | number,
  imageHeight?: PropertyImageSizes | number,
): string | undefined => {
  if (!property?.coverMediaUrl) return undefined;

  const isVideo =
    property.coverMediaType?.toLowerCase() === MediaTypes.video.toLowerCase();

  const height = imageHeight || imageWidth;

  if (isVideo) {
    const options = imageWidth ? `h_${height},w_${imageWidth},c_fill` : '';
    return getVideoThumbnailUrl(property?.coverMediaUrl, options, '.png');
  }

  return imageWidth
    ? transformImageUrl(property.coverMediaUrl, imageWidth, height)
    : property.coverMediaUrl;
};

const getPropertyMediaRefetchQueries = (
  propertyId?: number,
): PureQueryOptions[] => {
  if (!propertyId) return [];

  return [
    {
      query: GET_PROPERTY_MEDIA_QUERY,
      variables: { propertyId },
    },
  ];
};

export const getScoopMediaRefetchQueries = (
  scoopId?: number,
): PureQueryOptions[] => {
  if (!scoopId) return [];

  return [
    {
      query: GET_SCOOP_MEDIA,
      variables: { scoopId },
    },
  ];
};

const getSubmarketMediaRefetchQueries = (
  submarketId?: number,
  propertyTypeId?: number,
): PureQueryOptions[] => {
  if (!submarketId || !propertyTypeId) return [];

  return [
    {
      query: GET_SUBMARKET_MEDIA_QUERY,
      variables: { submarketId, propertyTypeId },
    },
  ];
};

export const getMediaConfigForProperty = (property: IProperty) => ({
  listMediaQuery: {
    query: GET_PROPERTY_MEDIA_QUERY,
    variables: { propertyId: property.id },
  },
  listDocumentQuery: {
    query: GET_PROPERTY_DOCUMENTS_QUERY,
    variables: { propertyId: property.id },
  },
  refetchQueries: getPropertyMediaRefetchQueries(property.id),
  documentRefetchQueries: getDocumentRefetchQueries(property.id),
  createMediaVariables: { propertyId: property.id },
  propertyTypeId: property.propertyType?.id,
  property: property,
});

export const getMediaConfigForSubmarket = (
  submarket: ISubmarket,
  propertyTypeId: number,
) => ({
  listMediaQuery: {
    query: GET_SUBMARKET_MEDIA_QUERY,
    variables: { submarketId: submarket.id, propertyTypeId },
  },
  refetchQueries: getSubmarketMediaRefetchQueries(submarket.id, propertyTypeId),
  createMediaVariables: {
    submarketInput: {
      submarketId: submarket.id,
      propertyTypeId,
    },
  },
  propertyTypeId,
});

export const getDocumentRefetchQueries = (propertyId?: number) => {
  if (!propertyId) return [];

  return [
    {
      query: GET_PROPERTY_QUERY,
      variables: { id: propertyId },
    },
    {
      query: GET_PROPERTY_DOCUMENTS_QUERY,
      variables: { propertyId },
    },
  ];
};
