import React, { useEffect, useState } from 'react';
import { IProperty } from 'interfaces/IProperty';
import GraphContainer from '../GraphContainer';
import { Col } from 'components/@codelitt/ay-design-library';
import { scaleBand, ScaleBand, scaleLinear, ScaleLinear } from 'd3-scale';
import styles from './RecentSales.module.scss';
import { graphDimensions } from './graphConstants';
import { max } from 'd3';
import Bar from './Elements/Bar';
import QuarterLegend from './Elements/QuarterLegend';
import PriceLegend from './Elements/PriceLegend';
import RemarkableLegend from './Elements/RemarkableLegend';
import { useQuery } from '@apollo/client';
import { IRecentSaleResponse, ISaleBarData, HoverData } from './interfaces';
import dayjs from 'dayjs';
import Tooltip, { updateTooltipPosition } from './Elements/Tooltip';
import { isEqual, isEmpty, isUndefined, isNil } from 'lodash';
import Popup from './Elements/Popup';
import { SALES_RECENT_QUERY } from 'graphql/sales';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_CHART_TEXT_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { deepCopy } from 'utils/objects';

interface Props {
  activePropertySet: IPropertySet | null;
  hasDataChanges?: boolean;
  onChangeData?: () => void;
  property: IProperty;
  showComponent?: (value: boolean) => void;
}

const ONE_BAR = 1;
const TWO_BARS = 2;

const RecentSalesGraph: React.FC<Props> = ({
  activePropertySet,
  hasDataChanges,
  onChangeData,
  property,
  showComponent,
}) => {
  const [activeSaleId, setActiveSaleId] = useState<string>('');
  const [activeHoverData, setActiveHoverData] = useState<HoverData>();
  const [isPopupActive, setIsPopupActive] = useState<boolean>();

  const { loading, data, refetch: refetchSalesData } = useQuery<{
    salesRecent: IRecentSaleResponse;
  }>(SALES_RECENT_QUERY, {
    variables: {
      propertyId: property.id,
      propertySetId: activePropertySet?.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (hasDataChanges) refetchSalesData?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDataChanges]);

  if (loading) return null;

  const sales = data && data.salesRecent ? data.salesRecent.sales : [];
  const hasSales = !isEmpty(sales);

  const currencySymbol = getCurrencySymbol(
    property?.propertyCountry?.currencyCode || property?.currencyCode,
  );
  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    property.propertyCountry?.code ||
      property?.measurementSystem ||
      sales[0]?.measurementSystem,
  );

  const onClickBar = (barData: any) => {
    setIsPopupActive(true);
    setActiveSaleId(barData);
  };

  const onClosePopup = () => {
    setIsPopupActive(false);
    setActiveSaleId('');
  };

  const graphData = deepCopy(sales)
    .sort((a, b) => {
      const firstDateUtc = dayjs(a.date).utc();
      const secondDateUtc = dayjs(b.date).utc();
      return firstDateUtc.isAfter(secondDateUtc) ? 1 : -1;
    })
    .map((sale, idx, arr) => {
      const dateUtc = dayjs(sale.date).utc();
      const label = `Q${dateUtc.quarter()} ${dateUtc.year()}`;
      const isMostRecent = idx === arr.length - 1;
      return {
        key: `${idx}`,
        labelLine1: sale.date ? label : '',
        total: sale.value,
        isQuarter: true,
        isHoverEnabled: true,
        remarkableLegend: isMostRecent
          ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.mostRecent`)
          : '',
        isMostRecent,
        barData: sale,
      } as ISaleBarData;
    });

  if (!hasSales) {
    const noSalesData: ISaleBarData = {
      key: '0',
      size: 20,
      isPlaceholder: true,
      isHoverEnabled: false,
      labelLine1: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.noSaleRecords`,
      ),
    };
    graphData.push(noSalesData);
  }

  if (data?.salesRecent.marketAverage) {
    const marketAverageData: ISaleBarData = {
      key: `${graphData.length}`,
      labelLine1: translateText(
        `${I18N_AVANT_PROPERTY_HEADINGS_PATH}.lastNMonths`,
        { n: 24 },
      ),
      total: !data?.salesRecent.marketAverage
        ? 0
        : data?.salesRecent.marketAverage,
      isQuarter: false,
      isHoverEnabled: true,
      isMarket: true,
      barData: data?.salesRecent,
    };
    graphData.push(marketAverageData);
  } else if (hasSales) {
    const marketAverageData: ISaleBarData = {
      key: 'no-mkt-data',
      size: 20,
      isPlaceholder: true,
      isHoverEnabled: false,
      labelLine1: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.noCompsSetRecords`,
      ),
      labelLine2: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.inThelastTwoYears`,
      ),
      isQuarter: false,
      isMarket: true,
    };
    graphData.push(marketAverageData);
  }

  const hasNoData = !graphData?.length;
  showComponent?.(!hasNoData);

  if (hasNoData) {
    return null;
  }

  const onHoverBar = (barNode: any, dataPoint: any) => {
    updateTooltipPosition(barNode, dataPoint);
    !isEqual(activeHoverData, dataPoint) && setActiveHoverData(dataPoint);
  };

  const getXScalePadding = (barCount: number) => {
    switch (barCount) {
      case ONE_BAR:
        return 0.675;
      case TWO_BARS:
        return 0.5125;
      default:
        return 0.35;
    }
  };

  const xScale: ScaleBand<string> = scaleBand()
    .domain(graphData.map(d => d.key))
    .range([0, graphDimensions.WIDTH])
    .padding(getXScalePadding(graphData.length));

  const yScale: ScaleLinear<number, number> = scaleLinear()
    .domain([
      0,
      max(graphData, d => (!isNil(d.total) ? d.total : d.size)) as number,
    ])
    .range([0, graphDimensions.HEIGHT]);

  const priceData = graphData.filter(bar => !isUndefined(bar.total));
  const shouldRenderPrice = priceData.length > 0;

  return (
    <Col lg={4} md={4} sm={8} xs={4}>
      <GraphContainer wrapperClassName={styles['recent-sales-graph-container']}>
        <Tooltip
          tooltipData={activeHoverData?.tooltipData}
          currencySymbol={currencySymbol}
          unitOfMeasurement={unitOfMeasurement}
        />
        {isPopupActive && (
          <Popup
            activePropertySet={activePropertySet}
            property={property}
            saleId={activeSaleId}
            onClose={onClosePopup}
            onChangeData={onChangeData}
            hasDataChanges={hasDataChanges}
          />
        )}
        <div className={styles.container}>
          <h5 className={styles.title}>
            {translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.recentSales`,
            )}
            {!hasSales && (
              <span className={styles.subtitle} data-test="subtitle">
                {translateText(
                  `${I18N_AVANT_PROPERTY_CHART_TEXT_PATH}.recentSales.noSaleForBuilding`,
                )}
              </span>
            )}
          </h5>
          <svg
            className={styles['svg-graph']}
            width={
              graphDimensions.WIDTH +
              graphDimensions.MARGINS.left +
              graphDimensions.MARGINS.right
            }
            height={
              graphDimensions.HEIGHT +
              graphDimensions.MARGINS.top +
              graphDimensions.MARGINS.bottom
            }
          >
            <g
              transform={`translate(${graphDimensions.MARGINS.left}, ${graphDimensions.MARGINS.top})`}
            >
              {!!activeHoverData?.isMostRecent && (
                <RemarkableLegend
                  data={graphData}
                  xScale={xScale}
                  yScale={yScale}
                />
              )}

              {shouldRenderPrice && (
                <PriceLegend
                  xScale={xScale}
                  yScale={yScale}
                  data={priceData}
                  currencySymbol={currencySymbol}
                />
              )}

              <Bar
                data={graphData}
                xScale={xScale}
                yScale={yScale}
                onHover={onHoverBar}
                onClick={onClickBar}
                unitOfMeasurement={unitOfMeasurement}
              />
              <QuarterLegend data={graphData} xScale={xScale} />
            </g>
          </svg>
        </div>
      </GraphContainer>
    </Col>
  );
};

export default RecentSalesGraph;
