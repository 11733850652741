import React from 'react';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import PropertyDonutsCharts from 'components/Graphs/PropertyDonutsCharts';
import { getPropertiesFilters } from 'components/Graphs/PropertyDonutsCharts/filters';
import { PropertyDonutsChartsIDs } from 'components/Graphs/PropertyDonutsCharts/constants';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { getDonutsData } from 'components/FindComps/PropertiesAnalyticsContainer/CompositionPerformance/DonutsCharts/utils';
import { useDonutsData } from 'components/FindComps/PropertiesAnalyticsContainer/CompositionPerformance/DonutsCharts/useDonutsData';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { useTranslation } from 'react-i18next';

interface Props {
  propertiesIds: number[];
  setPropertiesPopup(propertiesPopup: ILargestPropertiesPopup): void;
  unitOfMeasurement?: string;
}

const DonutsCharts: React.FC<Props> = ({
  propertiesIds,
  setPropertiesPopup,
  unitOfMeasurement,
}) => {
  const { t } = useTranslation();
  const donutsData = getDonutsData(
    useDonutsData(propertiesIds),
    unitOfMeasurement || UnitOfMeasurement.sf,
    t,
  );

  const onDonutClick = (
    donutId: PropertyDonutsChartsIDs,
    graphData?: IDonutGraphData,
  ) => {
    const propertiesIds = graphData?.propsToClick?.propertiesIds;
    if (propertiesIds && graphData?.title) {
      const propertiesFilter = getPropertiesFilters(donutId, graphData?.title);
      setPropertiesPopup({
        isVisible: true,
        popupTitle: graphData?.propsToClick.popupTitle,
        popupSubtitle: `${translateText(
          `${I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH}.largestIn`,
          {
            propertiesType: translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`,
            ).toLowerCase(),
          },
        )} ${graphData?.title}`,
        popupFilter: { ids: propertiesIds },
        propertiesFilter,
        popupSizeLimit: 50,
      });
    }
  };

  return (
    <PropertyDonutsCharts donutsData={donutsData} onDonutClick={onDonutClick} />
  );
};

export default DonutsCharts;
