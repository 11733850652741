import { IProperty } from 'interfaces/IProperty';
import { formatArea } from 'utils/formatters/area';
import { PropertyTypes } from 'constants/propertyTypes';
import { DASH_PLACEHOLDER, DOT } from 'constants/placeholders';
import { IGraphCoverageRatioResponse } from 'interfaces/graphs/coverageRatio';
import { RhombusGraphDataType } from 'components/Graphs/Rhombus/index';
import { hasDataForPropertyType } from 'components/Graphs/Rhombus/utils';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
  UNITS_MEASUREMENT_ABBREVIATION,
} from 'constants/unitOfMeasurement';
import {
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
  isUsingMeters,
} from 'utils/unitsOfMeasurement';
import { fieldHasValue } from 'utils/objects';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_CHART_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

type PropertyInformationType = {
  propertyType?: string;
  avgFloorPlateSize: number;
  graphDimensionSide: number;
  floorPlateSize?: number;
  propertyCoverageRatio?: number;
  marketAvgCoverageRatio?: number;
};

export class GraphSetupService {
  public static getGraphData(
    property: IProperty,
    data: IGraphCoverageRatioResponse,
    unitOfMeasurement: string,
  ): RhombusGraphDataType | null {
    if (!hasDataForPropertyType(property.propertyType?.name, data)) {
      return null;
    }

    const unitOfMeasurementIsMeter = isUsingMeters(unitOfMeasurement);

    switch (property.propertyType?.name) {
      case PropertyTypes.office:
        return this.getGraphPropertyOffice(
          property,
          data,
          unitOfMeasurement,
          unitOfMeasurementIsMeter,
        );
      case PropertyTypes.lifeScience:
        return this.getGraphPropertyLifeScience(
          property,
          data,
          unitOfMeasurement,
          unitOfMeasurementIsMeter,
        );
      case PropertyTypes.multifamily:
        return this.getGraphPropertyMultifamily(
          property,
          data,
          unitOfMeasurement,
          unitOfMeasurementIsMeter,
        );
      case PropertyTypes.industrial:
        return this.getGraphPropertyIndustrial(
          property,
          data,
          unitOfMeasurementIsMeter,
        );
      case PropertyTypes.dataCenter:
        return this.getGraphPropertyDataCenter(property, data);
      default:
        return this.getGraphPropertyOther(
          property,
          data,
          unitOfMeasurement,
          unitOfMeasurementIsMeter,
        );
    }
  }

  private static getGraphPropertyIndustrial(
    property: IProperty,
    data: IGraphCoverageRatioResponse,
    unitOfMeasurementIsMeter: boolean,
  ): RhombusGraphDataType | null {
    let topLegend = '';
    let topSubLegend = '';
    let bottomLegend = '';
    let bottomSubLegend = '';

    topLegend = `${data.propertyCoverageRatio} %`;
    bottomLegend = `${data.marketAvgCoverageRatio} %`;

    const typicalClearHeightMax = getFieldValueForProperty(
      'typicalClearHeightMax',
      property,
      ModelsWithUnitsOfMeasurement.Industrial,
    );

    const typicalClearHeightMaxUnit = getUnitOfMeasurementForProperty(
      'typicalClearHeightMax',
      ModelsWithUnitsOfMeasurement.Industrial,
      property?.propertyCountry?.code || property?.measurementSystem,
    );

    const unitOfMeasurementAbbreviation =
      UNITS_MEASUREMENT_ABBREVIATION[typicalClearHeightMaxUnit];

    const clearHeightLabel = translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.clearHeight`,
    );
    topSubLegend = [
      {
        eval: property.industrial?.loadingDocks,
        text: `${property.industrial?.loadingDocks} ${translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.loading`,
        )}`,
      },
      {
        eval: property.industrial?.driveInDoors,
        text: `${property.industrial?.driveInDoors} ${translateText(
          `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.driveIns`,
        )}`,
      },
      {
        eval: typicalClearHeightMax,
        text: `${typicalClearHeightMax}${unitOfMeasurementAbbreviation} ${clearHeightLabel}`,
      },
    ]
      .filter(item => Boolean(item.eval))
      .map(item => item.text)
      .join(` ${DASH_PLACEHOLDER} `);

    const modeClearHeight = data.industrial!.modeClearHeight;

    bottomSubLegend = [
      {
        eval: modeClearHeight,
        text: `${modeClearHeight}${unitOfMeasurementAbbreviation} ${clearHeightLabel}`,
      },
    ]
      .filter(item => Boolean(item.eval))
      .map(item => item.text)
      .join(' - ');

    return {
      topTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.topTitle`,
      ),
      topLegend,
      topSubLegend,
      bottomTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.bottomTitle`,
      ),
      bottomLegend,
      bottomSubLegend,
      avgFloorPlateSize: unitOfMeasurementIsMeter
        ? data.marketAvgFloorPlateSizeMt
        : data.marketAvgFloorPlateSize,
      marketAvgCoverageRatio: data.marketAvgCoverageRatio,
      propertyCoverageRatio: data.propertyCoverageRatio,
    };
  }

  private static getGraphPropertyOther(
    property: IProperty,
    data: IGraphCoverageRatioResponse,
    unitOfMeasurement: string,
    unitOfMeasurementIsMeter: boolean,
  ): RhombusGraphDataType | null {
    const mktAvgFloorPlateSize = unitOfMeasurementIsMeter
      ? data.marketAvgFloorPlateSizeMt
      : data.marketAvgFloorPlateSize;

    const bottomLegend = formatArea(mktAvgFloorPlateSize, unitOfMeasurement);

    return {
      topTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.title`,
      ),
      topLegend: formatArea(
        Math.ceil(getFieldValueForProperty('floorPlateSize', property)),
        unitOfMeasurement,
      ),
      bottomTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.subtitle`,
      ),
      bottomLegend,
      avgFloorPlateSize: unitOfMeasurementIsMeter
        ? data.marketAvgFloorPlateSizeMt
        : data.marketAvgFloorPlateSize,
      marketAvgCoverageRatio: data.marketAvgCoverageRatio,
      propertyCoverageRatio: data.propertyCoverageRatio,
    };
  }

  private static getGraphPropertyDataCenter(
    property: IProperty,
    data: IGraphCoverageRatioResponse,
  ): RhombusGraphDataType | null {
    const { dataCenter } = property;
    const avgTotalPower = data?.dataCenter?.avgTotalPower;
    const topSubLegend = [
      {
        eval: dataCenter?.commissionedPower,
        text: `${dataCenter?.commissionedPower} ${
          UnitOfMeasurement.kw
        } ${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.commissioned`)}`,
      },
      {
        eval: dataCenter?.underConstPower,
        text: `${dataCenter?.underConstPower} ${
          UnitOfMeasurement.kw
        } ${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.underConstruction`,
        )}`,
      },
      {
        eval: dataCenter?.plannedPower,
        text: `${dataCenter?.plannedPower} ${
          UnitOfMeasurement.kw
        } ${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.plannedPower`,
        )}`,
      },
    ]
      .filter(item => Boolean(item.eval))
      .map(item => item.text)
      .join(` ${DASH_PLACEHOLDER} `);

    return {
      topTitle: translateText(
        `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.totalPower`,
      ),
      topLegend: `${dataCenter?.totalPower} ${UnitOfMeasurement.kw}`,
      topSubLegend,
      bottomTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.dataCenterSubtitle`,
      ),
      bottomLegend: `${avgTotalPower} ${UnitOfMeasurement.kw}`,
      avgFloorPlateSize: avgTotalPower!,
      marketAvgCoverageRatio: data.marketAvgCoverageRatio,
      propertyCoverageRatio: data.propertyCoverageRatio,
    };
  }

  private static getGraphPropertyLifeScience(
    property: IProperty,
    data: IGraphCoverageRatioResponse,
    unitOfMeasurement: string,
    unitOfMeasurementIsMeter: boolean,
  ): RhombusGraphDataType | null {
    let topCeilingsMeasurement = [
      property.lifeScience?.typicalCeilingHeightFeet &&
        `${property.lifeScience?.typicalCeilingHeightFeet}'`,
      property.lifeScience?.typicalCeilingHeightInches &&
        `${property.lifeScience?.typicalCeilingHeightInches}''`,
    ]
      .filter(Boolean)
      .join(' ');

    if (unitOfMeasurementIsMeter) {
      topCeilingsMeasurement = property.lifeScience?.typicalCeilingHeightMt
        ? `${property.lifeScience.typicalCeilingHeightMt} ${UnitOfMeasurement.meter}`
        : '';
    }

    const ceilingLabel = translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.ceiling_plural`,
    );
    const columnsLabel = translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.columns`,
    );
    const topCeilings =
      topCeilingsMeasurement && `${topCeilingsMeasurement} ${ceilingLabel}`;

    const columnSpacingMin = getFieldValueForProperty(
      'columnSpacingMin',
      property,
      ModelsWithUnitsOfMeasurement.LifeScience,
    );
    const columnSpacingMax = getFieldValueForProperty(
      'columnSpacingMax',
      property,
      ModelsWithUnitsOfMeasurement.LifeScience,
    );
    const columnSpacingUnitOfMeasurement = getUnitOfMeasurementForProperty(
      'columnSpacingMax',
      ModelsWithUnitsOfMeasurement.LifeScience,
      property?.propertyCountry?.code || property?.measurementSystem,
    );

    const unitOfMeasurementAbbreviation =
      UNITS_MEASUREMENT_ABBREVIATION[columnSpacingUnitOfMeasurement];

    const topColumns =
      columnSpacingMin &&
      columnSpacingMax &&
      `${columnSpacingMin}${unitOfMeasurementAbbreviation} x ${columnSpacingMax}${unitOfMeasurementAbbreviation} ${columnsLabel}`;

    const topSubLegend =
      property.lifeScience &&
      [topCeilings, topColumns].filter(Boolean).join(` ${DOT} `);

    const mktAvgFloorPlateSize = unitOfMeasurementIsMeter
      ? data.marketAvgFloorPlateSizeMt
      : data.marketAvgFloorPlateSize;

    const bottomLegend = formatArea(mktAvgFloorPlateSize, unitOfMeasurement);

    const avgCeilingsValue = unitOfMeasurementIsMeter
      ? fieldHasValue(data.lifeScience?.avgTypicalCeilingHeightMt) &&
        `${data.lifeScience?.avgTypicalCeilingHeightMt}${UnitOfMeasurement.meter}`
      : [
          data.lifeScience!.avgTypicalCeilingHeightFeet &&
            `${data.lifeScience!.avgTypicalCeilingHeightFeet}'`,
          data.lifeScience!.avgTypicalCeilingHeightInches &&
            `${data.lifeScience!.avgTypicalCeilingHeightInches}''`,
        ]
          .filter(Boolean)
          .join(' ');

    const bottomCeilings =
      avgCeilingsValue && `${avgCeilingsValue} ${ceilingLabel}`;

    const avgColumnSpacingMinField = unitOfMeasurementIsMeter
      ? 'avgColumnSpacingMinMt'
      : 'avgColumnSpacingMin';
    const avgColumnSpacingMaxField = unitOfMeasurementIsMeter
      ? 'avgColumnSpacingMaxMt'
      : 'avgColumnSpacingMax';

    const bottomColumns =
      data.lifeScience![avgColumnSpacingMinField] &&
      data.lifeScience![avgColumnSpacingMaxField] &&
      `${
        data.lifeScience![avgColumnSpacingMinField]
      }${unitOfMeasurementAbbreviation} x ${
        data.lifeScience![avgColumnSpacingMaxField]
      }${unitOfMeasurementAbbreviation} ${columnsLabel}`;

    const bottomSubLegend: any =
      avgCeilingsValue &&
      [bottomCeilings, bottomColumns].filter(Boolean).join(` ${DOT} `);

    return {
      topTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.title`,
      ),
      topLegend: formatArea(
        Math.ceil(getFieldValueForProperty('floorPlateSize', property)),
        unitOfMeasurement,
      ),
      topSubLegend,
      bottomTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.subtitle`,
      ),
      bottomLegend,
      bottomSubLegend,
      avgFloorPlateSize: mktAvgFloorPlateSize,
      propertyCoverageRatio: data.propertyCoverageRatio,
      marketAvgCoverageRatio: data.marketAvgCoverageRatio,
    };
  }

  private static getGraphPropertyOffice(
    property: IProperty,
    data: IGraphCoverageRatioResponse,
    unitOfMeasurement: string,
    unitOfMeasurementIsMeter: boolean,
  ): RhombusGraphDataType | null {
    let topCeilingsMeasurement = [
      property.office?.typicalCeilingHeightFeet &&
        `${property.office?.typicalCeilingHeightFeet}'`,
      property.office?.typicalCeilingHeightInches &&
        `${property.office?.typicalCeilingHeightInches}''`,
    ]
      .filter(Boolean)
      .join(' ');

    if (unitOfMeasurementIsMeter) {
      topCeilingsMeasurement = property.office?.typicalCeilingHeightMt
        ? `${property.office.typicalCeilingHeightMt} ${UnitOfMeasurement.meter}`
        : '';
    }

    const ceilingLabel = translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.ceiling_plural`,
    );
    const columnsLabel = translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.columns`,
    );
    const topCeilings =
      topCeilingsMeasurement && `${topCeilingsMeasurement} ${ceilingLabel}`;

    const columnSpacingMin = getFieldValueForProperty(
      'columnSpacingMin',
      property,
      ModelsWithUnitsOfMeasurement.Office,
    );
    const columnSpacingMax = getFieldValueForProperty(
      'columnSpacingMax',
      property,
      ModelsWithUnitsOfMeasurement.Office,
    );
    const columnSpacingUnitOfMeasurement = getUnitOfMeasurementForProperty(
      'columnSpacingMax',
      ModelsWithUnitsOfMeasurement.Office,
      property?.propertyCountry?.code || property?.measurementSystem,
    );

    const unitOfMeasurementAbbreviation =
      UNITS_MEASUREMENT_ABBREVIATION[columnSpacingUnitOfMeasurement];

    const topColumns =
      columnSpacingMin &&
      columnSpacingMax &&
      `${columnSpacingMin}${unitOfMeasurementAbbreviation} x ${columnSpacingMax}${unitOfMeasurementAbbreviation} ${columnsLabel}`;

    const topSubLegend =
      property.office &&
      [topCeilings, topColumns].filter(Boolean).join(` ${DOT} `);

    const mktAvgFloorPlateSize = unitOfMeasurementIsMeter
      ? data.marketAvgFloorPlateSizeMt
      : data.marketAvgFloorPlateSize;

    const bottomLegend = formatArea(mktAvgFloorPlateSize, unitOfMeasurement);

    const avgCeilingsValue = unitOfMeasurementIsMeter
      ? fieldHasValue(data.office?.avgTypicalCeilingHeightMt) &&
        `${data.office?.avgTypicalCeilingHeightMt}${UnitOfMeasurement.meter}`
      : [
          data.office!.avgTypicalCeilingHeightFeet &&
            `${data.office!.avgTypicalCeilingHeightFeet}'`,
          data.office!.avgTypicalCeilingHeightInches &&
            `${data.office!.avgTypicalCeilingHeightInches}''`,
        ]
          .filter(Boolean)
          .join(' ');

    const bottomCeilings =
      avgCeilingsValue && `${avgCeilingsValue} ${ceilingLabel}`;

    const avgColumnSpacingMinField = unitOfMeasurementIsMeter
      ? 'avgColumnSpacingMinMt'
      : 'avgColumnSpacingMin';
    const avgColumnSpacingMaxField = unitOfMeasurementIsMeter
      ? 'avgColumnSpacingMaxMt'
      : 'avgColumnSpacingMax';

    const bottomColumns =
      data.office![avgColumnSpacingMinField] &&
      data.office![avgColumnSpacingMaxField] &&
      `${
        data.office![avgColumnSpacingMinField]
      }${unitOfMeasurementAbbreviation} x ${
        data.office![avgColumnSpacingMaxField]
      }${unitOfMeasurementAbbreviation} ${columnsLabel}`;

    const bottomSubLegend: any =
      avgCeilingsValue &&
      [bottomCeilings, bottomColumns].filter(Boolean).join(` ${DOT} `);

    return {
      topTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.title`,
      ),
      topLegend: formatArea(
        Math.ceil(getFieldValueForProperty('floorPlateSize', property)),
        unitOfMeasurement,
      ),
      topSubLegend,
      bottomTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.subtitle`,
      ),
      bottomLegend,
      bottomSubLegend,
      avgFloorPlateSize: mktAvgFloorPlateSize,
      propertyCoverageRatio: data.propertyCoverageRatio,
      marketAvgCoverageRatio: data.marketAvgCoverageRatio,
    };
  }

  private static getGraphPropertyMultifamily(
    property: IProperty,
    data: IGraphCoverageRatioResponse,
    unitOfMeasurement: string,
    unitOfMeasurementIsMeter: boolean,
  ): RhombusGraphDataType | null {
    const avgUnitValue = unitOfMeasurementIsMeter
      ? property.multifamily?.avgUnitSizeMt
      : property.multifamily?.avgUnitSizeSf;

    const mktAvgUnitValue = unitOfMeasurementIsMeter
      ? data.multifamily?.avgUnitSizeMt
      : data.multifamily?.avgUnitSizeSf;

    const bottomLegend = formatArea(mktAvgUnitValue, unitOfMeasurement);
    const unitsTotalAvg = translateText(
      `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.unitsTotalAvg`,
    );
    const unitsPerBuilding = translateText(
      `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.unitsPerBuilding`,
    );

    const bottomSubLegend = `${formatArea(
      data.multifamily?.totalUnits,
      '',
    )} ${unitsTotalAvg} ${formatArea(
      data.multifamily?.avgTotalUnits,
      '',
    )} ${unitsPerBuilding}`;

    return {
      topTitle: translateText(
        `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.avgUnitSize`,
      ),
      topLegend: formatArea(Math.ceil(avgUnitValue || 0), unitOfMeasurement),
      topSubLegend: property.multifamily?.totalUnits
        ? `${formatArea(
            property.multifamily?.totalUnits,
            '',
          )} ${unitsPerBuilding}`
        : '',
      bottomTitle: translateText(
        `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.rhombus.multifamilySubtitle`,
      ),
      bottomLegend,
      bottomSubLegend,
      avgFloorPlateSize: unitOfMeasurementIsMeter
        ? data.marketAvgFloorPlateSizeMt
        : data.marketAvgFloorPlateSize,
      propertyCoverageRatio: property.multifamily?.avgUnitSizeSf,
      marketAvgCoverageRatio: data.multifamily?.avgUnitSizeSf,
    };
  }

  public static getProportionXY(props: PropertyInformationType) {
    switch (props.propertyType) {
      case PropertyTypes.multifamily:
      case PropertyTypes.industrial: {
        const { rectASide, rectBSide } = this.calculateProportion(
          props.propertyCoverageRatio!,
          props.marketAvgCoverageRatio!,
          props.graphDimensionSide,
        );
        return { mktRectSide: rectASide, propertyRectSide: rectBSide };
      }
      default: {
        const { rectASide, rectBSide } = this.calculateProportion(
          props.floorPlateSize!,
          props.avgFloorPlateSize,
          props.graphDimensionSide,
        );
        return { mktRectSide: rectASide, propertyRectSide: rectBSide };
      }
    }
  }

  private static calculateProportion(
    propertyValue: number,
    marketValue: number,
    graphDimensionSide: number,
  ) {
    let rectASide: number = graphDimensionSide;
    let rectBSide: number = graphDimensionSide;

    const proportion = propertyValue / marketValue;

    const totalArea = Math.pow(graphDimensionSide, 2);

    if (proportion > 1) {
      rectASide = Math.sqrt(totalArea / proportion);
    } else {
      rectBSide = Math.sqrt(totalArea * proportion);
    }

    return { rectASide, rectBSide };
  }
}
