import React from 'react';
import FormControl from 'components/FormControl';
import { translateText } from 'utils/i18n';
import TextInput from 'components/Inputs/TextInput';
import { REGEX_DECIMALS } from 'constants/regex';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import styles from '../../FormSection.module.scss';

interface Props {
  escalationCap?: number;
  escalationCollar?: number;
  onChange: (field: string, value: string) => void;
}
export const CapCollarFields = ({
  escalationCap,
  escalationCollar,
  onChange,
}: Props) => {
  return (
    <div className={styles['collar-cap-container']}>
      <FormControl
        wrapperClassName={styles['collar-cap-wrapper']}
        columnClassName={styles['collar-cap-label']}
        label={translateText('avantProperties.labels.common.cap')}
      >
        <TextInput
          value={escalationCap}
          id="escalationCap"
          pattern={REGEX_DECIMALS}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          iconPosition="right"
          inputClassName={styles['percentage-input']}
          onChange={item => onChange('escalationCap', item)}
        />
      </FormControl>

      <FormControl
        wrapperClassName={styles['collar-cap-wrapper']}
        columnClassName={styles['collar-cap-label']}
        label={translateText('avantProperties.labels.common.collar')}
      >
        <TextInput
          value={escalationCollar}
          id="escalationCollar"
          pattern={REGEX_DECIMALS}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          iconPosition="right"
          inputClassName={styles['percentage-input']}
          onChange={item => {
            onChange('escalationCollar', item);
          }}
        />
      </FormControl>
    </div>
  );
};
