import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import classnames from 'classnames';

import Button from 'components/Button/new';
import ConfirmationModal from 'components/ConfirmationModal';
import ManageScoopModal from 'components/ManageScoopModal';
import ModalEditComp from 'components/ModalEditComp';
import NotificationMessage from 'components/NotificationMessage';
import OptionsContainer from 'components/OptionsContainer';
import ScoopCommentsModal from 'components/ScoopCommentsModal';
import { ShareScoopsModalMessage } from 'components/ShareScoopsModalMessage';
import { CompType } from 'constants/compType';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { MediaTabNames } from 'constants/media';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { DELETE_SCOOP_MUTATION } from 'graphql/scoops';
import { SHARE_ACTIVITIES_MESSAGE_MUTATION } from 'graphql/share';
import { IActivity } from 'interfaces/IActivity';
import { ErrorLogger } from 'services/ErrorLogger';
import { translateText as t } from 'utils/i18n';

import styles from './Actions.module.scss';

interface Props {
  activity: IActivity;
  refetchActivities: () => void;
  hideShare?: boolean;
  openScoopEditDocuments?: boolean;
  setOpenScoopEditDocuments?: Dispatch<SetStateAction<boolean>>;
  propertySetId?: number;
}

const I18N_PATH = 'avantProperties.texts.exploreActivity.scoop';
const messageScoopError = t(`${I18N_PATH}.scoopErrorMessage`);

const Actions: React.FC<Props> = ({
  activity,
  refetchActivities,
  hideShare,
  openScoopEditDocuments,
  setOpenScoopEditDocuments,
  propertySetId,
}) => {
  const { hasEditorAccess } = useContext(subscriptionsContext);

  const client = useApolloClient();

  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const onCommentsButtonClick = () => setIsCommentsModalOpen(true);

  const [isShareActivityOpened, setIsShareActivityOpened] = useState<boolean>(
    false,
  );
  const [showShareNotification, setShowShareNotification] = useState(false);
  const [showModalScoop, setShowModalScoop] = useState(false);
  const [isDeletingScoop, setIsDeletingScoop] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState<string>('');
  const [showEditScoopModal, setShowEditScoopModal] = useState(false);

  const [showEditLeaseModal, setShowEditLeaseModal] = useState(false);
  const [showEditPriceQuoteModal, setShowEditPriceQuoteModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [shareMessage] = useMutation(SHARE_ACTIVITIES_MESSAGE_MUTATION);
  const closeScoopModal = () => {
    setOpenScoopEditDocuments?.(false);
    setShowEditScoopModal(false);
    setShowModalScoop(false);
  };

  const onShareButtonClick = () => {
    shareMessage({
      variables: {
        search: {
          filter: {
            ids: [activity.id],
          },
          includeScoopsWithNoProperties: true,
          shouldReturnPricingQuotes: true,
        },
      },
    });
    setIsShareActivityOpened(true);
  };

  const closeShareActivity = () => setIsShareActivityOpened(false);

  const renderActivityShare = () => {
    if (!isShareActivityOpened) return null;

    return <ShareScoopsModalMessage onClose={closeShareActivity} />;
  };

  const renderSuccessShareNotification = () => {
    return (
      <NotificationMessage
        show={showShareNotification}
        isSuccess
        text={t(`${I18N_PATH}.scoopNotification`)}
        onClose={() => setShowShareNotification(false)}
      />
    );
  };

  const showScoopNotification = (message: string, isSuccess?: boolean) => {
    setMessageNotification(message);
    setIsSuccess(isSuccess ?? true);
    setShowNotification(true);
  };

  const deleteScoop = async () => {
    if (activity.scoop) {
      try {
        setIsDeletingScoop(true);
        const { data } = await client.mutate<any>({
          mutation: DELETE_SCOOP_MUTATION,
          variables: { scoopId: activity.scoop?.id },
        });

        if (data?.deleteScoop) {
          refetchActivities();
        } else {
          ErrorLogger.log(messageScoopError);
          showScoopNotification(messageScoopError, false);
        }
      } catch (e) {
        ErrorLogger.log(e as any, messageScoopError);
        showScoopNotification(messageScoopError, false);
      } finally {
        closeScoopModal();
        setIsDeletingScoop(false);
      }
    }
  };

  const onEditScoop = () => {
    if (activity.scoop) {
      setShowEditScoopModal(true);
    } else if (activity.lease) {
      setShowEditLeaseModal(true);
    } else if (activity.propertyPriceQuote) {
      setShowEditPriceQuoteModal(true);
    }
  };

  const renderDeleteModal = () => {
    if (showModalScoop) {
      return (
        <ConfirmationModal
          onCloseModal={closeScoopModal}
          negativeButton={{
            label: isDeletingScoop
              ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`)
              : t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`),
            isDisabled: isDeletingScoop,
            onclick: deleteScoop,
          }}
          positiveButton={{
            label: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`),
            onclick: closeScoopModal,
          }}
          header={t('avantProperties.labels.exploreActivity.deleteScoop')}
          paragraph={[
            t(`${I18N_PATH}.firstConfirmToDeleteScoopText`),
            t(`${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.askToProceed`),
          ]}
        />
      );
    }
  };

  const renderNotification = () => {
    return (
      <NotificationMessage
        show={showNotification}
        text={messageNotification}
        onClose={() => setShowNotification(false)}
        isSuccess={isSuccess}
      />
    );
  };

  const renderModalScoop = () => {
    return (
      <ManageScoopModal
        activeTab={openScoopEditDocuments ? MediaTabNames.documents : undefined}
        onCloseModal={closeScoopModal}
        onChangeData={refetchActivities}
        onSuccessNotification={showScoopNotification}
        isVisible={showEditScoopModal || !!openScoopEditDocuments}
        isEditing
        activityScoop={activity.scoop}
      />
    );
  };

  const renderModalLease = () => {
    return showEditLeaseModal ? (
      <ModalEditComp
        type={CompType.lease}
        compId={activity?.lease?.id!}
        onUpdate={refetchActivities}
        onModalToggle={setShowEditLeaseModal}
      />
    ) : null;
  };

  const renderModalPriceQuote = () => {
    return showEditPriceQuoteModal ? (
      <ModalEditComp
        propertySetId={propertySetId}
        type={CompType.pricingQuote}
        compId={activity?.propertyPriceQuote?.id!}
        onUpdate={refetchActivities}
        onModalToggle={setShowEditPriceQuoteModal}
      />
    ) : null;
  };

  const renderCommentsScoop = () => {
    if (!isCommentsModalOpen) return null;

    return (
      <ScoopCommentsModal
        date={activity.activityDate!}
        onChangeData={refetchActivities}
        onEditClick={onEditScoop}
        onModalToggle={setIsCommentsModalOpen}
        onShareClick={onShareButtonClick}
        scoop={activity.scoop!}
        scoopUser={activity.user!}
      />
    );
  };

  const mobileActions: JSX.Element[] = [
    <Button
      key="edit"
      icon="edit"
      iconPosition="left"
      onClick={onEditScoop}
      type="contextual-positive"
      label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.edit`)}
      wrapperClassName={styles['mobile-action-button']}
    />,
    <Button
      key="share"
      icon="share"
      iconPosition="left"
      onClick={onShareButtonClick}
      type="contextual-positive"
      label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.share`)}
      wrapperClassName={styles['mobile-action-button']}
    />,
    <Button
      key="comment"
      icon="comment"
      iconPosition="left"
      onClick={onCommentsButtonClick}
      type="contextual-positive"
      label={t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.comment`)}
      wrapperClassName={styles['mobile-action-button']}
    />,
  ];

  return hasEditorAccess ? (
    <>
      {renderActivityShare()}
      {renderSuccessShareNotification()}
      {renderDeleteModal()}
      {renderModalScoop()}
      {renderModalLease()}
      {renderModalPriceQuote()}
      {renderNotification()}
      {renderCommentsScoop()}
      <div
        className={classnames(styles['actions'], {
          [styles['scoop-actions']]: !!activity.scoop,
        })}
      >
        {!!activity.scoop && (
          <Button
            icon="comment"
            iconPosition="left"
            onClick={onCommentsButtonClick}
            size="s"
            type="contextual"
          />
        )}
        {!hideShare && (
          <Button
            icon="share"
            iconPosition="left"
            onClick={onShareButtonClick}
            size="s"
            type="contextual"
          />
        )}
        <Button
          icon="edit"
          iconPosition="left"
          onClick={onEditScoop}
          size="s"
          type="contextual"
        />
      </div>
      <div
        className={classnames(styles['mobile-actions'], {
          [styles['scoop-actions']]: !!activity.scoop,
        })}
      >
        <OptionsContainer
          isMedium
          customOptions={mobileActions}
          optionButtonIcon={'more'}
          optionContainerStyle={'contextual'}
          optionsIconClassName={styles['options-icon-container']}
        />
      </div>
    </>
  ) : null;
};

export default Actions;
