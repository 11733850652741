import LeasesSearchInput from 'components/LeaseSearchInput';
import { SearchInputSize } from 'components/SearchInput/enums';
import { IdName } from 'interfaces/IdName';
import { ISearchInputCustomOption } from 'interfaces/ISearchInputCustomOption';
import React from 'react';
import removeIcon from '../../assets/images/icons/clear-icon-small.svg';
import clearIcon from '../../assets/images/icons/clear-icon.svg';
import styles from './LeaseFilter.module.scss';

type Props = {
  aliasPlaceholder?: (suggestionName: string) => string;
  customOption?: ISearchInputCustomOption;
  hasBottomMargin?: boolean;
  inputId?: string;
  disabledPlaceholder?: string;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isSearchIconLeftVisible?: boolean;
  label?: string;
  onChangeText?: (value?: string) => void;
  onClearSelectedSuggestion?: () => void;
  onRemoveItem: (value: IdName) => void;
  onSelectItem: (newValue: IdName) => void;
  placeholder?: string;
  selectedItems: IdName[];
  size?: SearchInputSize;
  searchOnlyCompanies?: boolean;
  tenantCompanyId?: number;
  currentPropertyId?: number;
  currentLeaseId?: number | null;
  currentMarketId?: number;
  onInputClear?: () => void;
};

const LeaseFilter: React.FC<Props> = props => {
  const {
    aliasPlaceholder,
    customOption,
    hasBottomMargin,
    inputId,
    isDisabled,
    disabledPlaceholder,
    isMultiple,
    isSearchIconLeftVisible,
    label,
    onChangeText,
    onClearSelectedSuggestion,
    onRemoveItem,
    onSelectItem,
    placeholder,
    selectedItems,
    size,
    tenantCompanyId,
    currentPropertyId,
    currentLeaseId,
    currentMarketId,
    onInputClear,
  } = props;

  const hasSelectedItems = !!selectedItems?.length;
  const hasClearInputIcon = onInputClear instanceof Function;

  return (
    <div className={styles.container}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles['search-container']}>
        <LeasesSearchInput
          aliasPlaceholder={aliasPlaceholder}
          clearAfterSuggestionClick={isMultiple}
          customOption={customOption}
          filterOnlyCompanies
          hasBottomMargin={hasBottomMargin}
          initialValue={isMultiple ? undefined : selectedItems?.[0]?.name}
          inputId={inputId}
          isDisabled={isDisabled}
          disabledPlaceholder={disabledPlaceholder}
          isEditable={!isDisabled}
          isSearchIconLeftVisible={isSearchIconLeftVisible}
          onChangeText={onChangeText}
          onClearSelectedSuggestion={onClearSelectedSuggestion}
          onClickSuggestionCustom={onSelectItem}
          placeholder={placeholder}
          size={size || SearchInputSize.Small}
          withMarketSwitch={false}
          tenantCompanyId={tenantCompanyId}
          currentPropertyId={currentPropertyId}
          currentLeaseId={currentLeaseId}
          currentMarketId={currentMarketId}
        />
        {hasClearInputIcon && (
          <img
            className={styles['clear-input-icon']}
            src={clearIcon}
            alt="Input clear"
            onClick={onInputClear}
          />
        )}
        {isMultiple && hasSelectedItems && (
          <div className={styles.results}>
            {selectedItems &&
              selectedItems.map(item => (
                <div key={item?.id} className={styles['result-item']}>
                  {item?.name}
                  <img
                    className={styles['remove-icon']}
                    src={removeIcon}
                    alt="Clear"
                    onClick={() => {
                      onRemoveItem(item);
                    }}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LeaseFilter;
