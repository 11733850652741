import { gql } from '@apollo/client';

export const CREATE_AVAILABILITY_MUTATION = gql`
  mutation createAvailability($availability: AvailabilityInput!) {
    createAvailability(availability: $availability) {
      ... on Availability {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const UPDATE_AVAILABILITY_MUTATION = gql`
  mutation updateAvailability($availability: AvailabilityInput!) {
    updateAvailability(availability: $availability) {
      ... on Availability {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const DELETE_AVAILABILITY_MUTATION = gql`
  mutation deleteAvailability($id: Int!) {
    deleteAvailability(id: $id)
  }
`;

export const ADD_FLOORPLAN_MUTATION = gql`
  mutation addFloorPlan($file: Upload!, $floorPlan: FloorPlanInput!) {
    addFloorPlan(file: $file, floorPlan: $floorPlan) {
      id
    }
  }
`;
