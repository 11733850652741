import { gql } from '@apollo/client';

export const GET_OBJECT_TYPES = gql`
  query objectTypes {
    objectTypes {
      id
      name
    }
  }
`;

export const GET_THIRD_PARTY_TYPES = gql`
  query thirdPartyTypes($objectTypeId: ID) {
    thirdPartyTypes(objectTypeId: $objectTypeId) {
      thirdPartyTypeId: id
      thirdPartyTypeName: name
    }
  }
`;

export const GET_THIRD_PARTY_INFO = gql`
  query thirdPartyInfo($objectTypeId: ID!, $objectId: ID!) {
    thirdPartyInfo(objectTypeId: $objectTypeId, objectId: $objectId) {
      id
      value
      thirdPartyTypeId
      thirdPartyTypeName
    }
  }
`;
