import React from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalBarChartsIds } from 'components/Graphs/VerticalBarChart/graphIds';
import { parseGraphData } from 'components/SubmarketProfile/Sections/Performance/HistoricalLeasing/Concessions/utils';
import VictoryBarChart from 'components/VictoryBarChart';
import {
  getConcessionChartStyle,
  LEGENDS,
  TITLES,
} from 'components/MarketProfile/Sections/Performance/HistoricalLeasing/Concessions/utils';
import {
  UnitOfMeasurement,
  UNITS_MEASUREMENT_AREA,
} from 'constants/unitOfMeasurement';
import { DOT_LG } from 'constants/placeholders';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
  I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH,
} from 'constants/i18n';
import { LeasePeriodType } from 'constants/leases';
import { ILeaseAnalyticsConcessionResponse } from 'interfaces/ILeaseAnalyticsConcession';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { getCurrencySymbol } from 'utils/formatters/currency';

type Props = {
  data: ILeaseAnalyticsConcessionResponse;
  setPropertiesPopup?: (popup: ILargestPropertiesPopup) => void;
  unitOfMeasurement: UnitOfMeasurement;
  periodType?: LeasePeriodType;
};

const Concessions: React.FC<Props> = ({
  data,
  setPropertiesPopup,
  unitOfMeasurement,
  periodType,
}) => {
  const { t } = useTranslation();
  const isQuarterly = periodType === LeasePeriodType.QUARTER;

  // TODO - check if this value would be dynamic
  const currencySymbol = getCurrencySymbol();
  const barData = parseGraphData(
    data.slices,
    currencySymbol,
    UNITS_MEASUREMENT_AREA[unitOfMeasurement],
    isQuarterly,
  );

  const handleConcessionClick = (barData: any) => {
    const { year, quarter, topItems, isBar } = barData;
    const title = isBar
      ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.monthsOfFreeRent`)
      : t(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.tenantImprovementsAllowance`,
        );

    setPropertiesPopup?.({
      isVisible: true,
      popupFilter: { ids: topItems },
      popupTitle: title,
      popupSizeLimit: 50,
      popupSubtitle: t(
        `${I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH}.largestInQuarter`,
        {
          propertyType: t(`${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.leases`),
          year,
          dot: DOT_LG,
          quarter,
        },
      ),
      isLeases: true,
    });
  };

  return (
    <VictoryBarChart
      title={TITLES}
      key={VerticalBarChartsIds.AgencyLeasingConcessions}
      barData={barData}
      chartStyle={getConcessionChartStyle(
        UNITS_MEASUREMENT_AREA[unitOfMeasurement],
        isQuarterly,
        currencySymbol,
      )}
      handleClickBar={handleConcessionClick}
      handleClickLine={handleConcessionClick}
      isBarHoverable
      hasLineAverage
      legend={LEGENDS}
    />
  );
};

export default Concessions;
