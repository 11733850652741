import React from 'react';
import { uniqBy } from 'lodash';
import { IdName } from 'interfaces/IdName';
import { useQuery } from '@apollo/client';
import FormControl from 'components/FormControl';
import { PropertyTypeIds } from 'constants/propertyTypes';
import RemoteDataFilter from 'components/RemoteDataFilter';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  GET_APPROVED_AMENITIES_QUERY,
  GET_APPROVED_PROPERTY_TYPE_AMENITIES_QUERY,
} from 'graphql/amenities';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  isDisabled?: boolean;
  property: IPropertyInput;
  selectedItems?: IdName[];
  wrapperClassName?: string;
  onChange: (key: string, selectedOptions?: IdName[]) => void;
}

const AmenitiesSelector: React.FC<Props> = ({
  property,
  onChange,
  isDisabled,
  selectedItems,
  wrapperClassName,
}) => {
  const { t } = useTranslation();
  const propertyTypeAmenities = useQuery(
    GET_APPROVED_PROPERTY_TYPE_AMENITIES_QUERY,
    {
      // If no propertyType selected, show "Office" by default
      variables: {
        propertyTypeIds: [property.propertyType?.id || PropertyTypeIds.office],
      },
    },
  );

  const getAmenitiesToDisplay = (data: any) =>
    propertyTypeAmenities.data?.amenities?.length
      ? uniqBy(
          [...propertyTypeAmenities.data?.amenities, ...data?.amenities],
          'id',
        )
      : data?.amenities || [];

  return (
    <FormControl
      isMultiline
      label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.amenities`)}
      disabled={isDisabled}
      wrapperClassName={wrapperClassName}
    >
      <RemoteDataFilter
        isMultipleSelect
        isDisabled={isDisabled}
        selectedItems={selectedItems}
        queryParser={getAmenitiesToDisplay}
        query={GET_APPROVED_AMENITIES_QUERY}
        onChangeMultiple={(selectedOptions?: IdName[]) =>
          onChange('amenities', selectedOptions)
        }
      />
    </FormControl>
  );
};

export default AmenitiesSelector;
