import { gql } from '@apollo/client';

export const CREATE_THIRD_PARTY_MUTATION = gql`
  mutation createThirdPartyIds($thirdPartyIds: [ThirdPartyInput]!) {
    createThirdPartyIds(thirdPartyIds: $thirdPartyIds) {
      ... on ThirdPartyId {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const UPDATE_THIRD_PARTY_MUTATION = gql`
  mutation updateThirdPartyId($thirdPartyId: ThirdPartyInput) {
    updateThirdPartyId(thirdPartyId: $thirdPartyId) {
      ... on ThirdPartyId {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const DELETE_THIRD_PARTY_MUTATION = gql`
  mutation deleteThirdPartyId($id: Int) {
    deleteThirdPartyId(id: $id)
  }
`;
