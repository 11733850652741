import React, { useEffect, useState } from 'react';
import { translateText } from 'utils/i18n';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import {
  formatNumberPercentage,
  formatNumberWithCommas,
  removeCommaFromNumber,
  roundNumberWithDecimals,
} from 'utils/formatters/number';
import { REGEX_PERCENTAGE } from 'constants/regex';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_ATTR_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import styles from './PreLeased.module.scss';
import sectionStyles from '../../FormSection.module.scss';
import { I18N_PROPERTY_TEXT_PATH } from '../../../../PropertyProfile/constants';

interface Props {
  property: IPropertyInput;
  unitOfMeasurement: string;
  onChange: (field: string, value: any) => void;
  required?: boolean;
}
const PreLeasedFields = ({
  property,
  required,
  onChange,
  unitOfMeasurement,
}: Props) => {
  const isSqm = unitOfMeasurement === UnitOfMeasurement.sm;
  const preLeasedSize = isSqm ? 'preLeasedSm' : 'preLeasedSf';
  const preLeasedCustomSize = isSqm ? 'preLeasedCustomSm' : 'preLeasedCustomSf';
  const buildingSize = isSqm ? 'buildingSizeMt' : 'buildingSize';
  const invalidCustomSizeMessage = translateText(
    `${I18N_PROPERTY_TEXT_PATH}.preLeasedCustomInvalid`,
  );

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!property[buildingSize] || !property[preLeasedCustomSize]) {
      setHasError(false);
      return;
    }

    const isCustomPreLeasedGreater =
      +removeCommaFromNumber(String(property[preLeasedCustomSize])) >
      property[buildingSize]!;
    setHasError(isCustomPreLeasedGreater);
  }, [buildingSize, isSqm, preLeasedCustomSize, property]);

  const renderPreLeasedRow = (
    sizeValue: number | undefined,
    percentageValue: number | undefined,
    sizeField: string,
    isDisabledSizeField: boolean,
    label: string,
    isInvalid: boolean,
  ) => {
    return (
      <div className={styles['pre-leased-container']}>
        <FormControl
          required={required}
          disabled={isDisabledSizeField}
          label={label}
          columnClassName={styles['pre-leased-column']}
          wrapperClassName={styles['pre-leased-wrapper']}
        >
          <TextInput
            isInvalid={isInvalid}
            isDisabled={isDisabledSizeField}
            formatter={value => formatNumberWithCommas(value)}
            placeholder={translateText(
              `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.prompt.enterSize`,
            )}
            onChange={v => {
              const convertedNumberValue = Number(
                removeCommaFromNumber(String(v)),
              );
              if (isNaN(convertedNumberValue)) return;
              onChange(sizeField, v);
            }}
            value={sizeValue}
          />
          <UnitOfMeasurementLegend
            unitOfMeasurement={unitOfMeasurement}
            shouldConvert={false}
          />
          {isInvalid && hasError && (
            <span className={styles['error-message']}>
              {invalidCustomSizeMessage}
            </span>
          )}
        </FormControl>

        <FormControl
          columnClassName={styles['pre-leased-column']}
          wrapperClassName={styles['pre-leased-wrapper']}
          required={required}
          disabled={true}
        >
          <TextInput
            isDisabled={true}
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
            )}
            pattern={REGEX_PERCENTAGE}
            value={Math.round(
              parseFloat(formatNumberPercentage((percentageValue ?? 0) * 100)),
            )}
          />
          <span className={styles['pre-leased-percentage']}>%</span>
        </FormControl>
      </div>
    );
  };

  return (
    <>
      <div className={sectionStyles['form-row']}>
        {renderPreLeasedRow(
          property[preLeasedSize],
          Number(roundNumberWithDecimals(property?.preLeasedPct ?? 0)),
          preLeasedSize,
          true,
          translateText(`${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.preLeased`),
          false,
        )}

        {renderPreLeasedRow(
          property[preLeasedCustomSize],
          property.preLeasedCustomPct,
          preLeasedCustomSize,
          false,
          translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.customPreLeased`,
          ),
          hasError,
        )}
      </div>
    </>
  );
};

export default PreLeasedFields;
