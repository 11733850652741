import { IRadioItem } from 'components/Inputs/RadioInput';

export enum optionsValues {
  yes = 'Yes',
  no = 'No',
}

export const OPTIONS = [
  {
    id: optionsValues.no,
    name: optionsValues.no,
  },
  {
    id: optionsValues.yes,
    name: optionsValues.yes,
  },
];

export const COMPANIES_SUGGESTIONS: IRadioItem[] = [
  {
    id: 85,
    label: 'Avison Young',
    value: 'Avison Young',
  },
  {
    id: 4,
    label: 'CBRE',
    value: 'CBRE',
  },
  {
    id: 11,
    label: 'Cushman & Wakefield',
    value: 'Cushman & Wakefield',
  },
  {
    id: 8,
    label: 'JLL',
    value: 'JLL',
  },
  {
    id: 66606,
    label: 'Newmark',
    value: 'Newmark',
  },
  {
    id: 9172,
    label: 'Colliers',
    value: 'Colliers',
  },
  {
    id: 48,
    label: 'Savills',
    value: 'Savills',
  },
  {
    id: 19,
    label: 'Transwestern',
    value: 'Transwestern',
  },
  {
    id: 3719,
    label: 'NAI',
    value: 'NAI',
  },
  {
    id: 57570,
    label: 'Stream',
    value: 'Stream',
  },
];
