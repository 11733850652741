import { ILeaseOption } from 'interfaces/ILeaseOption';
import { getDateISOString } from 'utils/date';

export const transformToLeaseOptionsInput = (
  options: ILeaseOption[],
): ILeaseOption[] => {
  return options?.map(o => ({
    ...o,
    breakOptionPenalty: o.breakOptionPenalty?.toString(),
    expansionSf: o.expansionSf?.toString() ?? undefined,
    optionDate: o.optionDate ? getDateISOString(o.optionDate) : null,
    optionNoticeDate: o.optionNoticeDate
      ? getDateISOString(o.optionNoticeDate)
      : null,
  }));
};
