import { sortBy, camelCase } from 'lodash';
import { ScoopCategoriesType, ScoopCategoriesOrder } from 'interfaces/IScoop';
import { TranslateFunction } from './i18n';
import { I18N_AVANT_PROPERTY_ENUMERATORS } from 'constants/i18n';

export const getScoopsDataFormattedForRendering = (
  scoopCategories: ScoopCategoriesType[],
  t: TranslateFunction,
) =>
  sortBy(
    scoopCategories?.map(scoop => ({
      ...scoop,
      order: ScoopCategoriesOrder[scoop.type], // Used as group order, not used on selection
      type: t(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.scoopCategories.${camelCase(
          scoop.type,
        )}`,
      ), // Used as group title, not used on selection
    })),
    'order',
  );
