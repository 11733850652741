export enum SearchOperations {
  ilike = 'ilike',
  like = 'like',
  eq = 'eq',
  ne = 'ne',
  gte = 'gte',
  lte = 'lte',
  lt = 'lt',
  gt = 'gt',
  between = 'between',
}
