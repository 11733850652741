import { gql } from '@apollo/client';

export const LINK_PURSUITS_TO_PROJECT = gql`
  mutation linkProjectToPursuits($projectLinkInput: ProjectLinkInput!) {
    linkProjectToPursuits(projectLink: $projectLinkInput)
  }
`;

export const DELETE_PROJECT_FROM_PURSUIT = gql`
  mutation unlinkPursuitsAndProjects($unlink: UnlinkInput!) {
    unlinkPursuitsAndProjects(unlink: $unlink)
  }
`;
