import React, { useState } from 'react';
import classnames from 'classnames';
import defaultType from 'assets/images/mapTypes/default.svg';
import satelliteType from 'assets/images/mapTypes/satellite.svg';
import Button from 'components/Button/new';
import styles from './MapType.module.scss';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

const options = [
  {
    type: 'roadmap',
    icon: defaultType,
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.default`),
  },
  {
    type: 'satellite',
    icon: satelliteType,
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.satellite`),
  },
];
interface Props {
  mapType: string;
  setMapType: (type: string) => void;
}

export const MapType = ({ mapType, setMapType }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMapType = () => setIsOpen(!isOpen);

  return (
    <div className={styles['toggle-container']}>
      {isOpen ? (
        <div className={styles['toggle-modal']}>
          <div className={styles['toggle-header']}>
            <span>
              {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.basemap`)}
            </span>
            <Button
              iconSize={0.75}
              icon="close"
              onClick={toggleMapType}
              size="s"
              type="ghost"
              wrapperClassName={styles['toggle-close']}
            />
          </div>
          <div className={styles['toggle-options']}>
            {options.map(({ type, icon, label }) => (
              <div
                key={type}
                onClick={() => setMapType(type)}
                className={classnames(styles['toggle-option'], {
                  [styles['toggle-option-active']]: mapType === type,
                })}
              >
                <img src={icon} alt={type} />
                <span>{label}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Button
          wrapperClassName={styles['toggle-button']}
          type="ghost"
          icon="tertiaryPoint"
          onClick={toggleMapType}
        />
      )}
    </div>
  );
};
