import React from 'react';
import { IScoopMedia } from 'interfaces/IScoopMedia';
import { IDocument } from 'interfaces/IDocument';
import { isImageFileType, isMediaFileImage } from 'utils/media';

import { FileToUpload } from '../../../ModalMedia/types';
import AttachmentPreview from '../../../AttachmentsPreview/AttachmentPreview';
import { forceFileDownload } from '../../../ModalMedia/utils';
import styles from '../ScoopForm.module.scss';
import { DeleteModalState } from '../index';

interface Props {
  items: Array<FileToUpload | IScoopMedia>;
  setDeleteModalState: React.Dispatch<React.SetStateAction<DeleteModalState>>;
}
export const UploadedImagePreview = ({ items, setDeleteModalState }: Props) => {
  const onOpenDeleteMediaModal = (file: FileToUpload | IScoopMedia) => {
    const mediaId = (file as IScoopMedia).id;
    const fileId = (file as FileToUpload).key;
    const id = fileId || String(mediaId);

    const isImage = fileId
      ? isImageFileType((file as FileToUpload).file)
      : isMediaFileImage(file as IScoopMedia);

    setDeleteModalState({
      activeOpenScoopId: id,
      open: true,
      removeByRequest: !!mediaId,
      isDocument: !isImage,
      isImage: isImage,
    });
  };

  return (
    <div className={styles['attachment-container']}>
      {items.map(item => {
        const isDocument = !!(item as IDocument).id;
        const attachment = isDocument
          ? { document: item as IDocument }
          : { file: (item as FileToUpload).file };
        const uniqueKey = (item as File).name || (item as IDocument).id;

        return (
          <AttachmentPreview
            key={uniqueKey}
            showLabel={true}
            attachment={attachment}
            onRemove={() => onOpenDeleteMediaModal(item)}
            onDownload={() =>
              forceFileDownload(
                attachment.document?.url ||
                  URL.createObjectURL(attachment.file),
              )
            }
          />
        );
      })}
    </div>
  );
};
