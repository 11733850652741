import { COUNTRIES_QUERY } from 'graphql/countries/queries';
import React, { createContext, useEffect, useState } from 'react';
import { authContext } from './AuthContext';
import { useApolloClient } from '@apollo/client';

interface CountriesInterface {
  isLoading: boolean;
  countries: Country[];
  currencyCodeSuggestions: CurrencyCodeSuggestion[];
  getCurrencyCode: (countryCode: string) => string | undefined;
}

interface Country {
  id: number;
  name: string;
  code: string;
  flagURL: string;
  distanceMeasurement: string;
  areaMeasurement: string;
  currencyCode: string;
  createdAt: string;
  updatedAt: string;
}

interface CurrencyCodeSuggestion {
  label: string;
  value: string;
}

interface CountryCurrencyCode {
  code: string;
  currencyCode: string;
}

export const countriesContext = createContext<CountriesInterface>({
  isLoading: true,
  countries: [],
  currencyCodeSuggestions: [],
  getCurrencyCode: () => '',
});

const { Provider } = countriesContext;

type PropTypes = {
  children: React.ReactNode;
};

export const CountriesProvider: React.FC<PropTypes> = (props: PropTypes) => {
  const { user } = React.useContext(authContext);
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState<Country[]>([]);
  const [currencyCodeSuggestions, setCurrencyCodeSuggestions] = useState<
    CurrencyCodeSuggestion[]
  >([]);
  const [countryCurrencyCodes, setCountryCurrencyCodes] = useState<
    CountryCurrencyCode[]
  >([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await client.query({
        query: COUNTRIES_QUERY,
      });
      setCountries(response.data.countries);
      const suggestions: CurrencyCodeSuggestion[] = [];
      const countryCurrencyCodes: CountryCurrencyCode[] = [];
      response.data.countries.forEach((element: Country) => {
        suggestions.push({
          label: element.currencyCode,
          value: element.currencyCode,
        });
        countryCurrencyCodes.push({
          code: element.code,
          currencyCode: element.currencyCode,
        });
      });
      setCurrencyCodeSuggestions(suggestions);
      setCountryCurrencyCodes(countryCurrencyCodes);
      setIsLoading(false);
    };
    if (!!user.email) {
      fetchCountries();
    }
  }, [client, user]);

  const getCurrencyCode = (countryCode: string) => {
    return countryCurrencyCodes.find(country => country.code === countryCode)
      ?.currencyCode;
  };

  return (
    <Provider
      value={{ isLoading, countries, currencyCodeSuggestions, getCurrencyCode }}
    >
      {props.children}
    </Provider>
  );
};
