import React, { useEffect, useState } from 'react';
import styles from './MarketPropertyDataFilter.module.scss';
import DropdownFilter from 'components/DropdownFilter';
import { MARKETS_QUERY, SUB_MARKETS_QUERY } from 'graphql/markets';
import { filterItemsByName } from 'utils/filters';
import { SortDirections } from 'constants/sortDirections';
import {
  PROPERTY_CLASSES_QUERY,
  PROPERTY_SUBTYPES_QUERY,
  PROPERTY_TYPES_ENTITIES_QUERY,
} from 'graphql/property';
import { IMarket } from 'interfaces/IMarket';
import Button from 'components/Button';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { MarketPropertyDataFiltersType } from './constants';
import { getFilterLabel } from './utils';
import { enumerize } from 'utils/formatters/string';
import { IdName } from 'interfaces/IdName';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { PropertyTypeNames } from 'constants/propertyTypes';

interface Props {
  filters: MarketPropertyDataFiltersType;
  onChangeFilters: (updatedFilters: MarketPropertyDataFiltersType) => void;
}

const MarketPropertyDataFilter: React.FC<Props> = ({
  filters,
  onChangeFilters,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [localFilters, setLocalFilters] = useState<
    MarketPropertyDataFiltersType
  >(filters);
  const { t } = useTranslation();
  const canApplyFilters =
    !!localFilters.market?.id &&
    !!localFilters.submarket?.id &&
    !!localFilters.propertyClass?.id &&
    !!localFilters.propertySubtype?.id &&
    !!localFilters.propertyType?.id;

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  useEffect(() => {
    setIsDirty(!isEqual(filters, localFilters));
  }, [filters, localFilters]);

  const updateFilters = (key: string, newValue: any) => {
    setLocalFilters({
      ...localFilters,
      [key]: newValue,
    });
  };

  const updateMarket = (selectedOption: IMarket) =>
    setLocalFilters({
      ...localFilters,
      submarket: undefined,
      market: selectedOption,
    });

  const updatePropertyType = (propertyType?: IdName) =>
    setLocalFilters({
      ...localFilters,
      propertyType,
      propertySubtype: undefined,
    });

  return (
    <div className={styles.container}>
      <div className={styles['action-group']}>
        <div className={styles['dropdown-container']}>
          <DropdownFilter
            label={getFilterLabel(
              t(`${I18N_PLATFORM_COMMON_WORD_PATH}.propertyType`),
              localFilters.propertyType,
            )}
            placeholder={t('avantProperties.labels.common.searchType')}
            graphqlQuery={PROPERTY_TYPES_ENTITIES_QUERY}
            mapper={data => data.propertyTypesEntities}
            selectedValue={localFilters.propertyType}
            onChange={propertyType => updatePropertyType(propertyType)}
            clientSideFilter={filterItemsByName}
            renderFooterSelection
          />
        </div>

        <div className={styles['dropdown-container']}>
          <DropdownFilter
            label={getFilterLabel(
              t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`),
              localFilters.market,
            )}
            placeholder={t(
              'avantPlatform.attributes.property.prompt.searchMarket',
            )}
            graphqlQuery={MARKETS_QUERY}
            mapper={data => data.markets}
            selectedValue={localFilters.market}
            onChange={updateMarket}
            clientSideFilter={filterItemsByName}
            graphqlVariables={() => ({
              search: {
                order: { direction: SortDirections.asc, field: 'name' },
              },
            })}
            groupBySubfield="country"
          />
        </div>

        <div className={styles['dropdown-container']}>
          <DropdownFilter
            isDisabled={!localFilters.market || !localFilters.propertyType?.id}
            label={getFilterLabel(
              t(`${I18N_PLATFORM_COMMON_WORD_PATH}.submarket`),
              localFilters.submarket,
            )}
            placeholder={t(
              'avantPlatform.attributes.property.prompt.searchSubmarket',
            )}
            graphqlQuery={SUB_MARKETS_QUERY}
            skipQuery={!localFilters.market || !localFilters.propertyType?.id}
            mapper={data => data.submarkets}
            selectedValue={localFilters.submarket}
            onChange={submarket => updateFilters('submarket', submarket)}
            graphqlVariables={() => ({
              search: {
                filter: {
                  markets: [{ id: localFilters.market?.id }],
                  propertyTypeIds: [localFilters.propertyType?.id],
                  onlyItemsWithProperties: true,
                },
                order: { field: 'name', direction: SortDirections.asc },
              },
            })}
            clientSideFilter={filterItemsByName}
            groupBySubfield="market"
          />
        </div>

        <div className={styles['dropdown-container']}>
          <DropdownFilter
            label={getFilterLabel(
              t(`${I18N_PLATFORM_COMMON_WORD_PATH}.buildingClass`),
              localFilters.propertyClass,
            )}
            placeholder={t(
              'avantPlatform.attributes.property.prompt.searchClass',
            )}
            graphqlQuery={PROPERTY_CLASSES_QUERY}
            mapper={data => data.propertyClasses}
            selectedValue={localFilters.propertyClass}
            onChange={propertyClass =>
              updateFilters('propertyClass', propertyClass)
            }
            clientSideFilter={filterItemsByName}
          />
        </div>
        <div className={styles['dropdown-container']}>
          <DropdownFilter
            isDisabled={!localFilters.propertyType}
            label={getFilterLabel(
              t('avantProperties.headings.common.propertySubtype'),
              localFilters.propertySubtype,
            )}
            placeholder={t(
              'avantPlatform.attributes.property.prompt.searchSubtype',
            )}
            graphqlQuery={PROPERTY_SUBTYPES_QUERY}
            skipQuery={!localFilters.propertyType}
            mapper={data => {
              return data?.propertySubtypes
                ?.filter(
                  (subtype: any) =>
                    enumerize(
                      PropertyTypeNames[
                        localFilters.propertyType?.id
                      ].toUpperCase(),
                    ) === enumerize(subtype.type),
                )
                ?.map((subtype: any) => ({
                  ...subtype,
                  type: enumerize(subtype.type),
                }));
            }}
            selectedValue={localFilters.propertySubtype}
            onChange={propertySubtype =>
              updateFilters('propertySubtype', propertySubtype)
            }
            clientSideFilter={filterItemsByName}
            graphqlVariables={() => ({
              search: {
                typeId: localFilters.propertyType?.id,
              },
            })}
          />
        </div>
      </div>

      <div className={styles['action-group']}>
        <Button
          type="contextual"
          label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.apply`)}
          onClick={() => onChangeFilters(localFilters)}
          isDisabled={!isDirty || !canApplyFilters}
          wrapperClassName={classnames(
            styles['action-button'],
            styles['apply-selection-button'],
          )}
        />
      </div>
    </div>
  );
};
export default MarketPropertyDataFilter;
