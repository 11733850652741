import React, { useEffect, useRef, useState } from 'react';
import { select } from 'd3-selection';
import {
  getHorizontalLineId,
  getLeaseBubbleId,
  getLeaseBubbleShadowId,
  getLeaseVerticalLineId,
} from '../../RecentLeases/nodes';
import { GraphClasses } from '../../RecentLeases/graphConstants';
import * as d3 from 'd3';
import { ScaleLinear, ScalePower } from 'd3';
import isEqual from 'lodash/isEqual';
import { ScaleTime } from 'd3-scale';
import { hideTooltip, showTooltip } from '../Tooltip/utils';
import { colors } from 'constants/colors';
import { IBubbleDataPoint } from 'components/Graphs/BubbleChartsElements/interfaces';
import { cleanUpElement } from 'utils/d3Utils';

interface Props {
  borderColor?: string;
  disableBorderColor?: string;
  circleScale: ScalePower<number, number>;
  currencySymbol?: string;
  data: IBubbleDataPoint[];
  hoverColor: string;
  mainColor: string;
  disableColor?: string;
  onClick?: (data: IBubbleDataPoint) => void;
  onMouseOver?: (data: IBubbleDataPoint) => void;
  onMouseOut?: () => void;
  showHorizontalLine?: boolean;
  unitOfMeasurement?: string;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
  disabled?: boolean;
  hasHorizontalLines?: boolean;
}

const LeaseBubbles: React.FC<Props> = props => {
  const bubblesGroupRef = useRef(null);

  let previousVerticalLineStroke = '';
  let previousVerticalLineOpacity = 1;

  const {
    borderColor = colors.primaryColor400,
    disableBorderColor = colors.primaryColor600,
    hoverColor = colors.primaryColor400,
    disableColor = colors.primaryColor700,
    circleScale,
    disabled = false,
    showHorizontalLine = true,
    xScale,
    yScale,
    onMouseOver,
    hasHorizontalLines = false,
    onMouseOut,
  } = props;
  const [dataPoints, setDataPoints] = useState<IBubbleDataPoint[]>([]);

  useEffect(() => {
    if (!isEqual(props.data, dataPoints)) {
      cleanUpElement(bubblesGroupRef.current);
      setDataPoints(props.data);
    }
  }, [props.data, dataPoints]);

  const onHover = (context: any, d: IBubbleDataPoint, isMouseIn: boolean) => {
    const mainColor = d.color || colors.primaryColor500;
    const itemBorderColor = d.borderColor || borderColor;
    const itemDisableBorderColor = d.disableBorderColor || disableBorderColor;
    const itemHoverColor = d.hoverColor || hoverColor;
    const itemDisableColor = d.disableColor || disableColor;
    const node = bubblesGroupRef.current;
    const bubble = d3.select(context);
    const leaseShadow = d3.select(`#${getLeaseBubbleShadowId(d)}`);
    const horizontalLine = d3.select(`#${getHorizontalLineId(d)}`);
    const verticalLine = d3.select(`#${getLeaseVerticalLineId(d)}`);

    if (isMouseIn) {
      select(node)
        .selectAll(`.${d.class || GraphClasses.Bubble}`)
        .attr('fill', itemDisableColor)
        .attr('stroke', itemDisableBorderColor);

      bubble.attr('fill', itemHoverColor).attr('stroke', itemBorderColor);

      if (showHorizontalLine) horizontalLine.style('display', '');
      leaseShadow.style('display', '');

      previousVerticalLineStroke = verticalLine.attr('stroke');
      previousVerticalLineOpacity = +verticalLine.attr('stroke-opacity');

      verticalLine.attr('stroke', itemHoverColor).attr('stroke-opacity', 1);

      if (hasHorizontalLines) {
        verticalLine.style('display', 'block');
      }

      showTooltip({
        circleXPosition: +bubble.attr('cx'),
        isMarketData: false,
        node: d,
        backgroundColor: itemHoverColor,
      });

      onMouseOver?.(d);
    } else {
      hideTooltip(d.graphId);
      bubble.attr('fill', mainColor);
      if (itemBorderColor) {
        bubble.attr('stroke', itemBorderColor);
      }
      select(node)
        .selectAll(`.${d.class || GraphClasses.Bubble}`)
        .attr('fill', mainColor)
        .attr('stroke', itemBorderColor);

      horizontalLine.style('display', 'none');
      leaseShadow.style('display', 'none');

      verticalLine
        .attr('stroke', previousVerticalLineStroke)
        .attr('stroke-opacity', previousVerticalLineOpacity);

      if (hasHorizontalLines) {
        verticalLine.style('display', 'none');
      }

      onMouseOut?.();
    }
  };

  useEffect(() => {
    const node = bubblesGroupRef.current;
    const leaseData = dataPoints.filter(
      (d: IBubbleDataPoint) => d.value != null && d.value > 0,
    );

    const shadowRadius = 10;
    const shadowClassName =
      leaseData?.[0]?.shadowClass || GraphClasses.BubbleShadow;
    const className = leaseData?.[0]?.class || GraphClasses.Bubble;

    select(node)
      .selectAll(`.${shadowClassName}`)
      .data(leaseData)
      .join('circle')
      .attr(
        'class',
        (d: IBubbleDataPoint) => d.shadowClass || GraphClasses.BubbleShadow,
      )
      .attr('id', getLeaseBubbleShadowId)
      .attr('cx', (d: IBubbleDataPoint) => xScale(d.date))
      .attr('cy', (d: IBubbleDataPoint) => yScale(d.value)!)
      .attr('r', (d: IBubbleDataPoint) => circleScale(d.bubbleSize!)!)
      .attr('fill', 'transparent')
      .attr('stroke-width', shadowRadius)
      .attr('stroke', (d: IBubbleDataPoint) => d.hoverColor || hoverColor)
      .attr('stroke-opacity', 0.32)
      .style('display', 'none');

    select(node)
      .selectAll(`.${className}`)
      .data(leaseData)
      .join('circle')
      .attr('class', (d: IBubbleDataPoint) => d.class || GraphClasses.Bubble)
      .attr('id', getLeaseBubbleId)
      .attr('cx', (d: IBubbleDataPoint) => xScale(d.date)!)
      .attr('cy', (d: IBubbleDataPoint) => yScale(d.value!)!)
      .attr('r', (d: IBubbleDataPoint) => circleScale(d.bubbleSize!)!)
      .attr('fill', (d: IBubbleDataPoint) =>
        disabled
          ? d.disableColor || disableColor
          : d.color || colors.primaryColor500,
      )
      .attr('stroke-width', 1)
      .attr('stroke', (d: IBubbleDataPoint) =>
        disabled
          ? d.disableBorderColor || disableBorderColor
          : d.borderColor || borderColor,
      )
      .style('cursor', 'pointer')
      .on('click', (d: IBubbleDataPoint) => props.onClick?.(d))
      .on('mouseover', function(this: any, d: IBubbleDataPoint) {
        onHover(this, d, true);
      })
      .on('mouseout', function(this: any, d: IBubbleDataPoint) {
        onHover(this, d, false);
      });

    // eslint-disable-next-line
  }, [dataPoints, disabled]);

  return <g ref={bubblesGroupRef} />;
};

export default LeaseBubbles;
