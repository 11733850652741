import { IGraphsDimensions } from 'interfaces/graphs/IGraphsDimensions';

const margins = {
  top: 32,
  right: 24,
  bottom: 10,
  left: 148,
};

const width = 1025 - margins.left - margins.right;
const height = 205;

export const dimensions: IGraphsDimensions = {
  margins,
  width,
  height,
};

export const DEFAULT_NUMBER_OF_PAST_YEARS = 20;
export const QUARTERLY_NUMBER_OF_PAST_YEARS = 5;
