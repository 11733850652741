import React, { useEffect } from 'react';
import { IdName } from 'interfaces/IdName';
import { useQuery } from '@apollo/client';
import { GET_SPACE_USES } from 'graphql/spaceUses';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { PropertyTypes } from 'constants/propertyTypes';
import { parseEnumGraphQL } from 'utils/formatters/string';

interface Props {
  containerWrapperClassName?: string;
  type?: string;
  selectedItem: IdName;
  onChange: (fieldValue: IdName | null) => void;
  onDataLoad?: (data: any) => void;
}

const SpaceUseSelector: React.FC<Props> = ({
  containerWrapperClassName,
  type,
  onChange,
  onDataLoad,
  selectedItem,
}) => {
  const { loading, data } = useQuery(GET_SPACE_USES, {
    variables: {
      search: {
        type: parseEnumGraphQL(PropertyTypes, type || ''),
      },
    },
    skip: !type,
  });

  useEffect(() => {
    if (onDataLoad) onDataLoad(data);
  }, [data, onDataLoad]);

  if (!type || loading) return null;

  return (
    <ChoiceInput
      containerWrapperClassName={containerWrapperClassName}
      selectedItem={selectedItem}
      data={data.spaceUses}
      onChange={(item?: IdName) => onChange(item || null)}
    />
  );
};

export default SpaceUseSelector;
