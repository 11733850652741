import React, { useState } from 'react';
import LeaseForm from '../LeaseForm';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  GET_LEASE_QUERY,
  UPDATE_LEASE_MUTATION,
  DELETE_LEASE_MUTATION,
} from 'graphql/leases';
import { Container } from 'components/@codelitt/ay-design-library';
import { ILease } from 'interfaces/ILease';
import {
  convertILeaseIntoILeaseInput,
  prepareLeaseInputForMutation,
} from '../converters';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import NotificationMessage from 'components/NotificationMessage';
import EditCompForm from '../EditCompForm';
import LoadingMessage from 'components/LoadingMessage';
import { removeTypenameKey } from 'utils/graphql/typename';
import { ErrorLogger } from 'services/ErrorLogger';
import { useTranslation } from 'react-i18next';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

interface Props {
  leaseId: string | undefined;
  onCancel?: () => void;
  onUpdated?: () => void;
  onDeleted?: () => void;
  title?: string;
  showDeleteButton?: boolean;
  redirectToShowCompsPage?: boolean;
}

const updatingErrorLease = 'Unexpected error updating the lease';

const LeaseEditForm: React.FC<Props> = props => {
  const { t } = useTranslation();
  const client = useApolloClient();

  const [isUpdating, setIsUpdating] = useState(false);
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState<string>('');

  const { data, loading } = useQuery<ILease>(GET_LEASE_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: props.leaseId },
    skip: !props.leaseId,
  });

  const prepareLeaseUpdate = (lease: ILeaseInput) => {
    delete lease.__typename;
    const leaseInput = removeTypenameKey(lease);

    return prepareLeaseInputForMutation(leaseInput);
  };

  const getRefetchLeaseQuery = () => {
    if (!props.redirectToShowCompsPage) {
      return [];
    }

    return [
      {
        query: GET_LEASE_QUERY,
        variables: { id: props.leaseId },
      },
    ];
  };

  const updateLease = async (lease: ILeaseInput) => {
    try {
      setIsUpdating(true);

      const preparedLease = prepareLeaseUpdate(lease);

      const { data } = await client.mutate<{
        updateLease: ILeaseInput;
      }>({
        mutation: UPDATE_LEASE_MUTATION,
        variables: {
          lease: preparedLease,
        },
        refetchQueries: getRefetchLeaseQuery(),
      });

      if (data?.updateLease?.id) {
        props.onUpdated?.();
      } else {
        ErrorLogger.log(data?.updateLease?.message || updatingErrorLease);
        setMessageError(data?.updateLease?.message || updatingErrorLease);
        setShowError(true);
        setIsUpdating(false);
      }
    } catch (e) {
      ErrorLogger.log(e as any, updatingErrorLease);
      setMessageError(updatingErrorLease);
      setShowError(true);
      setIsUpdating(false);
    }
  };

  if (loading) {
    return (
      <Container>
        <LoadingMessage />
      </Container>
    );
  }

  if (!data?.lease) {
    return null;
  }

  return (
    <>
      <EditCompForm
        comp={data.lease}
        deleteErrorMessage={t(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.unableToDeleteLease`,
        )}
        deleteQueryParams={{
          query: DELETE_LEASE_MUTATION,
          paramName: 'leaseId',
          resultName: 'deleteLease',
        }}
        headerModal={t(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.deleteLeaseComp`,
        )}
        paragraphModal={[
          `${t(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.aboutToDeleteLeaseComp`,
          )}.`,
          t(`${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.askToProceed`),
        ]}
        onDeleted={props.onDeleted}
        showDeleteButton={props.showDeleteButton}
        title={props.title}
        compForm={
          <LeaseForm
            sourceLease={data.lease}
            lease={convertILeaseIntoILeaseInput(data.lease)}
            onSubmitForm={updateLease}
            onCancel={props.onCancel}
            submitLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.update`)}
            isSubmitting={isUpdating}
          />
        }
      />

      <NotificationMessage
        show={showError}
        text={messageError}
        onClose={() => {
          setShowError(false);
        }}
      />
    </>
  );
};

export default LeaseEditForm;
