import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { colors } from '../../../../constants/colors';
import {
  GRAPH_DIMENSIONS,
  TOOLTIP_LINE_STROKE,
  XCoordinates,
  Positions,
} from '../graphConstants';
import { getHorizontalLineId } from '../nodes';
import { BubblesType } from '../types';

type Props = {
  bubbles: BubblesType;
};

const CenterLine: React.FC<Props> = props => {
  const lineGroupRef = useRef(null);
  const { bubbles } = props;
  useEffect(() => {
    const node = lineGroupRef.current;
    const centerY = GRAPH_DIMENSIONS.height / 2;

    select(node)
      .append('line')
      .attr('x1', 0)
      .attr('y1', centerY)
      .attr('x2', GRAPH_DIMENSIONS.width - GRAPH_DIMENSIONS.padding)
      .attr('y2', centerY)
      .attr('stroke', colors.primaryColor500)
      .attr('stroke-width', TOOLTIP_LINE_STROKE * 2);

    const x1 = bubbles.propertyBubble?.position
      ? XCoordinates[bubbles.propertyBubble.position]
      : XCoordinates[Positions.LEFT];

    const x2 = bubbles.propertyRenovatedYearBubble?.position
      ? XCoordinates[bubbles.propertyRenovatedYearBubble.position]
      : XCoordinates[Positions.RIGHT];

    select(node)
      .append('line')
      .attr('id', getHorizontalLineId())
      .attr('x1', x1)
      .attr('y1', centerY)
      .attr('x2', x2)
      .attr('y2', centerY)
      .attr('stroke', colors.primaryColor300)
      .attr('stroke-width', TOOLTIP_LINE_STROKE * 2)
      .attr('display', 'none');
  }, [bubbles]);

  return <g ref={lineGroupRef} />;
};
export default CenterLine;
