import React from 'react';
import VictoryBarChart from 'components/VictoryBarChart';
import { getAbsortionChartStyle } from './utils';
import { LEGENDS, TITLE } from './constants';
import { LineSegment } from 'victory';

const Y1_COORDINATE = 82.45;

type Props = {
  graphData: any;
  isQuarterly?: boolean;
  unitOfMeasurement?: string;
  barWidth?: number;
  hideOddXTickValues?: boolean;
};

const AbsorptionChart: React.FC<Props> = ({
  graphData,
  unitOfMeasurement,
  isQuarterly,
  barWidth,
  hideOddXTickValues,
}) => (
  <VictoryBarChart
    legend={LEGENDS}
    isBarHoverable
    title={TITLE}
    barData={graphData}
    gridComponent={<LineSegment y1={Y1_COORDINATE} />}
    chartStyle={getAbsortionChartStyle({
      hideOddXTickValues,
      unitOfMeasurement,
      isQuarterly,
      width: barWidth,
    })}
  />
);

export default AbsorptionChart;
