import { ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { Axis, axisBottom } from 'd3-axis';
import styles from './GraphXAxis.module.scss';
import React, { useEffect, useRef } from 'react';
import { cleanUpElement } from 'utils/d3Utils';
import { getYearFromDate } from 'utils/date';

interface Props {
  height: number;
  xScale: ScaleTime<number, number>;
  tickFormat?: (date: any, i: number) => string;
  translateX?: number;
  translateY?: number;
}

export const X_AXIS_PADDING_TOP = 4;

const GraphXAxis: React.FC<Props> = ({
  xScale,
  height,
  tickFormat,
  translateX = 0,
  translateY = X_AXIS_PADDING_TOP,
}) => {
  const axisRef = useRef(null);
  const renderAxis = () => {
    const axis: Axis<any> = axisBottom(xScale).tickFormat(
      tickFormat ? tickFormat : (date: any) => getYearFromDate(date),
    );

    select(axisRef.current)
      .append('g')
      .call(axis)
      .selectAll('text');
  };

  useEffect(() => {
    cleanUpElement(axisRef.current);
    renderAxis();
    // eslint-disable-next-line
  }, [xScale]);

  return (
    <g
      ref={axisRef}
      transform={`translate(${translateX}, ${height + translateY})`}
      className={styles['x-axis']}
    />
  );
};

export default GraphXAxis;
