import React from 'react';
import classnames from 'classnames';
import styles from './ComparativeColumns.module.scss';
import { Link } from 'react-router-dom';
import locations from 'routes';
import { getPropertyAddress, getPropertyCity } from 'utils/formatters/property';
import { DASH_PLACEHOLDER, DOT_LG } from 'constants/placeholders';
import ComparativeCard from '../ComparativeCard';
import { Column } from '../constants';
import InformationRow from './ColumnInformation/InformationRow';
import { IdName } from 'interfaces/IdName';
import AddDataButton from './AddDataButton';
import { IProperty } from 'interfaces/IProperty';
import CardImageLogo from 'components/PropertyProfile/CardImageLogo';
import AlternateAddressIconSmall from 'components/AlternateAddressIconSmall';
import StaticMapImage from 'components/PropertyMap/StaticMapImage';
import { PropertyMapSize, ZoomLevel } from 'components/PropertyMap';

export const getOwnerLinks = (property: IProperty) =>
  property.owners?.map((company: IdName, index: number) => (
    <Link
      className={classnames(styles.owner, styles['company-link'])}
      to={locations.showCompany(company.id!)}
      key={company.id}
    >
      {company.name}
      {index < property.owners!.length - 1 ? ` ${DOT_LG} ` : ''}
    </Link>
  ));

const PrimaryColumn: React.FC<Column> = ({
  property,
  columnTheme,
  onDataChanges,
}) => {
  const address = getPropertyAddress(property);
  const city = getPropertyCity(property);

  return (
    <ComparativeCard
      theme={columnTheme}
      wrapperClassName={styles['primary-information']}
    >
      <AddDataButton property={property} onDataChanges={onDataChanges} />
      {property?.coverMediaUrl ? (
        <CardImageLogo property={property} />
      ) : (
        <StaticMapImage
          wrapperClassName={styles['static-map']}
          size={PropertyMapSize.fullWidth}
          zoomLevel={ZoomLevel.small}
          property={property}
        />
      )}
      <div className={styles.body}>
        <div>
          <InformationRow>
            <Link
              className={classnames(styles.address, styles.link)}
              to={locations.showProperty(property?.id!)}
            >
              {address}
              <div className={styles['address-icon']}>
                <AlternateAddressIconSmall property={property} />
              </div>
            </Link>
          </InformationRow>

          <InformationRow>
            <span className={styles['text-info']}>
              {property?.name || DASH_PLACEHOLDER}
            </span>
            <br />
            <span className={styles['text-info']}>
              {`${city ?? ''} ${city ? DOT_LG : ''} ${property?.state ?? ''}`}
            </span>
          </InformationRow>
        </div>
      </div>
    </ComparativeCard>
  );
};

export default PrimaryColumn;
