import { ILease } from 'interfaces/ILease';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import { calculateExpirationDate, calculateTermInMonths } from './formDates';
import { fieldHasValue } from 'utils/objects';
import {
  getFieldNameForLease,
  getFieldValueForLease,
} from 'utils/unitsOfMeasurement';
import { IProperty } from 'interfaces/IProperty';
import dayjs from 'dayjs';
import { removeLocaleFormatting, truncateFloat } from 'utils/formatters/number';
import { IPropertyExpense } from 'interfaces/inputs/IPropertyExpense';
import { PropertyTypeNames } from 'constants/propertyTypes';

export const REQUIRED_FIELDS = ['property', 'tenantCompany'];

export const isSignedDateValid = (signDate: string) =>
  dayjs()
    .add(3, 'month')
    .diff(dayjs(signDate), 'day') > 0;

export const isValidForm = (
  leaseInput: ILeaseInput,
  leaseProperty?: IProperty,
) => {
  const tenantInMarketSizeMin = getFieldValueForLease(
    'tenantInMarketSizeMin',
    leaseInput,
    leaseProperty?.propertyCountry?.code || leaseProperty?.measurementSystem,
  );
  const tenantInMarketSizeMax = getFieldValueForLease(
    'tenantInMarketSizeMax',
    leaseInput,
    leaseProperty?.propertyCountry?.code || leaseProperty?.measurementSystem,
  );
  const hasRequiredFields =
    REQUIRED_FIELDS.filter(fieldStr => !!leaseInput[fieldStr]).length ===
    REQUIRED_FIELDS.length;

  if (
    fieldHasValue(tenantInMarketSizeMin) ||
    fieldHasValue(tenantInMarketSizeMax)
  ) {
    return (
      hasRequiredFields &&
      convertToNumber(tenantInMarketSizeMin)! <
        convertToNumber(tenantInMarketSizeMax)!
    );
  }

  const hasExpenseProperty = !!getExpenseFromCommencementYear(
    leaseInput,
    leaseProperty,
  );

  const { taxAndOpex, tax, opex } = leaseInput;

  if (hasExpenseProperty && !(taxAndOpex || (tax && opex))) {
    return false;
  }

  if (!!leaseInput.signDate)
    return hasRequiredFields && isSignedDateValid(leaseInput?.signDate || '');

  return hasRequiredFields;
};

export const getBrokerName = (leaseInput: ILeaseInput, brokerField: string) =>
  leaseInput?.[brokerField]?.[0]?.company || '';

export const getPropertyTypeFilter = (
  propertyType: string | undefined,
  sourceLease?: ILease,
) => PropertyTypeNames[sourceLease?.property?.propertyType?.id] || propertyType;

export const updateExpirationDateRelatedFields = (
  {
    leaseInput,
    field,
    value,
  }: { leaseInput: ILeaseInput; field: string; value: string },
  formUpdater: (key: string, value: any) => void,
) => {
  const { termInMonths, commencementDate, expirationDate } = leaseInput;
  const isCommencementDate = field === 'commencementDate';
  const isExpirationDate = field === 'expirationDate';
  const isTermInMonths = field === 'termInMonths';

  const termsInMonthsParam = isTermInMonths ? value : termInMonths;
  const commencementDateParam = isCommencementDate ? value : commencementDate;
  const expirationDateParam = isExpirationDate ? value : expirationDate;
  const expirationDateValue =
    calculateExpirationDate(
      convertToNumber(termsInMonthsParam),
      commencementDateParam,
    ) || expirationDateParam;

  const termInMothsValue =
    calculateTermInMonths(expirationDateParam, commencementDateParam) ||
    termsInMonthsParam;

  // Update only the fields related to the one that changed.
  !isCommencementDate && formUpdater('commencementDate', commencementDateParam);
  !isExpirationDate && formUpdater('expirationDate', expirationDateValue);
  !isTermInMonths && formUpdater('termInMonths', termInMothsValue);

  // Set the current field value (without any calculated value)
  formUpdater(field, value);
};

export const calculateAdditionalConcessions = ({
  additionalConcessions,
  additionalConcessionsPSF,
  leaseInput,
  measurementSystem,
  updateLeaseProperty,
}: {
  additionalConcessions?: string | number;
  additionalConcessionsPSF?: string | number;
  leaseInput: ILeaseInput;
  measurementSystem?: string;
  updateLeaseProperty: (field: string, fieldValue: any) => void;
}) => {
  const unformattedAdditionalConcessions = removeLocaleFormatting(
    additionalConcessions?.toString() || '',
  );
  const unformattedAdditionalConcessionsPSF = removeLocaleFormatting(
    additionalConcessionsPSF?.toString() || '',
  );
  const leaseSize = getFieldValueForLease(
    'size',
    leaseInput,
    measurementSystem,
  );
  if (!leaseSize) {
    return;
  }

  const totalFieldName = getFieldNameForLease(
    'additionalConcessions',
    leaseInput,
    measurementSystem,
  );
  const psfFieldName = getFieldNameForLease(
    'additionalConcessionsPSF',
    leaseInput,
    measurementSystem,
  );

  if (unformattedAdditionalConcessions) {
    updateLeaseProperty(
      psfFieldName,
      truncateFloat(
        Number(unformattedAdditionalConcessions) / Number(leaseSize),
        2,
      ),
    );
  } else if (unformattedAdditionalConcessionsPSF) {
    updateLeaseProperty(
      totalFieldName,
      truncateFloat(
        Number(unformattedAdditionalConcessionsPSF) * Number(leaseSize),
        2,
      ),
    );
  } else {
    updateLeaseProperty(totalFieldName, null);
    updateLeaseProperty(psfFieldName, null);
  }
};

export const getExpenseFromCommencementYear = (
  leaseInput: ILeaseInput,
  leaseProperty?: IProperty,
): IPropertyExpense | undefined => {
  let commencementYearExpense: IPropertyExpense | undefined;
  if (leaseProperty?.expenses) {
    const { commencementDate } = leaseInput;
    commencementYearExpense = leaseProperty.expenses.find(
      x => x.year === Number(getYearFromCommencementDate(commencementDate)),
    );
  }

  return commencementYearExpense;
};

export const getYearFromCommencementDate = (
  commencementDate?: string | null,
) => {
  return commencementDate?.split('-')[0];
};
