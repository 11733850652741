import { ISnowflakeBlockAvailabilityTimeWideResponse } from 'interfaces/ISnowflakeBlockAvailability';
export const UI_PAGE_SIZE = 5;
export const API_PAGE_SIZE = 100;

export const getAvailabilitiesByProperties = (
  availabilitiesData: ISnowflakeBlockAvailabilityTimeWideResponse,
  isNNN: boolean,
  isAnnual: boolean,
) => {
  return availabilitiesData.blockAvailabilitiesOverTimeWide.availabilitiesByProperties.map(
    availability => ({
      id: availability.propertyId,
      averageRent: isNNN
        ? isAnnual
          ? availability.askingRentNNN
          : availability.askingRentNNNMonthly
        : isAnnual
        ? availability.askingRentFS
        : availability.askingRentFSMonthly,
    }),
  );
};

export const getAverageRentData = (
  isAnnual: boolean,
  availabilitiesData: ISnowflakeBlockAvailabilityTimeWideResponse,
  isNNN: boolean,
) => {
  return isNNN
    ? isAnnual
      ? availabilitiesData.blockAvailabilitiesOverTimeWide
          .annualDirectAskingRentNNN
      : availabilitiesData.blockAvailabilitiesOverTimeWide
          .monthlyDirectAskingRentNNN
    : isAnnual
    ? availabilitiesData.blockAvailabilitiesOverTimeWide
        .annualDirectAskingRentFS
    : availabilitiesData.blockAvailabilitiesOverTimeWide
        .monthlyDirectAskingRentFS;
};

export const getPropertyAvailabilityAskingRent = (
  availabilitiesData: any,
  propertyId: number,
) => {
  const availability = availabilitiesData.find(
    (availabilityByProperty: { id: number; averageRent: number }) =>
      availabilityByProperty.id == propertyId,
  );

  return !!availability ? availability.averageRent : undefined;
};
