import { StatusTypes } from 'constants/statusTypes';
import {
  getFullYear,
  getIsBuiltInTheFuture,
} from 'components/Graphs/utils/helpers';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_CHART_LABEL_PATH } from 'constants/i18n';

const I18N_AGE_CHART_LABEL_PATH = `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.ageGraph`;

export const formatBuiltYear = (builtYear?: number): string | null => {
  if (!builtYear) return null;

  const isBuiltInTheFuture = getIsBuiltInTheFuture(builtYear);
  const yearsToDeliver = builtYear - getFullYear();

  return isBuiltInTheFuture
    ? translateText(`${I18N_AGE_CHART_LABEL_PATH}.yearsToDelivery`, {
        yearsToDeliver,
      })
    : translateText(`${I18N_AGE_CHART_LABEL_PATH}.builtYear`, { builtYear });
};

export const formatAge = (
  startYear?: number,
  propertyStatusName?: string,
): string | null => {
  const isBuiltInTheFuture = startYear && getIsBuiltInTheFuture(startYear);

  if (!startYear || isBuiltInTheFuture) {
    return null;
  }

  const age = getFullYear() - startYear;

  if (age > 0) {
    return `${age} ${translateText(
      `${I18N_AGE_CHART_LABEL_PATH}.${age > 1 ? 'yearOld_plural' : 'yearOld'}`,
    )}`;
  }

  if (!propertyStatusName || propertyStatusName === StatusTypes.Existing) {
    return translateText(`${I18N_AGE_CHART_LABEL_PATH}.lessThan1YearsOld`);
  }

  return propertyStatusName;
};

export const formatRenovatedYears = (
  renovatedYears?: number[],
): string | null => {
  if (!renovatedYears) {
    return null;
  }
  return translateText(`${I18N_AGE_CHART_LABEL_PATH}.renovatedLabel`, {
    renovatedYears,
  });
};
