import * as React from 'react';
import classnames from 'classnames';
import style from './Button.module.scss';

export type Type =
  | 'main'
  | 'secondary'
  | 'ghost'
  | 'contextual'
  | 'neutral'
  | 'inline'
  | 'delete'
  | 'light'
  | 'navigation';
export type IconPosition = 'left' | 'right' | undefined;
export type Size = 'regular' | 'small';

type PropTypes = {
  isDisabled?: boolean;
  id?: string;
  label?: any;
  name?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  wrapperClassName?: string;

  /** 'left' | 'right' */
  iconPosition?: IconPosition;

  /** 'regular' | 'small' */
  size?: Size;

  /** 'main' | 'secondary' | 'ghost' | 'contextual' | 'inline' | 'delete' | 'neutral' | 'light' */
  type?: Type;
};

const Button: React.FC<PropTypes> = (props: PropTypes) => {
  const {
    isDisabled,
    iconPosition,
    label,
    onClick,
    size,
    type,
    wrapperClassName,
  } = props;

  const iconPositionClassname = style[`${iconPosition}-icon`];
  const buttonSizeClassname = style[`${size}-size`];

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    (event.target as HTMLButtonElement).blur();
    onClick?.(event);
  };

  return (
    <button
      type="button"
      className={classnames(
        style.button,
        style[type || ''],
        { [iconPositionClassname]: iconPosition },
        { [buttonSizeClassname]: size },
        wrapperClassName,
      )}
      disabled={isDisabled}
      onClick={onClickHandler}
    >
      {label}
    </button>
  );
};

export default Button;
