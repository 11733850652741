import { translateText } from 'utils/i18n';
import { IdName } from 'interfaces/IdName';
import { LeaseRentType } from 'constants/leases';

export const getRentTypesOptions = (): IdName[] => [
  {
    id: LeaseRentType.FS,
    name: translateText('avantPlatform.attributes.lease.label.rentTypeFS'),
  },
  {
    id: LeaseRentType.NNN,
    name: translateText('avantPlatform.attributes.lease.label.rentTypeNNN'),
  },
  {
    id: LeaseRentType.MG,
    name: translateText('avantPlatform.attributes.lease.label.rentTypeMG'),
  },
  {
    id: LeaseRentType.IG,
    name: translateText('avantPlatform.attributes.lease.label.rentTypeIG'),
  },
  {
    id: LeaseRentType.NN,
    name: translateText('avantPlatform.attributes.lease.label.rentTypeNN'),
  },
  {
    id: LeaseRentType.N,
    name: translateText('avantPlatform.attributes.lease.label.rentTypeN'),
  },
];
