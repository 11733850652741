import React, { ChangeEvent, useState } from 'react';
import classnames from 'classnames';
import styles from './UploadPhoto.module.scss';
import SpinnerLoader from 'components/SpinnerLoader';
import { ColorNames } from 'constants/colorNames';
import Icon from 'components/Icon';
import { colors } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  hoveredContent?: React.ReactNode;
  imageSrc?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  isUploading?: boolean;
  placeholderIcon: string;
  wrapperClassName?: string;
}

const UploadPhoto: React.FC<Props> = ({
  handleFileChange,
  hoveredContent,
  imageSrc,
  inputRef,
  isUploading,
  placeholderIcon,
  wrapperClassName,
}) => {
  const { t } = useTranslation();
  const [isOverlayHovered, setIsOverlayHovered] = useState(false);

  return (
    <div
      className={classnames(styles.container, wrapperClassName)}
      onMouseEnter={() => setIsOverlayHovered(true)}
      onMouseLeave={() => setIsOverlayHovered(false)}
    >
      <input
        id="upload-photo-input"
        type="file"
        onChange={handleFileChange}
        accept="image/x-png,image/jpeg,image/jpg"
        className={styles['upload-image-input']}
        ref={inputRef}
      />
      {!isUploading && imageSrc && (
        <img alt="" src={imageSrc} className={styles['profile-image']} />
      )}
      <div
        className={classnames(styles['image-overlay'], {
          [styles['has-image']]: !!imageSrc,
          [styles['image-empty']]: !imageSrc,
          [styles['is-uploading']]: isUploading,
          [styles['hovered-container']]: isOverlayHovered && !!hoveredContent,
        })}
      >
        {isUploading ? (
          <>
            <SpinnerLoader background={ColorNames.ayWhiteColor} />
            <p className={classnames(styles['image-overlay-text'])}>
              {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.uploading`)}
            </p>
          </>
        ) : (
          <>
            <div className={styles['image-overlay-icon']}>
              <Icon
                name={placeholderIcon}
                size={1.5}
                color={
                  isOverlayHovered
                    ? colors.primaryColor100
                    : colors.primaryColor400
                }
              />
            </div>
            {!imageSrc && (
              <p className={styles['upload-photo-label']}>
                {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.uploadPicture`)}
              </p>
            )}
            <p
              className={classnames(styles['image-overlay-text'], {
                [styles['image-empty']]: !imageSrc,
              })}
            >
              {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.maxPicSize`)}
            </p>
            <p
              className={classnames(styles['image-overlay-text'], {
                [styles['image-empty']]: !imageSrc,
              })}
            >
              {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.jpegOrPng`)}
            </p>
          </>
        )}
      </div>
      {isOverlayHovered && hoveredContent}
    </div>
  );
};

export default UploadPhoto;
