import React, { useEffect, useState } from 'react';
import { Container } from 'components/@codelitt/ay-design-library';
import Pictures from 'components/ModalMedia/Pictures';
import Documents from 'components/ModalMedia/Documents';
import Tabs from 'components/Tabs';
import styles from 'components/ModalMedia/ModalMedia.module.scss';
import ModalWrapper from 'components/ModalWrapper';
import { IMediaUploadQueryConfig } from 'components/ModalMedia/types';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { MediaTabNames } from 'constants/media';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { MediaNotesProvider } from 'contexts/MediaNoteContext';
import { GET_PROPERTY_DOCUMENTS_QUERY } from 'graphql/property';
import { lockScroll, unlockScroll } from 'utils/scroll';
import { translateText } from 'utils/i18n';
import { I18N_MODAL_MEDIA_DOCUMENT_LABEL_PATH } from './constants';
import { getDocumentRefetchQueries } from './utils';

type ModalProps = {
  activeTab?: MediaTabNames;
  hasCategories?: boolean;
  onCloseModal: () => void;
  onDataChanges?: () => void;
  queryConfig: IMediaUploadQueryConfig;
  profileType?: PROFILE_TYPES;
  header?: React.ReactNode;
};

const ModalMedia: React.FC<ModalProps> = ({
  activeTab,
  hasCategories = true,
  onCloseModal,
  onDataChanges,
  queryConfig,
  profileType,
  header,
}) => {
  const [activeTabName, setActiveTabName] = useState<string>(
    activeTab || MediaTabNames.pictures,
  );

  useEffect(() => {
    lockScroll();
    return unlockScroll;
  }, [queryConfig]);

  const picturesTab = {
    name: MediaTabNames.pictures,
    header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.photosAndVideos`),
    content: (
      <MediaNotesProvider>
        <Pictures
          queryConfig={queryConfig}
          onDataChanges={onDataChanges}
          hasCategories={hasCategories}
          profileType={profileType}
        />
      </MediaNotesProvider>
    ),
  };

  const documentsTab = {
    name: MediaTabNames.documents,
    header: translateText(`${I18N_MODAL_MEDIA_DOCUMENT_LABEL_PATH}.header`),
    content: (
      <Documents
        queryConfig={queryConfig}
        property={queryConfig.property}
        pursuit={queryConfig.pursuit}
        listDocumentQuery={
          queryConfig.listDocumentQuery?.query || GET_PROPERTY_DOCUMENTS_QUERY
        }
        documentRefetchQueries={
          queryConfig.documentRefetchQueries ||
          getDocumentRefetchQueries(queryConfig.property?.id)
        }
      />
    ),
  };

  return (
    <ModalWrapper
      fullScreen
      withCloseButton
      withCloseLabel
      withOpacity
      onCancel={onCloseModal}
    >
      <Container wrapperClassName={styles.container}>
        {header}
        <Tabs
          tabs={
            // @TODO - allow documents for Submarkets when the BE is ready
            queryConfig.property
              ? [picturesTab, documentsTab]
              : queryConfig.pursuit
              ? [documentsTab]
              : [picturesTab]
          }
          activeTabName={activeTabName}
          onActiveTabChange={tab => setActiveTabName(tab.name)}
        />
      </Container>
    </ModalWrapper>
  );
};

export default ModalMedia;
