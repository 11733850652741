import React from 'react';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import FormControl from 'components/FormControl';
import { translateText } from 'utils/i18n';
import TextInput from 'components/Inputs/TextInput';
import { REGEX_INTEGERS } from 'constants/regex';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

import styles from '../../../FormSection.module.scss';

type Props = {
  leaseInput: ILeaseInput;
  updateLeaseProperty: (property: string, propertyValue: any) => void;
};
const IndustrialFields: React.FC<Props> = ({
  leaseInput,
  updateLeaseProperty,
}) => {
  return (
    <>
      <FormControl
        wrapperClassName={styles['form-row']}
        label={translateText(
          'avantPlatform.attributes.property.label.loadingDocks',
        )}
      >
        <TextInput
          id={'loadingDocks'}
          pattern={REGEX_INTEGERS}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          onChange={value => updateLeaseProperty('loadingDocks', value)}
          value={leaseInput?.loadingDocks}
        />
      </FormControl>

      <FormControl
        wrapperClassName={styles['form-row']}
        label={translateText(
          'avantPlatform.attributes.property.label.driveInDoors',
        )}
      >
        <TextInput
          id={'driveInDoors'}
          pattern={REGEX_INTEGERS}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          onChange={value => updateLeaseProperty('driveInDoors', value)}
          value={leaseInput?.driveInDoors}
        />
      </FormControl>

      <FormControl
        wrapperClassName={styles['form-row']}
        label={translateText(
          'avantPlatform.attributes.property.label.parkingSpots',
        )}
      >
        <TextInput
          id={'parkingSpaces'}
          pattern={REGEX_INTEGERS}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          onChange={value => updateLeaseProperty('parkingSpaces', value)}
          value={leaseInput?.parkingSpaces}
        />
      </FormControl>

      <FormControl
        wrapperClassName={styles['form-row']}
        label={translateText(
          'avantPlatform.attributes.property.label.trailerParkingSpaces',
        )}
      >
        <TextInput
          id={'trailerParkingSpaces'}
          pattern={REGEX_INTEGERS}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          onChange={value => updateLeaseProperty('trailerParkingSpaces', value)}
          value={leaseInput?.trailerParkingSpaces}
        />
      </FormControl>
    </>
  );
};

export default IndustrialFields;
