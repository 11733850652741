import { IBubbleDataPoint } from 'components/Graphs/BubbleChartsElements/interfaces';

export const getMarketVerticalLineId = (node: any) => {
  return `vertical-line-mkt-${node.graphId}-${node.key}`;
};

export const getMarketBubbleId = (node: IBubbleDataPoint) => {
  return `market-bubble-${node.key}`;
};

export const getMarketBubbleShadowId = (node: IBubbleDataPoint) => {
  return `market-bubble-shadow-${node.key}`;
};
