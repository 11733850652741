import React, { useContext, useState } from 'react';
import ModalEditComp from 'components/ModalEditComp';
import { CompType } from 'constants/compType';
import OptionsContainer from 'components/OptionsContainer';
import { upperWords, splitUppercase } from 'utils/formatters/string';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { getTranslatedCompLabel } from 'utils/comps';

interface Props {
  compId: number;
  isDark?: boolean;
  type: CompType;
  onUpdate?: () => void;
  onDelete?: () => void;
  wrapperClassName?: string;
}

const CardDropdown: React.FC<Props> = ({
  compId,
  isDark,
  onUpdate,
  onDelete,
  type,
  wrapperClassName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { hasEditorAccess } = useContext(subscriptionsContext);

  const dropdownOptions = [
    {
      type: 'edit',
      shouldShowIcon: true,
      onClick: () => setIsModalOpen(true),
      label: `${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.edit`,
      )} ${upperWords(splitUppercase(getTranslatedCompLabel(type)))}`,
    },
  ];

  return (
    <>
      {hasEditorAccess ? (
        <OptionsContainer
          isDark={isDark}
          isMedium
          options={dropdownOptions}
          wrapperClassName={wrapperClassName}
          optionButtonLabel="···"
        />
      ) : null}
      {isModalOpen && (
        <ModalEditComp
          type={type}
          compId={compId}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onModalToggle={setIsModalOpen}
        />
      )}
    </>
  );
};

export default CardDropdown;
