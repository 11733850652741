import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import {
  abbreviateNumber,
  formatNumberPercentage,
  formatNumberWithDecimals,
} from 'utils/formatters/number';
import { translateText } from 'utils/i18n';
import { capitalize } from 'lodash';
import { formatCurrency } from 'utils/formatters/currency';
import { IPropertySetLeasingMetadata } from 'interfaces/IPropertySetMetadata';
import { EMPTY_VALUE } from 'components/AgencyAssignments/constants';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { isMonthlyTimeMeasurement } from 'utils/leases';

export const parseInfoBoxesData = (
  {
    numberOfBuildings,
    numberOfTenants,
    totalInventory,
    occupiedPercent,
    walt,
    ...leaseMetadata
  }: IPropertySetLeasingMetadata,
  unitOfMeasurement: string,
  { rentType, timeMeasurement }: LeaseToggleOptions,
  askingRentData?: Record<string, number | undefined>,
) => {
  const askingRent = {
    askingRentFS: askingRentData?.annualTotalAskingRentFS,
    askingRentFSMonthly: askingRentData?.monthlyTotalAskingRentFS,
    askingRentNNN: askingRentData?.annualTotalAskingRentNNN,
    askingRentNNNMonthly: askingRentData?.monthlyTotalAskingRentNNN,
  } as Partial<IPropertySetLeasingMetadata>;

  const combinedLeaseMetaData = { ...leaseMetadata, ...askingRent };
  const isMonthly = isMonthlyTimeMeasurement(timeMeasurement);
  const fieldSuffix = `${rentType}${isMonthly ? timeMeasurement : ''}`;
  const placeRentValue = combinedLeaseMetaData[`placeRent${fieldSuffix}`];
  const askingRentValue = combinedLeaseMetaData[`askingRent${fieldSuffix}`];

  return [
    {
      id: 'number-of-properties',
      title: '',
      details: [
        {
          title: capitalize(
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`),
          ),
          value: numberOfBuildings ? numberOfBuildings.toString() : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'inventory-size',
      title: '',
      details: [
        {
          title: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.inventory`),
          value: totalInventory
            ? `${abbreviateNumber(
                totalInventory,
              ) as string} ${unitOfMeasurement}`.toUpperCase()
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'total-occupied',
      title: '',
      details: [
        {
          title: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.occupied`),
          value: occupiedPercent
            ? formatNumberPercentage(occupiedPercent)
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'total-tenants',
      title: '',
      details: [
        {
          title: capitalize(
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenant_plural`),
          ),
          value: numberOfTenants ? numberOfTenants.toString() : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'total-walt',
      title: '',
      details: [
        {
          title: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.walt`,
          ).toUpperCase(),
          value: walt ? formatNumberWithDecimals(walt, 1) : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'avg-in-place-rent',
      title: '',
      details: [
        {
          title: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.avgInPlaceRent`,
          ),
          value: placeRentValue
            ? `${formatCurrency(placeRentValue, {})}`
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'asking-rent-fs',
      title: '',
      details: [
        {
          title: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.askingRent`),
          value: askingRentValue
            ? `${formatCurrency(askingRentValue, {})}`
            : EMPTY_VALUE,
        },
      ],
    },
  ];
};
