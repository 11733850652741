import React from 'react';
import classnames from 'classnames';
import { isArray } from 'lodash';

import styles from './LabeledData.module.scss';
import { LabeledDataProps } from './types';

const renderData = (
  data: LabeledDataProps['data'],
  separator: LabeledDataProps['separator'],
) => {
  if (!isArray(data)) {
    return data;
  }

  return data.map((datum, index, array) =>
    index === array.length - 1 ? datum : [datum, separator],
  );
};

const LabeledData = ({
  label,
  data,
  separator = ', ',
  dataInBold,
  inline,
  className,
  labelClassName,
  dataWrapperClassName,
}: LabeledDataProps) => (
  <div className={classnames(styles['labeled-data'], className)}>
    <span className={classnames(styles.label, labelClassName)}>{label}</span>
    <div
      className={classnames(styles['data-wrapper'], dataWrapperClassName, {
        [styles.inline]: inline,
        [styles['data-in-bold']]: dataInBold,
      })}
    >
      {renderData(data, separator)}
    </div>
  </div>
);

export default LabeledData;
