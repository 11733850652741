export enum LeaseTypeEnum {
  ASSIGNMENT = 'ASSIGNMENT',
  DIRECT = 'DIRECT',
  LICENSE = 'LICENSE',
  SUBLEASE = 'SUBLEASE',
  COWORKING = 'COWORKING',
  SHELL = 'SHELL',
  TURN_KEY = 'TURN_KEY',
}

export enum LeaseBaseRentTime {
  Monthly = 'Monthly',
  Annual = 'Annual',
}

export enum EscalationType {
  Percentage = '%',
  Currency = 'currency',
  IndexLinked = 'index-linked',
  OpenMarketRent = 'open-market-rent',
}

export enum EscalationIncrement {
  FLAT = 'FLAT',
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
  EVERY2YRS = 'EVERY2YRS',
  EVERY3YRS = 'EVERY3YRS',
  EVERY4YRS = 'EVERY4YRS',
  EVERY5YRS = 'EVERY5YRS',
  EVERY6YRS = 'EVERY6YRS',
  EVERY7YRS = 'EVERY7YRS',
  EVERY8YRS = 'EVERY8YRS',
  EVERY9YRS = 'EVERY9YRS',
  EVERY10YRS = 'EVERY10YRS',
}

export enum LeaseRentType {
  FS = 'FS',
  IG = 'IG',
  NNN = 'NNN',
  NN = 'NN',
  N = 'N',
  MG = 'MG',
}

export enum LeaseRentPaymentDetails {
  FRI = 'FRI',
  IRI = 'IRI',
}

export const RentPaymentDetailsOptionsForUK = [
  {
    id: LeaseRentPaymentDetails.FRI,
    name: LeaseRentPaymentDetails.FRI,
  },
  {
    id: LeaseRentPaymentDetails.IRI,
    name: LeaseRentPaymentDetails.IRI,
  },
];

export enum LeaseTransactionType {
  NEW = 'NEW',
  EXPANSION = 'EXPANSION',
  RENEWAL = 'RENEWAL',
}

export enum LeaseRecordType {
  LEASE_COMPS = 'LEASE_COMPARABLES',
  LEASE_EXPIRATIONS = 'EXPIRATIONS',
  ALL_TENANTS = 'TENANT_LOCATION',
}

export enum LeaseFinancialStatusType {
  CONFIRMED = 'CONFIRMED',
  ESTIMATED = 'ESTIMATED',
}

export enum LeaseTenantInMarketType {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
}

export enum LeaseConfidentialType {
  yes = 'Yes',
  no = 'No',
}

export enum LeaseTenantInMarketSubtype {
  RENEWED = 'RENEWED',
  RELOCATED = 'RELOCATED',
  CLOSING_LOCATION = 'CLOSING_LOCATION',
  SEEKING_BROKER = 'SEEKING_BROKER',
  TOURING = 'TOURING',
  NEGOTIATING = 'NEGOTIATING',
}

const { RENEWED, RELOCATED, CLOSING_LOCATION } = LeaseTenantInMarketSubtype;

export const TENANT_NOT_IN_MARKET_VALUES = {
  [RENEWED as LeaseTenantInMarketSubtype]: 'Renewed',
  [RELOCATED as LeaseTenantInMarketSubtype]: 'Relocated',
  [CLOSING_LOCATION as LeaseTenantInMarketSubtype]: 'Closing Location',
};

export const LEASES_INITIAL_STATE = {
  data: null,
  isLoading: false,
  queryVariables: undefined,
};

export const LEASES_PAGE_SIZE = 50;

export enum LeasePeriodType {
  ANNUAL = 'ANNUAL',
  QUARTER = 'QUARTER',
}
