export enum VerticalBarChartsIds {
  PropertyPage = 'vertical-bar-chart-property-page',
  DevelopmentTimeline = 'vertical-bar-chart-development-timeline',
  HistoricalSalesVolume = 'vertical-bar-chart-historical-sales-volume',
  MarketAnalyticsHistoricalSalesVolume = 'vertical-bar-chart-market-analytics-historical-sales-volume',
  SubmarketLeasingConcessions = 'vertical-bar-chart-submarket-leasing-concessions',
  MarketAnalyticsConcessions = 'vertical-bar-chart-market-analytics-concessions',
  SalesPricingCapRate = 'sales-pricing-cap-rate',
  AbsorptionChart = 'absorption-chart',
  LeasesAnalyticsConcessions = 'vertical-bar-chart-leases-analytics-concessions',
  AgencyLeasingConcessions = 'chart-leases-analytics-concessions',
  PropertyAnalyticsDeliveredChart = 'property-analytics-delivered-chart',
  PropertyAnalyticsAbsorptionChart = 'property-analytics-absorption-chart',
  SalesAnalyticsTransactionVolume = 'sales-analytics-transaction-volume',
  DeliveryTimeline = 'delivery-timeline-chart',
  HistoricalDemand = 'vertical-bar-chart-historical-demand',
}
