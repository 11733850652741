import React from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import ModalWrapper from 'components/ModalWrapper';
import VerticalFormControl from 'components/VerticalFormControl';
import TextInput from 'components/Inputs/TextInput';
import usePropertySet from 'hooks/usePropertySet';
import { CompetitivePropertySelector } from 'components/CompetitivePropertySelector';
import { ConfirmationModalFooter } from 'components/ConfirmationModalFooter';
import { Container } from 'components/@codelitt/ay-design-library';
import { PropertySetTypes } from 'constants/propertySetTypes';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

import { AgencyLeasingModalFormProps } from './types';
import styles from './AgencyLeasingModalForm.module.scss';
import locations from '../../routes';

const AgencyLeasingModalForm = ({
  initialPropertySet = null,
  onChange,
  onSubmit,
  onCancel,
  refetchQueries,
  ...modalWrapperProps
}: AgencyLeasingModalFormProps) => {
  const history = useHistory();
  const isEditing = !!initialPropertySet?.id;
  const {
    propertySet,
    updatePropertySet,
    submitPropertySet,
    isSubmitting,
  } = usePropertySet(initialPropertySet, {
    isEditing,
    onChange,
    onSubmit,
    refetchQueries,
    type: PropertySetTypes.AGENCY_ASSIGNMENT,
  });

  const handleSubmit = async () => {
    const { data } = await submitPropertySet();
    const newPropertySet = isEditing
      ? data?.updatePropertySet
      : data?.createPropertySet;

    onSubmit?.(newPropertySet!);
    history.push(locations.showAgencyAssignments(newPropertySet?.id!));
    onCancel();
  };

  const modalTitle = `${
    isEditing ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.edit`) : ''
  } ${translateText(
    `${I18N_AVANT_PROPERTY_LABEL_PATH}.leasingAssignment.agencyLeasingAssignment`,
  )}`;

  return (
    <>
      <ModalWrapper
        fullScreen
        withCloseLabel
        withCloseButton
        onCancel={onCancel}
        footer={
          <ConfirmationModalFooter
            saveButtonLabel={
              isEditing
                ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.update`)
                : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.create`)
            }
            isDisabled={!propertySet?.name || isEmpty(propertySet.properties)}
            isSubmitting={isSubmitting}
            onCancel={onCancel}
            onSave={handleSubmit}
          />
        }
        footerWrapperClassName={styles['footer-wrapper']}
        {...modalWrapperProps}
      >
        <Container>
          <h2 className={styles.title}>{modalTitle.trimStart()}</h2>
          <div>
            <VerticalFormControl
              wrapperClassName={styles['modal-row']}
              label={`${translateText(
                `${I18N_AVANT_PROPERTY_LABEL_PATH}.leasingAssignment.assignmentName`,
              )}:`}
            >
              <TextInput
                fullWidth
                id="assignment-name"
                inputClassName={styles['leasing-assignment-name']}
                value={propertySet?.name}
                placeholder={translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.enterName`,
                )}
                onChange={(name: string) => updatePropertySet('name', name)}
              />
            </VerticalFormControl>
            <CompetitivePropertySelector
              className={styles['modal-row']}
              properties={propertySet?.properties}
              propertiesToOmit={
                propertySet?.targetProperty?.id
                  ? [propertySet?.targetProperty?.id]
                  : []
              }
              label={`${translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.selectProperty_plural`,
              )}:`}
              propertyIdForRecommendations={propertySet?.targetProperty?.id}
              onChange={properties =>
                updatePropertySet?.('properties', properties)
              }
            />
          </div>
        </Container>
      </ModalWrapper>
    </>
  );
};

export { AgencyLeasingModalForm };
