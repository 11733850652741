import React, { useState } from 'react';
import styles from './DropdownCheckboxInput.module.scss';
import classnames from 'classnames';
import ArrowDownImage from '../../../assets/images/icons/dropdown-arrow-down.svg';
import ArrowUpImage from '../../../assets/images/icons/dropdown-arrow-up.svg';
import { IdName } from '../../../interfaces/IdName';
import DropdownCheckboxInputSubItems from './DropdownCheckboxInputSubItems';

type PropTypes = {
  label: string;
  data: IdName[];
  selectedSubItems?: IdName[];
  isSelected: boolean;
  isPartiallySelected: boolean;
  onClick: (shouldAddItem: boolean) => void;
  onClickSubItem: (subItem: IdName) => void;
  isDisabled?: boolean;
  itemWrapperClassName?: string;
};

const DropdownCheckboxInputItem: React.FC<PropTypes> = props => {
  const {
    label,
    data,
    selectedSubItems,
    isSelected,
    isPartiallySelected,
    onClick,
    onClickSubItem,
    isDisabled,
    itemWrapperClassName,
  } = props;
  const [isActive, setIsActive] = useState(false);

  const arrowImageSrc = isActive ? ArrowUpImage : ArrowDownImage;

  const onSelect = (event: React.MouseEvent, hasBeenSelected: boolean) => {
    event.stopPropagation();
    onClick(hasBeenSelected);
  };

  return (
    <div
      className={classnames(
        styles['checkbox-group-item-container'],
        itemWrapperClassName,
        {
          [styles.disabled]: isDisabled,
          [styles.selected]: isSelected,
          [styles['partially-selected']]: isPartiallySelected,
          [styles.active]: isActive,
        },
      )}
    >
      <div
        className={classnames(styles['checkbox-group-item'], {
          [styles.active]: isActive,
        })}
        title={label}
        onClick={() => !isDisabled && setIsActive(!isActive)}
      >
        <div
          className={classnames(styles.checkbox, {
            [styles.checked]: isSelected,
            [styles['partially-checked']]: isPartiallySelected,
          })}
          onClick={event =>
            !isDisabled && onSelect(event, !isPartiallySelected && !isSelected)
          }
        />
        <div className={styles.label}>{label}</div>
        <div className={styles['arrow-container']}>
          <img className={styles.arrow} src={arrowImageSrc} alt="" />
        </div>
      </div>
      <div
        className={classnames(styles['sub-items-options'], {
          [styles.active]: isActive,
        })}
      >
        <DropdownCheckboxInputSubItems
          data={data}
          selectedSubItems={selectedSubItems}
          onClick={subItem => {
            onClickSubItem(subItem);
          }}
        />
      </div>
    </div>
  );
};

export default DropdownCheckboxInputItem;
