import { gql } from '@apollo/client';

export const PROPERTIES_ANALYTICS_PROPERTY_AGES = gql`
  query propertiesAnalyticsPropertyAges($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsPropertyAges(search: $search) {
      average
      slices {
        text
        count
        size
        percentage
        topItems
        measurementSystem
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_PROPERTY_SUBTYPES = gql`
  query propertiesAnalyticsPropertySubtypes($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsPropertySubtypes(search: $search) {
      items {
        text
        count
        sizeMt
        size
        percentage
        topItems
        measurementSystem
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_PROPERTY_SIZES = gql`
  query propertiesAnalyticsPropertySizes($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsPropertySizes(search: $search) {
      items {
        text
        size
        sizeMt
        percentage
        count
        topItems
        measurementSystem
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_PROPERTY_CLASSES = gql`
  query propertiesAnalyticsPropertyClasses($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsPropertyClasses(search: $search) {
      classItems {
        text
        size
        sizeMt
        percentage
        count
        topItems
        measurementSystem
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_OWNERS = gql`
  query propertiesAnalyticsOwners($search: PropertiesAnalyticsOwnersInput) {
    propertiesAnalyticsOwners(search: $search) {
      summary {
        size
        sizeMt
        totalOwners
      }
      slices {
        name
        size
        sizeMt
        numberOfBuildings
        numberOfOwners
        percentage
        largestPropertyIds
      }
    }
  }
`;
export const PROPERTIES_ANALYTICS_TENANTS_INDUSTRIES = gql`
  query propertiesAnalyticsTenantsIndustries(
    $search: PropertiesAnalyticsInput
  ) {
    propertiesAnalyticsTenantsIndustries(search: $search) {
      summary {
        numberOfIndustries
        totalTenants
        size
        sizeMt
      }
      slices {
        industry
        size
        sizeMt
        floors
        tenants
        percentage
        topItems
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_ABSORPTION = gql`
  query propertiesAnalyticsAbsorption($search: PropertiesAnalyticsRangeInput) {
    propertiesAnalyticsAbsorption(search: $search) {
      year
      absorptionSf
      absorptionSm
      quarter
    }
  }
`;

export const PROPERTIES_ANALYTICS_ASSET_PERFORMANCE = gql`
  query propertiesAnalyticsAssetPerformance(
    $search: PropertiesAnalyticsAssetPerformanceInput
  ) {
    propertiesAnalyticsAssetPerformance(search: $search) {
      items {
        year
        quarter
        date
        availableSFDirect
        availableSFSublet
        availableSMDirect
        availableSMSublet
        vacantSFDirect
        vacantSFSublet
        vacantSMDirect
        vacantSMSublet
        occupiedSF
        occupiedSFPercentage
        vacantSFDirectPercentage
        vacantSMDirectPercentage
        vacantSFSubletPercentage
        vacantSMSubletPercentage
        availableSFDirectPercentage
        availableSMDirectPercentage
        availableSFSubletPercentage
        availableSMSubletPercentage
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_HISTORICAL_ASSET_PERFORMANCE = gql`
  query propertiesAnalyticsHistoricalAssetPerformance(
    $search: PropertiesAnalyticsHistoricalAssetPerformanceInput
  ) {
    propertiesAnalyticsHistoricalAssetPerformance(search: $search) {
      items {
        className
        year
        quarter
        date
        availableSFDirect
        availableSFSublet
        availableSMDirect
        availableSMSublet
        vacantSFDirect
        vacantSFSublet
        vacantSMDirect
        vacantSMSublet
        occupiedSF
        occupiedSFPercentage
        vacantSFDirectPercentage
        vacantSMDirectPercentage
        vacantSFSubletPercentage
        vacantSMSubletPercentage
        availableSFDirectPercentage
        availableSMDirectPercentage
        availableSFSubletPercentage
        availableSMSubletPercentage
        totalBuildingSizeExisting
        totalBuildingSizeExistingMt
        totalBuildingSizeAvailable
        totalBuildingSizeAvailableMt
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_LARGEST_SUBMARKETS = gql`
  query propertiesAnalyticsLargestSubmarkets(
    $search: PropertiesAnalyticsLargestSubmarketsInput!
  ) {
    propertiesAnalyticsLargestSubmarkets(search: $search) {
      items {
        id
        name
        vacantSFDirect
        vacantSFDirectPercentage
        vacantSFSublet
        vacantSFSubletPercentage
        vacantSMDirect
        vacantSMDirectPercentage
        vacantSMSublet
        vacantSMSubletPercentage
        occupiedSF
        occupiedSM
        occupiedSMPercentage
        occupiedSFPercentage
        totalBuildingsSizeMtNotInDevelopment
        totalBuildingsSizeNotInDevelopment
        totalVacantSfNotInDevelopment
        totalVacantSmNotInDevelopment
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_LARGEST_SUBMARKETS_ABSORPTION = gql`
  query propertiesAnalyticsLargestSubmarkets(
    $search: PropertiesAnalyticsLargestSubmarketsInput!
  ) {
    propertiesAnalyticsLargestSubmarkets(search: $search) {
      items {
        id
        name
        totalBuildingsSize
        absorptionSF
        absorptionSM
        totalBuildingsSizeNotInDevelopment
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_COMP_SET_HISTORICAL_ASKING_RENTS = gql`
  query propertiesAnalyticsCompSetHistoricalAskingRents(
    $search: PropertiesAnalyticsCompSetHistoricalAskingRentsInput
  ) {
    propertiesAnalyticsCompSetHistoricalAskingRents(search: $search) {
      topItems
      avgCompSetValueFS
      avgCompSetValueFSMonthly
      avgCompSetValueNNN
      avgCompSetValueNNNMonthly
      propertyValueFS
      propertyValueFSMonthly
      propertyValueNNN
      propertyValueNNNMonthly
      quarter
      year
    }
  }
`;

export const PROPERTIES_ANALYTICS_SUBMARKETS_SIZE = gql`
  query propertiesAnalyticsSubmarketsSize(
    $search: PropertiesAnalyticsSubmarketSizeInput
  ) {
    propertiesAnalyticsSubmarketsSize(search: $search) {
      summary {
        size
        sizeMt
        totalBuildings
        totalSubmarkets
      }
      slices {
        id
        name
        size
        sizeMt
        numberOfBuildings
        percentage
        topItems
      }
    }
  }
`;

export const PROPERTY_ANALYTICS_ASKING_RENTS = gql`
  query propertiesAnalyticsAskingRents(
    $search: PropertiesAnalyticsAskingRentsInput
  ) {
    propertiesAnalyticsAskingRents(search: $search) {
      className
      year
      quarter
      avgValueFS
      avgValueFSMonthly
      avgValueNNN
      avgValueNNNMonthly
    }
  }
`;
