import { IdName } from 'interfaces/IdName';

export const filterItemsByName = (
  searchedText: string,
  items: Partial<IdName>[],
) =>
  searchedText
    ? items.filter(
        ({ name }) =>
          name && name.toLowerCase().includes(searchedText.toLowerCase()),
      )
    : items;
