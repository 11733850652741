import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { IPropertyLookupInput } from 'interfaces/inputs/IPropertyLookupInput';
import { convertObjectNumericFields } from 'utils/parsers/convertObjectNumericFields';
import { removeCommaFromNumber } from 'utils/formatters/number';

const sanitizePriorAddress = (obj: IPropertyLookupInput | undefined) => {
  if (!obj) return;

  const newPriorAddress = {
    id: obj.id,
    name: obj.name,
    primaryAddress: obj.primaryAddress,
  };
  return newPriorAddress;
};

export const processLeaseBeforeSubmit = (input: ILeaseInput): ILeaseInput => {
  let lease: ILeaseInput = { ...input };

  // Do not submit the currencyCode and measurementSystem
  delete input.currencyCode;
  delete input.measurementSystem;
  delete input.property?.propertyTypeId;

  delete input.priorAddress?.currencyCode;

  lease.priorAddress = sanitizePriorAddress(input.priorAddress);

  lease.securityDepositType = lease?.securityDepositType
    ? { id: lease.securityDepositType.id }
    : null;

  lease = convertObjectNumericFields(lease, [
    'baseRent',
    'baseRentPerKw',
    'freeRentMonths',
    'opex',
    'size',
    'sizeMt',
    'tax',
    'taxAndOpex',
    'priorSize',
    'tenantImprovementAllowance',
    'tenantInMarketSizeMax',
    'tenantInMarketSizeMaxMt',
    'tenantInMarketSizeMin',
    'tenantInMarketSizeMinMt',
    'termInMonths',
    'netEffectiveRentCustom',
    'additionalConcessions',
    'securityDeposit',
  ]);

  lease.leaseOptions = lease.leaseOptions?.map(l => {
    delete l.id;
    return {
      ...l,
      leaseId: lease.id,
      expansionSf: l.expansionSf
        ? +removeCommaFromNumber(String(l.expansionSf))
        : null,
      breakOptionPenalty: l.breakOptionPenalty
        ? +removeCommaFromNumber(String(l.breakOptionPenalty))
        : null,
    };
  });

  return lease;
};
