import * as Sentry from '@sentry/react';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import { isTruePositiveError } from 'utils/sentry';

export class ErrorLogger {
  public static log(errorMessage: string, displayedMessage?: string) {
    console.error(errorMessage, displayedMessage);
    const appServiceStatus = localStorage.getItem(
      LocalStorageKeys.appServicesStatus,
    );
    if (
      isTruePositiveError(errorMessage, displayedMessage) &&
      !!appServiceStatus &&
      appServiceStatus === 'true'
    ) {
      try {
        Sentry.withScope(function(scope) {
          scope.setTag('type', 'error_caught');
          scope.setExtra('customMessage', displayedMessage || errorMessage);
          Sentry.captureException(new Error(errorMessage));
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
}
