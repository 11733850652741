import React from 'react';
import classnames from 'classnames';

import Breadcrumb from 'base-components/Breadcrumb';

import styles from './AgencyLeasingHeader.module.scss';
import { AgencyLeasingHeaderProps } from './types';

const AgencyLeasingHeader = ({
  className,
  breadcrumbClassName,
  breadcrumbLevels,
  actionContent,
}: AgencyLeasingHeaderProps) => (
  <div className={classnames(styles['agency-leasing-header'], className)}>
    {breadcrumbLevels && (
      <Breadcrumb className={breadcrumbClassName} levels={breadcrumbLevels} />
    )}
    {actionContent}
  </div>
);

export default AgencyLeasingHeader;
