import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './MultiColumnDropdownFilter.module.scss';
import { DocumentNode } from '@apollo/client';
import DropdownFilterItems from './DropdownFilterItems';
import { ApiClients } from 'constants/apiClients';
import { ColorNames } from 'constants/colorNames';
import { IdName } from 'interfaces/IdName';
import Icon from 'components/Icon';
import { colors } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export type Props = {
  borderColor?: ColorNames;
  clientSideFilter?: (searchedText: string, items: any[]) => any[];
  fullWidth?: boolean;
  customWidth?: boolean;
  graphqlClient?: ApiClients;
  graphqlQuery: DocumentNode;
  graphqlVariables?: (searchText?: string) => {};
  groupBySubfield?: string;
  isDisabled?: boolean;
  isFilterActive?: boolean;
  isMultipleSelect?: boolean;
  isRequired?: boolean;
  skipQuery?: boolean;
  itemRenderer?: (item: any, searchedText?: string) => JSX.Element;
  label: string;
  labelClassName?: string;
  mapper: (queryResultData: any) => any[];
  noBorderOnValueSelected?: boolean;
  onChange: (newValue: any, data?: any) => void;
  onChangeFullOptionObject?: (value: any) => void;
  placeholder?: string;
  renderFooterSelection?: boolean;
  selectedValue?: any;
  isSearchVisible?: boolean;
  verticalLabel?: string;
  wrapperClassName?: string;
  itemsClassName?: string;
  shouldShowInput?: boolean;
  selectAllOption?: {
    value: string;
    isVisible: boolean;
    selectAllByDefault?: (allItems: IdName[]) => void;
  };
  actionButton?: {
    value: string;
    isVisible: boolean;
    isDisabled: boolean;
    onClick?: () => void;
  };
  getSuggestedOptions?: (items: any) => void;
  optionsLabels?: {
    all: string;
    suggested: string;
  };
  groupItemsPathParams?: {
    sortByField: string;
    displaySortedField: string;
  };
};

const MultiColumnDropdownFilter: React.FC<Props> = ({
  label,
  mapper,
  onChange,
  onChangeFullOptionObject,
  fullWidth,
  customWidth,
  isDisabled,
  borderColor,
  placeholder,
  graphqlQuery,
  itemRenderer,
  isSearchVisible,
  actionButton,
  graphqlClient,
  selectedValue,
  verticalLabel,
  isFilterActive,
  groupBySubfield,
  shouldShowInput,
  selectAllOption,
  clientSideFilter,
  graphqlVariables,
  isMultipleSelect,
  wrapperClassName,
  itemsClassName,
  labelClassName,
  noBorderOnValueSelected = false,
  isRequired,
  skipQuery,
  getSuggestedOptions,
  optionsLabels,
  groupItemsPathParams,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState('');
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const resultsContainerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const iconName = isActive ? 'arrowUp' : 'arrowDown';
  const iconColor = isDisabled ? colors.ayGrey64Color : colors.primaryColor500;

  const hasValue =
    (!isMultipleSelect && !!selectedValue) ||
    (isMultipleSelect && !!selectedValue?.length);
  const borderColorClass =
    styles[`border-${borderColor || ColorNames.supportiveColor500}`];

  useEffect(() => {
    if (isFilterActive) {
      setIsActive(isFilterActive);
    }
  }, [isFilterActive]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        resultsContainerRef.current &&
        filterButtonRef.current &&
        !resultsContainerRef.current.contains(event.target) &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resultsContainerRef, filterButtonRef]);

  return (
    <>
      {verticalLabel && (
        <p className={styles['vertical-label']}>
          {verticalLabel}
          {isRequired && (
            <span role="note">
              {' '}
              {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.required`)}
            </span>
          )}
        </p>
      )}
      <div
        className={classnames(styles.container, {
          [styles['full-width']]: fullWidth,
          [styles['custom-width']]: customWidth,
        })}
      >
        <button
          ref={filterButtonRef}
          disabled={isDisabled}
          className={classnames(
            styles.button,
            borderColorClass,
            wrapperClassName,
            {
              [styles.active]: isActive,
              [styles['with-value-selected']]:
                !noBorderOnValueSelected && hasValue,
              [styles.disabled]: isDisabled,
              [styles['full-width']]: fullWidth,
              [styles['custom-width']]: customWidth,
            },
          )}
          onClick={e => {
            if (isActive) e.currentTarget.blur();
            !isDisabled && setIsActive(currentValue => !currentValue);
          }}
        >
          {value?.length ? (
            <span className={classnames(styles['container-text'])}>
              {value}
            </span>
          ) : (
            <span className={labelClassName}>{label}</span>
          )}
          <Icon
            name={iconName}
            color={iconColor}
            size={0.8}
            className={styles.icon}
          />
        </button>

        <div ref={resultsContainerRef}>
          <DropdownFilterItems
            mapper={mapper}
            isVisible={isActive}
            fullWidth={fullWidth}
            placeholder={placeholder}
            graphqlQuery={graphqlQuery}
            itemRenderer={itemRenderer}
            actionButton={actionButton}
            onActionButtonClick={() => setIsActive(false)}
            graphqlClient={graphqlClient}
            selectedValue={selectedValue}
            isSearchVisible={isSearchVisible}
            shouldShowInput={shouldShowInput}
            groupBySubfield={groupBySubfield}
            clientSideFilter={clientSideFilter}
            isMultipleSelect={isMultipleSelect}
            graphqlVariables={graphqlVariables}
            selectAllOption={selectAllOption}
            wrapperClassName={itemsClassName}
            setTextValue={setValue}
            skipQuery={skipQuery}
            onChange={(newValue, data) => {
              onChange(newValue, data);
              !isMultipleSelect && setIsActive(false);
            }}
            onChangeFullOptionObject={onChangeFullOptionObject}
            getSuggestedOptions={getSuggestedOptions}
            optionsLabels={optionsLabels}
            groupItemsPathParams={groupItemsPathParams}
          />
        </div>
      </div>
    </>
  );
};

export default MultiColumnDropdownFilter;
