import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

// DayJS plugins
export const applyDayJSPlugins = () => {
  if (dayjs.utc === undefined) {
    dayjs.extend(quarterOfYear);
    dayjs.extend(utc);
    dayjs.extend(relativeTime);
    dayjs.extend(advancedFormat);
  }
};
