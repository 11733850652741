import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { IProperty } from 'interfaces/IProperty';
import { HierarchyNode } from 'd3';
import styles from './Bubble.module.scss';
import GraphContainer from '../GraphContainer';
import { formatArea } from 'utils/formatters/area';
import { colors } from 'constants/colors';
import {
  GRAPH_DIMENSIONS,
  CIRCLE_WRAPPER,
  SVG_ID,
  GraphClasses,
} from './graphConstants';
import TooltipLine from './Elements/TooltipLine';
import { getBubbleId, getSvgRoot } from './nodes';
import { useHistory } from 'react-router-dom';
import locations from 'routes';
import Tooltip, { updateTooltipPosition } from './Elements/Tooltip';
import {
  IGraphBuildingSizeResponse,
  IGraphBuildingSizeProperty,
} from 'interfaces/graphs/buildingSize';
import {
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
  isUsingMeters,
} from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { DOT } from 'constants/placeholders';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_ATTR_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

interface Props {
  data: IGraphBuildingSizeResponse;
  property: IProperty;
  title: string;
}

type BubbleGraphDataType = {
  name: string;
  totalFloors: number;
  buildingSize: number;
  children: IGraphBuildingSizeProperty[];
};

const BUBBLE_MIN_RADIUS = 2;
const BUBBLE_MAX_RADIUS = 28;

const BubbleGraph: React.FC<Props> = ({ property, data, title }) => {
  const history = useHistory();
  const graphContainerElement = useRef(null);

  const [isReady, setIsReady] = useState(false);
  const [graphData, setGraphData] = useState<BubbleGraphDataType | null>(null);
  const [activeTooltipBubbleNode, setActiveTooltipBubbleNode] = useState<any>();
  const [activeTooltipPropertyId, setActiveTooltipPropertyId] = useState<
    number | undefined
  >();

  const showFloors = !!property?.stories;
  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  useEffect(() => {
    const properties = [...data.properties];
    const hasCurrentProperty = properties.some(p => p.id === property.id);

    if (!hasCurrentProperty) {
      const propertyItem: IGraphBuildingSizeProperty = {
        id: property.id!,
        buildingSize: property.buildingSize || 0,
        buildingSizeMt: property.buildingSizeMt || 0,
        stories: property.stories!,
        propertyTypeName: property.propertyType?.name,
        name: property.name!,
        status: property.status?.name,
        market: property.market?.name,
        submarket: property.submarket?.name,
        micromarket: property.micromarket?.name,
        propertyClass: property.propertyClass?.name,
        primaryAddress: property.primaryAddress!,
        measurementSystem:
          property?.propertyCountry?.code! || property?.measurementSystem!,
      };

      properties.splice(Math.ceil(properties.length / 2), 0, propertyItem);
    }

    setGraphData({
      name: title,
      totalFloors: data.totalStories,
      buildingSize: isUsingMeters(unitOfMeasurement)
        ? data.totalBuildingSizeMt
        : data.totalBuildingSize,
      children: properties,
    });
    setIsReady(true);
  }, [data, unitOfMeasurement, property, title]);

  const buildDataTree = () => {
    if (!graphData) {
      return;
    }

    const packLayout = d3
      .pack()
      .size([
        GRAPH_DIMENSIONS.width - GRAPH_DIMENSIONS.padding,
        GRAPH_DIMENSIONS.height - GRAPH_DIMENSIONS.padding,
      ])
      .padding(GRAPH_DIMENSIONS.padding);

    const rootNode = d3
      .hierarchy(graphData)
      .sum((d: BubbleGraphDataType) => d.buildingSize!)
      // this acts like a compareTo function, so it must return zero, a positive or a negative value.
      .sort((a: HierarchyNode<IProperty>, b: HierarchyNode<IProperty>) => {
        if (b.data.id === property.id) {
          return -1;
        } else if (a.data.id === property.id) {
          return 1;
        }
        return b.value! - a.value!;
      });

    return packLayout(rootNode);
  };

  const getDistanceBetweenPoints = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  ) => {
    const a = x1 - x2;
    const b = y1 - y2;
    return Math.sqrt(a * a + b * b);
  };

  const onMouseOverBubble = (
    d: any,
    hoveringMktLegends = false,
    currentBubble = null,
  ) => {
    if (!hoveringMktLegends && !d.children && d.data.id !== property.id) {
      setActiveTooltipPropertyId(d.data?.id);
      setActiveTooltipBubbleNode(currentBubble);
      updateTooltipPosition(currentBubble);
    }
    if (d.data!.buildingSize! === null) return;
    const bubble = d3.select(`#${getBubbleId(d.data)}`);
    const lineTop = d3.select(`.${GraphClasses.TooltipTopLine}`);
    const lineBottom = d3.select(`.${GraphClasses.TooltipBottomLine}`);

    const blueBubble = d3.select(`#${getBubbleId(property)}`);
    const innerBubble = d3.select(`#${CIRCLE_WRAPPER.innerId}`);

    if (d.data?.id !== property.id) {
      const hoveredBubble = d3.select(`#${getBubbleId(d.data, true)}`);
      hoveredBubble.attr('fill', colors.ayPureWhiteColor);
    }

    if (!blueBubble.empty() && !innerBubble.empty()) {
      const blueBubbleX = +blueBubble.attr('cx');
      const blueBubbleY = +blueBubble.attr('cy');

      const innerBubbleX = +innerBubble.attr('cx');
      const innerBubbleY = +innerBubble.attr('cy');

      const distanceFromCenter = getDistanceBetweenPoints(
        blueBubbleX,
        blueBubbleY,
        innerBubbleX,
        innerBubbleY,
      );

      const distanceFromSide = Math.abs(
        +innerBubble.attr('r') - distanceFromCenter,
      );
      // Update the line Y1, in order to put the blue line inside the blue bubble
      lineTop.attr('y1', distanceFromSide + +blueBubble.attr('r') + 2);
    }

    if (hoveringMktLegends || d.children) {
      d3.select(`#${CIRCLE_WRAPPER.outerId}`).attr('display', '');
      innerBubble.attr('fill-opacity', CIRCLE_WRAPPER.fillOpacityActive);
      lineBottom.attr('display', '');
    } else {
      if (d.data.id === property.id) {
        lineTop.attr('display', '');
        bubble.attr('display', '');
      }
    }
  };

  const onMouseOutBubble = (d: any, hoveringMktLegends = false) => {
    setActiveTooltipPropertyId(undefined);
    setActiveTooltipBubbleNode(undefined);
    d3.select('#building-size-tooltip').style('display', 'none');
    const bubble = d3.select(`#${getBubbleId(d.data)}`);
    const lineTop = d3.select(`.${GraphClasses.TooltipTopLine}`);
    const lineBottom = d3.select(`.${GraphClasses.TooltipBottomLine}`);
    if (d.data?.id !== property.id) {
      const hoveredBubble = d3.select(`#${getBubbleId(d.data, true)}`);
      hoveredBubble.attr('fill', colors.supportive500);
    }

    if (hoveringMktLegends || d.children) {
      d3.select(`#${CIRCLE_WRAPPER.outerId}`).attr('display', 'none');
      d3.select(`#${CIRCLE_WRAPPER.innerId}`).attr(
        'fill-opacity',
        CIRCLE_WRAPPER.fillOpacity,
      );
      lineBottom.attr('display', 'none');
    } else {
      d.data.id === property.id && lineTop.attr('display', 'none');
      bubble.attr('display', 'none');
    }
  };

  /**
   * This function rotate the SVG in order to put the blue bubble at the top.
   */
  const rotateSVG = () => {
    const blueBubble = d3.select(`#${getBubbleId(property)}`);
    const outerCircle = d3.select(`#${CIRCLE_WRAPPER.outerId}`);

    //center of the circle
    const p1x = +outerCircle.attr('cx');
    const p1y = +outerCircle.attr('cy');

    // destination coordinates
    const p2x = +outerCircle.attr('cx');
    const p2y = GRAPH_DIMENSIONS.padding + +blueBubble.attr('r');

    // blue bubble origin coordinates
    const p3x = +blueBubble.attr('cx');
    const p3y = +blueBubble.attr('cy');

    const getDistance = (x1: number, x2: number, y1: number, y2: number) => {
      return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
    };

    const p12 = getDistance(p1x, p2x, p1y, p2y);
    const p13 = getDistance(p1x, p3x, p1y, p3y);
    const p23 = getDistance(p2x, p3x, p2y, p3y);
    const p1AngleRadian = Math.acos(
      (p12 ** 2 + p13 ** 2 - p23 ** 2) / (2 * p12 * p13),
    );
    const p1AngleDegrees = (p1AngleRadian * 180) / Math.PI;

    if (!Number.isNaN(p1AngleDegrees)) {
      const direction = p3x > p1x ? -1 : 1;

      d3.select('#' + SVG_ID).attr('transform', () => {
        return 'rotate(' + direction * p1AngleDegrees + ')';
      });
    }
  };

  const getBubbleRadiusBetweenLimits = (
    radius: number,
    minRadius: number,
    maxRadius: number,
  ) => {
    return Math.min(Math.max(radius, minRadius), maxRadius);
  };

  /**
   * The "outer" bubbles are responsible for the Halo effect.
   *
   * Their radius are slightly higher than the inner bubbles, so when they become visible they act
   * like a shadow for the inner bubble.
   */
  const drawOuterBubbles = (circlesRoot: any) => {
    circlesRoot
      .append('circle')
      .attr('r', (d: any) => {
        if (d.children) {
          return d.r + GRAPH_DIMENSIONS.hoverStrokeWidth;
        }
        return d.r
          ? getBubbleRadiusBetweenLimits(
              d.r,
              BUBBLE_MIN_RADIUS,
              BUBBLE_MAX_RADIUS,
            )
          : 0;
      })
      .attr('stroke', (d: any) =>
        d.data?.id === property.id
          ? colors.primaryColor400
          : colors.ayPearGreenColor,
      )
      .attr('id', (d: any) => {
        if (d.children) {
          return CIRCLE_WRAPPER.outerId;
        }
        return getBubbleId(d.data);
      })
      .attr('stroke-width', (d: any) => (d.children ? 10 : 8))
      .attr('stroke-opacity', 0.32)
      .attr('fill', 'transparent')
      .attr('display', 'none')
      .attr('cx', (d: any) => {
        return d.x + GRAPH_DIMENSIONS.padding;
      })
      .attr('cy', (d: any) => {
        return d.y + GRAPH_DIMENSIONS.padding;
      })
      .style('z-index', '2');
  };

  /**
   * The inner bubbles are the bubbles visible in the graph.
   */
  const drawInnerBubbles = (svgRoot: any, circlesRoot: any) => {
    circlesRoot
      .append('circle')
      .attr('id', (d: any) => {
        if (d.children) {
          return CIRCLE_WRAPPER.innerId;
        }
        return getBubbleId(d.data, true);
      })
      .attr('r', (d: any) => {
        if (d.children) {
          return d.r;
        }
        return d.r
          ? getBubbleRadiusBetweenLimits(
              d.r,
              BUBBLE_MIN_RADIUS,
              BUBBLE_MAX_RADIUS,
            )
          : 0;
      })
      .attr('cx', (d: any) => {
        return d.x + GRAPH_DIMENSIONS.padding;
      })
      .attr('cy', (d: any) => {
        return d.y + GRAPH_DIMENSIONS.padding;
      })
      .attr('stroke', (d: any) => (d.children ? colors.supportive500 : ''))
      .attr('stroke-width', (d: any) =>
        d.children ? CIRCLE_WRAPPER.strokeWidth : 0,
      )
      .attr('fill-opacity', (d: any) =>
        d.children ? CIRCLE_WRAPPER.fillOpacity : 1,
      )
      .attr('fill', (d: any) => {
        if (d.data.id === property.id) {
          return colors.primaryColor500;
        } else {
          return colors.supportive500;
        }
      })
      .attr('cursor', (d: any) =>
        !d.children && d.data.id !== property.id ? 'pointer' : '',
      )
      .on('mouseover', function(this: any, d: any) {
        onMouseOverBubble(d, false, this);
      })
      .on('mouseout', (d: any) => {
        onMouseOutBubble(d);
      })
      .on('click', (d: any) => {
        if (!d.children && d.data.id !== property.id) {
          history.push(locations.showProperty(d.data.id));
        }
      });
  };

  const drawChart = () => {
    const rootNodeDataTree = buildDataTree();

    const svgRoot = getSvgRoot();

    const circlesRoot = svgRoot
      .selectAll('g')
      .data(
        d3
          .nest()
          .key((d: any) => d.id)
          .entries(rootNodeDataTree!.descendants()),
      )
      .join('g')
      .selectAll('g')
      .data(d => d.values)
      .join('g');

    drawOuterBubbles(circlesRoot);
    drawInnerBubbles(svgRoot, circlesRoot);

    rotateSVG();
    return svgRoot.node();
  };

  useEffect(() => {
    if (isReady && !!graphData?.buildingSize && graphData?.children?.length) {
      drawChart();
    }
    // eslint-disable-next-line
  }, [isReady]);

  const getTotalFloors = () =>
    showFloors &&
    `${formatArea(
      graphData!.totalFloors,
      translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.floor`, {
        count: graphData!.totalFloors,
      }),
    )} ${DOT} `;

  return (
    <GraphContainer wrapperClassName={styles.container}>
      <Tooltip
        property={
          data.properties.filter(p => p.id === activeTooltipPropertyId)[0]
        }
        onContentChanged={() => updateTooltipPosition(activeTooltipBubbleNode)}
      />
      <div
        className={styles['title-container']}
        onMouseOver={() =>
          onMouseOverBubble({ data: { id: property.id! } }, false)
        }
        onMouseOut={() => onMouseOutBubble({ data: { id: property.id! } })}
      >
        <p className={styles['title']}>
          {translateText(
            `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.label.buildingSize`,
          )}
        </p>
        <p className={styles['size']}>
          {formatArea(
            Math.ceil(getFieldValueForProperty('buildingSize', property)!),
            unitOfMeasurement,
          )}
        </p>
        {showFloors && (
          <p className={styles['floors']}>
            {formatArea(
              property.stories!,
              translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.floor`, {
                count: property.stories,
              }),
            )}
          </p>
        )}
      </div>

      <div className={styles['graph-container']} ref={graphContainerElement}>
        <TooltipLine showFloors={showFloors} />
        <svg
          id={SVG_ID}
          className={styles['svg-graph']}
          width={GRAPH_DIMENSIONS.width + GRAPH_DIMENSIONS.padding}
          height={GRAPH_DIMENSIONS.height + GRAPH_DIMENSIONS.padding}
        />
      </div>

      <div
        className={styles['footer-container']}
        onMouseOver={() => onMouseOverBubble({ data: {} }, true)}
        onMouseOut={() => onMouseOutBubble({ data: {} }, true)}
      >
        <p className={styles['title']}>{title}</p>
        <p className={styles['size']}>
          {graphData && formatArea(graphData.buildingSize, unitOfMeasurement)}
        </p>
        {graphData && (
          <p className={styles['floors']}>
            {getTotalFloors()}
            {graphData!.children.length}
            {` ${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.building`, {
              count: graphData!.children.length,
            })} `}
          </p>
        )}
      </div>
    </GraphContainer>
  );
};

export default BubbleGraph;
