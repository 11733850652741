import React from 'react';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import LoadingMessage from 'components/LoadingMessage';
import InfoBoxes from 'components/InfoBoxes';
import styles from './KPIS.module.scss';
import { usePropertySetCompositionMetadata } from 'hooks/usePropertySetCompositionMetadata';
import { parseInfoBoxesData } from '../utils';
import { IInfoBox } from 'components/InfoBoxes/constants';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';

interface Props {
  propertySetId: number;
}

const KPIs: React.FC<Props> = ({ propertySetId }) => {
  const {
    propertySetCompositionMetadataResponse,
    isLoading,
  } = usePropertySetCompositionMetadata(propertySetId);

  const parsedData =
    propertySetCompositionMetadataResponse &&
    (parseInfoBoxesData(
      propertySetCompositionMetadataResponse,
      UnitOfMeasurement.sf,
    ) as IInfoBox[]);

  return isLoading ? (
    <LoadingMessage />
  ) : (
    <Row wrapperClassName={styles.row}>
      <Col>
        <InfoBoxes data={parsedData!} textAlign="center" />
      </Col>
    </Row>
  );
};

export default KPIs;
