import React from 'react';
import styles from './ModalInformation.module.scss';
import { ColorNames } from 'constants/colorNames';
import ModalWrapper from 'components/ModalWrapper';
import Button from 'components/Button/new';
import classNames from 'classnames';

type ModalProps = {
  buttonLabel?: string;
  header: string;
  information: string;
  onClose: () => void;
  wrapperClassName?: string;
  buttonWrapperClassName?: string;
};

const ModalInformation: React.FC<ModalProps> = ({
  buttonLabel,
  header,
  information,
  onClose,
  wrapperClassName,
  buttonWrapperClassName,
}) => {
  return (
    <ModalWrapper
      withCloseButton
      modalBackgroundColor={ColorNames.ayWhiteColor}
      onCancel={onClose}
      wrapperClassName={wrapperClassName}
    >
      <div className={styles.container}>
        <header className={styles.header}>{header}</header>
        <p className={styles.information}>{information}</p>
        <Button
          wrapperClassName={classNames(
            styles['ok-button'],
            buttonWrapperClassName,
          )}
          type="main"
          size="m"
          label={buttonLabel}
          onClick={onClose}
        />
      </div>
    </ModalWrapper>
  );
};

export default ModalInformation;
