import { isNil } from 'lodash';
import { VisibilityLayer } from 'components/MapboxMap/constants';
import { IProperty } from 'interfaces/IProperty';
import mapboxgl from 'mapbox-gl';
import { colors } from 'constants/colors';
import { IAmenitiesPOILocation, IAmenityPOI } from 'interfaces/IAmenityPOI';

export const DEFAULT_PIN_COLOR = colors.supportive500;
export const TARGET_PIN_COLOR = colors.primaryOnDark;

interface Locatable {
  id?: number | string;
  latitude?: number | null;
  latitudeDisplay?: number | null;
  longitude?: number | null;
  longitudeDisplay?: number | null;
}

type Coordinate = {
  latitude?: number | null;
  longitude?: number | null;
};

interface IAmenityGeoJSONProperties {
  label?: number;
  amenitiesLocation?: IAmenitiesPOILocation;
}

export interface IPropertyGeoJSONProperties {
  buildingSize?: number;
  label?: number | string;
  propertyId?: number;
  targetPropertyId?: number;
  pinLabelFn?: (properties: IProperty[], propertyId: number) => number | string;
  onClickPropertyPin?: (propertyId: string | null) => void;
  onMouseEnterPropertyPin?: (propertyId?: number) => void;
}

export const getFeature = (
  coordinates: Coordinate,
  featureProps?: GeoJSON.GeoJsonProperties,
): GeoJSON.Feature<GeoJSON.Point> | null => {
  const { latitude, longitude } = coordinates;

  if (isNil(latitude) || isNil(longitude)) {
    return null;
  }

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    },
    properties: featureProps || null,
  };
};

export const getGeoJSON = <V extends Locatable>(
  values: V[],
  getProperties?: (value: V) => GeoJSON.GeoJsonProperties,
): GeoJSON.FeatureCollection<GeoJSON.Point> => ({
  type: 'FeatureCollection',
  features: values
    .map(value => {
      const coordinates: Coordinate = {
        latitude: value.latitudeDisplay || value.latitude,
        longitude: value.longitudeDisplay || value.longitude,
      };

      return getFeature(coordinates, getProperties?.(value));
    })
    .filter(Boolean) as GeoJSON.Feature<
    GeoJSON.Point,
    GeoJSON.GeoJsonProperties
  >[],
});

export const generateMarkers = (
  marker: GeoJSON.Feature<GeoJSON.Point, IPropertyGeoJSONProperties | null>,
  isPinClickable?: boolean,
  isPinOpaque = true,
  pinType = PinTypes.default,
) => {
  if (!marker) {
    return null;
  }

  const pinCustomType = document.createElement('div');
  pinCustomType.style.cursor = 'pointer';
  pinCustomType.innerHTML = PIN_TYPES[pinType];

  const pin = new mapboxgl.Marker({
    ...(pinType === PinTypes.default
      ? {
          color: DEFAULT_PIN_COLOR,
          rotation: 0,
        }
      : {
          element: pinCustomType,
        }),
  });

  if (isPinOpaque) {
    pin.getElement().style.opacity = '0.8';
  }

  if (marker.properties?.label && !marker.properties?.targetPropertyId) {
    const innerCircles = pin.getElement().getElementsByTagName('circle');
    for (let i = 0; i < innerCircles.length; i++) {
      innerCircles[i].style.fill = DEFAULT_PIN_COLOR;
    }

    const pinLabelContainer = getPinLabelByPinType(
      marker.properties?.label,
      pinType,
    );

    pin.getElement().setAttribute('data-value', `${marker.properties?.label}`);

    pin.getElement().appendChild(pinLabelContainer);
  }

  if (marker.properties?.targetPropertyId) {
    const innerCircles = pin.getElement().getElementsByTagName('circle');
    for (let i = 0; i < innerCircles.length; i++) {
      innerCircles[i].style.fill = TARGET_PIN_COLOR;
    }

    getTargetPropertyPinByPinType(
      pin.getElement(),
      pinType,
      marker.properties.targetPropertyId,
      marker.properties.label,
    );
  }

  marker.properties?.propertyId &&
    pin
      .getElement()
      .setAttribute('data-property-id', `${marker.properties?.propertyId}`);

  if (isPinClickable && marker?.properties?.onClickPropertyPin) {
    pin.getElement().addEventListener('click', () => {
      marker?.properties?.onClickPropertyPin?.(
        `${marker.properties?.propertyId}`,
      );
    });
  }

  if (marker?.properties?.onMouseEnterPropertyPin) {
    pin.getElement().addEventListener('mouseenter', () => {
      marker?.properties?.onMouseEnterPropertyPin?.(
        marker.properties?.propertyId,
      );
    });
    pin.getElement().addEventListener('mouseleave', () => {
      marker?.properties?.onMouseEnterPropertyPin?.(undefined);
    });
  }

  return pin;
};

export const setPropertiesPinsVisibility = (visibility: VisibilityLayer) => {
  const pins = document.getElementsByClassName(
    'mapboxgl-marker mapboxgl-marker-anchor-center',
  );
  const visible = visibility === VisibilityLayer.none ? 'hidden' : 'visible';
  for (let i = 0; i < pins.length; i++) {
    (pins[i] as HTMLDivElement).style.visibility = visible;
  }
};

export const getGeoJsonFeaturesCollection = (
  features: any,
): mapboxgl.AnySourceData => ({
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features,
  },
});

export interface MapBoxContent {
  id: string;
  type: 'layer' | 'source';
}

export const removeLayerSourceFromMap = (
  map: mapboxgl.Map,
  contents: MapBoxContent[],
) => {
  contents.map(c => {
    if (c.type === 'layer' && map.getLayer(c.id)) {
      map.removeLayer(c.id);
    }

    if (c.type === 'source' && map.getSource(c.id)) {
      map.removeSource(c.id);
    }
  });
};

export const getBoundsFromGeometryCoords = (
  singleCoords: mapboxgl.LngLatLike[][],
) => {
  const coords = singleCoords[0];
  return coords.reduce((bounds: any, coord: any) => {
    return bounds.extend(coord);
  }, new mapboxgl.LngLatBounds(coords[0], coords[0]));
};

export const getBoundsFromGeometryMultiCoords = (
  multiCoords: mapboxgl.LngLatLike[][],
) => {
  const bounds = new mapboxgl.LngLatBounds();
  multiCoords.forEach((coords: any) => {
    bounds.extend(getBoundsFromGeometryCoords(coords));
  });
  return bounds;
};

export const getBoundsFromGeometry = (geometry: any) => {
  let bounds: any = null;
  switch (geometry.type) {
    case 'MultiPolygon':
      bounds = getBoundsFromGeometryMultiCoords(geometry.coordinates);
      break;
    case 'Polygon':
    default:
      bounds = getBoundsFromGeometryCoords(geometry.coordinates);
  }
  return bounds;
};

export const getMapCenterLatLong = (geometry: any) =>
  new mapboxgl.LngLatBounds()
    .extend(getBoundsFromGeometry(geometry))
    .getCenter();

interface GenerateMarkersForAmenitiesProps {
  marker: GeoJSON.Feature<GeoJSON.Point, IAmenityGeoJSONProperties | null>;
  getAmenityPinColorByLocation: (
    amenitiesLocation: IAmenitiesPOILocation,
  ) => string;
  getAmenityPinColor: (amenity: IAmenityPOI) => string;
  getPinValueByLocation: (amenitiesLocation: IAmenitiesPOILocation) => string;
  getPinValue: (amenity: IAmenityPOI) => string;
  getPinId: (amenity: IAmenityPOI) => string;
  isOutOfTopFive: (amenitiesLocation?: IAmenitiesPOILocation) => boolean;
}

export const generateMarkersForAmenities = ({
  marker,
  getAmenityPinColorByLocation,
  getAmenityPinColor,
  getPinValueByLocation,
  getPinValue,
  getPinId,
  isOutOfTopFive,
}: GenerateMarkersForAmenitiesProps) => {
  const amenitiesLocation = marker.properties?.amenitiesLocation!;
  const AMENITY_COLOR = getAmenityPinColorByLocation(amenitiesLocation!);
  const isMultipleAmenities =
    !!amenitiesLocation?.amenities.length &&
    amenitiesLocation?.amenities.length > 1;

  if (isOutOfTopFive(amenitiesLocation)) {
    const outOfTopFivePinContainer = document.createElement('div');
    outOfTopFivePinContainer.style.width = '0.5rem';
    outOfTopFivePinContainer.style.height = '0.5rem';
    outOfTopFivePinContainer.style.borderRadius = '0.25rem';
    outOfTopFivePinContainer.style.backgroundColor = AMENITY_COLOR;
    const outOfTopFivePin = new mapboxgl.Marker({
      element: outOfTopFivePinContainer,
    });

    return outOfTopFivePin;
  }

  const pin = new mapboxgl.Marker({
    color: AMENITY_COLOR,
    rotation: 0,
  });

  const innerCircles = pin.getElement().getElementsByTagName('circle');
  for (let i = 0; i < innerCircles.length; i++) {
    innerCircles[i].style.fill = AMENITY_COLOR;
  }

  pin.getElement().style.cursor = 'pointer';
  pin.getElement().style.opacity = '0.8';

  const pinLabelContainer = document.createElement('div');
  pinLabelContainer.style.width = '27px';
  pinLabelContainer.style.display = 'flex';
  pinLabelContainer.style.justifyContent = 'center';
  pinLabelContainer.style.alignItems = 'center';
  pinLabelContainer.style.margin = '-2.375rem 0 0 0';
  pinLabelContainer.style.position = 'absolute';
  pinLabelContainer.style.cursor = 'pointer';

  if (isMultipleAmenities) {
    pinLabelContainer.style.width = '20px';
    pinLabelContainer.style.margin = '-2.8rem 15px 0px';
    pinLabelContainer.style.borderRadius = '10px';
    pinLabelContainer.style.backgroundColor = colors.uiColorBlack;
  }

  const pinLabelValue = getPinValueByLocation(amenitiesLocation);

  const pinLabel = document.createElement('label');
  pinLabel.innerText = pinLabelValue;
  pinLabel.style.color = colors.ayWhiteColor;
  pinLabel.style.fontSize = '0.8125rem';

  pinLabelContainer.appendChild(pinLabel);

  pin.getElement().setAttribute('data-value', pinLabelValue);
  pin.getElement().appendChild(pinLabelContainer);

  amenitiesLocation?.amenities &&
    amenitiesLocation?.amenities.forEach(amenity => {
      pin.getElement().setAttribute('data-amenity-id', getPinId(amenity));
    });

  if (isMultipleAmenities) {
    const pinMultiplesAmenitiesToolTip = document.createElement('div');
    pinMultiplesAmenitiesToolTip.style.padding = '0.5rem 0.25rem';
    pinMultiplesAmenitiesToolTip.style.display = 'flex';
    pinMultiplesAmenitiesToolTip.style.justifyContent = 'space-between';
    pinMultiplesAmenitiesToolTip.style.alignItems = 'center';
    pinMultiplesAmenitiesToolTip.style.backgroundColor = colors.ayWhiteColor;
    pinMultiplesAmenitiesToolTip.style.margin = '-5rem 0 0 -3.2rem';
    pinMultiplesAmenitiesToolTip.style.position = 'absolute';
    pinMultiplesAmenitiesToolTip.style.cursor = 'pointer';
    pinMultiplesAmenitiesToolTip.style.borderRadius = '0.25rem';

    amenitiesLocation?.amenities &&
      amenitiesLocation?.amenities.forEach(amenity => {
        const pinAmenityToolTipContent = document.createElement('div');
        pinAmenityToolTipContent.style.width = '1.5rem';
        pinAmenityToolTipContent.style.display = 'flex';
        pinAmenityToolTipContent.style.justifyContent = 'center';
        pinAmenityToolTipContent.style.alignItems = 'center';
        pinAmenityToolTipContent.style.borderRadius = '0.25rem';
        pinAmenityToolTipContent.style.margin = '0 0.25rem';
        pinAmenityToolTipContent.style.backgroundColor = getAmenityPinColor(
          amenity,
        );
        const pinAmenityLabel = document.createElement('label');
        pinAmenityLabel.innerText = getPinValue(amenity);
        pinAmenityLabel.style.color = colors.ayWhiteColor;
        pinAmenityLabel.style.fontSize = '0.8125rem';

        pinAmenityToolTipContent.appendChild(pinAmenityLabel);
        pinMultiplesAmenitiesToolTip.appendChild(pinAmenityToolTipContent);
      });

    pin.getElement().addEventListener('mouseenter', () => {
      pin.getElement().appendChild(pinMultiplesAmenitiesToolTip);
    });
    pin.getElement().addEventListener('mouseleave', () => {
      pin.getElement().removeChild(pinMultiplesAmenitiesToolTip);
    });
  }

  return pin;
};

export const getPinLabelByPinType = (
  label: string | number,
  pinType: PinTypes,
) => {
  const pinLabelContainer = document.createElement('div');
  pinLabelContainer.style.display = 'flex';
  pinLabelContainer.style.justifyContent = 'center';
  pinLabelContainer.style.alignItems = 'center';
  pinLabelContainer.style.position = 'absolute';
  pinLabelContainer.style.cursor = 'pointer';

  const pinLabel = document.createElement('label');
  pinLabel.innerText = `${label}` || '';
  pinLabel.style.color = colors.ayWhiteColor;
  pinLabel.style.fontSize = '0.8125rem';
  pinLabel.style.cursor = 'pointer';

  switch (pinType) {
    case PinTypes.balloon:
      pinLabelContainer.style.margin = '-2.8rem 0 0 0';
      pinLabelContainer.style.width = '2.5rem';
      break;
    case PinTypes.purpleBalloon:
      pinLabelContainer.style.margin = '-2.8rem 0 0 0';
      pinLabelContainer.style.width = '2.5rem';
      pinLabel.style.fontSize = '0.75rem';
      break;
    default:
      pinLabelContainer.style.margin = '-2.375rem 0 0 0';
      pinLabelContainer.style.width = '27px';
  }

  pinLabelContainer.appendChild(pinLabel);

  return pinLabelContainer;
};

export const getTargetPropertyPinByPinType = (
  pin: HTMLElement,
  pinType: PinTypes,
  propertyId: number,
  label?: string | number,
) => {
  if (pinType === PinTypes.balloon) {
    pin.style.opacity = '1';
    const selectedPin = pin.querySelector('svg');
    selectedPin?.setAttribute('fill', colors.primaryOnDark);

    if (label) {
      const pinLabelContainer = getPinLabelByPinType(label, pinType);

      pin.setAttribute('data-value', `${label}`);
      pin.appendChild(pinLabelContainer);
    }
  }

  if (pinType === PinTypes.default) {
    const pinStarContainer = document.createElement('div');
    pinStarContainer.innerHTML = PIN_TYPES[PinTypes.star];
    pinStarContainer.style.width = '27px';
    pinStarContainer.style.display = 'flex';
    pinStarContainer.style.justifyContent = 'center';
    pinStarContainer.style.alignItems = 'center';
    pinStarContainer.style.margin = '-2.2rem 0 0 0';
    pinStarContainer.style.position = 'absolute';
    pinStarContainer.style.cursor = 'pointer';

    const selectedPin = pin.querySelector(`svg g[fill="${DEFAULT_PIN_COLOR}"]`);

    selectedPin?.setAttribute('fill', TARGET_PIN_COLOR);

    pin.setAttribute('data-value', `${propertyId}`);
    pin.appendChild(pinStarContainer);
  }
};

export const PIN_TYPES: {
  [name: string]: string;
} = {
  default: 'default',
  balloon: `<svg width="40" height="48" viewBox="0 0 40 48" fill="${DEFAULT_PIN_COLOR}" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.8">
    <mask id="path-1-inside-1_8239_18061" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40H14.8038L19.134 47.5C19.5189 48.1667 20.4811 48.1667 20.866 47.5L25.1962 40H36C38.2091 40 40 38.2091 40 36V4C40 1.79086 38.2091 0 36 0H4Z"/>
    </mask>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40H14.8038L19.134 47.5C19.5189 48.1667 20.4811 48.1667 20.866 47.5L25.1962 40H36C38.2091 40 40 38.2091 40 36V4C40 1.79086 38.2091 0 36 0H4Z" fill="currenColor"/>
    <path d="M14.8038 40L15.6699 39.5L15.3812 39H14.8038V40ZM19.134 47.5L20 47L19.134 47.5ZM20.866 47.5L20 47L20.866 47.5ZM25.1962 40V39H24.6188L24.3301 39.5L25.1962 40ZM1 4C1 2.34315 2.34315 1 4 1V-1C1.23858 -1 -1 1.23858 -1 4H1ZM1 36V4H-1V36H1ZM4 39C2.34315 39 1 37.6569 1 36H-1C-1 38.7614 1.23858 41 4 41V39ZM14.8038 39H4V41H14.8038V39ZM20 47L15.6699 39.5L13.9378 40.5L18.2679 48L20 47ZM20 47H20L18.2679 48C19.0377 49.3333 20.9623 49.3333 21.732 48L20 47ZM24.3301 39.5L20 47L21.732 48L26.0622 40.5L24.3301 39.5ZM36 39H25.1962V41H36V39ZM39 36C39 37.6569 37.6569 39 36 39V41C38.7614 41 41 38.7614 41 36H39ZM39 4V36H41V4H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 1H36V-1H4V1Z" fill="#2D594A" mask="url(#path-1-inside-1_8239_18061)"/>
  </g>
  </svg>`,
  star: `<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m8 0 2.478 4.999 5.522.8-3.992 3.909.971 5.53L8 12.624l-4.98 2.616.972-5.531L0 5.8l5.522-.801L8 0Z" fill="#fff"/></svg>`,
  purpleBalloon: `<svg width="45" height="48" viewBox="0 0 45 48" fill="${TARGET_PIN_COLOR}" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.8">
    <mask id="path-1-inside-1_8239_18061" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40H14.8038L19.134 47.5C19.5189 48.1667 20.4811 48.1667 20.866 47.5L25.1962 40H36C38.2091 40 40 38.2091 40 36V4C40 1.79086 38.2091 0 36 0H4Z"/>
    </mask>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40H14.8038L19.134 47.5C19.5189 48.1667 20.4811 48.1667 20.866 47.5L25.1962 40H36C38.2091 40 40 38.2091 40 36V4C40 1.79086 38.2091 0 36 0H4Z" fill="currenColor"/>
    <path d="M14.8038 40L15.6699 39.5L15.3812 39H14.8038V40ZM19.134 47.5L20 47L19.134 47.5ZM20.866 47.5L20 47L20.866 47.5ZM25.1962 40V39H24.6188L24.3301 39.5L25.1962 40ZM1 4C1 2.34315 2.34315 1 4 1V-1C1.23858 -1 -1 1.23858 -1 4H1ZM1 36V4H-1V36H1ZM4 39C2.34315 39 1 37.6569 1 36H-1C-1 38.7614 1.23858 41 4 41V39ZM14.8038 39H4V41H14.8038V39ZM20 47L15.6699 39.5L13.9378 40.5L18.2679 48L20 47ZM20 47H20L18.2679 48C19.0377 49.3333 20.9623 49.3333 21.732 48L20 47ZM24.3301 39.5L20 47L21.732 48L26.0622 40.5L24.3301 39.5ZM36 39H25.1962V41H36V39ZM39 36C39 37.6569 37.6569 39 36 39V41C38.7614 41 41 38.7614 41 36H39ZM39 4V36H41V4H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 1H36V-1H4V1Z" fill="#2D594A" mask="url(#path-1-inside-1_8239_18061)"/>
  </g>
  </svg>`,
};

export enum PinTypes {
  balloon = 'balloon',
  star = 'star',
  default = 'default',
  purpleBalloon = 'purpleBalloon',
}
