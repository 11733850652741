import { SortDirections } from 'constants/sortDirections';
import { GET_PROPERTY_SETS_QUERY } from 'graphql/propertySet';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { IPropertySetFilterInput } from 'interfaces/IPropertySetFilterInput';
import { removeTypenameKey } from 'utils/graphql/typename';
import { LanguageEnum } from 'constants/languages';

const getRefetchQueries = (
  customFilter: IPropertySetFilterInput = {},
  refetchQueries?: any,
) => [
  {
    query: GET_PROPERTY_SETS_QUERY,
    variables: {
      search: {
        filter: {
          ...customFilter,
        },
        order: {
          field: 'createdAt',
          direction: SortDirections.desc,
        },
      },
    },
  },
  ...(refetchQueries || []),
];

const formatPropertySet = (propertySet: IPropertySet) =>
  removeTypenameKey({
    propertyId: propertySet?.targetProperty?.id,
    tenantCompanyId: propertySet?.targetTenant?.id,
    userId: propertySet?.userId,
    fileId: propertySet?.fileId,
    name: propertySet?.name,
    properties: (propertySet?.properties || []).map((p, idx) => ({
      propertyId: p.id,
      order: idx,
    })),
    type: propertySet?.type,
  });

const detectNonEnglishUserLanguage = (userLanguage: string | undefined) =>
  userLanguage === LanguageEnum.FR ||
  userLanguage === LanguageEnum.FR_CA ||
  userLanguage === LanguageEnum.DE;

export { getRefetchQueries, formatPropertySet, detectNonEnglishUserLanguage };
