import React, { Fragment, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';
import { colors } from 'constants/colors';

import styles from './Breadcrumb.module.scss';
import { BreadcrumbProps, BreadcrumbLevel } from './types';
import ReactTooltip from 'react-tooltip';

const Breadcrumb = ({
  className,
  separatorClassName,
  levels,
  onClick: genericOnClick,
  separator = 'arrowRight',
  separatorColor = colors.ayGrey40Color,
  separatorSize = 1,
  tooltip,
}: BreadcrumbProps) => {
  const [isContentFaded, setIsContentFaded] = useState(false);
  const breadcrumbRef = useRef<HTMLDivElement | null>(null);
  const contentWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (breadcrumbRef.current && contentWrapperRef.current) {
      // If the content size is larger than its parent, we fade the content and display a tooltip.
      setIsContentFaded(
        breadcrumbRef.current.clientWidth <
          contentWrapperRef.current.scrollWidth,
      );
    }
  }, [breadcrumbRef.current, contentWrapperRef.current]);

  const renderLevels = (levels: BreadcrumbLevel[]) =>
    levels.map(
      (
        { name, content, onClick = genericOnClick, showSeparator = true },
        index,
        levelArray,
      ) => (
        <Fragment key={name || index}>
          <div
            className={classnames(styles['level-wrapper'], {
              [styles.clickable]: !!onClick,
              [styles['current-level']]: index === levelArray.length - 1,
            })}
            onClick={() => onClick?.(name)}
          >
            {content}
          </div>
          {index !== levelArray.length - 1 && showSeparator && (
            <Icon
              className={classnames(styles.separator, separatorClassName)}
              name={separator}
              color={separatorColor}
              size={separatorSize}
            />
          )}
        </Fragment>
      ),
    );

  return (
    <div
      ref={breadcrumbRef}
      className={classnames(styles.breadcrumb, className, {
        [styles['content-faded']]: isContentFaded,
      })}
      data-for="tooltip"
      data-tip={isContentFaded && tooltip ? tooltip : ''}
    >
      <ReactTooltip />
      <div ref={contentWrapperRef} className={styles['content-wrapper']}>
        {renderLevels(levels)}
      </div>
    </div>
  );
};

export default Breadcrumb;
