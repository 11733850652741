import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import ProfileSubSection from 'components/ProfileSubSection';
import CompsPopup from 'components/CompsPopup';
import SwitchOptionButton, {
  SwitchOption,
} from 'components/SwitchOptionButton';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import VerticalGroupBarChart from 'components/Graphs/VerticalGroupBarChart';
import MissingGraphData from 'components/MissingGraphData';
import { ColorNames } from 'constants/colorNames';
import { translateText } from 'utils/i18n';
import { SEARCH_PROPERTIES_QUERY } from 'graphql/property';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import styles from './AskingRentBlockSize.module.scss';
import { AskingRentBlockSizeProps, IGraphAskingRentBlockSize } from './types';
import {
  ASKING_RENT_CHART_TOGGLE_OPTIONS,
  I18N_COMP_SET_AVAILABILITIES_LABEL_PATH,
  BLOCKS_BY_RENT_CHART,
  BLOCKS_BY_SIZE_CHART,
} from './constants';
import {
  buildBarDataChart,
  getAskingRentStyle,
  getBlockSizeStyle,
  getValue,
  parseGraphAskingRentData,
  parseGraphBlockSizeData,
} from './utils';

const AskingRentBlockSize: React.FC<AskingRentBlockSizeProps> = ({
  chartBlockSizeData,
  chartAskingRentData,
  filters,
  propertyTypeId,
}) => {
  const [propertiesPopup, setPropertiesPopup] = useState<
    ILargestPropertiesPopup
  >();
  const [mapToggleOptions, setMapToggleOptions] = useState<SwitchOption>(
    ASKING_RENT_CHART_TOGGLE_OPTIONS[0],
  );

  const graphBlockSizeData = parseGraphBlockSizeData(chartBlockSizeData);

  const graphAskingRentData = parseGraphAskingRentData(
    chartAskingRentData,
    filters,
  );

  const handleBarChartClick = (datum: any) => {
    const { rawData } = datum;

    const propertyIds = rawData.topItems;

    if (!isEmpty(propertyIds)) {
      setPropertiesPopup({
        isVisible: true,
        popupFilter: { ids: propertyIds },
      });
    }
  };

  const hasData = (data: IGraphAskingRentBlockSize[]) =>
    !data.every(datum => !getValue(datum, mapToggleOptions));

  return (
    <>
      <ProfileSubSection
        title={translateText(
          `${I18N_COMP_SET_AVAILABILITIES_LABEL_PATH}.askingRentBlockSize`,
        )}
        headerContent={
          <SwitchOptionButton
            activeOption={mapToggleOptions}
            onChange={mapOption => setMapToggleOptions(mapOption)}
            options={ASKING_RENT_CHART_TOGGLE_OPTIONS}
          />
        }
        headerContentToRight
        titleColSize={8}
      >
        <Row>
          <Col lg={6} md={6} sm={6} xs={4}>
            {!hasData(graphAskingRentData) ? (
              <MissingGraphData
                title={translateText(
                  `${I18N_COMP_SET_AVAILABILITIES_LABEL_PATH}.blocksByRent`,
                )}
                wrapperClassName={styles['missing-graph-data']}
                skin="dark"
                fontSize="sm"
              />
            ) : (
              <VerticalGroupBarChart
                title={translateText(
                  `${I18N_COMP_SET_AVAILABILITIES_LABEL_PATH}.blocksByRent`,
                )}
                customStyle={getAskingRentStyle(
                  graphAskingRentData,
                  mapToggleOptions,
                )}
                barData={buildBarDataChart(
                  BLOCKS_BY_RENT_CHART,
                  mapToggleOptions,
                  graphAskingRentData,
                  handleBarChartClick,
                )}
              />
            )}
          </Col>
          <Col lg={6} md={6} sm={6} xs={4}>
            {!hasData(graphBlockSizeData) ? (
              <MissingGraphData
                title={translateText(
                  `${I18N_COMP_SET_AVAILABILITIES_LABEL_PATH}.blocksBySize`,
                )}
                wrapperClassName={styles['missing-graph-data']}
                skin="dark"
                fontSize="sm"
              />
            ) : (
              <VerticalGroupBarChart
                title={translateText(
                  `${I18N_COMP_SET_AVAILABILITIES_LABEL_PATH}.blocksBySize`,
                )}
                customStyle={getBlockSizeStyle(
                  graphBlockSizeData,
                  mapToggleOptions,
                  propertyTypeId,
                )}
                barData={buildBarDataChart(
                  BLOCKS_BY_SIZE_CHART,
                  mapToggleOptions,
                  graphBlockSizeData,
                  handleBarChartClick,
                )}
              />
            )}
          </Col>
        </Row>
      </ProfileSubSection>
      {propertiesPopup?.isVisible && (
        <CompsPopup
          showPropertyData
          hideAction
          graphqlQuery={SEARCH_PROPERTIES_QUERY}
          graphqlVariables={{
            search: { filter: propertiesPopup.popupFilter },
          }}
          onClose={() => setPropertiesPopup(undefined)}
          subtitle={propertiesPopup.popupSubtitle}
          tagBackgroundColor={ColorNames.supportiveColor100}
          title={propertiesPopup.popupTitle}
        />
      )}
    </>
  );
};

export default AskingRentBlockSize;
