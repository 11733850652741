import React from 'react';
import { capitalize, round } from 'lodash';
import classnames from 'classnames';

import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { translateText } from 'utils/i18n';
import { formatNumberWithCommas } from 'utils/formatters/number';
import { formatDate } from 'utils/formatters/date';
import { getCurrencySymbol } from 'utils/formatters/currency';

import { SuggestionIcon } from '../enums';
import { getSuggestionParsed } from '../getSuggestionParsed';
import { getSuggestionIcon } from './getSuggestionIcon';
import { ResultParserFn, SearchResultDataType } from '../interfaces';
import styles from '../LeaseInput.module.scss';

interface Props {
  suggestion: SearchResultDataType;
  parseResults?: ResultParserFn;
  suggestionIconType?: SuggestionIcon;
  aliasPlaceholder?: (suggestionName: string) => string;
  index?: number;
  searchInputText: string;
  activeIndex?: number | null;
  currentLeaseId?: number | null;
  onActiveIndexChange?: (value: number | null) => void;
  onSelect: (suggestion: IPropertyCompany) => void;
}

const SearchInputSuggestion: React.FC<Props> = ({
  suggestion,
  parseResults,
  aliasPlaceholder,
  index,
  activeIndex,
  currentLeaseId,
  onActiveIndexChange,
  onSelect,
}) => {
  const leaseSize =
    !!suggestion.size && !!suggestion.country?.areaMeasurement
      ? `${formatNumberWithCommas(suggestion.size)} ${
          suggestion.country.areaMeasurement
        }`
      : null;
  const floor = !!suggestion.floor ? `Floor ${suggestion.floor}` : ``;
  const primaryText = !!leaseSize
    ? !!floor
      ? `${leaseSize} · ${floor}`
      : `${leaseSize}`
    : 'No data to show';

  const signedDate = !!suggestion.signDate
    ? `Signed ${formatDate(suggestion.signDate, 'MMM YYYY')}`
    : '';
  const rent =
    !!suggestion.currencyCode && !!suggestion.baseRent
      ? `${getCurrencySymbol(suggestion.currencyCode!)}${
          suggestion.baseRent
        } ${suggestion.rentType ?? ``}`
      : '';
  const leaseTypes =
    !!suggestion.leaseType && !!suggestion.transactionType
      ? `${suggestion.leaseType.name}/${capitalize(suggestion.transactionType)}`
      : '';
  const termInMonths = !!suggestion.termInMonths
    ? `${round(suggestion.termInMonths / 12, 2)} Years`
    : '';
  const expiryDate = !!suggestion.expirationDate
    ? `Expiring ${formatDate(suggestion.expirationDate, 'MMM YYYY')}`
    : '';
  const secondaryText = [signedDate, rent, leaseTypes, termInMonths, expiryDate]
    .filter(el => el)
    .join(' · ');
  const propertyCompanyParsed = getSuggestionParsed(suggestion, parseResults);

  const suggestionIcon = getSuggestionIcon(
    propertyCompanyParsed,
    SuggestionIcon.leaseIcon,
  );

  const mainTextArray = [
    propertyCompanyParsed.name,
    propertyCompanyParsed.propertyName,
    propertyCompanyParsed.businessParkName,
  ];

  propertyCompanyParsed.type === 'submarket'
    ? mainTextArray.push(propertyCompanyParsed.marketName)
    : null;

  const propertyCompany = suggestion as IPropertyCompany;
  const hasBeenMerged = !!propertyCompany.mergedCompanyId;
  const isAlias = hasBeenMerged && !propertyCompany.mergedAt;

  const showMergeText =
    (hasBeenMerged && !isAlias) || (hasBeenMerged && !!aliasPlaceholder);

  const isItemActive =
    (index !== undefined &&
      activeIndex !== undefined &&
      index === activeIndex) ||
    suggestion.id === currentLeaseId;

  return (
    <li
      onMouseEnter={() => onActiveIndexChange?.(null)}
      key={propertyCompanyParsed.id}
      className={classnames(styles['search-suggestion-item'], {
        [styles['search-suggestion-item-active']]: isItemActive,
      })}
      onMouseDown={() => onSelect(suggestion)}
    >
      <div className={styles['suggestion-value']}>
        <div className={styles['text-contanier']}>
          <p className={styles['suggestion-text-primary']}>
            {suggestionIcon && (
              <img
                src={suggestionIcon}
                alt={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.logo`)}
                className={styles['img-profile']}
              />
            )}
            <span className={styles['highlight']}>{primaryText}</span>
          </p>
          <p
            className={classnames(styles['suggestion-text-secondary'], {
              [styles['text-capitalized']]: !showMergeText,
            })}
          >
            {secondaryText}
          </p>
        </div>
      </div>
    </li>
  );
};

export default SearchInputSuggestion;
