import React from 'react';
import styles from './MapImage.module.scss';
import { IProperty } from 'interfaces/IProperty';
import { IMarket } from 'interfaces/IMarket';
import { transformImageUrl } from 'utils/cloudinary/urls';
import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  SMALL_IMAGE_HEIGHT,
  SMALL_IMAGE_WIDTH,
} from 'components/ActivityCard/new/utils';
import MarketMap from 'components/MarketMap';
import { MarketMapOptions } from 'components/MarketProfile/constants';
import StaticMapImage from 'components/PropertyMap/StaticMapImage';
import { PropertyMapSize, ZoomLevel } from 'components/PropertyMap';
import { PropertyTypeIds, PropertyTypeNames } from 'constants/propertyTypes';
import classNames from 'classnames';
import { GREATER_MARKET_IMAGES } from 'assets';

const defaultPropertyType = {
  id: PropertyTypeIds['office'],
  name: PropertyTypeNames[PropertyTypeIds['office']],
};

interface Props {
  property?: IProperty;
  market?: IMarket;
  isSmall?: boolean;
}

const MapImage: React.FC<Props> = ({ property, market, isSmall }) => {
  let coverImage;
  if (market) {
    if (market.mediaUrl) {
      coverImage = transformImageUrl(
        market.mediaUrl,
        IMAGE_WIDTH,
        IMAGE_HEIGHT,
      );
    } else if (true) {
      coverImage = GREATER_MARKET_IMAGES[market.name!];
    }
  } else if (property && property.coverMediaUrl) {
    coverImage = transformImageUrl(
      property.coverMediaUrl,
      IMAGE_WIDTH,
      IMAGE_HEIGHT,
    );
  }

  const renderMap = () => {
    if (market) {
      return (
        <div
          className={classNames(
            styles['container-map'],
            isSmall && styles['small-image'],
          )}
        >
          <MarketMap
            activeOption={MarketMapOptions.ALL_PROPERTIES}
            market={market}
            propertyType={defaultPropertyType}
            showMapControls={false}
            showOpenMap={false}
            mapZoom={11}
            shouldShowOnlySelectedBoundaries
          />
        </div>
      );
    } else if (property) {
      return (
        <div
          className={classNames(
            styles['container-map'],
            isSmall && styles['small'],
          )}
        >
          <StaticMapImage
            wrapperClassName={isSmall ? styles['map-small'] : styles.map}
            height={isSmall ? SMALL_IMAGE_HEIGHT : IMAGE_HEIGHT}
            width={isSmall ? SMALL_IMAGE_WIDTH : IMAGE_WIDTH}
            zoomLevel={ZoomLevel.regular}
            property={property!}
            size={PropertyMapSize.small}
          />
        </div>
      );
    }
  };

  return (
    <>
      {coverImage ? (
        <div
          className={classNames(styles.image, isSmall && styles['small'])}
          style={{
            backgroundImage: `url(${coverImage})`,
          }}
        />
      ) : (
        renderMap()
      )}
    </>
  );
};

export default MapImage;
