import { map } from 'lodash';
import { IndustrialSprinkler } from 'constants/industrialSprinkler';
import { IdName } from 'interfaces/IdName';
import { translateText } from 'utils/i18n';
import { DataCenterBuildType, DataCenterFeedType } from 'constants/dataCenter';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

export const SPRINKLERS_OPTIONS = map(IndustrialSprinkler, type => ({
  id: type,
  name: type,
}));

export const getYesNoOptions = (): IdName[] => [
  {
    id: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.yes`),
    name: true,
  },
  {
    id: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.no`),
    name: false,
  },
];

export const getFeedTypes = (): IdName[] => [
  {
    id: translateText('avantPlatform.attributes.property.label.feedTypeSingle'),
    name: DataCenterFeedType.SINGLE,
  },
  {
    id: translateText('avantPlatform.attributes.property.label.feedTypeDual'),
    name: DataCenterFeedType.DUAL,
  },
];

export const DATA_CENTER_BUILD_TYPE_OPTIONS = [
  {
    id: translateText(`${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.retrofit`),
    name: DataCenterBuildType.RETROFIT,
  },
  {
    id: translateText(
      `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.purposeBuilt`,
    ),
    name: DataCenterBuildType.PURPOSE_BUILT,
  },
];
