import { useState } from 'react';
import { IPropertySetItem } from 'interfaces/inputs/IPropertySetItem';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';

interface Props {
  initialProperties: IPropertySetItem[];
  updatePropertySet?: (key: keyof IPropertySet, value: any) => void;
}
export const useFeaturedProperties = ({
  initialProperties,
  updatePropertySet,
}: Props) => {
  const initialFeaturedIds =
    initialProperties?.filter(p => p.featured).map(p => Number(p.id)) ?? [];

  const [selectedFeaturedIds, setSelectedFeaturedIds] = useState<number[]>(
    initialFeaturedIds,
  );

  const targetProperties: IPropertySetItem[] = initialProperties.filter(p =>
    selectedFeaturedIds.includes(Number(p.id)),
  );

  const compSetProperties: IPropertySetItem[] = initialProperties.filter(
    p => !selectedFeaturedIds.includes(Number(p.id)),
  );

  const onClickFeatured = (id: number) => {
    setSelectedFeaturedIds(prevIds => {
      const isPropertySelected = prevIds.includes(id);
      const updatedProperties = initialProperties.map(property => {
        if (property.id === id) {
          return {
            ...property,
            featured: !property.featured,
          };
        }
        return property;
      });
      updatePropertySet?.('properties', updatedProperties);
      return isPropertySelected
        ? prevIds.filter(featuredId => featuredId !== id)
        : [...prevIds, id];
    });
  };

  const removeProperty = (property: IPropertySetItem) => {
    const updatedProperties = initialProperties.filter(
      p => p.id !== property.id,
    );
    setSelectedFeaturedIds(prevIds =>
      prevIds.filter(featuredId => featuredId !== property.id),
    );
    updatePropertySet?.('properties', updatedProperties);
  };

  return {
    selectedFeaturedIds,
    targetProperties,
    compSetProperties,
    onClickFeatured,
    removeProperty,
  };
};
