import { ITIM } from 'interfaces/ITIM';
import { ITimInput } from 'interfaces/inputs/ITimInput';
import { isUndefined } from 'lodash';

export const buildTIMPayload = (
  tim: ITimInput,
  areaMeasurement: string,
): ITIM => {
  const payload: ITIM = {};
  if (!!tim.id) {
    payload.id = tim.id;
  }
  if (!!tim.activeSinceDate) {
    payload.activeSinceDate = tim.activeSinceDate;
  }
  if (!!tim.clearHeightHigh) {
    if (areaMeasurement === 'sqm') {
      delete payload.clearHeightHigh;
    } else {
      payload.clearHeightHigh = tim.clearHeightHigh;
    }
  }
  if (!!tim.clearHeightHighMt) {
    if (areaMeasurement === 'sqm') {
      payload.clearHeightHighMt = tim.clearHeightHighMt;
    } else {
      delete payload.clearHeightHighMt;
    }
  }
  if (!!tim.clearHeightLow) {
    if (areaMeasurement === 'sqm') {
      delete payload.clearHeightLow;
    } else {
      payload.clearHeightLow = tim.clearHeightLow;
    }
  }
  if (!!tim.clearHeightLowMt) {
    if (areaMeasurement === 'sqm') {
      payload.clearHeightLowMt = tim.clearHeightLowMt;
    } else {
      delete payload.clearHeightLowMt;
    }
  }
  if (!isUndefined(tim.currentPropertyId)) {
    payload.currentPropertyId = tim.currentPropertyId;
  }
  if (!!tim.dockConfigurationTypeId) {
    payload.dockConfigurationType = { id: tim.dockConfigurationTypeId };
  }
  if (!!tim.driveInDoors) {
    payload.driveInDoors = tim.driveInDoors;
  }
  if (!!tim.exitDate) {
    payload.exitDate = tim.exitDate;
  }
  if (!!tim.loadingDocks) {
    payload.loadingDocks = tim.loadingDocks;
  }
  if (!!tim.targetMarket?.id) {
    payload.targetMarket = { id: tim.targetMarket.id };
  }
  if (!!tim.targetSubmarkets) {
    payload.targetSubmarkets = tim.targetSubmarkets.map(submarket => {
      return { id: submarket.id };
    });
  }
  if (!!tim.targetPropertyTypeId) {
    payload.targetPropertyType = { id: tim.targetPropertyTypeId };
  }
  if (!!tim.targetSizeMin) {
    if (areaMeasurement === 'sqm') {
      delete payload.targetSizeMin;
    } else {
      payload.targetSizeMin = tim.targetSizeMin;
    }
  }
  if (!!tim.targetSizeMinMt) {
    if (areaMeasurement === 'sqm') {
      payload.targetSizeMinMt = tim.targetSizeMinMt;
    } else {
      delete payload.targetSizeMinMt;
    }
  }
  if (!!tim.targetSizeMax) {
    if (areaMeasurement === 'sqm') {
      delete payload.targetSizeMax;
    } else {
      payload.targetSizeMax = tim.targetSizeMax;
    }
  }
  if (!!tim.targetSizeMaxMt) {
    if (areaMeasurement === 'sqm') {
      payload.targetSizeMaxMt = tim.targetSizeMaxMt;
    } else {
      delete payload.targetSizeMaxMt;
    }
  }
  if (!!tim.tenantCompanyId) {
    payload.tenantCompany = { id: tim.tenantCompanyId };
  }
  if (!!tim.timStatusTypeId) {
    payload.timStatusType = { id: tim.timStatusTypeId };
  }
  if (!!tim.trailerParkingSpaces) {
    payload.trailerParkingSpaces = tim.trailerParkingSpaces;
  }
  if (tim.newToMarket != null || tim.newToMarket != undefined) {
    payload.newToMarket = tim.newToMarket;
  }

  payload.targetPropertySubType = tim.targetPropertySubTypeId
    ? { id: tim.targetPropertySubTypeId }
    : null;
  payload.targetSubmarkets = tim.targetSubmarkets?.length
    ? tim.targetSubmarkets.map(submarket => {
        return { id: submarket.id };
      })
    : null;
  payload.targetCommencementDate = tim.targetCommencementDate ?? null;
  payload.targetThirdPartyPropertyClass = tim.targetThirdPartyPropertyClassId
    ? {
        id: tim.targetThirdPartyPropertyClassId,
      }
    : null;
  payload.currentMarket = tim.currentMarket?.id
    ? { id: tim.currentMarket.id }
    : null;
  payload.currentLeaseId = tim.currentLeaseId ?? null;
  if (!!tim.currentSize) {
    if (areaMeasurement === 'sqm') {
      delete payload.currentSize;
    } else {
      payload.currentSize = tim.currentSize;
    }
  }
  if (!!tim.currentSizeMt) {
    if (areaMeasurement === 'sqm') {
      payload.currentSizeMt = tim.currentSizeMt;
    } else {
      delete payload.currentSizeMt;
    }
  }
  payload.newToMarket = tim.newToMarket ?? null;
  payload.tenantRepBrokerCompany = tim.tenantRepBrokerCompany
    ? { id: tim.tenantRepBrokerCompany.id }
    : null;
  payload.tenantRepBroker =
    tim.tenantRepBrokerName || tim.tenantRepBroker?.name
      ? {
          name: tim.tenantRepBrokerName ?? tim.tenantRepBroker?.name,
        }
      : null;
  payload.source = tim.source ?? null;
  payload.comments = tim.comments ?? null;
  payload.lastDiscussedDate = tim.lastDiscussedDate ?? null;
  payload.expandingContracting = tim?.expandingContracting
    ? { id: tim.expandingContracting.id, name: tim.expandingContracting.name }
    : null;
  return payload;
};
