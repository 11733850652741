import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NotFound.module.scss';
import locations from '../../routes';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_TEXT_PATH } from 'constants/i18n';

const I18N_NOT_FOUND_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound`;

const NotFound = () => (
  <div className={styles['container']}>
    <h1 className={styles['header']}>
      {translateText(`${I18N_NOT_FOUND_TEXT_PATH}.pageNotFound`)}
    </h1>
    <h3 className={styles['message']}>
      {translateText(`${I18N_NOT_FOUND_TEXT_PATH}.notFoundErrorMessage`)}
    </h3>
    <Link className={styles['link']} to={locations.root()}>
      {translateText(`${I18N_NOT_FOUND_TEXT_PATH}.goToHomepage`)}
    </Link>
  </div>
);

export default NotFound;
