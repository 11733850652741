import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import RadioInput, { IRadioItem } from 'components/Inputs/RadioInput';
import { BrokerCompanySuggestionType } from 'constants/BrokerCompanySuggestionType';
import { BROKER_COMPANY_SUGGESTIONS } from 'graphql/brokers/queries';
import { IdName } from 'interfaces/IdName';

import styles from '../../FormSection.module.scss';

interface BrokerCompanyPayload {
  brokerCompanySuggestionType: BrokerCompanySuggestionType;
  marketId: number;
  propertyTypeId: number;
}

interface BrokerCompanyData {
  brokerCompanySuggestions: IdName[];
}

interface Props extends BrokerCompanyPayload {
  selectedItem: string | string[];
  onChange: (item: IRadioItem) => void;
  isMultiSelect?: boolean;
}

export const BrokerOptions = ({
  selectedItem,
  onChange,
  brokerCompanySuggestionType,
  propertyTypeId,
  marketId,
  isMultiSelect,
}: Props) => {
  const [suggestions, setSuggestions] = useState<IdName[]>([]);

  useQuery<BrokerCompanyData, BrokerCompanyPayload>(
    BROKER_COMPANY_SUGGESTIONS,
    {
      variables: {
        brokerCompanySuggestionType,
        propertyTypeId,
        marketId,
      },
      skip: !propertyTypeId || !marketId,
      onCompleted: data => {
        const brokerOptions = data?.brokerCompanySuggestions?.map(o => {
          return {
            id: o.id,
            label: o.name,
            value: o.name,
          };
        });
        setSuggestions(brokerOptions ?? []);
      },
    },
  );

  return (
    <RadioInput
      isMultiSelect={isMultiSelect}
      selectedItem={selectedItem}
      wrapperClassName={styles['broker-helpers']}
      data={suggestions ?? []}
      onChange={onChange}
    />
  );
};
