import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingMessage from 'components/LoadingMessage';
import { HistoricalAvailabilityTypes } from 'components/Graphs/HistoricalAvailabilityChart/utils';
import { IProperty } from 'interfaces/IProperty';
import { LeasePeriodType } from 'constants/leases';
import PeriodToggles from 'components/Leases/PeriodToggles';
import ProfileSubSection from 'components/ProfileSubSection';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { useAnalyticsAssetPerformance } from 'hooks/useAnalyticsAssetPerformance';
import {
  getChartStyle,
  getLegends,
  parseGraphData as parseGraphDataVictory,
  SUB_TITLE_PATH,
} from './utils';
import HistoricalAvailabilityVictoryChart from 'components/Graphs/HistoricalAvailabilityVictoryChart';
import { formatNumberWithCommas } from 'utils/formatters/number';

type Props = {
  properties: IProperty[];
};
const HistoricalAvailability: React.FC<Props> = ({ properties }) => {
  const [periodType, setPeriodType] = useState<LeasePeriodType>(
    LeasePeriodType.ANNUAL,
  );
  const [activeType, setActiveType] = useState<HistoricalAvailabilityTypes>(
    HistoricalAvailabilityTypes.OCCUPIED,
  );

  const {
    assetPerformance,
    isLoadingAssetPerformance,
  } = useAnalyticsAssetPerformance({ properties, periodType });

  const { t } = useTranslation();

  const isQuarterly = periodType === LeasePeriodType.QUARTER;

  const graphData =
    assetPerformance?.items &&
    parseGraphDataVictory(assetPerformance.items, activeType, isQuarterly);

  const renderHeaderContent = () => (
    <PeriodToggles
      periodType={periodType}
      handleChange={(value: LeasePeriodType) => setPeriodType(value)}
    />
  );

  if (isLoadingAssetPerformance) {
    return <LoadingMessage />;
  }

  if (!graphData) {
    return null;
  }

  const getLabel = (datum: any) => {
    if (datum?.item?.label) {
      return `${formatNumberWithCommas(datum?.item?.buildingSize)} ${
        datum?.item?.unitOfMeasurement
      } ${datum.item?.label}`;
    }
    return '';
  };

  return (
    <ProfileSubSection
      title={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.historicalPerformance`)}
      headerContent={renderHeaderContent()}
    >
      <HistoricalAvailabilityVictoryChart
        graphData={graphData}
        activeType={activeType}
        setActiveType={setActiveType}
        legends={getLegends(activeType)}
        getLabel={getLabel}
        isQuarterly={isQuarterly}
        hideOddXTickValues
        subTitle={t(SUB_TITLE_PATH, {
          status: '',
        })}
        chartStyle={getChartStyle(activeType)}
      />
    </ProfileSubSection>
  );
};

export default HistoricalAvailability;
