import classNames from 'classnames';
import {
  getCountries,
  getPropertyTypes,
} from 'components/MultipleMarketsLink/utils';
import { DOT_LG } from 'constants/placeholders';
import { IMarket } from 'interfaces/IMarket';
import { IProperty } from 'interfaces/IProperty';
import { IScoop } from 'interfaces/IScoop';
import React from 'react';
import Tag from '../../Tag';
import { TypeOfCard } from '../../utils';
import styles from '../CardHeader.module.scss';
import MapImage from '../MapImage';
import MultipleLink from '../MultipleLink';

interface Props {
  scoop?: IScoop;
  type?: TypeOfCard;
  market?: IMarket;
  property?: IProperty;
}

const CardHeader: React.FC<Props> = ({ scoop, type, market, property }) => {
  const renderImage = () => {
    return <MapImage market={market} property={property} />;
  };

  const renderCategories = () => {
    return scoop?.scoopCategories?.map((category, key) => {
      return (
        <Tag
          key={key}
          label={category.name}
          wrapperClassName={classNames(styles.tag, {
            [styles.share]: type == TypeOfCard.SHARE,
          })}
        />
      );
    })!;
  };

  return (
    <>
      {renderImage()}
      <div className={styles['text-container']}>
        <div className={styles['activity-property-info']}>
          <MultipleLink market={market} property={property} scoop={scoop} />
          <div className={styles['header-container']}>
            <div className={styles['property-name']}>
              {scoop?.properties?.[0]?.state
                ? `${scoop?.properties?.[0].state} ${DOT_LG} `
                : ''}
              {scoop && getCountries(scoop)}
            </div>
            <div className={styles['container-category']}>
              {scoop?.propertyTypes && (
                <div className={styles['property-name']}>
                  {getPropertyTypes(scoop)}
                </div>
              )}
              {renderCategories()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardHeader;
