import { EscalationIncrement, EscalationType } from 'constants/leases';
import { IRadioItem } from 'components/Inputs/RadioInput';
import { convertFloatToDecimal } from 'utils/formatters/number';
import { ESCALATION_SUGGESTIONS } from '../../LeaseForm/suggestions';

export const isEscalationFlat = (escalationIncrement?: string | null) =>
  escalationIncrement?.toLowerCase() === EscalationIncrement.FLAT.toLowerCase();

export const isEscalationTypeIndexOmr = (escalationType?: string | null) =>
  escalationType?.toLowerCase() === EscalationType.IndexLinked ||
  escalationType?.toLowerCase() === EscalationType.OpenMarketRent;

export const generateRadioInputsLabels = (): IRadioItem[] =>
  ESCALATION_SUGGESTIONS.map(({ value, label }) => ({
    value,
    label: `${label}%`,
  }));

export const isEscalationTypePercentage = (escalationType?: string | null) =>
  escalationType?.toLowerCase() !== EscalationType.Currency;

const isValueFromRadio = (escalation: string): boolean =>
  getEscalationSuggestionsValue(escalation).length > 0;

export const getInputValue = (
  escalation: string | null,
  escalationType: string | null,
): string | null => {
  if (!escalation) return null;

  const escalationValue = isValueFromRadio(escalation)
    ? getEscalationSuggestionsValue(escalation)[0].label
    : escalation;

  return isEscalationTypeIndexOmr(escalationType) ? '' : escalationValue ?? '';
};

export const getEscalationSuggestionsValue = (
  escalation: string,
): typeof ESCALATION_SUGGESTIONS =>
  ESCALATION_SUGGESTIONS.filter(({ value }) => value === escalation);

export const getEscalationValueForMutation = (
  escalation: string | null,
  escalationType: string | null,
): string | null | undefined => {
  if (escalation) {
    const escalationNumber = Number(escalation);

    const escalationValue = isEscalationTypePercentage(escalationType)
      ? String(convertFloatToDecimal(escalationNumber))
      : escalation;

    return isEscalationTypeIndexOmr(escalationType)
      ? EscalationType.IndexLinked
      : escalationValue;
  }
  return null;
};
