import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { graphDimensions } from '../graphConstants';
import { getLabelColor } from './Bar';
import { ScaleBand } from 'd3-scale';
import { ISaleBarData } from '../interfaces';

type Props = {
  data: ISaleBarData[];
  xScale: ScaleBand<string>;
};

const X_QUARTER_FACTOR = 12;
const X_PLACEHOLDER_FACTOR = -26;
const X_AVERAGE_FACTOR = -9;

const QuarterLegend: React.FC<Props> = (props: Props) => {
  const quarterLegendRef = useRef(null);
  const { data, xScale } = props;
  // since 'QN YYYY' legend text size differs from '12 Months' legend size we need to calculate with different offset values
  const getXTextOffset = (d: ISaleBarData) => {
    const x = d.isQuarter
      ? X_QUARTER_FACTOR
      : d.isPlaceholder && d.isMarket
      ? X_PLACEHOLDER_FACTOR
      : X_AVERAGE_FACTOR;
    return `${x}px`;
  };

  useEffect(() => {
    const node = quarterLegendRef.current;

    const dataNode = select(node)
      .selectAll('text')
      .data(data)
      .enter();

    dataNode
      .append('text')
      .attr('x', d => xScale(d.key)!)
      .attr('y', graphDimensions.HEIGHT)
      .attr('dx', getXTextOffset)
      .attr('dy', '28px')
      .style('fill', getLabelColor)
      .style('font-size', '12px')
      .html(d => d.labelLine1);

    dataNode
      .append('text')
      .attr('x', d => xScale(d.key)!)
      .attr('y', graphDimensions.HEIGHT)
      .attr('dx', '-14px')
      .attr('dy', '45px')
      .style('fill', getLabelColor)
      .style('font-size', '12px')
      .html(d => d.labelLine2 || '');
  }, [data, xScale]);

  return <g ref={quarterLegendRef} />;
};

export default QuarterLegend;
