import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import styles from './LeaseInput.module.scss';
import { DocumentNode, useQuery } from '@apollo/client';
import { IPropertiesCompaniesSearchResult } from 'interfaces/IPropertiesCompaniesSearch';
import { useLocation } from 'react-router-dom';
import { Keyboard } from 'constants/keyboard';
import { SearchResultDataType } from './interfaces';
import { SearchInputSize, SuggestionIcon } from './enums';
import { ISearchInputCustomOption } from 'interfaces/ISearchInputCustomOption';
import LoadingMessage from 'components/LoadingMessage';
import { INPUT_DEBOUNCE_TIME_MS } from 'constants/inputs';
import MarketSwitch from 'components/MarketSwitch';
import { IdName } from 'interfaces/IdName';
import SearchInputSuggestion from 'components/LeaseInput/SearchInputSuggestion';
import { getSuggestionParsed } from 'components/SearchInput/getSuggestionParsed';
import { authContext } from 'contexts/AuthContext';
import { useUserMarkets } from 'hooks/useUserMarkets';
import { convertIMarketIntoIdName } from 'utils/markets';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { colors } from 'constants/colors';
import Icon from 'components/Icon';

const PAGE_SIZE = 50;
const MIN_CHARS_FOR_SEARCH = 3;

export type SearchInputPropTypes = {
  aliasPlaceholder?: (suggestionName: string) => string;
  autofocus?: boolean;
  clearAfterSuggestionClick?: boolean;
  customOption?: ISearchInputCustomOption;
  filterSuggestions?: (data: SearchResultDataType[]) => SearchResultDataType[];
  graphqlParams: { query: DocumentNode; resultDataName: string };
  hasBottomMargin?: boolean;
  hideEmptySuggestionsPressEnter?: boolean;
  isSearchIconLeftVisible?: boolean;
  initialValue?: string;
  inputId?: string;
  disabledPlaceholder?: string;
  isDisabled?: boolean;
  isEditable?: boolean;
  navbar?: boolean;
  onClickSuggestion: (value: SearchResultDataType) => void;
  placeholder?: string;
  resultSelectedComponent?: any;
  selectable?: boolean;
  suggestionIconType?: SuggestionIcon;
  onBlur?: () => void;
  onChangeText?: (value?: string) => void;
  onClearSelectedSuggestion?: () => void;
  onFocus?: () => void;
  size?: SearchInputSize;
  withMarketSwitch?: boolean;
  wrapperClassName?: string;
  includeMapBoxResults?: boolean;
  tenantCompanyId?: number;
  currentPropertyId?: number;
  currentLeaseId?: number | null;
  currentMarketId?: number;
};

const LeaseInput: React.FC<SearchInputPropTypes> = ({
  aliasPlaceholder,
  onClickSuggestion,
  onChangeText,
  placeholder,
  selectable,
  navbar,
  autofocus,
  graphqlParams,
  isSearchIconLeftVisible,
  inputId,
  isDisabled,
  disabledPlaceholder,
  isEditable,
  suggestionIconType,
  initialValue,
  resultSelectedComponent,
  hasBottomMargin,
  onClearSelectedSuggestion,
  onFocus,
  onBlur,
  customOption,
  hideEmptySuggestionsPressEnter,
  withMarketSwitch,
  size = SearchInputSize.Medium,
  clearAfterSuggestionClick,
  wrapperClassName,
  includeMapBoxResults,
  tenantCompanyId,
  currentPropertyId,
  currentLeaseId,
  currentMarketId,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const marketSwitchContainerRef = useRef<HTMLDivElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const isQueryParamsReady =
    !!currentMarketId &&
    !!currentPropertyId &&
    !!tenantCompanyId &&
    showSuggestions;

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<
    number | null
  >(null);

  const iconName = showSuggestions ? 'arrowUp' : 'arrowDown';
  const [isInputFocused, setInputIsFocused] = useState(autofocus);
  const [searchInputText, setSearchInputText] = useState(initialValue || '');
  const [searchTerm, setSearchTerm] = useState(initialValue || '');

  const [marketsFilter, setMarketsFilter] = useState<IdName[]>([]);

  const { pathname } = useLocation();
  const { user } = React.useContext(authContext);
  const { userMarkets } = useUserMarkets(user);

  const debouncedSearchTerm = useRef(
    debounce(setSearchTerm, INPUT_DEBOUNCE_TIME_MS),
  );

  useEffect(() => {
    setMarketsFilter(userMarkets.map(convertIMarketIntoIdName));
  }, [userMarkets]);

  useEffect(() => {
    const debounceFn = debouncedSearchTerm.current;
    debounceFn(searchInputText);

    return () => {
      debounceFn.cancel?.();
    };
  }, [searchInputText]);

  const { query, resultDataName } = graphqlParams;

  const getFilterQuery = () => {
    const filter: any = {};
    if (tenantCompanyId) {
      filter.tenants = [{ id: tenantCompanyId }];
    }
    if (currentPropertyId) {
      filter.propertyId = currentPropertyId;
    }

    return filter;
  };

  const getSearchVariables = (page = 1) => {
    return {
      search: {
        page: {
          page: page,
          size: PAGE_SIZE,
        },
        filter: getFilterQuery(),
      },
    };
  };

  const { data, loading } = useQuery<
    IPropertiesCompaniesSearchResult<SearchResultDataType>
  >(query, {
    // Disable the cache in order to match exactly what the API returns
    fetchPolicy: 'cache-and-network',
    variables: getSearchVariables(),
    skip: !isQueryParamsReady,
  });

  const results = [
    ...(data?.[resultDataName]?.results ?? []),
    ...(includeMapBoxResults
      ? data?.[resultDataName]?.mapBoxResults ?? []
      : []),
  ];
  const filteredSuggestions: SearchResultDataType[] =
    !loading && results?.length ? results : [];

  useEffect(() => {
    setSearchInputText('');
  }, [pathname]);

  useEffect(() => {
    setSearchInputText(initialValue || '');
  }, [initialValue]);

  const onChange = (e: any) => {
    const userInput = e.currentTarget.value;
    setActiveSuggestionIndex(null);
    setShowSuggestions(userInput.length >= MIN_CHARS_FOR_SEARCH);
    setSearchInputText(userInput || '');
    onChangeText?.(userInput || '');
  };

  const clearSearchInput = () => {
    setShowSuggestions(false);
    setActiveSuggestionIndex(null);
    setSearchInputText('');
    onChangeText?.('');
    onClearSelectedSuggestion?.();
  };

  const onSuggestionSelect = (suggestion: SearchResultDataType) => {
    const suggestionParsed = getSuggestionParsed(suggestion);
    setSearchInputText(suggestionParsed?.name || '');
    setShowSuggestions(false);
    setInputIsFocused(false);
    onClickSuggestion(suggestion);

    if (clearAfterSuggestionClick) {
      clearSearchInput();
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const allKeysHandled = [
      Keyboard.Enter,
      Keyboard.UpArrow,
      Keyboard.DownArrow,
    ];

    if (allKeysHandled.includes(e.keyCode)) {
      e.preventDefault();
    }

    if (e.keyCode === Keyboard.Enter) {
      if (!showSuggestions) return;

      if (hideEmptySuggestionsPressEnter && !filteredSuggestions.length) {
        setShowSuggestions(false);
      }

      if (activeSuggestionIndex == null) {
        setActiveSuggestionIndex(0);
        return;
      }

      const selectedSuggestion =
        filteredSuggestions[activeSuggestionIndex || 0];
      if (!!selectedSuggestion) {
        onSuggestionSelect(selectedSuggestion);
      }
      return;
    }

    // User pressed the up arrow, decrement the index
    if (e.keyCode === Keyboard.UpArrow) {
      if (!activeSuggestionIndex) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === Keyboard.DownArrow) {
      if (activeSuggestionIndex === null) {
        setActiveSuggestionIndex(0);
      } else {
        if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    }
  };

  const renderNoResultsMessage = () => {
    if (
      loading ||
      filteredSuggestions.length > 0 ||
      searchTerm !== searchInputText
    ) {
      return null;
    }

    return (
      <p className={styles['results-feedback']}>
        {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.notFound`)}
      </p>
    );
  };

  const renderLoadingSpinner = () => {
    if (!loading) {
      return null;
    }

    return <LoadingMessage />;
  };

  const renderSuggestionItem = (
    suggestion: SearchResultDataType,
    index: number,
  ) => (
    <SearchInputSuggestion
      key={index}
      index={index}
      aliasPlaceholder={aliasPlaceholder}
      suggestionIconType={suggestionIconType}
      suggestion={suggestion}
      currentLeaseId={currentLeaseId}
      onSelect={onSuggestionSelect}
      searchInputText={searchInputText}
      activeIndex={activeSuggestionIndex}
      onActiveIndexChange={setActiveSuggestionIndex}
    />
  );

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    const suggestionItems =
      !loading && filteredSuggestions.length > 0 ? (
        <>{filteredSuggestions.map(renderSuggestionItem)}</>
      ) : null;

    const shouldShowCustomOption =
      customOption?.onSelection && !loading && results !== undefined;

    return (
      <>
        <div className={styles['separator']}>
          <span>
            {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.currentLeases`)}
          </span>
        </div>
        {renderLoadingSpinner()}
        <ul
          className={classnames(styles['search-suggestions'], {
            [styles['search-suggestions-hidden']]: !showSuggestions,
            [styles['with-custom-option']]: shouldShowCustomOption,
          })}
        >
          {!shouldShowCustomOption && renderNoResultsMessage()}
          {suggestionItems}
        </ul>
      </>
    );
  };

  const renderInput = () => {
    if (
      isEditable &&
      !isInputFocused &&
      searchInputText.length !== 0 &&
      resultSelectedComponent
    ) {
      return (
        <div
          className={classnames(
            styles['search-input'],
            styles[`search-input-${size}`],
          )}
          onBlur={() => {
            setTimeout(() => {
              setInputIsFocused(false);
            });
          }}
          onClick={() => {
            setTimeout(() => {
              setInputIsFocused(true);
              setShowSuggestions(true);
              inputRef.current?.focus?.();
            });
          }}
        >
          {resultSelectedComponent}
        </div>
      );
    }

    return (
      <input
        ref={inputRef}
        readOnly
        autoFocus={autofocus && !navbar}
        onFocus={() => {
          setInputIsFocused(true);
          setShowSuggestions(searchInputText.length >= MIN_CHARS_FOR_SEARCH);
          onFocus?.();
        }}
        onBlur={() => {
          setInputIsFocused(false);
          onBlur?.();
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={searchInputText}
        autoComplete="off"
        autoCorrect="off"
        disabled={isDisabled}
        placeholder={
          isDisabled ? disabledPlaceholder ?? DASH_PLACEHOLDER : placeholder
        }
        className={classnames(
          styles['search-input'],
          styles[`search-input-${size}`],
          {
            [styles['selectable']]: selectable,
            [styles['search-input-icon']]: isSearchIconLeftVisible,
            [styles['search-input-disabled']]: isDisabled,
          },
        )}
        type="text"
        id={inputId || 'search-input'}
        data-testid={inputId}
      />
    );
  };

  const isEditableWithSelectedValue = () => {
    return (
      isEditable &&
      !isInputFocused &&
      searchInputText.length >= MIN_CHARS_FOR_SEARCH &&
      !showSuggestions
    );
  };

  const renderExpandButton = () => {
    if (!isDisabled) {
      return (
        <button
          className={styles['editable-button']}
          onClick={() => {
            setShowSuggestions(!showSuggestions);
            setInputIsFocused(!isInputFocused);
          }}
        >
          <Icon name={iconName} size={0.8} color={colors.ayGrey40Color} />
        </button>
      );
    } else {
      return null;
    }
  };

  const renderMarketSwitch = () => {
    if (!withMarketSwitch || isEditableWithSelectedValue()) return null;

    return (
      <div
        ref={marketSwitchContainerRef}
        className={styles['market-switch-container']}
      >
        <MarketSwitch
          selectedMarkets={marketsFilter}
          onChange={newMarkets => setMarketsFilter(newMarkets as IdName[])}
          isDisabled={isDisabled}
        />
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles['search-input-wrapper'],
        styles[`search-input-wrapper-${size}`],
        wrapperClassName,
        {
          [styles[
            'search-input-wrapper-editable'
          ]]: isEditableWithSelectedValue(),
          [styles['search-input-wrapper-focused']]: isInputFocused,
        },
      )}
    >
      <div
        className={classnames(styles['suggestions-container'], {
          [styles['suggestions-container-active']]: isInputFocused && !navbar,
          [styles['suggestions-container-navbar']]: navbar,
          [styles['suggestions-container-page-bottom']]: hasBottomMargin,
          [styles['suggestions-container-disabled']]: isDisabled,
        })}
      >
        <div className={styles['input-container']}>
          {renderInput()}
          {renderMarketSwitch()}
        </div>
        {renderExpandButton()}
        {renderSuggestions()}
      </div>
    </div>
  );
};

export default LeaseInput;
