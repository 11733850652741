import { line } from 'd3-shape';
import { select } from 'd3-selection';
import React, { useEffect, useRef } from 'react';

export interface LineDataType {
  id: string | number;
  xValue: number;
  yValue: number;
  label: string | number;
  color?: string;
  opacity?: number;
  item: any;
}

interface Props {
  color: string;
  data: LineDataType[];
  className: string;
  id?: string;
  commonClassName?: string;
}

const Line: React.FC<Props> = ({
  color,
  className,
  commonClassName,
  id,
  data,
}) => {
  const lineGroupRef = useRef(null);

  useEffect(() => {
    const node = lineGroupRef.current;
    const lineGenerator = line<any>()
      .x(d => d.xValue)
      .y(d => d.yValue);

    const d: any = lineGenerator(data);

    select(node)
      .selectAll(`.${className}`)
      .data([data])
      .join('path')
      .attr('d', d)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', 3)
      .attr('stroke-linecap', 'round')
      .attr('id', id || '')
      .attr('class', [className, commonClassName].filter(Boolean).join(' '));
    // eslint-disable-next-line
  }, [data]);

  return <g ref={lineGroupRef} />;
};

export default Line;
