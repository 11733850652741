import ConfirmationModal from 'components/ConfirmationModal';
import {
  I18N_MODAL_MEDIA_DOCUMENT_LABEL_PATH,
  I18N_MODAL_MEDIA_DOCUMENT_TEXT_PATH,
} from 'components/ModalMedia/constants';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import React from 'react';
import { translateText } from 'utils/i18n';

type DeleteModalProps = {
  isDeleting?: boolean;
  onDelete?: () => void;
  onClose?: () => void;
};

const DeleteModal: React.FC<DeleteModalProps> = ({
  isDeleting,
  onClose,
  onDelete,
}) => {
  return (
    <ConfirmationModal
      onCloseModal={() => onClose?.()}
      negativeButton={{
        label: isDeleting
          ? `${translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`,
            )}`
          : translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`,
            ),
        isDisabled: isDeleting,
        onclick: onDelete,
      }}
      positiveButton={{
        label: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`,
        ),
        onclick: () => onClose?.(),
      }}
      header={translateText(
        `${I18N_MODAL_MEDIA_DOCUMENT_LABEL_PATH}.deleteDocumentButtonLabel`,
      )}
      paragraph={[
        translateText(
          `${I18N_MODAL_MEDIA_DOCUMENT_TEXT_PATH}.deleteDocumentWarning`,
        ),
      ]}
    />
  );
};

export default DeleteModal;
