import { compact } from 'lodash';

import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { IProperty } from 'interfaces/IProperty';

export const extractPropertyIdsFromCompSet = (
  propertySet: IPropertySet | null | undefined,
  excludedProperty?: IProperty,
): number[] => {
  if (!propertySet) return [];

  let ids = compact(propertySet.properties?.map(p => p.id) || []);
  if (propertySet.targetProperty?.id) {
    ids.push(propertySet.targetProperty.id);
  }

  if (excludedProperty?.id) {
    ids = ids.filter(id => id !== excludedProperty?.id);
  }

  return Array.from(new Set(ids));
};

/**
 * Returns only the property sets where their target property matches
 * the provided property ID.
 */
export const getPropertySetsByTargetProperty = (
  propertySets: IPropertySet[] = [],
  targetPropertyId: number,
) =>
  propertySets.filter(
    ({ targetProperty }) => targetProperty?.id === targetPropertyId,
  );
