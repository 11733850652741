import React, { useState } from 'react';
import { filter, capitalize } from 'lodash';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';

import locations from 'routes';
import Button from 'components/Button/new';
import ModalWrapper from 'components/ModalWrapper';
import { Col, Container, Row } from 'components/@codelitt/ay-design-library';
import CompanyForm from 'components/CreateComps/FormSection/CompanyForm';
import { isValidImage } from 'components/UploadPhoto/utils';
import {
  I18N_COMPANY_LABEL_PATH,
  I18N_COMPANY_TEXT_PATH,
} from 'components/CompanyProfile/constants';
import UpdateSummary, { getUpdateSummaryData } from 'components/UpdateSummary';
import { ColorNames } from 'constants/colorNames';
import { ModalNames, ModalTypes } from 'constants/CompanyUpdatesModal';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ICompany } from 'interfaces/ICompany';
import ICompanyDetail from 'interfaces/ICompanyDetail';
import { COMPANY_DETAILS } from 'graphql/company';
import {
  CREATE_MEDIA_MUTATION,
  DELETE_MEDIA_MUTATION,
} from 'graphql/images/mutations';
import { upperFirstLetter } from 'utils/formatters/string';
import { ErrorLogger } from 'services/ErrorLogger';
import { translateText } from 'utils/i18n';
import styles from './ModalCompanyMutations.module.scss';
import { buildMutationParams } from './mutationsData';

interface Props {
  companyData: ICompany;
  handleNotification: (
    hasError: boolean,
    name: string,
    errorMessage?: string,
  ) => void;
  modalType?: ModalTypes;
  onAction: (data?: any) => void;
  onDataChange?: () => void;
  onDataUpdate: (field: string, value: any) => void;
}

const FK_ERROR = translateText(`${I18N_COMPANY_TEXT_PATH}.fkErrorMessage`);

const DELETE_COMPANY_ERROR = translateText(
  `${I18N_COMPANY_TEXT_PATH}.associationErrorMessage`,
);

const ModalCompanyMutations: React.FC<Props> = ({
  companyData,
  handleNotification,
  modalType,
  onAction,
  onDataChange,
  onDataUpdate,
}) => {
  const history = useHistory();
  const client = useApolloClient();
  const isDelete = modalType === ModalNames.DELETE;
  const isEdit = modalType === ModalNames.EDIT;
  const isCreate = modalType === ModalNames.CREATE;

  const [companyLogoToUpload, setCompanyLogoToUpload] = useState<File>();
  const [companyLogoRemoved, setCompanyLogoRemoved] = useState<boolean>(false);

  const { data, loading } = useQuery<{
    companyDetails: ICompanyDetail;
  }>(COMPANY_DETAILS, {
    variables: { id: companyData.id },
    skip: !companyData.id,
  });

  const updateSummaryValue = getUpdateSummaryData(
    companyData.createdAt ?? '',
    companyData.createdUser,
    companyData.updatedAt ?? '',
    companyData.updatedUser,
  );
  const displayCreateUpdateSection =
    updateSummaryValue?.created?.date || updateSummaryValue?.modified?.date;

  const [uploadCompanyLogo] = useMutation(CREATE_MEDIA_MUTATION, {
    onCompleted: data => {
      if (data) onDataChange?.();
    },
    onError: error => {
      ErrorLogger.log(
        error.message,
        'CREATE_MEDIA_MUTATION > Unexpected error uploading company logo',
      );
    },
  });

  const [deleteCompanyLogo] = useMutation(DELETE_MEDIA_MUTATION, {
    onCompleted: data => {
      if (data) onDataChange?.();
    },
    onError: error => {
      ErrorLogger.log(
        error.message,
        'DELETE_MEDIA_MUTATION > Unexpected error removing company logo',
      );
    },
  });

  const isDeleteButtonVisible =
    isEdit &&
    !loading &&
    !filter(data?.companyDetails, item => item! > 0).length;

  const headerContent = {
    title: `${upperFirstLetter(modalType)} Company`,
    subTitle: isDelete
      ? translateText(`${I18N_COMPANY_TEXT_PATH}.deleteWarningMessage`)
      : translateText(`${I18N_COMPANY_TEXT_PATH}.provideInfoMessage`, {
          modalType,
        }),
  };

  const footerContent = {
    submit: isDelete
      ? translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`)
      : capitalize(
          translateText(`${I18N_COMPANY_LABEL_PATH}.mutateCompanyButtonLabel`, {
            modalType,
          }),
        ),
    cancel: isDelete
      ? translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`)
      : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`),
  };

  const defaultErrorMessage = translateText(
    `${I18N_COMPANY_TEXT_PATH}.mutateCompanyUnexpectedError`,
    { modalType },
  );

  const onResponse = (hasError: boolean, data: any) => {
    onAction(data || {});

    if (hasError) {
      const genericMessage = isDelete
        ? DELETE_COMPANY_ERROR
        : defaultErrorMessage;

      const errorMessage =
        data?.updateCompany?.message ||
        data?.createCompany?.message ||
        data?.errorMessage ||
        genericMessage;

      handleNotification(hasError, companyData.name || '', errorMessage);
    } else {
      handleNotification(hasError, companyData.name || '');
    }
  };

  const handleCompanyLogoChanges = (companyId: number) => {
    if (
      (isCreate || isEdit) &&
      companyLogoToUpload &&
      isValidImage(companyLogoToUpload)
    ) {
      uploadCompanyLogo({
        variables: {
          file: companyLogoToUpload,
          companyId,
        },
        context: {
          fetchOptions: {
            useUpload: true,
          },
        },
      });
    }

    if (isEdit && companyData?.logoId && companyLogoRemoved) {
      deleteCompanyLogo({
        variables: {
          mediaId: companyData.logoId,
        },
      });
    }
  };

  const onSubmit = async () => {
    const mutationParams = buildMutationParams(
      modalType,
      companyData,
      isDelete,
    );

    try {
      const { data } = await client.mutate(mutationParams);
      const hasError =
        !!data?.updateCompany?.message ||
        !!data?.createCompany?.message ||
        (isDelete && !data?.deleteCompany);

      onResponse(hasError, data);
      isDelete && !hasError && history.push(locations.root());

      const companyId = data?.createCompany?.id || data?.updateCompany?.id;
      if (companyId) {
        handleCompanyLogoChanges(companyId);
        onDataChange?.();
      }
    } catch (error) {
      const errorMessage =
        (error as any).message?.indexOf(FK_ERROR) !== -1
          ? DELETE_COMPANY_ERROR
          : defaultErrorMessage;

      ErrorLogger.log(error as any, errorMessage);
      onResponse(true, { errorMessage });
    }
  };

  const onClose = () => onAction({ type: undefined, isOpen: false });

  const renderHeaderContent = () =>
    isEdit || isCreate ? (
      <div className={styles['header-container']}>
        <div className={styles['title']}>{headerContent.title}</div>
        <div className={styles['header-buttons']}>
          {isEdit && (
            <Button
              size="s"
              type="supportive-neutral"
              icon={ModalNames.MERGE}
              iconPosition={'right'}
              label={translateText(
                'avantPlatform.roles.company.action.mergeCompany',
              )}
              wrapperClassName={styles['merge-button']}
              onClick={() => onAction({ type: ModalNames.MERGE, isOpen: true })}
            />
          )}
          {isDeleteButtonVisible && (
            <Button
              size="s"
              type="delete"
              icon={ModalNames.DELETE}
              iconPosition={'right'}
              label={translateText(`${I18N_COMPANY_LABEL_PATH}.deleteCompany`)}
              wrapperClassName={styles['delete-button']}
              onClick={() =>
                onAction({ type: ModalNames.DELETE, isOpen: true })
              }
            />
          )}
        </div>
      </div>
    ) : (
      <header className={styles.header}>
        <Row>
          <Col lg={8}>
            <h2 className={styles.title}>{headerContent.title}</h2>
            <span className={styles.subtitle}>{headerContent.subTitle}</span>
          </Col>
        </Row>
      </header>
    );

  const renderContent = () =>
    !isDelete && (
      <CompanyForm
        data={companyData}
        onChange={onDataUpdate}
        onSubmit={onSubmit}
        isLoading={loading}
        onCancel={onClose}
        setCompanyLogoToUpload={setCompanyLogoToUpload}
        setCompanyLogoRemoved={setCompanyLogoRemoved}
        onOpenAliases={() =>
          onAction({ type: ModalNames.ALIASES, isOpen: true })
        }
        showCompanyAliasesButton={isEdit}
      />
    );

  const renderCreatedUpdated = () =>
    displayCreateUpdateSection && (
      <UpdateSummary
        data={updateSummaryValue}
        wrapperClassName={styles['create-update']}
      />
    );

  const renderFooter = () =>
    !isEdit &&
    !isCreate && (
      <footer className={styles.footer}>
        <Row>
          <Col lg={6}>
            <Button
              size="m"
              type="neutral"
              onClick={onClose}
              label={footerContent.cancel}
              wrapperClassName={classnames(
                styles['modal-button'],
                styles['cancel-button'],
              )}
            />
          </Col>
          <Col lg={6}>
            <Button
              type="main"
              size="m"
              disabled={!companyData.name}
              onClick={onSubmit}
              label={footerContent.submit}
              wrapperClassName={styles['modal-button']}
            />
          </Col>
        </Row>
      </footer>
    );

  return (
    <ModalWrapper
      fullScreen={isEdit || isCreate}
      withCloseLabel
      withCloseButton
      wrapperClassName={styles.container}
      modalBackgroundColor={ColorNames.ayPureWhiteColor}
      onCancel={onClose}
    >
      <Container wrapperClassName={styles['company-form-container']}>
        {renderHeaderContent()}
        {renderContent()}
        {renderCreatedUpdated()}
        {renderFooter()}
      </Container>
    </ModalWrapper>
  );
};

export default ModalCompanyMutations;
