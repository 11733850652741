import { gql } from '@apollo/client';

export const AVAILABILITY_DATA = gql`
  fragment availabilityData on Availability {
    id
    property {
      id
      name
      primaryAddress
      displayAddress
      coverMediaUrl
      coverMediaType
      country
      latitude
      longitude
      latitudeDisplay
      longitudeDisplay
      market {
        id
        name
      }
      measurementSystem
      country
      propertyType {
        id
        name
      }
      submarket {
        id
        name
      }
      micromarket {
        id
        name
      }
      propertyClass {
        id
        name
      }
      status {
        id
        name
      }
    }
    spaceUse {
      id
      name
    }
    landlordBrokerCompany {
      id
      name
    }
    askingRent
    askingRentMin
    askingRentMax
    timeMeasurement
    askingRentType
    suite
    leaseType
    fullFloor
    floor
    availableSpace
    availableSpaceMin
    availableSpaceMax
    availableSpaceMt
    availableSpaceMinMt
    availableSpaceMaxMt
    floorContiguousSpace
    floorContiguousSpaceMt
    buildingContiguousSpace
    buildingContiguousSpaceMt
    landlordBrokerContacts
    propertySetId
    comments
    status
    occupancy
    occupancyDate
    occupancyStatusType {
      id
      name
    }
    term
    fileId
    freeRentType
    freeRentMonths
    tenantImprovementAllowance
    officeSf
    officeSm
    typicalClearHeightMin
    typicalClearHeightMax
    typicalClearHeightMinMt
    typicalClearHeightMaxMt
    loadingDocks
    driveInDoors
    taxAndOpex
  }
`;
