import styles from './TableView.module.scss';

export const TABS_HEIGHT = 40;
export const NAVBAR_HEIGHT = 56;

/**
 * Show or hide the fixed header depending on the scroll position.
 *
 * The breakpoint to fix the heading is the position: NAVBAR_HEIGHT + TABS_HEIGHT.
 */
export const toggleHeaderVisibility = (
  fixedHeadingDiv: HTMLDivElement,
  tableElement: HTMLTableElement,
) => {
  if (!tableElement) return;

  const topBreakpoint = NAVBAR_HEIGHT + TABS_HEIGHT;
  fixedHeadingDiv.style.top = `${topBreakpoint}px`;

  if (tableElement.getBoundingClientRect().top <= topBreakpoint) {
    fixedHeadingDiv.classList.add(styles['fixed-heading-visible']);
  } else {
    fixedHeadingDiv.classList.remove(styles['fixed-heading-visible']);
  }
};

/**
 * Change the position of the fixed header columns when the tableContainer gets scrolled horizontally.
 */
const listenHorizontalScroll = (
  tableElement: HTMLTableElement,
  tableContainerDiv: HTMLDivElement,
  fixedHeadingScrollDiv: HTMLDivElement,
) => {
  const tableHeadings = tableElement.querySelectorAll(`thead > tr > th`);
  const fixedHeadings = fixedHeadingScrollDiv.querySelectorAll(`th`);
  const firstTh = Array.prototype.slice
    .call(tableHeadings)
    .find(th => th.style.position !== 'sticky');

  const updateHeaderPosition = (tableDiv: HTMLDivElement) => {
    fixedHeadings[
      firstTh!.cellIndex
    ].style.marginLeft = `-${tableDiv.scrollLeft}px`;
  };

  tableContainerDiv.addEventListener('scroll', (e: any) => {
    updateHeaderPosition(e.target);
  });

  updateHeaderPosition(tableContainerDiv);
};

/**
 * Apply the hover on all the cols and ths of the table. If the col/th has the same index add the background color, if not just remove.
 */
const updateHoverState = (
  tableElement: HTMLTableElement,
  fixedHeadingScrollDiv: HTMLDivElement,
  cellIndex: number,
) => {
  const tableCols = Array.from(tableElement.querySelectorAll('colgroup col'));
  const tableThs = Array.from(tableElement.querySelectorAll('thead th'));
  const fixedThs = Array.from(fixedHeadingScrollDiv.querySelectorAll('th'));
  const tableTds = Array.from(tableElement.querySelectorAll('tbody td'));

  const allItems = [tableCols, tableThs, fixedThs];
  for (let i = 0; i < tableCols.length; i++) {
    const classListFunction = i === cellIndex ? 'add' : 'remove';
    allItems.map(ths =>
      ths[i].classList[classListFunction](styles['active-column']),
    );

    tableTds.forEach((td: any) => {
      if (td.cellIndex === i) {
        td.classList[classListFunction](styles['active-column']);
      }
    });
  }
};

const applyTableHover = (
  tableElement: HTMLTableElement,
  fixedHeadingScrollDiv: HTMLDivElement,
) => {
  const invalidCellIndex = -1;
  const mouseOverFn = (e: any) => {
    const targetTag = e.target.tagName.toLowerCase();

    const isCell = targetTag === 'th';
    if (isCell) {
      let cellIndex = e.target.cellIndex;

      // If the element don't have the cellIndex we need to get it from the fixed headings
      if (cellIndex === invalidCellIndex) {
        cellIndex = Array.from(
          fixedHeadingScrollDiv.querySelectorAll('th'),
        ).indexOf(e.target);
      }
      updateHoverState(tableElement, fixedHeadingScrollDiv, cellIndex);
    }
  };

  const mouseOutFn = (e: any) => {
    const t = e.target;
    const targetTag = e.target.tagName.toLowerCase();
    const isCell = targetTag === 'th';
    if (isCell && !t.contains(e.relatedTarget)) {
      updateHoverState(tableElement, fixedHeadingScrollDiv, invalidCellIndex);
    }
  };

  // Apply the event listeners in the table and also in the fixed heading
  tableElement.addEventListener('mouseover', mouseOverFn);
  fixedHeadingScrollDiv.addEventListener('mouseover', mouseOverFn);

  tableElement.addEventListener('mouseout', mouseOutFn);
  fixedHeadingScrollDiv.addEventListener('mouseout', mouseOutFn);
};

/**
 * Cleanup the fixed heading
 */
const destroyFixedHeader = (
  fixedHeadingDiv: HTMLDivElement,
  fixedHeadingScrollDiv: HTMLDivElement,
) => {
  fixedHeadingScrollDiv.innerHTML = '';
  fixedHeadingDiv.classList.remove(styles['fixed-heading-visible']);
};

/**
 * Create a Fixed heading for the table, using the table THs as a reference.
 * This function will clone every TH and append it in the fixed heading.
 */
export const createFixedHeader = (
  tableElement: HTMLTableElement,
  tableContainerDiv: HTMLDivElement,
  fixedHeadingDiv: HTMLDivElement,
  fixedHeadingScrollDiv: HTMLDivElement,
  onClickHeading: (columnId: string) => void,
) => {
  if (!tableElement || !fixedHeadingDiv) return;

  destroyFixedHeader(fixedHeadingDiv, fixedHeadingScrollDiv);

  const headings = tableElement.querySelectorAll(`thead > tr > th`);

  if (!headings?.length) {
    return null;
  }

  const headingComputedStyle = window.getComputedStyle(headings[0]);
  const thBorderWidth = parseFloat(
    headingComputedStyle.getPropertyValue('border-right-width'),
  );

  const firstHeading = headings[0];
  const tableContainerDimentions = tableContainerDiv.getBoundingClientRect();
  const firstHeadingDimensions = firstHeading.getBoundingClientRect();
  const tableDimensions = tableElement.getBoundingClientRect();

  // Update the fixed heading to have the same dimensions as the table THs
  fixedHeadingDiv.style.height = `${firstHeadingDimensions.height}px`;
  fixedHeadingDiv.style.width = `${tableContainerDimentions!.width -
    thBorderWidth * 2}px`;
  fixedHeadingDiv.style.left = `${tableContainerDimentions.x}px`;
  fixedHeadingScrollDiv.style.width = `${tableDimensions.width +
    thBorderWidth}px`;

  // Clone each TH and add it to the fixed heading
  Array.prototype.slice.call(headings).forEach((th: any) => {
    const cloneTh = th.cloneNode(true);
    const thComputedStyle = window.getComputedStyle(th);
    cloneTh.style.display = 'inline-block';
    cloneTh.style.width = thComputedStyle.getPropertyValue('width');
    cloneTh.onclick = () => onClickHeading(th.dataset.id);
    fixedHeadingScrollDiv.appendChild(cloneTh);
  });

  toggleHeaderVisibility(fixedHeadingDiv, tableElement);
  listenHorizontalScroll(
    tableElement,
    tableContainerDiv,
    fixedHeadingScrollDiv,
  );
  applyTableHover(tableElement, fixedHeadingDiv);
};
