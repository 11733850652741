import React from 'react';
import FormControl from '../index';
import TextInput from 'components/Inputs/TextInput';
import { translateText } from 'utils/i18n';
import { FormFieldConfig, FormFieldType } from './types';
import {
  REGEX_DECIMALS,
  REGEX_THOUSAND_SEP_WITH_DECIMALS,
} from 'constants/regex';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import RangeSelector from 'components/RangeSelector';
import styles from 'components/CreateComps/FormSection/FormSection.module.scss';
import { IdName } from 'interfaces/IdName';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { fieldHasValue } from 'utils/objects';
import { currencyInputFormatter } from 'utils/formatters/currency';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import TextInputMultiple from 'components/TextInputMultiple';

interface Props {
  dataObject: { [key: string]: any };
  fieldConfig: FormFieldConfig;
  formControlClassName?: string;
  inputHelperClassName?: string;
  onChange: (fieldName: string, fieldValue: any) => void;
}

const FormField: React.FC<Props> = ({
  dataObject,
  fieldConfig,
  inputHelperClassName,
  formControlClassName,
  onChange,
}) => {
  const placeholderText =
    (fieldConfig.placeholderI18nKey &&
      translateText(fieldConfig.placeholderI18nKey)) ||
    '';

  const isFullWidth = fieldConfig.type === FormFieldType.text;

  const hasDecimalPattern = [
    REGEX_DECIMALS,
    REGEX_THOUSAND_SEP_WITH_DECIMALS,
  ].includes(fieldConfig.pattern as any);

  const isNumberInput = [
    FormFieldType.integer,
    FormFieldType.decimal,
    FormFieldType.feetInches,
    FormFieldType.range,
    FormFieldType.currency,
  ].includes(fieldConfig.type);

  const parseToNumber = (value?: string) =>
    hasDecimalPattern ? currencyInputFormatter(value) : convertToNumber(value);

  const onChangeInput = (field: string, value: string) => {
    if (isNumberInput) {
      const valueAsNumber = parseToNumber(value);
      onChange(field, fieldHasValue(valueAsNumber) ? valueAsNumber : null);
    } else {
      onChange(field, value);
    }
  };

  const onChangeInputMultiple = (
    field: string,
    value: (string | undefined)[],
  ) => {
    const formattedValues = value?.map(item => {
      const valueAsNumber = parseToNumber(item);

      return fieldHasValue(valueAsNumber) ? valueAsNumber : null;
    });

    onChange(field, formattedValues);
  };

  const renderTextInput = (): React.ReactNode => (
    <>
      <TextInput
        placeholder={placeholderText}
        pattern={fieldConfig.pattern}
        value={dataObject?.[fieldConfig.field!]}
        onChange={value => onChangeInput(fieldConfig.field!, value)}
        textCentered={fieldConfig.textCentered}
        fullWidth={isFullWidth}
        borderBox={isFullWidth}
        isDisabled={fieldConfig.isDisabled}
        formatter={hasDecimalPattern ? currencyInputFormatter : undefined}
      />

      {!!fieldConfig.legend && (
        <span className={inputHelperClassName}>{fieldConfig.legend}</span>
      )}
    </>
  );

  const renderFeetAndInches = (): React.ReactNode => (
    <>
      <TextInput
        isNarrow
        textCentered
        placeholder={placeholderText}
        pattern={fieldConfig.pattern || REGEX_DECIMALS}
        value={dataObject?.[fieldConfig.feetInchesConfig!.fieldFeet!]}
        onChange={value =>
          onChangeInput(fieldConfig.feetInchesConfig!.fieldFeet!, value)
        }
      />
      <span className={inputHelperClassName}>{UnitOfMeasurement.feet}</span>
      <TextInput
        isNarrow
        textCentered
        placeholder={placeholderText}
        pattern={fieldConfig.pattern || REGEX_DECIMALS}
        value={dataObject?.[fieldConfig.feetInchesConfig!.fieldInches!]}
        onChange={value =>
          onChangeInput(fieldConfig.feetInchesConfig!.fieldInches!, value)
        }
      />
      <span className={inputHelperClassName}>{UnitOfMeasurement.inches}</span>
    </>
  );

  const renderRangeField = () => (
    <RangeSelector
      onChange={(field, value) => {
        if (field === 'from') {
          onChangeInput(fieldConfig.rangeConfig!.fieldFrom, value);
        } else {
          onChangeInput(fieldConfig.rangeConfig!.fieldTo, value);
        }
      }}
      placeholder={placeholderText}
      pattern={fieldConfig.pattern}
      selectedValue={{
        from: dataObject?.[fieldConfig.rangeConfig!.fieldFrom],
        to: dataObject?.[fieldConfig.rangeConfig!.fieldTo],
      }}
      unitType={fieldConfig.rangeConfig!.unitType}
      shouldValidateRange={fieldConfig.rangeConfig!.shouldValidate}
      fromLabel={
        fieldConfig.rangeConfig!.labelFromI18nKey &&
        translateText(fieldConfig.rangeConfig!.labelFromI18nKey)
      }
      toLabel={
        fieldConfig.rangeConfig!.labelToI18nKey &&
        translateText(fieldConfig.rangeConfig!.labelToI18nKey)
      }
    />
  );

  const renderCompanyField = () => (
    <CompanySearchCombo
      isMultiple={fieldConfig.companyConfig?.isMultiple}
      inputId={fieldConfig.inputId}
      label={translateText(fieldConfig.labelI18nKey)}
      btnLabel={translateText(
        fieldConfig.companyConfig!.createCompanyLabelI18nKey,
      )}
      companies={
        fieldConfig.companyConfig?.isMultiple
          ? dataObject?.[fieldConfig.field!]
          : [{ name: dataObject?.[fieldConfig.field!]?.name }]
      }
      placeholder={placeholderText}
      wrapperClassName={styles['form-row']}
      onCompanySelection={(value: any) => {
        const newValue = value?.raw || value || null;

        if (fieldConfig.companyConfig?.isMultiple) {
          newValue &&
            onChange(fieldConfig.field!, [
              ...(dataObject?.[fieldConfig.field!] || []),
              newValue,
            ]);
        } else {
          onChange(fieldConfig.field!, newValue);
        }
      }}
      onClearSelectedSuggestion={() => {
        !fieldConfig.companyConfig?.isMultiple &&
          onChange(fieldConfig.field!, null);
      }}
      onRemoveItem={(item: IdName) => {
        onChange(
          fieldConfig.field!,
          (dataObject?.[fieldConfig.field!] || []).filter(
            (value: IdName) => value.id !== item.id,
          ),
        );
      }}
    />
  );

  const renderChoiceInput = () => (
    <ChoiceInput
      labelFieldName={'id'}
      selectedItem={{
        name: dataObject?.[fieldConfig.field!],
      }}
      data={fieldConfig.choiceConfig!.options}
      containerWrapperClassName={styles['radio-input-container']}
      itemWrapperClassName={styles['button-input-item']}
      onChange={(item?: IdName) => {
        onChange(
          fieldConfig.field!,
          fieldHasValue(item?.name) ? item?.name : undefined,
        );
      }}
    />
  );

  const renderCurrencyInput = () => (
    <CurrencyInput
      currencySymbol={fieldConfig?.currencyConfig?.currencySymbol}
      iconPosition={'left'}
      id={fieldConfig?.inputId}
      pattern={REGEX_THOUSAND_SEP_WITH_DECIMALS}
      placeholder={placeholderText}
      value={dataObject?.[fieldConfig?.currencyConfig?.fieldCurrency!]}
      onChange={value =>
        onChangeInput(fieldConfig.currencyConfig!.fieldCurrency!, value)
      }
    />
  );

  const renderTextMultipleInput = () => (
    <TextInputMultiple
      value={
        dataObject?.[fieldConfig?.textMultipleConfig?.fieldMultiple!] || []
      }
      onChange={value =>
        onChangeInputMultiple(
          fieldConfig.textMultipleConfig!.fieldMultiple!,
          value,
        )
      }
      isInvalid={fieldConfig.textMultipleConfig!.isInvalid}
      addButtonText={fieldConfig.textMultipleConfig!.addButtonText!}
      textInputProps={fieldConfig.textMultipleConfig?.textInputProps}
    />
  );

  const buildField = () => {
    const renderFunctions: { [key: string]: () => React.ReactNode } = {
      [FormFieldType.text]: renderTextInput,
      [FormFieldType.integer]: renderTextInput,
      [FormFieldType.decimal]: renderTextInput,
      [FormFieldType.feetInches]: renderFeetAndInches,
      [FormFieldType.range]: renderRangeField,
      [FormFieldType.company]: renderCompanyField,
      [FormFieldType.choice]: renderChoiceInput,
      [FormFieldType.currency]: renderCurrencyInput,
      [FormFieldType.textMultiple]: renderTextMultipleInput,
    };

    return renderFunctions[fieldConfig.type]();
  };

  const needsFormControl = () => fieldConfig.type !== FormFieldType.company;

  return needsFormControl() ? (
    <FormControl
      key={fieldConfig.field}
      label={translateText(fieldConfig.labelI18nKey)}
      wrapperClassName={formControlClassName}
    >
      {buildField()}
    </FormControl>
  ) : (
    <>{buildField()}</>
  );
};

export default FormField;
