type GeolocationCoordinates = {
  latitude: number;
  longitude: number;
};

export class UserLocationService {
  private static position: Partial<GeolocationCoordinates>;

  public static async getPosition(): Promise<Partial<GeolocationCoordinates>> {
    return new Promise((resolve, reject) => {
      if (!UserLocationService.position) {
        const onChange = ({ coords }: any) => {
          UserLocationService.position = {
            latitude: coords.latitude,
            longitude: coords.longitude,
          };
          resolve(UserLocationService.position);
        };

        const onError = (error: any) => {
          reject(error);
        };

        navigator.geolocation.getCurrentPosition(onChange, onError);
      } else {
        resolve(UserLocationService.position);
      }
    });
  }
}
