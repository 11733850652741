import React from 'react';
import { ITransactionVolumeDataPoint } from '../interfaces';
import styles from '../TransactionVolume.module.scss';
import {
  abbreviateCurrency,
  getCurrencySymbol,
} from 'utils/formatters/currency';
import { formatArea } from 'utils/formatters/area';
import { DOT } from 'constants/placeholders';
import classnames from 'classnames';
import {
  getAreaSoldAndPurchased,
  getUnitOfMeasurement,
} from 'components/Graphs/TransactionVolume/utils';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export enum TooltipType {
  purchase,
  sell,
}

interface Props {
  dataPoint?: ITransactionVolumeDataPoint;
  totalDataPoint?: ITransactionVolumeDataPoint;
  type: TooltipType;
}

export const joinTexts = (texts: string[]) => {
  return texts.filter(Boolean).join(` ${DOT} `);
};

const Tooltip: React.FC<Props> = props => {
  const { dataPoint, totalDataPoint, type } = props;

  if (!dataPoint && !totalDataPoint) return null;

  const hasDataPoint = !!dataPoint;
  const label = hasDataPoint
    ? dataPoint!.year
    : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.total`);
  const transaction = dataPoint || totalDataPoint;
  const currencySymbol = hasDataPoint
    ? getCurrencySymbol(dataPoint?.currencyCode)
    : getCurrencySymbol(totalDataPoint?.currencyCode);

  const { totalAreaPurchased, totalAreaSold } = getAreaSoldAndPurchased(
    transaction!,
  );
  const unitOfMeasurement = getUnitOfMeasurement(
    transaction?.measurementSystem,
  );

  const getPurchasedText = () => {
    return (
      <>
        <span className={styles['tooltip-main-text']}>{label}</span>
        <span className={styles['tooltip-separator']}>|</span>
        <span>
          {joinTexts([
            `${translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.purchased`,
            )}: ${currencySymbol}${abbreviateCurrency(
              transaction!.valuePurchased,
            )}`,
            formatArea(totalAreaPurchased, unitOfMeasurement),
            `${transaction!.countPropertiesPurchased} ${translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.property`,
              {
                count: transaction!.countPropertiesPurchased!,
              },
            )}`,
          ])}
        </span>
      </>
    );
  };

  const getSoldText = () => {
    return (
      <>
        <span className={styles['tooltip-main-text']}>{label}</span>
        <span className={styles['tooltip-separator']}>|</span>
        <span>
          {joinTexts([
            `${translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.sold`,
            )}: ${currencySymbol}${abbreviateCurrency(transaction!.valueSold)}`,
            formatArea(totalAreaSold, unitOfMeasurement),
            `${transaction!.countPropertiesSold} ${translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.property`,
              {
                count: transaction!.countPropertiesSold,
              },
            )}`,
          ])}
        </span>
      </>
    );
  };

  const isSellData = type === TooltipType.sell;

  return (
    <div
      className={classnames(styles['tooltip-container'], {
        [styles['sold']]: isSellData,
        [styles['purchased']]: !isSellData,
      })}
    >
      {isSellData && getSoldText()}
      {!isSellData && getPurchasedText()}
    </div>
  );
};

export default Tooltip;
