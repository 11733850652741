import React from 'react';
import { PropertyTypeNames, PropertyTypes } from 'constants/propertyTypes';
import { FieldType } from '../../types';
import DataCenterFields from '../DataCenterFields';
import HealthcareFields from '../HealthcareFields';
import IndustrialFields from '../IndustrialFields';
import MultifamilyFields from '../MultifamilyFields';
import LandAndDevelopmentFields from '../LandAndDevelopmentFields';
import OfficeFields from '../OfficeFields';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import LifeScienceFields from '../LifeScienceFields';

interface Props {
  property: IPropertyInput;
  onChange: {
    [FieldType.common]: (fieldName: string, fieldValue: any) => void;
    [FieldType.office]: (fieldName: string, fieldValue: any) => void;
    [FieldType.industrial]: (fieldName: string, fieldValue: any) => void;
    [FieldType.dataCenter]: (fieldName: string, fieldValue: any) => void;
    [FieldType.healthcare]: (fieldName: string, fieldValue: any) => void;
    [FieldType.multifamily]: (fieldName: string, fieldValue: any) => void;
  };
  classNames: {
    formControlClassName: string;
    choiceInputClassName: string;
    inputHelperClassName: string;
  };
}

export interface ExclusiveFieldProps {
  property: IPropertyInput;
  onChange: (fieldName: string, fieldValue: any) => void;
  classNames: {
    formControlClassName: string;
    inputHelperClassName: string;
  };
}

const ExclusiveFields: React.FC<Props> = ({
  property,
  onChange,
  classNames,
}) => {
  const {
    formControlClassName,
    choiceInputClassName,
    inputHelperClassName,
  } = classNames;
  const propertyTypeName = PropertyTypeNames[property?.propertyType?.id];

  switch (propertyTypeName) {
    default:
      return null;
    case PropertyTypes.industrial:
    case PropertyTypes.flexIndustrial:
      return (
        <IndustrialFields
          property={property}
          onChange={onChange}
          classNames={{
            formControlClassName: formControlClassName,
            inputHelperClassName: inputHelperClassName,
            choiceInputClassName: choiceInputClassName,
          }}
        />
      );
    case PropertyTypes.office:
      return (
        <OfficeFields
          property={property}
          onChange={onChange[FieldType.office]}
          classNames={{
            formControlClassName: formControlClassName,
            inputHelperClassName: inputHelperClassName,
          }}
        />
      );
    case PropertyTypes.lifeScience:
      return (
        <LifeScienceFields
          property={property}
          onChange={onChange[FieldType.common]}
          classNames={{
            formControlClassName: formControlClassName,
            inputHelperClassName: inputHelperClassName,
          }}
        />
      );
    case PropertyTypes.dataCenter:
      return (
        <DataCenterFields
          property={property}
          onChange={onChange[FieldType.dataCenter]}
          classNames={{
            formControlClassName: formControlClassName,
            inputHelperClassName: inputHelperClassName,
          }}
        />
      );
    case PropertyTypes.healthcare:
      return (
        <HealthcareFields
          property={property}
          onChange={onChange[FieldType.healthcare]}
          classNames={{
            formControlClassName: formControlClassName,
            inputHelperClassName: inputHelperClassName,
          }}
        />
      );
    case PropertyTypes.multifamily:
      return (
        <MultifamilyFields
          property={property}
          onChange={onChange[FieldType.multifamily]}
          classNames={{
            formControlClassName: formControlClassName,
            inputHelperClassName: inputHelperClassName,
          }}
        />
      );
    case PropertyTypes.landAndDevelopment:
      return (
        <LandAndDevelopmentFields
          property={property}
          onChange={onChange[FieldType.common]}
          classNames={{
            formControlClassName: formControlClassName,
            inputHelperClassName: inputHelperClassName,
          }}
        />
      );
  }
};

export default ExclusiveFields;
