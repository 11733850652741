import {
  useApolloClient,
  useQuery,
  PureQueryOptions,
  DocumentNode,
} from '@apollo/client';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import Button from 'components/Button';
import Dropzone from 'components/Dropzone';
import LoadingMessage from 'components/LoadingMessage';
import DocumentItem from 'components/ModalMedia/Documents/DocumentItem';
import UploadItem from 'components/ModalMedia/Documents/UploadItem';
import styles from 'components/ModalMedia/Pictures/Pictures.module.scss';
import {
  FileToUpload,
  IMediaUploadQueryConfig,
  KVFilesToUpload,
} from 'components/ModalMedia/types';
import { buildFileKey } from 'components/ModalMedia/utils';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import {
  CREATE_DOCUMENT_MUTATION,
  SET_DOCUMENT_CATEGORY_MUTATION,
} from 'graphql/documents';
import { IdName } from 'interfaces/IdName';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyDocument } from 'interfaces/IPropertyDocument';
import { IPursuitResponse } from 'interfaces/IPursuit';
import React, { useState } from 'react';
import { ErrorLogger } from 'services/ErrorLogger';
import { translateText } from 'utils/i18n';
type Props = {
  property?: IProperty;
  pursuit?: IPursuitResponse;
  queryConfig: IMediaUploadQueryConfig;
  listDocumentQuery: DocumentNode;
  documentRefetchQueries: PureQueryOptions[];
};

const Documents: React.FC<Props> = props => {
  const client = useApolloClient();

  const [documentsToUpload, setDocumentsToUpload] = useState<KVFilesToUpload>(
    {},
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const {
    property,
    pursuit,
    queryConfig,
    listDocumentQuery,
    documentRefetchQueries,
  } = props;

  const { data, loading } = useQuery<{ documents: IPropertyDocument[] }>(
    listDocumentQuery,
    {
      variables: queryConfig.listDocumentQuery?.variables || {
        propertyId: property?.id,
      },
      fetchPolicy: 'network-only',
    },
  );

  const documents = data?.documents || [];

  const setDocumentToUploadCategory = (docKey: string, cat: IdName | null) => {
    setDocumentsToUpload(value => {
      const documents = { ...value };
      documents[docKey].categoryId = cat?.id ? +cat.id : null;
      return documents;
    });
  };

  const setDocumentToUploadName = (docKey: string, newName: string) => {
    setDocumentsToUpload(value => {
      const documents = { ...value };
      documents[docKey].name = newName;
      return documents;
    });
  };

  const setDocumentUploadProgress = (docKey: string, progress: number) => {
    setDocumentsToUpload(value => {
      const documents = { ...value };
      documents[docKey] && (documents[docKey].uploadProgress = progress);
      return documents;
    });
  };

  const getDocumentsToUpload = (): FileToUpload[] => {
    return Object.keys(documentsToUpload).map(key => documentsToUpload[key]);
  };

  const removeDocumentToUpload = (docKey: string) => {
    documentsToUpload[docKey]?.abortUploadHandler?.();
    if (getDocumentsToUpload()?.length <= 1) {
      setIsUploading(false);
    }
    setDocumentsToUpload(({ [docKey]: _, ...documents }) => documents);
  };

  const processFiles = (files: File[]) => {
    files.forEach(f => {
      const fileToUpload = {
        key: buildFileKey(f),
        uploadProgress: 0,
        categoryId: null,
        name: f.name.replace(/\.[^/.]+$/, ''),
        file: f,
      };

      const document = {
        [fileToUpload.key]: fileToUpload,
      };

      setDocumentsToUpload(value => ({
        ...value,
        ...document,
      }));
    });
  };

  const clearAllUploads = () => {
    getDocumentsToUpload().forEach(doc => {
      doc.abortUploadHandler?.();
    });
    setDocumentsToUpload({});
    setIsUploading(false);
  };

  const uploadAll = async () => {
    const documents: FileToUpload[] = getDocumentsToUpload();

    const promises = documents.map(doc => {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const { data, errors } = await client.mutate({
            mutation: CREATE_DOCUMENT_MUTATION,
            variables: {
              file: doc.file,
              propertyId: property?.id,
              pursuitId: pursuit?.id,
              name: doc.name,
            },
            context: {
              fetchOptions: {
                useUpload: true,
                onProgress: (ev: ProgressEvent) => {
                  setDocumentUploadProgress(
                    doc.key,
                    Math.floor((ev.loaded / ev.total) * 100),
                  );
                },
                onAbortPossible: (abortFn: () => void) => {
                  documentsToUpload[doc.key] &&
                    (documentsToUpload[doc.key].abortUploadHandler = abortFn);
                },
              },
            },
            refetchQueries: documentRefetchQueries,
          });

          if (errors?.length) {
            reject(errors);
            return;
          }

          await client.mutate({
            mutation: SET_DOCUMENT_CATEGORY_MUTATION,
            variables: {
              documentId: data.createDocument.id,
              categoryId: doc.categoryId,
            },
            refetchQueries: documentRefetchQueries,
          });

          // remove document from the list when upload finishes
          removeDocumentToUpload(doc.key);
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    });

    setIsUploading(true);

    try {
      await Promise.all(promises);
    } catch (e) {
      ErrorLogger.log(e as any, 'Unable to upload document');
    } finally {
      setIsUploading(false);
    }
  };

  const renderDocuments = () => {
    if (loading) {
      return <LoadingMessage />;
    }
    if (!documents?.length || Object.keys(documentsToUpload).length > 0) {
      return null;
    }

    return documents
      .sort((a, b) => a.id! - b.id!)
      .map(document => (
        <DocumentItem
          document={document}
          property={property!}
          key={document.id}
          documentCategoryQuery={queryConfig.documentCategoriesQuery?.query}
          refetchQueries={queryConfig.documentRefetchQueries}
        />
      ));
  };

  const renderUploadItems = () => {
    const documents = getDocumentsToUpload();

    if (!documents?.length) return null;

    return documents.map(document => (
      <UploadItem
        onFileNameChange={setDocumentToUploadName}
        onCategoryChange={setDocumentToUploadCategory}
        onDelete={removeDocumentToUpload}
        document={document}
        documentCategoryQuery={queryConfig.documentCategoriesQuery?.query}
        key={document.key}
      />
    ));
  };

  const renderButtonActions = () => {
    if (!Object.keys(documentsToUpload).length) return null;

    const documents = getDocumentsToUpload();
    const isValidForm = documents.filter(i => !i.categoryId).length === 0;

    return (
      <div className={styles['submit-btn-container']}>
        <Button
          wrapperClassName={styles['cancel-btn']}
          onClick={clearAllUploads}
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`)}
          type="neutral"
          size="small"
        />

        <Button
          wrapperClassName={styles['submit-btn']}
          isDisabled={isUploading || !isValidForm}
          onClick={uploadAll}
          label={
            isUploading
              ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.uploading`)
              : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.submit`)
          }
          type="main"
          size="small"
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Row wrapperClassName={styles['dropzone-container']}>
        <Col>
          <Dropzone onChange={processFiles} small={true} multipleFile />
        </Col>
      </Row>
      <Row>
        {renderDocuments()}
        {renderUploadItems()}
      </Row>

      {renderButtonActions()}
    </div>
  );
};

export default Documents;
