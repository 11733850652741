import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { capitalize, isEmpty } from 'lodash';
import { useMutation } from '@apollo/client';
import locations from 'routes';
import AYLink from 'components/AYLink';
import Button from 'components/Button/new';
import LabeledData from 'base-components/LabeledData';
import NotificationMessage from 'components/NotificationMessage';
import TagsList from 'components/TagsList';
import CompsComments from 'components/CompsComments';
import ModalEditComp from 'components/ModalEditComp';
import { buildTagsListItems } from 'components/Sales/buildTagsListItems';
import { ShareScoopsModalMessage } from 'components/ShareScoopsModalMessage';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { DOT } from 'constants/placeholders';
import { CompType } from 'constants/compType';
import { SHARE_ACTIVITIES_MESSAGE_MUTATION } from 'graphql/share';
import { translateText } from 'utils/i18n';
import {
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_AVANT_PLATFORM_SALE_LABEL_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
} from 'constants/i18n';
import { LanguageEnum } from 'constants/languages';
import { authContext } from 'contexts/AuthContext';
import CardContainer from '../CardContainer';
import PropertyHeader from '../CardHeader/PropertyHeader';
import Separator from '../Separator';
import Owner from '../Owner';
import { TypeOfCard } from '../utils';
import styles from './SaleCard.module.scss';
import { SaleCardProps } from './types';

const SaleCard = ({
  sale,
  fieldsToShow,
  activity,
  tagLimit,
  className,
  showSaleMainInfo = true,
  showBrokerInfo = true,
  showNotes = true,
  collapseNotes = false,
  showEditButton = true,
  showShareButton = true,
  showUserModificationInfo = true,
  type,
  userModificationSmallText,
  refetchScoopList,
}: SaleCardProps) => {
  const { user } = useContext(authContext);
  const { hasEditorAccess } = useContext(subscriptionsContext);

  const [showShareActivityModal, setShowShareActivityModal] = useState<boolean>(
    false,
  );
  const [showEditSaleModal, setShowEditSaleModal] = useState(false);
  const [showShareNotification, setShowShareNotification] = useState(false);
  const isUkLanguage = user.language === LanguageEnum.EN_GB;

  const modifiedSale = { ...sale };
  if (isUkLanguage && sale.type?.name === 'Condo') {
    modifiedSale.type = { ...modifiedSale.type, name: 'Sale of Part' };
  }
  const tags = buildTagsListItems(modifiedSale, fieldsToShow);

  const isShareCard = type === TypeOfCard.SHARE;
  const hasSellers = !isEmpty(sale.sellers);
  const hasBuyers = !isEmpty(sale.buyers);
  const hasSellerBrokers = !isEmpty(sale.sellersBrokers);
  const hasBuyerBrokers = !isEmpty(sale.buyersBrokers);

  const [shareMessage] = useMutation(SHARE_ACTIVITIES_MESSAGE_MUTATION);

  const onShareButtonClick = () => {
    shareMessage({
      variables: {
        search: {
          filter: {
            ids: [activity?.id],
          },
          includeScoopsWithNoProperties: true,
          shouldReturnPricingQuotes: true,
        },
      },
    });
    setShowShareActivityModal(true);
  };

  return (
    <CardContainer
      wrapperClassName={classnames(styles['sale-card'], className, {
        [styles['share-card']]: isShareCard,
      })}
    >
      <div className={styles.header}>
        {showSaleMainInfo && (
          <PropertyHeader
            property={sale.property}
            tagLabel={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.sale`)}
            type={type}
          />
        )}
        {hasEditorAccess && !isShareCard && (
          <div className={styles['action-button-wrapper']}>
            {/* If there's no activity, it doesn't make any sense to show the button to share activities. */}
            {activity && showShareButton && (
              <Button
                wrapperClassName={styles['action-button']}
                icon="share"
                iconPosition="left"
                onClick={onShareButtonClick}
                size="s"
                type="contextual"
              />
            )}
            {showEditButton && (
              <Button
                wrapperClassName={styles['action-button']}
                icon="edit"
                iconPosition="left"
                onClick={() => setShowEditSaleModal(true)}
                size="s"
                type="contextual"
              />
            )}
          </div>
        )}
      </div>
      {showSaleMainInfo && <Separator />}
      <div className={styles.content}>
        {hasBuyers && (
          <LabeledData
            dataInBold
            className={classnames(
              styles['sale-company-info'],
              styles['purchased-by-wrapper'],
              {
                [styles['with-margin']]: hasSellers,
              },
            )}
            label={capitalize(
              translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.purchasedBy`),
            )}
            data={(sale.buyers || []).map(buyer => (
              <AYLink
                key={buyer.id}
                to={locations.showCompany(buyer.id!)}
                wrapperClassName={styles['sale-link']}
              >
                {buyer.name}
              </AYLink>
            ))}
            dataWrapperClassName={styles['buyers-wrapper']}
          />
        )}
        {hasSellers && (
          <LabeledData
            dataInBold
            className={styles['sale-company-info']}
            label={capitalize(
              translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.soldBy`),
            )}
            data={(sale.sellers || []).map(seller => (
              <AYLink
                key={seller.id}
                to={locations.showCompany(seller.id!)}
                wrapperClassName={styles['sale-link']}
              >
                {seller.name}
              </AYLink>
            ))}
            dataWrapperClassName={styles['sellers-wrapper']}
          />
        )}
        {tags.length > 0 && (
          <TagsList
            className={classnames(styles.tags, {
              [styles['with-no-top-margin']]: !hasBuyers && !hasSellers,
              [styles['with-no-bottom-margin']]:
                !hasBuyerBrokers && !hasSellerBrokers,
            })}
            tagBackgroundClass={classnames(styles['tag-item'], {
              [styles.darker]: isShareCard,
            })}
            tags={tags}
            tagLimit={tagLimit}
          />
        )}
        {showBrokerInfo && (
          <div className={styles['broker-wrapper']}>
            {hasSellerBrokers && (
              <LabeledData
                dataInBold
                className={classnames(
                  styles['broker-wrapper-child'],
                  styles['seller-broker-wrapper'],
                )}
                label={`${translateText(
                  `${I18N_AVANT_PLATFORM_SALE_LABEL_PATH}.sellerBroker`,
                )}:`}
                data={(sale.sellersBrokers || []).map(sellerBroker => (
                  <AYLink
                    key={sellerBroker.id}
                    to={locations.showCompany(
                      !!sellerBroker.company
                        ? sellerBroker.company.id ?? sellerBroker.id!
                        : sellerBroker.id!,
                    )}
                    wrapperClassName={styles['sale-link']}
                  >
                    {sellerBroker.name || sellerBroker.company?.name}
                  </AYLink>
                ))}
              />
            )}
            {hasSellerBrokers && hasBuyerBrokers && DOT}
            {hasBuyerBrokers && (
              <LabeledData
                dataInBold
                className={classnames(
                  styles['broker-wrapper-child'],
                  styles['buyer-broker-wrapper'],
                )}
                label={`${translateText(
                  `${I18N_AVANT_PLATFORM_SALE_LABEL_PATH}.buyerBroker`,
                )}:`}
                data={(sale.buyersBrokers || []).map(buyerBroker => (
                  <AYLink
                    key={buyerBroker.id}
                    to={locations.showCompany(
                      !!buyerBroker.company
                        ? buyerBroker.company.id ?? buyerBroker.id!
                        : buyerBroker.id!,
                    )}
                    wrapperClassName={styles['sale-link']}
                  >
                    {buyerBroker.name || buyerBroker.company?.name}
                  </AYLink>
                ))}
              />
            )}
          </div>
        )}
        {showNotes && (
          <CompsComments
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.note_plural`,
            )}
            comments={sale.comments}
            className={styles['note-wrapper']}
            hideNote={collapseNotes}
          />
        )}
        {showUserModificationInfo && sale.createdUser && (
          <>
            <Separator />
            <Owner
              colored
              ignoreSameUserEdit
              wrapperClassName={styles['user-modification-date']}
              ownerSeparatorClassName={
                styles['user-modification-date-separator']
              }
              createdUser={sale.createdUser}
              createdAt={sale.createdAt}
              updatedUser={sale.updatedUser}
              updatedAt={sale.updatedAt}
              smallText={userModificationSmallText}
              lineBreak={
                type === TypeOfCard.HOME || type === TypeOfCard.CAROUSEL
              }
            />
          </>
        )}
      </div>
      {showEditSaleModal && (
        <ModalEditComp
          type={CompType.sale}
          compId={sale.id!}
          onUpdate={refetchScoopList}
          onModalToggle={setShowEditSaleModal}
        />
      )}

      {showShareActivityModal && (
        <ShareScoopsModalMessage
          onClose={() => setShowShareActivityModal(false)}
        />
      )}

      <NotificationMessage
        show={showShareNotification}
        isSuccess
        text={translateText(
          `${I18N_AVANT_PROPERTY_TEXT_PATH}.exploreActivity.scoop.scoopNotification`,
        )}
        onClose={() => setShowShareNotification(false)}
      />
    </CardContainer>
  );
};

export default SaleCard;
