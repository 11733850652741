import React from 'react';
import classnames from 'classnames';

import styles from './Separator.module.scss';
import { SeparatorProps } from './types';

const Separator = ({ className }: SeparatorProps) => (
  <div className={classnames(styles.separator, className)} />
);

export default Separator;
