import { gql } from '@apollo/client';

export const DELETE_TIM_MUTATION = gql`
  mutation deleteTIM($TIMId: Int!) {
    deleteTIM(TIMId: $TIMId)
  }
`;

export const UPDATE_TIM_MUTATION = gql`
  mutation updateTIM($TIM: TIMInput!) {
    updateTIM(TIM: $TIM) {
      ... on TIM {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const CREATE_TIM_MUTATION = gql`
  mutation createTIM($TIM: TIMInput!) {
    createTIM(TIM: $TIM) {
      ... on TIM {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;
