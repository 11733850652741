import { isValidISOFormat } from 'utils/validation/date';
import {
  ICustomValidation,
  MONTH_MIN_LENGTH,
  MONTH_MAX_LENGTH,
  DAY_MIN_LENGTH,
  DAY_MAX_LENGTH,
  YEAR_MAX_LENGTH,
} from 'components/Inputs/DateInput/constants';
import { DASH_PLACEHOLDER } from 'constants/placeholders';

const hasRequiredLength = (value: string, length: number) =>
  value.length === length;

export const handleValidation = (
  day: string,
  month: string,
  year: string,
  setValidation: (value: ICustomValidation) => void,
  onChange?: (value: string | null) => void,
  customValidation?: ICustomValidation,
  skipValidation?: boolean,
  allowEmpty?: boolean,
) => {
  const isValidDay =
    hasRequiredLength(day, DAY_MIN_LENGTH) ||
    hasRequiredLength(day, DAY_MAX_LENGTH);
  const isValidMonth =
    hasRequiredLength(day, MONTH_MIN_LENGTH) ||
    hasRequiredLength(day, MONTH_MAX_LENGTH);
  const isValidYear = hasRequiredLength(year, YEAR_MAX_LENGTH);
  const date = [year, month, day].join(DASH_PLACEHOLDER);
  const isEmpty = date === '--';

  if (skipValidation) {
    onChange?.(isEmpty ? null : date);
    return;
  }

  if (isEmpty) {
    const isValid = !!allowEmpty;
    const message = allowEmpty ? '' : 'Invalid date';

    onChange?.(null);
    setValidation({
      isValid,
      message,
    });

    return;
  }

  if (!isValidDay || !isValidMonth || !isValidYear) {
    return;
  }

  if (
    isValidISOFormat(date) &&
    customValidation &&
    !customValidation?.isValid
  ) {
    onChange?.(date);
    setValidation(customValidation);
    return;
  }

  onChange?.(date);

  setValidation({
    isValid: isValidISOFormat(date),
    message: 'Invalid date',
  });
};
