import { ISubmarketProfileConcessionSlices } from './ISubmarketLeasingConcession';

export interface ILeaseAnalyticsConcessionResponse {
  slices: ILeaseAnalyticsConcessionSlices[];
}

export enum LeaseAnalyticsConcessionType {
  ANNUAL = 'ANNUAL',
  QUARTER = 'QUARTER',
}

export interface ILeaseAnalyticsConcessionSlices
  extends ISubmarketProfileConcessionSlices {
  count: number;
}
