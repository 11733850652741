import { maxBy, minBy, uniq } from 'lodash';
import { IHistoricalLeasingData } from 'interfaces/IHistoricalLeasingData';
import { IGraphLeasesItem } from 'components/Graphs/RecentLeases/interfaces';
import {
  getValidLeases,
  parseLeasesItemsToDataPoints,
  prepareDataPoints,
} from 'components/Graphs/RecentLeases/lineGraphData';
import { RecentLeasesIds } from 'components/Graphs/RecentLeases/graphIds';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { isMonthlyTimeMeasurement, isRentTypeNNN } from 'utils/leases';
import { LeaseRentType } from 'constants/leases';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { IProperty } from 'interfaces/IProperty';

export const parseGraphData = (
  property: IProperty | undefined,
  graphId: RecentLeasesIds,
  data: IHistoricalLeasingData,
  toggleOptions: LeaseToggleOptions,
  refMaxYears?: number,
  isQuarterly?: boolean,
  refStartDate?: Date,
  currencySymbol?: string,
) => {
  const { historicalLeases, historicalAverage } = data;
  let leaseItems: IGraphLeasesItem[] = [];

  if (historicalLeases?.items) {
    leaseItems = historicalLeases.items;
  }

  const firstLeaseItem = leaseItems?.[0];
  const rentType = toggleOptions?.rentType || LeaseRentType.FS;
  const leases = getValidLeases(leaseItems, rentType);
  const currency =
    currencySymbol ||
    getCurrencySymbol(property?.propertyCountry?.currencyCode ?? 'USD');

  const unitOfMeasurement =
    property?.propertyCountry?.areaMeasurement ??
    getUnitOfMeasurementForProperty(
      'buildingSize',
      ModelsWithUnitsOfMeasurement.Property,
      firstLeaseItem?.measurementSystem,
    );

  //Market Avg Line
  const graphDataPoints = prepareDataPoints({
    graphId,
    averages: historicalAverage?.averages,
    leases: [],
    rentType: toggleOptions?.rentType,
    timeMeasurementType: toggleOptions?.timeMeasurement,
    refStartDate,
    refMaxYears,
    isQuarterly,
    currencyCode: property?.propertyCountry?.currencyCode,
  });

  //Lease bubbles
  const graphDataLeases = parseLeasesItemsToDataPoints({
    graphId,
    leases,
    rentType: toggleOptions?.rentType,
    timeMeasurementType: toggleOptions?.timeMeasurement,
  });

  const isMonthly = isMonthlyTimeMeasurement(toggleOptions.timeMeasurement);
  const isNNN = isRentTypeNNN(toggleOptions.rentType);

  const baseRentFSField = isMonthly
    ? 'baseRentFullServiceEquivMonthly'
    : 'baseRentFullServiceEquivAnnual';
  const baseRentNNNField = isMonthly
    ? 'baseRentNNNEquivMonthly'
    : 'baseRentNNNEquivAnnual';

  const fieldName = isNNN ? baseRentNNNField : baseRentFSField;

  const minValue = minBy(leaseItems, fieldName)?.value || 0;
  const maxValue = maxBy(leaseItems, fieldName)?.value || 0;
  const yAxis = [minValue, maxValue];
  const xAxis = uniq(leaseItems?.map((d: IGraphLeasesItem) => d.year));

  return {
    yAxis,
    xAxis,
    graphDataPoints,
    graphDataLeases,
    graphParams: {
      rentType,
      currencySymbol: currency,
      unitOfMeasurement,
    },
  };
};
