import { ICompany } from 'interfaces/ICompany';
import {
  ModalNames,
  ModalTypes,
  IMutationParamsTypes,
} from 'constants/CompanyUpdatesModal';
import {
  GET_COMPANY_QUERY,
  CREATE_COMPANY_MUTATION,
  UPDATE_COMPANY_MUTATION,
  DELETE_COMPANY_MUTATION,
} from 'graphql/company';

export const refetchQuery = (companyId?: number) => {
  if (!companyId) return;

  return [
    {
      query: GET_COMPANY_QUERY,
      variables: { id: companyId },
      skip: !companyId,
    },
  ];
};

export const buildMutationParams = (
  modalType: ModalTypes,
  companyData: ICompany,
  isDelete: boolean,
) => {
  const {
    id,
    name,
    legalName,
    operatingName,
    headquarters,
    industry,
    website,
    domain,
    stockCode,
    headquarterProperty,
    linkedIn,
    investorType,
    comments,
  } = companyData;

  const mutationParams: IMutationParamsTypes = {
    mutation: UPDATE_COMPANY_MUTATION,
    variables: undefined,
  };

  if (isDelete) {
    mutationParams.mutation = DELETE_COMPANY_MUTATION;
    mutationParams.variables = { id: id || 0 };
  } else {
    const isCreateMutation = modalType === ModalNames.CREATE;
    mutationParams.mutation = isCreateMutation
      ? CREATE_COMPANY_MUTATION
      : UPDATE_COMPANY_MUTATION;
    const defaultMutationValue = {
      name,
      comments,
      industry: industry?.name,
      legalName,
      operatingName,
      headquarters,
      website,
      domain,
      stockCode,
      linkedIn,
      headquarterPropertyId: headquarterProperty?.id || null,
      investorTypeId: investorType?.id ?? null,
    };
    const companyValue = isCreateMutation
      ? defaultMutationValue
      : { ...defaultMutationValue, id };

    mutationParams.variables = {
      company: companyValue,
    };
  }

  return {
    mutation: mutationParams.mutation,
    variables: mutationParams.variables,
    refetchQueries: refetchQuery(companyData.id),
  };
};
