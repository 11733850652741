import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH,
} from 'constants/i18n';
import { translateText } from 'utils/i18n';

export const I18N_COMP_SET_AVAILABILITIES_LABEL_PATH = `${I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH}.availabilities`;

export enum AskingRentOptions {
  BLOCKS = 'blocks',
  TOTAL_SF = 'total-sf',
}

export const ASKING_RENT_CHART_TOGGLE_OPTIONS = [
  {
    value: AskingRentOptions.BLOCKS,
    text: translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.blocks`),
  },
  {
    value: AskingRentOptions.TOTAL_SF,
    text: translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.totalSf`),
  },
];

export const BLOCKS_BY_RENT_CHART = 'blocks-by-rent-chart';
export const BLOCKS_BY_SIZE_CHART = 'blocks-by-size-chart';
