import { IdName } from 'interfaces/IdName';
import { PropertyPricing } from 'constants/propertyPricing';
import { TranslateFunction } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export const getPricingTypeOptions = (t: TranslateFunction): IdName[] => {
  const commonSourceTypes = [
    {
      id: PropertyPricing.ANNUAL,
      name: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.annual`),
    },
    {
      id: PropertyPricing.MONTHLY,
      name: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.monthly`),
    },
  ];

  return commonSourceTypes;
};
