import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import DeleteIcon from 'assets/images/icons/delete.svg';
import { Container } from 'components/@codelitt/ay-design-library';
import NotificationMessage from 'components/NotificationMessage';
import ConfirmationModal from 'components/ConfirmationModal';
import LoadingMessage from 'components/LoadingMessage';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { SortDirections } from 'constants/sortDirections';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import {
  DELETE_PROPERTY_PRICE_QUOTE_MUTATION,
  UPDATE_PROPERTY_PRICE_QUOTE_MUTATION,
  GET_PROPERTY_PRICE_QUOTE,
  SEARCH_PROPERTY_PRICE_QUOTES,
} from 'graphql/propertyPriceQuote';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import { BUILDING_INFO_KEY } from 'pages/PropertyPage/constants';
import locations from 'routes';

import { parsePropertyPriceQuote } from './utils';
import { buildPropertyPriceQuotePayload } from '../PricingQuoteCreateForm/utils';
import PricingQuoteForm from '../PricingQuoteForm';
import styles from '../EditCompForm/EditCompForm.module.scss';

export interface Props {
  pricingQuoteId: number;
  propertySetId?: number;
  onCancel: () => void;
  onUpdated?: () => void;
  onDeleted?: () => void;
  title?: string;
  showDeleteButton?: boolean;
}

const PricingQuoteEditForm: React.FC<Props> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState<string>('');
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const refetchQueries = () => {
    return [
      {
        query: SEARCH_PROPERTY_PRICE_QUOTES,
        variables: {
          search: {
            filter: {
              propertyId: +params.id,
              competitiveSet: true,
              propertySetId: props.propertySetId,
            },
            order: {
              field: 'date',
              direction: SortDirections.desc,
            },
          },
        },
      },
      {
        query: SEARCH_PROPERTY_PRICE_QUOTES,
        variables: {
          search: {
            filter: {
              propertyId: +params.id,
            },
            order: {
              field: 'date',
              direction: SortDirections.desc,
            },
          },
        },
      },
    ];
  };

  const [deletePriceQuote, { loading: deletePriceQuoteLoading }] = useMutation(
    DELETE_PROPERTY_PRICE_QUOTE_MUTATION,
    {
      refetchQueries: refetchQueries(),
      variables: { id: props.pricingQuoteId },
      onCompleted: () => {
        props.onDeleted?.();
        setShowConfirmDelete(false);
        const propertyPath = locations.showProperty(
          priceQuote?.propertyPriceQuote?.property.id!,
        );
        const path = `${propertyPath}/${BUILDING_INFO_KEY}`;
        history.push(path);
      },
      onError: e => {
        setMessageError(e.message);
        setShowError(true);
      },
    },
  );

  const [updatePriceQuite, { loading: updatePriceQuoteLoading }] = useMutation(
    UPDATE_PROPERTY_PRICE_QUOTE_MUTATION,

    {
      refetchQueries: refetchQueries(),
      onCompleted: data => {
        if (data?.updatePropertyPriceQuote.id) {
          props.onUpdated?.();
        }
      },
      onError: e => {
        setMessageError(e.message);
        setShowError(true);
      },
    },
  );

  const { data: priceQuote, loading: isLoadingPriceQuote } = useQuery(
    GET_PROPERTY_PRICE_QUOTE,
    {
      skip: !props.pricingQuoteId,
      variables: {
        id: props.pricingQuoteId,
      },
    },
  );

  const updatePricingQuote = async (propertyPriceQuote: IPricingQuoteInput) => {
    const propertyPriceQuotePayload = buildPropertyPriceQuotePayload(
      propertyPriceQuote,
      measurement,
    );
    await updatePriceQuite({
      variables: { propertyPriceQuote: propertyPriceQuotePayload },
    });
  };

  const measurement =
    priceQuote?.property?.propertyCountry?.areaMeasurement ??
    UnitOfMeasurement.sf;

  const renderHeader = () => {
    if (!props.title && !props.showDeleteButton) {
      return null;
    }

    return (
      <div className={styles['action-container']}>
        {!!props.title && <div className={styles['title']}>{props.title}</div>}
        {props.showDeleteButton && (
          <button
            className={styles['delete-button']}
            onClick={() => setShowConfirmDelete(true)}
          >
            <>
              {t(`${I18N_PLATFORM_COMMON_WORD_PATH}.deletePricingQuote`)}
              <img
                src={DeleteIcon}
                className={styles['delete-icon']}
                alt="Delete"
              />
            </>
          </button>
        )}
        {showConfirmDelete && (
          <ConfirmationModal
            onCloseModal={() => setShowConfirmDelete(false)}
            negativeButton={{
              label: deletePriceQuoteLoading
                ? t('avantProperties.labels.common.pleaseWait')
                : t('avantProperties.labels.common.yesDeleteIt'),
              isDisabled: deletePriceQuoteLoading,
              onclick: deletePriceQuote,
            }}
            positiveButton={{
              label: t('avantProperties.labels.common.noKeepIt'),
              onclick: () => setShowConfirmDelete(false),
            }}
            header={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.deletePricingQuote`)}
            paragraph={[
              `${t(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.aboutToDeletePricingQuote`,
              )}.`,
              t('avantProperties.texts.common.askToProceed'),
            ]}
          />
        )}
      </div>
    );
  };

  if (isLoadingPriceQuote) {
    return (
      <Container>
        <LoadingMessage />
      </Container>
    );
  }

  const parsedPricingQuote = parsePropertyPriceQuote(
    priceQuote.propertyPriceQuote,
  );
  return (
    <Container>
      {renderHeader()}
      <PricingQuoteForm
        onSubmit={async (pricingQuote: IPricingQuoteInput) => {
          await updatePricingQuote(pricingQuote);
        }}
        submitButtonLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.update`)}
        pricingQuote={parsedPricingQuote}
        onCancel={props.onCancel}
        isSubmitting={updatePriceQuoteLoading}
      />
      <NotificationMessage
        show={showError}
        text={messageError}
        onClose={() => {
          setShowError(false);
        }}
      />
    </Container>
  );
};

export default PricingQuoteEditForm;
