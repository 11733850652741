import { IOption } from 'components/OptionsContainer';
import locations from 'routes';
import { CompType } from 'constants/compType';
import * as H from 'history';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export const getAddButtonOptions = (
  history: H.History,
  onOpenScoopModal: () => void,
  onOpenAddHistoricalPerformanceModal: () => void,
  onOpenCompSetModal: () => void,
  onOpenLeasingAssignmentModal: () => void,
): IOption[] => [
  {
    type: 'scoop',
    onClick: onOpenScoopModal,
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.scoop`),
  },
  {
    type: 'tenant_tour_creation',
    onClick: () => history.push(locations.showTenantTourCreationPage()),
    label: translateText('avantPlatform.attributes.compSet.label.tenantTour'),
  },
  {
    type: 'comp_set_creation',
    onClick: onOpenCompSetModal,
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.compSet`),
  },
  {
    type: 'leasign_assignment',
    onClick: onOpenLeasingAssignmentModal,
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.leasingAssignment`),
  },
  {
    type: 'pricing_quote',
    onClick: () =>
      history.push(locations.newComps(), {
        tabType: CompType.pricingQuote,
      }),
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.pricingQuote`),
  },
  {
    type: 'property',
    onClick: () =>
      history.push(locations.newComps(), {
        tabType: CompType.property,
      }),
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.property`),
  },
  {
    type: 'lease',
    onClick: () =>
      history.push(locations.newComps(), {
        tabType: CompType.lease,
      }),
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease`),
  },
  {
    type: 'sale',
    onClick: () =>
      history.push(locations.newComps(), {
        tabType: CompType.sale,
      }),
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.sale`),
  },
  {
    type: 'tenantInMarket',
    onClick: () =>
      history.push(locations.newComps(), {
        tabType: CompType.tenantInMarket,
      }),
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenantInMarket`),
  },
  {
    type: 'company',
    onClick: () =>
      history.push(locations.newComps(), {
        tabType: CompType.company,
      }),
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.company`),
  },
  {
    type: 'historical_performance',
    onClick: onOpenAddHistoricalPerformanceModal,
    label: translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.historicalPerformance`,
    ),
  },
  {
    type: 'upload',
    onClick: () => history.push(locations.uploadComps()),
    label: translateText('avantProperties.labels.common.uploadData'),
  },
];
