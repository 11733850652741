import { I18N_LEASE_LABEL_PATH } from 'components/Leases/constants';
import { colors } from 'constants/colors';
import { TranslateFunction } from 'utils/i18n';

export const getSubmarketLeasesLegends = (translate: TranslateFunction) => [
  {
    color: colors.supportive500,
    label: translate(`${I18N_LEASE_LABEL_PATH}.submarketAverage`),
    isLine: true,
  },
  {
    color: colors.primaryColor500,
    label: translate(`${I18N_LEASE_LABEL_PATH}.recentLeases`),
  },
];
