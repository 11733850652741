import locations from 'routes';
import { uniqBy } from 'lodash';
import { ICompany } from 'interfaces/ICompany';
import { getCompsQueryParams } from 'components/Graphs/utils/getCompsQueryParams';
import {
  IGraphOwnerPortfolioLease,
  IGraphOwnerPortfolioProperty,
  IPortfolioDataPoint,
} from './interfaces';
import {
  getPredominantMeasurementSystem,
  getUnitsOfMeasurementText,
} from 'utils/unitsOfMeasurement/graphs';
import { FindCompTabs } from 'constants/FindCompTabs';
import { History } from 'history';
import { IProperty } from 'interfaces/IProperty';
import { IChartQueryOptions } from 'interfaces/IChartQueryOptions';
import { CURRENT_LEASES_ID } from 'components/FindComps/EditSearchCriteria/StaticFilters/leaseSort';

export const MAX_OWNER_TITLE_LENGTH = 22;

export const parseLeasesToDataPoints = (
  leases: IGraphOwnerPortfolioLease[],
): IPortfolioDataPoint[] =>
  leases.map(l => ({
    id: l.id,
    size: l.size,
    sizeMt: l.sizeMt,
    measurementSystem: l.measurementSystem,
    propertyId: l.propertyId,
    name: l.property.name,
    status: l.property.status,
    propertyClass: l.property.propertyClass,
    propertyType: l.property.propertyType,
    market: l.property.market,
    marketId: l.property.marketId,
    submarketId: l.property.submarketId,
    submarket: l.property.submarket,
    micromarket: l.property.micromarket,
    primaryAddress: l.property.primaryAddress,
    coverMediaUrl: l.property.coverMediaUrl,
    latitude: l.property.latitude,
    longitude: l.property.longitude,
    x: 0,
    y: 0,
    r: 0,
  }));

export const parsePropertiesToDataPoints = (
  properties: IGraphOwnerPortfolioProperty[],
): IPortfolioDataPoint[] =>
  properties.map(property => ({
    id: property.id,
    size: property.size,
    name: property.name,
    propertyId: property.id,
    sizeMt: property.sizeMt,
    status: property.status,
    market: property.market,
    marketId: property.marketId,
    ownerIds: property.ownerIds,
    submarket: property.submarket,
    submarketId: property.submarketId,
    micromarket: property.micromarket,
    propertyType: property.propertyType,
    propertyClass: property.propertyClass,
    primaryAddress: property.primaryAddress,
    measurementSystem: property.measurementSystem,
    coverMediaUrl: property.coverMediaUrl,
    latitude: property.latitude,
    longitude: property.longitude,
    x: 0,
    y: 0,
    r: 0,
  }));

export const getUnitsOfMeasurementForDataPoints = (
  data: IGraphOwnerPortfolioLease[] | IGraphOwnerPortfolioProperty[],
) => {
  const unitsOfMeasurement = (data as any).map((d: any) => d.measurementSystem);
  return getUnitsOfMeasurementText(unitsOfMeasurement || []);
};

export const getMeasurementSystem = (
  data: IGraphOwnerPortfolioLease[] | IGraphOwnerPortfolioProperty[],
) => {
  const unitsOfMeasurement = (data as any).map((d: any) => d.measurementSystem);
  return getPredominantMeasurementSystem(unitsOfMeasurement);
};

const redirectToFindComps = (
  history: History,
  resultActiveTab: FindCompTabs,
  queryParams: IChartQueryOptions,
) =>
  history.push(
    locations.findComps({
      resultActiveTab,
      urlParams: getCompsQueryParams(queryParams),
    }),
  );

const getMarketsForRedirection = (dataPoints: IPortfolioDataPoint[]) =>
  uniqBy(
    dataPoints.map(property => ({
      id: property.marketId,
      name: property.market,
    })),
    'name',
  );

const getCompanyRedirectionParams = (
  company: ICompany,
  dataPoints: IPortfolioDataPoint[],
  isOccupierSection: boolean,
) => {
  const leasesCriteria = isOccupierSection
    ? {
        tenants: [{ id: company?.id, name: company?.name }],
        sort: { id: CURRENT_LEASES_ID },
        onlyNotExpired: true,
      }
    : undefined;

  const owners = !isOccupierSection
    ? [
        {
          id: company?.id,
          name: company?.name,
        },
      ]
    : undefined;

  return {
    company,
    customCriteria: {
      owners,
      markets: getMarketsForRedirection(dataPoints),
      longLeaseHolders: owners,
    },
    leasesCriteria,
  };
};

export const goToFindCompsFromCompany = (
  company: ICompany,
  dataPoints: IPortfolioDataPoint[],
  history: History,
  isOccupierSection: boolean,
) =>
  redirectToFindComps(
    history,
    isOccupierSection ? FindCompTabs.leases : FindCompTabs.properties,
    getCompanyRedirectionParams(company, dataPoints, isOccupierSection),
  );

export const goToFindCompsFromProperty = (
  property: IProperty,
  dataPoints: IPortfolioDataPoint[],
  history: History,
) => {
  const ownersAndLongLeaseHolders = [
    ...(property?.longLeaseHolders ?? []),
    ...(property.owners ?? []),
  ];
  return redirectToFindComps(history, FindCompTabs.properties, {
    property,
    customCriteria: {
      longLeaseHolders: ownersAndLongLeaseHolders?.map(({ id, name }) => ({
        id,
        name,
      })),
      owners: ownersAndLongLeaseHolders?.map(({ id, name }) => ({
        id,
        name,
      })),
      markets: getMarketsForRedirection(dataPoints),
    },
  });
};
