import React from 'react';
import classnames from 'classnames';
import Truncate from 'components/Truncate';
import styles from './Tabs.module.scss';
import { useUser } from 'contexts/AuthContext';
import { isUserUsingEnglishLanguage } from 'utils/user';

export type TabType = {
  name: string;
  header: string | React.ReactNode;
  content: React.ReactNode;
};

type Props = {
  isDisabled?: boolean;
  tabs: TabType[];
  activeTabName: string;
  onActiveTabChange: (tab: TabType) => void;
  tabContainerClassName?: string;
  tabItemContainerClassName?: string;
  tabItemClassName?: string;
  tabContentContainerClassName?: string;
  tabContentClassName?: string;
};

const Tabs: React.FC<Props> = (props: Props) => {
  const { user } = useUser();
  const renderTabs = (tabItemClassName: Props['tabItemClassName']) => {
    return props.tabs.map((tab: TabType) => {
      return (
        <div
          key={tab.name}
          onClick={() => {
            !props.isDisabled && props.onActiveTabChange(tab);
          }}
          className={classnames(tabItemClassName, styles.item, {
            [styles.disabled]: props.isDisabled,
            [styles.selected]: props.activeTabName === tab.name,
          })}
        >
          {typeof tab.header == 'string' &&
          !isUserUsingEnglishLanguage(user) ? (
            <Truncate wrapperClassName={styles.truncate}>{tab.header}</Truncate>
          ) : (
            tab.header
          )}
        </div>
      );
    });
  };

  const renderContents = (
    tabContentClassName: Props['tabContentClassName'],
  ) => {
    return props.tabs.map((tab: TabType) => {
      return (
        <div
          key={tab.name}
          className={classnames(tabContentClassName, styles['tab-content'], {
            [styles.visible]: props.activeTabName === tab.name,
          })}
        >
          {tab.content}
        </div>
      );
    });
  };

  return (
    <div className={classnames(props.tabContainerClassName, styles['tabs'])}>
      <div
        className={classnames(
          props.tabItemContainerClassName,
          styles['tab-items'],
        )}
      >
        {renderTabs(props.tabItemClassName)}
      </div>

      <div
        className={classnames(
          props.tabContentContainerClassName,
          styles['tab-body'],
        )}
      >
        {renderContents(props.tabContentClassName)}
      </div>
    </div>
  );
};

export default Tabs;
