import { IdName } from 'interfaces/IdName';
import { getFormattedField, PricingQuoteCardField } from './fields';
import { IPricingQuote } from 'interfaces/IPricingQuote';
import { formatDate } from 'utils/formatters/date';
import dateFormats from 'constants/dateFormats';

export const buildTagsListItems = (
  pricingQuote: IPricingQuote,
  displayFields: PricingQuoteCardField[],
): IdName[] =>
  displayFields
    .map(field => getFormattedField(pricingQuote, field))
    .filter(Boolean)
    .map((tag, i) => ({ id: i, name: tag }));

export const buildPricingQuoteDate = (pricingQuote: IPricingQuote) => {
  const pricingQuoteDate = formatDate(pricingQuote.date, dateFormats.US_DATE);
  return `As of ${pricingQuoteDate}`;
};
