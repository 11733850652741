import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { select, selectAll } from 'd3-selection';
import { graphDimensions } from '../graphConstants';
import { ITransactionVolumeDataPoint } from '../interfaces';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import { GraphDimension } from '../index';
import { colors } from 'constants/colors';
import { getMinMaxValues } from '../utils';
import styles from '../TransactionVolume.module.scss';
import {
  getRhombusOutlineId,
  getTrianglePurchasedId,
  getTriangleSoldId,
  getYearId,
  OUTLINE_RHOMBUS_CLASS,
  TRIANGLE_PURCHASED_CLASS,
  TRIANGLE_SOLD_CLASS,
  YEAR_TEXT_CLASS,
} from '../nodes';
import {
  getRhombusOutline,
  getTrianglePathPurchased,
  getTrianglePathSold,
} from './elementsPaths';

export enum TransactionType {
  purchase,
  sell,
}

interface Props {
  data: ITransactionVolumeDataPoint[];
  graphDimension: GraphDimension;
  onHoverDataPoint: (dataPoint?: ITransactionVolumeDataPoint) => void;
  onClick?: (
    transactionType: TransactionType,
    dataPoint: ITransactionVolumeDataPoint,
  ) => void;
}

const INACTIVE_OPACITY = 0.2;
const HOVER_OPACITY = 0.25;
const STROKE_WIDTH = 8;

const Rhombus: React.FC<Props> = props => {
  const svgGroupRef = useRef<SVGGElement>(null);
  const { data, onHoverDataPoint, onClick } = props;

  const ALL_ELEMENTS_SELECTOR = `.${YEAR_TEXT_CLASS}, .${TRIANGLE_SOLD_CLASS}, .${TRIANGLE_PURCHASED_CLASS}`;

  const onMouseOverDataPoint = (dataPoint: ITransactionVolumeDataPoint) => {
    onHoverDataPoint(dataPoint);

    // Make all elements with the inactive opacity
    selectAll(ALL_ELEMENTS_SELECTOR).attr('opacity', INACTIVE_OPACITY);

    // Change the opacity of the hovered element, so it will be highlighted
    selectAll(
      `#${getTrianglePurchasedId(dataPoint)}, #${getTriangleSoldId(dataPoint)}`,
    ).attr('opacity', 1);

    // Change the opacity of the year of the hovered element, so it will be highlighted
    select(`#${getYearId(dataPoint)}`).attr('opacity', 1);

    select(`#${getRhombusOutlineId(dataPoint)}`)
      .attr('stroke', colors.ayWinterGreyColor)
      .attr('stroke-opacity', HOVER_OPACITY);
  };

  /**
   * Reset the state of all elements when the mouse is out.
   */
  const onMouseOutDataPoint = () => {
    onHoverDataPoint(undefined);

    selectAll(ALL_ELEMENTS_SELECTOR).attr('opacity', 1);

    selectAll(`.${OUTLINE_RHOMBUS_CLASS}`).attr('stroke-opacity', 0);
  };

  useEffect(() => {
    const { minValue, maxValue } = getMinMaxValues(data);

    // The xScale uses the years and graph width to determine the X position for each year alongside the graph container.
    const xScale: ScaleLinear<number, number> = scaleLinear()
      .domain([d3.min(data, d => d.year)!, d3.max(data, d => d.year)!])
      .range([
        graphDimensions.TRIANGLE_WIDTH / 2,
        props.graphDimension.width - graphDimensions.TRIANGLE_WIDTH,
      ]);

    const halfHeight = Math.ceil(graphDimensions.HEIGHT / 2);

    // This padding adds a small distance from the top and bottom lines, so the triangles will never reach those lines.
    const paddingYScale = 8;

    // The Y scale uses the min and max values from the data, so we can use to determine the height of each triangle.
    // It also adds a padding from the top of graph, so the triangle won't touch the first line.
    const yScale = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([paddingYScale, halfHeight]);

    // This scale adds a padding from the bottom of the graph, so the triangle won't touch the last line.
    const yScaleBottom = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([0, halfHeight - paddingYScale]);

    const svgGroup = d3.select(svgGroupRef.current!);

    const textPaddingY = 3;
    const textPaddingX = 0;
    svgGroup
      .selectAll(`.${YEAR_TEXT_CLASS}`)
      .data(data)
      .enter()
      .append('text')
      .attr('id', getYearId)
      .text(d => d.year)
      .attr(
        'x',
        d => xScale(d.year) - graphDimensions.TRIANGLE_WIDTH / 2 + textPaddingX,
      )
      .attr('y', halfHeight + textPaddingY)
      .attr('class', `${styles['year-value']} ${YEAR_TEXT_CLASS}`);

    svgGroup
      .selectAll(`.${OUTLINE_RHOMBUS_CLASS}`)
      .data(data)
      .join('path')
      .attr('d', d =>
        getRhombusOutline(
          d,
          halfHeight,
          xScale,
          yScale as ScaleLinear<number, number, never>,
          yScaleBottom as ScaleLinear<number, number, never>,
        ),
      )
      .attr('id', getRhombusOutlineId)
      .attr('fill', 'transparent')
      .attr('class', OUTLINE_RHOMBUS_CLASS)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-width', STROKE_WIDTH)
      .on('mouseover', d => onMouseOverDataPoint(d))
      .on('mouseout', onMouseOutDataPoint);

    svgGroup
      .selectAll(`.${TRIANGLE_PURCHASED_CLASS}`)
      .data(data)
      .join('path')
      .attr('d', d =>
        getTrianglePathPurchased(
          d,
          halfHeight,
          xScale,
          yScale as ScaleLinear<number, number, never>,
        ),
      )
      .attr('id', getTrianglePurchasedId)
      .attr('fill', colors.supportive500)
      .attr('class', TRIANGLE_PURCHASED_CLASS)
      .attr('stroke-linejoin', 'round')
      .attr('stroke', colors.supportive500)
      .attr('stroke-width', STROKE_WIDTH)
      .attr('stroke-opacity', 0)
      .style('cursor', 'pointer')
      .on('click', (d: ITransactionVolumeDataPoint) =>
        onClick?.(TransactionType.purchase, d),
      )
      .on('mouseover', d => onMouseOverDataPoint(d))
      .on('mouseout', onMouseOutDataPoint);

    svgGroup
      .selectAll(`.${TRIANGLE_SOLD_CLASS}`)
      .data(data)
      .join('path')
      .attr('d', d =>
        getTrianglePathSold(
          d,
          halfHeight,
          xScale,
          yScaleBottom as ScaleLinear<number, number, never>,
        ),
      )
      .attr('id', getTriangleSoldId)
      .attr('fill', colors.primaryColor500)
      .attr('stroke-linejoin', 'round')
      .attr('stroke', colors.primaryColor500)
      .attr('stroke-width', STROKE_WIDTH)
      .attr('stroke-opacity', 0)
      .attr('class', TRIANGLE_SOLD_CLASS)
      .style('cursor', 'pointer')
      .on('click', (d: ITransactionVolumeDataPoint) =>
        onClick?.(TransactionType.sell, d),
      )
      .on('mouseover', d => onMouseOverDataPoint(d))
      .on('mouseout', onMouseOutDataPoint);

    // eslint-disable-next-line
  }, [data]);

  return <g ref={svgGroupRef} />;
};

export default Rhombus;
