import React from 'react';
import locations from 'routes';
import classnames from 'classnames';
import AYLink from 'components/AYLink';
import { IdName } from 'interfaces/IdName';
import styles from './LinksList.module.scss';
import { DOT } from 'constants/placeholders';

interface Props {
  list: IdName[];
  bold?: boolean;
  separator?: string;
}

const LinksList: React.FC<Props> = ({ list, bold, separator = ` ${DOT} ` }) => (
  <pre
    className={classnames(styles['field-text'], {
      [styles.bold]: bold,
    })}
  >
    {list?.map((item, index) => {
      const isLastItem = list.length - 1 === index;

      return (
        <AYLink
          key={item.id}
          wrapperClassName={styles.link}
          to={locations.showCompany(item.id!)}
        >
          {isLastItem ? item.name : `${item.name}${separator}`}
        </AYLink>
      );
    })}
  </pre>
);

export default LinksList;
