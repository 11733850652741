import React, { Component } from 'react';
import { msalApp, GRAPH_REQUESTS, acquireMSToken } from 'services/MSAL';
import { WindowUtils } from 'msal';
import { AVANT_CITIES_ACCESS_TOKEN } from 'clients/avantCities/constants';
import jwtDecode from 'jwt-decode';
import { LocalStorageKeys } from 'constants/localStorageKeys';

type AuthProviderState = {
  account: object | null;
  isLoading: boolean;
  error: string | null;
  graphProfile: object | null;
  msToken: string | null;
};

const MSAuthProvider = (MSWrapper: any) =>
  class AuthProvider extends Component<any, AuthProviderState> {
    constructor(props: any) {
      super(props);

      this.state = {
        account: null,
        isLoading: true,
        error: null,
        graphProfile: null,
        msToken: null,
      };
    }

    async componentDidMount() {
      let msToken = null;
      let graphProfile = null;
      let error = null;

      msalApp.handleRedirectCallback(e => {
        if (e) {
          error = e.errorMessage
            ? e.errorMessage
            : 'Unable to acquire access token.';
        }
      });

      const account = msalApp.getAccount();

      if (account && !WindowUtils.isInIframe()) {
        const tokenResponse = await acquireMSToken(GRAPH_REQUESTS.API);

        if (tokenResponse) {
          msToken = tokenResponse.accessToken;
          const tokenInfo: any = jwtDecode(msToken);
          localStorage.setItem(AVANT_CITIES_ACCESS_TOKEN, msToken);
          localStorage.setItem(
            LocalStorageKeys.azureTokenExpirationDate,
            tokenResponse.expiresOn.getTime().toString(),
          );
          graphProfile = {
            givenName: tokenInfo.given_name,
            surname: tokenInfo.family_name,
          };
        }
      }

      if (error) {
        console.error(error);
      }

      this.setState({
        account,
        graphProfile,
        msToken,
        error,
        isLoading: false,
      });
    }

    async onSignIn(email: string | null): Promise<void> {
      // MS creates a hidden Iframe to try to acquire a token silently, and this shouldn't be executed if it's running on the iframe.
      if (WindowUtils.isInIframe()) {
        return;
      }
      return msalApp.loginRedirect({
        ...GRAPH_REQUESTS.API,
        redirectUri: window._env_.SSO_REDIRECT_URL,
        loginHint: email || '',
      });
    }

    onSignOut() {
      msalApp.logout();
    }

    render() {
      return (
        <MSWrapper
          {...this.props}
          msAccount={this.state.account}
          msGraphProfile={this.state.graphProfile}
          msError={this.state.error}
          msToken={this.state.msToken}
          isLoadingMSAccount={this.state.isLoading}
          onMSSignIn={this.onSignIn}
          onMSSignOut={() => this.onSignOut()}
        />
      );
    }
  };

export default MSAuthProvider;
