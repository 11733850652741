import React, { useState } from 'react';
import AgencyAssignmentsPropertyCard from 'components/AgencyAssignmentsPropertyCard';
import PropertiesMap from 'components/PropertiesMap';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyGeolocation } from 'interfaces/IPropertiesGeolocation';
import { AgencyAssignmentsProps } from 'components/AgencyAssignments/types';
import { colors } from 'constants/colors';
import KPIs from '../KPIs';
import styles from './SummaryContainer.module.scss';

const SummaryContainer: React.FC<AgencyAssignmentsProps> = ({
  propertySet,
  toggleOptions,
  askingRentData,
}) => {
  const [propertyIdHover, setPropertyIdHover] = useState<number | undefined>();

  const propertiesIds = propertySet?.properties?.map(property => property.id!);
  const getPinLabel = (properties: IProperty[], propertyId: number) => {
    const pinProperty = properties?.find(x => x.id === propertyId);

    if (pinProperty) {
      return (properties?.indexOf(pinProperty!) || 0) + 1;
    }
    return 0;
  };

  const sortGeoProperties = (geoProperties: IPropertyGeolocation[]) => {
    if (!propertiesIds?.length) return geoProperties;
    return geoProperties.sort((a, b) => {
      return propertiesIds.indexOf(a.id) - propertiesIds.indexOf(b.id);
    });
  };

  return (
    <div className={styles['summary-container']}>
      <KPIs
        askingRentData={askingRentData}
        propertySet={propertySet}
        toggleOptions={toggleOptions}
      />
      <PropertiesMap
        propertiesFilter={{
          ids: propertiesIds || [],
        }}
        pinLabelFn={getPinLabel}
        isPinOpaque={false}
        onMouseEnterPropertyPin={setPropertyIdHover}
        propertyIdHover={propertyIdHover}
        hoverPinColor={colors.secondaryColor500}
        onSortGeoProperties={sortGeoProperties}
      />
      <div className={styles['property-cards-content']}>
        {propertySet?.properties?.map((property, index) => (
          <AgencyAssignmentsPropertyCard
            key={property.id}
            property={property}
            number={index + 1}
            setPropertyIdHover={setPropertyIdHover}
            propertyIdHover={propertyIdHover}
          />
        ))}
      </div>
    </div>
  );
};

export default SummaryContainer;
