import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { colors } from 'constants/colors';
import { IHoldPeriodData } from '../interfaces';
import dayjs from 'dayjs';
import { graphDimensions } from '../graphConstants';
import { GraphIds } from '../nodes';
import { MIN_WIDTH_BAR_RIGHT } from './constants';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_CHART_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

interface Props {
  isMktHigher: boolean;
  data: IHoldPeriodData;
  containerWidth: number;
  firstRectWidth: number;
  secondRectWidth: number;
  isBuildingInfoVisible: boolean;
}

const MAX_WIDTH = 80;
const BOTTOM_Y = graphDimensions.SEGMENT_HEIGHT + 18;
const TOP_Y = -9;

const Tooltips: React.FC<Props> = (props: Props) => {
  const tooltipRef = useRef(null);
  const {
    data,
    isMktHigher,
    containerWidth,
    firstRectWidth,
    secondRectWidth,
    isBuildingInfoVisible,
  } = props;

  const getSellQuarterLegend = () => {
    const sellDate = dayjs.utc(data.holdPeriodDate);
    return `Q${sellDate.quarter()} ${sellDate.year()}`;
  };

  const getTextWidth = (element: any) => {
    return select(element)
      .node()
      .getBBox().width;
  };

  const tooltipYPosition =
    isMktHigher && secondRectWidth <= MIN_WIDTH_BAR_RIGHT ? TOP_Y : BOTTOM_Y;

  const drawHoverTooltips = (tooltipNode: any) => {
    if (isBuildingInfoVisible) {
      tooltipNode
        .append('text')
        .attr('id', GraphIds.TooltipQuarter)
        .text(getSellQuarterLegend)
        .attr('fill', colors.ayPureWhiteColor)
        .style('font-size', 12)
        .attr('y', BOTTOM_Y)
        .attr('x', 0);

      tooltipNode
        .append('text')
        .attr('id', GraphIds.TooltipCurrentDate)
        .text(translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.today`))
        .attr('fill', colors.ayPureWhiteColor)
        .style('font-size', 12)
        .attr('y', tooltipYPosition)
        .attr('x', function(this: any) {
          if (isMktHigher) {
            const subtractToBaseX =
              secondRectWidth <= MAX_WIDTH
                ? secondRectWidth
                : getTextWidth(this);

            const baseX = isMktHigher ? firstRectWidth : secondRectWidth;
            return baseX - subtractToBaseX;
          } else {
            return containerWidth - getTextWidth(this);
          }
        });
    }

    tooltipNode
      .append('text')
      .attr('id', GraphIds.TooltipAvgHold)
      .text(
        translateText(
          `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.holdPeriod.averageHold`,
        ),
      )
      .attr('fill', colors.ayPureWhiteColor)
      .style('font-size', 12)
      .attr('y', BOTTOM_Y)
      .attr('x', function(this: any) {
        if (isMktHigher) {
          return containerWidth - getTextWidth(this);
        } else {
          const addToBaseX = firstRectWidth <= MAX_WIDTH ? firstRectWidth : 0;
          const baseX = isMktHigher ? secondRectWidth : firstRectWidth;
          return baseX - getTextWidth(this) + addToBaseX;
        }
      });
  };

  useEffect(() => {
    const tooltipNode = select(tooltipRef.current);
    drawHoverTooltips(tooltipNode);
    // eslint-disable-next-line
  }, []);

  return <g ref={tooltipRef} />;
};

export default Tooltips;
