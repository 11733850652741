import { isNil } from 'lodash';

import { ISnowflakeBlockAvailabilitySearch } from 'interfaces/ISnowflakeBlockAvailability';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_AVANT_PROPERTY_AGENCY_ASSIGNMENT_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH,
} from 'constants/i18n';
import { LeaseBaseRentTime, LeaseRentType } from 'constants/leases';
import {
  abbreviateNumber,
  formatNumberPercentage,
  formatNumberWithDecimals,
} from 'utils/formatters/number';
import { translateText } from 'utils/i18n';
import { getAvailabilityCurrencySymbol } from 'utils/blockAvailabilities';
import {
  CHART_PADDING,
  DEFAULT_BAR_STYLE,
} from 'components/VictoryBarChart/utils';
import { DATUM_ID_PREFIX } from 'components/MostExpensiveAndLargestCharts/utils';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { formatStringUsingElipsis } from 'utils/formatters/string';

const I18N_AVAILABILITIES_PATH = `${I18N_AVANT_PROPERTY_AGENCY_ASSIGNMENT_LABEL_PATH}.availabilities`;
const I18N_KPIS_PATH = `${I18N_AVAILABILITIES_PATH}.kpis`;
const I18N_COMP_SET_AVAILABILITIES_PATH = `${I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH}.availabilities`;
const I18N_COMP_SET_KPIS_PATH = `${I18N_COMP_SET_AVAILABILITIES_PATH}.kpis`;
export const ITEMS_TO_DISPLAY_ON_HIGHEST_BLOCKS_CHART = 6;
const MAX_PROPERTY_ADDRESS_LENGTH = 25;

export const buildInfoBoxData = (
  availabilityBlock: ISnowflakeBlockAvailabilitySearch | null,
  availabilityFilter: LeaseToggleOptions,
) => {
  if (isNil(availabilityBlock)) {
    return [];
  }

  const {
    annualTotalAskingRentFS,
    annualTotalAskingRentNNN,
    monthlyTotalAskingRentFS,
    monthlyTotalAskingRentNNN,
    numberOfAvailabilities,
    directAvailableSf,
    percentOfSupplyDirectAvailable,
    availabilitiesByProperties = [],
  } = availabilityBlock;
  const isRentTypeFS = availabilityFilter.rentType === LeaseRentType.FS;

  let directAskingRent;
  if (availabilityFilter.timeMeasurement === LeaseBaseRentTime.Annual) {
    directAskingRent = isRentTypeFS
      ? annualTotalAskingRentFS
      : annualTotalAskingRentNNN;
  } else {
    directAskingRent = isRentTypeFS
      ? monthlyTotalAskingRentFS
      : monthlyTotalAskingRentNNN;
  }

  const currencySymbol = getAvailabilityCurrencySymbol(
    availabilitiesByProperties[0],
  );

  return [
    {
      details: [
        {
          title: translateText(`${I18N_COMP_SET_KPIS_PATH}.numOfAvails`),
          value: `${abbreviateNumber(numberOfAvailabilities) || 0}`,
        },
      ],
    },
    {
      details: [
        {
          title: translateText(`${I18N_KPIS_PATH}.directAvailableSf`),
          value: `${abbreviateNumber(directAvailableSf) || 0}`,
        },
      ],
    },
    {
      details: [
        {
          title: translateText(`${I18N_KPIS_PATH}.directAvailability`),
          value: `${
            percentOfSupplyDirectAvailable
              ? formatNumberPercentage(percentOfSupplyDirectAvailable * 100)
              : 0
          }`,
        },
      ],
    },
    {
      details: [
        {
          title: translateText(
            `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.askingRent`,
          ),
          value: `${currencySymbol}${formatNumberWithDecimals(
            directAskingRent,
          ) || 0}`,
        },
      ],
    },
  ];
};

const SIZE_CHART_PADDING = {
  ...CHART_PADDING,
  right: 60,
};

export const getHighestAskingRentStyle = (isLargestAvailabilities = false) => {
  return {
    ...DEFAULT_BAR_STYLE,
    chart: {
      ...DEFAULT_BAR_STYLE.chart,
      ...(isLargestAvailabilities
        ? { padding: SIZE_CHART_PADDING }
        : undefined),
    },
    xAxis: {
      ...DEFAULT_BAR_STYLE.xAxis,
      tickFormat: (t = '') => {
        const tickFormatValue = `${t.toString().split(DATUM_ID_PREFIX)?.[0] ||
          t}`;
        const tickFormatedValue = formatStringUsingElipsis(
          tickFormatValue,
          MAX_PROPERTY_ADDRESS_LENGTH,
        );
        return tickFormatedValue;
      },
    },
  };
};
