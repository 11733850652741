export enum PropertyClass {
  Trophy = 'Trophy',
  'Trophy+' = 'Trophy+',
  'Trophy-' = 'Trophy-',
  A = 'A',
  'A+' = 'A+',
  'A-' = 'A-',
  B = 'B',
  C = 'C',
}
