import RemoveIcon from 'assets/images/icons/clear-icon-small.svg';
import { IdName } from 'interfaces/IdName';
import React from 'react';
import styles from './Chip.module.scss';
import classNames from 'classnames';

interface Props {
  item: IdName;
  onDelete: (item: IdName) => void;
  wrapperChipClass?: string;
}

const Chip: React.FC<Props> = props => {
  const { item, onDelete } = props;

  return (
    <div>
      <div
        key={item.id}
        className={classNames(props.wrapperChipClass || styles['chip'])}
      >
        {item.name}
        <img
          src={RemoveIcon}
          className={styles['button-remove']}
          alt="Remove"
          onClick={() => onDelete(item)}
        />
      </div>
    </div>
  );
};

export default Chip;
