import React, { Dispatch, SetStateAction } from 'react';

import FormControl from 'components/FormControl';
import DateInput from 'components/Inputs/DateInput';
import TextInput from 'components/Inputs/TextInput';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import {
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
} from 'constants/i18n';
import { ILeaseOption } from 'interfaces/ILeaseOption';
import { translateText } from 'utils/i18n';
import { formatNumberWithCommas } from 'utils/formatters/number';

import styles from '../../../FormSection.module.scss';

interface Props {
  currentOption: ILeaseOption;
  setCurrentOption: Dispatch<SetStateAction<ILeaseOption>>;
  commonFields: React.ReactNode;
}

export const ExpansionFields = ({
  currentOption,
  setCurrentOption,
  commonFields,
}: Props) => {
  return (
    <>
      <FormControl
        wrapperClassName={styles['form-row']}
        label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.expansionOptionDate`,
        )}
      >
        <DateInput
          id="expansion-option-date"
          value={currentOption.optionDate}
          onChange={value =>
            setCurrentOption({
              ...currentOption,
              optionDate: value ?? null,
            })
          }
        />
      </FormControl>
      <FormControl
        wrapperClassName={styles['form-row']}
        label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.expansionSize`,
        )}
      >
        <TextInput
          id="expansion-size"
          formatter={value => formatNumberWithCommas(value)}
          value={currentOption?.expansionSf}
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterSize`,
          )}
          onChange={value => {
            setCurrentOption({
              ...currentOption,
              expansionSf: value,
            });
          }}
        />
        <UnitOfMeasurementLegend
          unitOfMeasurement={UnitOfMeasurement.sf}
          shouldConvert={false}
        />
      </FormControl>
      {commonFields}
    </>
  );
};
