import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { getTooltipId, getTooltipRectId } from '../../RecentLeases/nodes';
import { RecentLeasesIds } from '../../RecentLeases/graphIds';
import { TOOLTIP_RECT } from './constants';

interface Props {
  graphId: RecentLeasesIds;
}

const Tooltip: React.FC<Props> = props => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    const node = tooltipRef.current;

    select(node)
      .append('rect')
      .attr('id', () => getTooltipRectId(props.graphId))
      .attr('width', TOOLTIP_RECT.width)
      .attr('height', TOOLTIP_RECT.height)
      .attr('x', TOOLTIP_RECT.x)
      .attr('y', TOOLTIP_RECT.y)
      .attr('rx', TOOLTIP_RECT.borderRadius)
      .attr('ry', TOOLTIP_RECT.borderRadius)
      .style('display', 'none');

    // eslint-disable-next-line
  }, []);

  return <g id={getTooltipId(props.graphId)} ref={tooltipRef} />;
};

export default Tooltip;
