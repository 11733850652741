import { ILease } from 'interfaces/ILease';
import { LeaseTenantInMarketType } from 'constants/leases';
import React from 'react';
import classnames from 'classnames';
import { Size } from 'components/Card';
import { TENANT_NOT_IN_MARKET_VALUES } from 'constants/leases';
import styles from './TenantInMarketFooter.module.scss';
import { getTenantInMarketData } from '../utils';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PLATFORM_LEASE_LABEL_PATH } from 'constants/i18n';

interface Props {
  lease: ILease;
  size?: Size;
  hasTopBorder?: boolean;
}

const TenantInMarketFooter: React.FC<Props> = ({
  lease,
  size,
  hasTopBorder,
}) => {
  const isMarketTagVisible =
    lease.tenantInMarketType === LeaseTenantInMarketType.YES ||
    !!lease.tenantInMarketSubtype;
  const isTenantInMarket =
    lease.tenantInMarketType === LeaseTenantInMarketType.YES;
  const tenantInMarketData = getTenantInMarketData(lease);
  const tenantInMarketSubtype = lease.tenantInMarketSubtype
    ? TENANT_NOT_IN_MARKET_VALUES[lease.tenantInMarketSubtype]
    : null;
  const tenantInMarketComments = lease.tenantInMarketComments;

  return isMarketTagVisible ? (
    <footer
      className={classnames(styles['footer-container'], {
        [styles['card-separator']]: hasTopBorder,
      })}
    >
      <div
        className={classnames(styles['tim-data'], {
          [styles[`${size || 'regular'}`]]: size,
        })}
      >
        <div className={classnames(styles['content-footer-tag'])}>
          <p
            className={classnames(styles['footer-tag'], {
              [styles['in-market']]: isTenantInMarket,
              [styles[`${size || 'regular'}`]]: size,
            })}
          >
            {isTenantInMarket
              ? translateText('avantPlatform.attributes.lease.label.inMarket')
              : tenantInMarketSubtype}
          </p>
        </div>
        {isTenantInMarket && !!tenantInMarketData && (
          <div className={classnames(styles['content-footer-text'])}>
            <p
              className={classnames(styles['footer-text'], {
                [styles[`${size || 'regular'}`]]: size,
              })}
            >
              {tenantInMarketData}
            </p>
          </div>
        )}
      </div>
      {!!tenantInMarketComments && (
        <p className={styles['tim-comments']}>
          <span>
            {translateText(
              `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.tenantInMarketComments`,
            )}
            :
          </span>
          {` ${tenantInMarketComments}`}
        </p>
      )}
    </footer>
  ) : null;
};

export default TenantInMarketFooter;
