import React from 'react';
import styles from './MapLoader.module.scss';
import { ColorNames } from 'constants/colorNames';
import SpinnerLoader from 'components/SpinnerLoader';

interface Props {
  text: string;
}

const MapLoader: React.FC<Props> = ({ text }) => (
  <div className={styles.loading}>
    <SpinnerLoader background={ColorNames.ayWhiteColor} />
    <p>{text}</p>
  </div>
);

export default MapLoader;
