export enum DataCenterFeedType {
  SINGLE = 'SINGLE',
  DUAL = 'DUAL',
}

export enum DataCenterBuildType {
  RETROFIT = 'Retrofit',
  PURPOSE_BUILT = 'Purpose-Built',
}

export const DATA_CENTER_NUMERIC_FIELDS = [
  'typicalCeilingHeightMt',
  'typicalCeilingHeightFeet',
  'typicalCeilingHeightInches',
  'underConstPower',
  'underConstSf',
  'underConstMt',
  'plannedPower',
  'plannedSf',
  'plannedMt',
  'commissionedPower',
  'commissionedSf',
  'commissionedMt',
  'costPerKwhMin',
  'costPerKwhMax',
  'numSubstationsServingFacility',
  'raisedFloorHeight',
  'raisedFloorHeightMt',
];
