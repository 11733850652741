import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { ICompany } from 'interfaces/ICompany';

export const convertPropertyCompanyToCompany = (
  propertyCompany: IPropertyCompany,
): ICompany => {
  return {
    id: propertyCompany.companyId,
    name: propertyCompany.name,
    headquarters: propertyCompany.headquarters,
    industry: {
      name: propertyCompany.industryName,
    },
  };
};
