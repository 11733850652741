import { isEmpty } from 'lodash';

export const removeTypenameKey = (_obj: any, skipDeepCopy?: boolean) => {
  if (isEmpty(_obj)) return _obj;
  const obj = skipDeepCopy ? _obj : JSON.parse(JSON.stringify(_obj));
  const key = '__typename';
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] === 'object') {
      removeTypenameKey(obj[i], true); //From the second call, we do not need to do deep copy
    } else if (i === key) {
      delete obj[key];
    }
  }
  return obj;
};
