import React from 'react';
import styles from './EditTable.module.scss';
import { IHistoricalPerformanceTable } from 'interfaces/IHistoricalPerformance';
import classnames from 'classnames';
import { ICellFooter } from './interfaces';

interface Props {
  cellsFooter: ICellFooter[];
  onClick: () => void;
  placeholderRow: IHistoricalPerformanceTable;
}

const TableFooter: React.FC<Props> = ({
  cellsFooter,
  onClick,
  placeholderRow,
}) => (
  <tr className={styles['row-content']} onClick={onClick}>
    {cellsFooter.map((cell, f) => {
      return (
        <td
          key={`${placeholderRow.date}-${f}`}
          className={classnames(
            styles['row-cell'],
            styles['cell-placeholder'],
            {
              [styles['cell-align-right']]: cell.isAlignedOnRight,
              [styles['cell-align-left']]: cell.isAlignedOnLeft,
            },
          )}
        >
          {cell.value}
        </td>
      );
    })}
  </tr>
);

export default TableFooter;
