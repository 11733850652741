import React from 'react';
import Floors from '../../FormFields/Floors';
import Size from '../../FormFields/Size';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';

export interface Props {
  unitOfMeasurement: string;
  data: IPricingQuoteInput;
  onChange: (key: string, value: string) => void;
}

const SizePricingQuote: React.FC<Props> = ({
  unitOfMeasurement,
  data,
  onChange,
}) => {
  return (
    <>
      <Floors value={data.floor} onChange={onChange} />
      <Size
        value={
          unitOfMeasurement === UnitOfMeasurement.sm ? data.sizeMt : data.size
        }
        unitOfMeasurement={unitOfMeasurement}
        onChange={onChange}
      />
    </>
  );
};

export default SizePricingQuote;
