import { max, slice, orderBy, min } from 'lodash';

import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import {
  I18N_AVANT_PROPERTY_COMP_SET_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { CURRENCY_CODES_BY_COUNTRY } from 'constants/currencies';
import { CountryCodes } from 'constants/countryCodes';
import { SortDirections } from 'constants/sortDirections';
import { LeaseBaseRentTime, LeaseRentType } from 'constants/leases';
import { AvailabilityType } from 'constants/availabilityTypes';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { isMonthlyTimeMeasurement } from 'utils/leases';
import { TranslateFunction, translateText } from 'utils/i18n';
import { abbreviateNumber } from 'utils/formatters/number';
import { deepCopy } from 'utils/objects';

import { BlockPropertyAvailability } from './types';

const MAX_OF_ITEMS = 5;
export const DATUM_ID_PREFIX = '-datumId';
export const SIZE_CHART_NAME = 'property-availabilities';
export const ASKING_RENT_CHART_NAME = 'property-asking-rent';

enum AvailabilityFields {
  blockSize = 'blockSize',
  fsRentMonth = 'fsRentMonth',
  fsRent = 'fsRent',
  nnnRentMonth = 'nnnRentMonth',
  nnnRent = 'nnnRent',
}

enum SizeValues {
  lowest = 'lowest',
  highest = 'highest',
}

export enum IAvailabilityOption {
  SIZE = 'SIZE',
  PRICE = 'PRICE',
}

export enum IAvailabilitySizeOption {
  LARGEST = 'LARGEST',
  SMALLEST = 'SMALLEST',
}

export const AvailabilitySizeSwitchOptions = {
  [IAvailabilitySizeOption.LARGEST]: {
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.largest`),
    value: IAvailabilitySizeOption.LARGEST,
  },
  [IAvailabilitySizeOption.SMALLEST]: {
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.smallest`),
    value: IAvailabilitySizeOption.SMALLEST,
  },
};

export const getAvailabilitySizeSwitchOptions = (t: TranslateFunction) => [
  {
    text: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.largest`),
    value: IAvailabilitySizeOption.LARGEST,
  },
  {
    text: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.smallest`),
    value: IAvailabilitySizeOption.SMALLEST,
  },
];

export const getBarLabel = (datum: any) => {
  return `${
    datum.optionType === IAvailabilityOption.PRICE
      ? getCurrencySymbol(datum.currency)
      : ''
  }${abbreviateNumber(datum.y)}`;
};

const getPropertyNameByCompSetId = (id: number, compSet?: IPropertySet) => {
  const targetProperty = compSet?.targetProperty;
  if (id === targetProperty?.id) {
    return targetProperty.primaryAddress;
  }

  return compSet?.properties?.find(x => x.id === id)?.primaryAddress;
};

const getXAxisValues = (data: any): string[] => {
  return data.map((x: any) => x.x);
};

const getValueFieldName = (
  option: IAvailabilityOption,
  leaseToggles?: LeaseToggleOptions,
) => {
  if (option === IAvailabilityOption.SIZE) {
    return AvailabilityFields.blockSize;
  }

  const isMonthly = isMonthlyTimeMeasurement(leaseToggles?.timeMeasurement);

  if (leaseToggles?.rentType === LeaseRentType.FS) {
    return isMonthly
      ? AvailabilityFields.fsRentMonth
      : AvailabilityFields.fsRent;
  } else {
    return isMonthly
      ? AvailabilityFields.nnnRentMonth
      : AvailabilityFields.nnnRent;
  }
};

const handleDataTotal = (
  data: any,
  option: IAvailabilityOption,
  compSet?: IPropertySet,
  leaseToggles?: LeaseToggleOptions,
  isSmallestOption?: boolean,
): any => {
  const fieldName = getValueFieldName(option, leaseToggles);

  return data.map((data: any) => {
    const availabilities = (data.availabilities || []).filter(
      (x: any) => x.spaceLeaseType === AvailabilityType.Direct,
    );
    return {
      x: `${getPropertyNameByCompSetId(
        data?.propertyId,
        compSet,
      )}${DATUM_ID_PREFIX}${data?.propertyId}`,
      y: isSmallestOption
        ? min(availabilities.map((x: any) => x[fieldName])) || 0
        : max(availabilities.map((x: any) => x[fieldName])) || 0,
      optionType: option,
      propertyId: data.propertyId,
      currency:
        availabilities?.[0]?.currency ||
        CURRENCY_CODES_BY_COUNTRY[CountryCodes.US],
      primaryAddress: getPropertyNameByCompSetId(data?.propertyId, compSet),
    };
  });
};

export const parseGraphData = (
  data: any,
  compSet?: IPropertySet,
  option = IAvailabilityOption.PRICE,
  sizeToggle?: IAvailabilitySizeOption,
  leaseToggles?: LeaseToggleOptions,
  maxItems?: number,
) => {
  const list = deepCopy(data);
  const isSmallestOption = sizeToggle === IAvailabilitySizeOption.SMALLEST;
  const mountedDataTotal = orderBy(
    handleDataTotal(list, option, compSet, leaseToggles, isSmallestOption),
    ['y'],
    isSmallestOption ? SortDirections.desc : SortDirections.asc,
  );
  const itemsLimit = maxItems || MAX_OF_ITEMS;
  const handledDataList = mountedDataTotal.filter(item => item.y > 0);

  const numberOfItems = handledDataList.length;
  const xAxis = getXAxisValues(handledDataList);
  return {
    xAxis:
      numberOfItems > itemsLimit
        ? slice(xAxis, numberOfItems - itemsLimit, numberOfItems)
        : xAxis,
    yAxis: undefined,
    data:
      numberOfItems > itemsLimit
        ? slice(handledDataList, numberOfItems - itemsLimit, numberOfItems)
        : handledDataList,
  };
};

const getAttrRentType = (filters?: LeaseToggleOptions) => {
  return filters?.rentType == LeaseRentType.FS
    ? filters.timeMeasurement == LeaseBaseRentTime.Monthly
      ? 'fs-monthly'
      : 'fs'
    : filters?.timeMeasurement == LeaseBaseRentTime.Monthly
    ? 'nnn-monthly'
    : 'nnn';
};

const getChartNameDataByFilter = (
  chartName: string,
  leaseToggles?: LeaseToggleOptions,
  isAskingRentChart?: boolean,
  isLargest?: boolean,
) => {
  let optionChartType = '';
  if (isAskingRentChart) {
    optionChartType = isLargest
      ? IAvailabilitySizeOption.LARGEST.toLowerCase()
      : IAvailabilitySizeOption.SMALLEST.toLowerCase();
  } else {
    optionChartType = isLargest ? SizeValues.highest : SizeValues.lowest;
  }

  return `${chartName}-${optionChartType}${
    isAskingRentChart ? `-${getAttrRentType(leaseToggles)}` : ''
  }`;
};

export const parseMostExpensiveAndLargestData = (
  chartName: string,
  chartData?: BlockPropertyAvailability[],
  leaseToggles?: LeaseToggleOptions,
  isLargest?: boolean,
  isAskingRentChart?: boolean,
  currency?: string,
) => {
  const chartNameByFilter = getChartNameDataByFilter(
    chartName,
    leaseToggles,
    isAskingRentChart,
    isLargest,
  );
  const chartDataByFilter = chartData?.find(x => x.name === chartNameByFilter);
  return {
    xAxis: chartDataByFilter?.xAxis,
    yAxis: chartDataByFilter?.yAxis,
    data: chartDataByFilter?.data?.map(item => ({
      ...item,
      optionType: isAskingRentChart ? IAvailabilityOption.PRICE : '',
      currency: isAskingRentChart
        ? chartDataByFilter?.currency || currency
        : '',
    })),
  };
};

export const getHighestChartTitle = (isLargest?: boolean) => {
  return isLargest
    ? translateText(`${I18N_AVANT_PROPERTY_COMP_SET_PATH}.highestAskingRents`)
    : translateText(`${I18N_AVANT_PROPERTY_COMP_SET_PATH}.lowestAskingRents`);
};

export const getLargestChartTitle = (isLargest?: boolean) => {
  return isLargest
    ? translateText(
        `${I18N_AVANT_PROPERTY_COMP_SET_PATH}.largestAvailabilities`,
      )
    : translateText(
        `${I18N_AVANT_PROPERTY_COMP_SET_PATH}.smallestAvailabilities`,
      );
};
export const getSectionTitle = (isLargest?: boolean) => {
  return isLargest
    ? translateText(
        `${I18N_AVANT_PROPERTY_COMP_SET_PATH}.mostExpensiveLargestAvailableBlocks`,
      )
    : translateText(
        `${I18N_AVANT_PROPERTY_COMP_SET_PATH}.leastExpensiveSmallestAvailableBlocks`,
      );
};
