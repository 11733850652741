import {
  REGEX_INTEGERS,
  REGEX_THOUSAND_SEP_WITH_DECIMALS,
} from 'constants/regex';
import {
  getFieldNameForProperty,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import {
  FormFieldConfig,
  FormFieldType,
} from 'components/FormControl/FormField/types';

export const getMultifamilyFields = (
  property: IPropertyInput,
): FormFieldConfig[] => [
  {
    type: FormFieldType.integer,
    field: 'totalUnits',
    labelI18nKey: 'avantPlatform.attributes.property.label.totalUnits',
    pattern: REGEX_INTEGERS,
    placeholderI18nKey: '0',
  },
  {
    type: FormFieldType.decimal,
    field: getFieldNameForProperty(
      'avgUnitSizeSf',
      property,
      ModelsWithUnitsOfMeasurement.Multifamily,
    ),
    labelI18nKey: 'avantPlatform.attributes.property.label.avgUnitSize',
    placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterSize',
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    legend: getUnitOfMeasurementForProperty(
      'avgUnitSizeSf',
      ModelsWithUnitsOfMeasurement.Multifamily,
      property?.propertyCountry?.code || property?.measurementSystem,
    ),
  },
  {
    type: FormFieldType.integer,
    field: 'unitsZeroBed',
    labelI18nKey: 'avantPlatform.attributes.property.label.unitsZeroBed',
    pattern: REGEX_INTEGERS,
    placeholderI18nKey: '0',
  },
  {
    type: FormFieldType.integer,
    field: 'unitsOneBed',
    labelI18nKey: 'avantPlatform.attributes.property.label.unitsOneBed',
    pattern: REGEX_INTEGERS,
    placeholderI18nKey: '0',
  },
  {
    type: FormFieldType.integer,
    field: 'unitsTwoBed',
    labelI18nKey: 'avantPlatform.attributes.property.label.unitsTwoBed',
    pattern: REGEX_INTEGERS,
    placeholderI18nKey: '0',
  },
  {
    type: FormFieldType.integer,
    field: 'unitsThreeBed',
    labelI18nKey: 'avantPlatform.attributes.property.label.unitsThreeBed',
    pattern: REGEX_INTEGERS,
    placeholderI18nKey: '0',
  },
];
