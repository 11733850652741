import React, { useState } from 'react';
import classnames from 'classnames';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import TextInput from 'components/Inputs/TextInput';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import { formatNumberWithCommas } from 'utils/formatters/number';
import { translateText } from 'utils/i18n';

import { useCalculateSizeFields } from './useCalculateSizeFields';
import { useFocusCurrentInputId } from './useFocusCurrentInputId';
import styles from '../../FormSection.module.scss';

interface Props {
  id?: string;
  field: string;
  labelName?: string;
  input: ILeaseInput | IPropertyInput;
  onChange: (field: string, value: any) => void;
  property?: IProperty;
  required?: boolean;
  unitOfMeasurement: string;
  isDisabled?: boolean;
  wrapperClassName?: string;
  labelClassName?: string;
  columnClassName?: string;
  disableCalculationSizes?: boolean;
  sizeFieldsName?: string[];
  officeSizeFieldsName?: string[];
  warehouseSizeFieldsName?: string[];
}

const SizeFields: React.FC<Props> = ({
  id = 'lease-size',
  field,
  property,
  onChange,
  labelName = 'avantPlatform.attributes.lease.label.leaseSize',
  input,
  required = false,
  unitOfMeasurement,
  isDisabled,
  wrapperClassName,
  labelClassName,
  columnClassName,
  disableCalculationSizes = false,
  sizeFieldsName = ['sizeMt', 'size'],
  officeSizeFieldsName = ['officeSizeMt', 'officeSize'],
  warehouseSizeFieldsName = ['warehouseMt', 'warehouseSf'],
}) => {
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);
  const {
    currentFocusedField,
    setCurrentFocusedField,
  } = useFocusCurrentInputId();

  const measurement = property?.propertyCountry?.areaMeasurement
    ? property?.propertyCountry?.areaMeasurement
    : UnitOfMeasurement.sf;

  const isMeasurementSqm =
    measurement === UnitOfMeasurement.sm ||
    unitOfMeasurement === UnitOfMeasurement.sm;

  useCalculateSizeFields({
    input,
    onChange,
    currentFocusedField,
    isMeasurementSqm,
    preventCalculation: disableCalculationSizes,
    hasFormChanged,
    sizeFieldsName,
    officeSizeFieldsName,
    warehouseSizeFieldsName,
  });

  const getFieldName = () => {
    if (field === 'size') {
      return isMeasurementSqm ? sizeFieldsName[0] : sizeFieldsName[1];
    }
    if (field === 'officeSize') {
      return isMeasurementSqm
        ? officeSizeFieldsName[0]
        : officeSizeFieldsName[1];
    }
    if (field === 'warehouse') {
      return isMeasurementSqm
        ? warehouseSizeFieldsName[0]
        : warehouseSizeFieldsName[1];
    }
    if (field === 'priorSize') {
      return isMeasurementSqm ? 'priorSizeMt' : 'priorSize';
    }
    if (field === 'buildingSize') {
      return isMeasurementSqm ? 'buildingSizeMt' : 'buildingSize';
    }
    return '';
  };
  const fieldName = getFieldName();

  return (
    <FormControl
      required={required}
      wrapperClassName={classnames(wrapperClassName, styles['form-row'])}
      label={translateText(labelName)}
      disabled={isDisabled}
      labelClassName={labelClassName}
      columnClassName={columnClassName}
    >
      <TextInput
        id={id}
        data-field={field}
        setCurrentFocusedFieldId={setCurrentFocusedField}
        formatter={value => formatNumberWithCommas(value)}
        placeholder={translateText(
          'avantPlatform.attributes.property.prompt.enterSize',
        )}
        onChange={value => {
          const convertedNumberValue = convertToNumber(value);
          onChange(fieldName, convertedNumberValue || null);
          if (
            field === 'size' ||
            field === 'buildingSize' ||
            field === 'officeSize' ||
            field === 'warehouse'
          ) {
            setHasFormChanged(true);
          }
        }}
        value={input[fieldName]}
        isDisabled={isDisabled}
      />
      <UnitOfMeasurementLegend
        unitOfMeasurement={unitOfMeasurement}
        shouldConvert={false}
        disabled={isDisabled}
      />
    </FormControl>
  );
};

export default SizeFields;
