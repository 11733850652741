import React, { useLayoutEffect, useState } from 'react';
import styles from './BackToTopButton.module.scss';
import classNames from 'classnames';

interface Props {
  scrollContainer?: Element;
}

const BackToTopButton: React.FC<Props> = props => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollElement = props.scrollContainer || window;

  const containerClassName = props.scrollContainer
    ? 'container-relative'
    : 'container-page';

  const backToTop = () => {
    scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useLayoutEffect(() => {
    const onScroll = () => {
      let scrollY, height;

      if (props.scrollContainer) {
        scrollY = props.scrollContainer.scrollTop;
        height = props.scrollContainer.clientHeight;
      } else {
        scrollY = window.scrollY;
        height = window.innerHeight;
      }

      setIsVisible(scrollY >= height);
    };

    scrollElement.addEventListener('scroll', onScroll);
    return () => scrollElement.removeEventListener('scroll', onScroll);
  });

  return (
    <div
      className={classNames(styles[containerClassName], {
        [styles[`${containerClassName}-visible`]]: isVisible,
      })}
    >
      <button type={'button'} className={styles.button} onClick={backToTop}>
        Back to top
      </button>
    </div>
  );
};

export default BackToTopButton;
