import { CompositionSectionProps } from 'components/MarketProfile/constants';
import { PROPERTIES_PAGE_SIZE } from 'components/SubmarketProfile/Sections/Composition/PropertiesMap/SubmarketsMap/constants';
import { PropertyTypeNames } from 'constants/propertyTypes';

import { MARKETS_BOUNDARIES_QUERY } from 'graphql/markets';
import { GET_PROPERTIES_COORDINATES_QUERY } from 'graphql/property';
import { IProperty } from 'interfaces/IProperty';
import { ISearchResult } from 'interfaces/ISearchResult';
import { useCallback, useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { getPropertyStatusesFilter } from './utils';

export const useMarketData = (
  params: CompositionSectionProps,
  shouldShowOnlySelectedBoundaries: boolean,
) => {
  const [properties, setProperties] = useState<IProperty[] | undefined>(
    undefined,
  );
  const [isLoadingProperties, setIsLoadingProperties] = useState(true);
  const { activeOption, market, propertyType, trackedProperties } = params;
  const client = useApolloClient();

  const { data: boundariesData, loading: isLoadingBoundaries } = useQuery(
    MARKETS_BOUNDARIES_QUERY,
    {
      variables: {
        search: {
          filter: {
            marketId: market?.id,
            propertyTypeId: propertyType.id,
          },
        },
      },
      skip: !market?.id && !propertyType.id,
    },
  );

  const loadProperties = useCallback(
    async (currentPage = 1, currentItems: IProperty[] = []) => {
      const skip =
        !market.id || !activeOption || shouldShowOnlySelectedBoundaries;
      if (skip) return;
      const { data } = await client.query<ISearchResult<IProperty>>({
        query: GET_PROPERTIES_COORDINATES_QUERY,
        fetchPolicy: 'cache-first',
        variables: {
          search: {
            filter: {
              markets: [{ id: market.id, name: market.name }],
              propertyStatuses: getPropertyStatusesFilter(activeOption),
              propertyTypes: [PropertyTypeNames[propertyType?.id]],
              includeInStats: trackedProperties || undefined,
            },
            page: {
              page: currentPage,
              size: PROPERTIES_PAGE_SIZE,
            },
          },
        },
      });
      const newItems = data.properties.results;
      const allItems = [...currentItems, ...newItems];
      if (newItems.length < PROPERTIES_PAGE_SIZE) {
        setProperties(allItems);
        setIsLoadingProperties(false);
        return;
      } else {
        await loadProperties(currentPage + 1, allItems);
      }
    },
    [
      activeOption,
      client,
      market,
      propertyType,
      shouldShowOnlySelectedBoundaries,
      trackedProperties,
    ],
  );

  useEffect(() => {
    loadProperties(1);
    const timeout = setTimeout(loadProperties);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [
    loadProperties,
    market,
    activeOption,
    shouldShowOnlySelectedBoundaries,
    propertyType,
    trackedProperties,
  ]);

  return {
    properties,
    isLoadingProperties,
    boundariesData,
    isLoadingBoundaries,
  };
};
