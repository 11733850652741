export interface IDonutGraphData {
  largestPropertyIds?: number[];
  order?: number;
  propsToClick?: any;
  tags?: string[];
  title?: string;
  titleClassName?: string;
  totalSize: number;
  unitOfMeasurement: string;
  donutType?: string;
  topItems?: number[];
}

export const TAG_BREAK_LINE = '';
