import React, { useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Input } from 'components/@codelitt/ay-design-library';
import { IRadioItem } from 'components/Inputs/RadioInput';
import ImageCategoryPicker from 'components/ModalMedia/Pictures/ImageCategoryPicker';
import Button from 'components/Button';
import ModalWrapper from 'components/ModalWrapper';
import { PROFILE_TYPES } from 'constants/profileTypes';
import {
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IdName } from 'interfaces/IdName';
import { IMedia } from 'interfaces/IMedia';
import {
  SET_MEDIA_CATEGORY_MUTATION,
  SET_MEDIA_AMENITY_MUTATION,
} from 'graphql/images';
import { SET_MEDIA_NOTES } from 'graphql/images/mutations';
import { translateText } from 'utils/i18n';
import styles from './PictureItem.module.scss';
import { I18N_MODAL_MEDIA_LABEL_PATH } from '../../constants';

interface Props {
  onCancel: () => void;
  onSave: () => void;
  media: IMedia;
  isVideo?: boolean;
  propertyTypeId: number;
  profileType?: PROFILE_TYPES;
}

const EditTagsModal: React.FC<Props> = props => {
  const client = useApolloClient();

  const [category, setCategory] = useState<IdName | null>(
    props.media.category ?? null,
  );
  const [subCategory, setSubCategory] = useState<IdName | null>(
    props.media.subAmenity ?? null,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [note, setNote] = useState(props.media.notes);
  const [saveNote] = useMutation(SET_MEDIA_NOTES);

  const saveCategory = async () =>
    client.mutate({
      mutation: SET_MEDIA_CATEGORY_MUTATION,
      variables: {
        mediaId: props.media.id,
        categoryId: category?.id ?? null,
      },
    });

  const saveAmenity = async () =>
    client.mutate({
      mutation: SET_MEDIA_AMENITY_MUTATION,
      variables: {
        mediaId: props.media.id,
        subAmenityId: subCategory?.id,
      },
    });

  const save = async () => {
    setIsSaving(true);

    try {
      subCategory ? await saveAmenity() : await saveCategory();

      saveNote({
        variables: {
          mediaId: props.media.id,
          notes: note,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
      props.onSave();
    }
  };

  return (
    <ModalWrapper
      keepScrollLocked
      withCloseButton
      onCancel={props.onCancel}
      wrapperClassName={styles['modal-container']}
    >
      <ImageCategoryPicker
        propertyTypeId={props.propertyTypeId}
        isVideo={!!props.isVideo}
        label={`${translateText(
          `${I18N_MODAL_MEDIA_LABEL_PATH}.common.changeTag`,
        )}:`}
        selectedCategory={
          category
            ? {
                value: category.id,
                label: category.name,
              }
            : null
        }
        selectedSubCategory={{
          value: subCategory?.id,
          label: subCategory?.name,
        }}
        onCategoryChange={(selectedItem: IRadioItem) => {
          setCategory({
            id: selectedItem.value!,
            name: selectedItem.label,
          });
          setSubCategory(null);
        }}
        onSubCategoryChange={(selectedItem: IRadioItem | null) => {
          const subcategory = selectedItem
            ? {
                id: selectedItem.value!,
                name: selectedItem.label,
              }
            : null;

          setSubCategory(subcategory);
          setCategory(null);
        }}
        labelBig
        profileType={props.profileType}
      />
      <div className={styles['edit-note-container']}>
        <label>
          {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.photoNote`)}:
        </label>
        <Input
          wrapperClassName={styles['edit-note-input']}
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterDescriptionNote`,
          )}
          value={note}
          onChange={setNote}
          type="textarea"
        />
      </div>
      <div className={styles['edit-tags-buttons-container']}>
        <Button
          size="small"
          isDisabled={isSaving}
          onClick={props.onCancel}
          type="neutral"
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`)}
        />
        <Button
          size="small"
          type="main"
          onClick={save}
          isDisabled={isSaving}
          label={
            isSaving
              ? `${translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`,
                )}`
              : translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.updateInfo`,
                )
          }
        />
      </div>
    </ModalWrapper>
  );
};

export default EditTagsModal;
