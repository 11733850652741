import { useState, useEffect } from 'react';

export const useFocusCurrentInputId = () => {
  const [currentFocusedField, setCurrentFocusedField] = useState<string | null>(
    null,
  );

  useEffect(() => {
    const handleFocusChange = (event: FocusEvent) => {
      if (event.target instanceof HTMLElement) {
        setCurrentFocusedField(event.target.id || null);
      }
    };
    document.addEventListener('focusin', handleFocusChange);
    document.addEventListener('focusout', () => setCurrentFocusedField(null));

    return () => {
      document.removeEventListener('focusin', handleFocusChange);
      document.removeEventListener('focusout', () =>
        setCurrentFocusedField(null),
      );
    };
  }, []);

  return { currentFocusedField, setCurrentFocusedField };
};
