const MARGINS = {
  top: 12,
  right: 40,
  bottom: 32,
  left: 20,
};
const HEIGHT = 300;
const CIRCLE_PADDING = 0.5;

export const graphDimensions = {
  MARGINS,
  HEIGHT,
  CIRCLE_PADDING,
};
