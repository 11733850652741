import React from 'react';
import classNames from 'classnames';
import { colors } from 'constants/colors';
import { ColorNames } from 'constants/colorNames';
import { DONUT_SIZE } from 'components/Graphs/DonutGraph/graphConstants';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import GraphContainer from 'components/Graphs/GraphContainer';
import DonutGraph from 'components/Graphs/DonutGraph';
import { DONUT_COLOR_TOOLTIP } from 'components/Graphs/DonutGraph/Elements/Tooltip';
import MissingGraphData from 'components/MissingGraphData';
import SpinnerLoader from 'components/SpinnerLoader';
import styles from './LargeDonutGraph.module.scss';

type DonutChartType = {
  defaultData?: IDonutGraphData;
  donutId: number;
  graphData: IDonutGraphData[];
  isLoading?: boolean;
  onDonutClick?: (donutData?: IDonutGraphData) => void;
  title: string;
};

const LargeDonutGraph: React.FC<DonutChartType> = ({
  defaultData,
  donutId,
  isLoading,
  graphData,
  onDonutClick,
  title,
}) => {
  const renderGraph = () => {
    if (isLoading) {
      return (
        <div className={styles['loading-container']}>
          <SpinnerLoader background={ColorNames.ayWhiteColor} />
        </div>
      );
    } else if (graphData.length) {
      return (
        <DonutGraph
          defaultData={defaultData}
          donutId={donutId}
          graphData={graphData}
          onDonutClick={onDonutClick}
          firstSliceColor={colors.primaryColor500}
          secondSliceColor={colors.primaryColor500}
          secondaryDonutColor={colors.supportive100}
          size={DONUT_SIZE.LARGE}
          showTags={false}
          tooltipClassName={classNames(
            styles.tooltip,
            styles['tooltip-active'],
          )}
          tooltipDefaultDataClassName={classNames(
            styles.tooltip,
            styles['tooltip-default'],
          )}
          tooltipPrimaryColor={DONUT_COLOR_TOOLTIP.white}
        />
      );
    } else {
      return <MissingGraphData />;
    }
  };

  return (
    <GraphContainer wrapperClassName={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      {renderGraph()}
    </GraphContainer>
  );
};

export default LargeDonutGraph;
