import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { getCurrencySymbol } from 'utils/formatters/currency';

export const PROPERTIES_POPUP_INIT = {
  propertiesFilter: undefined,
  hideAction: undefined,
  isVisible: false,
  popupFilter: undefined,
  popupSubtitle: '',
  popupTitle: '',
};

export const MARKET_CONTEXT_INIT = {
  isLoading: true,
  unitOfMeasurement: UnitOfMeasurement.sf,
  currencySymbol: getCurrencySymbol(),
  propertiesPopup: PROPERTIES_POPUP_INIT,
};

export const COMPOSITION_SECTION = 'composition';
export const PERFORMANCE_SECTION = 'performance';
export const RECENT_ACTIVITY_SECTION = 'recent-activity';
export const DEMOGRAPHICS_SECTION = 'demographics';

export const MARKET_PAGE_SECTIONS = {
  COMPOSITION_SECTION,
  PERFORMANCE_SECTION,
  RECENT_ACTIVITY_SECTION,
  DEMOGRAPHICS_SECTION,
};
