import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { authContext } from './AuthContext';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import AuthService from 'services/Auth';

interface WebsocketInterface {
  isWSOpen: boolean;
  fileUploadStatus: any;
}
interface IFileUploadStatus {
  id: number;
  processedRows: number;
  totalRows: number;
  userId: number;
  fileType: string;
  filename: string;
  processedAt: string;
  error: string;
  isDone: boolean;
  infoMessage: string;
}

export const websocketContext = createContext<WebsocketInterface>({
  isWSOpen: false,
  fileUploadStatus: null,
});

const { Provider } = websocketContext;

type PropTypes = {
  children: React.ReactNode;
};

export const WebsocketProvider: React.FC<PropTypes> = (props: PropTypes) => {
  const OKTA_TOKENS = '@ay-pia-web-OKTA-Token';
  const [isWSOpen, setIsWSOpen] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = useState<IFileUploadStatus>();
  const ws = useRef<WebSocket | null>(null);
  const wsTokens = useRef<WebSocket | null>(null);
  const { user } = useContext(authContext);
  const fetchMinExpiryTime = (accessTokens: any) => {
    return Math.min(
      accessTokens['avant-functions'].expiry,
      accessTokens['geospatial-api'].expiry,
      accessTokens['data-science'].expiry,
      accessTokens['importer-api'].expiry,
      accessTokens['properties-api'].expiry,
      accessTokens['users-api'].expiry,
    );
  };

  useEffect(() => {
    const connectToWS = async () => {
      const isAuthReinforced =
        process.env['IS_OKTA_AUTH_REINFORCED'] === 'true';
      const propertiesResponse = await fetch(
        `${window._env_.WEBSOCKET_NEGOTIATE_URL}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(isAuthReinforced && {
              'x-api-token': AuthService.getOktaToken('avant-functions'),
            }),
            ...(isAuthReinforced && { 'ay-api-auth': 'OKTA' }),
          },
          body: JSON.stringify({
            hubName: 'properties',
          }),
        },
      );
      const tokensResponse = await fetch(
        `${window._env_.WEBSOCKET_NEGOTIATE_URL}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(isAuthReinforced && {
              'x-api-token': AuthService.getOktaToken('avant-functions'),
            }),
            ...(isAuthReinforced && { 'ay-api-auth': 'OKTA' }),
          },
          body: JSON.stringify({
            hubName: 'tokens',
          }),
        },
      );
      const propertiesResult = await propertiesResponse.json();
      const tokensResult = await tokensResponse.json();
      const propertiesSocket = new WebSocket(propertiesResult.url);
      const tokensSocket = new WebSocket(tokensResult.url);
      propertiesSocket.onopen = () => {
        setIsWSOpen(true);
        console.log('Channel 1 is listening');
      };
      propertiesSocket.onclose = () => setIsWSOpen(false);
      tokensSocket.onopen = () => {
        console.log('Channel 2 is listening');
      };
      tokensSocket.onmessage = event => {
        const data = JSON.parse(event.data);
        if (!!data['properties-web-app']) {
          const tokens = data['properties-web-app'];
          const expiryTime = fetchMinExpiryTime(tokens);
          const timeInSeconds = Date.now() / 1000;
          const expiryTimestamp = timeInSeconds + expiryTime;
          AuthService.setWSConnectionsStatus('tokens', {
            status: 'active',
            lastActivity: timeInSeconds,
          });
          localStorage.setItem(
            OKTA_TOKENS,
            JSON.stringify({
              ...tokens,
              timestamp: expiryTimestamp,
            }),
          );
        }
      };
      propertiesSocket.onmessage = event => {
        const data = JSON.parse(event.data);
        const env = window._env_.REACT_APP_ENV.toLowerCase();
        if (!!data[`users-api-${env}`]) {
          const isServicesUp = !(
            data[`users-api-${env}`]['deploymentRunning'] |
            data[`properties-api-${env}`]['deploymentRunning'] |
            data[`importer-api-${env}`]['deploymentRunning'] |
            data[`geospatial-api-${env}`]['deploymentRunning']
          );
          if (!isServicesUp) {
            console.log(
              'Deployment in progress. Deactivating Sentry logging temporarily...',
            );
          }
          AuthService.setWSConnectionsStatus('properties', {
            status: 'active',
            lastActivity: Date.now() / 1000,
          });
          localStorage.setItem(
            LocalStorageKeys.appServicesStatus,
            isServicesUp.toString(),
          );
        }
        if (!!data['processedRows']) {
          const parsedData = {
            id: parseInt(data.id),
            processedRows: parseInt(data.processedRows),
            totalRows: parseInt(data.totalRows),
            userId: parseInt(data.userId),
            error: data.error,
            processedAt: data.processedAt,
            infoMessage: data.infoMessage,
            filename: data.filename,
            fileType: data.fileType,
            isDone: data.isDone === 'true',
          };
          setFileUploadStatus(parsedData);
        }
      };
      ws.current = propertiesSocket;
      wsTokens.current = tokensSocket;
    };

    connectToWS();

    return () => {
      ws.current?.close();
      AuthService.setWSConnectionsStatus('properties', {
        status: 'inactive',
        lastActivity: Date.now() / 1000,
      });
      wsTokens.current?.close();
      AuthService.setWSConnectionsStatus('tokens', {
        status: 'inactive',
        lastActivity: Date.now() / 1000,
      });
    };
  }, [user.id]);

  return (
    <Provider
      value={{
        isWSOpen,
        fileUploadStatus,
      }}
    >
      {props.children}
    </Provider>
  );
};
