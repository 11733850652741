import { gql } from '@apollo/client';
import { COMPANY_BASIC_RESULTS_FRAGMENT } from '../company';

export const TIM_FRAGMENT = gql`
  fragment TIMFragment on TIM {
    id
    expandingContracting {
      id
      name
    }
    targetMarketId
    targetMarket {
      id
      name
      mediaUrl
      country {
        areaMeasurement
        distanceMeasurement
      }
    }
    targetSubmarkets {
      id
      name
    }
    targetPropertyTypeId
    targetPropertySubTypeId
    targetThirdPartyPropertyClassId
    targetThirdPartyPropertyClass {
      id
      name
    }
    targetSizeMax
    targetSizeMin
    targetSizeMaxMt
    targetSizeMinMt
    timStatusType {
      id
      name
    }
    timStatusTypeId
    currentMarketId
    currentMarket {
      id
      name
    }
    currentPropertyId
    currentProperty {
      id
      name
      primaryAddress
    }
    currentLeaseId
    currentSize
    currentSizeMt
    newToMarket
    source
    comments
    clearHeightHigh
    clearHeightLow
    clearHeightHighMt
    clearHeightLowMt
    loadingDocks
    driveInDoors
    dockConfigurationType {
      id
      name
    }
    dockConfigurationTypeId
    trailerParkingSpaces
    activeSinceDate
    exitDate
    targetCommencementDate
    lastDiscussedDate
    tenantRepBrokerCompany {
      id
      name
    }
    tenantCompany {
      ...companyBasicResults
    }
    tenantRepBroker {
      id
      name
    }
    createdAt
    updatedAt
    createdUser {
      id
      firstName
      lastName
      email
    }
    updatedUser {
      id
      firstName
      lastName
      email
    }
  }
  ${COMPANY_BASIC_RESULTS_FRAGMENT}
`;
