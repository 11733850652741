import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { graphDimensions } from '../graphConstants';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { ISaleBarData } from '../interfaces';
import { formatPunctuationNumber } from 'utils/formatters/number';
import { getLabelColor } from './Bar';

type Props = {
  currencySymbol?: string;
  data: ISaleBarData[];
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
};

const X_OFFSET_FACTOR = 24;
const DIGIT_SIZE = 5;

const PriceLegend: React.FC<Props> = (props: Props) => {
  const priceLegendRef = useRef(null);
  const { currencySymbol, data, xScale, yScale } = props;

  // since total values could have different total digits have we need to calculate with different offset values
  const getXTextOffset = (d: ISaleBarData) => {
    const totalDigits = d.total!.toString().length;
    return `${X_OFFSET_FACTOR - totalDigits * DIGIT_SIZE}px`;
  };

  useEffect(() => {
    const node = priceLegendRef.current;

    select(node)
      .selectAll('text')
      .data(data)
      .enter()
      .append('text')
      .attr('x', d => xScale(d.key)!)
      .attr('y', d => graphDimensions.HEIGHT - yScale(d.total!))
      .attr('dx', getXTextOffset)
      .attr('dy', '-20px')
      .style('fill', getLabelColor)
      .style('font-size', '20px')
      .style('font-weight', 600)
      .text(d => `${currencySymbol}${formatPunctuationNumber(d.total!)}`);
  }, [data, xScale, yScale, currencySymbol]);

  return <g ref={priceLegendRef} />;
};

export default PriceLegend;
