import React from 'react';
import classnames from 'classnames';

import { Col, Container, Row } from 'components/@codelitt/ay-design-library';
import { Link } from 'react-router-dom';

import LogoImg from 'assets/images/avant-big.svg';
import styles from './OAuthLogin.module.scss';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

type Props = {
  isAuthenticated: boolean;
  showErrorMessage: boolean;
  onTryAgain: () => void;
};

const OktaLoginPage: React.FC<Props> = ({
  isAuthenticated,
  showErrorMessage,
  onTryAgain,
}) => {
  const { t } = useTranslation();
  const renderErrorMessage = () => {
    if (!showErrorMessage) return null;

    return (
      <div className={styles['login-message']}>
        <p>There was an unexpected error validating your credentials.</p>

        <div className={styles['error-link-container']}>
          <Link onClick={onTryAgain} to={'#'}>
            {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.tryAgain`)}
          </Link>
        </div>
      </div>
    );
  };

  const renderLoadingMessage = () => {
    if (showErrorMessage) return null;

    return <p className={styles['login-message']}>Validating credentials...</p>;
  };

  return (
    <Container wrapperClassName={styles['login-container']}>
      <Row wrapperClassName={styles.header}>
        <Col
          wrapperClassName={classnames(styles['logo-container'], {
            [styles['sign-up']]: isAuthenticated,
          })}
        >
          <img className={styles.logo} src={LogoImg} alt="Avison Young" />
        </Col>
      </Row>

      {renderErrorMessage()}
      {!isAuthenticated && renderLoadingMessage()}
    </Container>
  );
};

export default OktaLoginPage;
