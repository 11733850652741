import React, { useState } from 'react';
import classnames from 'classnames';
import { Container } from 'components/@codelitt/ay-design-library';
import { getCompsPopupSearchFilter } from 'components/FindComps/LeasesAnalyticsContainer/utils';
import CompsPopup from 'components/CompsPopup';
import PageModal from 'components/PageModal';
import Button from 'components/Button/new';
import DownloadButton from 'components/DownloadButton';
import GroupOptionsContainer, {
  GroupOption,
} from 'components/GroupOptionsContainer';
import { AgencyLeasingTenantSection } from 'components/AgencyAssignments/types';
import AgencyLeasingHeader from 'components/AgencyAssignments/AgencyLeasingHeader';
import CommonAgencyLeasingControl from 'components/AgencyAssignments/AgencyLeasingHeader/CommonAgencyLeasingControl';
import { ColorNames } from 'constants/colorNames';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { ExportTypes } from 'constants/exportTypes';
import { SortDirections } from 'constants/sortDirections';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { SEARCH_LEASES_CLICKTHROUGHS_QUERY } from 'graphql/leases';
import { useLeasesSearch } from 'hooks/useLeasesSearch';
import { useLeasesPPT } from 'hooks/useLeasesPPT';
import { IModalInfo, MODALS } from 'interfaces/IModal';
import { ILease } from 'interfaces/ILease';
import { ISearchOrder } from 'interfaces/ISearch';
import { translateText } from 'utils/i18n';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { LEASES_POPUP_INIT } from 'stores/LeasesAnalytics/constants';
import { ILargestLeasesPopup } from 'stores/LeasesAnalytics/types';

import { getGraphqlQuery, PROPERTIES_POPUP_INIT } from '../Properties/utils';
import ListContainer from './ListContainer';
import { TenantProps } from './types';
import styles from './Tenants.module.scss';
import SummaryContainer from './SummaryContainer';

const Tenants = ({
  agencyLeasing,
  section,
  toggleOptions,
  onChangeToggleOptions,
}: TenantProps) => {
  const [order, setOrder] = useState<ISearchOrder>({
    field: 'signDate',
    direction: SortDirections.desc,
  });

  const [propertiesPopup, setPropertiesPopup] = useState<
    ILargestPropertiesPopup
  >(PROPERTIES_POPUP_INIT);
  const [leasesPopup, setLeasesPopup] = useState<ILargestLeasesPopup>(
    LEASES_POPUP_INIT,
  );

  const [activeModal, setActiveModal] = useState<MODALS | null>(null);
  const [modalInfo, setModalInfo] = useState<IModalInfo>({
    title: '',
    description: '',
    button: '',
  });

  const { data: leasesData } = useLeasesSearch(agencyLeasing, order);
  const { generateLeasesPPT } = useLeasesPPT({
    leasesIds: leasesData?.leases?.results?.map((x: ILease) => x.id) || [],
    setActiveModal,
    setModalInfo,
  });

  if (!agencyLeasing) {
    return null;
  }

  const isModalActive = (modal: MODALS) => activeModal === modal;

  const propertyIds =
    agencyLeasing?.properties?.map(property => property.id!) || [];

  const isList = section === AgencyLeasingTenantSection.List;
  const isSummary = section === AgencyLeasingTenantSection.Summary;

  const DOWNLOAD_OPTIONS = [
    {
      label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.basicExcel`),
      subType: 'basic',
    },
    {
      label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.extendedExcel`),
    },
  ];

  const groupOptions: GroupOption[] = [
    {
      title: `${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.emailed`)}:`,
      customOptions: [
        <Button
          iconPosition={'right'}
          description={translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.basicPPT`,
          )}
          icon="download"
          iconSize={1.3}
          label={translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.basicPPT`,
          )}
          onClick={generateLeasesPPT}
          type={'contextual-positive'}
          wrapperClassName={classnames(styles['action-button'])}
          key={'download-basic-ppt-button'}
        />,
      ],
      titleClassName: styles['group-option-label'],
    },
    {
      title: `${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.directDownload`,
      )}:`,
      customOptions: DOWNLOAD_OPTIONS.map(({ label, subType }) => (
        <DownloadButton
          label={label}
          iconPosition={'right'}
          key={`downloadExcel-button-${label}`}
          exportType={ExportTypes.leases}
          exportSubtype={subType}
          queryVariables={{
            filter: { propertiesIds: propertyIds, onlyNotExpired: true },
          }}
          wrapperClassName={classnames(
            styles['action-button'],
            styles['action-button-download'],
          )}
        />
      )),
      titleClassName: styles['group-option-label'],
    },
  ];

  return (
    <div>
      <Container>
        <AgencyLeasingHeader
          breadcrumbLevels={[
            { content: agencyLeasing.name },
            {
              content: translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.tenants`,
              ),
            },
          ]}
          actionContent={
            <div className={styles['action-container']}>
              <CommonAgencyLeasingControl
                className={styles['common-control-container']}
                showMoreTrendsButton
                toggleOptions={toggleOptions}
                onChangeToggleOptions={onChangeToggleOptions}
                agencyLeasing={agencyLeasing}
                propertyIds={propertyIds}
              />
              <GroupOptionsContainer
                disabled={!propertyIds?.length}
                groupOptions={groupOptions}
                optionButtonIcon="download"
                optionContainerStyle="contextual"
                optionsWrapperClassName={styles['group-options-container']}
              />
            </div>
          }
        />
        {isSummary && (
          <SummaryContainer
            propertySet={agencyLeasing}
            setPropertiesPopup={setPropertiesPopup}
            unitOfMeasurement={UnitOfMeasurement.sf}
            toggleOptions={toggleOptions}
            setLeasesPopup={setLeasesPopup}
          />
        )}
        {isList && (
          <ListContainer
            propertySet={agencyLeasing}
            order={order}
            setOrder={setOrder}
            propertyIds={propertyIds}
          />
        )}
      </Container>
      {propertiesPopup?.isVisible && (
        <CompsPopup
          action={propertiesPopup?.onSeeAllComps}
          actionLabel={propertiesPopup?.actionLabel}
          hideAction={propertiesPopup.hideAction}
          graphqlQuery={getGraphqlQuery(propertiesPopup)}
          graphqlVariables={getCompsPopupSearchFilter(propertiesPopup)}
          onClose={() => setPropertiesPopup(PROPERTIES_POPUP_INIT)}
          showPropertyData
          subtitle={propertiesPopup.popupSubtitle}
          tagBackgroundColor={ColorNames.supportiveColor100}
          title={propertiesPopup.popupTitle}
        />
      )}
      {leasesPopup.isVisible && (
        <CompsPopup
          graphqlVariables={getCompsPopupSearchFilter(leasesPopup)}
          onClose={() => setLeasesPopup?.(LEASES_POPUP_INIT)}
          title={leasesPopup.popupTitle}
          subtitle={leasesPopup.popupSubtitle}
          graphqlQuery={SEARCH_LEASES_CLICKTHROUGHS_QUERY}
          tagBackgroundColor={ColorNames.supportiveColor100}
          showPropertyData
          actionLabel={translateText(
            `${I18N_AVANT_PROPERTY_LABEL_PATH}.leases.allLeases`,
          )}
          hideAction={leasesPopup.hideAction}
        />
      )}
      {isModalActive(MODALS.NOTIFICATION) && (
        <PageModal
          title={modalInfo.title}
          compressed
          fullScreen={false}
          withCloseLabel={false}
          onClose={() => {
            setActiveModal(null);
          }}
        >
          <p>{modalInfo.description}</p>
          <div className={styles['modal-button']}>
            <Button
              type="main"
              label={modalInfo.button}
              minWidth
              onClick={() => {
                setActiveModal(null);
              }}
            />
          </div>
        </PageModal>
      )}
    </div>
  );
};

export default Tenants;
