import { IUser } from './IUser';
import { IdName } from './IdName';
import { IProperty } from './IProperty';
import { ICompany } from 'interfaces/ICompany';
import { IMarket } from './IMarket';
import { IPropertyType } from './IPropertyType';
import { IScoopComment } from './IScoopComment';

enum ScoopCategories {
  PERCEPTION = 'PERCEPTION',
  AVAILABILITY = 'AVAILABILITY',
  SALE_ACTIVITY = 'SALE_ACTIVITY',
  TENANT_ACTIVITY = 'TENANT_ACTIVITY',
  DEVELOPMENT_ACTIVITY = 'DEVELOPMENT_ACTIVITY',
}

export enum ScoopCategoriesOrder {
  TENANT_ACTIVITY = 1,
  SALE_ACTIVITY = 2,
  DEVELOPMENT_ACTIVITY = 3,
  PERCEPTION = 4,
  AVAILABILITY = 5,
}

export interface ScoopCategoriesType extends IdName {
  type: ScoopCategories;
}

export interface IScoop {
  companies?: ICompany[];
  createdAt?: string;
  id?: number;
  lastEditedUser?: IUser;
  lastEditedUserId?: number;
  properties?: IProperty[];
  markets?: IMarket[];
  scoopCategories?: ScoopCategoriesType[];
  text?: string;
  totalScoopComments?: number;
  user?: IUser;
  userId?: number;
  updatedAt?: string;
  propertyTypes?: IPropertyType[];
  url?: string;
  scoopComments?: IScoopComment[];
  scoopMediaUrl?: string | null;
  scoopMediaId?: number | null;
  documentCount?: number;
}
