import React from 'react';
import classnames from 'classnames';
import styles from './GraphLegend.module.scss';

export interface ILegend {
  color: string;
  label: string;
  isLine?: boolean;
}

type Props = {
  legends: ILegend[];
};

const GraphLegend: React.FC<Props> = ({ legends }) => (
  <div className={styles['legend-container']}>
    {legends.map(leg => (
      <div key={leg.label}>
        <span
          className={classnames(styles['legend-item'], {
            [styles['legend-ball']]: !leg.isLine,
            [styles['legend-line']]: leg.isLine,
          })}
          style={{
            backgroundColor: leg.color,
          }}
        />
        <span className={styles['legend-text']}>{leg.label}</span>
      </div>
    ))}
  </div>
);

export default GraphLegend;
