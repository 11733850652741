import { defaultDimensions } from './constants';
import { IGraphsDimensions } from 'interfaces/graphs/IGraphsDimensions';
import { RecentLeasesIds } from 'components/Graphs/RecentLeases/graphIds';
import { select } from 'd3-selection';
import { sumBy } from 'lodash';
import { IBubbleDataPoint } from '../BubbleChartsElements/interfaces';

export const getLineId = (graphId: RecentLeasesIds) =>
  `most-recent-leases-line-${graphId}`;

export const fadeMarketLine = (graphId: RecentLeasesIds) => {
  select(`#${getLineId(graphId)}`).attr('opacity', 0.6);
};

export const appearMarketLine = (graphId: RecentLeasesIds) => {
  select(`#${getLineId(graphId)}`).attr('opacity', 1);
};

export const shouldRenderLine = (dataPoints: IBubbleDataPoint[]): boolean => {
  return sumBy(dataPoints, 'value') > 0;
};

export const getGraphDimensions = (dimensions?: IGraphsDimensions) => {
  const { margins, width, height } = dimensions || defaultDimensions;

  return {
    graph: {
      width: width,
      height: height,
    },
    svg: {
      width: width + margins.left + margins.right,
      height: height + margins.top + margins.bottom,
      transform: `translate(${margins.left}, 0)`,
    },
  };
};

export const getYAxisValuesFromTotal = (
  maxValue: number,
  minValue = 0,
  numberOfAxis = 4,
) => {
  const difference = maxValue - minValue;
  const interval = difference / numberOfAxis;
  const xAxisValues = [maxValue];

  for (let i = 1; i <= numberOfAxis; i++) {
    const value = maxValue - interval * i;

    xAxisValues.push(value);
  }

  return xAxisValues;
};
