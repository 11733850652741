import React from 'react';
import FormControl from 'components/FormControl';
import styles from '../../FormSection.module.scss';
import TextInput from 'components/Inputs/TextInput';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  required?: boolean;
  value?: string | null;
  onChange: (field: string, value: any) => void;
}

const Floors: React.FC<Props> = ({ value, onChange, required = false }) => {
  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.floors`)}
    >
      <TextInput
        value={value}
        id="lease-floors"
        onChange={value => onChange('floor', value)}
        placeholder={translateText(
          'avantPlatform.attributes.lease.prompt.enterFloors',
        )}
      />
      <span className={styles['input-helper']}>
        {translateText('avantPlatform.attributes.lease.prompt.floorsLabel')}
      </span>
    </FormControl>
  );
};

export default Floors;
