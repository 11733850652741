import {
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { translateText } from 'utils/i18n';

export const HEATMAP_SOURCE = 'heatmapSourceID';
export const HEATMAP_LAYER = 'heatmapLayerID';

export const HEATMAP_COLORS = [
  '#55489d',
  '#7269a7',
  '#8f89b1',
  '#c8c9c4',
  '#dc9776',
  '#e67e4f',
  '#ef6528',
];

export enum VisibilityLayer {
  visible = 'visible',
  none = 'none',
}

export enum MapConstants {
  weightPropertyName = 'buildingSize',
}

export enum HeatMap {
  NONE = 'none',
  SEARCH_RESULTS = 'search_results',
}

export const HEATMAP_RADIO_OPTIONS = [
  {
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.default`),
    value: HeatMap.NONE,
    classNames: 'default-option',
  },
  {
    label: translateText(`${I18N_AVANT_PROPERTY_LABEL_PATH}.map.heatMap`),
    value: HeatMap.SEARCH_RESULTS,
    classNames: 'heatmap-option',
  },
];
