import React from 'react';
import { colors } from 'constants/colors';
import { DONUT_SIZE } from '../DonutGraph/graphConstants';
import StaticDonutGraph from '../StaticDonutGraph';
import { IPropertyIndustry } from '../TenantIndustriesContainer/interfaces';
import {
  isTopIndustry,
  isSecondTopIndustry,
  isThirdTopIndustry,
} from './utils';
import { IStaticDonutGraphData } from 'interfaces/IStaticDonutGraphData';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';

const TENANT_INDUSTRIES_GRAPH_ID = 1;
const OTHER_INDUSTRIES_DATA_POINT_TITLE = 'other industries';
const OTHER_INDUSTRIES_SLICE_ORDER = 4;

interface Props {
  topIndustries: IPropertyIndustry[];
  otherIndustries: IPropertyIndustry[];
  wrapperClassName?: string;
}

const PropertyTopIndustriesContainer: React.FC<Props> = ({
  topIndustries,
  otherIndustries,
  wrapperClassName,
}) => {
  const getSliceColor = (index: number) => {
    if (isTopIndustry(index)) return colors.primaryColor500;
    if (isSecondTopIndustry(index)) return colors.primaryColor400;
    if (isThirdTopIndustry(index)) return colors.primaryColor300;
  };

  const getOtherIndustriesDataPoint = () => {
    const totalSize = otherIndustries.reduce(
      (prev, curr) => prev + curr.totalSF,
      0,
    );
    const isEmpty = totalSize === 0 || !otherIndustries?.[0]?.industry?.id;

    return isEmpty
      ? null
      : ({
          id: otherIndustries?.[0]?.industry?.id,
          title: OTHER_INDUSTRIES_DATA_POINT_TITLE,
          totalSize,
          color: colors.uiColor700,
          order: OTHER_INDUSTRIES_SLICE_ORDER,
        } as IStaticDonutGraphData);
  };

  const generateDataPoints = () => {
    const otherIndustriesDataPoint = getOtherIndustriesDataPoint();
    const dataPoints: IStaticDonutGraphData[] = topIndustries.map(
      (data, index) => ({
        id: data?.industry?.id,
        title: data?.industry?.name,
        totalSize: data.totalSF,
        // @TODO - update
        unitOfMeasurement: UnitOfMeasurement.sf,
        color: getSliceColor(index),
        order: index + 1,
      }),
    );

    if (otherIndustriesDataPoint?.id) dataPoints.push(otherIndustriesDataPoint);

    return dataPoints;
  };

  return (
    <StaticDonutGraph
      data={generateDataPoints()}
      donutId={TENANT_INDUSTRIES_GRAPH_ID}
      size={DONUT_SIZE.EXTRA_SMALL}
      wrapperClassName={wrapperClassName}
    />
  );
};

export default PropertyTopIndustriesContainer;
