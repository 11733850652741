import React, { useState } from 'react';
import styles from './MultiplePropertiesLink.module.scss';
import AYLink from 'components/AYLink';
import { IProperty } from 'interfaces/IProperty';
import {
  getMultiplePropertiesAddressesShort,
  getPropertyAddress,
} from 'utils/formatters/property';
import locations from 'routes';
import ModalPropertiesLinks from 'components/MultiplePropertiesLink/ModalPropertiesLinks';
import AlternateAddressIconSmall from 'components/AlternateAddressIconSmall';
import classNames from 'classnames';

interface Props {
  properties: IProperty[];
  preselectedProperty?: IProperty;
  small?: boolean;
}

const MultiplePropertiesLink: React.FC<Props> = ({
  properties,
  preselectedProperty,
  small,
}) => {
  const [isModalLinkVisible, setIsModalLinkVisible] = useState(false);

  const hasMultipleProperties = properties.length > 1;

  return hasMultipleProperties ? (
    <>
      <div className={styles['address-container']}>
        <div
          id="multiple-address-button"
          data-testid="multiple-address-button"
          className={classNames(
            styles['property-address'],
            small && styles.small,
          )}
          onClick={() => setIsModalLinkVisible(true)}
        >
          {getMultiplePropertiesAddressesShort(properties, preselectedProperty)}
        </div>

        <div className={styles['address-icon']}>
          <AlternateAddressIconSmall property={properties[0]} />
        </div>
      </div>
      <ModalPropertiesLinks
        isVisible={isModalLinkVisible}
        properties={properties}
        onClose={() => setIsModalLinkVisible(false)}
      />
    </>
  ) : (
    <AYLink
      to={locations.showProperty(properties[0].id!)}
      wrapperClassName={classNames(
        styles['property-address'],
        small && styles.small,
      )}
    >
      {getPropertyAddress(properties[0])}
      <div>
        <AlternateAddressIconSmall property={properties[0]} />
      </div>
    </AYLink>
  );
};

export default MultiplePropertiesLink;
