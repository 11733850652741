import {
  VictoryBarStyle,
  AxisData,
} from 'components/Graphs/VerticalGroupBarChart/types';
import { DEFAULT_BAR_STYLE } from 'components/VictoryBarChart/utils';
import { colors } from 'constants/colors';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_HEADINGS_PATH,
  I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { DOT_LG } from 'constants/placeholders';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import { pluralizeText } from 'utils/formatters/string';
import { translateText } from 'utils/i18n';

const FIRST_QUARTER = 1;
const NONE_STYLE = 'none';

const getStrokeWidth = (datum: any) => (datum?.tick > 0 ? 1 : 0);

const handleQuarterAxis = (value: string) => {
  const data = value.split('/');
  return data[1] === FIRST_QUARTER.toString() ? data[0] : '';
};

const handleXAxisYearLabel = (d: string) => {
  const year = d.split('/')?.[0];
  return Number(year) % 2 === 0 ? handleQuarterAxis(d) : '';
};

export const getMonthsText = (months: number) =>
  `${formatNumberWithDecimals(months, 1)} ${pluralizeText(
    months,
    translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.month`),
    translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.month_plural`),
  )}`;

export const getCurrencyText = (
  value: number,
  unitOfMeasurement?: string,
  currencySymbol?: string,
  countOfLeases?: number,
) => {
  let text = `${currencySymbol}${formatNumberWithDecimals(
    value,
    2,
  )} ${unitOfMeasurement || UnitOfMeasurement.sf}`;

  if (countOfLeases) {
    text += ` ${DOT_LG} ${countOfLeases} ${translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.lease`,
      {
        count: countOfLeases,
      },
    )} `;
  }

  return text;
};

export const getConcessionChartStyle = (
  unitOfMeasurement?: string,
  isQuarterly?: boolean,
  currencySymbol?: string,
): VictoryBarStyle => {
  return {
    chart: {
      height: 150,
      padding: {
        left: 62,
        top: 40,
        bottom: 25,
        right: 60,
      },
      style: {
        parent: {
          ...DEFAULT_BAR_STYLE.chart?.style.parent,
          background: colors.primaryColor900,
        },
      },
      domain: { y: [0, 1] },
    },
    legend: {
      x: 280,
      style: {
        ...DEFAULT_BAR_STYLE.legend?.style,
        labels: {
          ...DEFAULT_BAR_STYLE.legend?.style.labels,
          fontSize: 5,
        },
      },
    },
    label: {
      style: [
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 7,
        },
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 5,
        },
      ],
    },
    bar: {
      cornerRadius: { top: 2 },
      tooltip: {
        cornerRadius: 2,
        pointerLength: 0,
        flyoutHeight: 12,
        flyoutStyle: {
          fill: colors.primaryColor500,
          border: NONE_STYLE,
        },
        y: 39,
      },
      barRatio: 0.9,
    },
    xAxis: {
      style: {
        ...DEFAULT_BAR_STYLE.xAxis?.style,
        tickLabels: {
          fontSize: 5,
          fill: colors.ayWhiteColor,
          paddingLeft: 20,
        },
      },
      tickFormat: (d = '') =>
        isQuarterly ? handleQuarterAxis(d) : handleXAxisYearLabel(d),
    },
    yAxis: [
      {
        style: {
          ...(DEFAULT_BAR_STYLE.yAxis as AxisData)?.style,
          tickLabels: {
            fontSize: 5,
            fill: colors.ayWhiteColor,
          },
          grid: {
            stroke: colors.uiColor800,
            strokeWidth: getStrokeWidth,
          },
          ticks: {
            stroke: colors.uiColor800,
            strokeWidth: getStrokeWidth,
            size: isQuarterly ? 20 : 25,
          },
        },
        tickFormat: (d: number) =>
          `${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.ti`,
          )} ${getCurrencyText(d, unitOfMeasurement, currencySymbol)}`,
        offsetX: 80,
      },
      {
        style: {
          ...(DEFAULT_BAR_STYLE.yAxis as AxisData)?.style,
          tickLabels: {
            fontSize: 5,
            fill: colors.ayWhiteColor,
            textAnchor: 'start',
          },
          ticks: {
            padding: isQuarterly ? -30 : -40,
            stroke: colors.uiColor800,
            strokeWidth: getStrokeWidth,
            size: isQuarterly ? 15 : 25,
          },
        },
        tickFormat: (d: number) => getMonthsText(d),
        offsetX: isQuarterly ? 400 : 410,
      },
    ],
    lineData: {
      style: {
        data: {
          fill: NONE_STYLE,
          stroke: colors.supportive500,
          cursor: 'default',
          strokeWidth: 1,
        },
        styleOnHover: {
          fill: colors.supportive500,
          stroke: colors.supportive500_opacity,
          strokeWidth: 3,
          cursor: 'pointer',
        },
      },
      tooltip: {
        style: { fontSize: 6, fill: colors.ayWhiteColor },
        cornerRadius: 2,
        pointerLength: 0,
        flyoutHeight: 10,
        flyoutStyle: {
          fill: colors.supportive500,
          stroke: NONE_STYLE,
        },
        y: 37,
      },
    },
  };
};

export const LEGENDS = [
  {
    name: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.tenantImprovementsAllowance`,
    ),
    symbol: {
      fill: colors.supportive500,
      type: 'minus',
      size: 3,
    },
  },
  {
    name: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.monthsOfFreeRent`,
    ),
    symbol: { fill: colors.primaryColor500 },
  },
];

export const TITLES = [
  translateText(`${I18N_AVANT_PROPERTY_HEADINGS_PATH}.concessions`),
  translateText(
    `${I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH}.valuesNormalizedForTen`,
  ),
];
