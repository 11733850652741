import { ActivityOwnerMarketScoop } from 'components/ActivityOwner';
import ScoopCommentsModal from 'components/ScoopCommentsModal';
import ScoopCompanies from 'components/ScoopCompanies';
import dateFormats from 'constants/dateFormats';
import { DOT } from 'constants/placeholders';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { IScoop } from 'interfaces/IScoop';
import { IUser } from 'interfaces/IUser';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  defaultToIsoFormat,
  formatDate,
  getRelativeDate,
} from 'utils/formatters/date';
import { ActivityCardContainer, ActivityCardHeader } from '../ActivityCard';
import styles from './ActivityMarketScoopCard.module.scss';
import { IActivity } from 'interfaces/IActivity';
import { IMarket } from 'interfaces/IMarket';
import Linkify from 'linkify-react';

export interface Props {
  date: string;
  onDelete: () => void;
  onEdit: () => void;
  onShareButtonClick: () => void;
  refetchScoopList?: () => void;
  scoop: IScoop;
  user: IUser;
  activity?: IActivity;
  preselectedMarket?: IMarket;
}

const ActivityMarketScoopCard: React.FC<Props> = props => {
  const hasCreatedUserOrScoop = !!props.scoop;
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [hasDataChanges, setHasDataChanges] = useState(false);
  const { hasEditorAccess } = useContext(subscriptionsContext);

  const { onEdit, refetchScoopList } = props;
  const date = props.scoop?.createdAt
    ? getRelativeDate(defaultToIsoFormat(props.scoop?.createdAt))
    : '';
  const time = formatDate(props.date, dateFormats.TIME_12_HOUR, false);
  const headerText = date ? `${date} ${DOT} ${time}` : '';

  const onChangeData = useCallback(() => setHasDataChanges(true), []);

  useEffect(() => {
    if (hasDataChanges) {
      refetchScoopList?.();
      setHasDataChanges(false);
    }
  }, [hasDataChanges, refetchScoopList]);

  const renderCreatedByUser = () => (
    <h3 className={styles['activity-date']}>
      {!!props.scoop && (
        <ActivityOwnerMarketScoop
          user={props.user!}
          scoop={props.scoop!}
          colored
        />
      )}
      {headerText}
    </h3>
  );

  return (
    <>
      <ActivityCardContainer>
        <ActivityCardHeader
          categories={props.scoop.scoopCategories || []}
          headerText={headerText}
          scoop={props.scoop}
          user={props.user}
          market={props.scoop.markets?.[0]}
          onEditClick={onEdit}
          onShareClick={props.onShareButtonClick}
          onCommentsClick={() => setIsCommentsModalOpen(true)}
          preselectedMarket={props.preselectedMarket}
        />
        <div className={styles['card-separator']} />
        <Linkify
          tagName="p"
          options={{
            className: styles.link,
            nl2br: true,
          }}
        >
          {props.scoop.text}
        </Linkify>
        <ScoopCompanies scoop={props.scoop} />
        {hasEditorAccess ? <div className={styles['card-separator']} /> : null}
        {hasCreatedUserOrScoop && renderCreatedByUser()}
      </ActivityCardContainer>
      {isCommentsModalOpen && (
        <ScoopCommentsModal
          date={props.date}
          onChangeData={onChangeData}
          onEditClick={onEdit}
          onModalToggle={setIsCommentsModalOpen}
          onShareClick={props.onShareButtonClick}
          scoop={props.scoop}
          scoopUser={props.user}
        />
      )}
    </>
  );
};

export default ActivityMarketScoopCard;
