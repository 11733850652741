export enum PROPERTY_SECTIONS {
  BUILDING_INFO = 'buildingInfo',
  RECENT_ACTIVITY = 'recentActivity',
  NEARBY_AMENITIES = 'nearbyAmenities',
  DEMOGRAPHICS = 'demographics',
  COMPARATIVE = 'comparative',
  TENANT_PROSPECT = 'tenantProspect',
}

export enum PROPERTY_SECTIONS_TITLES {
  BUILDING_INFO = 'Building Info',
  RECENT_ACTIVITY = 'Recent Activity',
  NEARBY_AMENITIES = 'Nearby Amenities',
  DEMOGRAPHICS = 'Demographics',
  COMPARATIVE = 'Comp Set',
  TENANT_PROSPECT = 'Tenant Prospect',
}
