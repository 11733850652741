import { ScaleTime } from 'd3';
import { BaseType, select } from 'd3-selection';
import { getDateFromYear } from './date';

export const cleanUpElement = (element: BaseType) =>
  select(element)
    .selectAll('*')
    .remove();

export const getXPosition = (
  xValue: any,
  xScale: ScaleTime<number, number>,
  isQuarterly?: boolean,
) => {
  const xPosition = isQuarterly
    ? xScale(new Date(xValue))
    : xScale(getDateFromYear(xValue));

  return xPosition || 0;
};
