import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { IdName } from 'interfaces/IdName';
import { Props as TextInputProps } from 'components/Inputs/TextInput';

export enum FormFieldType {
  choice = 'choice',
  company = 'company',
  currency = 'currency',
  decimal = 'decimal',
  feetInches = 'feetInches',
  integer = 'integer',
  range = 'range',
  text = 'text',
  textMultiple = 'textMultiple',
}

export type FormFieldConfig = {
  type: FormFieldType;
  field?: string;
  labelI18nKey: string;
  legend?: string;
  inputId?: string;
  isDisabled?: boolean;
  pattern?: RegExp;
  placeholderI18nKey?: string;
  textCentered?: boolean;
  textMultipleConfig?: {
    fieldMultiple?: string;
    isInvalid?: boolean;
    addButtonText?: string;
    textInputProps?: Partial<TextInputProps>;
  };
  currencyConfig?: {
    currencySymbol?: string;
    fieldCurrency?: string;
  };
  rangeConfig?: {
    fieldFrom: string;
    labelFromI18nKey: string;
    fieldTo: string;
    labelToI18nKey: string;
    unitType?: UnitOfMeasurement;
    shouldValidate?: boolean;
  };
  feetInchesConfig?: {
    fieldFeet?: string;
    fieldInches?: string;
  };
  companyConfig?: {
    createCompanyLabelI18nKey: string;
    isMultiple?: boolean;
  };
  choiceConfig?: {
    options: IdName[];
  };
};
