import { gql } from '@apollo/client';
import { TIM_FRAGMENT } from './fragments';

export const SEARCH_TIMS_QUERY = gql`
  query TIMs($search: TIMSearchInput!) {
    TIMs(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        ...TIMFragment
      }
    }
  }
  ${TIM_FRAGMENT}
`;

export const GET_TIM_QUERY = gql`
  query getTIM($id: ID!) {
    TIM(id: $id) {
      ...TIMFragment
    }
  }
  ${TIM_FRAGMENT}
`;

export const TIM_STATUSES_QUERY = gql`
  query timStatusTypes {
    timStatusTypes {
      id
      name
    }
  }
`;

export const TIMS_ANALYTICS_TIM_SIZE = gql`
  query timsAnalyticsTimSize($search: TimsAnalyticsTimSizeInput!) {
    timsAnalyticsTimSize(search: $search) {
      totalTims
      totalTargetSize
      totalTargetSizeMt
      averageRequirementSize
      averageRequirementSizeMt
      averageTimeInMarketInMonths
    }
  }
`;

export const TIMS_ANALYTICS_INDUSTRIES_DONUT = gql`
  query timsAnalyticsTenantIndustries(
    $search: TimsAnalyticsTenantIndustriesInput!
  ) {
    timsAnalyticsTenantIndustries(search: $search) {
      slices {
        ids
        name
        sizeRequirements
        sizeRequirementsMt
        totalTims
      }
    }
  }
`;

export const TIMS_ANALYTICS_RELOCATING_TENANTS = gql`
  query timsAnalyticsRelocatingTenants(
    $search: TimsAnalyticsRelocatingTenantsInput!
  ) {
    timsAnalyticsRelocatingTenants(search: $search) {
      slices {
        ids
        market
        marketTotal
        marketTotalMt
        marketPercentageTotal
      }
    }
  }
`;

export const TIMS_ANALYTICS_HISTORICAL_DEMAND = gql`
  query timsAnalyticsHistoricalDemand(
    $search: TimsAnalyticsHistoricalDemandInput!
  ) {
    timsAnalyticsHistoricalDemand(search: $search) {
      slices {
        ids
        year
        count
        quarter
        historicalDemandTargetSize
        historicalDemandTargetSizeMt
      }
    }
  }
`;
export const TIMS_ESTIMATED_OCCUPANCY_TIMING_CHART = gql`
  query timsEstimatedOccupancyTimingChart(
    $search: TimsEstimatedOccupancyTimingChartInput
  ) {
    timsEstimatedOccupancyTimingChart(search: $search) {
      slices {
        ids
        year
        count
        quarter
        totaltargetSize
        totaltargetSizeMt
      }
    }
  }
`;

export const TIMS_ANALYTICS_TIME_IN_MARKET = gql`
  query timsAnalyticsTimeInMarket($search: TimsAnalyticsTimeInMarketInput) {
    timsAnalyticsTimeInMarket(search: $search) {
      slices {
        ids
        sizeBucket
        averageMonths
      }
    }
  }
`;

export const TIMS_ANALYTICS_SIZE_REQUIREMENTS = gql`
  query timsAnalyticsSizeRequirements(
    $search: TimsAnalyticsSizeRequirementsInput
  ) {
    timsAnalyticsSizeRequirements(search: $search) {
      slices {
        sizeBucket
        ids
        idsMt
        count
        countMt
        totalTargetSizeMax
        totalTargetSizeMaxMt
      }
    }
  }
`;
