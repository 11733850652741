import React from 'react';
import { IProperty } from 'interfaces/IProperty';
import TIAllowance from '../../FormFields/TIAllowance';
import FreeRentMonths from '../../FormFields/FreeRentMonths';
import TaxAndOpEx from '../../FormFields/TaxAndOpEx';
import Term from '../../FormFields/Term';
import Comments from '../../FormFields/Comments';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import { ITaxAndOpex } from 'interfaces/ITaxAndOpex';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';

export interface Props {
  property?: IProperty;
  unitOfMeasurement: string;
  data: IPricingQuoteInput;
  onChange: (key: string, value: string) => void;
}

const DetailsPricingQuote: React.FC<Props> = ({
  property,
  unitOfMeasurement,
  data,
  onChange,
}) => {
  const taxAndOpex: ITaxAndOpex = {
    taxAndOpex: data.taxAndOpex,
    tax: data.tax,
    opex: data.opex,
  };

  return (
    <>
      <TIAllowance
        property={property}
        onChange={onChange}
        unitOfMeasurement={unitOfMeasurement}
        value={data.tenantImprovementAllowance}
      />
      <FreeRentMonths
        property={property}
        onChange={onChange}
        value={data.freeRentMonths}
      />
      <Term isTermInMonths value={data.termInMonths} onChange={onChange} />
      <TaxAndOpEx
        unitOfMeasurement={unitOfMeasurement}
        value={taxAndOpex}
        property={property}
        onChange={onChange}
        date={data.date}
      />
      <Comments
        field="comments"
        property={property}
        value={data.comments || ''}
        label={`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.comment_plural`}
        onChange={onChange}
      />
    </>
  );
};

export default DetailsPricingQuote;
