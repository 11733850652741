import React from 'react';
import { noop } from 'lodash';
import { useHistory } from 'react-router-dom';
import usePropertySet from 'hooks/usePropertySet';
import { CompSetModalForm } from 'components/CompSetModalForm';
import { PropertySetTypes } from 'constants/propertySetTypes';
import { SortDirections } from 'constants/sortDirections';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import {
  GET_PROPERTY_SETS_QUERY,
  GET_PROPERTY_SET_QUERY,
} from 'graphql/propertySet';

import { PropertyCompSetModalFormProps } from './types';
import locations from '../../../routes';
import { OVERVIEW_SUMMARY_SUBSECTION } from '../CompSetProfilePage/constants';

const getCreateRefetchQueries = (propertySet?: IPropertySet | null) => [
  {
    query: GET_PROPERTY_SETS_QUERY,
    variables: {
      search: {
        filter: {
          type: PropertySetTypes.AGENCY,
          includedProperty: propertySet?.targetProperty?.id,
        },
        order: {
          field: 'createdAt',
          direction: SortDirections.desc,
        },
      },
    },
  },
];

const getUpdateRefetchQueries = (propertySet?: IPropertySet | null) => [
  {
    query: GET_PROPERTY_SET_QUERY,
    variables: { id: propertySet?.id },
  },
];

const getDeleteRefetchQueries = (propertySet?: IPropertySet | null) =>
  getCreateRefetchQueries(propertySet);

const PropertyCompSetModalForm = ({
  initialPropertySet,
  onCancel = noop,
  onSubmit = noop,
  onDelete = noop,
}: PropertyCompSetModalFormProps) => {
  const history = useHistory();
  const isEditing = !!initialPropertySet?.id;
  const {
    propertySet,
    updatePropertySet,
    deletePropertySet,
    isDeleting,
    isSubmitting,
    submitPropertySet,
    isLoadingAvailabilities,
  } = usePropertySet(initialPropertySet, {
    isEditing,
    onDelete,
    onSubmit: newPropertySet => onSubmit(newPropertySet, isEditing),
  });

  const handleSubmit = async () => {
    const refetchQueries = isEditing
      ? getUpdateRefetchQueries(propertySet)
      : getCreateRefetchQueries(propertySet);

    const createUpdatePropertySet = await submitPropertySet({ refetchQueries });
    const firstFeaturedProperty = propertySet?.properties?.find(
      f => f.featured,
    );
    const propertySetId = isEditing
      ? createUpdatePropertySet?.data?.updatePropertySet?.id
      : createUpdatePropertySet?.data?.createPropertySet?.id;

    onCancel();

    history.push(
      locations.showPropertyCompSet(
        Number(Number(firstFeaturedProperty?.id)),
        Number(propertySetId),
        OVERVIEW_SUMMARY_SUBSECTION,
      ),
    );
  };

  const handleDelete = () => {
    deletePropertySet({
      refetchQueries: getDeleteRefetchQueries(propertySet),
    });
  };

  return (
    <CompSetModalForm
      isEditing={isEditing}
      isLoading={isLoadingAvailabilities}
      isSubmitting={isSubmitting || isDeleting}
      propertySet={propertySet}
      updatePropertySet={updatePropertySet}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  );
};

export { PropertyCompSetModalForm };
