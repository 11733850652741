import { gql } from '@apollo/client';

export const BUSINESSES_QUERY = gql`
  query businesses {
    businesses {
      id
      name
    }
  }
`;

export const BUSINESS_TYPES_QUERY = gql`
  query businessTypes($businessId: Int) {
    businessTypes(businessId: $businessId) {
      id
      name
    }
  }
`;

export const BUSINESS_QUERY = gql`
  query business($id: Int!) {
    business(id: $id) {
      id
      name
    }
  }
`;

export const BUSINESS_TYPE_QUERY = gql`
  query businessType($id: Int!) {
    businessType(id: $id) {
      id
      name
    }
  }
`;

export const SEARCH_BUSINESS_PARK_QUERY = gql`
  query businessParks($search: BusinessParkSearchInput!) {
    businessParks(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        id
        name
        marketId
        createdAt
        updatedAt
      }
    }
  }
`;
