import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { isNil, isEmpty } from 'lodash';
import locations from 'routes';
import LoadingMessage from 'components/LoadingMessage';
import NotificationMessage from 'components/NotificationMessage';
import NotFound from 'components/NotFound';
import Overview from 'components/AgencyAssignments/Sections/Overview';
import Properties from 'components/AgencyAssignments/Sections/Properties';
import RecentActivity from 'components/AgencyAssignments/Sections/RecentActivity';
import SideNavigationBar, {
  COLLAPSED_WIDTH,
  WIDTH,
} from 'components/SideNavigationBar';
import Tenants from 'components/AgencyAssignments/Sections/Tenants';
import { AgencyLeasingModalForm } from 'components/AgencyLeasingModalForm';
import { Availabilities } from 'components/AgencyAssignments/Sections/Availabilities';
import {
  AgencyLeasingAssignmentSection,
  AgencyLeasingOverviewSection,
  AgencyLeasingSection,
} from 'components/AgencyAssignments/types';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import {
  isOverviewSection,
  isPropertySection,
  isAvailabilitySection,
  isTenantSection,
  isRecentActivitySection,
} from 'components/AgencyAssignments/utils';
import useHelmet from 'hooks/useHelmet';
import { useAvailabilitiesOverTimeWide } from 'hooks/useAvailabilitiesOverTimeWide';
import { translateText } from 'utils/i18n';
import { PropertySetTypes } from 'constants/propertySetTypes';
import { LeaseBaseRentTime, LeaseRentType } from 'constants/leases';
import {
  I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH,
  I18N_AVANT_PROPERTY_PAGES,
} from 'constants/i18n';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { GET_PROPERTY_SET_QUERY } from 'graphql/propertySet';
import { GET_OVERVIEW_LEASING_METADATA } from 'graphql/agencyAssignments';
import { GET_SNOWFLAKE_BLOCK_AVAILABILITIES } from 'graphql/snowflakeBlockAvailability';
import { INotification } from 'constants/INotification';
import { SnowflakeBlockAvailabilitySearchData } from 'interfaces/ISnowflakeBlockAvailability';
import { getAgencyLeasingSectionFromURL, buildNavigationItems } from './utils';

type UrlParams = {
  id: string;
};

const NOTIFICATION_INIT_STATE = {
  isVisible: false,
  hasError: false,
  message: '',
};

const AgencyAssignmentsPage = () => {
  const history = useHistory();
  const { setPageTitle } = useHelmet();
  const { id: agencyLeasingId } = useParams<UrlParams>();
  const { pathname } = useLocation();

  const noId = !agencyLeasingId || isNaN(+agencyLeasingId);
  const [toggleOptions, setToggleOptions] = useState<LeaseToggleOptions>({
    rentType: LeaseRentType.FS,
    timeMeasurement: LeaseBaseRentTime.Annual,
  });
  const [isModalActive, setIsModalActive] = useState(false);
  const [agencyLeasing, setAgencyLeasingAssignment] = useState<IPropertySet>();
  const [isNavBarCollapsed, setIsNavBarCollapsed] = useState(false);
  const [hasAvailabilityData, setHasAvailabilityData] = useState<boolean>();
  const [notification, setNotification] = useState<INotification>(
    NOTIFICATION_INIT_STATE,
  );
  const section = getAgencyLeasingSectionFromURL(pathname);
  const propertyIds = (agencyLeasing?.properties || [])
    .filter(Boolean)
    .map(({ id }) => id!);
  const hasProperties = !isEmpty(propertyIds);
  const propertyTypeName = agencyLeasing?.properties![0]?.propertyType?.name;

  useEffect(() => {
    const PAGE_TITLE = `${I18N_AVANT_PROPERTY_PAGES}.agencyAssignments.title`;

    setPageTitle(translateText(PAGE_TITLE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!section && !noId) {
      const agencyLeasingPath = locations.showAgencyAssignments(
        agencyLeasingId,
      );

      history.replace(
        `${agencyLeasingPath}/${AgencyLeasingOverviewSection.Summary}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, noId, agencyLeasingId]);

  const { loading: isLoading } = useQuery(GET_PROPERTY_SET_QUERY, {
    variables: { id: agencyLeasingId },
    skip: noId,
    onCompleted: data => {
      setAgencyLeasingAssignment(data?.propertySet);
    },
  });

  const {
    data: blockAvailabilityData,
    loading: isLoadingBlockAvailabilities,
  } = useQuery<SnowflakeBlockAvailabilitySearchData>(
    GET_SNOWFLAKE_BLOCK_AVAILABILITIES,
    {
      variables: {
        propertyIds,
        search: {
          type: 'DIRECT',
          isExecutiveSuiteFlag: false,
          ...(propertyTypeName ? { sectors: [propertyTypeName] } : undefined),
        },
      },
      skip: !hasProperties || !agencyLeasing,
      onCompleted: data => {
        const hasAvailabilityData = !isEmpty(
          data.blockAvailabilities.availabilitiesByProperties,
        );

        setHasAvailabilityData(hasAvailabilityData);

        if (
          !hasAvailabilityData &&
          section === AgencyLeasingSection.Availabilities
        ) {
          if (agencyLeasing?.id) {
            setNotification({
              hasError: true,
              isVisible: true,
              message: translateText(
                `${I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH}.availabilities.noAvailabilitiesMessage`,
              ),
            });
            history.push(
              locations.showAgencyAssignments(
                agencyLeasing.id,
                AgencyLeasingOverviewSection.Summary,
              ),
            );
          } else {
            history.push(locations.showNotFoundPage());
          }
        }
      },
    },
  );

  const { availabilitiesOverTimeWideData } = useAvailabilitiesOverTimeWide({
    propertiesIds: propertyIds,
    toggleOptions: toggleOptions,
  });

  if (isLoading || isLoadingBlockAvailabilities) {
    return <LoadingMessage />;
  }

  const askingRentData = {
    annualTotalAskingRentFS:
      availabilitiesOverTimeWideData?.blockAvailabilitiesOverTimeWide
        .annualDirectAskingRentFS ?? undefined,
    annualTotalAskingRentNNN:
      availabilitiesOverTimeWideData?.blockAvailabilitiesOverTimeWide
        .annualDirectAskingRentNNN ?? undefined,
    monthlyTotalAskingRentFS:
      availabilitiesOverTimeWideData?.blockAvailabilitiesOverTimeWide
        .monthlyDirectAskingRentFS ?? undefined,
    monthlyTotalAskingRentNNN:
      availabilitiesOverTimeWideData?.blockAvailabilitiesOverTimeWide
        .monthlyDirectAskingRentNNN ?? undefined,
  };

  const renderContent = (section: AgencyLeasingAssignmentSection) => {
    if (!agencyLeasing) {
      return <NotFound />;
    }

    if (isOverviewSection(section)) {
      return (
        <Overview
          askingRentData={askingRentData}
          agencyLeasing={agencyLeasing}
          section={section}
          toggleOptions={toggleOptions}
          onChangeToggleOptions={setToggleOptions}
          onEditButtonClick={() => setIsModalActive(true)}
        />
      );
    }

    if (isPropertySection(section)) {
      return <Properties agencyLeasing={agencyLeasing} section={section} />;
    }

    if (isAvailabilitySection(section)) {
      return (
        <Availabilities
          agencyLeasing={agencyLeasing}
          propertyIds={propertyIds}
          blockAvailabilities={{
            ...blockAvailabilityData?.blockAvailabilities,
            ...askingRentData,
          }}
        />
      );
    }

    if (isTenantSection(section)) {
      return (
        <Tenants
          agencyLeasing={agencyLeasing}
          section={section}
          toggleOptions={toggleOptions}
          onChangeToggleOptions={setToggleOptions}
        />
      );
    }

    if (isRecentActivitySection(section)) {
      return <RecentActivity agencyLeasing={agencyLeasing} />;
    }
  };

  return (
    <div>
      <SideNavigationBar
        items={buildNavigationItems(
          +agencyLeasingId,
          !isNil(hasAvailabilityData) && !hasAvailabilityData
            ? [AgencyLeasingSection.Availabilities]
            : undefined,
        )}
        activeItem={section}
        isCollapsed={isNavBarCollapsed}
        onCollapse={setIsNavBarCollapsed}
      />
      <div
        style={{
          marginLeft: isNavBarCollapsed ? COLLAPSED_WIDTH : WIDTH,
        }}
      >
        <div>{renderContent(section as AgencyLeasingSection)}</div>
      </div>
      {isModalActive ? (
        <AgencyLeasingModalForm
          initialPropertySet={agencyLeasing}
          onCancel={() => setIsModalActive(false)}
          onChange={agencyLeasingAssignment =>
            setAgencyLeasingAssignment(agencyLeasingAssignment)
          }
          refetchQueries={[
            {
              query: GET_OVERVIEW_LEASING_METADATA,
              variables: {
                search: {
                  filter: {
                    type: PropertySetTypes.AGENCY_ASSIGNMENT,
                    setIds: agencyLeasing?.id ? [agencyLeasing.id] : undefined,
                  },
                },
              },
            },
          ]}
        />
      ) : null}
      <NotificationMessage
        text={notification.message}
        show={notification.isVisible}
        isSuccess={!notification.hasError}
        onClose={() => setNotification(NOTIFICATION_INIT_STATE)}
      />
    </div>
  );
};

export default AgencyAssignmentsPage;
