import React from 'react';
import { IProperty } from 'interfaces/IProperty';
import DateInput from 'components/Inputs/DateInput';
import FormControl from 'components/FormControl';
import formsStyles from '../../FormSection.module.scss';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export interface Props {
  property?: IProperty;
  data: IPricingQuoteInput;
  onChange: (key: string, value: string) => void;
}

const BottomPricingQuote: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.date`)}
        wrapperClassName={formsStyles['form-row']}
        required
      >
        <DateInput
          id="date"
          value={data.date}
          onChange={(value: any) => onChange('date', value)}
        />
      </FormControl>
    </>
  );
};

export default BottomPricingQuote;
