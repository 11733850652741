import { useQuery } from '@apollo/client';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { IdName } from 'interfaces/IdName';
import { GET_APPROVED_PROPERTY_TYPE_AMENITIES_QUERY } from 'graphql/amenities';
import {
  GET_IMAGE_CATEGORIES_QUERY,
  GET_VIDEO_CATEGORIES_QUERY,
} from 'graphql/images/queries';
import { ImageCategory, sortImageCategories } from '../../utils';

const SCOOP_IMAGE_CATEGORY_ID = 23;
const MAX_CHARACTERS = 20;
interface Props {
  propertyTypeId: number;
  isVideo: boolean;
  profileType?: PROFILE_TYPES;
}
export const useGetImageCategoryData = ({
  propertyTypeId,
  isVideo,
  profileType,
}: Props) => {
  const { data: amenitiesData } = useQuery(
    GET_APPROVED_PROPERTY_TYPE_AMENITIES_QUERY,
    {
      variables: { propertyTypeIds: [propertyTypeId] },
    },
  );

  const { data, loading } = useQuery<{ [key: string]: IdName[] }>(
    isVideo ? GET_VIDEO_CATEGORIES_QUERY : GET_IMAGE_CATEGORIES_QUERY,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const allCategories = isVideo ? data?.videoCategories : data?.imageCategories;
  const categoryItems =
    profileType === PROFILE_TYPES.PROPERTY
      ? allCategories?.filter(({ id }) => id !== SCOOP_IMAGE_CATEGORY_ID)
      : allCategories;

  const tagItems = sortImageCategories([...(categoryItems || [])]).map(ic => ({
    value: ic.id,
    label: ic.name,
  }));

  const categories = tagItems.filter(
    item => item.label !== ImageCategory.AMENITY,
  );

  const amenities = amenitiesData?.amenities.map((amenity: IdName) => ({
    value: amenity.id,
    label:
      amenity?.name?.length > MAX_CHARACTERS
        ? amenity?.name.substring(0, MAX_CHARACTERS) + '...'
        : amenity?.name,
  }));

  return {
    isLoading: loading,
    categories,
    amenities,
  };
};
