import ISale from 'interfaces/ISale';
import React, { useContext } from 'react';
import { ILease } from 'interfaces/ILease';
import { DOT } from 'constants/placeholders';
import styles from './ActivityOwner.module.scss';
import { authContext } from 'contexts/AuthContext';
import UserMailToLink from 'components/UserMailToLink';
import { translateText as t } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export interface Props {
  data: ILease | ISale;
}

const ActivityOwner: React.FC<Props> = ({ data }) => {
  const { user } = useContext(authContext);

  const { createdUser, updatedUser } = data;
  const isMe = user.id === createdUser?.id;
  const isSameUserEdit = createdUser?.id === updatedUser?.id;

  const renderEditedUser = () => {
    if (isSameUserEdit || !updatedUser) return <span> {DOT} </span>;

    const isMeEdit = user.id === updatedUser?.id;

    return (
      <>
        <span> {DOT} </span>
        <span className={styles['user-name']}>
          <span>Edited by </span>
          {isMeEdit ? (
            t(`${I18N_PLATFORM_COMMON_WORD_PATH}.you`)
          ) : (
            <UserMailToLink user={updatedUser} />
          )}
        </span>
        <span> {DOT} </span>
      </>
    );
  };

  return (
    <>
      {!!createdUser.email && (
        <>
          <span className={styles['user-name']}>
            {isMe ? (
              t(`${I18N_PLATFORM_COMMON_WORD_PATH}.you`)
            ) : (
              <UserMailToLink user={createdUser} />
            )}
          </span>
          {renderEditedUser()}
        </>
      )}
    </>
  );
};

export default ActivityOwner;
