import dayjs from 'dayjs';
import dateFormats from 'constants/dateFormats';
import { IRadioItem } from 'components/Inputs/RadioInput';

const { ISO_DATE, MONTH_YEAR } = dateFormats;

export const getSaleDateSuggestions = (date?: string): IRadioItem[] => {
  const baseDate = date ? dayjs(date, ISO_DATE).utc() : dayjs.utc();

  const currMonthLabel = baseDate.format(MONTH_YEAR);
  const currMonthValue = baseDate.format(ISO_DATE);

  const oneMonthAgoDate = baseDate.clone().subtract(1, 'month');
  const oneMonthAgoLabel = oneMonthAgoDate.format(MONTH_YEAR);
  const oneMonthAgoValue = oneMonthAgoDate.format(ISO_DATE);

  const twoMonthsAgoDate = baseDate.clone().subtract(2, 'month');
  const twoMonthsAgoLabel = twoMonthsAgoDate.format(MONTH_YEAR);
  const twoMonthsAgoValue = twoMonthsAgoDate.format(ISO_DATE);

  return [
    {
      label: currMonthLabel,
      value: currMonthValue,
    },
    {
      label: oneMonthAgoLabel,
      value: oneMonthAgoValue,
    },
    {
      label: twoMonthsAgoLabel,
      value: twoMonthsAgoValue,
    },
  ];
};
