import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { debounce, isFunction } from 'lodash';
import { useApolloClient, useQuery } from '@apollo/client';

import Button from 'components/Button/new';
import LoadingMessage from 'components/LoadingMessage';
import { ApiClients } from 'constants/apiClients';
import { Keyboard } from 'constants/keyboard';
import { ErrorLogger } from 'services/ErrorLogger';
import { setTimeout } from 'timers';
import { INPUT_DEBOUNCE_TIME_MS } from 'constants/inputs';
import { useOutsideElementClick } from 'hooks/useOutsideElementClick';

import SearchSuggestionItem from './SearchSuggestionItem';
import styles from './GenericSearchInput.module.scss';
import { GenericSearchInputProps, IconPosition } from './types';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import Icon from 'components/Icon';
import { colors } from 'constants/colors';
import { usersClient, usersClientWithOkta } from 'graphql/usersClient';

const DEFAULT_MIN_CHARS_SEARCH = 3;

const renderDefaultSuggestionOption = (
  searchText: string,
  textToHighlight?: string,
) => (
  <SearchSuggestionItem
    searchText={searchText}
    textToHighlight={textToHighlight}
  />
);

const GenericSearchInput = ({
  graphqlQuery,
  graphqlVariables = {},
  graphqlClient,
  graphqlSkip,
  graphqlUsersWithoutOkta,
  onSearchTermChange,
  mapper,
  itemRenderer,
  onItemSelect,
  clearAfterItemSelect,
  initialValue,
  placeholder,
  clearable,
  disabled,
  onFocus,
  onBlur,
  onClear,
  clientSideFilter,
  minCharsForSearch = DEFAULT_MIN_CHARS_SEARCH,
  createSearchedItem,
  createSearchedItemSuccess,
  className,
  suggestionContainerClassName,
  id,
  style,
  valueMapper,
  icon,
  iconPosition = IconPosition.Left,
}: GenericSearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const propertiesClient = useApolloClient();

  const [inputValue, setInputValue] = useState<string>(initialValue || '');

  const handleBlur = () => {
    setIsInputFocused(false);
    onBlur?.();
  };

  const handleClickOutside = () => {
    setIsSuggestionListOpen(false);
    handleBlur();
  };

  useOutsideElementClick(outerRef, handleClickOutside);

  const [searchTerm, setSearchTerm] = useState(initialValue || '');

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isSuggestionListOpen, setIsSuggestionListOpen] = useState(false);

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<
    number | null
  >(null);

  const debouncedSearchTerm = useRef(
    debounce(setSearchTerm, INPUT_DEBOUNCE_TIME_MS),
  );

  useEffect(() => {
    if (!createSearchedItemSuccess) return;
    setIsInputFocused(false);
    setIsSuggestionListOpen(false);
  }, [createSearchedItemSuccess]);

  useEffect(() => {
    const debounceFn = debouncedSearchTerm.current;
    debounceFn(inputValue.trim());

    return () => {
      debounceFn.cancel?.();
    };
  }, [inputValue]);

  useEffect(() => {
    onSearchTermChange?.(searchTerm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const { data, error, loading } = useQuery(graphqlQuery, {
    client:
      graphqlClient === ApiClients.users
        ? !!graphqlUsersWithoutOkta
          ? usersClient
          : usersClientWithOkta
        : propertiesClient,
    variables: isFunction(graphqlVariables)
      ? graphqlVariables(searchTerm)
      : graphqlVariables,
    fetchPolicy: 'cache-and-network',
    skip:
      graphqlSkip ??
      (inputValue.trim().length < minCharsForSearch || !graphqlQuery),
  });

  if (error) {
    ErrorLogger.log(error.message);
  }

  const items = !!data ? mapper(data) : [];

  const clientSideFilteredItems = clientSideFilter
    ? clientSideFilter(inputValue, items)
    : [];

  const clearButtonVisible = clearable;

  if (error) {
    console.error(error);
    return <p>Unable to load items</p>;
  }

  const clear = () => {
    setActiveSuggestionIndex(null);
    setInputValue('');
    onClear?.();
  };

  const select = (item: any) => {
    setIsInputFocused(false);
    setIsSuggestionListOpen(false);
    setInputValue(valueMapper?.(item) || '');
    onItemSelect(item);

    if (clearAfterItemSelect) {
      clear();
    }
  };

  const handleFocus = () => {
    setIsInputFocused(true);
    setIsSuggestionListOpen(inputValue.trim().length >= minCharsForSearch);
    onFocus?.();
  };

  const onInputChange = (value: string) => {
    setInputValue(value);
    setIsSuggestionListOpen(value.trim().length >= minCharsForSearch);
    setActiveSuggestionIndex(null);
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const allKeysHandled = [
      Keyboard.Enter,
      Keyboard.UpArrow,
      Keyboard.DownArrow,
    ];

    if (allKeysHandled.includes(e.keyCode)) {
      e.preventDefault();
    }

    const suggestions = clientSideFilter ? clientSideFilteredItems : items;

    switch (e.keyCode) {
      case Keyboard.Enter: {
        if (!isSuggestionListOpen || !suggestions.length) return;
        if (activeSuggestionIndex === null) {
          setActiveSuggestionIndex(0);
          return;
        }

        select(suggestions[activeSuggestionIndex || 0]);
        return;
      }

      case Keyboard.UpArrow: {
        if (!activeSuggestionIndex) {
          setActiveSuggestionIndex(suggestions.length - 1);
          return;
        }

        setActiveSuggestionIndex(activeSuggestionIndex - 1);
        return;
      }

      case Keyboard.DownArrow: {
        if (
          activeSuggestionIndex === null ||
          activeSuggestionIndex === suggestions.length - 1
        ) {
          setActiveSuggestionIndex(0);
          return;
        }

        setActiveSuggestionIndex(activeSuggestionIndex + 1);
        return;
      }
    }
  };

  const renderCreateSearchedItem = () => {
    return (
      <div className={styles['create-item-box']}>
        <p className={styles['searched-input']}>{inputValue}</p>
        <Button
          label="Create Business Park"
          type="ghost"
          onClick={() => {
            setTimeout(() => {
              setIsSuggestionListOpen(true);
              createSearchedItem?.();
              inputRef?.current?.focus();
            }, 0);
          }}
        />
      </div>
    );
  };

  const renderSuggestions = () => {
    const suggestions = clientSideFilter ? clientSideFilteredItems : items;

    let content = null;

    if (loading) {
      content = (
        <div className={styles['item-feedback']}>
          <LoadingMessage />
        </div>
      );
    } else if (!suggestions.length) {
      content = !createSearchedItem ? (
        <span className={styles['item-feedback']}>
          {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.notFound`)}
        </span>
      ) : (
        renderCreateSearchedItem()
      );
    } else {
      content = suggestions.map((item, index) => (
        <div
          key={index}
          onMouseDown={() => select(item)}
          className={classnames(styles.item, {
            [styles['item-active']]: activeSuggestionIndex === index,
          })}
        >
          {itemRenderer
            ? itemRenderer(item, searchTerm)
            : renderDefaultSuggestionOption(searchTerm, valueMapper?.(item))}
        </div>
      ));
    }

    return (
      <>
        <div className={styles.separator} />
        <div
          className={classnames(
            styles.suggestions,
            suggestionContainerClassName,
          )}
        >
          {content}
        </div>
      </>
    );
  };

  const renderClearButton = () => (
    <div className={styles['icon-wrapper']} onClick={clear}>
      <Icon name="clear" color={colors.ayGrey40Color} />
    </div>
  );

  return (
    <div
      className={classnames(styles['outer-container'], className)}
      ref={outerRef}
      style={style}
    >
      <div
        className={classnames(styles['inner-container'], {
          [styles['inner-container-focused']]: isInputFocused,
        })}
      >
        <div className={styles['input-container']}>
          <div
            className={classnames(styles['input-inner-wrapper'], {
              [styles['with-right-icon']]: iconPosition === IconPosition.Right,
            })}
          >
            {icon && (
              <Icon
                className={classnames({
                  [styles.icon]:
                    iconPosition === IconPosition.Left ||
                    (iconPosition === IconPosition.Right && clearable),
                })}
                name={icon}
              />
            )}
            <input
              id={id}
              className={styles.input}
              ref={inputRef}
              value={inputValue}
              onChange={e => onInputChange(e.currentTarget.value)}
              disabled={disabled}
              placeholder={placeholder}
              autoComplete="off"
              autoCorrect="off"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={onInputKeyDown}
            />
          </div>
          {clearButtonVisible && renderClearButton()}
        </div>
        {isSuggestionListOpen && renderSuggestions()}
      </div>
    </div>
  );
};

export default GenericSearchInput;
