import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { last, noop } from 'lodash';
import PropertyCardList from 'components/PropertyCardList';
import PropertiesSearchInput from 'components/PropertiesSearchInput';
import RecommendedProperties from 'components/FindComps/common/BuildingSetTab/PropertyListChooser/RecommendedProperties';
import VerticalFormControl from 'components/VerticalFormControl';
import { SearchInputSize } from 'components/SearchInput/enums';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { ColorNames } from 'constants/colorNames';
import { I18N_AVANT_PROPERTY_ATTR_PATH } from 'constants/i18n';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import { GET_PROPERTY_QUERY } from 'graphql/property';
import { translateText } from 'utils/i18n';

import styles from './CompetitivePropertySelector.module.scss';
import { CompetitivePropertySelectorProps } from './types';

const defaultParser = (data: IPropertyCompany[]) =>
  data.map(propertyCompany => ({
    ...propertyCompany,
    name: propertyCompany.displayAddress || propertyCompany.name,
  }));

const CompetitivePropertySelector = ({
  label,
  className,
  properties,
  propertiesToOmit,
  propertyIdForRecommendations,
  shouldShowRecommendations,
  onChange = noop,
  mapper,
  graphqlParams = {
    query: SEARCH_COMPANIES_PROPERTIES_QUERY,
    field: 'name',
    resultDataName: 'propertiesCompanies',
    onlyProperties: true,
  },
  parseResult = defaultParser,
  hasFeature,
  onClickFeatured,
}: CompetitivePropertySelectorProps) => {
  const [removedProperty, setRemovedProperty] = useState<IProperty>();

  const propertiesData = mapper ? mapper(properties ?? []) : properties;

  const [queryProperty, { data: selectedPropertyData }] = useLazyQuery<{
    property: IProperty;
  }>(GET_PROPERTY_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const addSuggestedProperty = (property: IPropertyCompany) => {
    queryProperty({
      variables: {
        id: property.propertyId,
      },
    });
  };

  const addProperty = (property: IProperty) => {
    if (!properties?.find(p => p.id === property.id)) {
      onChange([...(properties || []), property]);
    }
  };

  const removeProperty = (property: IProperty) => {
    const updatedProperties =
      properties?.filter(p => p.id !== property.id) || [];

    onChange(updatedProperties);
    setRemovedProperty(property);
  };

  useEffect(() => {
    if (!selectedPropertyData) return;

    addProperty(selectedPropertyData.property);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyData]);

  return (
    <>
      <VerticalFormControl label={label} wrapperClassName={className}>
        <PropertiesSearchInput
          onClickSuggestion={addSuggestedProperty}
          inputId="competitive-properties"
          placeholder={translateText(
            `${I18N_AVANT_PROPERTY_ATTR_PATH}.compSet.label.enterCompetitiveProperties`,
          )}
          filterSuggestions={suggestions =>
            propertiesToOmit?.length
              ? suggestions?.filter(
                  ({ propertyId }) =>
                    propertyId && propertiesToOmit.indexOf(propertyId) === -1,
                )
              : suggestions
          }
          searchInputProps={{
            size: SearchInputSize.Medium,
            clearAfterSuggestionClick: true,
          }}
          graphqlParams={graphqlParams}
          withMarketSwitch
          parseResults={parseResult}
        />
      </VerticalFormControl>
      <PropertyCardList
        isDraggable={true}
        hasFeature={hasFeature}
        onClickFeatured={onClickFeatured}
        noBorder
        properties={propertiesData || []}
        onItemTopRightButtonClick={removeProperty}
        cardBackgroundColor={ColorNames.ayGrey8Color}
        itemsTopRightButtonClassName={styles['button-remove-property']}
      />
      {shouldShowRecommendations && propertyIdForRecommendations && (
        <div>
          <RecommendedProperties
            removedProperty={
              removedProperty &&
              last(propertiesData)?.market === removedProperty?.market
                ? removedProperty
                : null
            }
            onAddRecommendedProperty={addProperty}
            propertyIdForRecommendations={propertyIdForRecommendations}
            alreadyExistingPropertyIds={[
              ...(propertiesData?.map(p => p.id) || []),
              ...(propertiesToOmit || []),
            ]}
          />
        </div>
      )}
    </>
  );
};

export { CompetitivePropertySelector };
