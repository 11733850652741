const MARGINS = {
  right: 24,
  left: 24,
};
const HEIGHT = 152;
const TRIANGLE_WIDTH = 64;

export const graphDimensions = {
  MARGINS,
  HEIGHT,
  TRIANGLE_WIDTH,
};
