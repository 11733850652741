import { gql } from '@apollo/client';

export const MARKET_QUERY = gql`
  query market($id: ID!) {
    market(id: $id) {
      id
      name
      latitude
      longitude
      country {
        id
        name
        code
        flagURL
        distanceMeasurement
        areaMeasurement
        currencyCode
      }
      propertiesCount
    }
  }
`;

export const SUB_MARKET_QUERY = gql`
  query submarket($id: ID!) {
    submarket(id: $id) {
      id
      name
      market {
        id
        name
        latitude
        longitude
        country {
          id
          name
          code
          flagURL
          distanceMeasurement
          areaMeasurement
          currencyCode
        }
      }
    }
  }
`;

export const SUB_MARKET_METADATA_QUERY = gql`
  query submarketMetadata(
    $id: ID!
    $propertyTypeId: Int!
    $marketOptions: MarketAnalyticsOptionsInput!
  ) {
    submarketMetadata(
      id: $id
      propertyTypeId: $propertyTypeId
      marketOptions: $marketOptions
    ) {
      overview {
        country
        state
        marketName
        subMarketName
      }
      existingPropertiesData {
        count
        sumSize
        sumSizeMt
      }
      propertiesUnderDevelopmentData {
        count
        sumSize
        sumSizeMt
      }
      averageAge
      averageRentFS
      averageRentFSMonthly
      averageRentNNN
      averageRentNNNMonthly
      developmentInventoryPercentage
      totalBuildingSizes
      availableSpacePercentage
      totalVacancyPercentage
      netAbsorptionLTM
      totalSalesPricing
      averageSalesPricing
      mostRecentLeaseRentType
    }
  }
`;

export const MARKET_ANALYTICS_METADATA_QUERY = gql`
  query marketAnalyticsMetadata($params: MarketAnalyticsMetadataInput) {
    marketAnalyticsMetadata(params: $params) {
      overview {
        country
        state
        marketName
      }
      existingPropertiesData {
        count
        sumSize
        sumSizeMt
      }
      propertiesUnderDevelopmentData {
        count
        sumSize
        sumSizeMt
      }
      averageAge
      averageRentFS
      averageRentFSMonthly
      averageRentNNN
      averageRentNNNMonthly
      developmentInventoryPercentage
      totalBuildingSizes
      availableSpacePercentage
      totalVacancyPercentage
      netAbsorptionLTM
      totalSalesPricing
      averageSalesPricing
      mostRecentLeaseRentType
    }
  }
`;

export const MARKETS_QUERY = gql`
  query markets($search: MarketSearchInput, $includePropertiesCount: Boolean) {
    markets(search: $search, includePropertiesCount: $includePropertiesCount) {
      id
      name
      country {
        id
        name
        code
        flagURL
        distanceMeasurement
        areaMeasurement
        currencyCode
      }
      propertiesCount
    }
  }
`;

export const SUB_MARKETS_QUERY = gql`
  query submarkets($search: SubmarketSearchInput) {
    submarkets(search: $search) {
      id
      name
      shapeGroup
      market {
        id
        name
        country {
          id
          name
          code
          flagURL
          distanceMeasurement
          areaMeasurement
          currencyCode
        }
      }
    }
  }
`;

export const MICRO_MARKETS_QUERY = gql`
  query micromarkets($search: MicromarketSearchInput) {
    micromarkets(search: $search) {
      id
      name
      submarket {
        id
        name
      }
    }
  }
`;

export const MARKETS_BOUNDARIES_QUERY = gql`
  query submarketsBoundaries($search: SubmarketBoundariesInput) {
    submarketsBoundaries(search: $search) {
      id
      name
      geometry {
        type
        coordinates
      }
    }
  }
`;

export const MARKETS_ANALYTICS_PROPERTIES_CAROUSEL = gql`
  query marketAnalyticsPropertiesCarousel(
    $params: MarketAnalyticsPropertiesCarouselInput
  ) {
    marketAnalyticsPropertiesCarousel(params: $params) {
      id
      name
      primaryAddress
      displayAddress
      coverMediaUrl
      coverMediaType
    }
  }
`;

export const PROPERTY_TYPES_BY_MARKET = gql`
  query propertyTypesOfMarket($search: PropertyTypesOfMarketInput!) {
    propertyTypesOfMarket(search: $search) {
      items {
        propertyTypeId
        propertyTypeName
      }
    }
  }
`;

export const MARKET_ANALYTICS_HISTORICAL_LEASING = gql`
  query marketAnalyticsHistoricalLeasing(
    $params: MarketAnalyticsHistoricalLeasingInput
  ) {
    marketAnalyticsHistoricalLeasing(params: $params) {
      historicalAverage {
        averageType
        totalAverage
        averages {
          date
          value
          valueMonthly
          NNNEquivValue
          NNNEquivValueMontly
          propertyValue
          totalSizeSF
          totalSizeSquareMeters
          leasesCount
          measurementSystem
          currencyCode
          topItems
        }
        leases {
          id
          date
          size
          sizeMt
          rent
          currencyCode
          measurementSystem
        }
      }
      historicalLeases {
        rentType
        items {
          id
          value
          size
          sizeMt
          measurementSystem
          date
          year
          quarter
          tenantCompany {
            id
            name
          }
          currencyCode
          timeMeasurement
          baseRentFullServiceEquiv
          baseRentFullServiceEquivMonthly
          baseRentFullServiceEquivAnnual
          baseRentNNNEquiv
          baseRentNNNEquivMonthly
          baseRentNNNEquivAnnual
          rentType
          baseRent
        }
      }
    }
  }
`;

export const MARKET_SALES_PRICING_QUERY = gql`
  query marketAnalyticsSalesPricing(
    $params: MarketAnalyticsSalesPricingInput!
  ) {
    marketAnalyticsSalesPricing(params: $params) {
      minSalesPricing
      minCapRate
      maxSalesPricing
      maxCapRate
      items {
        year
        quarter
        avgCapRate
        avgSalesPricing
        topItems
      }
    }
  }
`;

export const MARKET_DEVELOPMENT_GRAPH_QUERY = gql`
  query marketAnalyticsDevelopmentTimeline(
    $params: MarketAnalyticsDevelopmentTimelineInput
  ) {
    marketAnalyticsDevelopmentTimeline(params: $params) {
      years {
        builtYear
        totalBuildingSize
        totalBuildingSizeMt
        topItems
      }
    }
  }
`;
