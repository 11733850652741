import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import { REGEX_DECIMALS, REGEX_INTEGERS } from 'constants/regex';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
} from 'constants/unitOfMeasurement';
import { ILifeScience } from 'interfaces/inputs/ILifeScience';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import IOffice from 'interfaces/IOffice';
import React from 'react';
import { translateText } from 'utils/i18n';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { FieldType } from '../types';

interface ICeilingHeightField {
  property: IPropertyInput;
  classNames: {
    formControlClassName: string;
    inputHelperClassName: string;
  };
  valueField?: IOffice | ILifeScience | null;
  onChange: (fieldName: string, fieldValue: any) => void;
  fieldType: string;
}

const CeilingHeightField = ({
  property,
  classNames: { formControlClassName, inputHelperClassName },
  valueField,
  onChange,
  fieldType,
}: ICeilingHeightField) => {
  const typicalCeilingHeightUnitOfMeasurement = getUnitOfMeasurementForProperty(
    'typicalCeilingHeightFeet',
    ModelsWithUnitsOfMeasurement.Office,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  const updateCeilingHeightFields = (field: string, value: unknown) => {
    fieldType !== FieldType.lifeScience
      ? onChange(field, value)
      : onChange(fieldType, { ...(valueField ?? {}), [field]: value || null });
  };

  return (
    <div>
      {typicalCeilingHeightUnitOfMeasurement === UnitOfMeasurement.meter ? (
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.ceilingHeight',
          )}
          wrapperClassName={formControlClassName}
        >
          <TextInput
            id="typicalCeilingHeightMtInput"
            isNarrow
            textCentered
            placeholder={'00'}
            pattern={REGEX_DECIMALS}
            value={valueField?.typicalCeilingHeightMt}
            onChange={value =>
              updateCeilingHeightFields('typicalCeilingHeightMt', value)
            }
          />
          <span className={inputHelperClassName}>
            {UnitOfMeasurement.meter}
          </span>
        </FormControl>
      ) : (
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.ceilingHeight',
          )}
          wrapperClassName={formControlClassName}
        >
          <TextInput
            id="typicalCeilingHeightFeetInput"
            isNarrow
            textCentered
            placeholder={'00'}
            pattern={REGEX_INTEGERS}
            value={valueField?.typicalCeilingHeightFeet}
            onChange={value =>
              updateCeilingHeightFields('typicalCeilingHeightFeet', value)
            }
          />
          <span className={inputHelperClassName}>{UnitOfMeasurement.feet}</span>
          <TextInput
            id="typicalCeilingHeightInchesInput"
            isNarrow
            textCentered
            placeholder={'00'}
            pattern={REGEX_INTEGERS}
            value={valueField?.typicalCeilingHeightInches}
            onChange={value =>
              updateCeilingHeightFields('typicalCeilingHeightInches', value)
            }
          />
          <span className={inputHelperClassName}>
            {UnitOfMeasurement.inches}
          </span>
        </FormControl>
      )}
    </div>
  );
};

export default CeilingHeightField;
