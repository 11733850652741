import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { kebabCase } from 'lodash';
import styles from './ChoiceInput.module.scss';
import ChoiceInputItem from './ChoiceInputItem';
import { IdName } from 'interfaces/IdName';
import { getGroupedDataByPathName } from './utils';

export type Size = 'regular' | 'big';

export type ChoiceInputType = {
  id?: string;
  data: IdName[];
  isDisabled?: boolean;
  isPadded?: boolean;
  isThreeLineLabel?: boolean;
  isVertical?: boolean;
  itemWrapperClassName?: string;
  selectedItemWrapperClassName?: string;
  labelFieldName?: string;
  containerWrapperClassName?: string;
  onChange?: (item?: IdName) => void;
  onChangeFullOptionObject?: (item?: any) => void;
  selectedItem?: IdName;
  size?: Size;
  gridColumnsCount?: number;
  getSuggestedOptions?: (items: any) => any;
  optionsLabels?: {
    all: string;
    suggested: string;
  };
  groupItemsPathParams?: {
    sortByField: string;
    displaySortedField: string;
  };
  disableUnset?: boolean;
};

const ChoiceInput: React.FC<ChoiceInputType> = ({
  id,
  data,
  size,
  isDisabled,
  isVertical,
  selectedItem,
  onChange,
  onChangeFullOptionObject,
  itemWrapperClassName,
  selectedItemWrapperClassName,
  labelFieldName,
  containerWrapperClassName,
  gridColumnsCount,
  isPadded,
  isThreeLineLabel,
  getSuggestedOptions,
  optionsLabels,
  groupItemsPathParams,
  disableUnset,
}) => {
  const [activeItem, setActiveItem] = useState(selectedItem);

  useEffect(() => {
    setActiveItem(selectedItem);
  }, [selectedItem]);

  const selectItem = (item: IdName) => {
    if (disableUnset && activeItem?.id === item.id) return;

    let newActiveItem: IdName | undefined = item;
    if (activeItem?.id === item.id || activeItem?.name === item.name) {
      newActiveItem = undefined;
    }
    setActiveItem(newActiveItem);
    onChange && onChange(newActiveItem);
  };

  const getLabel = (item: any) => {
    if (labelFieldName) {
      return item[labelFieldName];
    }
    return item.name;
  };

  const renderChoiceInput = (item: IdName) => (
    <ChoiceInputItem
      id={id ? `${id}-${kebabCase(item.name)}` : undefined}
      key={item.id}
      isDisabled={isDisabled}
      isVertical={isVertical}
      isSelected={activeItem?.id === item.id || activeItem?.name === item.name}
      label={getLabel(item)}
      selectedItemWrapperClassName={selectedItemWrapperClassName}
      onSelect={() => {
        onChangeFullOptionObject?.(item);
        selectItem({ id: item.id, name: item.name });
      }}
      size={size}
      itemWrapperClassName={itemWrapperClassName}
    />
  );

  const renderSuggestedOptions = () => {
    const hasSuggestedOptions = !!getSuggestedOptions?.(data)?.length;
    if (!hasSuggestedOptions) return null;
    const renderSuggestedItems = getSuggestedOptions?.(
      data,
    )?.map((item: IdName) => renderChoiceInput(item));

    return (
      <>
        <div className={styles['suggested-title']}>
          {optionsLabels?.suggested}
        </div>
        {renderSuggestedItems}
        <div className={styles['suggested-title']}>{optionsLabels?.all}</div>
      </>
    );
  };

  return (
    <div
      style={
        gridColumnsCount
          ? {
              display: 'grid',
              gridAutoFlow: 'column',
              gridTemplateRows: `repeat(${Math.ceil(
                (data?.length || gridColumnsCount) / gridColumnsCount,
              )}, 1fr)`,
            }
          : {}
      }
      className={classnames(
        containerWrapperClassName,
        styles['radio-group-container'],
        {
          [styles.vertical]: isVertical,
          [styles.padded]: isPadded,
          [styles['three-line-padded']]: isThreeLineLabel,
        },
      )}
    >
      {renderSuggestedOptions()}
      {groupItemsPathParams
        ? getGroupedDataByPathName(data, groupItemsPathParams)?.map(
            (group: IdName) => {
              return (
                <React.Fragment key={group.id}>
                  {group.name}
                  {group.items.map((item: IdName) => renderChoiceInput(item))}
                </React.Fragment>
              );
            },
          )
        : data?.map((item: IdName) => renderChoiceInput(item))}
    </div>
  );
};

export default ChoiceInput;
