import React from 'react';
import { getSourceTypeOptions } from './utils';
import FormControl from 'components/FormControl';
import styles from '../../FormSection.module.scss';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { translateText } from 'utils/i18n';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string;
  required?: boolean;
  onChange: (field: string, value: any) => void;
}

const SourceType: React.FC<Props> = ({ value, onChange, required = false }) => {
  const { t } = useTranslation();
  const options = getSourceTypeOptions(t);

  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText('avantPlatform.attributes.property.label.source')}
    >
      <ChoiceInput
        data={options}
        onChange={item => onChange('source', item?.id)}
        selectedItem={{ id: value, name: value }}
        containerWrapperClassName={styles['radio-input-container']}
      />
    </FormControl>
  );
};

export default SourceType;
