import { gql } from '@apollo/client';

import {
  COMPANY_PAGINATION_DATA_FRAGMENT,
  COMPANY_BASIC_RESULTS_FRAGMENT,
} from 'graphql/company/fragments';

export const COMPANY_DETAILS = gql`
  query companyDetails($id: ID!) {
    companyDetails(id: $id) {
      leases
      properties
      sales
      tims
    }
  }
`;

export const COMPANY_TRANSACTION_VOLUME_QUERY = gql`
  query companyTransactionVolume($search: CompanyTransactionVolumeInput) {
    companyTransactionVolume(search: $search) {
      year
      valuePurchased
      countPropertiesPurchased
      totalSFPurchased
      totalSquareMetersPurchased
      valueSold
      countPropertiesSold
      totalSFSold
      totalSquareMetersSold
      currencyCode
      measurementSystem
    }
  }
`;

export const GET_COMPANY_QUERY = gql`
  query getCompany($id: ID!) {
    company(id: $id) {
      id
      name
      comments
      logoId
      logoUrl
      headquarters
      headquarterProperty {
        id
        name
        postalCode
        primaryAddress
        displayAddress
        state
        city
        country
      }
      mergedCompanyId
      investorType {
        id
        name
      }
      industry {
        id
        name
      }
      createdUser {
        id
        firstName
        lastName
      }
      updatedUser {
        id
        firstName
        lastName
      }
      legalName
      createdAt
      updatedAt
      operatingName
      website
      domain
      stockCode
      linkedIn
    }
  }
`;

export const SEARCH_COMPANIES_QUERY = gql`
  query companies($search: CompanySearchInput!) {
    companies(search: $search) {
      ...companyPaginationData
      results {
        ...companyBasicResults
      }
    }
  }
  ${COMPANY_PAGINATION_DATA_FRAGMENT}
  ${COMPANY_BASIC_RESULTS_FRAGMENT}
`;

export const COMPANIES_MERGED_QUERY = gql`
  query companiesMerged($companyId: Int!) {
    companiesMerged(companyId: $companyId) {
      ...companyBasicResults
    }
  }
  ${COMPANY_BASIC_RESULTS_FRAGMENT}
`;

export const COMPANY_INVESTOR_TYPES = gql`
  query investorTypes {
    investorTypes {
      id
      name
    }
  }
`;
