const UPLOAD_URL_SEGMENT = '/image/upload/';

export const VIDEO_GIF_TRANSFORMATIONS = 'c_fit,so_0,eo_30,h_180,w_180/e_loop';

const getTransformedUrl = (url: string, trans: string) => {
  const parts = url.split(UPLOAD_URL_SEGMENT);
  return [parts[0], UPLOAD_URL_SEGMENT, `${trans}/`, parts[1]].join('');
};

const roundMeasures = (number: number) => Math.round(number);

export const transformImageUrl = (
  url: string,
  width: number,
  height?: number,
) => {
  let trans = `f_auto,w_${roundMeasures(width)}`;

  if (height) {
    trans = `${trans},h_${roundMeasures(height)},c_fill`;
  }

  return getTransformedUrl(url, trans).replace(/^http:\/\//i, 'https://');
};

export const getFileExtensionFromUrl = (
  url: string,
  isUpperCase?: boolean,
): string => {
  const lastIndexOfDot = url.lastIndexOf('.');
  const extension = url.substr(lastIndexOfDot + 1, url.length + 1);
  return isUpperCase ? extension.toLocaleUpperCase() : extension;
};

const replaceUrlExtension = (url: string, replacement: string): string => {
  if (!replacement) return url;
  const extension = getFileExtensionFromUrl(url);
  return url.replace(`.${extension}`, `.${replacement}`);
};

const addTransformOptionsToUrl = (
  url: string,
  options?: string,
  separatorPath?: string,
): string => {
  if (!options) return url;
  const separator = separatorPath || 'upload';
  const [beforeInsertPath, afterInsertPath] = url.split(separator);
  return `${beforeInsertPath}${separator}/${options}${afterInsertPath}`;
};

export const getVideoThumbnailUrl = (
  url: string,
  options: string,
  thumbnailExtension: string,
): string => {
  const urlWithTransformations = addTransformOptionsToUrl(url, options);
  return replaceUrlExtension(urlWithTransformations, thumbnailExtension);
};

export const getCoverMediaGIFUrl = (url: string, options: string): string => {
  const urlWithTransformations = addTransformOptionsToUrl(url, options);
  return replaceUrlExtension(urlWithTransformations, 'gif');
};
