import React, { useState } from 'react';
import LoadingMessage from 'components/LoadingMessage';
import { usePropertiesLeasesAnalytics } from 'hooks/usePropertiesLeasesAnalytics';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { IProperty } from 'interfaces/IProperty';
import styles from './HistoricalPerformance.module.scss';
import Concessions from './Concessions';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import LeaseExpiration from './LeaseExpiration';
import MostRecentLeases from 'components/MostRecentLeases';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { LeasePeriodType } from 'constants/leases';
import ProfileSubSection from 'components/ProfileSubSection';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import PeriodToggles from 'components/Leases/PeriodToggles';
import Absorption from './Absorption';

interface Props {
  properties?: IProperty[];
  setPropertiesPopup?: (popup: ILargestPropertiesPopup) => void;
  unitOfMeasurement: UnitOfMeasurement;
  leaseToggleOptions: LeaseToggleOptions;
}

const HistoricalPerformance = ({
  properties,
  setPropertiesPopup,
  unitOfMeasurement,
  leaseToggleOptions,
}: Props) => {
  const { t } = useTranslation();
  const [periodType, setPeriodType] = useState<LeasePeriodType>(
    LeasePeriodType.ANNUAL,
  );
  const {
    hasConcessionData,
    isLoadingLeasingConcession,
    leasingConcessionData,
    hasLeasesExpirationData,
    isLoadingLeasesExpirationData,
    leasesExpirationData,
    mostRecentLeasesData,
    isLoadingMostRecentLeases,
    hasMostRecentLeasesData,
    absorptionData,
    isLoadingAbsorptionData,
  } = usePropertiesLeasesAnalytics(properties, periodType);

  const renderAbsortionChart = () => {
    if (isLoadingAbsorptionData) {
      return <LoadingMessage />;
    }

    return (
      <Absorption
        data={absorptionData?.propertiesAnalyticsAbsorption!}
        unitOfMeasurement={unitOfMeasurement}
        periodType={periodType}
      />
    );
  };

  const renderHeaderContent = () => (
    <PeriodToggles
      periodType={periodType}
      handleChange={(value: LeasePeriodType) => setPeriodType(value)}
    />
  );

  const isQuarterly = periodType === LeasePeriodType.QUARTER;

  return (
    <ProfileSubSection
      title={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.historicalPerformance`)}
      headerContent={renderHeaderContent()}
    >
      <div className={styles['chart-content']}>{renderAbsortionChart()}</div>
      <div className={styles['chart-content']}>
        {isLoadingMostRecentLeases && <LoadingMessage />}
        {mostRecentLeasesData?.leasesAnalyticsMostRecentLeases &&
          hasMostRecentLeasesData && (
            <MostRecentLeases
              data={mostRecentLeasesData!.leasesAnalyticsMostRecentLeases}
              toggleOptions={leaseToggleOptions}
              isQuarterly={isQuarterly}
              unitOfMeasurement={unitOfMeasurement}
            />
          )}
      </div>
      <div className={styles['chart-content']}>
        {isLoadingLeasingConcession && <LoadingMessage />}
        {leasingConcessionData?.leasesAnalyticsConcession &&
          hasConcessionData && (
            <Concessions
              data={leasingConcessionData.leasesAnalyticsConcession}
              setPropertiesPopup={setPropertiesPopup}
              unitOfMeasurement={unitOfMeasurement}
              periodType={periodType}
            />
          )}
      </div>
      <div className={styles['chart-content']}>
        {isLoadingLeasesExpirationData && <LoadingMessage />}
        {leasesExpirationData?.leasesAnalyticsExpirations &&
          hasLeasesExpirationData && (
            <LeaseExpiration
              periodType={periodType}
              data={leasesExpirationData.leasesAnalyticsExpirations}
              setPropertiesPopup={setPropertiesPopup}
              unitOfMeasurement={unitOfMeasurement}
            />
          )}
      </div>
    </ProfileSubSection>
  );
};

export default HistoricalPerformance;
