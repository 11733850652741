import AYLink from 'components/AYLink';
import ModalMarketsLinks from 'components/MultipleMarketsLink/ModalMarketsLinks';
import { ICountry, IMarket } from 'interfaces/IMarket';
import React, { useState } from 'react';
import locations from 'routes';
import { getMultipleMarketsNamesShort } from 'utils/formatters/property';
import styles from './MultipleMarketsLink.module.scss';

interface Props {
  markets: IMarket[];
  preselectedMarket?: IMarket;
}

interface Market {
  id: number;
  country: ICountry | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  mediaUrl: string | undefined;
  name: string | undefined;
}

const MultipleMarketsLink: React.FC<Props> = ({
  markets,
  preselectedMarket,
}) => {
  const [isModalLinkVisible, setIsModalLinkVisible] = useState(false);
  const formattedMarkets: Market[] = [];
  markets.forEach(market => {
    const formattedMarket: Market = {
      id: 0,
      country: undefined,
      latitude: undefined,
      longitude: undefined,
      mediaUrl: undefined,
      name: undefined,
    };
    formattedMarket['id'] = market.id;
    formattedMarket['country'] = market.country;
    formattedMarket['latitude'] = market.latitude;
    formattedMarket['longitude'] = market.longitude;
    formattedMarket['mediaUrl'] = market.mediaUrl;
    formattedMarket['name'] = market.name.includes('Z - ')
      ? market.name.replace('Z - ', '')
      : market.name.includes('Z-')
      ? market.name.replace('Z-', '')
      : market.name;
    formattedMarkets.push(formattedMarket);
  });

  const hasMultipleProperties = formattedMarkets.length > 1;

  return (
    <>
      {hasMultipleProperties ? (
        <>
          <div className={styles['address-container']}>
            <div
              className={styles['market-address']}
              onClick={() => setIsModalLinkVisible(true)}
            >
              {getMultipleMarketsNamesShort(
                formattedMarkets,
                preselectedMarket,
              )}
            </div>
          </div>
          <ModalMarketsLinks
            isVisible={isModalLinkVisible}
            markets={formattedMarkets}
            onClose={() => setIsModalLinkVisible(false)}
          />
        </>
      ) : (
        <AYLink
          to={locations.showMarket(formattedMarkets[0].id!)}
          wrapperClassName={styles['market-address']}
        >
          {formattedMarkets[0].name}
        </AYLink>
      )}
    </>
  );
};

export default MultipleMarketsLink;
