import { MediaTypes } from 'constants/media';
import { FileToUpload, KVFilesToUpload } from 'components/ModalMedia/types';
import { IScoopMedia } from 'interfaces/IScoopMedia';
import { IMedia } from 'interfaces/IMedia';

export const isVideo = (media: IMedia) => media.type === MediaTypes.video;

export const isVideoMediaType = (file: File) => file?.type.startsWith('video');

export const isDocumentFileType = (file: File): boolean => {
  const supportedExtensions = ['xlsx', 'xls', 'pdf', 'csv'];
  const fileExtension = file.name
    .split('.')
    .pop()
    ?.toLowerCase();

  return supportedExtensions.includes(fileExtension || '');
};

export const extractImageFiles = (files: KVFilesToUpload): FileToUpload[] => {
  return Object.values(files).filter(file => isImageFileType(file.file));
};

export const extractDocumentFiles = (
  files: KVFilesToUpload,
): FileToUpload[] => {
  return Object.values(files).filter(file => isDocumentFileType(file.file));
};

export const extractMediaImages = (mediaScoops: IScoopMedia[]) => {
  return mediaScoops.filter(m => m.type === MediaTypes.image);
};

export const extractMediaDocuments = (mediaScoops: IScoopMedia[]) => {
  return mediaScoops.filter(m => m.type !== MediaTypes.image);
};

export const isMediaFileImage = (mediaScoop: IScoopMedia) => {
  return mediaScoop.type === MediaTypes.image;
};

export const isImageFileType = (file: File): boolean => {
  return file.type.startsWith('image/');
};
