import React from 'react';
import styles from './PropertyMap.module.scss';
import { IProperty } from 'interfaces/IProperty';
import { PropertyMapSize, ZoomLevel } from './constants';
import classNames from 'classnames';
import { createStaticMapImageUrl } from 'utils/maps/staticMapboxImage';
import { staticMapImageSizesPx } from '.';

type PropTypes = {
  property: IProperty;
  size?: PropertyMapSize;
  height?: number;
  width?: number;
  wrapperClassName?: string;
  zoomLevel?: ZoomLevel;
};

const StaticMapImage: React.FC<PropTypes> = ({
  property,
  size,
  height,
  width,
  wrapperClassName,
  zoomLevel,
}) => {
  const sizeInPixels = staticMapImageSizesPx[size || PropertyMapSize.regular];
  const staticMapImageURL = createStaticMapImageUrl({
    height: height ? height : sizeInPixels,
    latitude: property.latitudeDisplay || property.latitude!,
    longitude: property.longitudeDisplay || property.longitude!,
    width: width
      ? width
      : size === PropertyMapSize.portrait ||
        size === PropertyMapSize.portraitLarge
      ? sizeInPixels * 1.5
      : sizeInPixels,
    zoom: zoomLevel || ZoomLevel.regular,
  });

  if (!staticMapImageURL) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.map,
        styles['static-map'],
        {
          [styles.regular]: !size || size === PropertyMapSize.regular,
          [styles['extra-small']]: size === PropertyMapSize.extraSmall,
          [styles.small]: size === PropertyMapSize.small,
          [styles.large]: size === PropertyMapSize.large,
          [styles.big]: size === PropertyMapSize.big,
          [styles.portrait]: size === PropertyMapSize.portrait,
          [styles['portrait-large']]: size === PropertyMapSize.portraitLarge,
          [styles['full-width']]: size === PropertyMapSize.fullWidth,
        },
        wrapperClassName,
      )}
    >
      <img src={staticMapImageURL} alt={property.name} />
      <div
        className={classNames(styles.marker, {
          [styles.portrait]: size === PropertyMapSize.portrait,
          [styles.small]: size === PropertyMapSize.small,
          [styles['extra-small']]: size === PropertyMapSize.extraSmall,
        })}
      />
    </div>
  );
};

export default StaticMapImage;
