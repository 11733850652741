import React from 'react';
import { ITableCell } from 'interfaces/ITableCell';
import styles from '../EditTable/EditTable.module.scss';
import {
  ILabelCell,
  ICellFormatter,
  ChangeFocusOrder,
} from '../EditTable/interfaces';
import {
  REGEX_THOUSAND_SEP_WITH_DECIMALS,
  REGEX_NEGATIVE_OR_POSITIVE_DECIMALS,
  REGEX_DECIMALS,
} from 'constants/regex';
import { roundNumberWithDecimals } from 'utils/formatters/number';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { I18N_HISTORICAL_PERFORMANCE_LABEL_PATH } from 'components/HistoricalPerformance/constants';

export enum SegmentPricingFields {
  directRent = 'directRent',
  darPercent = 'darPercent',
  tiLongTerm = 'tiLongTerm',
  tiPercent = 'tiPercent',
  freeRentLongTerm = 'freeRentLongTerm',
}

const getTableTranslations = (keyPath: string) => {
  return translateText(keyPath);
};

export const segmentPricingHeader = [
  {
    header: getTableTranslations(`${I18N_PLATFORM_COMMON_WORD_PATH}.year`),
    accessor: 'year',
    Cell: ({ cell: { value } }: ITableCell) => (
      <span className={styles['cell-bold']}>{value}</span>
    ),
    isAlignedOnLeft: true,
  },
  {
    header: getTableTranslations(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.directRent`,
    ),
    accessor: SegmentPricingFields.directRent,
    isAlignedOnRight: true,
  },
  {
    header: getTableTranslations(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.directRentChange`,
    ),
    accessor: SegmentPricingFields.darPercent,
    isAlignedOnRight: true,
    readOnly: true,
  },
  {
    header: getTableTranslations(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.ti10Year`,
    ),
    accessor: SegmentPricingFields.tiLongTerm,
    isAlignedOnRight: true,
  },
  {
    header: getTableTranslations(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.tiChange`,
    ),
    accessor: SegmentPricingFields.tiPercent,
    isAlignedOnRight: true,
    readOnly: true,
  },
  {
    header: getTableTranslations(
      `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.freeRentMonths`,
    ),
    accessor: SegmentPricingFields.freeRentLongTerm,
    isAlignedOnLeft: true,
  },
];

export const getSegmentPricingCellsLabels = (
  currencySymbol: string,
): ILabelCell[] => [
  {
    columnId: SegmentPricingFields.directRent,
    labelLeft: currencySymbol,
  },
  {
    columnId: SegmentPricingFields.darPercent,
    labelRight: '%',
  },
  {
    columnId: SegmentPricingFields.tiPercent,
    labelRight: '%',
  },
];

const segmentPricingFormatter = (number: string | number) =>
  roundNumberWithDecimals(+number);

export const segmentPricingCellsFormatters: ICellFormatter[] = [
  {
    columnId: SegmentPricingFields.directRent,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: segmentPricingFormatter,
    isAlignedOnRight: true,
  },
  {
    columnId: SegmentPricingFields.darPercent,
    pattern: REGEX_NEGATIVE_OR_POSITIVE_DECIMALS,
    formatter: segmentPricingFormatter,
    isAlignedOnRight: true,
  },
  {
    columnId: SegmentPricingFields.tiLongTerm,
    pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
    formatter: segmentPricingFormatter,
    isAlignedOnRight: true,
  },
  {
    columnId: SegmentPricingFields.tiPercent,
    pattern: REGEX_NEGATIVE_OR_POSITIVE_DECIMALS,
    formatter: segmentPricingFormatter,
    isAlignedOnRight: true,
  },
  {
    columnId: SegmentPricingFields.freeRentLongTerm,
    pattern: REGEX_DECIMALS,
    isAlignedOnRight: true,
  },
];

export const segmentPricingCellsFocusOrder: ChangeFocusOrder = {
  [SegmentPricingFields.directRent]: SegmentPricingFields.tiLongTerm,
  [SegmentPricingFields.tiLongTerm]: SegmentPricingFields.freeRentLongTerm,
  [SegmentPricingFields.freeRentLongTerm]: SegmentPricingFields.directRent,
};
