import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement/index';

export const getUnitsOfMeasurement = (
  measurementSystems: string[],
  field = 'buildingSize',
): string[] => {
  if (!measurementSystems.length) return [UnitOfMeasurement.sf];

  return Array.from(
    new Set(
      Array.from(new Set(measurementSystems)).map(measurementSystem =>
        getUnitOfMeasurementForProperty(
          field,
          ModelsWithUnitsOfMeasurement.Property,
          measurementSystem,
        ),
      ),
    ),
  );
};

export const getUnitsOfMeasurementText = (
  measurementSystems: string[],
  field = 'buildingSize',
): string => getUnitsOfMeasurement(measurementSystems, field).join(', ');

export const getPredominantMeasurementSystem = (
  measurementSystems: string[],
) => {
  if (!measurementSystems.length) return UnitOfMeasurementCode.US;

  return measurementSystems
    .sort(
      (a, b) =>
        measurementSystems.filter(v => v === a).length -
        measurementSystems.filter(v => v === b).length,
    )
    .pop();
};
