import React, { Fragment } from 'react';

interface ListWithSeparatorProps {
  items: React.ReactNode[];
  separator: React.ReactNode;
}

const ListWithSeparator = ({ items, separator }: ListWithSeparatorProps) => {
  return (
    <>
      {items.filter(Boolean).map((item, index) => {
        return (
          <Fragment key={index}>
            {!!index && separator}
            {item}
          </Fragment>
        );
      })}
    </>
  );
};

export default ListWithSeparator;
