import React from 'react';
import classnames from 'classnames';

import OptionsContainer, {
  IOption,
  OptionContainerStyle,
} from 'components/OptionsContainer';
import styles from './OptionsButton.module.scss';

type Props = {
  dropdownOptions: IOption[];
  label: string;
  icon?: string;
  optionsContainerStyle?: OptionContainerStyle;
  wrapperClassName?: string;
  optionsButtonClassName?: string;
  optionsIconClassName?: string;
  optionsIconColor?: string;
  optionClassName?: string;
};

const OptionsButton: React.FC<Props> = ({
  dropdownOptions,
  label,
  optionsContainerStyle,
  wrapperClassName,
  optionsButtonClassName,
  optionsIconClassName,
  optionsIconColor,
  optionClassName,
  icon = 'add',
}) => (
  <>
    <OptionsContainer
      options={dropdownOptions}
      wrapperClassName={classnames(
        styles['options-container'],
        wrapperClassName,
      )}
      optionButtonLabel={label}
      optionButtonIcon={icon}
      optionContainerStyle={optionsContainerStyle}
      customButtonStyle={optionsButtonClassName}
      optionsIconClassName={optionsIconClassName}
      optionButtonInitialColor={optionsIconColor}
      optionClassName={optionClassName}
    />
  </>
);

export default OptionsButton;
