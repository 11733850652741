import { IdName } from 'interfaces/IdName';
import { ILease } from 'interfaces/ILease';
import {
  getFormattedArrayField,
  getFormattedField,
  LeaseCardField,
} from './fields';

/**
 * This variable should take field names which are arrays to handle the tags for displaying individual tags
 * @param BUILD_ARRAY_TAGS
 */
const BUILD_ARRAY_TAGS = [LeaseCardField.LeaseOptions];

export const buildTagsListItems = (
  lease: ILease,
  displayFields: LeaseCardField[],
  leaseOptionTypes?: IdName[],
): IdName[] =>
  displayFields.reduce<IdName[]>((acc, field, i) => {
    const newTags = BUILD_ARRAY_TAGS.includes(field)
      ? (getFormattedArrayField(lease, field, leaseOptionTypes) || []).map(
          (tag, index) => ({
            id: `${i}-${index}`,
            name: tag,
          }),
        )
      : getFormattedField(lease, field)
      ? [{ id: i, name: getFormattedField(lease, field) }]
      : [];

    return [...acc, ...newTags];
  }, []);
