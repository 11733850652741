import { fieldHasValue } from 'utils/objects';

export const isValidNumber = (number?: string | number | null): boolean => {
  if (!fieldHasValue(number)) return false;

  try {
    return !isNaN(Number(number));
  } catch (__) {}

  return false;
};
