import { gql } from '@apollo/client';
import { MEDIA_FRAGMENT } from 'graphql/images/fragments';

export const GET_IMAGE_CATEGORIES_QUERY = gql`
  query getImageCategories {
    imageCategories {
      id
      name
    }
  }
`;

export const GET_VIDEO_CATEGORIES_QUERY = gql`
  query getVideoCategories {
    videoCategories {
      id
      name
    }
  }
`;

export const GET_PROPERTY_MEDIA_QUERY = gql`
  query media($propertyId: Int!) {
    media(propertyId: $propertyId) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const GET_MARKET_MEDIA_QUERY = gql`
  query media($marketId: Int!) {
    mediaMarkets(marketId: $marketId) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const GET_SUBMARKET_MEDIA_QUERY = gql`
  query media($submarketId: Int!, $propertyTypeId: Int!) {
    mediaSubmarkets(
      submarketId: $submarketId
      propertyTypeId: $propertyTypeId
    ) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const GET_NEIGHBORING_TENANTS_LOGOS_QUERY = gql`
  query marketAnalyticsNeighboringTenants(
    $params: MarketAnalyticsNeighboringTenantsInput!
  ) {
    marketAnalyticsNeighboringTenants(params: $params) {
      items {
        id
        logo
        name
        website
      }
    }
  }
`;

export const GET_NEIGHBORING_OWNERS_LOGOS_QUERY = gql`
  query marketAnalyticsNeighboringOwners(
    $params: MarketAnalyticsNeighboringOwnersInput!
  ) {
    marketAnalyticsNeighboringOwners(params: $params) {
      items {
        id
        name
        logo
        website
      }
    }
  }
`;

export const GET_MARKET_ANALYICS_MEDIA = gql`
  query marketAnalyticsMedia($params: MarketAnalyticsMediaInput) {
    marketAnalyticsMedia(params: $params) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;
