import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import { IPricingQuote } from 'interfaces/IPricingQuote';
import { formatDate } from 'utils/formatters/date';
import dateFormats from 'constants/dateFormats';
import { getTodayDateISOString } from 'utils/date';
import { convertDecimalToFloat } from 'utils/formatters/number';
import { isNil } from 'lodash';
import { EscalationType } from 'constants/leases';
import { isEscalationTypePercentage } from '../FormFields/EscalationFields/utils';

export const parsePropertyPriceQuote = (pricingQuote: IPricingQuote) => {
  if (!pricingQuote) return {};

  const inputs: IPricingQuoteInput = {
    id: pricingQuote?.id,
    property: pricingQuote?.property!,
    tenant: pricingQuote?.tenantCompany!,
    askingRent: pricingQuote?.askingRentPsf!,
    rentType: pricingQuote.rentType!,
    leaseType: pricingQuote.leaseType!,
    source: pricingQuote.source!,
    pricing: pricingQuote.pricing!,
    tenantImprovementAllowance: pricingQuote.tiAllowance,
    freeRentMonths: pricingQuote.freeRentMonths,
    termInMonths: pricingQuote.term,
    taxAndOpex: pricingQuote.opExAndTaxes?.toString(),
    tax: pricingQuote.taxes?.toString(),
    opex: pricingQuote.operatingExpenses?.toString(),
    comments: pricingQuote.comment,
    floor: pricingQuote.floors,
    escalation:
      getEscalationValueForInput(
        pricingQuote.escalation,
        pricingQuote.escalationType,
      ) ?? null,
    escalationIncrement: pricingQuote?.escalationIncrement ?? null,
    escalationType: isNil(pricingQuote?.escalationType)
      ? EscalationType.Percentage
      : pricingQuote.escalationType,
    size: pricingQuote.size?.toString(),
    sizeMt: pricingQuote.sizeMt?.toString(),
    date:
      formatDate(pricingQuote.date, dateFormats.ISO_DATE) ||
      getTodayDateISOString(),
  };

  return inputs;
};

export const getEscalationValueForInput = (
  escalation: string | null | undefined,
  escalationType: string | null | undefined,
): string | null | undefined => {
  if (escalation) {
    const escalationNumber = Number(escalation);
    return isEscalationTypePercentage(escalationType)
      ? String(convertDecimalToFloat(escalationNumber, 2))
      : escalation;
  }
  return escalation;
};
