import React from 'react';
import classnames from 'classnames';
import styles from './PaginationControl.module.scss';
import Button from 'components/Button/new';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

interface Props {
  onClickNext: () => void;
  onClickPrev: () => void;
  currentPage: number;
  totalPages: number;
  isLoading?: boolean;
  className?: string;
}

const PaginationControl: React.FC<Props> = ({
  currentPage,
  totalPages,
  onClickNext,
  onClickPrev,
  isLoading,
  className,
}) => {
  return (
    <div className={classnames(styles.container, className)}>
      <Button
        onClick={onClickPrev}
        type={'ghost'}
        icon={'arrowLeft'}
        size={'s'}
        label={translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.prev`)}
        iconPosition={'left'}
        disabled={currentPage === 1}
      />
      <span className={styles.pages}>
        {currentPage}/{totalPages}
      </span>
      <Button
        onClick={onClickNext}
        type={'ghost'}
        icon={'arrowRight'}
        size={'s'}
        label={
          isLoading
            ? `${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.loading`)}...`
            : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.next`)
        }
        iconPosition={'right'}
        disabled={currentPage >= totalPages || isLoading}
      />
    </div>
  );
};

export default PaginationControl;
