import React, { useMemo } from 'react';
import classNames from 'classnames';
import { getShortDate } from 'utils/date';
import { ITIM } from 'interfaces/ITIM';
import { translateText } from 'utils/i18n';
import { formatNumberWithCommas } from 'utils/formatters/number';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import locations from 'routes';
import AYLink from 'components/AYLink';
import CompsComments from 'components/CompsComments';
import { ExpandingContracting } from 'constants/expandingContracting';
import styles from '../TenantCard.module.scss';
import { renderSubtitles } from '../utils';

interface Props {
  tim?: ITIM;
  isCarouselView?: boolean;
}

const TenantBody = ({ tim, isCarouselView }: Props) => {
  const isExpanding =
    tim?.expandingContracting?.name === ExpandingContracting.EXPANDING;
  const isContracting =
    tim?.expandingContracting?.name === ExpandingContracting.CONTRACTING;

  const bodySubtitles = useMemo(() => {
    const tenantRepBrokerCompany = tim?.tenantRepBrokerCompany?.name
      ? `${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.with`)} ${
          tim?.tenantRepBrokerCompany.name
        }`
      : '';
    const activeSinceDate = tim?.activeSinceDate
      ? `${translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.active`,
        )} ${getShortDate(tim?.activeSinceDate)}`
      : '';
    const lastDiscussedDate = tim?.lastDiscussedDate
      ? `${translateText(
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.lastDiscussed`,
        )} ${getShortDate(tim?.lastDiscussedDate)}`
      : '';

    return [tenantRepBrokerCompany, activeSinceDate, lastDiscussedDate];
  }, [tim]);

  const bodyFieldChips = useMemo(() => {
    const measurement = tim?.targetMarket?.country?.areaMeasurement;
    const isSfMeasurement = measurement === UnitOfMeasurement.sf;
    const hasSize = tim?.targetSizeMin && tim?.targetSizeMax;
    const measurementChip = isSfMeasurement
      ? `${formatNumberWithCommas(
          tim?.targetSizeMin,
        )} - ${formatNumberWithCommas(tim?.targetSizeMax)} ${measurement}`
      : `${formatNumberWithCommas(
          tim?.targetSizeMinMt,
        )} - ${formatNumberWithCommas(tim?.targetSizeMaxMt)} ${measurement}`;
    const commencementDate = tim?.targetCommencementDate
      ? `${translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.targetCommencementDate`,
        )} ${getShortDate(tim?.targetCommencementDate)}`
      : '';
    const exitDate = tim?.exitDate
      ? `${translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.exitDate`,
        )} ${getShortDate(tim?.exitDate)}`
      : '';
    const expandingContracting = tim?.expandingContracting?.name ?? null;

    const industry = tim?.tenantCompany?.industry?.name ?? '';
    return [
      hasSize ? measurementChip : '',
      commencementDate,
      exitDate,
      industry,
      expandingContracting,
    ];
  }, [tim]);

  const renderComment = () => {
    return (
      tim?.comments && (
        <div>
          <span className={`${styles['comments-title']} ${styles['comments']}`}>
            {`${translateText(
              `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.tenantInMarketComments`,
            )}: `}
          </span>
          <span className={styles['comments']}>{tim?.comments}</span>
        </div>
      )
    );
  };
  const renderBody = () => {
    return (
      <div className={styles['card-body']}>
        <AYLink to={locations.showCompany(String(tim?.tenantCompany?.id))}>
          <div className={'tenant'}>{tim?.tenantCompany?.name}</div>
        </AYLink>
        <div
          className={classNames(styles['tags'], {
            [styles['expanding-tag']]: isExpanding,
            [styles['contracting-tag']]: isContracting,
          })}
        >
          {renderSubtitles(bodyFieldChips, false, true, [
            isExpanding ? styles['expanding-tag'] : '',
            isContracting ? styles['contracting-tag'] : '',
          ])}
        </div>

        <div className={styles['subtitle']}>
          <div className={styles['tenant-status']}>
            {tim?.timStatusType?.name}
          </div>
          {renderSubtitles(bodySubtitles)}
        </div>
        {isCarouselView ? (
          <div className={styles['hidden-notes']}>
            <CompsComments hideNote={true} comments={tim?.comments ?? ''} />
          </div>
        ) : (
          renderComment()
        )}
      </div>
    );
  };

  return <>{renderBody()}</>;
};
export default TenantBody;
