import React from 'react';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import styles from '../../FormSection.module.scss';
import { getTIAllowanceSuggestions } from './utils';
import RadioInput from 'components/Inputs/RadioInput';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import {
  currencyInputFormatter,
  getCurrencySymbol,
} from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  required?: boolean;
  property?: IProperty;
  value?: number | null;
  currencySymbol?: string;
  unitOfMeasurement: string;
  onChange: (field: string, value: any) => void;
}

const TIAllowance: React.FC<Props> = ({
  value,
  property,
  onChange,
  currencySymbol,
  required = false,
  unitOfMeasurement,
}) => {
  const currency = currencySymbol || getCurrencySymbol(property?.currencyCode);

  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText('avantPlatform.attributes.lease.label.TIAllowance')}
    >
      <div className={styles['large-input-container']}>
        <CurrencyInput
          value={value}
          id="ti-allowance"
          iconPosition={'left'}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          formatter={currencyInputFormatter}
          currencySymbol={currency}
          onChange={value =>
            value === ''
              ? onChange('tenantImprovementAllowance', null)
              : onChange('tenantImprovementAllowance', value)
          }
        />
        <UnitOfMeasurementLegend unitOfMeasurement={unitOfMeasurement} />
        <RadioInput
          selectedItem={value}
          data={getTIAllowanceSuggestions(currency)}
          onChange={item => onChange('tenantImprovementAllowance', item.value)}
        />
      </div>
    </FormControl>
  );
};

export default TIAllowance;
