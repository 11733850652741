import { HistoricalAvailabilityTypes } from 'components/Graphs/HistoricalAvailabilityChart/utils';
import {
  formatQuarter,
  getAverage,
} from 'components/Graphs/HistoricalAvailabilityVictoryChart/utils';
import {
  LineChart,
  LineData,
  VictoryLineStyle,
} from 'components/Graphs/VictoryLineChart/types';
import { colors } from 'constants/colors';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_PROPERTY_COMMON_WORD_PATH,
} from 'constants/i18n';
import { DOT_LG } from 'constants/placeholders';
import { IHistoricalAvailabilityData } from 'interfaces/ISubmarketHistoricalAvailability';
import { translateText } from 'utils/i18n';
import { uniq, max } from 'lodash';
import { getLineStyle } from 'components/Graphs/VictoryLineChart/utils';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { getCurrentYear } from 'utils/date';
import { formatQuarterForTooltip } from 'utils/graphs';

export const SUB_TITLE_PATH =
  'avantProperties.labels.historicalPerformance.graphs.historicalAvailability.companySubtitle';

export const YEARS_FOR_QUARTER_DATA = 5;
export const YEARS_FOR_YEARLY_DATA = 20;

export const getTooltipFormatter = (
  label: string,
  percentage?: number,
  isQuarterly?: boolean,
  quarter?: number | string,
) => {
  const ofPortfolioLabel = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.ofPortfolio`,
  );

  return `${label} ${DOT_LG} ${percentage}% ${ofPortfolioLabel} ${label} ${formatQuarterForTooltip(
    quarter,
    isQuarterly,
  )}`;
};

export const getLegends = (activeType: HistoricalAvailabilityTypes) => {
  const isOccupiedType = activeType === HistoricalAvailabilityTypes.OCCUPIED;
  const isAvailable = activeType === HistoricalAvailabilityTypes.AVAILABLE;

  if (isOccupiedType) {
    return [
      {
        name: translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.overall`),
        symbol: { fill: colors.supportive500, type: 'minus', size: 2 },
      },
    ];
  } else {
    const text = isAvailable
      ? translateText(`${I18N_PROPERTY_COMMON_WORD_PATH}.avail`)
      : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.vacant`);
    return [
      {
        name: translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.direct`, {
          text,
        }),
        symbol: { fill: colors.supportive500, type: 'minus', size: 2 },
      },
      {
        name: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.sublease`,
          { text },
        ),
        symbol: { fill: colors.ayWhiteColor, type: 'minus', size: 2 },
      },
    ];
  }
};

const getLineData = (
  areaData: IHistoricalAvailabilityData[],
  activeType: HistoricalAvailabilityTypes,
  chartId: string,
  unitOfMeasurement: string,
  getTooltipFormatter: (
    label: string,
    percentage?: number,
    isQuarterly?: boolean,
    quarter?: number | string,
  ) => string,
  isQuarterly?: boolean,
) => {
  const isAvailable = activeType === HistoricalAvailabilityTypes.AVAILABLE;
  const isOccupied = activeType === HistoricalAvailabilityTypes.OCCUPIED;

  const occupiedLabel = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.occupied`,
  ).toLowerCase();

  const occupiedArea = {
    id: `${chartId}-occupied-area`,
    color: colors.supportive500,
    colorOnHover: colors.ayWhiteColor,
    data: areaData.map(d => ({
      x: isQuarterly ? `${formatQuarter(d)}` : d.year,
      y: d.occupiedSFPercentage || 0,
      item: {
        buildingSize: d.occupiedSF,
        unitOfMeasurement,
        label: getTooltipFormatter(
          occupiedLabel,
          d.occupiedSFPercentage,
          isQuarterly,
          d.quarter,
        ),
      },
    })),
  };

  const subleaseArea = {
    id: `${chartId}-sublease-area`,
    color: colors.ayWhiteColor,
    colorOnHover: colors.ayBlackColor,
    data: areaData.map(d => {
      return {
        x: isQuarterly ? `${formatQuarter(d)}` : d.year,
        y: isAvailable
          ? d.availableSFSubletPercentage
          : d.vacantSFSubletPercentage,
        item: {
          buildingSize: isAvailable ? d.availableSFSublet : d.vacantSFSublet,
          unitOfMeasurement,
          label: isAvailable
            ? translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.subleaseAvailable`,
                {
                  percentage: getTooltipFormatter(
                    '',
                    d.availableSFSubletPercentage,
                    isQuarterly,
                    d.quarter,
                  ),
                },
              )
            : translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.subleaseVacant`,
                {
                  percentage: getTooltipFormatter(
                    '',
                    d.vacantSFSubletPercentage,
                    isQuarterly,
                    d.quarter,
                  ),
                },
              ),
        },
      };
    }),
  };

  const directArea = {
    id: `${chartId}-direct-area`,
    color: colors.supportive500,
    colorOnHover: colors.ayWhiteColor,
    data: areaData.map(d => {
      return {
        x: isQuarterly ? `${formatQuarter(d)}` : d.year,
        y: isAvailable
          ? d.availableSFDirectPercentage
          : d.vacantSFDirectPercentage,
        item: {
          buildingSize: isAvailable ? d.availableSFDirect : d.vacantSFDirect,
          unitOfMeasurement,
          label: isAvailable
            ? translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.directAvailable`,
                {
                  percentage: getTooltipFormatter(
                    '',
                    d.availableSFDirectPercentage,
                    isQuarterly,
                    d.quarter,
                  ),
                },
              )
            : translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.directVacant`,
                {
                  percentage: getTooltipFormatter(
                    '',
                    d.vacantSFDirectPercentage,
                    isQuarterly,
                    d.quarter,
                  ),
                },
              ),
        },
      };
    }),
  };

  return isOccupied ? [occupiedArea] : [directArea, subleaseArea];
};

const PROPERTIES_ANALYTICS_HISTORICAL_AVAILABILITY_CHART_ID =
  'properties-analytics-historical-availability';

export const parseGraphData = (
  data: IHistoricalAvailabilityData[],
  activeType: HistoricalAvailabilityTypes,
  isQuarterly?: boolean,
): LineChart => {
  const graphData: LineChart = { historicalLineData: [], xAxis: [], yAxis: [] };
  const currentYear = getCurrentYear();

  const handledData = isQuarterly
    ? data.filter(x => x.year >= currentYear - YEARS_FOR_QUARTER_DATA)
    : data.filter(x => x.year >= currentYear - YEARS_FOR_YEARLY_DATA);

  const lineDataArray = getLineData(
    handledData,
    activeType,
    PROPERTIES_ANALYTICS_HISTORICAL_AVAILABILITY_CHART_ID,
    UnitOfMeasurement.sf,
    getTooltipFormatter,
    isQuarterly,
  );

  graphData.historicalLineData?.push(
    getAverage(handledData, activeType, isQuarterly),
  );
  lineDataArray?.forEach(c => {
    const lineData: LineData = { ...getLineStyle(c.color, c.colorOnHover) };
    lineData.values = c.data;
    graphData.historicalLineData?.push(lineData);
  });

  graphData.yAxis = getYAxis(
    max(lineDataArray?.flatMap(x => x.data.map(item => item.y))) || 0,
  );
  graphData.xAxis = isQuarterly
    ? uniq(handledData.map((d: any) => formatQuarter(d)))
    : uniq(handledData.map((d: any) => d.year));

  return graphData;
};

const getYAxis = (maxYValueParam: number) => {
  const maxYValue =
    Math.round(maxYValueParam) > 100 ? 100 : Math.round(maxYValueParam) + 1;
  const firstLevel = 1 / 4;
  const xAxisValues = [0];
  for (let i = 1; i <= 4; i++) {
    const partialValue = firstLevel * i;
    const xAxisValue = maxYValue * partialValue;
    xAxisValues.push(xAxisValue > 100 ? 100 : xAxisValue);
  }
  return xAxisValues;
};

export const getChartStyle = (
  activeType: HistoricalAvailabilityTypes,
): VictoryLineStyle => {
  const isOccupied = activeType === HistoricalAvailabilityTypes.OCCUPIED;
  const isAvailable = activeType === HistoricalAvailabilityTypes.AVAILABLE;
  return {
    legend: {
      x: isOccupied ? 440 : isAvailable ? 370 : 365,
      y: 28,
    },
  };
};
