import React from 'react';
import { Row } from 'components/@codelitt/ay-design-library';
import SmallDonutGraph from 'components/SmallDonutGraph';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import { PropertyDonutsChartsIDs } from './constants';

export interface PropertyDonutChartData {
  defaultData?: IDonutGraphData;
  graphData?: IDonutGraphData[];
  id: number;
  loading: boolean;
  title: string;
}

type Props = {
  donutsData?: PropertyDonutChartData[];
  onDonutClick?: (id: number, graphData?: IDonutGraphData) => void;
};

const PropertyDonutsCharts: React.FC<Props> = ({
  donutsData,
  onDonutClick,
}) => (
  <Row>
    {donutsData?.map(donut => (
      <SmallDonutGraph
        key={donut.id}
        graphData={donut?.graphData}
        defaultData={donut?.defaultData}
        isLoading={donut.loading}
        donutId={donut.id}
        title={donut.title}
        onDonutClick={graphData => onDonutClick?.(donut.id, graphData)}
        shouldSortByTitle={
          donut.id === PropertyDonutsChartsIDs.DonutPropertyClass
        }
      />
    ))}
  </Row>
);

export default PropertyDonutsCharts;
