import React from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { MutationFunctionOptions } from '@apollo/client';

interface Props {
  commentIdToDelete: number | null;
  deleteScoopComment: (
    options?:
      | MutationFunctionOptions<any, Record<string, any>, any, any>
      | undefined,
  ) => Promise<any>;
  isDeleting: boolean;
  loadingDeleteComment: boolean;
  setCommentIdToDelete: (commentId: number | null) => void;
}

const DeleteCommentPopup: React.FC<Props> = ({
  commentIdToDelete,
  deleteScoopComment,
  isDeleting,
  loadingDeleteComment,
  setCommentIdToDelete,
}) =>
  isDeleting ? (
    <ConfirmationModal
      onCloseModal={() => setCommentIdToDelete(null)}
      negativeButton={{
        label: loadingDeleteComment ? 'Please wait' : 'Yes, delete it',
        isDisabled: loadingDeleteComment,
        onclick: async () => {
          await deleteScoopComment({
            variables: {
              scoopCommentId: commentIdToDelete,
            },
          });
          setCommentIdToDelete(null);
        },
      }}
      positiveButton={{
        label: 'No, keep it',
        onclick: () => setCommentIdToDelete(null),
      }}
      header={'Delete Comment'}
      paragraph={[
        'You’re about to delete the following comment, ',
        'do you want to proceed?',
      ]}
    />
  ) : null;

export default DeleteCommentPopup;
