import React, { useEffect, useRef, useState } from 'react';
import { select } from 'd3-selection';
import { arc } from 'd3-shape';
import { IStaticDonutGraphData } from 'interfaces/IStaticDonutGraphData';
import isEqual from 'lodash/isEqual';
import {
  DONUT_SIZE,
  getDonutInnerRadius,
  getDonutOuterRadius,
} from 'components/Graphs/DonutGraph/graphConstants';
import donutGenerator from 'components/Graphs/DonutGraph/Elements/donutGenerator';
import {
  getDonutDataId,
  getDonutSliceClass,
} from 'components/Graphs/DonutGraph/nodes';
import { COMMA } from 'constants/placeholders';

interface Props {
  donutId: number;
  data: IStaticDonutGraphData[];
  size: DONUT_SIZE;
}

const Donut: React.FC<Props> = ({ donutId, data, size }) => {
  const pieGroupRef = useRef(null);

  const [dataPoints, setDataPoints] = useState<IStaticDonutGraphData[]>();

  const getXYCoordinates = () => ({
    x: getDonutOuterRadius(size),
    y: getDonutOuterRadius(size),
  });

  const getArcPathGenerator = () =>
    arc()
      .outerRadius(getDonutOuterRadius(size))
      .innerRadius(getDonutInnerRadius(size));

  useEffect(() => {
    if (!isEqual(data, dataPoints)) {
      setDataPoints(data);
    }
  }, [data, dataPoints]);

  useEffect(() => {
    if (!dataPoints) return;

    const pieGroup = pieGroupRef?.current;

    if (!pieGroup) return;

    const translate = getXYCoordinates();
    const arcGenerator: any = getArcPathGenerator();

    select(pieGroup)
      .selectAll(`.${getDonutSliceClass(donutId)}`)
      .data(donutGenerator(dataPoints))
      .join('path')
      .attr('transform', 'translate(' + translate.x + COMMA + translate.y + ')')
      .attr('d', arcGenerator)
      .attr('class', `${getDonutSliceClass(donutId)}`)
      .attr('id', (d: any) => getDonutDataId(d.data, donutId))
      .attr('fill', (d: any) => d.data.color);

    // eslint-disable-next-line
  }, [dataPoints]);

  return <g ref={pieGroupRef} />;
};

export default Donut;
