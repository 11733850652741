import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { UnitsOfMeasurementModelType } from 'interfaces/unitOfMeasurement';

export const OFFICE_MAPPING: UnitsOfMeasurementModelType[] = [
  {
    key: 'columnSpacingMin',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'columnSpacingMin',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'columnSpacingMinMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'columnSpacingMinMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
  {
    key: 'columnSpacingMax',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'columnSpacingMax',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'columnSpacingMaxMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'columnSpacingMaxMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
  {
    key: 'slabCeilingHeightFeet',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'slabCeilingHeightFeet',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'slabCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'slabCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
  {
    key: 'slabCeilingHeightInches',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'slabCeilingHeightInches',
        unit: UnitOfMeasurement.inches,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'slabCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'slabCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
  {
    key: 'typicalCeilingHeightFeet',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'typicalCeilingHeightFeet',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'typicalCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'typicalCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
  {
    key: 'typicalCeilingHeightInches',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'typicalCeilingHeightInches',
        unit: UnitOfMeasurement.inches,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'typicalCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'typicalCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
];
