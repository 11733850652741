import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { colors } from 'constants/colors';

import { graphDimensions } from '../graphConstants';
import * as d3 from 'd3';
import { IProperty } from 'interfaces/IProperty';
import { bounds, pack } from '../rectPackLayout';
import { updateTooltipPosition } from './Tooltip';
import {
  getCircleClass,
  getCircleId,
  getCircleShadowClass,
  getCircleShadowId,
  getTooltipId,
} from '../nodes';
import { IPortfolioDataPoint } from '../interfaces';
import { useHistory } from 'react-router-dom';
import locations from 'routes';
import { IdName } from 'interfaces/IdName';
import isEqual from 'lodash/isEqual';

interface Props {
  activeOwner?: IdName;
  data: IPortfolioDataPoint[];
  height: number;
  onHoverStatusChange: (dataPoint: IPortfolioDataPoint | undefined) => void;
  property?: IProperty;
  svgRef: MutableRefObject<SVGSVGElement | null>;
  width: number;
  graphId: number;
  activeColor?: string;
  inactiveColor?: string;
}

const BASE_CIRCLE_RADIUS = 8;
const ACTIVE_STROKE_WIDTH = 1;
const HALO_STROKE_WIDTH = 6;
const HALO_STROKE_OPACITY = 0.32;

const Circles: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const circleGroupRef = useRef(null);
  const {
    activeOwner,
    property,
    width,
    height,
    svgRef,
    graphId,
    activeColor,
    inactiveColor,
  } = props;

  const [dataPoints, setDataPoints] = useState<IPortfolioDataPoint[]>([]);

  useEffect(() => {
    if (!isEqual(props.data, dataPoints)) {
      setDataPoints(props.data);
    }
  }, [props.data, dataPoints]);

  let hoverTimeout: any = null;

  const increaseBubbleSizes = dataPoints.length < 15;

  const defaultActiveColor = activeColor || colors.supportive500;
  const defaultInactiveColor = inactiveColor || colors.supportive500;

  const inactiveOpacity = 0.5;

  const isCircleOfCurrentProperty = (circle: IPortfolioDataPoint) => {
    return circle.propertyId === property?.id;
  };

  const getBubbleColor = (isActive?: boolean) => {
    if (isActive) {
      return defaultActiveColor;
    }

    return props.inactiveColor || defaultInactiveColor;
  };

  const getBubbleStrokeColor = (isActive?: boolean) => {
    if (!isActive) {
      return 'none';
    }

    return !props.activeColor ? colors.supportive500 : defaultActiveColor;
  };

  const getRadiusScale = () => {
    const minBuildingSize = +d3.min(dataPoints, (d: IPortfolioDataPoint) => {
      return d.size;
    })!;

    const maxBuildingSize = +d3.max(dataPoints, (d: IPortfolioDataPoint) => {
      return d.size;
    })!;

    const sumBuildingSize = +d3.sum(dataPoints, (d: IPortfolioDataPoint) => {
      return d.size;
    });

    // Used to increase the maxRadius of the circles depending on the number of properties we need to render.
    const additionalRadiusMultiplier = increaseBubbleSizes ? 1.25 : 3;

    // The minRadius of each circle can change depending on the number of circles we're going to draw.
    // This allows us to distribute the circles in a better way in the graph area.
    const minRadiusManyBubbles =
      BASE_CIRCLE_RADIUS +
      BASE_CIRCLE_RADIUS * (Math.ceil(100 / dataPoints.length) / 150);

    // Calculate the rectangle area of the graph
    const usableArea =
      Math.PI * Math.pow(Math.min(width, height) / 2, 2) * 0.667;

    // Calculate the scale factor to apply in the radius of the circles, so they can fit in the rectangle area
    const scaleFactor =
      Math.sqrt(usableArea) / Math.sqrt(sumBuildingSize) / Math.PI;
    const minRadiusLessBubbles = Math.sqrt(minBuildingSize) * scaleFactor;
    const maxRadius = Math.sqrt(maxBuildingSize) * scaleFactor;

    let minRadius = increaseBubbleSizes
      ? minRadiusLessBubbles
      : minRadiusManyBubbles;

    // Set a min radius of 8 in order to keep the circles visible in the graph.
    minRadius = Math.max(minRadius, 8);
    return d3
      .scaleSqrt()
      .domain([minBuildingSize, maxBuildingSize]) //data range
      .range([
        minRadius,
        Math.max(maxRadius, minRadius * additionalRadiusMultiplier),
      ]);
  };

  const packData = (width: number, height: number) => {
    const rScale = getRadiusScale();

    const graphProperties = dataPoints
      .map((d: IPortfolioDataPoint) => {
        d.r = rScale(d.size!)!;
        return d;
      })
      .sort((a: IPortfolioDataPoint, b: IPortfolioDataPoint) => {
        return b.size! - a.size!;
      });

    // The pack function will use the radius we added to the data, and will find the best
    // placement (x and y coordinates) for each circle.
    return pack(graphProperties, height, width);
  };

  const onMouseOutCircle = () => {
    hoverTimeout && clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      d3.select(`#${getTooltipId(graphId)}`).style('display', 'none');

      // reset the base fill color of all circles
      d3.selectAll(`.${getCircleClass(graphId)}`)
        .attr('fill', getBubbleColor())
        .attr('fill-opacity', inactiveOpacity);

      // Hide all shadow bubbles
      d3.selectAll(`.${getCircleShadowClass(graphId)}`).style(
        'display',
        'none',
      );

      if (property?.id) {
        // Active property is the property that you see on the page
        const activePropertyNode = d3.select(
          `#${getCircleId(property.id, graphId)}`,
        );

        if (!activePropertyNode.empty()) {
          activePropertyNode
            .attr('fill', getBubbleColor(true))
            .attr('stroke', 'none')
            .attr('fill-opacity', 1);
        }
      }

      props.onHoverStatusChange(undefined);
    }, 250);
  };

  const onMouseOverCircle = (bubble: IPortfolioDataPoint) => {
    hoverTimeout && clearTimeout(hoverTimeout);

    // Current property is the property that's hovered
    const currentPropertyNode = d3.select(
      `#${getCircleId(bubble.id, graphId)}`,
    );

    // reset the base fill color of all circles
    d3.selectAll(`.${getCircleClass(graphId)}`)
      .attr('fill', getBubbleColor())
      .attr('fill-opacity', inactiveOpacity)
      .attr('stroke', 'none');

    // set the active fill color on the active property bubble
    currentPropertyNode &&
      currentPropertyNode
        .attr('fill', defaultActiveColor)
        .attr('fill-opacity', 1);

    // Hide all shadow bubbles, to make sure we have only 1 visible at a time
    d3.selectAll(`.${getCircleShadowClass(graphId)}`).style('display', 'none');

    d3.select(`#${getCircleShadowId(bubble.id, graphId)}`).style(
      'display',
      'block',
    );

    if (property?.id) {
      // Active property is the property that you see on the page
      const activePropertyNode = d3.select(
        `#${getCircleId(property.id, graphId)}`,
      );

      if (!activePropertyNode.empty() && !isCircleOfCurrentProperty(bubble)) {
        activePropertyNode.attr('stroke', getBubbleStrokeColor(true));
      }
    }

    props.onHoverStatusChange(bubble);
    updateTooltipPosition(bubble.id, graphId);
  };

  useEffect(() => {
    const node = circleGroupRef.current;

    const circles: IPortfolioDataPoint[] = packData(width, height);

    // Based on the circles distribution, the bounds function will return a viewBox with the area to draw the circles,
    // so they won't be placed outside of the graph area.
    const rectBounds = bounds(circles, 0);
    if (rectBounds.every(bound => bound !== +Infinity && bound !== -Infinity)) {
      d3.select(svgRef.current!)
        .attr('viewBox', rectBounds.join())
        .attr('preserveAspectRatio', 'xMidYMid meet');
    }

    const circlesGroup = d3.select(node);

    circlesGroup
      .selectAll(`.${getCircleClass(graphId)}`)
      .data(circles)
      .join('circle')
      .attr('vector-effect', 'non-scaling-stroke')
      .attr('fill', circle => {
        return getBubbleColor(isCircleOfCurrentProperty(circle));
      })
      .attr('fill-opacity', circle => {
        return isCircleOfCurrentProperty(circle) ? 1 : inactiveOpacity;
      })
      .attr('stroke', (circle: IPortfolioDataPoint) => {
        return getBubbleStrokeColor(!isCircleOfCurrentProperty(circle));
      })
      .attr('stroke-width', (circle: IPortfolioDataPoint) => {
        return isCircleOfCurrentProperty(circle) ? ACTIVE_STROKE_WIDTH : 0;
      })
      .attr('id', circle => getCircleId(circle.id, graphId))
      .attr('class', getCircleClass(graphId))
      .attr('cx', circle => circle.x)
      .attr('cy', circle => circle.y)
      .attr(
        'r',
        propertyBubble => propertyBubble.r - graphDimensions.CIRCLE_PADDING,
      )
      .style('cursor', 'pointer')
      .on('click', circle => {
        if (!circle.propertyId) return false;
        history.push(locations.showProperty(circle.propertyId));
      })
      .on('mouseover', onMouseOverCircle)
      .on('mouseout', onMouseOutCircle);

    circlesGroup
      .selectAll(`.${getCircleShadowClass(graphId)}`)
      .data(circles)
      .join('circle')
      .attr('vector-effect', 'non-scaling-stroke')
      .attr('fill', 'none')
      .attr('stroke', defaultActiveColor)
      .attr('stroke-width', HALO_STROKE_WIDTH)
      .attr('stroke-opacity', HALO_STROKE_OPACITY)
      .attr('class', getCircleShadowClass(graphId))
      .attr('id', circle => getCircleShadowId(circle.id, graphId))
      .attr('cx', propertyBubble => propertyBubble.x)
      .attr('cy', propertyBubble => propertyBubble.y)
      .attr('r', propertyBubble => propertyBubble.r)
      .style('display', 'none')
      .style('cursor', 'pointer');

    // eslint-disable-next-line
  }, [dataPoints, property]);

  useEffect(() => {
    if (!dataPoints.length) return;

    if (activeOwner) {
      const ownedProperties = dataPoints.filter(dataPoint => {
        return (dataPoint.ownerIds || []).some(
          ownerId => ownerId === activeOwner?.id,
        );
      });

      ownedProperties.forEach(dataPoint => {
        d3.select(`#${getCircleId(dataPoint.id, graphId)}`)
          .attr('fill', getBubbleColor())
          .attr('fill-opacity', inactiveOpacity)
          .attr('stroke-width', ACTIVE_STROKE_WIDTH)
          .attr('stroke', getBubbleStrokeColor(true));
      });
    } else {
      // Remove the stroke from all bubbles
      d3.selectAll(`.${getCircleClass(graphId)}`).attr('stroke-width', 0);

      if (property?.id) {
        // Active property is the property that you see on the page
        const activePropertyNode = d3.select(
          `#${getCircleId(property.id, graphId)}`,
        );

        // Add the stroke and reset the fill color if there is an active node
        if (!activePropertyNode.empty()) {
          activePropertyNode
            .attr('fill', getBubbleColor(true))
            .attr('stroke', 'none')
            .attr('stroke-width', ACTIVE_STROKE_WIDTH)
            .attr('fill-opacity', 1);
        }
      }
    }

    // eslint-disable-next-line
  }, [activeOwner, dataPoints, graphId]);

  return <g ref={circleGroupRef} />;
};

export default Circles;
