import LEASE_SIZE_OPTIONS from 'components/FindComps/EditSearchCriteria/StaticFilters/leaseSize';
import { RangeFilterType } from 'interfaces/ISearchOptionsType';

export interface LeaseSearchFields {
  isIndustriesFilter?: boolean;
  isLeaseSizeFilter?: boolean;
  key?: string;
  op?: string;
  range?: RangeFilterType;
}

const getLeaseSizeFilter = (searchFilter: LeaseSearchFields, value: string) => {
  const filter = LEASE_SIZE_OPTIONS.find(op => op.name === value)?.filter;

  return {
    and: [
      {
        key: searchFilter.key,
        op: filter?.operator,
        range: filter?.range,
        value: filter?.value?.toString(),
      },
    ],
  };
};

export const getLeaseFilter = (
  searchFilter: LeaseSearchFields,
  value: string,
) => {
  if (searchFilter.isLeaseSizeFilter)
    return getLeaseSizeFilter(searchFilter, value);

  const { key, op } = searchFilter;
  return {
    and: [
      {
        key,
        op,
        value,
      },
    ],
  };
};

export const getIndustriesFilter = (industryName: string) => [
  { name: industryName },
];
