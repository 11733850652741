import React from 'react';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import LoadingMessage from 'components/LoadingMessage';
import InfoBoxes from 'components/InfoBoxes';
import styles from './KPIS.module.scss';
import { usePropertySetLeasingMetadata } from 'hooks/usePropertySetLeasingMetadata';
import { IInfoBox } from 'components/InfoBoxes/constants';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { GET_TENANT_LEASING_METADATA } from 'graphql/agencyAssignments';
import { parseInfoBoxesData } from '../utils';
import { AgencyAssignmentsProps } from 'components/AgencyAssignments/types';

const KPIs: React.FC<AgencyAssignmentsProps> = ({
  propertySet,
  unitOfMeasurement,
  toggleOptions,
}) => {
  const {
    propertySetLeasingMetadataResponse,
    isLoading,
  } = usePropertySetLeasingMetadata(
    propertySet.id!,
    GET_TENANT_LEASING_METADATA,
  );

  const parsedData =
    propertySetLeasingMetadataResponse &&
    (parseInfoBoxesData(
      propertySetLeasingMetadataResponse,
      toggleOptions!,
      unitOfMeasurement || UnitOfMeasurement.sf,
    ) as IInfoBox[]);

  return isLoading ? (
    <LoadingMessage />
  ) : (
    <Row wrapperClassName={styles.row}>
      <Col>
        <InfoBoxes data={parsedData} textAlign="center" />
      </Col>
    </Row>
  );
};

export default KPIs;
