import classNames from 'classnames';
import Loader from 'components/HistoricalPerformance/Loader';
import Icon from 'components/Icon';
import {
  getSubMarketVariables,
  getTopFiveMarketList,
} from 'components/SubNavBar/utils';
import { colors } from 'constants/colors';
import { PropertyTypeIds } from 'constants/propertyTypes';
import { authContext } from 'contexts/AuthContext';
import { translateText } from 'utils/i18n';
import { MARKETS_QUERY, SUB_MARKETS_QUERY } from 'graphql/markets';
import { IMarket, ISubmarket } from 'interfaces/IMarket';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import locations from 'routes';
import styles from './MarketDropdown.module.scss';
import { I18N_AVANT_PROPERTY_ENUMERATORS } from 'constants/i18n';
import { deepCopy } from 'utils/objects';

interface Props {
  label: string;
  id: string;
}

const MarketDropdown: React.FC<Props> = ({ id, label }) => {
  const { user } = useContext(authContext);
  const history = useHistory();
  const [showTable, setShowTable] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const client = useApolloClient();
  const [initialListMarket, setInitialListMarket] = useState<IMarket[]>();
  const [currentMarket, setCurrentMarket] = useState<IMarket>();

  useEffect(() => {
    const fetchMarket = async () => {
      const { data } = await client.query({
        query: MARKETS_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          search: {
            ids: user.preferences?.marketIds,
            order: {
              field: 'name',
              direction: 'asc',
            },
          },
          includePropertiesCount: true,
        },
      });

      const listMarkets = getTopFiveMarketList(deepCopy(data?.markets));
      setInitialListMarket(listMarkets);
      setCurrentMarket(listMarkets?.[0]);
    };

    fetchMarket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: officeSubMarkets,
    loading: isLoadingOfficeSubmarkets,
  } = useQuery<{
    submarkets: ISubmarket[];
  }>(SUB_MARKETS_QUERY, {
    variables: getSubMarketVariables([PropertyTypeIds.office], currentMarket),
    skip: !currentMarket,
  });

  const {
    data: industrialSubMarkets,
    loading: isLoadingIndustrialSubmarkets,
  } = useQuery<{
    submarkets: ISubmarket[];
  }>(SUB_MARKETS_QUERY, {
    variables: getSubMarketVariables(
      [PropertyTypeIds.industrial],
      currentMarket,
    ),
    skip: !currentMarket,
  });

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        showTable &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowTable(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef, buttonRef, showTable]);

  const handleHoverMarket = (market: IMarket) => {
    if (market.id !== currentMarket?.id) {
      setCurrentMarket(market);
    }
  };

  const handleClickMarket = (market: IMarket) => {
    history.push(locations.showMarket(market.id));
  };

  const handleClickSubMarket = (
    submarketId: number,
    propertyTypeId: number,
  ) => {
    history.push(locations.showSubmarket(submarketId, propertyTypeId));
    setShowTable(false);
  };

  const getSubMarketTable = () => {
    return isLoadingOfficeSubmarkets ||
      isLoadingIndustrialSubmarkets ||
      !currentMarket ? (
      <Loader />
    ) : (
      <div className={classNames(styles['menu-div'])} id={id}>
        <div className={styles['office-container']}>
          <label className={styles['link-title']}>
            {translateText(
              `${I18N_AVANT_PROPERTY_ENUMERATORS}.propertyTypes.office`,
            )}
          </label>
          {officeSubMarkets?.submarkets.map(submarket => (
            <button
              key={`${submarket.id}-office`}
              className={styles['link-item']}
              onClick={() => handleClickSubMarket(submarket.id, 1)}
            >
              {submarket.name}
            </button>
          ))}
        </div>
        <div className={styles['industrial-container']}>
          <label className={styles['link-title']}>
            {translateText(
              `${I18N_AVANT_PROPERTY_ENUMERATORS}.propertyTypes.industrial`,
            )}
          </label>
          {industrialSubMarkets?.submarkets.map(submarket => (
            <button
              key={`${submarket.id}-industrial`}
              onClick={() => handleClickSubMarket(submarket.id, 2)}
              className={styles['link-item']}
            >
              {submarket.name}
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <button
        className={classNames(
          styles['button-label'],
          showTable && styles['opened-table'],
        )}
        onClick={() => setShowTable(!showTable)}
        ref={buttonRef}
      >
        {label}
        <Icon
          name="arrowDown"
          width={0.6625}
          height={0.6875}
          className={styles['arrow']}
          color={colors.primaryColor500}
        />
      </button>
      {showTable ? (
        <div className={styles['market-container']} ref={divRef}>
          <div className={styles['user-market-container']}>
            {initialListMarket?.map(market => (
              <button
                key={market.id}
                className={classNames(
                  styles['market-button'],
                  currentMarket === market && styles['selected'],
                )}
                onMouseOver={() => handleHoverMarket(market)}
                onClick={() => handleClickMarket(market)}
              >
                {market.name}
              </button>
            ))}
          </div>
          {getSubMarketTable()}
        </div>
      ) : null}
    </>
  );
};

export default MarketDropdown;
