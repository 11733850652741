import { capitalize } from 'lodash';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { DOT } from 'constants/placeholders';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { translateText } from 'utils/i18n';
import { PropertyCompanyType } from 'constants/propertyCompanyType';
import AvantLogoImg from '../../../assets/images/avant-small.svg';
import MapBoxLogoImg from '../../../assets/images/mapbox-logo.svg';
import { SuggestionIcon } from '../enums';

const isNotMarketOrSubmarket = (propertyCompany: IPropertyCompany) => {
  return (
    propertyCompany.type !== PropertyCompanyType.MARKET &&
    propertyCompany.type !== PropertyCompanyType.SUBMARKET
  );
};

export const getSubmarketDetails = (propertyCompany: IPropertyCompany) => {
  if (isNotMarketOrSubmarket(propertyCompany)) return '';

  return [
    propertyCompany.state,
    propertyCompany.propertyType,
    `${propertyCompany.numberOfBuildings} ${capitalize(
      translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.building`, {
        count: propertyCompany.numberOfBuildings,
      }),
    )}`,
  ]
    .filter(Boolean)
    .join(` ${DOT} `);
};

export const getSourceIcon = (type?: string) => {
  if (type === SuggestionIcon.mapBoxAddress)
    return {
      icon: MapBoxLogoImg,
      alt: `Mapbox ${translateText(`avantProperties.labels.common.address`)}`,
    };
  return { icon: AvantLogoImg, alt: 'Avison Young' };
};
