import React, { useState } from 'react';

import AgencyLeasingHeader from 'components/AgencyAssignments/AgencyLeasingHeader';
import CommonAgencyLeasingControl from 'components/AgencyAssignments/AgencyLeasingHeader/CommonAgencyLeasingControl';
import CompsPopup from 'components/CompsPopup';
import { AgencyLeasingPropertySection } from 'components/AgencyAssignments/types';
import { Container } from 'components/@codelitt/ay-design-library';
import { getCompsPopupSearchFilter } from 'components/FindComps/PropertiesAnalyticsContainer/utils';
import { ColorNames } from 'constants/colorNames';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';

import Composition from './Composition';
import HistoricalAvailability from './HistoricalAvailability';
import ListContainer from './ListContainer';
import styles from './Properties.module.scss';
import { PropertiesProps } from './types';
import { getGraphqlQuery, PROPERTIES_POPUP_INIT } from './utils';

const Properties = ({ agencyLeasing, section }: PropertiesProps) => {
  const [propertiesPopup, setPropertiesPopup] = useState<
    ILargestPropertiesPopup
  >(PROPERTIES_POPUP_INIT);

  if (!agencyLeasing) {
    return null;
  }

  const isList = section === AgencyLeasingPropertySection.List;
  const isSummary = section === AgencyLeasingPropertySection.Summary;

  const propertyIds = agencyLeasing?.properties?.map(property => property.id!);

  return (
    <div>
      <Container>
        <AgencyLeasingHeader
          breadcrumbLevels={[
            { content: agencyLeasing.name },
            {
              content: translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`,
              ),
            },
          ]}
          actionContent={
            <CommonAgencyLeasingControl
              showMoreTrendsButton
              showExcelDownloadButton
              agencyLeasing={agencyLeasing}
              propertyIds={propertyIds}
            />
          }
        />
      </Container>
      <Container>
        {isList && <ListContainer propertySet={agencyLeasing} />}
        {isSummary ? (
          <Container wrapperClassName={styles['properties-content']}>
            <Composition
              propertySet={agencyLeasing}
              setPropertiesPopup={setPropertiesPopup}
            />
            <HistoricalAvailability
              properties={agencyLeasing.properties || []}
            />
          </Container>
        ) : null}
      </Container>
      {propertiesPopup?.isVisible && (
        <CompsPopup
          action={propertiesPopup?.onSeeAllComps}
          actionLabel={propertiesPopup?.actionLabel}
          hideAction={propertiesPopup.hideAction}
          graphqlQuery={getGraphqlQuery(propertiesPopup)}
          graphqlVariables={getCompsPopupSearchFilter(propertiesPopup)}
          onClose={() => setPropertiesPopup(PROPERTIES_POPUP_INIT)}
          showPropertyData
          subtitle={propertiesPopup.popupSubtitle}
          tagBackgroundColor={ColorNames.supportiveColor100}
          title={propertiesPopup.popupTitle}
        />
      )}
    </div>
  );
};

export default Properties;
