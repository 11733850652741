import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import graphDimensions, {
  GraphClasses,
} from '../../RecentLeases/graphConstants';
import {
  getHorizontalLineId,
  getLeaseVerticalLineId,
  getMarketVerticalLineId,
} from '../../RecentLeases/nodes';
import { ScaleTime, ScaleLinear } from 'd3-scale';
import { colors } from 'constants/colors';
import { IBubbleDataPoint } from 'components/Graphs/BubbleChartsElements/interfaces';
import { X_AXIS_PADDING } from 'components/Graphs/RecentLeases/graphScales';

interface Props {
  marketDataPoints: IBubbleDataPoint[];
  itemsDataPoints: IBubbleDataPoint[];
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
  linesColor: string;
  secondaryLinesColor?: string;
  graphWidth: number;
  graphHeight: number;
  horizontal?: boolean;
  yAxisValues?: number[];
}

const BackgroundLines: React.FC<Props> = props => {
  const verticalLinesRef = useRef(null);
  const fixedHorizontalLinesRef = useRef(null);
  //This one below is used to be displayed when hovering the asked target
  const horizontalLinesRef = useRef(null);
  const {
    marketDataPoints,
    itemsDataPoints,
    xScale,
    yScale,
    linesColor = colors.uiColor700,
    secondaryLinesColor = colors.uiColor800,
    graphWidth,
    graphHeight,
    horizontal,
    yAxisValues,
  } = props;

  useEffect(() => {
    if (horizontal) {
      select(fixedHorizontalLinesRef.current)
        .selectAll(`.${GraphClasses.FixedHorizontalLine}`)
        .data(yAxisValues!)
        .join('line')
        .attr('class', GraphClasses.FixedHorizontalLine)
        .attr('x1', -X_AXIS_PADDING)
        .attr('y1', (d: number) => yScale(d))
        .attr('x2', graphWidth + graphDimensions.MARGINS.right - 10)
        .attr('y2', (d: number) => yScale(d))
        .attr('fill', 'transparent')
        .attr('stroke-width', 1)
        .attr('stroke', linesColor);
    }

    const verticalLinesConfigs = [
      {
        getId: getMarketVerticalLineId,
        class: GraphClasses.VerticalLine,
        data: marketDataPoints,
        lineColor: (d: IBubbleDataPoint) =>
          d.quarter === 1 ? linesColor : secondaryLinesColor,
        strokeOpacity: () => 1,
      },
      {
        getId: getLeaseVerticalLineId,
        class: GraphClasses.VerticalLineLease,
        data: itemsDataPoints,
        lineColor: () => linesColor,
        strokeOpacity: (_d: IBubbleDataPoint) => 0,
      },
    ];

    verticalLinesConfigs.forEach(config => {
      select(verticalLinesRef.current)
        .selectAll(`.${config.class}`)
        .data(config.data)
        .join('line')
        .attr('class', config.class)
        .attr('id', config.getId)
        .attr('x1', (d: IBubbleDataPoint) => xScale(d.date))
        .attr('y1', graphHeight)
        .attr('x2', (d: IBubbleDataPoint) => xScale(d.date))
        .attr('y2', 0)
        .attr('fill', 'transparent')
        .attr('stroke-width', 1)
        .attr('stroke', config.lineColor)
        .attr('stroke-opacity', config.strokeOpacity);

      if (horizontal) {
        select(verticalLinesRef.current)
          .selectAll(`.${config.class}`)
          .style('display', 'none');
      }
    });

    select(horizontalLinesRef.current)
      .selectAll(`.${GraphClasses.HorizontalLine}`)
      .data(
        itemsDataPoints.filter(
          (d: IBubbleDataPoint) => d.value != null && d.value > 0,
        ),
      )
      .join('line')
      .attr('class', GraphClasses.HorizontalLine)
      .attr('id', getHorizontalLineId)
      .attr('x1', -X_AXIS_PADDING)
      .attr('y1', (d: IBubbleDataPoint) => yScale(d.value!))
      .attr('x2', graphWidth + graphDimensions.MARGINS.right)
      .attr('y2', (d: IBubbleDataPoint) => yScale(d.value!))
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('stroke', linesColor)
      .style('display', 'none');

    // eslint-disable-next-line
  }, [marketDataPoints, itemsDataPoints]);

  return (
    <g>
      <g ref={fixedHorizontalLinesRef} />
      <g ref={verticalLinesRef} />
      <g ref={horizontalLinesRef} />
    </g>
  );
};

export default BackgroundLines;
