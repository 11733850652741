import React, { ReactNode } from 'react';
import PageModal from 'components/PageModal/index';

type Props = {
  children?: ReactNode;
  onClose: () => void;
  title: string;
};

const HistoricalPerformanceModal: React.FC<Props> = ({
  children,
  onClose,
  title,
}) => (
  <PageModal title={title} onClose={onClose}>
    {children}
  </PageModal>
);

export default HistoricalPerformanceModal;
