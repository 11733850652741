import { colors } from 'constants/colors';

export const DEFAULT_ZOOM = 13;
export const MIN_TIMEOUT = 300;

export const MARKET_BOUNDARIES = 'market_boundaries';
export const MARKET_LAYER = 'market_layer';
export const MARKET_BORDER = 'market_border';

export const BOUNDARIES_STYLE = {
  'fill-color': colors.ayPureWhiteColor,
  'fill-opacity': 0.3,
};

export const BOUNDARIES_BORDER_STYLE = {
  'line-color': colors.ayPureWhiteColor,
  'line-width': 2,
};
