import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { UnitsOfMeasurementModelType } from 'interfaces/unitOfMeasurement';

export const DATA_CENTER_MAPPING: UnitsOfMeasurementModelType[] = [
  {
    key: 'underConstSf',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'underConstSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'underConstSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'underConstMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'plannedSf',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'plannedSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'plannedSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'plannedMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'commissionedSf',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'commissionedSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'commissionedSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'commissionedMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'raisedFloorHeight',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'raisedFloorHeight',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'raisedFloorHeight',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'raisedFloorHeightMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
  {
    key: 'typicalCeilingHeightFeet',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'typicalCeilingHeightFeet',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'typicalCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'typicalCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
  {
    key: 'typicalCeilingHeightInches',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'typicalCeilingHeightInches',
        unit: UnitOfMeasurement.inches,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'typicalCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'typicalCeilingHeightMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
];
