import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import styles from './DonutsCharts.module.scss';
import { formatNumberPercentage } from 'utils/formatters/number';
import { IPropertyAnalyticsDonutItem } from 'interfaces/IPropertyAnalyticsDonutData';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { camelCase, orderBy } from 'lodash';
import {
  PropertyDonutsChartsIDs,
  tagsOrder,
} from 'components/Graphs/PropertyDonutsCharts/constants';
import { PropertyDonutChartData } from 'components/Graphs/PropertyDonutsCharts';
import { TranslateFunction, translateText } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_ENUMERATORS,
  I18N_AVANT_PROPERTY_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { formatAndAbbreviateArea } from 'utils/formatters/area';

const getDefaultDonutData = (
  donutData: IPropertyAnalyticsDonutItem[],
  unitOfMeasurement: string,
  t?: TranslateFunction,
  translationPath?: string,
): IDonutGraphData | undefined => {
  if (!donutData?.length) return undefined;

  const top2 = orderBy(donutData, 'size', 'desc')
    ?.map(
      slice =>
        `${formatNumberPercentage(slice.percentage, 0)} ${
          t ? t(`${translationPath}.${camelCase(slice.text)}`) : slice.text
        }`,
    )
    .slice(0, 2);

  return {
    tags: top2,
    totalSize: 100,
    unitOfMeasurement,
    titleClassName: styles['donut-tag'],
  };
};

const getGraphData = (
  donutData: IPropertyAnalyticsDonutItem[],
  unitOfMeasurement: string,
  popupTitle: string,
  t?: TranslateFunction,
  translationPath?: string,
): IDonutGraphData[] | undefined => {
  const isValid = donutData?.some(slice => !!slice.text);
  if (!donutData?.length || !isValid) return undefined;

  const sizeFieldName =
    unitOfMeasurement === UnitOfMeasurement.sf ? 'size' : 'sizeMt';

  return donutData?.map(slice => {
    return {
      tags: [
        `${formatNumberPercentage(slice.percentage, 0)}`,
        formatAndAbbreviateArea(slice[sizeFieldName], unitOfMeasurement),
      ],
      title: t ? t(`${translationPath}.${camelCase(slice.text)}`) : slice.text,
      totalSize: slice.percentage || 0,
      order: tagsOrder[slice.text],
      propsToClick: { propertiesIds: slice.topItems, popupTitle },
      unitOfMeasurement,
    };
  });
};

export const getDonutsData = (
  data: any,
  unitOfMeasurement: string,
  t?: TranslateFunction,
): PropertyDonutChartData[] => {
  const {
    propertyClassesData,
    isLoadingPropertyClassesData,
    propertySubtypesData,
    isLoadingPropertySubtypesData,
    propertyAgesData,
    isLoadingPropertyAgesData,
    propertySizesData,
    isLoadingPropertySizesData,
  } = data;

  const propertyClassTitle = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.propertyClass`,
  );
  const propertyClasses = {
    graphData: getGraphData(
      propertyClassesData?.propertiesAnalyticsPropertyClasses?.classItems,
      unitOfMeasurement,
      propertyClassTitle,
    ),
    defaultData: getDefaultDonutData(
      propertyClassesData?.propertiesAnalyticsPropertyClasses?.parentClassItems,
      unitOfMeasurement,
    ),
    loading: isLoadingPropertyClassesData,
    id: PropertyDonutsChartsIDs.DonutPropertyClass,
    title: propertyClassTitle,
  };

  const propertySubtypeTitle = translateText(
    `${I18N_AVANT_PROPERTY_HEADINGS_PATH}.propertySubtype`,
  );
  const propertySubtypes = {
    graphData: getGraphData(
      propertySubtypesData?.propertiesAnalyticsPropertySubtypes?.items,
      unitOfMeasurement,
      propertySubtypeTitle,
      t,
      `${I18N_AVANT_PROPERTY_ENUMERATORS}.propertySubtypes`,
    ),
    defaultData: getDefaultDonutData(
      propertySubtypesData?.propertiesAnalyticsPropertySubtypes?.items,
      unitOfMeasurement,
      t,
      `${I18N_AVANT_PROPERTY_ENUMERATORS}.propertySubtypes`,
    ),
    loading: isLoadingPropertySubtypesData,
    id: PropertyDonutsChartsIDs.DonutPropertySubtype,
    title: propertySubtypeTitle,
  };

  const propertyAgeTitle = translateText(
    `${I18N_AVANT_PROPERTY_HEADINGS_PATH}.propertyAge`,
  );
  const propertyAges = {
    graphData: getGraphData(
      propertyAgesData?.propertiesAnalyticsPropertyAges?.slices,
      unitOfMeasurement,
      propertyAgeTitle,
    ),
    defaultData: getDefaultDonutData(
      propertyAgesData?.propertiesAnalyticsPropertyAges?.slices,
      unitOfMeasurement,
    ),
    loading: isLoadingPropertyAgesData,
    id: PropertyDonutsChartsIDs.DonutPropertyAge,
    title: propertyAgeTitle,
  };

  const propertySizeTitle = translateText(
    `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.propertySize`,
  );
  const propertySizes = {
    graphData: getGraphData(
      propertySizesData?.propertiesAnalyticsPropertySizes?.items,
      unitOfMeasurement,
      propertySizeTitle,
    ),
    defaultData: getDefaultDonutData(
      propertySizesData?.propertiesAnalyticsPropertySizes?.items,
      unitOfMeasurement,
    ),
    loading: isLoadingPropertySizesData,
    id: PropertyDonutsChartsIDs.DonutPropertySize,
    title: propertySizeTitle,
  };

  return [propertyClasses, propertySubtypes, propertyAges, propertySizes];
};
