import React, { useEffect, useRef, useState } from 'react';
import styles from './OptionsContainer.module.scss';
import classnames from 'classnames';
import Icon from 'components/Icon';
import { colors } from 'constants/colors';
import { ColorNames } from 'constants/colorNames';
import { ICON_COLORS } from 'components/Button/new/icon-colors';

export interface IOption {
  type: string;
  label: string;
  onClick: () => void;
  style?: string;
  shouldShowIcon?: boolean;
  iconColor?: string;
  optionColorName?: ColorNames;
}

export type OptionContainerStyle =
  | 'main'
  | 'contextual'
  | 'contextual-light'
  | 'contextual-positive'
  | 'contextual-transparent';

interface Props {
  customButtonStyle?: string;
  isDark?: boolean;
  isLeftAlign?: boolean;
  isLightButton?: boolean;
  isMedium?: boolean;
  isSmall?: boolean;
  optionButtonIcon?: string;
  optionButtonInitialColor?: string;
  optionButtonLabel?: string;
  optionContainerStyle?: OptionContainerStyle;
  options?: IOption[];
  optionsWrapperClassName?: string;
  optionsIconClassName?: string;
  testId?: string;
  wrapperClassName?: string;
  customOptions?: JSX.Element[];
  disabled?: boolean;
  optionClassName?: string;
}

const DEFAULT_OPTION_CONTAINER_STYLE = 'contextual-light';

const OptionsContainer: React.FC<Props> = ({
  customButtonStyle,
  isDark,
  isLeftAlign,
  isLightButton,
  isMedium,
  isSmall,
  optionButtonIcon,
  optionButtonInitialColor,
  optionButtonLabel,
  optionContainerStyle,
  optionsIconClassName,
  options,
  optionsWrapperClassName,
  testId,
  wrapperClassName,
  customOptions,
  disabled,
  optionClassName,
}) => {
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  const containerStyle =
    styles[optionContainerStyle || DEFAULT_OPTION_CONTAINER_STYLE];

  useEffect(() => {
    const handleClickOutsideOptions = (event: any) => {
      if (
        optionsContainerRef.current &&
        !optionsContainerRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideOptions);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideOptions);
    };
  }, [optionsContainerRef]);

  const getIconColor = (customIdleColor?: string) => {
    const { idle, active } = ICON_COLORS[
      optionContainerStyle || DEFAULT_OPTION_CONTAINER_STYLE
    ];
    return showOptions && optionContainerStyle !== 'contextual-transparent'
      ? active
      : customIdleColor
      ? customIdleColor
      : idle;
  };

  const renderOptions = () =>
    customOptions ||
    options?.map(option => (
      <button
        key={option.label}
        className={classnames(
          styles.option,
          containerStyle,
          option.style,
          optionClassName,
          [
            styles[
              `option-${option.optionColorName || ColorNames.primaryColor400}`
            ],
          ],
        )}
        onClick={() => {
          setShowOptions(false);
          option.onClick();
        }}
      >
        {option.label}
        {option?.shouldShowIcon && (
          <Icon
            name={option.type}
            size={1}
            color={option.iconColor || colors.primaryColor400}
          />
        )}
      </button>
    )) ||
    null;

  return (
    <div className={classnames(styles['options-container'], wrapperClassName)}>
      <div
        data-testid={testId}
        className={classnames(
          styles['options-button'],
          customButtonStyle,
          containerStyle,
          {
            [styles['active']]: showOptions,
            [styles['small']]: isSmall,
            [styles['medium']]: isMedium,
            [styles['dark']]: isDark,
            [styles['light-button']]: isLightButton,
            [styles['disabled']]: disabled,
          },
        )}
        role="button"
        onClick={() => !disabled && setShowOptions(true)}
      >
        {optionButtonIcon && (
          <div
            className={classnames(
              styles['options-button-icon-container'],
              optionsIconClassName,
            )}
          >
            <Icon
              name={optionButtonIcon}
              color={getIconColor(optionButtonInitialColor)}
            />
          </div>
        )}
        {optionButtonLabel}
      </div>
      <div
        ref={optionsContainerRef}
        className={classnames(
          styles.options,
          containerStyle,
          {
            [styles.show]: showOptions,
            [styles.left]: isLeftAlign,
          },
          optionsWrapperClassName,
        )}
      >
        {renderOptions()}
      </div>
    </div>
  );
};

export default OptionsContainer;
