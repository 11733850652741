import React, { useContext, useEffect, useMemo } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isEqual } from 'lodash';
import {
  CompSetOrderContext,
  ICompSetOrder,
} from 'contexts/CompSetOrderContext';
import styles from './DragAndDrop.module.scss';
import { DraggableItem, Item } from './DraggableItem';

interface onSortEndProps {
  oldIndex: number;
  newIndex: number;
}

interface DragAndDropProps<T extends Item> {
  items: T[];
  renderItem: (value: T, index: number) => React.ReactElement;
  saveContextOrderType?: keyof ICompSetOrder;
}
export const DragAndDrop = <T extends Item>({
  items,
  renderItem,
  saveContextOrderType = 'compSet',
}: DragAndDropProps<T>) => {
  const [elements, setElements] = React.useState(items);
  const { setCompSetOrder } = useContext(CompSetOrderContext);

  const orderedItems = useMemo(() => {
    return elements.map((i, idx) => ({
      propertyId: i.id as number,
      order: idx,
    }));
  }, [elements]);

  const onSortEnd = ({ oldIndex, newIndex }: onSortEndProps) => {
    const newSortedItems = arrayMove(elements, oldIndex, newIndex);
    if (isEqual(items, newSortedItems)) return;
    setElements(arrayMove(elements, oldIndex, newIndex));
  };

  // rerender on adding/removing items
  useEffect(() => {
    const itemsLengthChanged = elements.length !== items.length;
    if (itemsLengthChanged) {
      setElements(items);
    }
  }, [elements.length, items, items.length]);

  useEffect(() => {
    if (!orderedItems.length) return;
    setCompSetOrder((prevCompSetOrder: ICompSetOrder) => ({
      ...prevCompSetOrder,
      [saveContextOrderType]: orderedItems,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedItems]);

  const SortableList = SortableContainer((props: any) => {
    return (
      <div className={styles.container}>
        <div className={styles['items-list']}>
          {elements.map((item, index) => (
            <DraggableItem
              key={`item-${item.id}`}
              index={index}
              value={item}
              renderItem={renderItem}
              {...props}
            />
          ))}
        </div>
      </div>
    );
  });

  return <SortableList useDragHandle={true} axis="xy" onSortEnd={onSortEnd} />;
};
