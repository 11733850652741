import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CompType } from 'constants/compType';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { MediaTabNames } from 'constants/media';
import ModalMedia from 'components/ModalMedia';
import ModalEditComp from 'components/ModalEditComp';
import { getMediaConfigForProperty } from 'components/ModalMedia/utils';
import OptionsContainer from 'components/OptionsContainer';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { IProperty } from 'interfaces/IProperty';

import styles from '../ComparativeColumns.module.scss';
import { getAddDataOptions } from './utils';

type Props = {
  property: IProperty;
  onDataChanges?: () => void;
};

const AddDataButton: React.FC<Props> = ({ property, onDataChanges }) => {
  const history = useHistory();
  const { hasEditorAccess } = useContext(subscriptionsContext);

  enum MODALS {
    MEDIA = 'media-modal',
    EDIT_PROPERTY = 'edit-property-modal',
  }
  const [activeModal, setActiveModal] = useState<MODALS | null>(null);

  const closeActiveModal = () => setActiveModal(null);

  const isModalActive = (modal: MODALS) => activeModal === modal;

  const addDataOptions = getAddDataOptions(
    property,
    history,
    () => setActiveModal(MODALS.EDIT_PROPERTY),
    () => setActiveModal(MODALS.MEDIA),
  );

  return (
    <>
      {hasEditorAccess ? (
        <OptionsContainer
          testId={'add-data-button'}
          isSmall
          isLightButton
          options={addDataOptions}
          optionContainerStyle="contextual-positive"
          wrapperClassName={styles['add-data-button']}
          optionButtonLabel="···"
        />
      ) : null}
      {isModalActive(MODALS.MEDIA) && (
        <ModalMedia
          activeTab={MediaTabNames.pictures}
          onCloseModal={closeActiveModal}
          queryConfig={getMediaConfigForProperty(property)}
          onDataChanges={onDataChanges}
          profileType={PROFILE_TYPES.PROPERTY}
        />
      )}
      {isModalActive(MODALS.EDIT_PROPERTY) && property.id && (
        <ModalEditComp
          type={CompType.property}
          compId={property.id}
          onModalToggle={closeActiveModal}
          onUpdate={onDataChanges}
        />
      )}
    </>
  );
};

export default AddDataButton;
