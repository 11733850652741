import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Text } from 'components/@codelitt/ay-design-library';
import AYLink from 'components/AYLink';
import { DOT_LG } from 'constants/placeholders';
import { IMarket } from 'interfaces/IMarket';
import { IProperty } from 'interfaces/IProperty';
import { IScoop } from 'interfaces/IScoop';
import React, { ComponentProps } from 'react';
import locations from 'routes';
import { formatPropertyClass } from 'utils/formatters/property';
import { formatPropertyStatus } from 'utils/formatters/propertyStatus';
import Tag from '../../Tag';
import { TypeOfCard } from '../../utils';
import MapImage from '../MapImage';
import MultipleLink from '../MultipleLink';
import styles from './PropertyHeader.module.scss';
import { ILease } from 'interfaces/ILease';

interface Props {
  lease?: ILease;
  scoop?: IScoop;
  market?: IMarket;
  property?: IProperty;
  type?: TypeOfCard;
  hideMarketLine?: boolean;
  hideBuildingAttributes?: boolean;
  tagLabel?: string;
  tagClassName?: string;
}

const PropertyHeader: React.FC<Props> = ({
  property,
  type,
  hideMarketLine,
  hideBuildingAttributes,
  market,
  scoop,
  tagLabel,
  tagClassName,
}) => {
  const isCarousel = type === TypeOfCard.CAROUSEL;
  const statusClassTypeText = [
    formatPropertyStatus(property?.status),
    property?.propertyType?.name,
    property?.propertySubtype?.name,
    property?.propertySubtypeDetail?.name,
    formatPropertyClass(property?.propertyClass),
  ].filter(Boolean);

  const propertyMarkets = [
    property?.market?.name && (
      <AYLink
        wrapperClassName={styles['header-link']}
        key="header-market"
        to={locations.showMarket(property?.market?.id)}
      >
        {property?.market?.name}
      </AYLink>
    ),
    property?.submarket?.name && (
      <AYLink
        wrapperClassName={styles['header-link']}
        key="header-submarket"
        to={locations.showSubmarket(
          property?.submarket?.id,
          property?.propertyType?.id,
        )}
      >
        {property?.submarket?.name}
      </AYLink>
    ),
    property?.micromarket?.name && (
      <Text
        key="header-micromarket"
        type="body-content"
        value={property?.micromarket?.name}
      />
    ),
  ].filter(Boolean);

  const renderTitle = () => (
    <div className={styles['property-title']}>
      <MultipleLink
        market={market}
        property={property}
        scoop={scoop}
        small={isCarousel}
      />
    </div>
  );

  const renderCategories = () => {
    const tagPropArray: Array<Pick<
      ComponentProps<typeof Tag>,
      'label' | 'wrapperClassName'
    > & { key?: string }> = [];

    if (tagLabel) {
      tagPropArray.push({
        label: tagLabel,
        wrapperClassName: classNames(styles.tag, tagClassName, {
          [styles.share]: type == TypeOfCard.SHARE,
        }),
      });
    } else if (scoop) {
      scoop?.scoopCategories?.map(category => {
        tagPropArray.push({
          key: category.id,
          label: category.name,
          wrapperClassName: classNames(styles.tag, {
            [styles.share]: type == TypeOfCard.SHARE,
          }),
        });
      });
    }

    return tagPropArray.map((tagProps, index) => (
      <Tag
        key={tagProps.key || index}
        label={tagProps.label}
        wrapperClassName={tagProps.wrapperClassName}
      />
    ));
  };

  const renderImage = () => {
    if (type === TypeOfCard.CAROUSEL_PROPERTY) return null;
    return (
      <MapImage market={market} property={property} isSmall={isCarousel} />
    );
  };

  const renderPropertyName = () => {
    if (!property?.name) return null;
    return (
      <div className={classNames(styles['property-info-wrapper'])}>
        <p className={styles['property-info']}>{property?.name}</p>
      </div>
    );
  };

  const renderMarketLine = () => {
    if (hideMarketLine || !propertyMarkets) return null;
    return (
      <div className={classNames(styles['property-info-wrapper'])}>
        <p className={styles['property-info']}>
          {propertyMarkets.map((item, index) => (
            <React.Fragment key={index}>
              {!!index && (
                <Text
                  wrapperClassName={styles['property-info-separator']}
                  type="body-content"
                  value={DOT_LG}
                />
              )}
              {item}
            </React.Fragment>
          ))}
        </p>
      </div>
    );
  };

  const renderPropertyStatuses = () => {
    if (
      hideBuildingAttributes ||
      !statusClassTypeText ||
      isEmpty(statusClassTypeText)
    ) {
      return null;
    }

    return (
      <div
        className={classNames(
          styles['property-info'],
          styles['property-building-info'],
        )}
      >
        {statusClassTypeText.map((item, index) => (
          <React.Fragment key={index}>
            {!!index && (
              <Text
                wrapperClassName={styles['property-info-separator']}
                type="body-content"
                value={` ${DOT_LG} `}
              />
            )}
            {item}
          </React.Fragment>
        ))}
      </div>
    );
  };

  if (isCarousel)
    return (
      <div className={styles['container-in-carousel']}>
        <div className={styles['carousel-title-container']}>
          {renderImage()}
          <div className={styles['activity-property-info']}>
            {renderTitle()}
            {renderPropertyName()}
          </div>
        </div>
        <div className={styles['header-container']}>
          {renderMarketLine()}
          <div
            className={classNames(
              styles['property-info-wrapper'],
              styles['container-category'],
            )}
          >
            <>
              {renderPropertyStatuses()}
              {renderCategories()}
            </>
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      {renderImage()}
      <div className={styles['activity-property-info']}>
        {renderTitle()}
        <div className={styles['header-container']}>
          {renderPropertyName()}
          {renderMarketLine()}
          <div
            className={classNames(
              styles['property-info-wrapper'],
              styles['container-category'],
            )}
          >
            <>
              {renderPropertyStatuses()}
              {renderCategories()}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyHeader;
