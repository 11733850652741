import React from 'react';
import { DocumentNode, OperationVariables, useQuery } from '@apollo/client';
import OptionsFilter from '../OptionsFilter';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { IdName } from 'interfaces/IdName';
import { Size } from 'components/Inputs/ChoiceInput';

type Props = {
  label?: string;
  query: DocumentNode;
  queryParser: (data: any) => SearchOptionsType[];
  transformVisibleOptions?: (data: SearchOptionsType[]) => SearchOptionsType[];
  variables?: OperationVariables;
  isDisabled?: boolean;
  isMultipleSelect?: boolean;
  onChangeSingle?: (newValue?: IdName) => void;
  onChangeMultiple?: (
    newValue: IdName[],
    allOptions: SearchOptionsType[],
  ) => void;
  selectedItem?: SearchOptionsType;
  selectedItems?: SearchOptionsType[];
  hasHeaderLabel?: boolean;
  choiceInputSize?: Size;
  itemWrapperClassName?: string;
  haveItemsBackground?: boolean;
  isLabelMultiLine?: boolean;
};

const RemoteDataFilter: React.FC<Props> = (props: Props) => {
  const { loading, data } = useQuery(props.query, {
    variables: props.variables,
  });

  if (loading) return null;

  const parsedValues = props.queryParser(data);
  let visibleOptions = parsedValues;
  if (props.transformVisibleOptions) {
    visibleOptions = props.transformVisibleOptions(parsedValues);
  }

  if (!visibleOptions?.length) return null;

  return (
    <OptionsFilter
      allOptions={parsedValues}
      isLabelMultiLine={props.isLabelMultiLine}
      options={visibleOptions}
      isDisabled={props.isDisabled}
      choiceInputSize={props.choiceInputSize}
      haveItemsBackground={props.haveItemsBackground}
      itemWrapperClassName={props.itemWrapperClassName}
      {...props}
    />
  );
};

export default RemoteDataFilter;
