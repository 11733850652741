import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import MapboxMap from 'components/MapboxMap';
import MapLoader from 'components/MapboxMap/MapLoader';
import { useMarketBoundaries } from 'components/PropertiesMap/useMarketBoundaries';
import {
  generateHeatMapData,
  ICoords,
  loadPropertiesPinsOnMap,
} from 'components/MapboxMap/utils';
import { IPropertySearchFilters } from 'interfaces/IPropertySearch';
import { ILeaseSearchFilters } from 'interfaces/ILeasesSearch';
import { ISaleSearchFilters } from 'interfaces/ISalesSearch';
import {
  DEFAULT_PIN_COLOR,
  getMapCenterLatLong,
  TARGET_PIN_COLOR,
} from 'utils/maps/mapBox';
import useHeatmapControls from 'components/MapboxMap/useMapboxOptions';
import { HeatMap } from 'components/MapboxMap/constants';
import { GET_PROPERTIES_GEOLOCATION_QUERY } from 'graphql/property';
import {
  IPropertiesGeolocation,
  IPropertyGeolocation,
} from 'interfaces/IPropertiesGeolocation';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import { IProperty } from 'interfaces/IProperty';
import { ISearchOrder } from 'interfaces/ISearch';
import { loadLayersForPerformanceMode } from './utils';
import styles from './PropertiesMap.module.scss';
import {
  DEFAULT_MAP_STATE,
  DEFAULT_ZOOM,
  PROPERTIES_PAGE_SIZE,
  PROPERTY_THRESHOLD_PERFORMANCE_MODE,
} from './constants';

export enum PropertyMapMode {
  Default,
  Performance,
}

export interface Props {
  leasesFilter?: ILeaseSearchFilters;
  mapZoom?: number;
  onClickPropertyPin?: (propertyId: string | null) => void;
  propertiesFilter?: IPropertySearchFilters;
  salesFilter?: ISaleSearchFilters;
  showHeatMapControls?: boolean;
  showMapControls?: boolean;
  pinLabelFn?: (properties: IProperty[], propertyId: number) => number;
  isPinOpaque?: boolean;
  onMouseEnterPropertyPin?: (propertyId?: number) => void;
  propertyIdHover?: number;
  hoverPinColor?: string;
  targetPropertyIds?: number[];
  order?: ISearchOrder;
  mode?: PropertyMapMode;
  onSortGeoProperties?: (
    geoProperties: IPropertyGeolocation[],
  ) => IPropertyGeolocation[];
}

interface IPropertiesMapStatus {
  isReadyToDrawPins?: boolean;
  mapCenterLngLat?: ICoords;
}

const PropertiesMap: React.FC<Props> = ({
  leasesFilter,
  mapZoom = DEFAULT_ZOOM,
  onClickPropertyPin,
  propertiesFilter: filter,
  salesFilter,
  showHeatMapControls = false,
  showMapControls = true,
  pinLabelFn,
  isPinOpaque,
  onMouseEnterPropertyPin,
  propertyIdHover,
  hoverPinColor,
  targetPropertyIds,
  order,
  mode = PropertyMapMode.Default,
  onSortGeoProperties,
}) => {
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const client = useApolloClient();

  const [propertiesGeoData, setPropertiesGeoData] = useState<
    IPropertyGeolocation[]
  >([]);
  const [isLoadingProperties, setIsLoadingProperties] = useState(true);
  const [isDefaultMarketLoaded, setIsDefaultMarketLoaded] = useState(false);
  const [mapStatus, setMapStatus] = useState<IPropertiesMapStatus>(
    DEFAULT_MAP_STATE,
  );
  const { heatmapState, setHeatmapState } = useHeatmapControls();

  const { defaultBoundaries } = useMarketBoundaries(filter?.markets?.[0].id);

  // This function will load all properties recursivelly in batches of 500 (@see PROPERTIES_PAGE_SIZE variable)
  const loadPropertiesCoordinates = async (
    currentPage = 1,
    currentItems: IPropertyGeolocation[] = [],
  ) => {
    const { data } = await client.query<{
      properties: IPropertiesGeolocation;
    }>({
      query: GET_PROPERTIES_GEOLOCATION_QUERY,
      fetchPolicy: 'cache-first',
      variables: {
        search: {
          filter,
          leasesFilter,
          salesFilter,
          page: {
            page: currentPage,
            size: PROPERTIES_PAGE_SIZE,
          },
          order,
        },
      },
    });

    const newItems = data?.properties?.results || [];
    const allItems =
      onSortGeoProperties instanceof Function
        ? onSortGeoProperties([...currentItems, ...newItems])
        : [...currentItems, ...newItems];
    if (newItems.length < PROPERTIES_PAGE_SIZE) {
      setPropertiesGeoData(allItems);
      setIsLoadingProperties(false);
      return;
    } else {
      await loadPropertiesCoordinates(currentPage + 1, allItems);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(loadPropertiesCoordinates);
    return () => {
      timeout && clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, leasesFilter, salesFilter]);

  const hasProperties = propertiesGeoData.length > 0;

  const setDefaultCenterByMarketBoundaries = useCallback(() => {
    if (defaultBoundaries?.submarketsBoundaries) {
      const marketGeometry = defaultBoundaries?.submarketsBoundaries[0];
      if (marketGeometry?.geometry?.coordinates) {
        const centerLatLng = getMapCenterLatLong(marketGeometry?.geometry);
        if (centerLatLng.lat && centerLatLng.lng) {
          setMapStatus({
            mapCenterLngLat: {
              latitude: centerLatLng.lat,
              longitude: centerLatLng.lng,
            },
          });
          setIsDefaultMarketLoaded(true);
        }
      }
    }
  }, [defaultBoundaries]);

  const loadHeatmapData = useCallback(() => {
    generateHeatMapData(mapRef.current!, propertiesGeoData);
    setHeatmapState({ ...heatmapState, heatmapSelectedOption: HeatMap.NONE });
    // eslint-disable-next-line
  }, [propertiesGeoData]);

  const handleLoad = async (map: mapboxgl.Map) => {
    const numberOfProperties = propertiesGeoData.length;

    if (!numberOfProperties) {
      return;
    }

    mapRef.current = map;
    map.on('load', () => {
      setDefaultCenterByMarketBoundaries();
      if (
        mode === PropertyMapMode.Performance &&
        numberOfProperties > PROPERTY_THRESHOLD_PERFORMANCE_MODE
      ) {
        loadLayersForPerformanceMode(
          mapRef.current!,
          propertiesGeoData,
          onClickPropertyPin,
          onMouseEnterPropertyPin,
        );
      } else {
        loadPropertiesPinsOnMap(
          mapRef.current!,
          propertiesGeoData,
          onClickPropertyPin,
          pinLabelFn,
          isPinOpaque,
          onMouseEnterPropertyPin,
          targetPropertyIds,
        );
      }

      if (showHeatMapControls) {
        loadHeatmapData();
      }
    });
  };

  const changeColorOfPin = (
    color: string,
    opacity: string,
    pin?: Element | null,
    marker?: Element | null,
  ) => {
    if (pin && marker) {
      pin.setAttribute('fill', color);
      const innerCircles = marker?.getElementsByTagName('circle');
      for (let i = 0; i < innerCircles.length; i++) {
        innerCircles[i].style.fillOpacity = opacity;
      }
    }
  };

  useEffect(() => {
    const containerMap = mapRef.current?.getContainer();
    const markers = containerMap?.getElementsByClassName('mapboxgl-marker');

    if (markers) {
      for (let i = 0; i < markers.length; i++) {
        const marker = markers.item(i);
        const propertyIdAttribute = Number(
          marker?.getAttribute('data-property-id'),
        );

        const isTargetProperty = targetPropertyIds?.includes(
          Number(propertyIdAttribute),
        );
        const pinColor = isTargetProperty
          ? TARGET_PIN_COLOR
          : DEFAULT_PIN_COLOR;

        if (propertyIdAttribute == propertyIdHover) {
          const pin = marker?.querySelector(`svg g[fill="${pinColor}"]`);
          hoverPinColor && changeColorOfPin(hoverPinColor, '0', pin, marker);
        } else {
          const pin = marker?.querySelector(`svg g[fill="${hoverPinColor}"]`);
          changeColorOfPin(pinColor, '1', pin, marker);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyIdHover]);

  useEffect(() => {
    if (hasProperties && !isDefaultMarketLoaded) {
      setMapStatus({
        mapCenterLngLat: {
          latitude: propertiesGeoData?.[0]?.latitude || null,
          longitude: propertiesGeoData?.[0]?.longitude || null,
        },
      });
    }
  }, [propertiesGeoData, isDefaultMarketLoaded, hasProperties]);

  return (
    <Row>
      <Col>
        <MapboxMap
          loadMap={handleLoad}
          pinIds={propertiesGeoData?.map(p => p.id!) || []}
          isCenterMarkerVisible={false}
          mapZoom={mapZoom}
          centerCoordinate={mapStatus.mapCenterLngLat}
          showControls={showMapControls}
          showHeatMapControls={showHeatMapControls}
          wrapperClassName={styles.mapbox}
          heatmapState={heatmapState}
          setHeatmapState={setHeatmapState}
        >
          {isLoadingProperties && (
            <MapLoader
              text={translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.loadingProperties`,
              )}
            />
          )}
        </MapboxMap>
      </Col>
    </Row>
  );
};

export default PropertiesMap;
