import { gql } from '@apollo/client';
import { PROPERTY_BASIC_DATA_FRAGMENT } from 'graphql/property/fragments';
import { SCOOPS_BASIC_RESULTS_DATA_FRAGMENT } from 'graphql/scoops/fragments';

export const GET_SCOOPS_QUERY = gql`
  query getScoops($search: ScoopSearchInput) {
    scoops(search: $search) {
      totalPages
      currentPage
      results {
        ...scoopsBasicResultsData
      }
    }
  }
  ${SCOOPS_BASIC_RESULTS_DATA_FRAGMENT}
`;

export const GET_SCOOPS_WITH_PROPERTY_DATA_QUERY = gql`
  query getScoops($search: ScoopSearchInput) {
    scoops(search: $search) {
      totalPages
      currentPage
      results {
        ...scoopsBasicResultsData
        properties {
          ...propertyBasicDataFragment
        }
      }
    }
  }
  ${PROPERTY_BASIC_DATA_FRAGMENT}
  ${SCOOPS_BASIC_RESULTS_DATA_FRAGMENT}
`;

export const GET_SCOOP_CATEGORIES_QUERY = gql`
  query scoopCategories {
    scoopCategories {
      id
      name
      type
    }
  }
`;

export const GET_SCOOP_COMMENTS_QUERY = gql`
  query scoopComments($scoopId: Int!) {
    scoopComments(scoopId: $scoopId) {
      id
      user {
        id
        email
        firstName
        lastName
      }
      userId
      scoopId
      comment
      createdAt
      updatedAt
    }
  }
`;

export const GET_SCOOP_MEDIA = gql`
  query getScoopMedia($scoopId: Int!) {
    mediaScoop(scoopId: $scoopId) {
      id
      type
      url
      width
      height
      category {
        id
        name
        attachmentType
      }
    }
  }
`;
