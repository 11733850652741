import { gql } from '@apollo/client';

export const MEDIA_FRAGMENT = gql`
  fragment mediaData on Media {
    id
    notes
    publicId
    url
    width
    height
    propertyId
    submarketId
    propertyTypeId
    type
    category {
      id
      name
    }
    subAmenity {
      id
      name
    }
    isCover
    createdAt
    updatedAt
  }
`;
