import React from 'react';
import styles from './RadioInput.module.scss';
import classnames from 'classnames';
import { ColorNames } from 'constants/colorNames';

interface Props {
  backgroundColor?: ColorNames;
  isSelected?: boolean;
  isDisabled?: boolean;
  isCategoryParent?: boolean;
  isUnderline?: boolean;
  label?: string;
  onClick: () => void;
  wrapperClassName?: string;
  child?: React.ReactNode;
  isMultiSelect?: boolean;
}

const RadioInputItem: React.FC<Props> = ({
  backgroundColor,
  isSelected,
  isDisabled,
  label,
  onClick,
  isCategoryParent,
  isUnderline,
  wrapperClassName,
  child,
  isMultiSelect,
}) => {
  return (
    <React.Fragment>
      <label
        className={classnames(styles['radio-item'], wrapperClassName, {
          [styles['radio-item-underline']]: isUnderline,
        })}
      >
        <input
          type="radio"
          defaultChecked={isSelected}
          className={styles.radio}
          onClick={onClick}
          disabled={isDisabled}
        />
        <span
          className={classnames(
            styles.checked,
            styles[`checked-background-${backgroundColor}`],
            {
              [styles['checked-visible']]: isSelected,
              [styles['checked-parent']]: isSelected && isCategoryParent,
              [styles['checked-multi']]: isMultiSelect,
            },
          )}
        />
        {isSelected && (
          <span
            className={classnames(
              styles.checkmark,
              styles['checkmark-visible'],
              {
                [styles['checkmark-parent']]: isSelected && isCategoryParent,
                [styles['checkmark-multi']]: isMultiSelect,
              },
            )}
          />
        )}
        {label && (
          <div
            className={classnames(styles.label, {
              [styles['label-checked']]: isSelected,
              [styles['label-multi']]: isMultiSelect,
            })}
          >
            {label}
          </div>
        )}
      </label>
      {child ? child : null}
    </React.Fragment>
  );
};

export default RadioInputItem;
