import { gql } from '@apollo/client';

export const DELETE_SALE_MUTATION = gql`
  mutation deleteSale($saleId: Int!) {
    deleteSale(saleId: $saleId)
  }
`;

export const UPDATE_SALE_MUTATION = gql`
  mutation updateSale($sale: SaleInput!) {
    updateSale(sale: $sale) {
      ... on Sale {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const CREATE_SALE_MUTATION = gql`
  mutation createSale($sale: SaleInput!) {
    createSale(sale: $sale) {
      ... on SaleId {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;
