import { colors } from 'constants/colors';

export const GRAPH_DIMENSIONS = {
  width: 352,
  height: 270,
  padding: 40,
  hoverStrokeWidth: 5,
};

const graphHorizontalMargin = 85;

export const BUBBLES = {
  fillOpacity: 0.4,
  fillOpacityActive: 1,
  fill: colors.ayPearGreenColor,
  strokeWidth: 12,
  strokeWidthOuter: 20,
};

export const TOOLTIP_LINE_STROKE = 2;

export enum Positions {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right',
  CLOSE_RIGHT = 'close-right',
  FOOTER = 'footer',
  TITLE = 'title',
}

interface IXCoordinate {
  [key: string]: number;
}

export const XCoordinates: IXCoordinate = {
  [Positions.LEFT]: graphHorizontalMargin,
  [Positions.MIDDLE]: GRAPH_DIMENSIONS.width / 2,
  [Positions.RIGHT]: GRAPH_DIMENSIONS.width - graphHorizontalMargin,
  [Positions.CLOSE_RIGHT]: GRAPH_DIMENSIONS.width / 2 + 32,
};
