import { ILease } from 'interfaces/ILease';
import {
  LeaseBaseRentTime,
  LeaseRentType,
  LeaseTransactionType,
  LeaseTypeEnum,
} from 'constants/leases';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_ENUMERATORS,
} from 'constants/i18n';

/**
 * // Single floor (Singular)
 * Value: 10, means the lease is at Floor number 10.
 *
 * // Range (Plural)
 * Value: 5-8, means the lease is using floors 5,6,7, and 8
 *
 * // Multiple (Plural)
 * Value: 1,3. means the lease is using floors 1 and 3
 *
 * @param lease
 */
export const hasSingleFloor = (lease?: ILease) =>
  lease?.floor && /^\d+$/.test(lease?.floor);

export const isMonthlyTimeMeasurement = (timeMeasurement?: string) =>
  timeMeasurement == LeaseBaseRentTime.Monthly;

export const isRentTypeNNN = (rentType?: string) =>
  rentType === LeaseRentType.NNN;

export const getTranslatedNNN = () =>
  translateText('avantPlatform.attributes.lease.label.rentTypeNNN');

export const getTranslatedFS = () =>
  translateText('avantPlatform.attributes.lease.label.rentTypeFS');

export const getTranslatedRentType = (rentType: string) => {
  switch (rentType) {
    case LeaseRentType.FS:
      return getTranslatedFS();
    case LeaseRentType.NNN:
      return getTranslatedNNN();
    default:
      return rentType;
  }
};

export const getTranslatedTransactionType = (transactionType: string) => {
  switch (transactionType) {
    case LeaseTransactionType.NEW:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.transactionTypes.new`,
      );
    case LeaseTransactionType.EXPANSION:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.transactionTypes.expansion`,
      );
    case LeaseTransactionType.RENEWAL:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.transactionTypes.renewal`,
      );
    default:
      return transactionType;
  }
};

export const getTranslatedLeaseType = (leaseType: string) => {
  switch (leaseType) {
    case LeaseTypeEnum.DIRECT:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.leaseTypes.direct`,
      );
    case LeaseTypeEnum.ASSIGNMENT:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.leaseTypes.assignment`,
      );
    case LeaseTypeEnum.COWORKING:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.leaseTypes.coworking`,
      );
    case LeaseTypeEnum.LICENSE:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.leaseTypes.license`,
      );
    case LeaseTypeEnum.SHELL:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.leaseTypes.shell`,
      );
    case LeaseTypeEnum.SUBLEASE:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.leaseTypes.sublease`,
      );
    case LeaseTypeEnum.TURN_KEY:
      return translateText(
        `${I18N_AVANT_PROPERTY_ENUMERATORS}.leaseTypes.turnKey`,
      );
    default:
      return leaseType;
  }
};

export const getTranslatedQuarter = () =>
  translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.byQuarter`);

export const getTranslatedAnnual = () =>
  translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.byYear`);
