export enum GraphIds {
  PropertyLineId = 'rhombus-property-line',
  PropertyLayerId = 'rhombus-property-layer',
  PropertyShadowId = 'rhombus-property-shadow',
  MarketLineId = 'rhombus-market-line',
  MarketShadowId = 'rhombus-market-shadow',
  MarketLayerId = 'rhombus-market-layer',
  ShadowLayerId = 'rhombus-shadow-layer',
  ContainerLayerId = 'rhombus-container',
  ContainerTitle = 'rhombus-title-container',
  ContainerFooter = 'rhombus-footer-container',
}
