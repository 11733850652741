import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { IdName } from 'interfaces/IdName';
import { getCurrencySymbol } from 'utils/formatters/currency';
import {
  getFieldValueForAvailability,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import { formatArea } from 'utils/formatters/area';
import { AvailabilityFields, IAvailability } from 'interfaces/IAvailability';
import { formatNumberWithCommas } from '../../utils/formatters/number';
import { TIME_MEASUREMENTS_LABELS } from 'constants/availabilities';

export const getAvailabilityFormattedField = (
  field: AvailabilityFields,
  availability: IAvailability,
): string | null => {
  const {
    askingRentMin,
    askingRentMax,
    leaseType,
    property,
    suite,
    spaceUse,
  } = availability;

  const currencySymbol = getCurrencySymbol(property?.currencyCode);
  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  switch (field) {
    case AvailabilityFields.AvailableSpace: {
      const spaceMin = getFieldValueForAvailability(
        'availableSpaceMin',
        availability,
        property?.propertyCountry?.code || property?.measurementSystem,
      );
      const spaceMax = getFieldValueForAvailability(
        'availableSpaceMax',
        availability,
        property?.propertyCountry?.code || property?.measurementSystem,
      );
      const spaceMinFormatted = spaceMin
        ? formatArea(spaceMin, unitOfMeasurement)
        : '';
      const spaceMaxFormatted = spaceMax
        ? formatArea(spaceMax, unitOfMeasurement)
        : '';
      return [spaceMinFormatted, spaceMaxFormatted].join(' - ');
    }

    case AvailabilityFields.AskingRent: {
      const askingRentMinFormatted = askingRentMin
        ? `${currencySymbol}${formatNumberWithCommas(askingRentMin)}`
        : '';
      const askingRentMaxFormatted = askingRentMax
        ? `${currencySymbol}${formatNumberWithCommas(askingRentMax)}`
        : '';
      return [askingRentMinFormatted, askingRentMaxFormatted].join(' - ');
    }
    case AvailabilityFields.LeaseType:
      return leaseType ? leaseType : null;

    case AvailabilityFields.Suite:
      return suite ? `Suite ${suite}` : null;

    case AvailabilityFields.SpaceUse:
      return spaceUse?.name ? spaceUse?.name : null;
    case AvailabilityFields.Floor:
      return availability?.floor ? `${availability.floor} Floor` : null;
    case AvailabilityFields.FullFloor:
      return availability?.fullFloor ? 'Full Floor' : null;
    case AvailabilityFields.Term:
      return availability?.term ? availability?.term : null;
    case AvailabilityFields.FloorContiguousSpace: {
      const unit = getUnitOfMeasurementForProperty(
        'floorContiguousSpace',
        ModelsWithUnitsOfMeasurement.Property,
        property?.propertyCountry?.code || property?.measurementSystem,
      );
      return availability?.floorContiguousSpace
        ? `Floor Contig: ${formatArea(
            availability?.floorContiguousSpace,
            unit,
          )}`
        : null;
    }
    case AvailabilityFields.BuildingContiguousSpace: {
      const unit = getUnitOfMeasurementForProperty(
        'buildingContiguousSpace',
        ModelsWithUnitsOfMeasurement.Property,
        property?.propertyCountry?.code || property?.measurementSystem,
      );
      return availability?.buildingContiguousSpace
        ? `Bldg Contig: ${formatArea(
            availability?.buildingContiguousSpace,
            unit,
          )}`
        : null;
    }
    case AvailabilityFields.LandlordBrokerCompany:
      return availability?.landlordBrokerCompany?.name
        ? availability?.landlordBrokerCompany?.name
        : null;
    case AvailabilityFields.LandlordBrokerContact:
      return availability?.landlordBrokerContacts
        ? availability?.landlordBrokerContacts
        : null;
    case AvailabilityFields.TimeMeasurement:
      return availability?.timeMeasurement
        ? TIME_MEASUREMENTS_LABELS[availability?.timeMeasurement]
        : null;
    default:
      return null;
  }
};

export const buildTagsListItems = (
  displayFields: AvailabilityFields[],
  availability: IAvailability,
): IdName[] =>
  displayFields
    .map(field => getAvailabilityFormattedField(field, availability))
    .filter(Boolean)
    .map((tag, i) => ({ id: i, name: tag }));
