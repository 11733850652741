export enum LocationTypes {
  urban = 'Urban',
  suburban = 'Suburban',
  urbanSuburban = 'Urban-Suburban',
  exurban = 'Exurban',
  rural = 'Rural',
}

export const LocationTypeIds: { [key: string]: number } = {
  urban: 1,
  suburban: 2,
  urbanSuburban: 3,
  exurban: 4,
  rural: 5,
};

export const LocationTypeNames: { [key: string]: string } = {
  1: LocationTypes.urban,
  2: LocationTypes.suburban,
  3: LocationTypes.urbanSuburban,
  4: LocationTypes.exurban,
  5: LocationTypes.rural,
};
