import classnames from 'classnames';
import { ActivityOwner, ActivityOwnerScoop } from 'components/ActivityOwner';
import { IdName } from 'interfaces/IdName';
import { ILease } from 'interfaces/ILease';
import { IMarket } from 'interfaces/IMarket';
import { IPricingQuote } from 'interfaces/IPricingQuote';
import { IProperty } from 'interfaces/IProperty';
import ISale from 'interfaces/ISale';
import { IScoop } from 'interfaces/IScoop';
import { IUser } from 'interfaces/IUser';
import React from 'react';
import { ActivityCardCategories } from '..';
import ActivityCardMarketData from '../ActivityCardMarketData';
import ActivityCardPropertyData from '../ActivityCardPropertyData';
import styles from './ActivityCardHeader.module.scss';

interface Props {
  categories: IdName[];
  headerText: string;
  headlineText?: string;
  property?: IProperty;
  preselectedProperty?: IProperty;
  preselectedMarket?: IMarket;
  market?: IMarket;
  scoop?: IScoop;
  targetPropertyUrl?: string;
  user?: IUser;
  data?: ILease | ISale | IPricingQuote;
  onCommentsClick?: () => void;
  onEditClick?: () => void;
  onShareClick?: () => void;
}

const ActivityCardHeader: React.FC<Props> = ({
  categories,
  headerText,
  headlineText,
  property,
  market,
  preselectedProperty,
  preselectedMarket,
  scoop,
  targetPropertyUrl,
  user,
  data,
  onCommentsClick,
  onEditClick,
  onShareClick,
}) => {
  const hasCreatedUserOrScoop = !!scoop || !!data?.createdUser;

  const renderCreatedByUser = () => (
    <h3 className={styles['activity-date']}>
      {!!scoop ? (
        <ActivityOwnerScoop user={user!} scoop={scoop!} />
      ) : (
        data?.createdUser && <ActivityOwner data={data} />
      )}
      {headerText}
    </h3>
  );

  return (
    <header className={styles.container}>
      {market ? (
        <ActivityCardMarketData
          market={market}
          scoop={scoop}
          onEditClick={onEditClick}
          onShareClick={onShareClick}
          onCommentsClick={onCommentsClick}
          preselectedMarket={preselectedMarket}
        >
          <ActivityCardCategories categories={categories} />
        </ActivityCardMarketData>
      ) : (
        <ActivityCardPropertyData
          property={property}
          preselectedProperty={preselectedProperty}
          scoop={scoop}
          targetPropertyUrl={targetPropertyUrl}
        >
          <ActivityCardCategories categories={categories} />
          {hasCreatedUserOrScoop && renderCreatedByUser()}
        </ActivityCardPropertyData>
      )}

      {(!hasCreatedUserOrScoop || headlineText) && (
        <h3
          className={classnames(styles['activity-date'], styles['with-margin'])}
        >
          {headlineText && (
            <span className={styles['activity-headline']}>{headlineText}</span>
          )}
          {!hasCreatedUserOrScoop ? headerText : null}
        </h3>
      )}
    </header>
  );
};

export default ActivityCardHeader;
