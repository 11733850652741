import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './i18n/translationFiles/en.json';
import en_US from './i18n/en.json';
import en_GB from './i18n/translationFiles/en-gb.json';
import en_GB_fall from './i18n/en-GB.json';
import en_CA from './i18n/translationFiles/en-ca.json';
import en_CA_fall from './i18n/en-CA.json';
import fr from './i18n/translationFiles/fr.json';
import fr_CA from './i18n/translationFiles/fr-ca.json';
import de from './i18n/translationFiles/de.json';
import { I18nLanguage } from 'constants/i18n';
import { getUserLocaleKey } from './utils/i18n';

const resources = {
  en: en,
  'en-us': en_US,
  'en-gb': { translation: { ...en_GB.translation, ...en_GB_fall.translation } },
  'en-ca': { translation: { ...en_CA.translation, ...en_CA_fall.translation } },
  fr: fr,
  'fr-ca': fr_CA,
  de: de,
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: getUserLocaleKey() || I18nLanguage.en,
  fallbackLng: {
    en: ['en-us'],
    'en-ca': ['en', 'en-us'],
    'en-gb': ['en', 'en-us'],
    'fr-ca': ['fr', 'en', 'en-us'],
    fr: ['en', 'en-us'],
    de: ['en', 'en-us'],
    default: ['en', 'en-us'],
  },
  lowerCaseLng: true,
  debug: !!process.env.REACT_APP_DEV_MODE,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
