import { colors } from 'constants/colors';
import { VictoryBarStyle } from './types';

const Y_AXIS_STYLE = {
  axis: { stroke: 'none' },
  tickLabels: {
    fontSize: 8,
    fill: colors.ayWhiteColor,
    cursor: 'pointer',
  },
};

export const DEFAULT_STYLE: VictoryBarStyle = {
  chart: {
    width: 310,
    height: 300,
    domainPadding: { x: [25, 0], y: [0, 0] },
    padding: {
      top: 60,
      bottom: 35,
      right: 20,
      left: 20,
    },
    style: {
      parent: {
        background: colors.primaryColor900,
        borderRadius: 10,
        height: '28.25rem',
        width: '100%',
      },
    },
  },
  bar: {
    cornerRadius: {
      topRight: 2,
      bottomRight: 0,
      topLeft: 2,
      bottomLeft: 0,
    },
    style: {
      parent: {
        paddingTop: 30,
      },
      labels: { fill: colors.ayWhiteColor, fontSize: 8 },
    },
    width: 25,
  },
  legend: {
    width: 50,
    height: 20,
    style: {
      border: { stroke: 'none' },
      labels: { fill: colors.ayWhiteColor, fontSize: 8 },
    },
    x: 130,
    y: 20,
  },
  title: {
    style: [{ fill: colors.ayWhiteColor, fontSize: 11 }],
    x: -10,
    y: 25,
  },
  subtitle: {
    style: [{ fill: colors.ayWhiteColor, fontSize: 8 }],
    x: -10,
    y: 40,
  },
  tooltip: {
    cornerRadius: 2,
    pointerLength: 10,
    pointerWidth: 0,
    style: {
      border: 'none',
    },
  },
  xAxis: {
    style: {
      ...Y_AXIS_STYLE,
      grid: { stroke: colors.uiColor700, strokeWidth: 1 },
    },
  },
  yAxis: {
    style: Y_AXIS_STYLE,
  },
};
