export const getTooltipId = (graphId: number) => {
  return `owner-portfolio-graph-tooltip-container-${graphId}`;
};

export const getCircleId = (bubbleId: number, graphId: number) => {
  return `owner-portfolio-graph-${bubbleId}-${graphId}`;
};

export const getCircleClass = (graphId: number) => {
  return `owner-portfolio-graph-${graphId}`;
};

export const getCircleShadowClass = (graphId: number) => {
  return `owner-portfolio-graph-shadow-${graphId}`;
};

export const getCircleShadowId = (bubbleId: number, graphId: number) => {
  return `owner-portfolio-graph-shadow-${bubbleId}-${graphId}`;
};

export const getGraphContainerId = (graphId: number) => {
  return `owner-portfolio-graph-container-${graphId}`;
};
