import React from 'react';
import styles from './Loader.module.scss';
import { ColorNames } from 'constants/colorNames';
import SpinnerLoader from 'components/SpinnerLoader';

const Loader: React.FC = () => (
  <div className={styles['loading-content']}>
    <SpinnerLoader background={ColorNames.ayTotalBlackColor} />
  </div>
);

export default Loader;
