import React, { useEffect, useState } from 'react';
import { IActivity } from 'interfaces/IActivity';
import { LeaseCardField } from 'components/Leases/fields';
import { Container } from 'components/@codelitt/ay-design-library';
import { IProperty } from 'interfaces/IProperty';
import { IMarket } from 'interfaces/IMarket';
import { ILease } from 'interfaces/ILease';
import { TypeOfCard } from 'components/ActivityCard/new/utils';
import SaleCard from 'components/ActivityCard/new/SaleCard';
import { SaleField } from 'components/Sales/fields';
import buildMailToUrl from './buildMailToUrl';
import styles from './ActivityShare.module.scss';
import Button from '../Button';
import ModalWrapper from '../ModalWrapper';
import EmailInput from '../Inputs/EmailInput';
import ScoopCard from '../ActivityCard/new/ScoopCard';
import PricingQuoteCard from '../ActivityCard/new/PricingQuoteCard';
import { PricingQuoteCardField } from '../PricingQuotes/PropertyPricingQuoteCard/fields';
import LeaseCard from '../ActivityCard/new/LeaseCard';

interface Props {
  onClose: () => void;
  onSharePopupClose: () => void;
  activity?: IActivity;
  lease?: ILease;
  preselectedProperty?: IProperty;
  preselectedMarket?: IMarket;
}

const ActivityShare: React.FC<Props> = props => {
  const {
    activity,
    onClose,
    onSharePopupClose,
    preselectedProperty,
    lease,
  } = props;
  const [emails, setEmails] = useState<string[]>([]);
  const [additionalComments, setAdditionalComments] = useState<string>('');
  const isConfirmDisabled = !emails.some(Boolean);
  const [sharePopup, setSharePopup] = useState<any>(null);

  const renderActivityDetails = () => {
    if (lease) {
      return (
        <LeaseCard
          className={styles['lease-card']}
          lease={lease}
          activity={activity}
          type={TypeOfCard.SHARE}
          displayFields={[
            LeaseCardField.SignedDate,
            LeaseCardField.Size,
            LeaseCardField.RentPrice,
            LeaseCardField.Type,
            LeaseCardField.Escalation,
            LeaseCardField.TI,
            LeaseCardField.Term,
            LeaseCardField.FreeMonths,
            LeaseCardField.Floor,
            LeaseCardField.SpaceUse,
            LeaseCardField.ExpirationDate,
            LeaseCardField.TenantIndustry,
            LeaseCardField.AdditionalConcessions,
          ]}
        />
      );
    }

    if (activity?.scoop) {
      return (
        <div className={styles['container-share']}>
          <ScoopCard
            activity={activity}
            type={TypeOfCard.SHARE}
            preselectedProperty={preselectedProperty}
          />
        </div>
      );
    }

    if (activity?.sale) {
      return (
        <SaleCard
          type={TypeOfCard.SHARE}
          sale={activity.sale}
          activity={activity}
          fieldsToShow={[
            SaleField.Date,
            SaleField.Price,
            SaleField.PricePerSF,
            SaleField.AssetProfile,
            SaleField.CapRateFormatted,
            SaleField.Size,
            SaleField.AcquisitionType,
            SaleField.InterestPercentFormatted,
            SaleField.NumberOfBuildings,
            SaleField.Occupancy,
            SaleField.TenureType,
            SaleField.WaultToExpiry,
            SaleField.WaultToBreakDate,
            SaleField.TotalRent,
          ]}
        />
      );
    }

    if (activity?.propertyPriceQuote) {
      return (
        <div className={styles['container-share']}>
          <PricingQuoteCard
            activity={activity}
            type={TypeOfCard.SHARE}
            displayFields={[
              PricingQuoteCardField.TI,
              PricingQuoteCardField.FreeRentMonths,
              PricingQuoteCardField.TermInMonths,
              PricingQuoteCardField.LeaseType,
              PricingQuoteCardField.Size,
              PricingQuoteCardField.Floors,
              PricingQuoteCardField.Source,
              PricingQuoteCardField.QuoteDate,
            ]}
          />
        </div>
      );
    }

    return null;
  };

  const onEnterEmail = (email: string) => {
    setEmails(emails => [...emails, email]);
  };

  const onDeleteEmail = (index: number) => {
    setEmails(emails => {
      const newEmails = [...emails];
      newEmails.splice(index, 1);

      return newEmails;
    });
  };

  const handleShareScoop = () => {
    const sharePopup = window.open(
      buildMailToUrl({
        emails,
        additionalComments,
        activity: { ...activity, lease },
      }),
      '_blank',
    );

    setSharePopup(sharePopup);
  };

  useEffect(() => {
    let sharePopupInterval: any;

    if (sharePopup) {
      sharePopupInterval = setInterval(function() {
        if (sharePopup?.closed) {
          clearInterval(sharePopupInterval);
          onClose();
          onSharePopupClose();
        }
      }, 500);
    }

    return () => clearInterval(sharePopupInterval);
  }, [sharePopup, onClose, onSharePopupClose]);

  return (
    <>
      <ModalWrapper
        fullScreen
        withCloseButton
        withCloseLabel
        onCancel={onClose}
        wrapperClassName={styles.container}
      >
        <Container>
          <h3 className={styles.title}>Share Scoop</h3>
          <div className={styles['content-card']}>
            {renderActivityDetails()}
          </div>
          <div className={styles['content-card']}>
            <EmailInput
              label="Enter Recipient(s):"
              items={emails}
              onDelete={onDeleteEmail}
              onEnter={onEnterEmail}
            />
          </div>
          <div className={styles['content-card']}>
            <label
              className={styles['comment-label']}
              htmlFor="additional-comments"
            >
              Your Message:
            </label>
            <textarea
              name="additional-comments"
              id="additional-comments"
              placeholder="Enter text"
              className={styles['comment-area']}
              onChange={e => setAdditionalComments(e.target.value)}
            />
          </div>
          <div className={styles['submit-btn-container']}>
            <Button
              wrapperClassName={styles['cancel-btn']}
              onClick={() => {
                onClose();
              }}
              label="Cancel"
              type="neutral"
              size="small"
            />
            <Button
              wrapperClassName={styles['submit-btn']}
              isDisabled={isConfirmDisabled}
              onClick={handleShareScoop}
              label="Compose Email"
              type="main"
              size="small"
            />
          </div>
        </Container>
      </ModalWrapper>
    </>
  );
};

export default ActivityShare;
