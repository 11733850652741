import React from 'react';
import { Row } from 'react-table';
import classNames from 'classnames';
import styles from './TableView.module.scss';
import { extractItemId, mapSticky } from './utils';
import { DataRow } from './types';

interface Props {
  rows: Row<DataRow>[];
  prepareRow: (row: Row<DataRow>) => void;
}
export const TableViewRows = ({ rows, prepareRow }: Props) => {
  return (
    <>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            key={index}
            className={styles['content-row']}
            data-item-id={extractItemId(row)}
          >
            {row.cells.map((cell, j) => {
              const cellProps = cell.getCellProps();
              return (
                <td
                  {...mapSticky(cellProps)}
                  key={j}
                  className={classNames(
                    styles[`column-${cell.column.id}`],
                    styles['row-cell'],
                  )}
                >
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};
