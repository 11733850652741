import { REF_REDIRECT_PARAM } from 'routes';
import { LocalStorageKeys } from 'constants/localStorageKeys';

const { refRedirect, oAuthProvider } = LocalStorageKeys;

export const getRefParam = (searchParams?: URLSearchParams) =>
  searchParams?.get(REF_REDIRECT_PARAM);

const hasURLManyParams = (url: string) => {
  const formattedURL = new URL(url);
  return !!Array.from(formattedURL.searchParams)[0]?.length;
};

const rebuildURL = (url: URLSearchParams) => {
  // The reason why we need to rebuild the URL with the existing params
  // is because URLSearchParams understands only one param after ?
  // like, in this case: http://localhost:8081/login-email?ref=https://aygeospatialdev.z13.web.core.windows.net?property=600
  // it will give us as "ref" the expected outcome: ref=https://aygeospatialdev.z13.web.core.windows.net?property=600
  // but we need o reach the, say, URL's second level where the rest of the params are.
  // In this case: http://localhost:8081/login-email?ref=https://aygeospatialdev.z13.web.core.windows.net?property=600&marketId=1
  // it will still give us as "ref" as before ignoring the rest: ref=https://aygeospatialdev.z13.web.core.windows.net?property=600
  // As far as I can see, URLSearchParams doesn't provide a straight forward way to do that.
  const urlParams: string[] = [getRefParam(url)!];

  url.forEach(
    (value: string, key: string) =>
      key !== REF_REDIRECT_PARAM && urlParams.push(`${key}=${value}`),
  );

  return urlParams.join('&');
};

export const getRedirectionURL = (searchParams: URLSearchParams) => {
  const refParam = getRefParam(searchParams);
  if (!refParam) return;

  const hasManyParams = hasURLManyParams(refParam!);
  const localAuthProvider = localStorage.getItem(oAuthProvider);
  const providerParam = `provider=${localAuthProvider}`;

  return hasManyParams
    ? `${rebuildURL(searchParams)}&${providerParam}`
    : `${refParam}?${providerParam}`;
};

export const setRefParamInStorage = (url: URLSearchParams) =>
  url && localStorage.setItem(refRedirect, getRedirectionURL(url)!);
