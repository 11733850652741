import { scaleLinear, ScaleLinear, scaleSqrt, scaleTime } from 'd3-scale';
import { extent } from 'd3-array';
import { LeaseBaseRentTime } from 'constants/leases';
import { IBubbleDataPoint } from 'components/Graphs/BubbleChartsElements/interfaces';

export const Y_AXIS_PADDING = 16;
const MIN_VALUE_FOR_PADDING = 12;
const SMALL_Y_PADDING = 4;
export const X_AXIS_PADDING = 8;
const ROUND_TO_TENTH = 10;

export const buildBubblesScale = (leasesGraphData: IBubbleDataPoint[]) => {
  const bubbleSizeExtent: any = extent(
    leasesGraphData,
    (d: IBubbleDataPoint) => d.bubbleSize,
  )!;
  return scaleSqrt()
    .domain(bubbleSizeExtent)
    .range([8, 24]);
};

export const generateGraphScales = (
  marketItems: IBubbleDataPoint[],
  bubbleItems: IBubbleDataPoint[],
  graphWidth: number,
  graphHeight: number,
  params: {
    timeMeasurementType?: string;
    yDefaultPadding?: number;
    useYPadding?: boolean;
    xAxis?: number[];
  } = { useYPadding: true },
) => {
  const allItems = [...marketItems, ...bubbleItems];
  const datesExtent = extent(allItems, (d: IBubbleDataPoint) => d.date);

  const xScale = scaleTime()
    .domain(datesExtent as number[])
    .range([0, graphWidth]);

  const allMarketValues =
    marketItems.filter((d: IBubbleDataPoint) => !!d.value)?.map(d => d.value) ||
    [];

  const allLeaseValues = bubbleItems
    .filter((d: IBubbleDataPoint) => !!d.value)
    ?.map(d => d.value);

  const allPointsExtent: any = extent(
    [...allMarketValues, ...allLeaseValues],
    value => value,
  );

  const yPadding =
    params?.timeMeasurementType === LeaseBaseRentTime.Monthly &&
    allPointsExtent[1] <= MIN_VALUE_FOR_PADDING
      ? SMALL_Y_PADDING / 4
      : params?.yDefaultPadding || Y_AXIS_PADDING;

  let minYValue;
  let maxYValue;

  if (params.useYPadding) {
    // Use the MIN value, considering market and leases
    minYValue = Math.max(allPointsExtent[0] - yPadding, 0);

    // Use the MAX value, considering market and leases
    maxYValue = Math.round((allPointsExtent[1] || 0) + yPadding);
  } else {
    minYValue = Math.max(allPointsExtent[0] - yPadding, 0);

    maxYValue =
      Math.ceil(Math.ceil(allPointsExtent[1] || 0) / ROUND_TO_TENTH) *
      ROUND_TO_TENTH;
  }

  const rangeValues = params.useYPadding
    ? [graphHeight - Y_AXIS_PADDING, Y_AXIS_PADDING]
    : [graphHeight, 0];
  const yScale: ScaleLinear<number, number> = scaleLinear()
    .domain([minYValue, maxYValue])
    .range(rangeValues);

  return {
    xScale,
    yScale,
    minYValue: Math.max(Math.round(minYValue), 0),
    maxYValue: maxYValue,
  };
};
