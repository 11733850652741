import React, { useEffect } from 'react';

import TextInput, {
  Props as TextInputProps,
} from 'components/Inputs/TextInput';

import styles from './TextInputMultiple.module.scss';
import RemoveIcon from '../../assets/images/icons/delete.svg';
import Button from '../Button';

interface Props {
  value: (string | undefined)[];
  onChange: (value: (string | undefined)[]) => void;
  addButtonText: string;
  textInputProps?: Partial<TextInputProps>;
  isInvalid?: boolean;
}

const TextInputMultiple: React.FC<Props> = props => {
  const onChangeItemValue = (value: string, index: number) => {
    if (props.value.includes(value)) return;

    props.onChange(
      props.value.map((aValue, aIndex) => {
        return aIndex === index ? value : aValue;
      }),
    );
  };

  const addItem = () => props.onChange([...props.value, undefined]);

  const removeItem = (index: number) => {
    props.onChange(props.value.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (!props.value.length) {
      props.onChange([undefined]);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      {props.value.map((itemValue, index, arr) => (
        <div key={index} className={styles['item-container']}>
          <div className={styles['text-input-container']}>
            <TextInput
              key={index}
              isInvalid={props.isInvalid}
              {...(props.textInputProps || {})}
              value={itemValue}
              onChange={value => onChangeItemValue(value, index)}
            />
          </div>
          {arr.length > 1 && (
            <div
              className={styles['remove-btn-container']}
              onClick={() => removeItem(index)}
            >
              <img src={RemoveIcon} alt="Remove" />
            </div>
          )}
        </div>
      ))}

      <div>
        <Button
          onClick={addItem}
          type="ghost"
          label={props.addButtonText}
          size="small"
        />
      </div>
    </div>
  );
};

export default TextInputMultiple;
