import { IProperty } from 'interfaces/IProperty';

const COMP_SET_PARAM = 'compSet';
const COMP_SET_ID_PARAM = 'compSetId';
const FOCUS_PROPERTY_PARAM = 'property';
const MARKET_ID_PARAM = 'marketId';
const PROPERTY_TYPE_PARAM = 'propertytype';
const SALE_SET_PARAM = 'saleSet';
const LEASE_SET_PARAM = 'leaseSet';
const AGENCY_ASSIGNMENTS_COMP_SET_ID_PARAM = 'agencyAssignmentsCompsetId';

const MAX_PROPERTIES = 50;

export type AvantCitiesParam = {
  compSet?: number[];
  leaseSet?: number[];
  saleSet?: number[];
  compSetId?: number;
  focusProperty?: IProperty;
  marketId?: number;
  propertyType?: string;
  propertyIds?: number[];
  agencyAssignmentsCompsetId?: number;
};

export const buildAvantCitiesUrl = (
  path: string,
  citiesParams?: AvantCitiesParam,
) => {
  const params = [];

  if (!!citiesParams?.compSet?.length) {
    const compSetIds = Array.from(new Set(citiesParams?.compSet.map(p => p!)))
      .slice(0, MAX_PROPERTIES)
      .join(',');
    params.push(`${COMP_SET_PARAM}=${compSetIds}`);
  }

  if (citiesParams?.propertyIds?.length) {
    const propertiesSetIds = Array.from(new Set(citiesParams?.propertyIds))
      .slice(0, MAX_PROPERTIES)
      .join(',');
    params.push(`${COMP_SET_PARAM}=${propertiesSetIds}`);
  }

  if (citiesParams?.saleSet?.length) {
    const saleSetIds = Array.from(new Set(citiesParams?.saleSet.map(p => p!)))
      .slice(0, MAX_PROPERTIES)
      .join(',');
    params.push(`${SALE_SET_PARAM}=${saleSetIds}`);
  }

  if (citiesParams?.leaseSet?.length) {
    const leaseSetIds = Array.from(new Set(citiesParams?.leaseSet.map(p => p!)))
      .slice(0, MAX_PROPERTIES)
      .join(',');
    params.push(`${LEASE_SET_PARAM}=${leaseSetIds}`);
  }

  if (!!citiesParams?.focusProperty?.id) {
    params.push(`${FOCUS_PROPERTY_PARAM}=${citiesParams?.focusProperty.id}`);
  }

  if (citiesParams?.marketId) {
    params.push(`${MARKET_ID_PARAM}=${citiesParams?.marketId}`);
  }

  if (citiesParams?.compSetId) {
    params.push(`${COMP_SET_ID_PARAM}=${citiesParams?.compSetId}`);
  }

  if (citiesParams?.propertyType) {
    params.push(`${PROPERTY_TYPE_PARAM}=${citiesParams?.propertyType}`);
  }

  if (citiesParams?.agencyAssignmentsCompsetId) {
    params.push(
      `${AGENCY_ASSIGNMENTS_COMP_SET_ID_PARAM}=${citiesParams?.agencyAssignmentsCompsetId}`,
    );
  }

  if (params.length) {
    const urlParams = params.join('&');
    return `${path}?${urlParams}`;
  }

  return path;
};
