import React, { useEffect, useState } from 'react';
import styles from './DropdownCheckboxInput.module.scss';
import DropdownCheckboxInputItem from './DropdownCheckboxInputItem';
import { IdName } from '../../../interfaces/IdName';
import { IMultiselectItem } from '../../../interfaces/IMultiselectItem';

type PropTypes = {
  data: IMultiselectItem[];
  selectedItems: IMultiselectItem[];
  onChange: (items: IMultiselectItem[]) => void;
  isDisabled?: boolean;
  itemWrapperClassName?: string;
};

const DropdownCheckboxInput: React.FC<PropTypes> = props => {
  const { data, onChange, isDisabled, itemWrapperClassName } = props;
  const [selectedItems, setSelectedItems] = useState(props.selectedItems);

  useEffect(() => {
    setSelectedItems(props.selectedItems);
  }, [props.selectedItems]);

  const selectItem = (item: IMultiselectItem, shouldAddItem: boolean) => {
    let items: IMultiselectItem[];
    if (shouldAddItem) {
      items = [...selectedItems, { ...item }];
    } else {
      items = selectedItems.filter((el: IMultiselectItem) => el.id !== item.id);
    }
    setSelectedItems(items);
    onChange && onChange(items);
  };

  const selectSubItem = (item: IMultiselectItem, subItem: IdName) => {
    let items: IMultiselectItem[];
    const index = selectedItems.findIndex(
      (el: IMultiselectItem) => el.id === item.id,
    );
    const shouldAddItem = index === -1;
    if (shouldAddItem) {
      items = [
        ...selectedItems,
        {
          id: item.id,
          name: item.name,
          subItems: [{ ...subItem }],
        },
      ];
    } else {
      const shouldAddSubItem = !selectedItems[index].subItems.some(
        el => el.id === subItem.id,
      );
      if (shouldAddSubItem) {
        selectedItems[index].subItems.push({ ...subItem });
        items = [...selectedItems];
      } else {
        // remove subItem
        selectedItems[index].subItems = selectedItems[index].subItems.filter(
          el => el.id !== subItem.id,
        );
        // also remove Item
        if (selectedItems[index].subItems.length === 0) {
          items = [...selectedItems.filter((_, idx) => idx !== index)];
        } else {
          items = [...selectedItems];
        }
      }
    }
    setSelectedItems(items);
    onChange && onChange(items);
  };

  const getSelectedItem = (item: IMultiselectItem) =>
    selectedItems.find(el => el.id === item.id);

  const isItemSelected = (
    item: IMultiselectItem,
    selectedItem?: IMultiselectItem,
  ) => !!selectedItem && selectedItem.subItems.length === item.subItems.length;

  const isItemPartiallySelected = (
    item: IMultiselectItem,
    selectedItem?: IMultiselectItem,
  ) => !!selectedItem && selectedItem.subItems.length < item.subItems.length;

  if (!data?.length) return null;

  return (
    <div className={styles['checkbox-group-container']}>
      {data.map(item => {
        const selectedItem = getSelectedItem(item);
        const isSelected = isItemSelected(item, selectedItem);
        const isPartiallySelected = isItemPartiallySelected(item, selectedItem);
        return (
          <DropdownCheckboxInputItem
            key={item.id}
            label={item.name!}
            data={item.subItems}
            selectedSubItems={selectedItem?.subItems || []}
            isSelected={isSelected}
            isPartiallySelected={isPartiallySelected}
            onClick={shouldAddItem => {
              selectItem(item, shouldAddItem);
            }}
            onClickSubItem={subItem => {
              selectSubItem(item, subItem);
            }}
            isDisabled={isDisabled}
            itemWrapperClassName={itemWrapperClassName}
          />
        );
      })}
    </div>
  );
};

export default DropdownCheckboxInput;
