import dayjs from 'dayjs';
import { IUser } from 'interfaces/IUser';
import { IUpdateSummary } from './constants';

export const getDate = (date: string): string => {
  const dayInstance = dayjs(date);
  const dateFormat = 'MMMM Do, YYYY';
  const timeFormat = 'hh:mma';
  const formattedDate = dayInstance.format(dateFormat);
  const formattedTime = dayInstance.format(timeFormat);

  return `${formattedDate} at ${formattedTime}`;
};

export const getData = (data: IUpdateSummary): IUpdateSummary => {
  const { created, modified } = data;
  const isSameDate = dayjs(created?.date).isSame(
    dayjs(modified?.date),
    'second',
  );

  return isSameDate ? { created } : data;
};

export const getUpdateSummaryData = (
  createdAt?: string,
  createdUser?: IUser,
  updatedAt?: string,
  updatedUser?: IUser,
): IUpdateSummary => ({
  created: { date: createdAt, user: createdUser },
  modified: { date: updatedAt, user: updatedUser },
});
