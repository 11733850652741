import classnames from 'classnames';
import { DropdownOption } from 'components/Dropdown';
import Icon from 'components/Icon';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button/new';
import styles from './DropdownButton.module.scss';
import { colors } from 'constants/colors';

interface Props {
  disabled?: boolean;
  wrapperClassName?: string;
  isWhite?: boolean;
  options: DropdownOption[];
  onClick: (option: DropdownOption) => void;
  icon: string;
}

const DropdownButton: React.FC<Props> = ({
  disabled,
  options,
  wrapperClassName,
  isWhite,
  onClick,
  icon,
}) => {
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const isButtonDisabled = disabled;

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const isClickOutside =
        resultsContainerRef.current &&
        !resultsContainerRef.current.contains(event.target);

      if (isClickOutside) {
        setAreOptionsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resultsContainerRef]);

  const renderOptions = () => {
    if (!areOptionsVisible) {
      return null;
    }

    return options.map(option => (
      <div
        className={styles['options-item']}
        key={option.value}
        onClick={() => {
          setAreOptionsVisible(false);
          onClick(option);
        }}
      >
        {option.label || option.value}
        <Icon
          name={icon}
          color={colors.primaryColor500}
          className={classnames(styles['options-item-icon'], {
            [styles['icon-padded']]: option.value === '7',
          })}
        />
      </div>
    ));
  };

  return (
    <div className={classnames(styles.container, wrapperClassName)}>
      <Button
        type={isWhite ? 'contextual-positive' : 'contextual'}
        disabled={isButtonDisabled}
        onClick={() => setAreOptionsVisible(true)}
        icon={icon}
        wrapperClassName={styles['dropdown-button']}
        iconSize={1.3}
      />
      <div
        className={classnames(styles['options-container'], {
          [styles['is-visible']]: areOptionsVisible,
        })}
        ref={resultsContainerRef}
      >
        {renderOptions()}
      </div>
    </div>
  );
};

export default DropdownButton;
