import React from 'react';
import classnames from 'classnames';
import FormControl from 'components/FormControl';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import DateInput from 'components/Inputs/DateInput';
import RadioInput from 'components/Inputs/RadioInput';
import { isValidISOFormat } from 'utils/validation/date';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { ILease } from 'interfaces/ILease';
import { LeaseConfidentialType, LeaseTypeEnum } from 'constants/leases';
import { IProperty } from 'interfaces/IProperty';
import {
  getMonthsDifference,
  addMonthsToDate,
  getSignDateSuggestions,
  getCommencementDateSuggestions,
} from '../formDates';
import {
  getBaseRentOptions,
  getConfidentialLeaseOptions,
  getFinancialStatusTypes,
  getTransactionTypes,
} from '../staticData';
import {
  updateExpirationDateRelatedFields,
  getPropertyTypeFilter,
} from '../utils';
import { LeaseFormSectionProps } from '..';
import styles from '../../FormSection.module.scss';
import { translateText } from 'utils/i18n';
import { isSignedDateValid } from 'components/CreateComps/FormSection/LeaseForm/utils';
import UnitOfMeasurementLegend from '../../../../UnitOfMeasurementLegend/index';
import RentType from 'components/CreateComps/FormSection/FormFields/RentType';
import RentPaymentDetails from 'components/CreateComps/FormSection/FormFields/RentPaymentDetails';
import SizeFields from 'components/CreateComps/FormSection/FormFields/SizeFields';
import Floors from 'components/CreateComps/FormSection/FormFields/Floors';
import Suite from 'components/CreateComps/FormSection/FormFields/Suite';
import SpaceUse from 'components/CreateComps/FormSection/FormFields/SpaceUse';
import LeaseType from 'components/CreateComps/FormSection/FormFields/LeaseType';
import { getValuesAsIdName } from '../../FormFields/utils';
import Term from 'components/CreateComps/FormSection/FormFields/Term/index';
import { RentTypes } from 'constants/rentTypes';
import { CountryNames } from 'constants/countryCodes';
import { useTranslation } from 'react-i18next';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { currencyInputFormatter } from 'utils/formatters/currency';
import { PropertyTypes } from 'constants/propertyTypes';
import IndustrialFields from './IndustrialFields';
import OfficeWareHouseSizes from '../../FormFields/OfficeWareHouseSizes/OfficeWareHouseSizes';
import Sublessor from '../../FormFields/Sublessor';
import { SecurityDeposit } from '../../FormFields/SecurityDeposit';
import { LeaseOptions } from '../../FormFields/LeaseOptions';

interface Props extends LeaseFormSectionProps {
  lease: ILeaseInput;
  isPropertyIndustrialType: boolean;
  leaseCurrencySymbol: string;
  leaseProperty?: IProperty;
  propertyType?: string;
  sourceLease?: ILease;
  unitOfMeasurement: string;
  handleTriggeredCalculation: () => void;
  handleChangeTransactionType: () => void;
}

const LeaseDetails: React.FC<Props> = ({
  lease,
  isPropertyIndustrialType,
  leaseCurrencySymbol,
  leaseInput,
  leaseProperty,
  propertyType,
  sourceLease,
  unitOfMeasurement,
  updateLeaseProperty,
  handleTriggeredCalculation,
  handleChangeTransactionType,
}) => {
  const { t } = useTranslation();
  // suggestions for "commencementDate" depend on the sign date
  // so only enable them when the sign date is valid
  const commencementDateSuggestionsEnabled = isValidISOFormat(
    leaseInput.signDate,
  );

  const dateSuggestions = {
    signDate: getSignDateSuggestions(lease.signDate),
    commencementDate: getCommencementDateSuggestions(t),
  };

  const isIndustryOrFlexIndustryEdit =
    lease?.property?.type === PropertyTypes.flexIndustrial ||
    lease?.property?.type === PropertyTypes.industrial;

  const isIndustryOrFlexIndustryCreate =
    leaseInput?.property?.propertyTypeId === 2 ||
    leaseInput?.property?.propertyTypeId === 3;

  const hasIndustryFields = leaseInput?.id
    ? isIndustryOrFlexIndustryEdit
    : isIndustryOrFlexIndustryCreate;

  const shouldRenderRentPaymentDetails = [CountryNames.UK].some(
    country => leaseProperty?.country === country,
  );
  const displaySublessor = leaseInput?.type === LeaseTypeEnum.SUBLEASE;

  return (
    <>
      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.signedDate`)}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['large-input-container']}>
          <DateInput
            id="signed-date"
            value={leaseInput.signDate}
            onChange={(value: string | null) =>
              updateLeaseProperty('signDate', value)
            }
            customValidation={{
              isValid: leaseInput?.signDate
                ? isSignedDateValid(leaseInput?.signDate)
                : true,
              message: t(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.dateMustBeWithinThreeMonths`,
              ),
            }}
          />

          <RadioInput
            selectedItem={leaseInput.signDate}
            data={dateSuggestions.signDate}
            onChange={item => updateLeaseProperty('signDate', item.value)}
          />
        </div>
      </FormControl>
      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.commencementDate`)}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['large-input-container']}>
          <DateInput
            value={leaseInput.commencementDate}
            onChange={(value: string | null) =>
              updateExpirationDateRelatedFields(
                { leaseInput, field: 'commencementDate', value: value ?? '' },
                updateLeaseProperty,
              )
            }
            id="commencement-date"
          />

          <RadioInput
            isAllDisabled={!commencementDateSuggestionsEnabled}
            selectedItem={getMonthsDifference(
              leaseInput.commencementDate,
              leaseInput.signDate,
            )}
            data={getCommencementDateSuggestions(t)}
            onChange={item => {
              const commencementDate = addMonthsToDate(
                leaseInput.signDate,
                +item.value! || 0,
              );

              updateLeaseProperty('commencementDate', commencementDate);
            }}
          />
        </div>
      </FormControl>

      <Term
        isTermInMonths
        value={leaseInput.termInMonths}
        message={t(
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.maxTermMonthsMessage`,
        )}
        onChange={(field: string, value: string) =>
          updateExpirationDateRelatedFields(
            { leaseInput, field: field, value: value },
            updateLeaseProperty,
          )
        }
      />
      <FormControl
        label={t('avantProperties.labels.common.expirationDate')}
        wrapperClassName={styles['form-row']}
      >
        <DateInput
          value={leaseInput.expirationDate}
          onChange={(value: string | null) =>
            updateExpirationDateRelatedFields(
              { leaseInput, field: 'expirationDate', value: value ?? '' },
              updateLeaseProperty,
            )
          }
          id="expiration-date"
        />
      </FormControl>

      <LeaseOptions
        currencySymbol={leaseCurrencySymbol}
        onChange={updateLeaseProperty}
        initOptions={leaseInput?.leaseOptions ?? []}
      />

      <LeaseType
        value={leaseInput.type}
        property={leaseProperty}
        onChange={updateLeaseProperty}
      />
      {displaySublessor && (
        <Sublessor
          leaseInput={leaseInput}
          updateLeaseProperty={updateLeaseProperty}
        />
      )}
      <FormControl
        label={t(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.transactionType`)}
        wrapperClassName={styles['form-row']}
      >
        <ChoiceInput
          selectedItem={{
            id: leaseInput.transactionType,
            name: leaseInput.transactionType,
          }}
          data={getTransactionTypes(t)}
          containerWrapperClassName={styles['radio-input-container']}
          onChange={item => {
            updateLeaseProperty('transactionType', item?.id);
            handleChangeTransactionType();
          }}
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.lease.label.financialStatus',
        )}
        wrapperClassName={styles['form-row']}
      >
        <ChoiceInput
          selectedItem={{
            id: leaseInput.financialStatus,
            name: leaseInput.financialStatus,
          }}
          data={getFinancialStatusTypes(t)}
          containerWrapperClassName={styles['radio-input-container']}
          onChange={item => updateLeaseProperty('financialStatus', item?.id)}
        />
      </FormControl>
      {!!getPropertyTypeFilter(propertyType, sourceLease) && (
        <SpaceUse
          value={leaseInput?.spaceUse}
          onChange={updateLeaseProperty}
          propertyType={getPropertyTypeFilter(propertyType, sourceLease)!}
        />
      )}
      <SizeFields
        field="size"
        input={leaseInput}
        property={leaseProperty}
        onChange={(field, value) => {
          updateLeaseProperty(field, value);
          handleTriggeredCalculation();
        }}
        unitOfMeasurement={unitOfMeasurement}
        disableCalculationSizes={!isPropertyIndustrialType}
      />
      {isPropertyIndustrialType && (
        <OfficeWareHouseSizes
          leaseProperty={leaseProperty}
          onChange={updateLeaseProperty}
          unitOfMeasurement={unitOfMeasurement}
          input={leaseInput}
        />
      )}
      <Floors value={leaseInput.floor} onChange={updateLeaseProperty} />
      <Suite value={leaseInput.suite} onChange={updateLeaseProperty} />
      {hasIndustryFields && (
        <IndustrialFields
          leaseInput={leaseInput}
          updateLeaseProperty={updateLeaseProperty}
        />
      )}
      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.baseRent`)}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['wrapper-radio-container']}>
          <CurrencyInput
            currencySymbol={leaseCurrencySymbol}
            iconPosition={'left'}
            id="base-rent-input"
            onChange={value =>
              updateLeaseProperty(
                leaseInput?.type === LeaseTypeEnum.TURN_KEY
                  ? 'baseRentPerKw'
                  : 'baseRent',
                value,
              )
            }
            formatter={currencyInputFormatter}
            placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
            value={leaseInput.baseRent || leaseInput.baseRentPerKw}
          />
          <UnitOfMeasurementLegend unitOfMeasurement={unitOfMeasurement} />
          <ChoiceInput
            selectedItem={{
              id: leaseInput.timeMeasurement,
              name: leaseInput.timeMeasurement,
            }}
            data={getBaseRentOptions(t)}
            onChange={item => updateLeaseProperty('timeMeasurement', item?.id)}
            itemWrapperClassName={styles['button-input-item']}
            containerWrapperClassName={classnames(
              styles['radio-input-container'],
              styles['auto-width'],
            )}
          />
        </div>
      </FormControl>
      <RentType
        property={leaseProperty}
        rentType={RentTypes.RENT_TYPE}
        onChange={updateLeaseProperty}
        selectedItem={leaseInput.rentType}
        cleaningIncludedSelected={leaseInput.cleaningIncluded}
        showCleaningIncluded
      />
      <SecurityDeposit
        currencySymbol={leaseCurrencySymbol}
        securityDeposit={leaseInput.securityDeposit}
        securityDepositType={leaseInput.securityDepositType ?? undefined}
        onChangesSecurityDeposit={value =>
          updateLeaseProperty('securityDeposit', value)
        }
        onChangeSecurityDepositType={item =>
          updateLeaseProperty('securityDepositType', item)
        }
      />
      {shouldRenderRentPaymentDetails && (
        <RentPaymentDetails
          property={leaseProperty}
          onChange={updateLeaseProperty}
          selectedItem={leaseInput.rentPaymentDetail}
        />
      )}
      <FormControl
        label={translateText(
          'avantPlatform.attributes.lease.label.confidential',
        )}
        wrapperClassName={styles['form-row']}
      >
        <ChoiceInput
          selectedItem={getValuesAsIdName(leaseInput.confidential)}
          data={getConfidentialLeaseOptions(t)}
          containerWrapperClassName={styles['radio-input-container']}
          onChange={item => {
            const isConfidential =
              item?.id === LeaseConfidentialType.yes ||
              (item?.id === LeaseConfidentialType.no ? false : undefined);

            updateLeaseProperty('confidential', isConfidential);
          }}
        />
      </FormControl>
    </>
  );
};

export default LeaseDetails;
