import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import GraphContainer from 'components/Graphs/GraphContainer';
import Legend from 'components/Graphs/elements/Legend';
import Circle from 'components/Graphs/elements/Circle';
import Line from 'components/Graphs/elements/Line';
import Polyline from 'components/Graphs/elements/Polyline';
import { colors } from 'constants/colors';
import { formatCurrency, getCurrencySymbol } from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_ATTR_PATH,
  I18N_AVANT_PROPERTY_LABEL_PATH,
} from 'constants/i18n';

import styles from './AskingRentGraph.module.scss';
import { GRAPH_HEIGHT, GRAPH_WIDTH } from './constants';
import { AskingRentGraphProps } from './types';

const CIRCLE_RADIUS = 8;

const AskingRentGraph: React.FC<AskingRentGraphProps> = ({
  buildingAskingRent,
  marketAverageRent,
  currencyCode,
}) => {
  const [buildingRentCirclePointX, setBuildingRentCirclePointX] = useState(-1);
  const [marketRentCirclePointX, setMarketRentCirclePointX] = useState(-1);

  const [buildingRentLinePoints, setBuildingRentLinePoints] = useState<
    number[][]
  >();
  const [marketRentLinePoints, setMarketRentLinePoints] = useState<
    number[][]
  >();

  const centerLineYCoordinate = GRAPH_HEIGHT / 2;
  // Because there's a gap between the end of the line and the canvas.
  // Check https://www.figma.com/file/ukyfI1jc4NchTFOnr9APx8/03-Property-Profile?node-id=2527%3A3347 for more info.
  const CENTER_LINE_OFFSET = 16;
  const lineWidth = GRAPH_WIDTH - CENTER_LINE_OFFSET;

  useEffect(() => {
    const calculateXCoordinatesOfCircles = () => {
      const largerNumber = Math.max(buildingAskingRent, marketAverageRent);
      const smallerNumber = Math.min(buildingAskingRent, marketAverageRent);
      // First, we calculate how many times the larger number contains the smaller.
      const ratio = largerNumber / smallerNumber;
      // Next, we get the number of chunks into which we are going to split the line.
      // We just add "2" to get extra space on the edges of the line.
      const numberOfChunks = ratio + 2;
      const chunkSize = lineWidth / numberOfChunks;
      // We get the midpoint of the chunk to avoid placing the circles on the edges.
      const chunkMidpoint = chunkSize / 2;
      // Finally, we calculate the x-coordinates of the circles.
      let buildingRentCircleX = 0;
      let marketRentCircleX = 0;

      if (buildingAskingRent < marketAverageRent) {
        buildingRentCircleX = chunkMidpoint + chunkSize;
        marketRentCircleX = chunkMidpoint + chunkSize * ratio;
      } else {
        buildingRentCircleX = chunkMidpoint + chunkSize * ratio;
        marketRentCircleX = chunkMidpoint + chunkSize;
      }

      setBuildingRentCirclePointX(buildingRentCircleX);
      setMarketRentCirclePointX(marketRentCircleX);
    };

    calculateXCoordinatesOfCircles();
  }, [buildingAskingRent, marketAverageRent, lineWidth]);

  useEffect(() => {
    const calculateBuildingRentLinePoints = () => {
      const LEGEND_RIGHT_EDGE_X_COORD = 130;
      const LEGEND_MIDPOINT_Y_COORD = 27;

      const lineYCoord10PxOffset = LEGEND_RIGHT_EDGE_X_COORD + 10;
      const lineYCoord50PxOffset = LEGEND_MIDPOINT_Y_COORD + 50;

      const points = [
        // The first point is on the right middle edge of the legend.
        [LEGEND_RIGHT_EDGE_X_COORD, LEGEND_MIDPOINT_Y_COORD],
        // The second point is 10px to the right of the x-coordinate of the first point.
        [lineYCoord10PxOffset, LEGEND_MIDPOINT_Y_COORD],
        // The third point is 50px to the bottom of the y-coordinate of the second point.
        [lineYCoord10PxOffset, lineYCoord50PxOffset],
        // The fourth point is located in the same horizontal position as the building rent circle.
        [buildingRentCirclePointX, lineYCoord50PxOffset],
        // Finally, the fifth point reaches the center line.
        [buildingRentCirclePointX, centerLineYCoordinate],
      ];

      setBuildingRentLinePoints(points);
    };

    calculateBuildingRentLinePoints();
  }, [buildingRentCirclePointX, centerLineYCoordinate]);

  useEffect(() => {
    const calculateMarketRentLinePoints = () => {
      const LEGEND_LEFT_EDGE_X_COORD = 12;
      const LEGEND_RIGHT_EDGE_X_COORD = 108;

      const LEGEND_TOP_EDGE_Y_COORD = 35;
      const LEGEND_MIDPOINT_Y_COORD = 43;

      let lineXCoordOffset = marketRentCirclePointX;
      let lineYCoordOffset = centerLineYCoordinate + LEGEND_MIDPOINT_Y_COORD;

      if (marketRentCirclePointX > LEGEND_RIGHT_EDGE_X_COORD) {
        // The horizontal position of the market rent circle is beyond the right edge of the legend.
        lineXCoordOffset = LEGEND_RIGHT_EDGE_X_COORD;
      } else if (marketRentCirclePointX < LEGEND_LEFT_EDGE_X_COORD) {
        // The horizontal position of the market rent circle is before the left edge of the legend.
        lineXCoordOffset = LEGEND_LEFT_EDGE_X_COORD;
      } else {
        // The horizontal position of the market rent circle is "aligned" with the legend,
        // so we respect the x-coord of the circle but change the y-coord to reach the top edge of the legend.
        lineYCoordOffset = centerLineYCoordinate + LEGEND_TOP_EDGE_Y_COORD;
      }

      const points = [
        // The first point matches the horizontal position of the market rent circle.
        [marketRentCirclePointX, centerLineYCoordinate],
        // The second point is either at the top edge of the legend or at the midpoint of the legend.
        // This depends on the position of the circle.
        [marketRentCirclePointX, lineYCoordOffset],
        // Finally, the third point is either at the same position as the circle
        // or at the left or right edge of the legend depending on the location of the circle.
        [lineXCoordOffset, lineYCoordOffset],
      ];

      setMarketRentLinePoints(points);
    };

    calculateMarketRentLinePoints();
  }, [marketRentCirclePointX, centerLineYCoordinate]);

  const drawCircles = () => {
    if (buildingRentCirclePointX === -1 || marketRentCirclePointX === -1) {
      return null;
    }

    return (
      <>
        <Circle
          cx={buildingRentCirclePointX}
          cy={centerLineYCoordinate}
          r={CIRCLE_RADIUS}
          fillColor={colors.primaryColor500}
        />
        <Circle
          cx={marketRentCirclePointX}
          cy={centerLineYCoordinate}
          r={CIRCLE_RADIUS}
          fillColor={colors.supportive500}
        />
      </>
    );
  };

  const drawLines = () => {
    if (!buildingRentLinePoints || !marketRentLinePoints) {
      return null;
    }

    return (
      <>
        <Polyline
          points={buildingRentLinePoints}
          color={colors.primaryColor500}
        />
        <Polyline points={marketRentLinePoints} color={colors.supportive500} />
      </>
    );
  };

  return (
    <GraphContainer
      wrapperClassName={styles.container}
      style={{ height: GRAPH_HEIGHT, width: GRAPH_WIDTH }}
    >
      <div
        className={classnames(
          styles['legend-wrapper'],
          styles['top-legend-wrapper'],
        )}
      >
        <Legend
          className={classnames(styles.legend)}
          text={translateText(
            `${I18N_AVANT_PROPERTY_ATTR_PATH}.compSet.label.buildingAskingRent`,
          )}
        />
        <span className={styles.label}>
          {formatCurrency(buildingAskingRent, {
            decimals: 2,
            currencySymbol: getCurrencySymbol(currencyCode),
          })}
        </span>
      </div>
      <svg className={styles.graph}>
        <Line
          x1={0}
          x2={lineWidth}
          y1={centerLineYCoordinate}
          y2={centerLineYCoordinate}
          width={4}
          color={colors.primaryColor500}
        />
        {drawCircles()}
        {drawLines()}
      </svg>
      <div
        className={classnames(
          styles['legend-wrapper'],
          styles['bottom-legend-wrapper'],
        )}
      >
        <Legend
          className={styles.legend}
          text={translateText(
            `${I18N_AVANT_PROPERTY_LABEL_PATH}.historicalPerformance.compSetAverage`,
          )}
          legendColor={colors.supportive500}
        />
        <span className={styles.label}>
          {formatCurrency(marketAverageRent, {
            decimals: 2,
            currencySymbol: getCurrencySymbol(currencyCode),
          })}
        </span>
      </div>
    </GraphContainer>
  );
};

export { AskingRentGraph };
