import { uniq } from 'lodash';
import { IDonutGraphData, TAG_BREAK_LINE } from 'interfaces/IDonutGraphData';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { isUsingMeters } from 'utils/unitsOfMeasurement';
import {
  abbreviateNumber,
  roundNumberWithDecimals,
} from 'utils/formatters/number';
import { startCase, toLower } from 'lodash';
import { TFunction } from 'i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { IOwnersResponse } from 'interfaces/IOwners';
import { IPropertiesAnalyticsSubmarketsSizeResponse } from 'interfaces/IPropertiesAnalyticsSubmarketsSize';
import { formatAndAbbreviateArea } from 'utils/formatters/area';

export const parseOwnersDonutData = (
  ownersResponse: IOwnersResponse | undefined,
  unitOfMeasurement: UnitOfMeasurement,
  t: TFunction,
): IDonutGraphData[] => {
  if (!ownersResponse) return [];

  const useMeters = isUsingMeters(unitOfMeasurement);
  return ownersResponse.slices.map((s, i) => ({
    tags: [
      formatAndAbbreviateArea(useMeters ? s.sizeMt : s.size, unitOfMeasurement),
      `${roundNumberWithDecimals(s.percentage)}%`,
      `${s.numberOfBuildings} ${t(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.building`,
        {
          count: s.numberOfBuildings,
        },
      ).toLowerCase()}`,
      s.numberOfOwners
        ? `${s.numberOfOwners} ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.owner`, {
            count: s.numberOfOwners,
          }).toLowerCase()}`
        : '',
    ].filter(Boolean),
    title: startCase(toLower(s.name)),
    unitOfMeasurement,
    topItems: uniq(s.largestPropertyIds),
    totalSize: s.size,
    order: i + 1,
    donutType: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.owners`),
  }));
};

export const parseOwnersDonutDefaultData = (
  ownersResponse: IOwnersResponse | undefined,
  unitOfMeasurement: UnitOfMeasurement,
  t: TFunction,
): IDonutGraphData | undefined => {
  if (!ownersResponse) return;

  const useMeters = isUsingMeters(unitOfMeasurement);
  const { summary, slices } = ownersResponse;

  const tags: string[] = [
    `${abbreviateNumber(
      useMeters ? summary.sizeMt : summary.size,
    )} ${unitOfMeasurement}`,
    `${summary.totalOwners} ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.owner`, {
      count: summary.totalOwners,
    }).toLowerCase()}`,
    TAG_BREAK_LINE,
  ];

  const totalBuildings = slices
    .map(x => x.numberOfBuildings)
    .reduce((partialSum, buildings) => partialSum + buildings, 0);

  if (totalBuildings) {
    tags.push(
      `${totalBuildings} ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.building`, {
        count: totalBuildings,
      }).toLowerCase()}`,
    );
  }

  return {
    tags,
    unitOfMeasurement,
    totalSize: summary.size,
  };
};

export const parseSubmarketsDonutData = (
  submarketsResponse: IPropertiesAnalyticsSubmarketsSizeResponse | undefined,
  unitOfMeasurement: UnitOfMeasurement,
  t: TFunction,
): IDonutGraphData[] => {
  if (!submarketsResponse) return [];

  const useMeters = isUsingMeters(unitOfMeasurement);
  return submarketsResponse.slices.map((s, i) => ({
    tags: [
      formatAndAbbreviateArea(useMeters ? s.sizeMt : s.size, unitOfMeasurement),
      `${roundNumberWithDecimals(s.percentage)}%`,
      `${s.numberOfBuildings} ${t(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.building`,
        {
          count: s.numberOfBuildings,
        },
      ).toLowerCase()}`,
    ].filter(Boolean),
    title: startCase(toLower(s.name)),
    unitOfMeasurement,
    topItems: uniq(s.topItems),
    totalSize: s.size,
    order: i + 1,
    donutType: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.owners`),
  }));
};

export const parseSubmarketsDonutDefaultData = (
  submarketsResponse: IPropertiesAnalyticsSubmarketsSizeResponse | undefined,
  unitOfMeasurement: UnitOfMeasurement,
): IDonutGraphData | undefined => {
  if (!submarketsResponse) return;

  const { summary, slices } = submarketsResponse;

  const twoLargestSubmarkets = slices.slice(0, 2);

  const tags: string[] = [
    ...twoLargestSubmarkets.map(
      submarket =>
        `${roundNumberWithDecimals(submarket.percentage)}% ${submarket.name}`,
    ),
    TAG_BREAK_LINE,
  ];

  return {
    tags,
    unitOfMeasurement,
    totalSize: summary.size,
  };
};
