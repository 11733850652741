import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { defaultToIsoFormat } from 'utils/formatters/date';
import { isNil } from 'lodash';
import { EscalationType } from 'constants/leases';

const LEASE_DATE_FIELDS = [
  'commencementDate',
  'signDate',
  'expirationDate',
  'tenantInMarketLastDiscussedDate',
];

export const processLeaseBeforeEdit = (input: ILeaseInput): ILeaseInput => {
  const lease: ILeaseInput = { ...input };
  LEASE_DATE_FIELDS.forEach(dateField => {
    if (!!lease[dateField]) {
      lease[dateField] = defaultToIsoFormat(lease[dateField]);
    }
  });

  if (isNil(input.confidential)) {
    lease.confidential = false;
  }

  if (isNil(input.cleaningIncluded)) {
    lease.cleaningIncluded = false;
  }

  if (isNil(input.escalationType)) {
    lease.escalationType = EscalationType.Percentage;
  }

  return lease;
};
