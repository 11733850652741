import React from 'react';
import ISale from 'interfaces/ISale';
import { SaleField } from '../../fields';
import LinksList from 'components/LinksList';
import { IdName } from 'interfaces/IdName';
import { DOT } from 'constants/placeholders';
import styles from './SecondaryFields.module.scss';
import {
  getCompanies,
  getSecondaryFields,
  shouldRenderCompanyLinks,
} from './utils';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import {
  I18N_PLATFORM_SALE_PATH,
  I18N_SALE_LABEL_PATH,
} from 'components/Sales/constants';

const LABEL_FIELDS: { [SaleField: string]: string } = {
  [SaleField.AcquisitionType]: `${translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.type`,
  )}: `,
  [SaleField.BuyersBrokers]: `${translateText(
    `${I18N_PLATFORM_SALE_PATH}.label.buyerBroker`,
  )}: `,
  [SaleField.CapRate]: `${translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.capRate`,
  )}: `,
  [SaleField.InterestPercent]: `${translateText(
    `${I18N_SALE_LABEL_PATH}.partialInterest`,
  )}: `,
  [SaleField.SellersBrokers]: `${translateText(
    `${I18N_PLATFORM_SALE_PATH}.label.sellerBroker`,
  )}: `,
  [SaleField.Sellers]: `${translateText(`${I18N_SALE_LABEL_PATH}.seller`)}: `,
  [SaleField.Size]: `${translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.size`,
  )}: `,
};

type Props = {
  sale: ISale;
  fields: SaleField[];
};

const SecondaryFields: React.FC<Props> = ({ sale, fields }) => {
  const secondaryFields = getSecondaryFields(sale, fields);

  const renderContent = (field: IdName) => {
    const companies = getCompanies(field, sale);

    return shouldRenderCompanyLinks(field) && !!companies ? (
      <LinksList list={companies} bold separator=", " />
    ) : (
      field.name
    );
  };

  return (
    <ul className={styles['list-container']}>
      {secondaryFields.map((field, i) => (
        <li key={i} className={styles['item-container']}>
          <pre className={styles['field-text']}>{LABEL_FIELDS[field.id]}</pre>
          {renderContent(field)}
          {secondaryFields.length > i + 1 && (
            <pre className={styles['field-text']}> {DOT} </pre>
          )}
        </li>
      ))}
    </ul>
  );
};

export default SecondaryFields;
