import {
  PROPERTIES_ANALYTICS_PROPERTY_CLASSES,
  PROPERTIES_ANALYTICS_PROPERTY_SUBTYPES,
  PROPERTIES_ANALYTICS_PROPERTY_AGES,
  PROPERTIES_ANALYTICS_PROPERTY_SIZES,
} from 'graphql/analyticsFindComps';
import { useQuery } from '@apollo/client';

export const useDonutsData = (filterIds: number[]) => {
  const variables = {
    search: {
      filter: {
        ids: filterIds,
        overlapIds: true,
      },
    },
  };
  const skip = !filterIds.length;

  const {
    data: propertyClassesData,
    loading: isLoadingPropertyClassesData,
  } = useQuery(PROPERTIES_ANALYTICS_PROPERTY_CLASSES, {
    variables,
    skip,
  });

  const {
    data: propertySubtypesData,
    loading: isLoadingPropertySubtypesData,
  } = useQuery(PROPERTIES_ANALYTICS_PROPERTY_SUBTYPES, {
    variables,
    skip,
  });

  const {
    data: propertyAgesData,
    loading: isLoadingPropertyAgesData,
  } = useQuery(PROPERTIES_ANALYTICS_PROPERTY_AGES, {
    variables,
    skip,
  });

  const {
    data: propertySizesData,
    loading: isLoadingPropertySizesData,
  } = useQuery(PROPERTIES_ANALYTICS_PROPERTY_SIZES, {
    variables,
    skip,
  });

  return {
    propertyClassesData,
    isLoadingPropertyClassesData,
    propertySubtypesData,
    isLoadingPropertySubtypesData,
    propertyAgesData,
    isLoadingPropertyAgesData,
    propertySizesData,
    isLoadingPropertySizesData,
  };
};
