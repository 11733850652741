import { IDataPoint, IGraphLeasesItem, IMarketAvg } from './interfaces';
import { RecentLeasesIds } from './graphIds';
import { MAX_YEARS_OF_DATA } from 'components/Graphs/RecentLeases/graphConstants';
import { sortBy } from 'lodash';
import {
  getUnitOfMeasurementForLease,
  getUnitOfMeasurementForProperty,
  isUsingMeters,
} from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { isMonthlyTimeMeasurement, isRentTypeNNN } from 'utils/leases';
import { getCurrentYear, getQuarterDates, getYearsDates } from 'utils/date';
import { fieldHasValue } from 'utils/objects';
import { IBubbleDataPoint } from 'components/Graphs/BubbleChartsElements/interfaces';
import { getTranslatedRentType } from 'utils/leases';
import { abbreviateNumber } from 'utils/formatters/number';
import { DOT_LG } from 'constants/placeholders';
import { pluralizeText } from 'utils/formatters/string';
import { formatArea } from 'utils/formatters/area';
import { LeaseRentType } from 'constants/leases';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

const PREVIOUS_FIVE_YEARS = 5;

export const getTooltipText = (
  node: IDataPoint,
  rentType: string,
  isMarketData: boolean,
  isQuarterly?: boolean,
) => {
  const currencySymbol = getCurrencySymbol(node.currencyCode);
  const translatedRentType = getTranslatedRentType(
    rentType || LeaseRentType.FS,
  );

  const rentValue = `${currencySymbol}${abbreviateNumber(
    isMarketData ? node.marketAverage : node.leaseValue,
  )} ${translatedRentType}`;

  const isMeter = isUsingMeters(node.unitOfMeasurement);

  if (isMarketData) {
    return `${rentValue} ${DOT_LG} ${node.leasesCount} ${pluralizeText(
      node.leasesCount || 0,
      translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease`),
      translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`),
    )} ${DOT_LG} ${formatArea(
      isMeter ? node.leasesTotalSizeSquareMeter : node.leasesTotalSizeSF,
      node.unitOfMeasurement,
    )} ${isQuarterly ? DOT_LG + ' Q' + node.quarter : ''}`;
  }

  return `${rentValue} ${DOT_LG} ${formatArea(
    node.leaseSize,
    node.unitOfMeasurement,
  )} ${isQuarterly ? DOT_LG + ' Q' + node.quarter : ''}`;
};

export const dateStrToTime = (dateStr: string /* YYYY-MM-DD */): number =>
  new Date(dateStr).getTime();

const getMarketAverageValue = (
  dataPoint?: IMarketAvg,
  rentType?: string,
  timeMeasurement?: string,
) => {
  if (!dataPoint) return null;
  const isNNN = isRentTypeNNN(rentType);
  if (isMonthlyTimeMeasurement(timeMeasurement)) {
    return isNNN ? dataPoint.NNNEquivValueMontly : dataPoint.valueMonthly;
  }

  return isNNN ? dataPoint.NNNEquivValue : dataPoint.value;
};

const getLeaseValue = (
  dataPoint: IGraphLeasesItem,
  rentType?: string,
  timeMeasurement?: string,
) => {
  const isNNN = isRentTypeNNN(rentType);
  if (isMonthlyTimeMeasurement(timeMeasurement)) {
    return isNNN
      ? dataPoint.baseRentNNNEquivMonthly
      : dataPoint.baseRentFullServiceEquivMonthly;
  }

  return isNNN
    ? dataPoint.baseRentNNNEquivAnnual
    : dataPoint.baseRentFullServiceEquivAnnual;
};

export const isValidLeaseValue = (value?: number) =>
  fieldHasValue(value) && value! >= 0;

export const getValidLeases = (leases: IGraphLeasesItem[], rentType: string) =>
  leases.filter(d =>
    isRentTypeNNN(rentType)
      ? isValidLeaseValue(d.baseRentNNNEquivAnnual) &&
        isValidLeaseValue(d.baseRentNNNEquivMonthly)
      : isValidLeaseValue(d.baseRentFullServiceEquivAnnual) &&
        isValidLeaseValue(d.baseRentFullServiceEquivMonthly),
  );

export const getValidMarketAverages = (
  averages: IMarketAvg[],
  rentType: string,
) =>
  averages.filter(d =>
    isRentTypeNNN(rentType)
      ? isValidLeaseValue(d.NNNEquivValue) &&
        isValidLeaseValue(d.NNNEquivValueMontly)
      : !!d.value && isValidLeaseValue(d.valueMonthly),
  );

/**
 * This function takes 2 arrays from mkt averages and leases data
 * and join them as an array of N IMarketAvg items
 * and always returns an array of 4 items per year as DataPoints
 * from the oldest item between mkt averages and leases to the most recent.
 * It works by making up quarter dates, and checking if the API data (averages & leases)
 * has a value for each of the made up quarters.
 */
export const prepareDataPoints = (params: {
  averages?: IMarketAvg[];
  graphId: RecentLeasesIds;
  leases: IGraphLeasesItem[];
  refEndDate?: Date;
  refStartDate?: Date;
  refMaxYears?: number;
  timeMeasurementType?: string;
  rentType?: string;
  isDefaultFilter?: boolean;
  isQuarterly?: boolean;
  currencyCode?: string;
}): IDataPoint[] => {
  if (
    !params?.averages ||
    !params?.leases ||
    (!params.averages.length && !params.leases.length)
  ) {
    return [];
  }

  const yearForRecentLeases = getCurrentYear() - PREVIOUS_FIVE_YEARS;
  const currencyCode =
    params.averages[0]?.currencyCode ||
    params?.leases[0]?.currencyCode ||
    params.currencyCode;
  const measurementSystem =
    params.averages[0]?.measurementSystem ||
    params?.leases[0]?.measurementSystem;

  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    measurementSystem,
  );

  // make up average items from the leases array
  const averagesFromLeases: IMarketAvg[] = params?.leases.map(d => ({
    date: d.date,
    value: 0,
    valueMonthly: 0,
    NNNEquivValue: 0,
    NNNEquivValueMontly: 0,
    propertyValue:
      getLeaseValue(d, params.rentType, params.timeMeasurementType) || 0,
    totalSizeSF: 0,
    totalSizeSquareMeters: 0,
    leasesCount: 0,
    measurementSystem,
    currencyCode,
  }));

  const averages = sortBy([...params.averages, ...averagesFromLeases], 'date');

  const firstAverageWithValue = averages.find(
    x => x.value > 0 || x.NNNEquivValue > 0,
  )!;
  const refEndDate = params.refEndDate || new Date();
  const refMaxYears = params.refMaxYears || MAX_YEARS_OF_DATA;
  const dates = params.isQuarterly
    ? getQuarterDates(refEndDate, refMaxYears)
    : getYearsDates(refEndDate, refMaxYears);

  const averagesByDates = dates
    .map(x => {
      const currentAverage = (params.averages || []).filter(
        average => average.date.substr(0, 10) === x.dateStr,
      );
      if (currentAverage.length) {
        return {
          key: `${x.year}-${x.quarter}`,
          leaseIds: currentAverage?.map(x => x.topItems),
        };
      }
    })
    .filter(x => x);

  let mountedDates = dates
    .map(d => {
      const marketAvgForCurrentQuarter = averages.find(x => {
        const dpDate = x.date.substr(0, 10);
        return dpDate === d.dateStr;
      });

      const itemValue =
        getMarketAverageValue(
          marketAvgForCurrentQuarter,
          params.rentType,
          params.timeMeasurementType,
        ) || null;

      /*
       * when no data is present for the given quarter
       * set hidden = true and marketAverage = NULL
       * and handle the NULL value in the .reduce() block later
       */

      const key = params.isQuarterly ? `${d.year}-${d.quarter}` : `${d.year}-1`;
      const quarter = params.isQuarterly ? d.quarter : 1;
      return {
        graphId: params.graphId,
        date: dateStrToTime(d.dateStr),
        year: d.year,
        quarter,
        key,
        marketAverage: itemValue,
        hidden: !marketAvgForCurrentQuarter,
        leaseValue: itemValue,
        leasesTotalSizeSF: marketAvgForCurrentQuarter?.totalSizeSF || null,
        leasesTotalSizeSquareMeter:
          marketAvgForCurrentQuarter?.totalSizeSquareMeters || null,
        leasesCount: marketAvgForCurrentQuarter?.leasesCount || null,
        unitOfMeasurement,
        currencyCode,
      };
    })
    .reduce<IDataPoint[]>((prev, current) => {
      const dataPoint = { ...current };

      /*
       * make up a value for data points with temporary NULL values:
       * even if we've set hidden = true to indicate that no data exists for that quarter,
       * the graph still needs a Y value to render the line
       *
       * we cannot use 0 as the value because the line will drop
       * in case the other values are higher than 0
       *
       * so the logic down here tries to use the value
       * of the previous data point, when exists.
       * when it doesn't exist, it uses the value of the earlier quarter with data
       */
      if (dataPoint.marketAverage === null) {
        if (prev.length) {
          dataPoint.marketAverage = prev[prev.length - 1].marketAverage;
        } else {
          dataPoint.marketAverage =
            getMarketAverageValue(
              firstAverageWithValue,
              params.rentType,
              params.timeMeasurementType,
            ) || 0;
        }
      }

      return [...prev, dataPoint as IDataPoint];
    }, []);

  mountedDates = mountedDates.map(x => ({
    ...x,
    leaseIds: averagesByDates?.find(average => average?.key === x.key)
      ?.leaseIds?.[0],
  }));

  return params.isDefaultFilter
    ? mountedDates.filter(
        item =>
          item.year >= yearForRecentLeases &&
          dataPointsStartDateConditional(item, params.refStartDate),
      )
    : mountedDates.filter(item =>
        dataPointsStartDateConditional(item, params.refStartDate),
      );
};

export const parseLeasesItemsToDataPoints = (params: {
  graphId: RecentLeasesIds;
  leases: IGraphLeasesItem[];
  rentType: string;
  measurementSystem?: string;
  timeMeasurementType?: string;
  refStartDate?: Date;
}): IDataPoint[] =>
  params.leases
    .map(x => {
      const unitOfMeasurement = getUnitOfMeasurementForLease(
        'size',
        x.measurementSystem || params.measurementSystem,
      );

      const leaseValue = getLeaseValue(
        x,
        params.rentType,
        params.timeMeasurementType,
      );

      const dataPoint = {
        date: dateStrToTime(x.date),
        graphId: params.graphId,
        id: x.id,
        key: `${x.year}-${x.quarter}`,
        leaseSize: isUsingMeters(unitOfMeasurement) ? x.sizeMt : x.size,
        leaseValue: leaseValue || x.value,
        marketAverage: 0,
        quarter: x.quarter,
        rentType: params.rentType,
        year: x.year,
        currencyCode: x.currencyCode,
        unitOfMeasurement,
        baseRent: leaseValue,
      };

      return {
        ...dataPoint,
        tooltipText: getTooltipText(dataPoint, params.rentType, false),
      };
    })
    .filter(item => dataPointsStartDateConditional(item, params.refStartDate));

const dataPointsStartDateConditional = (
  item: IDataPoint,
  refStartDate?: Date,
) => {
  return (
    (refStartDate && new Date(item.date) >= refStartDate) ||
    (!refStartDate && true)
  );
};

export const parseMarketDataToBubbleDataPoints = (
  dataPoints: IDataPoint[],
  rentType: string,
  isQuarterly?: boolean,
  allowEmptyPoints?: boolean,
): IBubbleDataPoint[] => {
  return dataPoints
    .map(dp => {
      return {
        id: dp.id,
        date: dp.date,
        year: dp.year,
        quarter: dp.quarter,
        graphId: dp.graphId,
        key: dp.key,
        value: dp.marketAverage,
        hidden: dp.hidden,
        data: dp,
        countData: dp.leasesCount,
        currencyCode: dp.currencyCode,
        unitOfMeasurement: dp.unitOfMeasurement,
        tooltipText: getTooltipText(dp, rentType, true, isQuarterly),
        leasesId: dp.leaseIds,
      };
    })
    .filter(dp => allowEmptyPoints || !!dp.leasesId?.length);
};

export const parseLeaseDataToBubbleDataPoints = (
  dataPoints: IDataPoint[],
  rentType: string,
  isQuarterly?: boolean,
  mainColor?: string,
  hoverColor?: string,
  borderColor?: string,
  disableColor?: string,
  disableBorderColor?: string,
): IBubbleDataPoint[] => {
  return dataPoints.map(dp => {
    return {
      id: dp.id,
      date: dp.date,
      year: dp.year,
      quarter: dp.quarter,
      graphId: dp.graphId,
      key: dp.key,
      value: dp.leaseValue || 0,
      bubbleSize: dp.leaseSize,
      hidden: dp.hidden,
      data: dp,
      countData: dp.leasesCount,
      currencyCode: dp.currencyCode,
      unitOfMeasurement: dp.unitOfMeasurement,
      tooltipText: getTooltipText(dp, rentType, false, isQuarterly),
      color: mainColor,
      hoverColor,
      borderColor,
      disableColor,
      disableBorderColor,
    };
  });
};
