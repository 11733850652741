import React, { useState } from 'react';
import locations from 'routes';
import { ILease } from 'interfaces/ILease';
import { IdName } from 'interfaces/IdName';
import TagsList from 'components/TagsList';
import { LeaseCardField } from '../fields';
import { CompType } from 'constants/compType';
import { IProperty } from 'interfaces/IProperty';
import { ColorNames } from 'constants/colorNames';
import styles from './ActivityLeaseCard.module.scss';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import { buildTagsListItems } from '../buildTagsListItems';
import { buildLeaseCardHeader } from '../buildLeaseCardHeader';
import {
  ActivityCardContainer,
  ActivityCardHeader,
} from 'components/ActivityCard';
import TenantInMarketFooter from '../TenantInMarketFooter';
import AYLink from 'components/AYLink';
import ActivityCardActions from 'components/ActivityCardActions';
import ModalEditComp from 'components/ModalEditComp';
import CompsComments from 'components/CompsComments';

interface Props {
  date: string;
  lease: ILease;
  property: IProperty;
  onUpdate?: () => void;
  displayFields: LeaseCardField[];
  onShareButtonClick: () => void;
}

const LEASE_SCOOP_CATEGORY: IdName = {
  id: 3,
  name: 'Lease',
};

const ActivityLeaseCard: React.FC<Props> = props => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const headerText = buildLeaseCardHeader(props.lease);
  const lease = { ...props.lease, property: props.property };
  const tags = buildTagsListItems(lease, props.displayFields);

  return (
    <>
      <ActivityCardContainer>
        <ActivityCardHeader
          headerText={headerText}
          property={props.property}
          targetPropertyUrl={locations.showProperty(props.property.id!)}
          categories={[LEASE_SCOOP_CATEGORY]}
          data={lease}
        />

        {props.lease.tenantCompany?.id && (
          <AYLink
            wrapperClassName={styles['tenant-company']}
            to={locations.showCompany(props.lease.tenantCompany!.id!)}
          >
            {props.lease.tenantCompany!.name}
          </AYLink>
        )}
        <Row>
          <Col xs={4} lg={12}>
            <TagsList
              tags={tags}
              tagBackgroundClass={styles[`background-${ColorNames.uiColor100}`]}
            />
          </Col>
        </Row>
        <div className={styles['card-separator']} />
        <Row>
          <Col>
            <TenantInMarketFooter lease={lease} />
          </Col>
        </Row>
        <Row>
          <Col>
            <CompsComments comments={lease.comments} />
          </Col>
        </Row>
        <ActivityCardActions
          onShareClick={props.onShareButtonClick}
          onEditClick={() => setIsEditEnabled(true)}
        />
      </ActivityCardContainer>
      {isEditEnabled && (
        <ModalEditComp
          type={CompType.lease}
          compId={props.lease.id!}
          onUpdate={props.onUpdate}
          onModalToggle={setIsEditEnabled}
        />
      )}
    </>
  );
};

export default ActivityLeaseCard;
