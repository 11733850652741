import React from 'react';
import { IdName } from 'interfaces/IdName';
import styles from './ScoopTypesInput.module.scss';
import { GET_SCOOP_CATEGORIES_QUERY } from 'graphql/scoops';
import { IScoopInput } from 'interfaces/inputs/IScoopInput';
import { getScoopsDataFormattedForRendering } from 'utils/scoops';
import RemoteDataFilterGroups from 'components/RemoteDataFilterGroups';
import { useTranslation } from 'react-i18next';

interface Props {
  scoopInput: IScoopInput;
  setScoop: (property: string, scoopValue: IScoopInput) => void;
}

const ScoopTypesInput: React.FC<React.PropsWithChildren<Props>> = ({
  scoopInput,
  setScoop,
}) => {
  const { t } = useTranslation();
  return (
    <RemoteDataFilterGroups
      hasHeaderLabel={true}
      isMultipleSelect={true}
      haveItemsBackground={false}
      query={GET_SCOOP_CATEGORIES_QUERY}
      itemWrapperClassName={styles.checkboxes}
      selectedItems={scoopInput?.scoopCategories}
      queryParser={(data: any) =>
        getScoopsDataFormattedForRendering(data?.scoopCategories, t)
      }
      onChangeMultiple={(selectedOption: IdName[]) => {
        setScoop('scoopCategories', selectedOption);
      }}
      groupOptionsParams={{
        groupBy: 'type',
        groupClassName: styles['scoop-types'],
      }}
    />
  );
};

export default ScoopTypesInput;
