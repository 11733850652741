import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Column, Row, useRowSelect, useSortBy, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { SortDirections } from 'constants/sortDirections';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import Button from 'components/Button/new';
import NotificationMessage from 'components/NotificationMessage';
import { translateText } from 'utils/i18n';

import { EditForms } from './EditForms';
import { TableViewHeader } from './TableViewHeader';
import { TableViewRows } from './TableViewRows';
import { HorizontalScrollBar } from './horizontalScrollBar';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import styles from './TableView.module.scss';
import { DataRow, TableType } from './types';

export const PAGE_SIZE = 50;

export const COLUMN_ID_SELECTION = 'selection';

const Table: React.FC<TableType> = (props: TableType) => {
  const {
    columns,
    data,
    hasEditButton,
    hasSelectItems,
    scrollClassName,
    uncheckedIds,
    setUncheckedIds,
    setIsSelectAllChecked,
    isSelectAllChecked,
    displayEditCriteriaBtn = true,
  } = props;

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const fixedHeadingsRef = useRef<HTMLDivElement>(null);
  const fixedHeadingsScrollableRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const prepareColumns = useMemo(() => columns, [columns]);
  const prepareData: any = useMemo(() => data, [data]);

  const editButtonWrapperRef = useRef<HTMLDivElement>(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showSuccessDeleteMessage, setShowSuccessDeleteMessage] = useState(
    false,
  );
  const [itemId, setItemId] = useState<string | undefined>();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    getToggleAllRowsSelectedProps,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,
    isAllRowsSelected,
  } = useTable(
    {
      columns: prepareColumns as Array<Column<DataRow>>,
      data: prepareData,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      initialState: {
        selectedRowIds: prepareData,
        hiddenColumns: props.hiddenColumns ?? [],
      },
    },
    useSticky,
    useSortBy,
    useRowSelect,
    hooks => {
      if (hasSelectItems) {
        hooks.visibleColumns.push(columns => [
          {
            sticky: 'left',
            width: 35,
            id: COLUMN_ID_SELECTION,
            header: ({}) => (
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps({
                  onChange: e => {
                    toggleAllRowsSelected(isAllRowsSelected);
                    getToggleAllRowsSelectedProps().onChange?.(e);
                    const isChecked = !getToggleAllRowsSelectedProps()?.checked;
                    const selectedIds = selectedFlatRows.map(
                      r => r.original.id,
                    ) as number[];
                    setUncheckedIds(!isChecked ? selectedIds : []);
                    setIsSelectAllChecked?.(isChecked);
                  },
                })}
              />
            ),
            Cell: ({ row }: { row: Row<any> }) => (
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps({
                  onChange: () => {
                    const id = row.original.id;
                    toggleRowSelected(row.id, !row.isSelected);

                    if (row.isSelected) {
                      setUncheckedIds(prevIds => [...prevIds, id as number]);
                    } else {
                      setUncheckedIds(prevIds =>
                        prevIds.filter(uid => uid !== id),
                      );
                    }
                  },
                })}
              />
            ),
          },
          ...columns,
        ]);
      }
    },
  );

  const defineRowSelection = useCallback(() => {
    const selectedIds: number[] = [];

    // all header rows checked (default)
    if (isSelectAllChecked) {
      data.forEach((r, i) => {
        // make every row true by default, except for rows which we unselected
        if (!uncheckedIds.includes(r.id!)) {
          selectedIds.push(r.id!);
          toggleRowSelected(i.toString(), true);
        } else {
          toggleRowSelected(i.toString(), false);
        }
      });
    }

    // unselect all header
    if (!isSelectAllChecked) {
      // select by one row
      selectedFlatRows.forEach(r => {
        toggleRowSelected(r.id.toString(), true);
        selectedIds.push(r.original.id!);
      });
    }

    props.onSelectedIds?.(selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uncheckedIds, data, isSelectAllChecked]);

  useEffect(() => {
    defineRowSelection();
  }, [defineRowSelection]);

  const setPaginateOrder = (columnName: string) => {
    if (columnName === COLUMN_ID_SELECTION) {
      return;
    }
    let orderDirection = SortDirections.desc;
    if (
      props.order?.field === columnName &&
      props.order?.direction === SortDirections.desc
    ) {
      orderDirection = SortDirections.asc;
    }

    const canOrder = props.onChangeOrder?.(columnName, orderDirection);

    if (canOrder) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  if (!data || !data.length) {
    return (
      <div className={styles['no-results-container']}>
        <p className={styles['empty-message']}>{props.noResultsMessage}</p>
        {displayEditCriteriaBtn && (
          <Button
            size="l"
            onClick={props.onClickEditSearch}
            type="ghost"
            label={translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.editCriteria`,
            )}
          />
        )}
      </div>
    );
  }

  /**
   * Colgroups are used to change the background of the columns.
   * Instead of changing the background of each TD element, changing it directly in the equivalent COL has a better performance.
   */
  const renderColGroups = () => {
    const headerGroup = headerGroups[0];
    const cols = headerGroup.headers.map(column => <col key={column.id} />);
    return <colgroup>{cols}</colgroup>;
  };

  const onHandleEditForm = () => {
    setShowEditForm(false);
    props.onChangeData?.();
  };

  const onHandleDeleteForm = () => {
    setShowEditForm(false);
    setShowSuccessDeleteMessage(true);
    props.onChangeData?.();
  };

  const renderEditButton = () => {
    if (!data?.length || !hasEditButton) {
      return null;
    }

    return (
      <div ref={editButtonWrapperRef} className={styles['edit-button-wrapper']}>
        <Button
          type="ghost"
          icon="edit"
          onClick={() => setShowEditForm(true)}
          wrapperClassName={styles['edit-button']}
        />
      </div>
    );
  };

  return (
    <>
      <div ref={tableContainerRef} className={styles['container']}>
        {/* Fixed header element. Used to create and display the table fixed heading at the top */}
        <div ref={fixedHeadingsRef} className={styles['fixed-table-heading']}>
          <div
            ref={fixedHeadingsScrollableRef}
            className={styles['fixed-heading-scrollable']}
          />
        </div>

        <table
          ref={tableRef}
          id={props.id}
          {...getTableProps()}
          className={styles['table-container']}
        >
          {renderColGroups()}
          <TableViewHeader
            headerGroups={headerGroups}
            setPaginateOrder={setPaginateOrder}
            order={props.order}
          />
          <tbody {...getTableBodyProps()}>
            <TableViewRows rows={rows} prepareRow={prepareRow} />
          </tbody>
        </table>

        <HorizontalScrollBar
          id={props.id}
          tableContainerRef={tableContainerRef}
          tableRef={tableRef}
          editButtonWrapperRef={editButtonWrapperRef}
          fixedHeadingsScrollableRef={fixedHeadingsScrollableRef}
          fixedHeadingsRef={fixedHeadingsRef}
          isActive={!!props.isActive}
          scrollClassName={scrollClassName}
          data={data}
          hasEditButton={hasEditButton}
          order={props.order}
          toggleAllRowsSelected={toggleAllRowsSelected}
          isAllRowsSelected={isAllRowsSelected}
          setPaginateOrder={setPaginateOrder}
          setItemId={setItemId}
        />

        <EditForms
          id={props.id}
          showEditForm={showEditForm}
          itemId={itemId}
          setShowEditForm={setShowEditForm}
          onHandleEditForm={onHandleEditForm}
          onHandleDeleteForm={onHandleDeleteForm}
        />
      </div>

      {renderEditButton()}

      <NotificationMessage
        show={showSuccessDeleteMessage}
        isSuccess
        text={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.compDeleted`,
        )}
        onClose={() => {
          setShowSuccessDeleteMessage(false);
        }}
      />
    </>
  );
};

export default Table;
