import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { colors } from '../../../../constants/colors';
import { GRAPH_DIMENSIONS, GraphClasses } from '../graphConstants';

type Props = {
  showFloors: boolean;
};

const TooltipLine: React.FC<Props> = (props: Props) => {
  const lineGroupRef = useRef(null);

  const { showFloors } = props;

  useEffect(() => {
    const node = lineGroupRef.current;

    const linesRoot = select(node)
      .style('z-index', 2)
      .append('svg')
      .style('position', 'absolute')
      .style('left', '0')
      .style('top', '0')
      .attr('width', GRAPH_DIMENSIONS.width + GRAPH_DIMENSIONS.padding)
      .attr('height', GRAPH_DIMENSIONS.height + GRAPH_DIMENSIONS.padding)
      .style('overflow', 'visible');

    const centerX = GRAPH_DIMENSIONS.width / 2 + GRAPH_DIMENSIONS.padding / 2;

    const blueLineHeight = showFloors ? 15 : 32;
    const greenLineHeight = showFloors ? 28 : 40;

    // bottom line
    linesRoot
      .append('line')
      .attr('class', GraphClasses.TooltipBottomLine)
      .attr('x1', centerX)
      .attr('y1', GRAPH_DIMENSIONS.height)
      .attr('x2', centerX)
      .attr('y2', GRAPH_DIMENSIONS.height + greenLineHeight)
      .attr('display', 'none')
      .attr('stroke', colors.ayPearGreenColor)
      .attr('stroke-width', 2);

    // top line
    linesRoot
      .append('line')
      .attr('class', GraphClasses.TooltipTopLine)
      .attr('x1', centerX)
      .attr('y1', 26)
      .attr('x2', centerX)
      .attr('y2', -blueLineHeight)
      .attr('display', 'none')
      .attr('stroke', colors.primaryColor500)
      .attr('stroke-width', 2);

    // eslint-disable-next-line
  }, []);

  return <div ref={lineGroupRef} />;
};

export default TooltipLine;
