import { MARKETS_BOUNDARIES_QUERY } from 'graphql/markets';
import { useQuery } from '@apollo/client';

export const useMarketBoundaries = (marketId?: number) => {
  /**
   * In order to load the map correctly even without properties, we need the coordinates from the market.
   * Using the Office type 1 because is a required field to run the boundaries query.
   */
  const { data: defaultBoundaries, loading: isLoadingBoundaries } = useQuery(
    MARKETS_BOUNDARIES_QUERY,
    {
      variables: {
        search: {
          filter: {
            marketId,
            propertyTypeId: 1,
          },
        },
      },
      skip: !marketId,
    },
  );

  return {
    defaultBoundaries,
    isLoadingBoundaries,
  };
};
