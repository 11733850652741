import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SNOWFLAKE_BLOCK_AVAILABILITIES_TIME_WIDE } from 'graphql/snowflakeBlockAvailability';
import { ISnowflakeBlockAvailabilityTimeWideResponse } from 'interfaces/ISnowflakeBlockAvailability';
import {
  getAvailabilitiesByProperties,
  getAverageRentData,
  getPropertyAvailabilityAskingRent,
} from 'components/PropertyProfile/Sections/utils';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { LeaseRentType } from '../constants/leases';
import { PropertyPricing } from '../constants/propertyPricing';

interface AvailabilityItem {
  id: number | null;
  averageRent: string | null;
}
interface AskingRentAvailabilities {
  annualAskingRentNNN: number | null;
  annualAskingRentFS: number | null;
  monthlyAskingRentFS: number | null;
  monthlyAskingRentNNN: number | null;
  availabilitiesByPropertiesAnnualNNN: AvailabilityItem[];
  availabilitiesByPropertiesMonthlyNNN: AvailabilityItem[];
  availabilitiesByPropertiesAnnualFS: AvailabilityItem[];
  availabilitiesByPropertiesMonthlyFS: AvailabilityItem[];
}

interface Props {
  propertiesIds: number[];
  toggleOptions: LeaseToggleOptions | undefined;
}
export const useAvailabilitiesOverTimeWide = ({
  propertiesIds,
  toggleOptions,
}: Props) => {
  const [availabilities, setAvailabilities] = useState<
    AskingRentAvailabilities
  >();

  const { data: availabilitiesOverTimeWideData, loading } = useQuery<
    ISnowflakeBlockAvailabilityTimeWideResponse
  >(GET_SNOWFLAKE_BLOCK_AVAILABILITIES_TIME_WIDE, {
    variables: {
      propertyIds: propertiesIds,
    },
    onCompleted: data => {
      setAvailabilities({
        annualAskingRentNNN: getAverageRentData(true, data, true),
        annualAskingRentFS: getAverageRentData(true, data, false),
        monthlyAskingRentNNN: getAverageRentData(false, data, true),
        monthlyAskingRentFS: getAverageRentData(false, data, false),
        availabilitiesByPropertiesAnnualNNN:
          getAvailabilitiesByProperties(data, true, true) ?? [],
        availabilitiesByPropertiesMonthlyNNN: getAvailabilitiesByProperties(
          data,
          true,
          false,
        ),
        availabilitiesByPropertiesAnnualFS: getAvailabilitiesByProperties(
          data,
          false,
          true,
        ),
        availabilitiesByPropertiesMonthlyFS: getAvailabilitiesByProperties(
          data,
          false,
          false,
        ),
      });
    },
    skip: !toggleOptions || !propertiesIds.length,
  });

  const getBuildingAskingRent = (
    availabilities: AskingRentAvailabilities,
    propertyId: number,
    isNNN: boolean,
    toggleOptions: LeaseToggleOptions,
  ) => {
    const isRentTypeNNN = isNNN
      ? true
      : toggleOptions.rentType === LeaseRentType.NNN;
    const isRentTimeAnnual =
      toggleOptions.timeMeasurement === PropertyPricing.ANNUAL;

    const buildingsValues = isRentTimeAnnual
      ? isRentTypeNNN
        ? availabilities.availabilitiesByPropertiesAnnualNNN
        : availabilities.availabilitiesByPropertiesAnnualFS
      : isRentTypeNNN
      ? availabilities.availabilitiesByPropertiesMonthlyNNN
      : availabilities.availabilitiesByPropertiesMonthlyFS;
    return getPropertyAvailabilityAskingRent(buildingsValues, propertyId);
  };

  const getMarketAskingRent = (
    availabilities: AskingRentAvailabilities,
    toggleOptions: LeaseToggleOptions,
    isNNN: boolean,
  ) => {
    const isRentTypeNNN = isNNN
      ? true
      : toggleOptions.rentType === LeaseRentType.NNN;
    const isRentTimeAnnual =
      toggleOptions.timeMeasurement === PropertyPricing.ANNUAL;
    return isRentTimeAnnual
      ? isRentTypeNNN
        ? availabilities.annualAskingRentNNN
        : availabilities.annualAskingRentFS
      : isRentTypeNNN
      ? availabilities.monthlyAskingRentNNN
      : availabilities.monthlyAskingRentFS;
  };

  return {
    availabilitiesOverTimeWideData,
    availabilities,
    loadingAvailabilitiesTimeWide: loading,
    getMarketAskingRent,
    getBuildingAskingRent,
  };
};
