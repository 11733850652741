import React from 'react';
import DocumentIcon from 'assets/images/icons/document.svg';
import { IAttachmentInput } from 'interfaces/inputs/IAttachmentInput';
import { isImage } from 'utils/validation/url';
import styles from './AttachmentThumbnail.module.scss';

const AttachmentThumbnail = ({
  attachment,
}: {
  attachment: IAttachmentInput;
}) => {
  const { file, document } = attachment;

  const attachmentIsImage = file
    ? file.type.includes('image')
    : isImage(document?.url);

  const renderImage = () => {
    const src = attachmentIsImage
      ? file
        ? URL.createObjectURL(file)
        : document?.url
      : DocumentIcon;

    return (
      <div className={styles['thumbnail-container']}>
        <div
          className={
            styles[attachmentIsImage ? 'thumbnail-image' : 'thumbnail-new']
          }
        >
          <img src={src} alt="thumbnail" />
        </div>
      </div>
    );
  };

  return <>{renderImage()}</>;
};

export default AttachmentThumbnail;
