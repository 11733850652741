import React from 'react';
import classNames from 'classnames';
import styles from './Tag.module.scss';

export interface Props {
  label: string;
  wrapperClassName?: string;
  onClick?: () => void;
}

const Tag: React.FC<Props> = props => {
  return (
    <div
      className={classNames(styles.chip, props.wrapperClassName)}
      onClick={props.onClick}
    >
      {props.label}
    </div>
  );
};

export default Tag;
