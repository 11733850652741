import React from 'react';
import ModalInformation from 'components/ModalInformation';
import {
  I18N_PLATFORM_COMMON_LABELS_PATH,
  I18N_AVANT_PROPERTY_LABEL_PATH,
} from 'constants/i18n';
import { translateText } from 'utils/i18n';
import styles from './ShareScoopsModalMessage.module.scss';

interface ShareScoopsModalMessageProps {
  onClose: () => void;
}

export const ShareScoopsModalMessage = ({
  onClose,
}: ShareScoopsModalMessageProps) => {
  return (
    <ModalInformation
      onClose={onClose}
      header={translateText(`${I18N_PLATFORM_COMMON_LABELS_PATH}.shareScoops`)}
      information={translateText(
        `${I18N_PLATFORM_COMMON_LABELS_PATH}.shareScoopsModalTitle`,
      )}
      buttonLabel={translateText(
        `${I18N_AVANT_PROPERTY_LABEL_PATH}.common.gotIt`,
      )}
      buttonWrapperClassName={styles['close-button']}
    />
  );
};
