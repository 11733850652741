export default (str: string, length: number, hasEllipsis: boolean) => {
  if (!str || str?.length < length)
    return {
      str,
      isTruncated: false,
    };
  const ellipsis = hasEllipsis ? '...' : '';
  const truncated = str.substring(0, length - ellipsis.length);
  return {
    str: `${truncated}${ellipsis}`,
    isTruncated: true,
  };
};
