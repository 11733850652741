import { IHistoricalPerformanceTable } from 'interfaces/IHistoricalPerformance';
import React from 'react';
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';
import styles from './EditTable.module.scss';
import { EditableColumn, ICellFooter, ILabelCell } from './interfaces';
import TableFooter from './TableFooter';

interface Props {
  cellsFooter: ICellFooter[];
  cellsLabels?: ILabelCell[];
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<{}> | undefined,
  ) => TableBodyProps;
  onClickPlaceholderRow: () => void;
  placeholderRow?: IHistoricalPerformanceTable;
  prepareRow: (row: Row<{}>) => void;
  rows: Row<{}>[];
}

const TableBody: React.FC<Props> = ({
  cellsFooter,
  cellsLabels,
  getTableBodyProps,
  onClickPlaceholderRow,
  placeholderRow,
  prepareRow,
  rows,
}) => (
  <tbody {...getTableBodyProps()}>
    {rows.map((row, index) => {
      prepareRow(row);
      return (
        <tr
          {...row.getRowProps()}
          key={`${index}-${row.id}`}
          className={styles['row-content']}
        >
          {row.cells.map((cell, j) => {
            const readOnly = (cell.column as EditableColumn).readOnly;
            const labelsCell = cellsLabels?.find(
              c => c.columnId === cell.column.id,
            );

            return (
              <td key={`${j}-${cell.value}`} className={styles['row-cell']}>
                <span className={styles['row-cell-content']}>
                  {labelsCell?.labelLeft}
                  {cell.render('Cell', {
                    readOnly: cell.value !== null ? readOnly : false,
                  })}
                  {labelsCell?.labelRight}
                </span>
              </td>
            );
          })}
        </tr>
      );
    })}
    {placeholderRow && (
      <TableFooter
        cellsFooter={cellsFooter}
        placeholderRow={placeholderRow}
        onClick={onClickPlaceholderRow}
      />
    )}
  </tbody>
);

export default TableBody;
