import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import styles from './InfiniteScroll.module.scss';
import BackToTopButton from './BackToTopButton';
import SimpleBar from 'simplebar';
import LoadingMessage from 'components/LoadingMessage';

interface Props {
  onPageEndReached: () => void;
  loading: boolean;
  loadingMessage: string;
  children: ReactNode;
  container?: HTMLDivElement | null;
}

const InfiniteScroll: React.FC<Props> = props => {
  const [scrollContainer, setScrollContainer] = useState<Element | undefined>();
  const { container, onPageEndReached, loading, loadingMessage } = props;

  useLayoutEffect(() => {
    if (container) {
      new SimpleBar(container, { autoHide: false });

      const scrollEl = SimpleBar.instances.get(container)?.getScrollElement();
      setScrollContainer(scrollEl);
    }
  }, [container]);

  useEffect(() => {
    const checkPageEndReached = () => {
      let scrollY, height;

      if (scrollContainer) {
        scrollY = scrollContainer.scrollTop;
        height = scrollContainer.clientHeight;
      } else {
        scrollY = window.scrollY + window.innerHeight;
        height = document.body.offsetHeight;
      }

      if (scrollY >= height) {
        onPageEndReached();
      }
    };

    const scrollEl = scrollContainer || window;

    scrollEl.addEventListener('scroll', checkPageEndReached);
    return () => scrollEl.removeEventListener('scroll', checkPageEndReached);
  }, [onPageEndReached, scrollContainer]);

  return (
    <div className={styles.container}>
      <BackToTopButton scrollContainer={scrollContainer} />
      <div>{props.children}</div>
      {loading && <LoadingMessage itemBeingLoaded={loadingMessage} />}
    </div>
  );
};

export default InfiniteScroll;
