import { useLazyQuery, ApolloError, useQuery } from '@apollo/client';
import { ErrorLogger } from 'services/ErrorLogger';
import {
  PROPERTY_SUBTYPES_QUERY,
  PROPERTY_SUBTYPEDETAILS_QUERY,
} from 'graphql/property';
import { IPropertySubType } from 'interfaces/IPropertySubType';
import { IdName } from 'interfaces/IdName';

export const useSubtypes = () => {
  const [get, { data, loading }] = useLazyQuery<{
    propertySubtypes: IPropertySubType[];
  }>(PROPERTY_SUBTYPES_QUERY, {
    onError: (error: ApolloError) => {
      ErrorLogger.log(error.message);
      console.error('Unable to load property subtypes', error.message);
    },
  });
  return { get, data, loading };
};

export const useSubtypeDetails = (subType: IdName | undefined) => {
  const { data, loading } = useQuery(PROPERTY_SUBTYPEDETAILS_QUERY, {
    variables: {
      search: {
        filter: {
          propertySubtypeId: subType?.id,
        },
      },
    },
    skip: !subType,
    onError: (error: ApolloError) => {
      ErrorLogger.log(error.message);
      console.error('Unable to load property subtype details', error.message);
    },
  });
  return { data, loading };
};
