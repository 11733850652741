import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights;
if (!!window._env_.APPINSIGHTS_INSTRUMENTATIONKEY) {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: window._env_.APPINSIGHTS_INSTRUMENTATIONKEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
}
export { reactPlugin, appInsights };
