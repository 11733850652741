import React from 'react';
import { FieldType } from 'components/CreateComps/FormSection/PropertyForm/types';
import { isUKCountry } from 'utils/properties';
import UKFields from './UKFields';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { ExclusiveFieldProps } from '../ExclusiveFields';

interface Props {
  addressCountry?: string | null;
  property: IPropertyInput;
  onChange: {
    [FieldType.common]: (fieldName: string, fieldValue: any) => void;
    [FieldType.office]: (fieldName: string, fieldValue: any) => void;
    [FieldType.industrial]: (fieldName: string, fieldValue: any) => void;
    [FieldType.dataCenter]: (fieldName: string, fieldValue: any) => void;
    [FieldType.healthcare]: (fieldName: string, fieldValue: any) => void;
    [FieldType.multifamily]: (fieldName: string, fieldValue: any) => void;
  };
  classNames: {
    formControlClassName: string;
    inputHelperClassName: string;
  };
}

export type RegionalFieldProps = ExclusiveFieldProps;

const RegionalFields: React.FC<Props> = ({
  addressCountry,
  property,
  onChange,
  classNames: { formControlClassName, inputHelperClassName },
}) => {
  return isUKCountry(property?.country || addressCountry) ? (
    <UKFields
      property={property}
      onChange={onChange[FieldType.common]}
      onChangeOffice={onChange[FieldType.office]}
      classNames={{
        formControlClassName: formControlClassName,
        inputHelperClassName: inputHelperClassName,
      }}
    />
  ) : null;
};

export default RegionalFields;
