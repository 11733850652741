import locations from 'routes';
import classNames from 'classnames';
import Button from 'components/Button/new';
import { IdName } from 'interfaces/IdName';
import { useHistory } from 'react-router-dom';
import { NAVBAR_HEIGHT } from 'components/Navbar';
import { PROPERTY_SECTIONS } from 'constants/propertySections';
import styles from './ActivityFeedContent.module.scss';
import { formatMarketsLabel } from 'utils/formatters/markets';
import React, { useLayoutEffect, useRef, useState } from 'react';

interface Props {
  index: number;
  markets?: IdName[];
  relativeDate: string;
  onMarketClick?: () => void;
}

const ActivityFeedGroupHeader: React.FC<Props> = props => {
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const [isTopGroup, setIsTopGroup] = useState(false);
  const [firstGroupY, setFirstGroupY] = useState<number | null>(null);

  const shouldRenderMarket =
    isTopGroup && props.markets && props.markets.length > 0;

  const isActivityPage =
    window.location.href.includes(locations.exploreActivity()) ||
    history.location.state === PROPERTY_SECTIONS.RECENT_ACTIVITY;

  useLayoutEffect(() => {
    if (ref.current && props.index === 0) {
      const firstGroupOffsetY = ref.current.getBoundingClientRect().y;
      setFirstGroupY(firstGroupOffsetY);
      setIsTopGroup(window.pageYOffset < firstGroupOffsetY);
    }

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    const onScroll = () => {
      if (!ref.current) return;

      const isDisplayedOnTop =
        (ref.current?.getBoundingClientRect().y || 0) <= NAVBAR_HEIGHT;

      const isScrollPosAboveFirstGroup =
        props.index === 0 && window.pageYOffset < (firstGroupY || 0);

      setIsTopGroup(isDisplayedOnTop || isScrollPosAboveFirstGroup);
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);

    // eslint-disable-next-line
  }, [firstGroupY]);

  const renderMarketsName = () =>
    shouldRenderMarket && (
      <Button
        size="xs"
        type="ghost"
        icon="mapPin"
        iconPosition="right"
        onClick={props.onMarketClick}
        wrapperClassName={styles['market-button']}
        label={formatMarketsLabel(props.markets!) || ''}
      />
    );

  return (
    <div
      ref={ref}
      className={classNames(styles['group-header'], {
        [styles[`group-header-activity-page`]]: isActivityPage,
      })}
    >
      <div className={styles.content}>
        <span>{`${props.relativeDate} ${
          shouldRenderMarket ? 'in ' : ''
        }`}</span>
        {renderMarketsName()}
      </div>
    </div>
  );
};

export default ActivityFeedGroupHeader;
