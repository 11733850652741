import { IProperty } from 'interfaces/IProperty';
import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  GET_PROPERTY_BASIC_DATA_QUERY,
  GET_PROPERTY_QUERY,
} from 'graphql/property';

// Remove Delay due to we are not using it anymore...
interface IUsePropertyOptions {
  delay?: number;
  useMinimumData?: boolean;
}

interface IUsePropertyResult {
  property: IProperty | undefined;
  isLoading: boolean;
}

let reqTimeout: any;

export const useProperty = (
  id: number | undefined,
  opts: IUsePropertyOptions = {},
): IUsePropertyResult => {
  const client = useApolloClient();
  const [property, setProperty] = useState<IProperty | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const { delay, useMinimumData } = opts;

  const query = useMinimumData
    ? GET_PROPERTY_BASIC_DATA_QUERY
    : GET_PROPERTY_QUERY;

  useEffect(() => {
    const getCachedProperty = (): IProperty | undefined => {
      try {
        const cachedRes = client.readQuery<{ property: IProperty }>({
          query,
          variables: { id },
        });

        return cachedRes?.property;
      } catch (e) {
        return undefined;
      }
    };

    const loadProperty = async () => {
      setIsLoading(true);
      const res = await client.query<{ property: IProperty }>({
        query,
        variables: { id },
      });

      setProperty(res?.data?.property);
      setIsLoading(false);
    };

    if (!id) {
      setIsLoading(false);
      setProperty(undefined);
      return;
    }

    const cachedProperty = getCachedProperty();
    if (!!cachedProperty) {
      setIsLoading(false);
      setProperty(cachedProperty);
      return;
    }

    if (delay) {
      reqTimeout = setTimeout(() => {
        clearTimeout(reqTimeout);
        id && loadProperty();
      }, delay);

      return () => clearTimeout(reqTimeout);
    }

    loadProperty();
  }, [id, client, delay, query]);

  return { property, isLoading };
};
