import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
} from 'constants/unitOfMeasurement';
import { IProperty } from 'interfaces/IProperty';

export const getUnitOfMeasurementForSale = (saleProperty: IProperty) =>
  getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    saleProperty.propertyCountry?.code || saleProperty.measurementSystem,
  );

export const updateSaleFieldsBasedOnUnit = (
  unitOfMeasurement: string,
  updateSaleProperty: (field: string, value: any) => void,
) => {
  if (unitOfMeasurement === UnitOfMeasurement.sf) {
    updateSaleProperty('squareMeters', undefined);
  } else {
    updateSaleProperty('sf', undefined);
  }
};
