import React from 'react';
import styles from './SpinnerLoader.module.scss';
import classnames from 'classnames';
import { ColorNames } from 'constants/colorNames';

interface Props {
  background?: ColorNames.ayTotalBlackColor | ColorNames.ayWhiteColor;
}

const NUM_SEGMENTS = 8;

const SpinnerLoader: React.FC<Props> = ({
  background = ColorNames.ayWhiteColor,
}) => (
  <div
    className={classnames(
      styles['lds-spinner'],
      styles[`background-${background}`],
    )}
  >
    {Array.from(Array(NUM_SEGMENTS).keys()).map(key => (
      <div className={styles['lds-spinner-segment']} key={key} />
    ))}
  </div>
);

export default SpinnerLoader;
