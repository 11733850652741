import AYLink from 'components/AYLink';
import { IScoop } from 'interfaces/IScoop';
import React from 'react';
import locations from 'routes';
import styles from './ScoopCompanies.module.scss';

interface Props {
  scoop: IScoop;
}

const ScoopCompanies: React.FC<Props> = ({ scoop }) => {
  const { companies } = scoop;
  if (!companies?.length) return null;

  return (
    <div className={styles['companies-container']}>
      {companies!.map(c => (
        <AYLink
          key={c.id}
          wrapperClassName={styles.company}
          to={locations.showCompany(c.mergedCompanyId || c.id!)}
        >
          {c.mergedCompany?.name || c.name}
        </AYLink>
      ))}
    </div>
  );
};

export default ScoopCompanies;
