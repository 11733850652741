import { IOption } from 'components/OptionsContainer';
import locations from 'routes';
import { CompType } from 'constants/compType';
import { IProperty } from 'interfaces/IProperty';
import * as H from 'history';

export const getAddDataOptions = (
  property: IProperty,
  history: H.History,
  onOpenEditProperty: () => void,
  onOpenPhotos: () => void,
): IOption[] => [
  {
    label: 'Add Photo',
    onClick: onOpenPhotos,
    type: 'photo',
  },
  {
    label: 'Add Lease Comp',
    onClick: () =>
      history.push(locations.newComps(), {
        sourceProperty: property,
        tabType: CompType.lease,
      }),
    type: 'leasing',
  },
  {
    label: 'Add Sale Comp',
    onClick: () =>
      history.push(locations.newComps(), {
        sourceProperty: property,
        tabType: CompType.sale,
      }),
    type: 'sale',
  },
  {
    label: 'Edit Property',
    onClick: onOpenEditProperty,
    type: 'edit',
  },
];
