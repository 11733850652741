import { CountryCodes } from 'constants/countryCodes';

export enum UnitOfMeasurement {
  acres = 'acres',
  feet = 'ft',
  hectares = 'ha',
  inches = 'in',
  meter = 'm',
  sf = 'sf',
  sm = 'sqm',
  kwh = 'KWH',
  kw = 'kW',
  psf = 'psf',
  pu = 'pu',
  perSqm = 'per sqm',
  perKw = 'per kW',
}

export enum UnitOfMeasurementNames {
  acres = 'Acres',
  feet = 'Feet',
  hectares = 'Hectares',
  inches = 'Inches',
  meter = 'Meters',
}

export const UNITS_MEASUREMENT_ABBREVIATION: { [key: string]: string } = {
  [UnitOfMeasurement.feet]: "'",
  [UnitOfMeasurement.inches]: "''",
  [UnitOfMeasurement.meter]: UnitOfMeasurement.meter,
  [UnitOfMeasurement.sf]: UnitOfMeasurement.sf,
  [UnitOfMeasurement.acres]: UnitOfMeasurement.acres,
  [UnitOfMeasurement.hectares]: UnitOfMeasurementNames.hectares,
  [UnitOfMeasurement.sm]: UnitOfMeasurement.sm,
  [UnitOfMeasurement.kw]: UnitOfMeasurement.kw,
};

export const UNITS_MEASUREMENT_AREA: { [key: string]: UnitOfMeasurement } = {
  undefined: UnitOfMeasurement.psf,
  null: UnitOfMeasurement.psf,
  [UnitOfMeasurement.sf]: UnitOfMeasurement.psf,
  [UnitOfMeasurement.sm]: UnitOfMeasurement.perSqm,
  [UnitOfMeasurement.kw]: UnitOfMeasurement.perKw,
  [UnitOfMeasurement.meter]: UnitOfMeasurement.meter,
  [UnitOfMeasurement.feet]: UnitOfMeasurement.feet,
  [UnitOfMeasurement.inches]: UnitOfMeasurement.inches,
};

export const UNITS_MEASUREMENT_NAMES: { [key: string]: string } = {
  [UnitOfMeasurement.sf]: 'feet',
  [UnitOfMeasurement.sm]: 'meters',
};

export enum UnitOfMeasurementCode {
  US = 'US',
  UK = 'UK',
  EU = 'EU',
  CA = 'CA',
}

export enum ModelsWithUnitsOfMeasurement {
  Property = 'Property',
  Office = 'Office',
  Industrial = 'Industrial',
  DataCenter = 'DataCenter',
  Multifamily = 'Multifamily',
  Lease = 'Lease',
  Sale = 'Sale',
  Availability = 'Availability',
  LandAndDevelopment = 'LandAndDevelopment',
  LifeScience = 'LifeScience',
}

export const UNITS_OF_MEASUREMENT_CODE_MAPPING: {
  [key: string]: UnitOfMeasurementCode;
} = {
  // US and equivalents units of measurement countries
  [CountryCodes.US]: UnitOfMeasurementCode.US,
  [CountryCodes.CA]: UnitOfMeasurementCode.US,

  // United Kingdom (UK) and equivalent units of measurement countries
  [CountryCodes.GB]: UnitOfMeasurementCode.UK,
  [CountryCodes.UK]: UnitOfMeasurementCode.UK,

  // Europe (EU) and equivalent units of measurement countries
  [CountryCodes.EU]: UnitOfMeasurementCode.EU,
  [CountryCodes.DE]: UnitOfMeasurementCode.EU,
  [CountryCodes.AD]: UnitOfMeasurementCode.EU,
  [CountryCodes.AL]: UnitOfMeasurementCode.EU,
  [CountryCodes.AT]: UnitOfMeasurementCode.EU,
  [CountryCodes.AX]: UnitOfMeasurementCode.EU,
  [CountryCodes.BA]: UnitOfMeasurementCode.EU,
  [CountryCodes.BE]: UnitOfMeasurementCode.EU,
  [CountryCodes.BG]: UnitOfMeasurementCode.EU,
  [CountryCodes.BY]: UnitOfMeasurementCode.EU,
  [CountryCodes.CH]: UnitOfMeasurementCode.EU,
  [CountryCodes.CY]: UnitOfMeasurementCode.EU,
  [CountryCodes.CZ]: UnitOfMeasurementCode.EU,
  [CountryCodes.DK]: UnitOfMeasurementCode.EU,
  [CountryCodes.EE]: UnitOfMeasurementCode.EU,
  [CountryCodes.ES]: UnitOfMeasurementCode.EU,
  [CountryCodes.FI]: UnitOfMeasurementCode.EU,
  [CountryCodes.FO]: UnitOfMeasurementCode.EU,
  [CountryCodes.FR]: UnitOfMeasurementCode.EU,
  [CountryCodes.GG]: UnitOfMeasurementCode.EU,
  [CountryCodes.GR]: UnitOfMeasurementCode.EU,
  [CountryCodes.HR]: UnitOfMeasurementCode.EU,
  [CountryCodes.HU]: UnitOfMeasurementCode.EU,
  [CountryCodes.IE]: UnitOfMeasurementCode.EU,
  [CountryCodes.IL]: UnitOfMeasurementCode.EU,
  [CountryCodes.IM]: UnitOfMeasurementCode.EU,
  [CountryCodes.IC]: UnitOfMeasurementCode.EU,
  [CountryCodes.IT]: UnitOfMeasurementCode.EU,
  [CountryCodes.JE]: UnitOfMeasurementCode.EU,
  [CountryCodes.LI]: UnitOfMeasurementCode.EU,
  [CountryCodes.LT]: UnitOfMeasurementCode.EU,
  [CountryCodes.LU]: UnitOfMeasurementCode.EU,
  [CountryCodes.LV]: UnitOfMeasurementCode.EU,
  [CountryCodes.MC]: UnitOfMeasurementCode.EU,
  [CountryCodes.MD]: UnitOfMeasurementCode.EU,
  [CountryCodes.MK]: UnitOfMeasurementCode.EU,
  [CountryCodes.MT]: UnitOfMeasurementCode.EU,
  [CountryCodes.NL]: UnitOfMeasurementCode.EU,
  [CountryCodes.NO]: UnitOfMeasurementCode.EU,
  [CountryCodes.PL]: UnitOfMeasurementCode.EU,
  [CountryCodes.PT]: UnitOfMeasurementCode.EU,
  [CountryCodes.RO]: UnitOfMeasurementCode.EU,
  [CountryCodes.RU]: UnitOfMeasurementCode.EU,
  [CountryCodes.SE]: UnitOfMeasurementCode.EU,
  [CountryCodes.SI]: UnitOfMeasurementCode.EU,
  [CountryCodes.SJ]: UnitOfMeasurementCode.EU,
  [CountryCodes.SK]: UnitOfMeasurementCode.EU,
  [CountryCodes.SM]: UnitOfMeasurementCode.EU,
  [CountryCodes.UA]: UnitOfMeasurementCode.EU,
  [CountryCodes.VA]: UnitOfMeasurementCode.EU,
};
