import { geospatialAuthContext } from './authHeaders';
import { createUploadLink } from 'apollo-upload-client';
import { customFetch } from 'graphql/customFetch';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

const uploadLink = createUploadLink({
  uri: window._env_ ? window._env_.GEOSPATIAL_API_URL : '',
  credentials: 'same-origin',
  fetch: customFetch,
});

const link = ApolloLink.from([geospatialAuthContext, uploadLink]);

export const geospatialClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  connectToDevTools:
    !window._env_.REACT_APP_ENV || window._env_.REACT_APP_ENV !== 'PROD',
});
