import {
  LeaseFinancialStatusType,
  LeaseRecordType,
  LeaseTenantInMarketType,
} from 'constants/leases';
import { DASH_PLACEHOLDER, DOT } from 'constants/placeholders';

// @TODO - Check if we can use a lib like this to stringfy enums for GraphQL queries properly: https://github.com/atulmy/gql-query-builder
const replaceLeaseEnumValues = (stringfiedQuery: string) => {
  return stringfiedQuery
    .replace(
      `financialStatuses:"${LeaseFinancialStatusType.CONFIRMED}"`,
      `financialStatuses:${LeaseFinancialStatusType.CONFIRMED}`,
    )
    .replace(
      `financialStatuses:"${LeaseFinancialStatusType.ESTIMATED}"`,
      `financialStatuses:${LeaseFinancialStatusType.ESTIMATED}`,
    )
    .replace(`"${LeaseRecordType.LEASE_COMPS}"`, LeaseRecordType.LEASE_COMPS)
    .replace(
      `"${LeaseRecordType.LEASE_EXPIRATIONS}"`,
      LeaseRecordType.LEASE_EXPIRATIONS,
    )
    .replace(`"${LeaseRecordType.ALL_TENANTS}"`, LeaseRecordType.ALL_TENANTS)
    .replace(
      `tenantInMarketTypes:["${LeaseTenantInMarketType.YES}"]`,
      `tenantInMarketTypes:[${LeaseTenantInMarketType.YES}]`,
    )
    .replace(
      `tenantInMarketTypes:["${LeaseTenantInMarketType.NO}"]`,
      `tenantInMarketTypes:[${LeaseTenantInMarketType.NO}]`,
    )
    .replace(
      `tenantInMarketTypes:["${LeaseTenantInMarketType.UNKNOWN}"]`,
      `tenantInMarketTypes:[${LeaseTenantInMarketType.UNKNOWN}]`,
    );
};

export const stringifyGraphQL = (obj: any, removeParamIds?: boolean) => {
  if (removeParamIds && obj['paramIds']) delete obj['paramIds'];

  const s = JSON.stringify(obj);
  return replaceLeaseEnumValues(s.replace(/"([^(")"]+)":/g, '$1:'));
};

export const enumerize = (value: any) =>
  value?.toUpperCase().replaceAll(/\s+/gi, '');

export const parseEnumGraphQL = (aEnum: any, value?: string | null) => {
  if (!value?.trim()) {
    return undefined;
  }

  return enumerize(
    Object.values(aEnum).find(
      (val: any) => enumerize(val) === enumerize(value),
    ),
  );
};

export const pluralizeText = (
  count: number,
  singularText: string,
  pluralText: string,
) => (count === 1 ? singularText : pluralText);

export const upperFirstLetter = (text?: string) => {
  if (!text) return text;
  return `${text[0].toUpperCase()}${text.substr(1).toLowerCase()}`;
};

export const upperWords = (text?: string) => {
  if (!text) return text;
  return text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

export const splitUppercase = (text?: string) => {
  if (!text) return text;
  return text
    .match(/([A-Z]?[^A-Z]*)/g)!
    .slice(0, -1)
    .join(' ');
};

export const normalizeStringId = (idValue: string) =>
  idValue.replace(/[^a-z0-9-]/gi, DASH_PLACEHOLDER);

export const formatStringUsingElipsis = (str: string, maxLength: number) => {
  const ellipsis = '...';
  return str.length <= maxLength
    ? str
    : `${str.substring(0, maxLength)}${ellipsis}`;
};

export const removeExtraSpaces = (str: string) => {
  return str.replace(/\s+/g, ' ').trim();
};

export const formatStringWithDot = (words: (string | undefined)[]) => {
  return words?.filter(Boolean).join(` ${DOT} `);
};

export const formatStringWithComma = (words: (string | undefined)[]) => {
  return words?.filter(Boolean).join(`, `);
};
