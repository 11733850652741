import React, { useEffect, useState } from 'react';
import { SEARCH_BUSINESS_PARK_QUERY } from 'graphql/business/queries';
import GenericSearchInput from 'base-components/GenericSearchInput';
import { useMutation } from '@apollo/client';
import { CREATE_BUSINESS_PARK_MUTATION } from 'graphql/business/mutations';
import { useTranslation } from 'react-i18next';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';

export type PropsType = {
  updateFn: (fieldName: string, fieldValue: unknown) => void;
  initialValue: string;
  marketId?: number;
};

export const BusinessParkSearchInput = (props: PropsType) => {
  const { t } = useTranslation();
  const { updateFn, initialValue, marketId } = props;
  const [searchInput, setSearchInput] = useState(initialValue);

  const onItemSelect = (value: string) => updateFn('businessPark', value);

  const [createBusinessPark, { data }] = useMutation(
    CREATE_BUSINESS_PARK_MUTATION,
    {
      variables: {
        businessPark: {
          name: searchInput,
          marketId,
        },
      },
    },
  );

  useEffect(() => {
    if (!data) return;
    const name = data?.createBusinessPark?.name;
    const id = data?.createBusinessPark?.id;
    const marketId = data?.createBusinessPark?.marketId;
    setSearchInput(name);
    updateFn('businessPark', { name, id, marketId });
  }, [data, updateFn]);

  useEffect(() => {
    if (searchInput?.length) return;
    updateFn('businessPark', null);
  }, [searchInput, updateFn]);

  return (
    <GenericSearchInput
      onItemSelect={onItemSelect}
      initialValue={initialValue}
      placeholder={t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.enterName`)}
      onSearchTermChange={setSearchInput}
      mapper={data => data.businessParks.results}
      valueMapper={businessPark => businessPark.name}
      graphqlQuery={SEARCH_BUSINESS_PARK_QUERY}
      graphqlVariables={{
        search: {
          name: searchInput,
          marketId,
        },
      }}
      createSearchedItem={createBusinessPark}
      createSearchedItemSuccess={data}
    />
  );
};
