import React from 'react';
import { IHoldPeriodData } from '../interfaces';
import { HoldPeriodSegment } from '../graphConstants';
import styles from '../HoldPeriod.module.scss';
import Badge from 'components/Badge';
import { colors } from 'constants/colors';
import { compact } from 'lodash';
import { abbreviateNumber } from 'utils/formatters/number';
import { isUsingMeters } from 'utils/unitsOfMeasurement';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_CHART_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

interface Props {
  data: IHoldPeriodData;
  isBuiltInTheFuture: boolean;
  isBuildingInfoVisible?: boolean;
  activeSegment: HoldPeriodSegment | null;
  unitOfMeasurement: string;
}

const DATA_TYPE = {
  MARKET: 'market',
  PROPERTY: 'property',
};

const Legend: React.FC<Props> = ({
  data,
  activeSegment,
  isBuiltInTheFuture,
  isBuildingInfoVisible,
  unitOfMeasurement,
}) => {
  const { MARKET, PROPERTY } = DATA_TYPE;
  const { primaryColor500, supportive500, primaryColor900 } = colors;
  const {
    mktAverageHoldYears,
    marketTotalCount,
    marketTotalSF,
    marketTotalSquareMeters,
    holdPeriodYears,
    buildingSaleSF,
    buildingSaleSquareMeters,
  } = data;

  const formatYearsLabel = (years: number) => {
    const yearLabel = translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.year`, {
      count: years,
    }).toLowerCase();
    const lessThanYear = translateText(
      `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.holdPeriod.lessThanYear`,
    );
    return years === 0 ? lessThanYear : `${years} ${yearLabel}`;
  };

  const getLegendBadge = (content: any, type: string) => (
    <Badge
      color={primaryColor900}
      bgColor={type === MARKET ? primaryColor500 : supportive500}
      wrapperClassName={styles[`${type}-legend`]}
    >
      {content}
    </Badge>
  );

  const useMeters = isUsingMeters(unitOfMeasurement);

  const renderMarketLegend = () => {
    const marketYears = formatYearsLabel(mktAverageHoldYears);
    const totalSFFormattedValue = `${abbreviateNumber(
      useMeters ? marketTotalSquareMeters : marketTotalSF,
    )} ${unitOfMeasurement}`;

    const marketPropertiesLabel = translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.property`,
      { count: marketTotalCount },
    );

    const badgeContent =
      activeSegment === HoldPeriodSegment.market ? (
        <dl className={styles[`${MARKET}-legend-list`]}>
          <dd>{marketYears}</dd>
          {marketTotalCount && (
            <dd>
              {marketTotalCount} {marketPropertiesLabel}
            </dd>
          )}
          {!!marketTotalSF && <dd>{totalSFFormattedValue}</dd>}
        </dl>
      ) : (
        <p>{marketYears}</p>
      );

    return getLegendBadge(badgeContent, MARKET);
  };

  const renderPropertyLegend = () => {
    if (!isBuildingInfoVisible) return null;
    const propertyYears = formatYearsLabel(holdPeriodYears);
    const buildingSaleValue = `${abbreviateNumber(
      useMeters ? buildingSaleSquareMeters : buildingSaleSF,
    )} ${unitOfMeasurement}`;
    const badgeContent =
      activeSegment === HoldPeriodSegment.property ? (
        <dl className={styles[`${PROPERTY}-legend-list`]}>
          <dd>{propertyYears}</dd>
          {!!buildingSaleSF && <dd>{buildingSaleValue}</dd>}
        </dl>
      ) : (
        <p>{propertyYears}</p>
      );

    return getLegendBadge(badgeContent, PROPERTY);
  };

  const renderLegends = () => {
    const legends = compact([
      !isBuiltInTheFuture && renderPropertyLegend(),
      renderMarketLegend(),
    ]);
    const result =
      holdPeriodYears < mktAverageHoldYears ? legends : legends.reverse();
    return result.map((legend, key) => React.cloneElement(legend, { key }));
  };

  return <div className={styles['legend-container']}>{renderLegends()}</div>;
};

export default Legend;
