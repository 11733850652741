import React, { useContext, useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';
import classnames from 'classnames';
import { REGEX_INTEGERS } from 'constants/regex';
import { authContext } from 'contexts/AuthContext';
import { LanguageEnum } from 'constants/languages';
import { handleValidation } from 'components/Inputs/DateInput/utils';
import {
  ICustomValidation,
  MONTH_MAX_LENGTH,
  DAY_MAX_LENGTH,
  YEAR_MAX_LENGTH,
  SEPARATOR,
} from 'components/Inputs/DateInput/constants';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import styles from './DateInput.module.scss';

export interface Props {
  customValidation?: ICustomValidation;
  id?: string;
  isDisabled?: boolean;
  onChange?: (value: string | null) => void;
  onBlur?: (value: string) => void;
  skipValidation?: boolean;
  value?: string | null;
  wrapperClassName?: string;
  errorClassName?: string;
  allowEmpty?: boolean;
}

const DateInput: React.FC<Props> = props => {
  const {
    customValidation,
    id,
    isDisabled,
    onChange,
    onBlur,
    skipValidation,
    value,
    wrapperClassName,
    errorClassName = '',
    allowEmpty,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [validation, setValidation] = useState<ICustomValidation>({
    isValid: true,
    message: '',
  });

  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const { user } = useContext(authContext);
  const nonAmericanUser = user.language === LanguageEnum.EN_GB;

  useEffect(() => {
    if (!isFocused) {
      const dateParts = value?.split(DASH_PLACEHOLDER);

      if (dateParts?.length !== 3) return;

      setDay(dateParts[2]);
      setMonth(dateParts[1]);
      setYear(dateParts[0]);
    }
  }, [value, isFocused]);

  useEffect(() => {
    if (isNil(value)) {
      setDay('');
      setMonth('');
      setYear('');
    }
  }, [value]);

  useEffect(() => {
    if (isFocused)
      handleValidation(
        day,
        month,
        year,
        setValidation,
        onChange,
        customValidation,
        skipValidation,
        allowEmpty,
      );
    // eslint-disable-next-line
  }, [
    day,
    month,
    year,
    isFocused,
    customValidation?.isValid,
    allowEmpty,
    setValidation,
  ]);

  useEffect(() => {
    if (month.length === MONTH_MAX_LENGTH && isFocused) {
      nonAmericanUser ? yearRef?.current?.focus() : dayRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, nonAmericanUser]);

  useEffect(() => {
    if (day.length === DAY_MAX_LENGTH && isFocused) {
      nonAmericanUser ? monthRef?.current?.focus() : yearRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, nonAmericanUser]);

  const onDatePartFocus = () => setIsFocused(true);

  const onDatePartBlur = () => {
    dayRef.current?.blur();
    monthRef.current?.blur();
    yearRef.current?.blur();

    if (!onBlur) return;

    const value = `${year}-${month}-${day}`;
    onBlur?.(value);
    setIsFocused(false);
  };

  const handleInputChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (REGEX_INTEGERS.test(value)) setter(value);
  };

  const renderInput = (
    idSuffix: string,
    value: string,
    ref: React.RefObject<HTMLInputElement>,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    maxLength: number,
    placeholder: string,
  ) => (
    <input
      className={classnames(`date-input-${idSuffix}`, styles[idSuffix])}
      maxLength={maxLength}
      onBlur={onDatePartBlur}
      onChange={onChange}
      onFocus={onDatePartFocus}
      placeholder={isDisabled ? DASH_PLACEHOLDER : placeholder}
      ref={ref}
      type="text"
      value={value}
      disabled={isDisabled}
      id={`${id}-${idSuffix}`}
      data-testid={`${id}-${idSuffix}`}
    />
  );

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles['date-input'], wrapperClassName, {
          [styles['focus']]: isFocused,
          [styles['error']]: !validation.isValid,
          [errorClassName]: errorClassName && !validation.isValid,
          [styles.disabled]: isDisabled,
        })}
      >
        {nonAmericanUser
          ? renderInput(
              'day',
              day,
              dayRef,
              handleInputChange(setDay),
              DAY_MAX_LENGTH,
              'DD',
            )
          : renderInput(
              'month',
              month,
              monthRef,
              handleInputChange(setMonth),
              MONTH_MAX_LENGTH,
              'MM',
            )}
        <span className={styles.separator}>{SEPARATOR}</span>
        {nonAmericanUser
          ? renderInput(
              'month',
              month,
              monthRef,
              handleInputChange(setMonth),
              MONTH_MAX_LENGTH,
              'MM',
            )
          : renderInput(
              'day',
              day,
              dayRef,
              handleInputChange(setDay),
              DAY_MAX_LENGTH,
              'DD',
            )}
        <span className={styles.separator}>{SEPARATOR}</span>
        {renderInput(
          'year',
          year,
          yearRef,
          handleInputChange(setYear),
          YEAR_MAX_LENGTH,
          'YYYY',
        )}
      </div>
      {!validation.isValid && (
        <p className={styles['error-message']}>{validation.message}</p>
      )}
    </div>
  );
};

export default DateInput;
