import React from 'react';
import classnames from 'classnames';

import TextHighlighter from 'components/TextHighlighter';

import styles from './SearchSuggestionItem.module.scss';
import { SearchSuggestionItemProps } from './types';

const SearchSuggestionItem = ({
  className,
  textClassName,
  searchText = '',
  textToHighlight = '',
}: SearchSuggestionItemProps) => (
  <div className={classnames(styles['search-suggestion-item'], className)}>
    <span className={classnames(styles['item-text'], textClassName)}>
      <TextHighlighter
        searchString={searchText}
        textToHighlight={textToHighlight}
        useBold
      />
    </span>
  </div>
);

export default SearchSuggestionItem;
