import React, { useRef, useState } from 'react';
import styles from './OptionsContainer.module.scss';
import classnames from 'classnames';
import Icon from 'components/Icon';
import { ICON_COLORS } from 'components/Button/new/icon-colors';
import { useOutsideElementClick } from 'hooks/useOutsideElementClick';

export type OptionContainerStyle =
  | 'main'
  | 'contextual'
  | 'contextual-light'
  | 'contextual-positive'
  | 'contextual-transparent';

export type GroupOption = {
  title?: string;
  customOptions?: JSX.Element[];
  titleClassName?: string;
};

interface Props {
  customButtonStyle?: string;
  isDark?: boolean;
  isLeftAlign?: boolean;
  isLightButton?: boolean;
  isMedium?: boolean;
  isSmall?: boolean;
  optionButtonIcon?: string;
  optionButtonInitialColor?: string;
  optionButtonLabel?: string;
  optionContainerStyle?: OptionContainerStyle;
  optionsWrapperClassName?: string;
  optionsIconClassName?: string;
  testId?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  optionClassName?: string;
  groupOptions: GroupOption[];
}

const DEFAULT_OPTION_CONTAINER_STYLE = 'contextual-light';

const GroupOptionsContainer: React.FC<Props> = ({
  customButtonStyle,
  isDark,
  isLeftAlign,
  isLightButton,
  isMedium,
  isSmall,
  optionButtonIcon,
  optionButtonInitialColor,
  optionButtonLabel,
  optionContainerStyle,
  optionsIconClassName,
  optionsWrapperClassName,
  testId,
  wrapperClassName,
  disabled,
  groupOptions,
}) => {
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  const containerStyle =
    styles[optionContainerStyle || DEFAULT_OPTION_CONTAINER_STYLE];

  useOutsideElementClick(optionsContainerRef, () => setShowOptions(false));

  const getIconColor = (customIdleColor?: string) => {
    const { idle, active } = ICON_COLORS[
      optionContainerStyle || DEFAULT_OPTION_CONTAINER_STYLE
    ];
    return showOptions && optionContainerStyle !== 'contextual-transparent'
      ? active
      : customIdleColor
      ? customIdleColor
      : idle;
  };

  const renderOptions = () => {
    return (
      <>
        {groupOptions.map((option, index) => {
          return (
            <div key={`${option.title}-${index}`}>
              {option?.title && (
                <div className={option.titleClassName}>
                  <label>{option.title}</label>
                </div>
              )}
              {option.customOptions?.map(customOption => customOption)}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={classnames(styles['options-container'], wrapperClassName)}>
      <div
        data-testid={testId}
        className={classnames(
          styles['options-button'],
          customButtonStyle,
          containerStyle,
          {
            [styles['active']]: showOptions,
            [styles['small']]: isSmall,
            [styles['medium']]: isMedium,
            [styles['dark']]: isDark,
            [styles['light-button']]: isLightButton,
            [styles['disabled']]: disabled,
          },
        )}
        role="button"
        onClick={() => !disabled && setShowOptions(true)}
      >
        {optionButtonIcon && (
          <div
            className={classnames(
              styles['options-button-icon-container'],
              optionsIconClassName,
            )}
          >
            <Icon
              name={optionButtonIcon}
              color={getIconColor(optionButtonInitialColor)}
            />
          </div>
        )}
        {optionButtonLabel}
      </div>
      <div
        ref={optionsContainerRef}
        className={classnames(
          styles.options,
          containerStyle,
          {
            [styles.show]: showOptions,
            [styles.left]: isLeftAlign,
          },
          optionsWrapperClassName,
        )}
      >
        {renderOptions()}
      </div>
    </div>
  );
};

export default GroupOptionsContainer;
