import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import FormControl from 'components/FormControl';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { ITIM, SearchTimsQueryDataType } from 'interfaces/ITIM';
import { DOT } from 'constants/placeholders';
import { I18N_PROPERTY_COMMON_WORD_PATH } from 'constants/i18n';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';

import TImRecordSearchInput from '../TImRecordSearchInput';

import { SEARCH_TIMS_QUERY } from 'graphql/tims/queries';

export interface Props {
  currentPropertyCompany: IPropertyCompany | null;
  onClickSuggestion?: (tim: ITIM) => void;
  inputId?: string;
  placeholder?: string;
  leaseInput: ILeaseInput;
  hasLabel?: boolean;
  label?: string;
  wrapperClassName?: string;
  marketId?: number;
  marketName?: string;
  tenantInMarketId?: number | null;
  onClearSelectedSuggestion: () => void;
}

const PAGE = 1;
const SIZE = 50;
const TenantSearchCombo: React.FC<Props> = ({
  currentPropertyCompany,
  leaseInput,
  hasLabel = true,
  label,
  wrapperClassName,
  onClickSuggestion,
  marketId,
  marketName,
  tenantInMarketId,
  onClearSelectedSuggestion,
}) => {
  const { t } = useTranslation();
  const isCreate = !leaseInput?.id;

  const defaultMarket =
    leaseInput.property?.market || currentPropertyCompany?.marketName;

  const targetMarket = defaultMarket ? defaultMarket : marketName ?? '';
  const tenantCompany = leaseInput?.tenantCompany?.name ?? '';

  const inputTextValue =
    !!targetMarket && !!tenantCompany
      ? `${targetMarket} ${DOT} ${tenantCompany}`
      : '';

  const tenantMarketFilterId = isCreate
    ? currentPropertyCompany?.marketId ?? marketId
    : marketId;
  const tenantCompanyFilterId = leaseInput?.tenantCompany?.id;

  const getFilterQuery = () => {
    const filter: any = {};
    if (!!tenantInMarketId) {
      filter.ids = [tenantInMarketId];
    } else {
      filter.targetMarkets = [{ id: tenantMarketFilterId }];
      filter.tenantCompanies = [{ id: tenantCompanyFilterId }];
      filter.timStatusTypes = [1, 2, 4];
    }

    return filter;
  };

  const { data, loading } = useQuery<SearchTimsQueryDataType>(
    SEARCH_TIMS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        search: {
          page: {
            page: PAGE,
            size: SIZE,
          },
          filter: getFilterQuery(),
        },
      },
      skip: !inputTextValue || !tenantMarketFilterId || !tenantCompanyFilterId,
    },
  );
  const renderInput = () => (
    <TImRecordSearchInput
      data={data}
      loading={loading}
      isDisabled={!tenantCompany || !targetMarket}
      placeholder={
        inputTextValue.trim() ??
        t(`${I18N_PROPERTY_COMMON_WORD_PATH}.selectTIMRecord`)
      }
      initialValue={inputTextValue}
      onClickSuggestion={(tim: ITIM) => onClickSuggestion?.(tim)}
      onClearSelectedSuggestion={onClearSelectedSuggestion}
      showClearIcon={
        isCreate ? !!leaseInput?.tenantInMarketId : !!tenantInMarketId
      }
    />
  );

  return (
    <>
      {hasLabel ? (
        <FormControl
          label={label}
          required={false}
          wrapperClassName={wrapperClassName}
          disabled={!tenantCompany || !targetMarket}
        >
          {renderInput()}
        </FormControl>
      ) : (
        renderInput()
      )}
    </>
  );
};

export default TenantSearchCombo;
