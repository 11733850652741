import { IdName } from 'interfaces/IdName';
import { optionsValues } from './constants';

export const isTrue = (value?: boolean) =>
  ['true', 'false'].includes(String(value));

export const getValuesAsIdName = (value?: boolean): IdName | undefined =>
  isTrue(value)
    ? {
        id: String(value) === 'true' ? optionsValues.yes : optionsValues.no,
        name: String(value) === 'true' ? optionsValues.yes : optionsValues.no,
      }
    : undefined;

export const getBrokersValue = (value: { raw: { name: any }; name: any }) =>
  value && {
    company: value?.raw?.name || value.name,
  };
