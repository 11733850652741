export enum GraphIds {
  FirstRectBar = 'hold-period-property-bar',
  FirstRectShadowBar = 'hold-period-property-shadow-bar',
  SecondRectBar = 'hold-period-market-bar',
  SecondRectShadowBar = 'hold-period-market-shadow-bar',
  TooltipQuarter = 'hold-period-quarter-tooltip',
  TooltipHeldYears = 'hold-period-held-years-tooltip',
  TooltipCurrentDate = 'hold-period-current-date-tooltip',
  TooltipAvgHold = 'hold-period-avg-hold-tooltip',
}
