import { getCompanyUrlLogo } from 'utils/clearbit';
import { removeHTTPFromURL } from 'utils/formatters/url';
import { useCallback, useEffect, useState } from 'react';
import companyIcon from 'assets/images/icons/company.svg';
import { ICompany } from 'interfaces/ICompany';

const useGetCompanyLogo = () => {
  const getCompanyLogo = useCallback(async (company?: ICompany) => {
    const companyLogo = company?.logoUrl;
    if (companyLogo) return companyLogo;

    const formattedWebsite =
      company?.website && removeHTTPFromURL(company?.website);

    const clearbitResult = await getCompanyUrlLogo(formattedWebsite!);
    if (clearbitResult) return clearbitResult;
  }, []);

  return { getCompanyLogo };
};

const useCompanyLogo = (company?: ICompany) => {
  const [companyLogo, setCompanyLogo] = useState<string | undefined>(
    company?.logoUrl,
  );
  const { getCompanyLogo } = useGetCompanyLogo();

  useEffect(() => {
    const fetchLogo = async () => {
      const logo = await getCompanyLogo(company);
      setCompanyLogo(logo);
    };
    fetchLogo();
  }, [company, getCompanyLogo]);
  return { companyLogo: companyLogo || companyIcon };
};

export { useCompanyLogo, useGetCompanyLogo };
