import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';

import Icon from 'components/Icon';
import { colors } from 'constants/colors';

import SideNavigationItem from './SideNavigationItem';
import styles from './SideNavigationBar.module.scss';
import { SideNavigationBarProps } from './types';
import { WINDOW_OFFSET } from 'constants/scroll';

export const WIDTH = 208;
export const COLLAPSED_WIDTH = 56;

const handleScroll = (element: HTMLElement, offset: number) => {
  const { top } = element.getBoundingClientRect();
  const elementPosition = top + window.scrollY - offset;

  window.scrollTo({
    top: elementPosition,
    behavior: 'smooth',
  });
};

const renderItems = (
  items: SideNavigationBarProps['items'],
  activeItem: SideNavigationBarProps['activeItem'],
  offset: SideNavigationBarProps['offset'] = 0,
  itemClassName: SideNavigationBarProps['itemClassName'],
  onClickItem: SideNavigationBarProps['onClickItem'] = noop,
  isBarCollapsed: boolean,
) => (
  <div className={styles['item-list']}>
    {items.map(item => {
      const { name, to, label, icon, subitems = [], isDisabled } = item;
      const isActive = activeItem === name;

      return (
        <Fragment key={item.name}>
          <SideNavigationItem
            name={name}
            to={to}
            className={itemClassName}
            icon={icon}
            label={label}
            isActive={isActive}
            isDisabled={isDisabled}
            onClick={onClickItem}
            onScroll={element => handleScroll(element, offset)}
            isBarCollapsed={isBarCollapsed}
          />
          {(isActive ||
            !!subitems.find(subitem => subitem.name === activeItem)) && (
            <div className={styles['subitem-list']}>
              {subitems.map(
                ({
                  name: subitemName,
                  to: subitemTo,
                  icon: subitemIcon,
                  label: subitemLabel,
                  isDisabled: subitemDisabled,
                }) => (
                  <SideNavigationItem
                    isSubitem
                    key={subitemName}
                    name={subitemName}
                    to={subitemTo}
                    className={itemClassName}
                    icon={subitemIcon}
                    label={subitemLabel}
                    isActive={subitemName === activeItem}
                    isDisabled={subitemDisabled}
                    onClick={onClickItem}
                    onScroll={element => handleScroll(element, offset)}
                    isBarCollapsed={isBarCollapsed}
                  />
                ),
              )}
            </div>
          )}
        </Fragment>
      );
    })}
  </div>
);

const SideNavigationBar = ({
  className,
  itemClassName,
  items,
  activeItem,
  isCollapsed = false,
  offset,
  onClickItem = noop,
  onCollapse = noop,
}: SideNavigationBarProps) => {
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const [currentActiveItem, setCurrentActiveItem] = useState(activeItem);

  useEffect(() => {
    setCurrentActiveItem(activeItem);
  }, [activeItem]);

  const handleItemClick = (itemName: string) => {
    setCurrentActiveItem(itemName);
    onClickItem(itemName);
  };

  const toggleCollapsedFlag = () => {
    const newCollapsedStatus = !collapsed;

    setCollapsed(newCollapsedStatus);
    onCollapse(newCollapsedStatus);
  };

  return (
    <div
      className={classnames(styles['side-navigation-bar'], className)}
      style={{
        top: WINDOW_OFFSET,
        width: collapsed ? COLLAPSED_WIDTH : WIDTH,
      }}
    >
      {renderItems(
        items,
        currentActiveItem,
        offset,
        itemClassName,
        handleItemClick,
        collapsed,
      )}
      <div
        className={classnames(styles.footer, {
          [styles.collapsed]: collapsed,
        })}
      >
        <Icon
          className={styles['footer-icon']}
          name="arrowLeft"
          color={colors.primaryColor500}
          onClick={toggleCollapsedFlag}
          size={1.25}
        />
      </div>
    </div>
  );
};

export default SideNavigationBar;
