import {
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
} from 'constants/i18n';

export const I18N_MODAL_MEDIA_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.mediaModal`;
export const I18N_MODAL_MEDIA_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.mediaModal`;

export const I18N_MODAL_MEDIA_PICTURE_LABEL_PATH = `${I18N_MODAL_MEDIA_LABEL_PATH}.pictures`;
export const I18N_MODAL_MEDIA_PICTURE_TEXT_PATH = `${I18N_MODAL_MEDIA_TEXT_PATH}.pictures`;

export const I18N_MODAL_MEDIA_DOCUMENT_LABEL_PATH = `${I18N_MODAL_MEDIA_LABEL_PATH}.documents`;
export const I18N_MODAL_MEDIA_DOCUMENT_TEXT_PATH = `${I18N_MODAL_MEDIA_TEXT_PATH}.documents`;
