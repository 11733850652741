import {
  FindCompCriteriaTabs,
  FindCompTabs,
  FindCompTabsViews,
} from 'constants/FindCompTabs';

export const ID_PATH_PARAM = ':id';
export const COMPANY_ID_PATH_PARAM = ':companyId';
export const PROPERTIES_ID_PATH_PARAM = ':propertyId';
export const COMP_TYPE_PATH_PARAM = ':type(lease|sale|property|tim)';
export const SUBMARKET_TYPE_PARAM = ':propertyTypeId';
export const FIND_COMPS_TAB_PARAM = 't';
export const FIND_COMPS_CRITERIA_TAB_PARAM = 'ct';
export const FIND_COMPS_TAB_VIEW_PARAM = 'tv';
export const REF_REDIRECT_PARAM = 'ref';
export const FIND_COMPS_ADVANCED_FILTER = 'af';
export const TABLEAU_DASHBOARD_PARAM = 'tdp';

export type FindCompsParam = {
  criteriaActiveTab?: FindCompCriteriaTabs;
  resultActiveTab?: FindCompTabs;
  urlParams?: string;
  activeTabView?: FindCompTabsViews;
  showAdvancedFilter?: boolean;
};

export type TableauParam = {
  dashboardId?: number;
};

// @TODO - Confirm if we should remove the old login with password page
export const ROOT_PATH = '/';
const LOGIN_FORM_PATH = '/login';
const MS_OAUTH_PATH = '/oauth2';
const LOGIN_SSO_PATH = '/login-sso';
const LOGIN_EMAIL_PATH = '/login-email';
const PROPERTIES_PATH = '/properties';
const NEW_COMPS_PATH = '/comps/new';
const UPLOAD_COMPS_PATH = '/comps/upload';
const USER_SETTINGS_PATH = '/user-settings';
const SCOOPS_PATH_NEW = '/scoops/new';
const FIND_COMPS = '/comps';
const SHOW_COMPS = '/comps';
const COMPANIES_PATH = '/companies';
const TENANT_TOUR_PATH = '/tenant-tours';
const COMP_SET_PATH = '/comp-set';
const COMP_SETS_PATH = '/comp-sets';
const EDIT_COMPS = '/comps/edit';
const EXPLORE_ACTIVITY = '/activity';
const WELCOME_WIZARD = '/welcome';
const TABLEAU_DASHBOARDS = '/market-analytics';
const TABLEAU_ANALYTICS = '/analytics';
const OKTA_CALLBACK_PATH = '/okta/callback';
const SUBMARKETS = '/submarkets';
const MARKETS = '/markets';
const TENANT_TOUR_CREATION_PATH = `${TENANT_TOUR_PATH}/new`;
const HOME_PATH = '/home';
const AGENCY_ASSIGNMENTS_PATH = '/agency-assignments';
const PROJECTS_PATH = '/projects';
const ADDRESS_PROFILE = '/address';
const ADDRESS_PROFILE_AMENITIES = `${ADDRESS_PROFILE}/nearby-amenities`;
const ADDRESS_PROFILE_DEMOGRAPHICS = `${ADDRESS_PROFILE}/demographics`;
const NOT_FOUND_PATH = '/not-found';

const locations = {
  root: () => ROOT_PATH,
  loginMSCallback: () => MS_OAUTH_PATH,
  loginOktaCallback: () => OKTA_CALLBACK_PATH,
  loginMSPage: () => LOGIN_SSO_PATH,
  loginEmail: () => LOGIN_EMAIL_PATH,
  loginForm: () => LOGIN_FORM_PATH,
  uploadComps: () => UPLOAD_COMPS_PATH,
  newComps: () => NEW_COMPS_PATH,
  userSettings: () => USER_SETTINGS_PATH,
  showProperty: (id: number | string, section?: string) => {
    const BASE_PATH = `${PROPERTIES_PATH}/${id}`;

    return section ? `${BASE_PATH}/${section}` : `${BASE_PATH}`;
  },
  newScoop: () => SCOOPS_PATH_NEW,
  findComps: (params?: FindCompsParam) => {
    const tabParams = [];

    if (params?.resultActiveTab) {
      tabParams.push(
        `${FIND_COMPS_TAB_PARAM}=${params?.resultActiveTab || ''}`,
      );
    }

    if (params?.criteriaActiveTab) {
      tabParams.push(
        `${FIND_COMPS_CRITERIA_TAB_PARAM}=${params?.criteriaActiveTab || ''}`,
      );
    }

    if (params?.activeTabView) {
      tabParams.push(
        `${FIND_COMPS_TAB_VIEW_PARAM}=${params?.activeTabView || ''}`,
      );
    }

    if (params?.showAdvancedFilter) {
      tabParams.push(`${FIND_COMPS_ADVANCED_FILTER}=t`);
    }

    if (params?.urlParams) {
      tabParams.push(params?.urlParams);
    }

    let urlParams = '';
    if (tabParams.length) {
      urlParams = `?${tabParams.join('&')}`;
    }

    return FIND_COMPS + urlParams;
  },
  showCompany: (id: number | string, section?: string) => {
    const companyBasePath = `${COMPANIES_PATH}/${id}`;

    return section ? `${companyBasePath}/${section}` : companyBasePath;
  },
  showTenantTours: (companyId: number | string) =>
    `${COMPANIES_PATH}/${companyId}${TENANT_TOUR_PATH}`,
  showTenantTour: (
    companyId: number | string,
    id: number | string,
    section?: string,
  ) => {
    const tenantTourPath = `${COMPANIES_PATH}/${companyId}${TENANT_TOUR_PATH}/${id}`;

    if (section) {
      return `${tenantTourPath}/${section}`;
    }

    return tenantTourPath;
  },
  showComps: (id: number | string, type: string) =>
    `${SHOW_COMPS}/${id}/${type}`,
  editComps: (id: number | string, type: string) =>
    `${EDIT_COMPS}/${id}/${type}`,
  exploreActivity: (urlParams?: string) => {
    return urlParams ? `${EXPLORE_ACTIVITY}?${urlParams}` : EXPLORE_ACTIVITY;
  },
  welcomeWizard: () => WELCOME_WIZARD,
  showProjects: () => PROJECTS_PATH,
  marketAnalytics: (params?: TableauParam) => {
    if (params?.dashboardId) {
      return `${TABLEAU_DASHBOARDS}?${TABLEAU_DASHBOARD_PARAM}=${params?.dashboardId}`;
    }

    return TABLEAU_DASHBOARDS;
  },
  analytics: () => `${TABLEAU_ANALYTICS}/:params`,
  showSubmarket: (
    id: number | string,
    propertyTypeId: number | string,
    section?: string,
  ) => {
    const submarketPath = `${SUBMARKETS}/${id}/${propertyTypeId}`;

    if (section) {
      return `${submarketPath}/${section}`;
    }

    return submarketPath;
  },
  showMarket: (id: number | string, section?: string) => {
    const marketPath = `${MARKETS}/${id}`;

    if (section) {
      return `${marketPath}/${section}`;
    }

    return marketPath;
  },
  showTenantTourCreationPage: () => TENANT_TOUR_CREATION_PATH,
  home: () => HOME_PATH,
  showAgencyAssignments: (id: number | string, section?: string) => {
    const agencyLeasingPath = `${AGENCY_ASSIGNMENTS_PATH}/${id}`;

    if (section) {
      return `${agencyLeasingPath}/${section}`;
    }

    return agencyLeasingPath;
  },
  goToCompSet: (propertyId: number | string) =>
    `${PROPERTIES_PATH}/${propertyId}${COMP_SET_PATH}`,
  showPropertyCompSets: (propertyId: number | string) =>
    `${PROPERTIES_PATH}/${propertyId}${COMP_SETS_PATH}`,
  showPropertyCompSet: (
    propertyId: number | string,
    id: number | string,
    section?: string,
  ) => {
    const compSetPath = `${PROPERTIES_PATH}/${propertyId}${COMP_SETS_PATH}/${id}`;

    if (section) {
      return `${compSetPath}/${section}`;
    }

    return compSetPath;
  },
  showAddressProfileAmenities: (urlParams?: string) =>
    urlParams
      ? `${ADDRESS_PROFILE_AMENITIES}${urlParams}`
      : ADDRESS_PROFILE_AMENITIES,
  showAddressProfileDemographics: (urlParams?: string) =>
    urlParams
      ? `${ADDRESS_PROFILE_DEMOGRAPHICS}${urlParams}`
      : ADDRESS_PROFILE_DEMOGRAPHICS,
  showNotFoundPage: () => NOT_FOUND_PATH,
};

export default locations;
