import { IGraphsDimensions } from 'interfaces/graphs/IGraphsDimensions';

export const YAXIS_LEGEND_HEIGHT = 13;
export const HORIZONTAL_YAXIS_LEGEND_HEIGHT = 13.6875;
export const PADDING_Y_AXIS = 4;
export const YAXIS_LEGEND_BOTTOM = 2.6;
export const HORIZONTAL_YAXIS_LEGEND_BOTTOM = 3.5;
export const HORIZONTAL_YAXIS_LEGEND_TOP = -0.5;

const margins = {
  top: 32,
  right: 24,
  bottom: 16,
  left: 56,
};

const width = 674 - margins.left - margins.right;
const height = 140;

export const defaultDimensions: IGraphsDimensions = {
  margins,
  width,
  height,
};
