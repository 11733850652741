import { useCallback, useEffect, useState } from 'react';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { removeCommaFromNumber } from 'utils/formatters/number';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';

interface Props {
  input: ILeaseInput | IPropertyInput;
  onChange: (field: string, value: any) => void;
  currentFocusedField: string | null;
  isMeasurementSqm: boolean;
  preventCalculation: boolean;
  hasFormChanged: boolean;
  sizeFieldsName: string[];
  officeSizeFieldsName: string[];
  warehouseSizeFieldsName: string[];
}
export const useCalculateSizeFields = ({
  input,
  onChange,
  currentFocusedField,
  isMeasurementSqm,
  preventCalculation = false,
  hasFormChanged,
  sizeFieldsName,
  officeSizeFieldsName,
  warehouseSizeFieldsName,
}: Props) => {
  const [stopCalculation, setStopCalculation] = useState<boolean>(
    preventCalculation,
  );
  const sizeField = isMeasurementSqm ? sizeFieldsName[0] : sizeFieldsName[1];
  const officeSizeField = isMeasurementSqm
    ? officeSizeFieldsName[0]
    : officeSizeFieldsName[1];
  const warehouseField = isMeasurementSqm
    ? warehouseSizeFieldsName[0]
    : warehouseSizeFieldsName[1];

  const isLeaseSizeFocused = currentFocusedField === 'lease-size';
  const isOfficeFocused = currentFocusedField === 'office-input-size';
  const isWareHouseFocused = currentFocusedField === 'warehouse-input-size';

  const calculateFields = useCallback(() => {
    // if calculation occurred or warehouse + office sizes filled - then we do not calculate
    const hasSizedFilled = !!input[officeSizeField] && !!input[warehouseField];

    if (stopCalculation || hasSizedFilled) {
      setStopCalculation(true);
      return;
    }

    // prevents calculation on mount
    if (!hasFormChanged) return;

    // calculates office when warehouse is filled, warehouse, lease size not focused and office is empty
    const isCalculateOffice =
      input[warehouseField] &&
      !isWareHouseFocused &&
      !isLeaseSizeFocused &&
      !input[officeSizeField];
    // calculates warehouse when office is filled, office, lease size not focused and warehouse is empty
    const isCalculateWarehouse =
      input[officeSizeField] &&
      !isOfficeFocused &&
      !isLeaseSizeFocused &&
      !input[warehouseField];

    // set calculated field if greater than 0 and stop calculation
    if (isCalculateOffice) {
      const calculatedOffice =
        Number(removeCommaFromNumber(String(input[sizeField]))) -
        Number(removeCommaFromNumber(String(input[warehouseField])));
      onChange(officeSizeField, calculatedOffice > 0 ? calculatedOffice : null);
      setStopCalculation(true);
    }

    if (isCalculateWarehouse) {
      const calculatedWarehouse =
        Number(removeCommaFromNumber(String(input[sizeField]))) -
        Number(removeCommaFromNumber(String(input[officeSizeField])));
      onChange(
        warehouseField,
        calculatedWarehouse > 0 ? calculatedWarehouse : null,
      );
      setStopCalculation(true);
    }
  }, [
    isLeaseSizeFocused,
    hasFormChanged,
    isOfficeFocused,
    isWareHouseFocused,
    input,
    sizeField,
    officeSizeField,
    onChange,
    stopCalculation,
    warehouseField,
  ]);

  useEffect(() => {
    calculateFields();
  }, [input, currentFocusedField, calculateFields]);

  return;
};
