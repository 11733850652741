import { DEGREES } from 'constants/placeholders';

export const formatCoordinateValue = (
  type: 'lat' | 'lon',
  value: number | undefined,
): string => {
  if (!value) {
    return type === 'lat' ? 'Lat' : 'Long';
  }

  const degrees = (Math.round(value * 10000) / 10000).toFixed(4);
  const direction = type === 'lat' ? 'N' : 'W';
  return `${degrees}${DEGREES} ${direction}`;
};
