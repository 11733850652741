import { IdName } from 'interfaces/IdName';
import { IMarket, IMicromarket, ISubmarket } from 'interfaces/IMarket';
import { groupBy, uniqBy, orderBy } from 'lodash';

export const convertIMarketIntoIdName = (market: IMarket): IdName => ({
  id: market.id,
  name: market.name,
});

export const getUniqueSubmarketsByName = (submarkets: ISubmarket[]) => {
  const submarketsByMarket = groupBy(submarkets, 'shapeGroup');
  let uniqSubmarkets: any = [];
  Object.keys(submarketsByMarket).forEach(marketKey => {
    uniqSubmarkets = [
      ...uniqSubmarkets,
      ...uniqBy(submarketsByMarket[marketKey], 'name'),
    ];
  });

  return uniqSubmarkets;
};

export const getSubmarketsByMarketNameShapeGroup = (
  submarkets: ISubmarket[],
) => {
  const submarketsByMarketNameShapeGroup: any = [];
  submarkets.forEach(submarket => {
    submarketsByMarketNameShapeGroup.push({
      ...submarket,
      marketNameShapeGroup: `${submarket.market?.name} · ${submarket.shapeGroup}`,
    });
  });
  return orderBy(submarketsByMarketNameShapeGroup, ['marketNameShapeGroup']);
};

export const getUniqueMicromarketsByName = (micromarkets: IMicromarket[]) => {
  return uniqBy(micromarkets || [], 'name');
};
