import React from 'react';
import FormControl from 'components/FormControl';
import styles from '../../FormSection.module.scss';
import TextInput from 'components/Inputs/TextInput';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH } from 'constants/i18n';

interface Props {
  required?: boolean;
  value?: string | null;
  onChange: (field: string, value: any) => void;
}

const Suite: React.FC<Props> = ({ value, onChange, required = false }) => {
  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText('avantPlatform.attributes.lease.label.suite')}
    >
      <TextInput
        value={value}
        id="lease-suite"
        onChange={value => onChange('suite', value)}
        placeholder={translateText(
          `${I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH}.enterSuite`,
        )}
      />
    </FormControl>
  );
};

export default Suite;
