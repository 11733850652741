import React from 'react';
import ComparativeCard from 'components/PropertyProfile/Sections/Comparative/ComparativeCard';
import { Column, ComparativeCardTheme } from '../constants';
import { useQuery } from '@apollo/client';
import { SEARCH_LEASES_QUERY } from 'graphql/leases';
import { SortDirections } from 'constants/sortDirections';
import SpinnerLoader from 'components/SpinnerLoader';
import { ILease } from 'interfaces/ILease';
import { ISearchResult } from 'interfaces/ISearchResult';
import LeaseCard from 'components/Leases/LeaseCard';
import { LeaseCardField } from 'components/Leases/fields';
import { ColorNames } from 'constants/colorNames';
import styles from 'components/PropertyProfile/Sections/Comparative/ComparativeColumns/ComparativeColumns.module.scss';
import classnames from 'classnames';
import MissingCompData from 'components/MissingCompData';

const RecentLeaseColumn: React.FC<Column> = ({ property, columnTheme }) => {
  const isMainColumn = columnTheme === ComparativeCardTheme.main;

  const { data, loading } = useQuery<ISearchResult<ILease>>(
    SEARCH_LEASES_QUERY,
    {
      variables: {
        search: {
          filter: { propertyId: property.id },
          order: { field: 'signDate', direction: SortDirections.desc },
          page: { page: 1, size: 1 },
        },
      },
      fetchPolicy: 'cache-first',
    },
  );

  const lease = data?.leases?.results?.[0] || null;

  const renderLease = () => {
    if (loading) return null;

    return lease?.id ? (
      <LeaseCard
        tagBackgroundColor={
          isMainColumn ? ColorNames.ayGrey8Color : ColorNames.ayWhiteColor
        }
        lease={lease}
        size={'extra-small'}
        fieldsToShow={[
          LeaseCardField.Size,
          LeaseCardField.Floor,
          LeaseCardField.RentPrice,
          LeaseCardField.Escalation,
          LeaseCardField.TI,
          LeaseCardField.FreeMonths,
          LeaseCardField.ExpirationDate,
          LeaseCardField.TenantBroker,
          LeaseCardField.LandlordBroker,
        ]}
      />
    ) : (
      <div className={classnames(styles['missing-data-container'])}>
        <MissingCompData isLease property={property} />
      </div>
    );
  };

  return (
    <ComparativeCard theme={columnTheme}>
      {loading && (
        <div className={styles['loading-content']}>
          <SpinnerLoader background={ColorNames.ayTotalBlackColor} />
        </div>
      )}

      {renderLease()}
    </ComparativeCard>
  );
};

export default RecentLeaseColumn;
