import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize, isNil } from 'lodash';
import classNames from 'classnames';
import { getCustomOrCalculatedNetEffectiveRent } from 'components/CreateComps/FormSection/LeaseForm/LeaseTerms/NetEffectiveRentField/utils';
import { isValidNumber } from 'components/UserSettingsSections/utils';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_ROLES_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { LeaseRentType } from 'constants/leases';
import {
  UNITS_MEASUREMENT_AREA,
  UnitOfMeasurement,
} from 'constants/unitOfMeasurement';
import { ILease } from 'interfaces/ILease';
import { ITableCell } from 'interfaces/ITableCell';
import locations from 'routes';
import { formatArea } from 'utils/formatters/area';
import { getBrokerName } from 'utils/formatters/broker';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { formatDate } from 'utils/formatters/date';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import { getPropertyAddress } from 'utils/formatters/property';
import { upperFirstLetter } from 'utils/formatters/string';
import { TranslateFunction } from 'utils/i18n';
import { getTranslatedRentType, isMonthlyTimeMeasurement } from 'utils/leases';
import { getFieldValueForLease } from 'utils/unitsOfMeasurement';
import styles from './LeasesContainer.module.scss';
import tableViewStyles from 'components/TableView/TableView.module.scss';
import { formatEscalation } from 'components/FindComps/LeasesTableContainer/leaseTableHeader';

export const leaseTableHeader = (
  t: TranslateFunction,
  unitOfMeasurement?: string,
) => [
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.property`),
    id: 'primaryAddress',
    accessor: (lease: ILease) => getPropertyAddress(lease.property) || '',
    Cell: ({ cell }: ITableCell) => (
      <Link
        to={locations.showProperty(cell.row.original.property.id)}
        className={styles['cell-link']}
      >
        <div className={styles['cell-property']}>{cell.value}</div>
      </Link>
    ),
    width: 241,
    sticky: 'left',
  },
  {
    id: 'tenantName',
    header: capitalize(t(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenant`)),
    accessor: 'tenantCompany.name',
    Cell: ({ cell }: ITableCell) => {
      const dest = cell?.row?.original?.tenantCompany?.id;

      if (!dest) return null;

      return (
        <Link to={locations.showCompany(dest)} className={styles['cell-link']}>
          <div
            className={classNames(styles['cell-tenant'], styles['capitalized'])}
          >
            {cell.value}
          </div>
        </Link>
      );
    },
    sticky: 'left',
  },
  {
    header: `${t(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.size`,
    )} ${unitOfMeasurement || UnitOfMeasurement.sf}`,
    id: 'size',
    accessor: (lease: ILease) => getFieldValueForLease('size', lease),
    Cell: ({ cell: { value } }: ITableCell) => (
      <div className={tableViewStyles['number-field']}>
        {formatArea(+value, '')}
      </div>
    ),
  },
  {
    header: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.signDate`),
    accessor: 'signDate',
    Cell: ({ cell: { value } }: ITableCell) => formatDate(value, 'MMM YYYY'),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.termMonths`),
    accessor: 'termInMonths',
    Cell: ({ cell: { value } }: ITableCell) => {
      if (!value || !isValidNumber(value)) return null;
      return (
        <div className={tableViewStyles['number-field']}>
          {Math.floor(+value)}
        </div>
      );
    },
  },
  {
    header: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.expirationDate`),
    accessor: 'expirationDate',
    Cell: ({ cell: { value } }: ITableCell) => formatDate(value, 'MMM YYYY'),
  },
  {
    header: t(`${I18N_AVANT_PROPERTY_LABEL_PATH}.tenantTour.rent`, {
      unit: UNITS_MEASUREMENT_AREA[unitOfMeasurement || UnitOfMeasurement.sf],
    }),
    accessor: 'baseRent',
    Cell: ({ cell }: ITableCell) => {
      if (!cell.value || !isValidNumber(cell.value)) return null;

      const rentType = cell.row.original.rentType;

      const formattedRentType = getTranslatedRentType(rentType);

      const formattedPrice = formatNumberWithDecimals(cell.value);
      const currencySymbol = getCurrencySymbol(cell.row.original.currencyCode);

      return (
        !!formattedPrice && (
          <div className={tableViewStyles['number-field']}>
            <span className={styles['cell-price']}>
              {`${currencySymbol}${formattedPrice} ${formattedRentType}`.trim()}
            </span>
          </div>
        )
      );
    },
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.type`),
    accessor: 'type',
    Cell: ({ cell: { value } }: ITableCell) => upperFirstLetter(value),
  },
  {
    header: `${t(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.taxAndOpex`)} ${
      UNITS_MEASUREMENT_AREA[unitOfMeasurement || UnitOfMeasurement.sf]
    }`,
    id: 'taxAndOpex',
    accessor: 'taxAndOpex',
    Cell: ({ cell: { value, row } }: ITableCell) => {
      if (isNil(value)) {
        return '';
      }

      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      const formattedValue = formatNumberWithDecimals(value);

      return (
        <div className={tableViewStyles['number-field']}>
          {`${currencySymbol}${formattedValue}`}
        </div>
      );
    },
  },
  {
    header: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.escalation`),
    id: 'escalation',
    accessor: 'escalation',
    Cell: ({ cell: { value, row } }: ITableCell) => {
      if (!value) return null;

      return formatEscalation(value, row, t);
    },
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.freeRentMonths`),
    accessor: 'freeRentMonths',
    Cell: ({ cell: { value } }: ITableCell) => (
      <div className={tableViewStyles['number-field']}>{value}</div>
    ),
  },
  {
    header: `TIs ${
      UNITS_MEASUREMENT_AREA[unitOfMeasurement || UnitOfMeasurement.sf]
    }`,
    accessor: 'tenantImprovementAllowance',
    Cell: ({ cell: { value, row } }: ITableCell) => {
      if (!value || !isValidNumber(value)) return null;

      const formattedTIs = formatNumberWithDecimals(value);
      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      return (
        !!formattedTIs && (
          <div
            className={tableViewStyles['number-field']}
          >{`${currencySymbol}${formattedTIs}`}</div>
        )
      );
    },
  },
  {
    header: `NER ${LeaseRentType.FS}`,
    id: 'netEffectiveRent',
    accessor: (lease: ILease) => {
      const {
        netEffectiveRentCustom,
        netEffectiveRent,
        netEffectiveRentMonthly,
        timeMeasurement,
      } = lease;
      const validNetEffectiveRent = isMonthlyTimeMeasurement(timeMeasurement)
        ? netEffectiveRentMonthly
        : netEffectiveRent;

      return formatNumberWithDecimals(
        getCustomOrCalculatedNetEffectiveRent(
          netEffectiveRentCustom,
          validNetEffectiveRent,
        ),
      );
    },
    Cell: ({ cell: { value, row } }: ITableCell) => {
      if (!value) return null;

      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      return (
        <div
          className={tableViewStyles['number-field']}
        >{`${currencySymbol}${value}`}</div>
      );
    },
  },
  {
    header: t(
      `${I18N_AVANT_PROPERTY_ROLES_PATH}.company.label.tenantRepBroker`,
    ),
    accessor: 'tenantBrokers',
    Cell: ({ cell: { value } }: ITableCell) =>
      getBrokerName(value?.[0]) || null,
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.agencyLeasingBroker`),
    accessor: 'landlordBrokers',
    Cell: ({ cell: { value } }: ITableCell) => {
      const broker = getBrokerName(value?.[0]);
      return broker ? <span className={styles.broker}>{broker}</span> : null;
    },
  },
];
