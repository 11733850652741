import React from 'react';
import styles from './ActivityFeedEmptyMessage.module.scss';
import NoItemsImage from '../../../assets/images/satellite.svg';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';

interface Props {
  message?: string;
  wrapperClassName?: string;
  onAddScoopButtonClick: () => void;
}

const I18N_PATH = 'avantProperties.texts.exploreActivity.scoop';

const ActivityFeedEmptyMessage: React.FC<Props> = props => {
  const { message, wrapperClassName, onAddScoopButtonClick } = props;
  const { t } = useTranslation();
  const defaultMessage = t(`${I18N_PATH}.noScoop`);

  return (
    <div className={wrapperClassName}>
      <div className={styles.content}>
        <img className={styles.image} src={NoItemsImage} alt="No scoops" />
        <h2 className={styles.title}>{message || defaultMessage}</h2>
        <Button
          onClick={onAddScoopButtonClick}
          type={'ghost'}
          label={t(`${I18N_PATH}.addNewScoop`)}
          size={'small'}
        />
      </div>
    </div>
  );
};

export default ActivityFeedEmptyMessage;
