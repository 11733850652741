import {
  CustomSearchCriteriaType,
  LeasesSearchCriteriaType,
  OpenSelectionSearchCriteriaType,
  SalesSearchCriteriaType,
} from 'components/FindComps/EditSearchCriteria/types';
import { IOption } from 'components/OptionsContainer';
import {
  FindCompCriteriaTabs,
  FindCompTabs,
  FindCompTabsViews,
} from 'constants/FindCompTabs';
import { StatusTypes, StatusTypesIDs } from 'constants/statusTypes';
import { translateText } from 'utils/i18n';
import {
  LEASE_EXPIRING_TIME_OPTIONS,
  LEASE_RECENT_TIME_OPTIONS,
} from 'components/FindComps/EditSearchCriteria/StaticFilters/leaseTime';
import {
  EXPIRING_LEASES_OPTION,
  RECENT_LEASES_OPTION,
} from 'components/FindComps/EditSearchCriteria/StaticFilters/leaseSort';
import { FindCompsSearchCriteriaService } from 'services/FindCompsSearchCriteriaService';
import { convertIMarketIntoIdName } from 'utils/markets';
import { IUser } from 'interfaces/IUser';
import { History } from 'history';
import { IMarket } from 'interfaces/IMarket';
import locations, { FindCompsParam } from 'routes';
import { RECORD_TYPE_OPTIONS } from 'components/CreateComps/FormSection/LeaseForm/staticData';
import avantCitiesRoutes from 'avantCitiesRoutes';
import {
  PropertyTypeIds,
  PropertyTypes,
  PropertyTypeNames,
} from 'constants/propertyTypes';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

const getFindCompsCriteria = (
  user: IUser,
  marketsParam?: IMarket[],
  customCriteria?: CustomSearchCriteriaType,
  leasesCriteria?: LeasesSearchCriteriaType,
  salesCriteria?: SalesSearchCriteriaType,
  openSelectionCriteria?: OpenSelectionSearchCriteriaType,
) => {
  // TODO: Review this temporary fix when working on https://codelitt.atlassian.net/browse/HUB-1814
  const propertyTypeId = user.preferences?.propertyType
    ? PropertyTypeIds[user.preferences?.propertyType?.toLowerCase()]
    : undefined;
  const types = propertyTypeId
    ? [{ id: propertyTypeId, name: user.preferences?.propertyType }]
    : undefined;
  const propertyTypes = user.preferences?.propertyTypes?.map(id => {
    return { id: id, name: PropertyTypeNames[id] };
  });
  let markets = undefined;
  if (marketsParam) {
    markets = marketsParam.map(convertIMarketIntoIdName);
  }

  customCriteria = {
    types,
    propertyTypes,
    ...customCriteria,
    markets,
  };

  return FindCompsSearchCriteriaService.parseFiltersToUrlParams({
    customCriteria,
    leasesCriteria,
    salesCriteria,
    openSelectionCriteria,
  });
};

export const goToFindCompsResult = (
  user: IUser,
  history: History,
  userMarkets: IMarket[] | undefined,
  resultActiveTab: FindCompTabs,
  customCriteria?: CustomSearchCriteriaType,
  leasesCriteria?: LeasesSearchCriteriaType,
  criteriaTab?: FindCompTabsViews,
  salesCriteria?: SalesSearchCriteriaType,
  openSelectionCriteria?: OpenSelectionSearchCriteriaType,
) => {
  const urlParams = getFindCompsCriteria(
    user,
    userMarkets,
    customCriteria,
    leasesCriteria,
    salesCriteria,
    openSelectionCriteria,
  );

  history.push(
    locations.findComps({
      resultActiveTab,
      urlParams,
      activeTabView: criteriaTab,
    }),
  );
};

const goToFindCompsSearch = (history: History, param?: FindCompsParam) =>
  history.push(locations.findComps(param));

export const getPropertyButtonOptions = (
  user: IUser,
  userMarkets: IMarket[],
  history: History,
): IOption[] => [
  {
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.existing`),
    onClick: () =>
      goToFindCompsResult(user, history, userMarkets, FindCompTabs.properties, {
        statuses: [{ id: StatusTypesIDs.Existing, name: StatusTypes.Existing }],
      }),
    type: 'properties-existing',
  },
  {
    label: translateText(
      'avantProperties.navigation.properties.label.underDevelopment',
    ),
    onClick: () =>
      goToFindCompsResult(user, history, userMarkets, FindCompTabs.properties, {
        statuses: [
          {
            id: StatusTypesIDs.UnderConstruction,
            name: StatusTypes.UnderConstruction,
          },
          {
            id: StatusTypesIDs.UnderRenovation,
            name: StatusTypes.UnderRenovation,
          },
        ],
      }),
    type: 'properties-under-development',
  },
  {
    label: translateText(
      'avantProperties.navigation.properties.label.proposed',
    ),
    onClick: () =>
      goToFindCompsResult(user, history, userMarkets, FindCompTabs.properties, {
        statuses: [
          {
            id: StatusTypesIDs.ProposedShortTerm,
            name: StatusTypes.ProposedShortTerm,
          },
          {
            id: StatusTypesIDs.ProposedLongTerm,
            name: StatusTypes.ProposedLongTerm,
          },
        ],
      }),
    type: 'properties-proposed',
  },
  {
    label: translateText(
      'avantProperties.navigation.properties.label.customSearch',
    ),
    onClick: () => goToFindCompsSearch(history),
    type: 'properties-custom-search',
  },
];

export const getLeaseButtonOptions = (
  user: IUser,
  userMarkets: IMarket[],
  history: History,
): IOption[] => [
  {
    label: translateText('avantProperties.filters.lease.label.leaseComps'),
    onClick: () =>
      goToFindCompsResult(
        user,
        history,
        userMarkets,
        FindCompTabs.leases,
        {},
        {
          sort: RECENT_LEASES_OPTION,
          signTime: LEASE_RECENT_TIME_OPTIONS[0],
          recordType: RECORD_TYPE_OPTIONS[0],
        },
      ),
    type: 'leases-comparables',
  },
  {
    label: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.leaseExpirations`,
    ),
    onClick: () =>
      goToFindCompsResult(
        user,
        history,
        userMarkets,
        FindCompTabs.leases,
        {},
        {
          sort: EXPIRING_LEASES_OPTION,
          expirationTime: LEASE_EXPIRING_TIME_OPTIONS[2],
          recordType: RECORD_TYPE_OPTIONS[1],
        },
      ),
    type: 'leases-expirations',
  },
  {
    label: translateText('avantProperties.menus.lease.label.customSearch'),
    onClick: () =>
      goToFindCompsSearch(history, {
        criteriaActiveTab: FindCompCriteriaTabs.lease,
      }),
    type: 'leases-custom-search',
  },
];

export const redirectToCities = (user: IUser, userMarkets: IMarket[]) => {
  /** If more than 1 preferred market, do not pass in the marketID */
  const marketId = userMarkets?.length === 1 ? userMarkets?.[0].id : undefined;
  const citiesUrl = avantCitiesRoutes.userPreferences(
    user.preferences?.propertyType || PropertyTypes.office,
    marketId,
  );

  return window.open(citiesUrl, '_blank');
};

export const getExploreCityButtonOptions = (
  user: IUser,
  userMarkets: IMarket[],
  history: History,
): IOption[] => [
  {
    label: translateText('avantProperties.navigation.cities.label.asAMap'),
    onClick: () => redirectToCities(user, userMarkets),
    type: 'explore-as-map',
  },
  {
    label: translateText(
      'avantProperties.navigation.cities.label.marketAnalytics',
    ),
    onClick: () => history.push(locations.marketAnalytics()),
    type: 'explore-tableau',
  },
];
