import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { noop } from 'lodash';
import { select } from 'd3-selection';
import { colors } from 'constants/colors';

import { CircleProps } from './types';

const Circle = forwardRef<any, CircleProps>(
  (
    {
      cx,
      cy,
      id,
      r,
      fillColor = colors.primaryColor100,
      strokeColor = colors.primaryColor100,
      strokeWidth = 0,
      onMouseOut = noop,
      onMouseOver = noop,
    },
    ref,
  ) => {
    const circleElementRef = useRef(null);

    useImperativeHandle(ref, () => circleElementRef);

    useEffect(() => {
      const node = circleElementRef.current;
      const svgG = select(node);

      if (id) {
        svgG.attr('id', id);
      }

      svgG
        .append('circle')
        .attr('cx', cx)
        .attr('cy', cy)
        .attr('r', r)
        .style('fill', fillColor)
        .style('stroke', strokeColor)
        .style('stroke-width', strokeWidth)
        .on('mouseover', onMouseOver)
        .on('mouseout', onMouseOut);
    }, [
      cx,
      cy,
      r,
      fillColor,
      strokeColor,
      strokeWidth,
      id,
      onMouseOut,
      onMouseOver,
    ]);

    return <g ref={circleElementRef} />;
  },
);

export default Circle;
