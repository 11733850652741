const MARGINS = {
  top: 12,
  right: 16,
  bottom: 32,
  left: 110,
};

const WIDTH = 630 - MARGINS.left - MARGINS.right;
const WIDTH_LARGE = 700 - MARGINS.left - MARGINS.right;
const HEIGHT = 176;
const HEIGHT_SMALL = 82;

export default {
  MARGINS,
  WIDTH,
  WIDTH_LARGE,
  HEIGHT,
  HEIGHT_SMALL,
};

export enum GraphClasses {
  VerticalLine = 'vertical-line',
  VerticalLineLease = 'vertical-line-lease',
  HorizontalLine = 'horizontal-line',
  Bubble = 'property-bubble',
  BubbleTarget = 'target-property-bubble',
  BubbleShadow = 'property-bubble-shadow',
  BubbleTargetShadow = 'target-property-bubble-shadow',
  Line = 'mkt-avg',
  LineDataPoint = 'mkt-avg-quarter',
  LineDataPointShadow = 'mkt-avg-quarter-shadow',
  FixedHorizontalLine = 'fixed-horizontal-line',
}

export const MAX_YEARS_OF_DATA = 5;
