import React from 'react';
import styles from './CardContainer.module.scss';
import classNames from 'classnames';

interface Props {
  wrapperClassName?: string;
  children?: React.ReactNode;
}
const CardContainer: React.FC<Props> = props => {
  return (
    <div className={classNames(styles.container, props.wrapperClassName)}>
      {props.children}
    </div>
  );
};

export default CardContainer;
