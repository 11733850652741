import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from 'components/FormControl';
import RadioInput, { IRadioItem } from 'components/Inputs/RadioInput';
import TextInput from 'components/Inputs/TextInput';
import DateInput from 'components/Inputs/DateInput';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import { BrokerCompanySuggestionType } from 'constants/BrokerCompanySuggestionType';
import {
  I18N_AVANT_PROPERTY_LABEL_SALES_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { REGEX_DECIMALS } from 'constants/regex';
import { IdName } from 'interfaces/IdName';
import { isDateWithinLimit } from 'utils/date';
import { currencyInputFormatter } from 'utils/formatters/currency';

import { SaleFormSectionProps } from '..';
import styles from '../../FormSection.module.scss';
import { BrokerOptions } from '../../FormFields/BrokerOptions';

interface Props extends SaleFormSectionProps {
  saleDateSuggestions: IRadioItem[];
  isUkSales: boolean;
}

const DatesAndCompanies: React.FC<Props> = ({
  isUkSales,
  saleInput,
  updateSaleProperty,
  saleDateSuggestions,
}) => {
  const { t } = useTranslation();

  const onSelectItem = (type: string, value: IdName): void => {
    const newValue = value?.raw || value;
    newValue?.id &&
      updateSaleProperty(type, [...(saleInput[type] || []), newValue]);
  };

  const onRemoveItem = (type: string, value: IdName): void =>
    updateSaleProperty(
      type,
      saleInput[type]!.filter((seller: IdName) => seller.id !== value.id),
    );
  return (
    <>
      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.saleDate`)}
        wrapperClassName={styles['form-row']}
        required
      >
        <div className={styles['large-input-container']}>
          <DateInput
            id={'sale-date'}
            value={saleInput.date}
            onChange={value => updateSaleProperty('date', value)}
            customValidation={{
              isValid:
                !saleInput?.date ||
                isDateWithinLimit(saleInput.date, 6, 'month'),
              message: t(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.dateMustBeWithin6Months`,
              ),
            }}
          />

          <RadioInput
            selectedItem={saleInput.date}
            data={saleDateSuggestions}
            onChange={item => updateSaleProperty('date', item.value)}
          />
        </div>
      </FormControl>

      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.dateOnMarket`)}
        wrapperClassName={styles['form-row']}
      >
        <DateInput
          id={'sale-date-on-market'}
          value={saleInput.dateOnMarket}
          onChange={value => updateSaleProperty('dateOnMarket', value)}
        />
      </FormControl>

      <FormControl
        label={
          isUkSales
            ? t(`${I18N_AVANT_PROPERTY_LABEL_SALES_PATH}.waultToExpiry`)
            : t(`${I18N_AVANT_PROPERTY_LABEL_SALES_PATH}.walt`)
        }
        wrapperClassName={styles['form-row']}
      >
        <TextInput
          pattern={REGEX_DECIMALS}
          id="wault-by-expirity"
          value={saleInput.waultToExpiry}
          placeholder="0.00"
          onChange={value => {
            updateSaleProperty('waultToExpiry', value || null);
          }}
        />
        <span className={styles['input-helper']}>
          {t(`${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`).toLowerCase()}
        </span>
      </FormControl>
      {isUkSales && (
        <>
          <FormControl
            label={t(
              `${I18N_AVANT_PROPERTY_LABEL_SALES_PATH}.waultToBreakDate`,
            )}
            wrapperClassName={styles['form-row']}
          >
            <TextInput
              pattern={REGEX_DECIMALS}
              id="wault-break-date"
              value={saleInput.waultToBreakDate}
              placeholder="0.00"
              onChange={value => {
                updateSaleProperty('waultToBreakDate', value || null);
              }}
            />
            <span className={styles['input-helper']}>
              {t(`${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`).toLowerCase()}
            </span>
          </FormControl>
          <FormControl
            label={t(`${I18N_AVANT_PROPERTY_LABEL_SALES_PATH}.totalRent`)}
            wrapperClassName={styles['form-row']}
          >
            <CurrencyInput
              value={saleInput?.totalRent}
              id="total-rent"
              iconPosition="left"
              currencySymbol="£"
              placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
              onChange={value =>
                updateSaleProperty('totalRent', value || undefined)
              }
              formatter={value => currencyInputFormatter(value, true)}
            />
          </FormControl>
        </>
      )}

      <CompanySearchCombo
        isMultiple
        label={t('avantProperties.labels.sales.seller')}
        companies={saleInput.saleSellers}
        btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createSeller`)}
        inputId="sale-sellers-input-id"
        placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterSellerName`)}
        wrapperClassName={styles['form-row']}
        onCompanySelection={(value: any) => onSelectItem('saleSellers', value)}
        onRemoveItem={(value: IdName) => onRemoveItem('saleSellers', value)}
      />

      <CompanySearchCombo
        isMultiple
        label={t('avantPlatform.attributes.sale.label.sellerBroker')}
        btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createSeller`)}
        inputId={'sale-seller-broker-input-id'}
        companies={saleInput.sellersBrokers || []}
        placeholder={t(
          'avantPlatform.attributes.sale.prompt.enterSellerBrokerName',
        )}
        wrapperClassName={styles['form-row']}
        onCompanySelection={(value: any) =>
          onSelectItem('sellersBrokers', value)
        }
        onRemoveItem={(value: IdName) => onRemoveItem('sellersBrokers', value)}
      />
      <FormControl wrapperClassName={styles['secondary-row']} indented>
        <BrokerOptions
          isMultiSelect
          selectedItem={saleInput?.sellersBrokers?.map(n => n.name) ?? []}
          onChange={item => {
            const newCompany = { id: item.id, name: item.value };
            const hasCompany = saleInput?.sellersBrokers?.some(
              c => c.id === item.id,
            );
            hasCompany
              ? onRemoveItem('sellersBrokers', newCompany)
              : onSelectItem('sellersBrokers', newCompany);
          }}
          brokerCompanySuggestionType={BrokerCompanySuggestionType.SALE}
          marketId={saleInput?.property?.marketId!}
          propertyTypeId={saleInput?.property?.propertyTypeId!}
        />
      </FormControl>
      <CompanySearchCombo
        isMultiple
        label={t('avantPlatform.attributes.sale.label.buyerBroker')}
        btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createBuyer`)}
        inputId={'sale-buyers-broker-input-id'}
        companies={saleInput?.buyersBrokers || []}
        placeholder={t(
          'avantPlatform.attributes.sale.prompt.enterBuyerBrokerName',
        )}
        wrapperClassName={styles['form-row']}
        onCompanySelection={(value: any) =>
          onSelectItem('buyersBrokers', value)
        }
        onRemoveItem={(value: IdName) => onRemoveItem('buyersBrokers', value)}
      />
      <FormControl wrapperClassName={styles['secondary-row']} indented>
        <BrokerOptions
          isMultiSelect
          selectedItem={saleInput?.buyersBrokers?.map(n => n.name) ?? []}
          onChange={item => {
            const newCompany = { id: item.id, name: item.value };
            const hasCompany = saleInput?.buyersBrokers?.some(
              c => c.id === item.id,
            );
            hasCompany
              ? onRemoveItem('buyersBrokers', newCompany)
              : onSelectItem('buyersBrokers', newCompany);
          }}
          brokerCompanySuggestionType={BrokerCompanySuggestionType.SALE}
          marketId={saleInput?.property?.marketId!}
          propertyTypeId={saleInput?.property?.propertyTypeId!}
        />
      </FormControl>

      <CompanySearchCombo
        isMultiple
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.buyer`)}
        companies={saleInput.saleBuyers}
        inputId="sale-buyers-input-id"
        placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterBuyerName`)}
        wrapperClassName={styles['form-row']}
        onCompanySelection={(value: any) => onSelectItem('saleBuyers', value)}
        onRemoveItem={(value: IdName) => onRemoveItem('saleBuyers', value)}
      />
    </>
  );
};

export default DatesAndCompanies;
