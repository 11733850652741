import React from 'react';
import classnames from 'classnames';

import Button from 'components/Button/new';
import DownloadButton from 'components/DownloadButton';
import LeaseToggles from 'components/Leases/LeaseToggles';
import OptionsContainer from 'components/OptionsContainer';
import avantCitiesRoutes from 'avantCitiesRoutes';
import { ExportTypes } from 'constants/exportTypes';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

import styles from './CommonAgencyLeasingControl.module.scss';
import { CommonAgencyLeasingControlProps } from './types';

const CommonAgencyLeasingControl = ({
  className,
  agencyLeasing,
  toggleOptions,
  showMoreTrendsButton,
  showExcelDownloadButton,
  propertyIds,
  onChangeToggleOptions,
  onEditButtonClick,
}: CommonAgencyLeasingControlProps) => {
  const moreTrendsLabel = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.moreTrends`,
  );

  const defaultOptions: JSX.Element[] = [
    <DownloadButton
      wrapperClassName={styles['download-button-option']}
      label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.downloadExcel`)}
      iconPosition="right"
      key="downloadExcel-button"
      exportType={ExportTypes.properties}
      queryVariables={{
        filter: {
          ids: propertyIds,
        },
      }}
    />,
  ];

  return (
    <div
      className={classnames(styles['common-agency-leasing-control'], className)}
    >
      {toggleOptions && (
        <LeaseToggles
          className={styles.control}
          rentType={toggleOptions.rentType}
          timeMeasurement={toggleOptions.timeMeasurement}
          handleChange={(value: any) =>
            onChangeToggleOptions?.({ ...toggleOptions, ...value })
          }
        />
      )}

      {onEditButtonClick && (
        <Button
          wrapperClassName={classnames(styles.control, styles.button)}
          type="contextual"
          onClick={onEditButtonClick}
          icon="edit"
          iconPosition="left"
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.edit`)}
          size="s"
        />
      )}

      {showMoreTrendsButton && (
        <Button
          wrapperClassName={classnames(styles.control, styles.button)}
          disabled={!propertyIds?.length}
          type="contextual"
          tooltip={moreTrendsLabel}
          label={moreTrendsLabel}
          onClick={() =>
            window.open(
              avantCitiesRoutes.agencyAssignments(agencyLeasing?.id),
              '_blank',
            )
          }
        />
      )}

      {showExcelDownloadButton && (
        <OptionsContainer
          wrapperClassName={styles.control}
          disabled={!propertyIds?.length}
          customOptions={defaultOptions}
          optionButtonIcon="download"
          optionContainerStyle="contextual"
        />
      )}
    </div>
  );
};

export default CommonAgencyLeasingControl;
