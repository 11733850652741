import React from 'react';
import FormControl from 'components/FormControl';
import styles from '../../FormSection.module.scss';
import TextInput from 'components/Inputs/TextInput';
import { translateText } from 'utils/i18n';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { formatNumberWithCommas } from 'utils/formatters/number';

interface Props {
  value?: string | null;
  labelName?: string;
  onChange: (field: string, value: any) => void;
  required?: boolean;
  unitOfMeasurement: string;
}

const Size: React.FC<Props> = ({
  value,
  onChange,
  labelName = `${I18N_PLATFORM_COMMON_WORD_PATH}.size`,
  required = false,
  unitOfMeasurement,
}) => {
  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText(labelName)}
    >
      <TextInput
        id={'size'}
        formatter={value => formatNumberWithCommas(value)}
        placeholder={translateText(
          'avantPlatform.attributes.property.prompt.enterSize',
        )}
        onChange={text => onChange('size', text)}
        value={value}
      />
      <UnitOfMeasurementLegend
        unitOfMeasurement={unitOfMeasurement}
        shouldConvert={false}
      />
    </FormControl>
  );
};

export default Size;
