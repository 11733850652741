import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styles from './Portal.module.scss';
import { lockScroll, unlockScroll } from 'utils/scroll';
import classNames from 'classnames';

let portalRoot = document.getElementById('portal-root');

type PropTypes = {
  keepScrollLocked?: boolean;
  onClick: (event: any) => void;
  wrapperClassName?: string;
  children?: ReactNode;
};

class Portal extends React.Component<PropTypes> {
  element: HTMLDivElement;

  constructor(props: PropTypes) {
    super(props);
    this.element = document.createElement('div');
  }

  componentDidMount() {
    if (!portalRoot) {
      portalRoot = document.createElement('div');
      portalRoot.setAttribute('id', 'portal-root');
      document.body.appendChild(portalRoot);
    }
    portalRoot!.appendChild(this.element);
    lockScroll();
  }

  componentWillUnmount() {
    /**
     * There is a case where two or more modal wrappers can be in the DOM,
     * if this happens, we should not unlock the scroll until
     * there is only one modal left in the DOM
     */
    const modalWrappers = document.querySelectorAll('.modal-wrapper');
    const isOneModalOpen = modalWrappers.length === 1;

    portalRoot!.removeChild(this.element);

    if (!this.props.keepScrollLocked && isOneModalOpen) {
      unlockScroll();
    }
  }

  render() {
    const { onClick, wrapperClassName } = this.props;
    return createPortal(
      <div className="modal-wrapper">
        <div
          className={classNames(styles.portal, wrapperClassName)}
          onClick={onClick}
        />
        {this.props.children}
      </div>,
      this.element,
    );
  }
}

export default Portal;
