import { SwitchOption } from 'components/SwitchOptionButton';
import { LeaseBaseRentTime, LeaseRentType } from 'constants/leases';
import { formatCurrency, getCurrencySymbol } from 'utils/formatters/currency';
import { AskingRentOptions } from './constants';
import { IGraphAskingRentBlockSize } from './types';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { CountryCodes } from 'constants/countryCodes';
import {
  VerticalBarData,
  VictoryBarStyle,
} from 'components/Graphs/VerticalGroupBarChart/types';
import { DEFAULT_STYLE } from 'components/Graphs/VerticalGroupBarChart/utils';
import {
  abbreviateNumber,
  formatNumberWithCommas,
} from 'utils/formatters/number';
import { PropertyTypeIds } from 'constants/propertyTypes';
import { colors } from 'constants/colors';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { translateText } from 'utils/i18n';
import { lowerCase } from 'lodash';
import { SnowflakeBlockAvailabilityChart } from 'interfaces/ISnowflakeBlockAvailability';

export const parseGraphBlockSizeData = (
  blockSizeData?: SnowflakeBlockAvailabilityChart[],
) => {
  const graphBlockSize: any[] = [];

  blockSizeData?.[0]?.items?.map(item =>
    graphBlockSize.push({
      rawData: { topItems: item.topItems },
      count: item.count,
      sumSize: item.sum,
      xAxis: getXAxisLabel(item?.axisX?.min, item?.axisX?.max, true),
    }),
  );

  return graphBlockSize;
};

export const parseGraphAskingRentData = (
  askingRentData?: SnowflakeBlockAvailabilityChart[],
  filters?: LeaseToggleOptions,
) => {
  const askingRentName = `asking-rent-${getAttrRentType(filters)}`;
  const data = askingRentData?.find(x => x.name === askingRentName);

  const graphAskingRent: any[] = [];

  data?.items?.map((item: any) =>
    graphAskingRent.push({
      rawData: { topItems: item.topItems },
      count: item.count,
      sumSize: item.sum,
      xAxis: getXAxisLabel(
        item.axisX.min,
        item.axisX.max,
        false,
        data?.currency || CountryCodes.US,
        0,
      ),
    }),
  );

  return graphAskingRent;
};

const getXAxisLabel = (
  min?: number,
  max?: number,
  isBlockSize?: boolean,
  currencyCode?: string,
  increment = 1,
) => {
  const options = {
    currencySymbol: currencyCode ? getCurrencySymbol(currencyCode) : undefined,
    decimals: 2,
    ignoreDecimalsForInteger: true,
    abbreviateMillions: true,
  };
  if (isBlockSize) {
    return min && max
      ? `${abbreviateNumber(min)} - ${abbreviateNumber(max + increment)}`
      : min
      ? `> ${abbreviateNumber(min)}`
      : max
      ? `< ${abbreviateNumber(max + increment)}`
      : '';
  } else {
    return min && max
      ? `${formatCurrency(min, options)} - ${formatCurrency(
          max + increment,
          options,
        )}`
      : min
      ? `> ${formatCurrency(min, options)}`
      : max
      ? `< ${formatCurrency(max + increment, options)}`
      : '';
  }
};

const getAttrRentType = (filters?: LeaseToggleOptions) => {
  return filters?.rentType == LeaseRentType.FS
    ? filters.timeMeasurement == LeaseBaseRentTime.Monthly
      ? 'fs-monthly'
      : 'fs'
    : filters?.timeMeasurement == LeaseBaseRentTime.Monthly
    ? 'nnn-monthly'
    : 'nnn';
};

export const getValue = (
  item: IGraphAskingRentBlockSize,
  mapToggleOptions: SwitchOption,
) => {
  return mapToggleOptions.value == AskingRentOptions.BLOCKS
    ? item.count
    : item.sumSize;
};

export const getTickValues = (
  targetPropertyData: IGraphAskingRentBlockSize[],
  mapToggleOptions: SwitchOption,
) => {
  const values = targetPropertyData.map(
    item => getValue(item, mapToggleOptions)!,
  );

  const maxYAxisValue = Math.max(...values);
  const yAxisValue = Math.round(maxYAxisValue / 4);

  return [0, yAxisValue, yAxisValue * 2, yAxisValue * 3, maxYAxisValue];
};

const getStrokeWidth = (datum: any) => (datum?.tick >= 0 ? 1 : 0);

export const getBlockSizeStyle = (
  graphBlockSizeData: any[],
  mapToggleOptions: SwitchOption,
  propertyTypeId?: number,
): VictoryBarStyle => {
  return {
    bar: {
      ...DEFAULT_STYLE.bar,
      width: 40,
    },
    chart: {
      ...DEFAULT_STYLE.chart,
      domainPadding: { x: [30, 0], y: [0, 0] },
      padding: {
        ...DEFAULT_STYLE.chart?.padding,
        left: 36,
      },
    },
    yAxis: {
      ...DEFAULT_STYLE.yAxis,
      style: {
        axis: { stroke: 'none' },
        tickLabels: {
          fontSize: 7,
          fill: colors.ayWhiteColor,
        },
        grid: {
          stroke: colors.uiColor700,
          strokeWidth: getStrokeWidth,
        },
        ticks: {
          stroke: colors.uiColor700,
          strokeWidth: getStrokeWidth,
          size: 15,
        },
      },
      tickValues: getTickValues(graphBlockSizeData, mapToggleOptions),
      tickFormat: (value: number) => abbreviateNumber(value),
    },
    xAxis: {
      style: {
        axis: { stroke: 'none' },
        tickLabels: {
          ...DEFAULT_STYLE.xAxis?.style?.tickLabels,
          fontSize: propertyTypeId === PropertyTypeIds.industrial ? 7 : 8,
        },
      },
    },
  };
};

export const getAskingRentStyle = (
  graphAskingRentData: any[],
  mapToggleOptions: SwitchOption,
): VictoryBarStyle => {
  return {
    bar: { ...DEFAULT_STYLE.bar, width: 40 },
    chart: {
      ...DEFAULT_STYLE.chart,
      domainPadding: { x: [30, 0], y: [0, 0] },
      padding: {
        ...DEFAULT_STYLE.chart?.padding,
        left: 36,
      },
    },
    yAxis: {
      ...DEFAULT_STYLE.yAxis,
      style: {
        axis: { stroke: 'none' },
        tickLabels: {
          fontSize: 7,
          fill: colors.ayWhiteColor,
        },
        grid: {
          stroke: colors.uiColor700,
          strokeWidth: getStrokeWidth,
        },
        ticks: {
          stroke: colors.uiColor700,
          strokeWidth: getStrokeWidth,
          size: 15,
        },
      },
      tickValues: getTickValues(graphAskingRentData, mapToggleOptions),
      tickFormat: (value: number) => abbreviateNumber(value),
    },
    xAxis: {
      style: {
        axis: { stroke: 'none' },
        tickLabels: {
          ...DEFAULT_STYLE.xAxis?.style?.tickLabels,
        },
      },
    },
  };
};

const getLabelForTooltip = (mapToggleOptions: SwitchOption) => {
  return mapToggleOptions.value == AskingRentOptions.BLOCKS
    ? lowerCase(
        translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.blocks`),
      )
    : UnitOfMeasurement.sf;
};

const getMinBarValueToChart = (maxValue: number, percentage = 2) => {
  const minValue = (maxValue / 100) * percentage;
  return minValue;
};

export const buildBarDataChart = (
  name: string,
  mapToggleOptions: SwitchOption,
  graphData?: any,
  onClickBar?: (datum: any) => void,
): VerticalBarData[] => {
  const maxYValue = getTickValues(graphData, mapToggleOptions)[1];
  const minBarValue = getMinBarValueToChart(maxYValue);
  return [
    {
      name: name,
      color: colors.primaryColor500,
      values: graphData.map((item: any) => {
        const barYValue = getValue(item, mapToggleOptions) || 0;
        return {
          x: item.xAxis,
          y: barYValue > 0 && barYValue < minBarValue ? minBarValue : barYValue,
          label: getTooltipByToggleType(item, mapToggleOptions),
          rawData: item.rawData,
        };
      }),
      style: {
        data: {
          cursor: 'pointer',
        },
      },
      events: [
        {
          target: 'data',
          eventHandlers: {
            onClick: () => [
              {
                target: 'data',
                mutation: (props: any) => onClickBar?.(props.datum),
              },
            ],
          },
        },
      ],
    },
  ];
};

const getTooltipByToggleType = (
  item: IGraphAskingRentBlockSize,
  mapToggleOptions: SwitchOption,
) => {
  return `${formatNumberWithCommas(
    getValue(item, mapToggleOptions),
  )} ${getLabelForTooltip(mapToggleOptions)}`;
};
