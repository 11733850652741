import React from 'react';
import { SaleField } from '../fields';
import ISale from 'interfaces/ISale';
import Card, { Size } from 'components/Card';
import { ColorNames } from 'constants/colorNames';
import { buildSaleCardHeader } from '../buildSaleCardHeader';
import { buildTagsListItems } from '../buildTagsListItems';
import SecondaryFields from './SecondaryFields';
import { CompType } from 'constants/compType';

type SaleCardProps = {
  fieldsToShow: SaleField[];
  secondaryFieldsToShow?: SaleField[];
  item: ISale;
  tagBackgroundColor?: ColorNames;
  showPropertyData?: boolean;
  showActionButtons?: boolean;
  showDropdown?: boolean;
  onDeleteSale?: () => void;
  onEditSale?: () => void;
  onUpdate?: () => void;
  size?: Size;
};

const SaleCard: React.FC<SaleCardProps> = props => {
  const {
    fieldsToShow,
    item,
    tagBackgroundColor,
    showPropertyData,
    showActionButtons,
    showDropdown,
    onDeleteSale,
    onEditSale,
    onUpdate,
    size,
  } = props;

  const buildingMetadata = buildSaleCardHeader(item);
  const tags = buildTagsListItems(item, fieldsToShow);

  const comp = showDropdown
    ? {
        type: CompType.sale,
        id: item.id,
      }
    : undefined;

  return (
    <Card
      tags={tags}
      buildingMetadata={buildingMetadata}
      companiesLinks={item.buyers}
      property={item.property}
      showPropertyData={showPropertyData}
      showActionButtons={showActionButtons}
      tagBackgroundColor={tagBackgroundColor || ColorNames.ayFrozenGrassColor}
      onDelete={onDeleteSale}
      onEdit={onEditSale}
      onUpdate={onUpdate}
      comp={comp}
      size={size}
    >
      {!!props.secondaryFieldsToShow?.length && (
        <SecondaryFields fields={props.secondaryFieldsToShow} sale={item} />
      )}
    </Card>
  );
};

export default SaleCard;
