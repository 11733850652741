import { gql } from '@apollo/client';

export const COUNTRIES_QUERY = gql`
  query countries($search: CountrySearchInput) {
    countries(search: $search) {
      id
      name
      code
      flagURL
      distanceMeasurement
      areaMeasurement
      currencyCode
      createdAt
      updatedAt
    }
  }
`;
