import { SortDirections } from 'constants/sortDirections';
import { IActivitiesSearch } from 'interfaces/IActivitiesSearch';

export const recentActivityFilter = (propertiesIds: number[]) => {
  return {
    filter: {
      propertiesIds,
    },
  };
};

export const getQueryToDownloadPropertyActivities = (
  propertiesIds: number[],
): IActivitiesSearch => ({
  order: {
    direction: SortDirections.desc,
    field: 'activityDate',
  },
  ...recentActivityFilter(propertiesIds),
});
