import React, { useState } from 'react';
import classnames from 'classnames';
import styles from 'components/ModalMedia/Documents/DocumentCategoryPicker/DocumentCategoryPicker.module.scss';
import { DocumentNode, useQuery } from '@apollo/client';
import RadioInput, { IRadioItem } from 'components/Inputs/RadioInput';
import { IdName } from 'interfaces/IdName';
import { GET_DOCUMENT_CATEGORIES_QUERY } from 'graphql/documents';
import TextInput from 'components/Inputs/TextInput';
import LoadingMessage from 'components/LoadingMessage';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

type Props = {
  fileName?: string | null;
  labelInput?: string;
  labelTag?: string;
  onCategoryChange: (category: IRadioItem) => void;
  onFileNameChange: (newFileName: string) => void;
  selectedCategory?: IRadioItem;
  query?: DocumentNode;
};

const DocumentCategoryPicker: React.FC<Props> = props => {
  const [fileName, setFileName] = useState(props.fileName || '');

  const { data, loading } = useQuery(
    props.query || GET_DOCUMENT_CATEGORIES_QUERY,
    {
      fetchPolicy: 'cache-first',
    },
  );

  if (loading) {
    return (
      <div className={styles.loading}>
        <LoadingMessage
          itemBeingLoaded={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.category_plural`,
          )}
        />
      </div>
    );
  }

  const tagItems =
    data?.documentCategories?.map((ic: IdName) => ({
      value: ic.id,
      label: ic.name,
    })) ||
    data?.pursuitCategories?.map((ic: IdName) => ({
      value: ic.id,
      label: ic.name,
    })) ||
    [];

  const withLabels = props.labelInput && props.labelTag;

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles['file-name'], {
          [styles['file-name-with-label']]: withLabels,
        })}
      >
        {props.labelInput && <p className={styles.label}>{props.labelInput}</p>}
        <TextInput
          fullWidth
          isSmall
          value={fileName}
          onChange={t => {
            setFileName(t);
            props.onFileNameChange(t);
          }}
        />
      </div>
      <div
        className={classnames(styles['tags'], {
          [styles['tags-with-label']]: withLabels,
        })}
      >
        {props.labelTag && <p className={styles.label}>{props.labelTag}</p>}
        <RadioInput
          data={tagItems}
          selectedItem={props.selectedCategory}
          onChange={props.onCategoryChange}
          isColumnLayout
        />
      </div>
    </div>
  );
};

export default DocumentCategoryPicker;
