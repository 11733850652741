import React from 'react';
import { IActivity } from 'interfaces/IActivity';
import { IProperty } from 'interfaces/IProperty';
import LeaseCard from 'components/ActivityCard/new/LeaseCard';
import { LeaseCardField } from 'components/Leases/fields';
import PricingQuoteCard from '../ActivityCard/new/PricingQuoteCard';
import TenantCard from '../Leases/TenantCard';
import { TypeOfCard } from '../ActivityCard/new/utils';
import { PricingQuoteCardField } from '../PricingQuotes/PropertyPricingQuoteCard/fields';
import ActivityCardItem from '../ActivityCard/ActivityCardItem';
import ScoopCard from '../ActivityCard/new/ScoopCard';

interface Props {
  activities: IActivity[];
  refetchActivities: () => void;
  preselectedProperty?: IProperty;
  type?: TypeOfCard;
}

const ActivityList: React.FC<Props> = props => {
  const renderCard = (activity: IActivity, index: number) => {
    if (activity.scoop) {
      return (
        <ScoopCard
          key={`${activity.id}-${index}`}
          activity={activity}
          refetchScoopList={props.refetchActivities}
          type={props.type}
          preselectedProperty={props.preselectedProperty}
        />
      );
    }

    if (activity.TIM) {
      return (
        <TenantCard
          key={`${activity.id}-${index}`}
          tim={activity.TIM}
          isLoading={false}
          company={activity?.TIM.tenantCompany}
          hasDeleteButton={false}
          displayFormOnEdit={true}
          refetchScoopList={props.refetchActivities}
        />
      );
    }

    if (activity.lease) {
      return (
        <LeaseCard
          key={`${activity.id}-${index}`}
          lease={activity.lease}
          activity={activity}
          refetchScoopList={props.refetchActivities}
          type={props.type}
          displayFields={[
            LeaseCardField.SignedDate,
            LeaseCardField.Size,
            LeaseCardField.RentPrice,
            LeaseCardField.Type,
            LeaseCardField.Escalation,
            LeaseCardField.TI,
            LeaseCardField.Term,
            LeaseCardField.FreeMonths,
            LeaseCardField.Floor,
            LeaseCardField.SpaceUse,
            LeaseCardField.ExpirationDate,
            LeaseCardField.TenantIndustry,
            LeaseCardField.LeaseOptions,
            LeaseCardField.AdditionalConcessions,
            LeaseCardField.CommencementDate,
            LeaseCardField.TaxAndOpex,
            LeaseCardField.ExpandingContracting,
          ]}
        />
      );
    }

    if (activity.propertyPriceQuote) {
      return (
        <PricingQuoteCard
          key={`${activity.id}-${index}`}
          activity={activity}
          refetch={props.refetchActivities}
          displayFields={[
            PricingQuoteCardField.TI,
            PricingQuoteCardField.FreeRentMonths,
            PricingQuoteCardField.TermInMonths,
            PricingQuoteCardField.LeaseType,
            PricingQuoteCardField.Size,
            PricingQuoteCardField.Floors,
            PricingQuoteCardField.Source,
            PricingQuoteCardField.TaxAndOpex,
            PricingQuoteCardField.Escalation,
            PricingQuoteCardField.QuoteDate,
          ]}
          type={props.type}
        />
      );
    }

    return (
      <ActivityCardItem
        activity={activity}
        key={activity.id}
        refetchScoopList={props.refetchActivities}
        preselectedProperty={props.preselectedProperty}
        type={props.type}
      />
    );
  };

  return (
    <>
      {props.activities.map((activity, index) => renderCard(activity, index))}
    </>
  );
};

export default ActivityList;
