import React from 'react';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { SEARCH_PROPERTIES_QUERY } from 'graphql/property';
import SearchInput, { SearchInputPropTypes } from 'components/SearchInput';
import { IProperty } from 'interfaces/IProperty';
import styles from './PropertiesSearchInput.module.scss';
import { SuggestionIcon } from '../SearchInput/enums';
import { getCoverImageUrl } from '../ModalMedia/utils';
import { getPropertyAddress } from 'utils/formatters/property';
import {
  IQuerySearchInput,
  SearchResultDataType,
} from 'components/SearchInput/interfaces';
import { PropertyImageSizes } from 'constants/propertyImageSizes';
import { ISearchInputCustomOption } from 'interfaces/ISearchInputCustomOption';
import { IdName } from 'interfaces/IdName';

export type Props = {
  initialValue?: string;
  clearAfterSuggestionClick?: boolean;
  customOption?: ISearchInputCustomOption;
  filterSuggestions?: (data: SearchResultDataType[]) => SearchResultDataType[];
  hasIcon?: boolean;
  inputId?: string;
  isDisabled?: boolean;
  isEditable?: boolean;
  onChangeText?: (value?: string) => void;
  onClearSelectedSuggestion?: () => void;
  onClickSuggestion: (property: IProperty) => void;
  placeholder?: string;
  property?: IProperty;
  searchInputProps?: Partial<SearchInputPropTypes>;
  withMarketSwitch?: boolean;
  graphqlParams?: IQuerySearchInput;
  parseResults?: (data: IPropertyCompany[]) => IPropertyCompany[];
  companyFilter?: IdName[];
};

const PropertiesSearchInput: React.FC<Props> = props => {
  const {
    initialValue,
    clearAfterSuggestionClick,
    customOption,
    filterSuggestions,
    hasIcon,
    inputId,
    isDisabled,
    isEditable,
    onChangeText,
    onClearSelectedSuggestion,
    onClickSuggestion,
    placeholder,
    property,
    searchInputProps,
    withMarketSwitch = true,
    graphqlParams,
    parseResults,
    companyFilter,
  } = props;

  const parsePropertiesToPropertyCompany = (
    properties?: IProperty[],
  ): IPropertyCompany[] => {
    return (
      properties?.map((p: IProperty) => ({
        id: p.id,
        name: getPropertyAddress(p) || '',
        type: 'property',
        propertyClassName: p.propertyClass?.name,
        marketId: p.market?.id,
        marketName: p.market?.name,
        submarketName: p.submarket?.name,
        micromarketName: p.micromarket?.name,
        propertyStatusName: p.status?.name,
        propertyId: p.id,
        propertyName: p.name,
        propertyType: p.propertyType?.name,
        buildingSize: p.buildingSize,
        owners: p.owners,
      })) || []
    );
  };

  const propertyInputEditable = () => {
    if (!property?.primaryAddress) {
      return null;
    }

    const propertyImageUrl = getCoverImageUrl(property, PropertyImageSizes.xsm);
    return (
      <div className={styles['property-container']}>
        {!!propertyImageUrl && (
          <div className={styles['property-image-container']}>
            <img
              alt={property.name}
              src={propertyImageUrl}
              className={styles['property-image']}
            />
          </div>
        )}

        {getPropertyAddress(property)}
      </div>
    );
  };

  const queryParams = graphqlParams ?? {
    query: SEARCH_PROPERTIES_QUERY,
    field: 'primaryAddress',
    fieldOr: 'name',
    filterBySearchField: true,
    resultDataName: 'properties',
  };

  return (
    <SearchInput
      clearAfterSuggestionClick={clearAfterSuggestionClick}
      onClickSuggestion={onClickSuggestion}
      placeholder={placeholder}
      autofocus={false}
      isSearchIconLeftVisible={hasIcon}
      suggestionIconType={SuggestionIcon.propertyIcon}
      graphqlParams={{ ...queryParams, owners: companyFilter }}
      parseResults={parseResults || parsePropertiesToPropertyCompany}
      inputId={inputId || 'properties-search-input'}
      isEditable={isEditable}
      resultSelectedComponent={propertyInputEditable()}
      initialValue={getPropertyAddress(property) || initialValue || ''}
      onClearSelectedSuggestion={onClearSelectedSuggestion}
      isDisabled={isDisabled}
      filterSuggestions={filterSuggestions}
      {...(searchInputProps || {})}
      onChangeText={onChangeText}
      withMarketSwitch={withMarketSwitch}
      customOption={customOption}
    />
  );
};

export default PropertiesSearchInput;
