import React from 'react';
import styles from './SectionTitle.module.scss';

interface Props {
  title: string;
  fontSize?: number;
}

const DEFAULT_FONT_SIZE = 1.75;

const SectionTitle: React.FC<Props> = ({ title, fontSize }) => (
  <h3
    className={styles.title}
    style={{ fontSize: `${fontSize || DEFAULT_FONT_SIZE}rem` }}
  >
    {title}
  </h3>
);

export default SectionTitle;
