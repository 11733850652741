import { PROFILE_TYPES } from 'constants/profileTypes';
import {
  BuildingHistoricalRentResponse,
  CompanyHistoricalRentResponse,
  GraphsDataType,
} from './constants';
import {
  isDonutGraphDataValid,
  checkIfCurrentPerformancePercentageIsValid,
} from './Graphs/CurrentPerformance/utils';
import { isHistoricalRentDataValid } from './Graphs/HistoricalRent/utils';
import { isBarChartDataValid } from './Graphs/HistoricalAvailability/utils';
import {
  CurrentPerformanceDataType,
  HistoricalAvailabilityDataType,
} from './constants';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import { isNil } from 'lodash';

export const checkIfNumericFieldIsValid = (
  field?: any,
  mustBeHigherThanZero = false,
): boolean =>
  !isNil(field) && !isNaN(field) && (!mustBeHigherThanZero || field > 0);

export const checkIfIsCompanyProfile = (profileType: PROFILE_TYPES) =>
  profileType === PROFILE_TYPES.COMPANY;

export const sumSFValues = (available?: number, vacant?: number) =>
  (convertToNumber(available) || 0) + (convertToNumber(vacant) || 0);

export const shouldShowSection = (
  historicalAvailabilityGraphData: HistoricalAvailabilityDataType,
  currentPerformanceGraphData: CurrentPerformanceDataType,
  buildingHistoricalRentGraphData: BuildingHistoricalRentResponse,
  companyHistoricalRentGraphData: CompanyHistoricalRentResponse,
): boolean => {
  const hasHistoricalAvailabilityData =
    historicalAvailabilityGraphData.data &&
    !historicalAvailabilityGraphData.loading &&
    isBarChartDataValid(
      historicalAvailabilityGraphData.data?.graphAssetPerformanceTrend,
    );

  const hasCurrentPerformanceData =
    currentPerformanceGraphData.data &&
    !currentPerformanceGraphData.loading &&
    isDonutGraphDataValid(
      currentPerformanceGraphData.data?.graphAssetPerformanceCurrentProperty,
      currentPerformanceGraphData.data?.graphAssetPerformanceCurrentCompany,
    );

  const isCurrentPerformancePercentageValid =
    !!currentPerformanceGraphData.data?.graphAssetPerformanceCurrentProperty &&
    checkIfCurrentPerformancePercentageIsValid(
      currentPerformanceGraphData.data?.graphAssetPerformanceCurrentProperty,
    );

  const hasBuildingHistoricalRentData =
    buildingHistoricalRentGraphData.data &&
    !buildingHistoricalRentGraphData.loading &&
    isHistoricalRentDataValid(
      buildingHistoricalRentGraphData.data.buildingHistoricalRent,
    );

  const hasCompanyHistoricalRentData =
    companyHistoricalRentGraphData.data &&
    !companyHistoricalRentGraphData.loading &&
    isHistoricalRentDataValid(
      companyHistoricalRentGraphData.data.graphCompanyHistoricalRent,
    );

  return (
    (hasCurrentPerformanceData && isCurrentPerformancePercentageValid) ||
    hasHistoricalAvailabilityData ||
    hasCurrentPerformanceData ||
    hasCompanyHistoricalRentData ||
    hasBuildingHistoricalRentData
  );
};

export const getGraphsData = (
  companyHistoricalRentGraphData: CompanyHistoricalRentResponse,
  historicalRentGraphData: BuildingHistoricalRentResponse,
  currentPerformanceGraphData: CurrentPerformanceDataType,
  historicalAvailabilityGraphData: HistoricalAvailabilityDataType,
): GraphsDataType => ({
  currentPerformance: {
    data: currentPerformanceGraphData.data,
    loading: currentPerformanceGraphData.loading,
  },
  historicalPerformance: {
    data: historicalAvailabilityGraphData.data,
    loading: historicalAvailabilityGraphData.loading,
  },
  buildingHistoricalRent: {
    data: historicalRentGraphData.data,
    loading: false,
  },
  companyHistoricalRent: {
    data: companyHistoricalRentGraphData.data,
    loading: false,
  },
});
