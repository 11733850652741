import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { IActivity } from 'interfaces/IActivity';
import ActivityScoopCard from 'components/ActivityScoopCard';
import ActivityLeaseCard from 'components/Leases/ActivityLeaseCard';
import { LeaseCardField } from 'components/Leases/fields';
import ActivityPricingQuoteCard from 'components/PricingQuotes/ActivityPricingQuoteCard';
import { SaleField } from 'components/Sales/fields';
import ActivityShare from 'components/ActivityShare';
import ConfirmationModal from 'components/ConfirmationModal';
import NotificationMessage from 'components/NotificationMessage';
import { DELETE_SCOOP_MUTATION } from 'graphql/scoops';
import { ErrorLogger } from 'services/ErrorLogger';
import ManageScoopModal from 'components/ManageScoopModal';
import { PricingQuoteCardField } from 'components/PricingQuotes/PropertyPricingQuoteCard/fields';
import { IProperty } from 'interfaces/IProperty';
import ActivityMarketScoopCard from 'components/ActivityMarketScopCard';
import { translateText as t } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
} from 'constants/i18n';
import { IMarket } from 'interfaces/IMarket';
import SaleCard from '../new/SaleCard';
import { TypeOfCard } from '../new/utils';

interface Props {
  activity: IActivity;
  refetchScoopList: () => void;
  preselectedProperty?: IProperty;
  preselectedMarket?: IMarket;
  type?: TypeOfCard;
}

const I18N_PATH = 'avantProperties.texts.exploreActivity.scoop';

const messageScoopError = t(`${I18N_PATH}.scoopErrorMessage`);

const MINIMUM_MARKETS_LENGTH = 1;

const ActivityCardItem: React.FC<Props> = ({
  activity,
  refetchScoopList,
  preselectedProperty,
  preselectedMarket,
  type,
}) => {
  const client = useApolloClient();

  const [isShareActivityOpened, setIsShareActivityOpened] = useState<boolean>(
    false,
  );
  const [showShareNotification, setShowShareNotification] = useState(false);
  const [showModalScoop, setShowModalScoop] = useState(false);
  const [isDeletingScoop, setIsDeletingScoop] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState<string>('');
  const [showEditScoopModal, setShowEditScoopModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onShareButtonClick = () => setIsShareActivityOpened(true);

  const closeShareActivity = () => setIsShareActivityOpened(false);

  const renderActivityShare = () => {
    if (!isShareActivityOpened) return null;

    return (
      <ActivityShare
        onClose={closeShareActivity}
        activity={activity}
        onSharePopupClose={() => setShowShareNotification(true)}
      />
    );
  };

  const renderSuccessShareNotification = () => {
    return (
      <NotificationMessage
        show={showShareNotification}
        isSuccess
        text={t(`${I18N_PATH}.scoopNotification`)}
        onClose={() => setShowShareNotification(false)}
      />
    );
  };

  const showScoopNotification = (message: string, isSuccess?: boolean) => {
    setMessageNotification(message);
    setIsSuccess(isSuccess ?? true);
    setShowNotification(true);
  };

  const deleteScoop = async () => {
    if (activity.scoop) {
      try {
        setIsDeletingScoop(true);
        const { data } = await client.mutate<any>({
          mutation: DELETE_SCOOP_MUTATION,
          variables: { scoopId: activity.scoop?.id },
        });

        if (data?.deleteScoop) {
          refetchScoopList();
        } else {
          ErrorLogger.log(messageScoopError);
          showScoopNotification(messageScoopError, false);
        }
      } catch (e) {
        ErrorLogger.log(e as any, messageScoopError);
        showScoopNotification(messageScoopError, false);
      } finally {
        setShowModalScoop(false);
        setIsDeletingScoop(false);
      }
    }
  };

  const onEditScoop = () => activity.scoop && setShowEditScoopModal(true);

  const renderDeleteModal = () => {
    if (showModalScoop) {
      return (
        <ConfirmationModal
          onCloseModal={() => setShowModalScoop(false)}
          negativeButton={{
            label: isDeletingScoop
              ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`)
              : t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`),
            isDisabled: isDeletingScoop,
            onclick: deleteScoop,
          }}
          positiveButton={{
            label: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`),
            onclick: () => setShowModalScoop(false),
          }}
          header={t('avantProperties.labels.exploreActivity.deleteScoop')}
          paragraph={[
            t(`${I18N_PATH}.firstConfirmToDeleteScoopText`),
            t(`${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.askToProceed`),
          ]}
        />
      );
    }
  };

  const renderNotification = () => {
    return (
      <NotificationMessage
        show={showNotification}
        text={messageNotification}
        onClose={() => setShowNotification(false)}
        isSuccess={isSuccess}
      />
    );
  };

  const renderModalScoop = () => {
    return (
      <ManageScoopModal
        onCloseModal={() => setShowEditScoopModal(false)}
        onChangeData={refetchScoopList}
        onSuccessNotification={showScoopNotification}
        isVisible={showEditScoopModal}
        isEditing
        activityScoop={activity.scoop}
      />
    );
  };

  if (activity.scoop) {
    if (
      !activity?.scoop?.properties?.length ||
      (activity?.scoop?.properties?.length &&
        activity?.scoop?.markets &&
        activity?.scoop?.markets.length > MINIMUM_MARKETS_LENGTH)
    ) {
      return (
        <>
          <ActivityMarketScoopCard
            onShareButtonClick={onShareButtonClick}
            scoop={activity.scoop!}
            date={activity.activityDate!}
            user={activity.user!}
            key={activity.id}
            onDelete={() => setShowModalScoop(true)}
            onEdit={onEditScoop}
            refetchScoopList={refetchScoopList}
            activity={activity}
            preselectedMarket={preselectedMarket}
          />
          {renderActivityShare()}
          {renderSuccessShareNotification()}
          {renderDeleteModal()}
          {renderModalScoop()}
        </>
      );
    }

    return (
      <>
        <ActivityScoopCard
          onShareButtonClick={onShareButtonClick}
          scoop={activity.scoop!}
          property={activity.property!}
          preselectedProperty={preselectedProperty}
          date={activity.activityDate!}
          user={activity.user!}
          key={activity.id}
          onDelete={() => setShowModalScoop(true)}
          onEdit={onEditScoop}
          refetchScoopList={refetchScoopList}
        />
        {renderActivityShare()}
        {renderSuccessShareNotification()}
        {renderDeleteModal()}
        {renderModalScoop()}
      </>
    );
  }

  if (activity.lease) {
    return (
      <>
        <ActivityLeaseCard
          onShareButtonClick={onShareButtonClick}
          key={activity.id}
          lease={activity.lease}
          property={activity.property!}
          date={activity.activityDate!}
          onUpdate={refetchScoopList}
          displayFields={[
            LeaseCardField.Size,
            LeaseCardField.RentPrice,
            LeaseCardField.Escalation,
            LeaseCardField.TI,
            LeaseCardField.FreeMonths,
            LeaseCardField.Floor,
            LeaseCardField.SpaceUse,
            LeaseCardField.ExpirationDate,
            LeaseCardField.TenantBroker,
            LeaseCardField.LandlordBroker,
            LeaseCardField.LeaseOptions,
            LeaseCardField.AdditionalConcessions,
            LeaseCardField.CommencementDate,
            LeaseCardField.TaxAndOpex,
          ]}
        />
        {renderActivityShare()}
        {renderSuccessShareNotification()}
      </>
    );
  }

  if (activity.sale) {
    return (
      <>
        <SaleCard
          sale={activity.sale}
          activity={activity}
          fieldsToShow={[
            SaleField.Date,
            SaleField.Price,
            SaleField.PricePerSF,
            SaleField.AssetProfile,
            SaleField.CapRateFormatted,
            SaleField.Size,
            SaleField.AcquisitionType,
            SaleField.InterestPercentFormatted,
            SaleField.NumberOfBuildings,
            SaleField.Occupancy,
            SaleField.TenureType,
            SaleField.WaultToExpiry,
            SaleField.WaultToBreakDate,
            SaleField.TotalRent,
          ]}
          refetchScoopList={refetchScoopList}
          type={type}
        />
        {renderActivityShare()}
        {renderSuccessShareNotification()}
      </>
    );
  }

  if (activity.propertyPriceQuote) {
    return (
      <>
        <ActivityPricingQuoteCard
          date={activity.activityDate!}
          property={activity.property!}
          pricingQuote={activity.propertyPriceQuote}
          onShareButtonClick={onShareButtonClick}
          onUpdate={refetchScoopList}
          displayFields={[
            PricingQuoteCardField.TI,
            PricingQuoteCardField.FreeRentMonths,
            PricingQuoteCardField.TermInMonths,
            PricingQuoteCardField.LeaseType,
            PricingQuoteCardField.Size,
            PricingQuoteCardField.Floors,
            PricingQuoteCardField.Source,
            PricingQuoteCardField.TaxAndOpex,
          ]}
        />
        {renderActivityShare()}
        {renderSuccessShareNotification()}
      </>
    );
  }

  renderNotification();

  return null;
};

export default ActivityCardItem;
