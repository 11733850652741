import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import FormControl from 'components/FormControl';
import { REGEX_INTEGERS } from 'constants/regex';
import TextInput from 'components/Inputs/TextInput';
import RadioInput, { IRadioItem } from 'components/Inputs/RadioInput';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { getTermsSuggestions } from './constants';
import styles from '../../FormSection.module.scss';

interface Props {
  onChange: (field: string, value: any) => void;
  required?: boolean;
  value?: number | string | null;
  isTermInMonths?: boolean;
  message?: string;
}

const Term: React.FC<Props> = ({
  value,
  onChange,
  isTermInMonths,
  required = false,
  message,
}) => {
  const { t } = useTranslation();
  const field = isTermInMonths ? 'termInMonths' : 'term';
  const isTermInvalid = Number(value ?? 0) > 250;
  const [isInvalid, setIsInvalid] = useState<boolean>(isTermInvalid);

  useEffect(() => {
    setIsInvalid(isTermInvalid);
  }, [isTermInvalid]);

  return (
    <FormControl
      required={required}
      wrapperClassName={classnames(styles['form-row'], {
        [styles[`form-row-extended`]]: !!message,
      })}
      label={translateText('avantPlatform.attributes.lease.label.termInMonths')}
      labelClassName={classnames({
        [styles['error-message-label']]: !!message && isInvalid,
      })}
    >
      <div className={styles['large-input-container']}>
        <TextInput
          id="term"
          value={value}
          pattern={REGEX_INTEGERS}
          isInvalid={isTermInvalid}
          placeholder={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.enterMonths`,
          )}
          onChange={(value: string) => {
            onChange(field, value);
          }}
        />
        <RadioInput
          selectedItem={value}
          data={getTermsSuggestions(t)}
          onChange={(item: IRadioItem) => onChange(field, item.value)}
        />
      </div>
      {!!message && isInvalid && (
        <p className={styles['error-message']}>{message}</p>
      )}
    </FormControl>
  );
};

export default Term;
