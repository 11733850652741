export const DAY_MIN_LENGTH = 1;
export const DAY_MAX_LENGTH = 2;
export const MONTH_MIN_LENGTH = 1;
export const MONTH_MAX_LENGTH = 2;
export const YEAR_MAX_LENGTH = 4;
export const SEPARATOR = '/';

export interface ICustomValidation {
  isValid: boolean;
  message?: string;
}
