export const calculateFloorPlate = (
  buildingSize: number | undefined | null,
  stories: number | undefined | null,
): number | undefined => {
  if (!buildingSize || !stories) return undefined;
  return Math.floor(buildingSize / stories);
};

export const calculateTaxAndOpexTotal = (
  tax: number | undefined | null,
  opex: number | undefined | null,
): number | undefined => {
  const taxDefined = tax !== undefined && tax !== null;
  const opexDefined = opex !== undefined && opex !== null;
  return taxDefined && opexDefined
    ? Math.round((tax! + opex!) * 100) / 100
    : undefined;
};
