import { gql } from '@apollo/client';

export const AY_CONTACT_USER_FRAGMENT = gql`
  fragment AYContactUserInfo on AYContact {
    id
    firstName
    lastName
    email
  }
`;

export const USER_INFO_FRAGMENT = gql`
  ${AY_CONTACT_USER_FRAGMENT}
  fragment userInfo on User {
    id
    firstName
    lastName
    companyName
    jobTitle
    email
    token
    workPhone
    principalRole
    roles
    yearIndustry
    yearJoined
    language
    business {
      id
      name
    }
    businessType {
      id
      name
    }
    office {
      id
      name
    }
    subscriptions {
      features {
        id
        name
        featureType {
          id
          type
        }
      }
      product {
        name
      }
      fullAccess
      isActive
      productId
      accessData {
        marketId
        propertyId
        propertySetId
        submarketId
        companyId
        analyticsDashboardsId
      }
    }
    isProfileCompleted
    profileImageUrl
    preferences {
      propertyType
      propertyTypes
      marketIds
      termsAndConditionsDate
      termsAndConditionsVersion
      termsAndConditionsAccepted
      numberFormat
      weeklyScoopEmails
    }
    clientCompany {
      id
      name
      AYContactUser {
        ...AYContactUserInfo
      }
    }
  }
`;
