import { ILeasesAnalyticsExpirationsResponse } from 'interfaces/ILeasesAnalyticsExpiration';

export const isMissingData = (data?: ILeasesAnalyticsExpirationsResponse) => {
  if (!data) return true;
  if (!data.items) return true;
  for (let index = 0; index < data.items.length; index++) {
    const item = data.items[index];
    if (item.total > 0) {
      return false;
    }
  }
  return true;
};
