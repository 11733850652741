import React from 'react';
import classnames from 'classnames';
import styles from './UnitOfMeasurementLegend.module.scss';
import { UNITS_MEASUREMENT_AREA } from 'constants/unitOfMeasurement';

interface Props {
  disabled?: boolean;
  shouldConvert?: boolean;
  unitOfMeasurement: string;
}

const UnitOfMeasurementLegend: React.FC<Props> = ({
  disabled = false,
  unitOfMeasurement,
  shouldConvert = true,
}) => (
  <span
    className={classnames(styles['legend'], {
      [styles['disabled']]: disabled,
    })}
  >
    {shouldConvert
      ? UNITS_MEASUREMENT_AREA[unitOfMeasurement]
      : unitOfMeasurement}
  </span>
);

export default UnitOfMeasurementLegend;
