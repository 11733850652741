import React from 'react';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { REGEX_INTEGERS, REGEX_THREE_DECIMALS } from 'constants/regex';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  getFieldNameForProperty,
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
  getIndustrialFieldValue,
  getIndustrialFieldName,
} from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { translateText } from 'utils/i18n';
import { FieldType } from 'components/CreateComps/FormSection/PropertyForm/types';
import LoadingDocks from 'components/CreateComps/FormSection/FormFields/LoadingDocks';
import DriveInDoors from 'components/CreateComps/FormSection/FormFields/DriveInDoors';
import ClearHeightMin from 'components/CreateComps/FormSection/FormFields/ClearHeightMin';
import ClearHeightMax from 'components/CreateComps/FormSection/FormFields/ClearHeightMax';
import { TIMES } from 'constants/placeholders';
import { DOCK_CONFIGURATION_OPTIONS } from 'constants/dockConfigutations';
import styles from '../../FormSection.module.scss';
import { getBooleanSelectedOption, getBooleanValue } from '../utils';
import { getYesNoOptions, SPRINKLERS_OPTIONS } from './constants';

interface Props {
  property: IPropertyInput;
  onChange: {
    [FieldType.common]: (fieldName: string, fieldValue: any) => void;
    [FieldType.industrial]: (fieldName: string, fieldValue: any) => void;
  };
  classNames: {
    formControlClassName: string;
    choiceInputClassName: string;
    inputHelperClassName: string;
  };
}

const IndustrialFields: React.FC<Props> = ({
  onChange,
  property,
  property: { industrial },
  classNames: {
    formControlClassName,
    choiceInputClassName,
    inputHelperClassName,
  },
}) => {
  const YES_NO_OPTIONS = getYesNoOptions();

  const updateSiteSize = (value: string) => {
    onChange[FieldType.common](
      getFieldNameForProperty('siteSize', property),
      value || null,
    );
  };

  const updateColumnSpacingSize = (field: string, value: string) => {
    onChange[FieldType.industrial](
      getIndustrialFieldName(field, property),
      value || null,
    );
  };

  const getPropertyFieldValue = (field: string) =>
    getFieldValueForProperty(field, property);

  return (
    <>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.siteSize',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterSize',
          )}
          pattern={REGEX_THREE_DECIMALS}
          value={getPropertyFieldValue('siteSize')}
          onChange={value => updateSiteSize(value)}
        />
        <span className={inputHelperClassName}>
          {getUnitOfMeasurementForProperty(
            'siteSize',
            ModelsWithUnitsOfMeasurement.Property,
            property?.propertyCountry?.code || property?.measurementSystem,
          )}
        </span>
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.businessParkType',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          fullWidth
          borderBox
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterParkType',
          )}
          value={industrial?.businessParkType}
          onChange={value =>
            onChange[FieldType.industrial]('businessParkType', value)
          }
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.transportationConnectivity',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          fullWidth
          borderBox
          value={industrial?.transportationConnectivity}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterTransportationConnectivity',
          )}
          onChange={value =>
            onChange[FieldType.industrial]('transportationConnectivity', value)
          }
        />
      </FormControl>
      <LoadingDocks
        onChange={onChange[FieldType.industrial]}
        value={industrial?.loadingDocks}
      />
      <DriveInDoors
        onChange={onChange[FieldType.industrial]}
        value={industrial?.driveInDoors}
      />

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.dockConfiguration',
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          selectedItem={{
            id: industrial?.dockConfigurationType?.id,
          }}
          itemWrapperClassName={styles['form-margin-bottom-control']}
          data={DOCK_CONFIGURATION_OPTIONS}
          onChange={item => {
            onChange[FieldType.industrial]('dockConfigurationType', item);
          }}
        />
      </FormControl>

      <ClearHeightMin
        onChange={onChange[FieldType.industrial]}
        property={property}
        value={getIndustrialFieldValue('typicalClearHeightMin', property!)}
      />
      <ClearHeightMax
        onChange={onChange[FieldType.industrial]}
        property={property}
        value={getIndustrialFieldValue('typicalClearHeightMax', property!)}
      />
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.columnSpacing',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="columnSpacingMinInput"
          isNarrow
          textCentered
          placeholder={'00'}
          pattern={REGEX_INTEGERS}
          value={getIndustrialFieldValue('columnSpacingMin', property!)}
          onChange={value => updateColumnSpacingSize('columnSpacingMin', value)}
        />
        <span className={inputHelperClassName}>{TIMES}</span>
        <TextInput
          id="columnSpacingMaxInput"
          isNarrow
          textCentered
          placeholder={'00'}
          pattern={REGEX_INTEGERS}
          value={getIndustrialFieldValue('columnSpacingMax', property!)}
          onChange={value => updateColumnSpacingSize('columnSpacingMax', value)}
        />
        <span className={inputHelperClassName}>
          {getUnitOfMeasurementForProperty(
            'columnSpacingMax',
            ModelsWithUnitsOfMeasurement.Office,
            property?.propertyCountry?.code || property?.measurementSystem,
          )}
        </span>
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.trailerParkingSpaces',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          pattern={REGEX_INTEGERS}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterSpaces',
          )}
          value={industrial?.trailerParkingSpaces}
          onChange={value =>
            onChange[FieldType.industrial]('trailerParkingSpaces', value)
          }
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.parkingSpots',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          pattern={REGEX_INTEGERS}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterSpaces',
          )}
          value={property?.parkingSpots}
          onChange={value => onChange[FieldType.common]('parkingSpots', value)}
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.outsideStorage',
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={YES_NO_OPTIONS}
          containerWrapperClassName={choiceInputClassName}
          selectedItem={{
            name: getBooleanSelectedOption(
              YES_NO_OPTIONS,
              industrial?.outsideStorage,
            ),
          }}
          labelFieldName="id"
          onChange={value =>
            onChange[FieldType.industrial](
              'outsideStorage',
              getBooleanValue(
                value?.name,
                YES_NO_OPTIONS,
                industrial?.outsideStorage,
              ),
            )
          }
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.heavyPower',
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={YES_NO_OPTIONS}
          containerWrapperClassName={choiceInputClassName}
          selectedItem={{
            name: getBooleanSelectedOption(
              YES_NO_OPTIONS,
              industrial?.heavyPower,
            ),
          }}
          labelFieldName="id"
          onChange={value =>
            onChange[FieldType.industrial](
              'heavyPower',
              getBooleanValue(
                value?.name,
                YES_NO_OPTIONS,
                industrial?.heavyPower,
              ),
            )
          }
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.sprinklers',
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={SPRINKLERS_OPTIONS}
          containerWrapperClassName={choiceInputClassName}
          onChange={value =>
            onChange[FieldType.industrial]('sprinklers', value?.name)
          }
          selectedItem={{
            id: industrial?.sprinklers,
            name: industrial?.sprinklers,
          }}
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.buildingDepth',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          pattern={REGEX_INTEGERS}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterDepth',
          )}
          value={industrial?.buildingDepth}
          onChange={value =>
            onChange[FieldType.industrial]('buildingDepth', value)
          }
        />
      </FormControl>
    </>
  );
};

export default IndustrialFields;
