import FormField from 'components/FormControl/FormField';
import React from 'react';
import { RegionalFieldProps } from '..';
import { getUKFields } from './getUKFields';

interface Props extends RegionalFieldProps {
  onChangeOffice: (fieldName: string, fieldValue: any) => void;
}

const UKFields: React.FC<Props> = ({
  classNames: { formControlClassName, inputHelperClassName },
  onChange,
  onChangeOffice,
  property,
}) => (
  <>
    {getUKFields(property).map(fieldConfig => {
      const isOfficeField = [
        'storiesAboveGround',
        'storiesBelowGround',
      ].includes(fieldConfig.field!);

      return (
        <FormField
          key={fieldConfig.field || fieldConfig.labelI18nKey}
          onChange={isOfficeField ? onChangeOffice : onChange}
          dataObject={isOfficeField ? property?.office || {} : property || {}}
          fieldConfig={fieldConfig}
          formControlClassName={formControlClassName}
          inputHelperClassName={inputHelperClassName}
        />
      );
    })}
  </>
);

export default UKFields;
