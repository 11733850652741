import React, { useState } from 'react';
import { IPropertyDocument } from 'interfaces/IPropertyDocument';
import { IRadioItem } from 'components/Inputs/RadioInput';
import DocumentCategoryPicker from 'components/ModalMedia/Documents/DocumentCategoryPicker';
import { IdName } from 'interfaces/IdName';
import { IProperty } from 'interfaces/IProperty';
import ModalWrapper from 'components/ModalWrapper';
import Button from 'components/Button';
import styles from 'components/ModalMedia/Documents/DocumentItem/DocumentItem.module.scss';
import {
  SET_DOCUMENT_CATEGORY_MUTATION,
  UPDATE_DOCUMENT_MUTATION,
} from 'graphql/documents';
import { getDocumentRefetchQueries } from 'components/ModalMedia/utils';
import { I18N_MODAL_MEDIA_LABEL_PATH } from 'components/ModalMedia/constants';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import {
  useApolloClient,
  PureQueryOptions,
  DocumentNode,
} from '@apollo/client';
import { IAttachmentInput } from 'interfaces/inputs/IAttachmentInput';

interface Props {
  onCancel: () => void;
  onSave: (category?: IdName, name?: string) => void;
  document?: IPropertyDocument;
  property?: IProperty;
  refetchQueries?: PureQueryOptions[];
  documentCategoryQuery?: DocumentNode;
  attachment?: IAttachmentInput;
}

const EditTagsModal: React.FC<Props> = props => {
  const client = useApolloClient();

  const { property, refetchQueries, documentCategoryQuery } = props;

  const [category, setCategory] = useState<IdName>(
    props.document?.category || {
      id: props.attachment?.metadata?.category?.id,
      name: props.attachment?.metadata?.category?.name,
    },
  );

  const [name, setName] = useState(
    props.attachment?.metadata?.name ||
      props.document?.name ||
      props.attachment?.file?.name ||
      '',
  );
  const [isSaving, setIsSaving] = useState(false);

  const save = async () => {
    setIsSaving(true);

    if (props.document) {
      try {
        await client.mutate({
          mutation: SET_DOCUMENT_CATEGORY_MUTATION,
          variables: {
            documentId: props.document.id,
            categoryId: category.id,
          },
          refetchQueries:
            refetchQueries || getDocumentRefetchQueries(property?.id),
        });

        await client.mutate({
          mutation: UPDATE_DOCUMENT_MUTATION,
          variables: {
            documentId: props.document.id,
            name: name,
          },
          refetchQueries:
            refetchQueries || getDocumentRefetchQueries(property?.id),
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsSaving(false);
        props.onSave(category, name);
      }
    } else {
      setIsSaving(false);
      props.onSave(category, name);
    }
  };

  return (
    <ModalWrapper
      keepScrollLocked
      withCloseButton
      onCancel={props.onCancel}
      wrapperClassName={styles.container}
    >
      <DocumentCategoryPicker
        fileName={name}
        onFileNameChange={setName}
        query={documentCategoryQuery}
        labelTag={`${translateText(
          `${I18N_MODAL_MEDIA_LABEL_PATH}.common.changeTag`,
        )}:`}
        labelInput={`${translateText(
          `${I18N_MODAL_MEDIA_LABEL_PATH}.common.changeName`,
        )}:`}
        selectedCategory={{
          value: category.id,
          label: category.name,
        }}
        onCategoryChange={(selectedItem: IRadioItem) => {
          const category = {
            id: selectedItem.value!,
            name: selectedItem.label,
          };
          setCategory(category);
        }}
      />
      <div className={styles['edit-tags-buttons-container']}>
        <Button
          size="small"
          isDisabled={isSaving}
          onClick={props.onCancel}
          type="neutral"
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`)}
        />
        <Button
          size="small"
          type="main"
          onClick={save}
          isDisabled={isSaving}
          label={
            isSaving
              ? `${translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`,
                )}`
              : translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.updateInfo`,
                )
          }
        />
      </div>
    </ModalWrapper>
  );
};

export default EditTagsModal;
