import {
  LEASES_ANALYTICS_LEASE_SIZE,
  LEASES_ANALYTICS_LEASE_TYPE,
  LEASES_ANALYTICS_TOP_INDUSTRIES,
  LEASES_ANALYTICS_TRANSACTION_TYPE,
} from 'graphql/analyticsFindComps';
import { ILeaseSearchFilters } from 'interfaces/ILeasesSearch';
import { useQuery } from '@apollo/client';

export const useDonutsData = (filter?: ILeaseSearchFilters) => {
  const { data: transactionData, loading: isLoadingTransaction } = useQuery(
    LEASES_ANALYTICS_TRANSACTION_TYPE,
    {
      variables: {
        search: { filter: { ...filter, onlyNotExpired: true } },
      },
      skip: !filter,
    },
  );

  const { data: sizeData, loading: isLoadingSize } = useQuery(
    LEASES_ANALYTICS_LEASE_SIZE,
    {
      variables: {
        search: { filter: { ...filter, onlyNotExpired: true } },
      },
      skip: !filter,
    },
  );

  const { data: topIndustriesData, loading: isLoadingTopIndustries } = useQuery(
    LEASES_ANALYTICS_TOP_INDUSTRIES,
    {
      variables: {
        search: { filter: { ...filter, onlyNotExpired: true } },
      },
      skip: !filter,
    },
  );

  const { data: leaseTypeData, loading: isLoadingLeaseType } = useQuery(
    LEASES_ANALYTICS_LEASE_TYPE,
    {
      variables: {
        search: { filter: { ...filter, onlyNotExpired: true } },
      },
      skip: !filter,
    },
  );

  return {
    transactionData,
    isLoadingTransaction,
    sizeData,
    isLoadingSize,
    topIndustriesData,
    isLoadingTopIndustries,
    leaseTypeData,
    isLoadingLeaseType,
  };
};
