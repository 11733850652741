import { colors } from '../../../constants/colors';

export const GRAPH_DIMENSIONS = {
  width: 234,
  height: 234,
  padding: 10,
  hoverStrokeWidth: 5,
};

export const CIRCLE_WRAPPER = {
  outerId: 'outer-circle',
  innerId: 'inner-circle',
  fillOpacity: 0.24,
  fillOpacityActive: 0.32,
  fill: colors.ayPearGreenColor,
  strokeWidth: 1,
};

export const SVG_ID = 'svg-graph';

export enum GraphClasses {
  TooltipBottomLine = 'tooltip-bottom-line',
  TooltipTopLine = 'tooltip-top-line',
}
