import classnames from 'classnames';
import React, { useState } from 'react';
import { IdName } from 'interfaces/IdName';
import Button from 'components/Button/new';
import styles from './MarketSwitch.module.scss';
import { Size, Type } from 'interfaces/IIcon';
import { formatMarketsLabel } from 'utils/formatters/markets';
import MarketSelectorModal from 'components/MarketSelectorModal';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';

type Props = {
  size?: Size;
  type?: Type;
  isDisabled?: boolean;
  useLightLayout?: boolean;
  wrapperClassName?: string;
  selectedMarkets?: IdName[];
  onChange: (newMarkets: IdName[]) => void;
};

const MarketSwitch: React.FC<Props> = ({
  type,
  size,
  onChange,
  isDisabled,
  useLightLayout,
  selectedMarkets,
  wrapperClassName,
}) => {
  const [isMarketSelectorVisible, setIsMarketSelectorVisible] = useState(false);

  const buttonType = type ? type : useLightLayout ? 'ghost-light' : 'ghost';

  return (
    <>
      <div
        className={classnames(styles.container, {
          [styles.disabled]: isDisabled,
        })}
      >
        <Button
          type={buttonType}
          iconPosition="left"
          size={size || 'xs'}
          disabled={isDisabled}
          wrapperClassName={wrapperClassName}
          icon={useLightLayout ? 'mapPinDark' : 'mapPin'}
          label={
            formatMarketsLabel(selectedMarkets || []) ||
            translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.allMarkets`)
          }
          onClick={() => {
            !isDisabled && setIsMarketSelectorVisible(true);
          }}
        />
      </div>
      <MarketSelectorModal
        onSubmit={onChange}
        showRemoveSelection
        showResetMarketsButton
        selectedItems={selectedMarkets}
        isVisible={isMarketSelectorVisible}
        onClose={() => setIsMarketSelectorVisible(false)}
      />
    </>
  );
};

export default MarketSwitch;
