import { AgencyAssignmentsProps } from 'components/AgencyAssignments/types';
import ProfileSubSection from 'components/ProfileSubSection';
import { I18N_AVANT_PROPERTY_HEADINGS_PATH } from 'constants/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ILargestLeasesPopup } from 'stores/LeasesAnalytics/types';
import DonutsCharts from '../../DonutsCharts';
import KPIs from '../../KPIs';

interface Props extends AgencyAssignmentsProps {
  setLeasesPopup?: (popup: ILargestLeasesPopup) => void;
}

const Overview: React.FC<Props> = ({
  propertySet,
  setLeasesPopup,
  unitOfMeasurement,
  toggleOptions,
}) => {
  const { t } = useTranslation();
  const propertiesIds =
    propertySet.properties?.map(property => property.id!) || [];

  return (
    <ProfileSubSection
      title={t(`${I18N_AVANT_PROPERTY_HEADINGS_PATH}.overview`)}
    >
      {propertySet.id && (
        <KPIs propertySet={propertySet} toggleOptions={toggleOptions} />
      )}
      <DonutsCharts
        propertiesIds={propertiesIds}
        setLeasesPopup={setLeasesPopup}
        unitOfMeasurement={unitOfMeasurement}
      />
    </ProfileSubSection>
  );
};

export default Overview;
