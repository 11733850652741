import React, { Dispatch, SetStateAction, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import TableView from 'components/TableView';
import { FindCompCriteriaTabs } from 'constants/FindCompTabs';
import { SortDirections } from 'constants/sortDirections';
import {
  I18N_AVANT_PROPERTY_PAGES,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { PropertyTypes } from 'constants/propertyTypes';
import { GraphqlSearchVariables } from 'pages/FindComps';
import { ISearchOrder } from 'interfaces/ISearch';
import ISale from 'interfaces/ISale';
import { SEARCH_SALES_QUERY_PAGINATION } from 'graphql/sales';
import { translateText } from 'utils/i18n';
import { PAGE_SIZE, INITIAL_PAGE_SIZE } from '../findCompsConstants';
import { saleTableHeader } from './saleTableHeader';

type Props = {
  graphqlSearchVariables: GraphqlSearchVariables;
  isActive: boolean;
  isSelectedUkCountryOrCity: boolean;
  unitOfMeasurement?: string;
  isSelectAllChecked?: boolean;
  setIsSelectAllChecked?: (value: boolean) => void;
  uncheckedIds: number[];
  setUncheckedIds: Dispatch<SetStateAction<number[]>>;
  onClickEditSearch: (activeTab?: FindCompCriteriaTabs) => void;
  onLoadData: (data?: ISale[]) => void;
  onSelectedIds?: (ids: Array<number>) => void;
  selectedIds?: Array<number>;
};

export const SALES_TABLE_ID = 'find-comps-sales-table';

const SalesTableContainer: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    isSelectedUkCountryOrCity,
    selectedIds,
    onSelectedIds,
    isSelectAllChecked,
    setIsSelectAllChecked,
    uncheckedIds,
    setUncheckedIds,
  } = props;

  const [order, setOrder] = useState<ISearchOrder>(
    props.graphqlSearchVariables.sales.order || {
      field: 'date',
      direction: SortDirections.desc,
    },
  );

  const getQueryVariables = (page?: number) => {
    const filter = Object.assign(
      {},
      props.graphqlSearchVariables.sales?.filter,
    );
    delete filter?.ids;
    delete filter?.excludedIds;
    return {
      search: {
        order,
        filter: filter,
        page: {
          page: page,
          size: page === 1 ? INITIAL_PAGE_SIZE : PAGE_SIZE,
        },
      },
    };
  };

  const propertyTypes =
    props.graphqlSearchVariables?.sales?.filter?.propertyTypes || [];
  const onlyMultifamilySelected =
    propertyTypes.includes(PropertyTypes.multifamily) &&
    propertyTypes.length === 1;

  const queryVariables = getQueryVariables(1);

  const salesI18N = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.sale_plural`,
  );

  return (
    <TableView
      id={SALES_TABLE_ID}
      isActive={props.isActive}
      onClickEditSearch={() =>
        props.onClickEditSearch(FindCompCriteriaTabs.sale)
      }
      noResultsMessage={translateText(
        `${I18N_AVANT_PROPERTY_PAGES}.findComps.noCompsFound`,
        { comp: salesI18N.toLowerCase() },
      )}
      onChangeOrder={(orderField: string, orderDir: SortDirections) => {
        setOrder({
          field: orderField,
          direction: orderDir,
        });
        return true;
      }}
      order={order}
      columns={saleTableHeader(
        t,
        props.unitOfMeasurement,
        onlyMultifamilySelected,
        isSelectedUkCountryOrCity,
      )}
      hasEditButton
      hasSelectItems
      onSelectedIds={onSelectedIds}
      selectedIds={selectedIds}
      setIsSelectAllChecked={setIsSelectAllChecked}
      isSelectAllChecked={isSelectAllChecked}
      uncheckedIds={uncheckedIds}
      setUncheckedIds={setUncheckedIds}
      query={SEARCH_SALES_QUERY_PAGINATION}
      getQueryVariables={getQueryVariables}
      typeOfData="sales"
      skip={isEmpty(queryVariables.search.filter)}
      onCompleted={data => {
        if (data?.sales) {
          props.onLoadData(data.sales.results as ISale[]);
        }
      }}
    />
  );
};

export default SalesTableContainer;
