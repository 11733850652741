import React from 'react';
import { Menu, MenuButton, MenuList, MenuLink } from '@reach/menu-button';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';
import styles from './NavBarGridControl.module.scss';
import { colors } from 'constants/colors';
import { Text } from 'components/@codelitt/ay-design-library';
import { useSubscriptions } from 'contexts/SubscriptionsContext';
import {
  APPLICATIONS,
  EXTERNAL_LINKS as EXTERNAL_LINKS_ACCESS,
} from 'constants/subscriptions';
import { translateText } from 'utils/i18n';

const NavBarGridControl = () => {
  const { checkSubscriptions } = useSubscriptions();

  const I18N_PATH = `avantProperties.components.gridControl`;
  const I18N_COMMON_PATH = `avantPlatform.common.words`;

  const capitalizeAvant = translateText(
    `${I18N_COMMON_PATH}.avant`,
  ).toUpperCase();

  const EXTERNAL_LINKS = [
    {
      title: translateText(`${I18N_PATH}.hubspot`),
      link: 'https://app-eu1.hubspot.com/',
      access: checkSubscriptions(EXTERNAL_LINKS_ACCESS.HUBSPOT),
    },
    {
      title: translateText(`${I18N_PATH}.buildOut`),
      link: 'https://buildout.com/login',
      access: checkSubscriptions(EXTERNAL_LINKS_ACCESS.BUILD_OUT),
    },
    {
      title: translateText(`${I18N_PATH}.costar`),
      link: 'http://costar.com/',
      access: checkSubscriptions(EXTERNAL_LINKS_ACCESS.COSTAR),
    },
    {
      title: translateText(`${I18N_PATH}.altus`),
      link: 'https://www.altusinsite.com/',
      access: checkSubscriptions(EXTERNAL_LINKS_ACCESS.ALTUS),
    },
  ];

  const isExternalLinks = EXTERNAL_LINKS.some(link => link.access === true);

  const AVANT_LINKS = [
    {
      title: capitalizeAvant,
      subTitle: translateText(`${I18N_PATH}.subTitles.avant`),
      icon: 'performance',
      iconColor: colors.primaryColor500,
      link: '/',
      target: '_self',
      access: checkSubscriptions(APPLICATIONS.PROPERTIES),
    },
    {
      title: `${capitalizeAvant} ${translateText(
        `${I18N_COMMON_PATH}.cities`,
      )} `,
      subTitle: translateText(`${I18N_PATH}.subTitles.cities`),
      icon: 'world',
      iconColor: colors.supportive500,
      link: '/cities',
      target: '_blank',
      access: checkSubscriptions(APPLICATIONS.CITIES),
    },
  ];
  return (
    <Menu className={styles.menu}>
      <MenuButton className={styles['grid-control']}>
        <Icon name="gridLarge" className={styles.gridIcon} />
      </MenuButton>
      <MenuList className={styles.menulist}>
        <div className={styles['links-container']}>
          <div className={styles['internal-links-container']}>
            {AVANT_LINKS.map(
              ({ title, subTitle, icon, iconColor, access, link, target }) => {
                if (!access) return;
                return (
                  <div className={styles['links']} key={title}>
                    <Link
                      to={link}
                      target={target}
                      rel="noopener"
                      className={styles['link-wrapper']}
                    >
                      <Icon
                        name={icon}
                        color={iconColor}
                        className={styles['link-icon']}
                      />
                      <div>
                        <Text
                          type="title"
                          value={title}
                          wrapperClassName={styles['link-title']}
                        />
                        <Text
                          type="body-content"
                          value={subTitle}
                          wrapperClassName={styles['link-subtitle']}
                        />
                      </div>
                    </Link>
                  </div>
                );
              },
            )}
          </div>

          {isExternalLinks && (
            <div className={styles['external-links']}>
              <Text
                type="body-content"
                value={translateText(`${I18N_PATH}.externalLinks`)}
                wrapperClassName={styles['external-link-title']}
              />
              {EXTERNAL_LINKS.map(({ title, link, access }) => {
                if (!access) return;
                return (
                  <MenuLink
                    key={title}
                    as="a"
                    href={link}
                    target="_blank"
                    rel="noopener"
                    className={styles['external-link']}
                  >
                    {title}
                  </MenuLink>
                );
              })}
            </div>
          )}
        </div>
      </MenuList>
    </Menu>
  );
};

export { NavBarGridControl };
