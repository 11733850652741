import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { graphDimensions } from '../graphConstants';
import { colors } from 'constants/colors';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { ISaleBarData } from '../interfaces';

type Props = {
  data: ISaleBarData[];
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
};

const X_RECENT_FACTOR = 0.8;
const X_AVERAGE_FACTOR = 3.6;

const RemarkableLegend: React.FC<Props> = (props: Props) => {
  const remarkableLegendRef = useRef(null);
  const { data, xScale, yScale } = props;

  const getTextColor = (d: ISaleBarData) => {
    return d.isQuarter ? colors.ayGrey40Color : colors.ayGrassGreenColor;
  };

  // since 'Most Recent' legend text size differs from 'Market Avg' legend size we need to calculate with different offset values
  const getXTextOffset = (d: ISaleBarData) => {
    return `${d.isQuarter ? X_RECENT_FACTOR : X_AVERAGE_FACTOR}px`;
  };

  useEffect(() => {
    const node = remarkableLegendRef.current;
    const getYScale = (d: ISaleBarData) => {
      const value = d.total || d.size;
      const yValue = yScale(value!) || 0;

      return graphDimensions.HEIGHT - yValue;
    };

    select(node)
      .selectAll('text')
      .data(data)
      .enter()
      .append('text')
      .attr('x', d => xScale(d.key)!)
      .attr('y', d => getYScale(d))
      .attr('dx', getXTextOffset)
      .attr('dy', '-48px')
      .style('fill', getTextColor)
      .style('font-size', '12px')
      .text(d => d.remarkableLegend!);
  }, [data, xScale, yScale]);

  return <g ref={remarkableLegendRef} />;
};

export default RemarkableLegend;
