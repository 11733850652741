import { gql } from '@apollo/client';

export const GET_SNOWFLAKE_BLOCK_AVAILABILITIES = gql`
  query blockAvailabilities(
    $propertyIds: [Int!]!
    $sortOptions: [SnowlakeSortFilter]
    $search: SnowflakeBlockAvailabilitySearchInput
  ) {
    blockAvailabilities(
      propertyIds: $propertyIds
      sortOptions: $sortOptions
      search: $search
    ) {
      numberOfAvailabilities
      numberOfDirectAvailabilities
      numberOfSubletAvailabilities
      directAvailableSf
      subletAvailableSf
      totalAvailableSf
      annualDirectAskingRentFS
      monthlyDirectAskingRentFS
      annualDirectAskingRentNNN
      monthlyDirectAskingRentNNN
      annualTotalAskingRentFS
      monthlyTotalAskingRentFS
      annualTotalAskingRentNNN
      monthlyTotalAskingRentNNN
      annualSubletAskingRentFS
      monthlySubletAskingRentFS
      annualSubletAskingRentNNN
      monthlySubletAskingRentNNN
      percentOfSupplyAvailable
      percentOfSupplyDirectAvailable
      percentOfSupplySubletAvailable
      availabilitiesByProperties {
        propertyId
        primaryAddress
        latitude
        longitude
        askingRentFS
        askingRentFSMonthly
        askingRentNNN
        askingRentNNNMonthly
        totalAvailability
        currency
        propertyType {
          id
          name
        }
        propertySubType {
          id
          name
        }
        market {
          id
          name
        }
        subMarket {
          id
          name
        }
        buildingSize
        availabilities {
          blockName
          blockSize
          spaceLeaseType
          askingRent
          fsRent
          nnnRent
          rentFrequency
          currency
          opexAndTaxes
          expensesYear
          fsRentMonth
          nnnRentMonth
          askingRentMonth
          propertyId
        }
      }
      chartsAskingRent {
        name
        currency
        items {
          axisX {
            min
            max
          }
          count
          sum
          topItems
        }
      }
      chartsBlockSize {
        name
        items {
          axisX {
            min
            max
          }
          count
          sum
          topItems
        }
      }
      chartsPropertyAskingRents {
        name
        xAxis
        yAxis
        data {
          x
          y
          propertyId
          primaryAddress
        }
      }
      chartsPropertyAvailabilities {
        name
        xAxis
        yAxis
        data {
          x
          y
          propertyId
          primaryAddress
        }
      }
    }
  }
`;

export const GET_SNOWFLAKE_BLOCK_AVAILABILITIES_TIME_WIDE = gql`
  query blockAvailabilitiesOverTimeWide($propertyIds: [Int!]!) {
    blockAvailabilitiesOverTimeWide(propertyIds: $propertyIds) {
      directAvailableSf
      annualDirectAskingRentFS
      monthlyDirectAskingRentFS
      annualDirectAskingRentNNN
      monthlyDirectAskingRentNNN
      availabilitiesByProperties {
        propertyId
        primaryAddress
        askingRentFS
        askingRentFSMonthly
        askingRentNNN
        askingRentNNNMonthly
      }
    }
  }
`;
