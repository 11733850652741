import React from 'react';
import dayjs from 'dayjs';
import locations from 'routes';
import { useHistory } from 'react-router-dom';
import CompsPopup from 'components/CompsPopup';
import { IProperty } from 'interfaces/IProperty';
import { FindCompTabs } from 'constants/FindCompTabs';
import { FindCompsSearchCriteriaService } from 'services/FindCompsSearchCriteriaService';
import { SearchOperations } from 'constants/searchOperations';
import dateFormats from 'constants/dateFormats';
import { ColorNames } from 'constants/colorNames';
import { GET_SALE_QUERY, SEARCH_SALES_QUERY } from 'graphql/sales';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { isEmpty } from 'lodash';
import { extractPropertyIdsFromCompSet } from 'utils/propertySets';
import {
  I18N_RECENT_SALES_CHART_TEXT_PATH,
  I18N_RECENT_SALES_CHART_LABEL_PATH,
} from '../constants';
import { translateText } from 'utils/i18n';

interface Props {
  activePropertySet: IPropertySet | null;
  onClose: () => void;
  property: IProperty;
  saleId: string;
  onChangeData?: () => void;
  hasDataChanges?: boolean;
}

const Popup: React.FC<Props> = ({
  activePropertySet,
  property,
  saleId,
  onClose,
  onChangeData,
  hasDataChanges,
}) => {
  const history = useHistory();
  const compsPopupGraphqlQuery = saleId ? GET_SALE_QUERY : SEARCH_SALES_QUERY;

  const shouldUseActiveCompSetQuery =
    !isEmpty(activePropertySet?.properties) && !saleId;

  const baseCompSetVariables = {
    competitiveSet: true,
    competitiveSetIncludeProperty: true,
    propertyId: property.id,
    fields: {
      and: [
        {
          key: 'date',
          op: SearchOperations.gte,
          value: dayjs()
            .utc()
            .subtract(2, 'year')
            .format(dateFormats.ISO_DATE),
        },
        {
          key: 'date',
          op: SearchOperations.lte,
          value: dayjs()
            .utc()
            .format(dateFormats.ISO_DATE),
        },
      ],
    },
  };

  const activeCompSetVariables = {
    ...baseCompSetVariables,
    competitiveSet: false,
    competitiveSetIncludeProperty: undefined,
    propertyId: undefined,
    propertiesIds: extractPropertyIdsFromCompSet(activePropertySet),
  };

  const compSetFilterVariables = shouldUseActiveCompSetQuery
    ? activeCompSetVariables
    : baseCompSetVariables;

  const compsPopupGraphqlVariables = saleId
    ? { id: saleId }
    : {
        search: {
          order: {
            direction: 'desc',
            field: 'date',
          },
          filter: {
            ...compSetFilterVariables,
          },
        },
      };

  const onClickCompsPopupLink = () => {
    const urlParams = FindCompsSearchCriteriaService.buildPropertyFilters(
      property,
      false,
      true,
    );
    history.push(
      locations.findComps({ resultActiveTab: FindCompTabs.sales, urlParams }),
    );
  };

  return (
    <CompsPopup
      onClose={onClose}
      title={translateText(
        `${I18N_RECENT_SALES_CHART_LABEL_PATH}.competitiveSetSalesTitle`,
      )}
      action={onClickCompsPopupLink}
      actionLabel={translateText(
        `${I18N_RECENT_SALES_CHART_TEXT_PATH}.seeAllRecentSales`,
      )}
      graphqlQuery={compsPopupGraphqlQuery}
      showPropertyData={!saleId}
      tagBackgroundColor={
        !!saleId ? ColorNames.ayGrey8Color : ColorNames.ayFrozenGrassColor
      }
      graphqlVariables={compsPopupGraphqlVariables}
      hasDataChanges={hasDataChanges}
      onChangeData={onChangeData}
    />
  );
};

export default Popup;
