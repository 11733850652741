import {
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
} from 'constants/i18n';

export const MODALS = {
  EDIT: 'edit',
  MERGE: 'merge',
  CREATE: 'create',
  DELETE: 'delete',
  MERGE_SUCCESS: 'merge-success',
};

export const MODAL_STATUS_INIT = {
  type: undefined,
  isOpen: false,
};

export const PROPERTIES_PAGE_SIZE = 500;
export const DEFAULT_ZOOM = 10;

export const I18N_COMPANY_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.company`;
export const I18N_COMPANY_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.company`;
