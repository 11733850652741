import { gql } from '@apollo/client';

export const SCOOPS_BASIC_RESULTS_DATA_FRAGMENT = gql`
  fragment scoopsBasicResultsData on Scoop {
    id
    text
    userId
    createdAt
    totalScoopComments
    user {
      id
      firstName
      lastName
      email
    }
    lastEditedUserId
    lastEditedUser {
      id
      firstName
      lastName
      email
    }
    scoopCategories {
      id
      name
    }
    companies {
      id
      name
      mergedCompanyId
      mergedCompany {
        id
        name
      }
    }
  }
`;
