import React from 'react';
import classnames from 'classnames';
import styles from './FormControl.module.scss';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import Truncate from 'components/Truncate';
import { isUserUsingEnglishLanguage } from 'utils/user';
import { useUser } from 'contexts/AuthContext';

type Props = {
  children?: React.ReactNode;
  label?: string;
  indented?: boolean;
  required?: boolean;
  customRequiredText?: string;
  wrapperClassName?: string;
  isMultiline?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  labelClassName?: string;
  columnClassName?: string;
};

const FormControl: React.FC<Props> = props => {
  const isIndented = props.indented || props.label;
  const { user } = useUser();
  return (
    <Row
      wrapperClassName={classnames(props.wrapperClassName, {
        [styles.hidden]: props.hidden,
      })}
    >
      {isIndented && (
        <Col
          xs={1}
          sm={2}
          md={2}
          lg={2}
          wrapperClassName={classnames(
            styles['label-wrapper'],
            {
              [styles['label-wrapper-disabled']]: props.disabled,
            },
            props.columnClassName,
          )}
        >
          {props.label && (
            <div
              className={classnames(styles.container, {
                [styles.multiline]: props.isMultiline,
                [styles['label-required']]: props.required,
              })}
            >
              <label className={classnames(styles.label, props.labelClassName)}>
                {isUserUsingEnglishLanguage(user) ? (
                  props.label
                ) : (
                  <Truncate length={28} wrapperClassName={styles.truncate}>
                    {props.label}
                  </Truncate>
                )}
              </label>
              {props.required && (
                <div className={styles.required}>
                  {!!props.customRequiredText
                    ? props.customRequiredText
                    : translateText(
                        `${I18N_PLATFORM_COMMON_WORD_PATH}.required`,
                      )}
                </div>
              )}
            </div>
          )}
        </Col>
      )}
      <Col
        xs={3}
        sm={6}
        md={10}
        lg={10}
        wrapperClassName={props.columnClassName}
      >
        {props.children}
      </Col>
    </Row>
  );
};

export default FormControl;
