import React from 'react';
import styles from './ActivityCardContainer.module.scss';

const ActivityCardContainer: React.FC<{
  children?: React.ReactNode;
}> = props => {
  return <div className={styles.container}>{props.children}</div>;
};

export default ActivityCardContainer;
