import React from 'react';
import { HeatMap, HEATMAP_RADIO_OPTIONS } from '../constants';
import styles from './HeatMapButton.module.scss';
import classnames from 'classnames';
import Icon from 'components/Icon';
import { colors } from 'constants/colors';
import { IHeatMapControlsState } from '../useMapboxOptions';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';

interface Props {
  heatmapState: IHeatMapControlsState;
  onToggleHeatmap: (heatmapSelected: HeatMap) => void;
  setHeatmapState: (state: IHeatMapControlsState) => void;
}

const HeatMapButtons: React.FC<Props> = ({
  heatmapState,
  onToggleHeatmap,
  setHeatmapState,
}) => {
  if (!heatmapState.isHeatmapControlsOpen) return null;

  return (
    <div className={styles.container}>
      <p className={styles.title}>Map Details</p>
      <div
        className={styles['close-container']}
        onClick={() =>
          setHeatmapState({ ...heatmapState, isHeatmapControlsOpen: false })
        }
        role="button"
        aria-label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.closeModal`,
        )}
      >
        <Icon name="close" size={0.75} color={colors.uiColor500} />
      </div>
      <div className={styles.options}>
        {HEATMAP_RADIO_OPTIONS.map(hb => {
          const optionSelectedClass =
            hb.value === heatmapState.heatmapSelectedOption
              ? styles.selected
              : undefined;
          return (
            <div
              key={hb.label}
              className={styles.button}
              onClick={() => {
                setHeatmapState({
                  ...heatmapState,
                  heatmapSelectedOption: hb.value,
                });
                onToggleHeatmap(hb.value);
              }}
              role="button"
            >
              <div
                className={classnames(
                  styles[hb.classNames],
                  optionSelectedClass,
                )}
              ></div>
              <span className={classnames(styles.label, optionSelectedClass)}>
                {hb.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeatMapButtons;
