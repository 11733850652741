import React, { useEffect, useState } from 'react';
import MSAuthProvider from 'components/MSAuthProvider';
import { IUser } from 'interfaces/IUser';
import locations from 'routes';
import AuthService from 'services/Auth';
import { RouteComponentProps } from 'react-router-dom';
import { authContext } from 'contexts/AuthContext';
import OAuthLogin from 'components/OAuthLogin';
import { UserNotFoundError } from 'services/Auth/UserNotFoundError';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ErrorLogger } from 'services/ErrorLogger';
import loginUser from 'utils/login/loginUser';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import { AuthProviders } from 'constants/authProviders';

type Props = RouteComponentProps & {
  isLoadingMSAccount: boolean;
  msAccount: any;
  msGraphProfile: any;
  msError: string | null;
  msToken: string | null;
  onMSSignIn: (email: string | null) => void;
  onMSSignOut: () => void;
};

const SSOMSLoginPage: React.FC<Props> = props => {
  const auth = React.useContext(authContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const location: any = useLocation();
  const history = useHistory();
  const email = new URLSearchParams(props.location.search).get('email');
  const locationState = props.location?.state as any;

  useEffect(() => {
    if (locationState?.from?.pathname) {
      // MS changes the history due to some calls to the microsoft website and hidden iframes network requests.
      // When that happens, we lose the history state. In order to avoid losing the redirect URL, we store it in the localStorage and remove it once the redirect is done.
      localStorage.setItem(
        LocalStorageKeys.refRedirect,
        locationState?.from?.pathname,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  const login = (user: IUser) => {
    loginUser({
      user,
      auth,
      history: props.history,
    });
  };

  useEffect(() => {
    if (auth.user?.id) {
      login(auth.user);
      return;
    }

    const msAccountLoaded = props.msAccount && props.msToken;

    if (!msAccountLoaded && !props.isLoadingMSAccount) {
      props.onMSSignIn && props.onMSSignIn(email);
      return;
    }

    if (!msAccountLoaded && isAuthenticated) {
      setIsAuthenticated(false);
    }

    if (props.isLoadingMSAccount) {
      return;
    }

    const tryLogin = async () => {
      if (!props.msToken || !props.msAccount?.userName) return;
      setShowErrorMessage(false);

      try {
        const user = await AuthService.loginWithMS(
          props.msAccount.userName,
          props.msToken!,
        );

        if (user) {
          login(user);
        } else {
          setShowErrorMessage(true);
        }
      } catch (e) {
        if (e instanceof UserNotFoundError) {
          setIsAuthenticated(true);
        } else {
          ErrorLogger.log(e as any);
          setShowErrorMessage(true);
        }
      }
    };

    const loginTimeout = setTimeout(tryLogin);

    return () => {
      loginTimeout && clearTimeout(loginTimeout);
    };

    // eslint-disable-next-line
  }, [props.msAccount, props.msToken, props.isLoadingMSAccount, auth.user]);

  useEffect(() => {
    const createAccount = async () => {
      const givenName =
        props.msGraphProfile?.givenName || props.msAccount?.name;
      const surname = props.msGraphProfile?.surname || '';
      const path = location.state?.from?.pathname || locations.root();

      const user = await AuthService.createAccountWithMS(
        {
          id: 0,
          firstName: givenName,
          lastName: surname,
          email: props.msAccount.userName,
        },
        props.msToken!,
      );

      if (user) {
        auth.setUser(user);
        history.push(path);
      } else setShowErrorMessage(true);
    };

    if (isAuthenticated) createAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const oAuthProvider = localStorage.getItem(LocalStorageKeys.oAuthProvider);
  if (oAuthProvider !== AuthProviders.AZURE) {
    return <Redirect to={{ pathname: locations.loginEmail() }} />;
  }

  return (
    <OAuthLogin
      showErrorMessage={showErrorMessage}
      isAuthenticated={isAuthenticated}
      onTryAgain={() => props.onMSSignIn(email)}
    />
  );
};

export default MSAuthProvider(SSOMSLoginPage);
