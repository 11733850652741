import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import { select, selectAll } from 'd3-selection';
import {
  getDonutDataId,
  getDonutDataIdShadowId,
  getDonutInnerSliceClass,
  getDonutSliceClass,
  getDonutSliceShadowClass,
} from 'components/Graphs/DonutGraph/nodes';
import graphDimensions from '../graphConstants';
import { getDonutColor } from '../utils';

export const onMouseOverDonutSlice = (
  donutData: IDonutGraphData,
  cb: () => void,
  activeColor: string,
  secondaryColor: string,
  donutId?: number,
  showIndividualSlice?: boolean,
) => {
  selectAll(`.${getDonutSliceClass(donutId)}`)
    .attr('fill', 'transparent')
    .attr('stroke-width', 0);

  selectAll(`.${getDonutSliceShadowClass(donutId)}`).style('display', 'none');

  selectAll(`.${getDonutInnerSliceClass(donutId)}`).style(
    'fill',
    secondaryColor,
  );

  if (!showIndividualSlice) {
    select(`#${getDonutDataId(donutData, donutId)}`).attr('fill', activeColor);
  }

  select(`#${getDonutDataIdShadowId(donutData, donutId)}`)
    .attr('stroke', activeColor)
    .attr('stroke-width', graphDimensions.STROKE_WIDTH_LARGE)
    .style('display', '');

  cb();
};

export const onMouseOutDonutSlice = (
  cb: () => void,
  firstColor: string,
  secondColor: string,
  donutId?: number,
  showIndividualSlice?: boolean,
) => {
  selectAll(`.${getDonutSliceClass(donutId)}`)
    .attr('fill', (_, i: number) => getDonutColor(firstColor, secondColor, i))
    .attr(
      'stroke-width',
      showIndividualSlice ? graphDimensions.STROKE_WIDTH : 0,
    );

  selectAll(`.${getDonutSliceShadowClass(donutId)}`).attr(
    'stroke',
    'transparent',
  );

  cb();
};
