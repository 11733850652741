import React, { useState } from 'react';
import { VictoryLabel, VictoryTooltip } from 'victory';
import { isNil, noop } from 'lodash';

import { CustomVictoryLabelProps } from './types';
import { colors } from 'constants/colors';
import { DATUM_ID_PREFIX } from 'components/MostExpensiveAndLargestCharts/utils';

const CUSTOM_LABEL_MAX_LENGTH = 10;

const CustomVictoryLabel = <T,>({
  index,
  stringTicks = [],
  data,
  onClick = noop,
  accessKey = 'name',
  useCustomText,
  customTextLength = CUSTOM_LABEL_MAX_LENGTH,
  ...restOfProps
}: CustomVictoryLabelProps<T>) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const currentTick = !isNil(index) ? stringTicks[+index] : undefined;
  const currentData: T = data.find(
    (dataItem: any) => dataItem[accessKey] === currentTick,
  );

  const handleClick = () => {
    if (currentData) {
      onClick(currentData);
    }
  };

  let text = currentTick;
  let shouldRenderTooltip = false;
  const currentTickValue =
    currentTick?.split(DATUM_ID_PREFIX)?.[0] || currentTick;

  if (
    useCustomText &&
    currentTickValue?.length &&
    currentTickValue?.length > customTextLength
  ) {
    shouldRenderTooltip = true;
    text = currentTickValue?.substring(0, customTextLength) + '...';

    return (
      <>
        <VictoryLabel
          {...restOfProps}
          text={text}
          events={{
            onClick: handleClick,
            onMouseOver: () => setIsTooltipVisible(true),
            onMouseOut: () => setIsTooltipVisible(false),
          }}
        />
        {shouldRenderTooltip && (
          <VictoryTooltip
            active={isTooltipVisible}
            text={currentTickValue}
            x={(restOfProps.x || 0) - 30}
            y={(restOfProps.y || 0) - 5}
            cornerRadius={2}
            pointerLength={0}
            flyoutHeight={16}
            flyoutPadding={10}
            style={{ fontSize: 8 }}
            flyoutStyle={{
              fill: colors.ayWhiteColor,
              color: colors.uiColorBlack,
            }}
            orientation={'top'}
          />
        )}
      </>
    );
  }

  return <VictoryLabel {...restOfProps} events={{ onClick: handleClick }} />;
};

export default CustomVictoryLabel;
