import { colors } from 'constants/colors';

export const DEFAULT_ZOOM = 10;

export const PROPERTIES_PAGE_SIZE = 500;

export const SELECTED_SUBMARKET_BOUNDARIES = 'selected_submarket_boundaries';
export const SELECTED_SUBMARKET_LAYER = 'selected_submarket_layer';
export const SELECTED_SUBMARKET_BORDER = 'selected_submarket_border';

export const SUBMARKETS_BOUNDARIES = 'submarket_boundaries';
export const SUBMARKETS_BOUNDARIES_LAYER = 'submarket_boundaries_layer';

export const BOUNDARIES_BORDER_STYLE = {
  'line-color': colors.ayPureWhiteColor,
  'line-width': 2,
};
