import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { UnitsOfMeasurementModelType } from 'interfaces/unitOfMeasurement';

export const LEASE_MAPPING: UnitsOfMeasurementModelType[] = [
  {
    key: 'size',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'size',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'size',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'sizeMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'tenantInMarketSizeMin',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'tenantInMarketSizeMin',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'tenantInMarketSizeMin',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'tenantInMarketSizeMinMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'tenantInMarketSizeMax',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'tenantInMarketSizeMax',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'tenantInMarketSizeMax',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'tenantInMarketSizeMaxMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'additionalConcessionsPSF',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'additionalConcessionsPSF',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'additionalConcessionsPSF',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'additionalConcessionsPSM',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
];
