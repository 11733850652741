import React, { useEffect, useState } from 'react';
import Loader from 'components/HistoricalPerformance/Loader';
import {
  IMarketPricingInput,
  MarketPricingRentType,
  MarketPricingTimeMeasurement,
} from 'interfaces/inputs/IMarketPricingInput';
import RadioControl from './RadioControl';
import { SEGMENT_PRICING_TABLE } from '../constants';
import EditTable from '../EditTable';
import {
  getSegmentPricingCellsLabels,
  segmentPricingCellsFormatters,
  segmentPricingCellsFocusOrder,
  segmentPricingHeader,
} from './tableData';
import { getCurrencySymbol } from 'utils/formatters/currency';
import {
  generateMarketPricingPlaceholder,
  generateSegmentPricingFooterCells,
  getSegmentPricingQueryVariables,
  prepareSegmentPricingToEdit,
  prepareSegmentPricingToSubmit,
  updateMarketPricingData,
} from './utils';
import { useMutation, useQuery, PureQueryOptions } from '@apollo/client';
import { MARKET_PRICING_QUERY } from 'graphql/historicalPerformance/queries';
import FormFooter from 'components/FormFooter';
import { MARKETING_PRICING_MUTATION } from 'graphql/historicalPerformance';
import { ErrorLogger } from 'services/ErrorLogger';
import { parseEnumGraphQL, upperFirstLetter } from 'utils/formatters/string';
import { EditTableItems } from '../EditTable/interfaces';
import { isEqual } from 'lodash';
import { SegmentPricingParams } from '../interfaces';
import ConfirmationModal from 'components/ConfirmationModal';
import { getYearFromDate } from 'utils/date';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_USER_SETTINGS_LABEL_PATH,
} from 'constants/i18n';
import { I18N_HISTORICAL_PERFORMANCE_TEXT_PATH } from 'components/HistoricalPerformance/constants';
import { deepCopy } from 'utils/objects';

type Props = {
  canEdit?: boolean;
  onClose: () => void;
  refetchQueries?: PureQueryOptions[];
  segmentPricingParams: SegmentPricingParams;
  setHasUnsavedChanges?: (
    hasChanges: boolean,
    shouldResetData: boolean,
  ) => void;
  setSegmentPricingParams: (params: SegmentPricingParams) => void;
  shouldResetData?: boolean;
};

const SegmentPricing: React.FC<Props> = ({
  canEdit = true,
  onClose,
  refetchQueries,
  segmentPricingParams,
  setHasUnsavedChanges,
  setSegmentPricingParams,
  shouldResetData,
}) => {
  const [tableData, setTableData] = useState<IMarketPricingInput[]>([]);
  const [initialData, setInitialData] = useState<IMarketPricingInput[]>([]);
  const [isRequiredModalVisible, setIsRequiredModalVisible] = useState(false);

  const onCloseRequiredModal = () => setIsRequiredModalVisible(false);

  const {
    market,
    propertyType,
    propertySubtype,
    submarket,
    propertyClass,
    propertyId,
  } = segmentPricingParams;

  const hasPropertyId = !!propertyId;
  const hasMarketParams = !!market?.id && !!propertyType?.id && !!submarket?.id;
  const segmentQueryVariables = getSegmentPricingQueryVariables(
    segmentPricingParams,
  );
  const shouldSkipQuery =
    !segmentQueryVariables && !hasMarketParams && !hasPropertyId;

  const { data: segmentPricingData, loading: isLoadingPricingData } = useQuery(
    MARKET_PRICING_QUERY,
    {
      variables: segmentQueryVariables,
      skip: shouldSkipQuery,
    },
  );

  const [saveSegmentPricing, { loading: isSavingSegmentPricing }] = useMutation(
    MARKETING_PRICING_MUTATION,
    {
      onCompleted: onClose,
      onError: error => {
        if (error) {
          ErrorLogger.log(
            error.message,
            JSON.stringify(MARKETING_PRICING_MUTATION),
          );
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        ...(refetchQueries ? refetchQueries : []),
        {
          query: MARKET_PRICING_QUERY,
          variables: segmentQueryVariables,
        },
      ],
    },
  );

  useEffect(() => {
    if (segmentPricingData?.marketPricing) {
      const firstPricingData = segmentPricingData?.marketPricing?.[0];
      const _segmentPricingData = deepCopy(segmentPricingData);
      const currentYear = getYearFromDate();

      if (Number(currentYear) !== firstPricingData?.year) {
        const placeholder = {
          darPercent: 0,
          directRent: '0',
          freeRentLongTerm: 0,
          id: 0,
          rentType: 'FS',
          tiLongTerm: '0',
          tiPercent: 0,
          timeMeasurement: segmentPricingParams.timeMeasurement,
          year: currentYear,
        };
        _segmentPricingData.marketPricing = [
          placeholder,
          ...segmentPricingData?.marketPricing,
        ];
      }

      const pricingData = prepareSegmentPricingToEdit(
        _segmentPricingData?.marketPricing,
      );
      const firstElement = pricingData[0];

      setInitialData(pricingData);
      setTableData(pricingData);

      setSegmentPricingParams({
        ...segmentPricingParams,
        timeMeasurement:
          MarketPricingTimeMeasurement[
            upperFirstLetter(
              firstElement?.timeMeasurement,
            )! as MarketPricingTimeMeasurement
          ],
        rentType: firstElement?.rentType as MarketPricingRentType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentPricingData]);

  useEffect(() => {
    if (!segmentPricingData?.marketPricing?.length) {
      setTableData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market, submarket, propertyClass, propertyType, propertySubtype]);

  useEffect(() => {
    setHasUnsavedChanges?.(!isEqual(initialData, tableData), false);
    // eslint-disable-next-line
  }, [tableData]);

  useEffect(() => {
    if (shouldResetData) {
      setTableData(initialData);
      setHasUnsavedChanges?.(false, false);
    }
    // eslint-disable-next-line
  }, [shouldResetData]);

  const submitSegmentPricing = () => {
    const timeMeasurement = parseEnumGraphQL(
      MarketPricingTimeMeasurement,
      segmentPricingParams?.timeMeasurement,
    );
    const rentType = parseEnumGraphQL(
      MarketPricingRentType,
      segmentPricingParams?.rentType,
    );

    if (!timeMeasurement || !rentType) {
      setIsRequiredModalVisible(true);
    } else {
      saveSegmentPricing({
        variables: {
          input: {
            marketId: market?.id,
            submarketId: submarket?.id,
            propertyTypeId: propertyType?.id,
            classId: propertyClass?.id,
            propertySubTypeId: propertySubtype?.id,
            timeMeasurement,
            rentType,
            data: prepareSegmentPricingToSubmit(tableData),
          },
        },
      });
    }
  };

  if (isLoadingPricingData) return <Loader />;

  return (
    <>
      {canEdit && (
        <>
          <RadioControl
            segmentPricingParams={segmentPricingParams}
            setSegmentPricingParams={setSegmentPricingParams}
          />
          <EditTable
            id={SEGMENT_PRICING_TABLE}
            data={tableData}
            columns={segmentPricingHeader}
            generateRowPlaceholder={generateMarketPricingPlaceholder}
            generateCellsFooter={generateSegmentPricingFooterCells}
            updateData={(
              data: EditTableItems,
              itemUpdated?: IMarketPricingInput,
              columnId?: string,
            ) => {
              setTableData(
                itemUpdated?.year
                  ? updateMarketPricingData(data, itemUpdated.year!, columnId)
                  : data,
              );
            }}
            cellsLabels={getSegmentPricingCellsLabels(
              getCurrencySymbol(segmentPricingParams?.currencyCode),
            )}
            cellsFormatters={segmentPricingCellsFormatters}
            cellsFocusOrder={segmentPricingCellsFocusOrder}
          />
        </>
      )}

      <FormFooter
        onSubmit={submitSegmentPricing}
        onCancel={onClose}
        submitButtonLabel={
          isSavingSegmentPricing
            ? `${translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`,
              )}...`
            : translateText(`${I18N_USER_SETTINGS_LABEL_PATH}.saveChanges`)
        }
        isSubmitDisabled={isSavingSegmentPricing || !canEdit}
      />

      {isRequiredModalVisible && (
        <ConfirmationModal
          onCloseModal={onCloseRequiredModal}
          positiveButton={{
            label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.gotIt`),
            onclick: onCloseRequiredModal,
          }}
          header={translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.requiredFields`,
          )}
          paragraph={[
            translateText(
              `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.requiredFieldError`,
            ),
          ]}
        />
      )}
    </>
  );
};

export default SegmentPricing;
