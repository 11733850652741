import React, { useState } from 'react';
import { noop } from 'lodash';
import styles from './TagsList.module.scss';
import { IdName } from 'interfaces/IdName';
import classnames from 'classnames';
import { I18N_PLATFORM_COMMON_LABELS_PATH } from 'constants/i18n';
import { t } from 'i18next';

interface Props {
  tags: IdName[];
  className?: string;
  tagBackgroundClass?: string;
  isExtraSmall?: boolean;
  tagLimit?: number;
  forceShowMore?: boolean;
  onSeeMoreClick?: () => void;
}

export const Tag = ({
  tag,
  onClick,
  className,
  showFade,
  isExtraSmall,
  tagBackgroundClass,
}: {
  tag: IdName;
  onClick?: () => void;
  className?: string;
  tagBackgroundClass?: string;
  showFade?: boolean;
  isExtraSmall?: boolean;
}) => (
  <div
    className={classnames(styles.tag, tagBackgroundClass, className, {
      [styles['extra-small']]: isExtraSmall,
    })}
    onClick={onClick}
  >
    {tag.name}
    {showFade && <div className={styles['fade-tag']} />}
  </div>
);

const TagsList: React.FC<Props> = ({
  className,
  tagLimit,
  tags,
  tagBackgroundClass,
  isExtraSmall,
  forceShowMore,
  onSeeMoreClick = noop,
}) => {
  const [seeMore, setSeeMore] = useState(false);
  const tagsWithName = tags.filter(t => !!t.name);
  const isLimited = !!(
    !seeMore &&
    (forceShowMore || (tagLimit && tagsWithName.length > tagLimit))
  );

  if (!tagsWithName.length) return null;

  const limitedTags = isLimited
    ? tagsWithName.splice(0, tagLimit)
    : tagsWithName;

  const handleSeeMoreClick = () => {
    setSeeMore(true);
    onSeeMoreClick();
  };

  return (
    <div className={classnames(styles.container, className)}>
      {limitedTags.map((tag: IdName, index: number) => (
        <Tag
          tag={tag}
          key={tag.id}
          showFade={
            (forceShowMore && !seeMore && index === limitedTags.length - 1) ||
            (!!tagLimit && isLimited && index === tagLimit - 1)
          }
          tagBackgroundClass={tagBackgroundClass}
          isExtraSmall={isExtraSmall}
        />
      ))}
      {isLimited && (
        <Tag
          tag={{
            id: -1,
            name: t(`${I18N_PLATFORM_COMMON_LABELS_PATH}.seeMore`),
          }}
          onClick={handleSeeMoreClick}
          className={styles['see-more']}
        />
      )}
    </div>
  );
};

export default TagsList;
