import { PropertyClass } from 'constants/propertyClass';

export enum LeasesAnalyticsDonutsCharts {
  DonutTransactionData = 1,
  DonutLeaseSizeData,
  DonutTopIndustriesData,
  DonutLeaseTypeData,
  DonutPropertyClass,
}

const tagsOrderValues = {
  transactionType: {
    ['Expansion']: 1,
    ['Renewal']: 2,
    ['New']: 3,
  },
  leaseType: {
    ['Direct']: 1,
    ['Sublease']: 2,
    ['Coworking']: 3,
  },
  leaseSize: {
    ['50,000+']: 1,
    ['20,000 - 50,000']: 2,
    ['10,000 - 20,000']: 3,
    ['Under 10,000']: 4,
  },
  topIndustries: {
    ['Others']: 99,
  },
  propertyClass: {
    [PropertyClass['Trophy+']]: 1,
    [PropertyClass.Trophy]: 2,
    'A+': 3,
    [PropertyClass.A]: 4,
    'A-': 5,
    'B+': 6,
    [PropertyClass.B]: 7,
    [PropertyClass.C]: 8,
    ['Others']: 99,
  },
};

export const tagsOrder: { [key: string]: number } = {
  ...tagsOrderValues.transactionType,
  ...tagsOrderValues.leaseType,
  ...tagsOrderValues.leaseSize,
  ...tagsOrderValues.topIndustries,
  ...tagsOrderValues.propertyClass,
};
