const GRAPH_DIMENSIONS = {
  SIDE: 160,
  SIDE_WITH_SHADOW: 180,
  BORDER_RADIUS: '8px',
  MARGIN: 10,
  MARGIN_SHADOW: 20,
  INNER_MARGIN_SHADOW: 10,
};

const LINE = {
  XY1: 13,
  XY2: 0,
};

const BOX_SHADOW = '0 8px 24px rgba(0, 32, 91, 0.24)';

export default {
  GRAPH_DIMENSIONS,
  LINE,
  BOX_SHADOW,
};
