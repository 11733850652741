import { IActivity, IActivityGroup } from 'interfaces/IActivity';
import { formatDate, getRelativeDate } from 'utils/formatters/date';
import dateFormats from 'constants/dateFormats';

type ActivitiesGroupedByDate = { [date: string]: IActivity[] };

export const groupActivitiesByDate = (
  activities: IActivity[],
): IActivityGroup[] => {
  const groupsObj = activities.reduce((prev, curr) => {
    // use localized date when the activity is a scoop
    const useUtc = !curr.scoop?.id;
    const date = getRelativeDate(
      formatDate(curr.activityDate, dateFormats.ISO_DATE, useUtc)!,
    );

    prev[date] = [...(prev[date] || []), curr];
    return prev;
  }, {} as ActivitiesGroupedByDate);

  return Object.keys(groupsObj).map(date => {
    const activities = groupsObj[date];
    const relativeDate = date;
    return { date, relativeDate, activities };
  });
};
