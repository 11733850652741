import { gql } from '@apollo/client';
import { USER_INFO_FRAGMENT } from 'graphql/user/fragments';

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      ... on User {
        ...userInfo
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const SIGN_IN_MUTATION = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      ... on User {
        ...userInfo
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const SIGN_IN_WITH_MS_MUTATION = gql`
  mutation signInWithMS($email: String!, $msToken: String!) {
    signInWithMS(email: $email, msToken: $msToken, isApiToken: true) {
      ... on User {
        ...userInfo
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const SIGN_IN_WITH_JWT_TOKEN_MUTATION = gql`
  mutation signInWithJWTToken(
    $email: String!
    $jwtToken: String!
    $oAuthProvider: OAuthProvider
  ) {
    signInWithJWTToken(
      email: $email
      jwtToken: $jwtToken
      oAuthProvider: $oAuthProvider
    ) {
      ... on User {
        ...userInfo
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const SIGN_UP_MS_MUTATION = gql`
  mutation signUpWithMS($user: SignUpInput!, $msToken: String!) {
    signUpWithMS(user: $user, msToken: $msToken, isApiToken: true) {
      ... on User {
        ...userInfo
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const SIGN_UP_WITH_JWT_TOKEN_MUTATION = gql`
  mutation signUpWithJWTToken(
    $user: SignUpInput!
    $jwtToken: String!
    $oAuthProvider: OAuthProvider
  ) {
    signUpWithJWTToken(
      user: $user
      jwtToken: $jwtToken
      oAuthProvider: $oAuthProvider
    ) {
      ... on User {
        ...userInfo
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;
