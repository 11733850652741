import { lazy } from 'react';

const CHUNK_ERROR_FLAG = 'ay-chunk-error';

const retry = (fn: any): any => {
  return new Promise((resolve, reject) => {
    fn()
      .then((importResult: any) => {
        localStorage.removeItem(CHUNK_ERROR_FLAG);
        resolve(importResult);
      })
      .catch((error: any) => {
        const hasLoadChunkError = !!localStorage.getItem(CHUNK_ERROR_FLAG);

        if (
          error.toString().indexOf('ChunkLoadError') > -1 &&
          !hasLoadChunkError
        ) {
          localStorage.setItem(CHUNK_ERROR_FLAG, '1');

          // Reload the page to update the index.html file with the new .js references
          window.location.reload();
        } else {
          reject(error);
        }
      });
  });
};

/**
 * This function will try to lazy import a module, even if it faces a "Loading chunk" error.
 * References:
 * https://github.com/mozilla/addons-frontend/issues/8259#issuecomment-599445886
 * https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
 * https://goenning.net/2018/11/16/how-to-retry-dynamic-import-with-react-lazy/
 * @param importFn
 */
export const lazyRetry = (importFn: () => any) => {
  return lazy(() => retry(importFn));
};
