import dayjs from 'dayjs';

export const formatDate = (
  dateStr?: string | number,
  format?: string,
  useUtc = true,
): string | null => {
  if (!dateStr || !format) {
    return null;
  }

  const dayJsDate = dayjs(dateStr);
  return useUtc ? dayJsDate.utc().format(format) : dayJsDate.format(format);
};

export const formatMonthandYear = (dateStr?: string | number): string => {
  if (!dateStr) {
    return '';
  }
  const dateArray = dateStr.toString().split('-');
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = parseInt(dateArray[1]) - 1;
  const year = dateArray[0];
  return `${months[month]} ${year}`;
};

export const getRelativeDate = (date: string): string => {
  const relative = dayjs(date).fromNow();

  if (relative.toLowerCase().includes('hours ago')) {
    return 'Today';
  }

  if (relative.toLowerCase().includes('a day ago')) {
    return 'Yesterday';
  }

  return relative;
};

/**
 * Converts a date like 2020-08-16T00:00:00.000Z into just 2020-08-16
 * @param date
 */
export const defaultToIsoFormat = (date: string) => {
  return date.substring(0, 10);
};

export const millisecondsToMinutesAndSeconds = (milliseconds: number) => {
  const secondsPerMinute = 60;
  const millisecondsPerSecond = 1000;
  const millisecondsPerMinute = secondsPerMinute * millisecondsPerSecond;
  const minutes = Math.floor(milliseconds / millisecondsPerMinute);
  const seconds = +(
    (milliseconds % millisecondsPerMinute) /
    millisecondsPerSecond
  ).toFixed(0);

  return seconds === secondsPerMinute
    ? `${minutes + 1} :00`
    : `${minutes}:${String(seconds).padStart(2, '0')}`;
};
