import React from 'react';
import classNames from 'classnames';
import styles from '../ComparativeColumns.module.scss';
import InformationRow from './InformationRow';
import { ComparativeCardTheme } from 'components/PropertyProfile/Sections/Comparative/constants';

export type Props = {
  columnTheme: ComparativeCardTheme;
  fullWidth?: boolean;
};

const RowSeparator: React.FC<Props> = ({ columnTheme, fullWidth }) => {
  const isTenantProspectColumn =
    columnTheme === ComparativeCardTheme.tenantProspect;

  return (
    <InformationRow fullWidth={fullWidth}>
      <div
        className={classNames(styles.separator, {
          [styles['tenant-prospect']]: isTenantProspectColumn,
          [styles['full-width']]: !!fullWidth,
        })}
      />
    </InformationRow>
  );
};

export default RowSeparator;
