import React from 'react';
import { DOT } from 'constants/placeholders';
import { formatDate } from 'utils/formatters/date';
import { IActivity } from 'interfaces/IActivity';
import ISale from 'interfaces/ISale';
import { getSaleFormattedFields, SaleField } from '../../Sales/fields';
import styles from '../ActivityShare.module.scss';
import { getPropertyAddress } from 'utils/formatters/property';
import SecondaryFields from 'components/Sales/SaleCard/SecondaryFields';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  activity: IActivity;
}

export const buildBuyersDetails = (sale: ISale): string => {
  return sale.buyers?.map(buyer => buyer.name!)?.join(` ${DOT} `) || '';
};

export const buildPriceDetails = (sale: ISale): string => {
  const price = getSaleFormattedFields(sale, SaleField.Price);
  const pricePerSF = getSaleFormattedFields(sale, SaleField.PricePerSF);

  return [price, pricePerSF].filter(Boolean).join(` ${DOT} `);
};

const SaleDetails: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { sale = {} } = props.activity;
  const propertyAddress = getPropertyAddress(props.activity.property);
  const purchaseDate = `${t(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.purchased`,
  )} ${formatDate(sale?.date, 'MMM YYYY')}`;
  const buyers = buildBuyersDetails(sale);
  const priceDetails = buildPriceDetails(sale);

  return (
    <>
      <h5 className={styles['card-subtitle']}>{propertyAddress}</h5>
      <h5 className={styles['small-text']}>{purchaseDate}</h5>
      <p className={styles['small-text']}>#Sale</p>
      <h5 className={styles['card-subtitle']}>{buyers}</h5>
      <p className={styles['medium-text']}>{priceDetails}</p>
      <SecondaryFields
        sale={sale}
        fields={[
          SaleField.Size,
          SaleField.Sellers,
          SaleField.CapRate,
          SaleField.SellersBrokers,
          SaleField.BuyersBrokers,
          SaleField.InterestPercent,
        ]}
      />
    </>
  );
};

export default SaleDetails;
