export const DASH_PLACEHOLDER = '-';
export const COMMA = ',';
export const DOT = '·';
export const DOT_LG = '•';
export const SUM = '+';
export const DEGREES = '°';
export const TIMES = '×';
export const EMPTY_SINGLE_NEWLINE = '\n';
export const EMPTY_SPACE = ' ';
export const PIPE = '|';
