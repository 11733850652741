import { gql } from '@apollo/client';

export const SEARCH_BROKERS_QUERY = gql`
  query brokers($search: BrokerSearchInput) {
    brokers(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        id
        name
        company {
          id
          name
        }
      }
    }
  }
`;

export const BROKER_COMPANY_SUGGESTIONS = gql`
  query brokerCompanySuggestions(
    $brokerCompanySuggestionType: BrokerCompanySuggestionType!
    $marketId: Int!
    $propertyTypeId: Int!
  ) {
    brokerCompanySuggestions(
      brokerCompanySuggestionType: $brokerCompanySuggestionType
      marketId: $marketId
      propertyTypeId: $propertyTypeId
    ) {
      id
      name
    }
  }
`;
