import React, { Dispatch, SetStateAction, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GraphqlSearchVariables } from 'pages/FindComps';
import { ISearchOrder } from 'interfaces/ISearch';
import { SortDirections } from 'constants/sortDirections';
import { SEARCH_PROPERTIES_QUERY } from 'graphql/property';
import TableView from 'components/TableView';
import { IProperty } from 'interfaces/IProperty';
import { UnitOfMeasurementCode } from 'constants/unitOfMeasurement';
import { FindCompsActions, useFindCompsData } from 'contexts/FindCompsContext';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_PAGES,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { getMeasurementSystem } from '../utils';
import { PAGE_SIZE, INITIAL_PAGE_SIZE } from '../findCompsConstants';
import { getPropertyTableHeader } from './propertyTableHeader';

interface Props {
  graphqlSearchVariables: GraphqlSearchVariables;
  isActive: boolean;
  onLoadData: (data?: IProperty[]) => void;
  onClickEditSearch: () => void;
  unitOfMeasurement?: string;
  measurementSystemCode?: UnitOfMeasurementCode;
  hasSalesFilters?: boolean;
  hasLeasesFilters?: boolean;
  onSelectedIds?: (ids: Array<number>) => void;
  selectedIds?: Array<number>;
  isSelectAllChecked?: boolean;
  setIsSelectAllChecked?: (value: boolean) => void;
  uncheckedIds: number[];
  setUncheckedIds: Dispatch<SetStateAction<number[]>>;
}

export const PROPERTIES_TABLE_ID = 'find-comps-properties-table';

const PropertiesTableContainer: React.FC<Props> = props => {
  const { dispatch } = useFindCompsData();
  const { t } = useTranslation();
  const {
    selectedIds,
    onSelectedIds,
    isSelectAllChecked,
    setIsSelectAllChecked,
    uncheckedIds,
    setUncheckedIds,
  } = props;

  const [order, setOrder] = useState<ISearchOrder>(
    props.graphqlSearchVariables.properties.order || {
      field: 'primaryAddress',
      direction: SortDirections.asc,
    },
  );

  const getQueryVariables = (page?: number) => {
    const propertiesFilter = Object.assign(
      {},
      props.graphqlSearchVariables.properties?.filter,
    );
    delete propertiesFilter?.ids;
    delete propertiesFilter?.excludedIds;
    delete propertiesFilter?.paramIds;

    propertiesFilter.ids =
      props.graphqlSearchVariables.properties?.filter?.paramIds;

    const salesFilter = Object.assign(
      {},
      props.graphqlSearchVariables.sales?.filter,
    );
    delete salesFilter?.ids;
    delete salesFilter?.excludedIds;

    const leasesFilter = Object.assign(
      {},
      props.graphqlSearchVariables.leases?.filter,
    );
    delete leasesFilter?.ids;
    delete leasesFilter?.excludedIds;

    return {
      search: {
        order,
        filter: propertiesFilter,
        leasesFilter:
          !props.hasSalesFilters && props.hasLeasesFilters
            ? leasesFilter
            : undefined,
        salesFilter:
          !props.hasLeasesFilters && props.hasSalesFilters
            ? salesFilter
            : undefined,
        page: {
          page: page,
          size: page === 1 ? INITIAL_PAGE_SIZE : PAGE_SIZE,
        },
      },
    };
  };

  const queryVariables = getQueryVariables(1);

  const propertiesI18N = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`,
  );

  return (
    <TableView
      hasEditButton
      columns={getPropertyTableHeader(t)}
      id={PROPERTIES_TABLE_ID}
      noResultsMessage={translateText(
        `${I18N_AVANT_PROPERTY_PAGES}.findComps.noCompsFound`,
        { comp: propertiesI18N.toLowerCase() },
      )}
      onChangeOrder={(field, direction) => {
        setOrder({ field, direction });
        return true;
      }}
      onClickEditSearch={props.onClickEditSearch}
      isActive={props.isActive}
      order={order}
      hasSelectItems
      onSelectedIds={onSelectedIds}
      selectedIds={selectedIds}
      setIsSelectAllChecked={setIsSelectAllChecked}
      isSelectAllChecked={isSelectAllChecked}
      uncheckedIds={uncheckedIds}
      setUncheckedIds={setUncheckedIds}
      getQueryVariables={getQueryVariables}
      query={SEARCH_PROPERTIES_QUERY}
      typeOfData="properties"
      skip={isEmpty(queryVariables.search.filter)}
      onCompleted={data => {
        if (data && data.properties) {
          dispatch({
            type: FindCompsActions.set_measurement_kpi,
            measurementKPI: getMeasurementSystem(data),
          });
          props.onLoadData(data.properties.results as IProperty[]);
        }
      }}
    />
  );
};

export default PropertiesTableContainer;
