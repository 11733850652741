import { EMPTY_SINGLE_NEWLINE } from 'constants/placeholders';
import { ILease } from 'interfaces/ILease';
import {
  getFieldValueForLease,
  getUnitOfMeasurementForLease,
} from 'utils/unitsOfMeasurement';
import { fieldHasValue } from 'utils/objects';
import { formatArea } from 'utils/formatters/area';

export const getTenantInMarketSize = (lease: ILease) => {
  const unitOfMeasurement = getUnitOfMeasurementForLease(
    'tenantInMarketSizeMin',
    lease.country?.code ||
      lease.property?.propertyCountry?.code ||
      lease.measurementSystem,
  );
  const sizeMin = formatArea(
    getFieldValueForLease('tenantInMarketSizeMin', lease),
    '',
  );
  const sizeMax = formatArea(
    getFieldValueForLease('tenantInMarketSizeMax', lease),
    '',
  );
  const hasMin = fieldHasValue(sizeMin);
  const hasMax = fieldHasValue(sizeMax);

  if (hasMin && hasMax) {
    return `${sizeMin} - ${sizeMax} ${unitOfMeasurement}`;
  } else if (hasMin) {
    return `For ${sizeMin} ${unitOfMeasurement}`;
  } else if (hasMax) {
    return `Up to ${sizeMax} ${unitOfMeasurement}`;
  }

  return null;
};

export const getTenantInMarketData = (lease: ILease) => {
  const tenantRepBroker =
    lease?.tenantRepBrokers?.[0]?.company?.name ||
    lease?.tenantRepBroker?.company?.name;

  if (!!tenantRepBroker) {
    return [`with ${tenantRepBroker}`, getTenantInMarketSize(lease)]
      .filter(Boolean)
      .join(EMPTY_SINGLE_NEWLINE);
  }

  return getTenantInMarketSize(lease);
};
