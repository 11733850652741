import { PAGES } from 'constants/subscriptions';
import locations from 'routes';

export const SUBNAVBAR_PAGES = [
  PAGES.PAGE_ACTIVITY_FEED,
  PAGES.PAGE_PROPERTY_PROFILE,
  PAGES.PAGE_COMPANY_PROFILE,
  PAGES.PAGE_SUBMARKET_PROFILE,
  PAGES.PAGE_FIND_COMPS,
  PAGES.PAGE_MARKET_PROFILE,
  PAGES.PAGE_TENANT_TOUR_CREATION,
  PAGES.PAGE_TENANT_TOURS,
];

const SUBNAVBAR_PATHNAMES = [
  locations.newComps(),
  locations.marketAnalytics(),
  locations.uploadComps(),
  locations.showTenantTourCreationPage(),
];

export const isSubNavBarAllowedByPathname = (pathname: string) => {
  return SUBNAVBAR_PATHNAMES.includes(pathname);
};
