export enum SearchInputSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum SuggestionIcon {
  propertyIcon = 'property',
  leaseIcon = 'lease',
  companyIcon = 'company',
  dynamicIcon = 'company-property',
  mapBoxAddress = 'mapbox-address',
}
