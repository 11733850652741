import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import {
  BUBBLES,
  GRAPH_DIMENSIONS,
  Positions,
  XCoordinates,
} from '../graphConstants';
import { colors } from 'constants/colors';
import styles from '../Age.module.scss';
import { getBubbleId } from '../nodes';
import { BubbleType } from '../types';

type Props = {
  bubble: BubbleType;
  onMouseOverBubble: (position: Positions) => void;
  onMouseOutBubble: () => void;
};

const Bubble: React.FC<Props> = props => {
  const bubbleElementRef = useRef(null);

  const { bubble, onMouseOverBubble, onMouseOutBubble } = props;

  useEffect(() => {
    if (!bubbleElementRef.current) return;

    const node = bubbleElementRef.current;
    const centerY = GRAPH_DIMENSIONS.height / 2;
    const {
      year,
      fillColor,
      secondaryColor,
      textColor = colors.primaryColor100,
      hasOpacity,
      position,
      isYearBottom,
    } = bubble;

    const yTop = GRAPH_DIMENSIONS.height / 2 - 21;
    const yBottom = GRAPH_DIMENSIONS.height / 2 + 32;

    const yCoordinate = isYearBottom ? yBottom : yTop;

    const svgG = select(node);

    const writeGraphText = () => {
      const textClass = `age-year-${bubble.type}`;
      const svg = svgG
        .selectAll(`.${textClass}`)
        .data([year])
        .enter()
        .append('text')
        .text(year)
        .attr('y', yCoordinate)
        .attr('fill', textColor)
        .attr('class', `${textClass} ${styles['graph-age-text']}`);

      const xYearOffset = 18;

      switch (position) {
        case Positions.LEFT:
          svg.attr('x', XCoordinates[Positions.LEFT] - xYearOffset);
          break;
        case Positions.MIDDLE:
          svg.attr('x', XCoordinates[Positions.MIDDLE] - xYearOffset);
          break;
        case Positions.RIGHT:
          svg.attr('x', XCoordinates[Positions.RIGHT] - xYearOffset);
          break;
        case Positions.CLOSE_RIGHT:
          svg.attr('x', XCoordinates[Positions.CLOSE_RIGHT] - xYearOffset);
          break;
        default:
          return;
      }
    };

    writeGraphText();

    svgG
      .append('circle')
      .attr('id', getBubbleId(position))
      .attr('cx', XCoordinates[position])
      .attr('cy', centerY)
      .attr('r', BUBBLES.strokeWidth)
      .style('fill', hasOpacity ? secondaryColor : fillColor)
      .style('stroke', hasOpacity ? fillColor : 'transparent')
      .style('stroke-width', 2)
      .on('mouseover', () => {
        onMouseOverBubble(position);
      })
      .on('mouseout', () => {
        onMouseOutBubble();
      });

    // eslint-disable-next-line
  }, [props.bubble]);

  return <g ref={bubbleElementRef} />;
};

export default Bubble;
