import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Linkify from 'linkify-react';
import classNames from 'classnames';

import Button from 'components/Button/new';
import {
  I18N_AVANT_PROPERTY_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IActivity } from 'interfaces/IActivity';
import { IProperty } from 'interfaces/IProperty';
import { MODALS } from 'interfaces/IModal';
import { translateText } from 'utils/i18n';
import { transformImageUrl } from 'utils/cloudinary/urls';
import locations from 'routes';

import CardContainer from '../CardContainer';
import CardHeader from '../CardHeader';
import Owner from '../Owner';
import Tag from '../Tag';
import { HTTP, HTTPS, TypeOfCard } from '../utils';
import Comment from '../Comment';
import ScoopGallery from './ScoopGallery';
import styles from './ScoopCard.module.scss';

const MINIMUM_MARKETS_LENGTH = 1;

export interface Props {
  activity: IActivity;
  refetchScoopList?: () => void;
  type?: TypeOfCard;
  preselectedProperty?: IProperty;
}

const ScoopCard: React.FC<Props> = props => {
  const [openScoopEditDocuments, setOpenScoopEditDocuments] = useState(false);

  const history = useHistory();

  const property = props.activity.scoop?.properties?.length
    ? props.activity.scoop?.properties?.[0]
    : props.activity.property;

  const preselectedPropertyFiltered =
    props.preselectedProperty &&
    props.activity?.scoop?.properties?.find(
      property =>
        property.primaryAddress === props.preselectedProperty?.primaryAddress,
    );

  const market =
    !props.activity?.scoop?.properties?.length ||
    (props.activity?.scoop?.markets &&
      props.activity?.scoop?.markets?.length > MINIMUM_MARKETS_LENGTH)
      ? props.activity?.scoop?.markets?.[0]
      : undefined;

  const renderLinkedButton = (className = 'btn-card') => {
    if (!props.activity?.scoop?.url) return null;

    const url =
      props.activity?.scoop?.url.includes(HTTP) ||
      props.activity?.scoop?.url.includes(HTTPS)
        ? props.activity?.scoop?.url
        : `${HTTP}${props.activity?.scoop?.url}`;

    return (
      <Button
        icon="merge"
        iconPosition="left"
        size="s"
        type="link"
        onClick={() => (window.location.href = url)}
        wrapperClassName={styles[className]}
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.linkedUrl`)}
      />
    );
  };

  const [activeModal, setActiveModal] = useState<MODALS | null>(null);
  const isModalActive = (modal: MODALS) => activeModal === modal;
  const closeActiveModal = () => setActiveModal(null);

  const renderMedia = () => {
    if (props.activity.scoop?.scoopMediaUrl === null) {
      return null;
    }

    return (
      <div
        style={{
          backgroundImage: `url(${transformImageUrl(
            props.activity.scoop?.scoopMediaUrl!,
            props.type === TypeOfCard.HOME ? 736 : 1061,
          )})`,
        }}
        className={classNames(styles['scoop-media'], {
          [styles['scoop-media-expanded']]: props.type !== TypeOfCard.HOME,
        })}
        onClick={() => {
          setActiveModal(MODALS.GALLERY);
        }}
      />
    );
  };

  const renderDocuments = () => {
    const documentsCount = props.activity?.scoop?.documentCount;
    if (!documentsCount) return null;

    const buttonText = translateText(
      `${I18N_AVANT_PROPERTY_TEXT_PATH}.property.seeAllDocuments`,
      {
        number: documentsCount,
      },
    );

    return (
      <div className={styles['section-container']}>
        <Button
          label={buttonText}
          type="contextual"
          onClick={() => setOpenScoopEditDocuments(true)}
          wrapperClassName={styles['see-documents-button']}
        />
      </div>
    );
  };

  const renderText = () => {
    const options = {
      nl2br: true,
    };
    return (
      <div className={styles.text}>
        <Linkify tagName="p" options={options}>
          {props.activity?.scoop?.text}
        </Linkify>
      </div>
    );
  };

  const renderCreatedByUser = () => {
    return (
      <Owner
        wrapperClassName={styles['user-wrapper']}
        updatedUserClassName={styles['edit-user']}
        createdUser={props.activity.scoop?.user || props.activity.user!}
        createdAt={props.activity.scoop?.createdAt!}
        updatedUser={
          props.activity.scoop?.lastEditedUser || props.activity.userEdited!
        }
        updatedAt={props.activity.scoop?.updatedAt!}
        colored
        ignoreSameUserEdit
        lineBreak={props.type === TypeOfCard.HOME}
      />
    );
  };

  const renderCompanies = () => {
    const { companies } = props.activity.scoop!;
    if (!companies?.length) return null;

    return (
      <div className={styles['section-container']}>
        {companies!.map((c, key) => {
          return (
            <Tag
              key={key}
              label={c.mergedCompany?.name || c.name!}
              wrapperClassName={classNames(styles.tag, {
                [styles['tag-share']]: props.type === TypeOfCard.SHARE,
              })}
              onClick={() =>
                history.push(locations.showCompany(c.mergedCompanyId || c.id!))
              }
            />
          );
        })}
      </div>
    );
  };

  const renderMobileButtons = () => {
    return (
      <div
        className={classNames(
          styles['section-container'],
          styles['mobile-section-container'],
        )}
      >
        <Button
          icon="comment"
          iconPosition="left"
          size="s"
          type="link"
          onClick={() => (window.location.href = props.activity?.scoop?.url!)}
          wrapperClassName={styles['btn-card-mobile']}
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.seeComment`)}
        />
        {renderLinkedButton('btn-card-mobile')}
      </div>
    );
  };

  const renderComments = () => {
    if (!props.activity?.scoop?.scoopComments?.length) return null;

    return (
      <>
        <div className={styles['card-separator']} />
        <Comment comments={props.activity?.scoop?.scoopComments} />
      </>
    );
  };

  return (
    <>
      <CardContainer
        wrapperClassName={classNames({
          [styles.share]: props.type === TypeOfCard.SHARE,
        })}
      >
        <CardHeader
          scoop={props.activity.scoop}
          property={preselectedPropertyFiltered || property}
          market={market}
          activity={props.activity}
          refetchActivities={props.refetchScoopList!}
          type={props.type}
          openScoopEditDocuments={openScoopEditDocuments}
          setOpenScoopEditDocuments={setOpenScoopEditDocuments}
        />
        <div className={styles.content}>
          <div className={styles['card-separator']} />
          {renderText()}
          {renderLinkedButton()}
          {renderMedia()}
          {renderDocuments()}
          {renderCompanies()}
          <div className={styles['card-separator']} />
          {renderCreatedByUser()}
          {renderMobileButtons()}
          {renderComments()}
        </div>
      </CardContainer>
      {isModalActive(MODALS.GALLERY) && (
        <ScoopGallery
          onCloseModal={closeActiveModal}
          initialImageUrl={props.activity.scoop?.scoopMediaUrl!}
        />
      )}
    </>
  );
};

export default ScoopCard;
