import {
  AgencyLeasingAssignmentSection,
  AgencyLeasingOverviewSection,
  AgencyLeasingPropertySection,
  AgencyLeasingSection,
  AgencyLeasingTenantSection,
} from './types';

export const isOverviewSection = (section: AgencyLeasingAssignmentSection) => {
  return (
    section === AgencyLeasingSection.Overview ||
    section === AgencyLeasingOverviewSection.Summary ||
    section === AgencyLeasingOverviewSection.Grid
  );
};

export const isPropertySection = (section: AgencyLeasingAssignmentSection) => {
  return (
    section === AgencyLeasingSection.Properties ||
    section === AgencyLeasingPropertySection.Summary ||
    section === AgencyLeasingPropertySection.List
  );
};

export const isAvailabilitySection = (
  section: AgencyLeasingAssignmentSection,
) => {
  return section === AgencyLeasingSection.Availabilities;
};

export const isTenantSection = (section: AgencyLeasingAssignmentSection) => {
  return (
    section === AgencyLeasingSection.Tenants ||
    section === AgencyLeasingTenantSection.Summary ||
    section === AgencyLeasingTenantSection.List ||
    section === AgencyLeasingOverviewSection.Grid
  );
};

export const isRecentActivitySection = (
  section: AgencyLeasingAssignmentSection,
) => {
  return section === AgencyLeasingSection.RecentActivity;
};
