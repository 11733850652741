import React from 'react';
import styles from './LoadingMessage.module.scss';
import SpinnerLoader from 'components/SpinnerLoader';
import { ColorNames } from 'constants/colorNames';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  itemBeingLoaded?: string;
  background?: ColorNames.ayTotalBlackColor | ColorNames.ayWhiteColor;
}

const LoadingMessage: React.FC<Props> = props => {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <SpinnerLoader
        background={props.background || ColorNames.ayTotalBlackColor}
      />
      <p className={styles.text}>
        {`${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.loading`)}`}
        {props.itemBeingLoaded && ` ${props.itemBeingLoaded}`}
      </p>
    </section>
  );
};

export default LoadingMessage;
