import { gql } from '@apollo/client';

export const SEARCH_COMPANIES_PROPERTIES_QUERY = gql`
  query propertiesCompanies($search: PropertyCompanySearchInput!) {
    propertiesCompanies(search: $search) {
      results {
        companyId
        headquarters
        id
        industryName
        name
        displayAddress
        marketName
        mergedCompanyId
        mergedCompanyName
        mergedAt
        micromarketName
        propertyStatusName
        propertyClassName
        propertyId
        propertyName
        propertyType
        submarketName
        type
        submarketId
        propertyTypeId
        marketId
        numberOfBuildings
        businessParkName
        coverMediaUrl
        coverMediaType
        state
      }
      mapBoxResults {
        id
        displayAddress
        mapBoxDetail {
          latitude
          longitude
        }
        type
      }
    }
  }
`;
