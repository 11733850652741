import { maxBy } from 'lodash';
import {
  IMarketSubmarketDevelopmentTimelineData,
  IMarketSubmarketDevelopmentTimelineYear,
} from 'interfaces/ISubmarketDevelopmentTimeline';
import { getYearFromDate } from 'utils/date';
import { colors } from 'constants/colors';
import {
  abbreviateNumber,
  roundNumberToNearestTenThousand,
} from 'utils/formatters/number';
import { MarketMapOptions } from 'components/MarketProfile/constants';
import {
  VictoryBarStyle,
  AxisData,
} from 'components/Graphs/VerticalGroupBarChart/types';
import {
  CHART_PADDING,
  DEFAULT_BAR_STYLE,
  getYAxisValuesFromTotal,
} from 'components/VictoryBarChart/utils';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';

const FUTURE_YEARS_COUNT = 20;

export interface IVictoryBarData {
  x: number | string;
  y: number | string;
  propertiesIds?: number[];
  label?: string;
  quarter?: number;
}

export const getYearsToDevelopmentTimeline = (
  activeOption?: MarketMapOptions,
) => {
  const currentYear = Number(getYearFromDate());
  if (activeOption === MarketMapOptions.DEVELOPMENT) {
    const endYear = currentYear + FUTURE_YEARS_COUNT;
    return {
      startYear: currentYear,
      endYear,
    };
  }
  const startYear = currentYear - FUTURE_YEARS_COUNT;
  return {
    startYear,
    endYear: currentYear,
  };
};

const generateBarValues = (
  data: IMarketSubmarketDevelopmentTimelineYear,
): IVictoryBarData => ({
  y: data.totalBuildingSize,
  x: data.builtYear,
  propertiesIds: data.topItems,
  label: `${abbreviateNumber(data.totalBuildingSize)}`,
});

export const getLegends = () => {
  return [
    {
      name: translateText(
        `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.squareFeet`,
      ),
      symbol: { fill: colors.primaryColor500 },
    },
  ];
};

export const parseGraphData = (
  paramData: IMarketSubmarketDevelopmentTimelineData,
) => {
  const data = paramData || [];
  const yearWithMaxBuildingSize = maxBy(data.years, 'totalBuildingSize');
  const ceiledTotalSize = roundNumberToNearestTenThousand(
    yearWithMaxBuildingSize?.totalBuildingSize || 0,
  );
  const yAxis = getYAxisValuesFromTotal(ceiledTotalSize);
  const xAxis = data.years.map(
    (d: IMarketSubmarketDevelopmentTimelineYear) => d.builtYear,
  );
  const params = data.years.map(
    (d: IMarketSubmarketDevelopmentTimelineYear) => ({
      id: `market-development-timeline-${d.builtYear}`,
      ...generateBarValues(d),
    }),
  );

  return {
    xAxis,
    yAxis,
    data: params,
  };
};

const getStrokeWidth = (datum: any) => (datum?.tick > 0 ? 1 : 0);

export const getDevelopmentChartStyle = (
  unitOfMeasurement?: string,
): VictoryBarStyle => {
  return {
    chart: {
      height: 150,
      padding: {
        ...CHART_PADDING,
        left: 62,
        top: 40,
        bottom: 25,
      },
      style: {
        parent: {
          ...DEFAULT_BAR_STYLE.chart?.style.parent,
          background: colors.primaryColor900,
        },
      },
    },
    legend: {
      x: 400,
      style: {
        ...DEFAULT_BAR_STYLE.legend?.style,
        labels: {
          ...DEFAULT_BAR_STYLE.legend?.style.labels,
          fontSize: 5,
        },
      },
    },
    label: {
      style: [
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 7,
        },
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 5,
        },
      ],
    },
    bar: {
      ...DEFAULT_BAR_STYLE.bar,
      width: 15,
      cornerRadius: { top: 2 },
      tooltip: {
        cornerRadius: 2,
        pointerLength: 0,
        flyoutHeight: 12,
        flyoutStyle: {
          fill: colors.primaryColor500,
          border: 'none',
        },
        y: 39,
      },
    },
    xAxis: {
      style: {
        ...DEFAULT_BAR_STYLE.xAxis?.style,
        tickLabels: {
          fontSize: 5,
          fill: colors.ayWhiteColor,
          paddingLeft: 20,
        },
      },
      tickFormat: (d: any) => (Number(d) % 2 === 0 ? d : ''),
    },
    yAxis: {
      style: {
        ...(DEFAULT_BAR_STYLE.yAxis as AxisData)?.style,
        tickLabels: {
          fontSize: 5,
          fill: colors.ayWhiteColor,
        },
        grid: {
          stroke: colors.uiColor800,
          strokeWidth: getStrokeWidth,
        },
        ticks: {
          stroke: colors.uiColor800,
          strokeWidth: getStrokeWidth,
          size: 15,
        },
      },
      tickFormat: (d: any) => {
        return d
          ? `${`${abbreviateNumber(d)} ${unitOfMeasurement ||
              UnitOfMeasurement.sf}` ||
              `0 ${unitOfMeasurement || UnitOfMeasurement.sf}`}`
          : '';
      },
      offsetX: 70,
    },
  };
};
