export const CLEARBIT_SITE = 'https://clearbit.com';
export const CLEARBIT_URL = 'https://logo.clearbit.com/';

export const getCompanyUrlLogo = async (website: string) => {
  try {
    const url = `${CLEARBIT_URL}${website}`;
    if (!website) return null;
    const response = await fetch(url);
    return response.ok ? url : null;
  } catch (e) {
    return null;
  }
};
