import React from 'react';
import styles from './HeadingTitle.module.scss';

interface Props {
  text?: string;
}

const HeadingTitle: React.FC<Props> = ({ text }) => {
  return (
    <h2 className={styles['heading-title']}>
      {text}
      <span className={styles['heading-title-separator']} />
    </h2>
  );
};

export default HeadingTitle;
