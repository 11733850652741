import React, { useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';

import { authContext } from 'contexts/AuthContext';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
} from 'constants/i18n';
import { MediaTabNames } from 'constants/media';
import { ColorNames } from 'constants/colorNames';
import ModalWrapper from 'components/ModalWrapper';
import ConfirmationModal from 'components/ConfirmationModal';
import { KVFilesToUpload } from 'components/ModalMedia/types';
import { getScoopMediaRefetchQueries } from 'components/ModalMedia/utils';
import NotificationMessage from 'components/NotificationMessage';
import useMutationRefetchActivities from 'hooks/useMutationRefetchActivities';
import { IScoopInput } from 'interfaces/inputs/IScoopInput';
import { IScoop } from 'interfaces/IScoop';
import { IProperty } from 'interfaces/IProperty';
import { ICompany } from 'interfaces/ICompany';
import {
  CREATE_SCOOP_MUTATION,
  DELETE_SCOOP_MUTATION,
  SET_SCOOP_TAGS,
  UPDATE_SCOOP_MUTATION,
} from 'graphql/scoops';
import { CREATE_MEDIA_MUTATION } from 'graphql/images';
import { CREATE_DOCUMENT_MUTATION } from 'graphql/documents';
import { SET_SCOOP_COMPANIES } from 'graphql/scoops/mutations';
import { ErrorLogger } from 'services/ErrorLogger';
import { translateText as t } from 'utils/i18n';
import { isImageFileType } from 'utils/media';

import ScoopForm from './ScoopForm';

const I18N_PATH = 'avantProperties.texts.exploreActivity.scoop';
const I18N_LABEL_PATH = 'avantProperties.labels.exploreActivity';

const errorScoop = t(`${I18N_PATH}.errorScoop`);
const errorUpdatingScoop = t(`${I18N_PATH}.errorUpdatingScoop`);
const errorDeletingScoop = t(`${I18N_PATH}.errorDeletingScoop`);
const successMessage = t(`${I18N_PATH}.scoopAddedSuccessMessage`);

type ModalProps = {
  onCloseModal: () => void;
  onChangeData?: () => void;
  onSuccessNotification?: (message: string) => void;
  isVisible: boolean;
  isEditing?: boolean;
  activityScoop?: IScoop;
  preselectedProperty?: IProperty;
  preselectedCompany?: ICompany;
  activeTab?: MediaTabNames;
};

const ManageScoopModal: React.FC<ModalProps> = ({
  activeTab,
  onCloseModal,
  onChangeData,
  onSuccessNotification,
  isVisible,
  isEditing,
  preselectedProperty,
  preselectedCompany,
  activityScoop,
}) => {
  const { user } = useContext(authContext);
  const client = useApolloClient();
  const mutationAndRefetch = useMutationRefetchActivities();

  const initialState = {
    scoopInput: activityScoop || { userId: user!.id },
    isSubmitting: false,
    showError: false,
    messageError: '',
    selectedProperty: {},
  };

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isSuccessNotification, setIsSuccessNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState<string>('');
  const [showDeleteModalScoop, setShowDeleteModalScoop] = useState(false);
  const [isDeletingScoop, setIsDeletingScoop] = useState(false);
  const [
    mediaFilesToUpload,
    setMediaFilesToUpload,
  ] = useState<KVFilesToUpload | null>(null);

  const onCloseModalLocal = () => {
    setIsSubmitting(initialState.isSubmitting);
    setShowNotification(initialState.showError);
    setMessageNotification(initialState.messageError);
    setMediaFilesToUpload({});
    onChangeData?.();
    onCloseModal();
  };

  const notificationMessage = (message: string, isSuccess: boolean) => {
    if (isSuccess) {
      onSuccessNotification?.(message);
    }

    setMessageNotification(message);
    setIsSuccessNotification(isSuccess);
    setShowNotification(true);
  };

  const deleteScoop = async () => {
    if (activityScoop?.id) {
      try {
        setIsDeletingScoop(true);
        const { data } = await client.mutate<any>({
          mutation: DELETE_SCOOP_MUTATION,
          variables: { scoopId: activityScoop.id },
        });

        if (data?.deleteScoop) {
          onCloseModalLocal();
          onChangeData?.();
        } else {
          ErrorLogger.log(errorDeletingScoop);
          notificationMessage(errorDeletingScoop, false);
        }
      } catch (e) {
        ErrorLogger.log(e as any, errorDeletingScoop);
        notificationMessage(errorDeletingScoop, false);
      } finally {
        setShowDeleteModalScoop(false);
        setIsDeletingScoop(false);
      }
    }
  };

  const renderDeleteModal = () => {
    if (!showDeleteModalScoop) return null;
    return (
      <ConfirmationModal
        onCloseModal={() => setShowDeleteModalScoop(false)}
        negativeButton={{
          label: isDeletingScoop
            ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`)
            : t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`),
          isDisabled: isDeletingScoop,
          onclick: deleteScoop,
        }}
        positiveButton={{
          label: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`),
          onclick: () => setShowDeleteModalScoop(false),
        }}
        header={t(`${I18N_LABEL_PATH}.deleteScoop`)}
        paragraph={[
          t(`${I18N_PATH}.firstConfirmToDeleteScoopText`),
          t(`${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.askToProceed`),
        ]}
      />
    );
  };

  const executeSetScoopTagsMutation = (
    scoopId: number,
    scoopCategories?: number[],
  ) => {
    const variables = {
      scoopId: scoopId,
      scoopCategoryIds: scoopCategories,
    };

    mutationAndRefetch(SET_SCOOP_TAGS, variables);
  };

  const executeSetScoopCompaniesMutation = async (
    scoopId: number,
    companyIds: number[],
  ) => {
    await client.mutate({
      mutation: SET_SCOOP_COMPANIES,
      variables: {
        scoopId: scoopId,
        companiesIds: companyIds,
      },
    });
  };

  const setScoopTags = async (scoopId: number, scoopCategories?: number[]) => {
    executeSetScoopTagsMutation(scoopId, scoopCategories);
    notificationMessage(successMessage, true);
    onChangeData?.();
  };

  const [uploadScoopImage] = useMutation(CREATE_MEDIA_MUTATION, {
    onError: error => {
      ErrorLogger.log(
        error.message,
        'CREATE_MEDIA_MUTATION > Unexpected error uploading scoop image',
      );
    },
  });

  const [uploadScoopDocument] = useMutation(CREATE_DOCUMENT_MUTATION, {
    onError: error => {
      ErrorLogger.log(
        error.message,
        'CREATE_MEDIA_MUTATION > Unexpected error uploading scoop image',
      );
    },
  });
  const uploadMedia = async (fileData: File | null, scoopId?: number) => {
    if (!fileData || !scoopId) {
      return onCloseModalLocal();
    }
    const isImageType = isImageFileType(fileData);
    const mutationByType = isImageType ? uploadScoopImage : uploadScoopDocument;

    const variables = isImageType
      ? {
          file: fileData,
          scoopId,
        }
      : {
          file: fileData,
          scoopId,
          name: fileData.name,
        };

    await mutationByType({
      variables,
      context: {
        fetchOptions: {
          useUpload: true,
        },
      },
      refetchQueries: getScoopMediaRefetchQueries(scoopId),
    });
  };

  const createScoop = async (
    scoop: IScoopInput,
    scoopCategories?: number[],
    companyIds?: number[],
    propertiesIds?: number[],
    marketsIds?: number[],
    propertyTypesIds?: number[],
  ) => {
    const { data, errors } = await client.mutate<{
      createScoop: IScoop;
    }>({
      mutation: CREATE_SCOOP_MUTATION,
      variables: {
        scoop: {
          userId: user!.id,
          text: scoop.text,
          url: scoop.url,
          propertiesIds,
          marketsIds,
          propertyTypesIds,
        },
      },
    });

    const scoopId = data?.createScoop?.id;
    if (scoopId) {
      const files = mediaFilesToUpload ? Object.values(mediaFilesToUpload) : [];
      await executeSetScoopCompaniesMutation(scoopId, companyIds || []);
      await setScoopTags(scoopId, scoopCategories);
      for (const fileData of files) {
        if (fileData && fileData.file) {
          await uploadMedia(fileData.file, scoopId);
        }
      }
      onCloseModalLocal();
    } else {
      ErrorLogger.log(errors?.toString() || errorScoop);
      notificationMessage(errors?.toString() || errorScoop, false);
    }
  };

  const updateScoop = async (
    scoop: IScoopInput,
    scoopCategories?: number[],
    companyIds?: number[],
    propertiesIds?: number[],
    marketsIds?: number[],
    propertyTypesIds?: number[],
  ) => {
    const { data, errors } = await client.mutate<{
      updateScoop: IScoop;
    }>({
      mutation: UPDATE_SCOOP_MUTATION,
      variables: {
        scoop: {
          id: scoop.id,
          userId: scoop.userId,
          lastEditedUserId: user.id,
          text: scoop.text,
          propertiesIds,
          marketsIds,
          propertyTypesIds,
          url: scoop.url,
        },
      },
    });
    const scoopId = data?.updateScoop?.id;
    if (scoopId) {
      const files = mediaFilesToUpload ? Object.values(mediaFilesToUpload) : [];
      await executeSetScoopCompaniesMutation(scoopId, companyIds || []);
      await setScoopTags(scoopId, scoopCategories);
      for (const fileData of files) {
        if (fileData && fileData.file) {
          await uploadMedia(fileData.file, scoopId);
        }
      }
      onCloseModalLocal();
    } else {
      ErrorLogger.log(errors?.toString() || errorUpdatingScoop);
      notificationMessage(errors?.toString() || errorUpdatingScoop, false);
    }
  };

  const onSubmitForm = async (scoopInput: IScoopInput) => {
    try {
      setIsSubmitting(true);

      const { scoopCategories, companies, ...scoop } = scoopInput;

      const categoryIds = scoopCategories?.map(category => category.id) || [];
      const propertiesIds =
        scoop.properties?.map(property => property.id!) || [];
      const marketsIds = scoop.markets?.map(market => market.id) || [];
      const propertyTypesIds =
        scoop.propertyTypes?.map(propertyType => propertyType.id) || [];
      const companyIds = companies?.map(c => c.id) || [];

      isEditing
        ? await updateScoop(
            scoop,
            categoryIds,
            companyIds,
            propertiesIds,
            marketsIds,
            propertyTypesIds,
          )
        : await createScoop(
            scoop,
            categoryIds,
            companyIds,
            propertiesIds,
            marketsIds,
            propertyTypesIds,
          );
    } catch (e) {
      ErrorLogger.log(e as any, errorScoop);
      notificationMessage(errorScoop, false);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <ModalWrapper
      fullScreen
      withCloseButton
      withCloseLabel
      onCancel={onCloseModalLocal}
      modalBackgroundColor={ColorNames.ayPureWhiteColor}
    >
      <ScoopForm
        onDelete={() => setShowDeleteModalScoop(true)}
        onCancel={onCloseModalLocal}
        onSave={onSubmitForm}
        isSubmitting={isSubmitting}
        isEditing={isEditing}
        activityScoop={activityScoop}
        preselectedProperty={preselectedProperty}
        preselectedCompany={preselectedCompany}
        setMediaFilesToUpload={setMediaFilesToUpload}
        mediaFilesToUpload={mediaFilesToUpload}
        activeTab={activeTab}
      />
      <NotificationMessage
        show={showNotification}
        isSuccess={isSuccessNotification}
        text={messageNotification}
        onClose={() => {
          setShowNotification(false);
        }}
      />
      {renderDeleteModal()}
    </ModalWrapper>
  );
};

export default ManageScoopModal;
