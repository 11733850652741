import React from 'react';
import styles from '../SearchInput.module.scss';
import { DOT_LG } from 'constants/placeholders';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import classnames from 'classnames';
import TextHighlighter from 'components/TextHighlighter';
import Icon from 'components/Icon';
import { colors } from 'constants/colors';
import { ResultParserFn } from '../interfaces';
import { SuggestionIcon } from '../enums';
import { getSuggestionParsed } from '../getSuggestionParsed';
import { getSuggestionIcon } from './getSuggestionIcon';
import { getMarketText } from './getMarketText';
import { getSourceIcon, getSubmarketDetails } from './utils';
import {
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
} from 'constants/i18n';
import { translateText } from 'utils/i18n';

const I18N_SEARCH_INPUT_SUGGESTION_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.searchInputSuggestion`;

interface Props {
  suggestion: IPropertyCompany;
  parseResults?: ResultParserFn;
  suggestionIconType?: SuggestionIcon;
  aliasPlaceholder?: (suggestionName: string) => string;
  index?: number;
  searchInputText: string;
  activeIndex?: number | null;
  onActiveIndexChange?: (value: number | null) => void;
  onSelect: (suggestion: IPropertyCompany) => void;
}

const SearchInputSuggestion: React.FC<Props> = ({
  suggestion,
  parseResults,
  suggestionIconType,
  aliasPlaceholder,
  index,
  searchInputText,
  activeIndex,
  onActiveIndexChange,
  onSelect,
}) => {
  const propertyCompanyParsed = getSuggestionParsed(suggestion, parseResults);

  const suggestionIcon = getSuggestionIcon(
    propertyCompanyParsed,
    suggestionIconType,
  );

  const mainTextArray = [
    propertyCompanyParsed.name,
    propertyCompanyParsed.propertyName,
    propertyCompanyParsed.businessParkName,
  ];

  propertyCompanyParsed.type === 'submarket'
    ? mainTextArray.push(propertyCompanyParsed.marketName)
    : null;
  const mainText = mainTextArray.filter(Boolean).join(` ${DOT_LG} `);

  const propertyCompany = suggestion as IPropertyCompany;
  const hasBeenMerged = !!propertyCompany.mergedCompanyId;
  const isAlias = hasBeenMerged && !propertyCompany.mergedAt;
  const marketText = !hasBeenMerged && getMarketText(propertyCompanyParsed);
  const mergedCompanyName =
    propertyCompany.mergedCompanyName ||
    propertyCompanyParsed.mergedCompanyName;

  const shouldShowAliasSuggestion =
    !!aliasPlaceholder && hasBeenMerged && isAlias && mergedCompanyName;

  const defaultMergeText =
    hasBeenMerged &&
    translateText(
      `${I18N_SEARCH_INPUT_SUGGESTION_TEXT_PATH}.mergedCompanyMessage`,
      {
        mergedCompanyName,
        suggestion: suggestion.name,
      },
    );

  const aliasText = translateText(
    `${I18N_SEARCH_INPUT_SUGGESTION_TEXT_PATH}.aliasCompanyMessage`,
    {
      mergedCompanyName,
      suggestion: suggestion.name,
      aliasCompanyName: aliasPlaceholder?.(mergedCompanyName!),
    },
  );

  const mergeText = shouldShowAliasSuggestion ? aliasText : defaultMergeText;

  const showMergeText =
    (hasBeenMerged && !isAlias) || (hasBeenMerged && !!aliasPlaceholder);

  const isItemActive =
    index !== undefined && activeIndex !== undefined && index === activeIndex;

  const { icon, alt } = getSourceIcon(suggestion.type);

  return (
    <li
      onMouseEnter={() => onActiveIndexChange?.(null)}
      key={propertyCompanyParsed.id}
      className={classnames(styles['search-suggestion-item'], {
        [styles['search-suggestion-item-active']]: isItemActive,
      })}
      onMouseDown={() => onSelect(suggestion)}
    >
      <div className={styles['suggestion-value']}>
        <div className={styles['text-contanier']}>
          <p className={styles['suggestion-text-primary']}>
            {suggestionIcon && (
              <img
                src={suggestionIcon}
                alt={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.logo`)}
                className={styles['img-profile']}
              />
            )}
            <TextHighlighter
              searchString={searchInputText}
              textToHighlight={mainText}
            />
            {hasBeenMerged && (
              <>
                <Icon
                  width={0.6875}
                  name={isAlias ? 'alias' : 'merge'}
                  color={colors.primaryColor300}
                  className={styles['merge-icon']}
                />
                <span>{mergedCompanyName}</span>
              </>
            )}
          </p>
          <p
            className={classnames(styles['suggestion-text-secondary'], {
              [styles['text-capitalized']]: !showMergeText,
            })}
          >
            {showMergeText ? mergeText : marketText}
            {getSubmarketDetails(propertyCompanyParsed)}
          </p>
        </div>
        <img src={icon} className={styles['source-icon']} alt={alt} />
      </div>
    </li>
  );
};

export default SearchInputSuggestion;
