import { gql } from '@apollo/client';

export const CREATE_SCOOP_MUTATION = gql`
  mutation createScoop($scoop: ScoopInput!) {
    createScoop(scoop: $scoop) {
      ... on ScoopId {
        id
      }
    }
  }
`;

export const SET_SCOOP_TAGS = gql`
  mutation setScoopTags($scoopId: Int!, $scoopCategoryIds: [Int]!) {
    setScoopTags(scoopId: $scoopId, scoopCategoryIds: $scoopCategoryIds) {
      ... on Scoop {
        id
        text
        userId
        createdAt
      }
    }
  }
`;

export const SET_SCOOP_COMPANIES = gql`
  mutation setScoopCompanies($scoopId: Int!, $companiesIds: [Int]!) {
    setScoopCompanies(scoopId: $scoopId, companiesIds: $companiesIds) {
      ... on Scoop {
        id
        text
        userId
        createdAt
      }
    }
  }
`;

export const DELETE_SCOOP_MUTATION = gql`
  mutation deleteScoop($scoopId: Int!) {
    deleteScoop(scoopId: $scoopId)
  }
`;

export const UPDATE_SCOOP_MUTATION = gql`
  mutation updateScoop($scoop: ScoopInput!) {
    updateScoop(scoop: $scoop) {
      ... on Scoop {
        id
        text
        userId
        createdAt
      }
    }
  }
`;

export const CREATE_SCOOP_COMMENT = gql`
  mutation createScoopComment($scoopComment: ScoopCommentInput!) {
    createScoopComment(scoopComment: $scoopComment) {
      ... on ScoopComment {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const UPDATE_SCOOP_COMMENT = gql`
  mutation updateScoopComment($scoopComment: ScoopCommentInput!) {
    updateScoopComment(scoopComment: $scoopComment) {
      ... on ScoopComment {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const DELETE_SCOOP_COMMENT = gql`
  mutation deleteScoopComment($scoopCommentId: Int!) {
    deleteScoopComment(scoopCommentId: $scoopCommentId)
  }
`;
