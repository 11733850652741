import { gql } from '@apollo/client';

export const GET_EXPORT = gql`
  query export($exportId: Int!) {
    export(id: $exportId) {
      processedAt
      errors
    }
  }
`;
