import { IRadioItem } from 'components/Inputs/RadioInput';
import {
  IMarketPricingInput,
  IMarketPricingYear,
  MarketPricingRentType,
  MarketPricingTimeMeasurement,
} from 'interfaces/inputs/IMarketPricingInput';
import { last, orderBy } from 'lodash';
import { getYearFromDate } from 'utils/date';
import {
  roundNumberWithDecimals,
  // truncateFloat,
} from 'utils/formatters/number';
import { fieldHasValue } from 'utils/objects';
import { removeTypenameKey } from 'utils/graphql/typename';
import { convertObjectNumericFields } from 'utils/parsers/convertObjectNumericFields';
import { ICellFooter } from '../EditTable/interfaces';
import { SegmentPricingParams } from '../interfaces';
import {
  currencyInputFormatter,
  parseCurrencyToNumber,
} from 'utils/formatters/currency';
import { SegmentPricingFields } from './tableData';
import { getTranslatedRentType } from 'utils/leases';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export const getTimeMeasurementOption = (
  timeMeasurement?: MarketPricingTimeMeasurement,
) => ({
  label: translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.${timeMeasurement?.toLowerCase()}`,
  ),
  value: timeMeasurement?.toString(),
});

export const getRentTypeOption = (rentType?: MarketPricingRentType) => ({
  label: getTranslatedRentType(rentType!),
  value: rentType?.toString(),
});

export const getTimeMeasurementRadioOptions = (): IRadioItem[] => [
  getTimeMeasurementOption(MarketPricingTimeMeasurement.Annual),
  getTimeMeasurementOption(MarketPricingTimeMeasurement.Monthly),
];

export const getRentTypeRadioOptions = (): IRadioItem[] => [
  getRentTypeOption(MarketPricingRentType.FS),
  getRentTypeOption(MarketPricingRentType.NNN),
];

const isMarketPricingValid = (marketPricingData?: IMarketPricingInput) =>
  !!marketPricingData &&
  !!marketPricingData.year &&
  fieldHasValue(marketPricingData.directRent) &&
  fieldHasValue(marketPricingData.darPercent) &&
  fieldHasValue(marketPricingData.tiPercent) &&
  fieldHasValue(marketPricingData.tiLongTerm);

const getPreviousYearPlaceholder = (baseYearData: IMarketPricingInput) => {
  if (!isMarketPricingValid(baseYearData)) return baseYearData;

  return {
    year: baseYearData.year! - 1,
    darPercent: '--',
    tiPercent: '--',
  };
};

const calculatePreviousPercentage = (recentValue: number, oldValue: number) =>
  recentValue > 0
    ? roundNumberWithDecimals(
        parseFloat(`${1 - oldValue / recentValue}`) * 10 * 10,
      )
    : 0;

/**
 * Update the DAR and IT percentages based on the previous year.
 * @param baseYearData
 * @param recentYearData
 */
const updateNextYearData = (
  baseYearData: IMarketPricingInput,
  recentYearData?: IMarketPricingInput,
  columnId?: string,
): IMarketPricingYear | undefined => {
  const isDarRentField =
    SegmentPricingFields.directRent === columnId ||
    SegmentPricingFields.darPercent === columnId;
  if (isDarRentField) {
    if (recentYearData) {
      const darPercent = calculatePreviousPercentage(
        +parseCurrencyToNumber(recentYearData!.directRent!),
        +parseCurrencyToNumber(baseYearData.directRent!),
      );
      return {
        ...recentYearData,
        darPercent,
      };
    }
  }

  const isTiField =
    SegmentPricingFields.tiLongTerm === columnId ||
    SegmentPricingFields.tiPercent === columnId;
  if (isTiField) {
    if (recentYearData) {
      const tiPercent = calculatePreviousPercentage(
        +parseCurrencyToNumber(recentYearData!.tiLongTerm!),
        +parseCurrencyToNumber(baseYearData.tiLongTerm!),
      );
      return {
        ...recentYearData,
        tiPercent,
      };
    }
  }

  if (
    !isMarketPricingValid(baseYearData) ||
    !isMarketPricingValid(recentYearData)
  )
    return recentYearData;

  return {
    ...recentYearData,
    darPercent: parseFloat(
      calculatePreviousPercentage(
        +parseCurrencyToNumber(recentYearData!.directRent!),
        +parseCurrencyToNumber(baseYearData.directRent!),
      ).toString(),
    ),
    tiPercent: parseFloat(
      calculatePreviousPercentage(
        +parseCurrencyToNumber(recentYearData!.tiLongTerm!),
        +parseCurrencyToNumber(baseYearData.tiLongTerm!),
      ).toString(),
    ),
  };
};

export const generateMarketPricingPlaceholder = (
  currentData: IMarketPricingInput[],
): IMarketPricingInput => {
  const lastRow = last(currentData);
  const currentYear = getYearFromDate();
  const nextYear = lastRow?.year ? lastRow?.year - 1 : +currentYear;
  const defaultValue = {
    id: 0,
    year: nextYear,
    darPercent: '--',
    tiPercent: '--',
  };

  if (!lastRow || !isMarketPricingValid(lastRow)) return defaultValue;

  return {
    ...getPreviousYearPlaceholder(lastRow),
    ...defaultValue,
  };
};

export const updateMarketPricingData = (
  currentData: IMarketPricingInput[],
  yearEdited: number,
  columnId?: string,
): IMarketPricingInput[] => {
  const yearEditedData = currentData.find(d => d.year === yearEdited);
  if (!yearEditedData) return currentData;

  const recentData = orderBy(
    currentData.filter(d => d.year && d.year > yearEdited + 1),
    ['year'],
    ['asc'],
  );
  const oldData = orderBy<IMarketPricingInput>(
    currentData.filter(d => d.year && d.year < yearEdited),
    ['year'],
    ['desc'],
  );

  const nextYearData = updateNextYearData(
    yearEditedData,
    currentData.find(d => d.year === yearEdited + 1),
    columnId,
  );

  const currentYearData = updateNextYearData(
    currentData.find(d => d.year === yearEdited - 1) || yearEditedData,
    currentData.find(d => d.year === yearEdited),
    columnId,
  );

  const updatedData = [...recentData];

  if (currentYearData) {
    updatedData.push(currentYearData);
  } else {
    updatedData.push(yearEditedData);
  }

  if (nextYearData) {
    updatedData.push(nextYearData);
  }

  oldData.forEach(year => updatedData.push(year));

  return orderBy(updatedData, ['year'], ['desc']);
};

export const getSegmentPricingQueryVariables = (
  segmentParams: SegmentPricingParams,
) => {
  const {
    market,
    submarket,
    propertyType,
    propertySubtype,
    propertyClass,
    propertyId,
  } = segmentParams;

  const hasMarketParams = !!market?.id && !!propertyType?.id && !!submarket?.id;

  if (!hasMarketParams && !propertyId) return undefined;

  return {
    filter: hasMarketParams
      ? {
          marketId: market?.id,
          submarketId: submarket?.id,
          propertyTypeId: propertyType?.id,
          propertySubtypeId: propertySubtype?.id,
          thirdPartyPropertyClassId: propertyClass?.id,
        }
      : { propertyId },
  };
};

export const prepareSegmentPricingToEdit = (data: IMarketPricingInput[]) => {
  return data.map(d => ({
    ...d,
    directRent: d.directRent && roundNumberWithDecimals(+d.directRent),
    tiLongTerm: d.tiLongTerm && roundNumberWithDecimals(+d.tiLongTerm),
  }));
};

export const prepareSegmentPricingToSubmit = (
  data?: IMarketPricingInput[],
): IMarketPricingYear[] | undefined =>
  data?.map(d => {
    const {
      id,
      year,
      directRent,
      darPercent,
      tiLongTerm,
      tiPercent,
      freeRentLongTerm,
    } = removeTypenameKey(
      convertObjectNumericFields(d, [
        'year',
        'directRent',
        'darPercent',
        'tiLongTerm',
        'tiPercent',
        'freeRentLongTerm',
      ]),
    );

    return {
      id,
      year,
      directRent,
      darPercent: darPercent || 0,
      tiLongTerm,
      tiPercent: tiPercent || 0,
      freeRentLongTerm,
    };
  });

const formatSegmentCurrencies = (value?: number | string) =>
  value ? currencyInputFormatter(`${value}`, true) : '';

export const generateSegmentPricingFooterCells = (
  data: IMarketPricingInput,
): ICellFooter[] => [
  { value: data.year },
  { value: formatSegmentCurrencies(data?.directRent), isAlignedOnRight: true },
  { value: formatSegmentCurrencies(data?.darPercent), isAlignedOnRight: true },
  { value: formatSegmentCurrencies(data?.tiLongTerm), isAlignedOnRight: true },
  { value: formatSegmentCurrencies(data?.tiPercent), isAlignedOnRight: true },
  { value: data?.freeRentLongTerm, isAlignedOnRight: true },
];
