import { InteractionRequiredAuthError, UserAgentApplication } from 'msal';
import { AVANT_CITIES_ACCESS_TOKEN } from 'clients/avantCities/constants';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import dayjs from 'dayjs';

const SESSION_RENEW_OFFSET_MINUTES = 5;

export const requiresInteraction = (errorMessage: any) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  return msie || msie11;
};

export const GRAPH_SCOPES = {
  AVANT_API: `api://${window._env_.SSO_CLIENT_ID}/API`,
  REFRESH_TOKEN_SCOPE: 'offline_access',
};

export const GRAPH_REQUESTS = {
  API: {
    scopes: [GRAPH_SCOPES.AVANT_API, GRAPH_SCOPES.REFRESH_TOKEN_SCOPE],
  },
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: window._env_.SSO_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${window._env_.SSO_TENANT_ID}`,
    redirectUri: window._env_.SSO_REDIRECT_URL,
    validateAuthority: true,
    postLogoutRedirectUri: window._env_.SSO_REDIRECT_URL,
    navigateToLoginRequestUrl: false,
  },
  system: {
    // Default is 6000: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/f684e1c1ca7514e92871ef4c69cab0ca34af3ed2/lib/msal-core/src/Configuration.ts#L20
    loadFrameTimeout: 12000,
  },
  cache: {
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-between-browser-tabs
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE(),
  },
});

export const acquireMSToken = async (request: any, redirect = true) => {
  // @ts-ignore
  return await msalApp.acquireTokenSilent(request).catch((error: any) => {
    // Call acquireTokenRedirect in case of acquireTokenSilent failure due to consent or interaction required ONLY
    if (
      requiresInteraction(error.errorCode) ||
      error instanceof InteractionRequiredAuthError
    ) {
      return redirect
        ? msalApp.acquireTokenRedirect({
            ...request,
            redirectUri: window._env_.SSO_REDIRECT_URL,
          })
        : msalApp.acquireTokenPopup(request);
    } else {
      console.error('[acquireTokenSilent] Non-interactive error:', error);
    }
  });
};

/**
 * Reference: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
 *
 * This function validates if the MS Token is still valid.
 * From the MS docs, the token expires in an hour, and after this hour the msalApp.acquireTokenSilent will try to refresh the token if possible.
 * If it's not possible to refresh the token, it will throw a ClientAuthError.
 */
export const isMSTokenValid = async () => {
  try {
    const expirationDate = localStorage.getItem(
      LocalStorageKeys.azureTokenExpirationDate,
    );
    const msToken = localStorage.getItem(AVANT_CITIES_ACCESS_TOKEN);
    if (expirationDate && !isNaN(Number(expirationDate)) && msToken) {
      const diffInMinutes = dayjs(new Date(Number(expirationDate))).diff(
        dayjs(),
        'minute',
      );

      if (diffInMinutes && diffInMinutes > SESSION_RENEW_OFFSET_MINUTES) {
        return true;
      }
    }

    const msAPIToken = await acquireMSToken(GRAPH_REQUESTS.API);
    if (msAPIToken?.accessToken) {
      localStorage.setItem(AVANT_CITIES_ACCESS_TOKEN, msAPIToken.accessToken);
      localStorage.setItem(
        LocalStorageKeys.azureTokenExpirationDate,
        msAPIToken.expiresOn.getTime().toString(),
      );
    }

    return msAPIToken;
  } catch (error) {
    const _error = error as any;
    return (
      !_error.errorCode ||
      (!requiresInteraction(_error.errorCode) &&
        _error.errorCode.indexOf('user_login_error') === -1)
    );
  }
};
