import { gql } from '@apollo/client';
import {
  ASSET_PERFORMANCE_FRAGMENT,
  MARKET_PRICING_FRAGMENT,
} from './fragments';

export const ASSET_PERFORMANCE_MUTATION = gql`
  mutation saveAssetPerformances($items: [SaveAssetPerformanceInput]!) {
    saveAssetPerformances(items: $items) {
      ...assetPerformanceData
    }
  }
  ${ASSET_PERFORMANCE_FRAGMENT}
`;

export const MARKETING_PRICING_MUTATION = gql`
  mutation createMarketPricing($input: CreateMarketPricingInput!) {
    createMarketPricing(input: $input) {
      ...marketingPricingData
    }
  }
  ${MARKET_PRICING_FRAGMENT}
`;
