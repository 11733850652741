import React, { useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';

import HeadingTitle from '../../../HeadingTitle';
import { ComparativeCardTheme } from '../constants';

import calculateHeights from './rowHeightCalculator';
import { ComparativeRowProps } from './types';
import styles from './ComparativeRows.module.scss';
import LoadingMessage from 'components/LoadingMessage';

const ComparativeRows: React.FC<ComparativeRowProps> = ({
  targetProperty,
  comparativeProperties,
  onDataChanges,
  rowConfig,
  className,
  completeCompetitiveProperties,
  toggleOptions,
}) => {
  const rowsRefs = useRef<any>([]);

  const updateHeights = useCallback(() => {
    calculateHeights(rowsRefs.current);
  }, []);

  useEffect(updateHeights);

  const renderRow = (rowKey: string, index: number) => {
    const columnConfig = rowConfig[rowKey];
    if (!columnConfig.isVisible) return null;

    const ColumnComponent = columnConfig.component;
    return (
      <div key={rowKey}>
        {!!columnConfig.headerText && (
          <HeadingTitle text={columnConfig.headerText} />
        )}

        <div
          className={styles['comparative-container']}
          ref={el => (rowsRefs.current[index] = el)}
        >
          <ColumnComponent
            completeCompetitiveProperties={completeCompetitiveProperties}
            property={targetProperty}
            columnTheme={ComparativeCardTheme.main}
            onLoadComponent={updateHeights}
            toggleOptions={toggleOptions}
          />

          {comparativeProperties.map(property => (
            <ColumnComponent
              completeCompetitiveProperties={completeCompetitiveProperties}
              key={rowKey + property.id}
              columnTheme={ComparativeCardTheme.secondary}
              property={property}
              toggleOptions={toggleOptions}
              onLoadComponent={updateHeights}
              onDataChanges={onDataChanges}
            />
          ))}
        </div>
      </div>
    );
  };

  if (!completeCompetitiveProperties) {
    return <LoadingMessage />;
  }
  return (
    <div className={classnames(styles.properties, className)}>
      {Object.keys(rowConfig).map(renderRow)}
    </div>
  );
};

export default ComparativeRows;
