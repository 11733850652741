/**
 * This function generates the path to draw a SVG rounded rect.
 *
 * @param x
 * @param y
 * @param width
 * @param height
 * @param borderRadius
 * @param isTopLeftRounded
 * @param isTopRightRounded
 * @param isBottomLeftRounded
 * @param isBottomRightRounded
 */
export const roundedRectPath = (
  x: number,
  y: number,
  width: number,
  height: number,
  borderRadius: number,
  isTopLeftRounded = false,
  isTopRightRounded = false,
  isBottomLeftRounded = false,
  isBottomRightRounded = false,
) => {
  let retval;
  retval = `M${x + borderRadius},${y}`;
  retval += `h${width - 2 * borderRadius}`;
  if (isTopRightRounded) {
    retval += `a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}`;
  } else {
    retval += `h${borderRadius}`;
    retval += `v${borderRadius}`;
  }
  retval += `v${height - 2 * borderRadius}`;
  if (isBottomRightRounded) {
    retval += `a${borderRadius},${borderRadius} 0 0 1 ${-borderRadius},${borderRadius}`;
  } else {
    retval += `v${borderRadius}`;
    retval += `h${-borderRadius}`;
  }
  retval += `h${2 * borderRadius - width}`;
  if (isBottomLeftRounded) {
    retval += `a${borderRadius},${borderRadius} 0 0 1 ${-borderRadius},${-borderRadius}`;
  } else {
    retval += `h${-borderRadius}`;
    retval += `v${-borderRadius}`;
  }
  retval += `v${2 * borderRadius - height}`;
  if (isTopLeftRounded) {
    retval += `a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${-borderRadius}`;
  } else {
    retval += `v${-borderRadius}`;
    retval += `h${borderRadius}`;
  }
  retval += 'z';
  return retval;
};
