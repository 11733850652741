const MARGINS = {
  top: 8,
  right: 16,
  bottom: 56,
  left: 8,
};
const WIDTH = 360 - MARGINS.left - MARGINS.right;
const HEIGHT = 280;

export const graphDimensions = {
  MARGINS,
  WIDTH,
  HEIGHT,
};
