import React from 'react';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import { REGEX_THREE_DECIMALS } from 'constants/regex';
import { IdName } from 'interfaces/IdName';
import { translateText } from 'utils/i18n';
import { ExclusiveFieldProps } from './ExclusiveFields';

const HealthcareFields: React.FC<ExclusiveFieldProps> = ({
  classNames: { formControlClassName },
  onChange,
  property: { healthcare },
}) => (
  <>
    <FormControl
      label={translateText('avantPlatform.attributes.property.label.numBeds')}
      wrapperClassName={formControlClassName}
    >
      <TextInput
        placeholder={translateText(
          'avantPlatform.attributes.property.prompt.enterNumBeds',
        )}
        pattern={REGEX_THREE_DECIMALS}
        value={healthcare?.numBeds}
        onChange={value => onChange('numBeds', value)}
      />
    </FormControl>
    <CompanySearchCombo
      isMultiple
      label={translateText(
        'avantPlatform.roles.company.label.providerCompanies',
      )}
      btnLabel={translateText(
        'avantPlatform.roles.company.action.createLeaseholder',
      )}
      companies={healthcare?.providerCompanies}
      placeholder={translateText(
        'avantPlatform.roles.company.prompt.enterProviderCompanies',
      )}
      inputId={'property-healthcare-provider-companies-input-id'}
      wrapperClassName={formControlClassName}
      onCompanySelection={(value: any) => {
        const newValue = value?.raw || value || null;
        newValue &&
          onChange('providerCompanies', [
            ...(healthcare?.providerCompanies || []),
            newValue,
          ]);
      }}
      onRemoveItem={(owner: IdName) => {
        onChange(
          'providerCompanies',
          (healthcare?.providerCompanies || []).filter(
            (value: IdName) => value.id !== owner.id,
          ),
        );
      }}
    />
  </>
);

export default HealthcareFields;
