import React from 'react';
import { IdName } from 'interfaces/IdName';
import { IActivity } from 'interfaces/IActivity';
import ActivityList from 'components/ActivityList';
import styles from './ActivityFeedContent.module.scss';
import { groupActivitiesByDate } from './groupActivitiesByDate';
import ActivityFeedGroupHeader from './ActivityFeedGroupHeader';
import { IProperty } from 'interfaces/IProperty';

interface Props {
  markets?: IdName[];
  groupByDate?: boolean;
  activities: IActivity[];
  preselectedProperty?: IProperty;
  onMarketClick?: () => void;
  refetchScoopList: () => void;
}

const ActivityFeedContent: React.FC<Props> = props =>
  !props.groupByDate ? (
    <ActivityList
      activities={props.activities}
      refetchActivities={props.refetchScoopList}
      preselectedProperty={props.preselectedProperty}
    />
  ) : (
    <div className={styles.container}>
      {groupActivitiesByDate(props.activities).map((group, index) => (
        <div className={styles['group-container']} key={group.date}>
          <ActivityFeedGroupHeader
            index={index}
            markets={props.markets}
            relativeDate={group.relativeDate}
            onMarketClick={props.onMarketClick}
          />
          <ActivityList
            activities={group.activities}
            refetchActivities={props.refetchScoopList}
            preselectedProperty={props.preselectedProperty}
          />
        </div>
      ))}
    </div>
  );

export default ActivityFeedContent;
