import React from 'react';
import styles from './ChoiceInput.module.scss';
import classnames from 'classnames';
import { Size } from './';
import { removeExtraSpaces } from 'utils/formatters/string';

type PropTypes = {
  id?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  isVertical?: boolean;
  itemWrapperClassName?: string;
  label: string;
  onSelect: () => void;
  size?: Size;
  selectedItemWrapperClassName?: string;
};

const ChoiceInputItem: React.FC<PropTypes> = (props: PropTypes) => {
  const {
    id,
    isDisabled,
    isSelected,
    isVertical,
    itemWrapperClassName,
    label,
    onSelect,
    size,
    selectedItemWrapperClassName,
  } = props;
  const buttonSizeClassname = styles[`${size || 'regular'}-size`];

  const onClickHandler = () => {
    if (!isDisabled) {
      onSelect();
    }
  };

  return (
    <button
      type="button"
      id={id}
      className={removeExtraSpaces(
        classnames(
          styles['radio-group-item'],
          buttonSizeClassname,
          {
            [styles.disabled]: isDisabled,
            [[selectedItemWrapperClassName, styles.selected].join(
              ' ',
            )]: isSelected,
            [styles.vertical]: isVertical,
          },
          itemWrapperClassName,
        ),
      )}
      title={label}
      onClick={onClickHandler}
    >
      {label}
    </button>
  );
};

export default ChoiceInputItem;
