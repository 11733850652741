import React, { useState, useRef, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { REGEX_EMPTY_SPACES } from 'constants/regex';
import { Keyboard } from 'constants/keyboard';
import deleteMarkIcon from 'assets/images/icons/delete-mark.svg';
import styles from './EmailInput.module.scss';
import { isValidEmail } from 'utils/validation/email';

export type Props = {
  label: string;
  items: string[];
  onEnter: (email: string) => void;
  onDelete: (index: number) => void;
};

const CONFIRM_EMAIL_KEYS = [Keyboard.Enter, Keyboard.Space, Keyboard.Semicolon];

const EmailInput: React.FC<Props> = props => {
  const [text, setText] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const isEntryDuplicated = (entry: string) => {
    return props.items.some(item => item === entry);
  };

  const addEntry = () => {
    const canAddEmail = isValidEmail(text) && !isEntryDuplicated(text);

    if (canAddEmail) {
      props.onEnter(text);
      setText('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (CONFIRM_EMAIL_KEYS.includes(event.keyCode)) {
      addEntry();
    }
  };

  const cleanEntry = (entry: string) => {
    return entry
      .toLowerCase()
      .replace(REGEX_EMPTY_SPACES, '')
      .replace(';', '');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(cleanEntry(event.currentTarget.value));
  };

  const triggerFocusInput = (event: SyntheticEvent) => {
    if (event.target === event.currentTarget) {
      inputRef.current?.focus();
    }
  };

  const handleEmailClick = (index: number) => props.onDelete(index);

  const handleFocus = () => setIsInputFocused(true);

  const handleBlur = () => {
    setIsInputFocused(false);
    addEntry();
  };

  return (
    <>
      <label htmlFor="email-input" className={styles['label']}>
        {props.label}
      </label>
      <div
        className={classnames(styles['container'], {
          [styles['is-active']]: isInputFocused,
        })}
        onClick={triggerFocusInput}
      >
        {props.items?.map((email: string, index: number) => {
          return (
            <span key={email} className={styles['email-chip']}>
              {email}
              <img
                src={deleteMarkIcon}
                alt="remove email"
                onClick={() => handleEmailClick(index)}
                className={styles['email-chip-remove']}
              />
            </span>
          );
        })}
        <input
          autoComplete="none"
          id="email-input"
          className={styles['email-input']}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          placeholder="Enter Email(s)"
          type="text"
          value={text}
          ref={inputRef}
        />
      </div>
    </>
  );
};

export default EmailInput;
