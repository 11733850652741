import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { GraphDimension } from '../index';
import { colors } from '../../../../constants/colors';
import { graphDimensions } from '../graphConstants';
import { BOTTOM_LINE_CLASS, TOP_LINE_CLASS } from '../nodes';

interface Props {
  graphDimension: GraphDimension;
}

const DISTANCE_BETWEEN_LINES = 8.5;
const LINE_HEIGHT = 1;
const TOTAL_LINES_HALF_GRAPH = 8;

const BackgroundLines: React.FC<Props> = props => {
  const svgGroupRef = useRef<SVGGElement>(null);
  const { graphDimension } = props;

  const chartHeight = graphDimensions.HEIGHT;
  const halfHeight = Math.ceil(chartHeight / 2);

  /**
   * This function returns an array with the coordinates for each line to show in the first half of the graph.
   */
  const getLinesTop = () => {
    return [...Array(TOTAL_LINES_HALF_GRAPH)].map((_, i: number) => {
      const y = i * DISTANCE_BETWEEN_LINES + i;
      return {
        isFirst: i === 0,
        x1: 0,
        y1: y,
        x2: graphDimension.width,
        y2: y,
      };
    });
  };

  /**
   * This function returns an array with the coordinates for each line to show in the last half of the graph.
   */
  const getLinesBottom = () => {
    return [...Array(TOTAL_LINES_HALF_GRAPH)].map((_, i: number) => {
      const y = (i + 1) * DISTANCE_BETWEEN_LINES + halfHeight;
      return {
        isLast: i === TOTAL_LINES_HALF_GRAPH - 1,
        x1: 0,
        y1: y,
        x2: graphDimension.width,
        y2: y,
      };
    });
  };

  useEffect(() => {
    const linesTop = getLinesTop();
    const linesBottom = getLinesBottom();

    const svgGroup = d3.select(svgGroupRef.current!);

    // Draw all lines in the first half of the graph
    svgGroup
      .selectAll(`.${TOP_LINE_CLASS}`)
      .data(linesTop)
      .enter()
      .append('line')
      .attr('x1', d => d.x1)
      .attr('y1', d => d.y1)
      .attr('x2', d => d.x2)
      .attr('y2', d => d.y2)
      .attr('class', TOP_LINE_CLASS)
      .attr('stroke-width', LINE_HEIGHT)
      .attr('stroke-opacity', d => (d.isFirst ? 0.8 : 0.16))
      .attr('stroke', colors.supportive300);

    // Draw all lines in the last half of the graph
    svgGroup
      .selectAll(`.${BOTTOM_LINE_CLASS}`)
      .data(linesBottom)
      .enter()
      .append('line')
      .attr('x1', d => d.x1)
      .attr('y1', d => d.y1)
      .attr('x2', d => d.x2)
      .attr('y2', d => d.y2)
      .attr('class', BOTTOM_LINE_CLASS)
      .attr('stroke-width', LINE_HEIGHT)
      .attr('stroke-opacity', d => (d.isLast ? 0.8 : 0.16))
      .attr('stroke', colors.ayVioletColor);

    // eslint-disable-next-line
  }, []);

  return <g ref={svgGroupRef} />;
};

export default BackgroundLines;
