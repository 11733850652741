import { gql } from '@apollo/client';

export const GET_SPACE_USES = gql`
  query spaceUses($search: SpaceUseSearch) {
    spaceUses(search: $search) {
      id
      name
      type
    }
  }
`;
