import React, { useEffect } from 'react';
import {
  Helmet as HelmetAsync,
  HelmetProvider as HelmetAsyncProvider,
} from 'react-helmet-async';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DEFAULT_PAGE_NAME } from 'constants/pageNames';

interface HelmetInterface {
  setPageTitle: (title: string) => void;
}
export const HelmetContext = React.createContext<HelmetInterface>({
  setPageTitle: () => DEFAULT_PAGE_NAME,
});

const { Provider } = HelmetContext;

type PathParamsType = {};

type Props = RouteComponentProps<PathParamsType> & {
  children: React.ReactNode;
};

const HelmetProvider: React.FC<Props> = ({ children, location }) => {
  const [pageTitle, setPageTitle] = React.useState(DEFAULT_PAGE_NAME);
  const handleChangePageTitle = (title: string) => {
    const completeTitle = title
      ? `${DEFAULT_PAGE_NAME} - ${title}`
      : DEFAULT_PAGE_NAME;
    setPageTitle(completeTitle);
  };

  useEffect(() => {
    handleChangePageTitle('');
  }, [location]);

  return (
    <Provider value={{ setPageTitle: handleChangePageTitle }}>
      <HelmetAsyncProvider>
        <HelmetAsync>
          <title>{pageTitle}</title>
        </HelmetAsync>
        {children}
      </HelmetAsyncProvider>
    </Provider>
  );
};

export default withRouter(HelmetProvider);
