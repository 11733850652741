import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { colors } from 'constants/colors';

import styles from './Legend.module.scss';
import { LegendProps } from './types';

const Legend = forwardRef<HTMLDivElement, LegendProps>(
  (
    {
      className,
      textClassName,
      text,
      legendColor = colors.primaryColor500,
      textColor = colors.ayPureWhiteColor,
      onClick,
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={classnames(styles.wrapper, className)}
      style={{ backgroundColor: legendColor }}
      onClick={onClick}
    >
      <span
        className={classnames(styles.text, textClassName)}
        style={{ color: textColor }}
      >
        {text}
      </span>
    </div>
  ),
);

export default Legend;
