export enum ZoomLevel {
  small = 8,
  regular = 12,
  big = 14,
  extraBig = 16,
}

export enum PropertyMapSize {
  extraSmall = 'extraSmall',
  small = 'small',
  regular = 'regular',
  large = 'large',
  big = 'big',
  fullWidth = 'fullWidth',
  portrait = 'portrait',
  portraitLarge = 'portrait-large',
}

export const staticMapImageSizesPx = {
  [PropertyMapSize.extraSmall]: 48,
  [PropertyMapSize.small]: 64,
  [PropertyMapSize.portrait]: 64,
  [PropertyMapSize.portraitLarge]: 96,
  [PropertyMapSize.regular]: 104,
  [PropertyMapSize.large]: 170,
  [PropertyMapSize.big]: 180,
  [PropertyMapSize.fullWidth]: 526,
};
