import React from 'react';
import classnames from 'classnames';
import styles from './MissingGraphData.module.scss';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  imgSrc?: string;
  text?: string;
  title?: string;
  bordered?: boolean;
  wrapperClassName?: string;
  skin?: 'dark' | 'light';
  fontSize?: 'sm' | 'base' | 'lg';
  size?: number;
}

const MissingGraphData: React.FC<Props> = ({
  text,
  title,
  wrapperClassName,
  imgSrc = 'emptySectionStanding',
  skin = 'dark',
  fontSize = 'base',
  bordered = false,
  size = 6,
}: Props) => {
  const { t } = useTranslation();

  const chartText = text
    ? text
    : t(`${I18N_PLATFORM_COMMON_WORD_PATH}.noDataError`);
  return (
    <div
      className={classnames(
        styles['content'],
        styles[`${skin}-skin`],
        styles[`font-size-${fontSize}`],
        wrapperClassName,
        {
          [styles['bordered']]: bordered,
        },
      )}
    >
      {title && <h3 className={classnames(styles.title)}>{title}</h3>}
      <Icon name={imgSrc} size={size} />
      <span className={styles['text']}>{chartText}</span>
    </div>
  );
};

export default MissingGraphData;
