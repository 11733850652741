import { IFileUpload } from 'interfaces/IFileUpload';
import dayjs from 'dayjs';

const MAX_MINUTES_PROCESSING = 30;

export const shouldStopPolling = (file?: IFileUpload) => {
  if (!!file?.createdAt && !file?.processedAt) {
    const createdAtDate = dayjs(file?.createdAt);
    const now = dayjs();
    const hasTime = file.createdAt.indexOf('T00:00:00') === -1;

    return hasTime
      ? now.diff(createdAtDate, 'minute') >= MAX_MINUTES_PROCESSING
      : now.diff(createdAtDate, 'day') >= 1;
  }

  return false;
};

export const isFileProcessingDone = (file: IFileUpload) =>
  !!file.processedAt || !!file.error;

export const fileUploadType = (uploadType: string) => {
  switch (uploadType) {
    case 'SALES':
      return 'sale_plural';
    case 'PROPERTIES':
      return 'property_plural';
    case 'LEASES':
      return 'lease_plural';
    case 'AVAILABILITIES':
      return 'availability_plural';
    case 'TIMS':
      return 'timPlural';
    case 'TIM':
      return 'timSingular';
    case 'ASSET_PERFORMANCE':
      return 'historicalPerformance';
    default:
      return 'pricingQuote_plural';
  }
};
