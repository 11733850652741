import React from 'react';
import styles from './GraphYAxisLegend.module.scss';
import classNames from 'classnames';

interface Props {
  bottom?: number;
  height?: number;
  isLeftAligned?: boolean;
  isRightAligned?: boolean;
  top?: number | null;
  left?: number | null;
  right?: number;
  legends: string[];
  wrapperClassName?: string;
}

const GraphYAxisLegend: React.FC<Props> = ({
  top,
  bottom = 2.6,
  height = 9.75,
  isLeftAligned,
  isRightAligned,
  left = 2.75,
  right,
  legends,
  wrapperClassName,
}) => (
  <div
    className={classNames(
      styles['label'],
      {
        [styles['left-aligned']]: isLeftAligned,
        [styles['right-aligned']]: isRightAligned,
      },
      wrapperClassName,
    )}
    style={{
      top: top ? `${top}rem` : undefined,
      bottom: bottom ? `${bottom}rem` : undefined,
      height: height ? `${height}rem` : undefined,
      right: right ? `${right}rem` : undefined,
      left: left ? `${left}rem` : undefined,
    }}
  >
    {legends.map((label, index) => (
      <p key={index} className={styles['item']}>
        {label}
      </p>
    ))}
  </div>
);

export default GraphYAxisLegend;
