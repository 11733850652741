import { convertToNumber } from 'utils/parsers/convertToNumber';

export const convertObjectNumericFields = (
  obj: any,
  numericFields: string[],
): any => {
  const result = { ...obj };

  for (let i = 0; i < numericFields.length; i++) {
    const numericField = numericFields[i];
    if (numericField === 'typicalCeilingHeightFeet' && !!result[numericField]) {
      if (!result['typicalCeilingHeightInches']) {
        result['typicalCeilingHeightInches'] = 0;
      }
    }
    const value = result[numericField];
    result[numericField] = convertToNumber(value);
  }

  return result;
};
