import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styles from './Rhombus.module.scss';
import { IProperty } from 'interfaces/IProperty';
import GraphContainer from '../GraphContainer';
import { colors } from 'constants/colors';
import graphConstants from './graphConstants';
import { GraphSetupService } from './GraphSetupService';
import { GraphIds } from './nodes';
import { IGraphCoverageRatioResponse } from 'interfaces/graphs/coverageRatio';
import {
  onMouseOverPropertyPlate,
  onMouseOutPropertyPlate,
  onMouseOverMarketPlate,
  onMouseOutMarketPlate,
} from './Elements/hoverEffect';
import {
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';

const { GRAPH_DIMENSIONS, BOX_SHADOW } = graphConstants;

interface Props {
  property: IProperty;
  data: IGraphCoverageRatioResponse;
}

export type RhombusGraphDataType = {
  topTitle?: string;
  topLegend?: string;
  topSubLegend?: string | null;
  bottomTitle?: string;
  bottomLegend?: string;
  bottomSubLegend?: string;
  avgFloorPlateSize: number;
  propertyCoverageRatio?: number;
  marketAvgCoverageRatio?: number;
};

const RhombusGraph: React.FC<Props> = ({ property, data }) => {
  const [graphData, setGraphData] = useState<RhombusGraphDataType | null>(null);
  const [isReady, setIsReady] = useState(false);
  const graphContainerElement = useRef(null);

  useEffect(() => {
    const unitOfMeasurement = getUnitOfMeasurementForProperty(
      'floorPlateSize',
      ModelsWithUnitsOfMeasurement.Property,
      property?.propertyCountry?.code || property?.measurementSystem,
    );

    setGraphData(
      GraphSetupService.getGraphData(property, data, unitOfMeasurement),
    );
    setIsReady(true);
  }, [data, property]);

  const getSvgRoot = () => {
    return d3
      .select(graphContainerElement!.current)
      .append('svg')
      .attr('id', GraphIds.ContainerLayerId)
      .attr('width', GRAPH_DIMENSIONS.SIDE_WITH_SHADOW)
      .attr('height', GRAPH_DIMENSIONS.SIDE_WITH_SHADOW)
      .attr('transform', 'rotate(45)')
      .style('overflow', 'visible');
  };

  const drawFrontRect = (
    svgRoot: any,
    rectSideSize: number,
    propertyIsBigger: boolean,
  ) => {
    const fillColor = propertyIsBigger
      ? colors.supportive500
      : colors.primaryColor500;
    const onMouseOver = propertyIsBigger
      ? onMouseOverMarketPlate
      : onMouseOverPropertyPlate;
    const onMouseOut = propertyIsBigger
      ? onMouseOutMarketPlate
      : onMouseOutPropertyPlate;

    const bgStrokeWidth = 1;
    let coordinatesXY =
      rectSideSize === GRAPH_DIMENSIONS.SIDE
        ? GRAPH_DIMENSIONS.SIDE_WITH_SHADOW / 2 - GRAPH_DIMENSIONS.SIDE / 2
        : GRAPH_DIMENSIONS.MARGIN;

    let shadowCoordinatesXY = coordinatesXY;

    if (propertyIsBigger) {
      const sizeDiff = GRAPH_DIMENSIONS.SIDE - rectSideSize + bgStrokeWidth;
      coordinatesXY += sizeDiff;

      shadowCoordinatesXY =
        coordinatesXY - GRAPH_DIMENSIONS.INNER_MARGIN_SHADOW;
    } else {
      coordinatesXY -= bgStrokeWidth;
    }

    svgRoot
      .append('rect')
      .attr(
        'id',
        propertyIsBigger ? GraphIds.MarketShadowId : GraphIds.PropertyShadowId,
      )
      .attr('rx', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('ry', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('x', shadowCoordinatesXY)
      .attr('y', shadowCoordinatesXY)
      .attr('width', rectSideSize + GRAPH_DIMENSIONS.INNER_MARGIN_SHADOW)
      .attr('height', rectSideSize + GRAPH_DIMENSIONS.INNER_MARGIN_SHADOW)
      .attr('fill', fillColor)
      .attr('opacity', 0.32)
      .style('display', 'none');

    svgRoot
      .append('rect')
      .attr('id', GraphIds.PropertyLayerId)
      .attr('x', coordinatesXY)
      .attr('y', coordinatesXY)
      .attr('rx', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('ry', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('width', rectSideSize)
      .attr('height', rectSideSize)
      .attr('fill', fillColor)
      .attr('stroke-width', 2)
      .style('box-shadow', BOX_SHADOW)
      .on('mouseover', onMouseOver)
      .on('mouseout', onMouseOut);
  };

  const drawBackgroundRect = (
    svgRoot: any,
    rectSideSize: number,
    propertyIsBigger: boolean,
  ) => {
    const fillColor = propertyIsBigger
      ? colors.primaryColor200
      : colors.ayGrassGreenColor;
    const onMouseOver = propertyIsBigger
      ? onMouseOverPropertyPlate
      : onMouseOverMarketPlate;
    const onMouseOut = propertyIsBigger
      ? onMouseOutPropertyPlate
      : onMouseOutMarketPlate;

    const coordinatesXY =
      GRAPH_DIMENSIONS.SIDE_WITH_SHADOW / 2 - GRAPH_DIMENSIONS.SIDE / 2;

    svgRoot
      .append('rect')
      .attr(
        'id',
        propertyIsBigger ? GraphIds.PropertyShadowId : GraphIds.MarketShadowId,
      )
      .attr('rx', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('ry', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', rectSideSize + GRAPH_DIMENSIONS.MARGIN_SHADOW)
      .attr('height', rectSideSize + GRAPH_DIMENSIONS.MARGIN_SHADOW)
      .attr('fill', fillColor)
      .attr('opacity', 0.32)
      .style('display', 'none');

    svgRoot
      .append('rect')
      .attr('id', GraphIds.ShadowLayerId)
      .attr('x', coordinatesXY)
      .attr('y', coordinatesXY)
      .attr('rx', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('ry', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('width', rectSideSize)
      .attr('height', rectSideSize)
      .attr('opacity', 0.24)
      .attr('fill', fillColor);

    svgRoot
      .append('rect')
      .attr('id', GraphIds.MarketLayerId)
      .attr('x', coordinatesXY)
      .attr('y', coordinatesXY)
      .attr('rx', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('ry', GRAPH_DIMENSIONS.BORDER_RADIUS)
      .attr('width', rectSideSize)
      .attr('height', rectSideSize)
      .attr('fill', 'url(#dotsPattern)')
      .style('stroke', fillColor)
      .attr('stroke-width', 2)
      .on('mouseover', onMouseOver)
      .on('mouseout', onMouseOut);
  };

  d3.select(`#${GraphIds.ContainerTitle}`)
    .on('mouseover', onMouseOverPropertyPlate)
    .on('mouseout', onMouseOutPropertyPlate);

  d3.select(`#${GraphIds.ContainerFooter}`)
    .on('mouseover', onMouseOverMarketPlate)
    .on('mouseout', onMouseOutMarketPlate);

  const drawChart = () => {
    if (!graphData) {
      return;
    }

    const { propertyRectSide, mktRectSide } = GraphSetupService.getProportionXY(
      {
        propertyType: property.propertyType?.name,
        avgFloorPlateSize: graphData.avgFloorPlateSize,
        propertyCoverageRatio: graphData.propertyCoverageRatio,
        marketAvgCoverageRatio: graphData.marketAvgCoverageRatio!,
        graphDimensionSide: GRAPH_DIMENSIONS.SIDE,
        floorPlateSize: getFieldValueForProperty('floorPlateSize', property),
      },
    );

    const propertyIsBigger = propertyRectSide > mktRectSide;

    const svgRoot = getSvgRoot();

    svgRoot
      .append('defs')
      .append('pattern')
      .attr('id', 'dotsPattern')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 8)
      .attr('height', 8)
      .append('circle')
      .attr('cx', 5)
      .attr('cy', 5)
      .attr('r', 1)
      .attr(
        'fill',
        propertyIsBigger ? colors.primaryColor200 : colors.ayGrassGreenColor,
      );

    const mktLineDiff = propertyIsBigger
      ? GRAPH_DIMENSIONS.SIDE_WITH_SHADOW - mktRectSide - propertyRectSide
      : 0;
    const strokeMargin = 3;

    svgRoot
      .append('line')
      .attr('id', GraphIds.MarketLineId)
      .attr('x1', GRAPH_DIMENSIONS.SIDE_WITH_SHADOW + GRAPH_DIMENSIONS.MARGIN)
      .attr('y1', GRAPH_DIMENSIONS.SIDE_WITH_SHADOW + GRAPH_DIMENSIONS.MARGIN)
      .attr(
        'x2',
        GRAPH_DIMENSIONS.SIDE +
          GRAPH_DIMENSIONS.MARGIN -
          strokeMargin +
          mktLineDiff,
      )
      .attr(
        'y2',
        GRAPH_DIMENSIONS.SIDE +
          GRAPH_DIMENSIONS.MARGIN -
          strokeMargin +
          mktLineDiff,
      )
      .attr('stroke-width', 2)
      .attr('stroke', colors.ayGrassGreenColor)
      .attr('stroke-linecap', 'square')
      .style('display', 'none');

    const shadowStrokeMargin = 1;
    svgRoot
      .append('line')
      .attr('id', GraphIds.PropertyLineId)
      .attr(
        'x1',
        GRAPH_DIMENSIONS.SIDE_WITH_SHADOW -
          GRAPH_DIMENSIONS.SIDE -
          GRAPH_DIMENSIONS.INNER_MARGIN_SHADOW +
          shadowStrokeMargin,
      )
      .attr(
        'y1',
        GRAPH_DIMENSIONS.SIDE_WITH_SHADOW -
          GRAPH_DIMENSIONS.SIDE -
          GRAPH_DIMENSIONS.INNER_MARGIN_SHADOW +
          shadowStrokeMargin,
      )
      .attr('x2', -GRAPH_DIMENSIONS.MARGIN)
      .attr('y2', -GRAPH_DIMENSIONS.MARGIN)
      .attr('stroke-width', 2)
      .attr('stroke', colors.primaryColor200)
      .attr('stroke-linecap', 'square')
      .style('display', 'none');

    const bgRectSize = propertyIsBigger ? propertyRectSide : mktRectSide;
    const frontRectSize = propertyIsBigger ? mktRectSide : propertyRectSide;

    drawBackgroundRect(svgRoot, bgRectSize, propertyIsBigger);
    drawFrontRect(svgRoot, frontRectSize, propertyIsBigger);

    return svgRoot.node();
  };

  useEffect(() => {
    if (isReady && graphData) {
      drawChart();
    }
    // eslint-disable-next-line
  }, [isReady]);

  return (
    <GraphContainer wrapperClassName={styles.container}>
      <div
        id={`${GraphIds.ContainerTitle}`}
        className={styles['title-container']}
      >
        <p className={styles['title']}>{graphData?.topTitle!}</p>
        <p className={styles['size']}>{graphData?.topLegend!}</p>
        <p className={styles['ceilings-columns']}>{graphData?.topSubLegend}</p>
      </div>

      <div className={styles['graph-container']} ref={graphContainerElement} />

      <div id={GraphIds.ContainerFooter} className={styles['footer-container']}>
        <p className={styles['title']}>{graphData?.bottomTitle}</p>
        <p className={styles['size']}>{graphData?.bottomLegend}</p>
        <p className={styles['ceilings-columns']}>
          {graphData?.bottomSubLegend}
        </p>
      </div>
    </GraphContainer>
  );
};

export default RhombusGraph;
