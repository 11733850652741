import { IdName } from 'interfaces/IdName';
import { PropertySources } from 'constants/propertySources';
import { TranslateFunction } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_ENUMERATORS } from 'constants/i18n';

const I18N_SOURCE_ENUM_PATH = `${I18N_AVANT_PROPERTY_ENUMERATORS}.sourceTypes`;

export const getSourceTypeOptions = (t: TranslateFunction): IdName[] => {
  const commonSourceTypes = [
    {
      id: PropertySources.QUOTE,
      name: t(`${I18N_SOURCE_ENUM_PATH}.quote`),
    },
    {
      id: PropertySources.ESTIMATE,
      name: t(`${I18N_SOURCE_ENUM_PATH}.estimate`),
    },
  ];

  return commonSourceTypes;
};
