import React from 'react';
import Button from 'components/Button';
import styles from './FormFooter.module.scss';
import classnames from 'classnames';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitDisabled?: boolean;
  isCancelDisabled?: boolean;
  submitButtonLabel?: string;
  wrapperClassName?: string;
}

const FormFooter: React.FC<Props> = ({
  onCancel,
  onSubmit,
  isSubmitDisabled,
  isCancelDisabled,
  submitButtonLabel,
  wrapperClassName,
}) => (
  <footer className={classnames(styles['form-footer'], wrapperClassName)}>
    <Button
      size="small"
      label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`)}
      type="neutral"
      onClick={onCancel}
      isDisabled={isCancelDisabled}
      wrapperClassName={styles['cancel-btn']}
    />

    <Button
      type="main"
      size="small"
      onClick={onSubmit}
      isDisabled={isSubmitDisabled}
      label={
        submitButtonLabel ||
        translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.submit`)
      }
      wrapperClassName={styles['submit-btn']}
    />
  </footer>
);

export default FormFooter;
