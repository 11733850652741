import { I18N_AVANT_PLATFORM_LEASE_LABEL_PATH } from 'constants/i18n';
import { RentTypes } from 'constants/rentTypes';

export const params = {
  [RentTypes.RENT_TYPE]: {
    label: `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.rentType`,
    field: 'rentType',
  },
  [RentTypes.FREE_RENT_TYPE]: {
    label: 'avantPlatform.attributes.lease.label.freeRentType',
    field: 'freeRentType',
  },
  [RentTypes.ASKING_RENT_TYPE]: {
    label: `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.rentType`,
    field: 'askingRentType',
  },
};
