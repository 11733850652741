import * as React from 'react';
import { useState } from 'react';
import Notification from 'components/Notification';

export type NotificationProps = {
  id?: string;
  onClose?: () => void;
  onTryAgain?: () => void;
  show: boolean | undefined;
  subtitle?: string;
  title: string;
  wrapperClassName?: string;
};

type NotificationProviderProps = { children: React.ReactNode };

// Update this to accept all Notification props, instead of just a message
export const NotificationContext = React.createContext<{
  notification?: NotificationProps;
  setNotification: (notification?: NotificationProps) => void;
}>({
  notification: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNotification: () => {},
});

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notification, setNotification] = useState<
    NotificationProps | undefined
  >();

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {children}

      {notification ? (
        <Notification
          {...notification}
          onClose={() => {
            notification?.onClose?.() || setNotification(undefined);
          }}
        />
      ) : null}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
