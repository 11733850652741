import React from 'react';
import { formatDate } from 'utils/formatters/date';
import styles from './SalesTableContainer.module.scss';
import { ITableCell } from 'interfaces/ITableCell';
import { IdName } from 'interfaces/IdName';
import { Link } from 'react-router-dom';
import locations from 'routes';
import {
  formatNumberWithCommas,
  formatNumberPercentage,
  formatPunctuationNumber,
} from 'utils/formatters/number';
import ISale from 'interfaces/ISale';
import { getPropertyAddress } from 'utils/formatters/property';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { getSaleFormattedFields, SaleField } from 'components/Sales/fields';
import { getFormattedPercent } from 'components/Sales/fields';
import { getCurrencySymbol } from 'utils/formatters/currency';
import {
  UnitOfMeasurement,
  UNITS_MEASUREMENT_AREA,
} from 'constants/unitOfMeasurement';
import tableViewStyles from 'components/TableView/TableView.module.scss';
import classnames from 'classnames';
import LinksList from 'components/LinksList';
import { isEmpty, isNil } from 'lodash';
import { convertBrokerInputToIdName } from 'utils/formatters/broker';
import { TranslateFunction } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_LABEL_SALES_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

export const saleTableHeader = (
  t: TranslateFunction,
  unitOfMeasurement: string | undefined,
  onlyMultifamilySelected: boolean,
  isSelectedUkCountryOrCity: boolean,
) => [
  {
    id: 'primaryAddress',
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.property`),
    accessor: (sale: ISale) => {
      const propertyAddress = getPropertyAddress(sale.property);
      const condoFloor = getSaleFormattedFields(sale, SaleField.Floor);
      const condoSuite = getSaleFormattedFields(sale, SaleField.Suite);
      const propertyPortfolio = getSaleFormattedFields(
        sale,
        SaleField.NumberOfBuildings,
      );

      return !!propertyAddress
        ? [propertyAddress, condoSuite, condoFloor, propertyPortfolio]
            .filter(Boolean)
            .join(` ${DASH_PLACEHOLDER} `)
        : '';
    },
    sticky: 'left',
    Cell: ({ cell }: ITableCell) => (
      <Link
        to={locations.showProperty(cell.row.original.property.id)}
        className={styles['cell-link']}
      >
        <div className={styles['cell-property']}>{cell.value}</div>
      </Link>
    ),
  },
  {
    id: 'date',
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.sellDate`),
    accessor: 'date',
    Cell: ({ cell: { value } }: ITableCell) => formatDate(value, 'MMM YYYY'),
  },
  {
    id: 'buyerName',
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.buyer`),
    accessor: 'buyers',
    Cell: ({ cell }: ITableCell) => {
      const buyers: IdName[] = cell.value;
      return !isEmpty(buyers) ? (
        <LinksList list={buyers} separator=", " />
      ) : null;
    },
  },

  {
    id: 'pricePerSF',
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.priceUnitOfMeasurement`, {
      unitOfMeasurement: onlyMultifamilySelected
        ? UnitOfMeasurement.pu
        : UNITS_MEASUREMENT_AREA[unitOfMeasurement || UnitOfMeasurement.sf],
    }),
    accessor: 'pricePerSF',
    Cell: ({ cell: { value, row } }: ITableCell) => {
      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      const price = onlyMultifamilySelected ? row.original.pricePerUnit : value;

      return !isNil(price) ? (
        <div className={tableViewStyles['number-field']}>
          <span className={classnames(styles['cell-price'])}>
            {currencySymbol + formatNumberWithCommas(price)}
          </span>
        </div>
      ) : null;
    },
  },
  {
    id: 'price',
    header: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.totalPrice`),
    accessor: 'price',
    Cell: ({ cell: { value, row } }: ITableCell) => {
      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      const formattedTotalPrice = formatPunctuationNumber(value);
      return (
        !!formattedTotalPrice && (
          <div
            className={tableViewStyles['number-field']}
          >{`${currencySymbol}${formattedTotalPrice}`}</div>
        )
      );
    },
  },
  {
    id: 'occupancy',
    header: t(`${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.occupancy`),
    accessor: 'occupancy',
    Cell: ({ cell: { value } }: ITableCell) =>
      !isNil(value) ? (
        <div className={tableViewStyles['number-field']}>
          {getFormattedPercent(value, undefined, true)}
        </div>
      ) : null,
  },
  {
    id: 'capRate',
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.capRate`),
    accessor: 'capRate',
    Cell: ({ cell: { value } }: ITableCell) => {
      if (isNil(value)) return null;

      return (
        <div className={tableViewStyles['number-field']}>
          {formatNumberPercentage(value * 100)}
        </div>
      );
    },
  },
  {
    id: 'interestPercent',
    header: t(`${I18N_AVANT_PROPERTY_LABEL_SALES_PATH}.partialInterest`),
    accessor: 'interestPercent',
    Cell: ({ cell: { value } }: ITableCell) => {
      if (isNil(value)) return null;

      return (
        <div className={tableViewStyles['number-field']}>
          {formatNumberPercentage(value * 100)}
        </div>
      );
    },
  },
  {
    id: 'partialInvVol',
    header: t(`${I18N_AVANT_PROPERTY_LABEL_SALES_PATH}.partialInvVolume`),
    accessor: 'partialInvVol',
    Cell: ({ cell: { value, row } }: ITableCell) => {
      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      const formattedTotalPrice = formatPunctuationNumber(value);

      return (
        <p
          className={classnames(
            styles['partial-inv-volume'],
            tableViewStyles['number-field'],
          )}
        >
          {!!formattedTotalPrice && `${currencySymbol}${formattedTotalPrice}`}
        </p>
      );
    },
  },
  {
    id: 'sellerName',
    header: t(`${I18N_AVANT_PROPERTY_LABEL_SALES_PATH}.seller`),
    accessor: 'sellers',
    Cell: ({ cell }: ITableCell) => {
      const sellers: IdName[] = cell.value;
      return !isEmpty(sellers) ? (
        <LinksList list={sellers} separator=", " />
      ) : null;
    },
  },
  ...(isSelectedUkCountryOrCity
    ? [
        {
          id: 'totalRent',
          header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.totalRent`),
          accessor: 'totalRent',
          Cell: ({ cell: { value, row } }: ITableCell) => {
            const currencySymbol = getCurrencySymbol(row.original.currencyCode);
            if (isNil(value)) return null;

            return (
              <div className={tableViewStyles['number-field']}>
                {`${currencySymbol}${formatNumberWithCommas(value)}`}
              </div>
            );
          },
        },
      ]
    : []),
  {
    id: 'sellersBrokers',
    header: t(`${I18N_AVANT_PROPERTY_LABEL_SALES_PATH}.sellersBrokers`),
    accessor: 'sellersBrokers',
    Cell: ({ cell }: ITableCell) => {
      const sellersBrokers: IdName[] = convertBrokerInputToIdName(cell.value);
      return !isEmpty(sellersBrokers) ? (
        <LinksList list={sellersBrokers} separator=", " />
      ) : null;
    },
  },
];
