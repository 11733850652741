import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { SwitchOption } from 'components/SwitchOptionButton';
import { PropertyBlockAvailabilities } from 'interfaces/IAvailability';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { PinTypes } from 'utils/maps/mapBox';

export type AskingRentMapProps = {
  propertySet?: IPropertySet;
  filters: LeaseToggleOptions;
  data?: PropertyBlockAvailabilities[];
  isCompSetMap?: boolean;
  toggleOptions?: SwitchOption[];
  mapToggleOption?: SwitchOption;
  setMapToggleOption?: (mapToggleOption: SwitchOption) => void;
  getPinLabel?: (
    data: any[],
    filters: LeaseToggleOptions,
    propertyId?: number,
    mapToggleOption?: SwitchOption,
  ) => string;
  pinType?: PinTypes;
  sectionTitle?: string;
};

export enum PropertyBlockAvailabilityFields {
  askingRentNNN = 'askingRentNNN',
  askingRentFS = 'askingRentFS',
  askingRentFSMonthly = 'askingRentFSMonthly',
  askingRentNNNMonthly = 'askingRentNNNMonthly',
  totalAvailability = 'totalAvailability',
}
