import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import {
  abbreviateNumber,
  formatNumberWithDecimals,
} from 'utils/formatters/number';
import { translateText } from 'utils/i18n';
import { capitalize } from 'lodash';
import { formatCurrency } from 'utils/formatters/currency';
import { IPropertySetLeasingMetadata } from 'interfaces/IPropertySetMetadata';
import { EMPTY_VALUE } from 'components/AgencyAssignments/constants';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { isMonthlyTimeMeasurement } from 'utils/leases';

export const parseInfoBoxesData = (
  {
    numberOfLeases,
    totalLeaseSize,
    averageLeaseSize,
    walt,
    ...leaseMetadata
  }: IPropertySetLeasingMetadata,
  { rentType, timeMeasurement }: LeaseToggleOptions,
  unitOfMeasurement?: string,
) => {
  const isMonthly = isMonthlyTimeMeasurement(timeMeasurement);
  const placeRentSuffix = `${rentType}${isMonthly ? timeMeasurement : ''}`;
  const placeRentValue = leaseMetadata[`placeRent${placeRentSuffix}`];
  return [
    {
      id: 'number-of-leases',
      title: '',
      details: [
        {
          title: capitalize(
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`),
          ),
          value: numberOfLeases ? numberOfLeases.toString() : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'total-lease-sf',
      title: '',
      details: [
        {
          title: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.totalUnitOfMeasurement`,
            { unitOfMeasurement: unitOfMeasurement || UnitOfMeasurement.sf },
          ),
          value: totalLeaseSize
            ? `${abbreviateNumber(
                totalLeaseSize,
              ) as string} ${unitOfMeasurement ||
                UnitOfMeasurement.sf}`.toUpperCase()
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'avg-lease-size',
      title: '',
      details: [
        {
          title: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.avgLeaseSize`,
          ),
          value: averageLeaseSize
            ? `${abbreviateNumber(
                averageLeaseSize,
              ) as string} ${unitOfMeasurement ||
                UnitOfMeasurement.sf}`.toUpperCase()
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'avg-current-rent',
      title: '',
      details: [
        {
          title: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.avgCurrentRent`,
          ),
          value: placeRentValue
            ? `${formatCurrency(placeRentValue, {})}`
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'total-walt',
      title: '',
      details: [
        {
          title: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.walt`,
          ).toUpperCase(),
          value: walt ? formatNumberWithDecimals(walt, 1) : EMPTY_VALUE,
        },
      ],
    },
  ];
};
