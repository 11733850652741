import classNames from 'classnames';
import AYLink from 'components/AYLink';
import ModalWrapper from 'components/ModalWrapper';
import { DOT } from 'constants/placeholders';
import { IMarket } from 'interfaces/IMarket';
import React from 'react';
import locations from 'routes';
import styles from './ModalMarketsLinks.module.scss';
import { t } from 'i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  isVisible: boolean;
  markets: IMarket[];
  onClose: () => void;
}

const ModalMarketsLinks: React.FC<Props> = ({
  isVisible,
  markets,
  onClose,
}) => {
  if (!isVisible) return null;

  const getLinkRow = (market: IMarket) => {
    const propertyMarketInfo = [market.country?.name];

    return (
      <div
        key={market.id}
        className={classNames(styles['property-row-container'])}
      >
        <div className={styles['property-row']}>
          <div>
            <AYLink
              wrapperClassName={styles.address}
              to={locations.showMarket(market?.id!)}
            >
              {market.name}
            </AYLink>
            <h3 className={styles.markets}>
              {propertyMarketInfo.filter(Boolean).join(` ${DOT} `)}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      onCancel={onClose}
      wrapperClassName={classNames(
        styles['modal-container'],
        styles['links-container'],
      )}
      header={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market_plural`)}
      withCloseButton
      noPadding
      large
    >
      <section className={styles.content}>{markets.map(getLinkRow)}</section>
    </ModalWrapper>
  );
};

export default ModalMarketsLinks;
