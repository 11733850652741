import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { IdName } from 'interfaces/IdName';
import { GET_SCOOP_CATEGORIES_QUERY } from 'graphql/scoops';
import DropdownFilter from 'components/DropdownFilter';
import styles from './ScoopsDropdownFilter.module.scss';
import { getScoopsDataFormattedForRendering } from 'utils/scoops';
import { translateText as t } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

type PropTypes = {
  defaultSelectedItems?: IdName[];
  onChange: (items: IdName[]) => void;
};

const I18N_EXPLORE_ACTIVITY_PATH = `avantProperties.pages.exploreActivity`;

const ScoopsDropdownFilter: React.FC<PropTypes> = ({
  defaultSelectedItems = [],
  onChange,
}) => {
  const [selectedItemsList, setSelectedItemsList] = useState<IdName[]>([]);

  useEffect(() => {
    setSelectedItemsList(defaultSelectedItems);
  }, [defaultSelectedItems]);

  const actionButtonParams = {
    isVisible: true,
    value: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.applySelection`),
    isDisabled: !selectedItemsList.length,
    onClick: () => onChange(selectedItemsList),
  };
  const selectAllParams = {
    isVisible: true,
    value: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.allTypes`),
    selectAllByDefault: (allItems: IdName[]) =>
      setSelectedItemsList(
        !!selectedItemsList.length ? selectedItemsList : allItems,
      ),
  };

  const dropdownButtonLabel =
    isEmpty(selectedItemsList) ||
    selectedItemsList.filter(item => item.id === 'all').length
      ? t(`${I18N_EXPLORE_ACTIVITY_PATH}.allScoopsType`)
      : selectedItemsList.length > 1
      ? t(`${I18N_PLATFORM_COMMON_WORD_PATH}.multipleCategories`)
      : selectedItemsList[0].name;

  return (
    <>
      <span className={styles['dropdown-label']}>
        {`${t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.filteringBy`)}:`}
      </span>
      <DropdownFilter
        isMultipleSelect
        groupBySubfield="type"
        shouldShowInput={false}
        label={dropdownButtonLabel}
        selectAllOption={selectAllParams}
        actionButton={actionButtonParams}
        selectedValue={selectedItemsList}
        itemsClassName={styles['dropdown-menu']}
        graphqlQuery={GET_SCOOP_CATEGORIES_QUERY}
        wrapperClassName={styles['dropdown-button']}
        mapper={data =>
          getScoopsDataFormattedForRendering(data.scoopCategories, t)
        }
        onChange={(selectedItems: IdName[]) => {
          setSelectedItemsList(selectedItems);
        }}
      />
    </>
  );
};

export default ScoopsDropdownFilter;
