import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  ILeaseAnalyticsConcessionResponse,
  LeaseAnalyticsConcessionType,
} from 'interfaces/ILeaseAnalyticsConcession';
import { ILeasesAnalyticsExpirationsResponse } from 'interfaces/ILeasesAnalyticsExpiration';
import { LEASES_ANALYTICS_EXPIRATIONS } from '../graphql/analyticsFindComps';
import { LEASES_ANALYTICS_CONCESSION_QUERY } from 'graphql/leases/queries';
import { IProperty } from 'interfaces/IProperty';
import { IHistoricalLeasingData } from 'interfaces/IHistoricalLeasingData';
import { LEASES_ANALYTICS_MOST_RECENT_LEASES } from 'graphql/analyticsFindComps/queries';
import { PROPERTIES_ANALYTICS_ABSORPTION } from '../graphql/propertiesAnalytics';
import { IAbsorptionChartData } from '../interfaces/IAbsorptionChartData';
import { getYearsToQuery } from 'components/AbsorptionChart/utils';

export const HISTORICAL_LEASING_YEARS = 5;
export const HISTORICAL_LEASING_YEARS_BY_YEAR = 20;
export const MOST_RECENT_LEASES_PAST_YEARS = 20;
export const LEASE_EXPIRATIONS_YEARS = 6;

type PropertiesHistoricalLeasingType = {
  leasingConcessionData?: {
    leasesAnalyticsConcession: ILeaseAnalyticsConcessionResponse;
  };
  isLoadingLeasingConcession: boolean;
  hasConcessionData: boolean;
  leasesExpirationData?: {
    leasesAnalyticsExpirations?: ILeasesAnalyticsExpirationsResponse;
  };
  isLoadingLeasesExpirationData: boolean;
  hasLeasesExpirationData: boolean;
  mostRecentLeasesData?: {
    leasesAnalyticsMostRecentLeases: IHistoricalLeasingData;
  };
  isLoadingMostRecentLeases: boolean;
  hasMostRecentLeasesData: boolean;
  absorptionData?: {
    propertiesAnalyticsAbsorption?: IAbsorptionChartData[];
  };
  isLoadingAbsorptionData: boolean;
};

/**
 * usePropertiesLeasesAnalytics
 * --------------------------------------------------------------------------
 * @description using this hook we will be able to fetch Leasing Concession, Leases Expiration and
 * Most recent Leases data from leasesAnalyticsConcession, leasesAnalyticsExpirations and
 * leasesAnalyticsMostRecentLeases queries passing a list of properties as parameter.
 */
export const usePropertiesLeasesAnalytics = (
  properties?: IProperty[],
  periodType?: string,
): PropertiesHistoricalLeasingType => {
  const propertiesIds = properties?.map(property => property.id) || [];
  const skip = !properties?.length;

  const {
    startYear: absorptionStartYear,
    endYear: absorptionEndYear,
  } = getYearsToQuery({
    isQuarterly: periodType === LeaseAnalyticsConcessionType.QUARTER,
  });

  const currentYear = new Date().getFullYear();
  const concessionYears =
    periodType === LeaseAnalyticsConcessionType.QUARTER
      ? HISTORICAL_LEASING_YEARS
      : HISTORICAL_LEASING_YEARS_BY_YEAR;
  const {
    data: leasingConcessionData,
    loading: isLoadingLeasingConcession,
  } = useQuery<{
    leasesAnalyticsConcession: ILeaseAnalyticsConcessionResponse;
  }>(LEASES_ANALYTICS_CONCESSION_QUERY, {
    variables: {
      search: {
        filter: {
          propertiesIds,
        },
        startYear: currentYear - concessionYears,
        endYear: currentYear,
        type: periodType ?? LeaseAnalyticsConcessionType.QUARTER,
      },
    },
    fetchPolicy: 'network-only',
    skip,
  });

  const hasConcessionData = useMemo(() => {
    return !!leasingConcessionData?.leasesAnalyticsConcession?.slices?.length;
  }, [leasingConcessionData]);

  const expirationYearsByPeriodType =
    periodType === LeaseAnalyticsConcessionType.QUARTER
      ? LEASE_EXPIRATIONS_YEARS
      : 10;

  const {
    data: leasesExpirationData,
    loading: isLoadingLeasesExpirationData,
  } = useQuery<{
    leasesAnalyticsExpirations: ILeasesAnalyticsExpirationsResponse;
  }>(LEASES_ANALYTICS_EXPIRATIONS, {
    variables: {
      search: {
        filter: {
          propertiesIds,
        },
        startYear: currentYear,
        endYear: currentYear + expirationYearsByPeriodType,
        periodType: periodType ?? LeaseAnalyticsConcessionType.QUARTER,
      },
    },
    skip,
  });

  const hasLeasesExpirationData = useMemo(() => {
    return !!leasesExpirationData?.leasesAnalyticsExpirations?.items?.length;
  }, [leasesExpirationData]);

  const {
    data: mostRecentLeasesData,
    loading: isLoadingMostRecentLeases,
  } = useQuery<{
    leasesAnalyticsMostRecentLeases: IHistoricalLeasingData;
  }>(LEASES_ANALYTICS_MOST_RECENT_LEASES, {
    variables: {
      search: {
        filter: {
          propertiesIds,
          overlapIds: true,
        },
        numberOfPastYears: MOST_RECENT_LEASES_PAST_YEARS,
        includeYearStart: true,
        periodType: periodType ?? LeaseAnalyticsConcessionType.QUARTER,
      },
    },
    skip: skip,
  });

  const hasMostRecentLeasesData = useMemo(() => {
    return (
      !!mostRecentLeasesData?.leasesAnalyticsMostRecentLeases?.historicalLeases
        ?.items?.length ||
      !!mostRecentLeasesData?.leasesAnalyticsMostRecentLeases?.historicalAverage
        ?.averages?.length
    );
  }, [mostRecentLeasesData]);

  const { data: absorptionData, loading: isLoadingAbsorptionData } = useQuery(
    PROPERTIES_ANALYTICS_ABSORPTION,
    {
      skip: skip,
      variables: {
        search: {
          filter: {
            ids: propertiesIds,
          },
          startYear: absorptionStartYear,
          endYear: absorptionEndYear,
          type: periodType ?? LeaseAnalyticsConcessionType.QUARTER,
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  return {
    mostRecentLeasesData,
    isLoadingMostRecentLeases,
    hasMostRecentLeasesData,
    leasingConcessionData,
    isLoadingLeasingConcession,
    hasConcessionData,
    leasesExpirationData,
    isLoadingLeasesExpirationData,
    hasLeasesExpirationData,
    absorptionData,
    isLoadingAbsorptionData,
  };
};
