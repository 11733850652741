import React, { ReactNode } from 'react';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './Badge.module.scss';
import { colors } from 'constants/colors';

interface Props {
  icon?: string;
  color?: string;
  bgColor?: string;
  iconSize?: number;
  wrapperClassName?: string;
  height?: string;
  children?: ReactNode;
}

// TODO: add flexibility. It is being done for a specific use case.
const Badge: React.FC<Props> = props => (
  <section
    style={{
      backgroundColor: `${props?.bgColor || colors.ayWhiteColor}`,
      maxHeight: `${props.height || '1.5rem'}`,
      height: `${props.height}`,
    }}
    className={classnames(props.wrapperClassName, styles['badge'])}
  >
    {props.icon && (
      <Icon
        name={props.icon}
        size={props.iconSize}
        color={props.color}
        className={styles['badge-icon']}
      />
    )}
    {props.children}
  </section>
);

export default Badge;
