import { gql } from '@apollo/client';

export const BUILD_OUT_TYPES_QUERY = gql`
  query buildOutTypes {
    buildOutTypes {
      id
      name
    }
  }
`;
