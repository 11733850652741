import { gql } from '@apollo/client';
import { PROPERTY_COMPLETE_DATA_FRAGMENT } from 'graphql/property/fragments';

export const PROPERTY_SET_FRAGMENT = gql`
  fragment propertySetAttributes on PropertySet {
    id
    name
    userId
    fileId
    createdAt
    type
    targetTenant {
      id
      name
      headquarters
      industry {
        id
        name
      }
    }
    targetProperty {
      ...propertyCompleteDataFragment
    }
    properties {
      id
      order
      name
      featured
      primaryAddress
      displayAddress
      latitude
      longitude
      latitudeDisplay
      longitudeDisplay
      coverMediaUrl
      coverMediaType
      propertyType {
        id
        name
      }
      propertySubtype {
        id
        name
      }
      propertyClass {
        id
        name
      }
      market {
        id
        name
      }
      submarket {
        id
        name
      }
      micromarket {
        id
        name
      }
    }
    metadata {
      propertySetId
      propertyId
      availabilitiesCount
      rentRangeMin
      rentRangeMax
      sizeRangeMin
      sizeRangeMinMt
      sizeRangeMax
      sizeRangeMaxMt
    }
    user {
      id
      firstName
      lastName
      email
      profileImageUrl
    }
    sharedUsers {
      id
      email
      profileImageUrl
    }
    owners {
      id
    }
  }
  ${PROPERTY_COMPLETE_DATA_FRAGMENT}
`;
