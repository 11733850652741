import { gql } from '@apollo/client';
import {
  PROPERTY_BASIC_DATA_FRAGMENT,
  PROPERTY_COMPLETE_DATA_FRAGMENT,
  PROPERTY_COMP_SET_FRAGMENT,
  PROPERTY_COMPLETE_DATA_FRAGMENT_WITHOUT_COMMENTS,
} from 'graphql/property/fragments';

export const GET_PROPERTY_QUERY = gql`
  query getProperty($id: ID!) {
    property(id: $id, options: { onlyCoverImage: true }) {
      ...propertyCompleteDataFragment
    }
  }
  ${PROPERTY_COMPLETE_DATA_FRAGMENT}
`;

export const GET_PROPERTY_QUERY_WITHOUT_COMMENTS = gql`
  query getProperty($id: ID!) {
    property(id: $id, options: { onlyCoverImage: true }) {
      ...propertyCompleteDataFragment
    }
  }
  ${PROPERTY_COMPLETE_DATA_FRAGMENT_WITHOUT_COMMENTS}
`;

export const GET_PROPERTY_BASIC_DATA_QUERY = gql`
  query getProperty($id: ID!) {
    property(id: $id, options: { onlyCoverImage: true }) {
      ...propertyBasicDataFragment
    }
  }
  ${PROPERTY_BASIC_DATA_FRAGMENT}
`;

export const PROPERTY_CLASS_PARENTS_QUERY = gql`
  query propertyClassParents {
    propertyClassParents {
      name
      children {
        id
        name
        parent
      }
    }
  }
`;

export const SEARCH_PROPERTY_TYPE_BY_COMPANY_QUERY = gql`
  query propertyTypesAggregated($search: AggregatedPropertySearchInput!) {
    propertyTypesAggregated(search: $search) {
      text
      count
      size
      sizeMt
      measurementSystem
    }
  }
`;

export const SEARCH_PROPERTY_CLASS_BY_COMPANY_QUERY = gql`
  query propertyClassesAggregated($search: AggregatedPropertySearchInput!) {
    propertyClassesAggregated(search: $search) {
      text
      count
      size
      sizeMt
      measurementSystem
    }
  }
`;

export const GET_PROPERTIES_QUERY = gql`
  query properties($search: PropertySearchInput) {
    properties(search: $search, options: { onlyCoverImage: true }) {
      results {
        ...propertyBasicDataFragment
      }
    }
  }
  ${PROPERTY_BASIC_DATA_FRAGMENT}
`;

export const PROPERTY_TYPES_ENTITIES_QUERY = gql`
  query propertyTypesEntities {
    propertyTypesEntities {
      id
      name
    }
  }
`;

export const GET_PROPERTY_TYPE_QUERY = gql`
  query getPropertyType($id: Int!) {
    propertyType(id: $id) {
      id
      name
    }
  }
`;

export const PROPERTY_TENANCY_TYPES = gql`
  query tenancyTypes {
    tenancyTypes {
      id
      name
    }
  }
`;

export const PROPERTY_SUBTYPES_QUERY = gql`
  query getPropertySubtypes($search: PropertySubtypeSearch) {
    propertySubtypes(search: $search) {
      id
      name
      type
    }
  }
`;

export const PROPERTY_SUBTYPEDETAILS_QUERY = gql`
  query getPropertySubtypeDetails($search: PropertySubtypeDetailSearchInput) {
    propertySubtypeDetails(search: $search) {
      results {
        id
        name
        propertySubtype
      }
    }
  }
`;

export const PROPERTY_STATUSES_QUERY = gql`
  query propertyStatuses {
    propertyStatuses {
      id
      name
    }
  }
`;

export const PROPERTY_LOCATION_TYPES_QUERY = gql`
  query locationTypes {
    locationTypes {
      id
      name
    }
  }
`;

export const PROPERTY_OWNER_OCCUPIED_DATA = gql`
  query ownerOccupied {
    ownerOccupied {
      id
      name
    }
  }
`;

export const PROPERTY_CLASSES_QUERY = gql`
  query propertyClasses {
    propertyClasses {
      id
      name
      parent
    }
  }
`;

export const SEARCH_PROPERTIES_QUERY = gql`
  query properties($search: PropertySearchInput!) {
    properties(search: $search, options: { onlyCoverImage: true }) {
      total
      totalPages
      pageSize
      currentPage
      results {
        ...propertyBasicDataFragment
      }
    }
  }
  ${PROPERTY_BASIC_DATA_FRAGMENT}
`;

export const GET_PROPERTIES_COORDINATES_QUERY = gql`
  query properties($search: PropertySearchInput!) {
    properties(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        id
        latitudeDisplay
        longitudeDisplay
        latitude
        longitude
        buildingSize
        owners {
          id
        }
        market {
          id
        }
      }
    }
  }
`;

export const GET_PROPERTIES_GEOLOCATION_QUERY = gql`
  query getPropertiesGeolocation($search: PropertySearchInput) {
    properties(search: $search) {
      results {
        id
        latitude
        longitude
        latitudeDisplay
        longitudeDisplay
        buildingSize
        buildingSizeMt
      }
    }
  }
`;

export const SEARCH_PROPERTIES_COMPLETE_QUERY = gql`
  query properties($search: PropertySearchInput!) {
    properties(search: $search, options: { onlyCoverImage: true }) {
      total
      totalPages
      pageSize
      currentPage
      results {
        ...propertyCompleteDataFragment
      }
    }
  }
  ${PROPERTY_COMPLETE_DATA_FRAGMENT}
`;

export const SEARCH_PROPERTIES_COMP_SET_QUERY = gql`
  query properties($search: PropertySearchInput!) {
    properties(search: $search, options: { onlyCoverImage: true }) {
      total
      totalPages
      pageSize
      currentPage
      results {
        ...propertyCompSetFragment
      }
    }
  }
  ${PROPERTY_COMP_SET_FRAGMENT}
`;

export const SEARCH_PROPERTY_INDUSTRIES_QUERY = gql`
  query searchIndustriesOfProperty($search: IndustriesOfPropertyInput) {
    searchIndustriesOfProperty(search: $search) {
      industry {
        id
        name
      }
      tenants
      floors
      totalSF
      totalSquareMeters
      measurementSystem
    }
  }
`;

export const GRAPH_OWNER_PORTFOLIO_PROPERTIES_QUERY = gql`
  query graphOwnerPortfolioProperties($input: GraphOwnerPortfolioInput!) {
    graphOwnerPortfolioProperties(input: $input) {
      totalSize
      totalSizeMt
      properties {
        id
        size
        name
        sizeMt
        market
        marketId
        ownerIds
        submarket
        submarketId
        propertyType
        propertyClass
        primaryAddress
        measurementSystem
        coverMediaUrl
        latitude
        longitude
      }
    }
  }
`;

export const PROPERTY_TYPES_QUERY = gql`
  query propertyTypes {
    propertyTypes
  }
`;

export const GET_RECOMMENDATIONS_QUERY = gql`
  query getRecommendations($search: PropertySearchInput) {
    properties(search: $search, options: { onlyCoverImage: true }) {
      results {
        id
        primaryAddress
        displayAddress
        currencyCode
        measurementSystem
        name
        country
        latitude
        longitude
        latitudeDisplay
        longitudeDisplay
        market {
          id
          name
        }
        submarket {
          id
          name
        }
        micromarket {
          id
          name
        }
        coverMediaUrl
      }
    }
  }
`;

export const GRAPH_BUILDING_SIZE_QUERY = gql`
  query graphBuildingSize($propertyId: ID!, $propertySetId: Int) {
    graphBuildingSize(propertyId: $propertyId, propertySetId: $propertySetId) {
      properties {
        id
        name
        status
        market
        stories
        submarket
        micromarket
        buildingSize
        propertyClass
        buildingSizeMt
        primaryAddress
        propertyTypeName
        measurementSystem
      }
      totalBuildingSize
      totalBuildingSizeMt
      totalStories
    }
  }
`;

export const GRAPH_COVERAGE_RATIO = gql`
  query graphCoverageRatio($propertyId: ID!, $propertySetId: Int) {
    graphCoverageRatio(propertyId: $propertyId, propertySetId: $propertySetId) {
      marketAvgCoverageRatio
      marketAvgFloorPlateSize
      marketAvgFloorPlateSizeMt
      propertyCoverageRatio
      propertyFloorPlateSize
      propertyFloorPlateSizeMt
      industrial {
        modeClearHeight
        modeSprinklers
      }
      office {
        avgColumnSpacingMax
        avgColumnSpacingMin
        avgColumnSpacingMaxMt
        avgColumnSpacingMinMt
        avgTypicalCeilingHeightFeet
        avgTypicalCeilingHeightInches
        avgTypicalCeilingHeightMt
      }
      lifeScience {
        avgColumnSpacingMax
        avgColumnSpacingMin
        avgColumnSpacingMaxMt
        avgColumnSpacingMinMt
        avgTypicalCeilingHeightFeet
        avgTypicalCeilingHeightInches
        avgTypicalCeilingHeightMt
      }
      dataCenter {
        avgTotalPower
      }
      multifamily {
        avgUnitSizeSf
        avgUnitSizeMt
        totalUnits
        avgTotalUnits
      }
    }
  }
`;

export const GRAPH_AVERAGE_BUILT_YEAR = gql`
  query graphAverageBuiltYear($propertyId: ID!, $propertySetId: Int) {
    graphAverageBuiltYear(
      propertyId: $propertyId
      propertySetId: $propertySetId
    )
  }
`;

export const SEARCH_COMPETITIVE_PROPERTIES_QUERY = gql`
  query properties($search: PropertySearchInput!) {
    properties(search: $search, options: { onlyCoverImage: true }) {
      results {
        id
        name
        primaryAddress
        secondaryAddress
        tertiaryAddress
        quaternaryAddress
        numberOfAlternativeAddresses
        displayAddress
        country
        latitude
        longitude
        latitudeDisplay
        longitudeDisplay
        market {
          id
          name
        }
        submarket {
          id
          name
        }
        micromarket {
          id
          name
        }
        propertyClass {
          id
          name
        }
        status {
          id
          name
        }
        type
        coverMediaUrl
        coverMediaType
        propertyType {
          id
          name
        }
        propertySubtype {
          id
          name
        }
        buildingSize
        buildingSizeMt
        buildDate
        renovatedYears {
          year
        }
        propertyCountry {
          areaMeasurement
        }
      }
    }
  }
`;

export const GET_PROPERTY_DOCUMENTS_QUERY = gql`
  query documents($propertyId: Int!) {
    documents(propertyId: $propertyId) {
      id
      url
      name
      createdAt
      createdUser {
        id
        firstName
        lastName
      }
      updatedUser {
        id
        firstName
        lastName
      }
      category {
        id
        name
      }
    }
  }
`;

export const GET_PROPERTY_MARKET_DATA = gql`
  query getMarketHierarchy(
    $latitude: Float!
    $longitude: Float!
    $propertyTypeId: Int
  ) {
    marketHierarchy(
      latitude: $latitude
      longitude: $longitude
      propertyTypeId: $propertyTypeId
    ) {
      market {
        id
        name
      }
      submarket {
        id
        name
      }
      micromarket {
        id
        name
      }
    }
  }
`;

export const GET_WALKSCORE_DATA = gql`
  query walkscore($search: WalkscoreInput) {
    walkscore(search: $search) {
      id
      walkScore
      bikeScore
      transitScore
      wsLink
    }
  }
`;

export const GET_PROPERTY_AVAILABILITY_SPACE_DATA = gql`
  query availabilitySpace($propertyId: ID!) {
    availabilitySpace(propertyId: $propertyId) {
      totalAvailable
      totalDirectAvailableSf
      totalSubletAvailableSf
      average
      compSetAverage
    }
  }
`;

export const GET_PROPERTY_BUILDING_INFO = gql`
  query getPropertyBuildingInfo(
    $propertyId: Int!
    $propertySetId: Int
    $propertyIdId: ID!
    $propertyTypeIds: [Int]!
  ) {
    documents(propertyId: $propertyId) {
      id
      url
      name
      createdAt
      createdUser {
        id
        firstName
        lastName
      }
      updatedUser {
        id
        firstName
        lastName
      }
      category {
        id
        name
      }
    }
    graphCoverageRatio(
      propertyId: $propertyIdId
      propertySetId: $propertySetId
    ) {
      marketAvgCoverageRatio
      marketAvgFloorPlateSize
      marketAvgFloorPlateSizeMt
      propertyCoverageRatio
      propertyFloorPlateSize
      propertyFloorPlateSizeMt
      industrial {
        modeClearHeight
        modeSprinklers
      }
      office {
        avgColumnSpacingMax
        avgColumnSpacingMin
        avgColumnSpacingMaxMt
        avgColumnSpacingMinMt
        avgTypicalCeilingHeightFeet
        avgTypicalCeilingHeightInches
        avgTypicalCeilingHeightMt
      }
      lifeScience {
        avgColumnSpacingMax
        avgColumnSpacingMin
        avgColumnSpacingMaxMt
        avgColumnSpacingMinMt
        avgTypicalCeilingHeightFeet
        avgTypicalCeilingHeightInches
        avgTypicalCeilingHeightMt
      }
      dataCenter {
        avgTotalPower
      }
      multifamily {
        avgUnitSizeSf
        avgUnitSizeMt
        totalUnits
        avgTotalUnits
      }
    }
    graphBuildingSize(
      propertyId: $propertyIdId
      propertySetId: $propertySetId
    ) {
      properties {
        id
        name
        status
        market
        stories
        submarket
        micromarket
        buildingSize
        propertyClass
        buildingSizeMt
        primaryAddress
        propertyTypeName
        measurementSystem
      }
      totalBuildingSize
      totalBuildingSizeMt
      totalStories
    }
    graphAverageBuiltYear(
      propertyId: $propertyIdId
      propertySetId: $propertySetId
    )
    amenities(isApproved: true) {
      ... on Amenity {
        id
        name
      }
    }
    propertyTypeAmenities: amenities(
      isApprovedForPropertyType: true
      propertyTypeIds: $propertyTypeIds
    ) {
      ... on Amenity {
        id
        name
      }
    }
  }
`;
