import { gql } from '@apollo/client';
import { PROPERTY_SET_FRAGMENT } from 'graphql/propertySet/fragments';
import { PROJECT_LINK_FRAGMENT } from 'graphql/projects';

export const GET_PROPERTY_SET_QUERY = gql`
  query getPropertySet($id: ID!) {
    propertySet(id: $id) {
      ...propertySetAttributes
    }
  }
  ${PROPERTY_SET_FRAGMENT}
`;

export const GET_PROPERTY_SETS_QUERY = gql`
  query getPropertySets($search: PropertySetSearchInput!) {
    propertySets(search: $search) {
      id
      name
      type
      properties {
        featured
        id
        name
        primaryAddress
        displayAddress
        latitude
        longitude
        latitudeDisplay
        longitudeDisplay
        coverMediaUrl
        coverMediaType
        market {
          id
          name
        }
        submarket {
          id
          name
        }
        micromarket {
          id
          name
        }
      }
      user {
        id
        firstName
        lastName
        email
        profileImageUrl
      }
      sharedUsers {
        id
        firstName
        lastName
        email
        profileImageUrl
      }
      userId
      targetProperty {
        id
        name
        primaryAddress
        displayAddress
        coverMediaType
        coverMediaUrl
        builtYear
        buildingSizeMt
        buildingSize
        buildDate
        floorPlateSize
        floorPlateSizeMt
        latitude
        longitude
        latitudeDisplay
        longitudeDisplay
        amenities {
          id
          name
        }
        leasingCompany {
          id
          name
          website
          logoUrl
        }
        managementCompany {
          id
          name
          website
          logoUrl
        }
        office {
          columnSpacingMax
          columnSpacingMaxMt
          columnSpacingMin
          columnSpacingMinMt
          typicalCeilingHeightFeet
          typicalCeilingHeightInches
          typicalCeilingHeightMt
        }
        owners {
          id
          name
        }
        market {
          id
          name
        }
        submarket {
          id
          name
        }
        propertyClass {
          id
          name
        }
        propertyType {
          id
          name
        }
        type
      }
      targetTenant {
        id
        name
        headquarters
        website
        logoUrl
        logoId
        industry {
          id
          name
        }
      }
      createdAt
      metadata {
        propertySetId
        propertyId
        availabilitiesCount
        rentRangeMin
        rentRangeMax
        sizeRangeMin
        sizeRangeMinMt
        sizeRangeMax
        sizeRangeMaxMt
      }
      projects {
        ...projectLink
      }
    }
  }
  ${PROJECT_LINK_FRAGMENT}
`;
