import React from 'react';
import styles from './LatLonInput.module.scss';
import { formatCoordinateValue } from './formatCoordinateValue';

interface Props {
  lat: number | undefined;
  lon: number | undefined;
}

const LatLonInput: React.FC<Props> = props => (
  <div className={styles.container}>
    <span>{formatCoordinateValue('lat', props.lat)}</span>
    <span className={styles.separator}>/</span>
    <span>{formatCoordinateValue('lon', props.lon)}</span>
  </div>
);

export default LatLonInput;
