import { ApolloLink } from '@apollo/client';
import { getUserLocaleKey } from 'utils/i18n';
import AuthService from '../services/Auth';

export const getUserId = () => {
  const user = AuthService.getLocalUser();

  return user?.id || '';
};

export const getLanguageId = () => {
  return getUserLocaleKey();
};

export const authHeaders = new ApolloLink((operation, forward) => {
  const authToken = AuthService.getToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
      'user-id': getUserId(),
    },
  }));

  return forward(operation);
});
export const authHeadersOkta = new ApolloLink((operation, forward) => {
  const isAuthReinforced = process.env['IS_OKTA_AUTH_REINFORCED'] === 'true';
  const authToken = AuthService.getToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(isAuthReinforced && {
        'x-api-token': AuthService.getOktaToken('users-api'),
      }),
      ...(isAuthReinforced && { 'ay-api-auth': 'OKTA' }),
      ...(!isAuthReinforced && {
        authorization: authToken ? `Bearer ${authToken}` : '',
      }),
      'user-id': getUserId(),
    },
  }));

  return forward(operation);
});

export const propertiesAuthContext = new ApolloLink((operation, forward) => {
  const apiKey = window._env_.REACT_APP_GRAPHQL_API_KEY;
  const isAuthReinforced = process.env['IS_OKTA_AUTH_REINFORCED'] === 'true';
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(!isAuthReinforced && {
        authorization: apiKey ? `Bearer ${apiKey}` : '',
      }),
      ...(isAuthReinforced && {
        'x-api-token': AuthService.getOktaToken('properties-api'),
      }),
      ...(isAuthReinforced && { 'ay-api-auth': 'OKTA' }),
      'user-id': getUserId(),
      'application-from': 'web',
      'language-id': getLanguageId(),
    },
  }));

  return forward(operation);
});

export const geospatialAuthContext = new ApolloLink((operation, forward) => {
  const apiKey = window._env_.GEOSPATIAL_API_KEY;
  const isAuthReinforced = process.env['IS_OKTA_AUTH_REINFORCED'] === 'true';
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(!isAuthReinforced && {
        authorization: apiKey ? `Bearer ${apiKey}` : '',
      }),
      ...(isAuthReinforced && {
        'x-api-token': AuthService.getOktaToken('geospatial-api'),
      }),
      ...(isAuthReinforced && { 'ay-api-auth': 'OKTA' }),
      'user-id': getUserId(),
      'application-from': 'web',
      'language-id': getLanguageId(),
    },
  }));

  return forward(operation);
});
