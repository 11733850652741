import { parseCurrencyToNumber } from 'utils/formatters/currency';

export const convertToNumber = (
  value: string | undefined | null | number,
): number | undefined | null => {
  if (value === null || value === undefined || typeof value === 'number') {
    return value;
  }

  const valueAsNumber = parseCurrencyToNumber(value);
  const isUndefined = value === '' || isNaN(valueAsNumber);
  return isUndefined ? undefined : valueAsNumber;
};
