import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { authContext } from 'contexts/AuthContext';
import { GENERATE_POWER_POINT } from 'graphql/files/mutations';
import { IModalInfo, MODALS } from 'interfaces/IModal';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
} from 'constants/i18n';

interface LeasesPPTProps {
  leasesIds?: number[];
  setActiveModal: (value: MODALS | null) => void;
  setModalInfo: (value: IModalInfo) => void;
}

export const useLeasesPPT = ({
  leasesIds,
  setActiveModal,
  setModalInfo,
}: LeasesPPTProps) => {
  const client = useApolloClient();
  const auth = useContext(authContext);
  const { user } = auth;
  const variables = {
    params: {
      ids: leasesIds || [],
      fileType: 'LEASES',
      email: user.email,
    },
  };

  const generateLeasesPPT = async () => {
    try {
      const { data } = await client.mutate<any>({
        mutation: GENERATE_POWER_POINT,
        variables,
      });

      if (data!.createPowerPoint === 'Created') {
        setModalInfo({
          title: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.generatePowerPoint`,
          ),
          description: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.emailingPowerPoint`,
          ),
          button: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.gotIt`,
          ),
        });
        setActiveModal(MODALS.NOTIFICATION);
      }
    } catch (e) {
      setModalInfo({
        title: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.generatePowerPoint`,
        ),
        description: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.unexpectedErrorMessage`,
        ),
        button: translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.gotIt`),
      });
      setActiveModal(MODALS.NOTIFICATION);
    }
  };

  return {
    generateLeasesPPT,
  };
};
