import React from 'react';
import { getMultifamilyFields } from './multifamilyMapping';
import FormField from 'components/FormControl/FormField';
import { ExclusiveFieldProps } from './ExclusiveFields';

const MultifamilyFields: React.FC<ExclusiveFieldProps> = ({
  classNames: { formControlClassName, inputHelperClassName },
  onChange,
  property,
  property: { multifamily },
}) => (
  <>
    {getMultifamilyFields(property).map(fieldConfig => (
      <FormField
        key={fieldConfig.field || fieldConfig.labelI18nKey}
        onChange={onChange}
        dataObject={multifamily || {}}
        fieldConfig={fieldConfig}
        formControlClassName={formControlClassName}
        inputHelperClassName={inputHelperClassName}
      />
    ))}
  </>
);

export default MultifamilyFields;
