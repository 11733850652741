import React from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import styles from './DragAndDrop.module.scss';

export interface Item {
  id?: number;
}

interface DraggableBaseProps<T extends Item> {
  renderItem: (value: T, index: number) => React.ReactNode;
  value: T;
  index: number;
}

export const DraggableItem = SortableElement(
  <T extends Item>(props: DraggableBaseProps<T>) => {
    const { renderItem, value, index } = props;

    const DraggableElement = SortableHandle(() => renderItem(value, index));

    return (
      <div className={styles.item}>
        <div className={styles.content}>
          <DraggableElement />
        </div>
      </div>
    );
  },
);
