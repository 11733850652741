import React, { useEffect } from 'react';
import { capitalize } from 'lodash';
import styles from './ComparativeColumns.module.scss';
import ComparativeCard from 'components/PropertyProfile/Sections/Comparative/ComparativeCard';
import { Column } from 'components/PropertyProfile/Sections/Comparative/constants';
import { useQuery } from '@apollo/client';
import { SEARCH_PROPERTY_INDUSTRIES_QUERY } from 'graphql/property';
import { IPropertyIndustry } from 'components/Graphs/TenantIndustriesContainer/interfaces';
import SpinnerLoader from 'components/SpinnerLoader';
import { ColorNames } from 'constants/colorNames';
import { DOT, EMPTY_SPACE } from 'constants/placeholders';
import classnames from 'classnames';
import { formatArea } from 'utils/formatters/area';
import { PropertyTopIndustriesContainer } from 'components/Graphs';
import {
  isTopIndustry,
  isSecondTopIndustry,
  isThirdTopIndustry,
} from 'components/Graphs/PropertyTopIndustriesContainer/utils';
import InformationRow from './ColumnInformation/InformationRow';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import MissingCompData from 'components/MissingCompData';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { deepCopy } from 'utils/objects';

const TOTAL_INDUSTRIES = 3;

type TenentInformationRowProps = {
  name: string;
  unitOfMeasurement: string;
  totalSF: number;
  tenants: number;
  index?: number;
};

const TenentInformationRow: React.FC<TenentInformationRowProps> = ({
  index = -1,
  name,
  unitOfMeasurement,
  totalSF,
  tenants,
}) => {
  const headerClass = {
    [styles['industry-tag-top']]: isTopIndustry(index),
    [styles['industry-tag-second']]: isSecondTopIndustry(index),
    [styles['industry-tag-third']]: isThirdTopIndustry(index),
    [styles['industry-tag-others']]: index === -1,
  };
  return (
    <div className={styles['industry-content']}>
      <InformationRow>
        <h4 className={classnames(styles['industry-tag'], headerClass)}>
          {name}
        </h4>
        <p className={styles['industry-details']}>
          <span>{formatArea(totalSF, unitOfMeasurement)}</span>
          {EMPTY_SPACE}
          {DOT}
          {EMPTY_SPACE}
          <span>
            {tenants}
            {EMPTY_SPACE}
            {capitalize(
              translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenant`, {
                count: tenants,
              }),
            )}
          </span>
        </p>
      </InformationRow>
    </div>
  );
};

const TenantIndustriesColumn: React.FC<Column> = ({
  columnTheme,
  property,
  onLoadComponent,
}) => {
  const { data, loading } = useQuery<{
    searchIndustriesOfProperty: IPropertyIndustry[];
  }>(SEARCH_PROPERTY_INDUSTRIES_QUERY, {
    variables: {
      search: {
        propertyId: property?.id,
      },
    },
    fetchPolicy: 'cache-first',
  });
  const industries = data
    ? deepCopy(data).searchIndustriesOfProperty.sort(
        (a, b) => b.totalSF - a.totalSF,
      )
    : [];
  const topIndustries = industries.slice(0, TOTAL_INDUSTRIES);
  const otherIndustries = industries?.slice(TOTAL_INDUSTRIES);

  const hasIndustries = !!topIndustries?.length;
  const shouldDisplayContent = !loading && hasIndustries;
  const isEmpty = !loading && !hasIndustries;

  useEffect(() => {
    if (!loading && hasIndustries && onLoadComponent)
      setTimeout(onLoadComponent);
  }, [loading, hasIndustries, onLoadComponent]);

  const renderEmptyRows = (hasOthersProperties: boolean) => {
    const totalIndustries = !hasOthersProperties
      ? TOTAL_INDUSTRIES + 1
      : TOTAL_INDUSTRIES;
    const countEmptyRows = totalIndustries - topIndustries.length;
    return [...Array(countEmptyRows)].map((_, index) => (
      <InformationRow key={index}>{EMPTY_SPACE}</InformationRow>
    ));
  };

  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  const getOthersIndustries = (
    otherIndustries: Pick<IPropertyIndustry, 'totalSF' | 'tenants'>[],
  ): Pick<IPropertyIndustry, 'totalSF' | 'tenants'> => {
    return otherIndustries.reduce(
      (item, prevItem) => {
        return {
          totalSF: item?.totalSF + prevItem?.totalSF,
          tenants: item?.tenants + prevItem?.tenants,
        };
      },
      { tenants: 0, totalSF: 0 },
    );
  };
  const { tenants: othersTenants, totalSF: otherTotalSF } = getOthersIndustries(
    otherIndustries,
  );

  return (
    <ComparativeCard
      theme={columnTheme}
      wrapperClassName={classnames(styles['industries-container'])}
    >
      {loading && (
        <div className={styles['loading-content']}>
          <SpinnerLoader background={ColorNames.ayTotalBlackColor} />
        </div>
      )}
      {shouldDisplayContent && (
        <>
          <div
            className={classnames(
              styles['chart'],
              classnames(styles['industry-content-wrapper']),
            )}
          >
            {topIndustries.map(({ industry, totalSF, tenants }, index) => (
              <TenentInformationRow
                key={industry?.id}
                index={index}
                unitOfMeasurement={unitOfMeasurement}
                name={industry?.name}
                totalSF={totalSF}
                tenants={tenants}
              />
            ))}
            {othersTenants > 0 && otherTotalSF > 0 && (
              <>
                <TenentInformationRow
                  unitOfMeasurement={unitOfMeasurement}
                  name={capitalize(
                    translateText(
                      `${I18N_PLATFORM_COMMON_WORD_PATH}.other_plural`,
                    ),
                  )}
                  totalSF={otherTotalSF}
                  tenants={othersTenants}
                />
              </>
            )}
            {renderEmptyRows(!!otherIndustries?.length)}
            <InformationRow wrapperClassName={styles['industry-graph-row']}>
              <PropertyTopIndustriesContainer
                topIndustries={topIndustries}
                otherIndustries={otherIndustries}
                wrapperClassName={styles['industry-graph']}
              />
            </InformationRow>
          </div>
        </>
      )}
      {isEmpty && (
        <div className={classnames(styles['missing-industry-data-container'])}>
          <MissingCompData isTenantInformation property={property} />
        </div>
      )}
    </ComparativeCard>
  );
};

export default TenantIndustriesColumn;
