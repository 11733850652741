import { I18N_AVANT_PROPERTY_PAGES } from 'constants/i18n';

export const I18N_COMP_SET_LABEL_PATH = `${I18N_AVANT_PROPERTY_PAGES}.compSet`;

export const OVERVIEW_SECTION = 'overview';
export const OVERVIEW_SUMMARY_SUBSECTION = 'overview-summary';
export const OVERVIEW_GRID_SUBSECTION = 'overview-grid';

export const LEASING_TREND_SECTION = 'leasing-trend';
export const LEASING_TREND_LIST_SUBSECTION = 'leasing-trend-list';
export const LEASING_TREND_SUMMARY_SUBSECTION = 'leasing-trend-summary';
export const LEASING_TREND_GRID_SUBSECTION = 'leasing-trend-grid';

export const AVAILABILITY_SECTION = 'availabilities';

export const RECENT_ACTIVITY_SECTION = 'recent-activity';

export const COMP_SET_OVERVIEW_SUBSECTIONS = {
  OVERVIEW_SUMMARY_SUBSECTION,
  OVERVIEW_GRID_SUBSECTION,
};

export const COMP_SET_LEASING_TREND_SUBSECTIONS = {
  LEASING_TREND_LIST_SUBSECTION,
  LEASING_TREND_SUMMARY_SUBSECTION,
  LEASING_TREND_GRID_SUBSECTION,
};
