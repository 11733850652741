import React from 'react';
import { HeaderGroup } from 'react-table';
import styles from './EditTable.module.scss';
import { EditableColumn } from './interfaces';
import classnames from 'classnames';

interface Props {
  headerGroups: HeaderGroup<{}>[];
}

const TableHeader: React.FC<Props> = ({ headerGroups }) => (
  <thead>
    {headerGroups.map((headerGroup, index) => (
      <tr
        {...headerGroup.getHeaderGroupProps()}
        key={`${index}-${headerGroup.id}`}
      >
        {headerGroup.headers.map((column, j) => {
          const isAlignedOnLeft = (column as EditableColumn).isAlignedOnLeft;
          const isAlignedOnRight = (column as EditableColumn).isAlignedOnRight;
          return (
            <th
              className={classnames(styles['header-row'], {
                [styles['cell-align-left']]: isAlignedOnLeft,
                [styles['cell-align-right']]: isAlignedOnRight,
              })}
              key={`${j}-${column.id}`}
            >
              {column.render('header')}
            </th>
          );
        })}
      </tr>
    ))}
  </thead>
);

export default TableHeader;
