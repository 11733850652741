import locations from 'routes';
import { History } from 'history';
import { IUser } from 'interfaces/IUser';
import { getRedirectionURL } from 'pages/LoginEmail/utils';
import { AuthContextInterface } from 'contexts/AuthContext';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import * as Sentry from '@sentry/react';
import i18n from 'i18n';

const redirectToExternalURL = (redirectToURL: string) => {
  localStorage.removeItem(LocalStorageKeys.refRedirect);
  window.location.replace(redirectToURL);
};

function filterByFeatureType(featureArray: any) {
  return featureArray.filter(
    (feature: any) => feature.featureType.type === 'Page',
  );
}

const isHealthcareOnly = (user: IUser) => {
  if (
    !!user?.subscriptions &&
    !!user?.subscriptions[0].features &&
    user?.subscriptions[0].features.length > 0
  ) {
    const filteredFeatures = filterByFeatureType(
      user?.subscriptions[0].features,
    );
    if (
      filteredFeatures.length == 1 &&
      filteredFeatures[0].name === 'Avant.Cities.Page.Healthcare'
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isParcelFinderOnly = (user: IUser) => {
  if (
    !!user?.subscriptions &&
    !!user?.subscriptions[0].features &&
    user?.subscriptions[0].features.length > 0
  ) {
    const filteredFeatures = filterByFeatureType(
      user?.subscriptions[0].features,
    );
    if (
      filteredFeatures.length == 1 &&
      filteredFeatures[0].name === 'Avant.Cities.Page.ParcelFinder'
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export interface ILoginUserParams {
  user: IUser;
  auth: AuthContextInterface;
  history: History;
  url?: URLSearchParams;
  historyStateParams?: any;
  stopRedirect?: boolean;
}

const loginUser = (params: ILoginUserParams) => {
  const { user, auth, history, url, historyStateParams, stopRedirect } = params;
  const URLFromQuery = url && getRedirectionURL(url);
  const URLFromStorage = localStorage.getItem(LocalStorageKeys.refRedirect);
  const URL = URLFromStorage || URLFromQuery;
  const shouldRedirectToExternalURL = user.isProfileCompleted && !!URL;

  auth.setUser(user);

  if (user.language) {
    i18n.changeLanguage(`${user.language}`);
  }
  if (user) {
    Sentry.setUser({ id: `${user.id}`, email: user.email });
  }
  isHealthcareOnly(user)
    ? redirectToExternalURL(
        `${window._env_.AVANT_CITIES_URL}/locationintelligence/healthcare`,
      )
    : isParcelFinderOnly(user)
    ? redirectToExternalURL(`${window._env_.AVANT_CITIES_URL}/parcelfinder`)
    : shouldRedirectToExternalURL && !stopRedirect
    ? redirectToExternalURL(URL!)
    : history.push(locations.root(), historyStateParams);
};

export default loginUser;
