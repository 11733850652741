import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';

import Button from 'components/Button';
import FormControl from 'components/FormControl';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import DateInput from 'components/Inputs/DateInput';
import RadioInput from 'components/Inputs/RadioInput';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { LeaseOptionType } from 'constants/leaseOptionTypes';
import { LEASE_OPTION_TYPES } from 'graphql/leases/queries';
import { IdName } from 'interfaces/IdName';
import { translateText } from 'utils/i18n';
import { generateNumericId } from 'utils/formatters/number';
import { ILeaseOption } from 'interfaces/ILeaseOption';

import { getNoticeDateOptions } from './constants';
import styles from '../../FormSection.module.scss';
import localStyles from './LeaseOptions.module.scss';
import {
  getExactMonthDifference,
  subtractMonthsFromDate,
} from '../../LeaseForm/formDates';
import { RenewalFields } from './RenewalFields';
import { ExpansionFields } from './ExpansionFields';
import { TerminationFields } from './TerminationFields';
import { LeaseOptionItem } from './LeaseOptionItem';
import { transformToLeaseOptionsInput } from './utils';

interface Props {
  onChange: (name: string, value: any) => void;
  initOptions: ILeaseOption[];
  currencySymbol?: string | null;
}

export const LeaseOptions = ({
  onChange,
  initOptions,
  currencySymbol,
}: Props) => {
  const transformedOptions = transformToLeaseOptionsInput(initOptions);
  const [openLeaseOptions, setOpenLeaseOptions] = useState(false);
  const [leaseOptions, setLeaseOptions] = useState<ILeaseOption[]>(
    transformedOptions,
  );
  const [currentOption, setCurrentOption] = useState<ILeaseOption>({
    optionTypeId: LeaseOptionType.RENEWAL,
  });

  const toggleDisplayingLeaseOption = () =>
    setOpenLeaseOptions(!openLeaseOptions);

  const { data, loading } = useQuery<{
    leaseOptionTypes: IdName[];
  }>(LEASE_OPTION_TYPES);

  const leaseOptionTypes = data?.leaseOptionTypes ?? [];

  const onSaveOption = () => {
    // when new option is created - it generated integer id
    // if edit option - it takes existing id and replace new option changes
    const updatedOption = {
      ...currentOption,
      id: currentOption.id || generateNumericId(),
    };
    const existingIndex = leaseOptions.findIndex(
      option => option.id === updatedOption.id,
    );

    let updatedLeaseOptions;
    if (existingIndex > -1) {
      updatedLeaseOptions = leaseOptions.map((option, index) =>
        index === existingIndex ? updatedOption : option,
      );
    } else {
      updatedLeaseOptions = [...leaseOptions, updatedOption];
    }

    setLeaseOptions(updatedLeaseOptions);
    setCurrentOption({ optionTypeId: LeaseOptionType.RENEWAL });
    toggleDisplayingLeaseOption();
    onChange('leaseOptions', updatedLeaseOptions);
  };

  const onDeleteOption = (index: number) => {
    const updatedOptions = [...leaseOptions];
    updatedOptions.splice(index, 1);
    setLeaseOptions(updatedOptions);
    onChange('leaseOptions', updatedOptions);
  };

  const commonFields = () => {
    return (
      <FormControl
        label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.optionNoticeDate`,
        )}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['large-input-container']}>
          <DateInput
            id="option-notice-date"
            value={currentOption.optionNoticeDate}
            onChange={value => {
              setCurrentOption({
                ...currentOption,
                optionNoticeDate: value,
              });
            }}
          />
          <RadioInput
            selectedItem={getExactMonthDifference(
              currentOption.optionNoticeDate,
              currentOption.optionDate,
            )}
            isAllDisabled={!currentOption.optionDate}
            data={getNoticeDateOptions(translateText)}
            onChange={item => {
              const optionNoticeDate = subtractMonthsFromDate(
                currentOption.optionDate,
                +item.value!,
              );
              setCurrentOption({ ...currentOption, optionNoticeDate });
            }}
          />
        </div>
      </FormControl>
    );
  };

  const renderInitialFields = () => {
    return (
      <FormControl
        wrapperClassName={classnames(
          styles['form-row'],
          styles['form-row-start'],
        )}
        label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.leaseOptions`,
        )}
      >
        <div className={localStyles['initial-row']}>
          <div className={localStyles['lease-options']}>
            {leaseOptions?.map((l, i) => {
              return (
                <LeaseOptionItem
                  leaseOptionTypes={leaseOptionTypes}
                  onDelete={() => onDeleteOption(i)}
                  onEdit={() => {
                    setOpenLeaseOptions(true);
                    setCurrentOption(l);
                  }}
                  leaseOption={l}
                  key={l.id}
                />
              );
            })}
          </div>
          <Button
            onClick={() => {
              setCurrentOption({ optionTypeId: LeaseOptionType.RENEWAL });
              toggleDisplayingLeaseOption();
            }}
            type="ghost"
            label={translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.addLeaseOption`,
            )}
            size="small"
            isDisabled={loading}
          />
        </div>
      </FormControl>
    );
  };

  const renderFieldsBasedOnType = (typeId: number | null) => {
    if (!typeId) return null;
    switch (typeId) {
      case LeaseOptionType.RENEWAL: {
        return (
          <RenewalFields
            currentOption={currentOption}
            setCurrentOption={setCurrentOption}
            commonFields={commonFields()}
          />
        );
      }
      case LeaseOptionType.EXPANSION: {
        return (
          <ExpansionFields
            currentOption={currentOption}
            setCurrentOption={setCurrentOption}
            commonFields={commonFields()}
          />
        );
      }
      default: {
        return (
          <TerminationFields
            currencySymbol={currencySymbol}
            currentOption={currentOption}
            setCurrentOption={setCurrentOption}
            commonFields={commonFields()}
          />
        );
      }
    }
  };

  const renderLeaseOptionFields = () => {
    return (
      <>
        <FormControl
          wrapperClassName={classnames(
            styles['form-row'],
            styles['form-row-start'],
          )}
          label={'Lease Options'}
        >
          <ChoiceInput
            selectedItem={{
              id: +currentOption.optionTypeId!,
            }}
            data={leaseOptionTypes}
            containerWrapperClassName={styles['radio-input-container']}
            onChange={item => {
              setCurrentOption({
                ...currentOption,
                optionTypeId: item?.id || null,
              });
            }}
          />
        </FormControl>
        {renderFieldsBasedOnType(currentOption?.optionTypeId)}

        <div className={localStyles['action-buttons']}>
          <Button
            wrapperClassName={localStyles['action-button']}
            type="light"
            size="small"
            label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`)}
            onClick={toggleDisplayingLeaseOption}
          />
          <Button
            wrapperClassName={localStyles['action-button']}
            type="main"
            size="small"
            isDisabled={!currentOption?.optionTypeId}
            label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.save`)}
            onClick={onSaveOption}
          />
        </div>
      </>
    );
  };

  return (
    <>{openLeaseOptions ? renderLeaseOptionFields() : renderInitialFields()}</>
  );
};
