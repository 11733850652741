import React from 'react';
import { RentPaymentDetailsOptionsForUK } from 'constants/leases';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import styles from '../../FormSection.module.scss';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { translateText } from 'utils/i18n';

interface Props {
  required?: boolean;
  property?: IProperty;
  selectedItem: string | null | undefined;
  onChange: (field: string, value: any) => void;
}

// This component is implemented to be render for UK per business requirement.
const RentPaymentDetails: React.FC<Props> = ({
  onChange,
  selectedItem,
  required = false,
}) => {
  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText(
        'avantPlatform.attributes.lease.label.rentPaymentDetail',
      )}
    >
      <ChoiceInput
        selectedItem={{
          id: selectedItem,
          name: selectedItem,
        }}
        data={RentPaymentDetailsOptionsForUK}
        onChange={item => onChange('rentPaymentDetail', item?.id)}
        containerWrapperClassName={styles['radio-input-container']}
      />
    </FormControl>
  );
};

export default RentPaymentDetails;
