import React from 'react';
import { useQuery } from '@apollo/client';
import { IdName } from 'interfaces/IdName';
import styles from '../ComparativeColumns.module.scss';
import { GET_APPROVED_PROPERTY_TYPE_AMENITIES_QUERY } from 'graphql/amenities';
import { Column } from 'components/PropertyProfile/Sections/Comparative/constants';
import ComparativeCard from 'components/PropertyProfile/Sections/Comparative/ComparativeCard';
import AmenityRow from 'components/PropertyProfile/Sections/Comparative/ComparativeColumns/AmenitiesColumn/AmenityRow';

const AmenitiesColumn: React.FC<Column> = ({ columnTheme, property }) => {
  const { data } = useQuery(GET_APPROVED_PROPERTY_TYPE_AMENITIES_QUERY, {
    variables: { propertyTypeIds: [property.propertyType?.id] },
  });

  const approvedAmenities = data?.amenities;

  return (
    <ComparativeCard theme={columnTheme} wrapperClassName={styles['amenities']}>
      {approvedAmenities?.map((approvedAmenity: IdName, index: number) => (
        <AmenityRow
          key={approvedAmenity.id}
          columnTheme={columnTheme}
          approvedAmenity={approvedAmenity}
          propertyAmenities={property.amenities}
          isLastItem={index === approvedAmenities.length - 1}
        />
      ))}
    </ComparativeCard>
  );
};

export default AmenitiesColumn;
