import React from 'react';
import { isEmpty } from 'lodash';
import { DEFAULT_STYLE } from './utils';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTooltip,
} from 'victory';
import { VerticalGroupBarChartProps, AxisData } from './types';

const VerticalGroupBarChart: React.FC<VerticalGroupBarChartProps> = ({
  title,
  subtitle,
  barData,
  legendData,
  customStyle,
  events,
}) => {
  const style = Object.assign(Object.assign({}, DEFAULT_STYLE), customStyle);
  const hasLegend =
    !isEmpty(legendData) || barData.some(({ legend }) => !!legend);

  const buildLegendData = () => {
    if (!isEmpty(legendData)) {
      return legendData?.map(legendDatum => ({
        name: legendDatum.legend,
        symbol: {
          fill: legendDatum.color,
        },
      }));
    } else if (!isEmpty(barData)) {
      return barData.map(bar => ({
        name: bar.legend,
        symbol: {
          fill: bar.color,
        },
      }));
    } else {
      return [];
    }
  };

  return (
    <>
      <VictoryChart
        domainPadding={style.chart?.domainPadding}
        style={style.chart?.style}
        width={style.chart?.width}
        height={style.chart?.height}
        padding={style.chart?.padding}
        events={events}
      >
        <VictoryLabel
          x={style.title?.x}
          y={style.title?.y}
          text={title}
          style={style?.title?.style}
        />
        {subtitle && (
          <VictoryLabel
            x={style.subtitle?.x}
            y={style.subtitle?.y}
            text={subtitle}
            style={style?.subtitle?.style}
          />
        )}
        {hasLegend && (
          <VictoryLegend
            height={style.legend?.height}
            width={style.legend?.width}
            x={style.legend?.x}
            y={style.legend?.y}
            orientation="horizontal"
            style={style.legend?.style}
            data={buildLegendData()}
          />
        )}

        <VictoryAxis
          style={style.xAxis?.style}
          tickValues={(style.xAxis as AxisData)?.tickValues}
          tickFormat={(style.xAxis as AxisData)?.tickFormat}
        />
        <VictoryAxis
          dependentAxis
          style={(style.yAxis as AxisData)?.style}
          tickValues={(style.yAxis as AxisData)?.tickValues}
          tickFormat={(style.yAxis as AxisData)?.tickFormat}
        />

        <VictoryGroup offset={style.bar?.width}>
          {barData.map((bar, index) => (
            <VictoryBar
              key={index}
              name={bar.name}
              data={bar.values}
              events={bar.events}
              cornerRadius={style.bar?.cornerRadius}
              barWidth={style.bar?.width}
              style={{
                ...style.bar?.style,
                data: {
                  fill: bar.color,
                  ...bar.style?.data,
                },
              }}
              labels={({ datum }) => datum.y}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={style.tooltip?.cornerRadius}
                  pointerLength={style.tooltip?.pointerLength}
                  pointerWidth={style.tooltip?.pointerWidth}
                  flyoutStyle={{
                    border: style.tooltip?.style.border,
                    fill: bar.color,
                    stroke: bar.color,
                  }}
                />
              }
            />
          ))}
        </VictoryGroup>
      </VictoryChart>
    </>
  );
};

export default VerticalGroupBarChart;
