import { gql } from '@apollo/client';

const PROPERTY_PRICE_QUOTE_FIELDS = `
  id
  createdAt
  updatedAt
  date
  askingRentPsf
  leaseType
  rentType
  tiAllowance
  freeRentMonths
  term
  source
  pricing
  floors
  size
  sizeMt
  comment
  opExAndTaxes
  taxes
  operatingExpenses
`;

const PROPERTY_FIELDS = `
    id
    name
    primaryAddress
    displayAddress
    secondaryAddress
    tertiaryAddress
    quaternaryAddress
    numberOfAlternativeAddresses
    coverMediaUrl
    coverMediaType
    measurementSystem
    currencyCode
    buildingSize
    buildingSizeMt
    country
    state
    latitude
    longitude
    latitudeDisplay
    longitudeDisplay
    propertyCountry {
      id
      name
      code
      flagURL
      distanceMeasurement
      areaMeasurement
      currencyCode
    }
    market {
        id
        name
    }
    submarket {
        id
        name
    }
    micromarket {
        id
        name
    }
    propertyType {
        id
        name
    }
    propertyClass {
        id
        name
    }
`;

const TENANT_COMPANY_FIELDS = `
  id
  name
`;

const USER_FIELDS = `
  id
  firstName
  lastName
  email
`;

const EXPENSE_FIELDS = `
  id
  tax
  operatingExpenses
  taxAndOpex
  year
`;

const ESCALATION_FIELDS = `
  escalation
  escalationIncrement
  escalationType
  escalationFormatted
  `;

export const GET_PROPERTY_PRICE_QUOTE = gql`
  query getPropertyPriceQuote($id: ID!) {
    propertyPriceQuote(id: $id) {
      ${PROPERTY_PRICE_QUOTE_FIELDS}
      ${ESCALATION_FIELDS}
      property {
        ${PROPERTY_FIELDS}
        expenses {
          ${EXPENSE_FIELDS}
        }
      }
      tenantCompany {
        ${TENANT_COMPANY_FIELDS}
      }
      createdUser {
        ${USER_FIELDS}
      }
      updatedUser {
        ${USER_FIELDS}
      }
    }
  }
`;

export const SEARCH_PROPERTY_PRICE_QUOTES = gql`
  query searchPropertyPriceQuotes($search: PropertyPriceQuoteSearchInput) {
    propertyPriceQuotes(search: $search) {
      results {
        ${ESCALATION_FIELDS}
        ${PROPERTY_PRICE_QUOTE_FIELDS}
        propertyId
        property {
          ${PROPERTY_FIELDS}
        }
        tenantCompanyId
        tenantCompany {
          ${TENANT_COMPANY_FIELDS}
        }
        createdUserId
        createdUser {
          ${USER_FIELDS}
        }
        updatedUser {
            ${USER_FIELDS}
        }
      }
    }
  }
`;
