import { ONE_MILLION } from 'constants/numbers';
import { SearchOperations } from '../../../../constants/searchOperations';
import { SearchOptionsType } from '../../../../interfaces/ISearchOptionsType';

const LEASE_SIZE_OPTIONS: SearchOptionsType[] = [
  {
    id: 1,
    name: '50,000+',
    field: 'size',
    filter: {
      operator: SearchOperations.gte,
      value: 50000,
    },
  },
  {
    id: 2,
    name: '20,000 - 50,000',
    field: 'size',
    filter: {
      operator: SearchOperations.between,
      range: { start: 20000, end: 50000 },
    },
  },
  {
    id: 3,
    name: '10,000 - 20,000',
    field: 'size',
    filter: {
      operator: SearchOperations.between,
      range: { start: 10000, end: 20000 },
    },
  },
  {
    id: 4,
    name: 'Under 10,000',
    field: 'size',
    filter: {
      operator: SearchOperations.lte,
      value: 10000,
    },
  },
];

export const LEASE_INDUSTRIAL_SIZE_OPTIONS: SearchOptionsType[] = [
  {
    id: 6,
    name: '1M+',
    field: 'size',
    filter: {
      operator: SearchOperations.gte,
      value: ONE_MILLION,
    },
  },
  {
    id: 7,
    name: '750,000 - 1M',
    field: 'size',
    filter: {
      operator: SearchOperations.between,
      range: { start: 750000, end: ONE_MILLION },
    },
  },
  {
    id: 8,
    name: '500,000 - 750,000',
    field: 'size',
    filter: {
      operator: SearchOperations.between,
      range: { start: 500000, end: 750000 },
    },
  },
  {
    id: 9,
    name: '250,000 - 500,000',
    field: 'size',
    filter: {
      operator: SearchOperations.between,
      range: { start: 250000, end: 500000 },
    },
  },
  {
    id: 10,
    name: '100,000 - 250,000',
    field: 'size',
    filter: {
      operator: SearchOperations.between,
      range: { start: 100000, end: 250000 },
    },
  },
  {
    id: 11,
    name: '20,000 - 100,000',
    field: 'size',
    filter: {
      operator: SearchOperations.between,
      range: { start: 20000, end: 100000 },
    },
  },
  {
    id: 12,
    name: 'Under 20,000',
    field: 'size',
    filter: {
      operator: SearchOperations.lte,
      value: 20000,
    },
  },
];

export default LEASE_SIZE_OPTIONS;
