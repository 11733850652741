export enum CountryNames {
  US = 'United States',
  DE = 'Germany',
  UK = 'United Kingdom',
  CA = 'Canada',
}

export enum CountryNamesToCountryCodes {
  'United Kingdom' = 'UK',
  'United States' = 'US',
  Germany = 'DE',
  Canada = 'CA',
}

export enum CountryCodes {
  US = 'US',
  CA = 'CA',
  UK = 'UK',
  GB = 'GB',
  AD = 'AD',
  AL = 'AL',
  AT = 'AT',
  AX = 'AX',
  BA = 'BA',
  BE = 'BE',
  BG = 'BG',
  BY = 'BY',
  CH = 'CH',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  EE = 'EE',
  ES = 'ES',
  FI = 'FI',
  FO = 'FO',
  FR = 'FR',
  GG = 'GG',
  GR = 'GR',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IC = 'IC',
  IT = 'IT',
  JE = 'JE',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MC = 'MC',
  MD = 'MD',
  MK = 'MK',
  MT = 'MT',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  RU = 'RU',
  SE = 'SE',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SM = 'SM',
  UA = 'UA',
  VA = 'VA',
  EU = 'EU',
}
