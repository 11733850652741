type HoverLine = {
  lineElement: HTMLTableElement;
  tableContainerRight: number;
  tableContainerOffset: number;
  editButtonWrapperRef: HTMLDivElement;
  getItemId: (leaseSaleId: string | undefined) => void;
};

type EditButtonHandler = {
  tableElement: HTMLTableElement;
  tableContainer: HTMLDivElement;
  editButtonWrapperRef: HTMLDivElement;
  getItemId: (leaseSaleId: string | undefined) => void;
};

const setElementChildrenDisplayValue = (
  container: HTMLDivElement,
  visibility: string,
) => {
  const children = container.children;
  for (let i = 0; i < children.length; i++) {
    const child: any = children[i];
    child.style.display = visibility;
  }
};

const applyHoverContainerButton = (editButtonWrapperRef: HTMLDivElement) => {
  const mouseEnterButtonContainer = () => {
    setElementChildrenDisplayValue(editButtonWrapperRef, 'block');
  };

  const mouseLeaveButtonContainer = () => {
    setElementChildrenDisplayValue(editButtonWrapperRef, 'none');
  };

  editButtonWrapperRef.addEventListener(
    'mouseenter',
    mouseEnterButtonContainer,
  );
  editButtonWrapperRef.addEventListener(
    'mouseleave',
    mouseLeaveButtonContainer,
  );

  return () => {
    editButtonWrapperRef.removeEventListener(
      'mouseenter',
      mouseEnterButtonContainer,
    );
    editButtonWrapperRef.removeEventListener(
      'mouseleave',
      mouseLeaveButtonContainer,
    );
  };
};

const applyHoverLine = (hoverLine: HoverLine) => {
  const {
    lineElement,
    tableContainerRight,
    tableContainerOffset,
    editButtonWrapperRef,
    getItemId,
  } = hoverLine;

  const lineHeight = lineElement.getBoundingClientRect().height;

  const mouseEnterFn = () => {
    editButtonWrapperRef.style.display = 'flex';
    editButtonWrapperRef.style.height = `${lineHeight}px`;
    editButtonWrapperRef.style.top = `${lineElement.offsetTop +
      tableContainerOffset}px`;
    editButtonWrapperRef.style.left = `${tableContainerRight}px`;

    setElementChildrenDisplayValue(editButtonWrapperRef, 'block');
    getItemId(lineElement.dataset.itemId);
  };

  const mouseLeaveFn = () => {
    setElementChildrenDisplayValue(editButtonWrapperRef, 'none');
  };

  lineElement.addEventListener('mouseenter', mouseEnterFn);
  lineElement.addEventListener('mouseleave', mouseLeaveFn);

  return () => {
    lineElement.removeEventListener('mouseenter', mouseEnterFn);
    lineElement.removeEventListener('mouseleave', mouseLeaveFn);
  };
};

export const addHoverEditButton = (editButtonHandler: EditButtonHandler) => {
  const {
    tableElement,
    tableContainer,
    editButtonWrapperRef,
    getItemId,
  } = editButtonHandler;

  if (!tableElement) return;

  const tableTrs = Array.from(tableElement.querySelectorAll('tbody tr'));

  if (!tableTrs?.length) {
    return;
  }

  const tableContainerOffset = tableContainer.offsetTop;
  const tableContainerRight = tableContainer.getBoundingClientRect().right;

  const linesListenersArray: (() => void)[] = [];

  tableTrs.forEach((tr: any) => {
    linesListenersArray.push(
      applyHoverLine({
        lineElement: tr,
        tableContainerRight: tableContainerRight,
        tableContainerOffset: tableContainerOffset,
        editButtonWrapperRef: editButtonWrapperRef,
        getItemId: getItemId,
      }),
    );
  });

  const removeButtonContainerListeners = applyHoverContainerButton(
    editButtonWrapperRef,
  );

  return () => {
    removeButtonContainerListeners();
    linesListenersArray.forEach(remove => remove());
  };
};
