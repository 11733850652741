import React from 'react';
import classnames from 'classnames';
import Button, { Type } from 'components/Button';
import CloseImg from 'assets/images/icons/close-modal.svg';
import styles from './ConfirmationModal.module.scss';

export interface IModalButton {
  label: string;
  isDisabled?: boolean;
  onclick?: () => void;
}
export interface IModalPositiveButton extends IModalButton {
  positiveButtonType?: Type;
}

export interface IModalNegativeButton extends IModalButton {
  negativeButtonType?: Type;
}

type ModalProps = {
  header?: string;
  paragraph?: string[];
  negativeButton?: IModalNegativeButton;
  positiveButton?: IModalPositiveButton;
  onCloseModal: () => void;
};

const ConfirmationModal: React.FC<React.PropsWithChildren<
  ModalProps
>> = props => {
  const {
    onCloseModal,
    negativeButton,
    positiveButton,
    header,
    paragraph,
  } = props;

  return (
    <>
      <div className={styles.container} />
      <div className={styles.card} role="dialog">
        <img
          src={CloseImg}
          alt="Close Modal"
          className={styles['close-button']}
          onClick={onCloseModal}
        />
        {header && <div className={styles['text-header']}>{header}</div>}
        {paragraph && (
          <div className={styles['paragraphs-group']}>
            <p className={styles['text-paragraph']}>
              {paragraph.map(item => {
                return (
                  <React.Fragment key={item}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
            </p>
          </div>
        )}

        <div className={styles['buttons-group']}>
          {negativeButton && (
            <Button
              size="small"
              type={negativeButton.negativeButtonType ?? 'delete'}
              onClick={negativeButton.onclick}
              label={negativeButton.label}
              wrapperClassName={classnames(
                styles['modal-button'],
                styles['margin-right'],
              )}
              isDisabled={negativeButton.isDisabled}
            />
          )}
          {positiveButton && (
            <Button
              size="small"
              type={positiveButton.positiveButtonType ?? 'main'}
              onClick={positiveButton.onclick}
              label={positiveButton.label}
              wrapperClassName={styles['modal-button']}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
