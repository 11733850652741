import React, { useContext, useReducer } from 'react';
import { SearchCriteriaType } from 'components/FindComps/EditSearchCriteria/types';
import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { FindCompTabs } from '../constants/FindCompTabs';

interface IFindCompsContext {
  state: FindCompsState;
  dispatch: React.Dispatch<any>;
}

type FindCompsState = {
  children?: any;
  criteria?: SearchCriteriaType;
  currencySymbol?: string;
  initialCriteria?: SearchCriteriaType;
  leasesCount?: number;
  measurementSystemCode?: UnitOfMeasurementCode;
  measurementKPI?: UnitOfMeasurement;
  propertiesCount?: number;
  salesCount?: number;
  timsCount?: number;
  isEditingSearchCriteria?: boolean;
  currentTab?: FindCompTabs;
};

export enum FindCompsActions {
  reset_criteria,
  set_criteria,
  set_editing_criteria,
  set_initial_criteria,
  set_localization,
  set_totals,
  set_measurement_kpi,
  set_current_tab,
}

const FindCompsContext = React.createContext<IFindCompsContext | undefined>({
  state: { isEditingSearchCriteria: false },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

const FindCompsReducer = (state: FindCompsState, action: any) => {
  switch (action.type) {
    case FindCompsActions.set_criteria: {
      return { ...state, criteria: action.criteria };
    }

    case FindCompsActions.reset_criteria:
      return { ...state, criteria: {} };

    case FindCompsActions.set_initial_criteria:
      return { ...state, initialCriteria: action.criteria };

    case FindCompsActions.set_editing_criteria:
      return {
        ...state,
        isEditingSearchCriteria: action.isEditingSearchCriteria,
      };

    case FindCompsActions.set_localization:
      return {
        ...state,
        measurementSystemCode: action.measurementSystemCode,
        currencySymbol: action.currencySymbol,
      };

    case FindCompsActions.set_totals:
      return {
        ...state,
        [action.countField]: action.countValue,
      };

    case FindCompsActions.set_measurement_kpi:
      return {
        ...state,
        measurementKPI: action.measurementKPI,
      };

    case FindCompsActions.set_current_tab:
      return {
        ...state,
        currentTab: action.currentTab,
      };

    default:
      return state;
  }
};

export const FindCompsProvider = (props: FindCompsState) => {
  const [state, dispatch] = useReducer(FindCompsReducer, { ...props });

  return (
    <FindCompsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </FindCompsContext.Provider>
  );
};

export const useFindCompsData = () => {
  const context = useContext(FindCompsContext);

  if (context === undefined) {
    throw new Error('useFindCompsData must be used within a FindCompsProvider');
  }

  return context;
};
