import { colors } from 'constants/colors';

interface IconStateColors {
  idle: string;
  active: string;
  hover: string;
  disabled: string;
}

export const ICON_COLORS: { [type: string]: IconStateColors } = {
  main: {
    idle: colors.ayWhiteColor,
    active: colors.ayWhiteColor,
    hover: colors.ayWhiteColor,
    disabled: colors.ayGrey24Color,
  },
  secondary: {
    idle: colors.primaryColor400,
    active: colors.ayWhiteColor,
    hover: colors.ayWhiteColor,
    disabled: colors.ayGrey24Color,
  },
  ghost: {
    idle: colors.primaryColor500,
    active: colors.ayWhiteColor,
    hover: colors.primaryColor500,
    disabled: colors.ayGrey24Color,
  },
  'ghost-light': {
    idle: colors.primaryColor100,
    active: colors.ayWhiteColor,
    hover: colors.primaryColor500,
    disabled: colors.ayGrey24Color,
  },
  contextual: {
    idle: colors.primaryColor500,
    active: colors.ayWhiteColor,
    hover: colors.ayWhiteColor,
    disabled: colors.ayGrey24Color,
  },
  'contextual-transparent': {
    idle: colors.primaryColor500,
    active: colors.ayWhiteColor,
    hover: colors.ayWhiteColor,
    disabled: colors.ayGrey24Color,
  },
  'contextual-light': {
    idle: colors.primaryColor500,
    active: colors.ayWhiteColor,
    hover: colors.primaryColor500,
    disabled: colors.ayGrey24Color,
  },
  'contextual-positive': {
    idle: colors.primaryColor500,
    active: colors.ayWhiteColor,
    hover: colors.ayWhiteColor,
    disabled: colors.uiColor400,
  },
  navigation: {
    idle: colors.uiColor500,
    active: colors.uiColor500,
    hover: colors.ayWhiteColor,
    disabled: colors.primaryColor500,
  },
  link: {
    idle: colors.primaryColor400,
    active: colors.primaryColor400,
    hover: colors.primaryColor500,
    disabled: colors.ayGrey24Color,
  },
  neutral: {
    idle: 'red',
    active: 'blue',
    hover: 'green',
    disabled: 'cyan',
  },
  inline: {
    idle: 'red',
    active: 'blue',
    hover: 'green',
    disabled: 'cyan',
  },
  delete: {
    idle: colors.ayMainRedColor,
    active: colors.ayWhiteColor,
    hover: colors.ayWhiteColor,
    disabled: colors.ayGrey24Color,
  },
  'delete-neutral': {
    idle: colors.ayMainRedColor,
    active: colors.ayWhiteColor,
    hover: colors.ayWhiteColor,
    disabled: colors.ayGrey24Color,
  },
  light: {
    idle: 'red',
    active: 'blue',
    hover: 'green',
    disabled: 'cyan',
  },
  'supportive-neutral': {
    idle: colors.uiColorBlack,
    active: colors.ayWhiteColor,
    hover: colors.ayWhiteColor,
    disabled: colors.uiColor400,
  },
  upload: {
    idle: colors.uiColor600,
    active: colors.uiColor600,
    hover: colors.uiColor600,
    disabled: colors.uiColor600,
  },
};
