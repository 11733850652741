export const getExpiringLeasesBarId = (node: any, graphId: number) => {
  return `expiring-leases-${graphId}-bar-${Math.trunc(node.xPosition)}`;
};

export const getExpiringLeasesBarShadowId = (node: any, graphId: number) => {
  return `expiring-leases-${graphId}-bar-shadow-${Math.trunc(node.xPosition)}`;
};

export enum GraphClasses {
  Bar = 'expiring-leases-bar',
  BarShadow = 'expiring-leases-bar-shadow',
}

export const getGraphClass = (graphClass: GraphClasses, graphId: number) => {
  return `${graphClass}-${graphId}`;
};
