import { translateText } from 'utils/i18n';
import { colors } from 'constants/colors';
import {
  I18N_AVANT_PROPERTY_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IHistoricalAvailabilityData } from 'interfaces/ISubmarketHistoricalAvailability';
import { QUARTER_MONTHS } from 'utils/date';
import { groupBy, uniq, isEmpty } from 'lodash';
import { LineChart, LineData } from '../VictoryLineChart/types';
import { getLineStyle } from '../VictoryLineChart/utils';

const MAX_AVAILABILITY_PERCENTAGE = 100;

export enum HistoricalAvailabilityTypes {
  OCCUPIED = 'occupied',
  AVAILABLE = 'available',
  VACANT = 'vacant',
}

const { OCCUPIED, AVAILABLE, VACANT } = HistoricalAvailabilityTypes;

export const getLabelByType = (type: HistoricalAvailabilityTypes) => {
  const titleType = {
    [OCCUPIED]: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.occupied`),
    [VACANT]: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.vacant`),
    [AVAILABLE]: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.available`),
  };
  return titleType[type];
};

export const getSwitchOptions = () => ({
  [OCCUPIED]: {
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.occupied`),
    value: OCCUPIED,
  },
  [AVAILABLE]: {
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.available`),
    value: AVAILABLE,
  },
  [VACANT]: {
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.vacant`),
    value: VACANT,
  },
});

export const getTitle = (type: HistoricalAvailabilityTypes) => {
  const titleType = {
    [OCCUPIED]: translateText(
      `${I18N_AVANT_PROPERTY_HEADINGS_PATH}.historicalOccupancy`,
    ),
    [VACANT]: translateText(
      `${I18N_AVANT_PROPERTY_HEADINGS_PATH}.historicalVacancy`,
    ),
    [AVAILABLE]: translateText(
      `${I18N_AVANT_PROPERTY_HEADINGS_PATH}.historicalAvailability`,
    ),
  };
  return titleType[type];
};

export const getFieldNamePercentageByActiveType = (
  activeType: HistoricalAvailabilityTypes,
) => {
  return activeType == HistoricalAvailabilityTypes.OCCUPIED
    ? 'occupiedSFPercentage'
    : activeType == HistoricalAvailabilityTypes.VACANT
    ? 'vacantSFDirectPercentage'
    : 'availableSFDirectPercentage';
};

export const getFieldNameTotalBuildingSizeByActiveType = (
  activeType: HistoricalAvailabilityTypes,
) => {
  return activeType == HistoricalAvailabilityTypes.OCCUPIED
    ? 'totalBuildingSizeExisting'
    : activeType == HistoricalAvailabilityTypes.VACANT
    ? 'totalBuildingSizeExisting'
    : 'totalBuildingSizeAvailable';
};

export const getFieldNameByActiveType = (
  activeType: HistoricalAvailabilityTypes,
) => {
  return activeType == HistoricalAvailabilityTypes.OCCUPIED
    ? 'occupiedSF'
    : activeType == HistoricalAvailabilityTypes.VACANT
    ? 'vacantSFDirect'
    : 'availableSFDirect';
};

export const getYAxis = (
  data: IHistoricalAvailabilityData[],
  activeType: HistoricalAvailabilityTypes,
) => {
  const yAxis: number[] = [];
  const values = data
    .map(d => d[getFieldNamePercentageByActiveType(activeType)]!)
    .filter(Boolean);

  if (!isEmpty(values)) {
    let maxValue = Math.max(...values);
    let minValue = Math.min(...values);
    maxValue = maxValue + maxValue * 0.2;
    maxValue =
      maxValue > MAX_AVAILABILITY_PERCENTAGE
        ? MAX_AVAILABILITY_PERCENTAGE
        : maxValue;
    minValue = minValue == 0 ? minValue : minValue - minValue * 0.2;
    const interval = maxValue / 4;
    for (let i = minValue; i <= maxValue; i = i + interval) {
      yAxis.push(Math.round(i));
    }
    return yAxis;
  }
};

export const formatQuarter = (item: IHistoricalAvailabilityData) => {
  return `${item.year}-${QUARTER_MONTHS[Number(item.quarter) - 1]}-01`;
};

export const getAverage = (
  data: IHistoricalAvailabilityData[],
  activeType: HistoricalAvailabilityTypes,
  isQuarterly?: boolean,
) => {
  const groupedItems = groupBy(
    data.map(d => {
      return { ...d, date: formatQuarter(d) };
    }),
    isQuarterly ? 'date' : 'year',
  );

  const lineDataAverage: LineData = { ...getLineStyle(colors.supportive500) };
  lineDataAverage.values = [];
  Object.keys(groupedItems).forEach(item => {
    const items = groupedItems[item];
    const averagePercentage =
      items.reduce(
        (total, next) =>
          total + next[getFieldNamePercentageByActiveType(activeType)]!,
        0,
      ) / items.length;
    const averageSf =
      items.reduce(
        (total, next) => total + next[getFieldNameByActiveType(activeType)]!,
        0,
      ) / items.length;
    const itemAverage: any = {
      x: isQuarterly ? item : parseInt(item),
      y: averagePercentage,
      item: {},
    };
    itemAverage.item[
      getFieldNamePercentageByActiveType(activeType)
    ] = averagePercentage;
    itemAverage.item[getFieldNameByActiveType(activeType)] = averageSf;
    lineDataAverage.values?.push(itemAverage);
  });

  return lineDataAverage;
};

export const getTotal = (
  data: IHistoricalAvailabilityData[],
  activeType: HistoricalAvailabilityTypes,
  isQuarterly?: boolean,
) => {
  const groupedItems = groupBy(
    data.map(d => {
      return { ...d, date: formatQuarter(d) };
    }),
    isQuarterly ? 'date' : 'year',
  );

  const lineDataAverage: LineData = { ...getLineStyle(colors.supportive500) };
  lineDataAverage.values = [];
  Object.keys(groupedItems).forEach(item => {
    const items = groupedItems[item];
    const totalBuildingSize = items.reduce(
      (total, next) =>
        total + next[getFieldNameTotalBuildingSizeByActiveType(activeType)]!,
      0,
    );
    const totalSf = items.reduce(
      (total, next) => total + next[getFieldNameByActiveType(activeType)]!,
      0,
    );
    const totalPercentage = (totalSf / totalBuildingSize) * 100;
    const itemTotal: any = {
      x: isQuarterly ? item : parseInt(item),
      y: totalPercentage,
      item: {},
    };
    itemTotal.item[
      getFieldNamePercentageByActiveType(activeType)
    ] = totalPercentage;
    itemTotal.item[getFieldNameByActiveType(activeType)] = totalSf;
    itemTotal.item.quarter = items[0].quarter;
    lineDataAverage.values?.push(itemTotal);
  });

  return lineDataAverage;
};

export const removeFutureQuarters = (data: IHistoricalAvailabilityData[]) => {
  const today = new Date();
  const year = today.getFullYear();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  return data.filter(d => {
    if (d.year === year && d.quarter! > quarter) {
      return null;
    } else {
      return d;
    }
  });
};

export const parseGraphData = (
  data: IHistoricalAvailabilityData[],
  activeType: HistoricalAvailabilityTypes,
  isQuarterly?: boolean,
  classes?: any[],
): LineChart => {
  const filteredData = removeFutureQuarters(data);
  const graphData: LineChart = { historicalLineData: [], xAxis: [], yAxis: [] };
  graphData.historicalLineData?.push(
    getTotal(filteredData, activeType, isQuarterly),
  );

  classes?.forEach(c => {
    const lineData: LineData = { ...getLineStyle(c.color) };
    lineData.values = filteredData
      .filter(d => d.className === c.className)
      .map(item => {
        return {
          x: isQuarterly ? `${formatQuarter(item)}` : item.year,
          y: item[getFieldNamePercentageByActiveType(activeType)],
          item: item,
        };
      });
    graphData.historicalLineData?.push(lineData);
  });

  graphData.yAxis = getYAxis(filteredData, activeType);
  graphData.xAxis = isQuarterly
    ? uniq(filteredData.map((d: any) => formatQuarter(d)))
    : uniq(filteredData.map((d: any) => d.year));

  return graphData;
};

const checkDataFilterResult = (graphData: LineChart) => {
  const result = graphData.historicalLineData
    ?.map(lineData => lineData.values?.some(value => value.y))
    .filter(Boolean);
  return result;
};

export const hasData = (graphData: LineChart): boolean => {
  return !!checkDataFilterResult(graphData)?.length;
};
