import MarketPropertyDataFilter from 'components/MarketPropertyDataFilter';
import { MarketPropertyDataFiltersType } from 'components/MarketPropertyDataFilter/constants';
import React, { useEffect, useState } from 'react';
import HistoricalPerformanceModal from '.';
import { SegmentPricingParams } from './interfaces';
import SegmentPricing from './SegmentPricing';
import SegmentControl from './SegmentPricing/SegmentControl';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

type Props = {
  onClose: () => void;
};

const CreateHistoricalPerformance: React.FC<Props> = ({ onClose }) => {
  const [segmentPricingParams, setSegmentPricingParams] = useState<
    SegmentPricingParams
  >();
  const [canAddData, setCanAddData] = useState(false);
  const { t } = useTranslation();
  const onChangeFilters = (filters: MarketPropertyDataFiltersType) => {
    const {
      market,
      submarket,
      propertyType,
      propertyClass,
      propertySubtype,
    } = filters;

    setSegmentPricingParams({
      ...segmentPricingParams,
      market,
      submarket,
      propertyType,
      propertyClass,
      propertySubtype,
    });
  };

  useEffect(() => {
    setCanAddData(
      !!segmentPricingParams?.market?.id &&
        !!segmentPricingParams?.submarket?.id &&
        !!segmentPricingParams?.propertyClass?.id &&
        !!segmentPricingParams?.propertySubtype?.id &&
        !!segmentPricingParams?.propertyType?.id,
    );
  }, [segmentPricingParams]);

  return (
    <HistoricalPerformanceModal
      onClose={onClose}
      title={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.addPerformance`)}
    >
      <MarketPropertyDataFilter
        filters={{
          market: segmentPricingParams?.market,
          submarket: segmentPricingParams?.submarket,
          propertyClass: segmentPricingParams?.propertyClass,
          propertyType: segmentPricingParams?.propertyType,
          propertySubtype: segmentPricingParams?.propertySubtype,
        }}
        onChangeFilters={onChangeFilters}
      />

      <SegmentControl segmentPricingParams={segmentPricingParams} />

      {!!segmentPricingParams && (
        <SegmentPricing
          onClose={onClose}
          segmentPricingParams={segmentPricingParams}
          setSegmentPricingParams={setSegmentPricingParams}
          canEdit={canAddData}
        />
      )}
    </HistoricalPerformanceModal>
  );
};

export default CreateHistoricalPerformance;
