import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { IProperty } from 'interfaces/IProperty';
import { BUBBLES, GRAPH_DIMENSIONS, Positions } from './graphConstants';
import {
  getBubbleId,
  getCenterLineId,
  getHorizontalLineId,
  getLinePositionId,
  getMarketTooltipLineId,
  getOuterElementPositionId,
  getSVGId,
} from './nodes';
import Bubble from './Elements/Bubble';
import { colors } from 'constants/colors';
import GraphContainer from '../GraphContainer';
import styles from './Age.module.scss';
import { formatBuiltYear, formatRenovatedYears, formatAge } from './format';
import CenterLine from './Elements/CenterLine';
import TooltipLine from './Elements/TooltipLine';
import { AgeGraphDataType, BubblesType, BubbleType } from './types';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_CHART_LABEL_PATH } from 'constants/i18n';

const I18N_AGE_GRAPH_LABEL_PATH = `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.ageGraph`;

interface Props {
  property: IProperty;
  avgBuiltYear: number;
}

const AgeGraph: React.FC<Props> = (props: Props) => {
  const graphContainerElement = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [bubbles, setBubbles] = useState<BubblesType>({});
  const [graphData, setGraphData] = useState<AgeGraphDataType | null>(null);

  const { property, avgBuiltYear } = props;

  useEffect(() => {
    if (!avgBuiltYear) return;

    const renovatedYears =
      property.renovatedYears?.map(item => item?.year)?.sort() || [];

    const isSameYear = property?.builtYear! === avgBuiltYear;

    const buildingIsNewest = property?.builtYear! > avgBuiltYear;

    const wasRenovated = renovatedYears.length > 0;

    // use the last renovated year
    const renovatedYear = wasRenovated
      ? renovatedYears[renovatedYears.length - 1]
      : null;

    let propertyBubble: BubbleType | null = null;
    let marketBubble: BubbleType | null = null;
    let renovatedYearBubble: BubbleType | null = null;

    if (property?.builtYear) {
      let propertyPosition = buildingIsNewest
        ? Positions.RIGHT
        : Positions.LEFT;
      if (isSameYear && !buildingIsNewest) {
        propertyPosition = Positions.CLOSE_RIGHT;
      }

      propertyBubble = {
        year: property.builtYear,
        position: propertyPosition,
        strokeColor: colors.primaryColor500,
        fillColor: colors.primaryColor500,
        secondaryColor: colors.primaryColor700,
        textColor: colors.primaryColor100,
        isYearBottom: true,
        type: 'property',
      };
    }

    if (avgBuiltYear) {
      let mktPosition = Positions.MIDDLE;
      if (renovatedYear && renovatedYear < avgBuiltYear) {
        mktPosition = Positions.RIGHT;
      }
      marketBubble = {
        year: avgBuiltYear,
        position: mktPosition,
        strokeColor: colors.supportive500,
        fillColor: colors.supportive500,
        secondaryColor: colors.supportive500,
        textColor: colors.supportive500,
        hasOpacity: false,
        type: 'market',
      };
    }

    if (renovatedYear && !buildingIsNewest) {
      let renovatedYearPosition = Positions.RIGHT;
      if (avgBuiltYear && renovatedYear < avgBuiltYear) {
        renovatedYearPosition = Positions.MIDDLE;
      }

      renovatedYearBubble = {
        year: renovatedYear,
        position: renovatedYearPosition,
        strokeColor: colors.primaryColor500,
        fillColor: colors.primaryColor500,
        secondaryColor: colors.primaryColor700,
        textColor: colors.primaryColor100,
        hasOpacity: true,
        isYearBottom: true,
        type: 'renovated',
      };
    }

    setGraphData({
      name: 'Comp Set Avg Age',
      avgBuiltYear: avgBuiltYear,
      renovatedYears: renovatedYears,
    });

    setBubbles({
      propertyBubble: propertyBubble,
      propertyRenovatedYearBubble: renovatedYearBubble,
      marketAvgBubble: marketBubble,
      isSameYear,
    });
    setIsReady(true);
  }, [property, avgBuiltYear]);

  const toggleD3Element = (id: string, show: boolean) => {
    d3.select(id).attr('display', show ? '' : 'none');
  };

  const applyHoverBlue = (position: Positions) => {
    if (bubbles?.propertyRenovatedYearBubble?.position) {
      d3.select(
        `#${getBubbleId(bubbles?.propertyRenovatedYearBubble?.position)}`,
      ).attr('fill-opacity', BUBBLES.fillOpacityActive);

      toggleD3Element(`#${getHorizontalLineId()}`, true);
    }

    // Showing the bubble hover and line for the element in Over
    toggleD3Element(`#${getOuterElementPositionId(position)}`, true);
    toggleD3Element(`#${getLinePositionId(position)}`, true);

    toggleD3Element(`#${getCenterLineId()}`, true);

    const positions = [
      bubbles.propertyBubble?.position,
      bubbles.propertyRenovatedYearBubble?.position,
    ];

    positions.forEach(p => {
      if (p) {
        toggleD3Element(`#${getOuterElementPositionId(p)}`, true);
        toggleD3Element(`#${getLinePositionId(p)}`, true);
      }
    });

    toggleD3Element(
      `#${getOuterElementPositionId(Positions.CLOSE_RIGHT)}`,
      true,
    );
    toggleD3Element(`#${getLinePositionId(Positions.CLOSE_RIGHT)}`, true);

    const updateBubble = (pos: Positions) => {
      d3.select(`#${getBubbleId(pos)}`)
        .attr('fill-opacity', BUBBLES.fillOpacityActive)
        .style('fill', colors.primaryColor300)
        .style('stroke', colors.primaryColor300);
    };

    if (positions.includes(Positions.RIGHT)) updateBubble(Positions.RIGHT);
    if (positions.includes(Positions.LEFT)) updateBubble(Positions.LEFT);
  };

  const applyHoverGreen = () => {
    if (!bubbles.marketAvgBubble) return;

    toggleD3Element(`#${getMarketTooltipLineId()}`, true);
    toggleD3Element(
      `#${getOuterElementPositionId(bubbles.marketAvgBubble.position)}`,
      true,
    );
    toggleD3Element(
      `#${getLinePositionId(bubbles.marketAvgBubble.position)}`,
      true,
    );
  };

  const onMouseOverBubble = (position: Positions) => {
    const isGreenBubble =
      position === bubbles.marketAvgBubble?.position ||
      position === Positions.FOOTER;

    isGreenBubble ? applyHoverGreen() : applyHoverBlue(position);
  };

  const onMouseOutBubble = () => {
    toggleD3Element(`#${getHorizontalLineId()}`, false);
    toggleD3Element(`#${getMarketTooltipLineId()}`, false);

    if (bubbles?.propertyBubble?.position) {
      const leftBubble = bubbles?.propertyBubble;
      d3.select(`#${getBubbleId(leftBubble.position)}`)
        .style('fill', leftBubble.fillColor)
        .style('stroke', leftBubble.strokeColor);
    }

    if (bubbles?.propertyRenovatedYearBubble?.position) {
      const rightBubble = bubbles?.propertyRenovatedYearBubble;
      d3.select(`#${getBubbleId(rightBubble.position)}`)
        .style(
          'fill',
          rightBubble.hasOpacity
            ? rightBubble.secondaryColor
            : rightBubble.fillColor,
        )
        .style('stroke', rightBubble.strokeColor);
    }

    Object.values(Positions).forEach(position => {
      toggleD3Element(`#${getOuterElementPositionId(position)}`, false);
      toggleD3Element(`#${getLinePositionId(position)}`, false);
    });
    toggleD3Element(`#${getCenterLineId()}`, false);
  };

  const drawBubbles = () => {
    if (!isReady || !avgBuiltYear) {
      return null;
    }
    const {
      propertyBubble,
      marketAvgBubble,
      propertyRenovatedYearBubble,
    } = bubbles;

    return (
      <>
        {propertyBubble && (
          <Bubble
            bubble={propertyBubble}
            onMouseOverBubble={onMouseOverBubble}
            onMouseOutBubble={onMouseOutBubble}
          />
        )}
        {marketAvgBubble && (
          <Bubble
            bubble={marketAvgBubble}
            onMouseOverBubble={onMouseOverBubble}
            onMouseOutBubble={onMouseOutBubble}
          />
        )}
        {propertyRenovatedYearBubble && (
          <Bubble
            bubble={propertyRenovatedYearBubble}
            onMouseOverBubble={onMouseOverBubble}
            onMouseOutBubble={onMouseOutBubble}
          />
        )}
      </>
    );
  };

  if (!avgBuiltYear) return null;

  return (
    <GraphContainer wrapperClassName={styles.container}>
      <div
        className={styles['title-container']}
        onMouseOver={() => onMouseOverBubble(Positions.TITLE)}
        onMouseOut={() => onMouseOutBubble()}
      >
        <p className={styles['title']}>
          {property.builtYear &&
            translateText(`${I18N_AGE_GRAPH_LABEL_PATH}.buildingAgeTitle`)}
        </p>
        <p className={styles['year']}>
          {formatAge(property.builtYear, property.status?.name)}
        </p>
        <p className={styles['subtitle']}>
          {formatBuiltYear(property?.builtYear)}
          {property.renovatedYears &&
            property.renovatedYears?.length > 0 &&
            formatRenovatedYears(graphData?.renovatedYears)}
        </p>
      </div>
      {isReady && (
        <div className={styles['graph-container']} ref={graphContainerElement}>
          <svg
            id={getSVGId()}
            className={styles['svg-graph']}
            width={GRAPH_DIMENSIONS.width}
            height={GRAPH_DIMENSIONS.height}
          >
            <CenterLine bubbles={bubbles} />
            <TooltipLine bubbles={bubbles} />
            {drawBubbles()}
          </svg>
        </div>
      )}
      <div
        className={styles['footer-container']}
        onMouseOver={() => onMouseOverBubble(Positions.FOOTER)}
        onMouseOut={() => onMouseOutBubble()}
      >
        <p className={styles['title']}>
          {translateText(`${I18N_AGE_GRAPH_LABEL_PATH}.title`)}
        </p>
        <p className={styles['year']}>{formatAge(graphData?.avgBuiltYear)}</p>
        <p className={styles['subtitle']}>
          {formatBuiltYear(graphData?.avgBuiltYear)}
        </p>
      </div>
    </GraphContainer>
  );
};

export default AgeGraph;
