import { Positions } from './graphConstants';

export const getSVGId = () => {
  return 'svg-age-graph';
};

export const getOuterElementPositionId = (position: Positions) => {
  return `age-graph-${position}-outer`;
};

export const getLinePositionId = (position: Positions) => {
  return `age-graph-${position}-line`;
};

export const getMarketTooltipLineId = () => {
  return `age-graph-mkt-tooltip-line`;
};

export const getBubbleId = (position: Positions) => {
  return `age-graph-${position}-bubble`;
};

export const getHorizontalLineId = () => {
  return 'age-graph-horizontal-line';
};

export const getCenterLineId = () => {
  return 'age-graph-center-line';
};
