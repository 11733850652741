import { colors } from 'constants/colors';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';
import { uniq } from 'lodash';

import { VictoryBarStyle, AxisData } from '../VerticalGroupBarChart/types';
import {
  DEFAULT_BAR_STYLE,
  getYAxisValuesFromTotal,
} from 'components/VictoryBarChart/utils';
import {
  abbreviateNumber,
  roundNumberToNearestTenThousand,
} from 'utils/formatters/number';
import { getRemainingCurrentYearQuarters } from 'utils/date';

export const GRAPH_LEGEND = translateText(
  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.expiringLeases`,
);
export const GRAPH_TITLE = translateText(
  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.leaseExpirations`,
);
const BAR_RATIO_GRAPH = 0.9;
export const EXPIRING_CHART_HEIGHT = 180;
export const FIRST_QUARTER = 1;

export const expirationChartPadding = (isQuarterly?: boolean) => {
  return {
    top: 45,
    bottom: 20,
    left: isQuarterly ? 50 : 52,
    right: isQuarterly ? 60 : 50,
  };
};

export const EXPIRATION_BAR_BORDER_RADIUS = { top: 2, bottom: 2 };
export const EXPIRATION_BAR_STYLE = {
  parent: {
    paddingTop: 10,
  },
  data: {
    fill: colors.primaryColor500,
  },
};

const getYAxisValues = (dataExpirations: any) => {
  const maxSize = Math.max(...dataExpirations.map((x: any) => Number(x.total)));
  const ceiledMaxSize = roundNumberToNearestTenThousand(maxSize);
  return getYAxisValuesFromTotal(ceiledMaxSize);
};

const handleYearQuarterData = (data: any, isAnnual: boolean) => {
  return `${data.year}/${isAnnual ? 1 : data.quarter}`;
};

const getXAxisValues = (dataExpirations: any, isAnnual: boolean): string[] => {
  return uniq(
    dataExpirations.map((item: any) => handleYearQuarterData(item, isAnnual)),
  );
};

const handleLeaseExpirationData = (
  dataExpirations: any,
  isAnnual: boolean,
): any => {
  const builtExpirarionsData = dataExpirations.map((data: any) => ({
    ...data,
    x: handleYearQuarterData(data, isAnnual),
    y: data.total,
    quarter: isAnnual ? 1 : data.quarter,
  }));

  const removedQuarters = builtExpirarionsData.slice(
    0,
    builtExpirarionsData?.length - getRemainingCurrentYearQuarters(),
  );

  return isAnnual ? builtExpirarionsData : removedQuarters;
};

export const parseGraphData = (dataExpirations: any, isAnnual: boolean) => {
  const mountedExpirationData = handleLeaseExpirationData(
    dataExpirations,
    isAnnual,
  );

  return {
    xAxis: getXAxisValues(mountedExpirationData, isAnnual),
    yAxis: getYAxisValues(mountedExpirationData),
    items: mountedExpirationData,
  };
};

const getStrokeWidth = (datum: any) => (datum?.tick > 0 ? 1 : 0);

export const getExpirationChartStyle = (
  isQuarterly?: boolean,
  unitOfMeasurement?: string,
): VictoryBarStyle => {
  return {
    ...DEFAULT_BAR_STYLE,
    chart: {
      ...DEFAULT_BAR_STYLE.chart,
      domainPadding: { x: [10, 0] },
      height: 180,
      padding: expirationChartPadding(isQuarterly),
    },
    xAxis: {
      ...DEFAULT_BAR_STYLE.xAxis,
      style: {
        ...DEFAULT_BAR_STYLE.xAxis?.style,
        tickLabels: {
          ...DEFAULT_BAR_STYLE.xAxis?.style.tickLabels,
          fontSize: 5,
        },
      },
    },
    yAxis: {
      ...DEFAULT_BAR_STYLE.yAxis,
      style: {
        ...(DEFAULT_BAR_STYLE.yAxis as AxisData)?.style,
        tickLabels: {
          ...(DEFAULT_BAR_STYLE.yAxis as AxisData)?.style?.tickLabels,
          fontSize: 5,
          fontFamily: 'Open Sans',
        },
        grid: {
          stroke: colors.uiColor800,
          strokeWidth: getStrokeWidth,
        },
        ticks: {
          stroke: colors.uiColor800,
          strokeWidth: getStrokeWidth,
          size: isQuarterly ? 20 : 25,
        },
      },
      offsetX: isQuarterly ? 75 : 80,
      tickFormat: (d: number) =>
        `${`${abbreviateNumber(d)} ${unitOfMeasurement}` ||
          `0 ${unitOfMeasurement}`}`,
    },
    bar: {
      cornerRadius: EXPIRATION_BAR_BORDER_RADIUS,
      style: EXPIRATION_BAR_STYLE,
      tooltip: {
        cornerRadius: 2,
        pointerLength: 0,
        flyoutHeight: 15,
        flyoutStyle: {
          fill: colors.primaryColor500,
          border: 'none',
        },
        y: 45,
      },
      barRatio: BAR_RATIO_GRAPH,
    },
    legend: {
      ...DEFAULT_BAR_STYLE.legend,
      x: 390,
      style: {
        ...DEFAULT_BAR_STYLE.legend?.style,
        labels: {
          ...DEFAULT_BAR_STYLE.legend?.style.labels,
          fontSize: 5,
        },
      },
    },
    label: {
      ...DEFAULT_BAR_STYLE.label,
      x: 10,
      style: [
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 7,
        },
        {
          ...DEFAULT_BAR_STYLE.label?.style,
          fontSize: 5,
        },
      ],
    },
  };
};

export const getExpirationLegend = () => {
  return [
    {
      name: GRAPH_LEGEND,
      symbol: { fill: colors.primaryColor500 },
    },
  ];
};
