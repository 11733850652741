import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { colors } from 'constants/colors';
import {
  getMarketBubbleId,
  getMarketBubbleShadowId,
  getMarketVerticalLineId,
} from '../nodes';
import { GraphClasses } from '../../RecentLeases/graphConstants';
import { hideTooltip, showTooltip } from '../Tooltip/utils';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { IBubbleDataPoint } from 'components/Graphs/BubbleChartsElements/interfaces';

interface Props {
  currencySymbol: string;
  data: IBubbleDataPoint[];
  activeId: string | null;
  onClick?: (data: IBubbleDataPoint) => void;
  onMouseOver?: (id: string) => void;
  onMouseOut?: (id: string) => void;
  xScale: ScaleTime<number, number>;
  yScale: ScaleLinear<number, number>;
  hoverLineColor?: string;
  hasHorizontalLines?: boolean;
}

const DATA_POINT_ACTIVE_RADIUS = 6;

const MarketBubbles: React.FC<Props> = props => {
  const bubblesGroupRef = useRef(null);

  const {
    data,
    activeId = null,
    onClick,
    xScale,
    yScale,
    onMouseOver,
    onMouseOut,
    hoverLineColor = colors.supportive500,
    hasHorizontalLines,
  } = props;

  let previousVerticalLineStroke = '';
  let previousVerticalLineOpacity = 1;

  const handleMouseOver = (d: IBubbleDataPoint) => {
    const bubble = select(`#${getMarketBubbleId(d)}`);
    const verticalLine = select(`#${getMarketVerticalLineId(d)}`);

    bubble.attr('opacity', 1);
    select(`#${getMarketBubbleShadowId(d)}`).attr('opacity', 1);

    previousVerticalLineStroke = verticalLine.attr('stroke');
    previousVerticalLineOpacity = +verticalLine.attr('stroke-opacity');

    verticalLine.attr('stroke', hoverLineColor).attr('stroke-opacity', 1);

    if (hasHorizontalLines) {
      verticalLine.style('display', 'block');
    }

    showTooltip({
      circleXPosition: +bubble.attr('cx'),
      isMarketData: true,
      node: d,
      textColor: d.tooltipTextColor,
      backgroundColor: d.tooltipBackgroundColor || colors.supportive500,
    });

    onMouseOver && onMouseOver(d?.key);
  };

  const handleMouseOut = (d: any) => {
    select(`#${getMarketBubbleId(d)}`).attr('opacity', 0);
    select(`#${getMarketBubbleShadowId(d)}`).attr('opacity', 0);
    const marketVerticalLine = select(`#${getMarketVerticalLineId(d)}`);
    marketVerticalLine
      .attr('stroke', previousVerticalLineStroke || colors.uiColor800)
      .attr('stroke-opacity', previousVerticalLineOpacity);

    if (hasHorizontalLines) {
      marketVerticalLine.style('display', 'none');
    }

    hideTooltip(d.graphId);

    onMouseOut && onMouseOut(d?.key);
  };

  useEffect(() => {
    const node = bubblesGroupRef.current;
    const visibleCircles = data.filter(
      (d: IBubbleDataPoint) => !d.hidden && !!d.countData,
    );

    select(node)
      .selectAll(`.${GraphClasses.LineDataPoint}`)
      .data(visibleCircles)
      .join('circle')
      .attr('id', getMarketBubbleId)
      .attr('class', GraphClasses.LineDataPoint)
      .attr('fill', (d: IBubbleDataPoint) => d.color || colors.supportive500)
      .attr('r', DATA_POINT_ACTIVE_RADIUS)
      .attr('cx', (d: IBubbleDataPoint) => xScale(d.date))
      .attr('cy', (d: IBubbleDataPoint) => yScale(d.value))
      .attr('cursor', 'pointer')
      .attr('opacity', 0)
      .on('mouseover', handleMouseOver)
      .on('mouseout', handleMouseOut);

    select(node)
      .selectAll(`.${GraphClasses.LineDataPointShadow}`)
      .data(visibleCircles)
      .join('circle')
      .attr('id', getMarketBubbleShadowId)
      .attr('class', GraphClasses.LineDataPointShadow)
      .attr('fill', 'transparent')
      .attr('stroke-width', DATA_POINT_ACTIVE_RADIUS)
      .attr('stroke', (d: IBubbleDataPoint) => d.color || colors.supportive500)
      .attr('stroke-opacity', 0.32)
      .attr('r', DATA_POINT_ACTIVE_RADIUS)
      .attr('cx', (d: IBubbleDataPoint) => xScale(d.date))
      .attr('cy', (d: IBubbleDataPoint) => yScale(d.value))
      .attr('cursor', 'pointer')
      .attr('opacity', 0)
      .on('click', d => onClick?.(d))
      .on('mouseover', handleMouseOver)
      .on('mouseout', handleMouseOut);

    if (activeId) {
      const bgLine = data.find(lineData => lineData.key === activeId);

      select(`#vertical-line-mkt-${bgLine?.graphId}-${bgLine?.key}`)
        .attr('stroke', hoverLineColor)
        .attr('stroke-opacity', 1);

      select(node)
        .select(`#market-bubble-${activeId}`)
        .attr('opacity', 1);

      select(node)
        .select(`#market-bubble-shadow-${activeId}`)
        .attr('opacity', 1);
    }

    // eslint-disable-next-line
  }, [data]);

  return <g ref={bubblesGroupRef} />;
};

export default MarketBubbles;
