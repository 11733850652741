import React from 'react';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import LargeDonutGraph from 'components/LargeDonutGraph';
import { IProperty } from 'interfaces/IProperty';
import { useOwnersData } from './useOwnersData';
import {
  parseOwnersDonutData,
  parseOwnersDonutDefaultData,
  parseSubmarketsDonutData,
  parseSubmarketsDonutDefaultData,
} from './utils';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { useTranslation } from 'react-i18next';
import { MarketDonutsCharts } from 'components/MarketProfile/constants';
import {
  I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { useSubmarketsData } from './useSubmarketsData';
import { PROPERTY_COUNT_LIMIT } from 'components/FindComps/PropertiesAnalyticsContainer/ConstructionActivity/DeliveryTimelineChart/utils';

interface Props {
  properties: IProperty[];
  setPropertiesPopup(propertiesPopup: ILargestPropertiesPopup): void;
}

const SubmarketsAndOwners = ({ properties, setPropertiesPopup }: Props) => {
  const { t } = useTranslation();
  const { ownersData, isLoadingOwners } = useOwnersData(properties);
  const { submarketsData, isLoadingSubmarkets } = useSubmarketsData(properties);

  const handleSliceClick = (sliceData: IDonutGraphData | undefined) => {
    setPropertiesPopup({
      isVisible: true,
      popupFilter: { ids: sliceData?.topItems },
      popupTitle: sliceData?.donutType,
      popupSizeLimit: PROPERTY_COUNT_LIMIT,
      popupSubtitle: t(
        `${I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH}.largestInCategory`,
        {
          propertyType: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`),
          category: sliceData?.title,
        },
      ),
    });
  };

  return (
    <Row>
      <Col lg={6} md={6} sm={4} xs={4}>
        <LargeDonutGraph
          defaultData={parseSubmarketsDonutDefaultData(
            submarketsData?.propertiesAnalyticsSubmarketsSize,
            UnitOfMeasurement.sf,
          )}
          donutId={MarketDonutsCharts.DonutTenantIndustries}
          graphData={parseSubmarketsDonutData(
            submarketsData?.propertiesAnalyticsSubmarketsSize,
            UnitOfMeasurement.sf,
            t,
          )}
          isLoading={isLoadingSubmarkets}
          onDonutClick={sliceData => handleSliceClick(sliceData)}
          title={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.submarkets`) || ''}
        />
      </Col>
      <Col lg={6} md={6} sm={4} xs={4}>
        <LargeDonutGraph
          defaultData={parseOwnersDonutDefaultData(
            ownersData?.propertiesAnalyticsOwners,
            UnitOfMeasurement.sf,
            t,
          )}
          donutId={MarketDonutsCharts.DonutOwners}
          graphData={parseOwnersDonutData(
            ownersData?.propertiesAnalyticsOwners,
            UnitOfMeasurement.sf,
            t,
          )}
          isLoading={isLoadingOwners}
          onDonutClick={sliceData => handleSliceClick(sliceData)}
          title={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.owners`) || ''}
        />
      </Col>
    </Row>
  );
};

export default SubmarketsAndOwners;
