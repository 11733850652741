import { CountryCodes } from 'constants/countryCodes';

export const CURRENCY_CODES_BY_COUNTRY: { [key: string]: string } = {
  [CountryCodes.US]: 'USD',
  [CountryCodes.CA]: 'CAD',
  [CountryCodes.GB]: 'GBP',
  [CountryCodes.UK]: 'GBP',
  [CountryCodes.AD]: 'EUR',
  [CountryCodes.AL]: 'EUR',
  [CountryCodes.AT]: 'EUR',
  [CountryCodes.AX]: 'EUR',
  [CountryCodes.BA]: 'EUR',
  [CountryCodes.BE]: 'EUR',
  [CountryCodes.BG]: 'EUR',
  [CountryCodes.BY]: 'EUR',
  [CountryCodes.CH]: 'EUR',
  [CountryCodes.CY]: 'EUR',
  [CountryCodes.CZ]: 'EUR',
  [CountryCodes.DE]: 'EUR',
  [CountryCodes.DK]: 'EUR',
  [CountryCodes.EE]: 'EUR',
  [CountryCodes.ES]: 'EUR',
  [CountryCodes.FI]: 'EUR',
  [CountryCodes.FO]: 'EUR',
  [CountryCodes.FR]: 'EUR',
  [CountryCodes.GG]: 'EUR',
  [CountryCodes.GR]: 'EUR',
  [CountryCodes.HR]: 'EUR',
  [CountryCodes.HU]: 'EUR',
  [CountryCodes.IE]: 'EUR',
  [CountryCodes.IM]: 'EUR',
  [CountryCodes.IC]: 'EUR',
  [CountryCodes.IT]: 'EUR',
  [CountryCodes.JE]: 'EUR',
  [CountryCodes.LI]: 'EUR',
  [CountryCodes.LT]: 'EUR',
  [CountryCodes.LU]: 'EUR',
  [CountryCodes.LV]: 'EUR',
  [CountryCodes.MC]: 'EUR',
  [CountryCodes.MD]: 'EUR',
  [CountryCodes.MK]: 'EUR',
  [CountryCodes.MT]: 'EUR',
  [CountryCodes.NL]: 'EUR',
  [CountryCodes.NO]: 'EUR',
  [CountryCodes.PL]: 'EUR',
  [CountryCodes.PT]: 'EUR',
  [CountryCodes.RO]: 'EUR',
  [CountryCodes.RU]: 'EUR',
  [CountryCodes.SE]: 'EUR',
  [CountryCodes.SI]: 'EUR',
  [CountryCodes.SJ]: 'EUR',
  [CountryCodes.SK]: 'EUR',
  [CountryCodes.SM]: 'EUR',
  [CountryCodes.UA]: 'EUR',
  [CountryCodes.VA]: 'EUR',
};
