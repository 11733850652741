import { ISnowflakeBlockPropertyAvailability } from 'interfaces/ISnowflakeBlockAvailability';
import { getCurrencySymbol } from 'utils/formatters/currency';

export const getAvailabilityCurrencySymbol = (
  propertyAvailability?: ISnowflakeBlockPropertyAvailability,
) => {
  const firstAvailability = (propertyAvailability?.availabilities || [])[0];

  // We assume that all the availabilities in the block will have the same currency,
  // so we get the currency code from the first one. If there's no value, we default to USD.
  return getCurrencySymbol(firstAvailability?.currency || getCurrencySymbol());
};
