import React, { useState } from 'react';

interface MediaNotesProviderProps {
  children: React.ReactNode;
}

interface MediaNote {
  mediaKey: string;
  note: string;
}

interface IMediaNotesContext {
  mediaNotes: MediaNote[];
  addOrUpdateMediaNote: (mediaKey: string, note: string) => void;
  getMediaNoteByKey: (mediaKey: string) => string | undefined;
}

export const MediaNotesContext = React.createContext<IMediaNotesContext>({
  mediaNotes: [],
  addOrUpdateMediaNote: () => null,
  getMediaNoteByKey: () => undefined,
});

export const MediaNotesProvider = ({ children }: MediaNotesProviderProps) => {
  const [mediaNotes, setMediaNotes] = useState([] as MediaNote[]);

  const addOrUpdateMediaNote = (mediaKey: string, note: string) => {
    const existingNoteIndex = mediaNotes.findIndex(
      item => item.mediaKey === mediaKey,
    );

    if (existingNoteIndex !== -1) {
      const updatedMediaNotes = [...mediaNotes];
      updatedMediaNotes[existingNoteIndex].note = note;
      setMediaNotes(updatedMediaNotes);
    } else {
      setMediaNotes(prevNotes => [
        ...prevNotes,
        { mediaKey: mediaKey, note: note },
      ]);
    }
  };

  const getMediaNoteByKey = (mediaKey: string) => {
    return mediaNotes.find(item => item.mediaKey === mediaKey)?.note;
  };

  return (
    <MediaNotesContext.Provider
      value={{ mediaNotes, addOrUpdateMediaNote, getMediaNoteByKey }}
    >
      {children}
    </MediaNotesContext.Provider>
  );
};
