import { capitalize, lowerCase, map, split, startCase } from 'lodash';
import { formatDate } from 'utils/formatters/date';
import { ITableCell } from 'interfaces/ITableCell';
import { formatNumberWithCommas } from 'utils/formatters/number';
import { TranslateFunction } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { Link } from 'react-router-dom';
import locations from 'routes';
import classNames from 'classnames';
import styles from './TimsTableContainer.module.scss';
import React from 'react';

export const timsTableHeader = (
  t: TranslateFunction,
  measurement: UnitOfMeasurement,
) => [
  {
    header: capitalize(t(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenant`)),
    id: 'tenantName',
    accessor: 'tenantCompany.name',
    Cell: ({ cell }: ITableCell) => {
      const dest = cell?.row?.original?.tenantCompany?.id;

      if (!dest) return null;

      return (
        <Link to={locations.showCompany(dest)} className={styles['cell-link']}>
          <div
            className={classNames(styles['cell-tenant'], styles['capitalized'])}
          >
            {cell.value}
          </div>
        </Link>
      );
    },
    width: 241,
    sticky: 'left',
  },
  {
    id: 'timStatus',
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.timStatus`),
    accessor: 'timStatusType.name',
  },
  {
    header: capitalize(t(`${I18N_PLATFORM_COMMON_WORD_PATH}.industry`)),
    id: 'industry',
    accessor: 'tenantCompany.industry.name',
    Cell: ({ cell: { value } }: ITableCell) =>
      map(split(value, /\s+/), capitalize).join(' '),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.date`),
    id: 'activeSinceDate',
    accessor: 'activeSinceDate',
    Cell: ({ cell: { value } }: ITableCell) => formatDate(value, 'MMM YYYY'),
  },
  {
    header: `${t(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.size`,
    )} ${measurement} (${lowerCase(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.min`),
    )})`,
    id:
      measurement === UnitOfMeasurement.sm
        ? 'targetSizeMinMt'
        : 'targetSizeMin',
    accessor:
      measurement === UnitOfMeasurement.sm
        ? 'targetSizeMinMt'
        : 'targetSizeMin',
    Cell: ({ cell: { value } }: ITableCell) => formatNumberWithCommas(value),
  },
  {
    header: `${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.size`)} ${measurement}
      (${lowerCase(t(`${I18N_PLATFORM_COMMON_WORD_PATH}.max`))})`,
    id:
      measurement === UnitOfMeasurement.sm
        ? 'targetSizeMaxMt'
        : 'targetSizeMax',
    accessor:
      measurement === UnitOfMeasurement.sm
        ? 'targetSizeMaxMt'
        : 'targetSizeMax',
    Cell: ({ cell: { value } }: ITableCell) => formatNumberWithCommas(value),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.targetCommencementDate`),
    id: 'targetCommencementDate',
    accessor: 'targetCommencementDate',
    Cell: ({ cell: { value } }: ITableCell) => formatDate(value, 'MMM YYYY'),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`),
    id: 'market',
    accessor: 'targetMarket.name',
  },
  {
    header: `${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.submarket`)}`,
    id: 'submarket',
    accessor: 'targetSubmarkets',
    Cell: ({ cell: { value } }: ITableCell) => {
      if (value === null) {
        return '';
      } else {
        const submarkets: string[] = [];
        value.forEach((submarket: { name: string }) => {
          submarkets.push(submarket.name);
        });
        return submarkets.join(', ');
      }
    },
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.propertyType`),
    id: 'propertyType',
    accessor: 'targetPropertyType.name',
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.class`),
    id: 'propertyClass',
    accessor: 'targetThirdPartyPropertyClass.name',
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.currentAddress`),
    id: 'currentAddress',
    accessor: 'currentProperty.primaryAddress',
    Cell: ({ cell }: ITableCell) => {
      const dest = cell?.row?.original?.currentPropertyId;

      if (!dest) return null;
      return (
        <Link
          to={locations.showProperty(cell.row.original.currentPropertyId)}
          className={styles['cell-link']}
        >
          <div className={styles['cell-property']}>{cell.value}</div>
        </Link>
      );
    },
  },
  {
    header: `${t(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.currentSize`,
    )} ${measurement}`,
    id: 'currentSize',
    accessor:
      measurement === UnitOfMeasurement.sm ? 'currentSizeMt' : 'currentSize',
    Cell: ({ cell: { value } }: ITableCell) => formatNumberWithCommas(value),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenantRep`),
    accessor: 'tenantRepBrokerCompany.name',
    id: 'tenantRepBrokerCompany',
    Cell: ({ cell: { value } }: ITableCell) => startCase(value),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenantRepBroker`),
    accessor: 'tenantRepBroker.name',
    id: 'tenantRepBroker',
    Cell: ({ cell: { value } }: ITableCell) => startCase(value),
  },
  {
    header: `${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.clearHeight`)} (${lowerCase(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.min`),
    )})`,
    accessor: 'clearHeightLow',
    id: 'clearHeightLow',
    Cell: ({ cell: { value } }: ITableCell) => formatNumberWithCommas(value),
  },
  {
    header: `${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.clearHeight`)} (${lowerCase(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.max`),
    )})`,
    accessor: 'clearHeightHigh',
    id: 'clearHeightHigh',
    Cell: ({ cell: { value } }: ITableCell) => formatNumberWithCommas(value),
  },
  {
    header: t(`avantPlatform.attributes.property.label.loadingDocks`),
    accessor: 'loadingDocks',
    id: 'loadingDocks',
  },
  {
    header: t(`avantPlatform.attributes.property.label.driveInDoors`),
    accessor: 'driveInDoors',
    id: 'driveInDoors',
  },
  {
    header: t(`avantPlatform.attributes.property.label.trailerParkingSpaces`),
    accessor: 'trailerParkingSpaces',
    id: 'trailerParkingSpaces',
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.dateLastDiscussed`),
    accessor: 'lastDiscussedDate',
    id: 'lastDiscussedDate',
    Cell: ({ cell: { value } }: ITableCell) => formatDate(value, 'MMM YYYY'),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.comments`),
    accessor: 'comments',
    id: 'comments',
  },
];
