import { MarketMapOptions } from 'components/MarketProfile/constants';
import { StatusTypes } from 'constants/statusTypes';
import { isEmpty } from 'lodash';
import mapboxgl from 'mapbox-gl';
import { removeTypenameKey } from 'utils/graphql/typename';
import {
  getGeoJsonFeaturesCollection,
  getBoundsFromGeometry,
} from 'utils/maps/mapBox';
import {
  MARKET_BOUNDARIES,
  MARKET_LAYER,
  MARKET_BORDER,
  BOUNDARIES_BORDER_STYLE,
  BOUNDARIES_STYLE,
  DEFAULT_ZOOM,
} from './constants';

const { DEVELOPMENT } = MarketMapOptions;

export const getPropertyStatusesFilter = (activeOption?: MarketMapOptions) =>
  activeOption === DEVELOPMENT
    ? [
        { name: StatusTypes.UnderRenovation },
        { name: StatusTypes.UnderConstruction },
      ]
    : undefined;

export const loadMarketBoundariesOnMap = async (
  map: mapboxgl.Map,
  boundaries: any,
) => {
  const bounds = new mapboxgl.LngLatBounds();
  const subMarketsNames: any = [];
  const features = boundaries
    ?.map((submarket: any) => {
      if (submarket?.geometry?.type && submarket?.geometry?.coordinates) {
        bounds.extend(getBoundsFromGeometry(submarket.geometry));
        subMarketsNames.push(submarket.name);
        return {
          type: 'Feature',
          submarketName: submarket.name,
          geometry: removeTypenameKey(submarket.geometry),
        };
      }
    })
    .filter(Boolean);

  map.addSource(MARKET_BOUNDARIES, getGeoJsonFeaturesCollection(features));

  map.addLayer({
    id: MARKET_BORDER,
    type: 'line',
    source: MARKET_BOUNDARIES,
    paint: BOUNDARIES_BORDER_STYLE,
  });

  map.addLayer({
    id: MARKET_LAYER,
    type: 'fill',
    source: MARKET_BOUNDARIES,
    paint: BOUNDARIES_STYLE,
  });

  if (!isEmpty(bounds)) {
    map.fitBounds(bounds, {
      maxZoom: DEFAULT_ZOOM,
    });
  }
};
