import dayjs from 'dayjs';
import { formatDate } from 'utils/formatters/date';
import { DropdownOption } from '../Dropdown';

export const subtractDaysToDate = (
  date: string | null,
  daysToAdd: number,
  format = 'YYYY-MM-DD',
): string => {
  if (!date) return '';

  return dayjs(date)
    .subtract(daysToAdd, 'day')
    .format(format);
};

export const getDateRangeFilter = (dateOption: DropdownOption) => {
  const date = new Date();
  date.setSeconds(date.getSeconds() + 15);
  return {
    fields: {
      and: [
        {
          op: 'gte',
          key: 'activityDate',
          value: subtractDaysToDate(date.toString(), +dateOption.value),
        },
        {
          op: 'lte',
          key: 'activityDate',
          value: formatDate(date.toString(), 'YYYY-MM-DDTHH:mm:ss'),
        },
      ],
    },
  };
};
