import React from 'react';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import { I18N_AVANT_PROPERTY_PAGES } from 'constants/i18n';
import { useTranslation } from 'react-i18next';
import { ILargestLeasesPopup } from 'stores/LeasesAnalytics/types';
import { getDonutsData } from 'components/FindComps/LeasesAnalyticsContainer/Leasing/DonutsCharts/utils';
import { useDonutsData } from 'components/FindComps/LeasesAnalyticsContainer/Leasing/DonutsCharts/useDonutsData';
import PropertyDonutsCharts from 'components/Graphs/PropertyDonutsCharts';
import { PropertyDonutsChartsIDs } from 'components/Graphs/PropertyDonutsCharts/constants';

interface Props {
  propertiesIds: number[];
  setLeasesPopup?: (popup: ILargestLeasesPopup) => void;
  unitOfMeasurement?: string;
}

const DonutsCharts: React.FC<Props> = ({
  propertiesIds,
  setLeasesPopup,
  unitOfMeasurement,
}) => {
  const { t } = useTranslation();
  const leasesFilter = { propertiesIds };
  const donutsData = getDonutsData(
    useDonutsData(leasesFilter),
    unitOfMeasurement || UnitOfMeasurement.sf,
  );

  const onDonutClick = (
    donutId: PropertyDonutsChartsIDs,
    graphData?: IDonutGraphData,
  ) => {
    setLeasesPopup?.({
      leasesIds: graphData?.propsToClick.leasesIds,
      isVisible: true,
      popupTitle: graphData?.propsToClick.popupTitle,
      popupSubtitle: `${t(
        `${I18N_AVANT_PROPERTY_PAGES}.findComps.subtitles.topLargestLeases`,
        { n: 50 },
      )} ${graphData?.title}`,
      filter: {
        ...leasesFilter,
        fields: {
          ...graphData?.propsToClick?.filterLeases,
        },
        industries: graphData?.propsToClick?.filterIndustries,
      },
      hideAction: true,
    });
  };

  return (
    <PropertyDonutsCharts donutsData={donutsData} onDonutClick={onDonutClick} />
  );
};

export default DonutsCharts;
