import React, { useMemo, useState } from 'react';
import { Container } from 'components/@codelitt/ay-design-library';
import AgencyLeasingHeader from 'components/AgencyAssignments/AgencyLeasingHeader';
import RecentActivityControl from '../../AgencyLeasingHeader/RecentActivityControl';
import RecentActivityContent from 'components/RecentActivityContent';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { IdName } from 'interfaces/IdName';
import { translateText } from 'utils/i18n';
import {
  getQueryToDownloadPropertyActivities,
  recentActivityFilter,
} from './utils';
import { RecentActivityProps } from './types';

const RecentActivity = ({ agencyLeasing }: RecentActivityProps) => {
  const propertyIds = useMemo(() => {
    if (agencyLeasing?.properties) {
      return agencyLeasing.properties?.map(property => property.id!);
    }

    return [];
  }, [agencyLeasing]);

  const [showAddScoopModal, setShowAddScoopModal] = useState(false);
  const [hasActivities, setHasActivities] = useState(false);
  const [selectedScoopCategories, setSelectedScoopCategories] = useState<
    IdName[]
  >([]);

  return (
    <Container>
      <AgencyLeasingHeader
        breadcrumbLevels={[
          { content: agencyLeasing?.name },
          {
            content: translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.recentActivity`,
            ),
          },
        ]}
        actionContent={
          <RecentActivityControl
            disableDownloadButton={!hasActivities}
            onAddScoopButtonClick={() => setShowAddScoopModal(true)}
            queryVariablesToDownload={getQueryToDownloadPropertyActivities(
              propertyIds,
            )}
          />
        }
      />
      <RecentActivityContent
        {...recentActivityFilter(propertyIds)}
        preselectedProperty={agencyLeasing?.targetProperty}
        setHasActivities={setHasActivities}
        selectedScoopCategories={selectedScoopCategories}
        showAddScoopModal={showAddScoopModal}
        setShowAddScoopModal={setShowAddScoopModal}
        setSelectedScoopCategories={setSelectedScoopCategories}
      />
    </Container>
  );
};

export default RecentActivity;
