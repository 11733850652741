import { DONUT_HEIGHTS, DONUT_SIZE, DONUT_WIDTHS } from './graphConstants';

export const getSvgDimensions = (size?: DONUT_SIZE, customSize?: number) => {
  if (customSize) {
    return { width: customSize, height: customSize };
  }

  const donutSize = size || DONUT_SIZE.REGULAR;

  return { width: DONUT_WIDTHS[donutSize], height: DONUT_HEIGHTS[donutSize] };
};

export const getDonutColor = (
  firstColor: string,
  secondColor: string,
  index: number,
): string => {
  if (index === 0) return firstColor;

  return secondColor;
};
