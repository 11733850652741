import React, { useEffect, useRef, useState } from 'react';
import { IProperty } from 'interfaces/IProperty';
import styles from './HoldPeriod.module.scss';
import { graphDimensions, HoldPeriodSegment } from './graphConstants';
import { IHoldPeriodData } from './interfaces';
import Bars from './Elements/Bars';
import GraphContainer from '../GraphContainer';
import { useQuery } from '@apollo/client';
import { SALES_HOLD_PERIOD_QUERY } from 'graphql/sales';
import Legend from 'components/Graphs/HoldPeriod/Elements/Legend';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { isNil } from 'lodash';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_CHART_LABEL_PATH } from 'constants/i18n';

interface Props {
  activePropertySet: IPropertySet | null;
  hasDataChanges?: boolean;
  property: IProperty;
  showComponent?: (value: boolean) => void;
}

const HoldPeriodGraph: React.FC<Props> = props => {
  const { activePropertySet, property, showComponent, hasDataChanges } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [isGraphReady, setIsGraphReady] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [activeSegment, setActiveSegment] = useState<HoldPeriodSegment | null>(
    null,
  );

  const { loading, data, refetch: refetchSalesData } = useQuery<{
    salesHoldPeriod: IHoldPeriodData;
  }>(SALES_HOLD_PERIOD_QUERY, {
    variables: {
      propertyId: property.id,
      propertySetId: activePropertySet?.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (hasDataChanges) refetchSalesData?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDataChanges]);

  const graphData: IHoldPeriodData | undefined = data?.salesHoldPeriod;
  const isBuiltInTheFuture = graphData?.holdPeriodYears! < 0;

  useEffect(() => {
    if (isGraphReady || !containerRef.current) {
      return;
    }
    const containerWidth =
      containerRef.current!.getBoundingClientRect().width -
      graphDimensions.MARGINS.left -
      graphDimensions.MARGINS.right;

    setContainerWidth(containerWidth);
    setIsGraphReady(true);
  }, [loading, graphData, isGraphReady]);

  if (loading) return null;

  const hasNoData = !graphData || !graphData.mktAverageHoldYears;

  showComponent?.(!hasNoData);

  if (hasNoData) return null;

  const isBuildingInfoVisible = !isNil(graphData?.holdPeriodYears);

  const renderSVG = () => {
    if (containerWidth <= 0) return null;

    return (
      <svg
        className={styles['svg-graph']}
        width={containerWidth}
        height={
          graphDimensions.HEIGHT +
          graphDimensions.MARGINS.top +
          graphDimensions.MARGINS.bottom
        }
      >
        <g
          transform={`translate(${graphDimensions.MARGINS.left}, ${graphDimensions.MARGINS.top})`}
        >
          <Bars
            data={graphData!}
            containerWidth={containerWidth}
            isBuiltInTheFuture={isBuiltInTheFuture}
            onMouseOut={() => setActiveSegment(null)}
            isBuildingInfoVisible={isBuildingInfoVisible}
            onMouseOver={(segment: HoldPeriodSegment) =>
              setActiveSegment(segment)
            }
          />
        </g>
      </svg>
    );
  };

  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  return (
    <GraphContainer wrapperClassName={styles['hold-period-graph-container']}>
      <div className={styles.container} ref={containerRef}>
        <p className={styles.title}>
          {translateText(
            `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.holdPeriod.title`,
          )}
        </p>

        {graphData && (
          <Legend
            data={graphData!}
            activeSegment={activeSegment}
            isBuiltInTheFuture={isBuiltInTheFuture}
            isBuildingInfoVisible={isBuildingInfoVisible}
            unitOfMeasurement={unitOfMeasurement}
          />
        )}
        {renderSVG()}
      </div>
    </GraphContainer>
  );
};

export default HoldPeriodGraph;
