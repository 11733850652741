import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import locations from 'routes';
import { ITIM } from 'interfaces/ITIM';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import ConfirmationModal from 'components/ConfirmationModal';
import { translateText, translateText as t } from 'utils/i18n';
import { useCompanyLogo } from 'hooks/useCompanyLogo';
import { ICompany } from 'interfaces/ICompany';
import { CompType } from 'constants/compType';
import ModalWrapper from 'components/ModalWrapper';
import TenantInMarketEditForm from 'components/CreateComps/FormSection/TenantInMarketEditForm';
import TenantHeader from './TenantHeader';
import styles from './TenantCard.module.scss';
import TenantBody from './TenantBody';

interface Props {
  tim?: ITIM;
  isLoading: boolean;
  onDelete?: () => void;
  company?: ICompany | null;
  hasDeleteButton?: boolean;
  displayFormOnEdit?: boolean;
  refetchScoopList?: () => void;
  isCarouselView?: boolean;
  customImageUrl?: string;
  imageClassName?: string;
}

const TenantCard = ({
  tim,
  isLoading,
  onDelete,
  company,
  hasDeleteButton = true,
  displayFormOnEdit,
  refetchScoopList,
  isCarouselView,
  customImageUrl,
  imageClassName,
}: Props) => {
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const history = useHistory();
  const { companyLogo } = useCompanyLogo(company ?? undefined);

  const onEdit = () => {
    if (displayFormOnEdit) {
      setShowFormModal(true);
      return;
    }

    if (!tim?.id) return;
    history.push(
      locations.editComps(tim.id, CompType.tenantInMarket.toLowerCase()),
    );
  };

  const onDeleteTim = async () => {
    await onDelete?.();
    setIsShowDeleteModal(false);
    history.push(locations.newComps());
  };

  const renderDeleteModal = () => {
    return (
      isShowDeleteModal && (
        <ConfirmationModal
          paragraph={[
            `${t(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.aboutToDeleteTimComp`,
            )}.`,
            t(`${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.askToProceed`),
          ]}
          negativeButton={{
            label: isLoading
              ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`)
              : t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`),
            isDisabled: isLoading,
            onclick: onDeleteTim,
          }}
          positiveButton={{
            label: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`),
            onclick: () => {
              setIsShowDeleteModal(false);
            },
          }}
          onCloseModal={() => setIsShowDeleteModal(false)}
        />
      )
    );
  };

  return (
    <div className={styles['container']}>
      <TenantHeader
        tim={tim}
        onEdit={onEdit}
        setIsShowDeleteModal={setIsShowDeleteModal}
        companyLogo={customImageUrl || companyLogo}
        hasDeleteButton={hasDeleteButton}
        isCarouselView={isCarouselView}
        imageClassName={imageClassName}
      />
      <div className={styles['card-separator']} />
      <TenantBody tim={tim} isCarouselView={isCarouselView} />
      {renderDeleteModal()}
      {showFormModal && (
        <ModalWrapper
          fullScreen
          withCloseButton
          withCloseLabel
          onCancel={() => setShowFormModal(false)}
        >
          <TenantInMarketEditForm
            TIMId={String(tim?.id)}
            onUpdated={() => setShowFormModal(false)}
            onCancel={() => setShowFormModal(false)}
            onDeleted={() => {
              setShowFormModal(false);
              refetchScoopList?.();
            }}
            redirectToShowCompsPage
            showDeleteButton
            title={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.editTimsComp`,
            )}
          />
        </ModalWrapper>
      )}
    </div>
  );
};

export default TenantCard;
