export type PathCoordinate = {
  x: number;
  y: number;
};

const CORNER_RADIUS = 0.85;
const CURVE_SIZE = 10;

/**
 * This function draws a triangle and apply a rounded path in the center point of the triangle.
 * Details: https://stackoverflow.com/a/61917987/1286937
 * @param baseLeftPoint X and Y of the left point
 * @param centerPoint X and Y of the center point
 * @param baseRightPoint X and Y of the right point
 */
export const roundedTrianglePath = (
  baseLeftPoint: PathCoordinate,
  centerPoint: PathCoordinate,
  baseRightPoint: PathCoordinate,
) => {
  const qX =
    baseLeftPoint.x + CORNER_RADIUS * (centerPoint.x - baseLeftPoint.x);
  const qY =
    baseLeftPoint.y + CORNER_RADIUS * (centerPoint.y - baseLeftPoint.y);
  const q = `Q ${centerPoint.x} ${centerPoint.y} ${qX + CURVE_SIZE} ${qY}`;

  return `
    M ${baseLeftPoint.x} ${baseLeftPoint.y}
    L ${qX} ${qY}
    ${q}
    L ${baseRightPoint.x} ${baseRightPoint.y}
    Z
  `;
};

/**
 * This function draws a Rhombus and apply a rounded path in the top and bottom points.
 * Details: https://stackoverflow.com/a/61917987/1286937
 */
export const rhombusPath = (
  baseLeftPoint: PathCoordinate,
  topPoint: PathCoordinate,
  baseRightPoint: PathCoordinate,
  bottomPoint: PathCoordinate,
) => {
  // This calculation will generate a point at the top of the Rhombus to draw a Bézier Curve.
  // It creates an intermediary point, positioned 85% before the top point, and draw a curve, from left to right,
  // starting from the point.
  const qX = baseLeftPoint.x + CORNER_RADIUS * (topPoint.x - baseLeftPoint.x);
  const qY = baseLeftPoint.y + CORNER_RADIUS * (topPoint.y - baseLeftPoint.y);
  const q = `Q ${topPoint.x} ${topPoint.y} ${qX + CURVE_SIZE} ${qY}`;

  // This calculation will generate a point at the bottom of the Rhombus to draw a Bézier Curve.
  // It creates an intermediary point, positioned 85% before the bottom point, and draw a curve from right to left,
  // starting from the point.
  const qXBottom =
    baseRightPoint.x - CORNER_RADIUS * (baseRightPoint.x - bottomPoint.x);
  const qYBottom =
    baseRightPoint.y + CORNER_RADIUS * (bottomPoint.y - baseRightPoint.y);
  const qBottom = `Q ${bottomPoint.x} ${bottomPoint.y} ${qXBottom -
    CURVE_SIZE} ${qYBottom}`;

  return `
    M ${baseLeftPoint.x} ${baseLeftPoint.y}
    L ${qX} ${qY}
    ${q}
    L ${baseRightPoint.x} ${baseRightPoint.y}
    L ${qXBottom} ${qYBottom}
    ${qBottom}
    Z
  `;
};
