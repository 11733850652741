import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { colors } from 'constants/colors';
import { GraphIds } from '../nodes';
import { graphDimensions, HoldPeriodSegment } from '../graphConstants';
import { IHoldPeriodData } from '../interfaces';
import { roundedRectPath } from '../../utils/rectPathDrawer';
import {
  onMouseOutSecondBar,
  onMouseOutFirstBar,
  onMouseOverSecondBar,
  onMouseOverFirstBar,
} from './hoverEffect';
import Tooltips from './Tooltips';

import {
  MIN_WIDTH_BAR_LEFT,
  MIN_WIDTH_BAR_RIGHT,
  SHADOW_OPACITY,
} from './constants';

interface Props {
  data: IHoldPeriodData;
  containerWidth: number;
  onMouseOut: () => void;
  isBuildingInfoVisible: boolean;
  isBuiltInTheFuture: boolean;
  onMouseOver: (segment: HoldPeriodSegment) => void;
}

const Bars: React.FC<Props> = (props: Props) => {
  const barRef = useRef(null);
  const {
    data,
    onMouseOut,
    onMouseOver,
    containerWidth,
    isBuildingInfoVisible,
    isBuiltInTheFuture,
  } = props;
  const isMktHigher = data.holdPeriodYears < data.mktAverageHoldYears;

  const drawBarsBuildinghigher = () => {
    const buildingHoldWidthProportion =
      data.mktAverageHoldYears / data.holdPeriodYears;

    const mktHoldWidth = Math.min(
      buildingHoldWidthProportion * containerWidth,
      containerWidth - MIN_WIDTH_BAR_RIGHT,
    );
    const buildingWidth = Math.max(
      containerWidth - mktHoldWidth,
      MIN_WIDTH_BAR_RIGHT,
    );

    return {
      firstRectWidth: mktHoldWidth,
      secondRectWidth: buildingWidth,
    };
  };

  const drawBarsMkthigher = () => {
    const buildingHoldWidthProportion =
      data.holdPeriodYears / data.mktAverageHoldYears;

    let buildingWidth = 0;
    let mktHoldWidth = containerWidth;

    if (isBuildingInfoVisible) {
      buildingWidth = Math.max(
        buildingHoldWidthProportion * containerWidth,
        MIN_WIDTH_BAR_LEFT,
      );

      mktHoldWidth = containerWidth - buildingWidth;
    }

    return {
      firstRectWidth: buildingWidth,
      secondRectWidth: mktHoldWidth,
    };
  };

  const { firstRectWidth, secondRectWidth } = isMktHigher
    ? drawBarsMkthigher()
    : drawBarsBuildinghigher();

  useEffect(() => {
    const firstRectPath = roundedRectPath(
      0,
      0,
      isBuiltInTheFuture ? containerWidth : firstRectWidth,
      graphDimensions.SEGMENT_HEIGHT,
      graphDimensions.SEGMENT_RADIUS,
      true,
      isBuiltInTheFuture,
      true,
      isBuiltInTheFuture,
    );
    const firstRectShadowPath = roundedRectPath(
      0,
      0,
      firstRectWidth,
      graphDimensions.SEGMENT_HEIGHT,
      graphDimensions.SEGMENT_RADIUS,
      true,
      true,
      true,
      true,
    );

    const secondRectPath = roundedRectPath(
      firstRectWidth,
      0,
      secondRectWidth,
      graphDimensions.SEGMENT_HEIGHT,
      graphDimensions.SEGMENT_RADIUS,
      !isBuildingInfoVisible,
      true,
      !isBuildingInfoVisible,
      true,
    );

    const shadowWidthSide = graphDimensions.SHADOW_WIDTH / 2;
    const xyShadowCoordinates = -(shadowWidthSide / 2);
    const barShadowPath = roundedRectPath(
      xyShadowCoordinates,
      xyShadowCoordinates,
      containerWidth + shadowWidthSide,
      graphDimensions.SEGMENT_HEIGHT + shadowWidthSide,
      graphDimensions.SEGMENT_RADIUS + 2,
      true,
      true,
      true,
      true,
    );

    const barsGroupNode = select(barRef.current);

    const firstFillColor = isMktHigher
      ? colors.supportive500
      : colors.primaryColor500;
    const secondFillColor = isMktHigher
      ? colors.primaryColor500
      : colors.supportive500;

    props.isBuildingInfoVisible &&
      barsGroupNode
        .append('path')
        .attr('id', GraphIds.FirstRectBar)
        .attr('d', firstRectPath)
        .attr('stroke', firstFillColor)
        .attr('fill', firstFillColor)
        .on('mouseover', () => onMouseOverFirstBar(isMktHigher, onMouseOver))
        .on('mouseout', () => onMouseOutFirstBar(isMktHigher, onMouseOut));

    barsGroupNode
      .append('path')
      .attr('id', GraphIds.SecondRectBar)
      .attr('d', secondRectPath)
      .attr('fill', secondFillColor)
      .attr('cursor', 'pointer')
      .style('display', isBuiltInTheFuture ? 'none' : 'block')
      .on('mouseover', () => onMouseOverSecondBar(isMktHigher, onMouseOver))
      .on('mouseout', () => onMouseOutSecondBar(isMktHigher, onMouseOut));

    props.isBuildingInfoVisible &&
      barsGroupNode
        .append('path')
        .attr('id', GraphIds.FirstRectShadowBar)
        .attr('d', firstRectShadowPath)
        .attr('stroke', firstFillColor)
        .attr('stroke-opacity', SHADOW_OPACITY)
        .attr('stroke-width', graphDimensions.SHADOW_WIDTH)
        .attr('fill', 'transparent')
        .attr('cursor', 'pointer')
        .style('display', 'none')
        .on('mouseover', () => onMouseOverFirstBar(isMktHigher, onMouseOver))
        .on('mouseout', () => onMouseOutFirstBar(isMktHigher, onMouseOut));

    barsGroupNode
      .append('path')
      .attr('id', GraphIds.SecondRectShadowBar)
      .attr('d', barShadowPath)
      .attr('stroke', secondFillColor)
      .attr('stroke-opacity', SHADOW_OPACITY)
      .attr('stroke-width', graphDimensions.SHADOW_WIDTH / 2)
      .attr('fill', 'none')
      .style('display', 'none')
      .on('mouseover', () => onMouseOverSecondBar(isMktHigher, onMouseOver))
      .on('mouseout', () => onMouseOutSecondBar(isMktHigher, onMouseOut));
    // eslint-disable-next-line
  }, []);

  return (
    <g>
      <g ref={barRef} />
      <Tooltips
        data={data}
        isMktHigher={isMktHigher}
        containerWidth={containerWidth}
        firstRectWidth={firstRectWidth}
        secondRectWidth={secondRectWidth}
        isBuildingInfoVisible={props.isBuildingInfoVisible}
      />
    </g>
  );
};

export default Bars;
