import { extent } from 'd3-array';
import { getDateFromYear } from 'utils/date';
import { compact, sortBy } from 'lodash';
import { scaleLinear, ScaleLinear, scaleTime, ScaleTime } from 'd3-scale';
import {
  BuildingHistoricalRent,
  BuildingHistoricalRentItem,
  CompanyHistoricalRent,
  CompanyHistoricalRentItem,
  SubmarketHistoricalRent,
  SubmarketHistoricalRentItem,
} from '../../constants';
import { dimensions, PADDING_LEFT_X_SCALE, Y_RENT_PADDING } from './constants';
import {
  HistoricalRentConfig,
  HistoricalRentData,
  HistoricalRentDataItem,
  HistoricalRentObject,
} from 'components/HistoricalPerformance/Graphs/HistoricalRent/GraphBody/interfaces';
import {
  MarketPricingRentType,
  MarketPricingTimeMeasurement,
} from 'interfaces/inputs/IMarketPricingInput';
import { LineDataType } from 'components/Graphs/HorizontalChartsElements/Line';
import { DOT_LG } from 'constants/placeholders';
import { colors } from 'constants/colors';
import { MeasurementParams } from 'constants/i18n';
import { formatNumberWithCommas } from 'utils/formatters/number';
import { getTranslatedNNN } from 'utils/leases';
import { omit, some } from 'lodash';
import { checkIfNumericFieldIsValid } from '../../utils';

export const checkIfDataSetIsValid = (
  dataSet: (BuildingHistoricalRentItem | CompanyHistoricalRentItem)[],
): boolean => {
  return !!compact(
    dataSet.map((value: any) =>
      some(omit(value, ['year', '__typename', 'timeMeasurement']), field =>
        checkIfNumericFieldIsValid(field, true),
      ),
    ),
  ).length;
};

export const isHistoricalRentDataValid = (
  historicalRentData?: BuildingHistoricalRent | CompanyHistoricalRent,
): boolean => {
  const isRentTypeFSValid =
    !!historicalRentData &&
    checkIfDataSetIsValid(historicalRentData?.rentTypeFS);
  const isRentTypeNNNValid =
    !!historicalRentData &&
    checkIfDataSetIsValid(historicalRentData?.rentTypeNNN);

  return isRentTypeFSValid || isRentTypeNNNValid;
};

export const getPastSixYears = <
  T extends
    | BuildingHistoricalRentItem
    | CompanyHistoricalRentItem
    | SubmarketHistoricalRentItem
>(
  data: Array<T>,
): Array<T> => sortBy(data, 'year').slice(data?.length - 6, data?.length);

/**
 * Returns the min and max values for the list of HistoricalRentObjects
 * @param histRentObjects
 */
const getYExtent = (
  histRentObjects: Array<HistoricalRentObject | undefined>,
): [number, number] => {
  const extents = histRentObjects
    .filter(Boolean)
    .map(i => extent(i!.items, el => el.rentValue));
  return [
    Math.min(...extents.map(e => e[0] || 0)),
    Math.max(...extents.map(e => e[1] || 0)),
  ];
};

export const getYScale = (
  height: number,
  yExtent: [number, number],
): ScaleLinear<number, number> => {
  const [minValue, maxValue] = yExtent;
  const padding = (maxValue * Y_RENT_PADDING) / 100;

  return scaleLinear()
    .domain([minValue - padding, maxValue + padding])
    .range([height, 0]);
};

export const getXScale = (
  width: number,
  data: HistoricalRentDataItem[],
): ScaleTime<number, number> => {
  const datesExtent: any = extent(data.map(d => getDateFromYear(d.year)));

  return scaleTime()
    .domain(datesExtent)
    .range([PADDING_LEFT_X_SCALE, width]);
};

export const getGraphDimensions = () => {
  const { MARGINS, WIDTH, HEIGHT } = dimensions;

  return {
    graph: {
      width: WIDTH,
      height: HEIGHT,
    },
    svg: {
      width: WIDTH + MARGINS.left + MARGINS.right,
      height: HEIGHT + MARGINS.top + MARGINS.bottom,
      transform: `translate(${MARGINS.left}, 0)`,
    },
  };
};

export const getGraphParamsForProperty = (
  measurementParams: MeasurementParams,
  buildingData?: HistoricalRentObject,
  compSetFSData?: HistoricalRentObject,
  compSetNNNData?: HistoricalRentObject,
): HistoricalRentConfig => {
  const { currencySymbol, unitOfMeasurement } = measurementParams;
  const dimensions = getGraphDimensions();
  const yExtent = getYExtent([buildingData, compSetFSData, compSetNNNData]);

  return {
    measurementParams,
    yScale: getYScale(dimensions.graph.height, yExtent),
    xScale: getXScale(dimensions.graph.width, buildingData?.items || []),
    legends: [
      `${currencySymbol}${formatNumberWithCommas(
        yExtent[1],
      )} per ${unitOfMeasurement}`,
      `${currencySymbol}${formatNumberWithCommas(
        yExtent[0],
      )} per ${unitOfMeasurement}`,
    ],
  };
};

export const getGraphParamsForCompany = (
  measurementParams: MeasurementParams,
  companyFSData?: HistoricalRentObject,
  companyNNNData?: HistoricalRentObject,
): HistoricalRentConfig => {
  const { currencySymbol, unitOfMeasurement } = measurementParams;
  const dimensions = getGraphDimensions();
  const yExtent = getYExtent([companyFSData, companyNNNData]);
  const items = companyFSData?.items || companyNNNData?.items || [];

  return {
    measurementParams,
    yScale: getYScale(dimensions.graph.height, yExtent),
    xScale: getXScale(dimensions.graph.width, items),
    legends: [
      `${currencySymbol}${yExtent[1]} per ${unitOfMeasurement}`,
      `${currencySymbol}${yExtent[0]} per ${unitOfMeasurement}`,
    ],
  };
};

export const getGraphParamsForSubmarket = (
  measurementParams: MeasurementParams,
  submarketFSData?: HistoricalRentObject,
  submarketNNNData?: HistoricalRentObject,
): HistoricalRentConfig => {
  const { currencySymbol, unitOfMeasurement } = measurementParams;
  const dimensions = getGraphDimensions();
  const yExtent = getYExtent([submarketFSData, submarketNNNData]);
  const items = submarketFSData?.items || submarketNNNData?.items || [];

  return {
    measurementParams,
    yScale: getYScale(dimensions.graph.height, yExtent),
    xScale: getXScale(dimensions.graph.width, items),
    legends: [
      `${currencySymbol}${yExtent[1]} per ${unitOfMeasurement}`,
      `${currencySymbol}${yExtent[0]} per ${unitOfMeasurement}`,
    ],
  };
};

const isMonthlyData = (timeMeasurement?: string) =>
  timeMeasurement?.toLowerCase() ===
  MarketPricingTimeMeasurement.Monthly.toLowerCase();

export const getBuildingHistoricalRentDataPoints = (
  buildingHistoricalRent?: BuildingHistoricalRent,
  timeMeasurement?: string,
): HistoricalRentData => {
  const pastSixYearsFS = buildingHistoricalRent?.rentTypeFS
    ? getPastSixYears(buildingHistoricalRent.rentTypeFS)
    : [];
  const pastSixYearsNNN = buildingHistoricalRent?.rentTypeNNN
    ? getPastSixYears(buildingHistoricalRent.rentTypeNNN)
    : [];

  const hasFSValue = pastSixYearsFS.find(item => !!item.buildingValue);
  const hasNNNValue = pastSixYearsNNN.find(item => !!item.buildingValue);

  if (!hasFSValue && !hasNNNValue) return { building: undefined };

  const isMonthly = isMonthlyData(timeMeasurement);

  const mapBuildingItem = (
    item: BuildingHistoricalRentItem,
    rentType: MarketPricingRentType | string,
  ) => ({
    year: item.year,
    rentValue: isMonthly ? item.buildingValueMonthly : item.buildingValue,
    tiValue: isMonthly ? item.buildingTIMonthly : item.buildingTI,
    freeRentMonthsValue: item.buildingFreeRentMonths,
    rentType,
  });

  const rentType = hasFSValue ? MarketPricingRentType.FS : getTranslatedNNN();

  const isFS = rentType === MarketPricingRentType.FS;
  return {
    building: {
      id: `hist-rent-building-${rentType}`,
      rentType,
      items: (isFS ? pastSixYearsFS : pastSixYearsNNN).map(item =>
        mapBuildingItem(item, rentType),
      ),
      legend: {
        label: `Building Average Rent ${DOT_LG} ${rentType}`,
        color: colors.supportive500,
      },
      leftLegend: {
        label: `Building ${DOT_LG} ${rentType}`,
        color: colors.ayPureWhiteColor,
        width: isFS ? 60 : 70,
      },
      lineColor: colors.supportive500,
      tooltipFontColor: colors.ayPureWhiteColor,
    },
  };
};

export const getSubmarketHistoricalRentDataPoints = (
  submarketHistoricalRent?: SubmarketHistoricalRent,
  timeMeasurement?: string,
): HistoricalRentData => {
  const pastSixYearsFS = submarketHistoricalRent?.rentTypeFS
    ? getPastSixYears(submarketHistoricalRent.rentTypeFS)
    : [];
  const pastSixYearsNNN = submarketHistoricalRent?.rentTypeNNN
    ? getPastSixYears(submarketHistoricalRent.rentTypeNNN)
    : [];

  const hasFSValue = pastSixYearsFS.find(item => !!item.compSetValue);
  const hasNNNValue = pastSixYearsNNN.find(item => !!item.compSetValue);

  const isMonthly = isMonthlyData(timeMeasurement);

  const mapCompSetItem = (
    item: SubmarketHistoricalRentItem,
    rentType: MarketPricingRentType | string,
  ) => ({
    year: item.year,
    rentValue: isMonthly ? item.compSetValueMonthly : item.compSetValue,
    tiValue: isMonthly ? item.compSetTIMonthly : item.compSetTI,
    freeRentMonthsValue: item.compSetFreeRentMonths,
    rentType,
  });

  return {
    submarketFS: hasFSValue
      ? {
          id: `hist-rent-submarket-${MarketPricingRentType.FS}`,
          rentType: MarketPricingRentType.FS,
          items: pastSixYearsFS.map(item =>
            mapCompSetItem(item, MarketPricingRentType.FS),
          ),
          legend: {
            label: `Average Rent ${DOT_LG} ${MarketPricingRentType.FS}`,
            color: colors.primaryColor500,
          },
          leftLegend: {
            label: `Comp ${DOT_LG} ${MarketPricingRentType.FS}`,
            color: colors.ayPureWhiteColor,
            width: 50,
          },
          lineColor: colors.primaryColor500,
          tooltipFontColor: colors.ayPureWhiteColor,
        }
      : undefined,
    submarketNNN: hasNNNValue
      ? {
          id: `hist-rent-submarket-${MarketPricingRentType.NNN}`,
          rentType: MarketPricingRentType.NNN,
          items: pastSixYearsNNN.map(item =>
            mapCompSetItem(item, MarketPricingRentType.NNN),
          ),
          legend: {
            label: `Average Rent ${DOT_LG} ${MarketPricingRentType.NNN}`,
            color: colors.ayWhiteColor,
          },
          leftLegend: {
            label: `Comp ${DOT_LG} ${MarketPricingRentType.NNN}`,
            color: colors.uiColor900,
            width: 50,
          },
          lineColor: colors.ayWhiteColor,
          tooltipFontColor: colors.uiColor900,
        }
      : undefined,
  };
};

export const getCompSetHistoricalRentDataPoints = (
  buildingHistoricalRent?: BuildingHistoricalRent,
  timeMeasurement?: string,
): HistoricalRentData => {
  const pastSixYearsFS = buildingHistoricalRent?.rentTypeFS
    ? getPastSixYears(buildingHistoricalRent.rentTypeFS)
    : [];
  const pastSixYearsNNN = buildingHistoricalRent?.rentTypeNNN
    ? getPastSixYears(buildingHistoricalRent.rentTypeNNN)
    : [];
  const hasFSValue = pastSixYearsFS.find(item => !!item.compSetValue);

  const isMonthly = isMonthlyData(timeMeasurement);

  const mapCompSetItem = (
    item: BuildingHistoricalRentItem,
    rentType: MarketPricingRentType | string,
  ) => ({
    year: item.year,
    rentValue: isMonthly ? item.compSetValueMonthly : item.compSetValue,
    tiValue: isMonthly ? item.compSetTIMonthly : item.compSetTI,
    freeRentMonthsValue: item.compSetFreeRentMonths,
    rentType,
  });

  const hasNNNValue = pastSixYearsNNN.find(item => !!item.buildingValue);
  const NNNValue = getTranslatedNNN();

  return {
    compSetFS: hasFSValue
      ? {
          id: `hist-rent-compset-${MarketPricingRentType.FS}`,
          rentType: MarketPricingRentType.FS,
          items: pastSixYearsFS.map(item =>
            mapCompSetItem(item, MarketPricingRentType.FS),
          ),
          legend: {
            label: `Comp Set Average Rent ${DOT_LG} ${MarketPricingRentType.FS}`,
            color: colors.ayPureWhiteColor,
          },
          leftLegend: {
            label: `Comp ${DOT_LG} ${MarketPricingRentType.FS}`,
            color: colors.ayTotalBlackColor,
            width: 50,
          },
          lineColor: colors.ayPureWhiteColor,
          tooltipFontColor: colors.ayTotalBlackColor,
        }
      : undefined,
    compSetNNN: hasNNNValue
      ? {
          id: `hist-rent-compset-${NNNValue}`,
          rentType: NNNValue,
          items: pastSixYearsNNN.map(item => mapCompSetItem(item, NNNValue)),
          legend: {
            label: `Comp Set Average Rent ${DOT_LG} ${NNNValue}`,
            color: colors.ayWhiteColor,
          },
          leftLegend: {
            label: `Comp ${DOT_LG} ${NNNValue}`,
            color: colors.uiColor900,
            width: 60,
          },
          lineColor: colors.ayWhiteColor,
          tooltipFontColor: colors.uiColor900,
        }
      : undefined,
  };
};

export const getCompanyHistoricalRentDataPoints = (
  companyHistoricalRent?: CompanyHistoricalRent,
): HistoricalRentData => {
  const pastSixYearsFS = companyHistoricalRent?.rentTypeFS
    ? getPastSixYears(companyHistoricalRent.rentTypeFS)
    : [];
  const pastSixYearsNNN = companyHistoricalRent?.rentTypeNNN
    ? getPastSixYears(companyHistoricalRent.rentTypeNNN)
    : [];

  const hasFSValue = pastSixYearsFS.find(item => !!item.rentAvg);
  const hasNNNValue = pastSixYearsNNN.find(item => !!item.rentAvg);
  const NNNValue = getTranslatedNNN();

  const mapCompanyItem = (
    item: CompanyHistoricalRentItem,
    rentType: MarketPricingRentType | string,
  ) => ({
    year: item.year,
    rentValue: item.rentAvg,
    tiValue: item.compSetTI,
    freeRentMonthsValue: item.compSetFreeRentMonths,
    rentType,
  });

  return {
    companyFS: hasFSValue
      ? {
          id: `hist-rent-company-${MarketPricingRentType.FS}`,
          items: pastSixYearsFS.map(item =>
            mapCompanyItem(item, MarketPricingRentType.FS),
          ),
          rentType: MarketPricingRentType.FS,
          legend: {
            label: `Average Rent ${DOT_LG} ${MarketPricingRentType.FS}`,
            color: colors.primaryColor500,
          },
          leftLegend: {
            label: `Avg Rent ${DOT_LG} ${MarketPricingRentType.FS}`,
            color: colors.ayPureWhiteColor,
            width: 62,
          },
          lineColor: colors.primaryColor500,
          tooltipFontColor: colors.ayPureWhiteColor,
        }
      : undefined,
    companyNNN: hasNNNValue
      ? {
          id: `hist-rent-company-${NNNValue}`,
          items: pastSixYearsNNN.map(item => mapCompanyItem(item, NNNValue)),
          rentType: NNNValue,
          legend: {
            label: `Average Rent ${DOT_LG} ${NNNValue}`,
            color: colors.ayPureWhiteColor,
          },
          leftLegend: {
            label: `Avg Rent ${DOT_LG} ${NNNValue}`,
            color: colors.uiColor900,
            width: 72,
          },
          lineColor: colors.ayPureWhiteColor,
          tooltipFontColor: colors.uiColor900,
        }
      : undefined,
  };
};

export const parseHistoricalRentToLineData = (
  itemId: string,
  chartConfig?: HistoricalRentConfig | null,
  data?: HistoricalRentObject,
): LineDataType[] | null =>
  chartConfig && data?.items
    ? data?.items.map(item => ({
        id: `${itemId}-${item.year}`,
        label: item.year,
        xValue: chartConfig.xScale(getDateFromYear(item.year)),
        yValue: chartConfig.yScale(item.rentValue),
        item,
      }))
    : null;
