export default {
  ISO_DATE: 'YYYY-MM-DD',
  ISO_DATE_MONTH_NO_LEADING_ZEROS: 'YYYY-M-DD',
  ISO_DATE_DAY_NO_LEADING_ZEROS: 'YYYY-MM-D',
  ISO_DATE_NO_LEADING_ZEROS: 'YYYY-M-D',
  US_DATE_WITH_DOTS: 'MM.DD.YYYY',
  MONTH_YEAR: 'MMM YYYY',
  DEFAULT_DATE: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  TIME_12_HOUR: 'hh:mm a',
  TIME_24_HOUR: 'HH:mm',
  US_DATE: 'MMM Do, YYYY',
  ISO_DATE_TIME_WITH_DOTS: 'MM.DD.YYYY hh:mm a',
  DATE_TITLE: 'MM/DD/YYYY',
  SHORT_DATE: 'MMM D, YYYY',
};
