import React from 'react';
import { TIMES } from 'constants/placeholders';
import { REGEX_INTEGERS } from 'constants/regex';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import {
  getFieldNameForProperty,
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { translateText } from 'utils/i18n';
import { ExclusiveFieldProps } from './ExclusiveFields';
import { FieldType } from '../types';
import CeilingHeightField from './CeilingHeightField';

const OfficeFields: React.FC<ExclusiveFieldProps> = ({
  onChange,
  property,
  property: { office },
  classNames: { formControlClassName, inputHelperClassName },
}) => {
  const getFieldValue = (field: string) =>
    getFieldValueForProperty(
      field,
      property,
      ModelsWithUnitsOfMeasurement.Office,
    );

  const getFieldName = (field: string) =>
    getFieldNameForProperty(
      field,
      property,
      ModelsWithUnitsOfMeasurement.Office,
    );

  return (
    <>
      <CeilingHeightField
        onChange={onChange}
        fieldType={FieldType.office}
        property={property}
        valueField={office}
        classNames={{
          formControlClassName,
          inputHelperClassName,
        }}
      />

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.columnSpacing',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="columnSpacingMinInput"
          isNarrow
          textCentered
          placeholder={'00'}
          pattern={REGEX_INTEGERS}
          value={getFieldValue('columnSpacingMin')}
          onChange={value => onChange(getFieldName('columnSpacingMin'), value)}
        />
        <span className={inputHelperClassName}>{TIMES}</span>
        <TextInput
          id="columnSpacingMaxInput"
          isNarrow
          textCentered
          placeholder={'00'}
          pattern={REGEX_INTEGERS}
          value={getFieldValue('columnSpacingMax')}
          onChange={value => onChange(getFieldName('columnSpacingMax'), value)}
        />
        <span className={inputHelperClassName}>
          {getUnitOfMeasurementForProperty(
            'columnSpacingMax',
            ModelsWithUnitsOfMeasurement.Office,
            property?.propertyCountry?.code || property?.measurementSystem,
          )}
        </span>
      </FormControl>
    </>
  );
};

export default OfficeFields;
