export const lockScroll = () => {
  document.documentElement.style.overflowY = 'hidden';

  // change the body style to keep the page with the same width after hiding the documentElement scrollbar
  document.body.style.overflowY = 'scroll';
};

export const unlockScroll = () => {
  document.documentElement.style.overflowY = 'scroll';
  document.body.style.overflowY = '';
};
