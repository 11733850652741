import React, { useEffect, useRef, useState } from 'react';
import { select } from 'd3-selection';
import { arc } from 'd3-shape';
import graphDimensions, {
  DONUT_SIZE,
  getDonutInnerRadius,
  getDonutOuterRadius,
} from '../graphConstants';
import donutGenerator from './donutGenerator';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import {
  getDonutDataId,
  getDonutDataIdShadowId,
  getDonutSliceClass,
  getDonutSliceShadowClass,
} from 'components/Graphs/DonutGraph/nodes';
import {
  onMouseOutDonutSlice,
  onMouseOverDonutSlice,
} from 'components/Graphs/DonutGraph/Elements/mouseFunctions';
import isEqual from 'lodash/isEqual';
import { transition, interpolate } from 'd3';
import { getDonutColor } from '../utils';

interface Props {
  graphData: IDonutGraphData[];
  firstSliceColor: string;
  secondSliceColor: string;
  secondaryDonutColor?: string;
  activeColor?: string;
  onHover: (donutData?: IDonutGraphData) => void;
  onClick: (donutData?: IDonutGraphData) => void;
  donutId: number;
  size?: DONUT_SIZE;
  customSize?: number;
  shouldSortByTitle?: boolean;
  showIndividualSlice?: boolean;
}

const Donut: React.FC<Props> = (props: Props) => {
  const { customSize, size, shouldSortByTitle } = props;
  const pieGroupRef = useRef(null);
  const pieShadowGroupRef = useRef(null);

  const [dataPoints, setDataPoints] = useState<IDonutGraphData[]>();

  useEffect(() => {
    if (!isEqual(props.graphData, dataPoints)) {
      setDataPoints(props.graphData);
    }
  }, [props.graphData, dataPoints]);

  let mouseOutTimeout: any = null;

  const mouseoverEvent = (d: any, i: number) => {
    const activeColor =
      props.activeColor ||
      getDonutColor(props.firstSliceColor, props.secondSliceColor, i);

    mouseOutTimeout && clearTimeout(mouseOutTimeout);
    onMouseOverDonutSlice(
      d.data,
      () => {
        props.onHover(d.data);
      },
      activeColor,
      props.secondaryDonutColor || activeColor,
      props.donutId,
      props.showIndividualSlice,
    );
  };

  const mouseoutEvent = (_: any) => {
    mouseOutTimeout && clearTimeout(mouseOutTimeout);
    mouseOutTimeout = setTimeout(() => {
      onMouseOutDonutSlice(
        () => {
          props.onHover();
        },
        props.firstSliceColor,
        props.secondSliceColor,
        props.donutId,
        props.showIndividualSlice,
      );
    }, 100);
  };

  const mouseClickEvent = (d: any) => props.onClick(d.data);

  const getXYCoordinates = () => {
    return {
      x: getDonutOuterRadius(size, customSize),
      y: getDonutOuterRadius(size, customSize),
    };
  };

  const getArcPathGenerator = () => {
    return arc()
      .outerRadius(getDonutOuterRadius(size, customSize))
      .innerRadius(getDonutInnerRadius(size, customSize));
  };

  const getArcShadowPathGenerator = () => {
    return arc()
      .outerRadius(getDonutOuterRadius(size, customSize))
      .innerRadius(getDonutInnerRadius(size, customSize))
      .cornerRadius(1);
  };

  useEffect(() => {
    if (!dataPoints) return;

    const pieShadowGroup = pieShadowGroupRef.current;
    const pieGroup = pieGroupRef.current;

    const translate = getXYCoordinates();
    const arcGenerator: any = getArcPathGenerator();
    const arcShadowGenerator: any = getArcShadowPathGenerator();

    select(pieGroup)
      .selectAll(`.${getDonutSliceClass(props.donutId)}`)
      .data(donutGenerator(dataPoints, shouldSortByTitle))
      .join('path')
      .attr('transform', 'translate(' + translate.x + ',' + translate.y + ')')
      .attr('d', arcGenerator)
      .attr('class', `${getDonutSliceClass(props.donutId)}`)
      .attr('id', (d: any) => getDonutDataId(d.data, props.donutId))
      .attr('fill', (_, i) => {
        return getDonutColor(props.firstSliceColor, props.secondSliceColor, i);
      })
      .attr(
        'stroke-width',
        props.showIndividualSlice ? graphDimensions.STROKE_WIDTH : 0,
      )
      .attr('stroke', props.secondaryDonutColor!)
      .style('cursor', 'pointer')
      .on('click', mouseClickEvent)
      .on('mouseover', mouseoverEvent)
      .on('mouseout', mouseoutEvent)
      .transition(transition().duration(300) as any)
      .attrTween('d', (d: any) => {
        const i = interpolate(d.startAngle, d.endAngle);
        return (t: any) => {
          d.endAngle = i(t);
          return arcGenerator(d);
        };
      });

    select(pieShadowGroup)
      .selectAll(`.${getDonutSliceShadowClass(props.donutId)}`)
      .data(donutGenerator(dataPoints, shouldSortByTitle))
      .join('path')
      .attr('transform', 'translate(' + translate.x + ',' + translate.y + ')')
      .attr('d', arcShadowGenerator)
      .attr('class', `${getDonutSliceShadowClass(props.donutId)}`)
      .attr('id', (d: any) => getDonutDataIdShadowId(d.data, props.donutId))
      .attr('fill', (_, i) => {
        return getDonutColor(props.firstSliceColor, props.secondSliceColor, i);
      })
      .attr('stroke', 'transparent')
      .attr('stroke-opacity', 0.32)
      .attr('stroke-width', graphDimensions.STROKE_WIDTH)
      .style('cursor', 'pointer')
      .on('mouseover', mouseoverEvent)
      .on('mouseout', mouseoutEvent);

    // eslint-disable-next-line
  }, [dataPoints]);

  return (
    <g>
      <g ref={pieShadowGroupRef} />
      <g ref={pieGroupRef} />
    </g>
  );
};

export default Donut;
