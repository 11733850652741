import { select } from 'd3-selection';
import { colors } from 'constants/colors';
import {
  getTooltipId,
  getTooltipRectId,
  getTooltipTextId,
} from '../../RecentLeases/nodes';
import { RecentLeasesIds } from '../../RecentLeases/graphIds';
import { TOOLTIP_TEXT } from './constants';
import { IBubbleDataPoint } from 'components/Graphs/BubbleChartsElements/interfaces';

const getTextWidth = (element: any) => {
  const textPadding = 13;
  const d3Element = select(element);

  if (d3Element.empty()) return 0;

  return d3Element.node().getBBox().width + textPadding;
};

export const showTooltip = ({
  circleXPosition,
  isMarketData,
  node,
  textColor,
  backgroundColor,
}: {
  circleXPosition: number;
  isMarketData: boolean;
  backgroundColor?: string;
  textColor?: string;
  node: IBubbleDataPoint;
}) => {
  const tooltipRect = select(`#${getTooltipRectId(node.graphId)}`);
  const tooltipText = select(`#${getTooltipTextId(node.graphId)}`);

  const isCompanyTenantsGraph = node.graphId === RecentLeasesIds.CompanyTenants;

  // @TODO - refactor this colors logic, passing as prop
  const leaseColor = isCompanyTenantsGraph
    ? colors.ayMainGoldColor
    : colors.ayMainRedColor;
  const bgColor = isMarketData ? colors.supportive500 : leaseColor;

  const textFontColor = colors.ayPureWhiteColor;

  const tooltipTextValue = node.tooltipText;
  const d3TooltipTextId = getTooltipTextId(node.graphId);

  tooltipRect
    .attr('fill', backgroundColor || bgColor)
    .style('z-index', 2)
    .style('display', '');

  tooltipText && tooltipText.remove();
  select(`#${getTooltipId(node.graphId)}`)
    .append('text')
    .attr('id', d3TooltipTextId)
    .attr('fill', textColor || textFontColor)
    .text(tooltipTextValue)
    .attr('y', TOOLTIP_TEXT.y)
    .style('font-size', '0.75rem');

  // Calculate the size of the text to determine the center position of the tooltip
  const tooltipTextIdSelector = `#${d3TooltipTextId}`;
  const tooltipContainerSize = getTextWidth(tooltipTextIdSelector);
  const centerXPosition = circleXPosition - tooltipContainerSize / 2;

  tooltipRect.attr('width', tooltipContainerSize).attr('x', centerXPosition);
  select(tooltipTextIdSelector).attr(
    'x',
    centerXPosition + TOOLTIP_TEXT.paddingLeft,
  );
};

export const hideTooltip = (graphId: RecentLeasesIds) => {
  select(`#${getTooltipRectId(graphId)}`).style('display', 'none');
  select(`#${getTooltipTextId(graphId)}`).remove();
};
