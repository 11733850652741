import React from 'react';
import classnames from 'classnames';
import styles from './GraphHeader.module.scss';
import Legend, {
  ILegend,
} from 'components/Graphs/HorizontalChartsElements/GraphLegend';

interface Props {
  hasMissingData?: boolean;
  headerContent?: React.ReactNode;
  legends?: ILegend[];
  subtitle?: string;
  title: string;
  hasSwitchActions?: boolean;
}

const GraphHeader: React.FC<Props> = ({
  hasMissingData = false,
  hasSwitchActions = false,
  headerContent,
  legends,
  subtitle,
  title,
}) => {
  const switchActionsStyles = hasSwitchActions && styles['switch-actions'];
  return (
    <header className={styles.header}>
      <div className={styles['header-inner-container']}>
        <div className={styles['header-title-container']}>
          <h3 className={classnames(styles.title, switchActionsStyles)}>
            {title}
          </h3>
          {!!subtitle && !hasMissingData && (
            <span className={styles.subtitle}>{subtitle}</span>
          )}
        </div>
        <div className={styles['header-actions']}>
          {headerContent && (
            <div
              className={classnames(
                styles['header-content'],
                switchActionsStyles,
              )}
            >
              {headerContent}
            </div>
          )}
          <div className={styles['header-legends']}>
            {!!legends && !hasMissingData && <Legend legends={legends} />}
          </div>
        </div>
      </div>
    </header>
  );
};

export default GraphHeader;
