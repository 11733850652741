import { select } from 'd3-selection';
import { GraphIds } from '../nodes';

const displayId = (id: string) => {
  select(`#${id}`).style('display', '');
};

const hideId = (id: string) => {
  select(`#${id}`).style('display', 'none');
};

export const onMouseOverMarketPlate = () => {
  [GraphIds.MarketLineId, GraphIds.MarketShadowId].map(displayId);
};

export const onMouseOutMarketPlate = () => {
  [GraphIds.MarketLineId, GraphIds.MarketShadowId].map(hideId);
};

export const onMouseOverPropertyPlate = () => {
  [GraphIds.PropertyLineId, GraphIds.PropertyShadowId].map(displayId);
};

export const onMouseOutPropertyPlate = () => {
  [GraphIds.PropertyLineId, GraphIds.PropertyShadowId].map(hideId);
};
