import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { ISearchOrder } from 'interfaces/ISearch';
import { useQuery } from '@apollo/client';
import { SEARCH_LEASES_BASIC_QUERY } from 'graphql/leases/queries';

export const useLeasesSearch = (
  propertySet?: IPropertySet,
  order?: ISearchOrder,
) => {
  const propertiesIds =
    propertySet?.properties?.map(property => property.id!) || [];
  const { data, loading: isLoading, refetch } = useQuery(
    SEARCH_LEASES_BASIC_QUERY,
    {
      variables: {
        search: {
          filter: {
            propertiesIds,
            onlyNotExpired: true,
          },
          order,
        },
      },
      skip: !propertiesIds?.length,
      fetchPolicy: 'cache-and-network',
    },
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
