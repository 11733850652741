import { gql } from '@apollo/client';

export const LEASES_ANALYTICS_IDS = gql`
  query leasesAnalyticsIDs($search: LeasesAnalyticsMetadataInput) {
    leasesAnalyticsIDs(search: $search)
  }
`;

export const LEASES_ANALYTICS_TOP_INDUSTRIES = gql`
  query leasesAnalyticsTopIndustries(
    $search: LeasesAnalyticsTopIndustriesInput
  ) {
    leasesAnalyticsTopIndustries(search: $search) {
      overview {
        totalIndustries
        sumSize
      }
      slices {
        text
        totalIndustries
        sumSize
        topItems
      }
    }
  }
`;

export const LEASES_ANALYTICS_LEASE_TYPE = gql`
  query leasesAnalyticsLeaseType($search: LeasesAnalyticsLeaseTypeInput) {
    leasesAnalyticsLeaseType(search: $search) {
      overview {
        totalLeases
        sumSize
      }
      slices {
        text
        totalLeases
        sumSize
        topItems
      }
    }
  }
`;

export const LEASES_ANALYTICS_LEASE_SIZE = gql`
  query leasesAnalyticsLeaseSize($search: LeasesAnalyticsLeaseSizeInput) {
    leasesAnalyticsLeaseSize(search: $search) {
      overview {
        totalLeases
        sumSize
      }
      slices {
        text
        totalLeases
        sumSize
        topItems
      }
    }
  }
`;

export const LEASES_ANALYTICS_TRANSACTION_TYPE = gql`
  query leasesAnalyticsTransactionType(
    $search: LeasesAnalyticsTransactionTypeInput
  ) {
    leasesAnalyticsTransactionType(search: $search) {
      overview {
        totalTransactions
        sumSize
      }
      slices {
        text
        totalTransactions
        sumSize
        topItems
      }
    }
  }
`;

export const LEASES_ANALYTICS_MOST_RECENT_LEASES = gql`
  query leasesAnalyticsMostRecentLeases(
    $search: LeasesAnalyticsMostRecentInput
  ) {
    leasesAnalyticsMostRecentLeases(search: $search) {
      historicalAverage {
        totalAverage
        averages {
          date
          value
          valueMonthly
          NNNEquivValue
          NNNEquivValueMontly
          propertyValue
          totalSizeSF
          totalSizeSquareMeters
          leasesCount
          measurementSystem
          currencyCode
          topItems
        }
        leases {
          id
          size
          date
          sizeMt
          rent
          currencyCode
          measurementSystem
        }
        averageType
      }
      historicalLeases {
        rentType
        items {
          id
          value
          size
          sizeMt
          measurementSystem
          date
          year
          quarter
          tenantCompany {
            id
            name
          }
          currencyCode
          timeMeasurement
          baseRentFullServiceEquiv
          baseRentFullServiceEquivMonthly
          baseRentFullServiceEquivAnnual
          baseRentNNNEquiv
          baseRentNNNEquivMonthly
          baseRentNNNEquivAnnual
          rentType
          baseRent
        }
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_IDS = gql`
  query propertiesAnalyticsIDs($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsIDs(search: $search)
  }
`;

export const PROPERTIES_ANALYTICS_PROPERTY_CLASSES = gql`
  query propertiesAnalyticsPropertyClasses($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsPropertyClasses(search: $search) {
      classItems {
        text
        count
        size
        sizeMt
        percentage
        topItems
      }
      parentClassItems {
        text
        count
        size
        sizeMt
        percentage
        topItems
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_PROPERTY_SUBTYPES = gql`
  query propertiesAnalyticsPropertySubtypes($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsPropertySubtypes(search: $search) {
      items {
        text
        count
        size
        sizeMt
        percentage
        topItems
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_PROPERTY_AGES = gql`
  query propertiesAnalyticsPropertyAges($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsPropertyAges(search: $search) {
      average
      slices {
        text
        count
        size
        sizeAvg
        sizeMt
        sizeMtAvg
        builtYear
        percentage
        topItems
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_PROPERTY_SIZES = gql`
  query propertiesAnalyticsPropertySizes($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsPropertySizes(search: $search) {
      average
      range {
        min
        max
      }
      items {
        text
        count
        size
        sizeAvg
        sizeMt
        sizeMtAvg
        percentage
        topItems
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_METADATA = gql`
  query propertiesAnalyticsMetadata($search: PropertiesAnalyticsInput) {
    propertiesAnalyticsMetadata(search: $search) {
      numberProperties
      totalBuildingsSize
      totalBuildingsSizeMt
      totalNetAbsorptionLTM
      totalVacancyPercentage
      totalAvailabilityPercentage
      averageAskingRent
      averageTI
      freeRentMonths
      percentageLeased
    }
  }
`;

export const PROPERTIES_ANALYTICS_DELIVERED = gql`
  query propertiesAnalyticsDelivered($search: PropertiesAnalyticsRangeInput) {
    propertiesAnalyticsDelivered(search: $search) {
      year
      sumSize
      sumSizeMt
      topItems
      quarter
    }
  }
`;

export const PROPERTIES_ANALYTICS_ASSET_PERFORMANCE = gql`
  query propertiesAnalyticsAssetPerformance(
    $search: PropertiesAnalyticsAssetPerformanceInput
  ) {
    propertiesAnalyticsAssetPerformance(search: $search) {
      items {
        year
        date
        availableSFDirect
        availableSFDirectPercentage
        availableSFSublet
        availableSFSubletPercentage
        availableSMDirect
        availableSMDirectPercentage
        availableSMSublet
        availableSMSubletPercentage
        occupiedSF
        occupiedSFPercentage
        occupiedSM
        occupiedSMPercentage
        vacantSFDirect
        vacantSFDirectPercentage
        vacantSFSublet
        vacantSFSubletPercentage
        vacantSMDirect
        vacantSMDirectPercentage
        vacantSMSublet
        vacantSMSubletPercentage
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_ABSORPTION = gql`
  query propertiesAnalyticsAbsorption($search: PropertiesAnalyticsRangeInput) {
    propertiesAnalyticsAbsorption(search: $search) {
      year
      absorptionSf
      absorptionSm
    }
  }
`;

export const PROPERTIES_ANALYTICS_TENANTS = gql`
  query propertiesAnalyticsTenantsIndustries(
    $search: PropertiesAnalyticsInput
  ) {
    propertiesAnalyticsTenantsIndustries(search: $search) {
      summary {
        size
        sizeMt
        numberOfIndustries
        totalTenants
      }
      slices {
        floors
        industry
        largestPropertyIds
        topItems
        percentage
        size
        sizeMt
        tenants
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_OWNERS = gql`
  query propertiesAnalyticsOwners($search: PropertiesAnalyticsOwnersInput) {
    propertiesAnalyticsOwners(search: $search) {
      summary {
        size
        sizeMt
        totalOwners
      }
      slices {
        largestPropertyIds
        name
        numberOfBuildings
        numberOfOwners
        percentage
        size
        sizeMt
      }
    }
  }
`;

export const SALES_ANALYTICS_IDS = gql`
  query salesAnalyticsIDs($search: SalesAnalyticsInput) {
    salesAnalyticsIDs(search: $search)
  }
`;

export const SALES_ANALYTICS_METADATA = gql`
  query salesAnalyticsMetadata($search: SalesAnalyticsInput) {
    salesAnalyticsMetadata(search: $search) {
      averageCapRate
      averageHoldPeriod
      averageOccupancy
      averagePrice
      totalTransactions
      totalVolume
    }
  }
`;

export const SALES_ANALYTICS_PORTFOLIO_TRANSACTION = gql`
  query salesAnalyticsPortfolioTransaction($search: SalesAnalyticsInput) {
    salesAnalyticsPortfolioTransaction(search: $search) {
      text
      totalVolume
      percentage
      topItems
    }
  }
`;

export const SALES_ANALYTICS_TOP_BUYERS = gql`
  query salesAnalyticsTopBuyers($search: SalesAnalyticsInput) {
    salesAnalyticsTopBuyers(search: $search) {
      text
      count
      totalVolume
      percentage
      topItems
    }
  }
`;

export const SALES_ANALYTICS_TOP_SELLERS = gql`
  query salesAnalyticsTopSellers($search: SalesAnalyticsInput) {
    salesAnalyticsTopSellers(search: $search) {
      text
      count
      totalVolume
      percentage
      topItems
    }
  }
`;

export const SALES_ANALYTICS_TRANSACTION_TYPE = gql`
  query salesAnalyticsTransactionType($search: SalesAnalyticsInput) {
    salesAnalyticsTransactionType(search: $search) {
      id
      text
      totalVolume
      percentage
      topItems
    }
  }
`;

export const SALES_ANALYTICS_HISTORICAL_PRICING = gql`
  query salesAnalyticsHistoricalPricing($search: SalesAnalyticsRangeInput) {
    salesAnalyticsHistoricalPricing(search: $search) {
      historicalAverage {
        capRate
        currencyCode
        measurementSystem
        pricePerSF
        quarter
        saleDate
        salesCount
        salesIds
        totalSizeSF
        totalSizeSquareMeters
        year
      }
      historicalSales {
        capRate
        currencyCode
        date
        id
        measurementSystem
        pricePerSF
        price
        quarter
        sf
        squareMeters
        transactionSize
        year
      }
    }
  }
`;

export const SALES_ANALYTICS_TRANSACTION_VOLUME = gql`
  query salesAnalyticsTransactionVolume($search: SalesAnalyticsRangeInput) {
    salesAnalyticsTransactionVolume(search: $search) {
      currencyCode
      measurementSystem
      items {
        quarter
        topItems
        totalAmount
        totalVolumeSf
        totalVolumeSqm
        year
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_SQUARE_FOOTAGE = gql`
  query propertiesAnalyticsSquareFootage(
    $search: PropertiesAnalyticsRangeInput
  ) {
    propertiesAnalyticsSquareFootage(search: $search) {
      text
      timelineData {
        year
        size
        sizeMt
        topItems
      }
    }
  }
`;

export const PROPERTIES_ANALYTICS_AMENITIES = gql`
  query propertiesAnalyticsAmenities(
    $search: PropertiesAnalyticsAmenitiesInput
  ) {
    propertiesAnalyticsAmenities(search: $search) {
      slices {
        id
        name
        totalBuildings
        largestPropertyIds
      }
    }
  }
`;

export const LEASES_ANALYTICS_PROPERTY_CLASS = gql`
  query leasesAnalyticsPropertyClass(
    $search: LeasesAnalyticsPropertyClassInput
  ) {
    leasesAnalyticsPropertyClass(search: $search) {
      name
      totalLeases
      sumSize
      percentage
      topItems
    }
  }
`;

export const LEASES_ANALYTICS_MARKETS = gql`
  query leasesAnalyticsMarkets($search: LeasesAnalyticsMarketsInput) {
    leasesAnalyticsMarkets(search: $search) {
      text
      totalLeases
      sumSize
      percentage
      topItems
    }
  }
`;

export const LEASES_ANALYTICS_EXPIRATIONS = gql`
  query leasesAnalyticsExpirations($search: LeasesAnalyticsExpirationsInput) {
    leasesAnalyticsExpirations(search: $search) {
      items {
        year
        quarter
        total
        topItems
        count
      }
    }
  }
`;

export const LEASES_ANALYTICS_DISTRIBUTION_CLASSES = gql`
  query leasesAnalyticsDistributionClasses(
    $search: LeasesAnalyticsDistributionClassesInput
  ) {
    leasesAnalyticsDistributionClasses(search: $search) {
      items {
        classId
        className
        rentAtExpirationAvg
        rentAtExpirationMin
        rentAtExpirationMax
        leases {
          id
          rentAtExpiration
          size
        }
      }
    }
  }
`;
