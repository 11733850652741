import { uniq } from 'lodash';
import { IProperty } from 'interfaces/IProperty';

export const ownersToBeRemovedByProperty = (
  property?: IProperty,
  properties?: IProperty[],
) => {
  const ownersToRemove: number[] = [];
  const propertyOwnerIds = property?.owners?.map(x => x?.id!) || [];
  const propertyLongLeaseHoldersIds =
    property?.longLeaseHolders?.map(x => Number(x?.id)) || [];

  [...propertyOwnerIds, ...propertyLongLeaseHoldersIds]?.map(owner => {
    if (
      !properties?.find(item => item.owners?.map(x => x.id).includes(owner))
    ) {
      ownersToRemove.push(owner);
    }
  });
  return uniq(ownersToRemove);
};
