import {
  REGEX_DECIMALS,
  REGEX_INTEGERS,
  REGEX_THOUSAND_SEP_WITH_DECIMALS,
} from 'constants/regex';
import {
  getFieldNameForProperty,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
} from 'constants/unitOfMeasurement';
import {
  FormFieldConfig,
  FormFieldType,
} from 'components/FormControl/FormField/types';
import { translateText } from 'utils/i18n';
import { getFeedTypes, getYesNoOptions } from './constants';
import { I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH } from 'constants/i18n';

export const getDataCenterFields = (
  property: IPropertyInput,
): FormFieldConfig[] => {
  const typicalCeilingHeightUnitOfMeasurement = getUnitOfMeasurementForProperty(
    'typicalCeilingHeightFeet',
    ModelsWithUnitsOfMeasurement.DataCenter,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  const typicalCeilingHeighConfig: FormFieldConfig =
    typicalCeilingHeightUnitOfMeasurement === UnitOfMeasurement.meter
      ? {
          type: FormFieldType.decimal,
          pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
          labelI18nKey: 'avantPlatform.attributes.property.label.ceilingHeight',
          placeholderI18nKey: '0',
          field: 'typicalCeilingHeightMt',
          legend: UnitOfMeasurement.meter,
        }
      : {
          type: FormFieldType.feetInches,
          pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
          labelI18nKey: 'avantPlatform.attributes.property.label.ceilingHeight',
          placeholderI18nKey: '0',
          feetInchesConfig: {
            fieldFeet: 'typicalCeilingHeightFeet',
            fieldInches: 'typicalCeilingHeightInches',
          },
        };

  return [
    typicalCeilingHeighConfig,
    {
      field: 'underConstPower',
      labelI18nKey:
        'avantPlatform.attributes.property.label.underConstructionPower',
      pattern: REGEX_INTEGERS,
      placeholderI18nKey: '0',
      legend: translateText('avantPlatform.attributes.property.label.kw'),
      type: FormFieldType.integer,
    },
    {
      type: FormFieldType.integer,
      field: 'plannedPower',
      labelI18nKey: 'avantPlatform.attributes.property.label.plannedPower',
      legend: translateText('avantPlatform.attributes.property.label.kw'),
      pattern: REGEX_INTEGERS,
      placeholderI18nKey: '0',
    },
    {
      type: FormFieldType.integer,
      field: 'commissionedPower',
      labelI18nKey: 'avantPlatform.attributes.property.label.commissionedPower',
      pattern: REGEX_INTEGERS,
      placeholderI18nKey: '0',
      legend: translateText('avantPlatform.attributes.property.label.kw'),
    },
    {
      type: FormFieldType.integer,
      field: 'totalPower',
      isDisabled: true,
      labelI18nKey: 'avantPlatform.attributes.property.label.totalPower',
      pattern: REGEX_INTEGERS,
      placeholderI18nKey: '0',
      legend: translateText('avantPlatform.attributes.property.label.kw'),
    },
    {
      type: FormFieldType.decimal,
      field: getFieldNameForProperty(
        'underConstSf',
        property,
        ModelsWithUnitsOfMeasurement.DataCenter,
      ),
      labelI18nKey:
        'avantPlatform.attributes.property.label.underConstructionArea',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterSize',
      pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
      legend: getUnitOfMeasurementForProperty(
        'underConstSf',
        ModelsWithUnitsOfMeasurement.DataCenter,
        property?.propertyCountry?.code || property?.measurementSystem,
      ),
    },
    {
      type: FormFieldType.decimal,
      field: getFieldNameForProperty(
        'plannedSf',
        property,
        ModelsWithUnitsOfMeasurement.DataCenter,
      ),
      labelI18nKey: 'avantPlatform.attributes.property.label.plannedArea',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterSize',
      pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
      legend: getUnitOfMeasurementForProperty(
        'plannedSf',
        ModelsWithUnitsOfMeasurement.DataCenter,
        property?.propertyCountry?.code || property?.measurementSystem,
      ),
    },
    {
      type: FormFieldType.decimal,
      field: getFieldNameForProperty(
        'commissionedSf',
        property,
        ModelsWithUnitsOfMeasurement.DataCenter,
      ),
      labelI18nKey: 'avantPlatform.attributes.property.label.commissionedArea',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterSize',
      pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
      legend: getUnitOfMeasurementForProperty(
        'commissionedSf',
        ModelsWithUnitsOfMeasurement.DataCenter,
        property?.propertyCountry?.code || property?.measurementSystem,
      ),
    },
    {
      type: FormFieldType.range,
      labelI18nKey: 'avantPlatform.attributes.property.label.costPerKWH',
      pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
      placeholderI18nKey: '0',
      rangeConfig: {
        fieldFrom: 'costPerKwhMin',
        labelFromI18nKey:
          'avantPlatform.attributes.property.label.costPerKWHMin',
        fieldTo: 'costPerKwhMax',
        labelToI18nKey: 'avantPlatform.attributes.property.label.costPerKWHMax',
        shouldValidate: true,
      },
    },
    {
      type: FormFieldType.choice,
      field: 'feedType',
      labelI18nKey: 'avantPlatform.attributes.property.label.feedType',
      choiceConfig: {
        options: getFeedTypes(),
      },
    },
    {
      type: FormFieldType.choice,
      field: 'hasOnsiteSubstation',
      labelI18nKey:
        'avantPlatform.attributes.property.label.hasOnsiteSubstation',
      choiceConfig: {
        options: getYesNoOptions(),
      },
    },
    {
      type: FormFieldType.company,
      field: 'powerProviderCompany',
      inputId: 'powerProviderCompany',
      labelI18nKey: `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.powerProvider`,
      placeholderI18nKey:
        'avantPlatform.roles.company.prompt.enterPowerProviderName',
      companyConfig: {
        createCompanyLabelI18nKey:
          'avantPlatform.roles.company.action.createPowerProvider',
      },
    },
    {
      type: FormFieldType.integer,
      field: 'numSubstationsServingFacility',
      labelI18nKey:
        'avantPlatform.attributes.property.label.numOfSubstationsServingFacility',
      pattern: REGEX_INTEGERS,
      placeholderI18nKey: '0',
    },
    {
      type: FormFieldType.decimal,
      field: getFieldNameForProperty(
        'raisedFloorHeight',
        property,
        ModelsWithUnitsOfMeasurement.DataCenter,
      ),
      labelI18nKey: 'avantPlatform.attributes.property.label.raisedFloorHeight',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterSize',
      pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
      legend: getUnitOfMeasurementForProperty(
        'raisedFloorHeight',
        ModelsWithUnitsOfMeasurement.DataCenter,
        property?.propertyCountry?.code || property?.measurementSystem,
      ),
    },
    {
      type: FormFieldType.text,
      field: 'ups',
      labelI18nKey: 'avantPlatform.attributes.property.label.ups',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterText',
    },
    {
      type: FormFieldType.text,
      field: 'upsDescription',
      labelI18nKey: 'UPS Description',
      placeholderI18nKey:
        'avantPlatform.attributes.property.prompt.enterUpsDescription',
    },
    {
      type: FormFieldType.text,
      field: 'generator',
      labelI18nKey: 'avantPlatform.attributes.property.label.generator',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterText',
    },
    {
      type: FormFieldType.text,
      field: 'generatorDescription',
      labelI18nKey: 'Generator Description',
      placeholderI18nKey:
        'avantPlatform.attributes.property.prompt.enterGeneratorDescription',
    },
    {
      type: FormFieldType.text,
      field: 'pdu',
      labelI18nKey: 'avantPlatform.attributes.property.label.pdu',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterText',
    },
    {
      type: FormFieldType.text,
      field: 'pduDescription',
      labelI18nKey: 'PDU Description',
      placeholderI18nKey:
        'avantPlatform.attributes.property.prompt.enterPduDescription',
    },
    {
      type: FormFieldType.text,
      field: 'crah',
      labelI18nKey:
        'avantPlatform.attributes.property.label.computerRoomAirHandler',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterText',
    },
    {
      type: FormFieldType.text,
      field: 'crahDescription',
      labelI18nKey: 'CRAH Description',
      placeholderI18nKey:
        'avantPlatform.attributes.property.prompt.enterCrahDescription',
    },
    {
      type: FormFieldType.decimal,
      field: 'powerUseEfficiency',
      labelI18nKey: 'PUE',
      pattern: REGEX_DECIMALS,
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterPue',
    },
    {
      type: FormFieldType.text,
      field: 'cooling',
      labelI18nKey: 'avantPlatform.attributes.property.label.cooling',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterText',
    },
    {
      type: FormFieldType.company,
      field: 'fiberLinesCompanies',
      inputId: 'fiberLinesCompanies',
      labelI18nKey:
        'avantPlatform.attributes.property.label.fiberLinesCompanies',
      placeholderI18nKey:
        'avantPlatform.roles.company.prompt.enterFiberLineCompanyName',
      companyConfig: {
        createCompanyLabelI18nKey:
          'avantPlatform.roles.company.action.createFiberLineCompany',
        isMultiple: true,
      },
    },
    {
      type: FormFieldType.company,
      field: 'cloudServicesCompanies',
      inputId: 'cloudServicesCompanies',
      labelI18nKey:
        'avantPlatform.attributes.property.label.cloudServiceCompanies',
      placeholderI18nKey:
        'avantPlatform.roles.company.prompt.enterCloudServiceCompanyName',
      companyConfig: {
        createCompanyLabelI18nKey:
          'avantPlatform.roles.company.action.createCloudServiceCompany',
        isMultiple: true,
      },
    },
  ];
};
