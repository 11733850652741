import React, { Dispatch, SetStateAction } from 'react';

import FormControl from 'components/FormControl';
import DateInput from 'components/Inputs/DateInput';
import TextInput from 'components/Inputs/TextInput';
import RadioInput, { IRadioItem } from 'components/Inputs/RadioInput';
import { REGEX_INTEGERS } from 'constants/regex';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ILeaseOption } from 'interfaces/ILeaseOption';
import { translateText } from 'utils/i18n';

import styles from '../../../FormSection.module.scss';
import { getRenewalTermOptions } from '../constants';

interface Props {
  currentOption: ILeaseOption;
  setCurrentOption: Dispatch<SetStateAction<ILeaseOption>>;
  commonFields: React.ReactNode;
}
export const RenewalFields = ({
  currentOption,
  setCurrentOption,
  commonFields,
}: Props) => {
  return (
    <>
      <FormControl
        wrapperClassName={styles['form-row']}
        label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.renewalOptionDate`,
        )}
      >
        <DateInput
          id="renewal-option-date"
          value={currentOption.optionDate}
          onChange={value =>
            setCurrentOption({
              ...currentOption,
              optionDate: value ?? null,
            })
          }
        />
      </FormControl>
      <FormControl
        wrapperClassName={styles['form-row']}
        label={translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.renewalTerm`,
        )}
      >
        <div className={styles['large-input-container']}>
          <TextInput
            id="renewal-term"
            value={currentOption?.renewalTermInMonths ?? ''}
            pattern={REGEX_INTEGERS}
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enterMonths`,
            )}
            onChange={value => {
              setCurrentOption({
                ...currentOption,
                renewalTermInMonths: +value || null,
              });
            }}
          />
          <RadioInput
            selectedItem={currentOption?.renewalTermInMonths ?? ''}
            data={getRenewalTermOptions(translateText)}
            onChange={(item: IRadioItem) =>
              setCurrentOption({
                ...currentOption,
                renewalTermInMonths: +item.value!,
              })
            }
          />
        </div>
      </FormControl>
      {commonFields}
    </>
  );
};
