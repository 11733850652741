import React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { startCase, noop } from 'lodash';
import { HashLink } from 'react-router-hash-link';

import Icon from 'components/Icon';

import styles from './SideNavigationItem.module.scss';
import { SideNavigationItemProps } from './types';
import { colors } from 'constants/colors';

const SideNavigationItem = ({
  className,
  name,
  to,
  icon,
  label,
  isActive,
  isBarCollapsed,
  isSubitem,
  isDisabled,
  onClick = noop,
  onScroll,
}: SideNavigationItemProps) => {
  const text = label || startCase(name);

  return (
    <HashLink
      smooth
      className={classnames(styles['side-navigation-item'], className, {
        [styles.active]: isActive,
        [styles.collapsed]: isBarCollapsed,
        [styles.subitem]: isSubitem,
        [styles.disabled]: isDisabled,
      })}
      to={to || `#${name}`}
      scroll={onScroll}
      onClick={() => onClick(name)}
      data-tip={isBarCollapsed ? text : ''}
      data-place="right"
      data-arrow-color="transparent"
    >
      {icon && (
        <Icon
          color={isActive ? colors.uiColorBlack : colors.uiColor500}
          className={styles.icon}
          name={icon}
          size={isSubitem && !isBarCollapsed ? 1 : 1.25}
        />
      )}
      {!isBarCollapsed ? (
        <span className={styles.label}>{text}</span>
      ) : (
        <ReactTooltip />
      )}
    </HashLink>
  );
};

export default SideNavigationItem;
