export const PROPERTY_SOURCE_ID = 'propertySourceID';
export const PROPERTY_LAYER_ID = 'propertyLayerID';

// The threshold at which the map switches between heatmap and symbol layers in performance mode.
export const ZOOM_THRESHOLD_PERFORMANCE_MODE = 14;
// The threshold at which the map switches to performance mode.
export const PROPERTY_THRESHOLD_PERFORMANCE_MODE = 1000;
export const PIN_IMAGE_ID = 'property-pin-medium-size';
export const DEFAULT_ZOOM = 10;
export const PROPERTIES_PAGE_SIZE = 500;
export const DEFAULT_MAP_STATE = {
  isReadyToDrawPins: false,
  isMapReady: false,
  mapCenterLngLat: undefined,
};
