import React from 'react';
import classNames from 'classnames';

import { securityDepositTypes } from 'constants/securityDepositTypes';
import FormControl from 'components/FormControl';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IdName } from 'interfaces/IdName';
import { currencyInputFormatter } from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';

import styles from '../../FormSection.module.scss';

interface Props {
  currencySymbol: string;
  securityDeposit: number | undefined;
  securityDepositType: IdName | undefined;
  onChangesSecurityDeposit: (v: string) => void;
  onChangeSecurityDepositType: (v: IdName | undefined) => void;
}
export const SecurityDeposit = ({
  currencySymbol,
  securityDeposit,
  securityDepositType,
  onChangesSecurityDeposit,
  onChangeSecurityDepositType,
}: Props) => {
  return (
    <FormControl
      label={translateText(
        `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.securityDeposit`,
      )}
      wrapperClassName={styles['form-row']}
    >
      <div
        className={classNames(
          styles['large-input-container'],
          styles['large-input-container-gap'],
        )}
      >
        <CurrencyInput
          id="secutiry-deposit"
          value={securityDeposit}
          onChange={onChangesSecurityDeposit}
          currencySymbol={currencySymbol}
          iconPosition="left"
          formatter={currencyInputFormatter}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
        />
        <div className={styles['wrapper-radio-container']}>
          <ChoiceInput
            id="secutiry-deposit-type"
            data={securityDepositTypes}
            onChange={onChangeSecurityDepositType}
            selectedItem={securityDepositType}
            containerWrapperClassName={styles['radio-input-container']}
          />
        </div>
      </div>
    </FormControl>
  );
};
