import { AgencyAssignmentsProps } from 'components/AgencyAssignments/types';
import ProfileSubSection from 'components/ProfileSubSection';
import { I18N_AVANT_PROPERTY_HEADINGS_PATH } from 'constants/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import DonutsCharts from '../DonutsCharts';
import KPIs from '../KPIs';
import SubmarketsAndOwners from '../SubmarketsAndOwners';
import styles from '../Properties.module.scss';

interface CompositionProps extends AgencyAssignmentsProps {
  setPropertiesPopup(propertiesPopup: ILargestPropertiesPopup): void;
}

const Composition: React.FC<CompositionProps> = ({
  propertySet,
  setPropertiesPopup,
}) => {
  const { t } = useTranslation();
  const properties = propertySet?.properties || [];
  const propertiesIds =
    propertySet?.properties?.map(property => property.id!) || [];

  return (
    <ProfileSubSection
      title={t(`${I18N_AVANT_PROPERTY_HEADINGS_PATH}.composition`)}
    >
      {propertySet.id && <KPIs propertySetId={propertySet?.id} />}
      <DonutsCharts
        propertiesIds={propertiesIds}
        setPropertiesPopup={setPropertiesPopup}
      />
      <div className={styles['chart-content']}>
        <SubmarketsAndOwners
          properties={properties}
          setPropertiesPopup={setPropertiesPopup}
        />
      </div>
    </ProfileSubSection>
  );
};

export default Composition;
