import { TableHeaderProps } from 'react-table';

export const extractItemId = (row: any) => row?.original?.id || '';

export const mapSticky = (tableProps: TableHeaderProps, isHeader?: boolean) => {
  const { style } = tableProps;
  if (style && style.position === 'sticky') {
    style.whiteSpace = 'normal';
    style.zIndex = isHeader ? 10 : 8;
  }

  return tableProps;
};
