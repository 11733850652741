import { select } from 'd3-selection';
import { GraphIds } from '../nodes';
import { HoldPeriodSegment } from 'components/Graphs/HoldPeriod/graphConstants';

const displayId = (id: string) => {
  select(`#${id}`).style('display', '');
};

const hideId = (id: string) => {
  select(`#${id}`).style('display', 'none');
};

export const onMouseOverFirstBar = (
  isMktHigher: boolean,
  onMouseOver?: (segment: HoldPeriodSegment) => void,
) => {
  displayId(GraphIds.FirstRectShadowBar);
  onMouseOver?.(
    isMktHigher ? HoldPeriodSegment.property : HoldPeriodSegment.market,
  );
};

export const onMouseOutFirstBar = (
  isMktHigher: boolean,
  onMouseOut?: () => void,
) => {
  hideId(GraphIds.FirstRectShadowBar);
  onMouseOut?.();
};

export const onMouseOverSecondBar = (
  isMktHigher: boolean,
  onMouseOver?: (segment: HoldPeriodSegment) => void,
) => {
  displayId(GraphIds.SecondRectShadowBar);
  onMouseOver?.(
    isMktHigher ? HoldPeriodSegment.market : HoldPeriodSegment.property,
  );
};

export const onMouseOutSecondBar = (
  isMktHigher: boolean,
  onMouseOut?: () => void,
) => {
  hideId(GraphIds.SecondRectShadowBar);
  onMouseOut?.();
};
