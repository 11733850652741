export enum RecentLeasesIds {
  PropertyPage = 'recent-leases-property-page',
  CompanyLeases = 'recent-leases-company-page',
  CompanyTenants = 'recent-leases-property-page-tenants',
  SubmarketPerformancePage = 'recent-leases-submkt-page',
  LeasesAnalyticsPage = 'recent-leases-find-comps-page',
  SalesAnalyticsPage = 'recent-sales-find-comps-page',
  MarketPerformancePage = 'recent-leases-mkt-page',
  CompSetLeasingTrendsPage = 'recent-leases-comp-set-page',
}
