import React from 'react';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import LoadingMessage from 'components/LoadingMessage';
import InfoBoxes from 'components/InfoBoxes';
import { usePropertySetLeasingMetadata } from 'hooks/usePropertySetLeasingMetadata';
import { IInfoBox } from 'components/InfoBoxes/constants';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { GET_OVERVIEW_LEASING_METADATA } from 'graphql/agencyAssignments';
import { AgencyAssignmentsProps } from 'components/AgencyAssignments/types';
import { parseInfoBoxesData } from '../utils';
import styles from './KPIS.module.scss';

const KPIs: React.FC<AgencyAssignmentsProps> = ({
  propertySet,
  toggleOptions,
  askingRentData,
}) => {
  const {
    propertySetLeasingMetadataResponse,
    isLoading,
  } = usePropertySetLeasingMetadata(
    propertySet.id!,
    GET_OVERVIEW_LEASING_METADATA,
  );

  const parsedData =
    propertySetLeasingMetadataResponse &&
    (parseInfoBoxesData(
      propertySetLeasingMetadataResponse,
      UnitOfMeasurement.sf,
      toggleOptions!,
      askingRentData,
    ) as IInfoBox[]);

  return isLoading ? (
    <LoadingMessage />
  ) : (
    <Row wrapperClassName={styles.row}>
      <Col>
        <InfoBoxes data={parsedData} textAlign="center" />
      </Col>
    </Row>
  );
};

export default KPIs;
