import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import ReactTooltip from 'react-tooltip';

import 'simplebar/dist/simplebar.css';
import './assets/stylesheets/global.scss';

import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import './i18n';
import './numeralInstance';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthContextProvider from './contexts/AuthContext';
import SubscriptionsContextProvider from 'contexts/SubscriptionsContext';
import HelmetContextProvider from 'contexts/HelmetContext';
import createClient from './graphql/propertiesClient';
import ScrollToTop from './components/ScrollToTop';
import { loadGoogleMapsLibrary } from 'utils/maps/loadGoogleMapsLibrary';
import { applyDayJSPlugins } from 'utils/dayjsPlugins';
import { reactPlugin } from './utils/applicationInsights';
import { WebsocketProvider } from 'contexts/WebsocketContext';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import { CountriesProvider } from 'contexts/CountriesContext';
import LeaseProvider from 'contexts/LeaseInputContext';
import CompSetOrderProvider from './contexts/CompSetOrderContext';
import { CurrencyProvider } from './contexts/CurrencyContext';

loadGoogleMapsLibrary();
applyDayJSPlugins();

if (window._env_.REACT_APP_SENTRY && window._env_.REACT_APP_ENV) {
  localStorage.setItem(LocalStorageKeys.appServicesStatus, 'true');
  Sentry.init({
    dsn: window._env_.REACT_APP_SENTRY,
    integrations: [new TracingIntegrations.BrowserTracing()],
    environment: window._env_.REACT_APP_ENV,
    tracesSampleRate: 0.3,
    denyUrls: ['https://logo.clearbit.com', 'https://clearbit.com/'],
    ignoreErrors: [
      // This error happens when the backend throws an error, which we already track with Graylogs.
      'Network error: Response not successful: Received status code 400',
      // These 2 errors are just warnings to the user
      'The Availability Data is bigger than the Total Size available',
      'The Historical Data is bigger than the Total Size available',
      // The app is already handling this error, there is no need to noise Sentry with it.
      'Loading CSS chunk',
      'Loading chunk',
      'ChunkLoadError',
      'clearbit.com',
      'Unauthorized!',
    ],
  });
  Sentry.setTag('app', 'Properties');
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <WebsocketProvider>
    <ApolloProvider client={createClient()}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <AuthContextProvider>
          <CurrencyProvider>
            <CountriesProvider>
              <CompSetOrderProvider>
                <LeaseProvider>
                  <Router>
                    <HelmetContextProvider>
                      <ScrollToTop />
                      <SubscriptionsContextProvider>
                        <App />
                      </SubscriptionsContextProvider>
                      <ReactTooltip id="tooltip" className="tooltip-wrapper" />
                    </HelmetContextProvider>
                  </Router>
                </LeaseProvider>
              </CompSetOrderProvider>
            </CountriesProvider>
          </CurrencyProvider>
        </AuthContextProvider>
      </AppInsightsContext.Provider>
    </ApolloProvider>
  </WebsocketProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
