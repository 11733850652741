import { IdName } from 'interfaces/IdName';
import { ActivityFeedCriteriaType } from 'components/ActivityFeed/types';
import { IUser } from 'interfaces/IUser';
import { PropertyTypeNames } from 'constants/propertyTypes';

interface IActivitiesQueryFilter {
  tags?: number[];
  propertyId?: number;
  propertiesIds?: number[];
  propertyTypes?: string[];
  markets?: IdName[];
  submarkets?: IdName[];
  micromarkets?: IdName[];
  propertyClasses?: IdName[];
  industries?: IdName[];
  companies?: IdName[];
}

export const buildQueryFilter = (
  user?: IUser,
  searchCriteria?: ActivityFeedCriteriaType,
  useDefaultUserPropertyType = true,
): IActivitiesQueryFilter => {
  let filter: IActivitiesQueryFilter = {};

  const userPreferences = user?.preferences;
  const propertiesIds = user?.subscriptions?.[0].accessData?.propertyId;

  // checking if the user has full access in any subscription
  const fullAccess =
    !!user?.subscriptions?.[0]?.isActive &&
    !!user.subscriptions?.[0].fullAccess;

  if (!!searchCriteria?.tags?.length) {
    filter.tags = searchCriteria?.tags
      ?.filter(sc => !isNaN(sc.id))
      ?.map(sc => sc.id);
  }

  if (searchCriteria?.customCriteria?.companies) {
    filter.companies = searchCriteria.customCriteria.companies.map(c => ({
      id: c.id,
    }));
  }

  if (!!searchCriteria?.buildingSet?.properties?.length) {
    filter.propertiesIds =
      searchCriteria?.buildingSet?.properties?.map(p => p.id!) || undefined;

    return filter;
  }

  if (searchCriteria?.customCriteria?.markets) {
    filter.markets = searchCriteria.customCriteria.markets.map(market => ({
      id: market.id,
    }));
  } else if (userPreferences?.marketIds) {
    filter.markets = userPreferences?.marketIds.map(market => ({
      id: market,
    }));
  }

  if (searchCriteria?.customCriteria?.propertyTypes?.length) {
    filter.propertyTypes = searchCriteria?.customCriteria?.propertyTypes?.map(
      t => t.name,
    );
  } else if (useDefaultUserPropertyType && userPreferences?.propertyTypes) {
    filter.propertyTypes = userPreferences?.propertyTypes?.map(
      x => PropertyTypeNames[x],
    );
  } else if (useDefaultUserPropertyType && userPreferences?.propertyType) {
    filter.propertyTypes = [userPreferences?.propertyType];
  }

  if (!fullAccess && propertiesIds) {
    filter.propertiesIds = propertiesIds;
  }

  filter = {
    ...filter,
    submarkets: searchCriteria?.customCriteria?.submarkets?.map(s => ({
      id: s.id,
    })),
    propertyClasses: searchCriteria?.customCriteria?.propertyClasses,
    industries: searchCriteria?.customCriteria?.industries,
  };

  return filter;
};
