import classnames from 'classnames';
import React, {
  useState,
  createContext,
  useMemo,
  PropsWithChildren,
} from 'react';

import styles from './Tooltip.module.scss';

const RootContext = createContext<{
  isActive: boolean;
  handleIsActiveToggle: () => void;
} | null>(null);

export const Root = ({
  children,
  className,
  preventFromOpening,
}: {
  children: React.ReactNode;
  className?: string;
  preventFromOpening?: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleIsActiveToggle = () => {
    if (!preventFromOpening) {
      setIsActive(!isActive);
    }
  };

  const context = useMemo(
    () => ({
      isActive,
      handleIsActiveToggle,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive],
  );
  return (
    <RootContext.Provider value={context}>
      <div className={classnames(styles['root'], className)}>{children}</div>
    </RootContext.Provider>
  );
};

const useRootContext = (component: string) => {
  const context = React.useContext(RootContext);

  if (context === null) {
    throw new Error(
      `<Tooltip.${component} /> component is not called within expected parent component`,
    );
  }

  return context;
};

const Trigger = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const { handleIsActiveToggle } = useRootContext('Trigger');
  return (
    <div
      onMouseEnter={handleIsActiveToggle}
      onMouseLeave={handleIsActiveToggle}
      className={className}
    >
      {children}
    </div>
  );
};

const Body = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  const { isActive } = useRootContext('Trigger');
  return (
    <span className={classnames(styles.body, className)} hidden={!isActive}>
      {children}
    </span>
  );
};

export const Tooltip = Object.assign(Root, { Trigger, Body });
