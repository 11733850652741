import React from 'react';
import { IdName } from 'interfaces/IdName';
import { DocumentNode, OperationVariables, useQuery } from '@apollo/client';
import ChoiceInput, { ChoiceInputType } from 'components/Inputs/ChoiceInput';

type PropTypes = {
  query: DocumentNode;
  queryParser: (data: any) => IdName[];
  variables?: OperationVariables;
  selectedItem?: IdName;
} & Omit<ChoiceInputType, 'data'>;

const RemoteChoiceInput: React.FC<PropTypes> = (props: PropTypes) => {
  const { loading, data } = useQuery(props.query, {
    variables: props.variables,
  });

  if (loading) return null;

  const parsedValues = props.queryParser(data);

  if (!parsedValues?.length) return null;

  return <ChoiceInput {...props} data={parsedValues} />;
};

export default RemoteChoiceInput;
