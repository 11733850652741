import React from 'react';
import classnames from 'classnames';
import styles from './GraphContainer.module.scss';

interface Props {
  children?: React.ReactNode;
  isBorderBottomRounded?: boolean;
  isBorderTopRounded?: boolean;
  wrapperClassName?: string;
  style?: React.CSSProperties;
}

const GraphContainer: React.FC<Props> = ({
  children,
  isBorderBottomRounded = true,
  isBorderTopRounded = true,
  wrapperClassName,
  style,
}) => {
  return (
    <div
      className={classnames(
        styles['graph-container'],
        {
          [styles['border-top']]: isBorderTopRounded,
          [styles['border-bottom']]: isBorderBottomRounded,
        },
        wrapperClassName,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default GraphContainer;
