import React from 'react';
import classnames from 'classnames';
import AvantLogoShort from 'assets/images/avant-logo-short.svg';
import { IProperty } from 'interfaces/IProperty';
import { getPropertyAddress } from 'utils/formatters/property';
import { getCoverImageUrl } from '../../ModalMedia/utils';
import styles from './CardImageLogo.module.scss';
import { ZoomLevel } from 'components/PropertyMap';
import { createStaticMapImageUrl } from 'utils/maps/staticMapboxImage';

interface Props {
  property: IProperty | undefined;
}

const CARD_IMAGE_WIDTH = 208;
const CARD_IMAGE_HEIGHT = 88;

const CardImageLogo: React.FC<Props> = ({ property }) => {
  property = property ?? {};
  const propertyCoverImageUrl = getCoverImageUrl(
    property,
    CARD_IMAGE_WIDTH,
    CARD_IMAGE_HEIGHT,
  );
  const address = getPropertyAddress(property);

  const staticMapImageURL = createStaticMapImageUrl({
    height: CARD_IMAGE_HEIGHT,
    latitude: property.latitudeDisplay || property.latitude!,
    longitude: property.longitudeDisplay || property.longitude!,
    width: CARD_IMAGE_WIDTH,
    zoom: ZoomLevel.regular,
  });

  if (!staticMapImageURL) {
    return null;
  }

  return (
    <div className={styles.logo}>
      <div
        className={classnames({
          [styles.marker]: !propertyCoverImageUrl && staticMapImageURL,
        })}
      />
      <img
        className={classnames(styles['logo-image'], {
          [styles['avant-logo']]: !staticMapImageURL,
        })}
        alt={address!}
        src={propertyCoverImageUrl ?? staticMapImageURL ?? AvantLogoShort}
      />
    </div>
  );
};

export default CardImageLogo;
