export enum StatusTypesIDs {
  Existing = 1,
  UnderConstruction = 2,
  UnderRenovation = 5,
  ProposedShortTerm = 4,
  ProposedLongTerm = 3,
  Demolished = 7,
}

export enum StatusTypes {
  Existing = 'Existing',
  UnderConstruction = 'Under Construction',
  UnderRenovation = 'Under Renovation',
  ProposedShortTerm = 'Proposed Short-Term',
  ProposedLongTerm = 'Proposed Long-Term',
  Demolished = 'Demolished',
}
