import React, { useState } from 'react';
import { IdName } from 'interfaces/IdName';
import { ICompany } from 'interfaces/ICompany';
import FormControl from 'components/FormControl';
import LeaseFilter from 'components/LeaseFilter';
import { SearchInputSize } from 'components/SearchInput/enums';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export interface Props {
  aliasPlaceholder?: (suggestionName: string) => string;
  btnLabel?: string;
  companies?: IdName[];
  hasLabel?: boolean;
  inputId?: string;
  disabledPlaceholder?: string;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isRequired?: boolean;
  isSearchIconLeftVisible?: boolean;
  label?: string;
  onClearSelectedSuggestion?: () => void;
  onLeaseSelection: (newValue: any) => void;
  onRemoveItem?: (value: IdName) => void;
  placeholder?: string;
  size?: SearchInputSize;
  wrapperClassName?: string;
  searchOnlyCompanies?: boolean;
  tenantCompanyId?: number;
  currentLeaseId?: number | null;
  currentPropertyId?: number;
  currentMarketId?: number;
  onInputClear?: () => void;
}

const CREATE_COMPANY_DATA = {
  domain: '',
  headquarters: '',
  industry: undefined,
  name: '',
  stockCode: '',
  website: '',
  headquarterProperty: undefined,
};

const LeaseSearchCombo: React.FC<Props> = ({
  aliasPlaceholder,
  btnLabel,
  companies,
  hasLabel = true,
  inputId,
  isDisabled,
  disabledPlaceholder,
  isMultiple,
  isRequired,
  isSearchIconLeftVisible,
  label,
  onClearSelectedSuggestion,
  onLeaseSelection,
  onRemoveItem,
  placeholder,
  size,
  wrapperClassName,
  searchOnlyCompanies,
  tenantCompanyId,
  currentPropertyId,
  currentLeaseId,
  currentMarketId,
  onInputClear,
}) => {
  const { t } = useTranslation();
  const [companyData, setCompanyData] = useState<ICompany>(CREATE_COMPANY_DATA);

  const handleCreateCompanyDataUpdate = (field: string, value: any) => {
    const createCompanyNewData = {
      ...companyData,
      [field]: value,
    };
    setCompanyData(createCompanyNewData);
  };

  const onChangeText = (value?: string) =>
    !value?.length && onLeaseSelection(null);

  const renderInput = () => (
    <LeaseFilter
      tenantCompanyId={tenantCompanyId}
      currentPropertyId={currentPropertyId}
      currentMarketId={currentMarketId}
      currentLeaseId={currentLeaseId}
      onChangeText={value => onChangeText(value)}
      aliasPlaceholder={aliasPlaceholder}
      inputId={inputId}
      placeholder={placeholder}
      isMultiple={isMultiple || false}
      onClearSelectedSuggestion={onClearSelectedSuggestion}
      onRemoveItem={(selectedValue: IdName) =>
        onRemoveItem && onRemoveItem(selectedValue)
      }
      onSelectItem={(selectedValue: SearchOptionsType) =>
        onLeaseSelection(selectedValue)
      }
      selectedItems={companies || []}
      customOption={{
        btnLabel:
          btnLabel || t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterBuyerName`),
        onSelection: (value: string) => {
          handleCreateCompanyDataUpdate('name', value);
        },
      }}
      isDisabled={isDisabled}
      disabledPlaceholder={disabledPlaceholder}
      isSearchIconLeftVisible={isSearchIconLeftVisible}
      size={size}
      searchOnlyCompanies={searchOnlyCompanies}
      onInputClear={onInputClear}
    />
  );

  return (
    <>
      {hasLabel ? (
        <FormControl
          label={label}
          required={isRequired || false}
          wrapperClassName={wrapperClassName}
          disabled={isDisabled}
        >
          {renderInput()}
        </FormControl>
      ) : (
        renderInput()
      )}
    </>
  );
};

export default LeaseSearchCombo;
