import React, { useState } from 'react';
import styles from './ActivityPricingQuoteCard.module.scss';
import { IProperty } from 'interfaces/IProperty';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import { ColorNames } from 'constants/colorNames';
import LinksList from 'components/LinksList';
import { IPricingQuote } from 'interfaces/IPricingQuote';
import { IdName } from 'interfaces/IdName';
import { CompType } from 'constants/compType';
import { ActivityCardContainer, ActivityCardHeader } from '../../ActivityCard';
import ActivityCardActions from 'components/ActivityCardActions';
import ModalEditComp from 'components/ModalEditComp';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { PricingQuoteCardField } from '../PropertyPricingQuoteCard/fields';
import { getTranslatedRentType } from 'utils/leases';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import {
  buildPricingQuoteDate,
  buildTagsListItems,
} from '../PropertyPricingQuoteCard/buildTagsListItems';
import locations from '../../../routes';
import TagsList from '../../TagsList';

interface Props {
  date: string;
  displayFields: PricingQuoteCardField[];
  onShareButtonClick: () => void;
  onUpdate?: () => void;
  property: IProperty;
  pricingQuote: IPricingQuote;
}

const PRICING_QUOTE_SCOOP_CATEGORY: IdName = {
  id: 5,
  name: 'Pricing Quote',
};

const ActivityPricingQuoteCard: React.FC<Props> = props => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const pricingQuoteHeader = buildPricingQuoteDate(props.pricingQuote);
  const tags = buildTagsListItems(props.pricingQuote, props.displayFields);

  const renderBuyerLinks = () =>
    props.pricingQuote.tenantCompany ? (
      <LinksList list={[props.pricingQuote.tenantCompany] as IdName[]} bold />
    ) : null;

  const translatedSubheadline = getTranslatedRentType(
    props.pricingQuote.rentType,
  );
  const currencySymbol = getCurrencySymbol(
    props.property?.propertyCountry?.currencyCode ||
      props.property?.currencyCode,
  );
  const headlineText = `${currencySymbol}${formatNumberWithDecimals(
    props.pricingQuote.askingRentPsf.toString(),
    2,
  )} ${translatedSubheadline}`;

  return (
    <>
      <ActivityCardContainer>
        <ActivityCardHeader
          headerText={pricingQuoteHeader}
          headlineText={headlineText}
          targetPropertyUrl={locations.showProperty(props.property.id!)}
          property={props.property}
          categories={[PRICING_QUOTE_SCOOP_CATEGORY]}
          data={props.pricingQuote}
        />
        {renderBuyerLinks()}
        <Row wrapperClassName={styles['pricing-quote-data']}>
          <Col xs={4} lg={12}>
            <TagsList
              tags={tags}
              tagBackgroundClass={styles[`background-${ColorNames.uiColor100}`]}
            />
          </Col>
        </Row>
        <ActivityCardActions
          onEditClick={() => setIsEditEnabled(true)}
          onShareClick={props.onShareButtonClick}
        />
      </ActivityCardContainer>
      {isEditEnabled && (
        <ModalEditComp
          type={CompType.pricingQuote}
          compId={props.pricingQuote.id!}
          onUpdate={props.onUpdate}
          onModalToggle={setIsEditEnabled}
        />
      )}
    </>
  );
};

export default ActivityPricingQuoteCard;
