import { I18N_AVANT_PROPERTY_LABEL_PATH } from 'constants/i18n';

export const I18N_TENANT_TOUR_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.tenantTour`;

export const OVERVIEW_SECTION = 'overview';
export const SPACES_SECTION = 'spaces';
export const RECENT_ACTIVITY_SECTION = 'recent-activity';
export const TENANT_TOUR_SECTIONS = {
  OVERVIEW_SECTION,
  SPACES_SECTION,
  RECENT_ACTIVITY_SECTION,
};
