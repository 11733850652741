import React from 'react';
import { isBoolean } from 'lodash';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import { translateText } from 'utils/i18n';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { REGEX_INTEGERS } from 'constants/regex';
import { TIMES } from 'constants/placeholders';
import { DOCK_CONFIGURATION_OPTIONS } from 'constants/dockConfigutations';
import {
  getFieldNameForProperty,
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
} from 'constants/unitOfMeasurement';
import { getBooleanSelectedOption, getBooleanValue } from '../utils';
import styles from '../../FormSection.module.scss';
import { FieldType } from '../types';
import CeilingHeightField from './CeilingHeightField';
import { getYesNoOptions } from './constants';
import { ExclusiveFieldProps } from './ExclusiveFields';

const LifeScienceFields: React.FC<ExclusiveFieldProps> = ({
  onChange,
  property,
  property: { lifeScience },
  classNames: { formControlClassName, inputHelperClassName },
}) => {
  const updateLifeScienceFields = (field: string, value: unknown) => {
    onChange(FieldType.lifeScience, {
      ...(lifeScience ?? {}),
      [field]: isBoolean(value) ? value : value || null,
    });
  };

  const getFieldValue = (field: string) =>
    getFieldValueForProperty(
      field,
      property,
      ModelsWithUnitsOfMeasurement.LifeScience,
    );

  const getFieldName = (field: string) =>
    getFieldNameForProperty(
      field,
      property,
      ModelsWithUnitsOfMeasurement.LifeScience,
    );

  const YES_NO_OPTIONS = getYesNoOptions();
  return (
    <div>
      <CeilingHeightField
        onChange={onChange}
        fieldType={FieldType.lifeScience}
        property={property}
        valueField={lifeScience}
        classNames={{
          formControlClassName,
          inputHelperClassName,
        }}
      />

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.columnSpacing',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="columnSpacingMinInput"
          isNarrow
          textCentered
          placeholder={'00'}
          pattern={REGEX_INTEGERS}
          value={getFieldValue('columnSpacingMin')}
          onChange={value =>
            updateLifeScienceFields(getFieldName('columnSpacingMin'), value)
          }
        />
        <span className={inputHelperClassName}>{TIMES}</span>
        <TextInput
          id="columnSpacingMaxInput"
          isNarrow
          textCentered
          placeholder={'00'}
          pattern={REGEX_INTEGERS}
          value={getFieldValue('columnSpacingMax')}
          onChange={value =>
            updateLifeScienceFields(getFieldName('columnSpacingMax'), value)
          }
        />
        <span className={inputHelperClassName}>
          {getUnitOfMeasurementForProperty(
            'columnSpacingMax',
            ModelsWithUnitsOfMeasurement.Office,
            property?.propertyCountry?.code || property?.measurementSystem,
          )}
        </span>
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.slabToSlab',
        )}
        wrapperClassName={formControlClassName}
        columnClassName={styles['slab-field-container']}
        labelClassName={styles['slab-label-container']}
      >
        <div>
          <TextInput
            id="slabCeilingHeightFeetInput"
            pattern={REGEX_INTEGERS}
            value={property?.lifeScience?.slabCeilingHeightFeet}
            placeholder="00"
            inputClassName={styles['form-input-field-small']}
            onChange={value =>
              updateLifeScienceFields('slabCeilingHeightFeet', value)
            }
          />
          <span className={inputHelperClassName}>{UnitOfMeasurement.feet}</span>
        </div>
        <div>
          <TextInput
            id="slabCeilingHeightInchesInput"
            pattern={REGEX_INTEGERS}
            value={property?.lifeScience?.slabCeilingHeightInches}
            placeholder="00"
            inputClassName={styles['form-input-field-small']}
            onChange={value =>
              updateLifeScienceFields('slabCeilingHeightInches', value)
            }
          />
          <span className={inputHelperClassName}>
            {UnitOfMeasurement.inches}
          </span>
        </div>
      </FormControl>

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.loadingDocks',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="loadingDocksInput"
          value={lifeScience?.loadingDocks}
          placeholder={translateText(
            'avantPlatform.attributes.property.label.loadingDocks',
          )}
          onChange={value => {
            updateLifeScienceFields('loadingDocks', value);
          }}
        />
      </FormControl>

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.dockConfiguration',
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          selectedItem={{
            id: lifeScience?.dockConfigurationType?.id,
          }}
          itemWrapperClassName={styles['form-margin-bottom-control']}
          data={DOCK_CONFIGURATION_OPTIONS}
          onChange={item => {
            updateLifeScienceFields('dockConfigurationType', item);
          }}
        />
      </FormControl>

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.driveInDoors',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="driveInDoorsInput"
          value={lifeScience?.driveInDoors}
          placeholder={translateText(
            'avantPlatform.attributes.property.label.driveInDoors',
          )}
          onChange={value => {
            updateLifeScienceFields('driveInDoors', value);
          }}
        />
      </FormControl>

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.typicalClearHeightLow',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="typicalClearHeightMinInput"
          value={lifeScience?.typicalClearHeightMin}
          placeholder="00"
          onChange={value => {
            updateLifeScienceFields('typicalClearHeightMin', value);
          }}
          inputClassName={styles['form-input-field-small']}
        />
        <span className={inputHelperClassName}>{UnitOfMeasurement.feet}</span>
      </FormControl>

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.typicalClearHeightHigh',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="typicalClearHeightMaxInput"
          value={lifeScience?.typicalClearHeightMax}
          placeholder="00"
          onChange={value => {
            updateLifeScienceFields('typicalClearHeightMax', value);
          }}
          inputClassName={styles['form-input-field-small']}
        />
        <span className={inputHelperClassName}>{UnitOfMeasurement.feet}</span>
      </FormControl>

      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.conversion',
        )}
        wrapperClassName={formControlClassName}
      >
        <ChoiceInput
          data={YES_NO_OPTIONS}
          itemWrapperClassName={styles['form-margin-bottom-control']}
          selectedItem={{
            name: getBooleanSelectedOption(
              YES_NO_OPTIONS,
              property?.lifeScience?.conversion,
            ),
          }}
          labelFieldName="id"
          onChange={value =>
            updateLifeScienceFields(
              'conversion',
              getBooleanValue(
                value?.name,
                YES_NO_OPTIONS,
                property?.lifeScience?.conversion,
              ),
            )
          }
        />
      </FormControl>
    </div>
  );
};

export default LifeScienceFields;
