import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import locations from 'routes';
import styles from './PropertyCard.module.scss';
import { IProperty } from 'interfaces/IProperty';
import { getCoverImageUrl } from 'components/ModalMedia/utils';
import { DOT_LG } from 'constants/placeholders';
import {
  formatPropertyClass,
  getPropertyAddress,
  getPropertyMarkets,
} from 'utils/formatters/property';
import { PropertyImageSizes } from 'constants/propertyImageSizes';
import AYLink from 'components/AYLink';
import { PropertyMapSize, ZoomLevel } from 'components/PropertyMap';
import StaticMapImage from 'components/PropertyMap/StaticMapImage';
import AlternateAddressIconSmall from 'components/AlternateAddressIconSmall';
import { formatPropertyStatus } from 'utils/formatters/propertyStatus';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_COMP_SET_PATH } from 'constants/i18n';
import Button from 'components/Button/new';
import { getPropertySetsByTargetProperty } from 'utils/propertySets';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { GET_PROPERTY_SETS_QUERY } from 'graphql/propertySet';
import { PropertySetTypes } from 'constants/propertySetTypes';
import { SortDirections } from 'constants/sortDirections';
import { useHistory } from 'react-router-dom';
import LoadingMessage from 'components/LoadingMessage';
import { useQuery } from '@apollo/client';
import { PropertyCompSetModalForm } from '../../pages/PropertyPage/PropertyCompSetModalForm';
import { detectNonEnglishUserLanguage } from 'pages/ProjectsPage/utils';
import { authContext } from 'contexts/AuthContext';
import { formatStringUsingElipsis } from 'utils/formatters/string';

type AgencyAssignmentsPropertyCardProps = {
  number: number;
  property?: IProperty;
  wrapperClassName?: string;
  setPropertyIdHover?: (id?: number) => void;
  propertyIdHover?: number;
};

const AgencyAssignmentsPropertyCard = (
  props: AgencyAssignmentsPropertyCardProps,
) => {
  const {
    number,
    property,
    wrapperClassName,
    setPropertyIdHover,
    propertyIdHover,
  } = props;
  const name = property?.name;
  const { user } = useContext(authContext);
  const isLanguageNonEnglish = detectNonEnglishUserLanguage(user.language);
  const history = useHistory();

  const [showModalAddCompSet, setShowModalAddCompSet] = useState(false);
  const [compSet, setCompSet] = useState<IPropertySet>();

  const {
    data: propertySetData,
    loading: isLoadingPropertySet,
    refetch: refetchPropertySet,
  } = useQuery<{
    propertySets: IPropertySet[];
  }>(GET_PROPERTY_SETS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      search: {
        filter: {
          type: PropertySetTypes.AGENCY,
          propertyId: property?.id!,
        },
        order: {
          field: 'createdAt',
          direction: SortDirections.desc,
        },
      },
    },
  });
  const handleButtonLabel = (
    isLanguageNonEnglish: boolean,
    labelText: string,
  ) =>
    isLanguageNonEnglish
      ? formatStringUsingElipsis(labelText, labelText.indexOf(' '))
      : labelText;

  const handleViewCompSetButtonClick = () => {
    const { propertySets = [] } = propertySetData || {};
    const targetPropertySets = getPropertySetsByTargetProperty(
      propertySets,
      property?.id!,
    );

    if (targetPropertySets.length === 1) {
      const propertySet = propertySets[0];

      history.push(
        locations.showPropertyCompSet(property?.id!, propertySet.id!),
      );
    } else {
      history.push(locations.showPropertyCompSets(property?.id!));
    }
  };

  const handleAddCompSetButtonClick = () => {
    setCompSet({ targetProperty: property });
    setShowModalAddCompSet(true);
  };

  const handleCompSetSubmit = () => {
    refetchPropertySet?.();
    setShowModalAddCompSet(false);
  };

  const renderHeaderWithProperty = () => {
    const propertyImage = getCoverImageUrl(property, PropertyImageSizes.xxl);
    const propertyMarkets = getPropertyMarkets(property, DOT_LG);

    const renderNumberSlot = () => {
      return (
        <div
          className={classNames(styles['number-wrapper'], {
            [styles['hover']]: propertyIdHover == property?.id,
          })}
        >
          <p className={classNames(styles['number'])}>{number}</p>
        </div>
      );
    };

    const renderButtonCompSet = () => {
      if (isLoadingPropertySet) {
        return <LoadingMessage />;
      }
      return propertySetData?.propertySets?.length ? (
        <Button
          wrapperClassName={styles['comp-set-button']}
          label={handleButtonLabel(
            isLanguageNonEnglish,
            translateText(`${I18N_AVANT_PROPERTY_COMP_SET_PATH}.viewCompSets`),
          )}
          icon="comparative"
          type="contextual"
          size="s"
          onClick={handleViewCompSetButtonClick}
        />
      ) : (
        <Button
          wrapperClassName={styles['comp-set-button']}
          label={handleButtonLabel(
            isLanguageNonEnglish,
            translateText(`${I18N_AVANT_PROPERTY_COMP_SET_PATH}.addCompset`),
          )}
          icon="addCircle"
          type="contextual"
          size="s"
          onClick={handleAddCompSetButtonClick}
        />
      );
    };

    const renderPropertyThumb = () => {
      if (!property) return null;

      return !!propertyImage ? (
        <div className={styles['content-thumb']}>
          <img alt={name} src={propertyImage} className={styles.image} />
          {renderButtonCompSet()}
        </div>
      ) : (
        <div className={styles['content-thumb']}>
          <StaticMapImage
            wrapperClassName={styles.map}
            size={PropertyMapSize.large}
            zoomLevel={ZoomLevel.big}
            property={property!}
          />
          {renderButtonCompSet()}
        </div>
      );
    };

    const renderPropertyDescriptions = () => {
      return (
        <AYLink
          to={locations.showProperty(property?.id!)}
          wrapperClassName={styles['content-title']}
        >
          <div className={styles['title']}>
            {getPropertyAddress(property)}
            <div className={styles['title-icon']}>
              <AlternateAddressIconSmall property={property} />
            </div>
          </div>
          {!!name && <p className={styles['subtitle']}>{name}</p>}
          {!!propertyMarkets && (
            <p className={styles['subtitle']}>{propertyMarkets}</p>
          )}
          <p className={styles['subtitle']}>
            {[
              formatPropertyClass(property?.propertyClass),
              formatPropertyStatus(property?.status),
              property?.propertyType?.name,
              property?.propertySubtype?.name,
            ]
              .filter(Boolean)
              .join(` ${DOT_LG} `)}
          </p>
        </AYLink>
      );
    };

    return (
      <div className={classNames(styles['header-with-property'])}>
        <div className={styles['header-container']}>
          {renderNumberSlot()}
          {renderPropertyThumb()}
          {renderPropertyDescriptions()}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(styles['card'], wrapperClassName)}
        data-testid="card"
        onMouseEnter={() => setPropertyIdHover?.(property?.id)}
        onMouseLeave={() => setPropertyIdHover?.(undefined)}
      >
        <header className={styles['header-wrapper']}>
          {renderHeaderWithProperty()}
        </header>
      </div>

      {showModalAddCompSet && (
        <PropertyCompSetModalForm
          initialPropertySet={compSet}
          onCancel={() => setShowModalAddCompSet(false)}
          onSubmit={handleCompSetSubmit}
        />
      )}
    </>
  );
};

export default AgencyAssignmentsPropertyCard;
