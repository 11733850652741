import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import { REGEX_INTEGERS, REGEX_THREE_DECIMALS } from 'constants/regex';
import { translateText } from 'utils/i18n';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import {
  convertAcreToSf,
  convertSftoAcre,
  convertHectareToSm,
  convertSmtoHectare,
} from 'utils/unitOfMeasurement';
import { ExclusiveFieldProps } from './ExclusiveFields';
import styles from '../../FormSection.module.scss';
import {
  getUnitOfMeasurementForProperty,
  getFieldNameForProperty,
  getFieldValueForProperty,
} from 'utils/unitsOfMeasurement';
import {
  ModelsWithUnitsOfMeasurement,
  UNITS_MEASUREMENT_ABBREVIATION,
} from 'constants/unitOfMeasurement';

const LandAndDevelopmentFields: React.FC<ExclusiveFieldProps> = ({
  classNames: { formControlClassName },
  onChange,
  property,
}) => {
  const acresMeasurement = getUnitOfMeasurementForProperty(
    'siteSize',
    ModelsWithUnitsOfMeasurement.Property,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  const sfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  const siteSizeSf = getFieldValueForProperty(
    'siteSizeSf',
    property,
    ModelsWithUnitsOfMeasurement.Property,
  );
  const siteSizeAcres = getFieldValueForProperty(
    'siteSizeAcres',
    property,
    ModelsWithUnitsOfMeasurement.Property,
  );

  const [tempSiteSizeSf, setTempSiteSizeSf] = useState(siteSizeSf);

  const convert = (type: string, value: string) => {
    let converter;
    const valueNumber = parseFloat(value);
    if (type === 'acres') {
      converter =
        acresMeasurement === UNITS_MEASUREMENT_ABBREVIATION.acres
          ? convertAcreToSf
          : convertHectareToSm;
    } else if (type === 'sf') {
      converter =
        acresMeasurement === UNITS_MEASUREMENT_ABBREVIATION.acres
          ? convertSftoAcre
          : convertSmtoHectare;
    }

    return value ? converter?.(valueNumber) : '';
  };

  useEffect(() => {
    const newAcres = siteSizeSf ? convert('sf', siteSizeSf) : '';
    const acresFieldName = getFieldNameForProperty(
      'siteSizeAcres',
      property,
      ModelsWithUnitsOfMeasurement.Property,
    );

    if (
      siteSizeSf &&
      formatNumberWithDecimals(newAcres, 3) !==
        formatNumberWithDecimals(siteSizeAcres, 3)
    ) {
      onChange(acresFieldName, formatNumberWithDecimals(newAcres, 3));
    } else if (!(siteSizeSf > 0)) {
      if (!siteSizeSf) {
        onChange(acresFieldName, '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSizeSf]);

  useEffect(() => {
    const newSf = siteSizeAcres ? convert('acres', siteSizeAcres) : '';
    const sfFieldName = getFieldNameForProperty(
      'siteSizeSf',
      property,
      ModelsWithUnitsOfMeasurement.Property,
    );
    const sizeSizeSfValue = Math.ceil(newSf || 0);
    const isSameValue = sizeSizeSfValue === tempSiteSizeSf;
    if (
      !isSameValue &&
      siteSizeAcres &&
      formatNumberWithDecimals(newSf, 3) !==
        formatNumberWithDecimals(siteSizeSf, 3)
    ) {
      onChange(sfFieldName, sizeSizeSfValue);
      setTempSiteSizeSf(sizeSizeSfValue);
    } else if (!(siteSizeAcres > 0)) {
      onChange(sfFieldName, '');
      if (!siteSizeSf) {
        setTempSiteSizeSf('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSizeAcres]);

  return (
    <>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.siteSize',
        )}
        required
        wrapperClassName={formControlClassName}
      >
        <div className={styles['twin-fields']}>
          <TextInput
            placeholder={translateText(
              'avantPlatform.attributes.property.label.siteSize',
            )}
            pattern={REGEX_THREE_DECIMALS}
            value={siteSizeAcres}
            onChange={value =>
              onChange(
                getFieldNameForProperty(
                  'siteSizeAcres',
                  property,
                  ModelsWithUnitsOfMeasurement.Property,
                ),
                value,
              )
            }
          />
          <span className={styles['twin-label']}>{acresMeasurement}</span>
          <TextInput
            placeholder={translateText(
              'avantPlatform.attributes.property.label.siteSize',
            )}
            pattern={REGEX_INTEGERS}
            value={tempSiteSizeSf}
            onChange={value => {
              setTempSiteSizeSf(value);
            }}
            onBlur={value =>
              onChange(
                getFieldNameForProperty(
                  'siteSizeSf',
                  property,
                  ModelsWithUnitsOfMeasurement.Property,
                ),
                value,
              )
            }
          />
          <span className={styles['twin-label']}>{sfMeasurement}</span>
          <span className={styles['twin-helper']}>
            {translateText('avantPlatform.attributes.property.label.siteSize', {
              acres: capitalize(
                UNITS_MEASUREMENT_ABBREVIATION[acresMeasurement],
              ),
              sf: UNITS_MEASUREMENT_ABBREVIATION[sfMeasurement].toUpperCase(),
            })}
          </span>
        </div>
      </FormControl>
      <FormControl
        label={translateText('avantPlatform.attributes.property.label.zoning')}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.zoning',
          )}
          value={property?.zoning}
          onChange={value => onChange('zoning', value)}
        />
      </FormControl>
    </>
  );
};

export default LandAndDevelopmentFields;
