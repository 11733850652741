import { gql } from '@apollo/client';

export const DYNAMIC_URLS = gql`
  query($search: SearchDynamicURLsInput!) {
    dynamicURLs(search: $search) {
      id
      createdAt
      updatedAt
      key
      name
      iconUrl
      order
      link
      hasMarketFilter
      hasSubmarketFilter
      hasMicromarketFilter
      hasPropertyTypeFilter
      hasBuildingClassFilter
      hasPropertySubtypeFilter
      hasPropertyStatusFilter
      hasIndustryFilter
      group
      category
    }
  }
`;
