import { IRadioItem } from 'components/Inputs/RadioInput';

export const getTaxAndOpexSuggestions = (
  currencySymbol: string,
): IRadioItem[] => [
  {
    label: `${currencySymbol}15`,
    value: 15,
  },
  {
    label: `${currencySymbol}20`,
    value: 20,
  },
  {
    label: `${currencySymbol}25`,
    value: 25,
  },
];
