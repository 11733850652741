import { CSSProperties } from 'react';

import { ApiClients } from 'constants/apiClients';
import { DocumentNode } from '@apollo/client';

export enum IconPosition {
  Left = 'left',
  Right = 'right',
}

type GenericSearchInputBaseProps = {
  graphqlQuery: DocumentNode;
  graphqlVariables?: any | ((searchTerm: string) => any);
  graphqlClient?: ApiClients;
  graphqlSkip?: boolean;
  graphqlUsersWithoutOkta?: boolean;
  onSearchTermChange?: (value: string) => void;
  mapper: (queryResultData: any) => any[];
  onItemSelect: (item: any) => void;
  clearAfterItemSelect?: boolean;
  initialValue?: string;
  placeholder?: string;
  clearable?: boolean;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onClear?: () => void;
  clientSideFilter?: (searchedText: string, items: any[]) => any[];
  minCharsForSearch?: number;
  createSearchedItem?: () => void;
  createSearchedItemSuccess?: boolean;
  className?: string;
  suggestionContainerClassName?: string;
  id?: string;
  style?: CSSProperties;
  icon?: string;
  iconPosition?: IconPosition;
};

type GenericSearchInputWithDefaultRender = {
  valueMapper: (item: any) => string | undefined;
  itemRenderer?: never;
};

type GenericSearchInputWithCustomRender = {
  itemRenderer?: (item: any, searchedText: string) => JSX.Element;
  valueMapper?: never;
};

export type GenericSearchInputProps = GenericSearchInputBaseProps &
  (GenericSearchInputWithDefaultRender | GenericSearchInputWithCustomRender);
