import mapboxgl from 'mapbox-gl';
import { IProperty } from 'interfaces/IProperty';
import {
  generateMarkers,
  getGeoJSON,
  getGeoJsonFeaturesCollection,
} from 'utils/maps/mapBox';
import {
  SELECTED_SUBMARKET_BOUNDARIES,
  SUBMARKETS_BOUNDARIES,
  SELECTED_SUBMARKET_LAYER,
  SUBMARKETS_BOUNDARIES_LAYER,
  SELECTED_SUBMARKET_BORDER,
  BOUNDARIES_BORDER_STYLE,
} from './constants';
import { removeTypenameKey } from 'utils/graphql/typename';
import { getBoundsFromGeometry } from 'utils/maps/mapBox';

interface Feature {
  type: string;
  submarketName: string;
  geometry: any;
}

export const loadPropertiesOnMap = async (
  map: mapboxgl.Map,
  properties: IProperty[],
  onClickPropertyPin?: (propertyId: string | null) => void,
) => {
  const geojson = getGeoJSON(properties, property => ({
    onClickPropertyPin,
    propertyId: property.id,
  }));
  const bounds = new mapboxgl.LngLatBounds();

  geojson.features.forEach(marker => {
    const pin = generateMarkers(marker, true);
    // @ts-ignore: Unreachable code error
    bounds.extend(marker.geometry.coordinates);
    // @ts-ignore: Unreachable code error
    pin.setLngLat(marker.geometry.coordinates).addTo(map);
  });

  map.fitBounds(bounds, { padding: 50, maxZoom: 13 });
};

const refreshMapZoom = (map: mapboxgl.Map, geometry: any, zoom: number) => {
  const bounds = getBoundsFromGeometry(geometry);
  map.fitBounds(bounds, {
    maxZoom: zoom,
  });
};

export const loadSubmarketBoundariesOnMap = async (
  map: mapboxgl.Map,
  boundaries: any,
  selectedSubmarketName: string,
  mapZoom: number,
  shouldShowOnlySelectedBoundaries?: boolean,
) => {
  let geometry = null;
  const features = boundaries
    ?.map((submarket: any) => {
      if (submarket?.geometry?.type && submarket?.geometry?.coordinates) {
        return {
          type: 'Feature',
          submarketName: submarket.name,
          geometry: removeTypenameKey(submarket.geometry),
        };
      }
    })
    .filter(Boolean);

  const selectedSubmarketBoundaries = features?.filter(
    (f: Feature) => f.submarketName === selectedSubmarketName,
  );
  const allOthersSubmarkets = features?.filter(
    (f: Feature) => f.submarketName !== selectedSubmarketName,
  );
  const hasSelectedBoundaries = !!selectedSubmarketBoundaries?.length;
  const hasOthersBoundaries = !!allOthersSubmarkets?.length;

  //all others submarkets boundaries
  if (hasOthersBoundaries) {
    geometry = allOthersSubmarkets?.[0]?.geometry;

    if (!shouldShowOnlySelectedBoundaries) {
      map.addSource(
        SUBMARKETS_BOUNDARIES,
        getGeoJsonFeaturesCollection(allOthersSubmarkets),
      );
      map.addLayer({
        id: SUBMARKETS_BOUNDARIES_LAYER,
        type: 'line',
        source: SUBMARKETS_BOUNDARIES,
        paint: {
          ...BOUNDARIES_BORDER_STYLE,
          'line-dasharray': [2, 3],
        },
      });
    }
  }

  //adding the selected submarket layer
  if (hasSelectedBoundaries) {
    geometry = selectedSubmarketBoundaries?.[0]?.geometry;
    map.addSource(
      SELECTED_SUBMARKET_BOUNDARIES,
      getGeoJsonFeaturesCollection(selectedSubmarketBoundaries),
    );
    map.addLayer({
      id: SELECTED_SUBMARKET_LAYER,
      type: 'fill',
      source: SELECTED_SUBMARKET_BOUNDARIES,
      paint: {
        'fill-color': '#FFF',
        'fill-opacity': 0.1,
      },
    });
    map.addLayer({
      id: SELECTED_SUBMARKET_BORDER,
      type: 'line',
      source: SELECTED_SUBMARKET_BOUNDARIES,
      paint: BOUNDARIES_BORDER_STYLE,
    });
  }

  if (geometry) {
    refreshMapZoom(map, geometry, mapZoom);
  }
};
