import React from 'react';

import AgencyLeasingHeader from 'components/AgencyAssignments/AgencyLeasingHeader';
import CommonAgencyLeasingControl from 'components/AgencyAssignments/AgencyLeasingHeader/CommonAgencyLeasingControl';
import { AgencyLeasingOverviewSection } from 'components/AgencyAssignments/types';
import { Container } from 'components/@codelitt/ay-design-library';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_HEADINGS_PATH } from 'constants/i18n';

import GridContainer from './GridContainer';
import SummaryContainer from './SummaryContainer';
import styles from './Overview.module.scss';
import { OverviewProps } from './types';

const Overview = ({
  agencyLeasing,
  askingRentData,
  section,
  toggleOptions,
  onEditButtonClick,
  onChangeToggleOptions,
}: OverviewProps) => {
  const isSummary = section === AgencyLeasingOverviewSection.Summary;
  const isGrid = section === AgencyLeasingOverviewSection.Grid;

  if (!agencyLeasing) {
    return null;
  }

  const propertyIds = agencyLeasing?.properties?.map(property => property.id!);

  return (
    <Container>
      <AgencyLeasingHeader
        breadcrumbLevels={[{ content: agencyLeasing.name }]}
        actionContent={
          <CommonAgencyLeasingControl
            showMoreTrendsButton
            showExcelDownloadButton
            agencyLeasing={agencyLeasing}
            toggleOptions={toggleOptions}
            onChangeToggleOptions={onChangeToggleOptions}
            onEditButtonClick={onEditButtonClick}
            propertyIds={propertyIds}
          />
        }
      />
      <div>
        <h2 className={styles.title}>
          {translateText(`${I18N_AVANT_PROPERTY_HEADINGS_PATH}.overview`)}
        </h2>
        {isSummary && (
          <SummaryContainer
            askingRentData={askingRentData}
            propertySet={agencyLeasing}
            toggleOptions={toggleOptions}
          />
        )}
        {isGrid && (
          <GridContainer
            toggleOptions={toggleOptions}
            propertySet={agencyLeasing}
          />
        )}
      </div>
    </Container>
  );
};

export default Overview;
