import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { SearchOperations } from 'constants/searchOperations';
import { translateText } from 'utils/i18n';

export enum CompanyFieldsNames {
  DOMAIN = 'domain',
  HEADQUARTER_PROPERTY = 'headquarterProperty',
  HEADQUARTERS = 'headquarters',
  INDUSTRY = 'industry',
  INVESTOR = 'investorType',
  NAME = 'name',
  OPERATING_NAME = 'operatingName',
  LEGAL_NAME = 'legalName',
  STOCK_CODE = 'stockCode',
  WEBSITE = 'website',
  LINKEDIN = 'linkedIn',
}
export interface ICompaniesFieldsTypes {
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  error?: boolean;
  onClear?: () => void;
}

const getCompanyTranslationFields = (pathKey: string) => {
  return translateText(pathKey);
};

export const COMPANY_FIELDS_INFO = {
  [CompanyFieldsNames.INVESTOR]: {
    label: getCompanyTranslationFields(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.investorType`,
    ),
  },
  [CompanyFieldsNames.INDUSTRY]: {
    label: getCompanyTranslationFields(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.companyIndustry`,
    ),
    placeholder: getCompanyTranslationFields(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.selectIndustry`,
    ),
  },
  [CompanyFieldsNames.WEBSITE]: {
    label: getCompanyTranslationFields(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.website`,
    ),
    placeholder: getCompanyTranslationFields(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.enterCompanyWebsite`,
    ),
  },
  [CompanyFieldsNames.LINKEDIN]: {
    label: getCompanyTranslationFields(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.linkedIn`,
    ),
    placeholder: getCompanyTranslationFields(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.enterCompanyLinkedIn`,
    ),
  },
  [CompanyFieldsNames.DOMAIN]: {
    label: getCompanyTranslationFields(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.emailDomain`,
    ),
    placeholder: getCompanyTranslationFields(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.atDomain`,
    ),
  },
  [CompanyFieldsNames.STOCK_CODE]: {
    label: getCompanyTranslationFields(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.stockTicker`,
    ),
    placeholder: getCompanyTranslationFields(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.enterStockTicker`,
    ),
  },
};

export const NOTIFICATION_MESSAGES = {
  DEFAULT: translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.companyCouldntCreated`,
  ),
  SUCCESS: (companyName: string) =>
    `${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.companyCreated`, {
      companyName: companyName,
    })}`,
  ERROR: (companyName: string) =>
    `${translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.givenCompanyCouldntCreated`,
      {
        companyName: companyName,
      },
    )}`,
};

export const NOTIFICATION_INIT = {
  isVisible: false,
  hasError: false,
  message: '',
};

export const COMPANY_FIELDS_INIT = {
  domain: undefined,
  headquarterProperty: undefined,
  headquarters: undefined,
  comments: undefined,
  industry: undefined,
  name: undefined,
  stockCode: undefined,
  website: undefined,
  linkedIn: undefined,
  investorType: undefined,
};

export const INDUSTRY_FIELD_VARIABLES = {
  search: {
    fields: {
      or: [
        {
          key: 'approved',
          op: SearchOperations.eq,
          value: 'true',
        },
      ],
    },
  },
};
