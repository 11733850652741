import { capitalize } from 'lodash';
import { IRadioItem } from 'components/Inputs/RadioInput';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { TranslateFunction } from 'utils/i18n';

export const getTermsSuggestions = (t: TranslateFunction): IRadioItem[] => [
  {
    label: `60 ${capitalize(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.month_plural`),
    )} (5 ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`)})`,
    value: 60,
  },
  {
    label: `84 ${capitalize(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.month_plural`),
    )} (7 ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`)})`,
    value: 84,
  },
  {
    label: `120 ${capitalize(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.month_plural`),
    )} (10 ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`)})`,
    value: 120,
  },
];
