import React from 'react';
import styles from './ActivityCardCategories.module.scss';
import { IdName } from '../../../interfaces/IdName';

interface Props {
  categories: IdName[];
}

const ActivityCardCategories: React.FC<Props> = props => (
  <div className={styles.container}>
    {props.categories.map((category, key) => (
      <div key={key} className={styles.chip}>
        {category.name}
      </div>
    ))}
  </div>
);

export default ActivityCardCategories;
