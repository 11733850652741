import React, { useState, useEffect } from 'react';
import styles from '../FormSection.module.scss';
import classNames from 'classnames';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import UpdateSummary from 'components/UpdateSummary';
import { IProperty } from 'interfaces/IProperty';
import FormFooter from 'components/FormFooter';
import General from './General';
import Details from './Details';
import Size from './Size';
import Bottom from './Bottom';
import { getPricingQuoteState, isValidForm } from './utils';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export interface Props {
  onSubmit: (pricingQuote: IPricingQuoteInput) => void;
  onCancel: () => void;
  submitLabel?: string;
  isSubmitting?: boolean;
  submitButtonLabel?: string;
  pricingQuote?: IPricingQuoteInput;
  sourceProperty?: IProperty;
}

const PricingQuoteForm: React.FC<Props> = props => {
  const [pricingQuote, setPricingQuote] = useState<IPricingQuoteInput>({
    property: props.sourceProperty,
    ...getPricingQuoteState(props.pricingQuote || {}),
  });
  const { t } = useTranslation();
  const [unitOfMeasurement, setUnitOfMeasurement] = useState<string>(
    getUnitOfMeasurementForProperty(
      'buildingSize',
      ModelsWithUnitsOfMeasurement.Property,
      pricingQuote.property?.propertyCountry?.code ||
        pricingQuote.property?.measurementSystem,
    ),
  );

  const updateSummaryValue = false;

  const handleChange = (key: string, value: any) => {
    setPricingQuote((prevValue: any) => ({
      ...prevValue,
      [key]: value || value === 0 ? value : null,
    }));
  };

  useEffect(() => {
    const newUnitOfMeasurement = getUnitOfMeasurementForProperty(
      'buildingSize',
      ModelsWithUnitsOfMeasurement.Property,
      pricingQuote.property?.propertyCountry?.code ||
        pricingQuote.property?.measurementSystem,
    );
    setUnitOfMeasurement(newUnitOfMeasurement);
  }, [pricingQuote.property]);

  return (
    <>
      <div className={classNames(styles.container)}>
        <General
          property={pricingQuote.property}
          data={pricingQuote}
          unitOfMeasurement={unitOfMeasurement}
          onChange={handleChange}
        />
      </div>
      <div className={classNames(styles.container)}>
        <Details
          property={pricingQuote.property}
          unitOfMeasurement={unitOfMeasurement}
          data={pricingQuote}
          onChange={handleChange}
        />
      </div>
      <div className={classNames(styles.container)}>
        <Size
          unitOfMeasurement={unitOfMeasurement}
          data={pricingQuote}
          onChange={handleChange}
        />
      </div>
      <div
        className={classNames(styles.container, {
          [styles['is-last']]: !updateSummaryValue,
        })}
      >
        <Bottom data={pricingQuote} onChange={handleChange} />
      </div>
      {updateSummaryValue && (
        <div className={classNames(styles.container, styles['is-last'])}>
          <UpdateSummary data={updateSummaryValue} />
        </div>
      )}
      <FormFooter
        onSubmit={() => {
          props.onSubmit(pricingQuote);
        }}
        onCancel={props.onCancel}
        submitButtonLabel={
          props.submitButtonLabel ||
          t(`${I18N_PLATFORM_COMMON_WORD_PATH}.submit`)
        }
        isCancelDisabled={props.isSubmitting}
        isSubmitDisabled={props.isSubmitting || !isValidForm(pricingQuote)}
      />
    </>
  );
};

export default PricingQuoteForm;
