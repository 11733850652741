import * as React from 'react';
import { IUser } from 'interfaces/IUser';
import AuthService from '../services/Auth';

export interface AuthContextInterface {
  user: IUser;
  setUser: (userAuth: IUser) => void;
}

export const authContext = React.createContext<AuthContextInterface>({
  user: AuthService.getLocalUser(),
  setUser: () => {
    //comment required to comply to rule https://eslint.org/docs/rules/no-empty-function
  },
});

const useAuthHandler = (initialState: IUser) => {
  const [user, setUser] = React.useState(initialState);

  return {
    user,
    setUser,
  };
};

const { Provider } = authContext;

type PropTypes = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<PropTypes> = (props: PropTypes) => {
  const { user, setUser } = useAuthHandler(AuthService.getLocalUser());

  return <Provider value={{ user, setUser }}>{props.children}</Provider>;
};

export function useUser() {
  const user = React.useContext(authContext);
  if (user === undefined) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }
  return user;
}

export function useUserAccessData() {
  const { user } = React.useContext(authContext);
  if (user === undefined) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }

  // @TODO - check if we need to filter multiple subscriptions
  const accessData = user?.subscriptions?.[0]?.accessData;

  // checking if the user has full access in any subscription
  const fullAccess =
    !!user?.subscriptions?.[0]?.isActive &&
    !!user?.subscriptions?.[0]?.fullAccess;
  // checking if the user has any active subscription
  const isActive = !!user?.subscriptions?.[0]?.isActive;

  return { accessData, fullAccess, isActive };
}

export function useUserAYContact() {
  const { user } = React.useContext(authContext);
  if (user === undefined) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }

  const AYContactUser = user?.clientCompany?.AYContactUser;

  return { AYContactUser };
}

export function useCheckClientCompanyIsAY() {
  const { user } = React.useContext(authContext);
  return user.clientCompany?.id === 1;
}

export default AuthProvider;
