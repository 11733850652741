import { PropertySetTypes } from 'constants/propertySetTypes';
import { IPropertySetLeasingMetadata } from 'interfaces/IPropertySetMetadata';
import { DocumentNode, useQuery } from '@apollo/client';

export const usePropertySetLeasingMetadata = (
  propertySetId: number,
  leasingMetadataQuery: DocumentNode,
): {
  propertySetLeasingMetadataResponse: IPropertySetLeasingMetadata;
  isLoading: boolean;
} => {
  const { data, loading } = useQuery(leasingMetadataQuery, {
    variables: {
      search: {
        filter: {
          type: PropertySetTypes.AGENCY_ASSIGNMENT,
          setIds: [propertySetId],
        },
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !propertySetId,
  });

  return {
    propertySetLeasingMetadataResponse: data?.propertySetsLeasingMetadata,
    isLoading: loading,
  };
};
