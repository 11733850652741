import dayjs from 'dayjs';
import { capitalize, isEmpty, isNil } from 'lodash';
import { IPricingQuote } from 'interfaces/IPricingQuote';
import { formatArea } from 'utils/formatters/area';
import { formatNumberWithCommas } from 'utils/formatters/number';
import { getCurrencySymbol } from 'utils/formatters/currency';
import {
  getUnitOfMeasurementForLease,
  getFieldValueForPriceQuote,
} from 'utils/unitsOfMeasurement';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { upperFirstLetter } from 'utils/formatters/string';
import dateFormats from 'constants/dateFormats';

export enum PricingQuoteCardField {
  TI,
  FreeRentMonths,
  TermInMonths,
  LeaseType,
  Size,
  Floors,
  Source,
  TaxAndOpex,
  Escalation,
  QuoteDate,
}

const MANY_FLOORS = 2;
const SINGLE_FLOOR = 1;

export const hasSingleFloor = (pricingQuote?: IPricingQuote) =>
  pricingQuote?.floors && /^\d+$/.test(pricingQuote?.floors);

export const getFormattedField = (
  pricingQuote: IPricingQuote,
  field: PricingQuoteCardField,
): string | null => {
  const { property } = pricingQuote;
  const currencySymbol = getCurrencySymbol(
    property?.propertyCountry?.currencyCode || property?.currencyCode,
  );

  switch (field) {
    default:
      return null;
    case PricingQuoteCardField.TI: {
      if (!pricingQuote.tiAllowance) {
        return null;
      }

      const ti = formatNumberWithCommas(pricingQuote.tiAllowance, 2);
      return `${currencySymbol}${ti} ${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.tis`,
      )}`;
    }
    case PricingQuoteCardField.TaxAndOpex:
      const currencyCode = getCurrencySymbol(
        pricingQuote?.property?.currencyCode!,
      );

      return Number(pricingQuote.opExAndTaxes) > 0
        ? `${currencyCode}${pricingQuote.opExAndTaxes?.toFixed(
            2,
          )} ${translateText(
            `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.taxAndOpex`,
          )}`
        : '';
    case PricingQuoteCardField.FreeRentMonths: {
      return !isNil(pricingQuote.freeRentMonths)
        ? `${pricingQuote.freeRentMonths} ` +
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.month`, {
              count: pricingQuote.freeRentMonths,
            }) +
            ' ' +
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.free`)
        : null;
    }
    case PricingQuoteCardField.TermInMonths: {
      return pricingQuote.term
        ? `${+(pricingQuote.term / 12).toFixed(1)} ${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`,
          )}`
        : null;
    }
    case PricingQuoteCardField.LeaseType: {
      if (!pricingQuote.leaseType) {
        return null;
      }
      return capitalize(pricingQuote.leaseType);
    }
    case PricingQuoteCardField.Source: {
      if (!pricingQuote.source) {
        return null;
      }
      return capitalize(pricingQuote.source);
    }
    case PricingQuoteCardField.Size: {
      if (!pricingQuote.size) {
        return null;
      }
      const unitOfMeasurement = getUnitOfMeasurementForLease(
        'size',
        property?.propertyCountry?.code || property?.measurementSystem,
      );
      const priceQuoteSize = getFieldValueForPriceQuote('size', pricingQuote);
      return priceQuoteSize
        ? formatArea(priceQuoteSize, unitOfMeasurement)
        : null;
    }
    case PricingQuoteCardField.Floors: {
      return isEmpty(pricingQuote.floors)
        ? null
        : `${upperFirstLetter(
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.floor`, {
              count: hasSingleFloor(pricingQuote) ? SINGLE_FLOOR : MANY_FLOORS,
            }),
          )} ${pricingQuote.floors}`;
    }
    case PricingQuoteCardField.Escalation: {
      return pricingQuote?.escalationFormatted ?? null;
    }
    case PricingQuoteCardField.QuoteDate: {
      return !!pricingQuote.date
        ? `${dayjs(pricingQuote.date)
            .utc()
            .format(dateFormats.SHORT_DATE)} - ${translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.quoteDate`,
          )} `
        : null;
    }
  }
};
