export const ageTypes = {
  inDevelopment: 'In Development',
  '2020s': '2020s',
  '2010s': '2010s',
  '2000s': '2000s',
  '1990s': '1990s',
  '1980s': '1980s',
  '1970s': '1970s',
  '1960s': '1960s',
  '1950s': '1950s',
  'Pre-1950': 'Pre-1950',
};
