import React from 'react';
import dayjs from 'dayjs';

import { ILeaseOption } from 'interfaces/ILeaseOption';
import { IdName } from 'interfaces/IdName';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import dateFormats from 'constants/dateFormats';
import { LeaseOptionType } from 'constants/leaseOptionTypes';
import Button from 'components/Button/new';
import { monthsToYears } from 'utils/date';
import { formatStringWithComma } from 'utils/formatters/string';
import { formatNumberWithCommas } from 'utils/formatters/number';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';

import localStyles from '../LeaseOptions.module.scss';

interface Props {
  leaseOption: ILeaseOption;
  leaseOptionTypes: IdName[];
  onEdit: () => void;
  onDelete: () => void;
}

export const LeaseOptionItem = ({
  leaseOption,
  leaseOptionTypes,
  onEdit,
  onDelete,
}: Props) => {
  const displayNameBasedOnType = (typeId: number | null) => {
    const optionDate = leaseOption?.optionDate
      ? dayjs(leaseOption?.optionDate).format(dateFormats.DATE_TITLE)
      : '';

    const optionName = leaseOptionTypes?.find(o => o.id === typeId)?.name;

    if (!typeId) return null;
    switch (typeId) {
      case LeaseOptionType.RENEWAL: {
        const renewalTerm = leaseOption?.renewalTermInMonths
          ? `${monthsToYears(leaseOption?.renewalTermInMonths)} ${translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`,
            ).toLowerCase()}`
          : '';

        return `${optionName}: ${formatStringWithComma([
          renewalTerm,
          optionDate,
        ])}`;
      }
      case LeaseOptionType.EXPANSION: {
        const size = leaseOption?.expansionSf
          ? `${formatNumberWithCommas(leaseOption.expansionSf)} ${
              UnitOfMeasurement.sf
            }`
          : '';
        return `${optionName}: ${formatStringWithComma([size, optionDate])}`;
      }
      default: {
        return `${optionName}: ${optionDate}`;
      }
    }
  };
  return (
    <div className={localStyles['option-container']}>
      <div className={localStyles['option-item']}>
        {displayNameBasedOnType(leaseOption.optionTypeId)}
        <Button
          wrapperClassName={localStyles['option-button']}
          type="ghost"
          icon="edit"
          onClick={onEdit}
        />
      </div>
      <Button
        wrapperClassName={localStyles['option-button']}
        type="delete"
        icon="delete"
        onClick={onDelete}
      />
    </div>
  );
};
