import React from 'react';
import classnames from 'classnames';
import companyIcon from 'assets/images/icons/company.svg';
import { ITIM } from 'interfaces/ITIM';
import { DOT } from 'constants/placeholders';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { formatDate } from 'utils/formatters/date';
import dateFormats from 'constants/dateFormats';

import styles from './TimRecordInputSuggestion.module.scss';

interface Props {
  suggestion: ITIM;
  index?: number;
  activeIndex?: number | null;
  onSelect: (suggestion: ITIM) => void;
  onActiveIndexChange?: (value: number | null) => void;
  currentLeaseId?: number | null;
}

const TimRecordInputSuggestion: React.FC<Props> = ({
  suggestion,
  index,
  activeIndex,
  onActiveIndexChange,
  onSelect,
}) => {
  const hasSize = suggestion?.targetSizeMin && suggestion?.targetSizeMax;
  const size = hasSize
    ? `${suggestion.targetSizeMin} - ${suggestion?.targetSizeMax} sf`
    : '';
  const subMarketNames = suggestion?.targetSubmarkets
    ?.map(s => s.name)
    .join(', ');
  const primaryText = [size, subMarketNames].filter(Boolean).join(` ${DOT} `);

  const activeSinceDate = suggestion?.activeSinceDate
    ? `${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.activeSince`,
      )} ${formatDate(suggestion.activeSinceDate, dateFormats.MONTH_YEAR)}`
    : '';
  const timStatus = suggestion?.timStatusType?.name ?? '';
  const targetCommencementDate = suggestion?.targetCommencementDate
    ? `${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.targetCommencement`,
      )} ${formatDate(
        suggestion.targetCommencementDate,
        dateFormats.MONTH_YEAR,
      )}`
    : '';
  const secondaryText = [activeSinceDate, timStatus, targetCommencementDate]
    .filter(Boolean)
    .join(` ${DOT} `);

  const isItemActive =
    index !== undefined && activeIndex !== undefined && index === activeIndex;

  return (
    <li
      onMouseEnter={() => onActiveIndexChange?.(null)}
      key={index}
      className={classnames(styles['search-suggestion-item'], {
        [styles['search-suggestion-item-active']]: isItemActive,
      })}
      onMouseDown={() => onSelect(suggestion)}
    >
      <div className={styles['suggestion-value']}>
        <div className={styles['text-container']}>
          <p className={styles['suggestion-text-primary']}>
            <img
              src={companyIcon}
              alt={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.logo`)}
              className={styles['img-profile']}
            />

            <span className={styles['highlight']}>{primaryText}</span>
          </p>
          <p className={classnames(styles['suggestion-text-secondary'], {})}>
            {secondaryText}
          </p>
        </div>
      </div>
    </li>
  );
};

export default TimRecordInputSuggestion;
