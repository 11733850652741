import { gql } from '@apollo/client';

export const CREATE_PROPERTY_PRICE_QUOTE_MUTATION = gql`
  mutation createPropertyPriceQuote(
    $propertyPriceQuote: PropertyPriceQuoteInput!
  ) {
    createPropertyPriceQuote(propertyPriceQuote: $propertyPriceQuote) {
      ... on PropertyPriceQuote {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const UPDATE_PROPERTY_PRICE_QUOTE_MUTATION = gql`
  mutation updatePropertyPriceQuote(
    $propertyPriceQuote: PropertyPriceQuoteInput!
  ) {
    updatePropertyPriceQuote(propertyPriceQuote: $propertyPriceQuote) {
      ... on PropertyPriceQuote {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const DELETE_PROPERTY_PRICE_QUOTE_MUTATION = gql`
  mutation deletePropertyPriceQuote($id: Int!) {
    deletePropertyPriceQuote(id: $id)
  }
`;
