import React from 'react';
import styles from './RangeSelector.module.scss';
import FormControl from 'components/FormControl';
import { REGEX_THOUSAND_SEP_WITH_DECIMALS } from 'constants/regex';
import TextInput from 'components/Inputs/TextInput';
import classnames from 'classnames';
import { IRangeValue } from 'interfaces/IRangeValue';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { fieldHasValue } from 'utils/objects';
import {
  currencyInputFormatter,
  parseCurrencyToNumber,
} from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  formatter?: ((value: string, isFocused?: boolean) => string) | null;
  fromLabel?: string;
  isFromLabelDisabled?: boolean;
  id?: string;
  inputClassName?: string;
  isDisabled?: boolean;
  onChange: (field: 'from' | 'to', value: any) => void;
  pattern?: RegExp;
  placeholder?: string;
  selectedValue: IRangeValue;
  shouldValidateRange?: boolean;
  toLabel?: string;
  unitType?: UnitOfMeasurement;
  useFormControlWrapper?: boolean;
}

const RangeSelector: React.FC<Props> = ({
  formatter = currencyInputFormatter,
  fromLabel,
  isFromLabelDisabled,
  id,
  inputClassName,
  isDisabled,
  onChange,
  pattern,
  placeholder,
  selectedValue,
  shouldValidateRange,
  toLabel,
  unitType,
  useFormControlWrapper = true,
}) => {
  const fromInputId = id ? `${id}-from` : 'from';
  const toInputId = id ? `${id}-to` : 'to';

  const isRangeValid = () => {
    const hasValueFrom = fieldHasValue(selectedValue.from);
    const hasValueTo = fieldHasValue(selectedValue.to);

    return (
      !hasValueTo ||
      !hasValueFrom ||
      (hasValueTo &&
        hasValueFrom &&
        parseCurrencyToNumber(`${selectedValue.to}`) >
          parseCurrencyToNumber(`${selectedValue.from}`))
    );
  };

  const updateValue = (value: string, field: 'from' | 'to') =>
    onChange(
      field,
      fieldHasValue(value)
        ? formatter === null
          ? value
          : formatter(value)
        : undefined,
    );

  const TRANSLATED_ENTER_VALUE_LABEL = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.enterValue`,
  );
  const TRANSLATED_TO_LABEL = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.to`,
  ).toLowerCase();

  const content = (
    <>
      <label
        className={classnames(styles.label, {
          [styles.disabled]: isDisabled,
          [styles['from-label-disabled']]: isFromLabelDisabled,
        })}
        htmlFor={fromInputId}
      >
        {isFromLabelDisabled
          ? ''
          : fromLabel ||
            `${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.from`)}:`}
      </label>
      <TextInput
        id={fromInputId}
        isDisabled={isDisabled}
        onChange={value => updateValue(value, 'from')}
        pattern={pattern || REGEX_THOUSAND_SEP_WITH_DECIMALS}
        formatter={formatter === null ? undefined : currencyInputFormatter}
        placeholder={placeholder || TRANSLATED_ENTER_VALUE_LABEL}
        textCentered
        value={selectedValue.from}
        inputClassName={classnames(styles['input'], inputClassName)}
        isInvalid={shouldValidateRange && !isRangeValid()}
      />
      <label
        className={classnames(styles.label, {
          [styles.disabled]: isDisabled,
        })}
        htmlFor={toInputId}
      >
        {toLabel || `${TRANSLATED_TO_LABEL}:`}
      </label>
      <TextInput
        id={toInputId}
        isDisabled={isDisabled}
        onChange={value => updateValue(value, 'to')}
        pattern={pattern || REGEX_THOUSAND_SEP_WITH_DECIMALS}
        formatter={formatter === null ? undefined : currencyInputFormatter}
        placeholder={placeholder || TRANSLATED_ENTER_VALUE_LABEL}
        textCentered
        value={selectedValue.to}
        inputClassName={classnames(styles['input'], inputClassName)}
        isInvalid={shouldValidateRange && !isRangeValid()}
      />
      {unitType && (
        <span
          className={classnames(styles.units, {
            [styles.disabled]: isDisabled,
          })}
        >
          {unitType}
        </span>
      )}
    </>
  );

  return useFormControlWrapper ? (
    <FormControl disabled={isDisabled}>{content}</FormControl>
  ) : (
    content
  );
};

export default RangeSelector;
