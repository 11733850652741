import React from 'react';
import { HelmetContext } from 'contexts/HelmetContext';

export default () => {
  const contextValue = React.useContext(HelmetContext);
  if (!contextValue) {
    throw Error(
      'Helmet Context not found; wrap your App with <HelmetContextProvider>',
    );
  }
  return contextValue;
};
