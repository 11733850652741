import { gql } from '@apollo/client';

export const SUMMARY_REPORT_FRAGMENT = gql`
  fragment summaryReport on File {
    summaryReport {
      created
      total
      invalid
      duplicate
      updated
      other
      notFound
      propertyNotFound
      multipleCompany
      countSalesPropertyOwnerUpdated
      numAbstractedRows
      companyCheck {
        processed
        validated
        found
        notFound
      }
    }
  }
`;

export const FILE_DATA_FRAGMENT = gql`
  fragment fileData on File {
    id
    filename
    type
    createdAt
    processedAt
    updatedAt
    error
    availabilitiesData
    resultData {
      rowNumber
      message
      errors
      id
    }
    ...summaryReport
  }
  ${SUMMARY_REPORT_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment userData on File {
    user {
      id
      email
      firstName
      lastName
    }
  }
`;
