import { ExpirationBarStyle } from 'components/Graphs/VictoryLeaseExpiration/interfaces';
import { getExpirationChartStyle } from 'components/Graphs/VictoryLeaseExpiration/utils';
import { colors } from 'constants/colors';

export const EXPIRING_CHART_HEIGHT = 140;
export const EXPIRATION_CHART_STYLE = {
  parent: {
    background: colors.primaryColor900,
    borderRadius: 10,
    height: '22.25rem',
  },
};

export const getExpirationStyle = (
  isQuarterly?: boolean,
  unitOfMeasurement?: string,
): ExpirationBarStyle => {
  const expirirationStyle = getExpirationChartStyle(
    isQuarterly,
    unitOfMeasurement,
  );
  return {
    ...expirirationStyle,
    chart: {
      ...expirirationStyle.chart,
      height: EXPIRING_CHART_HEIGHT,
    },
    ...EXPIRATION_CHART_STYLE,
  };
};
