import classNames from 'classnames';
import Loader from 'components/HistoricalPerformance/Loader';
import Icon from 'components/Icon';
import { IMenuDropdownItem, IMenuLink } from 'components/SubNavBar/utils';
import { colors } from 'constants/colors';
import React, { useEffect, useRef, useState } from 'react';
import styles from './MenuDropdown.module.scss';
import classnames from 'classnames';

interface Props {
  label: string;
  listItems: IMenuDropdownItem[];
  gridClassName?: string;
  id: string;
  isLoading?: boolean;
}

const MenuDropdown: React.FC<Props> = ({
  id,
  label,
  listItems,
  gridClassName,
  isLoading,
}) => {
  const [showTable, setShowTable] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        showTable &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowTable(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef, buttonRef, showTable]);

  const handleClickLink = (link: IMenuLink) => {
    link.onClick();
    setShowTable(false);
  };

  return (
    <>
      <button
        className={classNames(
          styles['button-label'],
          showTable && styles['opened-table'],
        )}
        onClick={() => setShowTable(!showTable)}
        ref={buttonRef}
      >
        {label}
        <Icon
          name="arrowDown"
          width={0.6625}
          height={0.6875}
          className={styles['arrow']}
          color={colors.primaryColor500}
        />
      </button>
      {showTable && (
        <div
          className={classNames(styles['menu-div'], gridClassName)}
          ref={divRef}
          id={id}
        >
          {isLoading ? (
            <Loader />
          ) : (
            listItems.map(menu => (
              <div className={menu.classname} key={menu.title}>
                <label className={styles['link-title']}>{menu.title}</label>
                {menu.links.map(link => (
                  <button
                    key={link.label}
                    onClick={() => handleClickLink(link)}
                    className={classnames({
                      [styles['link-item']]: !link.disabled,
                      [styles['link-item-no-hover']]: link.disabled,
                    })}
                    disabled={link.disabled}
                  >
                    {link.label}
                  </button>
                ))}
                {menu.lineSeparator && <div className={styles['separator']} />}
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default MenuDropdown;
