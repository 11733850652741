import { SearchOperations } from '../../../../constants/searchOperations';
import { SearchOptionsType } from '../../../../interfaces/ISearchOptionsType';

const BUILDING_DATE_OPTIONS: SearchOptionsType[] = [
  {
    id: 1,
    name: 'Past 3 Years',
    field: 'builtYear',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: new Date().getFullYear() - 3,
        end: new Date().getFullYear(),
      },
    },
  },
  {
    id: 2,
    name: '2010+',
    field: 'builtYear',
    filter: {
      operator: SearchOperations.gte,
      value: 2010,
    },
  },
  {
    id: 3,
    name: '2000+',
    field: 'builtYear',
    filter: {
      operator: SearchOperations.gte,
      value: 2000,
    },
  },
  {
    id: 4,
    name: 'Pre-2000',
    field: 'builtYear',
    filter: {
      operator: SearchOperations.lte,
      value: 2000,
    },
  },
];

export default BUILDING_DATE_OPTIONS;
