import React, { createContext, ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { ICurrency } from 'interfaces/ICurrency';
import { GET_CURRENCIES } from 'graphql/currency/queries';

interface CurrencyContextType {
  currencies: ICurrency[];
}

const CurrencyContext = createContext<CurrencyContextType | undefined>(
  undefined,
);

interface PropTypes {
  children: ReactNode;
}

export const CurrencyProvider: React.FC<PropTypes> = ({ children }) => {
  const { data } = useQuery<{ currencies: ICurrency[] }>(GET_CURRENCIES, {
    onCompleted: data => {
      updateGlobalCurrencies(data.currencies ?? []);
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <CurrencyContext.Provider value={{ currencies: data?.currencies || [] }}>
      {children}
    </CurrencyContext.Provider>
  );
};
export let globalCurrencies: ICurrency[] = [];

export const updateGlobalCurrencies = (currencies: ICurrency[]) => {
  globalCurrencies = currencies;
};
