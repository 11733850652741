import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { colors } from '../../../../constants/colors';
import {
  GRAPH_DIMENSIONS,
  Positions,
  TOOLTIP_LINE_STROKE,
  XCoordinates,
} from '../graphConstants';
import {
  getCenterLineId,
  getLinePositionId,
  getMarketTooltipLineId,
} from '../nodes';
import { BubblesType } from '../types';

type Props = {
  bubbles: BubblesType;
};

const TooltipLine: React.FC<Props> = props => {
  const lineGroupRef = useRef(null);

  const centerY = GRAPH_DIMENSIONS.height / 2;
  const centerX = GRAPH_DIMENSIONS.width / 2;
  const greenLineHeight = 240;

  const { bubbles } = props;

  const writeMktLine = () => {
    if (!bubbles.marketAvgBubble) return;

    const node = select(lineGroupRef.current);

    if (bubbles.marketAvgBubble.position === Positions.MIDDLE) {
      node
        .append('line')
        .attr('id', `${getMarketTooltipLineId()}`)
        .attr('x1', XCoordinates[Positions.MIDDLE])
        .attr('y1', centerY)
        .attr('x2', XCoordinates[Positions.MIDDLE])
        .attr('y2', centerY + greenLineHeight)
        .attr('stroke', colors.ayGrassGreenColor)
        .attr('stroke-width', TOOLTIP_LINE_STROKE)
        .attr('display', 'none');
    } else if (bubbles.marketAvgBubble.position === Positions.RIGHT) {
      node
        .append('polyline')
        .attr('id', `${getMarketTooltipLineId()}`)
        .style('stroke', colors.ayGrassGreenColor)
        .style('fill', 'none')
        .attr(
          'points',
          `${XCoordinates[Positions.RIGHT]}, ${centerY},
          ${XCoordinates[Positions.RIGHT]}, ${centerY + 75},
          ${centerX - 1}, ${centerY + 75},
          ${centerX - 1}, ${centerY + 150}`,
        )
        .attr('stroke-width', TOOLTIP_LINE_STROKE)
        .attr('display', 'none');
    }
  };

  const writePropertyLine = () => {
    const { propertyBubble, propertyRenovatedYearBubble, isSameYear } = bubbles;

    const node = select(lineGroupRef.current);

    const allPositions = [
      propertyBubble?.position,
      propertyRenovatedYearBubble?.position,
    ];

    const hasValueLeft = allPositions.includes(Positions.LEFT);
    const hasValueRight = allPositions.includes(Positions.RIGHT);

    //Center top line
    if (propertyBubble || propertyRenovatedYearBubble) {
      node
        .append('line')
        .attr('id', getCenterLineId())
        .attr('x1', XCoordinates[Positions.MIDDLE])
        .attr('y1', 0)
        .attr('x2', XCoordinates[Positions.MIDDLE])
        .attr('y2', 65)
        .attr('stroke', colors.primaryColor200)
        .attr('stroke-width', TOOLTIP_LINE_STROKE)
        .attr('display', 'none');
    }

    // Left top line
    if (hasValueLeft) {
      node
        .append('polyline')
        .attr('id', `${getLinePositionId(Positions.LEFT)}`)
        .style('stroke', colors.primaryColor200)
        .style('fill', 'none')
        .attr(
          'points',
          `${XCoordinates[Positions.LEFT]}, ${centerY},
          ${XCoordinates[Positions.LEFT]}, 65
          ${centerX - 1}, 65`,
        )
        .attr('stroke-width', TOOLTIP_LINE_STROKE)
        .attr('display', 'none');
    }

    // Renovated Year line
    if (propertyRenovatedYearBubble) {
      node
        .append('polyline')
        .attr(
          'id',
          `${getLinePositionId(propertyRenovatedYearBubble.position)}`,
        )
        .style('stroke', colors.primaryColor200)
        .style('fill', 'none')
        .attr(
          'points',
          `${centerX - 1}, 65,
          ${XCoordinates[propertyRenovatedYearBubble.position]}, 65
          ${XCoordinates[propertyRenovatedYearBubble.position]}, ${centerY}`,
        )
        .attr('stroke-width', TOOLTIP_LINE_STROKE)
        .attr('display', 'none');
    }

    // Right top line
    if (hasValueRight) {
      node
        .append('polyline')
        .attr('id', `${getLinePositionId(Positions.RIGHT)}`)
        .style('stroke', colors.primaryColor200)
        .style('fill', 'none')
        .attr(
          'points',
          `${centerX - 1}, 65,
          ${XCoordinates[Positions.RIGHT]}, 65
          ${XCoordinates[Positions.RIGHT]}, ${centerY}`,
        )
        .attr('stroke-width', TOOLTIP_LINE_STROKE)
        .attr('display', 'none');
    }

    // Close right line
    if (isSameYear) {
      node
        .append('polyline')
        .attr('id', `${getLinePositionId(Positions.CLOSE_RIGHT)}`)
        .style('stroke', colors.primaryColor200)
        .style('fill', 'none')
        .attr(
          'points',
          `${centerX - 1}, 65,
          ${XCoordinates[Positions.CLOSE_RIGHT]}, 65
          ${XCoordinates[Positions.CLOSE_RIGHT]}, ${centerY}`,
        )
        .attr('stroke-width', TOOLTIP_LINE_STROKE)
        .attr('display', 'none');
    }
  };

  useEffect(() => {
    writeMktLine();
    writePropertyLine();

    // eslint-disable-next-line
  }, []);
  return <g ref={lineGroupRef} />;
};

export default TooltipLine;
