export const AVANT_CITIES_ACCESS_TOKEN = '@ay-pia-web-ACToken';

export enum AvantCitiesEndpoints {
  GetApplicationMarketData = 'GetApplicationMarketData',
}

export type RegionData = {
  marketData?: {
    regionGeoJsonUrl?: string;
    censusTractsGeoJsonUrl?: string;
  };
};

export type MapColor = {
  value: number;
  color: string;
  description: string;
};

export type MapLegend = {
  propKey: string;
  display: string;
  layerGroupName: string;
  colors: MapColor[];
};
