import React from 'react';
import { Col } from 'components/@codelitt/ay-design-library';
import DonutGraph from 'components/Graphs/DonutGraph';
import SpinnerLoader from 'components/SpinnerLoader';
import LoadingMessage from 'components/LoadingMessage';
import { colors } from 'constants/colors';
import GraphContainer from 'components/Graphs/GraphContainer';
import styles from './SmallDonutGraph.module.scss';
import { DONUT_COLOR_TOOLTIP } from 'components/Graphs/DonutGraph/Elements/Tooltip';
import { DONUT_SIZE } from 'components/Graphs/DonutGraph/graphConstants';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import MissingGraphData from 'components/MissingGraphData';
import { sum } from 'd3';
interface Props {
  defaultData?: IDonutGraphData;
  donutId: number;
  graphData?: IDonutGraphData[];
  isLoading?: boolean;
  onDonutClick?: (graphData?: IDonutGraphData) => void;
  shouldSortByTitle?: boolean;
  title: string;
}

const DONUT_WIDTH = 208;

const SmallDonutGraph: React.FC<Props> = ({
  defaultData,
  donutId,
  graphData,
  isLoading,
  onDonutClick,
  shouldSortByTitle,
  title,
}) => {
  const renderGraph = () => {
    if (isLoading) {
      return (
        <div className={styles['loading-container']}>
          <SpinnerLoader />
        </div>
      );
    } else if (
      graphData?.length &&
      sum(graphData, slice => slice.totalSize) > 0
    ) {
      return (
        <DonutGraph
          graphData={graphData}
          defaultData={defaultData}
          donutId={donutId}
          firstSliceColor={colors.primaryColor500}
          secondSliceColor={colors.primaryColor500}
          secondaryDonutColor={colors.supportive100}
          customSize={DONUT_WIDTH}
          showTags={false}
          size={DONUT_SIZE.SMALL}
          tooltipClassName={styles.tooltip}
          tooltipPrimaryColor={DONUT_COLOR_TOOLTIP.white}
          shouldSortByTitle={shouldSortByTitle}
          onDonutClick={onDonutClick}
        />
      );
    } else {
      return <MissingGraphData />;
    }
  };

  const renderDonut = () => (
    <Col lg={3} md={3} sm={3} xs={3}>
      <GraphContainer>
        <h3 className={styles['donut-title']}>{title}</h3>
        <div className={styles['donut-inner-container']}>{renderGraph()}</div>
      </GraphContainer>
    </Col>
  );

  return <>{isLoading ? <LoadingMessage /> : renderDonut()}</>;
};

export default SmallDonutGraph;
