import React from 'react';
import { escapeRegExpFn, findAll } from './searchStrings';
import styles from './TextHighlighter.module.scss';
import classnames from 'classnames';

type Props = {
  searchString: string;
  textToHighlight: string;
  useDefaultHighlighted?: boolean;
  useBold?: boolean;
};

const TextHighlighter = (props: Props) => {
  const {
    searchString,
    textToHighlight,
    useDefaultHighlighted,
    useBold,
  } = props;

  const searchWords = (searchString?.split(/\s/) || [])
    .filter(Boolean)
    ?.map(escapeRegExpFn);

  const hasSearchWords = searchWords?.length > 0;
  const noSearchHighlighted = useDefaultHighlighted && !hasSearchWords;

  const chunks = findAll({ textToHighlight, searchWords });

  return (
    <span
      className={classnames({
        [styles['highlight']]: noSearchHighlighted,
        [styles['bold']]: noSearchHighlighted && useBold,
      })}
    >
      {chunks.map((chunk, index) => {
        const text = textToHighlight.substr(
          chunk.start,
          chunk.end - chunk.start,
        );

        return chunk.highlight ? (
          <span
            key={index}
            className={classnames(styles.highlight, {
              [styles['bold']]: useBold,
            })}
          >
            {text}
          </span>
        ) : (
          <span
            key={index}
            className={classnames({
              [styles['default-color']]: hasSearchWords && useBold,
            })}
          >
            {text}
          </span>
        );
      })}
    </span>
  );
};

export default TextHighlighter;
