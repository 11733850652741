import { gql } from '@apollo/client';
import { USER_FRAGMENT, FILE_DATA_FRAGMENT } from 'graphql/files/fragments';

export const GET_FILE_STATUS = gql`
  query fileStatus($fileId: Int!) {
    file(id: $fileId) {
      ...fileData
    }
  }
  ${FILE_DATA_FRAGMENT}
`;

export const GET_FILE = gql`
  query getFile($id: Int!) {
    file(id: $id) {
      ...userData
      ...fileData
    }
  }
  ${USER_FRAGMENT}
  ${FILE_DATA_FRAGMENT}
`;

export const GET_FILES = gql`
  query getFiles($search: FileSearch!) {
    files(search: $search) {
      total
      totalPages
      pageSize
      currentPage
      results {
        ...userData
        ...fileData
      }
    }
  }
  ${USER_FRAGMENT}
  ${FILE_DATA_FRAGMENT}
`;
