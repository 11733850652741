import React from 'react';
import styles from './DropdownCheckboxInput.module.scss';
import { IdName } from '../../../interfaces/IdName';
import classnames from 'classnames';

type PropTypes = {
  data: IdName[];
  selectedSubItems?: IdName[];
  onClick: (subItem: IdName) => void;
};

const DropdownCheckboxInputSubItems: React.FC<PropTypes> = props => {
  const { data, selectedSubItems, onClick } = props;

  return (
    <div className={styles['sub-items-container']}>
      {data.map(subItem => {
        const isSelected = selectedSubItems!.some(el => el.id === subItem.id);
        return (
          <div
            key={subItem.id}
            className={classnames(styles['sub-item-checkbox-group'], {
              [styles['sub-item-selected']]: isSelected,
            })}
            onClick={() => onClick(subItem)}
          >
            <div
              className={classnames(styles['sub-item-checkbox'], {
                [styles['sub-item-checked']]: isSelected,
              })}
            />
            <div className={styles['sub-item-label']}>{subItem.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DropdownCheckboxInputSubItems;
