import { ICompany } from 'interfaces/ICompany';
import { ILease } from 'interfaces/ILease';

export const getTenantBrokerCompany = (lease: ILease): ICompany | undefined => {
  const tenantBroker = lease?.tenantBrokers?.[0] || lease?.tenantBroker;
  return tenantBroker?.company;
};

export const getLandlordBrokerCompany = (
  lease: ILease,
): ICompany | undefined => {
  const landLordBroker = lease?.landlordBrokers?.[0] || lease?.landlordBroker;
  return landLordBroker?.company;
};
