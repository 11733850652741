import React, { useEffect, useMemo } from 'react';
import { getDataCenterFields } from 'components/CreateComps/FormSection/PropertyForm/BuildingFields/dataCenterMapping';
import FormField from 'components/FormControl/FormField';
import { ExclusiveFieldProps } from './ExclusiveFields';
import { FormFieldConfig } from '../../../../FormControl/FormField/types';

const DataCenterFields: React.FC<ExclusiveFieldProps> = ({
  onChange,
  property,
  property: { dataCenter },
  classNames: { formControlClassName, inputHelperClassName },
}) => {
  const totalPower = useMemo(
    () =>
      (dataCenter?.plannedPower || 0) +
      (dataCenter?.commissionedPower || 0) +
      (dataCenter?.underConstPower || 0),
    [
      dataCenter?.commissionedPower,
      dataCenter?.plannedPower,
      dataCenter?.underConstPower,
    ],
  );

  useEffect(() => {
    if (!totalPower) return;
    if (dataCenter?.totalPower === totalPower) return;
    onChange('totalPower', totalPower);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPower]);

  const onChangeHandler = (
    fieldConfig: FormFieldConfig,
    field: string,
    fieldValue: unknown,
  ) => {
    if (fieldConfig.field === 'powerUseEfficiency') {
      onChange(field, fieldValue ? Number(fieldValue) : null);
    } else {
      onChange(field, fieldValue);
    }
  };

  return (
    <>
      {getDataCenterFields(property).map(fieldConfig => (
        <FormField
          key={fieldConfig.field || fieldConfig.labelI18nKey}
          onChange={(field, value) =>
            onChangeHandler(fieldConfig, field, value)
          }
          dataObject={dataCenter || {}}
          fieldConfig={fieldConfig}
          formControlClassName={formControlClassName}
          inputHelperClassName={inputHelperClassName}
        />
      ))}
    </>
  );
};

export default DataCenterFields;
