import React from 'react';
import styles from './SegmentPricing.module.scss';
import {
  MarketPricingRentType,
  MarketPricingTimeMeasurement,
} from 'interfaces/inputs/IMarketPricingInput';
import RadioInput from 'components/Inputs/RadioInput';
import {
  getRentTypeOption,
  getRentTypeRadioOptions,
  getTimeMeasurementOption,
  getTimeMeasurementRadioOptions,
} from './utils';
import { SegmentPricingParams } from '../interfaces';
import { translateText } from 'utils/i18n';
import { I18N_HISTORICAL_PERFORMANCE_TEXT_PATH } from 'components/HistoricalPerformance/constants';
import { I18N_AVANT_PLATFORM_LEASE_LABEL_PATH } from 'constants/i18n';

type Props = {
  segmentPricingParams: SegmentPricingParams;
  setSegmentPricingParams: (data: SegmentPricingParams) => void;
};

const RadioControl: React.FC<Props> = ({
  segmentPricingParams,
  setSegmentPricingParams,
}) => (
  <div className={styles['type-selector-container']}>
    <div className={styles['time-measurement']}>
      <span className={styles.label}>
        {translateText(
          `${I18N_HISTORICAL_PERFORMANCE_TEXT_PATH}.radioControlMessage`,
        )}
      </span>
      <RadioInput
        selectedItem={getTimeMeasurementOption(
          segmentPricingParams?.timeMeasurement,
        )}
        data={getTimeMeasurementRadioOptions()}
        onChange={item =>
          setSegmentPricingParams({
            ...segmentPricingParams,
            timeMeasurement: item.value as MarketPricingTimeMeasurement,
          })
        }
      />
    </div>
    <div className={styles['rent-type']}>
      <span className={styles.label}>
        {translateText(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.rentType`)}
      </span>
      <RadioInput
        selectedItem={getRentTypeOption(segmentPricingParams.rentType)}
        data={getRentTypeRadioOptions()}
        onChange={item =>
          setSegmentPricingParams({
            ...segmentPricingParams,
            rentType: item.value as MarketPricingRentType,
          })
        }
      />
    </div>
  </div>
);

export default RadioControl;
