import React, { useState, useEffect } from 'react';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import { ITaxAndOpex } from 'interfaces/ITaxAndOpex';
import styles from '../../FormSection.module.scss';
import { calculateTaxAndOpexTotal } from '../../PropertyForm/calculateFields';
import { translateText } from 'utils/i18n';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import {
  currencyInputFormatter,
  getCurrencySymbol,
} from 'utils/formatters/currency';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import RadioInput from 'components/Inputs/RadioInput';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend/index';
import { getExpenseFromQuoteDate } from '../../PricingQuoteForm/utils';
import { useTranslation } from 'react-i18next';
import {
  I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { getTaxAndOpexSuggestions } from 'utils/getTaxAndOpexSuggestions';

interface Props {
  unitOfMeasurement: string;
  value: ITaxAndOpex;
  property?: IProperty;
  onChange: (field: string, value: any) => void;
  date?: string;
}

const TaxAndOpEx: React.FC<Props> = ({
  value,
  unitOfMeasurement,
  property,
  onChange,
  date,
}) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasTaxThroughDate, setHasTaxThroughDate] = useState(false);
  const [opexChangedManually, setOpexChangedManually] = useState(true);

  useEffect(() => {
    if (property?.expenses && date) {
      const quoteExpense = getExpenseFromQuoteDate(date, property);
      setHasTaxThroughDate(!!quoteExpense);
    }
  }, [property, date]);

  useEffect(() => {
    const quoteExpense = getExpenseFromQuoteDate(date, property);
    const { taxAndOpex, tax, opex } = value;
    if (quoteExpense && quoteExpense.taxAndOpex) {
      setHasTaxThroughDate(!!quoteExpense);
    }
    if (quoteExpense && !(taxAndOpex || (tax && opex))) {
      onChange('tax', quoteExpense?.tax);
      onChange('opex', quoteExpense?.operatingExpenses);
      onChange('taxAndOpex', quoteExpense?.taxAndOpex);
      setOpexChangedManually(false);
    } else if (date && !opexChangedManually) {
      onChange('tax', null);
      onChange('opex', null);
      onChange('taxAndOpex', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const currency = getCurrencySymbol(property?.currencyCode);

  useEffect(() => {
    setIsDisabled(Boolean(value.taxAndOpex && !value.tax && !value.opex));
  }, [value.opex, value.tax, value.taxAndOpex]);

  const onTaxAndOpexChange = (value: string) => {
    onChange('taxAndOpex', value);
    const hasValue = value.trim();
    if (hasValue) {
      setIsDisabled(true);
      onChange('tax', null);
      onChange('opex', null);
    } else {
      setIsDisabled(false);
    }
    setOpexChangedManually(true);
  };

  const calculateTaxAndOpex = (tax?: number | null, opex?: number | null) => {
    // calculate total tax&opex on tax or opex value changes
    const taxOpex = calculateTaxAndOpexTotal(tax, opex) || null;
    onChange('taxAndOpex', taxOpex);
    setOpexChangedManually(true);
  };

  const updateOperatingExpenses = (newOpex: string) => {
    const tax = convertToNumber(value.tax);
    const opex = convertToNumber(newOpex);
    onChange('opex', newOpex);
    calculateTaxAndOpex(tax, opex);
  };

  const updateTax = (newTax: string) => {
    const tax = convertToNumber(newTax);
    const opex = convertToNumber(value.opex);
    onChange('tax', newTax);
    calculateTaxAndOpex(tax, opex);
  };

  return (
    <>
      <FormControl
        label={translateText('avantPlatform.attributes.lease.label.taxAndOpex')}
        wrapperClassName={styles['form-row']}
        required={hasTaxThroughDate}
      >
        <div className={styles['large-input-container']}>
          <CurrencyInput
            currencySymbol={currency}
            iconPosition={'left'}
            id="tax-and-opex"
            onChange={onTaxAndOpexChange}
            formatter={currencyInputFormatter}
            placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
            value={value.taxAndOpex}
            isRequired={hasTaxThroughDate}
          />
          <UnitOfMeasurementLegend unitOfMeasurement={unitOfMeasurement} />
          <RadioInput
            selectedItem={Number(value.taxAndOpex)}
            data={getTaxAndOpexSuggestions(currency)}
            onChange={item => onTaxAndOpexChange(String(item?.value) || '')}
          />
        </div>
      </FormControl>
      <FormControl
        label={translateText('avantPlatform.attributes.property.label.opex')}
        wrapperClassName={styles['form-row']}
        disabled={isDisabled}
      >
        <div className={styles['large-input-container']}>
          <CurrencyInput
            currencySymbol={currency}
            iconPosition={'left'}
            id="lease-opex"
            isDisabled={isDisabled}
            onChange={updateOperatingExpenses}
            formatter={currencyInputFormatter}
            placeholder={t(
              `${I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH}.enterQuantity`,
            )}
            value={value.opex}
          />
          <UnitOfMeasurementLegend
            unitOfMeasurement={unitOfMeasurement}
            disabled={isDisabled}
          />
        </div>
      </FormControl>
      <FormControl
        label={translateText('avantPlatform.attributes.property.label.taxes')}
        wrapperClassName={styles['form-row']}
        disabled={isDisabled}
      >
        <div className={styles['large-input-container']}>
          <CurrencyInput
            currencySymbol={currency}
            iconPosition={'left'}
            id="lease-tax"
            isDisabled={isDisabled}
            onChange={updateTax}
            formatter={currencyInputFormatter}
            placeholder={t(
              `${I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH}.enterQuantity`,
            )}
            value={value.tax}
          />
          <UnitOfMeasurementLegend
            unitOfMeasurement={unitOfMeasurement}
            disabled={isDisabled}
          />
        </div>
      </FormControl>
    </>
  );
};

export default TaxAndOpEx;
