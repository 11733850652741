import React from 'react';
import { I18N_AVANT_PLATFORM_LEASE_LABEL_PATH } from 'constants/i18n';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';

import styles from '../../FormSection.module.scss';
import SizeFields from '../SizeFields';

interface Props {
  input: ILeaseInput | IPropertyInput;
  unitOfMeasurement: string;
  onChange: (field: string, value: string) => void;
  leaseProperty?: IProperty;
  officeSizeLabel?: string;
  warehouseSizeLabel?: string;
  sizeFieldsName?: string[];
  officeSizeFieldsName?: string[];
  warehouseSizeFieldsName?: string[];
}
const OfficeWareHouseSizes: React.FC<Props> = ({
  input,
  unitOfMeasurement,
  leaseProperty,
  onChange,
  officeSizeLabel,
  warehouseSizeLabel,
  sizeFieldsName,
  officeSizeFieldsName,
  warehouseSizeFieldsName,
}) => {
  return (
    <div className={styles['wrapper-size-container']}>
      <SizeFields
        id="office-input-size"
        labelClassName={styles['lease-column-label']}
        wrapperClassName={styles['lease-size-column-wrapper']}
        columnClassName={styles['lease-size-column']}
        labelName={
          officeSizeLabel ||
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.officeLeaseSize`
        }
        field="officeSize"
        input={input}
        property={leaseProperty}
        onChange={onChange}
        unitOfMeasurement={unitOfMeasurement}
        sizeFieldsName={sizeFieldsName}
        officeSizeFieldsName={officeSizeFieldsName}
        warehouseSizeFieldsName={warehouseSizeFieldsName}
      />
      <SizeFields
        id="warehouse-input-size"
        labelClassName={styles['lease-column-label']}
        wrapperClassName={styles['lease-size-column-wrapper']}
        columnClassName={styles['lease-size-column']}
        labelName={
          warehouseSizeLabel ||
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.warehouseLeaseSize`
        }
        field="warehouse"
        input={input}
        property={leaseProperty}
        onChange={onChange}
        unitOfMeasurement={unitOfMeasurement}
        sizeFieldsName={sizeFieldsName}
        officeSizeFieldsName={officeSizeFieldsName}
        warehouseSizeFieldsName={warehouseSizeFieldsName}
      />
    </div>
  );
};

export default OfficeWareHouseSizes;
