export enum PropertySubtypes {
  agricultural = 'Agricultural',
  airport = 'Airport',
  assistedLivingFacility = 'Assisted Living Facility',
  behavioralHealthHospital = 'Behavioral Health Hospital',
  bigBox = 'Big Box',
  bioManufacturing = 'Bio-manufacturing',
  commuterRail = 'Commuter Rail',
  conventional = 'Conventional',
  coworking = 'Coworking',
  creative = 'Creative',
  dataCenter = 'Data Center',
  developmentSiteWithProForma = 'Development Site with Pro-Forma',
  distribution = 'Distribution',
  educational = 'Educational',
  filmStudio = 'Film Studio',
  freestanding = 'Freestanding',
  generalHospital = 'General Hospital',
  generalWarehouse = 'General Warehouse',
  government = 'Government',
  healthcare = 'Healthcare',
  hotel = 'Hotel',
  industrial = 'Industrial',
  labRD = 'Lab R&D',
  lifeScience = 'Life Science',
  lowIncomeUse = 'Low Income-Use',
  ltacHospital = 'LTAC Hospital',
  manufacturing = 'Manufacturing',
  medicalCondo = 'Medical Condo',
  medicalOffice = 'Medical Office',
  medicalRetail = 'Medical Retail',
  mixedUse = 'Mixed-Use',
  motel = 'Motel',
  multifamily = 'Multifamily',
  office = 'Office',
  officeShowroom = 'Office Showroom',
  parkingLot = 'Parking Lot',
  port = 'Port',
  powerCenter = 'Power Center',
  rail = 'Rail',
  rD = 'R&D',
  regionalMall = 'Regional Mall',
  rehabilitationHospital = 'Rehabilitation Hospital',
  retail = 'Retail',
  selfStorage = 'Self Storage',
  seniorHousing = 'Senior Housing',
  skilledNursingFacility = 'Skilled Nursing Facility',
  stripMall = 'Strip Mall',
  studentHousing = 'Student Housing',
  subway = 'Subway',
  vacantLand = 'Vacant Land',
  carrierHotel = 'Carrier Hotel',
  colocation = 'Colocation',
  edge = 'Edge',
  enterprise = 'Enterprise',
  hyperscapeCloud = 'Hyperscale/Cloud',
  outdoorStorage = 'Outdoor Storage',
  singleFamily = 'Single-family',
}

export const PropertySubtypeNames: { [key: number]: PropertySubtypes } = {
  1: PropertySubtypes.office,
  2: PropertySubtypes.creative,
  3: PropertySubtypes.government,
  5: PropertySubtypes.mixedUse,
  11: PropertySubtypes.distribution,
  13: PropertySubtypes.generalWarehouse,
  18: PropertySubtypes.manufacturing,
  33: PropertySubtypes.coworking,
  41: PropertySubtypes.officeShowroom,
  43: PropertySubtypes.conventional,
  44: PropertySubtypes.lowIncomeUse,
  45: PropertySubtypes.studentHousing,
  46: PropertySubtypes.seniorHousing,
  47: PropertySubtypes.dataCenter,
  48: PropertySubtypes.generalHospital,
  49: PropertySubtypes.medicalCondo,
  50: PropertySubtypes.medicalOffice,
  51: PropertySubtypes.medicalRetail,
  52: PropertySubtypes.subway,
  53: PropertySubtypes.commuterRail,
  54: PropertySubtypes.rail,
  55: PropertySubtypes.airport,
  56: PropertySubtypes.port,
  73: PropertySubtypes.rD,
  81: PropertySubtypes.hotel,
  82: PropertySubtypes.motel,
  83: PropertySubtypes.vacantLand,
  84: PropertySubtypes.developmentSiteWithProForma,
  85: PropertySubtypes.selfStorage,
  86: PropertySubtypes.retail,
  87: PropertySubtypes.agricultural,
  88: PropertySubtypes.office,
  89: PropertySubtypes.industrial,
  90: PropertySubtypes.multifamily,
  91: PropertySubtypes.dataCenter,
  92: PropertySubtypes.healthcare,
  93: PropertySubtypes.parkingLot,
  94: PropertySubtypes.regionalMall,
  95: PropertySubtypes.stripMall,
  96: PropertySubtypes.powerCenter,
  97: PropertySubtypes.bigBox,
  98: PropertySubtypes.freestanding,
  99: PropertySubtypes.educational,
  100: PropertySubtypes.labRD,
  101: PropertySubtypes.bioManufacturing,
  102: PropertySubtypes.lifeScience,
  103: PropertySubtypes.filmStudio,
  104: PropertySubtypes.filmStudio,
  105: PropertySubtypes.skilledNursingFacility,
  106: PropertySubtypes.assistedLivingFacility,
  107: PropertySubtypes.ltacHospital,
  108: PropertySubtypes.rehabilitationHospital,
  109: PropertySubtypes.behavioralHealthHospital,
  110: PropertySubtypes.mixedUse,
  116: PropertySubtypes.carrierHotel,
  117: PropertySubtypes.colocation,
  118: PropertySubtypes.edge,
  119: PropertySubtypes.enterprise,
  120: PropertySubtypes.hyperscapeCloud,
  121: PropertySubtypes.outdoorStorage,
  122: PropertySubtypes.singleFamily,
};
