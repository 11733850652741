import React, { useState } from 'react';
import { ExportTypes } from 'constants/exportTypes';
import { I18N_AVANT_PROPERTY_TEXT_PATH } from 'constants/i18n';
import DropdownButton from 'components/DropdownButton';
import { DropdownOption } from 'components/Dropdown';
import { IActivitiesSearch } from 'interfaces/IActivitiesSearch';
import { useFileDownload } from 'hooks/useFileDownload';
import { stringifyGraphQL } from 'utils/formatters/string';
import { translateText } from 'utils/i18n';

import Notification from '../Notification';
import { dateRangeOptions } from './dateRangeOptions';
import { getDateRangeFilter } from './utils';

const I18N_DOWNLOAD_BUTTON_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.downloadButton`;

const DEFAULT_ERROR_MESSAGE = translateText(
  `${I18N_DOWNLOAD_BUTTON_TEXT_PATH}.downloadErrorTitle`,
);

interface Props {
  disabled?: boolean;
  queryVariables?: IActivitiesSearch;
  wrapperClassName?: string;
  notificationClassName?: string;
  isWhite?: boolean;
}

const DownloadActivitiesButton: React.FC<Props> = props => {
  const {
    disabled,
    queryVariables,
    wrapperClassName,
    notificationClassName,
  } = props;
  const [dateRange, setDateRange] = useState<DropdownOption | null>(null);

  const getSearchVariables = () => {
    if (!queryVariables || !dateRange) {
      return null;
    }

    return {
      type: ExportTypes.activities,
      order: stringifyGraphQL(queryVariables.order),
      shouldReturnPricingQuotes: true,
      filters: stringifyGraphQL({
        ...queryVariables.filter,
        ...getDateRangeFilter(dateRange),
      }),
    };
  };

  const { isDownloading, setHasError, hasError, createFile } = useFileDownload({
    queryVariables: getSearchVariables(),
  });

  const isButtonDisabled = disabled || isDownloading;

  const onClickHandler = async (dateRange: DropdownOption) => {
    await setDateRange(dateRange);
    await createFile();
  };

  return (
    <>
      <DropdownButton
        options={dateRangeOptions}
        onClick={onClickHandler}
        icon="download"
        wrapperClassName={wrapperClassName}
        disabled={isButtonDisabled}
      />
      <Notification
        title={`${translateText(
          `${I18N_DOWNLOAD_BUTTON_TEXT_PATH}.preparingFileTitle`,
        )}...`}
        subtitle={translateText(
          `${I18N_DOWNLOAD_BUTTON_TEXT_PATH}.gatheringInfoMessage`,
        )}
        show={isDownloading}
        wrapperClassName={notificationClassName}
      />
      <Notification
        title={DEFAULT_ERROR_MESSAGE}
        subtitle={translateText(
          `${I18N_DOWNLOAD_BUTTON_TEXT_PATH}.downloadErrorMessage`,
        )}
        show={hasError}
        onClose={() => setHasError(false)}
        wrapperClassName={notificationClassName}
        onTryAgain={() => {
          setHasError(false);
          createFile();
        }}
      />
    </>
  );
};

export default DownloadActivitiesButton;
