import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './Dropdown.module.scss';

export type DropdownOption = {
  id: string | number;
  label?: string;
  value: string;
};

type Props = {
  containerWrapperClassName?: string;
  inputWrapperClassName?: string;
  onChange: (value: string | number) => void;
  options: DropdownOption[];
  defaultOpen?: boolean;
  optionWrapperClassName?: string;
  selectedId?: string | number;
  editOnFocus?: boolean;
  optionContainerClassName?: string;
  showSelectedItemIcon?: boolean;
};

const Dropdown: React.FC<Props> = (props: Props) => {
  const {
    containerWrapperClassName,
    inputWrapperClassName,
    optionWrapperClassName,
    options,
    defaultOpen = false,
    selectedId,
    editOnFocus,
    optionContainerClassName,
    showSelectedItemIcon = true,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const dropdownRef = useRef<HTMLInputElement>(null);

  const selectedOption = options.find(option => {
    return option.id === selectedId;
  });

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  if (editOnFocus && !isFocused) {
    return (
      <input
        tabIndex={1}
        onFocus={() => {
          setIsFocused(true);
        }}
        type="text"
        value={selectedOption?.value}
        className={styles['input-span']}
      />
    );
  }

  return (
    <div
      ref={dropdownRef}
      tabIndex={1}
      onFocus={() => {
        setIsFocused(true);
      }}
      className={classnames(styles.container, containerWrapperClassName)}
    >
      <div
        className={classnames(styles.input, inputWrapperClassName, {
          [styles['list-opened']]: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption?.label || selectedOption?.value}
      </div>

      {isOpen && (
        <div
          className={classnames(
            styles['options-container'],
            optionContainerClassName,
          )}
        >
          {options.map((item: DropdownOption) => {
            return (
              <div
                className={classnames(
                  styles.option,
                  optionWrapperClassName,
                  showSelectedItemIcon
                    ? {
                        [styles.selected]: item.id === selectedOption?.id,
                      }
                    : null,
                )}
                key={item.id}
                onClick={() => {
                  setIsOpen(false);
                  setIsFocused(false);
                  if (item.id !== selectedOption?.id) {
                    props.onChange(item.id);
                  }
                }}
              >
                {item.label || item.value}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
