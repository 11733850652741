import { useCallback, useEffect, useState } from 'react';
import { SIZE_GROWTH_OPTIONS } from 'constants/expandingContracting';
import { applyPercentageFromNumber } from 'utils/formatters/number';
import { ITIM } from 'interfaces/ITIM';

interface Props {
  tim: ITIM;
  updateField: (field: string, value: any) => void;
  unitOfMeasurement?: string;
}

export const useCalculateSizeGrowth = ({
  tim,
  updateField,
  unitOfMeasurement,
}: Props) => {
  const [triggeredSizeGrowth, setTriggeredSizeGrowth] = useState<boolean>(
    false,
  );
  const [changedSizeGrowth, setChangedSizeGrowth] = useState<boolean>(false);

  const isSqm = unitOfMeasurement === 'sqm';
  const targetMaxSizeField = tim[isSqm ? 'targetSizeMaxMt' : 'targetSizeMax'];
  const currentSizeField = tim[isSqm ? 'currentSizeMt' : 'currentSize'];

  const handleChangedSizeGrowth = () => {
    setChangedSizeGrowth(true);
  };

  const handleTriggeredSizeGrowth = () => {
    setTriggeredSizeGrowth(true);
  };

  const calculateExpandingContracting = useCallback(() => {
    // if size fields were triggered AND one of the sizes are empty - clear value
    const clearGrowthSize =
      triggeredSizeGrowth && (!targetMaxSizeField || !currentSizeField);
    if (clearGrowthSize) {
      updateField('expandingContracting', null);
    }
    // if sizes are empty OR growth size hasn't been toggled manually OR sizes haven't been changed - prevent calculation
    if (
      !targetMaxSizeField ||
      !currentSizeField ||
      !triggeredSizeGrowth ||
      changedSizeGrowth
    ) {
      return;
    }

    const currentSizePercentage = applyPercentageFromNumber(
      currentSizeField,
      10,
    );

    const isExpanding =
      currentSizePercentage + currentSizeField <= targetMaxSizeField;

    const isContracting =
      currentSizeField - currentSizePercentage >= targetMaxSizeField;

    if (isExpanding) {
      updateField('expandingContracting', SIZE_GROWTH_OPTIONS[0]);
    } else if (isContracting) {
      updateField('expandingContracting', SIZE_GROWTH_OPTIONS[1]);
    } else {
      updateField('expandingContracting', null);
    }
  }, [
    changedSizeGrowth,
    triggeredSizeGrowth,
    targetMaxSizeField,
    currentSizeField,
    updateField,
  ]);

  useEffect(() => {
    calculateExpandingContracting();
  }, [calculateExpandingContracting]);

  return {
    handleChangedSizeGrowth,
    handleTriggeredSizeGrowth,
  };
};
