import { authHeaders, authHeadersOkta } from './authHeaders';
import { createUploadLink } from 'apollo-upload-client';
import { customFetch } from 'graphql/customFetch';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

const uploadLink = createUploadLink({
  uri: window._env_ ? window._env_.USERS_API_URL : '',
  credentials: 'same-origin',
  fetch: customFetch,
});
const link = ApolloLink.from([authHeaders, uploadLink]);
const linkOkta = ApolloLink.from([authHeadersOkta, uploadLink]);

export const usersClientWithOkta = new ApolloClient({
  cache: new InMemoryCache(),
  link: linkOkta,
  name: 'users-api-client',
});

export const usersClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  name: 'users-api-client',
});
