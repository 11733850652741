import React from 'react';
import classnames from 'classnames';
import styles from './Notification.module.scss';
import { Col, Container, Row } from 'components/@codelitt/ay-design-library';
import closeIcon from '../../assets/images/icons/clear-icon.svg';
import { NotificationProps } from 'contexts/NotificationContext';

const Notification: React.FC<NotificationProps> = ({
  id,
  onClose,
  onTryAgain,
  show,
  subtitle,
  title,
  wrapperClassName,
}) => {
  const renderDots = () => {
    return (
      <div className={styles['three-dots']}>
        <div className={styles['dot']}></div>
        <div className={styles['dot']}></div>
        <div className={styles['dot']}></div>
      </div>
    );
  };

  const renderButtonClose = () => {
    return (
      <div className={styles['close-button']} onClick={onClose}>
        <img src={closeIcon} alt="Close" />
      </div>
    );
  };

  const renderTryAgain = () => {
    return (
      <>
        {' Please '}
        <div className={styles['try-again']} onClick={onTryAgain}>
          try again.
        </div>
      </>
    );
  };

  return (
    <div
      className={classnames(styles.container, wrapperClassName, {
        [styles.visible]: show,
      })}
      data-testid={id}
      id={id}
    >
      <Container>
        <Row wrapperClassName={styles.content}>
          <Col xs={3} sm={7} md={11} lg={11}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>
              {subtitle}
              {onTryAgain && renderTryAgain()}
            </div>
          </Col>
          <Col
            xs={1}
            sm={1}
            md={1}
            lg={1}
            wrapperClassName={styles['action-container']}
          >
            {onClose ? renderButtonClose() : renderDots()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notification;
