import React, { useState } from 'react';
import classnames from 'classnames';
import { isNil, isEmpty } from 'lodash';
import styles from './VerticalBubbleChart.module.scss';
import GraphContainer from '../GraphContainer';
import { VerticalBubbleData } from './interfaces';
import {
  appearMarketLine,
  fadeMarketLine,
  getGraphDimensions,
  getLineId,
  shouldRenderLine,
  getYAxisValuesFromTotal,
} from './utils';
import XAxis from '../HorizontalChartsElements/GraphXAxis';
import BackgroundLines from 'components/Graphs/BubbleChartsElements/BackgroundLines';
import { colors } from 'constants/colors';
import GraphHeader from 'components/Graphs/HorizontalChartsElements/GraphHeader';
import { ILegend } from 'components/Graphs/HorizontalChartsElements/GraphLegend';
import GraphYAxisLegend from '../HorizontalChartsElements/GraphyAxisLegend/index';
import { IGraphsDimensions } from 'interfaces/graphs/IGraphsDimensions';
import {
  HORIZONTAL_YAXIS_LEGEND_BOTTOM,
  HORIZONTAL_YAXIS_LEGEND_HEIGHT,
  PADDING_Y_AXIS,
  YAXIS_LEGEND_BOTTOM,
  YAXIS_LEGEND_HEIGHT,
  HORIZONTAL_YAXIS_LEGEND_TOP,
} from './constants';
import {
  buildBubblesScale,
  generateGraphScales,
} from '../RecentLeases/graphScales';
import LeaseBubbles from 'components/Graphs/BubbleChartsElements/LeaseBubbles';
import Tooltip from 'components/Graphs/BubbleChartsElements/Tooltip';
import { RecentLeasesIds } from '../RecentLeases/graphIds';
import { GraphClasses } from '../RecentLeases/graphConstants';
import Line from 'components/Graphs/HorizontalChartsElements/Line';
import MarketBubbles from '../BubbleChartsElements/MarketBubbles';
import {
  UnitOfMeasurement,
  UNITS_MEASUREMENT_AREA,
} from 'constants/unitOfMeasurement';
import MissingGraphData from 'components/MissingGraphData';
import { IDataPoint } from '../RecentLeases/interfaces';
import { ScaleTime } from 'd3-scale';

interface Props {
  data: VerticalBubbleData;
  graphDimensions?: IGraphsDimensions;
  graphId: RecentLeasesIds;
  isBorderBottomRounded?: boolean;
  isBorderTopRounded?: boolean;
  legends?: ILegend[];
  verticalLegends?: string[];
  onClickAvgLinePoint?: (dataPoint: IDataPoint) => void;
  // @TODO - rename this prop to something more generic
  onClickLeaseBubble?: (dataPoint: IDataPoint) => void;
  subtitle?: string;
  title: string;
  xAxisFormat?: (date: any, i: number) => string;
  showHorizontalLine?: boolean;
  mktAvgLineColor?: string;
  marketBubblesHoverLineColor?: string;
  hideXAxisLine?: boolean;
  hasHorizontalLines?: boolean;
  useYPadding?: boolean;
  yHeight?: number;
  defaultXScale?: ScaleTime<number, number, never>;
  legendTopPosition?: number;
  legendRightPosition?: number;
  legendBottomPosition?: number;
  legendLeftPosition?: number;
  useXAxisToScale?: boolean;
  xAxisTranslateX?: number;
  xAxisTranslateY?: number;
  className?: string;
  containerClassName?: string;
}

const VerticalBubbleChart: React.FC<Props> = ({
  data,
  graphDimensions,
  graphId,
  isBorderBottomRounded,
  isBorderTopRounded,
  legends,
  onClickAvgLinePoint,
  onClickLeaseBubble,
  subtitle,
  title,
  xAxisFormat,
  verticalLegends,
  showHorizontalLine,
  mktAvgLineColor,
  marketBubblesHoverLineColor,
  hideXAxisLine,
  hasHorizontalLines,
  useYPadding,
  yHeight,
  defaultXScale,
  legendTopPosition,
  legendRightPosition,
  legendBottomPosition,
  legendLeftPosition,
  useXAxisToScale,
  className,
  containerClassName,
  xAxisTranslateX,
  xAxisTranslateY,
}) => {
  const [activeMarket, setActiveMarket] = useState<string | null>(null);
  const dimensions = getGraphDimensions(graphDimensions);
  const { xScale, yScale, minYValue, maxYValue } = generateGraphScales(
    data.marketItems,
    data.bubbleItems,
    dimensions.graph.width,
    dimensions.graph.height,
    {
      yDefaultPadding: PADDING_Y_AXIS,
      useYPadding: useYPadding,
      xAxis: useXAxisToScale ? data.xAxis : undefined,
    },
  );
  const circleScale = buildBubblesScale(data.bubbleItems);
  const { unitOfMeasurement, currencySymbol } = data.graphParams;

  const unitOfMeasurementArea =
    UnitOfMeasurement.sm === unitOfMeasurement
      ? 'psqm'
      : UNITS_MEASUREMENT_AREA[unitOfMeasurement];

  const hasNoData = data.marketItems.length <= 0;

  const yAxisValuesToHorizontalLines = hasHorizontalLines
    ? getYAxisValuesFromTotal(maxYValue, minYValue)
    : undefined;

  const buildYAxisValues = () => {
    if (
      isNil(yAxisValuesToHorizontalLines) ||
      isEmpty(yAxisValuesToHorizontalLines)
    ) {
      return [
        `${currencySymbol}${maxYValue} ${unitOfMeasurementArea}`,
        `${currencySymbol}${minYValue} ${unitOfMeasurementArea}`,
      ];
    }

    return yAxisValuesToHorizontalLines.map(
      value => `${currencySymbol}${value.toFixed()} ${unitOfMeasurementArea}`,
    );
  };

  return (
    <GraphContainer
      isBorderBottomRounded={isBorderBottomRounded}
      isBorderTopRounded={isBorderTopRounded}
      wrapperClassName={classnames(styles['verticalbubble'], className)}
    >
      <GraphHeader
        legends={legends}
        title={title}
        subtitle={subtitle}
        hasMissingData={hasNoData}
      />
      <div className={classnames(styles.container, containerClassName)}>
        {hasNoData ? (
          <MissingGraphData />
        ) : (
          <>
            <GraphYAxisLegend
              legends={verticalLegends || buildYAxisValues()}
              height={
                yHeight ||
                (hasHorizontalLines
                  ? HORIZONTAL_YAXIS_LEGEND_HEIGHT
                  : YAXIS_LEGEND_HEIGHT)
              }
              top={
                legendTopPosition || hasHorizontalLines
                  ? HORIZONTAL_YAXIS_LEGEND_TOP
                  : undefined
              }
              right={legendRightPosition}
              bottom={
                legendBottomPosition || hasHorizontalLines
                  ? HORIZONTAL_YAXIS_LEGEND_BOTTOM
                  : YAXIS_LEGEND_BOTTOM
              }
              left={legendLeftPosition}
            />
            <svg
              id={`${graphId}`}
              className={styles['svg-graph']}
              width={dimensions.svg.width}
              height={dimensions.svg.height}
            >
              <g transform={dimensions.svg.transform}>
                {!hideXAxisLine && (
                  <XAxis
                    xScale={defaultXScale || xScale}
                    height={dimensions.graph.height}
                    tickFormat={xAxisFormat}
                    translateX={xAxisTranslateX}
                    translateY={xAxisTranslateY}
                  />
                )}
                <BackgroundLines
                  marketDataPoints={data.marketItems}
                  itemsDataPoints={data.bubbleItems}
                  xScale={xScale}
                  yScale={yScale}
                  linesColor={colors.uiColor700}
                  graphWidth={dimensions.graph.width}
                  graphHeight={dimensions.graph.height}
                  horizontal={hasHorizontalLines}
                  yAxisValues={yAxisValuesToHorizontalLines}
                />
                <LeaseBubbles
                  data={data.bubbleItems}
                  disabled={!!activeMarket}
                  xScale={xScale}
                  yScale={yScale}
                  circleScale={circleScale}
                  mainColor={colors.primaryColor500}
                  hoverColor={colors.primaryColor500}
                  borderColor={colors.primaryColor400}
                  showHorizontalLine={showHorizontalLine}
                  onClick={bubbleItem => onClickLeaseBubble?.(bubbleItem.data)}
                  onMouseOver={() => fadeMarketLine(graphId)}
                  onMouseOut={() => appearMarketLine(graphId)}
                  hasHorizontalLines={hasHorizontalLines}
                />
                <MarketBubbles
                  data={data.marketItems}
                  activeId={activeMarket}
                  xScale={xScale}
                  yScale={yScale}
                  currencySymbol={currencySymbol}
                  onClick={marketBubble =>
                    onClickAvgLinePoint?.(marketBubble.data)
                  }
                  onMouseOver={setActiveMarket}
                  onMouseOut={() => setActiveMarket(null)}
                  hoverLineColor={marketBubblesHoverLineColor}
                  hasHorizontalLines={hasHorizontalLines}
                />
                {shouldRenderLine(data.marketItems) && (
                  <Line
                    id={getLineId(graphId)}
                    className={GraphClasses.Line}
                    color={mktAvgLineColor || colors.ayGrassGreenColor}
                    data={data.marketItems.map(item => {
                      return {
                        id: item.date,
                        xValue: xScale(item.date),
                        yValue: yScale(item.value),
                        item,
                        label: item.date,
                      };
                    })}
                  />
                )}
                <Tooltip graphId={graphId} />
              </g>
            </svg>
          </>
        )}
      </div>
    </GraphContainer>
  );
};

export default VerticalBubbleChart;
