interface StaticMapConfig {
  height: number;
  latitude: number;
  longitude: number;
  width: number;
  zoom?: number;
}

const DEFAULT_ZOOM = 13;
const STATIC_MAP_API_URL = 'https://api.mapbox.com/styles/v1/';

export const createStaticMapImageUrl = (
  config: StaticMapConfig,
): string | null => {
  const mapboxStyle = window._env_.MAPBOX_STYLE_URL;

  if (!mapboxStyle || !config.latitude || !config.longitude) {
    return null;
  }

  const mapboxVars = mapboxStyle.split('/');
  const styleUserName = mapboxVars[3];
  const styleID = mapboxVars[4];
  const mapZoom = config.zoom || DEFAULT_ZOOM;

  const params = [
    styleUserName,
    styleID,
    'static',
    `${config.longitude},${config.latitude},${mapZoom}`,
    `${config.width}x${config.height}`,
  ];
  const token = `access_token=${window._env_.MAPBOX_ACCESS_KEY}`;

  return `${STATIC_MAP_API_URL}${params.join('/')}?${token}`;
};
