import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, map, startCase, toLower } from 'lodash';
import { useLazyQuery, useQuery } from '@apollo/client';

import { ErrorLabel } from 'components/ErrorLabel';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import OptionSearchFilter from 'components/OptionSearchFilter';
import FormFooter from 'components/FormFooter';
import {
  I18N_COMPANY_LABEL_PATH,
  I18N_COMPANY_TEXT_PATH,
} from 'components/CompanyProfile/constants';
import {
  COMPANY_FIELDS_INFO,
  CompanyFieldsNames,
  ICompaniesFieldsTypes,
  INDUSTRY_FIELD_VARIABLES,
} from 'components/CreateComps/FormSection/CompanyForm/constants';
import PropertiesSearchInput from 'components/PropertiesSearchInput';
import { SearchInputSize } from 'components/SearchInput/enums';
import Button from 'components/Button/new';
import Comments from 'components/CreateComps/FormSection/FormFields/Comments';
import RemoteChoiceInput from 'components/Inputs/RemoteChoiceInput';
import { ColorNames } from 'constants/colorNames';
import {
  I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH,
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH,
} from 'constants/i18n';
import locations from 'routes';
import { IdName } from 'interfaces/IdName';
import { ICompany } from 'interfaces/ICompany';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { IProperty } from 'interfaces/IProperty';
import { INDUSTRIES_QUERY } from 'graphql/industries';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import {
  COMPANIES_MERGED_QUERY,
  SEARCH_COMPANIES_QUERY,
} from 'graphql/company';
import { COMPANY_INVESTOR_TYPES } from 'graphql/company/queries';
import { getPropertyAddress } from 'utils/formatters/property';
import { translateText } from 'utils/i18n';

import formsStyles from '../FormSection.module.scss';
import CompanyLogo from './CompanyLogo';
import styles from './CompanyForm.module.scss';

export interface Props {
  data: ICompany;
  isLoading: boolean;
  onChange: (field: string, value: any) => void;
  onSubmit: () => void;
  showCompanyAliasesButton?: boolean;
  onCancel?: () => void;
  onOpenAliases?: () => void;
  setCompanyLogoRemoved?: (removed: boolean) => void;
  setCompanyLogoToUpload?: (file?: File) => void;
}

interface IFieldsErrors {
  [CompanyFieldsNames.OPERATING_NAME]?: boolean;
  [CompanyFieldsNames.LEGAL_NAME]?: boolean;
}

const CompanyForm: React.FC<Props> = ({
  data,
  isLoading,
  onCancel,
  onChange,
  onOpenAliases,
  onSubmit,
  setCompanyLogoRemoved,
  setCompanyLogoToUpload,
  showCompanyAliasesButton,
}) => {
  const history = useHistory();
  const { INDUSTRY, HEADQUARTER_PROPERTY, INVESTOR } = CompanyFieldsNames;
  const [fieldsErrors, setFieldsErrors] = useState<IFieldsErrors | null>(null);

  const hasValidationError = !isEmpty(fieldsErrors);

  const { data: mergedCompanies } = useQuery<{ companiesMerged: ICompany[] }>(
    COMPANIES_MERGED_QUERY,
    {
      variables: {
        companyId: data.id,
      },
      skip: !data.id,
    },
  );

  const [requestMatchingCompanies] = useLazyQuery<{
    companies: { results: ICompany[] };
  }>(SEARCH_COMPANIES_QUERY);

  const validateCompanyName = async (
    validationFields: (keyof IFieldsErrors)[],
  ) => {
    // allows to enter name which exist on alias company names, prohibit company name if exists out of aliases
    const aliasCompanyNames = mergedCompanies?.companiesMerged.map(c => c.name);
    const allFieldsInAlias = validationFields.every(field =>
      aliasCompanyNames?.includes(data[field]),
    );
    if (allFieldsInAlias) return;

    const errors: IFieldsErrors = {};

    for (const field of validationFields) {
      const fieldValue = data[field];

      const { data: companiesData } = await requestMatchingCompanies({
        variables: {
          search: {
            filter: {
              name: fieldValue,
            },
          },
          skip: !fieldValue,
        },
      });

      const matchingCompanies = companiesData?.companies?.results || [];
      const fieldValueLowerCase = fieldValue?.toLowerCase();
      const hasMatch = matchingCompanies?.some(
        (c: ICompany) => c?.name?.toLowerCase() === fieldValueLowerCase,
      );
      if (hasMatch) errors[field] = true;
    }
    setFieldsErrors(errors);
    return errors;
  };

  const handleSubmit = async () => {
    const hasErrors = await validateCompanyName([
      CompanyFieldsNames.OPERATING_NAME,
      CompanyFieldsNames.LEGAL_NAME,
    ]);

    if (isEmpty(hasErrors)) {
      onSubmit();
    }
  };

  const renderFormControl = (
    inputInfo: ICompaniesFieldsTypes,
    content: React.ReactNode,
  ) => (
    <FormControl
      key={inputInfo.label}
      label={inputInfo.label}
      required={inputInfo.isRequired}
      wrapperClassName={formsStyles['form-row']}
    >
      {content}
    </FormControl>
  );

  const renderInputTextField = (
    inputInfo: ICompaniesFieldsTypes,
    inputName: CompanyFieldsNames,
  ) =>
    renderFormControl(
      inputInfo,
      <>
        <TextInput
          borderBox
          fullWidth
          onClear={inputInfo.onClear}
          id={`company-${inputName}-field`}
          isRequired={inputInfo.isRequired}
          onChange={(value: string) => {
            const key = inputName as keyof IFieldsErrors;
            if (fieldsErrors?.[key]) {
              setFieldsErrors(prev => {
                const updatedErrors = { ...prev };
                delete updatedErrors[key];
                return updatedErrors;
              });
            }
            onChange(inputName, value);
          }}
          placeholder={inputInfo.placeholder}
          isInvalid={inputInfo.error}
          value={
            inputName !== INDUSTRY &&
            inputName !== HEADQUARTER_PROPERTY &&
            inputName !== INVESTOR
              ? data[inputName]
              : undefined
          }
        />

        {inputInfo.error && (
          <ErrorLabel
            errorMessage={translateText(
              `${I18N_COMPANY_TEXT_PATH}.companyNameExistError`,
            )}
          />
        )}
      </>,
    );

  const renderIndustryField = (inputInfo: ICompaniesFieldsTypes) =>
    renderFormControl(
      inputInfo,
      <OptionSearchFilter
        small
        hasBorder
        noMarginBottom
        sortAlphabetically
        gridColumnsCount={4}
        isMultipleSelect={false}
        query={INDUSTRIES_QUERY}
        selectedItem={data[INDUSTRY]}
        label={inputInfo.placeholder}
        searchPlaceholder={startCase(
          toLower(
            translateText(
              `${I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH}.searchIndustry`,
            ),
          ),
        )}
        variables={INDUSTRY_FIELD_VARIABLES}
        resultBarClassName={styles['industry-field']}
        backgroundContainerColor={ColorNames.ayWhiteColor}
        onChange={(value?: IdName[]) => onChange(INDUSTRY, value)}
        queryParser={(data: any) =>
          data?.industries?.filter((item: IdName) => item.name)
        }
      />,
    );

  const renderInvestorType = (inputInfo: ICompaniesFieldsTypes) => {
    return (
      <FormControl
        label={inputInfo.label}
        wrapperClassName={formsStyles['form-row']}
        isMultiline
      >
        <RemoteChoiceInput
          query={COMPANY_INVESTOR_TYPES}
          queryParser={data => data?.investorTypes || []}
          selectedItem={{ id: data.investorType?.id }}
          onChange={(value?: IdName) => onChange(INVESTOR, value)}
        />
      </FormControl>
    );
  };

  const getFields = () =>
    map(
      COMPANY_FIELDS_INFO,
      (inputInfo: ICompaniesFieldsTypes, inputName: CompanyFieldsNames) => {
        switch (inputName) {
          case INDUSTRY:
            return renderIndustryField(inputInfo);
          case INVESTOR:
            return renderInvestorType(inputInfo);
          default:
            return renderInputTextField(inputInfo, inputName);
        }
      },
    );

  const handleOnCancel = () =>
    onCancel ? onCancel() : history.push(locations.root());

  const updateHeadQuarterFields = (
    propertyHeadquarter: IProperty | null,
    textHeadquarter?: string | null,
  ) => {
    onChange(CompanyFieldsNames.HEADQUARTERS, textHeadquarter);
    onChange(CompanyFieldsNames.HEADQUARTER_PROPERTY, propertyHeadquarter);
  };

  return (
    <form className={formsStyles.container}>
      {renderFormControl(
        {
          label: translateText(`${I18N_COMPANY_LABEL_PATH}.companyPhoto`),
        },
        <CompanyLogo
          logoUrl={data.logoUrl}
          setCompanyLogoToUpload={setCompanyLogoToUpload}
          setCompanyLogoRemoved={setCompanyLogoRemoved}
        />,
      )}

      {renderInputTextField(
        {
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.companyName`),
          placeholder: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.enterCompanyName`,
          ),
          isRequired: true,
        },
        CompanyFieldsNames.NAME,
      )}

      <>
        {renderInputTextField(
          {
            label: translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.operatingName`,
            ),
            error: !!fieldsErrors?.operatingName,
            placeholder: translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enterCompanyOperatingName`,
            ),
            onClear: () => onChange(CompanyFieldsNames.OPERATING_NAME, ''),
          },
          CompanyFieldsNames.OPERATING_NAME,
        )}

        {renderInputTextField(
          {
            label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.legalName`),
            error: !!fieldsErrors?.legalName,
            placeholder: translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enterCompanyLegalName`,
            ),
            onClear: () => onChange(CompanyFieldsNames.LEGAL_NAME, ''),
          },
          CompanyFieldsNames.LEGAL_NAME,
        )}
      </>

      {onOpenAliases && showCompanyAliasesButton && (
        <div className={styles['company-aliases-button']}>
          <Button
            type="ghost"
            label={translateText(
              `${I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH}.companyAliases`,
            )}
            iconPosition="right"
            icon="aliases"
            onClick={onOpenAliases}
          />
        </div>
      )}

      {renderFormControl(
        {
          label: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.companyAddress`,
          ),
        },
        <PropertiesSearchInput
          inputId={'company-headquarter-property-input-id'}
          searchInputProps={{ size: SearchInputSize.Small }}
          onClickSuggestion={property => {
            property.id = property.propertyId ?? property.id;
            property.primaryAddress = property.primaryAddress ?? property.name;
            updateHeadQuarterFields(property, null);
          }}
          onChangeText={(value?: string) =>
            updateHeadQuarterFields(null, value)
          }
          graphqlParams={{
            query: SEARCH_COMPANIES_PROPERTIES_QUERY,
            field: 'name',
            resultDataName: 'propertiesCompanies',
            onlyProperties: true,
          }}
          property={{
            id: data.headquarterProperty?.id,
            primaryAddress: data.headquarterProperty
              ? getPropertyAddress(data.headquarterProperty) || ''
              : data.headquarters,
            name: data.headquarterProperty?.name,
          }}
          placeholder={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterAddress`,
          )}
          parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
            return data.map(propertyCompany => ({
              ...propertyCompany,
              name: propertyCompany.displayAddress || propertyCompany.name,
            }));
          }}
          onClearSelectedSuggestion={() => updateHeadQuarterFields(null, null)}
          withMarketSwitch={false}
          customOption={{
            onSelection: value => updateHeadQuarterFields(null, value),
          }}
        />,
      )}

      {getFields()}
      <div className={styles['is-last']}>
        <Comments
          field="comments"
          onChange={onChange}
          value={data?.comments}
          label={translateText(
            'avantPlatform.attributes.property.label.descriptionComment',
          )}
        />
      </div>

      <FormFooter
        onSubmit={handleSubmit}
        onCancel={handleOnCancel}
        isCancelDisabled={isLoading}
        isSubmitDisabled={!data.name || isLoading || hasValidationError}
      />
    </form>
  );
};

export default CompanyForm;
