import React from 'react';
import { IPropertyExpense } from 'interfaces/inputs/IPropertyExpense';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { getCurrencySymbol } from 'utils/formatters/currency';
import Expense from './Expense';

interface Props {
  property: IPropertyInput;
  propertyExpense: IPropertyExpense;
  setPropertyExpense: (value: React.SetStateAction<IPropertyExpense>) => void;
}

export const CURRENT_YEAR = new Date().getFullYear();

const ExpenseFields: React.FC<Props> = ({
  property,
  propertyExpense,
  setPropertyExpense,
}) => {
  const propertyCurrencySymbol = getCurrencySymbol(property.currencyCode);
  const expenses = property?.expenses || [];

  const updateExpenseField = (fieldName: string, fieldValue: any) => {
    setPropertyExpense(value => ({ ...value, [fieldName]: fieldValue }));
  };

  const currentYearExpense = expenses?.filter(x => x.year === CURRENT_YEAR);

  return (
    <>
      <Expense
        expense={propertyExpense}
        propertyCurrencySymbol={propertyCurrencySymbol}
        updateExpenseField={updateExpenseField}
        isAnnualMonthlyVisible={true}
      />
      {currentYearExpense ? (
        <Expense
          expense={currentYearExpense[0]}
          propertyCurrencySymbol={propertyCurrencySymbol}
          updateExpenseField={updateExpenseField}
          isDisabled
          isSampleExpense
        />
      ) : null}
    </>
  );
};

export default ExpenseFields;
