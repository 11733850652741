import React from 'react';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import { REGEX_INTEGERS } from 'constants/regex';
import styles from '../../FormSection.module.scss';
import { translateText } from 'utils/i18n';

interface Props {
  value?: number;
  onChange: (field: string, value: any) => void;
}

const DriveInDoors: React.FC<Props> = ({ value, onChange }) => {
  return (
    <FormControl
      label={translateText(
        'avantPlatform.attributes.property.label.driveInDoors',
      )}
      wrapperClassName={styles['form-row']}
    >
      <TextInput
        pattern={REGEX_INTEGERS}
        id="drive-in-doors"
        placeholder={translateText(
          'avantPlatform.attributes.property.prompt.enterDoors',
        )}
        value={value}
        onChange={value => onChange('driveInDoors', value)}
      />
    </FormControl>
  );
};

export default DriveInDoors;
