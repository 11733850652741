import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { UnitsOfMeasurementModelType } from 'interfaces/unitOfMeasurement';

export const AVAILABILITY_MAPPING: UnitsOfMeasurementModelType[] = [
  {
    key: 'availableSpace',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'availableSpace',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'availableSpace',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'availableSpaceMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'officeSf',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'officeSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'officeSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'officeSqm',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'typicalClearHeightMin',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'typicalClearHeightMin',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'typicalClearHeightMinMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'typicalClearHeightMinMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
  {
    key: 'typicalClearHeightMax',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'typicalClearHeightMax',
        unit: UnitOfMeasurement.feet,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'typicalClearHeightMaxMt',
        unit: UnitOfMeasurement.meter,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'typicalClearHeightMaxMt',
        unit: UnitOfMeasurement.meter,
      },
    },
  },
];
