import React, { useEffect } from 'react';
import styles from '../OwnerPortfolio.module.scss';
import { getGraphContainerId, getCircleId, getTooltipId } from '../nodes';
import { select } from 'd3-selection';
import { DOT } from 'constants/placeholders';
import { upperFirstLetter } from 'utils/formatters/string';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import classnames from 'classnames';
import SpinnerLoader from 'components/SpinnerLoader';
import { ColorNames } from 'constants/colorNames';
import { IPortfolioDataPoint } from '../interfaces';
import { formatPropertyStatus } from 'utils/formatters/propertyStatus';
import { formatPropertyClass } from 'utils/formatters/property';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { formatNumberWithCommas } from 'utils/formatters/number';
import {
  PropertyMap,
  PropertyMapSize,
  ZoomLevel,
} from 'components/PropertyMap';

interface Props {
  graphId: number;
  isLoading: boolean;
  onContentChanged: () => void;
  dataPoint: IPortfolioDataPoint | undefined;
}

const TOOLTIP_PADDING = 16;

export const updateTooltipPosition = (bubbleId: number, graphId: number) => {
  const activePropertyBubbleNode: any = select(
    `#${getCircleId(bubbleId, graphId)}`,
  )?.node();
  const graphContainerNode: any = select(
    `#${getGraphContainerId(graphId)}`,
  )?.node();

  const tooltipElement = select(`#${getTooltipId(graphId)}`);

  if (!activePropertyBubbleNode || !graphContainerNode || !tooltipElement) {
    return;
  }

  const activeBubbleBounds = activePropertyBubbleNode.getBoundingClientRect();
  const containerBounds = graphContainerNode.getBoundingClientRect();

  const tooltipX = activeBubbleBounds.x - containerBounds.x;
  const tooltipY = activeBubbleBounds.y - containerBounds.y;

  const tooltipLeftDistance = `${tooltipX +
    activeBubbleBounds.width +
    TOOLTIP_PADDING}px`;
  const tooltipRightDistance = '';

  tooltipElement
    .style('display', 'block')
    .style('top', function() {
      const top =
        tooltipY -
        (this as any).getBoundingClientRect().height / 2 +
        activeBubbleBounds.width / 2;
      return `${top}px`;
    })
    .style('left', tooltipLeftDistance)
    .style('right', tooltipRightDistance);
};

const Tooltip: React.FC<Props> = ({
  isLoading,
  onContentChanged,
  graphId,
  dataPoint,
}) => {
  useEffect(() => {
    onContentChanged?.();
  }, [isLoading, onContentChanged, dataPoint]);

  if (!dataPoint && !isLoading) return null;

  const renderLoading = () => (
    <div
      id={getTooltipId(graphId)}
      className={classnames(
        styles['tooltip-right'],
        isLoading && styles['tooltip-right-loading'],
      )}
    >
      <div className={styles['tooltip-spinner-container']}>
        <SpinnerLoader background={ColorNames.ayTotalBlackColor} />
      </div>
    </div>
  );

  const renderTooltip = () => {
    const {
      name,
      status,
      propertyClass,
      propertyType,
      market,
      submarket,
      micromarket,
      primaryAddress,
      size,
      measurementSystem,
      coverMediaUrl,
      latitude,
      longitude,
    } = dataPoint!;

    const unitOfMeasurement = getUnitOfMeasurementForProperty(
      'buildingSize',
      ModelsWithUnitsOfMeasurement.Property,
      measurementSystem,
    );
    const propertySize = `${formatNumberWithCommas(size)} ${unitOfMeasurement}`;

    const renderPropertyThumb = () => {
      return coverMediaUrl ? (
        <div>
          <img
            alt={name}
            src={coverMediaUrl}
            className={styles['tooltip-image']}
          />
        </div>
      ) : (
        <div>
          <PropertyMap
            wrapperClassName={styles['tooltip-map']}
            property={{
              latitude,
              longitude,
            }}
            size={PropertyMapSize.small}
            zoomLevel={ZoomLevel.small}
            showControls={false}
          />
        </div>
      );
    };

    return (
      <div className={styles['tooltip-right']} id={getTooltipId(graphId)}>
        <div className={styles['tooltip-container']}>
          {renderPropertyThumb()}
          <div>
            <div className={styles['tooltip-address']}>{primaryAddress}</div>
            <div className={styles['tooltip-small-text']}>{name}</div>
            <div className={styles['tooltip-small-text']}>
              {[
                formatPropertyStatus({ name: status }),
                formatPropertyClass({ name: propertyClass }),
                upperFirstLetter(propertyType),
                propertySize,
              ]
                .filter(Boolean)
                .join(` ${DOT} `)}
            </div>
            <div className={styles['tooltip-small-text']}>
              {[market, submarket, micromarket]
                .filter(Boolean)
                .join(` ${DOT} `)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return isLoading ? renderLoading() : renderTooltip();
};

export default Tooltip;
