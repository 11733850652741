import { IFileUpload } from 'interfaces/IFileUpload';
import { LocalStorageKeys } from 'constants/localStorageKeys';

export const getUploadedFilesData = (): IFileUpload[] => {
  const data = localStorage.getItem(LocalStorageKeys.uploadedFilesIds);
  return data ? JSON.parse(data) : [];
};

export const addUploadedFileData = (file: IFileUpload): void => {
  const files = [...getUploadedFilesData(), file];
  localStorage.setItem(
    LocalStorageKeys.uploadedFilesIds,
    JSON.stringify(files),
  );
  localStorage.setItem(
    LocalStorageKeys.timeLastUploadedFile,
    JSON.stringify(new Date()),
  );
};

export const removeUploadedFileById = (id?: number | string): void => {
  const files = getUploadedFilesData().filter(file => file.id !== id);
  localStorage.setItem(
    LocalStorageKeys.uploadedFilesIds,
    JSON.stringify(files),
  );
};

export const cleanupUploadedFiles = () =>
  localStorage.removeItem(LocalStorageKeys.uploadedFilesIds);
