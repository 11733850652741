import { gql } from '@apollo/client';

export const PROJECT_LINK_FRAGMENT = gql`
  fragment projectLink on ProjectLink {
    id
    projectId
    pursuitId
    projectType {
      id
      name
    }
  }
`;
