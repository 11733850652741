import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableView from 'components/TableView';
import { SEARCH_TIMS_QUERY } from 'graphql/tims';
import { GraphqlSearchVariables } from 'pages/FindComps';
import { ISearchOrder } from 'interfaces/ISearch';
import { ITimSearchFilters } from 'interfaces/ILeasesSearch';
import { ITIM } from 'interfaces/ITIM';
import { SortDirections } from 'constants/sortDirections';

import { FindCompCriteriaTabs } from 'constants/FindCompTabs';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_PAGES,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { SearchCriteriaType } from '../EditSearchCriteria/types';
import { timsTableHeader } from './timsTableHeader';
import { PAGE_SIZE, INITIAL_PAGE_SIZE } from '../findCompsConstants';

type Props = {
  graphqlSearchVariables: GraphqlSearchVariables;
  isActive: boolean;
  onLoadData: (data?: ITIM[]) => void;
  searchCriteria?: SearchCriteriaType;
  onClickEditSearch: (activeTab?: FindCompCriteriaTabs) => void;
  unitOfMeasurement?: string;
  onSelectedIds?: (ids: Array<number>) => void;
  selectedIds?: Array<number>;
  isSelectAllChecked?: boolean;
  setIsSelectAllChecked?: (value: boolean) => void;
  isIndustrialFlex?: boolean;
  uncheckedIds: number[];
  setUncheckedIds: Dispatch<SetStateAction<number[]>>;
};

export const TIMS_TABLE_ID = 'find-comps-tims-table';

const TimsTableContainer: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    selectedIds,
    onSelectedIds,
    isSelectAllChecked,
    setIsSelectAllChecked,
    uncheckedIds,
    setUncheckedIds,
  } = props;

  const [order, setOrder] = useState<ISearchOrder>({
    field: 'updatedAt',
    direction: SortDirections.desc,
  });
  const [isEULeases, setIsEULeases] = useState(false);
  const [isUKCAUSLeases, setIsUKCAUSLeases] = useState(false);
  const timsOrder = props.graphqlSearchVariables.tims?.order;

  useEffect(() => {
    if (timsOrder) {
      setOrder(timsOrder);
    }
  }, [timsOrder]);
  const getQueryVariables = (page?: number) => {
    const dirtyFilter = props.graphqlSearchVariables.tims.filter;
    const dirtyLeasesFilter = props.graphqlSearchVariables.leases.filter;
    const filter: ITimSearchFilters = {};
    if (!!dirtyFilter && dirtyFilter.targetMarkets) {
      filter.targetMarkets = dirtyFilter.targetMarkets;
    }
    if (!!dirtyFilter && dirtyFilter.newToMarket != undefined) {
      filter.newToMarket = dirtyFilter.newToMarket;
    }
    if (!!dirtyLeasesFilter && dirtyLeasesFilter.tenantBrokers) {
      filter.tenantBrokerCompanies = dirtyLeasesFilter.tenantBrokers.map(
        tenantBroker => ({
          id: tenantBroker.id,
        }),
      );
    }
    if (
      !!dirtyFilter &&
      dirtyFilter.tenantBrokers &&
      !!dirtyFilter.tenantBrokers[0] &&
      !!dirtyFilter.tenantBrokers[0].name
    ) {
      filter.tenantBrokers = dirtyFilter.tenantBrokers;
    }
    if (
      !!dirtyFilter &&
      dirtyFilter.dockConfiguration &&
      !!dirtyFilter.dockConfiguration[0] &&
      !!dirtyFilter.dockConfiguration[0].id
    ) {
      filter.dockConfigurationTypes = dirtyFilter.dockConfiguration;
    }
    if (!!dirtyLeasesFilter && dirtyLeasesFilter.tenants) {
      filter.tenantCompanies = dirtyLeasesFilter.tenants.map(tenant => ({
        id: tenant.id,
      }));
    }
    if (!!dirtyFilter && dirtyFilter.targetSubmarkets) {
      filter.targetSubmarkets = dirtyFilter.targetSubmarkets;
    }
    if (!!dirtyFilter && dirtyFilter.targetPropertySubtypeIds) {
      filter.targetPropertySubtypeIds = dirtyFilter.targetPropertySubtypeIds;
    }
    if (!!dirtyFilter && dirtyFilter.targetPropertyTypeIds) {
      filter.targetPropertyTypeIds = dirtyFilter.targetPropertyTypeIds;
    }
    if (!!dirtyFilter && dirtyFilter.targetThirdPartyPropertyClassIds) {
      filter.targetThirdPartyPropertyClassIds =
        dirtyFilter.targetThirdPartyPropertyClassIds;
    }
    if (!!dirtyFilter && dirtyFilter.timStatusTypes) {
      filter.timStatusTypes = dirtyFilter.timStatusTypes;
    }
    if (!!dirtyFilter && dirtyFilter.tenantIndustries) {
      filter.tenantIndustries = dirtyFilter.tenantIndustries;
    }
    if (!!dirtyFilter && dirtyFilter.fields) {
      filter.fields = dirtyFilter.fields;
    }
    if (!!dirtyFilter && dirtyFilter.expandingContracting) {
      filter.expandingContracting = dirtyFilter.expandingContracting;
    }

    return {
      search: {
        order,
        filter,
        page: {
          page: page,
          size: page === 1 ? INITIAL_PAGE_SIZE : PAGE_SIZE,
        },
      },
    };
  };

  const timsI18N = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.tenantInMarketShortPlural`,
  );
  const measurement =
    isUKCAUSLeases && isEULeases
      ? UnitOfMeasurement.sf
      : isEULeases
      ? UnitOfMeasurement.sm
      : UnitOfMeasurement.sf;

  return (
    <TableView
      id={TIMS_TABLE_ID}
      isActive={props.isActive}
      onClickEditSearch={() =>
        props.onClickEditSearch(FindCompCriteriaTabs.tims)
      }
      hiddenColumns={
        !!props.isIndustrialFlex
          ? undefined
          : [
              'trailerParkingSpaces',
              'driveInDoors',
              'loadingDocks',
              'clearHeightLow',
              'clearHeightHigh',
            ]
      }
      noResultsMessage={translateText(
        `${I18N_AVANT_PROPERTY_PAGES}.findComps.noCompsFound`,
        { comp: timsI18N.toLowerCase() },
      )}
      onChangeOrder={(orderField: string, orderDir: SortDirections) => {
        setOrder({
          field: orderField,
          direction: orderDir,
        });
        return true;
      }}
      order={order}
      columns={timsTableHeader(t, measurement)}
      hasEditButton
      hasSelectItems
      onSelectedIds={onSelectedIds}
      selectedIds={selectedIds}
      setIsSelectAllChecked={setIsSelectAllChecked}
      isSelectAllChecked={isSelectAllChecked}
      uncheckedIds={uncheckedIds}
      setUncheckedIds={setUncheckedIds}
      typeOfData={'TIMs'}
      skip={false}
      query={SEARCH_TIMS_QUERY}
      getQueryVariables={getQueryVariables}
      onCompleted={data => {
        setIsEULeases(false);
        setIsUKCAUSLeases(false);
        data?.['TIMs'].results.forEach(tim => {
          if (
            tim.targetMarket?.country?.areaMeasurement === UnitOfMeasurement.sm
          ) {
            setIsEULeases(true);
          } else {
            setIsUKCAUSLeases(true);
          }
        });
        props.onLoadData(data?.['TIMs'].results as ITIM[]);
      }}
    />
  );
};
export default TimsTableContainer;
