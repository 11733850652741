import CompaniesPropertiesSearchInput from 'components/CompaniesPropertiesSearchInput';
import { SearchInputSize } from 'components/SearchInput/enums';
import { ICompany } from 'interfaces/ICompany';
import { IdName } from 'interfaces/IdName';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { ISearchInputCustomOption } from 'interfaces/ISearchInputCustomOption';
import React from 'react';
import { convertPropertyCompanyToCompany } from 'utils/parsers/convertPropertyCompanyToCompany';
import removeIcon from '../../assets/images/icons/clear-icon-small.svg';
import styles from './CompanyFilter.module.scss';
import classnames from 'classnames';

type Props = {
  aliasPlaceholder?: (suggestionName: string) => string;
  customOption?: ISearchInputCustomOption;
  hasBottomMargin?: boolean;
  inputId?: string;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isLabelExtended?: boolean;
  isSearchIconLeftVisible?: boolean;
  label?: string;
  onChangeText?: (value?: string) => void;
  onClearSelectedSuggestion?: () => void;
  onRemoveItem: (value: IdName) => void;
  onSelectItem: (newValue: IdName) => void;
  placeholder?: string;
  selectedItems: IdName[];
  size?: SearchInputSize;
  searchOnlyCompanies?: boolean;
};

const CompanyFilter: React.FC<Props> = props => {
  const {
    aliasPlaceholder,
    customOption,
    hasBottomMargin,
    inputId,
    isDisabled,
    isMultiple,
    isSearchIconLeftVisible,
    label,
    onChangeText,
    onClearSelectedSuggestion,
    onRemoveItem,
    onSelectItem,
    placeholder,
    selectedItems,
    size,
    searchOnlyCompanies,
    isLabelExtended,
  } = props;

  const hasSelectedItems = !!selectedItems?.length;

  const onClickSuggestion = (value: IPropertyCompany) => {
    const companyParsed = convertPropertyCompanyToCompany(value);
    if (!isMultiple) {
      onSelectItem(companyParsed);
      return;
    }

    if (!selectedItems.find(item => item.id === companyParsed.id)) {
      onSelectItem({ id: companyParsed.id, name: companyParsed.name });
    }
  };

  const onClickSuggestionCompany = (value: ICompany) => {
    if (!isMultiple) {
      onSelectItem(value);
      return;
    }

    if (!selectedItems.find(item => item.id === value.id)) {
      onSelectItem({
        id: value.companyId,
        name: value.name,
        domain: value.domain,
        website: value.website,
      });
    }
  };

  return (
    <div className={styles.container}>
      {label && (
        <div
          className={classnames(styles.label, {
            [styles['label-extended']]: isLabelExtended,
          })}
        >
          {label}
        </div>
      )}
      <div className={styles['search-container']}>
        <CompaniesPropertiesSearchInput
          aliasPlaceholder={aliasPlaceholder}
          clearAfterSuggestionClick={isMultiple}
          customOption={customOption}
          filterOnlyCompanies
          hasBottomMargin={hasBottomMargin}
          initialValue={isMultiple ? undefined : selectedItems?.[0]?.name}
          inputId={inputId}
          isDisabled={isDisabled}
          isEditable={!isDisabled}
          isSearchIconLeftVisible={isSearchIconLeftVisible}
          onChangeText={onChangeText}
          onClearSelectedSuggestion={onClearSelectedSuggestion}
          onClickSuggestionCustom={
            searchOnlyCompanies ? onClickSuggestionCompany : onClickSuggestion
          }
          placeholder={placeholder}
          size={size || SearchInputSize.Small}
          withMarketSwitch={false}
        />
        {isMultiple && hasSelectedItems && (
          <div className={styles.results}>
            {selectedItems &&
              selectedItems.map(item => (
                <div key={item?.id} className={styles['result-item']}>
                  {item?.name}
                  <img
                    className={styles['remove-icon']}
                    src={removeIcon}
                    alt="Clear"
                    onClick={() => {
                      onRemoveItem(item);
                    }}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyFilter;
