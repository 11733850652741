import { IScoop } from 'interfaces/IScoop';
import { uniq } from 'lodash';
import { DOT_LG } from 'constants/placeholders';
import { deepCopy } from 'utils/objects';

const MAXIMUM_PROPERTY_TYPES_LENGTH = 2;
const MAXIMUM_COUNTRIES_LENGTH = 2;

export const getCountries = (scoop: IScoop) => {
  let result = '';
  if (!scoop?.markets) return result;
  const countries = uniq(scoop.markets.map(market => market.country?.name));
  const size = countries.length - 1;
  for (let i = 0; i <= size; i++) {
    if (i > MAXIMUM_COUNTRIES_LENGTH) break;
    result += ` ${countries[i]}`;
    if (i < size && i < MAXIMUM_PROPERTY_TYPES_LENGTH) {
      result += ` ${DOT_LG}`;
    }
  }
  return result;
};

export const getPropertyTypes = (scoop: IScoop) => {
  let result = '';
  if (!scoop?.propertyTypes) return result;
  const orderedPropertyTypes = deepCopy(scoop?.propertyTypes || []).sort(
    (a, b) => (a.id || 0) - (b.id || 0),
  );
  const size = orderedPropertyTypes.length - 1;
  for (let i = 0; i <= size; i++) {
    if (i > MAXIMUM_PROPERTY_TYPES_LENGTH) break;
    result += ` ${orderedPropertyTypes[i].name}`;
    if (i < size && i < MAXIMUM_PROPERTY_TYPES_LENGTH) {
      result += ` ${DOT_LG}`;
    }
  }
  return result;
};
