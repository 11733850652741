import { gql } from '@apollo/client';

export const CREATE_PROPERTY_SET_MUTATION = gql`
  mutation createPropertySet($propertySet: PropertySetInput!) {
    createPropertySet(propertySet: $propertySet) {
      ... on PropertySet {
        id
        name
        fileId
        properties {
          id
        }
        owners {
          id
        }
        createdAt
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const UPDATE_PROPERTY_SET_MUTATION = gql`
  mutation updatePropertySet($propertySet: PropertySetInput!) {
    updatePropertySet(propertySet: $propertySet) {
      ... on PropertySet {
        id
        name
        fileId
        sharedUsersIds
        properties {
          id
        }
        owners {
          id
        }
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const DELETE_PROPERTY_SET_MUTATION = gql`
  mutation deletePropertySet($propertySetId: Int!) {
    deletePropertySet(propertySetId: $propertySetId)
  }
`;
