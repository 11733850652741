import { VictoryBarStyle } from 'components/Graphs/VerticalGroupBarChart/types';
import { colors } from 'constants/colors';
import { roundNumberToNearestTenThousand } from 'utils/formatters/number';

export const CHART_PADDING = {
  top: 70,
  bottom: 40,
  left: 150,
  right: 50,
};

export interface IBarHovered {
  xValue?: number | string;
  barColor?: string;
}

export const DEFAULT_BAR_STYLE: VictoryBarStyle = {
  chart: {
    style: {
      parent: {
        background: colors.ayBlackColor,
        borderRadius: 5,
      },
    },
    padding: CHART_PADDING,
  },
  label: {
    x: 15,
    y: 20,
    backgroundPadding: [{ bottom: 4 }],
    style: { fill: colors.ayWhiteColor, fontSize: 14 },
  },
  legend: {
    height: 20,
    width: 50,
    x: 240,
    y: 10,
    gutter: 20,
    style: {
      border: { stroke: 'none' },
      labels: { fill: colors.ayWhiteColor, fontSize: 12 },
    },
  },
  xAxis: {
    style: {
      axis: { stroke: 'none' },
      tickLabels: {
        fontSize: 10,
        fill: colors.ayWhiteColor,
        cursor: 'pointer',
      },
    },
  },
  yAxis: {
    style: {
      axis: { stroke: 'none' },
      tickLabels: {
        fontSize: 10,
        fill: colors.ayWhiteColor,
        fontStyle: 'normal',
      },
    },
  },
  bar: {
    style: {
      data: {
        fill: colors.primaryColor500,
      },
      labels: { fill: colors.ayWhiteColor, fontSize: 10 },
    },
    width: 32,
    cornerRadius: { top: 4, bottom: 4 },
  },
};

export const getYAxisValuesFromTotal = (
  highestValue: number,
  numberOfAxis = 4,
  roundFn?: (value: number) => number,
) => {
  const firstLevel = 1 / numberOfAxis;
  const yAxisValues = [0];
  for (let i = 1; i <= numberOfAxis; i++) {
    const partialValue = firstLevel * i;
    yAxisValues.push(
      roundFn
        ? roundFn(highestValue * partialValue)
        : roundNumberToNearestTenThousand(highestValue * partialValue),
    );
  }
  return yAxisValues;
};
