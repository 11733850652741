import FormFooter from 'components/FormFooter';
import Loader from 'components/HistoricalPerformance/Loader';
import { IHistoricalPerformanceTable } from 'interfaces/IHistoricalPerformance';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { AVAILABILITY_VACANCY_TABLE } from '../constants';
import EditTable from '../EditTable';
import {
  getAvailabilityVacancyCellsFormatters,
  availabilityVacancyHeader,
  getAvailabilityVacancyCellsLabels,
  availabilityCellsFocusOrder,
} from './tableData';
import {
  generateAvailabilityFooterCells,
  generateAvailabilityPlaceholder,
  hasInvalidAvailabilityVacancyData,
  prepareAvailabilityVacancyToSubmit,
} from './utils';
import { ASSET_PERFORMANCE_MUTATION } from 'graphql/historicalPerformance/mutations';
import { ErrorLogger } from 'services/ErrorLogger';
import { ASSET_PERFORMANCES_TABLE_PROPERTY } from 'graphql/historicalPerformance/queries';
import { isEqual } from 'lodash';
import { getRefetchQueries } from '../utils';
import { IdName } from 'interfaces/IdName';
import NotificationMessage from 'components/NotificationMessage';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_USER_SETTINGS_LABEL_PATH,
  I18N_AVANT_PROPERTY_HISTORICAL_PERFORMANCE_LABEL_PATH,
} from 'constants/i18n';
import { PropertyTypes } from 'constants/propertyTypes';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { LeaseRentType } from 'constants/leases';
import { TimeMeasurement } from 'constants/timeMeasurement';

type Props = {
  companyId?: number;
  marketsFilter?: IdName[];
  onClose: () => void;
  propertyId?: number;
  propertyType?: PropertyTypes;
  propertySetId?: number;
  setHasUnsavedChanges: (hasChanges: boolean, shouldResetData: boolean) => void;
  shouldResetData?: boolean;
  buildingSize?: number;
  buildingSizeInKw?: number;
  unitOfMeasurement: string;
  currencySymbol?: string;
};

const AvailabilityVacancy: React.FC<Props> = ({
  companyId,
  marketsFilter,
  onClose,
  propertyId,
  propertyType,
  propertySetId,
  setHasUnsavedChanges,
  shouldResetData,
  buildingSize,
  buildingSizeInKw,
  unitOfMeasurement,
  currencySymbol,
}) => {
  const [tableData, setTableData] = useState<IHistoricalPerformanceTable[]>([]);
  const [initialData, setInitialData] = useState<IHistoricalPerformanceTable[]>(
    [],
  );
  const [error, setError] = useState({
    message: '',
    isVisible: false,
  });

  const refetchQueries =
    (companyId || propertyId) &&
    getRefetchQueries(companyId, propertyId, marketsFilter, propertySetId);

  const {
    data: availabilityVacancyData,
    loading: isLoadingAvailability,
  } = useQuery(ASSET_PERFORMANCES_TABLE_PROPERTY, {
    variables: { propertyId },
    skip: !propertyId,
  });

  const setDefaultValues = (item: IHistoricalPerformanceTable) => {
    const {
      vacantSfShell,
      availableSfShell,
      vacantKwTurnkey,
      availableKwTurnkey,
    } = item || {};
    if (item?.rentType == null) {
      if (vacantSfShell != null || availableSfShell != null) {
        item.rentType = LeaseRentType.NNN;
        item.timeMeasurement = TimeMeasurement.ANNUAL;
      } else if (vacantKwTurnkey != null || availableKwTurnkey != null) {
        item.rentType = LeaseRentType.MG;
        item.timeMeasurement = TimeMeasurement.MONTHLY;
      }
    }
  };

  const [
    saveAvailabilityVacancy,
    { loading: isSavingAvailability },
  ] = useMutation(ASSET_PERFORMANCE_MUTATION, {
    onCompleted: onClose,
    onError: error => {
      if (error) {
        if (error.message.includes('asset performance list cannot be empty')) {
          setError({
            message: translateText(
              `${I18N_AVANT_PROPERTY_HISTORICAL_PERFORMANCE_LABEL_PATH}.noAsset`,
            ),
            isVisible: true,
          });
        }
        ErrorLogger.log(
          error.message,
          JSON.stringify(ASSET_PERFORMANCE_MUTATION),
        );
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      ...(refetchQueries || []),
      {
        query: ASSET_PERFORMANCES_TABLE_PROPERTY,
        variables: { propertyId },
      },
    ],
  });

  useEffect(() => {
    if (availabilityVacancyData?.assetPerformances) {
      setInitialData(availabilityVacancyData?.assetPerformances);
      setTableData(availabilityVacancyData?.assetPerformances);
    }
  }, [availabilityVacancyData]);

  useEffect(() => {
    setHasUnsavedChanges(!isEqual(initialData, tableData), false);
    // eslint-disable-next-line
  }, [tableData]);

  useEffect(() => {
    if (shouldResetData) {
      setTableData(initialData);
      setHasUnsavedChanges(false, false);
    }
    // eslint-disable-next-line
  }, [shouldResetData]);

  const submitAvailabilityVacancy = () => {
    if (propertyId && (buildingSize || buildingSizeInKw)) {
      const errorMessage = hasInvalidAvailabilityVacancyData(
        buildingSize,
        unitOfMeasurement,
        tableData,
      );
      errorMessage.push(
        ...hasInvalidAvailabilityVacancyData(
          buildingSizeInKw,
          UnitOfMeasurement.kw,
          tableData,
        ),
      );
      if (errorMessage.length > 0) {
        setError({
          isVisible: true,
          message: errorMessage[0],
        });
      } else {
        setHasUnsavedChanges(false, false);
        saveAvailabilityVacancy({
          variables: {
            items: prepareAvailabilityVacancyToSubmit(
              initialData,
              propertyId,
              tableData,
            ),
          },
        });
      }
    }
  };

  if (isLoadingAvailability) return <Loader />;

  return (
    <>
      <EditTable
        id={AVAILABILITY_VACANCY_TABLE}
        data={tableData}
        columns={availabilityVacancyHeader(propertyType)}
        generateRowPlaceholder={generateAvailabilityPlaceholder}
        generateCellsFooter={data =>
          generateAvailabilityFooterCells(data, unitOfMeasurement, propertyType)
        }
        updateData={(data, itemUpdated) => {
          setDefaultValues(itemUpdated as IHistoricalPerformanceTable);
          setTableData(data);
        }}
        cellsLabels={getAvailabilityVacancyCellsLabels({
          unitOfMeasurement,
          currencySymbol,
          propertyType,
        })}
        cellsFormatters={getAvailabilityVacancyCellsFormatters(
          buildingSize,
          buildingSizeInKw,
        )}
        cellsFocusOrder={availabilityCellsFocusOrder}
      />

      <FormFooter
        onSubmit={submitAvailabilityVacancy}
        onCancel={onClose}
        submitButtonLabel={
          isSavingAvailability
            ? `${translateText(
                `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`,
              )}...`
            : translateText(`${I18N_USER_SETTINGS_LABEL_PATH}.saveChanges`)
        }
        isSubmitDisabled={isSavingAvailability}
      />

      <NotificationMessage
        show={error.isVisible}
        text={error.message}
        isSuccess={false}
        onClose={() => setError({ ...error, isVisible: false })}
        dismissOnHover={false}
        closeOnClick
      />
    </>
  );
};

export default AvailabilityVacancy;
