import React from 'react';
import { getPricingTypeOptions } from './utils';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import styles from '../../FormSection.module.scss';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { translateText } from 'utils/i18n';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string;
  required?: boolean;
  property?: IProperty;
  onChange: (field: string, value: any) => void;
}

const PricingType: React.FC<Props> = ({
  value,
  onChange,
  required = false,
}) => {
  const { t } = useTranslation();
  const options = getPricingTypeOptions(t);

  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText('avantPlatform.attributes.property.label.pricing')}
    >
      <ChoiceInput
        data={options}
        onChange={item => onChange('pricing', item?.id)}
        selectedItem={{ id: value, name: value }}
        containerWrapperClassName={styles['radio-input-container']}
      />
    </FormControl>
  );
};

export default PricingType;
