import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { IProperty } from 'interfaces/IProperty';

export enum ComparativeCardTheme {
  main = 'main',
  secondary = 'secondary',
  tenantProspect = 'tenant-prospect',
}

export type Column = {
  columnTheme: ComparativeCardTheme;
  property: IProperty;
  completeCompetitiveProperties?: IProperty[];
  onLoadComponent?: () => void;
  onDataChanges?: () => void;
  toggleOptions?: LeaseToggleOptions;
};
