import { translateText } from 'utils/i18n';
import { I18N_AVANT_PLATFORM_LEASE_LABEL_PATH } from 'constants/i18n';

export const securityDepositTypes = [
  {
    id: 1,
    name: translateText(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.cash`),
  },
  {
    id: 2,
    name: translateText(
      `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.letterOfCredit`,
    ),
  },
];
