import { PropertyTypeIds } from 'constants/propertyTypes';
import { IProperty } from 'interfaces/IProperty';
import { IdName } from 'interfaces/IdName';

const commonPropertyTypes = [
  PropertyTypeIds.office,
  PropertyTypeIds.industrial,
  PropertyTypeIds.flexIndustrial,
  PropertyTypeIds.healthcare,
];

const isValidPropertyType = (
  possiblePropertyTypeIds: number[],
  property: IProperty,
) =>
  possiblePropertyTypeIds.some(typeId => typeId === property.propertyType?.id);

export const areBuildingSectionChartsVisible = (property: IProperty) =>
  isValidPropertyType(
    [
      ...commonPropertyTypes,
      PropertyTypeIds.multifamily,
      PropertyTypeIds.dataCenter,
      PropertyTypeIds.lifeScience,
    ],
    property,
  );

export const isLeasingSectionVisible = (property: IProperty) =>
  isValidPropertyType(
    [
      ...commonPropertyTypes,
      PropertyTypeIds.lifeScience,
      PropertyTypeIds.retail,
      PropertyTypeIds.dataCenter,
    ],
    property,
  );

export const isCompSetTabVisible = (property: IProperty) =>
  isValidPropertyType(
    [...commonPropertyTypes, PropertyTypeIds.multifamily],
    property,
  );

export const isTenantProspectTabVisible = (property: IProperty) =>
  isValidPropertyType(commonPropertyTypes, property);

export const getMissingFields = (object: any, keys: IdName[]) => {
  return keys.reduce((strings: string[], current: IdName) => {
    if (!object[current.id]?.id) {
      strings.push(current.name);
    }
    return strings;
  }, []);
};

export const isPerformanceSectionVisible = (property: IProperty) =>
  isValidPropertyType(
    [...commonPropertyTypes, PropertyTypeIds.lifeScience],
    property,
  );

export const isSaleSectionVisible = (property: IProperty) =>
  isValidPropertyType(
    [
      ...commonPropertyTypes,
      PropertyTypeIds.multifamily,
      PropertyTypeIds.lifeScience,
      PropertyTypeIds.landAndDevelopment,
      PropertyTypeIds.retail,
      PropertyTypeIds.dataCenter,
      PropertyTypeIds.hospitality,
    ],
    property,
  );

export const isBuildingAttributeSectionVisible = (property: IProperty) =>
  property.propertyType?.id !== PropertyTypeIds.landAndDevelopment;
