export const TOOLTIP_RECT = {
  x: 0,
  y: -32,
  width: 100,
  height: 24,
  borderRadius: 4,
};

export const TOOLTIP_TEXT = {
  y: -16,
  paddingLeft: 8,
};
