import React from 'react';
import classNames from 'classnames';
import styles from '../ComparativeColumns.module.scss';

export type Props = {
  children: React.ReactNode;
  fullWidth?: boolean;
  wrapperClassName?: string;
};

const InformationRow: React.FC<Props> = ({
  children,
  fullWidth,
  wrapperClassName,
}) => {
  return (
    <div
      className={classNames(styles['information-row'], wrapperClassName, {
        [styles['full-width']]: fullWidth,
      })}
    >
      {children}
    </div>
  );
};

export default InformationRow;
