import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useApolloClient } from '@apollo/client';
import { Container } from 'components/@codelitt/ay-design-library';
import NotificationMessage from 'components/NotificationMessage';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  GET_PROPERTY_QUERY,
  DELETE_PROPERTY_MUTATION,
  UPDATE_PROPERTY_MUTATION,
  GET_PROPERTY_QUERY_WITHOUT_COMMENTS,
} from 'graphql/property';
import LoadingMessage from 'components/LoadingMessage';
import { ErrorLogger } from 'services/ErrorLogger';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import PropertyForm from '../PropertyForm';
import EditCompForm from '../EditCompForm';
import { buildPropertyPayload } from '../PropertyCreateForm/buildPropertyPayload';

interface Props {
  propertyId: string | number | undefined;
  onCancel: () => void;
  onUpdated?: () => void;
  onDeleted?: () => void;
  title?: string;
  showDeleteButton?: boolean;
  redirectToShowCompsPage?: boolean;
}

const DEFAULT_ERROR_MESSAGE = 'Unexpected error updating the property';

const PropertyEditForm: React.FC<Props> = props => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState<string>('');

  const { data, loading } = useQuery<{ property: IProperty }>(
    GET_PROPERTY_QUERY_WITHOUT_COMMENTS,
    {
      fetchPolicy: 'network-only',
      variables: { id: props.propertyId },
      skip: !props.propertyId,
    },
  );

  const getRefetchQuery = () => {
    return [
      {
        query: GET_PROPERTY_QUERY,
        variables: { id: props.propertyId },
      },
    ];
  };

  const showMessageError = (message = DEFAULT_ERROR_MESSAGE) => {
    setMessageError(message);
    setShowError(true);
  };

  const updateProperty = async (
    input: IPropertyInput,
    onSucessCallback: Function,
  ) => {
    try {
      setIsUpdating(true);

      const property = buildPropertyPayload(input);

      const { data } = await client.mutate<{
        updateProperty: IProperty;
      }>({
        mutation: UPDATE_PROPERTY_MUTATION,
        variables: {
          property,
          opexChangedManually: property.expense?.taxAndOpexChangedManually,
        },
        refetchQueries: getRefetchQuery(),
      });

      if (data?.updateProperty?.id) {
        setTimeout(() => props.onUpdated?.());
        onSucessCallback(data?.updateProperty?.id);
      } else {
        ErrorLogger.log((data?.updateProperty as any).message);
        showMessageError((data?.updateProperty as any).message);
      }
    } catch (e) {
      ErrorLogger.log(e as any, DEFAULT_ERROR_MESSAGE);
      showMessageError();
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) {
    return (
      <Container>
        <LoadingMessage />
      </Container>
    );
  }

  if (!data?.property) {
    return null;
  }

  return (
    <>
      <EditCompForm
        comp={data.property}
        deleteButtonText={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.deleteProperty`)}
        deleteErrorMessage={t(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.unableToDeleteProperty`,
        )}
        deleteQueryParams={{
          query: DELETE_PROPERTY_MUTATION,
          paramName: 'id',
          resultName: 'deleteProperty',
        }}
        headerModal={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.deleteProperty`)}
        paragraphModal={[
          t(`${I18N_PLATFORM_COMMON_WORD_PATH}.aboutToDeleteProperty`),
          t('avantProperties.texts.common.askToProceed'),
        ]}
        onDeleted={props.onDeleted}
        showDeleteButton={props.showDeleteButton}
        title={props.title}
        compForm={
          <PropertyForm
            property={data.property as IPropertyInput}
            onSubmit={updateProperty}
            onCancel={props.onCancel}
            submitLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.update`)}
            isLoading={isUpdating}
          />
        }
      />

      <NotificationMessage
        show={showError}
        text={messageError}
        onClose={() => {
          setShowError(false);
        }}
      />
    </>
  );
};

export default PropertyEditForm;
