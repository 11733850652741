import { IRadioItem } from 'components/Inputs/RadioInput';

export const getTIAllowanceSuggestions = (
  currencySymbol: string,
): IRadioItem[] => [
  {
    label: `${currencySymbol}0`,
    value: 0,
  },
  {
    label: `${currencySymbol}50`,
    value: 50,
  },
  {
    label: `${currencySymbol}70`,
    value: 70,
  },
  {
    label: `${currencySymbol}100`,
    value: 100,
  },
];
