import { gql } from '@apollo/client';

export const INDUSTRIES_QUERY = gql`
  query industries($search: IndustrySearchInput) {
    industries(search: $search) {
      id
      name
      category
    }
  }
`;
