import React, { ChangeEvent, useRef, useState } from 'react';
import styles from '../CompanyForm.module.scss';
import Button from 'components/Button/new';
import UploadPhoto from 'components/UploadPhoto';
import classnames from 'classnames';

type Props = {
  logoUrl?: string;
  setCompanyLogoRemoved?: (removed: boolean) => void;
  setCompanyLogoToUpload?: (file?: File) => void;
};

const CompanyLogo: React.FC<Props> = ({
  logoUrl,
  setCompanyLogoRemoved,
  setCompanyLogoToUpload,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [logoUrlPath, setLogoUrlPath] = useState(logoUrl);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (file) {
      setLogoUrlPath(URL.createObjectURL(file));
      setCompanyLogoToUpload?.(file);
    }
  };

  const removeFile = () => {
    setCompanyLogoToUpload?.(undefined);
    setLogoUrlPath(undefined);
    setCompanyLogoRemoved?.(true);
    if (inputRef?.current?.id) {
      (document.getElementById(inputRef?.current?.id) as any).value = null;
    }
  };

  const renderButtons = () =>
    logoUrlPath && (
      <div className={styles['action-buttons']}>
        <Button
          size="s"
          type="supportive-neutral"
          onClick={() => inputRef?.current?.click()}
          icon="edit"
          wrapperClassName={classnames(styles.button, styles.edit)}
        />
        <Button
          size="s"
          type="supportive-neutral"
          onClick={removeFile}
          icon="delete"
          wrapperClassName={styles.button}
        />
      </div>
    );

  return (
    <UploadPhoto
      inputRef={inputRef}
      imageSrc={logoUrlPath}
      handleFileChange={handleFileChange}
      placeholderIcon="company"
      hoveredContent={renderButtons()}
    />
  );
};

export default CompanyLogo;
