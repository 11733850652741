import { EMPTY_VALUE } from 'components/AgencyAssignments/constants';
import {
  I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { SEARCH_LEASES_CLICKTHROUGHS_QUERY } from 'graphql/leases';
import { SEARCH_PROPERTIES_QUERY } from 'graphql/property';
import { SEARCH_SALES_QUERY } from 'graphql/sales';
import { IPropertySetCompositionMetadata } from 'interfaces/IPropertySetMetadata';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import { abbreviateNumber } from 'utils/formatters/number';
import { translateText } from 'utils/i18n';
import { capitalize } from 'lodash';

export const PROPERTIES_POPUP_INIT = {
  propertiesFilter: undefined,
  hideAction: undefined,
  isVisible: false,
  popupFilter: undefined,
  popupSubtitle: '',
  popupTitle: '',
};

export const getGraphqlQuery = (propertiesPopup: ILargestPropertiesPopup) => {
  let graphqlQuery = SEARCH_PROPERTIES_QUERY;
  graphqlQuery = propertiesPopup?.isLeases
    ? SEARCH_LEASES_CLICKTHROUGHS_QUERY
    : graphqlQuery;

  graphqlQuery = propertiesPopup?.isSales ? SEARCH_SALES_QUERY : graphqlQuery;
  return graphqlQuery;
};

export const DONUT_GRAPH_DATA_LIMIT = 5;

export const parseInfoBoxesData = (
  {
    numberOfBuildings,
    avgBuildingSize,
    avgAge,
    avgFloorPlate,
    totalSf,
  }: IPropertySetCompositionMetadata,
  unitOfMeasurement: string,
) => {
  return [
    {
      id: 'number-of-buildings-comp',
      details: [
        {
          title: capitalize(
            translateText(`${I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH}.numberOf`, {
              name: translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.building_plural`,
              ),
            }),
          ),
          value: numberOfBuildings ? numberOfBuildings.toString() : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'total-size',
      details: [
        {
          title: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.totalUnitOfMeasurement`,
            { unitOfMeasurement },
          ),
          value: totalSf
            ? `${abbreviateNumber(totalSf) as string} ${unitOfMeasurement}`
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'avg-building-size',
      details: [
        {
          title: translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.avgBuildingSize`,
          ),
          value: avgBuildingSize
            ? `${abbreviateNumber(
                avgBuildingSize,
              ) as string} ${unitOfMeasurement}`
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'avg-floor-plate',
      details: [
        {
          title: capitalize(
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.avgFloorPlate`),
          ),
          value: avgFloorPlate
            ? `${abbreviateNumber(
                avgFloorPlate,
              ) as string} ${unitOfMeasurement}`
            : EMPTY_VALUE,
        },
      ],
    },
    {
      id: 'avg-age',
      details: [
        {
          title: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.avgAge`),
          value: avgAge ? avgAge.toString() : EMPTY_VALUE,
        },
      ],
    },
  ];
};
