import React, { FC } from 'react';

import StaticDonutGraph from 'components/Graphs/StaticDonutGraph';
import Legend from 'components/Graphs/elements/Legend';
import { colors } from 'constants/colors';
import { DONUT_SIZE } from 'components/Graphs/DonutGraph/graphConstants';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { formatNumberPercentage } from 'utils/formatters/number';
import { translateText } from 'utils/i18n';

import styles from './AvailabilityDonutGraph.module.scss';
import { AvailabilityDonutGraphProps } from './types';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

const AVAILABILITY_DONUT_GRAPH_ID = 1;

const AvailabilityDonutGraph: FC<AvailabilityDonutGraphProps> = ({
  totalAvailability,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles['top-bar']}>
        <Legend
          className={styles['title-legend']}
          text={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.available`)}
        />
      </div>
      <div className={styles['graph-container']}>
        <Legend
          className={styles['availability-legend']}
          text={formatNumberPercentage(totalAvailability, 2)}
        />
        <StaticDonutGraph
          donutId={AVAILABILITY_DONUT_GRAPH_ID}
          data={[
            {
              totalSize: totalAvailability,
              unitOfMeasurement: UnitOfMeasurement.sf,
              color: colors.primaryColor500,
              order: 1,
            },
            {
              totalSize: 100 - totalAvailability,
              unitOfMeasurement: UnitOfMeasurement.sf,
              color: colors.uiColor700,
              order: 2,
            },
          ]}
          size={DONUT_SIZE.EXTRA_SMALL}
        />
      </div>
    </div>
  );
};

export { AvailabilityDonutGraph };
