import { isUndefined } from 'lodash';
import { IdName } from 'interfaces/IdName';
import { IProperty } from 'interfaces/IProperty';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { PropertyClass } from 'constants/propertyClass';
import { COMMA, DOT_LG } from 'constants/placeholders';
import { pluralizeText } from 'utils/formatters/string';
import { CountryNames } from 'constants/countryCodes';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';
import { IMarket } from 'interfaces/IMarket';
import { t } from 'i18next';

export const getPropertyAddress = (
  property: IProperty | undefined,
): string | null =>
  property?.displayAddress || property?.primaryAddress || null;

export const getPropertyFullAddress = (property: IProperty | undefined) => {
  const addressParts = [
    `${property?.primaryAddress},`,
    `${property?.city},`,
    property?.state,
    property?.postalCode,
  ];
  return addressParts.filter(Boolean).join(' ');
};
export const getPropertyCity = (
  property: IProperty | undefined,
): string | null => property?.city || property?.city || null;

export const getPropertyCoordinates = (
  property: IProperty | undefined,
): { latitude: number | null; longitude: number | null } => {
  const latitude = property?.latitudeDisplay || property?.latitude || null;
  const longitude = property?.longitudeDisplay || property?.longitude || null;
  return { latitude, longitude };
};

export const getPropertyMarkets = (
  property: any | undefined,
  placeholder: string,
) =>
  [
    property?.market?.name,
    property?.submarket?.name,
    property?.micromarket?.name,
  ]
    .filter(Boolean)
    .join(placeholder === COMMA ? `${placeholder} ` : ` ${placeholder} `);

export const isTrophyClass = (c: SearchOptionsType): boolean => {
  return c.name?.includes(PropertyClass.Trophy);
};

export const isRegularClass = (c: SearchOptionsType): boolean => {
  return !isTrophyClass(c);
};

/**
 * Format a class name, with the option to remove the "+" and "-" chars from it.
 * A+ -> A
 * A- -> A
 * A  -> A
 */
export const formatPropertyClass = (
  propertyClass?: IdName | null,
  shouldReplacePlusMinus?: boolean,
  shouldAddPrefix?: boolean,
): string | null => {
  if (!propertyClass?.name) return null;

  const { name } = propertyClass;
  const prefix =
    (shouldAddPrefix || isUndefined(shouldAddPrefix)) &&
    isRegularClass(propertyClass)
      ? `${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.class`)} `
      : '';

  return shouldReplacePlusMinus
    ? prefix + name.replace(/[+-]/g, '')
    : prefix + name;
};

export const getPropertyClassAndTypeText = (
  property: IProperty | undefined,
) => {
  const { propertySubtype, propertyType, propertyClass } = property || {};
  const isTypeAndSubtypeEqual =
    propertyType?.name.toLowerCase() === propertySubtype?.name?.toLowerCase();

  return [
    formatPropertyClass(propertyClass),
    propertyType?.name,
    isTypeAndSubtypeEqual ? null : propertySubtype?.name,
  ]
    .filter(Boolean)
    .join(` ${DOT_LG} `);
};

export const getMultiplePropertiesAddressesShort = (
  properties: IProperty,
  preselectedProperty?: IProperty | undefined,
): string => {
  const propertyAddressCandidate = preselectedProperty || properties[0];
  const propertyAddress = getPropertyAddress(propertyAddressCandidate);
  if (properties.length === 1) return propertyAddress || '';

  const totalOtherProperties = properties.length - 1;
  const propertiesLabel = pluralizeText(
    totalOtherProperties,
    'property',
    'properties',
  );
  return `${propertyAddress} +${totalOtherProperties} other ${propertiesLabel}`;
};

export const getMultipleMarketsNamesShort = (
  markets: IMarket,
  preselectedMarket?: IMarket | undefined,
): string => {
  const marketName = preselectedMarket?.name || markets[0].name;
  if (markets.length === 1) return marketName || '';

  const totalOtherMarkets = markets.length - 1;
  const marketsLabel = pluralizeText(
    totalOtherMarkets,
    t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`).toLowerCase(),
    t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market_plural`).toLowerCase(),
  );
  return `${marketName} +${totalOtherMarkets} other ${marketsLabel}`;
};

export const formatAddressDetails = (country: string): Array<string> | null => {
  if (!country) return null;

  switch (country) {
    case CountryNames.UK: {
      return ['city', 'postalCode'];
    }

    case CountryNames.DE: {
      return ['postalCode', 'city'];
    }

    default:
      return ['city', 'state', 'postalCode'];
  }
};
