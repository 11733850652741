import React from 'react';
import { IAttachmentInput } from 'interfaces/inputs/IAttachmentInput';
import ActionButton from 'components/ActionButton';
import { colors } from 'constants/colors';
import AttachmentThumbnail from '../AttachmentThumbnail';
import styles from './AttachmentPreview.module.scss';

interface AttachmentPreviewProps {
  attachment: IAttachmentInput;
  onDownload: () => void;
  onEdit?: () => void;
  onRemove: () => void;
  showLabel?: boolean;
}

const AttachmentPreview = ({
  attachment,
  onDownload,
  onEdit,
  onRemove,
  showLabel,
}: AttachmentPreviewProps) => {
  const { file, document, metadata } = attachment;

  return (
    <div className={styles['attachment-thumbnail-container']}>
      <AttachmentThumbnail attachment={attachment} />

      <div className={styles['attachment-thumbnail-footer']}>
        {showLabel && (
          <div className={styles['attachment-info-container']}>
            <span className={styles['attachment-name']}>
              {metadata?.name || file?.name || document?.name}
            </span>
            <span className={styles['attachment-category']}>
              {metadata?.category?.name || document?.category?.name || ''}
            </span>
          </div>
        )}

        <div className={styles['action-buttons-container']}>
          {onDownload && (
            <ActionButton
              buttonClassName={styles['action-button']}
              onClick={onDownload}
              icon="download"
            />
          )}
          {onEdit && (
            <ActionButton
              buttonClassName={styles['action-button']}
              onClick={onEdit}
              icon="edit"
            />
          )}
          {onRemove && (
            <ActionButton
              buttonClassName={styles['action-button']}
              onClick={onRemove}
              icon="delete"
              iconColor={colors.ayBlackColor}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AttachmentPreview;
