import { useCallback, useEffect, useState } from 'react';
import { LeaseTransactionType } from 'constants/leases';
import {
  applyPercentageFromNumber,
  removeCommaFromNumber,
} from 'utils/formatters/number';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { SIZE_GROWTH_OPTIONS } from 'constants/expandingContracting';

interface Props {
  leaseInput: ILeaseInput;
  unitOfMeasurement: string;
  updateLeaseProperty: (property: string, propertyValue: any) => void;
}

export const useCalculateSizeGrowth = ({
  leaseInput,
  unitOfMeasurement,
  updateLeaseProperty,
}: Props) => {
  const [changedSizeGrowth, setChangedSizeGrowth] = useState<boolean>(false);
  const [changedTransactionType, setChangedTransactionType] = useState<boolean>(
    false,
  );
  const [triggeredCalculation, setTriggeredCalculation] = useState<boolean>(
    false,
  );

  const isSqm = unitOfMeasurement === 'sqm';

  const priorSizeField = leaseInput[isSqm ? 'priorSizeMt' : 'priorSize'];
  const sizeField = leaseInput[isSqm ? 'sizeMt' : 'size'];

  const calculateExpandingContracting = useCallback(() => {
    // changing transaction type on click, prevent changing size growth initially in case if transaction type initially = expansion
    const enableSetGrowthSizeByTransactionType =
      leaseInput?.transactionType === LeaseTransactionType.EXPANSION &&
      changedTransactionType;

    // when size/prior sizes have changed and one of the sizes are empty - set growth size to null
    const enableClearGrowthSize =
      triggeredCalculation && (!sizeField || !priorSizeField);

    // enable calculation when size/prior sizes are not empty and user did not toggle growth size manually
    const enableCalculateBySizePercentage =
      sizeField && priorSizeField && !changedSizeGrowth && triggeredCalculation;

    if (enableSetGrowthSizeByTransactionType) {
      updateLeaseProperty('expandingContracting', SIZE_GROWTH_OPTIONS[0]);
      return;
    }
    // stop running calculation when growth size was changed manually
    if (changedSizeGrowth) return;

    if (enableClearGrowthSize) {
      updateLeaseProperty('expandingContracting', null);
    }
    if (enableCalculateBySizePercentage) {
      const convertedSize = Number(
        removeCommaFromNumber(String(sizeField ?? '')),
      );
      const convertedPriorSize = Number(
        removeCommaFromNumber(String(priorSizeField ?? '')),
      );
      const priorSizePercentage = applyPercentageFromNumber(
        convertedPriorSize,
        10,
      );
      const isExpanding =
        convertedPriorSize + priorSizePercentage <= convertedSize;

      const isContracting =
        convertedPriorSize - priorSizePercentage >= convertedSize;

      if (isExpanding) {
        updateLeaseProperty('expandingContracting', SIZE_GROWTH_OPTIONS[0]);
      } else if (isContracting) {
        updateLeaseProperty('expandingContracting', SIZE_GROWTH_OPTIONS[1]);
      } else {
        updateLeaseProperty('expandingContracting', null);
      }
    }
  }, [
    leaseInput?.transactionType,
    sizeField,
    priorSizeField,
    changedTransactionType,
    triggeredCalculation,
    changedSizeGrowth,
    updateLeaseProperty,
  ]);

  const handleChangedSizeGrowth = () => {
    setChangedSizeGrowth(true);
  };

  const handleTriggeredCalculation = () => {
    setTriggeredCalculation(true);
  };

  const handleChangeTransactionType = () => {
    setChangedTransactionType(true);
  };

  useEffect(() => {
    calculateExpandingContracting();
  }, [calculateExpandingContracting]);

  return {
    handleChangedSizeGrowth,
    handleTriggeredCalculation,
    handleChangeTransactionType,
  };
};
