import React from 'react';
import classnames from 'classnames';
import { map, capitalize } from 'lodash';
import { DOT } from 'constants/placeholders';
import UserMailToLink from 'components/UserMailToLink';
import { IUpdateSummary } from './constants';
import styles from './UpdateSummary.module.scss';
import { getDate, getData, getUpdateSummaryData } from './utils';

interface Props {
  data: IUpdateSummary;
  wrapperClassName?: string;
}

const UpdateSummary: React.FC<Props> = ({ wrapperClassName, data }) => (
  <ul className={classnames(styles.list, wrapperClassName)}>
    {map(getData(data), (value, key) =>
      value?.date ? (
        <li key={key}>
          <dl className={styles.details}>
            <dt>{capitalize(key)}</dt>
            <dd>{getDate(value?.date!)}</dd>
            <dd>
              {value?.user && <span> {DOT} </span>}
              <UserMailToLink user={value?.user!} />
            </dd>
          </dl>
        </li>
      ) : null,
    )}
  </ul>
);

export { getUpdateSummaryData };
export default UpdateSummary;
