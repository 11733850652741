import React, { useRef, useState } from 'react';
import ModalWrapper from 'components/ModalWrapper';
import { IScoop } from 'interfaces/IScoop';
import { ActivityCardHeader } from 'components/ActivityCard';
import { IProperty } from 'interfaces/IProperty';
import { IUser } from 'interfaces/IUser';
import { ApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  defaultToIsoFormat,
  formatDate,
  getRelativeDate,
} from 'utils/formatters/date';
import dateFormats from 'constants/dateFormats';
import { DOT } from 'constants/placeholders';
import locations from 'routes';
import ScoopCompanies from 'components/ScoopCompanies';
import { GET_SCOOP_COMMENTS_QUERY } from 'graphql/scoops/queries';
import { IScoopComment } from 'interfaces/IScoopComment';
import ScoopComment from 'components/ScoopComment';
import CommentInput from 'components/CommentInput';
import { ErrorLogger } from 'services/ErrorLogger';
import {
  CREATE_SCOOP_COMMENT,
  DELETE_SCOOP_COMMENT,
  UPDATE_SCOOP_COMMENT,
} from 'graphql/scoops/mutations';
import styles from './ScoopCommentsModal.module.scss';
import ScoopCommentsModalActions from './ModalActions';
import DeleteCommentPopup from './DeleteCommentPopup';
import { authContext } from 'contexts/AuthContext';
import Linkify from 'linkify-react';

const CREATE_COMMENT_SCOOP_ERROR = 'Unable add a comment';
const UPDATE_COMMENT_SCOOP_ERROR = 'Unable to edit the comment';
const DELETE_COMMENT_SCOOP_ERROR = 'Unable to delete the comment';

interface Props {
  date: string;
  onChangeData: () => void;
  onEditClick?: () => void;
  onModalToggle: (isOpen: boolean) => void;
  onShareClick?: () => void;
  property?: IProperty;
  scoop: IScoop;
  scoopUser?: IUser;
  client?: ApolloClient<Object>;
}

const ScoopCommentsModal: React.FC<Props> = props => {
  const [commentToEdit, setCommentToEdit] = useState<IScoopComment | null>(
    null,
  );
  const [commentIdToDelete, setCommentIdToDelete] = useState<number | null>(
    null,
  );

  const contentRef = useRef<HTMLDivElement>(null);
  const {
    onChangeData,
    onEditClick,
    onModalToggle,
    onShareClick,
    property,
    scoop,
    scoopUser,
  } = props;
  const date = getRelativeDate(defaultToIsoFormat(props.date));
  const time = formatDate(props.date, dateFormats.TIME_12_HOUR, false);
  const headerText = `${date} ${DOT} ${time}`;
  const isEditing = !!commentToEdit;
  const isDeleting = !!commentIdToDelete;

  const { user: currentUser } = React.useContext(authContext);

  const [createScoopComment, { loading: loadingCreateComment }] = useMutation(
    CREATE_SCOOP_COMMENT,
    {
      onCompleted: data => {
        const onCreateSuccessful = async () => {
          await refetchComments();
          scrollToLastComment();
          onChangeData();
        };

        if (data) onCreateSuccessful();
      },
      onError: error => {
        if (error) {
          ErrorLogger.log(error.message, CREATE_COMMENT_SCOOP_ERROR);
        }
      },
      client: props.client ? props.client : undefined,
    },
  );

  const [updateScoopComment, { loading: loadingUpdateComment }] = useMutation(
    UPDATE_SCOOP_COMMENT,
    {
      onCompleted: data => {
        const onUpdateSuccessful = async () => {
          await refetchComments();
          scrollToLastComment();
          setCommentToEdit(null);
        };

        if (data) onUpdateSuccessful();
      },
      onError: error => {
        ErrorLogger.log(error.message, UPDATE_COMMENT_SCOOP_ERROR);
      },
      client: props.client ? props.client : undefined,
    },
  );

  const [deleteScoopComment, { loading: loadingDeleteComment }] = useMutation(
    DELETE_SCOOP_COMMENT,
    {
      onCompleted: () => {
        refetchComments();
        onChangeData();
      },
      onError: error => {
        ErrorLogger.log(error.message, DELETE_COMMENT_SCOOP_ERROR);
      },
      client: props.client ? props.client : undefined,
    },
  );

  const {
    data: scoopCommentsData,
    refetch: refetchComments,
    loading: loadingComments,
  } = useQuery<{
    scoopComments: IScoopComment[];
  }>(GET_SCOOP_COMMENTS_QUERY, {
    variables: {
      scoopId: scoop.id,
    },
    skip: !scoop.id,
    fetchPolicy: 'network-only',
    client: props.client ? props.client : undefined,
  });

  const scoopComments = scoopCommentsData?.scoopComments;

  const handleCommentSubmit = async (comment: string) => {
    isEditing
      ? updateScoopComment({
          variables: {
            scoopComment: {
              userId: currentUser?.id,
              id: commentToEdit?.id,
              comment,
            },
          },
        })
      : createScoopComment({
          variables: {
            scoopComment: {
              scoopId: scoop.id,
              userId: currentUser?.id,
              comment,
            },
          },
        });
  };

  const scrollToLastComment = () => {
    contentRef.current?.scrollTo?.({
      top: contentRef.current.scrollHeight - contentRef.current.clientHeight,
    });
  };

  return (
    <>
      <ModalWrapper
        onCancel={() => onModalToggle(false)}
        wrapperClassName={styles.container}
      >
        <div className={styles.content} ref={contentRef}>
          <ScoopCommentsModalActions
            onShareClick={onShareClick}
            onEditClick={onEditClick}
            onModalToggle={onModalToggle}
          />
          <ActivityCardHeader
            categories={scoop?.scoopCategories || []}
            headerText={headerText}
            property={props.property}
            targetPropertyUrl={
              property ? locations.showProperty(property.id!) : undefined
            }
            scoop={scoop}
            user={scoopUser}
          />
          <Linkify
            tagName="p"
            options={{
              className: styles.link,
              nl2br: true,
            }}
          >
            {scoop.text}
          </Linkify>
          <ScoopCompanies scoop={props.scoop} />
          <section className={styles['scoop-comments']}>
            {loadingComments ? (
              <p className={styles['empty-comment']}>Loading comments...</p>
            ) : scoopComments?.length ? (
              scoopComments?.map(comment => (
                <ScoopComment
                  key={comment.id}
                  comment={comment}
                  user={currentUser}
                  onDeleteClick={setCommentIdToDelete}
                  onEditClick={setCommentToEdit}
                  isDisabled={commentToEdit?.id === comment.id}
                />
              ))
            ) : (
              <p className={styles['empty-comment']}>
                There are no comments yet
              </p>
            )}
          </section>
        </div>
        <section className={styles['add-comment']}>
          <CommentInput
            disabled={loadingCreateComment || loadingUpdateComment}
            id="scoop-comment"
            isEditing={isEditing}
            onCancelEdit={() => setCommentToEdit(null)}
            onSubmit={handleCommentSubmit}
            shouldClearOnSubmit
            value={commentToEdit?.comment}
          />
        </section>
      </ModalWrapper>
      <DeleteCommentPopup
        commentIdToDelete={commentIdToDelete}
        deleteScoopComment={deleteScoopComment}
        isDeleting={isDeleting}
        loadingDeleteComment={loadingDeleteComment}
        setCommentIdToDelete={setCommentIdToDelete}
      />
    </>
  );
};

export default ScoopCommentsModal;
