import { translateText as t } from 'utils/i18n';
import { colors } from 'constants/colors';
import {
  AreasData,
  IAreasDataResult,
  VerticalAreaData,
} from '../VerticalAreaChart/interfaces';
import { IHistoricalAvailabilityData } from 'interfaces/ISubmarketHistoricalAvailability';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { max, maxBy, uniq } from 'lodash';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { getYearFromDate } from 'utils/date';
import { getStartYearAndEndYearFromFilters } from 'components/FindComps/utils';
import { IPropertySearchFilters } from '../../../interfaces/IPropertySearch';
import dayjs from 'dayjs';
import { validateAndBuildQuarterlyData } from 'utils/graphs';

export const PAST_YEARS_COUNT = 5;

export enum HistoricalAvailabilityTypes {
  OCCUPIED = 'occupied',
  AVAILABLE = 'available',
  VACANT = 'vacant',
}

const { OCCUPIED, AVAILABLE, VACANT } = HistoricalAvailabilityTypes;

export const getSwitchOptions = () => ({
  [OCCUPIED]: {
    text: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.occupied`),
    value: OCCUPIED,
  },
  [AVAILABLE]: {
    text: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.available`),
    value: AVAILABLE,
  },
  [VACANT]: {
    text: t('avantProperties.common.words.vacant'),
    value: VACANT,
  },
});

export const getLegends = (type: HistoricalAvailabilityTypes) => {
  const isOccupied = type === OCCUPIED;
  const isAvailable = type === AVAILABLE;
  const text = isAvailable
    ? t('avantProperties.common.words.avail')
    : t('avantProperties.common.words.vacant');
  return !isOccupied
    ? [
        {
          label: `${t('avantProperties.labels.common.direct', { text })}`,
          color: colors.supportive500,
          isLine: true,
        },
        {
          label: `${t('avantProperties.labels.common.sublease', { text })}`,
          color: colors.ayWhiteColor,
          isLine: true,
        },
      ]
    : [];
};

export const getTitle = (type: HistoricalAvailabilityTypes) => {
  const titleType = {
    [OCCUPIED]: t('avantProperties.headings.common.historicalOccupancy'),
    [VACANT]: t('avantProperties.headings.common.historicalVacancy'),
    [AVAILABLE]: t('avantProperties.headings.common.historicalAvailability'),
  };
  return titleType[type];
};

const getAreasData = (
  areaData: IHistoricalAvailabilityData[],
  activeType: HistoricalAvailabilityTypes,
  chartId: string,
  unitOfMeasurement: string,
  getTooltipFormatter: (
    label: string,
    percentage?: number,
    isQuarterly?: boolean,
    quarter?: number | string,
  ) => string,
  isQuarterly?: boolean,
): IAreasDataResult => {
  const isAvailable = activeType === HistoricalAvailabilityTypes.AVAILABLE;
  const isOccupied = activeType === HistoricalAvailabilityTypes.OCCUPIED;

  const occupiedLabel = t(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.occupied`,
  ).toLowerCase();

  const occupiedArea: AreasData = {
    id: `${chartId}-occupied-area`,
    backgroundColor: colors.supportive500,
    textColor: colors.ayWhiteColor,
    data: areaData.map(d => ({
      date: d.date,
      value: d.occupiedSFPercentage || 0,
      label: getTooltipFormatter(
        occupiedLabel,
        d.occupiedSFPercentage,
        isQuarterly,
        d.quarter,
      ),
      buildingSize: d.occupiedSF,
    })),
    unitOfMeasurement,
  };

  const subleaseArea: AreasData = {
    id: `${chartId}-sublease-area`,
    textColor: colors.primaryColor900,
    backgroundColor: colors.ayWhiteColor,
    data: areaData.map(d => {
      return {
        date: d.date,
        value: isAvailable
          ? d.availableSFSubletPercentage
          : d.vacantSFSubletPercentage,
        label: isAvailable
          ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.subleaseAvailable`, {
              percentage: getTooltipFormatter(
                '',
                d.availableSFSubletPercentage,
                isQuarterly,
                d.quarter,
              ),
            })
          : t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.subleaseVacant`, {
              percentage: getTooltipFormatter(
                '',
                d.vacantSFSubletPercentage,
                isQuarterly,
                d.quarter,
              ),
            }),
        buildingSize: isAvailable ? d.availableSFSublet : d.vacantSFSublet,
      };
    }),
    unitOfMeasurement,
  };

  const directArea: AreasData = {
    id: `${chartId}-direct-area`,
    backgroundColor: colors.supportive500,
    textColor: colors.ayWhiteColor,
    data: areaData.map(d => {
      return {
        date: d.date,
        value: isAvailable
          ? d.availableSFDirectPercentage
          : d.vacantSFDirectPercentage,
        label: isAvailable
          ? t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.directAvailable`, {
              percentage: getTooltipFormatter(
                '',
                d.availableSFDirectPercentage,
                isQuarterly,
                d.quarter,
              ),
            })
          : t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.directVacant`, {
              percentage: getTooltipFormatter(
                '',
                d.vacantSFDirectPercentage,
                isQuarterly,
                d.quarter,
              ),
            }),
        buildingSize: isAvailable ? d.availableSFDirect : d.vacantSFDirect,
      };
    }),
    unitOfMeasurement,
  };

  const maxDataYValue: number =
    max(
      [occupiedArea, directArea, subleaseArea].map(
        a => maxBy(a.data, 'value')?.value,
      ),
    ) || 0;

  return {
    areas: isOccupied ? [occupiedArea] : [directArea, subleaseArea],
    maxDataYValue,
  };
};

export const parseGraphData = (
  chartId: string,
  data: IHistoricalAvailabilityData[],
  activeType: HistoricalAvailabilityTypes,
  unitOfMeasurement: string = UnitOfMeasurement.sf,
  getTooltipFormatter: (
    label: string,
    percentage?: number,
    isQuarterly?: boolean,
    quarter?: number | string,
  ) => string,
  totalBuildingSizes?: number | null,
  propertyFilters?: IPropertySearchFilters,
  isQuarterly?: boolean,
): VerticalAreaData => {
  const currentYear = Number(getYearFromDate());
  const currentQuarter = dayjs(Date()).quarter();

  const { startYear, endYear } = getStartYearAndEndYearFromFilters(
    currentYear - PAST_YEARS_COUNT,
    currentYear,
    propertyFilters,
  );

  let filteredData = data.filter(
    i => i.year && i.year >= startYear && i.year <= endYear,
  );

  if (isQuarterly) {
    filteredData = validateAndBuildQuarterlyData(
      filteredData,
      currentYear,
      currentQuarter,
    ) as IHistoricalAvailabilityData[];
  }

  const yAxis = [0, 100];
  const xAxis = isQuarterly
    ? uniq(filteredData?.map(d => d.date as any))
    : uniq(filteredData?.map((d: IHistoricalAvailabilityData) => d.year!)) ||
      [];

  const { areas, maxDataYValue } = getAreasData(
    filteredData,
    activeType,
    chartId,
    unitOfMeasurement,
    getTooltipFormatter,
    isQuarterly,
  );

  const params = xAxis.map(year => ({
    id: `${chartId}-${year}`,
    label: year,
    xValue: year,
  }));

  return {
    xAxis,
    yAxis,
    areas,
    maxDataYValue,
    params,
  };
};
