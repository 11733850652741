import React, { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import styles from './EditTable.module.scss';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import EditableCell from './EditableCell';
import { HeaderCell } from 'interfaces/ITableCell';
import {
  CellData,
  ChangeFocusOrder,
  EditableTable,
  EditTableItem,
  EditTableItems,
  ICellFooter,
  ICellFormatter,
  ILabelCell,
} from './interfaces';
import TableAddButton from './TableAddButton';
import { deepCopy } from 'utils/objects';

interface Props {
  cellsFormatters?: ICellFormatter[];
  cellsLabels?: ILabelCell[];
  cellsFocusOrder?: ChangeFocusOrder;
  columns: Array<HeaderCell>;
  data: Array<EditTableItem>;
  generateCellsFooter: (data: EditTableItem) => ICellFooter[];
  generateRowPlaceholder: (data: EditTableItems) => EditTableItem;
  id: string;
  updateData: (
    data: EditTableItems,
    itemUpdated?: EditTableItem,
    columnId?: string,
  ) => void;
}

const EditTable: React.FC<Props> = ({
  cellsFormatters,
  cellsLabels,
  cellsFocusOrder,
  columns,
  data,
  generateCellsFooter,
  generateRowPlaceholder,
  id,
  updateData,
}) => {
  const [focusedCell, setFocusedCell] = useState<CellData>();
  const [isAddButtonVisible, setIsAddButtonVisible] = useState<boolean>(false);
  const prepareColumns: any = useMemo(() => columns, [columns]);
  const placeholderRow = useMemo(() => {
    setIsAddButtonVisible(data.length === 0);
    return generateRowPlaceholder(data);
  }, [data, generateRowPlaceholder]);

  const updateCellValue = (cellData: CellData) => {
    let itemUpdated;
    const { columnId, rowIndex, value } = cellData;

    const newValues = deepCopy(data).map((row, index) => {
      if (index === Number(rowIndex)) {
        itemUpdated = {
          ...data[Number(rowIndex)],
          [columnId]: value,
        };
        return itemUpdated;
      }
      return row;
    });
    updateData(newValues, itemUpdated, columnId as string);
  };

  const addPlaceholderToDataTable = () => updateData([...data, placeholderRow]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: prepareColumns,
    data,
    defaultColumn: {
      Cell: EditableCell,
    },
    updateCellValue,
    cellsFormatters,
    focusedCell,
    setFocusedCell,
    cellsFocusOrder,
    addPlaceholderToDataTable,
  } as EditableTable<EditTableItem>);

  return (
    <div className={styles.container}>
      <table id={id} {...getTableProps()} className={styles['table-container']}>
        {isAddButtonVisible ? (
          <TableAddButton onClick={() => setIsAddButtonVisible(false)} />
        ) : (
          <>
            <TableHeader headerGroups={headerGroups} />
            <TableBody
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              prepareRow={prepareRow}
              placeholderRow={placeholderRow}
              onClickPlaceholderRow={addPlaceholderToDataTable}
              cellsLabels={cellsLabels}
              cellsFooter={generateCellsFooter(placeholderRow)}
            />
          </>
        )}
      </table>
    </div>
  );
};

export default EditTable;
