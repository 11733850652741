import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { IProperty } from 'interfaces/IProperty';
import GraphContainer from '../GraphContainer';
import { select } from 'd3-selection';
import styles from './OwnerPortfolio.module.scss';
import { useApolloClient } from '@apollo/client';
import { GRAPH_OWNER_PORTFOLIO_LEASES_QUERY } from 'graphql/leases';
import { GRAPH_OWNER_PORTFOLIO_PROPERTIES_QUERY } from 'graphql/property';
import { formatArea } from 'utils/formatters/area';
import Circles from './Elements/Circles';
import Tooltip, { updateTooltipPosition } from './Elements/Tooltip';
import { getGraphContainerId } from './nodes';
import { DOT } from 'constants/placeholders';
import { ICompany } from 'interfaces/ICompany';
import { IGraphOwnerPortfolioResult, IPortfolioDataPoint } from './interfaces';
import {
  getMeasurementSystem,
  getUnitsOfMeasurementForDataPoints,
  MAX_OWNER_TITLE_LENGTH,
  parseLeasesToDataPoints,
  parsePropertiesToDataPoints,
} from './utils';
import { IdName } from 'interfaces/IdName';
import { Link } from 'react-router-dom';
import locations from 'routes';
import MarketSwitch from 'components/MarketSwitch';
import Button from 'components/Button/new';
import classNames from 'classnames';
import {
  goToFindCompsFromCompany,
  goToFindCompsFromProperty,
} from 'components/Graphs/OwnerPortfolio/utils';
import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { useHistory } from 'react-router-dom';
import graphIds from 'components/CompanyProfile/Graphs/graphConstants';
import {
  I18N_AVANT_PROPERTY_CHART_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
  MeasurementParams,
} from 'constants/i18n';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { translateText } from 'utils/i18n';
import { formatStringUsingElipsis } from 'utils/formatters/string';

interface Props {
  activeColor?: string;
  company?: ICompany;
  filterByTenant?: boolean;
  graphId: number;
  hasDataChanges?: boolean;
  inactiveColor?: string;
  marketsFilter?: IdName[];
  onChangeMarketsFilter?: (newMarkets?: IdName[]) => void;
  property?: IProperty;
  showComponent?: (value: boolean) => void;
  showMarketSwitcher?: boolean;
  showSeeInList?: boolean;
  title?: string;
  wrapperClassName?: string;
  onLoad?: Dispatch<SetStateAction<MeasurementParams>>;
  includeLongLeaseHolders?: boolean;
}

const OwnerPortfolioGraph: React.FC<Props> = (props: Props) => {
  const {
    activeColor,
    company,
    graphId,
    hasDataChanges,
    inactiveColor,
    marketsFilter,
    onChangeMarketsFilter,
    property,
    showComponent,
    showMarketSwitcher,
    showSeeInList = true,
    title,
    wrapperClassName,
    onLoad,
    includeLongLeaseHolders = false,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const client = useApolloClient();
  const history = useHistory();

  const [activeDataPoint, setActiveDataPoint] = useState<
    IPortfolioDataPoint | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataPoints, setDataPoints] = useState<IPortfolioDataPoint[]>([]);
  const [activeOwner, setActiveOwner] = useState<IdName>();
  const [totalSize, setTotalSize] = useState<number | undefined>();
  const [unitsOfMeasurement, setUnitsOfMeasurement] = useState<string>(
    UnitOfMeasurement.sf,
  );
  const [graphContainerDimensions, setGraphContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const isLeases = !!company?.id && props.filterByTenant;
  const hasFilter = !!company?.id || !!property?.owners?.length;
  const hasDataPoints = dataPoints.length > 0;
  const hasMarketSwitcher = !!property?.id && showMarketSwitcher;
  const isReady = svgRef.current && graphContainerDimensions.width > 0;

  const label = isLeases
    ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease`, {
        count: dataPoints.length,
      }).toLowerCase()
    : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.property`, {
        count: dataPoints.length,
      }).toLowerCase();

  const getQueryAndVariables = () => {
    const query = isLeases
      ? GRAPH_OWNER_PORTFOLIO_LEASES_QUERY
      : GRAPH_OWNER_PORTFOLIO_PROPERTIES_QUERY;
    const companyIds = company?.id
      ? [company.id]
      : (property?.owners || []).map(o => o.id!);

    const longLeaseHolderIds = includeLongLeaseHolders
      ? property?.longLeaseHolders?.map(l => l.id) ?? []
      : [];

    const input = {
      marketIds: marketsFilter?.map(m => m.id),
      companyIds: [...companyIds, ...longLeaseHolderIds],
      includeLongLeaseHolders,
    };

    return { query, variables: { input } };
  };

  const getOwnerAndLeaseHolderNames = () => {
    if (company?.id) return undefined;
    const maxOwnersToBeDisplayed = 2;
    let filteredOwners = (property?.owners || []).filter(owner => !!owner.id);
    if (filteredOwners.length > maxOwnersToBeDisplayed) {
      filteredOwners = filteredOwners.slice(0, maxOwnersToBeDisplayed);
    }
    const ownersAndLongLeaseHolders = [
      ...filteredOwners,
      ...(property?.longLeaseHolders ?? []),
    ];

    return ownersAndLongLeaseHolders?.map(
      (owner: IdName, index: number, currentArray) => (
        <span
          key={index}
          onMouseOver={() => {
            currentArray.length > 1 && setActiveOwner(owner);
          }}
          onMouseOut={() => {
            setActiveOwner(undefined);
          }}
        >
          <Link
            className={styles['owner-link']}
            to={{
              pathname: locations.showCompany(owner.id),
              state: { marketsFilter },
            }}
          >
            {formatStringUsingElipsis(owner.name, MAX_OWNER_TITLE_LENGTH)}
          </Link>
          {currentArray.length > index + 1 && ', '}
        </span>
      ),
    );
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      if (!hasFilter) {
        setDataPoints([]);
        setTotalSize(0);
        setIsLoading(false);
        return;
      }

      const { query, variables } = getQueryAndVariables();
      const { data } = await client.query<IGraphOwnerPortfolioResult>({
        query,
        variables,
        fetchPolicy: 'network-only',
      });

      let tempDataPoints: IPortfolioDataPoint[];

      if (isLeases) {
        const {
          totalSize,
          totalSizeMt,
          leases,
        } = data.graphOwnerPortfolioLeases;

        tempDataPoints = parseLeasesToDataPoints(leases);
        setTotalSize(
          getMeasurementSystem(leases) === UnitOfMeasurementCode.EU
            ? totalSizeMt
            : totalSize,
        );
        setUnitsOfMeasurement(getUnitsOfMeasurementForDataPoints(leases));
      } else {
        const {
          totalSize,
          totalSizeMt,
          properties,
        } = data.graphOwnerPortfolioProperties;
        setTotalSize(
          getMeasurementSystem(properties) === UnitOfMeasurementCode.EU
            ? totalSizeMt
            : totalSize,
        );

        tempDataPoints = parsePropertiesToDataPoints(properties);
        const measurementUnits = getUnitsOfMeasurementForDataPoints(properties);
        setUnitsOfMeasurement(measurementUnits);
        onLoad?.({
          totalSize,
          unitOfMeasurement: measurementUnits,
          currencySymbol: getCurrencySymbol(property?.currencyCode),
        });
      }

      if (!tempDataPoints.length) {
        setDataPoints([]);
        setTotalSize(0);
        setIsLoaded(false);
      } else {
        setDataPoints(tempDataPoints);
        setIsLoaded(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasDataChanges) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDataChanges]);

  useEffect(() => {
    const searchTimeout = window.setTimeout(fetchData);

    return () => {
      window.clearTimeout(searchTimeout);
    };

    // eslint-disable-next-line
  }, [marketsFilter]);

  useEffect(() => {
    if (!containerRef.current) return;
    const { width, height } = containerRef.current!.getBoundingClientRect();

    if (!graphContainerDimensions.width) {
      setGraphContainerDimensions({ width, height });
    }

    select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    // eslint-disable-next-line
  }, [containerRef.current, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      showComponent?.(dataPoints.length > 0);
    }

    // eslint-disable-next-line
  }, [isLoading, dataPoints]);

  const redirectToFindComps = () => {
    if (company?.id) {
      const isOccupierSection = graphId === graphIds.OCCUPIER_OWNER_PORTFOLIO;
      goToFindCompsFromCompany(company, dataPoints, history, isOccupierSection);
    } else if (property?.id) {
      goToFindCompsFromProperty(property, dataPoints, history);
    }
  };

  // If the graph has the marketSwitcher on it, continue showing the graph even with no properties,
  // so the user can try a different market
  if (
    (isLoading && !isLoaded) ||
    !hasFilter ||
    (!hasDataPoints && !hasMarketSwitcher)
  ) {
    return null;
  }

  return (
    <GraphContainer
      wrapperClassName={
        wrapperClassName || styles['owner-portfolio-graph-container']
      }
    >
      <div
        className={styles.container}
        id={getGraphContainerId(graphId)}
        ref={containerRef}
      >
        <div className={styles['floating-actions']}>
          {showSeeInList && (
            <Button
              size="xs"
              label={translateText(
                `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.ownerPortfolioGraph.seeInList`,
              )}
              type={'ghost-light'}
              onClick={redirectToFindComps}
              wrapperClassName={classNames({
                [styles['see-in-list-button']]: showMarketSwitcher,
              })}
            />
          )}
          {showMarketSwitcher && onChangeMarketsFilter && (
            <div className={styles['market-switch-container']}>
              <MarketSwitch
                selectedMarkets={marketsFilter}
                onChange={onChangeMarketsFilter}
                useLightLayout
              />
            </div>
          )}
        </div>

        {isReady && (
          <Tooltip
            isLoading={isLoading}
            graphId={graphId}
            onContentChanged={() =>
              activeDataPoint &&
              updateTooltipPosition(activeDataPoint.id, graphId)
            }
            dataPoint={activeDataPoint}
          />
        )}

        <div className={styles['svg-graph-wrapper']}>
          <svg ref={svgRef} className={styles['svg-graph']}>
            {isReady && (
              <Circles
                activeOwner={activeOwner}
                data={dataPoints}
                property={property}
                width={graphContainerDimensions.width}
                height={graphContainerDimensions.height}
                svgRef={svgRef}
                onHoverStatusChange={setActiveDataPoint}
                graphId={graphId}
                activeColor={activeColor}
                inactiveColor={inactiveColor}
              />
            )}
          </svg>

          <div className={styles['title-container']}>
            <p
              className={classNames(styles.title, {
                [styles['large']]: !showMarketSwitcher,
              })}
            >
              {getOwnerAndLeaseHolderNames()}{' '}
              {title ||
                translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.portfolio`)}
            </p>
            {totalSize && hasDataPoints && (
              <p className={styles.subtitle}>
                {`${formatArea(totalSize, unitsOfMeasurement)} ${DOT} ${
                  dataPoints.length
                } ${label}`}
              </p>
            )}
          </div>
        </div>
      </div>
    </GraphContainer>
  );
};

export default OwnerPortfolioGraph;
