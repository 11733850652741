import React from 'react';
import { LeasePeriodType } from 'constants/leases';
import SwitchOptionButton from 'components/SwitchOptionButton';
import styles from '../LeaseToggles/LeaseToggles.module.scss';
import { getTranslatedAnnual, getTranslatedQuarter } from 'utils/leases';

type Props = {
  periodType: string;
  handleChange: Function;
};

const PeriodToggles: React.FC<Props> = ({ periodType, handleChange }) => (
  <div className={styles['lease-toggles']}>
    {periodType && (
      <SwitchOptionButton
        buttonClass={styles['switch-button']}
        wrapperClassName={styles['types-switch']}
        activeOption={{
          value: periodType,
          text: periodType,
        }}
        onChange={({ value }) => handleChange(value)}
        options={[
          {
            value: LeasePeriodType.QUARTER,
            text: getTranslatedQuarter(),
          },
          {
            value: LeasePeriodType.ANNUAL,
            text: getTranslatedAnnual(),
          },
        ]}
      />
    )}
  </div>
);

export default PeriodToggles;
