import { gql } from '@apollo/client';

export const CREATE_COMPANY_MUTATION = gql`
  mutation createCompany($company: CompanyInput!) {
    createCompany(company: $company) {
      ... on CompanyId {
        id
      }
      ... on CompanyError {
        message
      }
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation updateCompany($company: CompanyInput!) {
    updateCompany(company: $company) {
      ... on Company {
        id
      }
      ... on CompanyError {
        message
        duplicate {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_COMPANY_MUTATION = gql`
  mutation deleteCompany($id: Int!) {
    deleteCompany(id: $id)
  }
`;

export const MERGE_COMPANY_MUTATION = gql`
  mutation mergeCompany($origin: Int!, $destination: Int!) {
    mergeCompany(origin: $origin, destination: $destination) {
      ... on Company {
        id
      }
    }
  }
`;

export const CREATE_COMPANY_ALIAS_MUTATION = gql`
  mutation createCompanyAlias($name: String!, $companyId: Int!) {
    createCompanyAlias(name: $name, companyId: $companyId) {
      ... on Company {
        id
      }
      ... on CompanyError {
        message
        duplicate {
          id
          name
          headquarters
          industry {
            id
            name
          }
        }
      }
    }
  }
`;
