import React from 'react';
import styles from './SegmentPricing.module.scss';
import { formatPropertyClass } from 'utils/formatters/property';
import { DOT_LG } from 'constants/placeholders';
import { SegmentPricingParams } from '../interfaces';
import { translateText } from 'utils/i18n';
import { I18N_HISTORICAL_PERFORMANCE_LABEL_PATH } from 'components/HistoricalPerformance/constants';

type Props = {
  segmentPricingParams?: SegmentPricingParams;
};

const SegmentControl: React.FC<Props> = ({ segmentPricingParams }) => {
  const getSegmentDescription = () => {
    if (!segmentPricingParams)
      return (
        <span className={styles['segment-placeholder']}>
          {translateText(
            `${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.selectSegment`,
          )}
        </span>
      );

    const {
      market,
      submarket,
      propertyType,
      propertyClass,
      propertySubtype,
    } = segmentPricingParams;

    const areTypeAndSubTypeEqual =
      propertyType?.name?.toLowerCase() ===
      propertySubtype?.name?.toLowerCase();

    return (
      <span className={styles['segment-description']}>
        {[
          market?.name,
          submarket?.name,
          propertyType?.name,
          areTypeAndSubTypeEqual ? null : propertySubtype?.name,
          formatPropertyClass(propertyClass),
        ]
          .filter(Boolean)
          .join(` ${DOT_LG} `)}
      </span>
    );
  };

  return (
    <div className={styles['segment-container']}>
      <span className={styles['segment-title']}>
        {translateText(`${I18N_HISTORICAL_PERFORMANCE_LABEL_PATH}.segment`)}:{' '}
      </span>
      {getSegmentDescription()}
    </div>
  );
};

export default SegmentControl;
