import React from 'react';
import classNames from 'classnames';
import styles from './ComparativeCard.module.scss';
import { ComparativeCardTheme } from '../constants';

interface Props {
  children: React.ReactNode;
  theme: ComparativeCardTheme;
  wrapperClassName?: string;
}

const ComparativeCard: React.FC<Props> = ({
  children,
  theme,
  wrapperClassName,
}) => (
  <div className={classNames(styles.card, styles[theme], wrapperClassName)}>
    {children}
  </div>
);

export default ComparativeCard;
