import { ITransactionVolumeDataPoint } from './interfaces';

export const YEAR_TEXT_CLASS = 'transaction-volume-year-text';
export const OUTLINE_RHOMBUS_CLASS = 'transaction-volume-outline-rhombus';
export const TRIANGLE_PURCHASED_CLASS = 'transaction-volume-triangle-purchased';
export const TRIANGLE_SOLD_CLASS = 'transaction-volume-triangle-sold';
export const BOTTOM_LINE_CLASS = 'transaction-volume-bottom-line';
export const TOP_LINE_CLASS = 'transaction-volume-top-line';

export const getYearId = (d: ITransactionVolumeDataPoint) => {
  return `transaction-volume-year-${d.year}`;
};

export const getRhombusOutlineId = (d: ITransactionVolumeDataPoint) => {
  return `transaction-volume-rhombus-${d.year}`;
};

export const getTrianglePurchasedId = (d: ITransactionVolumeDataPoint) => {
  return `transaction-volume-purchased-${d.year}`;
};

export const getTriangleSoldId = (d: ITransactionVolumeDataPoint) => {
  return `transaction-volume-sold-${d.year}`;
};
