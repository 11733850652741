import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { ICompany } from 'interfaces/ICompany';
import { ICompanyInput } from 'interfaces/inputs/ICompanyInput';

export const convertIPropertyCompanyIntoICompany = (
  propertyCompany: IPropertyCompany,
): ICompany => ({
  id: propertyCompany.companyId,
  name: propertyCompany.name,
  headquarters: propertyCompany.headquarters,
  mergedAt: propertyCompany.mergedAt,
  mergedCompanyId: propertyCompany.mergedCompanyId,
});

export const convertICompanyIntoICompanyInput = (
  company: ICompany | undefined,
): ICompanyInput => {
  return {
    id: company?.id,
    name: company?.name,
    comments: company?.comments,
    operatingName: company?.operatingName,
    legalName: company?.legalName,
    industry: company?.industry?.name,
    headquarters: company?.headquarters,
    headquarterPropertyId: company?.headquarterProperty?.id,
    website: company?.website,
    linkedIn: company?.linkedIn,
    mergedCompanyId: company?.mergedCompanyId ?? null,
    investorTypeId: company?.investorType?.id ?? null,
  };
};
