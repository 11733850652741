import { useQuery } from '@apollo/client';
import { PROPERTIES_ANALYTICS_OWNERS } from 'graphql/propertiesAnalytics';
import { IOwnersResponse } from 'interfaces/IOwners';
import { IProperty } from 'interfaces/IProperty';
import { DONUT_GRAPH_DATA_LIMIT } from '../utils';

export const useOwnersData = (properties: IProperty[]) => {
  const filterIds = properties.map(property => property.id);
  const variables = {
    search: {
      filter: {
        ids: filterIds,
      },
      includeLongLeaseHolders: true,
      limit: DONUT_GRAPH_DATA_LIMIT,
    },
  };

  const { data: ownersData, loading: isLoadingOwners } = useQuery<{
    propertiesAnalyticsOwners: IOwnersResponse;
  }>(PROPERTIES_ANALYTICS_OWNERS, {
    variables,
  });

  return {
    ownersData,
    isLoadingOwners,
  };
};
