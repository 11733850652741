import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { isEqual, uniqBy } from 'lodash';

import { Container } from 'components/@codelitt/ay-design-library';
import Button from 'components/Button/new';
import UpdateSummary, { getUpdateSummaryData } from 'components/UpdateSummary';
import Dropzone, { AcceptedFiles } from 'components/Dropzone';
import { KVFilesToUpload } from 'components/ModalMedia/types';
import Tabs from 'components/Tabs';
import { authContext } from 'contexts/AuthContext';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { MediaTabNames } from 'constants/media';
import { IScoopInput } from 'interfaces/inputs/IScoopInput';
import { IProperty } from 'interfaces/IProperty';
import { IScoop } from 'interfaces/IScoop';
import { IDocumentsResponse } from 'interfaces/IDocument';
import { ICompany } from 'interfaces/ICompany';
import { IScoopMedia, ScoopMediaResult } from 'interfaces/IScoopMedia';
import { GET_PROPERTY_BASIC_DATA_QUERY } from 'graphql/property';
import { GET_SCOOP_MEDIA } from 'graphql/scoops/queries';
import { GET_DOCUMENTS_BY_SCOOP_QUERY } from 'graphql/documents/queries';
import { translateText, translateText as t } from 'utils/i18n';
import {
  extractDocumentFiles,
  extractImageFiles,
  extractMediaDocuments,
  extractMediaImages,
} from 'utils/media';

import ScoopTypesInput from '../ScoopTypesInput';
import { UploadedImagePreview } from './components/UploadedImagePreview';
import { BasicScoopFields } from './components/BasicScoopFields';
import { DeleteMediaModal } from './components/DeleteMediaModal';
import styles from './ScoopForm.module.scss';

type ModalProps = {
  mediaFilesToUpload: KVFilesToUpload | null;
  setMediaFilesToUpload: React.Dispatch<
    React.SetStateAction<KVFilesToUpload | null>
  >;
  onDelete: () => void;
  onCancel: () => void;
  onSave: (scoopData: IScoopInput) => void;
  isEditing?: boolean;
  isSubmitting?: boolean;
  activityScoop?: IScoop;
  preselectedProperty?: IProperty;
  preselectedCompany?: ICompany;
  activeTab?: MediaTabNames;
};

export type DeleteModalState = {
  open: boolean;
  activeOpenScoopId: string | null;
  removeByRequest: boolean;
  isDocument: boolean;
  isImage: boolean;
};

export const INITIAL_DELETE_MODAL_STATE = {
  open: false,
  activeOpenScoopId: null,
  removeByRequest: false,
  isDocument: false,
  isImage: false,
};

const I18N_LABEL_PATH = 'avantProperties.labels.exploreActivity';
const I18N_COMMON_LABEL_PATH = 'avantProperties.labels.common';

const ScoopForm: React.FC<ModalProps> = ({
  activeTab = MediaTabNames.pictures,
  onDelete,
  onCancel,
  onSave,
  isEditing,
  isSubmitting,
  preselectedProperty,
  preselectedCompany,
  activityScoop = {},
  mediaFilesToUpload,
  setMediaFilesToUpload,
}) => {
  const { user } = useContext(authContext);

  const [activeTabName, setActiveTabName] = useState<string>(activeTab);
  const [scoopInput, setScoopInput] = useState<IScoopInput>({});
  const [scoopFromApi, setScoopFromApi] = useState<IScoopInput>(activityScoop);
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalState>(
    INITIAL_DELETE_MODAL_STATE,
  );

  const { data: propertyData } = useQuery<{ property: IProperty }>(
    GET_PROPERTY_BASIC_DATA_QUERY,
    {
      variables: { id: preselectedProperty?.id },
      skip: !preselectedProperty?.id,
    },
  );

  const { data: scoopMedia } = useQuery<ScoopMediaResult>(GET_SCOOP_MEDIA, {
    variables: {
      scoopId: activityScoop?.id,
    },
    skip: !activityScoop?.id,
    fetchPolicy: 'network-only',
  });

  const { data: documents } = useQuery<IDocumentsResponse>(
    GET_DOCUMENTS_BY_SCOOP_QUERY,
    {
      variables: {
        scoopId: activityScoop?.id,
      },
      skip: !activityScoop?.id,
      fetchPolicy: 'network-only',
    },
  );

  const savedImages = useMemo(
    () => extractMediaImages(scoopMedia?.mediaScoop ?? []),
    [scoopMedia?.mediaScoop],
  );
  const savedDocuments = useMemo(
    () => extractMediaDocuments((documents?.documents as IScoopMedia[]) ?? []),
    [documents?.documents],
  );

  const uploadedImages = useMemo(
    () => extractImageFiles(mediaFilesToUpload ?? {}),
    [mediaFilesToUpload],
  );

  const uploadedDocuments = useMemo(
    () => extractDocumentFiles(mediaFilesToUpload ?? {}),
    [mediaFilesToUpload],
  );

  const picturesTab = {
    name: MediaTabNames.pictures,
    header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.photosAndVideos`),
    content: (
      <>
        <Dropzone
          multipleFile={true}
          acceptedFiles={AcceptedFiles.imagesAndVideos}
          setDocumentsToUpload={setMediaFilesToUpload}
        />
        <UploadedImagePreview
          setDeleteModalState={setDeleteModalState}
          items={[...uploadedImages, ...savedImages]}
        />
      </>
    ),
  };

  const documentsTab = {
    name: MediaTabNames.documents,
    header: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.document_plural`),
    content: (
      <>
        <Dropzone
          acceptedFiles={AcceptedFiles.comps}
          setDocumentsToUpload={setMediaFilesToUpload}
        />
        <UploadedImagePreview
          setDeleteModalState={setDeleteModalState}
          items={[...uploadedDocuments, ...savedDocuments]}
        />
      </>
    ),
  };

  const updateSummaryValue =
    activityScoop &&
    activityScoop.createdAt &&
    getUpdateSummaryData(
      activityScoop.createdAt,
      activityScoop.user!,
      activityScoop.updatedAt,
      activityScoop.lastEditedUser,
    );

  const updateScoopInput = (field: string, fieldValue: any) =>
    setScoopInput(value => ({ ...value, [field]: fieldValue }));

  useEffect(() => {
    if (isSubmitting) return;

    const input = activityScoop
      ? {
          ...activityScoop,
          properties: activityScoop?.properties || [],
        }
      : null;

    setScoopInput(input || { userId: user!.id });
    setScoopFromApi(input || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityScoop?.id, user, isSubmitting]);

  useEffect(() => {
    if (preselectedCompany?.id) {
      updateScoopInput('companies', [
        ...(scoopInput.companies || []),
        preselectedCompany,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedCompany?.id]);

  useEffect(() => {
    if (preselectedProperty?.id) {
      updateScoopInput('properties', [
        ...(scoopInput?.properties || []),
        preselectedProperty,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedProperty?.id]);

  useEffect(() => {
    if (
      !scoopInput.userId ||
      !preselectedProperty?.id ||
      !propertyData?.property ||
      scoopInput.properties?.length
    ) {
      return;
    }

    updateScoopInput('properties', [propertyData.property]);
  }, [preselectedProperty, propertyData, scoopInput]);

  // This `useEffect` is intended to sync markets, property types and companies with the
  // selected properties.
  useEffect(() => {
    const { properties = [] } = scoopInput;

    // The markets the selected properties belong to.
    const syncedMarkets = properties.map(({ market }) => ({
      ...market,
      isSynced: true,
    }));

    // The property types of the selected properties.
    const syncedPropTypes = properties.map(({ propertyType }) => ({
      ...propertyType,
      isSynced: true,
    }));

    // The owners and long lease holders of the selected properties.
    const propertyOwners = properties.map(({ owners = [] }) =>
      owners.map(owner => ({ ...owner, isSynced: true })),
    );
    const propertyLongLeaseHolders = properties.map(
      ({ longLeaseHolders = [] }) =>
        longLeaseHolders.map(longLeaseHolder => ({
          ...longLeaseHolder,
          isSynced: true,
        })),
    );
    const syncedCompanies = propertyOwners
      .concat(propertyLongLeaseHolders)
      .flat();

    setScoopInput(scoopInput => {
      const { markets = [], propertyTypes = [], companies = [] } = scoopInput;

      const notSyncedMarkets = markets.filter(({ isSynced }) => !isSynced);
      const notSyncedPropTypes = propertyTypes.filter(
        ({ isSynced }) => !isSynced,
      );
      const notSyncedCompanies = companies.filter(({ isSynced }) => !isSynced);

      return {
        ...scoopInput,
        markets: uniqBy(notSyncedMarkets.concat(syncedMarkets), 'id'),
        propertyTypes: uniqBy(notSyncedPropTypes.concat(syncedPropTypes), 'id'),
        companies: uniqBy(notSyncedCompanies.concat(syncedCompanies), 'id'),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoopInput.properties?.length]);

  const isValidForm = () => {
    return (
      !!scoopInput.text &&
      !!scoopInput?.scoopCategories?.length &&
      (!!scoopInput.properties?.length ||
        !!preselectedProperty?.id ||
        !!scoopInput?.markets?.length)
    );
  };

  const getIsFormDirty = () => {
    const { ...scoopToCompare } = scoopInput;

    if (scoopFromApi && scoopToCompare) {
      const areSameCategories = isEqual(
        scoopToCompare.scoopCategories,
        scoopFromApi.scoopCategories,
      );
      const areSameCompanies = isEqual(
        scoopToCompare.companies?.map(c => c?.id),
        scoopFromApi.companies?.map(c => c?.id),
      );
      const areSameProperties = isEqual(
        scoopToCompare.propertiesIds,
        scoopFromApi.propertiesIds,
      );
      const hasSameTextContent = scoopToCompare.text === scoopFromApi.text;

      let areSameMarkets;
      if (scoopFromApi.markets && scoopInput.markets) {
        areSameMarkets = isEqual(
          scoopInput.markets.map(m => m.id),
          scoopFromApi.markets?.map(m => m?.id),
        );
      }

      let areSamePropertyTypes;
      if (scoopFromApi.propertyTypes && scoopInput.propertyTypes) {
        areSamePropertyTypes = isEqual(
          scoopInput.propertyTypes.map(t => t.id),
          scoopFromApi.propertyTypes.map(t => t?.id),
        );
      }

      const hasSameUrlContent = scoopToCompare.url === scoopFromApi.url;
      const hasNoMediaToUpload = !Object.values(mediaFilesToUpload || {})
        .length;

      return (
        !areSameCategories ||
        !hasSameTextContent ||
        !areSameCompanies ||
        !areSameProperties ||
        !areSameMarkets ||
        !areSamePropertyTypes ||
        !hasSameUrlContent ||
        !hasNoMediaToUpload
      );
    }

    return false;
  };

  const submitLabel = isSubmitting
    ? `${t(`${I18N_COMMON_LABEL_PATH}.pleaseWait`)}...`
    : isEditing
    ? t(`${I18N_COMMON_LABEL_PATH}.updateScoop`)
    : t(`${I18N_PLATFORM_COMMON_WORD_PATH}.addScoop`);

  return (
    <>
      <Container>
        <div className={styles.header}>
          <h2 className={styles.title}>
            {isEditing
              ? t(`${I18N_COMMON_LABEL_PATH}.updateScoop`)
              : t(`${I18N_PLATFORM_COMMON_WORD_PATH}.addScoop`)}
          </h2>
        </div>
      </Container>
      <BasicScoopFields
        scoopInput={scoopInput}
        setScoopInput={setScoopInput}
        updateScoopInput={updateScoopInput}
        preselectedProperty={preselectedProperty}
      />
      <Container wrapperClassName={styles.container}>
        <Tabs
          tabs={[picturesTab, documentsTab]}
          activeTabName={activeTabName}
          onActiveTabChange={tab => setActiveTabName(tab.name)}
        />
      </Container>
      <Container
        wrapperClassName={classNames(styles.container, {
          [styles['is-last']]: !updateSummaryValue,
        })}
      >
        <h6 className={styles.subtitle}>{`${t(
          `${I18N_LABEL_PATH}.selectScoopType`,
        )}:`}</h6>
        <ScoopTypesInput scoopInput={scoopInput} setScoop={updateScoopInput} />
      </Container>
      {updateSummaryValue && (
        <Container wrapperClassName={styles.container}>
          <UpdateSummary
            data={updateSummaryValue}
            wrapperClassName={classNames(
              styles['is-last'],
              styles['update-summary'],
            )}
          />
        </Container>
      )}
      <footer className={styles.footer}>
        <Container
          wrapperClassName={classNames(styles['actions-container'], {
            [styles['is-editing']]: isEditing,
          })}
        >
          {isEditing && (
            <Button
              icon="delete"
              iconPosition="left"
              label={t(`${I18N_LABEL_PATH}.deleteScoop`)}
              onClick={onDelete}
              size="l"
              type="delete-neutral"
              wrapperClassName={styles['delete-btn']}
            />
          )}
          <div>
            <Button
              disabled={isSubmitting}
              label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`)}
              onClick={onCancel}
              size="l"
              type="neutral"
              wrapperClassName={styles['cancel-btn']}
            />
            <Button
              disabled={isSubmitting || !(isValidForm() && getIsFormDirty())}
              label={submitLabel}
              onClick={() => onSave(scoopInput)}
              size="l"
              type="main"
              wrapperClassName={styles['submit-btn']}
            />
          </div>

          <DeleteMediaModal
            documents={documents?.documents ?? []}
            mediaScoop={scoopMedia?.mediaScoop ?? []}
            activityScoop={activityScoop}
            deleteModalState={deleteModalState}
            setDeleteModalState={setDeleteModalState}
            setMediaFilesToUpload={setMediaFilesToUpload}
          />
        </Container>
      </footer>
    </>
  );
};

export default ScoopForm;
