import React, { useContext, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Row, Col } from 'components/@codelitt/ay-design-library';
import Dropzone, { AcceptedFiles } from 'components/Dropzone';
import PictureItem from 'components/ModalMedia/Pictures/PictureItem';
import { IdName } from 'interfaces/IdName';
import UploadItem from 'components/ModalMedia/Pictures/UploadItem';
import styles from 'components/ModalMedia/Pictures/Pictures.module.scss';
import Button from 'components/Button';
import {
  CREATE_MEDIA_MUTATION,
  SET_MEDIA_AMENITY_MUTATION,
  SET_MEDIA_CATEGORY_MUTATION,
  GET_PROPERTY_MEDIA_QUERY,
} from 'graphql/images';
import ModalGallery from 'components/PropertyProfile/ModalGallery';
import { getPropertyImagesOrdered } from 'components/ModalMedia/utils';
import {
  FileToPreview,
  FileToUpload,
  IMediaUploadQueryConfig,
  KVFilesToUpload,
} from 'components/ModalMedia/types';
import { buildFileKey } from 'components/ModalMedia/utils';
import LoadingMessage from 'components/LoadingMessage';
import { ErrorLogger } from 'services/ErrorLogger';
import { IMedia } from 'interfaces/IMedia';
import { isVideoMediaType } from 'utils/media';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { SET_MEDIA_NOTES } from 'graphql/images/mutations';
import { MediaNotesContext } from 'contexts/MediaNoteContext';

type Props = {
  hasCategories?: boolean;
  queryConfig: IMediaUploadQueryConfig;
  onDataChanges?: () => void;
  profileType?: PROFILE_TYPES;
};

const Pictures: React.FC<Props> = ({
  hasCategories,
  queryConfig,
  onDataChanges,
  profileType,
}) => {
  const client = useApolloClient();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [mediaFilesToUpload, setMediaFilesToUpload] = useState<KVFilesToUpload>(
    {},
  );
  const [gallerySettings, setGallerySettings] = useState<{
    visible: boolean;
    media?: IMedia;
  }>({
    visible: false,
  });

  const { data, loading, refetch: refetchImages } = useQuery<{
    [key: string]: IMedia[];
  }>(queryConfig.listMediaQuery?.query || GET_PROPERTY_MEDIA_QUERY, {
    variables: queryConfig.listMediaQuery?.variables,
  });

  const [saveNote] = useMutation(SET_MEDIA_NOTES);

  const images = data?.media || data?.mediaSubmarkets || [];

  const { getMediaNoteByKey } = useContext(MediaNotesContext);

  const setImageToUploadCategory = (
    imageKey: string,
    cat: IdName | null,
    isAmenityChildParam: boolean,
  ) => {
    setMediaFilesToUpload(value => {
      const images = { ...value };
      images[imageKey].categoryId = cat?.id ? +cat.id : null;
      images[imageKey].isAmenityChild = isAmenityChildParam;
      return images;
    });
  };

  const setImageUploadProgress = (imageKey: string, progress: number) => {
    setMediaFilesToUpload(value => {
      const images = { ...value };
      images[imageKey] && (images[imageKey].uploadProgress = progress);
      return images;
    });
  };

  const getMediaFilesToUpload = (): FileToUpload[] => {
    return Object.keys(mediaFilesToUpload).map(key => mediaFilesToUpload[key]);
  };

  const getMediaForPreview = (): FileToPreview[] => {
    return Object.keys(mediaFilesToUpload).map(key => {
      const media: FileToPreview = mediaFilesToUpload[key];
      if (isVideoMediaType(media.file)) {
        media.isVideo = true;
      }
      return media;
    });
  };

  const removeImageToUpload = (imageKey: string) => {
    mediaFilesToUpload[imageKey]?.abortUploadHandler?.();
    if (getMediaFilesToUpload()?.length <= 1) {
      setIsUploading(false);
    }
    setMediaFilesToUpload(({ [imageKey]: _, ...images }) => images);
  };

  const processFiles = (files: File[]) => {
    files.forEach(f => {
      const fileToUpload = {
        key: buildFileKey(f),
        uploadProgress: 0,
        name: f.name,
        categoryId: null,
        file: f,
      };

      const image = {
        [fileToUpload.key]: fileToUpload,
      };

      setMediaFilesToUpload(value => ({
        ...value,
        ...image,
      }));
    });
  };

  const clearAllUploads = () => {
    getMediaFilesToUpload().forEach(img => {
      img.abortUploadHandler?.();
    });
    setMediaFilesToUpload({});
    setIsUploading(false);
  };

  const updateImageCategory = async (data: any, media: FileToUpload) => {
    if (!hasCategories) return;

    const mutationsParams = {
      mutation: media.isAmenityChild
        ? SET_MEDIA_AMENITY_MUTATION
        : SET_MEDIA_CATEGORY_MUTATION,
      field: media.isAmenityChild ? 'subAmenityId' : 'categoryId',
    };

    await client.mutate({
      mutation: mutationsParams.mutation,
      variables: {
        mediaId: data.createMedia.id,
        [mutationsParams.field]: media.categoryId,
      },
      refetchQueries: queryConfig.refetchQueries,
    });
  };

  const uploadAll = async () => {
    const files: FileToUpload[] = getMediaFilesToUpload();

    const promises = files.map(media => {
      return new Promise(async (resolve, reject) => {
        try {
          const { data, errors } = await client.mutate({
            mutation: CREATE_MEDIA_MUTATION,
            variables: {
              file: media.file,
              ...queryConfig.createMediaVariables,
            },
            context: {
              fetchOptions: {
                useUpload: true,
                onProgress: (ev: ProgressEvent) => {
                  setImageUploadProgress(
                    media.key,
                    Math.floor((ev.loaded / ev.total) * 100),
                  );
                },
                onAbortPossible: (abortFn: any) => {
                  mediaFilesToUpload[media.key].abortUploadHandler = abortFn;
                },
              },
            },
          });

          if (errors && errors.length) {
            reject(errors);
            return;
          }

          await updateImageCategory(data, media);

          const noteByKey = getMediaNoteByKey(media.key);
          if (noteByKey) {
            await saveNote({
              variables: {
                mediaId: data.createMedia.id,
                notes: noteByKey,
              },
            });
          }

          // remove image from the list when upload finishes
          removeImageToUpload(media.key);
          resolve(data.createMedia);
        } catch (e) {
          reject(e);
        }
      });
    });

    setIsUploading(true);

    try {
      await Promise.all(promises);

      await onDataChanges?.();

      if (queryConfig.refetchQueries) {
        await Promise.all(
          queryConfig.refetchQueries?.map(query =>
            client.query({
              ...query,
              // Use network-only to force updating the property data/cache after the mutations
              fetchPolicy: 'network-only',
            }),
          ),
        );
      }
    } catch (e) {
      ErrorLogger.log(
        e as any,
        'Media > CREATE_MEDIA_MUTATION > Unable to upload media',
      );
    } finally {
      setIsUploading(false);
    }
  };

  const renderPropertyImages = () => {
    if (loading) {
      return <LoadingMessage />;
    }

    if (!images?.length || Object.keys(mediaFilesToUpload).length > 0) {
      return null;
    }

    return getPropertyImagesOrdered(images).map(image => (
      <PictureItem
        queryConfig={queryConfig}
        hasCategories={hasCategories}
        media={image}
        key={image.id}
        onClickImage={image => {
          setGallerySettings({
            visible: true,
            media: image,
          });
        }}
        onDelete={async () => {
          await onDataChanges?.();
        }}
        onUpdateCategory={async () => {
          await onDataChanges?.();
          refetchImages?.();
        }}
        profileType={profileType}
      />
    ));
  };

  const renderUploadItems = () => {
    const mediaItems = getMediaForPreview();

    return mediaItems?.length
      ? mediaItems.map(mediaItem => (
          <UploadItem
            queryConfig={queryConfig}
            hasCategories={hasCategories}
            media={mediaItem}
            key={mediaItem.key}
            profileType={profileType}
            onDelete={removeImageToUpload}
            onCategoryChange={setImageToUploadCategory}
          />
        ))
      : null;
  };

  const renderButtonActions = () => {
    if (!Object.keys(mediaFilesToUpload).length) return null;

    const images = getMediaFilesToUpload();
    const isValidForm =
      !hasCategories || images.filter(i => !i.categoryId).length === 0;

    return (
      <div className={styles['submit-btn-container']}>
        <Button
          wrapperClassName={styles['cancel-btn']}
          onClick={clearAllUploads}
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`)}
          type="neutral"
          size="small"
        />

        <Button
          wrapperClassName={styles['submit-btn']}
          isDisabled={isUploading || !isValidForm}
          onClick={uploadAll}
          label={
            isUploading
              ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.uploading`)
              : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.submit`)
          }
          type="main"
          size="small"
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Row>
        <Col>
          <Dropzone
            onChange={processFiles}
            acceptedFiles={AcceptedFiles.imagesAndVideos}
            small={true}
            multipleFile
          />
        </Col>
      </Row>
      <Row>
        {renderPropertyImages()}
        {renderUploadItems()}
      </Row>

      {renderButtonActions()}

      {queryConfig.property &&
        gallerySettings.visible &&
        gallerySettings.media?.url && (
          <ModalGallery
            onCloseModal={() =>
              setGallerySettings({
                visible: false,
                media: undefined,
              })
            }
            property={queryConfig.property}
            initialImageUrl={gallerySettings.media.url}
            keepScrollLocked
          />
        )}
    </div>
  );
};

export default Pictures;
