import React from 'react';
import styles from './Chip.module.scss';
import { IdName } from 'interfaces/IdName';
import Chip from './Chip';

interface Props {
  items: IdName[];
  onChange: (items: IdName[]) => void;
  wrapperChipClass?: string;
}

const Chips: React.FC<Props> = props => {
  const { items, onChange } = props;

  const hasItems = !!items.length;

  const remove = (item: IdName): void => {
    onChange(items.filter(a => a.id !== item.id));
  };

  return (
    <div>
      {hasItems && (
        <div className={styles['chips-container']}>
          {items.map(item => (
            <Chip
              item={item}
              key={item.id}
              onDelete={remove}
              wrapperChipClass={props.wrapperChipClass}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Chips;
