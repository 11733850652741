import locations from 'routes';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ModalWrapper from 'components/ModalWrapper';
import { CompType } from 'constants/compType';
import SaleEditForm from 'components/CreateComps/FormSection/SaleEditForm';
import LeaseEditForm from 'components/CreateComps/FormSection/LeaseEditForm';
import PropertyEditForm from 'components/CreateComps/FormSection/PropertyEditForm';
import PricingQuoteEditForm from 'components/CreateComps/FormSection/PricingQuoteEditForm';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

interface Props {
  type: CompType;
  onUpdate?: () => void;
  onDelete?: () => void;
  compId: number;
  onModalToggle: (isOpen: boolean) => void;
  propertySetId?: number;
}

const ModalEditComp: React.FC<Props> = ({
  type,
  compId,
  propertySetId,
  onModalToggle,
  onUpdate,
  onDelete,
}) => {
  const history = useHistory();
  const [isUpdateFinished, setIsUpdateFinished] = useState(false);
  const [isDeleteFinished, setIsDeleteFinished] = useState(false);

  useEffect(() => {
    if (isUpdateFinished) {
      onUpdate?.();
      onModalToggle(false);
      setIsUpdateFinished(false);
    }
  }, [isUpdateFinished, onUpdate, onModalToggle]);

  useEffect(() => {
    if (isDeleteFinished) {
      onDelete?.();
      onModalToggle(false);
      setIsDeleteFinished(false);
    }
  }, [isDeleteFinished, onDelete, onModalToggle]);

  const renderEditForm = () => {
    switch (type) {
      case CompType.lease:
        return (
          <LeaseEditForm
            showDeleteButton
            leaseId={`${compId}`}
            title={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.editLeaseComp`,
            )}
            onCancel={() => onModalToggle(false)}
            onUpdated={() => setIsUpdateFinished(true)}
            onDeleted={() => setTimeout(() => setIsUpdateFinished(true))}
          />
        );
      case CompType.sale:
        return (
          <SaleEditForm
            showDeleteButton
            saleId={`${compId}`}
            title={translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.editSaleComp`,
            )}
            onCancel={() => onModalToggle(false)}
            onUpdated={() => setIsUpdateFinished(true)}
            onDeleted={() => setTimeout(() => setIsUpdateFinished(true))}
          />
        );
      case CompType.property:
        return (
          <PropertyEditForm
            showDeleteButton
            propertyId={compId}
            title={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.editProperty`,
            )}
            onCancel={() => onModalToggle(false)}
            onUpdated={() => setIsUpdateFinished(true)}
            onDeleted={() => history.push(locations.root())}
          />
        );
      case CompType.pricingQuote:
        return (
          <PricingQuoteEditForm
            showDeleteButton
            propertySetId={propertySetId}
            pricingQuoteId={compId}
            title={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.editPricingQuote`,
            )}
            onCancel={() => onModalToggle(false)}
            onUpdated={() => setIsUpdateFinished(true)}
            onDeleted={() => setIsDeleteFinished(true)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalWrapper
      fullScreen
      withCloseLabel
      withCloseButton
      onCancel={() => onModalToggle(false)}
    >
      {renderEditForm()}
    </ModalWrapper>
  );
};

export default ModalEditComp;
