import { DOT } from 'constants/placeholders';
import { ILease } from 'interfaces/ILease';
import { formatDate } from 'utils/formatters/date';
import { upperFirstLetter } from 'utils/formatters/string';
import dateFormats from 'constants/dateFormats';

export const buildLeaseCardHeader = (lease: ILease): string => {
  const { type, signDate, termInMonths, transactionType } = lease;

  const formattedTransactionType = transactionType
    ? ` / ${upperFirstLetter(transactionType)}`
    : null;
  const formattedType = type ? upperFirstLetter(type) : null;
  const formattedTypes = formattedType
    ? formattedType + (formattedTransactionType ? formattedTransactionType : '')
    : null;
  const signedValue = formatDate(signDate, dateFormats.MONTH_YEAR);
  const signed = signedValue ? `Signed ${signedValue}` : null;

  const term = termInMonths ? `${Math.floor(termInMonths / 12)} Years` : null;

  return [signed, term, formattedTypes].filter(Boolean).join(` ${DOT} `);
};
