import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { select } from 'd3-selection';

import { colors } from 'constants/colors';

import { PolylineProps } from './types';

const Polyline = forwardRef<any, PolylineProps>(
  ({ points, width = 1, color = colors.ayBlackColor }, ref) => {
    const lineGroupRef = useRef(null);

    useImperativeHandle(ref, () => lineGroupRef);

    useEffect(() => {
      const node = lineGroupRef.current;
      const pointString = points.map(p => p.join(', ')).join(' ');
      const svg = select(node)
        .append('polyline')
        .attr('points', pointString)
        .style('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', width);

      return () => {
        svg.remove();
      };
    }, [points, color, width]);

    return <g ref={lineGroupRef} />;
  },
);

export default Polyline;
