import { IProperty } from './IProperty';
import { IdName } from './IdName';
import { ICompany } from './ICompany';
import IIndustry from './IIndustry';
import { IBroker } from 'interfaces/IBroker';
import { IUser } from './IUser';
import { ICountry } from './IMarket';

export enum SaleTenureTypeEnum {
  FREEHOLD = 'FREEHOLD',
  LONG_LEASEHOLD = 'LONG_LEASEHOLD',
}

export default interface ISale {
  [key: string]: any;
  id?: number;
  dealId?: string;
  sellersBrokers?: IBroker[];
  buyersBrokers?: IBroker[];
  createdAt?: string;
  date?: string;
  interestPercent?: number;
  occupancy?: number;
  partialInvVol?: number;
  pricePerSF?: number;
  pricePerUnit?: number;
  pricePerSFRaw?: number;
  price?: number;
  property?: IProperty;
  sf?: number;
  squareMeters?: number;
  currencyCode?: string;
  suite?: string;
  floor?: string;
  buyers?: IdName[];
  dateOnMarket?: string;
  partOfPortfolio?: boolean;
  capRate?: number;
  sellers?: ICompany[];
  assetProfile?: IdName;
  type?: IdName;
  industriesOfTenants?: IIndustry[];
  underbidders?: ICompany[];
  comments?: string;
  numberOfBuildings?: number;
  measurementSystem?: string;
  tenureType?: string;
  longLeaseHoldExpiry?: string;
  longLeaseHoldGroundRent?: number;
  longLeaseHoldServiceCharge?: number;
  longLeaseHoldAdminCharge?: number;
  createdUser?: IUser;
  updatedUser?: IUser;
  country?: ICountry;
  units?: number;
  waultToBreakDate?: number;
  waultToExpiry?: number;
  totalRent?: number;
  vacantPossession?: boolean;
}
