import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DocumentNode } from '@apollo/client';
import { OperationVariables, useQuery } from '@apollo/client';
import classnames from 'classnames';
import styles from './GroupedOptionSearchFilter.module.scss';
import ClearInputImage from '../../assets/images/icons/clear-icon-grey-64.svg';
import ArrowDownImage from '../../assets/images/icons/dropdown-arrow-down.svg';
import ArrowUpImage from '../../assets/images/icons/dropdown-arrow-up.svg';
import DropdownCheckboxInput from '../Inputs/DropdownCheckboxInput';
import { IMultiselectItem } from '../../interfaces/IMultiselectItem';
import { getSelectedItemsInOptions } from './utils';

type Props = {
  label?: string;
  innerLabel: string;
  innerLabelActive?: string;
  searchPlaceholder: string;
  query: DocumentNode;
  variables: OperationVariables;
  queryParser: (data: any) => IMultiselectItem[];
  selectedItems: IMultiselectItem[];
  onChange: (selectedOptions?: IMultiselectItem[]) => void;
  showCategoryOnResults?: boolean;
  isDisabled?: boolean;
  onLoadOptions?: (options: IMultiselectItem[]) => void;
};

const GroupedOptionSearchFilter: React.FC<Props> = props => {
  const {
    label,
    innerLabel,
    innerLabelActive,
    searchPlaceholder,
    query,
    variables,
    queryParser,
    selectedItems,
    onChange,
    showCategoryOnResults,
    onLoadOptions,
  } = props;
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const [searchVariables, setSearchVariables] = useState<OperationVariables>();
  const [isActive, setIsActive] = useState(false);
  const [searchInputText, setSearchInputText] = useState('');
  const [selectedItemsParsed, setSelectedItemsParsed] = useState<
    IMultiselectItem[]
  >([]);

  const { loading, data } = useQuery(query, {
    variables: searchVariables,
  });

  useEffect(() => {
    setSearchVariables(variables);
  }, [variables]);

  useEffect(() => {
    if (isActive) {
      searchInputRef.current!.focus();
    }
  }, [isActive]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchContainerRef]);

  const options = useMemo(() => (loading ? [] : queryParser(data)), [
    data,
    loading,
    queryParser,
  ]);

  useEffect(() => {
    if (!loading && onLoadOptions) {
      onLoadOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (options.length && selectedItems.length)
      setSelectedItemsParsed(getSelectedItemsInOptions(options, selectedItems));
  }, [options, selectedItems]);

  const arrowImageSrc = isActive ? ArrowUpImage : ArrowDownImage;

  const renderLabel = () => {
    let labelText = innerLabel;
    let resultText = '';
    if (selectedItemsParsed?.length > 0) {
      labelText = innerLabelActive ? `${innerLabelActive}:` : '';
      resultText = ` ${selectedItemsParsed
        ?.reduce((acc: string[], option: IMultiselectItem | null) => {
          const subItemsNames = option?.subItems.map(el => el.name) || [];
          return showCategoryOnResults
            ? [...acc, option?.name, ...subItemsNames]
            : [...acc, ...subItemsNames];
        }, [])
        .join(', ')}`;
    }

    return (
      <div className={styles['search-label-container']}>
        <span className={styles['search-label']}>{labelText}</span>
        <span>{resultText}</span>
      </div>
    );
  };

  const renderResults = () => {
    let visibleOptions = options;
    if (searchInputText.length) {
      visibleOptions = visibleOptions.filter((option: IMultiselectItem) => {
        return (
          option.name!.toLowerCase().indexOf(searchInputText.toLowerCase()) !==
          -1
        );
      });
    }

    return (
      <DropdownCheckboxInput
        data={visibleOptions}
        selectedItems={selectedItemsParsed}
        onChange={items => {
          onChange(items);
        }}
        isDisabled={props.isDisabled}
        itemWrapperClassName={classnames(styles['checkbox-items'], {
          [styles['no-label']]: !label,
        })}
      />
    );
  };

  const toggleResults = () => {
    if (loading || !options?.length || props.isDisabled) {
      return;
    }

    setIsActive(!isActive);
  };

  const isDisabled = loading || !options?.length || props.isDisabled;

  return (
    <div className={styles.container}>
      {label && <div className={styles.label}>{label}</div>}
      <div
        ref={searchContainerRef}
        className={classnames(styles['search-container'], {
          [styles['no-label']]: !label,
          [styles.disabled]: isDisabled,
        })}
      >
        <div>
          <div
            className={classnames(styles['result-bar'], {
              [styles['result-bar-disabled']]: isDisabled,
              [styles['hoverable']]: !isDisabled && !isActive,
              [styles['active']]: isActive,
            })}
            onClick={toggleResults}
          >
            {renderLabel()}
            <div className={styles['arrow-container']}>
              <img className={styles.arrow} src={arrowImageSrc} alt="" />
            </div>
          </div>

          <div className={styles['search-input-container']}>
            <input
              ref={searchInputRef}
              className={classnames(styles['search-input'], {
                [styles.active]: isActive,
              })}
              type="text"
              value={searchInputText}
              placeholder={searchPlaceholder}
              onChange={e => setSearchInputText(e.currentTarget.value)}
            />

            {searchInputText.length !== 0 && isActive && (
              <button
                className={styles['clear-search']}
                type="button"
                onClick={() => setSearchInputText('')}
              >
                <img
                  className={styles['clear-search-icon']}
                  src={ClearInputImage}
                  alt="Clear"
                />
              </button>
            )}
          </div>
        </div>

        <div
          className={classnames(styles.options, {
            [styles.active]: isActive,
          })}
        >
          {renderResults()}
        </div>
      </div>
    </div>
  );
};

export default GroupedOptionSearchFilter;
