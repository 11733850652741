import React, { useState } from 'react';
import {
  useApolloClient,
  DocumentNode,
  PureQueryOptions,
} from '@apollo/client';
import DocumentIcon from 'assets/images/icons/document.svg';
import { Col } from 'components/@codelitt/ay-design-library';
import Button from 'components/Button';
import DeleteModal from 'components/DeleteModal';
import { I18N_MODAL_MEDIA_DOCUMENT_TEXT_PATH } from 'components/ModalMedia/constants';
import styles from 'components/ModalMedia/Documents/DocumentItem/DocumentItem.module.scss';
import EditTagsModal from 'components/ModalMedia/Documents/DocumentItem/EditTagsModal';
import {
  forceFileDownload,
  getDocumentRefetchQueries,
} from 'components/ModalMedia/utils';
import { DOT_LG } from 'constants/placeholders';
import { DELETE_DOCUMENT_MUTATION } from 'graphql/documents';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyDocument } from 'interfaces/IPropertyDocument';
import { ErrorLogger } from 'services/ErrorLogger';
import { getDateFromNow, getFormatedTime } from 'utils/date';
import { translateText } from 'utils/i18n';
import { isImage } from 'utils/validation/url';

type Props = {
  property: IProperty;
  document: IPropertyDocument;
  onDelete?: () => void;
  refetchQueries?: PureQueryOptions[];
  documentCategoryQuery?: DocumentNode;
};

const DEFAULT_ERROR_MESSAGE = translateText(
  `${I18N_MODAL_MEDIA_DOCUMENT_TEXT_PATH}.deleteDocumentError`,
);

const DocumentItem: React.FC<Props> = props => {
  const client = useApolloClient();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    document,
    property,
    refetchQueries,
    onDelete,
    documentCategoryQuery,
  } = props;

  if (!document.url) return null;

  const deleteDocument = async () => {
    const documentId = document.id;
    setIsDeleting(true);

    try {
      const { errors } = await client.mutate({
        mutation: DELETE_DOCUMENT_MUTATION,
        variables: { documentId },
        refetchQueries:
          refetchQueries || getDocumentRefetchQueries(property?.id),
      });

      if (errors?.length) {
        ErrorLogger.log(DEFAULT_ERROR_MESSAGE);
      }

      onDelete?.();
    } catch (e) {
      ErrorLogger.log(e as any, DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  const getDocPreview = () => {
    if (isImage(document.url)) {
      return <img src={document.url} alt={document.category?.name} />;
    }

    return (
      <img
        src={DocumentIcon}
        alt={document.category?.name}
        className={styles['document-icon']}
      />
    );
  };

  const getExtension = (url?: string) => {
    return url
      ?.substring(url.lastIndexOf('.'))
      .replace('.', '')
      .toUpperCase();
  };

  return (
    <Col lg={4} md={4} sm={4} xs={4} wrapperClassName={styles['container']}>
      <div className={styles['document-container']}>
        <div className={styles['preview-container']}>{getDocPreview()}</div>
        <div className={styles['info-container']}>
          <div className={styles['header-container']}>
            <div className={styles['document-name-container']}>
              {document.name}
            </div>
            <div className={styles['document-actions-container']}>
              <Button
                onClick={() => {
                  forceFileDownload(document.url!);
                }}
                wrapperClassName={styles['document-download-btn']}
                type={'ghost'}
              />
              <Button
                onClick={() => {
                  setShowEditModal(true);
                }}
                wrapperClassName={styles['document-edit-btn']}
                type={'ghost'}
              />
              <Button
                onClick={() => {
                  setShowDeleteConfirmation(true);
                }}
                wrapperClassName={styles['document-delete-btn']}
                type={'ghost'}
              />
            </div>
          </div>
          <div className={styles['category-container']}>
            <div className={styles['category']}>{`${getExtension(
              document.url,
            )} ${
              document.category ? `${DOT_LG} ${document.category.name}` : ''
            }`}</div>
          </div>
          <div className={styles['user-container']}>
            <div className={styles['category']}>
              <span>
                {`${document.createdUser?.firstName} ${document.createdUser?.lastName}`}
              </span>
              {` ${DOT_LG} ${getDateFromNow(
                document.createdAt || '',
              )} ${DOT_LG} ${getFormatedTime(document.createdAt)}`}
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <EditTagsModal
          property={props.property}
          document={document}
          onSave={() => {
            setShowEditModal(false);
          }}
          onCancel={() => {
            setShowEditModal(false);
          }}
          documentCategoryQuery={documentCategoryQuery}
          refetchQueries={refetchQueries}
        />
      )}

      {showDeleteConfirmation && (
        <DeleteModal
          onClose={() => setShowDeleteConfirmation(false)}
          isDeleting={isDeleting}
          onDelete={deleteDocument}
        />
      )}
    </Col>
  );
};

export default DocumentItem;
