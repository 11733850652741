import { pie } from 'd3-shape';
import GraphConstants from '../graphConstants';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import { sum } from 'd3';
import { PropertyClass } from 'constants/propertyClass';
import { IStaticDonutGraphData } from 'interfaces/IStaticDonutGraphData';

const PERCENTAGE_MIN_SLICE = 2 / 100;

/**
 * This function will sort the donut slices by size or class.
 * If it should sort by title, the "+" and "-" variations will be considered, so "A+" will come before "A", and "A-" next.
 * The Trophy class will also take precedence over the others.
 *
 * @param sliceLeft
 * @param sliceRight
 * @param shouldSortByClass
 */
export const sortDonutSlices = (
  sliceLeft: IStaticDonutGraphData,
  sliceRight: IStaticDonutGraphData,
  shouldSortByClass?: boolean,
) => {
  if (shouldSortByClass) {
    const weight: any = { '+': -1, '-': 1 },
      leftSliceVariations = sliceLeft.title!.split(/(?=[+-])/),
      rightSliceVariations = sliceRight.title!.split(/(?=[+-])/);

    const leftText = leftSliceVariations[0];
    const rightText = rightSliceVariations[0];

    if (
      leftText === PropertyClass.Trophy &&
      rightText !== PropertyClass.Trophy
    ) {
      return -1;
    }

    if (
      rightText === PropertyClass.Trophy &&
      leftText !== PropertyClass.Trophy
    ) {
      return 1;
    }

    return (
      leftText[0].localeCompare(rightText[0]) ||
      rightText.localeCompare(leftText) ||
      (weight[leftSliceVariations[1]] || 0) -
        (weight[rightSliceVariations[1]] || 0)
    );
  } else if (sliceLeft?.order || sliceRight?.order) {
    return sliceLeft.order! - sliceRight.order!;
  } else {
    return sliceRight.totalSize! - sliceLeft.totalSize!;
  }
};

/**
 * This function will change the Donut Slices, to make sure no slices are smaller than PERCENTAGE_MIN_SLICE of the total.
 *
 * This will make the smaller slice bigger enough to be visible hoverable.
 * The value increased in the smaller slices, will be decreased in the bigger slices values.
 *
 * @param donutSlices
 * @param shouldSortByTitle
 */
export const normalizeData = (
  donutSlices: IDonutGraphData[],
  shouldSortByTitle?: boolean,
) => {
  if (!donutSlices.length) {
    return donutSlices;
  }
  donutSlices.sort((a: any, b: any) => {
    return b.totalSize! - a.totalSize!;
  });
  const totalSize = sum(donutSlices, slice => slice.totalSize);

  // This is the minimum value that a Slice can receive
  const minValue = Math.ceil(totalSize * PERCENTAGE_MIN_SLICE);

  let diffValues = 0;

  // Set the minimum value on the smaller slices
  let slicesUpdated = donutSlices.map(slice => {
    if (slice.totalSize < minValue) {
      // Add the difference to the sum, in order to control the amount that will be needed to remove from the bigger slices
      diffValues += minValue - slice.totalSize;
      slice.totalSize = minValue;
    }
    return slice;
  });

  // If we updated any slice value, this condition will decrease the diff in the bigger slices.
  if (diffValues > 0) {
    const diffToSpread =
      diffValues / slicesUpdated.filter(s => s.totalSize > minValue)?.length ||
      1;
    slicesUpdated = slicesUpdated.map(s => {
      const decreasedValue = Math.ceil(s.totalSize - diffToSpread);
      // Only decrease the totalSize if the end value will be bigger than the minValue
      if (s.totalSize > minValue && decreasedValue > minValue) {
        s.totalSize = decreasedValue;
      }
      return s;
    });

    const totalUpdated = sum(slicesUpdated, slice => slice.totalSize);
    if (totalUpdated < totalSize) {
      // Case there is still a difference in the totalSize, add this diff to the bigger slice, so it won't affect the scale
      slicesUpdated[0].totalSize += totalSize - totalUpdated;
    }
  }

  return slicesUpdated.sort((a: any, b: any) => {
    return sortDonutSlices(a, b, shouldSortByTitle);
  });
};

export default (data: any, shouldSortByTitle?: boolean) => {
  const donutPathGenerator = pie()
    .padAngle(GraphConstants.PADDING_ANGLE)
    .value((d: any) => d.totalSize)
    .sort((a: any, b: any) => {
      return sortDonutSlices(a, b, shouldSortByTitle);
    });

  return donutPathGenerator(normalizeData(data) as any);
};
