export enum LanguageEnum {
  EN = 'EN',
  EN_CA = 'EN_CA',
  EN_GB = 'EN_GB',
  FR = 'FR',
  FR_CA = 'FR_CA',
  DE = 'DE',
}

export const Languages: { [key: string]: string } = {
  EN: 'English',
  EN_CA: 'English (Canadian)',
  EN_GB: 'English (UK)',
  FR: 'French',
};
