const ACRE_SF = 43560;
const HECTARE_SM = 10000;

export const convertAcreToSf = (acre: number): number => acre * ACRE_SF;

export const convertSftoAcre = (sf: number): number => sf / ACRE_SF;

export const convertHectareToSm = (hectare: number): number =>
  hectare * HECTARE_SM;

export const convertSmtoHectare = (sm: number): number => sm / HECTARE_SM;
