export enum DataTypes {
  availabilities = 'availabilities',
  leases = 'leases',
  properties = 'properties',
  sales = 'sales',
  tims = 'tims',
}

export const getDataType = (data: any): DataTypes | null => {
  if (data?.leases || data?.lease) {
    return DataTypes.leases;
  }

  if (data?.sales || data?.sale) {
    return DataTypes.sales;
  }

  if (data?.properties || data?.property) {
    return DataTypes.properties;
  }

  if (data?.availabilities) {
    return DataTypes.availabilities;
  }

  if (data?.TIMs) {
    return DataTypes.tims;
  }

  return null;
};

export const getItemsToRender = (data: any): any[] => {
  let items: any[] = [];

  if (data?.sale || data?.sales) {
    items = data?.sale ? [data?.sale] : data?.sales?.results;
  }

  if (data?.lease || data?.leases) {
    items = data?.lease ? [data?.lease] : data?.leases?.results;
  }

  if (data?.property || data?.properties) {
    items = data?.property ? [data?.property] : data?.properties?.results;
  }

  if (data?.availabilities) {
    items = data?.availabilities?.results;
  }
  if (data?.TIMs) {
    items = data?.TIMs?.results;
  }

  return items;
};
