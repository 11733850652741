import { IGraphCoverageRatioResponse } from 'interfaces/graphs/coverageRatio';
import { PropertyTypes } from 'constants/propertyTypes';

export const hasDataForPropertyType = (
  propertyTypeName: string | undefined,
  data: IGraphCoverageRatioResponse | undefined,
): boolean => {
  if (!data || !propertyTypeName) return false;

  switch (propertyTypeName) {
    case PropertyTypes.industrial: {
      return (
        !!data.industrial &&
        !!data.marketAvgCoverageRatio &&
        !!data.propertyCoverageRatio
      );
    }

    case PropertyTypes.office: {
      return !!data.office && !!data.marketAvgFloorPlateSize;
    }

    case PropertyTypes.dataCenter: {
      return !!data.dataCenter && !!data.dataCenter.avgTotalPower;
    }

    case PropertyTypes.multifamily: {
      return !!data.multifamily && !!data.multifamily.avgUnitSizeSf;
    }

    default: {
      return !!data.marketAvgFloorPlateSize && !!data.propertyFloorPlateSize;
    }
  }
};
