import { capitalize } from 'lodash';
import { checkIfNumericFieldIsValid } from '../../utils';
import { IdName } from 'interfaces/IdName';
import { HistoricalTypes } from '../../constants';
import {
  ASSET_PERFORMANCE_COMPANY,
  ASSET_PERFORMANCE_PROPERTY,
} from 'graphql/historicalPerformance';
import {
  IAssetPerformanceCompany,
  IAssetPerformanceProperty,
} from 'interfaces/IHistoricalPerformance';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

type DonutLabelMapper = {
  [profileTypeKey: string]: {
    profileLabel: string;
    secondaryTitleLabel: {
      [historicalTypeKey: string]: string;
    };
    hoverTitleLabel: {
      [historicalTypeKey: string]: string;
    };
  };
};

export const getCurrentPerformanceQuery = (isCompany: boolean) =>
  isCompany ? ASSET_PERFORMANCE_COMPANY : ASSET_PERFORMANCE_PROPERTY;

export const isDonutGraphDataValid = (
  dataProperty?: IAssetPerformanceProperty,
  dataCompany?: IAssetPerformanceCompany,
): boolean => {
  const hasPropertyData =
    checkIfNumericFieldIsValid(dataProperty?.propertyTotalOccupiedSF) ||
    checkIfNumericFieldIsValid(dataProperty?.propertyTotalAvailableSFDirect) ||
    checkIfNumericFieldIsValid(dataProperty?.propertyTotalAvailableSFSublet) ||
    checkIfNumericFieldIsValid(dataProperty?.propertyTotalVacantSFDirect) ||
    checkIfNumericFieldIsValid(dataProperty?.propertyTotalVacantSFSublet);

  const hasCompanyData =
    checkIfNumericFieldIsValid(dataCompany?.totalOccupiedSF) ||
    checkIfNumericFieldIsValid(dataCompany?.totalAvailableSFDirect) ||
    checkIfNumericFieldIsValid(dataCompany?.totalAvailableSFSublet) ||
    checkIfNumericFieldIsValid(dataCompany?.totalVacantSFDirect) ||
    checkIfNumericFieldIsValid(dataCompany?.totalVacantSFSublet);

  return hasPropertyData || hasCompanyData;
};

export const checkIfCurrentPerformancePercentageIsValid = (
  propertyData: IAssetPerformanceProperty,
) => {
  const data = propertyData;

  const hasPercentages =
    checkIfNumericFieldIsValid(data?.compSetAvailablePercent) &&
    checkIfNumericFieldIsValid(data?.compSetVacantPercent);

  return (
    hasPercentages &&
    (data?.compSetAvailablePercent! > 0 ||
      data?.compSetVacantPercent! > 0 ||
      data?.propertyAvailablePercent! > 0 ||
      data?.propertyVacantPercent! > 0)
  );
};

export const getDonutTitles = (
  activeType: HistoricalTypes,
  profileType: PROFILE_TYPES,
  isCompSet?: boolean,
  showSecondaryLabel?: boolean,
) => {
  const TRANSLATED_OCCUPANCY_LABEL = translateText(
    `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.occupancy`,
  );
  const TRANSLATED_AVAILABILITY_LABEL = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.availability`,
  );
  const TRANSLATED_VACANCY_LABEL = translateText(
    'avantProperties.common.words.vacancy',
  );

  const labelMapper: DonutLabelMapper = {
    [PROFILE_TYPES.PROPERTY]: {
      profileLabel: isCompSet
        ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.compSet`)
        : capitalize(
            translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.building`),
          ),
      secondaryTitleLabel: {
        [HistoricalTypes.OCCUPIED]: translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.propertyOccupancy`,
        ),
        [HistoricalTypes.AVAILABLE]: translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.propertyAvailability`,
        ),
        [HistoricalTypes.VACANT]: translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.propertyVacancy`,
        ),
      },
      hoverTitleLabel: {
        [HistoricalTypes.OCCUPIED]: TRANSLATED_OCCUPANCY_LABEL,
        [HistoricalTypes.AVAILABLE]: TRANSLATED_AVAILABILITY_LABEL,
        [HistoricalTypes.VACANT]: TRANSLATED_VACANCY_LABEL,
      },
    },
    [PROFILE_TYPES.COMPANY]: {
      profileLabel: translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.portfolio`,
      ),
      secondaryTitleLabel: {
        [HistoricalTypes.OCCUPIED]: TRANSLATED_OCCUPANCY_LABEL,
        [HistoricalTypes.AVAILABLE]: TRANSLATED_AVAILABILITY_LABEL,
        [HistoricalTypes.VACANT]: TRANSLATED_VACANCY_LABEL,
      },
      hoverTitleLabel: {
        [HistoricalTypes.OCCUPIED]: TRANSLATED_VACANCY_LABEL,
        [HistoricalTypes.AVAILABLE]: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.leased`,
        ),
        [HistoricalTypes.VACANT]: TRANSLATED_OCCUPANCY_LABEL,
      },
    },
  };

  const labels = labelMapper[profileType];
  const profileLabel = labels.profileLabel;

  const mainTitle = `${profileLabel} \n${labels.hoverTitleLabel[activeType]}`;
  const secondaryTitle =
    `${showSecondaryLabel ? profileLabel : ''} ` +
    `${labels.secondaryTitleLabel[activeType]}`;

  return { mainTitle, secondaryTitle };
};

export const buildDonutGraphQueryParams = (
  propertyId?: number,
  companyId?: number,
  marketsFilter?: IdName[],
  propertySetId?: number,
) => {
  const isCompanyProfile = !!companyId;
  const marketIds = !!marketsFilter?.length
    ? marketsFilter.map(m => m.id)
    : undefined;

  return isCompanyProfile
    ? {
        companyId,
        marketIds,
        includeLongLeaseHolders: true,
      }
    : { propertyId, propertySetId };
};
