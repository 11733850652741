import { IdName } from 'interfaces/IdName';
import { isNil, values } from 'lodash';

export const fieldHasValue = (field?: any) =>
  !isNil(field) &&
  field !== '' &&
  (typeof field !== 'object' || values(field).some(x => x !== undefined));

export const getFieldValueOrNull = (field?: any) => {
  if (!fieldHasValue(field)) {
    return null;
  }

  return field;
};

export const idNameBuilder = (type: IdName) => ({
  id: type.id,
  name: type.name,
});

export const arrayToMap = (array: any[]) => {
  return array.reduce((prev, current) => {
    prev[current] = current;
    return prev;
  }, {});
};

export const deepCopy = <T>(obj: T) => {
  return JSON.parse(JSON.stringify(obj)) as T;
};
