import React from 'react';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import styles from '../../FormSection.module.scss';
import { SearchInputSize } from 'components/SearchInput/enums';
import PropertiesSearchInput from 'components/PropertiesSearchInput';
import { useTranslation } from 'react-i18next';
import {
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IQuerySearchInput } from 'components/SearchInput/interfaces';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
interface Props {
  id: string;
  value?: {
    id: number | undefined;
    name: string | undefined;
    primaryAddress: string | undefined;
  };
  initialValue?: string;
  required?: boolean;
  onChange: (property: IProperty) => void;
  onChangeText?: (text: any) => void;
  onClear: () => void;
  label?: string;
  isDisabled?: boolean;
  graphqlParams?: IQuerySearchInput;
  parseResults?: (data: IPropertyCompany[]) => IPropertyCompany[];
}

const SelectProperty: React.FC<Props> = ({
  id,
  value,
  initialValue,
  required,
  onChange,
  onChangeText,
  onClear,
  label,
  isDisabled,
  graphqlParams,
  parseResults,
}) => {
  const { t } = useTranslation();
  return (
    <FormControl
      label={label || t(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectProperty`)}
      required={required}
      wrapperClassName={styles['form-row']}
      disabled={isDisabled}
    >
      <PropertiesSearchInput
        initialValue={initialValue}
        inputId={id}
        searchInputProps={{ size: SearchInputSize.Small }}
        onClickSuggestion={onChange}
        property={value}
        graphqlParams={graphqlParams}
        parseResults={parseResults}
        placeholder={t(
          `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterAddress`,
        )}
        onClearSelectedSuggestion={onClear}
        isDisabled={isDisabled}
        onChangeText={onChangeText}
      />
    </FormControl>
  );
};

export default SelectProperty;
