import React from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';
import ModalWrapper from 'components/ModalWrapper';
import LoadingMessage from 'components/LoadingMessage';
import VerticalFormControl from 'components/VerticalFormControl';
import TextInput from 'components/Inputs/TextInput';
import { Container } from 'components/@codelitt/ay-design-library';
import { ConfirmationModalFooter } from 'components/ConfirmationModalFooter';
import { CompetitivePropertySelector } from 'components/CompetitivePropertySelector';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_ATTR_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMP_SET_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ColorNames } from 'constants/colorNames';

import styles from './CompSetModalForm.module.scss';
import { CompSetModalFormProps } from './types';
import PropertyCardList from '../PropertyCardList';
import { useFeaturedProperties } from './useFeaturedProperties';

const CompSetModalForm = ({
  propertySet,
  isEditing,
  updatePropertySet,
  onCancel = noop,
  onDelete = noop,
  onSubmit = noop,
  isSubmitting,
  isLoading,
}: CompSetModalFormProps) => {
  const title = isEditing
    ? translateText(`${I18N_AVANT_PROPERTY_COMP_SET_PATH}.editCompSet`)
    : translateText(
        `${I18N_AVANT_PROPERTY_ATTR_PATH}.compSet.label.createCompSet`,
      );

  const {
    removeProperty,
    compSetProperties,
    selectedFeaturedIds,
    targetProperties,
    onClickFeatured,
  } = useFeaturedProperties({
    initialProperties: propertySet?.properties ?? [],
    updatePropertySet,
  });

  const checkIfFormValid = (propertySet?: IPropertySet | null) => {
    return !!(
      propertySet?.name &&
      targetProperties?.length &&
      selectedFeaturedIds?.length
    );
  };

  return (
    <ModalWrapper
      fullScreen
      withCloseLabel
      withCloseButton
      onCancel={onCancel}
      footerWrapperClassName={styles['footer-wrapper']}
      footer={
        <ConfirmationModalFooter
          className={styles.footer}
          deleteTarget={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.compSet`,
          )}
          showDeleteButton={isEditing}
          onCancel={onCancel}
          onDelete={onDelete}
          onSave={onSubmit}
          isDisabled={isSubmitting || !checkIfFormValid(propertySet)}
          isSubmitting={isSubmitting}
        />
      }
    >
      <Container wrapperClassName={styles.container}>
        {isLoading ? (
          <LoadingMessage />
        ) : (
          <>
            <h2 className={styles.title}>{title}</h2>
            <div>
              <div className={styles['field-container']}>
                <VerticalFormControl
                  label={translateText(
                    `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.sectionName`,
                    {
                      sectionName: translateText(
                        `${I18N_PLATFORM_COMMON_WORD_PATH}.compSet`,
                      ),
                    },
                  )}
                >
                  <TextInput
                    fullWidth
                    inputClassName={classnames(
                      styles.field,
                      styles['comp-set-name-field'],
                    )}
                    value={propertySet?.name}
                    placeholder={translateText(
                      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.enterName`,
                    )}
                    onChange={name => updatePropertySet?.('name', name)}
                  />
                </VerticalFormControl>
              </div>
              <div className={styles['field-container']}>
                <VerticalFormControl
                  label={`${translateText(
                    `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.targetProperties`,
                  )} (${targetProperties?.length ?? 0}):`}
                >
                  <PropertyCardList
                    isDraggable={true}
                    hasFeature={true}
                    isPropertyFeatured={true}
                    noBorder={true}
                    saveContextOrderType="targetComps"
                    onClickFeatured={onClickFeatured}
                    properties={targetProperties ?? []}
                    onItemTopRightButtonClick={removeProperty}
                    cardBackgroundColor={ColorNames.ayGrey8Color}
                    itemsTopRightButtonClassName={
                      styles['button-remove-property']
                    }
                  />
                </VerticalFormControl>
              </div>
              <div className={styles['field-container']}>
                <CompetitivePropertySelector
                  hasFeature={true}
                  shouldShowRecommendations={true}
                  properties={propertySet?.properties}
                  mapper={data => data.filter(d => !d.featured)}
                  propertiesToOmit={
                    selectedFeaturedIds?.length ? selectedFeaturedIds : []
                  }
                  label={`${translateText(
                    `${I18N_PLATFORM_COMMON_WORD_PATH}.compSet`,
                  )} (${compSetProperties?.length ?? 0}):`}
                  propertyIdForRecommendations={
                    propertySet?.properties?.length
                      ? propertySet?.properties?.[0].id
                      : undefined
                  }
                  onChange={properties =>
                    updatePropertySet?.('properties', properties)
                  }
                  onClickFeatured={onClickFeatured}
                />
              </div>
            </div>
          </>
        )}
      </Container>
    </ModalWrapper>
  );
};

export { CompSetModalForm };
