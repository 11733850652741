import React from 'react';
import classNames from 'classnames';
import { DOT } from 'constants/placeholders';
import styles from './TenantCard.module.scss';

export const renderSubtitles = (
  subtitleList: string[],
  hasDot = true,
  isChip?: boolean,
  additionalStyles?: string[],
) => {
  if (!subtitleList?.length) return null;

  return subtitleList?.filter(Boolean)?.map((subtitle, index) => {
    if (!subtitle) return null;
    return (
      <React.Fragment key={index}>
        {hasDot && index > 0 && ` ${DOT} `}
        {isChip ? (
          <span
            className={classNames(styles['chip'], ...(additionalStyles ?? []))}
          >
            {subtitle}
          </span>
        ) : (
          <span>{subtitle}</span>
        )}
      </React.Fragment>
    );
  });
};
