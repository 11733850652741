import { TimeMeasurement } from 'constants/timeMeasurement';
import { ICompany } from './ICompany';
import { IProperty } from './IProperty';
import { ISpaceUse } from './ISpaceUse';
import { IdName } from './IdName';

export enum AvailabilityFields {
  AskingRent,
  AvailableSpace,
  LeaseType,
  SpaceUse,
  Suite,
  Floor,
  FullFloor,
  Term,
  FloorContiguousSpace,
  BuildingContiguousSpace,
  LandlordBrokerCompany,
  LandlordBrokerContact,
  TimeMeasurement,
}

export interface IAvailability {
  [key: string]: any;
  id?: number;
  property?: IProperty;
  spaceUse?: ISpaceUse;
  landlordBrokerCompany?: ICompany;
  askingRentMin?: number;
  askingRentMax?: number;
  askingRentType?: string;
  suite?: string;
  leaseType?: string;
  floor?: string;
  availableSpaceMin?: number;
  availableSpaceMax?: number;
  availableSpaceMinMt?: number;
  availableSpaceMaxMt?: number;
  floorContiguousSpace?: number;
  floorContiguousSpaceMt?: number;
  buildingContiguousSpace?: number;
  buildingContiguousSpaceMt?: number;
  landlordBrokerContacts?: string;
  comments?: string;
  status?: string;
  occupancy?: string;
  occupancyDate?: string;
  occupancyStatusType?: IdName;
  term?: string;
  fileId?: number;
  freeRentType?: string;
  freeRentMonths?: number;
  tenantImprovementAllowance?: number;
  officeSf?: number;
  officeSm?: number;
  typicalClearHeightMin?: number;
  typicalClearHeightMax?: number;
  typicalClearHeightMinMt?: number;
  typicalClearHeightMaxMt?: number;
  loadingDocks?: number;
  driveInDoors?: number;
  timeMeasurement?: TimeMeasurement;
  taxAndOpex?: number;
}

export type PropertyBlockAvailabilities = {
  propertyId?: number;
  primaryAddress?: string;
  latitude?: number;
  longitude?: number;
  propertyType?: IdName;
  propertySubType?: IdName;
  market?: IdName;
  subMarket?: IdName;
  buildingSize?: number;
  totalAvailability?: number;
  askingRentFS?: number;
  askingRentFSMonthly?: number;
  askingRentNNN?: number;
  askingRentNNNMonthly?: number;
  currency?: string;
};
