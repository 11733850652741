import { FindCompsSearchCriteriaService } from 'services/FindCompsSearchCriteriaService';
import { IChartQueryOptions } from 'interfaces/IChartQueryOptions';
import { isEmpty } from 'lodash';

export const getCompsQueryParams = (options: IChartQueryOptions): string => {
  const {
    property,
    company,
    market,
    submarket,
    customCriteria,
    leasesCriteria,
    salesCriteria,
    timsCriteria,
  } = options;

  let findCompsQueryParam = '';

  if (property?.id) {
    if (!isEmpty(customCriteria)) {
      findCompsQueryParam = FindCompsSearchCriteriaService.parseFiltersToUrlParams(
        {
          customCriteria: customCriteria || undefined,
        },
      );
    } else {
      findCompsQueryParam = FindCompsSearchCriteriaService.parseFiltersToUrlParams(
        {
          openSelectionCriteria: { unsavedList: [property.id] },
        },
      );
    }
  }

  if (company?.id || submarket?.id || market?.id) {
    findCompsQueryParam = FindCompsSearchCriteriaService.parseFiltersToUrlParams(
      {
        customCriteria: customCriteria || undefined,
        leasesCriteria: leasesCriteria || undefined,
        salesCriteria: salesCriteria || undefined,
        timsCriteria: timsCriteria || undefined,
      },
    );
  }
  return findCompsQueryParam;
};
