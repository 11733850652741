import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import locations from 'routes';
import { Row, Col } from 'components/@codelitt/ay-design-library';
import SwitchOptionButton from 'components/SwitchOptionButton';
import VictoryBarChart from 'components/VictoryBarChart';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import ProfileSubSection from 'components/ProfileSubSection';
import { VictoryBarStyle } from 'components/Graphs/VerticalGroupBarChart/types';
import MissingGraphData from 'components/MissingGraphData';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { BlockPropertyAvailability } from './types';
import {
  AvailabilitySizeSwitchOptions,
  getAvailabilitySizeSwitchOptions,
  IAvailabilitySizeOption,
  getBarLabel,
  parseMostExpensiveAndLargestData,
  getHighestChartTitle,
  getLargestChartTitle,
  getSectionTitle,
  ASKING_RENT_CHART_NAME,
  SIZE_CHART_NAME,
  parseGraphData,
  IAvailabilityOption,
} from './utils';
import styles from './MostExpensiveAndLargestCharts.module.scss';

// TODO - Remove some props and code when we migrate all references to use the new
// blockAvailabilities query structure created to handle these charts
interface Props {
  compSet?: IPropertySet;
  data?: any;
  leaseToggles: LeaseToggleOptions;
  mostExpensiveChartStyle?: VictoryBarStyle;
  largestChartStyle?: VictoryBarStyle;
  legends?: any;
  itemsLimit?: number;
  askingRentChartData?: BlockPropertyAvailability[];
  sizeChartData?: BlockPropertyAvailability[];
  isUsingNewQuery?: boolean;
  currency?: string;
}

const MostExpensiveAndLargestCharts: React.FC<Props> = ({
  compSet,
  data,
  leaseToggles,
  mostExpensiveChartStyle,
  largestChartStyle,
  legends,
  itemsLimit,
  askingRentChartData,
  sizeChartData,
  isUsingNewQuery,
  currency,
}) => {
  const history = useHistory();
  const [sizeOption, setSizeOption] = useState(IAvailabilitySizeOption.LARGEST);
  const { t } = useTranslation();
  const isLargest = sizeOption === IAvailabilitySizeOption.LARGEST;
  const handlePropertyClick = (datum: any) => {
    const { propertyId } = datum;

    history.push(locations.showProperty(propertyId));
  };

  let dataAskingCharts;
  let dataSizeChart;

  if (isUsingNewQuery) {
    dataAskingCharts = parseMostExpensiveAndLargestData(
      ASKING_RENT_CHART_NAME,
      askingRentChartData,
      leaseToggles,
      isLargest,
      true,
      currency,
    );

    dataSizeChart = parseMostExpensiveAndLargestData(
      SIZE_CHART_NAME,
      sizeChartData,
      leaseToggles,
      isLargest,
      false,
      currency,
    );
  } else {
    dataAskingCharts = parseGraphData(
      data,
      compSet,
      IAvailabilityOption.PRICE,
      sizeOption,
      leaseToggles,
      itemsLimit,
    );
    dataSizeChart = parseGraphData(
      data,
      compSet,
      IAvailabilityOption.SIZE,
      sizeOption,
      undefined,
      itemsLimit,
    );
  }

  const handleSizeToggleChange = (value: any) => {
    setSizeOption(value);
  };

  const sizeToggles = () => {
    return (
      <div className={styles['availability-size-toggles']}>
        <SwitchOptionButton
          buttonClass={styles['switch-button']}
          wrapperClassName={styles['types-switch']}
          activeOption={AvailabilitySizeSwitchOptions[sizeOption]}
          onChange={({ value }) => handleSizeToggleChange(value)}
          options={getAvailabilitySizeSwitchOptions(t)}
        />
      </div>
    );
  };

  const sectionTitle = getSectionTitle(isLargest);
  const highestChartTitle = getHighestChartTitle(isLargest);

  const largestChartTitle = getLargestChartTitle(isLargest);
  return (
    <ProfileSubSection
      title={sectionTitle}
      headerContent={sizeToggles()}
      headerContentToRight
      titleColSize={8}
    >
      <Row>
        <Col lg={6} md={6} sm={6} xs={4}>
          {isEmpty(dataAskingCharts.data) ? (
            <MissingGraphData
              title={highestChartTitle}
              wrapperClassName={styles['missing-graph-data']}
              skin="dark"
              fontSize="sm"
            />
          ) : (
            <div id="victory-chart">
              <VictoryBarChart
                title={highestChartTitle}
                legend={legends}
                horizontal
                onAxisClick={handlePropertyClick}
                barData={dataAskingCharts}
                getBarLabel={getBarLabel}
                chartStyle={mostExpensiveChartStyle}
                useCustomTextYAxis
                customTextLength={15}
              />
            </div>
          )}
        </Col>
        <Col lg={6} md={6} sm={6} xs={4}>
          {isEmpty(dataSizeChart.data) ? (
            <MissingGraphData
              title={largestChartTitle}
              wrapperClassName={styles['missing-graph-data']}
              skin="dark"
              fontSize="sm"
            />
          ) : (
            <div id="victory-chart">
              <VictoryBarChart
                title={largestChartTitle}
                legend={legends}
                horizontal
                onAxisClick={handlePropertyClick}
                barData={dataSizeChart}
                getBarLabel={getBarLabel}
                chartStyle={largestChartStyle}
                useCustomTextYAxis
                customTextLength={15}
              />
            </div>
          )}
        </Col>
      </Row>
    </ProfileSubSection>
  );
};

export default MostExpensiveAndLargestCharts;
