import React from 'react';
import styles from '../CardHeader.module.scss';
import { IProperty } from 'interfaces/IProperty';
import { IMarket } from 'interfaces/IMarket';
import MultipleMarketsLink from 'components/MultipleMarketsLink';
import MultiplePropertiesLink from 'components/MultiplePropertiesLink';
import { IScoop } from 'interfaces/IScoop';

interface Props {
  property?: IProperty;
  market?: IMarket;
  scoop?: IScoop;
  small?: boolean;
}

const MultipleLink: React.FC<Props> = ({ property, market, scoop, small }) => {
  const renderMulpipleLink = () => {
    if (market) {
      return (
        <MultipleMarketsLink
          markets={scoop?.markets || []}
          preselectedMarket={market}
        />
      );
    } else if (property) {
      return (
        <MultiplePropertiesLink
          preselectedProperty={property}
          properties={scoop?.properties ?? (property ? [property] : [])}
          small={small}
        />
      );
    }
  };

  return <div className={styles['property-link']}>{renderMulpipleLink()}</div>;
};

export default MultipleLink;
