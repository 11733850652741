import React, { useContext } from 'react';
import { IScoopComment } from 'interfaces/IScoopComment';
import classnames from 'classnames';
import styles from './Comment.module.scss';
import UserMailToLink from 'components/UserMailToLink';
import { DOT } from 'constants/placeholders';
import { formatDate } from 'utils/formatters/date';
import { translateText } from 'utils/i18n';
import { authContext } from 'contexts/AuthContext';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../utils';

export interface Props {
  comments: IScoopComment[];
}

const Comment: React.FC<Props> = ({ comments }) => {
  const comment = sortBy(comments, comment =>
    dayjs(comment.createdAt),
  ).reverse()[0];

  const { user } = useContext(authContext);
  const isCommentUser = user.id === comment.userId;
  const createdAt = formatDate(comment.createdAt, DATE_FORMAT, false);

  return (
    <div>
      <div className={classnames(styles['container'])}>
        <div className={styles.col}>
          <b className={styles.comment}>
            {translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.comment`)}:
          </b>
          <span className={classnames(styles['user-name'], styles['colored'])}>
            {isCommentUser ? (
              translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.you`)
            ) : (
              <UserMailToLink user={comment.user} />
            )}
          </span>
          <span> {DOT} </span>
          {createdAt}
        </div>
      </div>
      {comment.comment}
    </div>
  );
};

export default Comment;
