export interface IModalInfo {
  title: string;
  description: string;
  button: string;
}

export enum MODALS {
  NOTIFICATION = 'notification',
  MEDIA = 'media-modal',
  SCOOP = 'scoop-modal',
  EDIT = 'edit-property',
  GALLERY = 'gallery-modal',
  HISTORICAL_PERFORMANCE = 'historical-performance',
}
