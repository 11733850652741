import React, { useState } from 'react';
import styles from 'components/ModalMedia/Documents/UploadItem/UploadItem.module.scss';
import DocumentIcon from 'assets/images/icons/document.svg';
import DocumentCategoryPicker from 'components/ModalMedia/Documents/DocumentCategoryPicker';
import { IdName } from 'interfaces/IdName';
import { IRadioItem } from 'components/Inputs/RadioInput';
import { Col } from 'components/@codelitt/ay-design-library';
import { FileToUpload } from 'components/ModalMedia/types';
import Button from 'components/Button';
import { DocumentNode } from '@apollo/client';

type Props = {
  document: FileToUpload;
  onCategoryChange: (docKey: string, category: IdName) => void;
  onFileNameChange: (docKey: string, newFileName: string) => void;
  onDelete: (imgKey: string) => void;
  documentCategoryQuery?: DocumentNode;
};

const UploadItem: React.FC<Props> = props => {
  const [category, setCategory] = useState<IdName>();

  const {
    document,
    onDelete,
    onCategoryChange,
    onFileNameChange,
    documentCategoryQuery,
  } = props;

  return (
    <Col lg={3} md={3} sm={4} xs={4}>
      <div className={styles['documents-container']}>
        <div className={styles['container-doc']}>
          <img src={DocumentIcon} alt={category?.name} />
          <div className={styles['trash']}>
            <Button
              onClick={() => {
                onDelete(document.key);
              }}
              wrapperClassName={styles['delete-btn']}
              type={'ghost'}
            />
          </div>
        </div>
        <div className={styles['progress-bar']}>
          <div
            className={styles['progress-bar-fill']}
            style={{ width: `${document.uploadProgress}%` }}
          />
        </div>
        <DocumentCategoryPicker
          query={documentCategoryQuery}
          selectedCategory={{
            value: category?.id,
            label: category?.name,
          }}
          fileName={document.name}
          onFileNameChange={(newName: string) => {
            onFileNameChange(document.key, newName);
          }}
          onCategoryChange={(selectedItem: IRadioItem) => {
            const category = {
              id: selectedItem.value!,
              name: selectedItem.label,
            };
            setCategory(category);
            onCategoryChange(document.key, category);
          }}
        />
      </div>
    </Col>
  );
};

export default UploadItem;
