import numeral from 'numeral';

import { getUserLocaleKey, parseLocaleKeyString } from 'utils/i18n';
import { I18nLanguage } from 'constants/i18n';

export const setNumeralLocale = (localeKey: string = I18nLanguage.en) => {
  const locale = parseLocaleKeyString(localeKey)?.toLowerCase();

  import(`numeral/locales/${locale}`)
    .then(() => {
      numeral.locale(locale);
    })
    .catch(() => {
      numeral.locale(I18nLanguage.en);
    });
};

const initialize = () => {
  const localeKey = getUserLocaleKey();

  setNumeralLocale(localeKey);
};

initialize();

export default numeral;
