import React from 'react';
import { ILeaseInput } from '../interfaces/inputs/ILeaseInput';

interface ILeaseContext {
  leaseInput: ILeaseInput | null;
  setLeaseInput: (lease: ILeaseInput | null) => void;
}

export const LeaseInputContext = React.createContext<ILeaseContext>({
  leaseInput: null,
  setLeaseInput: () => null,
});

const { Provider } = LeaseInputContext;

type Props = {
  children: React.ReactNode;
};

const LeaseProvider: React.FC<Props> = ({ children }) => {
  const [leaseInput, setLeaseInput] = React.useState<ILeaseInput | null>(null);

  const handleSetLeaseInput = (newLeaseValue: ILeaseInput | null) => {
    setLeaseInput(newLeaseValue);
  };

  return (
    <Provider value={{ setLeaseInput: handleSetLeaseInput, leaseInput }}>
      {children}
    </Provider>
  );
};

export default LeaseProvider;
