import { QUARTER_MONTHS } from './date';
import { DOT_LG } from 'constants/placeholders';

export const validateAndBuildQuarterlyData = (
  data: any,
  currentYear: number,
  currentQuarter: number,
  dateFormatterFn: (item: any, quarter: number) => string = (item, quarter) =>
    `${item.year}-${QUARTER_MONTHS[quarter - 1]}-01`,
) => {
  const filteredData = data?.filter(
    (item: any) =>
      item?.year < currentYear ||
      (item?.year === currentYear &&
        item?.quarter &&
        item?.quarter <= currentQuarter),
  );

  return (
    filteredData?.map((item: any) => {
      const q = item.quarter as number;
      return {
        ...item,
        date: dateFormatterFn(item, q),
      };
    }) || []
  );
};

export const formatQuarterForTooltip = (
  quarter?: number | string,
  isQuarterly?: boolean,
) => {
  return `${isQuarterly ? DOT_LG + ' Q' + quarter : ''}`;
};
