import { ISaleInput } from 'interfaces/inputs/ISaleInput';
import { defaultToIsoFormat } from 'utils/formatters/date';
import { convertDecimalToFloat } from 'utils/formatters/number';

const SALE_DATE_FIELDS = ['date', 'dateOnMarket', 'longLeaseHoldExpiry'];

export const processSaleBeforeEdit = (input: ISaleInput): ISaleInput => {
  const sale: ISaleInput = { ...input };

  if (!!sale.capRate) {
    sale.capRate = +(sale.capRate * 100).toFixed(2);
  }

  if (!!sale.interestPercent) {
    sale.interestPercent = convertDecimalToFloat(sale.interestPercent, 2);
  }

  if (!!sale.occupancy) {
    sale.occupancy = convertDecimalToFloat(sale.occupancy, 2);
  }

  SALE_DATE_FIELDS.forEach(dateField => {
    if (!!sale[dateField]) {
      sale[dateField] = defaultToIsoFormat(sale[dateField]);
    }
  });

  return sale;
};
