import { CURRENCY_CODES_BY_COUNTRY } from 'constants/currencies';
import { UnitOfMeasurementCode } from 'constants/unitOfMeasurement';
import { getCurrencySymbol } from 'utils/formatters/currency';

export const I18N_PLATFORM_COMMON_WORD_PATH = 'avantPlatform.common.words';
export const I18N_PROPERTY_COMMON_WORD_PATH = 'avantProperties.common.words';
export const I18N_PLATFORM_COMMON_LABELS_PATH = 'avantPlatform.common.labels';
export const I18N_AVANT_PROPERTY_ATTR_PATH = 'avantPlatform.attributes';
export const I18N_AVANT_PROPERTY_ROLES_PATH = 'avantPlatform.roles';
export const I18N_AVANT_PROPERTY_LABEL_PATH = 'avantProperties.labels';
export const I18N_AVANT_PROPERTY_TEXT_PATH = 'avantProperties.texts';
export const I18N_AVANT_PROPERTY_PAGES = 'avantProperties.pages';
export const I18N_AVANT_PROPERTY_COMPONENTS = 'avantProperties.components';
export const I18N_AVANT_PROPERTY_NAVIGATION = 'avantProperties.navigation';
export const I18N_AVANT_PROPERTY_ENUMERATORS = 'avantProperties.enumerators';
export const I18N_AVANT_PROPERTY_BUTTONS_PATH = 'avantProperties.buttons';
export const I18N_AVANT_PROPERTY_PURSUIT_LABEL =
  'avantProperties.pursuit.label';

export const I18N_AVANT_PROPERTY_COMMON_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.common`;
export const I18N_AVANT_PROPERTY_CHART_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.charts`;
export const I18N_AVANT_PROPERTY_SALES_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.sales`;
export const I18N_AVANT_PROPERTY_HISTORICAL_PERFORMANCE_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.historicalPerformance`;
export const I18N_AVANT_PROPERTY_UPLOAD_METHOD_MODAL_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.uploadMethodModal`;

export const I18N_AVANT_PROPERTY_COMMON_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.common`;
export const I18N_AVANT_PROPERTY_CHART_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.charts`;
export const I18N_AVANT_PROPERTY_UPLOAD_METHOD_MODAL_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.uploadMethodModal`;

export const I18N_AVANT_PROPERTY_UPLOAD_METHOD_MODAL_BUTTONS_PATH = `${I18N_AVANT_PROPERTY_BUTTONS_PATH}.uploadMethodModal`;

export const I18N_AVANT_PROPERTY_HEADINGS_PATH =
  'avantProperties.headings.common';
export const I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH =
  'avantProperties.subHeadings.common';
export const I18N_AVANT_PLATFORM_LEASE_LABEL_PATH = `${I18N_AVANT_PROPERTY_ATTR_PATH}.lease.label`;
export const I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH = `${I18N_AVANT_PROPERTY_ATTR_PATH}.lease.prompt`;
export const I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH = `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.label`;
export const I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH = `${I18N_AVANT_PROPERTY_ATTR_PATH}.property.prompt`;
export const I18N_AVANT_PLATFORM_SALE_LABEL_PATH = `${I18N_AVANT_PROPERTY_ATTR_PATH}.sale.label`;
export const I18N_AVANT_PLATFORM_COMP_SET_LABEL_PATH = `${I18N_AVANT_PROPERTY_ATTR_PATH}.compSet.label`;
export const I18N_AVANT_PROPERTY_LABEL_SALES_PATH =
  'avantProperties.labels.sales';
export const I18N_AVANT_PROPERTY_LABEL_LEASES_PATH =
  'avantProperties.labels.leases';
export const I18N_USER_SETTINGS_LABEL_PATH = `${I18N_AVANT_PROPERTY_ATTR_PATH}.userSettings.label`;
export const I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH =
  'avantPlatform.roles.company.label';
export const I18N_PLATFORM_ROLES_COMPANY_ACTION_PATH =
  'avantPlatform.roles.company.action';
export const I18N_PLATFORM_ROLES_COMPANY_PROMPT_PATH =
  'avantPlatform.roles.company.prompt';

export const I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH = `${I18N_AVANT_PROPERTY_PAGES}.home.labels`;
export const I18N_AVANT_PROPERTY_COMP_SET_PATH = `${I18N_AVANT_PROPERTY_PAGES}.compSet`;
export const I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.compSet`;
export const I18N_AVANT_PROPERTY_AGENCY_ASSIGNMENT_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.agencyAssignments`;
export const I18N_AVANT_PLATFORM_LANGUAGES = 'avantPlatform.languages';

export const I18N_PROJECT_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.projects`;
export const I18N_PURSUIT_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.pursuits`;

export enum I18nLanguage {
  en = 'en',
  en_GB = 'en-gb',
  en_CA = 'en-ca',
  fr = 'fr',
  fr_CA = 'fr-ca',
  de = 'de',
}

export type MeasurementParams = {
  totalSize: number;
  currencySymbol?: string;
  unitOfMeasurement: string;
  language?: I18nLanguage;
};

export const MEASUREMENT_PARAMS_INIT = {
  totalSize: 0,
  currencySymbol: getCurrencySymbol(CURRENCY_CODES_BY_COUNTRY.US),
  unitOfMeasurement: UnitOfMeasurementCode.US,
};
