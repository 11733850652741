import React from 'react';
import classnames from 'classnames';
import styles from '../DonutGraph.module.scss';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import {
  onMouseOutDonutSlice,
  onMouseOverDonutSlice,
} from 'components/Graphs/DonutGraph/Elements/mouseFunctions';

interface Props {
  tagsItem: IDonutGraphData[];
  activeTag?: string;
  onHover: (tag?: IDonutGraphData) => void;
  onClick: (tag?: IDonutGraphData) => void;
  firstSliceColor: string;
  secondSliceColor: string;
  secondaryColor?: string;
  activeColor: string;
  donutId: number;
}

const DonutTags: React.FC<Props> = (props: Props) => {
  let hoverTimeout: any = null;
  const renderTag = (tag: IDonutGraphData) => {
    if (!tag.title) {
      return null;
    }

    const isActive = tag.title === props.activeTag;
    return (
      <div
        key={tag.title}
        onMouseOver={() => {
          hoverTimeout && clearTimeout(hoverTimeout);
          onMouseOverDonutSlice(
            tag,
            () => {
              props.onHover(tag);
            },
            props.activeColor,
            props.secondaryColor || props.activeColor,
            props.donutId,
          );
        }}
        onMouseOut={() => {
          hoverTimeout && clearTimeout(hoverTimeout);
          hoverTimeout = setTimeout(() => {
            onMouseOutDonutSlice(
              () => {
                props.onHover();
              },
              props.firstSliceColor,
              props.secondSliceColor,
              props.donutId,
            );
          }, 100);
        }}
        onClick={() => props.onClick(tag)}
        className={classnames(styles.tag, styles.capitalize, {
          [styles.active]: isActive,
        })}
      >
        {tag.title}
      </div>
    );
  };

  return <div className={styles.tags}>{props.tagsItem.map(renderTag)}</div>;
};

export default DonutTags;
