import Icon from 'components/Icon';
import classnames from 'classnames';
import { colors } from 'constants/colors';
import React, { useEffect, useCallback } from 'react';
import styles from './NotificationMessage.module.scss';

const DEFAULT_TIME_TO_SHOW = 5000;

interface Props {
  closeOnClick?: boolean;
  dismissOnHover?: boolean;
  isSuccess?: boolean;
  onClose?: () => void;
  onRightTextClick?: () => void;
  rightText?: string;
  show: boolean;
  text: string;
  wrapperClassName?: string;
}

const NotificationMessage: React.FC<Props> = ({
  closeOnClick,
  dismissOnHover = true,
  isSuccess,
  onClose,
  onRightTextClick,
  rightText,
  show,
  text,
  wrapperClassName,
}) => {
  const hasClickableRightText = !!onRightTextClick && !!rightText;

  const handleClose = useCallback(() => onClose?.(), [onClose]);

  const handleMouseLeave = () => !rightText && dismissOnHover && handleClose();

  const getIcon = (isArrow = false) => {
    const iconData = {
      name: isArrow ? 'arrowRight' : isSuccess ? 'check' : 'clear',
      className: isArrow ? styles['right-arrow-night-icon'] : styles['icon'],
      color: isArrow
        ? colors.ayNightBlueColor
        : isSuccess
        ? colors.ayGrassGreenColor
        : colors.ayMainRedColor,
    };

    return (
      <Icon
        name={iconData.name}
        color={iconData.color}
        className={iconData.className}
      />
    );
  };

  const getIconElement = () => {
    const icon = getIcon();
    return !isSuccess && onClose ? (
      <button
        onClick={handleClose}
        className={classnames({
          [styles['close-button']]: onClose,
        })}
      >
        {icon}
      </button>
    ) : (
      icon
    );
  };

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(handleClose, DEFAULT_TIME_TO_SHOW);
      return () => clearTimeout(timeout);
    }
  }, [show, handleClose]);

  return (
    <div
      className={classnames(
        wrapperClassName,
        styles['container'],
        styles[isSuccess ? 'success' : 'error'],
        { [styles['visible']]: show },
      )}
      onMouseLeave={handleMouseLeave}
      onClick={() => closeOnClick && handleClose()}
      data-testid="notification-message"
      role="alert"
    >
      <div className={styles['content-left']}>
        {getIconElement()}
        <span>{text}</span>
      </div>
      {hasClickableRightText && (
        <button
          onClick={onRightTextClick}
          className={styles['right-text-button']}
        >
          <span>{rightText}</span>
          {getIcon(true)}
        </button>
      )}
    </div>
  );
};

export default NotificationMessage;
