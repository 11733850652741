import { max, min } from 'd3';
import { ITransactionVolumeDataPoint } from './interfaces';
import { MAX_YEARS_OF_DATA } from './index';
import { CURRENCY_CODES_BY_COUNTRY } from 'constants/currencies';
import { CountryCodes } from 'constants/countryCodes';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';

export const getMinMaxValues = (data: ITransactionVolumeDataPoint[]) => {
  const maxPurchasedValue = +max(data, (d: ITransactionVolumeDataPoint) => {
    return d.valuePurchased;
  })!;

  const maxSoldValue = +max(data, (d: ITransactionVolumeDataPoint) => {
    return d.valueSold;
  })!;

  const minPurchasedValue = +min(data, (d: ITransactionVolumeDataPoint) => {
    return d.valuePurchased;
  })!;

  const minSoldValue = +min(data, (d: ITransactionVolumeDataPoint) => {
    return d.valueSold;
  })!;

  const minValue = Math.min(minPurchasedValue, minSoldValue);
  const maxValue = Math.max(maxPurchasedValue, maxSoldValue);

  return {
    minValue,
    maxValue,
  };
};

const zeroNullValues = (transactions: ITransactionVolumeDataPoint[]) => {
  transactions.forEach(t => {
    Object.keys(t).forEach(key => {
      if (t[key] === null) {
        t[key] = 0;
      }
    });
  });

  return transactions;
};

/**
 * This function will create fake data for the years we don't have data for, so we can show them on the graph.
 */
export const createEmptyYearsDataPoints = (
  transactions?: ITransactionVolumeDataPoint[],
) => {
  if (!transactions || transactions.length === 0) {
    return [];
  }

  const currentYear = new Date().getFullYear();
  const firstYear = currentYear - MAX_YEARS_OF_DATA;
  const nonNullTransactions = zeroNullValues(transactions);
  const finalDataPoints = [];
  const currencyCode = nonNullTransactions[0]?.currencyCode;
  const measurementSystem = nonNullTransactions[0]?.measurementSystem;
  for (let year = firstYear; year <= currentYear; year++) {
    const dataPoint = nonNullTransactions.find(t => t.year === year) || {
      year,
      valuePurchased: 0,
      countPropertiesPurchased: 0,
      totalSFPurchased: 0,
      totalSquareMetersPurchased: 0,
      valueSold: 0,
      countPropertiesSold: 0,
      totalSFSold: 0,
      totalSquareMetersSold: 0,
      currencyCode: currencyCode || CURRENCY_CODES_BY_COUNTRY[CountryCodes.US],
      measurementSystem: measurementSystem || UnitOfMeasurementCode.US,
    };
    finalDataPoints.push(dataPoint);
  }

  return finalDataPoints;
};

export const getUnitOfMeasurement = (measurementSystem?: string) =>
  getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    measurementSystem,
  );

export const getAreaSoldAndPurchased = (
  transaction: ITransactionVolumeDataPoint,
) => {
  const {
    totalSFPurchased,
    totalSFSold,
    totalSquareMetersSold,
    totalSquareMetersPurchased,
    measurementSystem,
  } = transaction;

  const shouldUseMeters = measurementSystem === UnitOfMeasurementCode.EU;
  const totalAreaPurchased = shouldUseMeters
    ? totalSquareMetersPurchased
    : totalSFPurchased;
  const totalAreaSold = shouldUseMeters ? totalSquareMetersSold : totalSFSold;

  return { totalAreaPurchased, totalAreaSold };
};
