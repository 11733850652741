import { gql } from '@apollo/client';

export const CREATE_BUSINESS_PARK_MUTATION = gql`
  mutation createBusinessPark($businessPark: BusinessParkInput!) {
    createBusinessPark(businessPark: $businessPark) {
      ... on BusinessPark {
        id
        name
        marketId
      }
      ... on EntityError {
        message
      }
    }
  }
`;
