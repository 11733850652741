import { maxBy, meanBy, noop } from 'lodash';

import pinImage from 'assets/images/pin.png';
import { MapConstants, VisibilityLayer } from 'components/MapboxMap/constants';
import { addHeatmapLayer, loadImage } from 'components/MapboxMap/utils';
import { IProperty } from 'interfaces/IProperty';
import { getGeoJSON } from 'utils/maps/mapBox';

import {
  PROPERTY_SOURCE_ID,
  PROPERTY_LAYER_ID,
  ZOOM_THRESHOLD_PERFORMANCE_MODE,
  PIN_IMAGE_ID,
} from './constants';

export const addPropertySource = (
  map: mapboxgl.Map,
  properties: IProperty[],
  onClickPropertyPin?: (propertyId: string | null) => void,
  onMouseEnterPropertyPin?: (propertyId?: number) => void,
) => {
  // We don't need to add the source again if the map already has it.
  if (map.getSource(PROPERTY_SOURCE_ID)) {
    return;
  }

  const geoJSON = getGeoJSON(properties, property => ({
    propertyId: property.id,
    [MapConstants.weightPropertyName]: property.buildingSize,
    onClickPropertyPin,
    onMouseEnterPropertyPin,
  }));

  map.addSource(PROPERTY_SOURCE_ID, {
    type: 'geojson',
    data: geoJSON,
  });
};

export const addPropertyPinLayer = (
  map: mapboxgl.Map,
  onClickPropertyPin: (propertyId: string | null) => void = noop,
  onMouseEnterPropertyPin: (propertyId?: number) => void = noop,
) => {
  // We don't need to add the layer again if the map already has it.
  if (map.getLayer(PROPERTY_LAYER_ID)) {
    return;
  }

  loadImage(map, pinImage, PIN_IMAGE_ID, () => {
    map.addLayer({
      id: PROPERTY_LAYER_ID,
      source: PROPERTY_SOURCE_ID,
      type: 'symbol',
      minzoom: ZOOM_THRESHOLD_PERFORMANCE_MODE,
      layout: {
        'icon-image': PIN_IMAGE_ID,
        'icon-allow-overlap': true,
        'symbol-placement': 'point',
      },
    });

    map.on('click', PROPERTY_LAYER_ID, (event: any) => {
      onClickPropertyPin(event.features[0].properties.propertyId);
    });

    map.on('mouseenter', PROPERTY_LAYER_ID, (event: any) => {
      onMouseEnterPropertyPin(event.features[0].properties.propertyId);
    });
  });
};

export const loadLayersForPerformanceMode = (
  map: mapboxgl.Map,
  properties: IProperty[],
  onClickPropertyPin?: (propertyId: string | null) => void,
  onMouseEnterPropertyPin?: (propertyId?: number) => void,
) => {
  addPropertySource(
    map,
    properties,
    onClickPropertyPin,
    onMouseEnterPropertyPin,
  );

  addHeatmapLayer(
    map,
    {
      min: 0,
      max:
        maxBy(properties, MapConstants.weightPropertyName)?.buildingSize || 0,
      avg: meanBy(properties, MapConstants.weightPropertyName),
    },
    PROPERTY_SOURCE_ID,
    VisibilityLayer.visible,
    { maxzoom: ZOOM_THRESHOLD_PERFORMANCE_MODE },
  );
  addPropertyPinLayer(map, onClickPropertyPin);
};
