import { gql } from '@apollo/client';
import {
  SALE_BASIC_DATA_FRAGMENT,
  SALE_BASIC_DATA_NO_IMAGES_FRAGMENT,
  SALE_PAGINATION_DATA_FRAGMENT,
} from 'graphql/sales/fragments';

export const GET_SALE_QUERY = gql`
  query sale($id: ID!) {
    sale(id: $id) {
      ...saleBasicData
      partOfPortfolio
      numberOfBuildings
      assetProfile {
        id
        name
      }
      type {
        id
        name
      }
      industriesOfTenants {
        id
        name
      }
      underbidders {
        id
        name
      }
      comments
    }
  }
  ${SALE_BASIC_DATA_FRAGMENT}
`;

export const SEARCH_SALES_QUERY = gql`
  query sales($search: SaleSearchInput!) {
    sales(search: $search) {
      ...salePaginationData
      results {
        ...saleBasicData
      }
    }
  }
  ${SALE_PAGINATION_DATA_FRAGMENT}
  ${SALE_BASIC_DATA_FRAGMENT}
`;

export const SEARCH_SALES_QUERY_PAGINATION = gql`
  query sales($search: SaleSearchInput!) {
    sales(search: $search) {
      total
      totalPages
      ...salePaginationData
      results {
        ...saleBasicDataNoImages
      }
    }
  }
  ${SALE_PAGINATION_DATA_FRAGMENT}
  ${SALE_BASIC_DATA_NO_IMAGES_FRAGMENT}
`;

export const SALES_RECENT_QUERY = gql`
  query salesRecent($propertyId: ID!, $propertySetId: Int) {
    salesRecent(propertyId: $propertyId, propertySetId: $propertySetId) {
      sales {
        id
        date
        value
        size
        sizeMt
        measurementSystem
        price
      }
      marketAverage
      marketTotalSF
      marketTotalSquareMeters
      marketTotalCount
      marketTotalPrice
    }
  }
`;

export const GET_SALE_TYPES = gql`
  query getSaleTypes {
    saleTypes {
      id
      name
    }
  }
`;

export const SALES_HOLD_PERIOD_QUERY = gql`
  query salesHoldPeriod($propertyId: ID!, $propertySetId: Int) {
    salesHoldPeriod(propertyId: $propertyId, propertySetId: $propertySetId) {
      holdPeriodDate
      buildingSaleSF
      buildingSaleSquareMeters
      holdPeriodYears
      mktAverageHoldYears
      marketTotalSF
      marketTotalSquareMeters
      marketTotalCount
    }
  }
`;
