import React from 'react';
import classnames from 'classnames';
import { omit } from 'lodash';
import { currencyInputFormatter } from 'utils/formatters/currency';

import TextInput, { Props as TextInputProps } from '../TextInput';
import styles from './CurrencyInput.module.scss';

interface Props extends TextInputProps {
  currencySymbol?: string | null;
  className?: string;
  useDefaultCurrencyFormatter?: boolean;
  currencyClassName?: string;
}

const CurrencyInput: React.FC<Props> = props => {
  const defaultFormatter = (value: string, isFocused?: boolean) =>
    currencyInputFormatter(value, true, isFocused);

  const currencySymbol = props.currencySymbol && (
    <span
      className={classnames(styles['currency-symbol'], props.currencyClassName)}
    >
      {props.currencySymbol}
    </span>
  );

  return (
    <TextInput
      startNode={currencySymbol}
      wrapperClassName={styles['currency-wrapper']}
      inputClassName={styles['currency-input']}
      formatter={
        props.formatter ||
        (props.useDefaultCurrencyFormatter ? defaultFormatter : undefined)
      }
      {...omit(props, ['currencySymbol', 'formatter'])}
    />
  );
};

export default CurrencyInput;
