import React, { useState } from 'react';
import classnames from 'classnames';
import styles from 'components/ModalMedia/Pictures/PictureItem/PictureItem.module.scss';
import { getVideoThumbnailUrl, transformImageUrl } from 'utils/cloudinary/urls';
import { Col } from 'components/@codelitt/ay-design-library';
import { useApolloClient } from '@apollo/client';
import { DELETE_MEDIA_MUTATION } from 'graphql/images';
import ConfirmationModal from 'components/ConfirmationModal';
import Button from 'components/Button';
import { forceFileDownload } from 'components/ModalMedia/utils';
import EditTagsModal from 'components/ModalMedia/Pictures/PictureItem/EditTagsModal';
import CoverImage from 'assets/images/icons/cover-image.svg';
import { ErrorLogger } from 'services/ErrorLogger';
import { IMedia } from 'interfaces/IMedia';
import { isVideo } from 'utils/media';
import { IMediaUploadQueryConfig } from 'components/ModalMedia/types';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_COMMON_LABEL_PATH } from 'constants/i18n';
import {
  I18N_MODAL_MEDIA_PICTURE_LABEL_PATH,
  I18N_MODAL_MEDIA_PICTURE_TEXT_PATH,
} from '../../constants';
import { PROFILE_TYPES } from 'constants/profileTypes';

type Props = {
  hasCategories?: boolean;
  queryConfig: IMediaUploadQueryConfig;
  media: IMedia;
  onClickImage: (image: IMedia) => void;
  onDelete?: () => void;
  onUpdateCategory?: () => void;
  profileType?: PROFILE_TYPES;
};

const IMAGE_WIDTH = 256;
const IMAGE_HEIGHT = 120;
const DEFAULT_ERROR_MESSAGE = translateText(
  `${I18N_MODAL_MEDIA_PICTURE_TEXT_PATH}.deleteImageError`,
);

const PictureItem: React.FC<Props> = ({
  hasCategories,
  media,
  queryConfig,
  onDelete,
  onUpdateCategory,
  onClickImage,
  profileType,
}) => {
  const client = useApolloClient();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  if (!media.url) return null;
  const imgSrc = isVideo(media)
    ? getVideoThumbnailUrl(
        media.url,
        `h_${IMAGE_HEIGHT},w_${IMAGE_WIDTH}`,
        '.png',
      )
    : transformImageUrl(media.url, IMAGE_WIDTH, IMAGE_HEIGHT);

  const deleteMedia = async () => {
    const mediaId = media.id;
    setIsDeleting(true);

    try {
      const { errors } = await client.mutate({
        mutation: DELETE_MEDIA_MUTATION,
        variables: { mediaId },
        refetchQueries: queryConfig.refetchQueries,
      });

      if (errors && errors.length) {
        console.error(DEFAULT_ERROR_MESSAGE, errors);
      }

      onDelete?.();
    } catch (e) {
      ErrorLogger.log(e as any, DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  return (
    <Col lg={3} md={3} sm={4} xs={4}>
      <div
        className={classnames(styles['picture-container'], {
          [styles['picture-cover']]: media.isCover,
        })}
      >
        <div
          className={styles['container-img']}
          onClick={() => {
            onClickImage(media);
          }}
        >
          {!!media.isCover && (
            <div className={styles['cover-icon-container']}>
              <img
                alt={translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.cover`,
                )}
                className={styles['cover-icon']}
                src={CoverImage}
              />
            </div>
          )}
          <img
            className={styles['item-img']}
            src={imgSrc}
            alt={media.category?.name}
          />
        </div>
        <div className={styles['actions-container']}>
          <h6 className={styles['category']}>
            {media.category?.name || media.publicId}
            {media?.subAmenity && (
              <small className={styles['sub-category']}>
                {media.subAmenity.name}
              </small>
            )}
          </h6>
          <div className={styles['action-buttons']}>
            <Button
              onClick={() => {
                forceFileDownload(media.url!);
              }}
              wrapperClassName={styles['image-download-btn']}
              type={'ghost'}
            />
            {hasCategories && (
              <Button
                onClick={() => {
                  setShowEditModal(true);
                }}
                wrapperClassName={styles['image-edit-btn']}
                type={'ghost'}
              />
            )}
            <Button
              onClick={() => {
                setShowDeleteConfirmation(true);
              }}
              wrapperClassName={styles['image-delete-btn']}
              type={'ghost'}
            />
          </div>
        </div>
      </div>

      {showEditModal && (
        <EditTagsModal
          media={media}
          isVideo={isVideo(media)}
          propertyTypeId={queryConfig.propertyTypeId}
          onSave={() => {
            setShowEditModal(false);
            onUpdateCategory?.();
          }}
          onCancel={() => {
            setShowEditModal(false);
          }}
          profileType={profileType}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal
          onCloseModal={() => setShowDeleteConfirmation(false)}
          negativeButton={{
            label: isDeleting
              ? `${translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`,
                )}`
              : translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`,
                ),
            isDisabled: isDeleting,
            onclick: deleteMedia,
          }}
          positiveButton={{
            label: translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`,
            ),
            onclick: () => setShowDeleteConfirmation(false),
          }}
          header={translateText(
            `${I18N_MODAL_MEDIA_PICTURE_LABEL_PATH}.deleteImageButtonLabel`,
          )}
          paragraph={[
            translateText(
              `${I18N_MODAL_MEDIA_PICTURE_TEXT_PATH}.deleteImageWarning`,
            ),
          ]}
        />
      )}
    </Col>
  );
};

export default PictureItem;
