import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import DropzoneImage from 'assets/images/dropzone.svg';
import {
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
} from 'constants/i18n';
import { KVFilesToUpload } from 'components/ModalMedia/types';
import { buildFileKey } from 'components/ModalMedia/utils';

import styles from './Dropzone.module.scss';

export enum AcceptedFiles {
  imagesAndVideos = 'image/*,video/mp4,video/x-m4v,video/*',
  images = 'image/*',
  comps = '.xlsx,.xls,.pdf,.csv',
}

type PropTypes = {
  onChange?: (files: any) => void;
  multipleFile?: boolean;
  small?: boolean;
  acceptedFiles?: AcceptedFiles;
  setDocumentsToUpload?: (
    value: React.SetStateAction<KVFilesToUpload | null>,
  ) => void;
};

const Dropzone: React.FC<PropTypes> = ({
  onChange,
  multipleFile = false,
  small,
  acceptedFiles,
  setDocumentsToUpload,
}: PropTypes) => {
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const processFiles = (acceptedFiles: File[]) => {
      acceptedFiles.forEach(f => {
        const fileToUpload = {
          key: buildFileKey(f),
          uploadProgress: 0,
          name: f.name,
          categoryId: null,
          file: f,
          preview: URL.createObjectURL(f),
        };

        const image = {
          [fileToUpload.key]: fileToUpload,
        };

        setDocumentsToUpload?.(value => ({
          ...value,
          ...image,
        }));
      });
    };

    setDocumentsToUpload
      ? processFiles(acceptedFiles)
      : onChange?.(acceptedFiles);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: multipleFile,
    accept: acceptedFiles,
  });

  return (
    <div
      {...getRootProps({
        className: classNames(styles.container, {
          [styles['small']]: small,
        }),
      })}
    >
      <input {...getInputProps()} />
      <div className={styles['upload-logo']}>
        <img
          className={styles['image']}
          src={DropzoneImage}
          alt={t(`${I18N_AVANT_PROPERTY_LABEL_PATH}.dropzone.uploadLogo`)}
        />
      </div>
      <p
        className={styles['upload-text']}
        dangerouslySetInnerHTML={{
          __html: t(`${I18N_AVANT_PROPERTY_TEXT_PATH}.dropzone.infoMessage`, {
            interpolation: { escapeValue: false },
          }),
        }}
      />
    </div>
  );
};

export default Dropzone;
