import React, { useState } from 'react';
import styles from './CompsComments.module.scss';
import Icon from 'components/Icon';
import { t } from 'i18next';
import {
  I18N_PLATFORM_COMMON_LABELS_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import classNames from 'classnames';

interface Props {
  label?: string;
  comments?: string;
  hideNote?: boolean;
  className?: string;
}

const CompsComments: React.FC<Props> = ({
  className,
  label = t(`${I18N_PLATFORM_COMMON_WORD_PATH}.note_plural`),
  comments,
  hideNote,
}) => {
  const [noteEnabled, setNoteEnabled] = useState(false);

  if (!comments) return null;

  if (hideNote && !noteEnabled) {
    return (
      <div
        className={styles['see-note-contanier']}
        onClick={() => setNoteEnabled(true)}
      >
        <>
          <Icon name="note" className={styles.icon} />{' '}
          {t(`${I18N_PLATFORM_COMMON_LABELS_PATH}.seeNote`)}
        </>
      </div>
    );
  }

  return (
    <p
      className={classNames(
        className,
        styles.comment,
        hideNote && styles['hide-margin'],
      )}
    >
      <span>{`${label}: `}</span>
      {comments}
    </p>
  );
};

export default CompsComments;
