import { DASH_PLACEHOLDER, DOT } from 'constants/placeholders';
import { ILease } from 'interfaces/ILease';
import { IActivity } from 'interfaces/IActivity';
import { buildLeaseCardHeader } from 'components/Leases/buildLeaseCardHeader';
import { formatDate } from 'utils/formatters/date';
import { buildBuyersDetails, buildPriceDetails } from './SaleDetails';
import {
  buildScoopCategories,
  getAuthorName,
  buildTenantLeaseDetails,
} from './utils';
import {
  getMultiplePropertiesAddressesShort,
  getPropertyAddress,
} from 'utils/formatters/property';
import { buildTagsListItems } from 'components/Sales/buildTagsListItems';
import {
  buildPricingQuoteDate,
  buildTagsListItems as buildPriceQuotesTagsListItems,
} from 'components/PricingQuotes/PropertyPricingQuoteCard/buildTagsListItems';
import { PricingQuoteCardField } from 'components/PricingQuotes/PropertyPricingQuoteCard/fields';
import { SaleField } from 'components/Sales/fields';
import ISale from 'interfaces/ISale';
import { IPricingQuote } from 'interfaces/IPricingQuote';
import { IdName } from 'interfaces/IdName';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';

interface Params {
  emails: string[];
  additionalComments?: string;
  activity: IActivity;
}

const EMPTY_NEWLINE = '\n';

interface SaleDetail {
  title: string;
  value: string;
}

const buildSaleDetails = (sale: ISale): SaleDetail[] => {
  const titles = [
    'Seller',
    'Cap Rate',
    'Seller Broker',
    'Buyer Broker',
    'Partial Interest',
  ];

  const fields = buildTagsListItems(sale, [
    SaleField.Size,
    SaleField.Sellers,
    SaleField.CapRate,
    SaleField.SellersBrokers,
    SaleField.BuyersBrokers,
    SaleField.InterestPercent,
  ]);

  return fields
    .map((field, index) => {
      return {
        title: titles[index],
        value: field.name!,
      };
    })
    .filter(item => !!item?.value);
};

const buildPriceQuoteDetails = (priceQuote: IPricingQuote): IdName[] => {
  const fields = buildPriceQuotesTagsListItems(priceQuote, [
    PricingQuoteCardField.TI,
    PricingQuoteCardField.FreeRentMonths,
    PricingQuoteCardField.TermInMonths,
    PricingQuoteCardField.LeaseType,
    PricingQuoteCardField.Size,
    PricingQuoteCardField.Floors,
    PricingQuoteCardField.Source,
  ]);

  return fields;
};

const buildLeaseEmailBody = (lease: ILease): string => {
  const title = lease.tenantCompany?.name || '';
  const subtitle = buildLeaseCardHeader(lease);
  const tag = '#Lease';
  const leaseDetails = buildTenantLeaseDetails(lease);

  return [title, subtitle, tag, ...leaseDetails]
    .filter(Boolean)
    .join(EMPTY_NEWLINE);
};

const buildScoopEmailBody = (activity: IActivity): string => {
  const { scoop, user } = activity;
  const title = scoop?.properties
    ?.map(p => getPropertyAddress(p))
    .join(` ${DOT} `);
  const date = formatDate(scoop?.createdAt, 'MMM YYYY');
  const text = scoop?.text;
  const authorName = `By ${getAuthorName(user!)}`;
  const scoopTags = buildScoopCategories(scoop?.scoopCategories!);

  return [title, date, text, scoopTags, authorName]
    .filter(Boolean)
    .join(EMPTY_NEWLINE);
};

const buildSaleEmailBody = (activity: IActivity): string => {
  const { sale = {} } = activity;
  const title = getPropertyAddress(activity.property);
  const tag = '#Sale';
  const purchaseDate = `${translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.purchased`,
  )} ${formatDate(sale?.date, 'MMM YYYY')}`;
  const buyers = buildBuyersDetails(sale);
  const priceDetails = buildPriceDetails(sale);
  const saleDetails = buildSaleDetails(sale)
    .map(detail => `${detail.title} ${detail.value}`)
    .join(` ${DOT} `);

  return [title, tag, purchaseDate, buyers, priceDetails, saleDetails]
    .filter(Boolean)
    .join(EMPTY_NEWLINE);
};

const buildPricingQuoteEmailBody = (activity: IActivity): string => {
  const { propertyPriceQuote } = activity;
  const title = getPropertyAddress(activity.property);
  const tag = '#Pricing Quote';
  const purchaseDate = buildPricingQuoteDate(propertyPriceQuote!);
  const buyers = propertyPriceQuote?.tenantCompany?.name;
  const tags = buildPriceQuoteDetails(propertyPriceQuote!)
    .map(detail => detail.name)
    .join(` ${DOT} `);

  return [tag, purchaseDate, title, buyers, tags]
    .filter(Boolean)
    .join(EMPTY_NEWLINE);
};

const buildEmailContent = (activity: IActivity): string => {
  let content = '';

  if (activity.lease) {
    const lease = { ...activity.lease, property: activity.property };
    content = buildLeaseEmailBody(lease);
  }

  if (activity.scoop) {
    content = buildScoopEmailBody(activity);
  }

  if (activity.sale) {
    content = buildSaleEmailBody(activity);
  }

  if (activity.propertyPriceQuote) {
    content = buildPricingQuoteEmailBody(activity);
  }

  return content;
};

const buildMailToUrl = (params: Params) => {
  const { property, scoop } = params.activity;

  const content = buildEmailContent(params.activity);
  const recipients = params.emails.join(';');
  const subject = scoop
    ? getMultiplePropertiesAddressesShort(scoop.properties || [])
    : [getPropertyAddress(property), property?.name]
        .filter(Boolean)
        .join(` ${DASH_PLACEHOLDER} `);
  const body = !!params.additionalComments
    ? `${params.additionalComments}${EMPTY_NEWLINE}${content}`
    : content;

  return `mailto:${recipients}?subject=${subject}&body=${encodeURIComponent(
    body,
  )}`;
};

export default buildMailToUrl;
