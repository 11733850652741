import React from 'react';
import classnames from 'classnames';
import { IScoopComment } from 'interfaces/IScoopComment';
import { DOT } from 'constants/placeholders';
import dateFormats from 'constants/dateFormats';
import {
  getRelativeDate,
  defaultToIsoFormat,
  formatDate,
} from 'utils/formatters/date';
import UserMailToLink from 'components/UserMailToLink';
import { IUser } from 'interfaces/IUser';
import Button from 'components/Button/new';
import styles from './ScoopComment.module.scss';

interface Props {
  comment: IScoopComment;
  isDisabled: boolean;
  onDeleteClick: (commentId: number) => void;
  onEditClick: (comment: IScoopComment) => void;
  user?: IUser;
}

const ScoopComment: React.FC<Props> = ({
  comment,
  isDisabled,
  onDeleteClick,
  onEditClick,
  user,
}) => {
  const date = getRelativeDate(defaultToIsoFormat(comment.updatedAt!));
  const time = formatDate(comment.updatedAt, dateFormats.TIME_12_HOUR, false);
  const isMe = comment?.userId === user?.id;
  const scoopCommentDate = `${date} ${` ${DOT} `} ${time}`;

  return (
    <div
      className={classnames(styles.container, {
        [styles.disabled]: isDisabled,
      })}
      data-testid="scoop-comment"
    >
      <h2 className={styles['comment-info']}>
        <span className={styles['user-name']}>
          {isMe ? `You` : <UserMailToLink user={comment.user} />}
        </span>
        {` ${DOT} `}
        {scoopCommentDate}
      </h2>
      <p className={styles.text}>{comment.comment}</p>
      {isMe && (
        <div className={styles.actions}>
          <Button
            description={'Edit comment'}
            icon="edit"
            size="xs"
            id="edit-comment"
            iconSize={0.75}
            onClick={() => onEditClick(comment)}
            type="contextual-transparent"
            disabled={isDisabled}
          />
          <Button
            description={'Delete comment'}
            icon="delete"
            size="xs"
            id="delete-comment"
            iconSize={0.75}
            onClick={() => onDeleteClick(comment.id)}
            type="delete"
            disabled={isDisabled}
          />
        </div>
      )}
    </div>
  );
};

export default ScoopComment;
