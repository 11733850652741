import { IdName } from 'interfaces/IdName';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';
export const DOCK_CONFIGURATION_OPTIONS: IdName[] = [
  {
    id: 1,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.frontLoad`),
  },
  {
    id: 2,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.rearLoad`),
  },
  {
    id: 3,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.sideLoad`),
  },
  {
    id: 4,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.crossDock`),
  },
];
