import { PropertyClass } from 'constants/propertyClass';
import { ageTypes } from 'constants/ageTypes';
import { sizeTypes } from 'constants/sizeTypes';
import { SearchOperations } from 'constants/searchOperations';
import { ISearchFieldFiltersOption } from 'interfaces/ISearch';
import { ONE_MILLION } from 'constants/numbers';

export enum PropertyDonutsChartsIDs {
  DonutPropertyClass,
  DonutPropertySubtype,
  DonutPropertyAge,
  DonutPropertySize,
}

const tagsOrderValues = {
  class: {
    [PropertyClass['Trophy+']]: 1,
    [PropertyClass.Trophy]: 2,
    'A+': 3,
    [PropertyClass.A]: 4,
    'A-': 5,
    'B+': 6,
    [PropertyClass.B]: 7,
    [PropertyClass.C]: 8,
  },
  age: {
    [ageTypes.inDevelopment]: 1,
    [ageTypes['2020s']]: 2,
    [ageTypes['2010s']]: 3,
    [ageTypes['2000s']]: 4,
    [ageTypes['1990s']]: 5,
    [ageTypes['1980s']]: 6,
    [ageTypes['1970s']]: 7,
    [ageTypes['1960s']]: 8,
    [ageTypes['1950s']]: 9,
    [ageTypes['Pre-1950']]: 10,
  },
  size: {
    [sizeTypes['1M+']]: 1,
    [sizeTypes['500K-1M']]: 2,
    [sizeTypes['250K-500K']]: 3,
    [sizeTypes['100K-250K']]: 4,
    [sizeTypes['0-100K']]: 5,
  },
};

export const tagsOrder: { [key: string]: number } = {
  ...tagsOrderValues.class,
  ...tagsOrderValues.age,
  ...tagsOrderValues.size,
};

const currentYear = new Date().getUTCFullYear();
const slicesFiltersValues = {
  age: {
    [ageTypes.inDevelopment]: {
      op: SearchOperations.gte,
      value: `${currentYear}`,
    },
    [ageTypes['2020s']]: {
      op: SearchOperations.between,
      range: { start: 2020, end: 2029 },
    },
    [ageTypes['2010s']]: {
      op: SearchOperations.between,
      range: { start: 2010, end: 2019 },
    },
    [ageTypes['2000s']]: {
      op: SearchOperations.between,
      range: { start: 2000, end: 2009 },
    },
    [ageTypes['1990s']]: {
      op: SearchOperations.between,
      range: { start: 1990, end: 1999 },
    },
    [ageTypes['1980s']]: {
      op: SearchOperations.between,
      range: { start: 1980, end: 1989 },
    },
    [ageTypes['1970s']]: {
      op: SearchOperations.between,
      range: { start: 1970, end: 1979 },
    },
    [ageTypes['1960s']]: {
      op: SearchOperations.between,
      range: { start: 1960, end: 1969 },
    },
    [ageTypes['1950s']]: {
      op: SearchOperations.between,
      range: { start: 1950, end: 1959 },
    },
    [ageTypes['Pre-1950']]: { op: SearchOperations.lte, value: '1950' },
  },
  size: {
    [sizeTypes['1M+']]: {
      op: SearchOperations.gte,
      value: ONE_MILLION.toString(),
    },
    [sizeTypes['500K-1M']]: {
      op: SearchOperations.between,
      range: { start: 500000, end: ONE_MILLION },
    },
    [sizeTypes['250K-500K']]: {
      op: SearchOperations.between,
      range: { start: 250000, end: 500000 },
    },
    [sizeTypes['100K-250K']]: {
      op: SearchOperations.between,
      range: { start: 100000, end: 250000 },
    },
    [sizeTypes['0-100K']]: { op: SearchOperations.lte, value: '100000' },
  },
};

export const slicesFilters: { [key: string]: ISearchFieldFiltersOption } = {
  ...slicesFiltersValues.age,
  ...slicesFiltersValues.size,
};
