import React, { useState } from 'react';
import { IdName } from 'interfaces/IdName';
import { ICompany } from 'interfaces/ICompany';
import FormControl from 'components/FormControl';
import { ErrorLogger } from 'services/ErrorLogger';
import CompanyFilter from 'components/CompanyFilter';
import { INotification } from 'constants/INotification';
import { ModalNames } from 'constants/CompanyUpdatesModal';
import { SearchInputSize } from 'components/SearchInput/enums';
import NotificationMessage from 'components/NotificationMessage';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import ModalCompanyMutations from 'components/ModalCompanyMutations';
import { CompanyFieldsNames } from '../FormSection/CompanyForm/constants';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export interface Props {
  aliasPlaceholder?: (suggestionName: string) => string;
  btnLabel?: string;
  companies?: IdName[];
  hasLabel?: boolean;
  inputId?: string;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isRequired?: boolean;
  isSearchIconLeftVisible?: boolean;
  label?: string;
  onClearSelectedSuggestion?: () => void;
  onCompanySelection: (newValue: any) => void;
  onRemoveItem?: (value: IdName) => void;
  placeholder?: string;
  size?: SearchInputSize;
  wrapperClassName?: string;
  searchOnlyCompanies?: boolean;
}

const CREATE_COMPANY_DATA = {
  domain: '',
  headquarters: '',
  industry: undefined,
  name: '',
  stockCode: '',
  website: '',
  headquarterProperty: undefined,
};

const CompanySearchCombo: React.FC<Props> = ({
  aliasPlaceholder,
  btnLabel,
  companies,
  hasLabel = true,
  inputId,
  isDisabled,
  isMultiple,
  isRequired,
  isSearchIconLeftVisible,
  label,
  onClearSelectedSuggestion,
  onCompanySelection,
  onRemoveItem,
  placeholder,
  size,
  wrapperClassName,
  searchOnlyCompanies,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotification>({
    isVisible: false,
    hasError: false,
    message: '',
  });
  const [companyData, setCompanyData] = useState<ICompany>(CREATE_COMPANY_DATA);

  const handleCreateCompanyDataUpdate = (field: string, value: any) => {
    const createCompanyNewData = {
      ...companyData,
      [field]: field === CompanyFieldsNames.INDUSTRY ? value?.[0] : value,
    };
    setCompanyData(createCompanyNewData);
  };

  const handleNotification = (
    hasError: boolean,
    name: string,
    errorMessage?: string,
  ) => {
    const message = hasError
      ? errorMessage || `Company ${name} could not be created`
      : `New company ${name} was created`;

    if (hasError) {
      ErrorLogger.log(message);
    }

    setNotification({
      isVisible: true,
      hasError,
      message,
    });
  };

  const handleModalOnClose = (data: any) => {
    setIsModalOpen(false);
    setCompanyData(CREATE_COMPANY_DATA);
    // raw & custom allow to return both kinds of data
    // and let the parent choose which one to use.
    const newData =
      !notification.hasError && data?.createCompany
        ? {
            raw: {
              id: data.createCompany.id,
              name: companyData.name,
            },
            custom: {
              id: data.createCompany.id,
              ...companyData,
            },
          }
        : {
            raw: null,
            custom: CREATE_COMPANY_DATA,
          };

    onCompanySelection(newData);
  };

  const onChangeText = (value?: string) =>
    !value?.length && onCompanySelection(null);

  const renderInput = () => (
    <CompanyFilter
      onChangeText={value => onChangeText(value)}
      aliasPlaceholder={aliasPlaceholder}
      inputId={inputId}
      placeholder={placeholder}
      isMultiple={isMultiple || false}
      onClearSelectedSuggestion={onClearSelectedSuggestion}
      onRemoveItem={(selectedValue: IdName) =>
        onRemoveItem && onRemoveItem(selectedValue)
      }
      onSelectItem={(selectedValue: SearchOptionsType) =>
        onCompanySelection(selectedValue)
      }
      selectedItems={companies || []}
      customOption={{
        btnLabel:
          btnLabel || t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterBuyerName`),
        onSelection: (value: string) => {
          handleCreateCompanyDataUpdate('name', value);
          setIsModalOpen(true);
        },
      }}
      isDisabled={isDisabled}
      isSearchIconLeftVisible={isSearchIconLeftVisible}
      size={size}
      searchOnlyCompanies={searchOnlyCompanies}
    />
  );

  return (
    <>
      {hasLabel ? (
        <FormControl
          label={label}
          required={isRequired || false}
          wrapperClassName={wrapperClassName}
          disabled={isDisabled}
        >
          {renderInput()}
        </FormControl>
      ) : (
        renderInput()
      )}

      {isModalOpen && (
        <ModalCompanyMutations
          companyData={companyData}
          modalType={ModalNames.CREATE}
          handleNotification={handleNotification}
          onDataUpdate={handleCreateCompanyDataUpdate}
          onAction={(data: any) => handleModalOnClose(data)}
        />
      )}

      <NotificationMessage
        text={notification.message}
        show={notification.isVisible}
        isSuccess={!notification.hasError}
        onClose={() =>
          setNotification({
            isVisible: false,
            hasError: false,
            message: '',
          })
        }
      />
    </>
  );
};

export default CompanySearchCombo;
