import { gql } from '@apollo/client';

export const SHARE_MESSAGE_MUTATION = gql`
  mutation shareMessage($sharedMessage: SharedMessageInput!) {
    shareMessage(sharedMessage: $sharedMessage) {
      messageId
    }
  }
`;

export const SHARE_ACTIVITIES_MESSAGE_MUTATION = gql`
  mutation shareActivitiesMessage(
    $search: ActivitySearchInput
    $numberOfDays: Int
  ) {
    shareActivitiesMessage(search: $search, numberOfDays: $numberOfDays) {
      messageId
    }
  }
`;
