import React, { useState } from 'react';
import { IdName } from 'interfaces/IdName';
import FormControl from 'components/FormControl';
import styles from '../../FormSection.module.scss';
import SpaceUseSelector from 'components/SpaceUseSelector';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PLATFORM_LEASE_LABEL_PATH } from 'constants/i18n';

interface Props {
  value?: IdName;
  required?: boolean;
  propertyType: string;
  onChange: (field: string, value: any) => void;
}

const SpaceUse: React.FC<Props> = ({
  value,
  onChange,
  required = false,
  propertyType,
}) => {
  const [shouldRender, setShouldRender] = useState(false);

  return (
    <FormControl
      isMultiline
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.spaceUse`)}
      hidden={!shouldRender}
    >
      <SpaceUseSelector
        type={propertyType}
        selectedItem={{
          id: value?.id,
          name: value?.name,
        }}
        containerWrapperClassName={styles['radio-input-container']}
        onDataLoad={data => {
          setShouldRender(data?.spaceUses?.length > 0);
        }}
        onChange={(spaceUse: IdName | null) => {
          if (spaceUse?.id) {
            onChange('spaceUse', { id: spaceUse.id });
          }
        }}
      />
    </FormControl>
  );
};

export default SpaceUse;
