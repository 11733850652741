import React, { useCallback, useState } from 'react';
import classnames from 'classnames';

import Button from 'components/Button/new';
import ConfirmationModal from 'components/ConfirmationModal';
import { Container } from 'components/@codelitt/ay-design-library';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

import styles from './ConfirmationModalFooter.module.scss';
import { ConfirmationModalFooterProps } from './types';

const ConfirmationModalFooter = ({
  className,
  buttonContainerClassName,
  cancelButtonClassName,
  deleteButtonClassName,
  submitButtonClassName,
  onCancel,
  onSave,
  onDelete,
  isDisabled,
  isSubmitting,
  showDeleteButton,
  deleteTarget,
  saveButtonLabel = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.saveAndApply`,
  ),
  cancelButtonLabel = translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`),
}: ConfirmationModalFooterProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const hideConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const displayConfirmationModal = useCallback(() => {
    setShowConfirmationModal(true);
  }, []);

  return (
    <>
      <footer
        className={classnames(className, styles['confirmation-modal-footer'])}
      >
        <Container
          wrapperClassName={classnames(
            buttonContainerClassName,
            styles['button-container'],
            {
              [styles['with-delete']]: showDeleteButton,
            },
          )}
        >
          {showDeleteButton && (
            <Button
              size="l"
              type="delete"
              icon="delete"
              onClick={displayConfirmationModal}
              label={
                deleteTarget
                  ? translateText(
                      `${I18N_PLATFORM_COMMON_WORD_PATH}.deleteSectionName`,
                      { sectionName: deleteTarget },
                    )
                  : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.delete`)
              }
              disabled={isDisabled}
              wrapperClassName={classnames(
                deleteButtonClassName,
                styles.button,
                styles['delete-button'],
              )}
            />
          )}
          <div>
            <Button
              size="l"
              label={cancelButtonLabel}
              type="neutral"
              onClick={onCancel}
              disabled={isSubmitting}
              wrapperClassName={classnames(
                cancelButtonClassName,
                styles.button,
                styles['cancel-button'],
              )}
            />
            <Button
              size="l"
              type="main"
              onClick={onSave}
              label={saveButtonLabel}
              id="save-and-apply-button"
              disabled={isDisabled}
              wrapperClassName={classnames(
                submitButtonClassName,
                styles.button,
                styles['submit-button'],
              )}
            />
          </div>
        </Container>
      </footer>

      {showConfirmationModal && (
        <ConfirmationModal
          onCloseModal={hideConfirmationModal}
          negativeButton={{
            label: isSubmitting
              ? translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.pleaseWait`,
                )
              : translateText(
                  `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.yesDeleteIt`,
                ),
            isDisabled: isSubmitting,
            onclick: onDelete,
          }}
          positiveButton={{
            label: translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.noKeepIt`,
            ),
            onclick: hideConfirmationModal,
          }}
          header={
            deleteTarget
              ? translateText(
                  `${I18N_PLATFORM_COMMON_WORD_PATH}.deleteSectionName`,
                  { sectionName: deleteTarget },
                )
              : translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.delete`)
          }
          paragraph={[
            deleteTarget
              ? translateText(
                  `${I18N_PLATFORM_COMMON_WORD_PATH}.aboutToDeleteSectionName`,
                  { sectionName: deleteTarget },
                )
              : '',
            translateText(
              `${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.askToProceed`,
            ),
          ]}
        />
      )}
    </>
  );
};

export { ConfirmationModalFooter };
