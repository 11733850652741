import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useApolloClient } from '@apollo/client';
import { Container } from 'components/@codelitt/ay-design-library';

import { ISaleInput } from 'interfaces/inputs/ISaleInput';
import ISale from 'interfaces/ISale';
import LoadingMessage from 'components/LoadingMessage';
import NotificationMessage from 'components/NotificationMessage';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import {
  GET_SALE_QUERY,
  DELETE_SALE_MUTATION,
  UPDATE_SALE_MUTATION,
} from 'graphql/sales';
import { ErrorLogger } from 'services/ErrorLogger';
import EditCompForm from '../EditCompForm';
import {
  convertISaleIntoISaleInput,
  prepareSaleInputForMutation,
} from '../converters';
import SaleForm from '../SaleForm';

interface Props {
  saleId: string | undefined;
  title?: string;
  showDeleteButton?: boolean;
  redirectToShowCompsPage?: boolean;
  onCancel?: () => void;
  onUpdated?: () => void;
  onDeleted?: () => void;
}

const DEFAULT_ERROR_MESSAGE = 'Unexpected error updating the sale';

const SaleEditForm: React.FC<Props> = props => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState<string>('');

  const { data, loading } = useQuery<{ sale: ISale }>(GET_SALE_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: props.saleId },
    skip: !props.saleId,
  });

  const getRefetchSaleQuery = () => {
    if (!props.redirectToShowCompsPage) {
      return [];
    }

    return [
      {
        query: GET_SALE_QUERY,
        variables: { id: props.saleId },
      },
    ];
  };

  const showMessageError = (message = DEFAULT_ERROR_MESSAGE) => {
    setMessageError(message);
    setShowError(true);
  };

  const updateSale = async (sale: ISaleInput) => {
    try {
      setIsUpdating(true);

      const { data } = await client.mutate<{
        updateSale: ISaleInput;
      }>({
        mutation: UPDATE_SALE_MUTATION,
        variables: { sale: prepareSaleInputForMutation(sale) },
        refetchQueries: getRefetchSaleQuery(),
      });

      if (data?.updateSale?.id) {
        props.onUpdated?.();
      } else {
        ErrorLogger.log(data?.updateSale?.message);
        showMessageError(data?.updateSale?.message);
      }
    } catch (e) {
      ErrorLogger.log(e as any, DEFAULT_ERROR_MESSAGE);
      showMessageError();
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading || !data?.sale) {
    return (
      <Container>
        <LoadingMessage />
      </Container>
    );
  }

  return (
    <>
      <EditCompForm
        comp={data.sale as ISaleInput}
        deleteErrorMessage={t(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.unableToDeleteTheSale`,
        )}
        deleteQueryParams={{
          query: DELETE_SALE_MUTATION,
          paramName: 'saleId',
          resultName: 'deleteSale',
        }}
        headerModal={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.deleteSaleComp`)}
        paragraphModal={[
          `${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.aboutToDeleteSaleComp`)}.`,
          t('avantProperties.texts.common.askToProceed'),
        ]}
        onDeleted={props.onDeleted}
        showDeleteButton={props.showDeleteButton}
        title={props.title}
        compForm={
          <SaleForm
            sourceSale={data.sale}
            sale={convertISaleIntoISaleInput(data.sale)}
            salePropertyBuildingSize={data.sale.property?.buildingSize}
            onSubmitForm={updateSale}
            onCancel={props.onCancel}
            submitLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.update`)}
            isSubmitting={isUpdating}
          />
        }
      />

      <NotificationMessage
        show={showError}
        text={messageError}
        onClose={() => {
          setShowError(false);
        }}
      />
    </>
  );
};

export default SaleEditForm;
