export enum ExpandingContracting {
  EXPANDING = 'Expanding',
  CONTRACTING = 'Contracting',
}

export const SIZE_GROWTH_OPTIONS = [
  {
    id: 1,
    name: ExpandingContracting.EXPANDING,
  },
  {
    id: 2,
    name: ExpandingContracting.CONTRACTING,
  },
];
