import { SearchOperations } from 'constants/searchOperations';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import {
  addYearsDateUTCTime,
  getTodayUTCTime,
  addMonthssDateUTCTime,
} from 'utils/date';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_PAGES } from 'constants/i18n';
import { ISearchFieldFilters } from 'interfaces/ISearch';

export const LEASE_RECENT_TIME_OPTIONS: SearchOptionsType[] = [
  {
    id: 1,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastYears`, {
      numberOfYears: 2,
    }),
    field: 'signDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: addYearsDateUTCTime(-2),
        end: getTodayUTCTime(),
      },
    },
  },
  {
    id: 2,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastYears`, {
      numberOfYears: 4,
    }),
    field: 'signDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: addYearsDateUTCTime(-4),
        end: getTodayUTCTime(),
      },
    },
  },
  {
    id: 3,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastYears`, {
      numberOfYears: 6,
    }),
    field: 'signDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: addYearsDateUTCTime(-6),
        end: getTodayUTCTime(),
      },
    },
  },
];

export const TIMS_ACTIVE_SINCE_DATE_OPTIONS: SearchOptionsType[] = [
  {
    id: 1,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastDays`, {
      numberOfDays: 30,
    }),
    field: 'activeSinceDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        end: getTodayUTCTime(),
        start: addMonthssDateUTCTime(-1),
      },
    },
  },
  {
    id: 2,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastDays`, {
      numberOfDays: 60,
    }),
    field: 'activeSinceDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        end: getTodayUTCTime(),
        start: addMonthssDateUTCTime(-2),
      },
    },
  },
  {
    id: 3,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastDays`, {
      numberOfDays: 90,
    }),
    field: 'activeSinceDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        end: getTodayUTCTime(),
        start: addMonthssDateUTCTime(-3),
      },
    },
  },
];

export const TIMS_LAST_DISCUSSED_DATE_OPTIONS: SearchOptionsType[] = [
  {
    id: 1,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastDays`, {
      numberOfDays: 30,
    }),
    field: 'lastDiscussedDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        end: getTodayUTCTime(),
        start: addMonthssDateUTCTime(-1),
      },
    },
  },
  {
    id: 2,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastDays`, {
      numberOfDays: 60,
    }),
    field: 'lastDiscussedDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        end: getTodayUTCTime(),
        start: addMonthssDateUTCTime(-2),
      },
    },
  },
  {
    id: 3,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.lastDays`, {
      numberOfDays: 90,
    }),
    field: 'lastDiscussedDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        end: getTodayUTCTime(),
        start: addMonthssDateUTCTime(-3),
      },
    },
  },
];

export const TIMS_TARGET_COMMENCEMENT_DATE_OPTIONS: SearchOptionsType[] = [
  {
    id: 1,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.nextMonths`, {
      numberOfMonths: 3,
    }),
    field: 'targetCommencementDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: getTodayUTCTime(),
        end: addMonthssDateUTCTime(3),
      },
    },
  },
  {
    id: 2,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.nextMonths`, {
      numberOfMonths: 6,
    }),
    field: 'targetCommencementDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: getTodayUTCTime(),
        end: addMonthssDateUTCTime(6),
      },
    },
  },
  {
    id: 3,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.nextMonths`, {
      numberOfMonths: 12,
    }),
    field: 'targetCommencementDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: getTodayUTCTime(),
        end: addMonthssDateUTCTime(12),
      },
    },
  },
];

export const LEASE_EXPIRING_TIME_OPTIONS: SearchOptionsType[] = [
  {
    id: 4,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.nextYears`, {
      numberOfYears: 2,
    }),
    field: 'expirationDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: getTodayUTCTime(),
        end: addYearsDateUTCTime(2),
      },
    },
  },
  {
    id: 5,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.nextYears`, {
      numberOfYears: 4,
    }),
    field: 'expirationDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: getTodayUTCTime(),
        end: addYearsDateUTCTime(4),
      },
    },
  },
  {
    id: 6,
    name: translateText(`${I18N_AVANT_PROPERTY_PAGES}.findComps.nextYears`, {
      numberOfYears: 6,
    }),
    field: 'expirationDate',
    filter: {
      operator: SearchOperations.between,
      range: {
        start: getTodayUTCTime(),
        end: addYearsDateUTCTime(6),
      },
    },
  },
];

export const LEASE_CAROUSEL_EXPIRING_TIME_OPTIONS: ISearchFieldFilters[] = [
  {
    and: [
      {
        key: 'expirationDate',
        op: SearchOperations.between,
        range: {
          start: getTodayUTCTime(),
          end: addYearsDateUTCTime(6),
        },
      },
    ],
  },
];
