import { isNil } from 'lodash';

import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { abbreviateNumber, formatNumberWithCommas } from './number';

/**
 * Given a number like 200000 as input, this function returns "200,000 sf"
 * The unit param defaults to square feet
 */
export const formatArea = (
  value?: number | string | null,
  unit: string = UnitOfMeasurement.sf,
) => {
  if (isNil(value)) return '';

  const num = formatNumberWithCommas(value);

  return `${num} ${unit}`.trim();
};

export const formatAreaWithMeasurement = (
  value?: number | string | null,
  valueMt?: number | string | null,
  unit: string = UnitOfMeasurement.sf,
) => {
  if (isNil(value)) return '';

  const num = formatNumberWithCommas(unit === 'sf' ? value : valueMt);

  return `${num} ${unit}`.trim();
};

export const formatAndAbbreviateArea = (
  value?: number | null,
  unit: string = UnitOfMeasurement.sf,
) => {
  if (isNil(value)) return '';

  const num = abbreviateNumber(value);

  return `${num} ${unit}`.trim();
};
