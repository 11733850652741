import { gql } from '@apollo/client';
import { USER_INFO_FRAGMENT } from 'graphql/user/fragments';

export const USER_QUERY = gql`
  query me {
    me {
      ... on User {
        ...userInfo
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const GET_CONTACT_QUERY = gql`
  query getContacts($search: String!) {
    getContacts(search: $search) {
      ... on User {
        id
        firstName
        lastName
        email
        companyName
      }
      ... on UserError {
        message
      }
    }
  }
`;

export const OAUTH_EMAIL_QUERY = gql`
  query oAuthEmail($email: String!) {
    oAuthEmail(email: $email) {
      ... on OAuthEmail {
        id
        email
        provider
      }
    }
  }
`;
