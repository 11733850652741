export const getRecentSalesBarShadowId = (node: any) => {
  return `recent-sales-bar-shadow-${node.key}`;
};

export const tooltipId = 'recent-sales-tooltip';

export enum GraphClasses {
  Bar = 'recent-sales-bar',
  BarShadow = 'recent-sales-bar-shadow',
}
