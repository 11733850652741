import { DOT } from 'constants/placeholders';

export const IMAGE_HEIGHT = 114;
export const IMAGE_WIDTH = 152;

export const SMALL_IMAGE_HEIGHT = 56;
export const SMALL_IMAGE_WIDTH = 72;

export const TAG_LIMIT = 7;

export enum TypeOfCard {
  SHARE = 'share',
  ACTIVITY = 'activity',
  HOME = 'home',
  CAROUSEL = 'carousel',
  CAROUSEL_PROPERTY = 'carousel-property',
}

export const HTTP = 'http://';
export const HTTPS = 'https://';

export const DATE_FORMAT = `MMMM Do, YYYY [${DOT}] hh:mma`;
