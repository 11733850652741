import { ApolloLink } from '@apollo/client';
import { OperationDefinitionNode } from 'graphql';
import { UserLocationService } from 'services/UserLocationService';

export const userLocationLink = new ApolloLink((operation, forward) => {
  const operationType =
    (operation.query.definitions[0] as OperationDefinitionNode).operation ||
    undefined;

  operation.setContext(async ({ headers = {} }) => {
    if (operationType === 'mutation') {
      try {
        const { latitude, longitude } = await UserLocationService.getPosition();
        return {
          headers: {
            ...headers,
            'latitude-from': latitude,
            'longitude-from': longitude,
          },
        };
      } catch (e) {
        console.error(e);
      }
    }
  });

  return forward(operation);
});
