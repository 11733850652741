import React, { useMemo } from 'react';
import { compact, isEmpty } from 'lodash';
import { REGEX_INTEGERS, REGEX_DECIMALS } from 'constants/regex';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import DateInput from 'components/Inputs/DateInput';
import TextInputMultiple from 'components/TextInputMultiple';
import { convertToNumber } from 'utils/parsers/convertToNumber';
import { getYearFromDate } from 'utils/date';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  getFieldNameForProperty,
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { translateText } from 'utils/i18n';
import { FieldType } from 'components/CreateComps/FormSection/PropertyForm/types';
import { ErrorLabel } from 'components/ErrorLabel';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { PropertyTypes } from 'constants/propertyTypes';
import { checkIfBuildingSizeIsRequired } from 'utils/properties';
import { formatNumberWithCommas } from 'utils/formatters/number';
import OfficeWareHouseSizes from '../../FormFields/OfficeWareHouseSizes/OfficeWareHouseSizes';
import { calculateFloorPlate } from '../calculateFields';
import ExclusiveFields from './ExclusiveFields';
import RegionalFields from './RegionalFields';
import PreLeasedFields from '../../FormFields/PreLeasedFields';

interface Props {
  addressCountry?: string | null;
  property: IPropertyInput;
  setIsYearRenovatedInvalid: (isYearRenovatedInvalid: boolean) => void;
  onChange: {
    [FieldType.common]: (fieldName: string, fieldValue: any) => void;
    [FieldType.office]: (fieldName: string, fieldValue: any) => void;
    [FieldType.industrial]: (fieldName: string, fieldValue: any) => void;
    [FieldType.dataCenter]: (fieldName: string, fieldValue: any) => void;
    [FieldType.healthcare]: (fieldName: string, fieldValue: any) => void;
    [FieldType.multifamily]: (fieldName: string, fieldValue: any) => void;
  };
  classNames: {
    formControlClassName: string;
    choiceInputClassName: string;
    inputHelperClassName: string;
    dateInputErrorClassName: string;
  };
}

const BuildingFields: React.FC<Props> = ({
  addressCountry,
  onChange,
  property,
  setIsYearRenovatedInvalid,
  classNames: {
    formControlClassName,
    inputHelperClassName,
    dateInputErrorClassName,
  },
  classNames,
}) => {
  if (!property.measurementSystem) {
    property.measurementSystem = property.propertyCountry?.code;
  }
  const isBuildingSizeRequired = checkIfBuildingSizeIsRequired(property);
  const renovatedYearsValue = (property.renovatedYears || []).map(y =>
    y === undefined ? undefined : `${y.year}`,
  );

  const isIndustrial = useMemo(() => {
    const propertyType = property.propertyType?.name;
    return (
      propertyType === PropertyTypes.industrial ||
      propertyType === PropertyTypes.flexIndustrial
    );
  }, [property.propertyType?.name]);

  const getIsYearRenovatedInvalid = () =>
    !isEmpty(compact(renovatedYearsValue)) &&
    !isEmpty(
      renovatedYearsValue.filter(
        yearRenovated =>
          +yearRenovated! < +getYearFromDate(property?.buildDate),
      ),
    );
  const getIsBuildDateValid = () => {
    return (
      (!!property.brokeGroundDate &&
        +getYearFromDate(property?.buildDate) >
          +getYearFromDate(property.brokeGroundDate)) ||
      !property.brokeGroundDate
    );
  };

  const updateFloorPlateSize = (
    buildingSize?: number | null,
    stories?: number | null,
  ) => {
    // calculate floor plate size on buildingSize or stories value changes
    const fpSize = calculateFloorPlate(buildingSize, stories) || null;
    onChange[FieldType.common](
      getFieldNameForProperty(
        'floorPlateSize',
        property,
        ModelsWithUnitsOfMeasurement.Property,
      ),
      fpSize,
    );
  };

  const updateBuildingSize = (value: string) => {
    const buildingSize = convertToNumber(value);
    const stories = convertToNumber(property.stories);
    updateFloorPlateSize(buildingSize, stories);
    onChange[FieldType.common](
      getFieldNameForProperty(
        'buildingSize',
        property,
        ModelsWithUnitsOfMeasurement.Property,
      ),
      buildingSize || null,
    );
  };

  const updateStories = (value: string) => {
    const buildingSize = convertToNumber(
      getFieldValueForProperty('buildingSize', property),
    );
    const stories = convertToNumber(value);
    updateFloorPlateSize(buildingSize, stories);
    onChange[FieldType.common]('stories', stories || null);
  };

  const updateRenovatedYears = (val: (string | undefined)[]) => {
    const renovatedYears = val.map(itemValue => {
      const isNewItem = itemValue === undefined;
      const isInvalidValue = isNaN(+itemValue!) || itemValue!.trim() === '';
      return isNewItem || isInvalidValue ? undefined : { year: +itemValue! };
    });

    onChange[FieldType.common]('renovatedYears', renovatedYears);
  };

  const isYearRenovatedInvalid = getIsYearRenovatedInvalid();
  setIsYearRenovatedInvalid(isYearRenovatedInvalid);

  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  return (
    <>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.buildingSize',
        )}
        wrapperClassName={formControlClassName}
        required={isBuildingSizeRequired}
      >
        <TextInput
          id="buildingSizeInput"
          formatter={value => formatNumberWithCommas(value)}
          value={getFieldValueForProperty('buildingSize', property)}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterSize',
          )}
          onChange={updateBuildingSize}
        />
        <span className={inputHelperClassName}>{unitOfMeasurement}</span>
      </FormControl>
      <PreLeasedFields
        property={property}
        onChange={onChange[FieldType.common]}
        unitOfMeasurement={unitOfMeasurement}
      />

      {isIndustrial && (
        <OfficeWareHouseSizes
          onChange={onChange[FieldType.common]}
          unitOfMeasurement={unitOfMeasurement}
          input={property}
          officeSizeLabel={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.officeSize`,
          )}
          warehouseSizeLabel={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.warehouseSize`,
          )}
          warehouseSizeFieldsName={['warehouseSizeMt', 'warehouseSize']}
          sizeFieldsName={['buildingSizeMt', 'buildingSize']}
        />
      )}

      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.floors`)}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          pattern={REGEX_INTEGERS}
          value={property.stories}
          placeholder={translateText(
            'avantPlatform.attributes.lease.prompt.enterFloors',
          )}
          onChange={updateStories}
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.floorplate',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="floorPlateSizeInput"
          formatter={value => formatNumberWithCommas(value)}
          value={getFieldValueForProperty(
            'floorPlateSize',
            property,
            ModelsWithUnitsOfMeasurement.Property,
          )}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterSize',
          )}
          onChange={value => {
            onChange[FieldType.common](
              getFieldNameForProperty(
                'floorPlateSize',
                property,
                ModelsWithUnitsOfMeasurement.Property,
              ),
              value || null,
            );
          }}
        />
        <span className={inputHelperClassName}>
          {getUnitOfMeasurementForProperty(
            'floorPlateSize',
            ModelsWithUnitsOfMeasurement.Property,
            property?.propertyCountry?.code || property?.measurementSystem,
          )}
        </span>
      </FormControl>
      <RegionalFields
        addressCountry={addressCountry}
        classNames={classNames}
        onChange={onChange}
        property={property}
      />
      <ExclusiveFields
        classNames={classNames}
        onChange={onChange}
        property={property}
      />
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.parkingRatio',
        )}
        wrapperClassName={formControlClassName}
      >
        <TextInput
          id="parkingRatioInput"
          pattern={REGEX_DECIMALS}
          value={property.parkingRatio}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterParkingRatio',
          )}
          onChange={value =>
            onChange[FieldType.common]('parkingRatio', value || null)
          }
        />
        <span className={inputHelperClassName}>
          {`per 1,000 ${getUnitOfMeasurementForProperty(
            'parkingRatio',
            ModelsWithUnitsOfMeasurement.Property,
            property?.propertyCountry?.code || property?.measurementSystem,
          )}`}
        </span>
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.brokeGroundDate',
        )}
        wrapperClassName={formControlClassName}
      >
        <DateInput
          errorClassName={dateInputErrorClassName}
          value={property.brokeGroundDate}
          allowEmpty={true}
          onChange={value => {
            onChange[FieldType.common]('brokeGroundDate', value);
          }}
        />
      </FormControl>
      <FormControl
        label={translateText('avantProperties.pursuit.label.buildDate')}
        wrapperClassName={formControlClassName}
      >
        <DateInput
          errorClassName={dateInputErrorClassName}
          customValidation={{
            isValid: !isYearRenovatedInvalid && getIsBuildDateValid(),
          }}
          allowEmpty={true}
          value={property.buildDate}
          onChange={value => {
            onChange[FieldType.common]('buildDate', value);
            if (!value) {
              onChange[FieldType.common]('builtYear', null);
            }
          }}
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.yearRenovated',
        )}
        wrapperClassName={formControlClassName}
        isMultiline
      >
        <TextInputMultiple
          value={renovatedYearsValue}
          onChange={updateRenovatedYears}
          isInvalid={isYearRenovatedInvalid}
          addButtonText={translateText(
            'avantPlatform.attributes.property.action.addRenovationYear',
          )}
          textInputProps={{
            placeholder: translateText(
              'avantPlatform.attributes.property.prompt.enterYear',
            ),
          }}
        />
        {isYearRenovatedInvalid && (
          <ErrorLabel
            errorMessage={translateText(
              'avantProperties.texts.property.yearRenovatedInvalid',
            )}
          />
        )}
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.demolishedConvertedDate',
        )}
        wrapperClassName={formControlClassName}
      >
        <DateInput
          errorClassName={dateInputErrorClassName}
          customValidation={{
            isValid: true,
          }}
          value={property.demolishedConverted}
          onChange={value =>
            onChange[FieldType.common]('demolishedConverted', value)
          }
        />
      </FormControl>
    </>
  );
};

export default BuildingFields;
