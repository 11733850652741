import { DocumentNode } from '@apollo/client';

export enum ModalNames {
  EDIT = 'edit',
  MERGE = 'merge',
  CREATE = 'create',
  DELETE = 'delete',
  MERGE_SUCCESS = 'merge-success',
  ALIASES = 'aliases',
}

export type ModalTypes =
  | ModalNames.MERGE
  | ModalNames.MERGE_SUCCESS
  | ModalNames.EDIT
  | ModalNames.DELETE
  | ModalNames.CREATE
  | ModalNames.ALIASES
  | undefined;

export interface IModals {
  type: ModalNames | undefined;
  isOpen: boolean;
}

export interface IMutationParamsTypes {
  variables: any;
  mutation: DocumentNode;
}
