import { IBroker } from 'interfaces/IBroker';
import { IBrokerInput } from 'interfaces/inputs/IBrokerInput';
import { IdName } from 'interfaces/IdName';

export const getBrokerName = (broker: IBroker | undefined) =>
  broker?.company?.name;

export const convertIdNameToBrokerInput = (value: IdName[]): IBrokerInput[] =>
  value.map<IBrokerInput>(({ name }) => ({
    company: name,
  })) || [];

export const convertBrokerInputToIdName = (value: IBroker[]): IdName[] =>
  value.map<IBrokerInput>(({ company }) => ({
    id: company?.id,
    name: company?.name!,
  })) || [];
