import React from 'react';
import styles from './EditTable.module.scss';
import { IHistoricalPerformanceTable } from 'interfaces/IHistoricalPerformance';
import Button from 'components/Button/new';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  onClick: (dataRow: IHistoricalPerformanceTable) => void;
}

const TableAddButton: React.FC<Props> = ({ onClick }) => (
  <thead>
    <tr className={styles['row-content']}>
      <td>
        <Button
          type="link"
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.addYear`)}
          icon="add"
          wrapperClassName={styles['add-button-table']}
          onClick={onClick}
        />
      </td>
    </tr>
  </thead>
);

export default TableAddButton;
