import React from 'react';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { SearchInputSize, SuggestionIcon } from '../SearchInput/enums';
import { ISearchInputCustomOption } from 'interfaces/ISearchInputCustomOption';
import locations from 'routes';
import { translateText } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_LABEL_PATH } from 'constants/i18n';
import { PropertyCompanyType } from 'constants/propertyCompanyType';
import { SearchParamsService } from 'services/SearchParamsService';
import LeaseInput from 'components/LeaseInput';
import { SEARCH_LEASES_QUERY } from 'graphql/leases';

const I18N_COMPANY_PROPERTY_SEARCH_INPUT_LABEL_PATH = `${I18N_AVANT_PROPERTY_LABEL_PATH}.companyPropertySearchInput`;

export type Props = {
  aliasPlaceholder?: (suggestionName: string) => string;
  autofocus?: boolean;
  clearAfterSuggestionClick?: boolean;
  customOption?: ISearchInputCustomOption;
  editValue?: string;
  disabledPlaceholder?: string;
  filterOnlyCompanies?: boolean;
  hasBottomMargin?: boolean;
  hideEmptySuggestionsPressEnter?: boolean;
  ignoreCompanyId?: number;
  includeSubmarkets?: boolean;
  includeMarkets?: boolean;
  initialValue?: string;
  inputId?: string;
  isDisabled?: boolean;
  isEditable?: boolean;
  isSearchIconLeftVisible?: boolean;
  navbar?: boolean;
  onChangeText?: (value?: string) => void;
  onClearSelectedSuggestion?: () => void;
  onClickSuggestionCustom?: (propertyCompany: IPropertyCompany) => void;
  placeholder?: string;
  removeMergedCompanies?: boolean;
  selectable?: boolean;
  size?: SearchInputSize;
  withMarketSwitch: boolean;
  wrapperClassName?: string;
  tenantCompanyId?: number;
  currentPropertyId?: number;
  currentLeaseId?: number | null;
  currentMarketId?: number;
};

const LeaseSearchInput: React.FC<Props> = props => {
  const {
    aliasPlaceholder,
    autofocus,
    clearAfterSuggestionClick,
    customOption,
    editValue,
    filterOnlyCompanies,
    hasBottomMargin,
    hideEmptySuggestionsPressEnter,
    initialValue,
    inputId,
    isDisabled,
    disabledPlaceholder,
    isEditable,
    isSearchIconLeftVisible,
    navbar,
    onChangeText,
    onClearSelectedSuggestion,
    onClickSuggestionCustom,
    placeholder = translateText(
      `${I18N_COMPANY_PROPERTY_SEARCH_INPUT_LABEL_PATH}.inputPlaceholder`,
    ),
    selectable,
    size,
    withMarketSwitch,
    wrapperClassName,
    tenantCompanyId,
    currentPropertyId,
    currentLeaseId,
    currentMarketId,
  } = props;

  const parseMergedCompanyToPropertyCompany = (
    mergeCompanyId: number,
    mergeCompanyName: string,
  ): IPropertyCompany => {
    return {
      id: mergeCompanyId,
      name: mergeCompanyName,
      type: 'company',
      industryName: '',
      headquarters: '',
      companyId: mergeCompanyId,
    };
  };

  const onClickSuggestion = (propertyCompany: IPropertyCompany) => {
    const locationOptions = {
      [PropertyCompanyType.COMPANY]: locations.showCompany(
        propertyCompany?.companyId!,
      ),
      [PropertyCompanyType.PROPERTY]: locations.showProperty(
        propertyCompany?.propertyId!,
      ),
      [PropertyCompanyType.MARKET]: locations.showMarket(
        propertyCompany.marketId!,
      ),
      [PropertyCompanyType.SUBMARKET]: locations.showSubmarket(
        propertyCompany?.submarketId!,
        propertyCompany?.propertyTypeId!,
      ),
      [PropertyCompanyType.MAPBOX_ADDRESS]: locations.showAddressProfileAmenities(
        SearchParamsService.parseMapBoxDetailsToUrlParams(
          propertyCompany?.mapBoxDetail,
          propertyCompany?.displayAddress,
        ),
      ),
    };

    window.location.href =
      locationOptions[propertyCompany.type! as PropertyCompanyType];
  };

  const onClickSearchSuggestion = (value: IPropertyCompany) => {
    const { mergedCompanyId, mergedCompanyName } = value;
    // Check if the value is a merged company, and use it for the click action
    const selectedValue =
      mergedCompanyId && mergedCompanyName
        ? parseMergedCompanyToPropertyCompany(
            mergedCompanyId,
            mergedCompanyName,
          )
        : value;

    !!onClickSuggestionCustom
      ? onClickSuggestionCustom?.(selectedValue)
      : onClickSuggestion(selectedValue);
  };

  return (
    <LeaseInput
      aliasPlaceholder={aliasPlaceholder}
      clearAfterSuggestionClick={clearAfterSuggestionClick}
      hasBottomMargin={hasBottomMargin}
      inputId={inputId || 'companies-properties-search-input'}
      isEditable={isEditable}
      navbar={navbar}
      autofocus={autofocus}
      suggestionIconType={SuggestionIcon.dynamicIcon}
      onClickSuggestion={onClickSearchSuggestion}
      onChangeText={onChangeText}
      onClearSelectedSuggestion={onClearSelectedSuggestion}
      resultSelectedComponent={editValue}
      placeholder={placeholder}
      selectable={selectable}
      initialValue={initialValue}
      hideEmptySuggestionsPressEnter={hideEmptySuggestionsPressEnter}
      size={size}
      isSearchIconLeftVisible={isSearchIconLeftVisible}
      graphqlParams={{
        query: SEARCH_LEASES_QUERY,
        resultDataName: 'leases',
      }}
      tenantCompanyId={tenantCompanyId}
      currentPropertyId={currentPropertyId}
      currentMarketId={currentMarketId}
      currentLeaseId={currentLeaseId}
      withMarketSwitch={withMarketSwitch}
      customOption={
        customOption && {
          btnLabel: customOption.btnLabel,
          onSelection: customOption.onSelection,
        }
      }
      wrapperClassName={wrapperClassName}
      isDisabled={isDisabled}
      disabledPlaceholder={disabledPlaceholder}
      includeMapBoxResults={!filterOnlyCompanies}
    />
  );
};

export default LeaseSearchInput;
