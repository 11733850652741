import React from 'react';
import { Link } from 'react-router-dom';
import { IProperty } from 'interfaces/IProperty';
import { IdName } from 'interfaces/IdName';
import classnames from 'classnames';
import locations from 'routes';
import {
  getPropertyClassAndTypeText,
  getPropertyMarkets,
} from 'utils/formatters/property';
import styles from './ComparativeColumns.module.scss';
import ComparativeCard from 'components/PropertyProfile/Sections/Comparative/ComparativeCard';
import { Column } from '../constants';
import InformationRow from './ColumnInformation/InformationRow';
import { COMMA, DOT_LG } from 'constants/placeholders';
import classNames from 'classnames';

export const getOwnerLinks = (property: IProperty) =>
  property.owners?.map((company: IdName, index: number) => (
    <Link
      className={classnames(styles.owner, styles['company-link'])}
      to={locations.showCompany(company.id!)}
      key={company.id}
    >
      {company.name}
      {index < property.owners!.length - 1 ? ` ${DOT_LG} ` : ''}
    </Link>
  ));

const AddressAndNameColumn: React.FC<Column> = ({ property, columnTheme }) => {
  return (
    <div className={classNames(styles['heading-title'])}>
      <ComparativeCard
        theme={columnTheme}
        wrapperClassName={classNames(styles['primary-information'])}
      >
        <div className={styles['comparative-container']}>
          <InformationRow>{getOwnerLinks(property)}</InformationRow>

          <InformationRow>
            <span className={styles['text-info']}>
              {getPropertyMarkets(property, COMMA)}
            </span>
          </InformationRow>

          <InformationRow>
            <span className={styles['text-info']}>
              {getPropertyClassAndTypeText(property)}
            </span>
          </InformationRow>
        </div>
      </ComparativeCard>
    </div>
  );
};

export default AddressAndNameColumn;
