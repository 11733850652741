import React from 'react';
import { ILease } from 'interfaces/ILease';
import Card, { Size } from 'components/Card';
import { ColorNames } from 'constants/colorNames';
import { CompType } from 'constants/compType';
import styles from './LeaseCard.module.scss';
import { buildLeaseCardHeader } from '../buildLeaseCardHeader';
import { buildTagsListItems } from '../buildTagsListItems';
import { LeaseCardField } from '../fields';
import TenantInMarketFooter from '../TenantInMarketFooter';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH } from 'constants/i18n';

type LeaseCardProps = {
  lease: ILease;
  fieldsToShow: LeaseCardField[];
  showPropertyData?: boolean;
  showBrokersData?: boolean;
  tagBackgroundColor?: ColorNames;
  showActionButtons?: boolean;
  showDropdown?: boolean;
  onDeleteLease?: () => void;
  onEditLease?: () => void;
  onUpdate?: () => void;
  size?: Size;
};

const LeaseCard: React.FC<LeaseCardProps> = (props: LeaseCardProps) => {
  const {
    lease,
    fieldsToShow,
    showPropertyData,
    tagBackgroundColor,
    showActionButtons,
    showBrokersData,
    showDropdown,
    onDeleteLease,
    onEditLease,
    onUpdate,
    size,
  } = props;
  const { tenantCompany, property, tenantBrokers, landlordBrokers } = lease;
  const tags = buildTagsListItems(lease, fieldsToShow);

  const comp = showDropdown
    ? {
        type: CompType.lease,
        id: lease.id,
      }
    : undefined;

  const renderBrokers = () => {
    return (
      <div className={styles['brokers-container']}>
        {!!tenantBrokers?.length && (
          <div className={styles['broker-text']}>
            {translateText(
              `${I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH}.tenantRepBroker`,
            )}
            :
            <div className={styles['broker-name']}>
              {tenantBrokers.map(broker => broker.company?.name).join(', ')}
            </div>
          </div>
        )}
        {!!landlordBrokers?.length && (
          <div className={styles['broker-text']}>
            {translateText(
              `${I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH}.landlordBroker`,
            )}
            :
            <div className={styles['broker-name']}>
              {landlordBrokers.map(broker => broker.company?.name).join(', ')}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card
      tags={tags}
      bodyLine={tenantCompany?.name}
      property={property}
      company={tenantCompany}
      size={size || 'small'}
      showPropertyData={showPropertyData}
      buildingMetadata={buildLeaseCardHeader(lease)}
      tagBackgroundColor={tagBackgroundColor || ColorNames.ayFrozenGrassColor}
      showActionButtons={showActionButtons}
      onDelete={onDeleteLease}
      onEdit={onEditLease}
      onUpdate={onUpdate}
      comp={comp}
    >
      {showBrokersData && renderBrokers()}
      <TenantInMarketFooter lease={lease} size={size} hasTopBorder />
    </Card>
  );
};

export default LeaseCard;
