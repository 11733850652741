import React, { useEffect, useRef, useState } from 'react';
import styles from './DropdownFilterItems.module.scss';
import { useQuery, useApolloClient, DocumentNode } from '@apollo/client';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import TextInput from 'components/Inputs/TextInput';
import CheckboxGroupInput from 'components/Inputs/CheckboxGroupInput';
import { ApiClients } from 'constants/apiClients';
import classnames from 'classnames';
import LoadingMessage from 'components/LoadingMessage';
import Button from 'components/Button/new';
import { IdName } from 'interfaces/IdName';
import { ErrorLogger } from 'services/ErrorLogger';
import { useTranslation } from 'react-i18next';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { usersClientWithOkta } from 'graphql/usersClient';

type Props = {
  clientSideFilter?: (searchedText: string, items: any[]) => any[];
  fullWidth?: boolean;
  graphqlClient?: ApiClients;
  graphqlQuery: DocumentNode;
  graphqlVariables?: (searchText?: string) => {};
  groupBySubfield?: string;
  isMultipleSelect?: boolean;
  isVisible?: boolean;
  itemRenderer?: (item: any, searchedText?: string) => JSX.Element;
  mapper: (queryResultData: any) => any[];
  onChange: (newValue: any, data?: any) => void;
  onChangeFullOptionObject?: (value: any) => void;
  placeholder?: string;
  selectedValue?: any;
  shouldShowInput?: boolean;
  isSearchVisible?: boolean;
  wrapperClassName?: string;
  setTextValue?: (value: any) => void;
  selectAllOption?: {
    value: string;
    isVisible: boolean;
    selectAllByDefault?: (allItems: IdName[]) => void;
  };
  actionButton?: {
    value: string;
    isVisible: boolean;
    isDisabled: boolean;
    onClick?: () => void;
  };
  onActionButtonClick?: () => void;
  skipQuery?: boolean;
  getSuggestedOptions?: (items: any) => void;
  optionsLabels?: {
    all: string;
    suggested: string;
  };
  groupItemsPathParams?: {
    sortByField: string;
    displaySortedField: string;
  };
};

const DropdownFilterItems: React.FC<Props> = props => {
  const propertiesClient = useApolloClient();
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const chipsRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const {
    clientSideFilter,
    fullWidth,
    graphqlQuery,
    graphqlVariables,
    groupBySubfield,
    isMultipleSelect,
    isVisible,
    isSearchVisible,
    itemRenderer,
    mapper,
    onChange,
    onChangeFullOptionObject,
    placeholder,
    selectedValue,
    selectAllOption,
    actionButton,
    wrapperClassName,
    setTextValue,
    onActionButtonClick,
    skipQuery,
    getSuggestedOptions,
    optionsLabels,
    groupItemsPathParams,
  } = props;

  const [multiselectHeight, setMultiselectHeight] = useState(0);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!isVisible || !optionsContainerRef.current || !!multiselectHeight) {
      return;
    }

    setMultiselectHeight(optionsContainerRef.current.clientHeight);
  }, [optionsContainerRef, isVisible, multiselectHeight]);

  useEffect(() => {
    if (!isVisible || !optionsContainerRef.current || !multiselectHeight) {
      return;
    }

    let chipsHeight = 0;
    if (chipsRef.current) {
      const chipsBoundingRect = chipsRef.current.getBoundingClientRect();
      const chipsMargin = 4;
      chipsHeight = chipsBoundingRect.height - chipsMargin;
      chipsRef.current.style.bottom = `-${chipsHeight}px`;
    }
  }, [chipsRef, selectedValue, isVisible, multiselectHeight]);

  useEffect(() => {
    if (setTextValue && !isMultipleSelect) {
      setTextValue(selectedValue?.name);
    }
    if (setTextValue && isMultipleSelect) {
      setTextValue(
        (selectedValue ?? []).map((text: any) => text.name).join(', '),
      );
    }
  }, [selectedValue, setTextValue, isMultipleSelect]);

  const { data, error, loading } = useQuery(graphqlQuery, {
    variables: graphqlVariables?.(searchText) || {},
    fetchPolicy: 'cache-and-network',
    skip: skipQuery,
    client:
      props.graphqlClient === ApiClients.users
        ? usersClientWithOkta
        : propertiesClient,
  });

  if (error) {
    ErrorLogger.log(error.message);
  }

  const items = !!data ? mapper(data) : [];

  const clientSideFilteredItems = clientSideFilter
    ? clientSideFilter(searchText, items)
    : items;

  const renderOptions = () => {
    if (error) {
      console.error(error);
      return (
        <p>
          {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.unableToLoadItems`)}
        </p>
      );
    }

    if (loading) return <LoadingMessage />;

    if (itemRenderer) {
      return clientSideFilteredItems.map(item =>
        itemRenderer(item, searchText),
      );
    }

    if (!clientSideFilteredItems.length) {
      return (
        <p className={styles['empty-message']}>
          {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.noItemsFound`)}
        </p>
      );
    }

    if (isMultipleSelect) {
      return (
        <CheckboxGroupInput
          isVertical
          size={'regular'}
          containerWrapperClassName={styles['option-items-container']}
          isMultikey={true}
          multiKeyContainerWrapperClassName={styles['checkbox-items-container']}
          groupItemWrapperClassName={
            styles['checkbox-multi-grid-group-container']
          }
          itemWrapperClassName={styles['choice-item']}
          selectedItems={selectedValue || []}
          data={clientSideFilteredItems}
          onChange={items => onChange(items, data)}
          groupBy={groupBySubfield}
          selectAllOption={selectAllOption}
        />
      );
    } else {
      return (
        <ChoiceInput
          isVertical
          size={'regular'}
          containerWrapperClassName={styles['option-items-container']}
          itemWrapperClassName={styles['choice-item']}
          data={clientSideFilteredItems}
          selectedItem={selectedValue}
          onChange={onChange}
          onChangeFullOptionObject={onChangeFullOptionObject}
          getSuggestedOptions={getSuggestedOptions}
          optionsLabels={optionsLabels}
          groupItemsPathParams={groupItemsPathParams}
        />
      );
    }
  };

  const renderActionButton = () =>
    actionButton?.isVisible && (
      <div className={styles['action-button-container']}>
        <Button
          fullWidth
          label={actionButton.value}
          onClick={() => {
            actionButton.onClick?.();
            onActionButtonClick?.();
          }}
          disabled={actionButton.isDisabled}
        />
      </div>
    );

  if (!isVisible) {
    return null;
  }
  return (
    <div
      className={classnames(wrapperClassName, styles.container, {
        [styles['full-width']]: fullWidth,
      })}
    >
      <div className={styles['items-container']}>
        {isSearchVisible && (
          <div className={styles['search-input-container']}>
            <TextInput
              placeholder={placeholder}
              inputClassName={styles['search-input']}
              onChange={setSearchText}
              value={searchText}
              fullWidth
            />
          </div>
        )}
        <div ref={optionsContainerRef} className={styles['options-container']}>
          {renderOptions()}
        </div>
      </div>
      {renderActionButton()}
    </div>
  );
};

export default DropdownFilterItems;
