import { PropertyDonutsChartsIDs, slicesFilters } from './constants';

export const getPropertiesFilters = (
  donutId: PropertyDonutsChartsIDs,
  tagName: string,
) => {
  switch (donutId) {
    case PropertyDonutsChartsIDs.DonutPropertyClass:
      return { propertyClasses: [{ name: tagName }] };

    case PropertyDonutsChartsIDs.DonutPropertySubtype:
      return { propertySubtypes: [{ name: tagName }] };

    case PropertyDonutsChartsIDs.DonutPropertyAge: {
      const ageFilters = slicesFilters[tagName];
      return {
        fields: {
          and: [
            {
              key: 'builtYear',
              ...ageFilters,
            },
          ],
        },
      };
    }

    case PropertyDonutsChartsIDs.DonutPropertySize: {
      const sizeFilters = slicesFilters[tagName];
      return {
        fields: {
          and: [
            {
              key: 'buildingSize',
              ...sizeFilters,
            },
          ],
        },
      };
    }

    default:
      return undefined;
  }
};
