import React from 'react';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { SALES_TABLE_ID } from 'components/FindComps/SalesTableContainer';
import SaleEditForm from 'components/CreateComps/FormSection/SaleEditForm';
import { PROPERTIES_TABLE_ID } from 'components/FindComps/PropertiesTableContainer';
import PropertyEditForm from 'components/CreateComps/FormSection/PropertyEditForm';
import ModalWrapper from 'components/ModalWrapper';
import { LEASES_TABLE_ID } from 'components/FindComps/LeasesTableContainer';
import LeaseEditForm from 'components/CreateComps/FormSection/LeaseEditForm';
import TenantInMarketEditForm from 'components/CreateComps/FormSection/TenantInMarketEditForm';

import { TIMS_TABLE_ID } from '../FindComps/TimsTableContainer';

interface Props {
  id: string;
  showEditForm: boolean;
  itemId: string | undefined;
  setShowEditForm: (showEditForm: boolean) => void;
  onHandleEditForm: () => void;
  onHandleDeleteForm: () => void;
}
export const EditForms = ({
  id,
  itemId,
  showEditForm,
  setShowEditForm,
  onHandleEditForm,
  onHandleDeleteForm,
}: Props) => {
  const renderEditForm = () => {
    if (!showEditForm) return null;

    let form: JSX.Element | null = null;

    switch (id) {
      case LEASES_TABLE_ID: {
        form = (
          <LeaseEditForm
            leaseId={itemId}
            onCancel={() => setShowEditForm(false)}
            onUpdated={onHandleEditForm}
            onDeleted={onHandleDeleteForm}
            showDeleteButton
            title={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.editLeaseComp`,
            )}
          />
        );
        break;
      }

      case TIMS_TABLE_ID: {
        form = (
          <TenantInMarketEditForm
            TIMId={itemId}
            onCancel={() => setShowEditForm(false)}
            onUpdated={onHandleEditForm}
            onDeleted={onHandleDeleteForm}
            showDeleteButton
            title={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.editTimsComp`,
            )}
          />
        );
        break;
      }

      case SALES_TABLE_ID: {
        form = (
          <SaleEditForm
            saleId={itemId}
            onCancel={() => setShowEditForm(false)}
            onUpdated={onHandleEditForm}
            onDeleted={onHandleDeleteForm}
            showDeleteButton
            title={translateText(
              `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.editSaleComp`,
            )}
          />
        );
        break;
      }

      case PROPERTIES_TABLE_ID: {
        form = (
          <PropertyEditForm
            propertyId={itemId}
            onCancel={() => setShowEditForm(false)}
            onUpdated={onHandleEditForm}
            onDeleted={onHandleDeleteForm}
            showDeleteButton
            title={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.editProperty`,
            )}
          />
        );
        break;
      }
    }

    return (
      <ModalWrapper
        fullScreen
        withCloseButton
        withCloseLabel
        onCancel={() => setShowEditForm(false)}
      >
        {form}
      </ModalWrapper>
    );
  };

  return <>{renderEditForm()}</>;
};
