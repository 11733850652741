import React from 'react';
import styles from './ScoopGallery.module.scss';
import ModalWrapper from 'components/ModalWrapper';
import { Col, Container, Row } from 'components/@codelitt/ay-design-library';

interface Props {
  onCloseModal: () => void;
  initialImageUrl?: string | null;
  keepScrollLocked?: boolean;
}

const ScoopGallery: React.FC<Props> = props => {
  const { initialImageUrl, keepScrollLocked } = props;

  const { onCloseModal } = props;

  return (
    <ModalWrapper
      fullScreen
      darkTheme
      withCloseButton
      withCloseLabel
      withOpacity
      onCancel={onCloseModal}
      keepScrollLocked={keepScrollLocked}
      wrapperClassName={styles['modal-centered']}
    >
      <Row>
        <Col wrapperClassName={styles['title-container']}></Col>
        <Col wrapperClassName={styles['slide-images-container']}>
          <Container key={'id'}>
            <div className={styles['media-carousel-wrapper']}>
              <img className={styles['media-item']} src={initialImageUrl!} />
            </div>
          </Container>
        </Col>
      </Row>
    </ModalWrapper>
  );
};

export default ScoopGallery;
