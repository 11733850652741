import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IScoop } from 'interfaces/IScoop';
import { IProperty } from 'interfaces/IProperty';
import { IUser } from 'interfaces/IUser';
import { ActivityCardContainer, ActivityCardHeader } from '../ActivityCard';
import {
  defaultToIsoFormat,
  formatDate,
  getRelativeDate,
} from 'utils/formatters/date';
import { DOT } from 'constants/placeholders';
import locations from 'routes';
import styles from './ActivityScoopCard.module.scss';
import dateFormats from 'constants/dateFormats';
import ActivityCardActions from 'components/ActivityCardActions';
import ScoopCommentsModal from 'components/ScoopCommentsModal';
import ScoopCompanies from 'components/ScoopCompanies';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import Linkify from 'linkify-react';

export interface Props {
  date: string;
  onDelete: () => void;
  onEdit: () => void;
  onShareButtonClick: () => void;
  property: IProperty;
  preselectedProperty?: IProperty;
  refetchScoopList?: () => void;
  scoop: IScoop;
  user: IUser;
}

const ActivityScoopCard: React.FC<Props> = props => {
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [hasDataChanges, setHasDataChanges] = useState(false);
  const { hasEditorAccess } = useContext(subscriptionsContext);

  const { onEdit, refetchScoopList } = props;
  const date = getRelativeDate(defaultToIsoFormat(props.date));
  const time = formatDate(props.date, dateFormats.TIME_12_HOUR, false);
  const headerText = `${date} ${DOT} ${time}`;
  const firstProperty = props.scoop.properties?.[0] || props.property;

  const onChangeData = useCallback(() => setHasDataChanges(true), []);

  useEffect(() => {
    if (hasDataChanges) {
      refetchScoopList?.();
      setHasDataChanges(false);
    }
  }, [hasDataChanges, refetchScoopList]);

  return (
    <>
      <ActivityCardContainer>
        <ActivityCardHeader
          categories={props.scoop.scoopCategories || []}
          headerText={headerText}
          property={props.scoop.properties?.[0]}
          preselectedProperty={props.preselectedProperty}
          targetPropertyUrl={locations.showProperty(firstProperty.id!)}
          scoop={props.scoop}
          user={props.user}
        />
        <Linkify
          tagName="p"
          options={{
            className: styles.link,
            nl2br: true,
          }}
        >
          {props.scoop.text}
        </Linkify>
        <ScoopCompanies scoop={props.scoop} />
        {hasEditorAccess ? <div className={styles['card-separator']} /> : null}
        <ActivityCardActions
          onEditClick={onEdit}
          onShareClick={props.onShareButtonClick}
          scoop={props.scoop}
          onCommentsClick={() => setIsCommentsModalOpen(true)}
        />
      </ActivityCardContainer>
      {isCommentsModalOpen && (
        <ScoopCommentsModal
          date={props.date}
          onChangeData={onChangeData}
          onEditClick={onEdit}
          onModalToggle={setIsCommentsModalOpen}
          onShareClick={props.onShareButtonClick}
          property={firstProperty}
          scoop={props.scoop}
          scoopUser={props.user}
        />
      )}
    </>
  );
};

export default ActivityScoopCard;
