import React from 'react';
import CheckboxGroupInput from '../Inputs/CheckboxGroupInput';
import styles from './OptionsFilter.module.scss';
import ChoiceInput, { Size } from '../Inputs/ChoiceInput';
import classnames from 'classnames';
import { IdName } from 'interfaces/IdName';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';

type Props = {
  filterNode?: React.ReactNode;
  isDisabled?: boolean;
  isMultipleSelect?: boolean;
  isLabelMultiLine?: boolean;
  isLabelExtended?: boolean;
  label?: string;
  options: SearchOptionsType[];
  allOptions?: SearchOptionsType[];
  onChangeSingle?: (newValue?: IdName) => void;
  onChangeMultiple?: (
    newValue: IdName[],
    allOptions: SearchOptionsType[],
  ) => void;
  selectedItem?: SearchOptionsType;
  selectedItems?: SearchOptionsType[];
  hasHeaderLabel?: boolean;
  isChoicesPadded?: boolean;
  isThreeLineLabel?: boolean;
  choiceInputSize?: Size;
  itemWrapperClassName?: string;
  containerWrapperClassName?: string;
  haveItemsBackground?: boolean;
  wrapperClassName?: string;
};

const OptionsFilter: React.FC<Props> = (props: Props) => {
  const {
    label,
    options,
    allOptions,
    isMultipleSelect,
    hasHeaderLabel,
    haveItemsBackground,
    itemWrapperClassName,
    isLabelMultiLine,
    wrapperClassName,
    isLabelExtended,
  } = props;

  if (!options || !options.length) {
    return null;
  }

  const renderOptions = () => {
    if (isMultipleSelect) {
      return (
        <CheckboxGroupInput
          data={options}
          onChange={(selectedItems: IdName[]) => {
            props.onChangeMultiple &&
              props.onChangeMultiple(selectedItems, allOptions || options);
          }}
          isDisabled={props.isDisabled}
          selectedItems={props.selectedItems || []}
          size={'regular'}
          itemWrapperClassName={itemWrapperClassName}
          haveItemsBackground={haveItemsBackground}
          hideSelectAndDeselect={true}
        />
      );
    }

    return (
      <ChoiceInput
        data={options}
        onChange={props.onChangeSingle}
        selectedItem={props.selectedItem}
        isDisabled={props.isDisabled}
        size={props.choiceInputSize || 'regular'}
        itemWrapperClassName={props.itemWrapperClassName}
        isPadded={props.isChoicesPadded}
        isThreeLineLabel={props.isThreeLineLabel}
      />
    );
  };

  return (
    <div
      className={classnames(
        hasHeaderLabel ? styles['header-label-container'] : styles.container,
        wrapperClassName,
      )}
    >
      {label && (
        <div
          className={classnames(styles.label, {
            [styles['header-label']]: hasHeaderLabel,
            [styles['multiline']]: isLabelMultiLine,
            [styles['extended']]: isLabelExtended,
          })}
        >
          {label}
        </div>
      )}
      <div
        className={classnames({
          [styles['extra-filter-node']]: !!props.filterNode,
        })}
      >
        {props.filterNode}
      </div>
      {renderOptions()}
    </div>
  );
};

export default OptionsFilter;
