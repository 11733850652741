export enum Keyboard {
  Backspace = 8,
  DownArrow = 40,
  Enter = 13,
  Esc = 27,
  LeftArrow = 37,
  RightArrow = 39,
  Semicolon = 186,
  Space = 32,
  Tab = 9,
  UpArrow = 38,
}
