import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import { AuthProviders } from 'constants/authProviders';

const config: OktaAuthOptions = {
  issuer: window._env_.OKTA_ISSUER,
  clientId: window._env_.OKTA_CLIENT_ID,
  redirectUri: window._env_.OKTA_REDIRECT_URI,
  scopes: ['openid', 'email', 'profile'],
};

export const isOktaSessionValid = async () => {
  const authProvider = localStorage.getItem(LocalStorageKeys.oAuthProvider);

  if (authProvider !== AuthProviders.OKTA) return true;

  const oktaAuth = new OktaAuth(config);
  const accessToken = await oktaAuth.tokenManager.get('accessToken');
  return accessToken && !oktaAuth.tokenManager.hasExpired(accessToken);
};

export default new OktaAuth(config);
