import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { formatPropertyStatus } from 'utils/formatters/propertyStatus';
import { formatPropertyClass } from 'utils/formatters/property';
import { DOT_LG } from 'constants/placeholders';

export const getMarketText = (propertyCompany: IPropertyCompany) => {
  if (!propertyCompany.propertyId) {
    const { industryName, headquarters } = propertyCompany;
    return [industryName, headquarters].filter(Boolean).join(` ${DOT_LG} `);
  }

  const {
    marketName,
    submarketName,
    micromarketName,
    propertyType,
    propertyStatusName,
    propertyClassName,
  } = propertyCompany;

  return [
    formatPropertyStatus({ name: propertyStatusName }),
    formatPropertyClass({ name: propertyClassName }),
    propertyType,
    marketName,
    submarketName,
    micromarketName,
  ]
    .filter(Boolean)
    .join(` ${DOT_LG} `);
};
