import { gql } from '@apollo/client';

export const ASSET_PERFORMANCE_FRAGMENT = gql`
  fragment assetPerformanceData on AssetPerformance {
    id
    createdAt
    updatedAt
    propertyId
    date
    vacantSfDirect
    vacantSfSublet
    vacantSfShell
    vacantKwTurnkey
    availableSfDirect
    availableSfSublet
    availableSfShell
    availableKwTurnkey
    notes
  }
`;

export const MARKET_PRICING_FRAGMENT = gql`
  fragment marketingPricingData on MarketPricing {
    id
    createdAt
    updatedAt
    year
    market {
      id
      name
    }
    submarket {
      id
      name
    }
    propertyType {
      id
      name
    }
    propertyClass {
      id
      name
    }
    propertySubtype {
      id
      name
    }
    directRent
    darPercent
    tiLongTerm
    tiPercent
    freeRentLongTerm
    rentType
    timeMeasurement
  }
`;
