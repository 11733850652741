import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { UnitsOfMeasurementModelType } from 'interfaces/unitOfMeasurement';

export const PROPERTY_MAPPING: UnitsOfMeasurementModelType[] = [
  {
    key: 'buildingSize',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'buildingSize',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'buildingSize',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'buildingSizeMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'parkingRatio',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'parkingRatio',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'parkingRatio',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'parkingRatioMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'floorPlateSize',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'floorPlateSize',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'floorPlateSize',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'floorPlateSizeMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
  {
    key: 'siteSize',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'siteSize',
        unit: UnitOfMeasurement.acres,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'siteSize',
        unit: UnitOfMeasurement.acres,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'siteSizeHectares',
        unit: UnitOfMeasurement.hectares,
      },
    },
  },
  {
    key: 'siteSizeAcres',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'siteSize',
        unit: UnitOfMeasurement.acres,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'siteSize',
        unit: UnitOfMeasurement.acres,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'siteSizeHectares',
        unit: UnitOfMeasurement.hectares,
      },
    },
  },
  {
    key: 'siteSizeSf',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'siteSizeSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'siteSizeSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'siteSizeMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
];
