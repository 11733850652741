import { TimeMeasurement } from 'constants/timeMeasurement';
import { IPropertyExpense } from 'interfaces/inputs/IPropertyExpense';
import { IProperty } from 'interfaces/IProperty';
import { useCallback } from 'react';
import { getCurrentYear } from 'utils/date';
import { formatNumberWithDecimals } from 'utils/formatters/number';

export const useGetDefaultValue = () => {
  const calculatePropertyExpenseTaxValue = useCallback(
    (propertyExpense?: IPropertyExpense) => {
      const propertyExpenseTaxAndOpex: {
        [TimeMeasurement.MONTHLY]: number | null;
        [TimeMeasurement.ANNUAL]: number | null;
      } = {
        [TimeMeasurement.MONTHLY]: null,
        [TimeMeasurement.ANNUAL]: null,
      };

      const taxAndOpex = propertyExpense?.taxAndOpex;

      const propertyExpenseType = propertyExpense?.timeMeasurement;

      if (!taxAndOpex || !propertyExpenseType) return null;

      if (propertyExpenseType === TimeMeasurement.ANNUAL) {
        propertyExpenseTaxAndOpex[TimeMeasurement.MONTHLY] = taxAndOpex / 12;
        propertyExpenseTaxAndOpex[TimeMeasurement.ANNUAL] = taxAndOpex;
      } else if (propertyExpenseType === TimeMeasurement.MONTHLY) {
        propertyExpenseTaxAndOpex[TimeMeasurement.MONTHLY] = taxAndOpex;
        propertyExpenseTaxAndOpex[TimeMeasurement.ANNUAL] = taxAndOpex * 12;
      }

      return propertyExpenseTaxAndOpex;
    },
    [],
  );

  const getDefaultValue = useCallback(
    (property: IProperty, year?: number) => {
      const expenseYear = year || getCurrentYear();
      const propertyExpense = property?.expenses?.find(
        expense => expense.year === expenseYear,
      );

      const propertyExpenseTaxAndOpex = calculatePropertyExpenseTaxValue(
        propertyExpense,
      );

      if (!propertyExpenseTaxAndOpex) {
        return null;
      }

      const formattedTaxAndOpex = {
        [TimeMeasurement.MONTHLY]: String(
          formatNumberWithDecimals(
            propertyExpenseTaxAndOpex[TimeMeasurement.MONTHLY],
          ),
        ),
        [TimeMeasurement.ANNUAL]: String(
          formatNumberWithDecimals(
            propertyExpenseTaxAndOpex[TimeMeasurement.ANNUAL],
          ),
        ),
      };

      return formattedTaxAndOpex;
    },
    [calculatePropertyExpenseTaxValue],
  );

  const getDefaultTimeMeasurementValue = useCallback(
    (property: IProperty) => {
      const propertyExpense = property?.expenses?.find(
        expense => expense.year === getCurrentYear(),
      );

      return propertyExpense?.timeMeasurement || TimeMeasurement.ANNUAL;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calculatePropertyExpenseTaxValue],
  );

  return {
    calculatePropertyExpenseTaxValue,
    getDefaultValue,
    getDefaultTimeMeasurementValue,
  };
};
