import React, { useContext } from 'react';
import { authContext } from 'contexts/AuthContext';
import UserMailToLink from 'components/UserMailToLink';
import { DOT } from 'constants/placeholders';
import { IScoop } from 'interfaces/IScoop';
import { IUser } from 'interfaces/IUser';
import styles from './ActivityOwner.module.scss';

export interface Props {
  scoop: IScoop;
  user: IUser;
}

const ActivityScoopOwner: React.FC<Props> = props => {
  const { user } = useContext(authContext);
  const isMe = user.id === props.user?.id;
  const isSameUserEdit = props.user?.id === props.scoop.lastEditedUserId;

  const renderEditedUser = () => {
    if (isSameUserEdit || !props.scoop.lastEditedUser)
      return <span> {DOT} </span>;

    const isMeEditScoop = user.id === props.scoop.lastEditedUserId;

    return (
      <>
        <span> {DOT} </span>
        <span className={styles['user-name']}>
          <span>Edited by </span>
          {isMeEditScoop ? (
            'you'
          ) : (
            <UserMailToLink user={props.scoop.lastEditedUser} />
          )}
        </span>
        <span> {DOT} </span>
      </>
    );
  };

  return (
    <>
      {!!props.user?.email && (
        <>
          <span className={styles['user-name']}>
            {isMe ? `You` : <UserMailToLink user={props.user} />}
          </span>

          {renderEditedUser()}
        </>
      )}
    </>
  );
};

export default ActivityScoopOwner;
