import { SortDirections } from 'constants/sortDirections';
import { ILargestLeasesPopup } from 'stores/LeasesAnalytics/types';
import { LEASES_SIZE_LIMIT } from './constants';
import { PropertyTypeIds } from 'constants/propertyTypes';
import { ILeaseSearchFilters } from 'interfaces/ILeasesSearch';

export const getCompsPopupSearchFilter = (leasesPopup: ILargestLeasesPopup) => {
  let filter = {};
  let page = {};

  if (leasesPopup.leasesIds) {
    filter = { ids: leasesPopup.leasesIds.slice(0, 50) };
  }

  if (leasesPopup.popupFilter) {
    if (leasesPopup.popupFilter.ids) {
      filter = {
        ids: leasesPopup.popupFilter.ids.slice(0, 50),
        ...leasesPopup.popupFilter,
      };
    } else {
      filter = { ...leasesPopup.popupFilter };
    }
    page = { page: 1, size: leasesPopup.popupSizeLimit || LEASES_SIZE_LIMIT };
  }

  if (leasesPopup.popupFilter?.propertyId) {
    filter = { propertyId: leasesPopup.popupFilter?.propertyId };
  }

  return {
    search: {
      filter,
      page,
      order: {
        direction: SortDirections.desc,
        field: 'size',
      },
    },
  };
};

export const getMarketIds = (leasesFilter?: ILeaseSearchFilters) =>
  (leasesFilter && leasesFilter.markets ? leasesFilter.markets : []).map(
    ({ id }) => id,
  );

export const getPropertyTypeIds = (leasesFilter?: ILeaseSearchFilters) =>
  (leasesFilter && leasesFilter.propertyTypes
    ? leasesFilter.propertyTypes
    : []
  ).map(id => PropertyTypeIds[id.toLowerCase()]);
