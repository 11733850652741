import React, { ReactNode } from 'react';
import styles from './PageModal.module.scss';
import { ColorNames } from 'constants/colorNames';
import ModalWrapper from 'components/ModalWrapper';
import { Col, Container, Row } from 'components/@codelitt/ay-design-library';
import classnames from 'classnames';

type Props = {
  children?: ReactNode;
  title: string;
  closeLabel?: string;
  fullScreen?: boolean;
  compressed?: boolean;
  withCloseLabel?: boolean;
  onClose: () => void;
};

const PageModal: React.FC<Props> = ({
  children,
  onClose,
  title,
  fullScreen = true,
  compressed,
  withCloseLabel = true,
}) => (
  <ModalWrapper
    fullScreen={fullScreen}
    compressed={compressed}
    withCloseButton
    withCloseLabel={withCloseLabel}
    onCancel={onClose}
    modalBackgroundColor={ColorNames.ayPureWhiteColor}
  >
    <Container
      wrapperClassName={classnames([
        styles.container,
        {
          [styles['compressed']]: compressed,
        },
      ])}
    >
      <header>
        <Row>
          <Col>
            <h2 className={styles.title}>{title}</h2>
          </Col>
        </Row>
      </header>
      {children}
    </Container>
  </ModalWrapper>
);

export default PageModal;
