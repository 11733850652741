import React from 'react';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { ILargestPropertiesPopup } from 'stores/PropertiesAnalytics/types';
import HistoricalPerformance from './HistoricalPerformance';
import styles from './SummaryContainer.module.scss';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import Overview from './Overview';
import { ILargestLeasesPopup } from 'stores/LeasesAnalytics/types';

interface Props {
  propertySet: IPropertySet;
  setPropertiesPopup?: (popup: ILargestPropertiesPopup) => void;
  unitOfMeasurement: UnitOfMeasurement;
  toggleOptions?: LeaseToggleOptions;
  setLeasesPopup?: (popup: ILargestLeasesPopup) => void;
}

const SummaryContainer = ({
  propertySet,
  setPropertiesPopup,
  unitOfMeasurement,
  toggleOptions,
  setLeasesPopup,
}: Props) => {
  return (
    <div className={styles['summary-container']}>
      <Overview
        propertySet={propertySet}
        setLeasesPopup={setLeasesPopup}
        unitOfMeasurement={unitOfMeasurement}
        toggleOptions={toggleOptions}
      />
      <HistoricalPerformance
        properties={propertySet.properties}
        setPropertiesPopup={setPropertiesPopup}
        unitOfMeasurement={unitOfMeasurement}
        leaseToggleOptions={toggleOptions!}
      />
    </div>
  );
};

export default SummaryContainer;
