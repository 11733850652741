import React from 'react';
import styles from './RadioInput.module.scss';
import classnames from 'classnames';
import RadioInputItem from './RadioInputItem';
import { ColorNames } from 'constants/colorNames';

export interface IRadioItem {
  id?: number;
  label?: string;
  isDisabled?: boolean;
  value?: string | number | null;
  child?: React.ReactNode | null;
}

export interface RadioInputType {
  data?: IRadioItem[];
  isColumnLayout?: boolean;
  onChange: (item: IRadioItem) => void;
  selectedItem?: any;
  wrapperClassName?: string;
  labelClassName?: string;
  isAllDisabled?: boolean;
  isUnderline?: boolean;
  uncheckable?: boolean;
  isMultiSelect?: boolean;
  /**
   * @param {boolean} isMultiSelect - This parameter allows selecting multiple radio inputs.
   * When `isMultiSelect` is `true`, the radio input will have the same layout as a checkbox.
   * `selectedItem` should be passed as an array of strings.
   */
}

const RadioInput: React.FC<RadioInputType> = props => {
  const { selectedItem, data, uncheckable } = props;

  if (!data?.length) return null;

  const isSelected = (value: any) => {
    if (!selectedItem && selectedItem !== 0) return false;
    if (props.isMultiSelect) {
      return selectedItem?.includes(value);
    }
    if (selectedItem.value) {
      return selectedItem.value === value;
    }
    return selectedItem === value;
  };

  const handleRadioChange = (item: IRadioItem) => {
    if (isSelected(item.value) && uncheckable) {
      props.onChange({});
    } else {
      props.onChange(item);
    }
  };

  return (
    <div
      className={classnames(styles['radio-group'], props.wrapperClassName, {
        [styles['radio-group-column-layout']]: props.isColumnLayout,
        [styles['radio-group-row-layout']]: !props.isColumnLayout,
        [styles['radio-group-disabled']]: props.isAllDisabled,
      })}
    >
      {data.map((item: IRadioItem, index) => {
        return (
          <RadioInputItem
            key={`${index}-${item.label}`}
            isCategoryParent={selectedItem?.isCategoryParent}
            isSelected={isSelected(item.value)}
            label={item.label!}
            isUnderline={props.isUnderline}
            isDisabled={props.isAllDisabled || item.isDisabled}
            onClick={() => handleRadioChange(item)}
            backgroundColor={ColorNames.ayWhiteColor}
            child={item.child ? item.child : null}
            wrapperClassName={props.labelClassName}
            isMultiSelect={props.isMultiSelect}
          />
        );
      })}
    </div>
  );
};

export default RadioInput;
