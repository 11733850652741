import React from 'react';
import { parseGraphData } from 'components/AbsorptionChart/utils';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { IAbsorptionChartData } from 'interfaces/IAbsorptionChartData';
import { LeasePeriodType } from 'constants/leases';
import AbsorptionChart from 'components/AbsorptionChart';

type Props = {
  data: IAbsorptionChartData[];
  unitOfMeasurement: UnitOfMeasurement;
  periodType?: string;
};

const Absorption: React.FC<Props> = ({
  data,
  unitOfMeasurement,
  periodType,
}) => {
  const isQuarterly = periodType === LeasePeriodType.QUARTER;

  const graphData = parseGraphData(data, unitOfMeasurement, isQuarterly);

  return (
    <AbsorptionChart
      hideOddXTickValues
      graphData={graphData}
      isQuarterly={isQuarterly}
      unitOfMeasurement={unitOfMeasurement}
    />
  );
};

export default Absorption;
