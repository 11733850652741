import classnames from 'classnames';
import Button from 'components/Button/new';
import { subscriptionsContext } from 'contexts/SubscriptionsContext';
import { IScoop } from 'interfaces/IScoop';
import React, { useContext } from 'react';
import styles from './ActivityCardActionsSmall.module.scss';

interface Props {
  onCommentsClick?: () => void;
  onEditClick?: () => void;
  onShareClick?: () => void;
  scoop?: IScoop;
}

const ActivityCardActionsSmall: React.FC<Props> = ({
  onCommentsClick,
  onEditClick,
  onShareClick,
  scoop,
}) => {
  const { hasEditorAccess } = useContext(subscriptionsContext);
  return hasEditorAccess ? (
    <div
      className={classnames(styles['activity-actions'], {
        [styles['scoop-actions']]: !!scoop,
      })}
    >
      {!!scoop && (
        <Button
          icon="comment"
          iconPosition="left"
          onClick={() => onCommentsClick?.()}
          size="s"
          type="contextual"
        />
      )}
      <Button
        icon="share"
        iconPosition="left"
        onClick={() => onShareClick?.()}
        size="s"
        type="contextual"
      />
      <Button
        icon="edit"
        iconPosition="left"
        onClick={() => onEditClick?.()}
        size="s"
        type="contextual"
      />
    </div>
  ) : null;
};

export default ActivityCardActionsSmall;
