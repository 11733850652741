import React from 'react';
import styles from './ModalPropertiesLinks.module.scss';
import ModalWrapper from 'components/ModalWrapper';
import classNames from 'classnames';
import { IProperty } from 'interfaces/IProperty';
import { getPropertyAddress } from 'utils/formatters/property';
import AYLink from 'components/AYLink';
import locations from 'routes';
import { DASH_PLACEHOLDER, DOT } from 'constants/placeholders';
import { getCoverImageUrl } from 'components/ModalMedia/utils';
import { PropertyImageSizes } from 'constants/propertyImageSizes';

interface Props {
  isVisible: boolean;
  properties: IProperty[];
  onClose: () => void;
}

const ModalPropertiesLinks: React.FC<Props> = ({
  isVisible,
  properties,
  onClose,
}) => {
  if (!isVisible) return null;

  const getLinkRow = (property: IProperty) => {
    const propertyMarketInfo = [
      property.market?.name,
      property.submarket?.name,
      property.micromarket?.name,
    ];
    const imageSrc = getCoverImageUrl(property, PropertyImageSizes.l);

    return (
      <div
        key={property.id}
        className={classNames(styles['property-row-container'])}
      >
        <div className={styles['property-row']}>
          {!!imageSrc && (
            <img
              className={styles.image}
              alt={getPropertyAddress(property) || ''}
              src={imageSrc}
            />
          )}
          <div>
            <AYLink
              wrapperClassName={styles.address}
              to={locations.showProperty(property?.id!)}
            >
              {getPropertyAddress(property)}
            </AYLink>
            <h2 className={styles.name}>{property.name || DASH_PLACEHOLDER}</h2>
            <h3 className={styles.markets}>
              {propertyMarketInfo.filter(Boolean).join(` ${DOT} `)}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      onCancel={onClose}
      wrapperClassName={classNames(
        styles['modal-container'],
        styles['links-container'],
      )}
      header="Properties"
      withCloseButton
      noPadding
      large
    >
      <section className={styles.content}>{properties.map(getLinkRow)}</section>
    </ModalWrapper>
  );
};

export default ModalPropertiesLinks;
