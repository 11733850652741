import React from 'react';
import { DOT_LG } from 'constants/placeholders';
import { IProperty } from 'interfaces/IProperty';
import { getPropertyAddress } from 'utils/formatters/property';
import { getCoverImageUrl } from 'components/ModalMedia/utils';
import { formatPropertyStatus } from 'utils/formatters/propertyStatus';
import { formatPropertyClass } from 'utils/formatters/property';
import { Text } from 'components/@codelitt/ay-design-library';
import locations from 'routes';
import classNames from 'classnames';
import AYLink from 'components/AYLink';
import MultiplePropertiesLink from 'components/MultiplePropertiesLink';
import { IScoop } from 'interfaces/IScoop';
import styles from './ActivityCardPropertyData.module.scss';
import { PropertyMapSize, ZoomLevel } from 'components/PropertyMap';
import StaticMapImage from 'components/PropertyMap/StaticMapImage';
import AlternateAddressIconSmall from 'components/AlternateAddressIconSmall';

interface Props {
  children?: React.ReactNode;
  property?: IProperty;
  preselectedProperty?: IProperty;
  scoop?: IScoop;
  targetPropertyUrl?: string;
}

const IMAGE_HEIGHT = 114;
const IMAGE_WIDTH = 152;

const ActivityCardPropertyData: React.FC<Props> = ({
  children,
  property,
  preselectedProperty,
  scoop,
  targetPropertyUrl,
}) => {
  const propertyCandidate =
    preselectedProperty && scoop
      ? scoop.properties?.find(
          property =>
            property.primaryAddress === preselectedProperty.primaryAddress,
        )
      : property;
  const propertyName = propertyCandidate?.name;
  const propertyAddress = getPropertyAddress(propertyCandidate) || '';
  const coverImage = getCoverImageUrl(
    propertyCandidate,
    IMAGE_WIDTH,
    IMAGE_HEIGHT,
  );

  const shouldDisplayMap = !coverImage && !!propertyCandidate;

  const statusClassTypeText = [
    formatPropertyStatus(propertyCandidate?.status),
    formatPropertyClass(propertyCandidate?.propertyClass),
    propertyCandidate?.propertyType?.name,
    propertyCandidate?.propertySubtype?.name,
    propertyCandidate?.propertySubtypeDetail?.name,
  ].filter(Boolean);

  const propertyMarkets = [
    propertyCandidate?.market?.name && (
      <AYLink
        wrapperClassName={styles['header-link']}
        key="header-market"
        to={locations.showMarket(propertyCandidate?.market?.id)}
      >
        {propertyCandidate?.market?.name}
      </AYLink>
    ),
    propertyCandidate?.submarket?.name && (
      <AYLink
        wrapperClassName={styles['header-link']}
        key="header-submarket"
        to={locations.showSubmarket(
          propertyCandidate?.submarket?.id,
          propertyCandidate?.propertyType?.id,
        )}
      >
        {propertyCandidate?.submarket?.name}
      </AYLink>
    ),
    propertyCandidate?.micromarket?.name && (
      <Text
        key="header-micromarket"
        type="body-content"
        value={propertyCandidate?.micromarket?.name}
      />
    ),
  ].filter(Boolean);

  return (
    <div className={styles.container}>
      {!!coverImage && (
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${coverImage})`,
          }}
        />
      )}
      {shouldDisplayMap && (
        <StaticMapImage
          wrapperClassName={styles.map}
          size={PropertyMapSize.regular}
          zoomLevel={ZoomLevel.regular}
          property={propertyCandidate!}
        />
      )}
      <div className={styles['text-container']}>
        <div className={styles['activity-property-info']}>
          {scoop?.properties?.length ? (
            <MultiplePropertiesLink
              preselectedProperty={preselectedProperty}
              properties={scoop.properties}
            />
          ) : (
            <AYLink
              to={targetPropertyUrl || ''}
              wrapperClassName={classNames(styles['property-address'], {
                [styles['property-address-clickable']]: !!targetPropertyUrl,
              })}
            >
              <div className={styles['title']}>
                {propertyAddress}
                <div className={styles['title-icon']}>
                  <AlternateAddressIconSmall property={propertyCandidate} />
                </div>
              </div>
            </AYLink>
          )}
          <div>
            {!!propertyName && (
              <p className={styles['property-name']}>{propertyName}</p>
            )}
            {!!statusClassTypeText && (
              <p className={styles['property-name']}>
                {statusClassTypeText.map((item, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && (
                      <Text type="body-content" value={` ${DOT_LG} `} />
                    )}
                    {item}
                  </React.Fragment>
                ))}
              </p>
            )}
            {!!propertyMarkets && (
              <p className={styles['property-name']}>
                {propertyMarkets.map((item, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && (
                      <Text type="body-content" value={` ${DOT_LG} `} />
                    )}
                    {item}
                  </React.Fragment>
                ))}
              </p>
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ActivityCardPropertyData;
