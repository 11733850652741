import { IDataPoint } from 'components/Graphs/RecentLeases/interfaces';
import { DOT_LG, EMPTY_SPACE } from 'constants/placeholders';
import { formatArea } from 'utils/formatters/area';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import { TranslateFunction } from 'utils/i18n';
import { getTranslatedRentType } from 'utils/leases';

export const I18N_MOST_RECENT_LEASES_PATH =
  'avantProperties.components.mostRecentLeases';

export const getCompsPopupLeasesTitle = (params: {
  activeLeaseDataPoint: IDataPoint | undefined | null;
  currencySymbol: string;
  leaseMarketDataPoint: IDataPoint | undefined | null;
  rentType: string;
  translate: TranslateFunction;
}): string => {
  const {
    activeLeaseDataPoint,
    currencySymbol,
    leaseMarketDataPoint,
    translate,
  } = params;
  if (activeLeaseDataPoint) {
    const {
      leaseSize,
      unitOfMeasurement,
      currencyCode,
      baseRent,
      rentType,
    } = activeLeaseDataPoint;

    const translatedRentType = getTranslatedRentType(rentType!);

    const finalCurrencySymbol = currencyCode
      ? getCurrencySymbol(currencyCode)
      : currencySymbol;
    const value = `${finalCurrencySymbol}${formatNumberWithDecimals(baseRent)}`;
    const size = formatArea(leaseSize, unitOfMeasurement);

    return [value, translatedRentType, DOT_LG, size].join(EMPTY_SPACE);
  } else if (leaseMarketDataPoint) {
    return translate(`${I18N_MOST_RECENT_LEASES_PATH}.labels.weightedAvg`);
  }

  return '';
};

export const getCompsPopupLeasesSubtitle = (
  leaseMarketDataPoint: IDataPoint | undefined | null,
  translate: TranslateFunction,
  isQuarterly: boolean,
): string => {
  return `${translate(
    `${I18N_MOST_RECENT_LEASES_PATH}.labels.largestLeasesIn`,
  )} ${leaseMarketDataPoint?.year} ${
    isQuarterly ? `${DOT_LG} Q${leaseMarketDataPoint?.quarter}` : ''
  }`;
};
