import React, { useEffect, useRef, useState } from 'react';
import { DocumentNode, OperationVariables, useQuery } from '@apollo/client';
import { IdName } from 'interfaces/IdName';
import classnames from 'classnames';
import styles from './OptionSearchFilter.module.scss';
import ClearInputImage from 'assets/images/icons/clear-icon-grey-64.svg';
import ArrowDownImage from 'assets/images/icons/dropdown-arrow-down.svg';
import ArrowUpImage from 'assets/images/icons/dropdown-arrow-up.svg';
import CheckboxGroupInput from '../Inputs/CheckboxGroupInput';
import ChoiceInput from '../Inputs/ChoiceInput';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { ColorNames } from 'constants/colorNames';
import { sortBy } from 'lodash';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

type Props = {
  backgroundContainerColor?: ColorNames;
  clearable?: boolean;
  hasBorder?: boolean;
  groupBy?: string;
  hasFixedHeight?: boolean;
  isDisabled?: boolean;
  isMultipleSelect?: boolean;
  label?: string;
  labelActive?: string;
  noMarginBottom?: boolean;
  onChange: (selectedOptions?: IdName[], data?: any) => void;
  query: DocumentNode;
  queryParser: (data: any) => IdName[];
  searchPlaceholder: string;
  selectedItem?: SearchOptionsType;
  selectedItems?: SearchOptionsType[];
  skipQuery?: boolean;
  small?: boolean;
  variables?: OperationVariables;
  verticalLabel?: string;
  sortAlphabetically?: boolean;
  gridColumnsCount?: number;
  resultBarClassName?: string;
};

const OptionSearchFilter: React.FC<Props> = (props: Props) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const [searchVariables, setSearchVariables] = useState<OperationVariables>();
  const [isActive, setIsActive] = useState(false);
  const [searchInputText, setSearchInputText] = useState('');

  const { loading, data } = useQuery(props.query, {
    variables: searchVariables,
    skip: props.skipQuery,
  });
  const options = loading ? [] : props.queryParser(data);
  const isDisabled = loading || !options?.length || props.isDisabled;

  useEffect(() => {
    setSearchVariables(props.variables);
  }, [props.variables]);

  useEffect(() => {
    if (isActive) {
      searchInputRef.current!.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if (!props.isMultipleSelect && props.selectedItem) {
      setSearchInputText('');
      setIsActive(false);
    }
  }, [props.selectedItem, props.isMultipleSelect]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchContainerRef]);

  const hasSelectedItem =
    props.selectedItem ||
    (props.selectedItems && props.selectedItems.length > 0);

  const showClearButton = props.clearable && hasSelectedItem;

  const getArrowImageSrc = () => {
    if (isActive) return ArrowUpImage;
    if (showClearButton) return ClearInputImage;
    return ArrowDownImage;
  };

  const renderLabel = () => {
    if (!props.label) return;

    let labelText = props.label;
    let resultText = '';
    if (hasSelectedItem) {
      const selectedIemsInOptions = options
        ?.map(op =>
          props.selectedItems?.find(sel => sel.id === op.id) ? op : null,
        )
        .filter(Boolean);
      labelText = props.labelActive ? `${props.labelActive}:` : '';
      resultText = props.isMultipleSelect
        ? ` ${selectedIemsInOptions?.map(option => option?.name).join(', ')}`
        : ` ${props.selectedItem?.name}`;
    }

    return (
      <div className={styles['label-container']}>
        <span className={styles.label}>
          {isDisabled ? DASH_PLACEHOLDER : labelText}
        </span>
        <span>{resultText}</span>
      </div>
    );
  };

  const getVisibleOptions = () => {
    let visibleOptions = props.sortAlphabetically
      ? sortBy(options, 'name')
      : options;

    if (searchInputText.length) {
      visibleOptions = visibleOptions.filter((option: IdName) => {
        return (
          option.name!.toLowerCase().indexOf(searchInputText.toLowerCase()) !==
          -1
        );
      });
    }

    return visibleOptions;
  };

  const renderResults = () => {
    const visibleOptions = getVisibleOptions();

    if (props.isMultipleSelect) {
      return (
        <CheckboxGroupInput
          data={visibleOptions}
          groupBy={props.groupBy}
          onChange={(items: IdName[]) => {
            props.onChange(items, visibleOptions);
          }}
          isDisabled={props.isDisabled}
          selectedItems={props.selectedItems || []}
          size={'regular'}
          itemWrapperClassName={styles['checkbox-item']}
        />
      );
    }

    return (
      <ChoiceInput
        data={visibleOptions}
        onChange={(item?: IdName) => {
          item
            ? props.onChange([item], visibleOptions)
            : props.onChange(undefined);
        }}
        isDisabled={props.isDisabled}
        selectedItem={props.selectedItem}
        size={'regular'}
        itemWrapperClassName={styles['radio-item']}
        gridColumnsCount={props.gridColumnsCount}
      />
    );
  };

  const toggleResults = () => {
    if (loading || !options?.length || props.isDisabled) {
      return;
    }

    setIsActive(!isActive);
  };

  const backgroundColor =
    styles[
      `background-${props.backgroundContainerColor || ColorNames.ayGrey8Color}`
    ];
  const resultSize = styles[`result-bar-${props.small ? 'small' : 'regular'}`];

  const clear = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onChange(undefined);
  };

  return (
    <>
      {props.verticalLabel && (
        <p className={styles['vertical-label']}>{props.verticalLabel}</p>
      )}
      <div
        ref={searchContainerRef}
        className={classnames(styles.container, backgroundColor, {
          [styles['container-disabled']]: isDisabled,
          [styles['container-margin-bottom']]: !props.noMarginBottom,
          [styles['container-small']]: props.small && !isActive,
          [styles['active']]: isActive,
        })}
      >
        <div>
          <div
            className={classnames(
              styles['result-bar'],
              props.resultBarClassName,
              resultSize,
              {
                [styles['result-bar-disabled']]: isDisabled,
                [styles['hoverable']]: !isDisabled && !isActive,
                [styles['has-border']]: !isActive && props.hasBorder,
              },
            )}
            onClick={toggleResults}
          >
            {renderLabel()}
            {!isDisabled && (
              <div
                className={classnames(
                  styles['arrow-container'],
                  backgroundColor,
                  {
                    [styles['arrow-container-small']]: props.small,
                  },
                )}
                onClick={(e: React.MouseEvent) => showClearButton && clear(e)}
              >
                <img className={styles.arrow} src={getArrowImageSrc()} alt="" />
              </div>
            )}
          </div>
          <div
            className={classnames(
              styles['options-container'],
              backgroundColor,
              {
                [styles.active]: isActive,
              },
            )}
          >
            <div className={styles['search-input-container']}>
              <input
                ref={searchInputRef}
                className={classnames(styles['search-input'], {
                  [styles.active]: isActive,
                })}
                type="text"
                value={searchInputText}
                placeholder={
                  props.isDisabled ? DASH_PLACEHOLDER : props.searchPlaceholder
                }
                onChange={e => setSearchInputText(e.currentTarget.value)}
              />

              {searchInputText.length !== 0 && isActive && (
                <button
                  className={styles['clear-search']}
                  type="button"
                  onClick={() => setSearchInputText('')}
                >
                  <img
                    className={styles['clear-search-icon']}
                    src={ClearInputImage}
                    alt={translateText(
                      `${I18N_PLATFORM_COMMON_WORD_PATH}.clear`,
                    )}
                  />
                </button>
              )}
            </div>

            <div
              className={classnames(styles.options, {
                [styles['fixed-height']]: props.hasFixedHeight,
                [styles.active]: isActive,
              })}
            >
              {renderResults()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OptionSearchFilter;
