import React, { useState } from 'react';
import { IFileUpload } from 'interfaces/IFileUpload';
import { IFileProcessedNotification } from './interfaces';
import importerClient from 'graphql/importerClient';
import { useLocation, useHistory } from 'react-router-dom';
import { GET_FILE_STATUS } from 'graphql/files';
import { authContext } from 'contexts/AuthContext';
import {
  cleanupUploadedFiles,
  getUploadedFilesData,
  removeUploadedFileById,
} from 'utils/localStorage';
import locations from 'routes';
import NotificationMessage from '../NotificationMessage';
import { shouldStopPolling } from 'utils/fileUploads';

interface Props {
  children: JSX.Element;
}

let interval: number;

const NOTIFICATION_DURATION_MS = 5000;
const NOTIFICATION_CHECK_MS = 10000;

const AppNotificationWrapper: React.FC<Props> = props => {
  const location = useLocation();
  const history = useHistory();
  const { user } = React.useContext(authContext);

  const isUploadCompsPage = location.pathname === locations.uploadComps();

  const [
    notification,
    setNotification,
  ] = useState<IFileProcessedNotification | null>(null);

  const checkFileStatus = async (file: IFileUpload) => {
    const fileId = file.id;
    if (!fileId) {
      cleanupUploadedFiles();
      return;
    }

    const { data } = await importerClient.query({
      fetchPolicy: 'network-only',
      query: GET_FILE_STATUS,
      variables: { fileId },
    });

    if (data?.file?.processedAt) {
      setNotification({ fileId, isSuccess: !data.file.error });
      setTimeout(() => setNotification(null), NOTIFICATION_DURATION_MS);
      removeUploadedFileById(fileId);
    } else if (shouldStopPolling(data.file)) {
      removeUploadedFileById(fileId);
    }
  };

  if (!interval) {
    interval = window.setInterval(() => {
      if (user?.id) {
        getUploadedFilesData().forEach(checkFileStatus);
      }
    }, NOTIFICATION_CHECK_MS);
  }

  return (
    <>
      {props.children}
      <NotificationMessage
        show={notification !== null}
        isSuccess={!!notification?.isSuccess}
        onRightTextClick={
          !isUploadCompsPage
            ? () => history.push(locations.uploadComps())
            : undefined
        }
        text={
          !!notification?.isSuccess
            ? 'Your file has been uploaded'
            : 'Your file couldn’t be uploaded'
        }
        rightText={
          !!notification?.isSuccess
            ? 'See summary report'
            : 'Go to Create Comps'
        }
      />
    </>
  );
};

export default AppNotificationWrapper;
