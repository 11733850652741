import { gql } from '@apollo/client';

export const SET_DOCUMENT_CATEGORY_MUTATION = gql`
  mutation setDocumentCategory($documentId: Int!, $categoryId: Int) {
    setDocumentCategory(documentId: $documentId, categoryId: $categoryId) {
      ... on Document {
        id
      }
    }
  }
`;

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation deleteDocument($documentId: Int!) {
    deleteDocument(documentId: $documentId)
  }
`;

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation createDocument(
    $file: Upload!
    $propertyId: Int
    $pursuitId: Int
    $scoopId: Int
    $name: String
  ) {
    createDocument(
      file: $file
      propertyId: $propertyId
      pursuitId: $pursuitId
      scoopId: $scoopId
      name: $name
    ) {
      id
    }
  }
`;

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation updateDocument($documentId: Int!, $name: String!) {
    updateDocument(documentId: $documentId, name: $name) {
      id
    }
  }
`;
