const TENANT_INDUSTRY_ID = 0;
const PROPERTY_CLASS_ID = 1;
const PROPERTY_TYPE_ID = 2;
const PROPERTY_OCCUPIER_CLASS_ID = 3;
const PROPERTY_OCCUPIER_TYPE_ID = 4;
const INVESTOR_OWNER_PORTFOLIO = 5;
const OCCUPIER_OWNER_PORTFOLIO = 6;
const CURRENT_PERFORMANCE_TOP = 7;
const CURRENT_PERFORMANCE_BOTTOM = 8;
const HISTORICAL_RENT = 9;

export default {
  TENANT_INDUSTRY_ID,
  PROPERTY_CLASS_ID,
  PROPERTY_TYPE_ID,
  PROPERTY_OCCUPIER_CLASS_ID,
  PROPERTY_OCCUPIER_TYPE_ID,
  INVESTOR_OWNER_PORTFOLIO,
  OCCUPIER_OWNER_PORTFOLIO,
  CURRENT_PERFORMANCE_TOP,
  CURRENT_PERFORMANCE_BOTTOM,
  HISTORICAL_RENT,
};
