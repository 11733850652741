import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';

export interface AgencyAssignmentsProps {
  propertySet: IPropertySet;
  askingRentData?: Record<string, number | undefined>;
  unitOfMeasurement?: string;
  onEditButtonClick?: () => void;
  toggleOptions?: LeaseToggleOptions;
  setToggleOptions?: (value: LeaseToggleOptions) => void;
  section?: AgencyLeasingAssignmentSection;
}

export enum AgencyLeasingSection {
  Overview = 'overview',
  Properties = 'properties',
  Availabilities = 'availabilities',
  Tenants = 'tenants',
  RecentActivity = 'recent-activity',
}

export enum AgencyLeasingOverviewSection {
  Summary = 'overview-summary',
  Grid = 'overview-grid',
}

export enum AgencyLeasingPropertySection {
  Summary = 'property-summary',
  List = 'property-list',
}

export enum AgencyLeasingTenantSection {
  List = 'tenant-list',
  Summary = 'tenant-summary',
  Grid = 'tenant-grid',
}

export type AgencyLeasingAssignmentSection =
  | AgencyLeasingSection
  | AgencyLeasingOverviewSection
  | AgencyLeasingPropertySection
  | AgencyLeasingTenantSection;
