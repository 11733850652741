import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'components/@codelitt/ay-design-library';
import { useQuery } from '@apollo/client';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';
import { colors } from 'constants/colors';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import Icon from 'components/Icon';
import { MARKETS_BOUNDARIES_QUERY } from 'graphql/markets/queries';
import { MapType } from './MapType';
import styles from './LatLonMap.module.scss';

type Coordinate = {
  lat?: number;
  lng?: number;
};

type MapProps = {
  latLng: Coordinate;
  marketId?: number;
  onMapChange: (center: Coordinate) => void;
  propertyTypeId?: number;
};

const DEFAULT_ZOOM = 17;
const MAP_OPTIONS = {
  fullscreenControl: false,
  zoomControl: false,
  rotateControl: false,
};

const LatLonMap: React.FC<MapProps> = ({
  latLng: { lat, lng },
  marketId,
  onMapChange,
  propertyTypeId,
}) => {
  const { t } = useTranslation();
  const mapRef = useRef<GoogleMapReact>(null);
  const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapType, setMapType] = useState('roadmap');

  const { data } = useQuery(MARKETS_BOUNDARIES_QUERY, {
    variables: {
      search: {
        filter: {
          marketId,
          propertyTypeId,
        },
      },
    },
    skip: !marketId || !propertyTypeId || !mapRef.current,
  });

  const boundaries = data?.submarketsBoundaries;

  const buttonsControl = [
    {
      iconName: 'add',
      className: styles['button-up'],
      action: () => setZoomLevel(zoomLevel + 1),
    },
    {
      iconName: 'minus',
      className: styles['button-down'],
      action: () => setZoomLevel(zoomLevel - 1),
    },
  ];

  const cleanUpGeoJsonData = (map: any) =>
    map?.data?.forEach((feature: any) => map.data.remove(feature));

  const addGeoJsonData = (map: any, boundaries: any) => {
    const features = boundaries.map((boundaries: any) => ({
      type: 'Feature',
      geometry: boundaries.geometry,
    }));
    map?.data?.addGeoJson({
      idPropertyName: 'marketBoundaries',
      type: 'FeatureCollection',
      features,
    });
  };

  useEffect(() => {
    const renderMarketsBoundaries = async () => {
      if (!mapRef.current) return;

      //@ts-ignore
      const { map_: map } = mapRef.current;

      if (isMapLoaded && map && boundaries) {
        cleanUpGeoJsonData(map);
        addGeoJsonData(map, boundaries);
      }
    };

    renderMarketsBoundaries();
  }, [boundaries, isMapLoaded]);

  const renderControls = () => (
    <div className={styles.controls}>
      {buttonsControl.map(({ action, iconName, className }, index) => (
        <button key={index} onClick={action} className={className}>
          <Icon
            name={iconName}
            className={styles['button-icon']}
            size={1}
            color={colors.ayWhiteColor}
          />
        </button>
      ))}
    </div>
  );

  return lat && lng ? (
    <Row>
      <Col>
        <div className={styles.container}>
          <div className={styles.instruction}>
            {`${t(
              I18N_PLATFORM_COMMON_WORD_PATH + '.mapPropertyLocationHelp',
            )}`}
          </div>

          <Icon
            className={styles.marker}
            size={2}
            name="crosshair"
            color={colors.primaryColor900}
          />
          <MapType mapType={mapType} setMapType={setMapType} />
          {renderControls()}

          <GoogleMapReact
            ref={mapRef}
            options={{ ...MAP_OPTIONS, mapTypeId: mapType }}
            center={{ lat: lat!, lng: lng! }}
            defaultZoom={DEFAULT_ZOOM}
            zoom={zoomLevel}
            onDragEnd={map =>
              onMapChange({ lat: map.center.lat(), lng: map.center.lng() })
            }
            onTilesLoaded={() => setIsMapLoaded(true)}
          />
        </div>
      </Col>
    </Row>
  ) : null;
};

export default LatLonMap;
