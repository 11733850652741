import { useQuery } from '@apollo/client';
import { IProperty } from 'interfaces/IProperty';
import { IAssetPerformance } from 'interfaces/IAssetPerformance';
import { LeasePeriodType } from 'constants/leases';
import { PROPERTIES_ANALYTICS_ASSET_PERFORMANCE } from 'graphql/propertiesAnalytics';

interface AnalyticsAssetPerformanceProps {
  properties: IProperty[];
  periodType: LeasePeriodType;
}

/**
 * useAnalyticsAssetPerformance
 * --------------------------------------------------------------------------
 * @description using this hook we will be able to fetch asset performance data
 * from propertiesAnalyticsAssetPerformance query passing a list of properties as parameter.
 */
export const useAnalyticsAssetPerformance = ({
  properties,
  periodType,
}: AnalyticsAssetPerformanceProps) => {
  const filterIds = properties.map(property => property.id);
  const variables = {
    search: {
      filter: {
        ids: filterIds,
      },
      type: periodType,
    },
  };

  const { data, refetch, loading } = useQuery<{
    propertiesAnalyticsAssetPerformance: IAssetPerformance;
  }>(PROPERTIES_ANALYTICS_ASSET_PERFORMANCE, {
    variables: variables,
    fetchPolicy: 'network-only',
  });

  return {
    assetPerformance: data?.propertiesAnalyticsAssetPerformance,
    isLoadingAssetPerformance: loading,
    refetch,
  };
};
