import React from 'react';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import styles from '../../FormSection.module.scss';
import { translateText } from 'utils/i18n';

interface Props {
  label: string;
  field: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  property?: IProperty;
  onChange: (field: string, value: any) => void;
}

const Comments: React.FC<Props> = ({
  value,
  label,
  field,
  onChange,
  disabled = false,
  required = false,
}) => {
  return (
    <FormControl
      isMultiline
      required={required}
      disabled={disabled}
      wrapperClassName={styles['form-row']}
      label={translateText(label)}
    >
      <div className={styles['large-input-container']}>
        <textarea
          id="comments"
          disabled={disabled}
          value={value || ''}
          data-testid="comments"
          className={styles['comment-area']}
          onChange={e => onChange(field, e.target.value)}
          placeholder={translateText(
            'avantPlatform.attributes.lease.prompt.enterComment',
          )}
        />
      </div>
    </FormControl>
  );
};

export default Comments;
