import { colors } from 'constants/colors';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_HEADINGS_PATH,
  I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH,
} from 'constants/i18n';

export const DEFAULT_ABSORPTION_YEARS = 5;

export const TITLE = [
  translateText(`${I18N_AVANT_PROPERTY_HEADINGS_PATH}.netAbsorption`),
  translateText(`${I18N_AVANT_PROPERTY_SUB_HEADINGS_PATH}.changeInOccupancy`),
];

export const LEGENDS = [
  {
    name: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.occupancyGains`,
    ),
    symbol: { fill: colors.supportive500 },
  },
  {
    name: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.occupancyLosses`,
    ),
    symbol: { fill: colors.secondaryColor500 },
  },
];
