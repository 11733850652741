import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import { IActivity } from 'interfaces/IActivity';
import { ILease } from 'interfaces/ILease';
import { IMarket } from 'interfaces/IMarket';
import { IProperty } from 'interfaces/IProperty';
import { IScoop } from 'interfaces/IScoop';
import { TypeOfCard } from '../utils';
import Actions from './Actions';
import LeaseActions from './LeaseActions';
import MarketHeader from './MarketHeader';
import PropertyHeader from './PropertyHeader';
import styles from './CardHeader.module.scss';

interface Props {
  activity?: IActivity;
  property?: IProperty;
  lease?: ILease;
  scoop?: IScoop;
  market?: IMarket;
  refetchActivities?: () => void;
  type?: TypeOfCard;
  hideMarketLine?: boolean;
  hideBuildingAttributes?: boolean;
  hideBuildingInfo?: boolean;
  tagLabel?: string;
  tagClassName?: string;
  openScoopEditDocuments?: boolean;
  setOpenScoopEditDocuments?: Dispatch<SetStateAction<boolean>>;
  propertySetId?: number;
}

const CardHeader: React.FC<Props> = ({
  scoop,
  property,
  market,
  activity,
  refetchActivities,
  type,
  hideMarketLine,
  hideBuildingAttributes,
  hideBuildingInfo,
  lease,
  tagLabel,
  tagClassName,
  openScoopEditDocuments,
  setOpenScoopEditDocuments,
  propertySetId,
}) => {
  const renderTitle = () => {
    if (hideBuildingInfo || type === TypeOfCard.CAROUSEL_PROPERTY) return null;
    if (market) {
      return <MarketHeader scoop={scoop} type={type} market={market} />;
    }
    return (
      <PropertyHeader
        property={property}
        type={type}
        hideMarketLine={hideMarketLine}
        hideBuildingAttributes={hideBuildingAttributes}
        market={market}
        scoop={scoop}
        tagLabel={tagLabel}
        tagClassName={tagClassName}
      />
    );
  };

  const renderActions = () => {
    if (type === TypeOfCard.SHARE) return null;

    if (lease) {
      return (
        <LeaseActions
          refetchActivities={refetchActivities!}
          activity={activity}
          lease={lease}
          hideShare={
            type === TypeOfCard.CAROUSEL_PROPERTY ||
            type === TypeOfCard.CAROUSEL
          }
        />
      );
    }
    if (activity) {
      return (
        <Actions
          openScoopEditDocuments={openScoopEditDocuments}
          setOpenScoopEditDocuments={setOpenScoopEditDocuments}
          activity={activity}
          refetchActivities={refetchActivities!}
          hideShare={
            type === TypeOfCard.CAROUSEL_PROPERTY ||
            type === TypeOfCard.CAROUSEL
          }
          propertySetId={propertySetId}
        />
      );
    }

    return null;
  };

  return (
    <>
      <div
        className={classNames(
          type !== TypeOfCard.CAROUSEL_PROPERTY &&
            !hideBuildingInfo &&
            styles.container,
        )}
      >
        {renderTitle()}
        {renderActions()}
      </div>
    </>
  );
};

export default CardHeader;
