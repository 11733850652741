import React, { useState } from 'react';
import { sortBy, startCase, toLower } from 'lodash';
import { useTranslation } from 'react-i18next';

import TextInput from 'components/Inputs/TextInput';
import FormControl from 'components/FormControl';
import GroupedOptionSearchFilter from 'components/GroupedOptionSearchFilter';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import { IndustryDataType } from 'components/FindComps/EditSearchCriteria/types';
import {
  I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH,
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { REGEX_INTEGERS } from 'constants/regex';
import { IdName } from 'interfaces/IdName';
import { ISaleInput } from 'interfaces/inputs/ISaleInput';
import { IMultiselectItem } from 'interfaces/IMultiselectItem';
import IIndustry from 'interfaces/IIndustry';
import { INDUSTRIES_QUERY } from 'graphql/industries';
import { translateText } from 'utils/i18n';

import styles from '../../FormSection.module.scss';
import { SaleFormSectionProps } from '..';

import {
  convertIdName,
  convertIndustriesToMultiselectItems,
  convertMultiselectItemsToIndustries,
} from '../../converters';

interface Props extends SaleFormSectionProps {
  processedSale: ISaleInput;
}

const SaleDetails: React.FC<Props> = ({
  processedSale,
  saleInput,
  updateSaleProperty,
}) => {
  const { t } = useTranslation();
  const [
    selectedIndustriesOfTenants,
    setSelectedIndustriesOfTenants,
  ] = useState<IIndustry[]>();

  const populateIndustriesOfTenantsField = (options: IMultiselectItem[]) => {
    const selectedIndustries = convertMultiselectItemsToIndustries(
      options || [],
    ).filter(industry => {
      return processedSale.industriesOfTenants?.some(
        el => el.id === industry.id,
      );
    });
    setSelectedIndustriesOfTenants(sortBy(selectedIndustries, 'id'));
  };

  return (
    <>
      <FormControl
        label={t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.rcaDealId`)}
        wrapperClassName={styles['form-row']}
        isMultiline
      >
        <TextInput
          id={'deal-id'}
          pattern={REGEX_INTEGERS}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          onChange={value => updateSaleProperty('dealId', value || null)}
          value={saleInput.dealId}
        />
      </FormControl>
      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.industryOfTenants`)}
        wrapperClassName={styles['form-row']}
        isMultiline
      >
        <GroupedOptionSearchFilter
          innerLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectIndustries`)}
          searchPlaceholder={startCase(
            toLower(
              t(`${I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH}.searchIndustry`),
            ),
          )}
          query={INDUSTRIES_QUERY}
          variables={{ search: {} }}
          queryParser={(data: IndustryDataType) =>
            convertIndustriesToMultiselectItems(
              data?.industries.filter(
                ({ category, name }) => category && name,
              ) || [],
            )
          }
          selectedItems={convertIndustriesToMultiselectItems(
            selectedIndustriesOfTenants || [],
          )}
          onChange={selectedOptions => {
            const selectedIndustries = convertMultiselectItemsToIndustries(
              selectedOptions || [],
            );
            setSelectedIndustriesOfTenants(selectedIndustries);
            updateSaleProperty(
              'industriesOfTenants',
              sortBy(selectedIndustries.map(convertIdName), 'id'),
            );
          }}
          onLoadOptions={populateIndustriesOfTenantsField}
        />
      </FormControl>

      <CompanySearchCombo
        label={t('avantProperties.labels.common.underBidders')}
        btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createSeller`)}
        inputId={'sale-under-bidders-input-id'}
        companies={saleInput.underbidders || []}
        placeholder={t('avantProperties.labels.common.enterName')}
        isMultiple
        wrapperClassName={styles['form-row']}
        onRemoveItem={(value: IdName) => {
          updateSaleProperty(
            'underbidders',
            saleInput.underbidders!.filter(tenant => tenant.id !== value.id),
          );
        }}
        onCompanySelection={(newValue: any) => {
          if (newValue?.custom && !newValue?.custom?.name) return;

          const value = newValue?.custom || newValue;

          updateSaleProperty('underbidders', [
            ...(saleInput.underbidders || []),
            {
              id: value.id,
              name: value.name,
            },
          ]);
        }}
      />

      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.note_plural`)}
        wrapperClassName={styles['form-row']}
        isMultiline
      >
        <div className={styles['large-input-container']}>
          <textarea
            placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterNote`)}
            className={styles['comment-area']}
            onChange={e => updateSaleProperty('comments', e.target.value)}
            value={saleInput.comments || ''}
          />
        </div>
      </FormControl>
    </>
  );
};

export default SaleDetails;
