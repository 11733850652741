import React from 'react';

interface IOrder {
  propertyId: number;
  order: number;
  featured?: boolean;
}

export interface ICompSetOrder {
  compSet: IOrder[];
  targetComps: IOrder[];
}

interface ICompSetOrderContext {
  compSetOrder: ICompSetOrder;
  setCompSetOrder: (ICompSetOrder: any) => void;
}

export const CompSetOrderContext = React.createContext<ICompSetOrderContext>({
  compSetOrder: { compSet: [], targetComps: [] },
  setCompSetOrder: () => null,
});

const { Provider } = CompSetOrderContext;

type Props = {
  children: React.ReactNode;
};

const CompSetOrderProvider: React.FC<Props> = ({ children }) => {
  const [compSetOrder, setCompSetOrder] = React.useState<ICompSetOrder>({
    compSet: [],
    targetComps: [],
  });

  const handleCompSetOrder = (newCompSetOrder: ICompSetOrder) => {
    setCompSetOrder(newCompSetOrder);
  };

  return (
    <Provider
      value={{
        setCompSetOrder: handleCompSetOrder,
        compSetOrder,
      }}
    >
      {children}
    </Provider>
  );
};

export default CompSetOrderProvider;
