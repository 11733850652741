import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { ILargestLeasesPopup } from './types';

export const LEASES_POPUP_INIT: ILargestLeasesPopup = {
  filter: undefined,
  hideAction: undefined,
  isVisible: false,
  leasesIds: undefined,
  popupFilter: undefined,
  popupSubtitle: '',
  popupTitle: '',
};

export const INITIAL_STATE = {
  filter: undefined,
  unitOfMeasurement: UnitOfMeasurement.sf,
  currencySymbol: getCurrencySymbol(),
  filterIds: [],
  currentYear: new Date().getFullYear(),
  metadata: {
    numberLeases: null,
    averageRentFS: null,
    averageRentFSMonthly: null,
    averageRentNNN: null,
    averageRentNNNMonthly: null,
    averageTIPsf: null,
    averageFreeRent: null,
    averageTerm: null,
    sumLeaseSize: null,
    sumLeaseSizeMt: null,
    averageLeaseSize: null,
    averageLeaseSizeMt: null,
    totalTenantInMarket: null,
    averageRentAtExpiration: null,
    averageRentFSAtExpiration: null,
    averageRentFSAtExpirationMonthly: null,
    averageRentNNNAtExpiration: null,
    averageRentNNNAtExpirationMonthly: null,
    averageNetEffectiveRentFS: null,
    averageNetEffectiveRentNNN: null,
  },
  metadataIsLoading: true,
  concessions: [],
  concessionsIsLoading: true,
  leasesPopup: LEASES_POPUP_INIT,
  shouldRefetch: false,
  leasesFilterType: '',
};

export const HISTORICAL_LEASING_YEARS = 6;
