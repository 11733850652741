import React from 'react';
import styles from './FormCard.module.scss';

interface Props {
  children: React.ReactNode;
}

const FormCard: React.FC<Props> = ({ children }) => (
  <section className={styles['form-card']}>{children}</section>
);

export default FormCard;
