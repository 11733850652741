import { ISaleInput } from 'interfaces/inputs/ISaleInput';
import {
  convertFloatToDecimal,
  formatNumberWithDecimals,
} from 'utils/formatters/number';
import { convertObjectNumericFields } from 'utils/parsers/convertObjectNumericFields';
import { isCondoSale, isLongLeaseHolder } from 'utils/sales';

export const processSaleBeforeSubmit = (
  input: ISaleInput,
  isUk: boolean,
): ISaleInput => {
  let sale = { ...input };

  // Do not submit the currencyCode and measurementSystem
  delete sale.currencyCode;
  delete sale.measurementSystem;

  sale = convertObjectNumericFields(sale, [
    'capRate',
    'numberOfBuildings',
    'price',
    'pricePerSF',
    'sf',
    'squareMeters',
    'occupancy',
    'longLeaseHoldGroundRent',
    'longLeaseHoldServiceCharge',
    'longLeaseHoldAdminCharge',
    'waultToExpiry',
    'waultToBreakDate',
    'totalRent',
  ]);

  if (!isCondoSale(sale)) {
    sale.floor = !!input.floor ? input.floor : null;
    sale.suite = !!input.suite ? input.suite : null;
    sale.sf = !!input.sf ? input.sf : null;
    sale.squareMeters = !!input.squareMeters ? input.squareMeters : null;
  }

  if (!isLongLeaseHolder(sale)) {
    sale.longLeaseHoldExpiry = null;
    sale.longLeaseHoldGroundRent = null;
    sale.longLeaseHoldServiceCharge = null;
    sale.longLeaseHoldAdminCharge = null;
  }

  if (!!sale.capRate) {
    sale.capRate = sale.capRate / 100;
  }

  if (!!sale.pricePerSF) {
    sale.pricePerSF = +formatNumberWithDecimals(sale.pricePerSF);
  }

  if (!!sale.interestPercent) {
    sale.interestPercent = convertFloatToDecimal(sale.interestPercent);
  }

  if (!!sale.occupancy) {
    sale.occupancy = convertFloatToDecimal(sale.occupancy);
  }

  if (!isUk) {
    delete sale?.waultToBreakDate;
    delete sale?.totalRent;
  }

  return sale;
};
