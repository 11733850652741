import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { arc } from 'd3-shape';
import {
  DONUT_SIZE,
  getDonutOuterRadius,
  getDonutInnerRadiusHover,
} from '../graphConstants';
import donutGenerator from './donutGenerator';
import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import { getDonutInnerSliceClass } from '../nodes';
import graphDimensions from '../graphConstants';

interface Props {
  donutData: IDonutGraphData[];
  donutId: number;
  size?: DONUT_SIZE;
  customSize?: number;
  shouldSortByTitle?: boolean;
}

const InnerDonut: React.FC<Props> = (props: Props) => {
  const { customSize, size, donutId, shouldSortByTitle } = props;
  const pieGroupRef = useRef(null);

  useEffect(() => {
    const node = pieGroupRef.current;

    const translate = {
      x: getDonutOuterRadius(size, customSize),
      y: getDonutOuterRadius(size, customSize),
    };
    const arcGenerator: any = arc()
      .outerRadius(getDonutOuterRadius(size, customSize) - 1)
      .innerRadius(getDonutInnerRadiusHover(size, customSize));

    select(node)
      .selectAll(`.${getDonutInnerSliceClass(donutId)}`)
      .data(donutGenerator(props.donutData, shouldSortByTitle))
      .join('path')
      .attr('class', getDonutInnerSliceClass(donutId))
      .attr('transform', 'translate(' + translate.x + ',' + translate.y + ')')
      .attr('d', arcGenerator)
      .attr('stroke-width', graphDimensions.STROKE_WIDTH)
      .attr('fill', 'transparent');

    // eslint-disable-next-line
  }, [props.donutData]);

  return <g ref={pieGroupRef} />;
};

export default InnerDonut;
