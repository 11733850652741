import {
  REGEX_INTEGERS,
  REGEX_THOUSAND_SEP_WITH_DECIMALS,
} from 'constants/regex';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  FormFieldConfig,
  FormFieldType,
} from 'components/FormControl/FormField/types';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { PropertyTypes } from 'constants/propertyTypes';
import { translateText } from 'utils/i18n';

export const getUKFields = (property: IPropertyInput): FormFieldConfig[] => {
  const fields: FormFieldConfig[] = [
    {
      type: FormFieldType.textMultiple,
      field: 'uarn',
      labelI18nKey: 'avantPlatform.attributes.property.label.uarn',
      textMultipleConfig: {
        fieldMultiple: 'uarn',
        addButtonText: translateText(
          'avantPlatform.attributes.property.action.addUarn',
        ),
        textInputProps: {
          placeholder: '00',
        },
      },
    },
    {
      type: FormFieldType.currency,
      labelI18nKey:
        'avantPlatform.attributes.property.label.currentYearTaxAssessment',
      placeholderI18nKey: '0',
      inputId: 'current-year-tax-assessment',
      pattern: REGEX_THOUSAND_SEP_WITH_DECIMALS,
      currencyConfig: {
        currencySymbol: getCurrencySymbol(property.currencyCode),
        fieldCurrency: 'currentYearTaxAssessment',
      },
    },
    {
      type: FormFieldType.integer,
      field: 'siteNumber',
      labelI18nKey: 'avantPlatform.attributes.property.label.siteNumber',
      pattern: REGEX_INTEGERS,
      placeholderI18nKey: '0',
    },
    {
      type: FormFieldType.company,
      field: 'siteNumberProviderCompany',
      inputId: 'site-number-provider-company',
      labelI18nKey:
        'avantPlatform.roles.company.label.siteNumberProviderCompany',
      placeholderI18nKey:
        'avantPlatform.roles.company.prompt.enterSiteNumberProviderCompany',
      companyConfig: {
        createCompanyLabelI18nKey:
          'avantPlatform.roles.company.action.createSiteNumberProviderCompany',
      },
    },
    {
      type: FormFieldType.text,
      field: 'breeam',
      labelI18nKey: 'avantPlatform.attributes.property.label.breeam',
      placeholderI18nKey:
        'avantPlatform.attributes.property.prompt.enterBreeam',
    },
    {
      type: FormFieldType.text,
      field: 'energyPerformanceCertificate',
      labelI18nKey:
        'avantPlatform.attributes.property.label.energyPerformanceCertificate',
      placeholderI18nKey:
        'avantPlatform.attributes.property.prompt.enterEnergyPerformanceCertificate',
    },
    {
      type: FormFieldType.text,
      field: 'fitwell',
      labelI18nKey: 'avantPlatform.attributes.property.label.fitwell',
      placeholderI18nKey:
        'avantPlatform.attributes.property.prompt.enterFitwell',
    },
    {
      type: FormFieldType.text,
      field: 'well',
      labelI18nKey: 'avantPlatform.attributes.property.label.well',
      placeholderI18nKey: 'avantPlatform.attributes.property.prompt.enterWell',
    },
    {
      type: FormFieldType.text,
      field: 'planningStatus',
      labelI18nKey: 'avantPlatform.attributes.property.label.planningStatus',
      placeholderI18nKey:
        'avantPlatform.roles.company.prompt.enterPlanningStatus',
    },
    {
      type: FormFieldType.company,
      field: 'architectCompany',
      inputId: 'architect-company',
      labelI18nKey: 'avantPlatform.roles.company.label.architectCompany',
      placeholderI18nKey:
        'avantPlatform.roles.company.prompt.enterArchitectCompany',
      companyConfig: {
        createCompanyLabelI18nKey:
          'avantPlatform.roles.company.action.createArchitectCompany',
      },
    },
    {
      type: FormFieldType.integer,
      field: 'numberOfElevators',
      labelI18nKey: 'avantPlatform.attributes.property.label.numberOfElevators',
      placeholderI18nKey: '0',
    },
  ];

  return property?.propertyType?.name === PropertyTypes.office
    ? [
        ...fields,
        {
          type: FormFieldType.integer,
          field: 'storiesAboveGround',
          labelI18nKey:
            'avantPlatform.attributes.property.label.storiesAboveGround',
          placeholderI18nKey: '0',
        },
        {
          type: FormFieldType.integer,
          field: 'storiesBelowGround',
          labelI18nKey:
            'avantPlatform.attributes.property.label.storiesBelowGround',
          placeholderI18nKey: '0',
        },
      ]
    : fields;
};
