import React from 'react';
import classnames from 'classnames';
import styles from './Header.module.scss';
import SectionTitle from 'components/SectionTitle';
import { Col, Row } from 'components/@codelitt/ay-design-library';

interface Props {
  content?: React.ReactNode;
  contentToRight?: boolean;
  fontSize?: number;
  title?: string;
  titleColSize?: number;
  rowWrapperClassName?: string;
}

const Header: React.FC<Props> = ({
  content,
  contentToRight,
  fontSize,
  title,
  titleColSize = 4,
  rowWrapperClassName,
}) => {
  const contentSize = 12 - titleColSize;
  return (
    <Row wrapperClassName={rowWrapperClassName}>
      {title && (
        <Col md={titleColSize as any}>
          <SectionTitle title={title} fontSize={fontSize} />
        </Col>
      )}
      {content && (
        <Col
          md={(title ? contentSize : 12) as any}
          wrapperClassName={classnames(styles['second-column'], {
            [styles['full-width']]: !title,
            [styles['content-to-right']]: contentToRight,
          })}
        >
          {content}
        </Col>
      )}
    </Row>
  );
};

export default Header;
