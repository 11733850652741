import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import Simplebar from 'simplebar';
import classnames from 'classnames';
import styles from './MarketSelectorModal.module.scss';
import { MARKETS_QUERY } from 'graphql/markets';
import ModalWrapper from '../ModalWrapper';
import { IdName } from 'interfaces/IdName';
import Button from 'components/Button';
import { useQuery } from '@apollo/client';
import { SortDirections } from 'constants/sortDirections';
import { FLAGS_ICONS } from 'assets';
import { groupByCountry } from 'utils/formatters/markets';
import { ICountry, IMarket } from 'interfaces/IMarket';
import TextInput from 'components/Inputs/TextInput';
import { authContext } from 'contexts/AuthContext';
import LoadingMessage from 'components/LoadingMessage';
import CheckboxGroupInput from 'components/Inputs/CheckboxGroupInput';
import { isEqual } from 'lodash';
import SelectAndDeselect from 'components/SelectAndDeselect';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { useTranslation } from 'react-i18next';
import { deepCopy } from 'utils/objects';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSubmit?: (selectedOptions: IdName[]) => void;
  selectedItems?: IdName[];
  showRemoveSelection?: boolean;
  showResetMarketsButton?: boolean;
}

const MarketSelectorModal: React.FC<Props> = ({
  isVisible,
  onClose,
  onSubmit,
  selectedItems,
  showRemoveSelection,
  showResetMarketsButton,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const scrollWrapperRef = useRef<HTMLTableElement>(null);
  const { t } = useTranslation();

  const { user } = React.useContext(authContext);
  const userMarketIds = user.preferences?.marketIds;
  const [searchInputText, setSearchInputText] = useState('');
  const [selectedMarkets, setSelectedMarkets] = useState<IdName[]>([]);

  const { loading, data } = useQuery(MARKETS_QUERY, {
    variables: {
      search: {
        order: { field: 'name', direction: SortDirections.asc },
      },
    },
    fetchPolicy: 'cache-first',
  });

  useLayoutEffect(() => {
    if (scrollWrapperRef?.current) {
      new Simplebar(scrollWrapperRef.current!, {
        autoHide: false,
      });
    }
  });

  useEffect(() => {
    if (isVisible) {
      searchInputRef.current!.focus();
    }
  }, [isVisible]);

  useEffect(() => {
    if (selectedItems?.length) setSelectedMarkets(selectedItems);
  }, [selectedItems, data]);

  const getVisibleOptions = () => {
    const options = loading ? [] : deepCopy(data).markets;
    let visibleOptions = options;

    if (searchInputText.length) {
      visibleOptions = visibleOptions.filter((option: IdName) => {
        return (
          option.name!.toLowerCase().indexOf(searchInputText.toLowerCase()) !==
          -1
        );
      });
    }

    visibleOptions = groupByCountry(visibleOptions);

    return visibleOptions;
  };

  const renderResults = () => (
    <div>
      {getVisibleOptions().map((visibleOptionsGroup: ICountry) => {
        if (!!visibleOptionsGroup.markets?.length) {
          return (
            <div key={visibleOptionsGroup.name}>
              <div className={styles['options-label-container']}>
                {!!FLAGS_ICONS[visibleOptionsGroup.code] && (
                  <img
                    src={FLAGS_ICONS[visibleOptionsGroup.code]}
                    className={styles['flag-image']}
                    alt={visibleOptionsGroup.code}
                  />
                )}
                <p className={styles['options-label']}>
                  {visibleOptionsGroup.name}
                </p>
              </div>
              <CheckboxGroupInput
                size="regular"
                itemWrapperClassName={styles['radio-item']}
                selectedItems={selectedMarkets || []}
                data={visibleOptionsGroup.markets}
                onChange={setSelectedMarkets}
                hideSelectAndDeselect
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );

  const renderLoading = () => {
    if (!loading) return null;

    return (
      <LoadingMessage
        itemBeingLoaded={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`)}
      />
    );
  };

  const isResetMarketsDisabled = () => {
    return (
      !userMarketIds?.length ||
      isEqual(
        selectedMarkets?.map(market => market.id),
        userMarketIds,
      )
    );
  };

  const resetToUserMarkets = () => {
    const userMarkets = userMarketIds?.map(marketId => {
      const { id, name }: IMarket = data.markets.find(
        (market: IMarket) => market.id === marketId,
      );
      return {
        id,
        name,
      };
    });

    setSelectedMarkets(userMarkets || []);
  };

  const isValid = () => {
    const hasSelectedMarkets = selectedMarkets?.length > 0;

    if (showRemoveSelection && !hasSelectedMarkets) return true;

    return hasSelectedMarkets;
  };

  const handleClose = () => {
    onClose();
    setSelectedMarkets(selectedItems || []);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <ModalWrapper
      large
      withCloseButton
      onCancel={handleClose}
      wrapperClassName={styles['modal-container']}
    >
      <div className={styles.header}>
        {t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectMarket`)}
        <div className={styles['header-actions-container']}>
          {showResetMarketsButton && (
            <Button
              type={'ghost'}
              wrapperClassName={classnames(
                styles['action-button'],
                styles['reset-market'],
              )}
              label={t(
                'avantPlatform.attributes.userSettings.label.resetMyMarkets',
              )}
              isDisabled={isResetMarketsDisabled()}
              onClick={resetToUserMarkets}
            />
          )}
          {showRemoveSelection && (
            <Button
              type={'delete'}
              wrapperClassName={classnames(
                styles['action-button'],
                styles['remove-selection'],
              )}
              label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.removeSelection`)}
              isDisabled={!selectedMarkets?.length}
              onClick={() => setSelectedMarkets([])}
            />
          )}
        </div>
      </div>
      <div>
        <div className={styles['search-input-container']}>
          <TextInput
            ref={searchInputRef}
            inputClassName={styles['search-input']}
            fullWidth
            borderBox
            iconPosition={'left'}
            value={searchInputText}
            placeholder={t(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.searchPreferredMarket`,
            )}
            onChange={setSearchInputText}
          />
        </div>
      </div>

      {renderLoading()}
      <div className={styles.options} ref={scrollWrapperRef}>
        <SelectAndDeselect
          selectedItems={selectedMarkets}
          options={data?.markets || []}
          onSelectAll={(selectedOptions?: IdName[]) =>
            setSelectedMarkets(selectedOptions || [])
          }
          onDeselectAll={() => setSelectedMarkets([])}
          wrapperClassName={styles['select-deselect']}
        />
        {renderResults()}
      </div>
      <div className={styles['modal-footer']}>
        <Button
          wrapperClassName={styles['tab-button']}
          onClick={handleClose}
          type={'neutral'}
          label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.cancel`)}
        />
        <Button
          wrapperClassName={styles['tab-button']}
          onClick={() => {
            onSubmit?.(selectedMarkets || []);
            onClose();
          }}
          isDisabled={!isValid()}
          type={'main'}
          label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.apply`)}
        />
      </div>
    </ModalWrapper>
  );
};

export default MarketSelectorModal;
