import { capitalize } from 'lodash';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import { IPricingQuote } from 'interfaces/IPricingQuote';
import { convertObjectNumericFields } from 'utils/parsers/convertObjectNumericFields';
import { convertFloatToDecimal } from 'utils/formatters/number';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { isEscalationTypePercentage } from '../FormFields/EscalationFields/utils';

export const buildPropertyPriceQuotePayload = (
  pricingQuote: IPricingQuoteInput,
  measurement?: string,
): IPricingQuote => {
  const pricingQuoteSize = pricingQuote.size
    ? Number(pricingQuote.size.replace(/,/g, ''))
    : null;
  const pricingQuoteSizeMt = pricingQuote.sizeMt
    ? Number(pricingQuote.sizeMt.replace(/,/g, ''))
    : null;

  const payload: IPricingQuote = {
    id: pricingQuote?.id,
    propertyId: pricingQuote?.property?.id!,
    tenantCompanyId: pricingQuote?.tenant?.id!,
    askingRentPsf: pricingQuote.askingRent!,
    leaseType: capitalize(pricingQuote.leaseType!),
    rentType: pricingQuote.rentType!,
    source: pricingQuote.source!,
    pricing: pricingQuote.pricing!,
    tiAllowance:
      pricingQuote.tenantImprovementAllowance ||
      pricingQuote.tenantImprovementAllowance === 0
        ? pricingQuote.tenantImprovementAllowance
        : null,
    freeRentMonths:
      pricingQuote.freeRentMonths || pricingQuote.freeRentMonths === 0
        ? pricingQuote.freeRentMonths
        : null,
    term: pricingQuote.termInMonths || null,
    expensesYear:
      new Date(pricingQuote.date!).getFullYear() || new Date().getUTCFullYear(),
    opExAndTaxes: Number(pricingQuote.taxAndOpex) || null,
    operatingExpenses: pricingQuote.opex ? Number(pricingQuote.opex) : null,
    taxes: pricingQuote.tax ? Number(pricingQuote.tax) : null,
    comment: pricingQuote.comments || null,
    floors: pricingQuote.floor! || null,
    size: pricingQuoteSize,
    sizeMt: pricingQuoteSizeMt,
    date: pricingQuote.date!,
    escalationIncrement: pricingQuote?.escalationIncrement ?? null,
    escalationType: pricingQuote?.escalationType ?? null,
    escalation:
      getEscalationValueForPriceQuite(
        pricingQuote?.escalation,
        pricingQuote?.escalationType,
      ) ?? null,
  };

  const payloadWithNumericFields = convertObjectNumericFields(payload, [
    'askingRentPsf',
    'tiAllowance',
    'freeRentMonths',
    'term',
  ]);

  measurement === UnitOfMeasurement.sm
    ? delete payloadWithNumericFields?.size
    : delete payloadWithNumericFields?.sizeMt;

  return payloadWithNumericFields;
};

export const getEscalationValueForPriceQuite = (
  escalation: string | null | undefined,
  escalationType: string | null | undefined,
): string | null | undefined => {
  if (escalation) {
    const escalationNumber = Number(escalation);
    return isEscalationTypePercentage(escalationType)
      ? String(convertFloatToDecimal(escalationNumber))
      : escalation;
  }
  return escalation;
};
