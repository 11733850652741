import columnStyles from '../ComparativeColumns/ComparativeColumns.module.scss';
import { max } from 'lodash';

const updateRowHeight = (infoRow: HTMLElement, maxHeight?: number) => {
  infoRow.style.height = maxHeight ? `${maxHeight}px` : '';
  infoRow.style.paddingTop = maxHeight ? '0' : '';
  infoRow.style.paddingBottom = maxHeight ? '0' : '';
};

const getColumnsMeasures = (
  columns: NodeListOf<HTMLDivElement>,
): number[][] => {
  const columnsHeights: number[][] = [];

  columns.forEach((column: HTMLDivElement) => {
    const infoRows = column.getElementsByClassName(
      columnStyles['information-row'],
    ) as HTMLCollectionOf<HTMLElement>;

    const infoRowHeights = Array.from(infoRows).map(infoRow => {
      // remove inline styles
      updateRowHeight(infoRow);
      return infoRow.getBoundingClientRect().height;
    });
    columnsHeights.push(infoRowHeights);
  });

  return columnsHeights;
};

/**
 * This function maps each row of the comparative table, and update each element to have the same height as its neighbor.
 * @param rows
 */
export default (rows: HTMLDivElement[]) => {
  rows.forEach((row: HTMLDivElement) => {
    if (!row) return;
    const columns = row.childNodes as NodeListOf<HTMLDivElement>;
    const columnsMeasures: number[][] = getColumnsMeasures(columns);

    const firstColumnMeasures: number[] =
      columnsMeasures.find(m => !!m.length) || [];

    firstColumnMeasures.forEach((_measure: number, index: number) => {
      // Calculate the max height for the row, considering all columns.
      const rowHeights = columnsMeasures.map(
        (colBuffer: number[]) => colBuffer[index],
      );
      const maxHeight = max(rowHeights);

      // Apply the max height in every row
      columns.forEach((column: HTMLDivElement) => {
        const infoRows = column.getElementsByClassName(
          columnStyles['information-row'],
        ) as HTMLCollectionOf<HTMLElement>;

        if (infoRows[index]) {
          // Apply the height on each row
          updateRowHeight(infoRows[index], maxHeight);
        }
      });
    });
  });
};
