export const colors = {
  // New Colors
  primaryOnDark: '#a08fff',
  primaryColor900: '#06061a',
  primaryColor700: '#21214b',
  primaryColor600: '#3B377A',
  primaryColor500: '#55489e',
  primaryColor400: '#817db8',
  primaryColor300: '#a7a3cd',
  primaryColor200: '#cecde3',
  primaryColor100: '#eae9f3',
  secondaryColor500: '#ef6528',
  secondaryColor700: '#8c4722',
  uiColor200: '#dfdee3',
  uiColor300: '#c7c5cb',
  uiColor400: '#9b9ba5',
  uiColor500: '#777886',
  uiColor600: '#585C6C',
  uiColor700: '#3A4458',
  uiColor800: '#222A3A',
  uiColor900: '#12161F',
  uiColorBlack: '#000000',
  supportive100: '#DCEBE4',
  supportive300: '#84C0A9',
  supportive500: '#4db595',
  supportive500_opacity: '#4DB59552',
  supportive700: '#2D594A',
  supportiveBlue400: '#5fb1ff',
  supportiveBlue500: '#1990FF',
  supportivePink500: '#ec407a',
  supportiveYellow500: '#F5AD1D',

  // Old Colors
  ayNavyBlueColor: '#010e28',
  ayMainBlueColor: '#00205B',
  ayNightBlueColor: '#0337a0',
  ayMadridBlueColor: '#0553f0',
  ayBerlinBlueColor: '#73a1fc',
  ayLondonBlueColor: '#c3d7fe',

  ayWineRedColor: '#6e130c',
  ayMainRedColor: '#da291c',
  ayEllieRedColor: '#f9a59f',
  ayPepapigRedColor: '#fddbd9',

  ayLeafGreenColor: '#31725c',
  ayGrassGreenColor: '#40b58e',
  ayPearGreenColor: '#90e0c2',
  ayFrozenGrassColor: '#d2efe5',

  ayPureWhiteColor: '#ffffff',
  ayWhiteColor: '#fcfcfc',
  ayBlackColor: '#131416',
  ayTotalBlackColor: '#000000',

  ayGrey80Color: '#383c42',
  ayGrey64Color: '#5e636e',
  ayGrey40Color: '#868c98',
  ayGrey24Color: '#b2b6bd',
  ayGrey16Color: '#dee0e3',
  ayGrey8Color: '#f4f4f5',
  ayWinterGreyColor: '#dbdbd3',

  aySuccessColor: '#2cc92c',
  ayErrorColor: '#f02405',
  ayWarningColor: '#f2952c',

  ayVioletColor: '#9291C4',
  ayDarkGoldColor: '#a05b0d',
  ayMainGoldColor: '#f18f1f',
  ayGold8Color: '#f9e1c6',
  ayStoneColor: '#DBDBD3',

  aySystemColorWarning: '#f5ad1d',
};
