import React from 'react';
import classNames from 'classnames';
import styles from './ActionButton.module.scss';
import { colors } from 'constants/colors';
import Icon from 'components/Icon';

interface ActionButtonProps {
  buttonClassName: string;
  icon: string;
  onClick: () => void;
  iconColor?: string;
}

const ActionButton = ({
  buttonClassName,
  icon,
  iconColor = colors.primaryColor500,
  onClick,
}: ActionButtonProps) => {
  return (
    <div
      className={classNames(styles['action-button'], buttonClassName)}
      onClick={onClick}
    >
      <Icon size={0.8} name={icon} color={iconColor} />
    </div>
  );
};

export default ActionButton;
