import { IMarket, ICountry } from 'interfaces/IMarket';
import { SUM } from 'constants/placeholders';
import { IdName } from 'interfaces/IdName';

export const groupByCountry = (markets: IMarket[]) => {
  const groupedMarkets: ICountry[] = [];

  markets?.forEach(market => {
    if (market.country) {
      const countryGroup = groupedMarkets.find(
        country => market.country?.name === country.name,
      );

      if (!!countryGroup) {
        if (!!countryGroup?.markets) {
          countryGroup.markets.push(market);
        } else {
          Object.assign(countryGroup, {
            markets: [{ id: market.id, name: market.name }],
          });
        }
      } else {
        groupedMarkets.push(
          Object.assign(market.country, {
            markets: [{ id: market.id, name: market.name }],
          }),
        );
      }
    } else {
      groupedMarkets[0].markets?.push({ id: market.id, name: market.name });
    }
  });

  return groupedMarkets;
};

export const formatMarketsLabel = (markets: IdName[]): string | null => {
  if (!markets.length) return null;

  const firstMarket = markets![0];
  const totalMarkets = markets?.length;
  const areMoreThanOne = totalMarkets > 1;

  return `${firstMarket.name} ${areMoreThanOne ? SUM : ''} ${
    areMoreThanOne ? totalMarkets - 1 : ''
  }`;
};

export const formatPropertyTypeLabel = (
  propertyTypes: IdName[],
): string | null => {
  if (!propertyTypes.length) return null;

  const firstType = propertyTypes![0];
  const totalTypes = propertyTypes?.length;
  const areMoreThanOne = totalTypes > 1;

  return `${firstType.name} ${areMoreThanOne ? SUM : ''} ${
    areMoreThanOne ? totalTypes - 1 : ''
  }`;
};
