import { IPropertyCommon } from 'interfaces/IPropertyCommon';
import { PropertyTypeNames, PropertyTypes } from 'constants/propertyTypes';
import { CountryNames, CountryCodes } from 'constants/countryCodes';
import { IProperty } from 'interfaces/IProperty';
import { SearchOptionsType } from 'interfaces/ISearchOptionsType';
import { getCurrentYear } from 'utils/date';

export const isOfficeBuilding = (property: IPropertyCommon) =>
  property.propertyType?.name === PropertyTypes.office && !!property.office;

export const isLifeScienceBuilding = (property: IPropertyCommon) =>
  property.propertyType?.name === PropertyTypes.lifeScience;

export const isIndustrialBuilding = (property: IPropertyCommon) =>
  [PropertyTypes.industrial, PropertyTypes.flexIndustrial].includes(
    property.propertyType?.name,
  );

export const isDataCenterBuilding = (property: IPropertyCommon) =>
  property.propertyType?.name === PropertyTypes.dataCenter;

export const isMultifamilyBuilding = (property: IPropertyCommon) =>
  property?.propertyType?.name === PropertyTypes.multifamily;

export const isMultifamilyBuildingList = (properties: IPropertyCommon[]) => {
  const propertiesNames = properties.map(p => p.propertyType?.name);
  return propertiesNames.includes(PropertyTypes.multifamily);
};

export const isLandAndDevelopmentBuilding = (property: IPropertyCommon) =>
  property.propertyType?.name === PropertyTypes.landAndDevelopment;

export const isUKCountry = (country?: string) =>
  !!country &&
  (country === CountryNames.UK ||
    country === CountryCodes.UK ||
    country === CountryCodes.GB);

export const isUkProperty = (property: IProperty | undefined) => {
  if (!property) return false;
  return property.propertyCountry?.id === 4;
};

export const checkIfBuildingSizeIsRequired = (property: IPropertyCommon) => {
  const { propertyType } = property;
  const { industrial, office, flexIndustrial } = PropertyTypes;
  const typesWithRequiredBuildingSize = [industrial, office, flexIndustrial];

  return typesWithRequiredBuildingSize.some(
    type => propertyType?.name === type,
  );
};

export const convertIPropertyIntoIPropertyLookupInput = (
  property: IProperty | undefined,
  returnMarketPropertyIds?: boolean,
) => ({
  id: property?.id,
  primaryAddress: property?.primaryAddress,
  name: property?.name,
  city: property?.city,
  market: property?.market?.name,
  country: property?.country,
  postalCode: property?.postalCode,
  status: property?.status?.name,
  type: property?.type,
  ...(returnMarketPropertyIds && {
    marketId: property?.market?.id,
    propertyTypeId: property?.propertyType?.id,
  }),
});

/**
 * The property type name is used in many places in the app
 * Due to the FindComps URL refactoring we need to add the properties names using this function
 *
 * @param types
 * @returns
 */
export const getPropertyTypeNames = (types?: SearchOptionsType[]) => {
  let propertyTypesNames = types?.map(type => type.name).filter(Boolean);

  if (!propertyTypesNames?.length) {
    propertyTypesNames = types?.map(type => PropertyTypeNames[type.id]);
  }

  return propertyTypesNames;
};

export const getCurrentYearExpense = (property: IProperty) => {
  const currentYear = getCurrentYear();

  return property.expenses?.find(expense => expense.year === currentYear);
};
