import { ITransactionVolumeDataPoint } from '../interfaces';
import { ScaleLinear } from 'd3-scale';
import {
  rhombusPath,
  roundedTrianglePath,
} from '../../utils/polygonPathDrawer';
import { graphDimensions } from '../graphConstants';

const PADDING_MIDDLE = 0.5;
const PADDING_TOP = 5.5;

/**
 * The triangles for the purchased values are shown at the top.
 * This function returns the SVG path to draw the triangle.
 */
export const getTrianglePathPurchased = (
  d: ITransactionVolumeDataPoint,
  halfHeight: number,
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
) => {
  if (d.valuePurchased <= 0) {
    return '';
  }

  // The base Y is the middle of the graph. This padding is to put a small line between the purchased and sold triangles.
  const baseY = halfHeight - PADDING_MIDDLE;

  // Position for the year
  const baseX = xScale(d.year);

  // This is the starting point to draw the triangle. It is placed right next of the YEAR text, in the middle of the graph.
  const baseLeftPoint = { x: baseX, y: baseY };

  // This value is the Y coordinate at the center point of the triangle, at the top.
  const topY = halfHeight - yScale(d.valuePurchased) - PADDING_TOP;

  // The X coordinate for the center point is the middle of the triangle.
  const topX = baseX + graphDimensions.TRIANGLE_WIDTH / 2;
  const topPoint = { x: topX, y: topY };

  // the point at the right is the last point, placed in the middle of the graph
  const baseRightPoint = {
    x: baseX + graphDimensions.TRIANGLE_WIDTH,
    y: baseY,
  };

  return roundedTrianglePath(baseLeftPoint, topPoint, baseRightPoint);
};

/**
 * The triangles for the sold values are shown at the bottom.
 * This function returns the SVG path to draw the triangle.
 */
export const getTrianglePathSold = (
  d: ITransactionVolumeDataPoint,
  halfHeight: number,
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
) => {
  if (d.valueSold <= 0) {
    return '';
  }
  // The base Y is the middle of the graph. This padding is to put a small line between the purchased and sold triangles.
  const baseY = halfHeight + PADDING_MIDDLE;

  // Position for the year
  const baseX = xScale(d.year);

  // This is the starting point to draw the triangle. It is placed right next of the YEAR text, in the middle of the graph.
  const baseLeftPoint = { x: baseX, y: baseY };

  // This value is the Y coordinate at the center point of the triangle, at the bottom. We sum the halfHeight of the graph to
  // move this point to bottom, so the triangle will look upside down.
  const topY = yScale(d.valueSold) + halfHeight + PADDING_TOP;

  // The X coordinate for the center point is the middle of the triangle.
  const topX = baseX + graphDimensions.TRIANGLE_WIDTH / 2;
  const topPoint = { x: topX, y: topY };

  // the point at the right is the last point, placed in the middle of the graph
  const baseRightPoint = {
    x: baseX + graphDimensions.TRIANGLE_WIDTH,
    y: baseY,
  };

  return roundedTrianglePath(baseLeftPoint, topPoint, baseRightPoint);
};

export const getRhombusOutline = (
  d: ITransactionVolumeDataPoint,
  halfHeight: number,
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
  yScaleBottom: ScaleLinear<number, number>,
) => {
  if (!d.valueSold && !d.valuePurchased) {
    return '';
  }

  if (d.valueSold && !d.valuePurchased) {
    return getTrianglePathSold(d, halfHeight, xScale, yScaleBottom);
  } else if (d.valuePurchased && !d.valueSold) {
    return getTrianglePathPurchased(d, halfHeight, xScale, yScale);
  }

  const baseY = halfHeight - 0.5;
  const baseX = xScale(d.year);
  const topY = halfHeight - yScale(d.valuePurchased) - PADDING_TOP;
  const topPoint = { x: baseX + graphDimensions.TRIANGLE_WIDTH / 2, y: topY };
  const baseLeftPoint = { x: baseX, y: baseY };
  const baseRightPoint = {
    x: baseX + graphDimensions.TRIANGLE_WIDTH,
    y: baseY,
  };

  const bottomY = yScaleBottom(d.valueSold) + halfHeight + PADDING_TOP;
  const bottomPoint = {
    x: topPoint.x,
    y: bottomY,
  };

  return rhombusPath(baseLeftPoint, topPoint, baseRightPoint, bottomPoint);
};
