import React, { useState } from 'react';
import { ILeasesAnalyticsExpirationsResponse } from 'interfaces/ILeasesAnalyticsExpiration';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryTooltip,
} from 'victory';
import {
  parseGraphData,
  GRAPH_TITLE,
  getExpirationLegend,
  EXPIRING_CHART_HEIGHT,
  FIRST_QUARTER,
} from './utils';

import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
  I18N_AVANT_PROPERTY_PAGES,
} from 'constants/i18n';
import { colors } from 'constants/colors';
import { abbreviateNumber } from 'utils/formatters/number';
import { DOT_LG } from 'constants/placeholders';
import MissingGraphData from 'components/MissingGraphData';
import GraphContainer from '../GraphContainer';
import GraphHeader from '../HorizontalChartsElements/GraphHeader';
import { DEFAULT_BAR_STYLE } from 'components/VictoryBarChart/utils';
import { ExpirationBarStyle } from './interfaces';
import { AxisData } from '../VerticalGroupBarChart/types';

type Props = {
  data?: ILeasesAnalyticsExpirationsResponse;
  unitOfMeasurement?: string;
  handleClick: (expiringLeasePeriod: string) => void;
  style?: ExpirationBarStyle;
  height?: number;
  isMissingData?: boolean;
  isQuarterly?: boolean;
};

const I18N_PATH = `${I18N_AVANT_PROPERTY_PAGES}.findComps.subtitles`;

const VictoryLeaseExpiration: React.FC<Props> = ({
  data,
  unitOfMeasurement,
  handleClick,
  style,
  isMissingData,
  isQuarterly,
}) => {
  const [tickBarHovered, setTickBarHovered] = useState<string | undefined>(
    undefined,
  );
  const GRAPH_HEIGHT = style?.chart?.height || EXPIRING_CHART_HEIGHT;
  const handleQuarterAxis = (value: string) => {
    const data = value.split('/');
    return data[1] === FIRST_QUARTER.toString() ? data[0] : '';
  };
  const expiringLeasesItems = data?.items;
  const barData = parseGraphData(expiringLeasesItems, !isQuarterly);
  const victoryAxisStyle =
    style?.xAxis?.style || DEFAULT_BAR_STYLE.xAxis?.style;
  const controlledVictoryAxisStyle = {
    ...victoryAxisStyle,
    grid: {
      ...victoryAxisStyle.grid,
      stroke: (t: any) =>
        t.tick === tickBarHovered ? colors.primaryColor500 : colors.uiColor700,
      strokeWidth: (t: any) => (t.tick === tickBarHovered ? 2 : 0),
    },
  };

  return (
    <GraphContainer style={style?.parent}>
      {isMissingData ? (
        <>
          <GraphHeader title={GRAPH_TITLE} hasMissingData={isMissingData} />
          <MissingGraphData />
        </>
      ) : (
        <VictoryChart
          domainPadding={style?.chart?.domainPadding}
          style={style?.chart?.style}
          height={GRAPH_HEIGHT}
          padding={style?.chart?.padding}
        >
          <VictoryLabel
            x={style?.label?.x}
            y={style?.label?.y}
            text={[GRAPH_TITLE, translateText(`${I18N_PATH}.sfPerQuarter`)]}
            backgroundPadding={style?.label?.backgroundPadding}
            style={style?.label?.style}
          />
          <VictoryLegend
            height={style?.legend?.height}
            width={style?.legend?.width}
            x={style?.legend?.x}
            y={style?.legend?.y}
            orientation="horizontal"
            style={style?.legend?.style}
            data={getExpirationLegend()}
          />
          <VictoryAxis
            dependentAxis
            tickValues={barData.yAxis}
            style={(style?.yAxis as AxisData)?.style}
            offsetX={(style?.yAxis as AxisData)?.offsetX}
            tickFormat={(style?.yAxis as AxisData)?.tickFormat}
          />
          <VictoryAxis
            tickValues={barData.xAxis}
            style={controlledVictoryAxisStyle}
            tickFormat={x => handleQuarterAxis(x)}
          />
          <VictoryBar
            data={barData.items}
            cornerRadius={style?.bar?.cornerRadius}
            barRatio={style?.bar?.barRatio}
            style={style?.bar?.style}
            labelComponent={
              <VictoryTooltip
                cornerRadius={style?.bar?.tooltip?.cornerRadius}
                pointerLength={style?.bar?.tooltip?.pointerLength}
                flyoutHeight={style?.bar?.tooltip?.flyoutHeight}
                flyoutStyle={style?.bar?.tooltip?.flyoutStyle}
                y={style?.bar?.tooltip?.y}
              />
            }
            labels={({ datum }) =>
              `${abbreviateNumber(datum.y)} ${unitOfMeasurement} ${DOT_LG} ${
                datum.count
              } ${translateText(
                `${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.leases`,
              )} ${isQuarterly ? `${DOT_LG} Q${datum.quarter}` : ''}`
            }
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: 'data',
                        mutation: ({ style, datum }) => {
                          setTickBarHovered(datum.x);
                          return {
                            style: {
                              fill: style.fill,
                              strokeWidth: 3,
                              stroke: `${style.fill}52`,
                              cursor: 'pointer',
                            },
                          };
                        },
                      },
                      {
                        target: 'labels',
                        mutation: () => ({
                          active: true,
                          style: {
                            fill: colors.ayPureWhiteColor,
                            fontSize: 5,
                            fontFamily: 'Open Sans',
                          },
                        }),
                      },
                    ];
                  },
                  onMouseOut: () => {
                    setTickBarHovered(undefined);
                    return [
                      {
                        target: 'data',
                        mutation: props => ({
                          style: {
                            fill: props.style.fill,
                            fillOpacity: 1,
                            strokeWidth: 0,
                            cursor: 'default',
                          },
                        }),
                      },
                      {
                        target: 'labels',
                        mutation: () => ({
                          active: false,
                        }),
                      },
                    ];
                  },
                  onClick: () => {
                    return [
                      {
                        target: 'data',
                        mutation: props => handleClick(props.datum.x),
                      },
                    ];
                  },
                },
              },
            ]}
          />
        </VictoryChart>
      )}
    </GraphContainer>
  );
};

export default VictoryLeaseExpiration;
