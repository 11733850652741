export const updateLeaseTaxAndOpexValues = (
  updateLeaseProperty: (property: string, propertyValue: any) => void,
  opexValue?: any,
  taxValue?: any,
  taxAndOpexValue?: any,
  taxAndOpexChangedManuallyValue?: any,
) => {
  updateLeaseProperty('opex', opexValue);
  updateLeaseProperty('tax', taxValue);
  updateLeaseProperty('taxAndOpex', taxAndOpexValue);
  updateLeaseProperty(
    'taxAndOpexChangedManually',
    taxAndOpexChangedManuallyValue,
  );
};
