import React, {
  useRef,
  useEffect,
  useState,
  MouseEvent,
  CSSProperties,
} from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Icon from 'components/Icon';
import { IconPosition, Size, Type } from 'interfaces/IIcon';
import Truncate from 'components/Truncate';
import { useUser } from 'contexts/AuthContext';
import { isUserUsingEnglishLanguage } from 'utils/user';
import { ICON_SIZES } from './icon-sizes';
import { ICON_COLORS } from './icon-colors';
import styles from './Button.module.scss';

const DEFAULT_TYPE: Type = 'main';
const DEFAULT_SIZE: Size = 'm';
const DEFAULT_ICON_POSITION: IconPosition = 'left';
const DEFAULT_ICON_SIZE = 1;

// @TODO - move the Icon props to a config object: { name, url, size }.
export interface ButtonProps {
  description?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  isTextPaddingRight?: boolean;
  icon?: string;
  iconUrl?: string;
  iconPosition?: IconPosition;
  iconSize?: number;
  id?: string;
  testid?: string;
  isSubmit?: boolean;
  label?: string;
  minWidth?: boolean;
  onClick?: (event: any) => void;
  onHover?: () => void;
  size?: Size;
  type?: Type;
  tooltip?: string;
  wrapperClassName?: string;
  contentClassName?: string;
  style?: CSSProperties;
}

const Button: React.FC<ButtonProps> = props => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { user } = useUser();

  const [isHover, setIsHover] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const { icon, iconUrl, label } = props;

  const type = props.type || DEFAULT_TYPE;
  const iconPosition = props.iconPosition || DEFAULT_ICON_POSITION;
  const size = props.size || DEFAULT_SIZE;

  const getIconSize = () => {
    if (props.iconSize) return props.iconSize;
    return ICON_SIZES[size] || DEFAULT_ICON_SIZE;
  };

  const typeClassName = styles[`button-type-${type}`];
  const sizeClassName = styles[`button-size-${size}`];

  const getIconColor = () => {
    const { active, hover, idle, disabled } = ICON_COLORS[type];
    if (props.disabled) return disabled;
    if (isHover && isFocus && isActive) return active;
    if (isHover || isFocus) return hover;
    return idle;
  };

  const getContent = () => {
    const hasIcon = icon || iconUrl;
    if (label && !hasIcon) {
      return isUserUsingEnglishLanguage(user) ? (
        <>{label}</>
      ) : (
        <Truncate wrapperClassName={styles.truncate}>{label}</Truncate>
      );
    }

    const iconSize = getIconSize();
    const iconComponent = icon ? (
      <Icon
        className={styles['button-icon']}
        name={icon!}
        size={iconSize}
        color={getIconColor()}
      />
    ) : (
      <img
        src={iconUrl}
        alt={label}
        style={{ width: `${iconSize}rem`, height: `${iconSize}rem` }}
      />
    );

    if (hasIcon && !label) {
      return iconComponent;
    }

    return (
      <div
        className={classNames(
          styles['button-with-text-and-icon'],
          props.contentClassName,
          {
            [styles.reverse]: iconPosition === 'right',
            [styles.between]: iconPosition === 'between',
          },
        )}
      >
        {iconComponent}

        <span
          className={classNames({
            [styles['text-padding']]: props.isTextPaddingRight,
            [styles['text-margin-right']]: iconPosition === 'right',
            [styles['text-margin-left']]: iconPosition === 'left',
          })}
        >
          {label}
        </span>
      </div>
    );
  };

  const onClickHandler = (event: MouseEvent<HTMLElement>) => {
    // blur the button to make sure it gets back to its initial state after clicking on it
    buttonRef.current!.blur();
    // pass the "event" to prop.onClick to be able to access the event from the parent.
    props.onClick?.(event);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (props.onHover && isHover) {
      props.onHover();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHover]);

  useEffect(() => {
    const resetStatus = () => {
      setIsFocus(false);
      setIsHover(false);
      setIsActive(false);
    };

    if (props.disabled) resetStatus();
  }, [props.disabled]);

  return (
    <>
      <button
        ref={buttonRef}
        id={props.id}
        data-testid={props.testid || props.id}
        type={props.isSubmit ? 'submit' : 'button'}
        aria-label={props.description}
        disabled={props.disabled}
        data-for="tooltip"
        data-tip={props.tooltip}
        onClick={onClickHandler}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        className={classNames(
          styles.button,
          typeClassName,
          sizeClassName,
          {
            [styles.active]: isActive,
            [styles['button-full-width']]: props.fullWidth,
            [styles['button-min-width']]: props.minWidth,
          },
          props.wrapperClassName,
        )}
        style={props.style}
      >
        {getContent()}
      </button>
    </>
  );
};

export default Button;
