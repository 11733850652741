import React from 'react';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import { REGEX_INTEGERS } from 'constants/regex';
import styles from '../../FormSection.module.scss';
import { translateText } from 'utils/i18n';

interface Props {
  value?: number;
  onChange: (field: string, value: any) => void;
}

const LoadingDocks: React.FC<Props> = ({ value, onChange }) => {
  return (
    <FormControl
      label={translateText(
        'avantPlatform.attributes.property.label.loadingDocks',
      )}
      wrapperClassName={styles['form-row']}
    >
      <TextInput
        pattern={REGEX_INTEGERS}
        placeholder={translateText(
          'avantPlatform.attributes.property.prompt.enterDocks',
        )}
        value={value}
        id="loading-docks"
        onChange={value => onChange('loadingDocks', value)}
      />
    </FormControl>
  );
};

export default LoadingDocks;
