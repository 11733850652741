import React, { useEffect, useCallback, useState } from 'react';

import { AccessToken } from '@okta/okta-auth-js';
import { RouteComponentProps } from 'react-router-dom';

import OAuthLogin from 'components/OAuthLogin';

import AuthService from 'services/Auth';
import { AuthProviders } from 'constants/authProviders';
import { ErrorLogger } from 'services/ErrorLogger';
import { IUser } from 'interfaces/IUser';
import { UserNotFoundError } from 'services/Auth/UserNotFoundError';
import { authContext } from 'contexts/AuthContext';

import locations from 'routes';
import loginUser from 'utils/login/loginUser';
import oktaAuth from 'utils/oktaAuth';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import { Redirect } from 'react-router-dom';
import { AVANT_CITIES_ACCESS_TOKEN } from 'clients/avantCities/constants';

type Props = RouteComponentProps & {};

const OktaLoginPage: React.FC<Props> = props => {
  const auth = React.useContext(authContext);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = useCallback(
    (user: IUser) => {
      loginUser({
        user,
        auth,
        history: props.history,
      });
    },
    [props.history, auth],
  );

  useEffect(() => {
    const createAccount = async () => {
      const {
        email,
        given_name: givenName,
        family_name: surname,
      } = await oktaAuth.getUser();
      const { accessToken } = (await oktaAuth.tokenManager.get(
        'accessToken',
      )) as AccessToken;

      const user = await AuthService.createAccountWithJWTToken(
        {
          id: 0,
          firstName: givenName,
          lastName: surname,
          email: email,
        },
        accessToken,
        AuthProviders.OKTA,
      );

      if (user) {
        login(user);
      } else {
        setShowErrorMessage(true);
      }
    };

    if (isAuthenticated) createAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const doLoginWithJwtToken = useCallback(
    async (email: string, accessToken: string) => {
      try {
        const user = await AuthService.loginWithJwtToken(
          email,
          accessToken,
          AuthProviders.OKTA,
        );

        if (user) {
          login(user);
        } else {
          setShowErrorMessage(true);
        }
      } catch (e) {
        if (e instanceof UserNotFoundError) {
          setIsAuthenticated(true);
        } else {
          ErrorLogger.log(e as any);
          setShowErrorMessage(true);
        }
      }
    },
    [login],
  );

  useEffect(() => {
    const tryLogin = async () => {
      try {
        const tokenResponse = await oktaAuth.token.parseFromUrl();
        oktaAuth.tokenManager.setTokens(tokenResponse.tokens);
        const { accessToken } = (await oktaAuth.tokenManager.get(
          'accessToken',
        )) as AccessToken;
        const { email } = await oktaAuth.getUser();

        localStorage.setItem(AVANT_CITIES_ACCESS_TOKEN, accessToken);

        if (!email || !accessToken) return;

        await doLoginWithJwtToken(email, accessToken);
      } catch (error) {
        return;
      }
    };

    const loginTimeout = setTimeout(tryLogin);

    return () => {
      loginTimeout && clearTimeout(loginTimeout);
    };
  }, [doLoginWithJwtToken]);

  const oAuthProvider = localStorage.getItem(LocalStorageKeys.oAuthProvider);
  if (oAuthProvider !== AuthProviders.OKTA) {
    return <Redirect to={{ pathname: locations.loginForm() }} />;
  }

  return (
    <OAuthLogin
      showErrorMessage={showErrorMessage}
      isAuthenticated={isAuthenticated}
      onTryAgain={() => props.history.push(locations.loginForm())}
    />
  );
};

export default OktaLoginPage;
