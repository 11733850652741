import React, { useEffect, useState } from 'react';
import Textarea from 'react-expanding-textarea';
import classnames from 'classnames';
import Button from 'components/Button/new';
import styles from './CommentInput.module.scss';

interface Props {
  disabled?: boolean;
  id: string;
  isEditing?: boolean;
  onCancelEdit: () => void;
  onSubmit: (value: string) => void;
  placeholder?: string;
  shouldClearOnSubmit?: boolean;
  value?: string;
  wrapperClassName?: string;
}

const CommentInput: React.FC<Props> = ({
  disabled,
  id,
  isEditing,
  onCancelEdit,
  onSubmit,
  placeholder,
  shouldClearOnSubmit,
  value,
  wrapperClassName,
}) => {
  const [comment, setComment] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(comment);

    if (shouldClearOnSubmit) {
      setComment('');
    }
  };

  useEffect(() => {
    value && setComment(value);
  }, [value]);

  useEffect(() => {
    if (!isEditing) setComment('');
  }, [isEditing]);

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <Textarea
        aria-label={placeholder || 'Comment here'}
        className={classnames(styles.textarea, wrapperClassName, {
          [styles['is-editing']]: isEditing,
        })}
        disabled={disabled}
        id={id}
        onChange={(event: any) => setComment(event.target.value)}
        placeholder={placeholder || 'Comment here'}
        value={comment}
      />
      <div className={styles.actions}>
        {isEditing && (
          <Button
            description="Cancel edit operation"
            icon="close"
            iconSize={0.75}
            onClick={onCancelEdit}
            size="s"
            type="secondary"
            wrapperClassName={styles.button}
          />
        )}
        <Button
          disabled={!comment.length || disabled}
          isSubmit
          label={isEditing ? 'Update' : 'Post'}
          size="s"
          type="main"
          wrapperClassName={styles.button}
        />
      </div>
    </form>
  );
};

export default CommentInput;
