import {
  REGEX_DECIMALS,
  REGEX_INTEGERS,
  REGEX_THREE_DECIMALS,
} from 'constants/regex';

export const isValidNumber = (input: string | number): boolean => {
  return (
    REGEX_INTEGERS.test(input.toString()) ||
    REGEX_DECIMALS.test(input.toString()) ||
    REGEX_THREE_DECIMALS.test(input.toString())
  );
};

export const isValidYear = (input: string): boolean => {
  return isValidNumber(input) && input.length <= 4;
};

const emailHasDomain = (domain: string, email: string | undefined) => {
  const emailDomain = email?.split('@')?.pop();
  return emailDomain?.includes(domain);
};

export const isAvisonYoungEmail = (email: string | undefined) => {
  return emailHasDomain('avisonyoung', email);
};

export const isCodelittEmail = (email: string | undefined) => {
  return emailHasDomain('codelitt', email);
};

export const yearToString = (year: number | null | undefined): string => {
  return year ? `${year}` : '';
};
