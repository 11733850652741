import {
  UnitOfMeasurement,
  UnitOfMeasurementCode,
} from 'constants/unitOfMeasurement';
import { UnitsOfMeasurementModelType } from 'interfaces/unitOfMeasurement';

export const MULTIFAMILY_MAPPING: UnitsOfMeasurementModelType[] = [
  {
    key: 'avgUnitSizeSf',
    config: {
      [UnitOfMeasurementCode.US]: {
        field: 'avgUnitSizeSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.UK]: {
        field: 'avgUnitSizeSf',
        unit: UnitOfMeasurement.sf,
      },
      [UnitOfMeasurementCode.EU]: {
        field: 'avgUnitSizeMt',
        unit: UnitOfMeasurement.sm,
      },
    },
  },
];
