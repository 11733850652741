import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ExportTypes } from 'constants/exportTypes';
import importerClient from 'graphql/importerClient';
import { CREATE_EXPORT, GET_EXPORT } from 'graphql/export';
import { getUserId } from 'graphql/authHeaders';
import { ErrorLogger } from 'services/ErrorLogger';
import { formatDate } from 'utils/formatters/date';

const DOWNLOAD_IMPORTER_URL = window._env_.IMPORTER_API_URL;
const RETRIEVE_INTERVAL_MS = 3000;
const RETRIEVE_MAX_TRIES = 10;
const DEFAULT_ERROR_MESSAGE = 'Error occurred while downloading the file';

interface CreateExportVariables {
  order: string;
  filters: string;
  type?: ExportTypes;
  shouldReturnPricingQuotes?: boolean;
  createUpdate?: boolean;
  exportSubtype?: string;
}

interface Props {
  queryVariables: CreateExportVariables | null;
  onDownloadComplete?: () => void;
  onDownloadError?: () => void;
}

export const useFileDownload = ({
  onDownloadComplete,
  onDownloadError,
  queryVariables,
}: Props) => {
  const [fileId, setFileId] = useState<number>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [createFileTryCount, setCreateFileTryCount] = useState(0);

  const getExportName = () => {
    if (!queryVariables) return null;
    const date = formatDate(Date.now(), 'MM-DD-YY_THHmmss', false);
    switch (queryVariables?.type) {
      case ExportTypes.activities:
        return `${ExportTypes.activities}_${date}`;
      case ExportTypes.properties:
        return `properties_${date}`;
      default:
        return `${queryVariables?.type}_comps_${date}`;
    }
  };

  const onErrorHandler = () => {
    setIsDownloading(false);
    setFileId(undefined);
    setHasError(true);
  };

  const [createExport] = useMutation(CREATE_EXPORT, {
    client: importerClient,
    variables: {
      name: getExportName(),
      userId: getUserId(),
      ...queryVariables,
    },
    onCompleted: data => {
      if (data.createExport.id) {
        setFileId(data.createExport.id);
      }
    },
    onError: error => {
      ErrorLogger.log(error.message, DEFAULT_ERROR_MESSAGE);
      onErrorHandler();
    },
  });

  useQuery(GET_EXPORT, {
    client: importerClient,
    skip: !fileId || hasError,
    variables: { exportId: fileId },
    pollInterval: RETRIEVE_INTERVAL_MS,
    onCompleted: data => {
      if (createFileTryCount >= RETRIEVE_MAX_TRIES) {
        ErrorLogger.log('Maximum retry attempts reached');
        onErrorHandler();
        return;
      }
      if (data.export.errors) {
        setErrorMessage(data.export.errors);
        onErrorHandler();
        return;
      }
      setCreateFileTryCount(prev => prev + 1);
      if (data?.export?.processedAt) {
        window.open(`${DOWNLOAD_IMPORTER_URL}/export?id=${fileId}`, '_self');
        setIsDownloading(false);
        setFileId(undefined);
        onDownloadComplete?.();
      }
    },
    onError: error => {
      ErrorLogger.log(error.message);
      onErrorHandler();
      onDownloadError?.();
    },
  });

  const createFile = async () => {
    setIsDownloading(true);
    await createExport();
  };

  return {
    isDownloading,
    hasError,
    createFile,
    setHasError,
    errorMessage,
  };
};
