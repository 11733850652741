interface AddressParts {
  primaryAddress: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  postalCode: string | null;
}

type ExpectedType = {
  mainType: string;
  secondaryType?: string;
};

const countryCityMappings: Record<string, ExpectedType[]> = {
  US: [{ mainType: 'locality', secondaryType: 'sublocality' }],
  CA: [{ mainType: 'locality', secondaryType: 'sublocality' }],
  UK: [{ mainType: 'postal_town' }],
  GB: [{ mainType: 'postal_town' }],
  IL: [{ mainType: 'locality' }],
  default: [{ mainType: 'locality', secondaryType: 'sublocality' }],
};

const isExpectedAddressType = (
  types: string[],
  expectedTypes: ExpectedType[],
) => {
  return expectedTypes.some(
    et =>
      types.includes(et.mainType) &&
      (!et.secondaryType || types.includes(et.secondaryType)),
  );
};

const isExpectedCityType = (types: string[], expectedTypes: ExpectedType[]) => {
  return expectedTypes.some(et => {
    const foundMainType = types.includes(et.mainType);
    return foundMainType || types.includes(et.secondaryType ?? '');
  });
};

export const hasStreetAddress = (
  suggestion: google.maps.places.AutocompletePrediction,
) => suggestion.types.includes('street_address');

const isState = (addressComponent: google.maps.GeocoderAddressComponent) =>
  isExpectedAddressType(addressComponent.types, [
    { mainType: 'administrative_area_level_1', secondaryType: 'political' },
  ]);

export const isCity = (
  addressComponent: google.maps.GeocoderAddressComponent,
  shortCountryName: string,
) => {
  const expectedTypes =
    countryCityMappings[shortCountryName] || countryCityMappings['default'];

  return isExpectedCityType(addressComponent.types, expectedTypes);
};

const isRoute = (addressComponent: google.maps.GeocoderAddressComponent) =>
  isExpectedAddressType(addressComponent.types, [{ mainType: 'route' }]);

const isStreetNumber = (
  addressComponent: google.maps.GeocoderAddressComponent,
) =>
  isExpectedAddressType(addressComponent.types, [
    { mainType: 'street_number' },
  ]);

const isCountry = (addressComponent: google.maps.GeocoderAddressComponent) =>
  isExpectedAddressType(addressComponent.types, [{ mainType: 'country' }]);

const isPostalCode = (addressComponent: google.maps.GeocoderAddressComponent) =>
  isExpectedAddressType(addressComponent.types, [{ mainType: 'postal_code' }]);

export const getCountryCodeByAddress = (
  place: google.maps.places.PlaceResult | null,
) => {
  if (!place) return null;
  const foundPlace = place.address_components?.find(a =>
    a.types.includes('country'),
  );
  return foundPlace?.short_name ?? '';
};

const getPrimaryAddress = (
  streetNumber: string | null,
  route: string | null,
): string | null => {
  const primaryAddress = [streetNumber, route].filter(Boolean).join(' ');
  return primaryAddress.trim() || null;
};

export const getAddressParts = (
  place: google.maps.places.PlaceResult | null,
  suggestion?: string,
): AddressParts | null => {
  if (!place) return null;

  const stateComp = place?.address_components?.find(isState);
  const state = stateComp?.long_name || null;

  const countryComp = place?.address_components?.find(isCountry);
  const country = countryComp?.long_name || null;
  const shortCountryName = countryComp?.short_name || null;

  const cityComp = place?.address_components?.find(addressComp =>
    isCity(addressComp, shortCountryName || 'default'),
  );
  const city = cityComp?.long_name || null;

  const routeComp = place?.address_components?.find(isRoute);
  const route = routeComp?.long_name || null;

  const streetNoComp = place?.address_components?.find(isStreetNumber);
  const streetNo = streetNoComp?.long_name || null;

  const postalCodeComp = place?.address_components?.find(isPostalCode);
  const postalCode = postalCodeComp?.long_name || null;
  const primaryAddress = !!getPrimaryAddress(streetNo, route)
    ? getPrimaryAddress(streetNo, route)
    : !!suggestion
    ? suggestion
    : null;

  return {
    city,
    country,
    primaryAddress,
    state,
    postalCode,
  };
};
