import React from 'react';
import classnames from 'classnames';
import styles from './VerticalFormControl.module.scss';

type Props = {
  label: string;
  required?: boolean;
  disabled?: boolean;
  wrapperClassName?: string;
  containerClassName?: string;
  childrenContainerClassName?: string;
  labelClassName?: string;
  children?: React.ReactNode;
};

const VerticalFormControl: React.FC<Props> = ({
  label,
  children,
  required,
  disabled,
  wrapperClassName,
  containerClassName,
  labelClassName,
  childrenContainerClassName,
}) => (
  <div className={containerClassName}>
    <div
      className={classnames(styles.container, wrapperClassName, {
        [styles['label-required']]: required,
        [styles['label-disabled']]: disabled,
      })}
    >
      <h5 className={classnames(styles.label, labelClassName)}>{label}</h5>
      {required && <p className={styles.required}>Required</p>}
    </div>
    <div className={childrenContainerClassName}>{children}</div>
  </div>
);

export default VerticalFormControl;
