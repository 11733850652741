import ISale from 'interfaces/ISale';
import { SaleField } from '../../fields';
import { IdName } from 'interfaces/IdName';
import { IBroker } from 'interfaces/IBroker';
import { ICompany } from 'interfaces/ICompany';
import { buildTagsListItems } from '../../buildTagsListItems';
import { convertBrokerInputToIdName } from 'utils/formatters/broker';

interface IFormattedSale extends Omit<ISale, 'buyersBrokers, sellersBrokers'> {
  buyersBrokers: IdName[] | undefined;
  sellersBrokers: IdName[] | undefined;
}

const { Sellers, BuyersBrokers, SellersBrokers } = SaleField;

export const handleBrokerFieldsFormatting = (
  value?: IBroker[],
): IdName[] | undefined => (value ? convertBrokerInputToIdName(value) : value);

export const getFormattedSale = (sale: ISale): IFormattedSale => {
  const buyersBrokers = handleBrokerFieldsFormatting(sale?.buyersBrokers);
  const sellersBrokers = handleBrokerFieldsFormatting(sale?.sellersBrokers);
  return { ...sale, buyersBrokers, sellersBrokers };
};

export const getSecondaryFields = (
  sale: ISale,
  fields: SaleField[],
): IdName[] => buildTagsListItems(sale, fields).filter(f => f.name);

export const isGivenField = (field: IdName, givenField: SaleField): boolean =>
  field.id === givenField;

export const shouldRenderCompanyLinks = (field: IdName): boolean =>
  isGivenField(field, Sellers) ||
  isGivenField(field, BuyersBrokers) ||
  isGivenField(field, SellersBrokers);

export const getCompanies = (
  field: IdName,
  sale: ISale,
): ICompany[] | undefined => {
  const formattedSale = getFormattedSale(sale);
  // TODO: This should done in src/components/Sales/fields.ts.
  // But entails a refactor because the method is not ready to return other than strings.

  if (isGivenField(field, Sellers)) return formattedSale?.sellers;
  if (isGivenField(field, BuyersBrokers)) return formattedSale?.buyersBrokers;
  if (isGivenField(field, SellersBrokers)) return formattedSale?.sellersBrokers;

  return undefined;
};
