import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { select } from 'd3-selection';

import { colors } from 'constants/colors';

import { LineProps } from './types';

const Line = forwardRef<any, LineProps>(
  ({ x1, y1, x2, y2, width = 1, color = colors.ayBlackColor }, ref) => {
    const lineGroupRef = useRef(null);

    useImperativeHandle(ref, () => lineGroupRef);

    useEffect(() => {
      const node = lineGroupRef.current;

      const svg = select(node)
        .append('line')
        .attr('x1', x1)
        .attr('y1', y1)
        .attr('x2', x2)
        .attr('y2', y2)
        .attr('stroke', color)
        .attr('stroke-width', width);

      return () => {
        svg.remove();
      };
    }, [x1, x2, y1, y2, color, width]);

    return <g ref={lineGroupRef} />;
  },
);

export default Line;
