import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import stylesCheckbox from 'components/Checkbox/Checkbox.module.scss';
import styles from './TableView.module.scss';

interface IndeterminateCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
}

export const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    if (typeof resolvedRef === 'object' && resolvedRef.current) {
      resolvedRef.current.indeterminate = Boolean(indeterminate);
    }
  }, [resolvedRef, indeterminate]);

  return (
    <label
      className={classNames(
        stylesCheckbox.container,
        styles['checkbox-container'],
      )}
    >
      <input
        type="checkbox"
        className={stylesCheckbox.checkbox}
        ref={resolvedRef as React.MutableRefObject<HTMLInputElement>}
        {...rest}
      />
      <span
        className={classNames(stylesCheckbox.checkmark, styles.checkmark)}
      />
    </label>
  );
});
