import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import RadioInput from 'components/Inputs/RadioInput';
import { REGEX_DECIMALS } from 'constants/regex';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IProperty } from 'interfaces/IProperty';
import { translateText } from 'utils/i18n';
import { isUKCountry } from 'utils/properties';

import styles from './FreeRentMonths.module.scss';
import commonStyles from '../../FormSection.module.scss';

interface Props {
  required?: boolean;
  property?: IProperty;
  value?: number | null;
  onChange: (field: string, value: any) => void;
}

const FREE_MONTHS_NUMBERS = [0, 5, 7, 10];
const FREE_MONTHS_NUMBERS_UK = [0, 3, 6, 9, 12];

const FreeRentMonths: React.FC<Props> = ({
  property,
  value,
  onChange,
  required = false,
}) => {
  const { t } = useTranslation();
  const isUkProperty = isUKCountry(property?.country);

  const freeMonthSuggestions = isUkProperty
    ? FREE_MONTHS_NUMBERS_UK
    : FREE_MONTHS_NUMBERS;

  const freeMonthsData = useMemo(() => {
    return freeMonthSuggestions.map(m => {
      return {
        label: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.freeMonths`,
          {
            number: m,
          },
        ),
        value: m,
      };
    });
  }, [freeMonthSuggestions]);

  return (
    <FormControl
      required={required}
      wrapperClassName={commonStyles['form-row']}
      label={translateText(
        'avantPlatform.attributes.lease.label.freeRentMonth',
      )}
    >
      <div className={commonStyles['large-input-container']}>
        <TextInput
          id="free-rent-months"
          pattern={REGEX_DECIMALS}
          value={value}
          placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enterMonths`)}
          onChange={value =>
            value === ''
              ? onChange('freeRentMonths', null)
              : onChange('freeRentMonths', value)
          }
        />
        <RadioInput
          wrapperClassName={styles['free-months-radio']}
          selectedItem={value}
          data={freeMonthsData}
          onChange={item => onChange('freeRentMonths', item.value)}
        />
      </div>
    </FormControl>
  );
};

export default FreeRentMonths;
