import React from 'react';
import { getParamFromUrl, hasParamInUrl } from 'utils/urls';
import { authContext, useUserAccessData } from 'contexts/AuthContext';
import {
  SUBSCRIPTIONS,
  PAGES,
  FUNCTIONALITIES,
  APPLICATIONS,
  EXTERNAL_LINKS,
} from 'constants/subscriptions';

interface SubscriptionsInterface {
  checkSubscriptions: (section: string) => boolean;
  hasEditorAccess: boolean;
  hasGranularAccess: boolean;
}

export const subscriptionsContext = React.createContext<SubscriptionsInterface>(
  {
    checkSubscriptions: () => true,
    hasEditorAccess: false,
    hasGranularAccess: false,
  },
);

const { Provider } = subscriptionsContext;

type Props = {
  children: React.ReactNode;
};

const SubscriptionsProvider: React.FC<Props> = ({ children }) => {
  const { user } = React.useContext(authContext);

  // checking if the user has full access in any subscription
  const { fullAccess, isActive } = useUserAccessData();

  const userSubscriptions: any =
    user.subscriptions?.[0]?.features?.reduce((subscriptions, current) => {
      const temp: any = subscriptions;
      if (current.name) {
        temp[current.name] = true;
      }
      return temp;
    }, {}) || {};

  const checkUrlParams = (param: string) => {
    if (hasParamInUrl(param) && window._env_.REACT_APP_ENV !== 'PROD') {
      return getParamFromUrl(param) === param;
    }
    return true;
  };

  const checkSubscriptions = (section: string) => {
    if (fullAccess) {
      return true;
    }

    if (Object.values(userSubscriptions).length === 0) {
      return false;
    }

    if (!isActive) {
      return false;
    }

    switch (section) {
      case PAGES.PAGE_ACTIVITY_FEED:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_ACTIVITY_FEED.name] &&
            checkUrlParams(SUBSCRIPTIONS.AVANT_ACTIVITY_FEED.query),
        );
      case PAGES.PAGE_MARKET_ANALYTICS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_MARKET_ANALYTICS.name] &&
            checkUrlParams(SUBSCRIPTIONS.AVANT_MARKET_ANALYTICS.query),
        );
      case PAGES.PAGE_PROPERTY_PROFILE:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_PROPERTY_PROFILE.name] &&
            checkUrlParams(SUBSCRIPTIONS.AVANT_PROPERTY_PROFILE.query),
        );
      case PAGES.SHOW_PURSUITS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.SHOW_ADDRESS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.SHOW_PROJECTS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.PAGE_TENANT_TOURS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.PAGE_TENANT_TOURS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.PAGE_TENANT_TOUR_CREATION:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.PAGE_AGENCY_ASSIGNMENTS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.PAGE_ADD_SCOOP:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.SHOW_COMPS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.PAGE_MARKET_PROFILE:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.SHOW_PROPERTY_COMP_SETS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      case PAGES.PAGE_SUBMARKET_PROFILE:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_SUBMARKET_PROFILE.name] &&
            checkUrlParams(SUBSCRIPTIONS.AVANT_SUBMARKET_PROFILE.query),
        );
      case PAGES.PAGE_COMPANY_PROFILE:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_COMPANY_PROFILE.name] &&
            checkUrlParams(SUBSCRIPTIONS.AVANT_COMPANY_PROFILE.query),
        );
      case PAGES.PAGE_FIND_COMPS:
      case PAGES.PAGE_ADD_COMPS:
      case PAGES.PAGE_UPLOAD_COMPS:
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_FIND_COMPS.name] &&
            checkUrlParams(SUBSCRIPTIONS.AVANT_FIND_COMPS.query),
        );
      case FUNCTIONALITIES.EDITOR: {
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_FUNCTIONALITY_EDITOR.name],
        );
      }
      case FUNCTIONALITIES.GRANULAR: {
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_FUNCTIONALITY_GRANULAR.name],
        );
      }
      case APPLICATIONS.PROPERTIES: {
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_PROPERTIES.name],
        );
      }
      case APPLICATIONS.CITIES: {
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_APPLICATION_CITIES.name],
        );
      }
      case EXTERNAL_LINKS.ALTUS: {
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_EXTERNAL_LINK_ALTUS.name],
        );
      }
      case EXTERNAL_LINKS.BUILD_OUT: {
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_EXTERNAL_LINK_BUILD_OUT.name],
        );
      }
      case EXTERNAL_LINKS.COSTAR: {
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_EXTERNAL_LINK_COSTAR.name],
        );
      }
      case EXTERNAL_LINKS.HUBSPOT: {
        return Boolean(
          userSubscriptions[SUBSCRIPTIONS.AVANT_EXTERNAL_LINK_HUBSPOT.name],
        );
      }
      default: {
        return false;
      }
    }
  };

  const hasEditorAccess =
    fullAccess || checkSubscriptions(FUNCTIONALITIES.EDITOR);
  const hasGranularAccess =
    fullAccess || checkSubscriptions(FUNCTIONALITIES.GRANULAR);

  return (
    <Provider
      value={{ checkSubscriptions, hasEditorAccess, hasGranularAccess }}
    >
      {children}
    </Provider>
  );
};

export function useSubscriptions() {
  const subscriptions = React.useContext(subscriptionsContext);

  if (subscriptions === undefined) {
    throw new Error(
      `useSubscriptions must be used within a SubscriptionsProvider`,
    );
  }

  return { ...subscriptions };
}
export default SubscriptionsProvider;
