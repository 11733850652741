import { gql } from '@apollo/client';

export const GET_OVERVIEW_LEASING_METADATA = gql`
  query propertySetsLeasingMetadata($search: PropertySetSearchInput) {
    propertySetsLeasingMetadata(search: $search) {
      numberOfBuildings
      totalInventory
      occupiedPercent
      numberOfTenants
      walt
      placeRentFS
      placeRentNNN
      placeRentFSMonthly
      placeRentNNNMonthly
      askingRentFS
      askingRentFSMonthly
      askingRentNNN
      askingRentNNNMonthly
    }
  }
`;

export const GET_OVERVIEW_COMPOSITION_METADATA = gql`
  query propertySetsCompositionMetadata($search: PropertySetSearchInput) {
    propertySetsCompositionMetadata(search: $search) {
      numberOfBuildings
      totalSf
      avgBuildingSize
      avgFloorPlate
      avgAge
    }
  }
`;

export const GET_TENANT_LEASING_METADATA = gql`
  query propertySetsLeasingMetadata($search: PropertySetSearchInput) {
    propertySetsLeasingMetadata(search: $search) {
      averageLeaseSize
      numberOfLeases
      placeRentFS
      placeRentNNN
      placeRentFSMonthly
      placeRentNNNMonthly
      totalLeaseSize
      walt
    }
  }
`;
