import { StatusTypesIDs } from 'constants/statusTypes';
import { MarketProps } from './interfaces';

export const PROPERTIES_IMAGES_LIMIT = 10;

export const MARKET_DEVELOPMENT_STATUSES = [
  StatusTypesIDs.UnderConstruction,
  StatusTypesIDs.ProposedShortTerm,
  StatusTypesIDs.ProposedLongTerm,
];

export const I18N_MARKET_PROFILE_PATH = 'avantProperties.pages.marketProfile';

export enum MarketMapOptions {
  ALL_PROPERTIES = 'ALL_PROPERTIES',
  DEVELOPMENT = 'DEVELOPMENT',
}

export interface CompositionSectionProps extends MarketProps {
  activeOption?: MarketMapOptions;
  setActiveOption?: (type: MarketMapOptions) => void;
}

export enum MarketDonutsCharts {
  DonutTenantIndustries = 4,
  DonutOwners = 5,
}
