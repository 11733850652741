import { IUser } from 'interfaces/IUser';
import { lowerCase } from 'lodash';

export const generateAccountObject = (user: IUser) => {
  const envType = window._env_.REACT_APP_ENV;
  const envTypes = ['DEV', 'QA', 'UT', 'PROD'];
  const env = envTypes.includes(envType) ? lowerCase(envType) : 'local';
  const account = {
    id: `${env}-${user.clientCompany?.id ?? ''}`,
    name: `${env}-${user.clientCompany?.name ?? user.companyName ?? ''}`,
  };
  return account;
};
