import { gql } from '@apollo/client';

export const CREATE_PROPERTY_MUTATION = gql`
  mutation createProperty($property: PropertyInput!) {
    createProperty(property: $property) {
      ... on PropertyId {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const UPDATE_PROPERTY_MUTATION = gql`
  mutation updateProperty(
    $property: PropertyInput!
    $opexChangedManually: Boolean
  ) {
    updateProperty(
      property: $property
      opexChangedManually: $opexChangedManually
    ) {
      ... on Property {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const DELETE_PROPERTY_MUTATION = gql`
  mutation deleteProperty($id: Int!) {
    deleteProperty(id: $id) {
      ... on Property {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;
