import { IMultiselectItem } from 'interfaces/IMultiselectItem';

export const getSelectedItemsInOptions = (
  options: IMultiselectItem[],
  selectedItems: IMultiselectItem[],
): IMultiselectItem[] => {
  const selectedIemsInOptions: IMultiselectItem[] = [];
  const subItemsIds: number[] = [];
  selectedItems.map(i => i.subItems.map(d => subItemsIds.push(d.id)));
  options?.map((op: IMultiselectItem) => {
    const subItems = op?.subItems.filter(el => subItemsIds?.includes(el.id));
    if (subItems.length) {
      selectedIemsInOptions.push({
        ...op,
        subItems,
      });
    }
  });

  return selectedIemsInOptions;
};
