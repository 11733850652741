import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './AYLink.module.scss';

interface ILink {
  pathname: string;
  state: string;
}

interface Props {
  to: string | ILink;
  children: React.ReactNode;
  wrapperClassName?: string;
}

const AYLink: React.FC<Props> = ({ to, children, wrapperClassName }) => (
  <Link to={to} className={classNames(styles.link, wrapperClassName)}>
    {children}
  </Link>
);

export default AYLink;
