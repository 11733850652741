import { gql } from '@apollo/client';

export const PRICE_QUOTE_INFO_FRAGMENT = gql`
  fragment priceQuoteInfo on PropertyPriceQuote {
    id
    escalationFormatted
    createdAt
    updatedAt
    date
    askingRentPsf
    leaseType
    rentType
    tiAllowance
    freeRentMonths
    term
    source
    pricing
    floors
    size
    sizeMt
    comment
    opExAndTaxes
    taxes
    operatingExpenses
    tenantCompany {
      id
      name
    }
    property {
      id
      name
      primaryAddress
      displayAddress
      secondaryAddress
      tertiaryAddress
      quaternaryAddress
      numberOfAlternativeAddresses
      coverMediaUrl
      coverMediaType
      measurementSystem
      currencyCode
      buildingSize
      buildingSizeMt
      country
      propertyCountry {
        id
        name
        code
        flagURL
        distanceMeasurement
        areaMeasurement
        currencyCode
      }
      state
      latitude
      longitude
      latitudeDisplay
      longitudeDisplay
      market {
        id
        name
      }
      submarket {
        id
        name
      }
      micromarket {
        id
        name
      }
      propertyType {
        id
        name
      }
      propertyClass {
        id
        name
      }
    }
    createdUser {
      id
      firstName
      lastName
      email
    }
    updatedUser {
      id
      firstName
      lastName
      email
    }
  }
`;
