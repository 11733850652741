import { IDonutGraphData } from 'interfaces/IDonutGraphData';
import { DASH_PLACEHOLDER } from 'constants/placeholders';

const DONUT_INNER_SLICE_CLASS = 'donut-inner-slice';
const DONUT_SLICE_CLASS = 'donut-slice';
const DONUT_SLICE_SHADOW_CLASS = 'donut-slice-shadow';

export const getDonutDataId = (
  donutData: IDonutGraphData,
  donutId?: number,
) => {
  return `data-${(donutData.title || '').toLowerCase()}-${
    donutData.totalSize
  }-${donutData.order || ''}-${donutId}`.replace(
    /[^a-z0-9-]/gi,
    DASH_PLACEHOLDER,
  );
};

export const getDonutDataIdShadowId = (
  donutData: IDonutGraphData,
  donutId?: number,
) => {
  return `${getDonutDataId(donutData, donutId)}-shadow`;
};

export const getDonutSliceClass = (donutId?: number) => {
  return `${DONUT_SLICE_CLASS}-${donutId}`;
};

export const getDonutInnerSliceClass = (donutId?: number) => {
  return `${DONUT_INNER_SLICE_CLASS}-${donutId}`;
};

export const getDonutSliceShadowClass = (donutId?: number) => {
  return `${DONUT_SLICE_SHADOW_CLASS}-${donutId}`;
};
