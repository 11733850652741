import React from 'react';
import Donut from './Elements/Donut';
import styles from 'components/Graphs/DonutGraph/DonutGraph.module.scss';
import graphDimensions, {
  DONUT_SIZE,
} from 'components/Graphs/DonutGraph/graphConstants';
import { IStaticDonutGraphData } from 'interfaces/IStaticDonutGraphData';
import classnames from 'classnames';

interface Props {
  donutId: number;
  data: IStaticDonutGraphData[];
  size: DONUT_SIZE;
  wrapperClassName?: string;
}

const StaticDonutGraph: React.FC<Props> = ({
  data,
  donutId,
  size,
  wrapperClassName,
}) => {
  return (
    <svg
      className={classnames(styles['svg-graph'], wrapperClassName)}
      width={graphDimensions.WIDTH_EXTRA_SMALL}
      height={graphDimensions.HEIGHT_EXTRA_SMALL}
    >
      <Donut data={data} donutId={donutId} size={size} />
    </svg>
  );
};

export default StaticDonutGraph;
