import { gql } from '@apollo/client';

export const CREATE_LEASE_MUTATION = gql`
  mutation createLease($lease: LeaseInput!) {
    createLease(lease: $lease) {
      ... on LeaseId {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;

export const DELETE_LEASE_MUTATION = gql`
  mutation deleteLease($leaseId: Int!) {
    deleteLease(leaseId: $leaseId)
  }
`;

export const UPDATE_LEASE_MUTATION = gql`
  mutation updateLease($lease: LeaseInput!) {
    updateLease(lease: $lease) {
      ... on Lease {
        id
      }
      ... on EntityError {
        message
      }
    }
  }
`;
