export enum DONUT_SIZE {
  LARGE = 'large',
  REGULAR = 'regular',
  SMALL = 'small',
  EXTRA_SMALL = 'extra-small',
}

const WIDTH = 296;
const HEIGHT = 296;

const WIDTH_LARGE = 348;
const HEIGHT_LARGE = 348;

const WIDTH_SMALL = 224;
const HEIGHT_SMALL = 224;

const WIDTH_EXTRA_SMALL = 108;
const HEIGHT_EXTRA_SMALL = 108;

const DONUT_WIDTH_HOVER = 5;

const PADDING_ANGLE = 0.038;

const STROKE_WIDTH = 1;
const STROKE_WIDTH_LARGE = 12;

export const DONUT_WIDTHS = {
  [DONUT_SIZE.EXTRA_SMALL]: WIDTH_EXTRA_SMALL,
  [DONUT_SIZE.SMALL]: WIDTH_SMALL,
  [DONUT_SIZE.REGULAR]: WIDTH,
  [DONUT_SIZE.LARGE]: WIDTH_LARGE,
};

export const DONUT_HEIGHTS = {
  [DONUT_SIZE.EXTRA_SMALL]: HEIGHT_EXTRA_SMALL,
  [DONUT_SIZE.SMALL]: HEIGHT_SMALL,
  [DONUT_SIZE.REGULAR]: HEIGHT,
  [DONUT_SIZE.LARGE]: HEIGHT_LARGE,
};

export const getDonutOuterRadius = (size?: DONUT_SIZE, customSize?: number) => {
  if (customSize) return customSize / 2;

  return DONUT_WIDTHS[size || DONUT_SIZE.REGULAR] / 2;
};

const getDonutSliceWidth = (size?: DONUT_SIZE, customSize?: number) => {
  if (size === DONUT_SIZE.EXTRA_SMALL) return 24;

  if (size === DONUT_SIZE.SMALL || !!customSize) return 28;

  if (size === DONUT_SIZE.LARGE) return 48;

  return 32;
};

export const getDonutInnerRadius = (size?: DONUT_SIZE, customSize?: number) =>
  getDonutOuterRadius(size, customSize) - getDonutSliceWidth(size, customSize);

// This is the donut visible only when hovering the initial donut
export const getDonutInnerRadiusHover = (
  size?: DONUT_SIZE,
  customSize?: number,
) => getDonutOuterRadius(size, customSize) - DONUT_WIDTH_HOVER;

export default {
  WIDTH,
  HEIGHT,
  WIDTH_LARGE,
  HEIGHT_LARGE,
  WIDTH_SMALL,
  HEIGHT_SMALL,
  PADDING_ANGLE,
  STROKE_WIDTH,
  STROKE_WIDTH_LARGE,
  WIDTH_EXTRA_SMALL,
  HEIGHT_EXTRA_SMALL,
};
