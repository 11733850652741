import ActivityCardActionsSmall from 'components/ActivityCardActionsSmall';
import MarketMap from 'components/MarketMap';
import { MarketMapOptions } from 'components/MarketProfile/constants';
import MultipleMarketsLink from 'components/MultipleMarketsLink';
import { PropertyTypeIds, PropertyTypeNames } from 'constants/propertyTypes';
import { IMarket } from 'interfaces/IMarket';
import { IScoop } from 'interfaces/IScoop';
import React from 'react';
import styles from './ActivityCardMarketData.module.scss';
import { DOT_LG } from 'constants/placeholders';
import {
  getCountries,
  getPropertyTypes,
} from 'components/MultipleMarketsLink/utils';

interface Props {
  children?: React.ReactNode;
  market?: IMarket;
  scoop?: IScoop;
  onCommentsClick?: () => void;
  onEditClick?: () => void;
  onShareClick?: () => void;
  preselectedMarket?: IMarket;
}

const defaultPropertyType = {
  id: PropertyTypeIds['office'],
  name: PropertyTypeNames[PropertyTypeIds['office']],
};

const ActivityCardMarketData: React.FC<Props> = ({
  children,
  market,
  scoop,
  onCommentsClick,
  onEditClick,
  onShareClick,
  preselectedMarket,
}) => {
  const preselectedMarketFiltered = scoop?.markets?.filter(
    market => market.id === preselectedMarket?.id,
  )?.[0];
  const shouldDisplayImage =
    (!preselectedMarket && market?.mediaUrl) ||
    (preselectedMarket && preselectedMarketFiltered?.mediaUrl);
  const shouldDisplayMap =
    (!preselectedMarket && !market?.mediaUrl && market?.latitude) ||
    (preselectedMarket &&
      !preselectedMarketFiltered?.mediaUrl &&
      preselectedMarketFiltered?.latitude);

  return (
    <div className={styles.container}>
      {shouldDisplayImage && (
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${preselectedMarketFiltered?.mediaUrl ||
              market?.mediaUrl})`,
          }}
        />
      )}
      {shouldDisplayMap && market && (
        <div className={styles['submarket-cover']}>
          <MarketMap
            activeOption={MarketMapOptions.ALL_PROPERTIES}
            market={preselectedMarketFiltered || market}
            propertyType={defaultPropertyType}
            showMapControls={false}
            showOpenMap={false}
            mapZoom={11}
            shouldShowOnlySelectedBoundaries
          />
        </div>
      )}
      <div className={styles['text-container']}>
        <div className={styles['activity-market-info']}>
          <MultipleMarketsLink
            preselectedMarket={preselectedMarket}
            markets={scoop?.markets || []}
          />
          <div className={styles['market-country']}>
            {scoop?.properties?.[0]?.state
              ? `${scoop?.properties?.[0].state} ${DOT_LG} `
              : ''}
            {scoop && getCountries(scoop)}
          </div>
          {scoop?.propertyTypes && (
            <div className={styles['market-country']}>
              {getPropertyTypes(scoop)}
            </div>
          )}
        </div>
        {children}
      </div>
      <ActivityCardActionsSmall
        onEditClick={onEditClick}
        onShareClick={onShareClick}
        onCommentsClick={onCommentsClick}
        scoop={scoop}
      />
    </div>
  );
};

export default ActivityCardMarketData;
