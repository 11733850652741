import { IProperty } from '../../../interfaces/IProperty';
import * as d3 from 'd3';
import { SVG_ID } from './graphConstants';

export const getBubbleId = (p: IProperty, innerBubble = false) => {
  const id = `property-${p.id}`;
  return innerBubble ? `inner-${id}` : id;
};

export const getSvgRoot = () => {
  return d3.select(`#${SVG_ID}`);
};
