import { get } from 'lodash';
import { IdName } from 'interfaces/IdName';

export const getGroupedDataByPathName = (
  items: IdName[],
  groupItemsPathParams: {
    sortByField: string;
    displaySortedField: string;
  },
) => {
  const resultArray: IdName = {};

  items.forEach((item: IdName) => {
    const sortByKey = get(item, groupItemsPathParams.sortByField);
    const displayKey = get(item, groupItemsPathParams.displaySortedField);
    if (resultArray[sortByKey]) {
      resultArray[sortByKey].items.push({
        id: item.id,
        name: item.name,
      });
    } else {
      resultArray[sortByKey] = {
        id: sortByKey,
        name: displayKey,
        items: [item],
      };
    }
  });
  return Object.values(resultArray ?? []);
};
