import CustomVictoryLabel from 'components/CustomVictoryLabel';
import {
  VictoryBarStyle,
  AxisData,
} from 'components/Graphs/VerticalGroupBarChart/types';
import { colors } from 'constants/colors';
import React, { useState } from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';
import { DEFAULT_BAR_STYLE, IBarHovered } from './utils';

interface Props {
  title: string | string[];
  barData?: any;
  legend?: any;
  horizontal?: boolean;
  onAxisClick?: (datum?: any) => void;
  getBarLabel?: (datum?: any) => string;
  chartStyle?: VictoryBarStyle;
  isBarHoverable?: boolean;
  handleClickBar?: (datum?: any) => void;
  hasLineAverage?: boolean;
  handleClickLine?: (datum?: any) => void;
  gridComponent?: React.ReactElement;
  useCustomTextYAxis?: boolean;
  customTextLength?: number;
}

const VictoryBarChart: React.FC<Props> = ({
  title,
  barData,
  legend,
  horizontal,
  onAxisClick,
  getBarLabel,
  chartStyle,
  isBarHoverable,
  handleClickBar,
  hasLineAverage,
  handleClickLine,
  gridComponent,
  useCustomTextYAxis,
  customTextLength,
}) => {
  const [tickBarHovered, setTickBarHovered] = useState<IBarHovered>();

  const victoryAxisStyle =
    chartStyle?.xAxis?.style || DEFAULT_BAR_STYLE.xAxis?.style;
  const controlledVictoryAxisStyle = isBarHoverable
    ? {
        ...victoryAxisStyle,
        grid: {
          ...victoryAxisStyle.grid,
          stroke: (t: any) => {
            return t.tick == tickBarHovered?.xValue
              ? tickBarHovered?.barColor || colors.primaryColor500
              : colors.uiColor700;
          },
          strokeWidth: (t: any) => (t.tick == tickBarHovered?.xValue ? 2 : 0),
        },
      }
    : victoryAxisStyle;

  const maxima = hasLineAverage && barData.lineData ? barData.yAxis : undefined;
  if (!barData.data.length) {
    return null;
  } else {
    return (
      <>
        <VictoryChart
          style={chartStyle?.chart?.style || DEFAULT_BAR_STYLE.chart?.style}
          padding={
            chartStyle?.chart?.padding || DEFAULT_BAR_STYLE.chart?.padding
          }
          height={chartStyle?.chart?.height}
          singleQuadrantDomainPadding
          domain={chartStyle?.chart?.domain}
        >
          <VictoryLabel
            x={chartStyle?.label?.x || DEFAULT_BAR_STYLE.label?.x}
            y={chartStyle?.label?.y || DEFAULT_BAR_STYLE.label?.y}
            text={title}
            backgroundPadding={
              chartStyle?.label?.backgroundPadding ||
              DEFAULT_BAR_STYLE.label?.backgroundPadding
            }
            style={chartStyle?.label?.style || DEFAULT_BAR_STYLE.label?.style}
          />
          {legend ? (
            <VictoryLegend
              height={
                chartStyle?.legend?.height || DEFAULT_BAR_STYLE.legend?.height
              }
              width={
                chartStyle?.legend?.width || DEFAULT_BAR_STYLE.legend?.width
              }
              x={chartStyle?.legend?.x || DEFAULT_BAR_STYLE.legend?.x}
              y={chartStyle?.legend?.y || DEFAULT_BAR_STYLE.legend?.y}
              gutter={
                chartStyle?.legend?.gutter || DEFAULT_BAR_STYLE.legend?.gutter
              }
              orientation="horizontal"
              style={
                chartStyle?.legend?.style || DEFAULT_BAR_STYLE.legend?.style
              }
              data={legend}
            />
          ) : null}
          {barData?.yAxis && hasLineAverage ? (
            barData?.yAxis.map((item: any, i: number) => {
              return (
                <VictoryAxis
                  key={i}
                  dependentAxis
                  fixLabelOverlap={true}
                  tickValues={[0, 0.25, 0.5, 0.75, 1]}
                  style={
                    chartStyle?.yAxis?.[i]?.style ||
                    (DEFAULT_BAR_STYLE.yAxis as AxisData)?.style
                  }
                  tickFormat={(t, index) =>
                    chartStyle?.yAxis?.[i]?.tickFormat(maxima?.[i][index] || 0)
                  }
                  offsetX={chartStyle?.yAxis?.[i]?.offsetX}
                  offsetY={chartStyle?.yAxis?.[i]?.offsetY}
                />
              );
            })
          ) : barData?.yAxis ? (
            <VictoryAxis
              dependentAxis
              fixLabelOverlap={true}
              tickValues={barData.yAxis}
              style={
                (chartStyle?.yAxis as AxisData)?.style ||
                (DEFAULT_BAR_STYLE.yAxis as AxisData)?.style
              }
              tickFormat={(chartStyle?.yAxis as AxisData)?.tickFormat}
              offsetX={(DEFAULT_BAR_STYLE.yAxis as AxisData)?.offsetX}
              offsetY={(chartStyle?.yAxis as AxisData)?.offsetY}
            />
          ) : null}
          <VictoryAxis
            offsetX={chartStyle?.xAxis?.offsetX}
            offsetY={chartStyle?.xAxis?.offsetY}
            fixLabelOverlap={true}
            tickValues={barData.xAxis}
            tickFormat={chartStyle?.xAxis?.tickFormat}
            gridComponent={gridComponent}
            style={controlledVictoryAxisStyle}
            axisValue={chartStyle?.xAxis?.axisValue}
            tickLabelComponent={
              <CustomVictoryLabel<any>
                data={barData.data}
                onClick={onAxisClick}
                accessKey="x"
                useCustomText={useCustomTextYAxis}
                customTextLength={customTextLength}
              />
            }
          />
          <VictoryBar
            horizontal={horizontal}
            style={chartStyle?.bar?.style || DEFAULT_BAR_STYLE.bar?.style}
            cornerRadius={
              chartStyle?.bar?.cornerRadius ||
              DEFAULT_BAR_STYLE.bar?.cornerRadius
            }
            barWidth={chartStyle?.bar?.width ?? 15}
            barRatio={chartStyle?.bar?.barRatio}
            data={barData.data}
            labels={({ datum }) => getBarLabel?.(datum) || ''}
            labelComponent={
              isBarHoverable ? (
                <VictoryTooltip
                  cornerRadius={chartStyle?.bar?.tooltip?.cornerRadius}
                  pointerLength={chartStyle?.bar?.tooltip?.pointerLength}
                  flyoutHeight={chartStyle?.bar?.tooltip?.flyoutHeight}
                  flyoutStyle={chartStyle?.bar?.tooltip?.flyoutStyle}
                  y={chartStyle?.bar?.tooltip?.y}
                  centerOffset={chartStyle?.bar?.tooltip?.offset}
                />
              ) : (
                undefined
              )
            }
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => {
                    return isBarHoverable
                      ? [
                          {
                            target: 'data',
                            mutation: ({ style, datum }) => {
                              setTickBarHovered({
                                barColor: datum?.color,
                                xValue: datum.x,
                              });

                              const fillColor = datum?.color || style.fill;

                              return {
                                style: {
                                  fill: fillColor,
                                  strokeWidth: 3,
                                  stroke: `${fillColor}52`,
                                  cursor: 'pointer',
                                },
                              };
                            },
                          },
                          {
                            target: 'labels',
                            mutation: () => ({
                              active: true,
                              style: {
                                fill: colors.ayPureWhiteColor,
                                fontSize: 5,
                                fontFamily: 'Open Sans',
                              },
                            }),
                          },
                        ]
                      : [];
                  },
                  onMouseOut: () => {
                    setTickBarHovered(undefined);
                    return [
                      {
                        target: 'data',
                        mutation: props => ({
                          style: {
                            fill: props.style.fill,
                            fillOpacity: 1,
                            strokeWidth: 0,
                            cursor: 'default',
                          },
                        }),
                      },
                      {
                        target: 'labels',
                        mutation: () => ({
                          active: false,
                        }),
                      },
                    ];
                  },
                  onClick: () => {
                    return [
                      {
                        target: 'data',
                        mutation: props => handleClickBar?.(props.datum),
                      },
                    ];
                  },
                },
              },
            ]}
          />
          {barData.lineData && (
            <VictoryGroup
              data={barData.lineData}
              style={{
                labels: {
                  fill: 'none',
                },
              }}
            >
              <VictoryLine style={chartStyle?.lineData?.style} />
              <VictoryScatter
                data={barData.lineData}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={chartStyle?.lineData?.tooltip?.cornerRadius}
                    pointerLength={chartStyle?.lineData?.tooltip?.pointerLength}
                    flyoutHeight={chartStyle?.lineData?.tooltip?.flyoutHeight}
                    flyoutStyle={chartStyle?.lineData?.tooltip?.flyoutStyle}
                    y={chartStyle?.lineData?.tooltip?.y}
                  />
                }
                style={{
                  data: {
                    fill: chartStyle?.lineData?.style?.data?.fill,
                    cursor: chartStyle?.lineData?.style?.data?.cursor,
                    stroke: 'none',
                    strokeWidth: chartStyle?.lineData?.style?.data?.strokeWidth,
                  },
                }}
                size={3}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: 'data',
                            mutation: ({ datum }) => {
                              setTickBarHovered({
                                barColor: datum?.color,
                                xValue: datum.x,
                              });
                              return {
                                style: {
                                  fill:
                                    chartStyle?.lineData?.style?.styleOnHover
                                      ?.fill,
                                  stroke:
                                    chartStyle?.lineData?.style?.styleOnHover
                                      ?.stroke,
                                  cursor:
                                    chartStyle?.lineData?.style?.styleOnHover
                                      ?.cursor,
                                  strokeWidth:
                                    chartStyle?.lineData?.style?.styleOnHover
                                      ?.strokeWidth,
                                },
                              };
                            },
                          },
                          {
                            target: 'labels',
                            mutation: () => ({
                              active: true,
                              style: {
                                fill: colors.ayPureWhiteColor,
                                fontSize: 5,
                                fontFamily: 'Open Sans',
                              },
                            }),
                          },
                        ];
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            mutation: () => {
                              setTickBarHovered(undefined);
                              return {
                                style: {
                                  fill: chartStyle?.lineData?.style?.data?.fill,
                                  cursor:
                                    chartStyle?.lineData?.style?.data?.cursor,
                                  stroke: 'none',
                                  strokeWidth:
                                    chartStyle?.lineData?.style?.data
                                      ?.strokeWidth,
                                },
                              };
                            },
                          },
                          {
                            target: 'labels',
                            mutation: () => {
                              return {
                                active: false,
                              };
                            },
                          },
                        ];
                      },
                      onClick: () => {
                        return [
                          {
                            target: 'data',
                            mutation: props => handleClickLine?.(props.datum),
                          },
                        ];
                      },
                    },
                  },
                ]}
              />
            </VictoryGroup>
          )}
        </VictoryChart>
      </>
    );
  }
};

export default VictoryBarChart;
