import { formatDate } from 'utils/formatters/date';
import ISale from 'interfaces/ISale';
import dateFormats from 'constants/dateFormats';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';

export const buildSaleCardHeader = (sale: ISale): string => {
  return sale.date
    ? `${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.purchased`,
      )} ${formatDate(sale.date, dateFormats.MONTH_YEAR)}`
    : '';
};
