import { SideNavigationItemType } from 'components/SideNavigationBar/types';
import { I18N_COMPANY_LABEL_PATH } from 'components/CompanyProfile/constants';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import locations from 'routes';

export const INVESTOR_SECTION = 'investor';
export const OCCUPIER_SECTION = 'occupier';
export const RECENT_ACTIVITY_SECTION = 'recent-activity';
export const COMPANY_SECTIONS = {
  INVESTOR_SECTION,
  OCCUPIER_SECTION,
  RECENT_ACTIVITY_SECTION,
};

export const buildNavigationItems = (
  companyId: number,
  hasInvestorData?: boolean,
  hasOccupierData?: boolean,
): SideNavigationItemType[] => [
  {
    name: INVESTOR_SECTION,
    icon: 'investor',
    isDisabled: !hasInvestorData,
    label: translateText(`${I18N_COMPANY_LABEL_PATH}.investor`),
    to: `${locations.showCompany(companyId)}/${INVESTOR_SECTION}`,
  },
  {
    name: OCCUPIER_SECTION,
    icon: 'occupier',
    isDisabled: !hasOccupierData,
    label: translateText(`${I18N_COMPANY_LABEL_PATH}.occupier`),
    to: `${locations.showCompany(companyId)}/${OCCUPIER_SECTION}`,
  },
  {
    name: RECENT_ACTIVITY_SECTION,
    icon: 'activity',
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.recentActivity`),
    to: `${locations.showCompany(companyId)}/${RECENT_ACTIVITY_SECTION}`,
  },
];
