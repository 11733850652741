import Icon from 'components/Icon';
import { colors } from 'constants/colors';
import React from 'react';
import styles from './HistoricalPerformanceModal.module.scss';

type Props = {
  message: string;
};

const ErrorMessage: React.FC<Props> = ({ message }) => (
  <div className={styles['error-message']}>
    <Icon name="warning" color={colors.ayWarningColor} />
    <p>{message}</p>
  </div>
);

export default ErrorMessage;
