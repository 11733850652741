import React, { useEffect, useMemo, useState } from 'react';
import AuthService, { NO_USER } from 'services/Auth';
import { useLocation } from 'react-router-dom';
import locations from 'routes';
import { authContext } from 'contexts/AuthContext';
import * as Sentry from '@sentry/react';
import LoadingMessage from 'components/LoadingMessage';
import { isMSTokenValid } from 'services/MSAL';
import { AuthProviders } from 'constants/authProviders';
import oktaAuth from 'utils/oktaAuth';
import { generateAccountObject } from 'utils/pendoInit';
import { LocalStorageKeys } from 'constants/localStorageKeys';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { appInsights } from 'utils/applicationInsights';
import { IUser } from 'interfaces/IUser';

type Props = {
  children: JSX.Element;
};

const AuthLoadingWrapper: React.FC<Props> = props => {
  const { user, setUser } = React.useContext(authContext);
  const appInsightsPlugin = useAppInsightsContext();
  const trackUserLogin = useTrackEvent(appInsightsPlugin, 'Login', user);
  const [userLoaded, setUserLoaded] = useState(false);
  const [userLogged, setUserLogged] = useState(false);
  const [loginTracked, setLoginTracked] = useState(false);
  const location = useLocation();
  if (
    window.location.pathname !== '/login-email' &&
    window.location.pathname !== '/' &&
    !window.location.pathname.includes('okta/callback')
  ) {
    localStorage.setItem(LocalStorageKeys.refRedirect, window.location.href);
  }
  const isOAuthRedirectPage = useMemo(() => {
    const { pathname } = location;
    return [locations.loginOktaCallback(), locations.loginMSCallback()].some(
      route => pathname.indexOf(route) !== -1,
    );
  }, [location]);

  const setApplicationInsightsSessionId = () => {
    if (!!appInsights && !!appInsights.context) {
      const sessionId = (appInsights.context as any).sessionManager
        .automaticSession.id;
      if (!!sessionId) {
        localStorage.setItem(
          LocalStorageKeys.applicationInsightsSessionId,
          sessionId,
        );
      }
    }
  };

  const initializePendoLoggedinUser = (user: IUser) => {
    window.pendo.initialize({
      visitor: {
        id: user.email ?? '',
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        officeName: user.office?.name ?? '',
        business: user.business?.name ?? '',
        businessType: user.businessType?.name ?? '',
        principal: user.principalRole ?? '',
        yearStartedAY: user.yearJoined ?? '',
        yearStartedCRE: user.yearIndustry ?? '',
      },
      account: generateAccountObject(user),
    });
  };

  useEffect(() => {
    const checkUser = async () => {
      let verifiedUser = await AuthService.refreshUser();

      if (verifiedUser?.email) {
        const localAuthProvider = localStorage.getItem(
          LocalStorageKeys.oAuthProvider,
        );
        const authProvider = await AuthService.findAuthProvider(
          verifiedUser.email,
        );

        let isInvalidToken = false;

        if (localAuthProvider !== authProvider) {
          isInvalidToken = true;
        } else if (authProvider === AuthProviders.AZURE) {
          const msToken = await isMSTokenValid();
          isInvalidToken = !msToken;
        } else {
          const accessToken = await oktaAuth.tokenManager.get('accessToken');
          const isExpired =
            accessToken && oktaAuth.tokenManager.hasExpired(accessToken);

          isInvalidToken = !accessToken || isExpired;
        }

        if (isInvalidToken) {
          AuthService.logout();
          verifiedUser = null;
        }
      }

      setUser(verifiedUser || NO_USER);

      if (verifiedUser) {
        Sentry.setUser({ id: verifiedUser.id, email: verifiedUser.email });
      } else if (!isOAuthRedirectPage) {
        AuthService.logout();
      }

      setUserLoaded(true);
    };

    // For the MS Oauth path ignore the local user
    if (location.pathname.indexOf(locations.loginMSCallback()) !== -1) {
      setUserLoaded(true);
    } else {
      checkUser();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!user.id && !userLogged && !loginTracked) {
      trackUserLogin(user);
      setLoginTracked(true);
      setApplicationInsightsSessionId();
      initializePendoLoggedinUser(user);
      setUserLogged(true);
    }
  }, [user, trackUserLogin, userLogged, loginTracked]);

  if (!userLoaded) {
    return <LoadingMessage />;
  }

  return props.children;
};

export default AuthLoadingWrapper;
