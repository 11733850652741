export enum ColorNames {
  // New Colors
  primaryColor900 = 'primary-color-900',
  primaryColor700 = 'primary-color-700',
  primaryColor500 = 'primary-color-500',
  primaryColor400 = 'primary-color-400',
  primaryColor300 = 'primary-color-300',
  primaryColor200 = 'primary-color-200',
  uiColorBlack = 'ui-color-black',
  uiColor100 = 'ui-color-100',
  systemColorWarning = 'system-color-warning',
  supportiveColor100 = 'supportive-color-100',
  supportiveColor500 = 'supportive-color-500',

  // Old Colors
  ayFrozenGrassColor = 'ay-frozen-grass-color',
  ayPepapigRedColor = 'ay-pepa-pig-red-color',
  ayGrey8Color = 'ay-grey-8-color',
  ayMainBlueColor = 'ay-main-blue-color',
  ayNightBlueColor = 'ay-night-blue-color',
  ayGrey80Color = 'ay-grey-80-color',
  ayMainRedColor = 'ay-main-red-color',
  ayGold8Color = 'ay-gold-8-color',
  ayDarkGoldColor = 'ay-dark-gold-color',
  ayWhiteColor = 'ay-white-color',
  ayPureWhiteColor = 'ay-pure-white-color',
  ayMadridBlueColor = 'ay-madrid-blue-color',
  ayBerlinBlueColor = 'ay-berlin-blue-color',
  ayGrassGreenColor = 'ay-grass-green-color',
  ayTotalBlackColor = 'ay-total-black-color',
  ayErrorColor = 'ay-error-color',
}
