import { translateText } from 'utils/i18n';
import { CompType } from 'constants/compType';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

export const getTranslatedCompLabel = (compType: CompType) => {
  if (Object.values(CompType).indexOf(compType) === -1) {
    return '';
  }

  const mapper = {
    sale: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.sale`),
    lease: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease`),
    property: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.property`),
    company: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.company`),
    pricingQuote: translateText(
      `${I18N_PLATFORM_COMMON_WORD_PATH}.pricingQuote`,
    ),
    TIM: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenantInMarket`),
  };

  return mapper[compType];
};
