import { IDataPoint } from './interfaces';
import { RecentLeasesIds } from './graphIds';

export const getMarketVerticalLineId = (node: any) => {
  return `vertical-line-mkt-${node.graphId}-${node.key}`;
};

export const getLeaseVerticalLineId = (node: any) => {
  return `vertical-line-lease-${node.graphId}-${node.id}`;
};

export const getHorizontalLineId = (node: any) => {
  return `horizontal-line-${node.graphId}-${node.id}`;
};

export const getLeaseBubbleId = (node: any) => {
  return `lease-bubble-${node.graphId}-${node.id}`;
};

export const getLeaseBubbleShadowId = (node: any) => {
  return `lease-bubble-shadow-${node.graphId}-${node.id}`;
};

export const getMarketBubbleId = (node: IDataPoint) => {
  return `market-bubble-${node.key}`;
};

export const getTooltipId = (graphId: RecentLeasesIds) => {
  return `tooltip-container-${graphId}`;
};

export const getTooltipTextId = (graphId: RecentLeasesIds) => {
  return `tooltip-text-${graphId}`;
};

export const getTooltipRectId = (graphId: RecentLeasesIds) => {
  return `tooltip-rect-${graphId}`;
};
