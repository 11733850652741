import React from 'react';
import styles from './PropertyMap.module.scss';
import { PropertyMapSize, ZoomLevel } from './constants';
import MapboxMap from 'components/MapboxMap';
import classNames from 'classnames';
import { PropertyMapCommonProps } from './interfaces';

interface Props extends PropertyMapCommonProps {
  showControls?: boolean;
}

const PropertyMap: React.FC<Props> = ({
  property,
  showControls,
  size,
  wrapperClassName,
  zoomLevel,
}) => {
  const useSmallMarker =
    size === PropertyMapSize.small || size === PropertyMapSize.extraSmall;

  return (
    <MapboxMap
      wrapperClassName={classNames(
        styles.map,
        {
          [styles.regular]: !size || size === PropertyMapSize.regular,
          [styles['extra-small']]: size === PropertyMapSize.extraSmall,
          [styles.small]: size === PropertyMapSize.small,
          [styles.big]: size === PropertyMapSize.big,
          [styles['full-width']]: size === PropertyMapSize.fullWidth,
        },
        wrapperClassName,
      )}
      showControls={showControls}
      mapZoom={zoomLevel || ZoomLevel.regular}
      useSmallMarker={useSmallMarker}
      isAttributionHidden={useSmallMarker}
      centerCoordinate={{
        longitude: property.longitudeDisplay || property.longitude!,
        latitude: property.latitudeDisplay || property.latitude!,
      }}
    />
  );
};

export default PropertyMap;
