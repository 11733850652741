import React from 'react';
import classnames from 'classnames';
import LoadingMessage from 'components/LoadingMessage';
import RadioInput, { IRadioItem } from 'components/Inputs/RadioInput';
import { ImageCategory } from 'components/ModalMedia/utils';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';
import { useGetImageCategoryData } from './useGetImageCategoryData';
import styles from './ImageCategoryPicker.module.scss';

type Props = {
  isVideo: boolean;
  propertyTypeId: number;
  selectedCategory: IRadioItem | null;
  selectedSubCategory: IRadioItem | null;
  onCategoryChange: (category: IRadioItem) => void;
  onSubCategoryChange: (category: IRadioItem | null) => void;
  label?: string;
  labelBig?: boolean;
  profileType?: PROFILE_TYPES;
};

const ImageCategoryPicker: React.FC<Props> = ({
  label,
  labelBig,
  isVideo,
  propertyTypeId,
  selectedCategory,
  selectedSubCategory,
  profileType,
  onCategoryChange,
  onSubCategoryChange,
}) => {
  const isSelectedItemAmenity = (label: string | undefined) =>
    label === ImageCategory.AMENITY;

  const selectedCategoryData = {
    ...selectedCategory,
    isCategoryParent: isSelectedItemAmenity(selectedCategory?.label),
  };

  const primaryCategoryOnChange = (item: IRadioItem) => {
    onCategoryChange(item);
  };

  const { amenities, categories, isLoading } = useGetImageCategoryData({
    propertyTypeId,
    isVideo,
    profileType,
  });

  return isLoading ? (
    <div className={styles.loading}>
      <LoadingMessage
        itemBeingLoaded={translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.category_plural`,
        )}
      />
    </div>
  ) : (
    <div className={styles.container}>
      <div
        className={classnames(styles['title'], {
          [styles['title-big']]: labelBig,
        })}
      >
        {label ||
          translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.whatPictureOfQuestion`,
          )}
      </div>
      <div className={styles['tags']}>
        <RadioInput
          isUnderline
          isColumnLayout
          data={categories}
          selectedItem={selectedCategoryData}
          onChange={primaryCategoryOnChange}
        />
        <label>
          {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.amenities`)}
        </label>
        <RadioInput
          wrapperClassName={styles['amenities-list']}
          isColumnLayout
          data={amenities}
          selectedItem={selectedSubCategory}
          onChange={value => onSubCategoryChange?.(value)}
        />
      </div>
    </div>
  );
};

export default ImageCategoryPicker;
