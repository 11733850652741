import React from 'react';
import FormControl from 'components/FormControl';
import styles from '../../FormSection.module.scss';
import TextInput from 'components/Inputs/TextInput';
import { translateText } from 'utils/i18n';
import { REGEX_DECIMALS } from 'constants/regex';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import Checkbox from 'components/Checkbox';
import classNames from 'classnames';

interface Props {
  value?: number;
  vacantPossession?: boolean;
  required?: boolean;
  isUk?: boolean;
  onChange: (field: string, value: any) => void;
}

const Occupancy: React.FC<Props> = ({
  value,
  vacantPossession,
  onChange,
  required = false,
  isUk,
}) => {
  return (
    <FormControl
      required={required}
      wrapperClassName={styles['form-row']}
      label={translateText('avantPlatform.attributes.property.label.occupancy')}
    >
      <div
        className={classNames(
          styles['large-input-container'],
          styles['large-input-container-gap'],
        )}
      >
        <TextInput
          id="occupancy"
          value={value}
          pattern={REGEX_DECIMALS}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          iconPosition={'right'}
          inputClassName={styles['percentage-input']}
          onChange={item => onChange('occupancy', item)}
        />
        {isUk && (
          <Checkbox
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.vacantPossession`,
            )}
            onChange={checked => {
              checked ? onChange('occupancy', 0) : onChange('occupancy', null);
              onChange('vacantPossession', checked);
            }}
            selected={!!vacantPossession}
            labelClassName={styles['checkbox-label']}
          />
        )}
      </div>
    </FormControl>
  );
};

export default Occupancy;
