import { IdName } from 'interfaces/IdName';
import { isUKCountry, isDataCenterBuilding } from 'utils/properties';
import { LeaseTypeEnum } from 'constants/leases';
import { IProperty } from 'interfaces/IProperty';
import { TranslateFunction } from 'utils/i18n';
import { I18N_AVANT_PROPERTY_ENUMERATORS } from 'constants/i18n';
import { camelCase } from 'lodash';

const I18N_LEASE_ENUMERATOR_PATH = `${I18N_AVANT_PROPERTY_ENUMERATORS}.leaseTypes`;

export const getLeaseTypeOptions = (
  property?: IProperty,
  t?: TranslateFunction,
): IdName[] => {
  const commonLeaseTypes = [
    {
      id: LeaseTypeEnum.DIRECT,
      name: t?.(
        `${I18N_LEASE_ENUMERATOR_PATH}.${LeaseTypeEnum.DIRECT.toLowerCase()}`,
      ),
    },
    {
      id: LeaseTypeEnum.SUBLEASE,
      name: t?.(
        `${I18N_LEASE_ENUMERATOR_PATH}.${LeaseTypeEnum.SUBLEASE.toLowerCase()}`,
      ).trim(),
    },
    {
      id: LeaseTypeEnum.COWORKING,
      name: t?.(
        `${I18N_LEASE_ENUMERATOR_PATH}.${LeaseTypeEnum.COWORKING.toLowerCase()}`,
      ),
    },
  ];

  const ukLeaseTypes = [
    {
      id: LeaseTypeEnum.LICENSE,
      name: t?.(
        `${I18N_LEASE_ENUMERATOR_PATH}.${LeaseTypeEnum.LICENSE.toLowerCase()}`,
      ),
    },
    {
      id: LeaseTypeEnum.ASSIGNMENT,
      name: t?.(
        `${I18N_LEASE_ENUMERATOR_PATH}.${LeaseTypeEnum.ASSIGNMENT.toLowerCase()}`,
      ),
    },
  ];

  const dataCenterLeaseTypes = [
    {
      id: LeaseTypeEnum.SHELL,
      name: t?.(
        `${I18N_LEASE_ENUMERATOR_PATH}.${LeaseTypeEnum.SHELL.toLowerCase()}`,
      ),
    },
    {
      id: LeaseTypeEnum.TURN_KEY,
      name: t?.(
        `${I18N_LEASE_ENUMERATOR_PATH}.${camelCase(LeaseTypeEnum.TURN_KEY)}`,
      ),
    },
  ];

  if (isUKCountry(property?.country)) {
    return [...commonLeaseTypes, ...ukLeaseTypes];
  }

  if (property && isDataCenterBuilding(property)) {
    return dataCenterLeaseTypes;
  }

  return commonLeaseTypes;
};
