import { colors } from 'constants/colors';
import { VictoryLineStyle } from './types';

export const NONE_STYLE_VALUE = 'none';

export const CHART_PADDING = {
  top: 70,
  bottom: 40,
  left: 150,
  right: 50,
};

export const handleQuarterAxis = (
  value: string,
  params?: { isQuarterly?: boolean; hideOddXTickValues?: boolean },
) => {
  if (params?.isQuarterly) {
    const [year, quarter] = value.split('-');
    return +quarter === 1 ? year : '';
  }

  return params?.hideOddXTickValues
    ? parseInt(value) % 2 === 0
      ? value
      : ''
    : value;
};

export const getLineStyle = (color: string, colorOnHover?: string) => {
  return {
    style: {
      data: {
        fill: NONE_STYLE_VALUE,
        stroke: color,
        cursor: 'default',
        strokeWidth: 1,
      },
    },
    styleOnHover: {
      style: {
        fill: color,
        stroke: NONE_STYLE_VALUE,
        strokeWidth: 3,
        cursor: 'pointer',
      },
    },
    tooltipFlyoutStyle: {
      fill: color,
      stroke: NONE_STYLE_VALUE,
    },
    ...(colorOnHover
      ? {
          tooltipStyle: {
            fontSize: 6,
            fill: colorOnHover,
          },
        }
      : undefined),
  };
};

export const DEFAULT_LINE_STYLE: VictoryLineStyle = {
  chart: {
    width: 490,
    height: 190,
    voronoiPadding: 40,
    style: {
      parent: {
        background: colors.primaryColor900,
        borderRadius: 10,
      },
      grid: { stroke: colors.uiColor800, strokeWidth: 0.5 },
    },
  },
  label: {
    x: 15,
    y: 20,
    backgroundPadding: [{ bottom: 4 }],
    style: [
      { fill: colors.ayWhiteColor, fontSize: 8 },
      { fill: colors.ayWhiteColor, fontSize: 6 },
    ],
  },
  legend: {
    height: 20,
    width: 50,
    x: 140,
    y: 15,
    style: {
      border: { stroke: NONE_STYLE_VALUE },
      labels: { fill: colors.ayWhiteColor, fontSize: 6 },
    },
  },
  xAxis: {
    style: {
      axis: { stroke: NONE_STYLE_VALUE },
      tickLabels: {
        fontSize: 6,
        paddingTop: 20,
        fill: colors.ayWhiteColor,
      },
      grid: { stroke: NONE_STYLE_VALUE },
    },
  },
  yAxis: {
    style: {
      axis: { stroke: NONE_STYLE_VALUE },
      axisLabel: { padding: 10 },
      tickLabels: {
        fontSize: 6,
        paddingRight: 5,
        paddingBottom: 10,
        fill: colors.ayWhiteColor,
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
      },
      grid: { stroke: colors.uiColor800 },
    },
  },
  tooltip: {
    flyoutHeight: 10,
    cornerRadius: 2,
    pointerLength: 2,
    pointerWidth: 2,
    flyoutPadding: 10,
    style: {
      fontSize: 6,
      fill: colors.ayBlackColor,
    },
  },
  line: {
    onHoverScatterSize: 3,
    idleScatterSize: 3,
  },
};
