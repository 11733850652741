import React, { ReactNode, useEffect, useMemo } from 'react';
import ModalWrapper from 'components/ModalWrapper';
import styles from './CompsPopup.module.scss';
import { useQuery, NetworkStatus, DocumentNode } from '@apollo/client';
import Button from 'components/Button/new';
import { ILease } from 'interfaces/ILease';
import LeaseCard from 'components/ActivityCard/new/LeaseCard';
import { LeaseCardField } from 'components/Leases/fields';
import { ColorNames } from 'constants/colorNames';
import SpinnerLoader from 'components/SpinnerLoader';
import ISale from 'interfaces/ISale';
import SaleCard from 'components/Sales/SaleCard';
import { SaleField } from 'components/Sales/fields';
import PropertyCard from 'components/PropertyCardList/PropertyCard';
import { IProperty } from 'interfaces/IProperty';
import { ErrorLogger } from 'services/ErrorLogger';
import { DataTypes, getDataType, getItemsToRender } from './utils';
import AvailabilityCard from 'components/AvailabilityCard';
import { IAvailability } from 'interfaces/IAvailability';
import { ITIM } from 'interfaces/ITIM';
import { translateText } from 'utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import classNames from 'classnames';
import TenantCard from '../Leases/TenantCard';

interface Props {
  children?: ReactNode;
  action?: (data?: any) => void;
  actionLabel?: string;
  graphqlQuery: DocumentNode;
  graphqlVariables?: any;
  hasDataChanges?: boolean;
  hideAction?: boolean;
  onChangeData?: (data?: any) => void;
  onDeleteData?: (data?: any) => void;
  onClose: () => void;
  showPropertyData?: boolean;
  tagBackgroundColor?: ColorNames;
  title?: string;
  subtitle?: string;
  useLeaseTitle?: boolean;
  emptyContent?: React.ReactNode;
  footer?: {
    content: JSX.Element;
    className?: string;
  };
  allowContentOverflow?: boolean;
  hideBuildingInfo?: boolean;
}

const CompsPopup: React.FC<Props> = ({
  action,
  actionLabel,
  children,
  emptyContent,
  footer,
  graphqlQuery,
  graphqlVariables,
  hasDataChanges,
  hideAction = false,
  onChangeData,
  onDeleteData,
  onClose,
  showPropertyData,
  tagBackgroundColor,
  title,
  subtitle,
  hideBuildingInfo = false,
  allowContentOverflow = true,
}) => {
  const { data, error, loading, refetch, networkStatus } = useQuery(
    graphqlQuery,
    {
      variables: graphqlVariables || {},
      fetchPolicy: 'cache-and-network',
    },
  );

  if (error) ErrorLogger.log(error.message);

  const dataType = getDataType(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const itemsToRender = useMemo(() => getItemsToRender(data), [data, dataType]);
  const hasItemsToRender = !!itemsToRender?.length;

  useEffect(() => {
    if (hasDataChanges) refetch();
    if (data && !hasItemsToRender && !emptyContent) onClose();
    // eslint-disable-next-line
  }, [hasDataChanges, itemsToRender]);

  const renderContent = () => {
    if (dataType === DataTypes.leases) {
      return itemsToRender?.map((lease: ILease) => (
        <div className={styles['card-item']} key={lease.id}>
          <LeaseCard
            lease={lease}
            displayFields={[
              LeaseCardField.SignedDate,
              LeaseCardField.Size,
              LeaseCardField.Floor,
              LeaseCardField.RentPrice,
              LeaseCardField.Type,
              LeaseCardField.Term,
              LeaseCardField.FreeMonths,
              LeaseCardField.TI,
              LeaseCardField.Escalation,
              LeaseCardField.SpaceUse,
              LeaseCardField.ExpirationDate,
              LeaseCardField.TenantIndustry,
              LeaseCardField.AdditionalConcessions,
            ]}
            hideCreatedUpdate
            hideBrokers
            hideBuildingInfo={hideBuildingInfo}
          />
        </div>
      ));
    }

    if (dataType === DataTypes.sales) {
      return itemsToRender?.map((sale: ISale) => (
        <div className={styles['card-item']} key={sale.id}>
          <SaleCard
            item={sale}
            fieldsToShow={[SaleField.PricePerSF, SaleField.Price]}
            showPropertyData={showPropertyData}
            tagBackgroundColor={
              tagBackgroundColor || ColorNames.ayFrozenGrassColor
            }
            secondaryFieldsToShow={[
              SaleField.Size,
              SaleField.Sellers,
              SaleField.CapRate,
              SaleField.SellersBrokers,
              SaleField.BuyersBrokers,
              SaleField.InterestPercent,
              SaleField.Floor,
              SaleField.Suite,
              SaleField.AcquisitionType,
            ]}
            onUpdate={onChangeData}
          />
        </div>
      ));
    }
    if (dataType === DataTypes.tims) {
      return itemsToRender?.map((tim: ITIM) => (
        <div className={styles['card-item']} key={tim.id}>
          <TenantCard tim={tim} isLoading={false} />
        </div>
      ));
    }

    if (dataType === DataTypes.properties) {
      return itemsToRender?.map((property: IProperty) => (
        <div className={styles['card-item']} key={property.id}>
          <PropertyCard property={property} isAddressClickable />
        </div>
      ));
    }

    if (dataType === DataTypes.availabilities) {
      return itemsToRender?.map((availability: IAvailability) => (
        <div className={styles['card-item']} key={availability.id}>
          <AvailabilityCard
            availability={availability}
            onEdit={onChangeData}
            onDelete={onDeleteData}
          />
        </div>
      ));
    }

    return null;
  };

  return (
    <ModalWrapper
      large
      withOpacity
      footer={footer?.content}
      withCloseButton
      onCancel={onClose}
      wrapperClassName={styles.container}
      footerWrapperClassName={footer?.className}
    >
      <header className={styles.header}>
        <div className={styles['title-container']}>
          {!!title && <h3 className={styles.title}>{title}</h3>}
          {!!subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        {!hideAction && (
          <Button
            icon={'compass'}
            iconPosition="left"
            type={'ghost'}
            size="m"
            label={actionLabel}
            onClick={() => action?.(data)}
          />
        )}
      </header>

      {children}

      {loading || networkStatus === NetworkStatus.refetch ? (
        <div className={styles.loading}>
          <SpinnerLoader background={ColorNames.ayTotalBlackColor} />
          <p className={styles['loading-label']}>
            {`${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.loading`)}...`}
          </p>
        </div>
      ) : (
        <div
          className={classNames({
            [styles['content-overflow']]: allowContentOverflow,
          })}
        >
          {renderContent()}
        </div>
      )}

      {error && (
        <p>
          {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.unableToLoadItems`)}
        </p>
      )}
      {!loading && !hasItemsToRender && emptyContent}
    </ModalWrapper>
  );
};

export default CompsPopup;
