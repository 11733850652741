import classnames from 'classnames';
import UserMailToLink from 'components/UserMailToLink';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { DOT } from 'constants/placeholders';
import { authContext } from 'contexts/AuthContext';
import { IScoop } from 'interfaces/IScoop';
import { IUser } from 'interfaces/IUser';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ActivityOwner.module.scss';

export interface Props {
  scoop: IScoop;
  user: IUser;
  colored?: boolean;
}

const ActivityOwnerMarketScoop: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { user } = useContext(authContext);
  const isMe = user.id === props.user?.id;
  const isSameUserEdit = props.user?.id === props.scoop.lastEditedUserId;

  const renderEditedUser = () => {
    if (isSameUserEdit || !props.scoop.lastEditedUser)
      return <span> {DOT} </span>;

    const isMeEditScoop = user.id === props.scoop.lastEditedUserId;

    const modifiedText = t(`${I18N_PLATFORM_COMMON_WORD_PATH}.modified`);

    return (
      <>
        <span> {DOT} </span>
        <span>
          {`${modifiedText} `}
          <span
            className={classnames(styles['user-name'], {
              [styles['colored']]: props.colored,
            })}
          >
            {isMeEditScoop ? (
              t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.you`)
            ) : (
              <UserMailToLink user={props.scoop.lastEditedUser} />
            )}
          </span>
        </span>
        <span> {DOT} </span>
      </>
    );
  };

  const createdText = t(`${I18N_PLATFORM_COMMON_WORD_PATH}.created`);

  return (
    <>
      {!!props.user?.email && (
        <>
          {`${createdText} `}
          <span
            className={classnames(styles['user-name'], {
              [styles['colored']]: props.colored,
            })}
          >
            {isMe ? (
              t<string>(`${I18N_PLATFORM_COMMON_WORD_PATH}.you`)
            ) : (
              <UserMailToLink user={props.user} />
            )}
          </span>
          {renderEditedUser()}
        </>
      )}
    </>
  );
};

export default ActivityOwnerMarketScoop;
