import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { colors } from 'constants/colors';
import { GraphClasses, getRecentSalesBarShadowId } from '../nodes';
import { graphDimensions } from '../graphConstants';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { roundedRectPath } from '../../utils/rectPathDrawer';
import { ISaleBarData, HoverData } from '../interfaces';
import { isUsingMeters } from 'utils/unitsOfMeasurement';

type Props = {
  data: ISaleBarData[];
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number>;
  onHover: (barNode?: any, hoverData?: HoverData) => void;
  onClick: (clickData?: string) => void;
  unitOfMeasurement: string;
};

const BAR_RADIUS = 8;
const SHADOW_RADIUS = 1;
const SHADOW_WIDTH = 12;
const SHADOW_OPACITY = 0.32;
const MIN_HEIGHT = 8;

export const getElementColor = (d: ISaleBarData) =>
  d.isQuarter || (d.isPlaceholder && !d.isMarket)
    ? colors.primaryColor500
    : colors.supportive500;

export const getLabelColor = (d: ISaleBarData) =>
  d.isQuarter || (d.isPlaceholder && !d.isMarket)
    ? colors.ayPureWhiteColor
    : colors.supportive300;

const getOpacity = (d: ISaleBarData) => (d.isPlaceholder ? 0.2 : 1);

const Bar: React.FC<Props> = ({
  data,
  xScale,
  yScale,
  onHover,
  onClick,
  unitOfMeasurement,
}) => {
  const barRef = useRef(null);

  const getValueY = (d: ISaleBarData) => {
    const value = d.isPlaceholder
      ? d.size
      : d.total! > 0
      ? yScale(d.total!)
      : d.total;

    return value || 0;
  };

  const drawPathShadow = (d: ISaleBarData) => {
    const yValue = getValueY(d);
    return roundedRectPath(
      xScale(d.key)!,
      graphDimensions.HEIGHT - yValue,
      xScale.bandwidth(),
      Math.max(yValue, MIN_HEIGHT),
      SHADOW_RADIUS,
      true,
      true,
      true,
      true,
    );
  };

  const drawPath = (d: ISaleBarData) => {
    const yValue = getValueY(d);
    return yValue
      ? roundedRectPath(
          xScale(d.key)!,
          graphDimensions.HEIGHT - yValue!,
          xScale.bandwidth(),
          Math.max(yValue!, MIN_HEIGHT),
          BAR_RADIUS,
          true,
          true,
        )
      : '';
  };

  useEffect(() => {
    const node = barRef.current;
    const isPlaceholder = data.length === 1 && data[0].isPlaceholder;
    const hasTotal = data.some(bar => !!bar.total);
    const isDataBar = data.length >= 1 && hasTotal;
    const shouldRenderShadow = !isPlaceholder && isDataBar;

    shouldRenderShadow &&
      select(node)
        .selectAll(`.${GraphClasses.BarShadow}`)
        .data(data)
        .enter()
        .append('path')
        .attr('id', getRecentSalesBarShadowId)
        .attr('d', drawPathShadow)
        .attr('fill', 'transparent')
        .style('stroke-width', SHADOW_WIDTH)
        .style('stroke', getElementColor)
        .attr('stroke-opacity', SHADOW_OPACITY)
        .style('display', 'none');

    select(node)
      .selectAll(`.${GraphClasses.Bar}`)
      .data(data)
      .enter()
      .append('path')
      .attr('d', drawPath)
      .attr('fill', getElementColor)
      .attr('fill-opacity', getOpacity)
      .attr('data-test', d => (d.isPlaceholder ? 'placeholder-bar' : null))
      .attr('stroke', d =>
        d.isPlaceholder && !d.isMarket
          ? colors.ayPureWhiteColor
          : getElementColor(d),
      )
      .style('stroke-dasharray', d => (d.isPlaceholder ? 4 : 0))
      .attr('cursor', 'pointer')
      .on('click', function(barParams: any) {
        if (!barParams.isPlaceholder) {
          const lastBarKey = data.length - 1;
          const saleId =
            barParams.key !== lastBarKey ? barParams.barData.id : '';
          onClick(saleId);
        }
      })
      .on('mouseover', function(this: any, dataPoint: any) {
        if (!dataPoint.isHoverEnabled) return;

        if (dataPoint.isPlaceholder) {
          onHover(this, { key: dataPoint.key, isPlaceholder: true });
        } else {
          const {
            key,
            isMarket,
            isMostRecent,
            barData: {
              price,
              size,
              value,
              marketTotalCount,
              marketTotalPrice,
              marketTotalSF,
              marketTotalSquareMeters,
              marketAverage,
            },
          } = dataPoint;
          const tooltipData = {
            price: price || marketTotalPrice,
            size:
              size ||
              (isUsingMeters(unitOfMeasurement)
                ? marketTotalSquareMeters
                : marketTotalSF),
            value: value || marketAverage,
            count: marketTotalCount,
          };

          onHover(this, { key, tooltipData, isMarket, isMostRecent });
        }

        select(`#${getRecentSalesBarShadowId(dataPoint)}`).style(
          'display',
          'block',
        );
      })
      .on('mouseout', barParams => {
        if (barParams.isHoverEnabled) {
          onHover(null);
          select(`#${getRecentSalesBarShadowId(barParams)}`).style(
            'display',
            'none',
          );
        }
      });
    // eslint-disable-next-line
  }, []); // Render only once

  return <g ref={barRef} />;
};

export default Bar;
