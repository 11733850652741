import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './MissingCompData.module.scss';
import { useHistory } from 'react-router-dom';
import { IProperty } from 'interfaces/IProperty';
import locations from 'routes';
import { CompType } from 'constants/compType';
import { useTranslation } from 'react-i18next';
import { I18N_AVANT_PROPERTY_TEXT_PATH } from 'constants/i18n';
import EditHistoricalPerformance from 'components/HistoricalPerformance/HistoricalPerformanceModal/EditHistoricalPerformance';

interface Props {
  isAskingRent?: boolean;
  isAvailability?: boolean;
  isSale?: boolean;
  isLease?: boolean;
  isTenantInformation?: boolean;
  property?: IProperty;
}

const MissingCompData: React.FC<Props> = ({
  isAskingRent,
  isAvailability,
  isSale,
  isLease,
  isTenantInformation,
  property,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isPerformanceModalVisible, setIsPerformanceModalVisible] = useState<
    boolean
  >(false);

  const tabType = isLease
    ? CompType.lease
    : isSale
    ? CompType.sale
    : isTenantInformation
    ? CompType.lease
    : isAskingRent
    ? CompType.pricingQuote
    : CompType.lease;

  const splitMissingDataText = (
    missingDataText: string,
    isLinkText: boolean,
  ) => {
    if (isLinkText) {
      return missingDataText.slice(
        missingDataText.indexOf('click'),
        missingDataText.length,
      );
    }
    return missingDataText.slice(0, missingDataText.indexOf('click'));
  };

  const getMissingDataText = (isLinkText: boolean) => {
    if (isLease) {
      return splitMissingDataText(
        t(`${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound.missingLeaseData`),
        isLinkText,
      );
    } else if (isSale) {
      return splitMissingDataText(
        t(`${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound.missingSaleData`),
        isLinkText,
      );
    } else if (isTenantInformation) {
      return splitMissingDataText(
        t(
          `${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound.missingTenantInformationData`,
        ),
        isLinkText,
      );
    } else if (isAvailability) {
      return splitMissingDataText(
        t(
          `${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound.missingAssetPerformanceData`,
        ),
        isLinkText,
      );
    } else if (isAskingRent) {
      return splitMissingDataText(
        t(`${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound.missingAskingRentData`),
        isLinkText,
      );
    }
  };

  return (
    <div
      className={classnames({
        [styles['asking-rent-layout']]: isAskingRent,
        [styles['availability-layout']]: isAvailability,
        [styles['tenant-information-layout']]: isTenantInformation,
        [styles['sale-layout']]: isSale,
        [styles['lease-layout']]: isLease,
      })}
    >
      <span className={classnames(styles.text)}>
        {getMissingDataText(false)}
        <span
          className={classnames(styles['link-text'])}
          onClick={() => {
            if (isAvailability) {
              setIsPerformanceModalVisible(true);
              return;
            }
            history.push(locations.newComps(), {
              sourceProperty: property,
              tabType: tabType,
            });
          }}
        >
          {getMissingDataText(true)}
        </span>
      </span>
      {isPerformanceModalVisible && (
        <EditHistoricalPerformance
          propertyId={property?.id}
          onClose={() => setIsPerformanceModalVisible(false)}
        />
      )}
    </div>
  );
};

export default MissingCompData;
