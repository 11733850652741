import React, { useMemo } from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { ITIM } from 'interfaces/ITIM';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { PropertyTypeIds } from 'constants/propertyTypes';
import { translateText } from 'utils/i18n';
import AYLink from 'components/AYLink';
import locations from 'routes';
import { renderSubtitles } from '../utils';
import styles from '../TenantCard.module.scss';
import Button from '../../../Button/new';

interface Props {
  tim?: ITIM;
  onEdit: () => void;
  setIsShowDeleteModal: (isShow: boolean) => void;
  companyLogo: string;
  hasDeleteButton?: boolean;
  isCarouselView?: boolean;
  imageClassName?: string;
}

const TenantHeader = ({
  tim,
  onEdit,
  setIsShowDeleteModal,
  companyLogo,
  hasDeleteButton,
  isCarouselView,
  imageClassName,
}: Props) => {
  const getPropertyTypeFromId = (): string => {
    for (const propertyType in PropertyTypeIds) {
      if (PropertyTypeIds[propertyType] === tim?.targetPropertyTypeId) {
        return capitalize(propertyType);
      }
    }
    return '';
  };

  const targetPropertyAndClass = [
    getPropertyTypeFromId(),
    tim?.targetThirdPartyPropertyClass?.name ?? '',
  ];
  const targetSubmarkets = useMemo(() => {
    return tim?.targetSubmarkets?.map(submarket => submarket.name) ?? [];
  }, [tim]);

  const secondaryHeaderInfo = (
    <div className={styles['subtitle']}>
      {renderSubtitles(targetPropertyAndClass)}
      <span className={`${styles['chip']} ${styles['chip-margin']}`}>
        {translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.tim`)}
      </span>
    </div>
  );

  const headerInfo = (
    <div className={styles['header-info']}>
      <AYLink to={locations.showMarket(tim?.targetMarket?.id)}>
        <div className={styles['title']}>{tim?.targetMarket?.name}</div>
      </AYLink>
      <div className={styles['subtitle']}>
        {renderSubtitles(targetSubmarkets)}
      </div>
      {!isCarouselView && secondaryHeaderInfo}
    </div>
  );

  const renderHeader = () => {
    return (
      <>
        <div className={styles['header-wrapper']}>
          <div className={styles['header-content']}>
            <div className={styles['logo-container']}>
              <img
                src={companyLogo}
                alt="company logo"
                className={classNames(
                  {
                    [styles['company-logo-small']]: isCarouselView,
                    [styles['company-logo-default']]: !isCarouselView,
                  },
                  imageClassName,
                )}
              />
            </div>
            {headerInfo}
          </div>
          <div>
            <div className={styles['action-buttons']}>
              {hasDeleteButton && (
                <button
                  className={classNames(
                    styles['delete-button'],
                    styles['act-button'],
                  )}
                  type="button"
                  onClick={() => setIsShowDeleteModal(true)}
                />
              )}
              <Button
                wrapperClassName={styles['edit-button']}
                icon="edit"
                iconPosition="left"
                onClick={onEdit}
                size="s"
                type="contextual"
              />
            </div>
          </div>
        </div>
        {isCarouselView && secondaryHeaderInfo}
      </>
    );
  };
  return <>{renderHeader()}</>;
};

export default TenantHeader;
