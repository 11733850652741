import Button from 'components/Button/new';
import React from 'react';
import styles from '../ScoopCommentsModal.module.scss';

interface Props {
  onEditClick?: () => void;
  onModalToggle: (isOpen: boolean) => void;
  onShareClick?: () => void;
}

const ScoopCommentsModalActions: React.FC<Props> = ({
  onShareClick,
  onEditClick,
  onModalToggle,
}) => (
  <div className={styles.actions}>
    <Button
      description={'Share scoop'}
      icon="share"
      iconSize={0.75}
      onClick={onShareClick}
      size="s"
      type="contextual"
      wrapperClassName={styles['action-button']}
    />
    <Button
      description={'Edit scoop'}
      icon="edit"
      iconSize={0.75}
      onClick={onEditClick}
      size="s"
      type="contextual"
      wrapperClassName={styles['action-button']}
    />
    <Button
      description={'Close comments modal'}
      icon="close"
      iconSize={0.75}
      onClick={() => onModalToggle(false)}
      size="s"
      type="supportive-neutral"
      wrapperClassName={styles['action-button']}
    />
  </div>
);

export default ScoopCommentsModalActions;
