import React from 'react';
import classnames from 'classnames';

import { Tooltip } from 'base-components/Tooltip';
import { IUser } from 'interfaces/IUser';

import styles from './UserAvatar.module.scss';
import { transformImageUrl } from 'utils/cloudinary/urls';

type PropTypes = {
  className?: string;
  user: IUser;
  showTooltip?: boolean;
};

const UserAvatar: React.FC<PropTypes> = (props: PropTypes) => {
  const { className, user, showTooltip = false } = props;

  const { firstName, lastName, email, profileImageUrl } = user;

  const renderProfileImage = () => (
    <img
      src={transformImageUrl(profileImageUrl!, 200, 200)}
      className={styles['profile-image']}
      alt="Profile"
    />
  );

  const renderProfileInitials = () => {
    const initials =
      firstName && lastName
        ? `${firstName?.charAt(0)}${lastName?.charAt(0)}`
        : email?.charAt(0);

    return <span className={styles['initials']}>{initials}</span>;
  };

  return (
    <Tooltip preventFromOpening={!showTooltip}>
      <Tooltip.Trigger>
        <div className={classnames(styles.container, className)}>
          {profileImageUrl ? renderProfileImage() : renderProfileInitials()}
        </div>
      </Tooltip.Trigger>
      <Tooltip.Body>{`${user.firstName} ${user.lastName}`}</Tooltip.Body>
    </Tooltip>
  );
};

export default UserAvatar;
