import React from 'react';
import { omit } from 'lodash';
import { useApolloClient, useMutation } from '@apollo/client';
import { GET_DOCUMENTS_BY_SCOOP_QUERY } from 'graphql/documents/queries';
import { GET_SCOOP_MEDIA } from 'graphql/scoops/queries';
import { DELETE_MEDIA_MUTATION } from 'graphql/images';
import { DELETE_DOCUMENT_MUTATION } from 'graphql/documents';
import { IDocument } from 'interfaces/IDocument';
import { IScoop } from 'interfaces/IScoop';
import { IScoopMedia } from 'interfaces/IScoopMedia';

import DeleteModal from '../../../DeleteModal';
import { KVFilesToUpload } from '../../../ModalMedia/types';
import { DeleteModalState, INITIAL_DELETE_MODAL_STATE } from '../index';

interface Props {
  documents: IDocument[];
  mediaScoop: IScoopMedia[];
  deleteModalState: DeleteModalState;
  setDeleteModalState: React.Dispatch<React.SetStateAction<DeleteModalState>>;
  setMediaFilesToUpload: React.Dispatch<
    React.SetStateAction<KVFilesToUpload | null>
  >;
  activityScoop?: IScoop;
}
export const DeleteMediaModal = ({
  documents,
  mediaScoop,
  deleteModalState,
  setMediaFilesToUpload,
  setDeleteModalState,
  activityScoop,
}: Props) => {
  const client = useApolloClient();

  const [deleteMedia, { loading: loadingDeleteMedia }] = useMutation(
    DELETE_MEDIA_MUTATION,
  );
  const [deleteDocument, { loading: loadingDeleteDocument }] = useMutation(
    DELETE_DOCUMENT_MUTATION,
  );
  const onDeleteMedia = async () => {
    const mediaId = deleteModalState.activeOpenScoopId as string;
    const isDocument = deleteModalState?.isDocument;

    // local uploads from state
    if (!deleteModalState.removeByRequest) {
      setMediaFilesToUpload(prevFiles => omit(prevFiles, mediaId));
      setDeleteModalState(INITIAL_DELETE_MODAL_STATE);
      return;
    }

    // saved uploads should be removed by request
    // delete document
    if (isDocument) {
      await deleteDocument({
        variables: {
          documentId: +mediaId,
        },
      });

      const newMediaDocuments = documents?.filter(d => d.id !== +mediaId);

      client.writeQuery({
        query: GET_DOCUMENTS_BY_SCOOP_QUERY,
        variables: {
          scoopId: activityScoop?.id,
        },
        data: {
          documents: newMediaDocuments,
        },
      });
    } else {
      // delete image
      await deleteMedia({
        variables: {
          mediaId: +mediaId,
        },
      });

      const newMediaScoop = mediaScoop?.filter(media => media.id !== +mediaId);

      client.writeQuery({
        query: GET_SCOOP_MEDIA,
        variables: {
          scoopId: activityScoop?.id,
        },
        data: {
          mediaScoop: newMediaScoop,
        },
      });
    }
    setDeleteModalState(INITIAL_DELETE_MODAL_STATE);
  };

  return (
    <>
      {deleteModalState.open && (
        <DeleteModal
          onDelete={onDeleteMedia}
          isDeleting={loadingDeleteMedia || loadingDeleteDocument}
          onClose={() => setDeleteModalState(INITIAL_DELETE_MODAL_STATE)}
        />
      )}
    </>
  );
};
