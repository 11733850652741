const MARGINS = {
  top: 8,
  right: 24,
  bottom: 32,
  left: 24,
};
const HEIGHT = 46;
const SEGMENT_HEIGHT = 40;
const SEGMENT_RADIUS = 4;
const SHADOW_WIDTH = 10;

export const graphDimensions = {
  MARGINS,
  HEIGHT,
  SEGMENT_HEIGHT,
  SEGMENT_RADIUS,
  SHADOW_WIDTH,
};

export enum HoldPeriodSegment {
  property,
  market,
}
