import { capitalize } from 'lodash';
import { TranslateFunction, translateText } from 'utils/i18n';
import { IRadioItem } from 'components/Inputs/RadioInput';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

export const getRenewalTermOptions = (t: TranslateFunction): IRadioItem[] => [
  {
    label: `60 ${capitalize(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.month_plural`),
    )} (5 ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`)})`,
    value: 60,
  },
  {
    label: `84 ${capitalize(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.month_plural`),
    )} (7 ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`)})`,
    value: 84,
  },
  {
    label: `120 ${capitalize(
      t(`${I18N_PLATFORM_COMMON_WORD_PATH}.month_plural`),
    )} (10 ${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.year_plural`)})`,
    value: 120,
  },
];

export const getNoticeDateOptions = (_t: TranslateFunction): IRadioItem[] => [
  {
    label: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.monthPrior`,
      {
        number: 3,
      },
    ),
    value: 3,
  },
  {
    label: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.monthPrior`,
      {
        number: 6,
      },
    ),
    value: 6,
  },
  {
    label: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.monthPrior`,
      {
        number: 9,
      },
    ),
    value: 9,
  },
  {
    label: translateText(
      `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.monthPrior`,
      {
        number: 12,
      },
    ),
    value: 12,
  },
];
