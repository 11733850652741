import React, { useEffect } from 'react';
import styles from '../Bubble.module.scss';
import { select } from 'd3-selection';
import { formatArea } from 'utils/formatters/area';
import { DOT } from 'constants/placeholders';
import {
  getPropertyAddress,
  getPropertyMarkets,
} from 'utils/formatters/property';
import {
  getFieldValueForProperty,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { IGraphBuildingSizeProperty } from 'interfaces/graphs/buildingSize';

interface Props {
  property?: IGraphBuildingSizeProperty;
  onContentChanged?: () => void;
}

const TOOLTIP_HEIGHT = 80;

export const updateTooltipPosition = (bubbleNode: any) => {
  const tooltipNode: any = select('#building-size-tooltip')?.node();
  const currentBubbleBounds = bubbleNode?.getBoundingClientRect();

  if (!currentBubbleBounds || !tooltipNode) return;

  select('#building-size-tooltip').style('display', 'block');
  select('#building-size-tooltip')
    .style(
      'top',
      `${currentBubbleBounds.y -
        TOOLTIP_HEIGHT +
        document.documentElement.scrollTop}px`,
    )
    .style(
      'left',
      `${currentBubbleBounds.x +
        currentBubbleBounds.width / 2 -
        tooltipNode.getBoundingClientRect().width / 2}px`,
    );
};

const Tooltip: React.FC<Props> = ({ onContentChanged, property }) => {
  useEffect(() => {
    onContentChanged?.();
  }, [onContentChanged, property]);

  if (!property) return null;

  const mainText = [
    getPropertyAddress({ primaryAddress: property.primaryAddress }),
    property?.name,
  ]
    .filter(Boolean)
    .join(` ${DOT} `);

  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    property?.measurementSystem,
  );

  const buildingSize = getFieldValueForProperty(
    'buildingSize',
    property!,
    ModelsWithUnitsOfMeasurement.Property,
  );

  return (
    <div className={styles['tooltip-top']} id="building-size-tooltip">
      <div className={styles['tooltip-main-text']}>{mainText}</div>
      <div className={styles['tooltip-secondary-text']}>
        {formatArea(buildingSize, unitOfMeasurement)}
      </div>
      <div className={styles['tooltip-secondary-text']}>
        {getPropertyMarkets(
          {
            market: { name: property.market },
            submarket: { name: property.submarket },
            micromarket: { name: property.micromarket },
          },
          DOT,
        )}
      </div>
    </div>
  );
};

export default Tooltip;
