import { gql } from '@apollo/client';

export const COMPANY_PAGINATION_DATA_FRAGMENT = gql`
  fragment companyPaginationData on CompanySearchResponse {
    total
    totalPages
    pageSize
    currentPage
  }
`;

export const COMPANY_BASIC_RESULTS_FRAGMENT = gql`
  fragment companyBasicResults on Company {
    id
    name
    headquarters
    domain
    website
    logoUrl
    industry {
      id
      name
    }
    mergedAt
    mergedCompanyId
    mergeCompanyEventId
    mergedCompany {
      id
      name
      headquarters
      industry {
        id
        name
      }
    }
  }
`;
