import { PropertySetTypes } from 'constants/propertySetTypes';
import { GET_OVERVIEW_COMPOSITION_METADATA } from 'graphql/agencyAssignments';
import { IPropertySetCompositionMetadata } from 'interfaces/IPropertySetMetadata';
import { useQuery } from '@apollo/client';

export const usePropertySetCompositionMetadata = (
  propertySetId: number,
): {
  propertySetCompositionMetadataResponse: IPropertySetCompositionMetadata;
  isLoading: boolean;
} => {
  const { data, loading } = useQuery(GET_OVERVIEW_COMPOSITION_METADATA, {
    variables: {
      search: {
        filter: {
          type: PropertySetTypes.AGENCY_ASSIGNMENT,
          setIds: [propertySetId],
        },
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !propertySetId,
  });

  return {
    propertySetCompositionMetadataResponse:
      data?.propertySetsCompositionMetadata,
    isLoading: loading,
  };
};
