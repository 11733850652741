import React from 'react';
import { IdName } from 'interfaces/IdName';
import styles from '../../FormSection.module.scss';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import { translateText } from 'utils/i18n';
import { isUKCountry } from 'utils/properties';
import FormControl from 'components/FormControl';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { PropertyTypes } from 'constants/propertyTypes';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';

interface Props {
  addressCountry?: string | null;
  onUpdate: (fieldName: string, fieldValue: any) => void;
  property: IPropertyInput;
  ownerOccupiedData: IdName[];
  isLoadingOwnerOccupiedData: boolean;
}

const CompaniesFields: React.FC<Props> = ({
  addressCountry,
  onUpdate,
  property,
  ownerOccupiedData,
  isLoadingOwnerOccupiedData,
}) => (
  <div className={styles.container}>
    <CompanySearchCombo
      isMultiple
      label={translateText('avantPlatform.roles.company.label.owners')}
      btnLabel={translateText('avantPlatform.roles.company.action.createOwner')}
      companies={property.owners}
      placeholder={translateText(
        'avantPlatform.roles.company.prompt.enterOwnerNames',
      )}
      inputId={'property-owners-input-id'}
      wrapperClassName={styles['form-row']}
      aliasPlaceholder={(suggestionName: string) =>
        translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.tagAsOwner`, {
          suggestionName,
        })
      }
      onCompanySelection={(value: any) => {
        const newValue = value?.raw || value;
        newValue?.id &&
          onUpdate('owners', [...(property.owners || []), newValue]);
      }}
      onRemoveItem={(owner: IdName) => {
        onUpdate(
          'owners',
          (property.owners || []).filter(
            (value: IdName) => value.id !== owner.id,
          ),
        );
      }}
    />

    <FormControl
      label={translateText(
        'avantPlatform.attributes.property.label.ownerOccupied',
      )}
      wrapperClassName={styles['form-row']}
      isMultiline
    >
      <ChoiceInput
        isDisabled={isLoadingOwnerOccupiedData}
        selectedItem={property.ownerOccupied}
        data={ownerOccupiedData}
        containerWrapperClassName={styles['radio-input-container-multiline']}
        onChange={item => onUpdate('ownerOccupied', item)}
      />
    </FormControl>

    {isUKCountry(
      property.country || addressCountry || property.propertyCountry?.code,
    ) && (
      <CompanySearchCombo
        isMultiple
        label={translateText(
          'avantPlatform.roles.company.label.longLeaseholders',
        )}
        btnLabel={translateText(
          'avantPlatform.roles.company.action.createLeaseholder',
        )}
        companies={property.longLeaseHolders}
        placeholder={translateText(
          'avantPlatform.roles.company.prompt.enterLeaseholderNames',
        )}
        inputId={'property-leaseholders-input-id'}
        wrapperClassName={styles['form-row']}
        onCompanySelection={(value: any) => {
          const newValue = value?.raw || value || null;
          newValue &&
            onUpdate('longLeaseHolders', [
              ...(property.longLeaseHolders || []),
              newValue,
            ]);
        }}
        onRemoveItem={(owner: IdName) => {
          onUpdate(
            'longLeaseHolders',
            (property.longLeaseHolders || []).filter(
              (value: IdName) => value.id !== owner.id,
            ),
          );
        }}
      />
    )}

    <CompanySearchCombo
      label={translateText('avantPlatform.roles.company.label.leasingCompany')}
      btnLabel={translateText(
        'avantPlatform.roles.company.action.createLeasingCompany',
      )}
      wrapperClassName={styles['form-row']}
      placeholder={translateText(
        'avantPlatform.roles.company.prompt.enterLeasingCompanyName',
      )}
      inputId={'property-leasing-company-input-id'}
      companies={property.leasingCompany ? [property.leasingCompany] : []}
      onClearSelectedSuggestion={() => onUpdate('leasingCompany', null)}
      onCompanySelection={(value: any) => {
        const newValue = value?.custom || value || null;
        onUpdate('leasingCompany', newValue);
      }}
    />

    <CompanySearchCombo
      label={translateText('avantPlatform.roles.company.label.propertyManager')}
      btnLabel={translateText(
        'avantPlatform.roles.company.action.createPropertyManager',
      )}
      wrapperClassName={styles['form-row']}
      inputId={'property-management-company-input-id'}
      placeholder={translateText(
        'avantPlatform.roles.company.prompt.enterPropertyManagerName',
      )}
      companies={property.managementCompany ? [property.managementCompany] : []}
      onClearSelectedSuggestion={() => onUpdate('managementCompany', null)}
      onCompanySelection={(value: any) => {
        const newValue = value?.custom || value || null;
        onUpdate('managementCompany', newValue);
      }}
    />

    {property.propertyType?.name === PropertyTypes.dataCenter && (
      <CompanySearchCombo
        label={translateText(
          'avantPlatform.roles.company.label.operatorCompany',
        )}
        btnLabel={translateText(
          'avantPlatform.roles.company.action.createOperatorCompany',
        )}
        wrapperClassName={styles['form-row']}
        inputId={'property-operator-company-input-id'}
        placeholder={translateText(
          'avantPlatform.roles.company.prompt.enterOperatorCompanyName',
        )}
        companies={
          property.operatorCompany
            ? [property.operatorCompany]
            : property.owners
            ? [property.owners[0]]
            : []
        }
        onClearSelectedSuggestion={() => onUpdate('operatorCompany', null)}
        onCompanySelection={(value: any) => {
          const newValue = value?.custom || value || null;
          onUpdate('operatorCompany', newValue);
        }}
      />
    )}

    <CompanySearchCombo
      label={translateText('avantPlatform.roles.company.label.developer')}
      btnLabel={translateText(
        'avantPlatform.roles.company.action.createDeveloper',
      )}
      placeholder={translateText(
        'avantPlatform.roles.company.prompt.enterDeveloperName',
      )}
      wrapperClassName={styles['form-row']}
      inputId={'property-development-company-input-id'}
      companies={
        property.developmentCompanies ? property.developmentCompanies : []
      }
      isMultiple
      onCompanySelection={(value: any) => {
        const newValue = value?.custom || value;

        newValue?.id &&
          onUpdate('developmentCompanies', [
            ...(property.developmentCompanies || []),
            newValue,
          ]);
      }}
      onRemoveItem={(owner: IdName) => {
        onUpdate(
          'developmentCompanies',
          (property.developmentCompanies || []).filter(
            (value: IdName) => value.id !== owner.id,
          ),
        );
      }}
    />
  </div>
);

export default CompaniesFields;
