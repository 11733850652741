import React from 'react';
import FormCard from './FormCard';
import classNames from 'classnames';
import styles from './FormCardGroup.module.scss';

interface Props {
  wrapperClassName?: string;
  children: React.ReactNode | React.ReactNode[];
}

// TODO: Improve columns rendering layout if required at some point when being reused.
const FormCardGroup: React.FC<Props> = ({ children, wrapperClassName }) => (
  <section className={classNames(wrapperClassName, styles['form-card-group'])}>
    {(Array.isArray(children) ? children : [children]).map(
      (child: React.ReactNode, index: number) => (
        <FormCard key={index}>{child}</FormCard>
      ),
    )}
  </section>
);

export default FormCardGroup;
